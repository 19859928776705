import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPatientsService } from '../../../services/patient/PatientService';
import { replaceRecords } from '../PatientsReducer';

export function useGetPatients() {
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();

  const callback = useCallback(async () => {
    setIsProcessing(true);
    const response = await getPatientsService();
    const patientsResult = response.result;
    if (patientsResult) {
      dispatch(replaceRecords(patientsResult));
    }
    setIsProcessing(false);
  }, []);

  return { isProcessing, getPatients: callback };
}
