import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import { setUsers } from "../../../pages/Search/simpleSearch/SimpleSearchReducer";
import { SearchUsers, UserSearchCriteria } from "../../../services/UsersService";

export default function useSearchUsers() {
    const dispatch = useDispatch();
    const users = useTypedSelector(p => p.simpleSearchInfo?.userSelector.users);

    async function search(criteria: UserSearchCriteria, refresh?: boolean) {
        const response = await SearchUsers(criteria);
      if (response.result) {
        const activeUsers = response.result.records.filter(u => u.isActive);
        dispatch(setUsers([...(refresh ? [] : users ?? []), ...activeUsers]));
          return response.result;
        }
    }
    return { search, users };
}