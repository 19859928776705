import { useState } from 'react';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'typeface-roboto';
import { EDS_Button } from '@EH/eh.eds.react';
import { ReactComponent as Close } from 'assets/svgs/icon-popup-close.svg';
import '../../../assets/styles/components/_clonePaymentPanelModal.scss';
import MultiSelect, { MultiSelectOption } from 'components/select/MultiSelect';
import { Popup } from 'components/popup/Popup';
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from 'models/enums/EnumPopupType';

export interface PaymentPanelCloneMultiselectOption extends MultiSelectOption { tradingPartnerName: string };

export default (props: {
  isOpen: boolean;
  close: () => void;
  organizationOptions?: PaymentPanelCloneMultiselectOption[];
  disabledOption?: string;
  onChangeSelectedValues: (selected: any[], propertyName: string) => void;
  selectedValues?: PaymentPanelCloneMultiselectOption[];
  onPaymentPanelClone: () => void;
}) => {
  const { isOpen, close: onCancelButtonClick, organizationOptions, disabledOption, onChangeSelectedValues, selectedValues, onPaymentPanelClone } = props;
  const [isConfirmationPopUpOpen, setIsConfirmationPopUpOpen] = useState<boolean>(false);
  const openClass = isOpen ? 'eds-modal.open' : '';
  const onCloneButtonClick = () => {
    const hasSelectedValues = selectedValues!.length ? true : false;
    setIsConfirmationPopUpOpen(hasSelectedValues);
  };

  return (
    <div>
      <section className={`eds-modal ${openClass}`} id="clientInfoModal">
        <div className="eds-modal_#container selectFacilityPopupContainer">
          <header>
            <div className="headerFacilitySearchText">Select Facilities</div>
            <div className="text-right headerFacilitySearchCloseButton" onClick={onCancelButtonClick}>
              <Close />
            </div>
          </header>
          <div className="eds-modal_#content">
            <div className="adminSelectFacilityContainer">
              <div className="flexRow">
                <div className={'row clone-buttons-row'}>
                  <div className={'button-group'}>
                    <MultiSelect
                      disableCloseOnSelect={true}
                      label={'Clone Destination to: '}
                      options={organizationOptions ?? []}
                      onChange={onChangeSelectedValues}
                      name={'destination'}
                      values={selectedValues ?? []}
                      multiple={true}
                      groupBy={(o: PaymentPanelCloneMultiselectOption) => o.tradingPartnerName.toUpperCase()}
                      getOptionDisabled={(option) => disabledOption === option.value}
                    />            
                  </div>
                </div>
              </div>

              <div className="flexRow">
                <EDS_Button
                  modifiers="eds-button.primary"
                  buttonText="Clone as Draft"
                  disabled={selectedValues ? false : true}
                  onClick={onCloneButtonClick}
                />
              </div>
              <div className="flexRow">
                <EDS_Button
                  modifiers="eds-button.secondary"
                  buttonText="Cancel"
                  onClick={onCancelButtonClick}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

    {isConfirmationPopUpOpen ? (
      <Popup
        header="PaymentSafe®"
        onClose={() => setIsConfirmationPopUpOpen(false)}
        title="Clone Payment Panel"
        footer={[
          {
            type: EnumPopupButtonsType.cancel,
            style: EnumPopupButtonsStyle.secondary,
            text: 'Cancel',
          },
          {
            type: EnumPopupButtonsType.submit,
            style: EnumPopupButtonsStyle.primary,
            text: 'Confirm',
          },
        ]}
        onSubmit={onPaymentPanelClone}
        customClassNames={{
          footerButton: 'height30',
          container: 'autoHeightWidth',
          title: 'title',
        }}
        >
        Do you want to continue cloning the payment panel?
      </Popup>
      ) : null}

    </div>
  );
};