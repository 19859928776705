import { Metadata } from "../features/admin/epic/whitelist/WhitelistReducer";
import { GenericAttribute } from "../models/metaData/MetaData";
import useErrorStore from "./useErrorStore";
import useTypedValidator from "./useTypedValidator";

export function useGenericGridFormValidator<T extends {id?: string,  metadata?: Metadata }>(attributes: GenericAttribute<T>[]) {
  const { addError, removeError, errorsRef } = useErrorStore();

  const typedSelectorProps = useTypedValidator({
    attributes,
    errors: errorsRef.current,
    removeError,
    addError,
    dispatchable: false,
    getKeySuffix: (entity) => entity?.id ?? entity?.metadata?.tempId ?? ""
  }
  );

  return {
    ...typedSelectorProps,
    getErrorMessage: (key: string) => {
      return typedSelectorProps.getErrorMessage(key, errorsRef.current);
    },
    errorClass: (key: string) => {
      return typedSelectorProps.errorClass(key, errorsRef.current);
    },
    errorsRef,
  }
}