import { useTypedSelector } from "app/rootReducer";
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { useDispatch } from "react-redux";
import { setSimpleSearchInfoState, OrganizationUserSettings } from "./SimpleSearchReducer";
import { getUserDepartmentOrFacilityesOptions } from "utils/UtilsOrganizationDropdowns";
import { useUserUtils } from "utils/useUserUtils";
import { DepartmentMultiselectOption, UserMultiSelectOption, getOrganizationOptions, getUserOption, useGetFilteredDepartments } from "./OrganizationUserSearch";
import { MultiSelectOption } from "components/select/MultiSelect";
import { UserDetailModel } from "models/UserInfoAndRolesModel";
import { FullOrganizationLevelDocument } from "models/OrganizationLevelDocument";

export const useOrganizationUserSearchState = (props?: {
  facilities?: FullOrganizationLevelDocument[],
  departments?: FullOrganizationLevelDocument[],
  users?: UserDetailModel[],
  state: OrganizationUserSettings,
  setState: (state: OrganizationUserSettings) => void
}) => {  
  const { facilities: filteredFacilities, users: filteredUsers, state, setState } = props ?? {};
  const dispatch = useDispatch();

  const { useGetDepartments, useGetFacilities, getFacilityByDepartment, organizations } = useOrganizations();
  const { getLoggedUserOrganizations } = useUserUtils();
  const searchInfo = useTypedSelector(s => s.simpleSearchInfo.value);
  const activeTab = useTypedSelector(s => s.simpleSearchInfo.activeTab);
  const savedState = useTypedSelector(s => s.simpleSearchInfo.savedOrganizationUserSettings[activeTab]);
  const simpleSearchSavedSettings = useTypedSelector(s => s.simpleSearchInfo.saveSettings);
  const advancedSearchSavedSettings = useTypedSelector(s => s.advanceSearchInfo.saveSettings);
  const planSearchSavedSettings = useTypedSelector(s => s.paymentPlanSearchInfo.saveSettings);

  const allFacilities = useGetFacilities();
  const facilities = filteredFacilities ?? allFacilities;
  const departments = useGetDepartments();
  const userOrganisations = getLoggedUserOrganizations();
  const allUsers = useTypedSelector(s => s.userInfo.users) ?? [];
  const users = filteredUsers ?? allUsers;
  
  const userFacilitiesAPG = getUserDepartmentOrFacilityesOptions(facilities, userOrganisations )
  const userDepartmentsAPG = getUserDepartmentOrFacilityesOptions(organizations, userOrganisations, departments)
  const getDepartmentsToUse = (selectedFacilities: MultiSelectOption[]) => filteredFacilities ? getFilteredDepartments(selectedFacilities) : userDepartmentsAPG;

  const getFilteredDepartments = useGetFilteredDepartments(filteredFacilities);

  const recalculateAndSetState = (type: string) => {
    switch(type){
      case 'departments':
        setState && state && setState({
          facilities: state.facilities,
          departments: getSelectedDepartmentsBySelectedFacilities(state.facilities),
          users: state.users
        });
        break;
      case 'users':
        setState && state && setState({
          facilities: state.facilities,
          departments: state.departments,
          users: getSelectedUsersBySelectedFacilities(state.facilities)
        });
        break;
    }
  }

  const withoutRecalculateAndSetState = (newState: { facilities?: MultiSelectOption[], departments?: DepartmentMultiselectOption[], users?: UserMultiSelectOption[] }) => {
    const { facilities: selectedFacilities, departments: selectedDepartments, users: selectedUsers } = newState;
    if(selectedFacilities && state){

      setState && setState({
        facilities: selectedFacilities,
        departments: state.departments,
        users: state.users
      });
    }
    if(selectedDepartments && state){
      setState && setState({
        facilities: state.facilities,
        departments: selectedDepartments,
        users: state.users
      });
    }
    if(selectedUsers && state){
      setState && setState({
        facilities: state.facilities,
        departments: state.departments,
        users: selectedUsers
      });
    }
  }

  const getSelectedUsersBySelectedFacilities = (selectedFacilities: MultiSelectOption[]) => {
    return users
      .filter(u =>
        u.organizationLevels?.find(o => selectedFacilities.find(f => f.value.includes(o.organizationLevel_Path))))
      .map(getUserOption);
  }

   const getSelectedDepartmentsBySelectedFacilities = (selectedFacilities: MultiSelectOption[]) => {
    const selectedDepartments: DepartmentMultiselectOption[] = [];
    selectedFacilities?.forEach(f => {
      selectedDepartments.push(...getDepartmentsToUse(selectedFacilities)
        .filter(d => d.path.includes(f.value))
        .map(d => ({ value: d.path, label: d.name, facilityName: getFacilityByDepartment(d.path)?.name ?? '' }))
      )
    });
    return selectedDepartments;
  }

  const setInitialState = (uses: UserDetailModel[]) => {
    const isSavedFlags: {[key:string]: boolean } = {
      '1': !!simpleSearchSavedSettings,
      '2': !!advancedSearchSavedSettings,
      '3': !!planSearchSavedSettings
    };

    let facilitiesOptions = savedState?.facilities ?? [];
    let departmentsOptions = savedState?.departments ?? [];
    let usersOptions = savedState?.users ?? [];

    let billingId = savedState?.billingId ?? '';
    if (!isSavedFlags[activeTab] || !savedState) {
      facilitiesOptions = getOrganizationOptions(userFacilitiesAPG);
      departmentsOptions = getOrganizationOptions(getDepartmentsToUse(facilitiesOptions)).map(o => ({ ...o, facilityName: getFacilityByDepartment(o.value)?.name ?? '' }));
      usersOptions = uses.map(getUserOption);
    }

    dispatch(setSimpleSearchInfoState({
      ...searchInfo,
      facilities: facilitiesOptions,
      departments: departmentsOptions,
      users: usersOptions,
      billingId: billingId
    }));
  }

  return {
    userFacilitiesAPG,
    userDepartmentsAPG,
    users,
    recalculateAndSetState,
    withoutRecalculateAndSetState,
    setInitialState,
    getSelectedUsersBySelectedFacilities,
    getSelectedDepartmentsBySelectedFacilities
  }
}