import { EDS_TextBox } from '@EH/eh.eds.react';
import { PatientMailingInformation } from 'features/paymentInfo/PatientMailingInformationReducer';
import { TenderInfo } from 'models/PaymentPlan';

export function TenderPaperCheckCart (props: {
  source: PatientMailingInformation;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, name: keyof TenderInfo) => void;
  errorClass: (name: keyof TenderInfo) => '' | 'invalid-field';
  showInputError: (name: keyof TenderInfo) => string | undefined;
}) {
  const {
    source,
    handleChange,
    errorClass,
    showInputError
  } = props;

  return (
    <>
      <div className="flex-row">
        <EDS_TextBox
          name="paperCheckNumber"
          label="*Check Number:"
          type={'text'}
          modifiers={`row-item row-item-size-double ${errorClass(
            'paperCheckNumber'
          )}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'paperCheckNumber')}
          value={source?.paperCheckNumber ? source?.paperCheckNumber : ''}
        />
        
      </div>
      <span className={`${errorClass('paperCheckNumber') ? 'error-message-required' : ''}`}>
            {showInputError('paperCheckNumber')}
        </span>
      <div className="flex-row">
        <EDS_TextBox
          name="financialInstitution"
          label="Financial Institution:"
          modifiers={'row-item row-item-size-double'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'financialInstitution')}
          value={source?.financialInstitution ? source?.financialInstitution : ''}
        />
      </div>
    </>
  )
}