import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'utils/useHistory';
import moment from 'moment';
import {
    PatientInfoModel,
  onReceivePatientData,
  setPatientInfoState,
} from './PatientInfoReducer';
import { useTypedSelector } from 'app/rootReducer';
import { setOpenPatientInfoModal } from 'features/patients/PatientsReducer';
import { useGetOrganizations } from 'features/organizations/hooks/useGetOrganizations';
import { Popup } from 'components/popup/Popup';
import {
  EnumPopupButtonsStyle,
  EnumPopupButtonsType,
} from 'models/enums/EnumPopupType';
import { GetPatientEncountersService, createPatientService, getPatientsPOSTService } from 'services/patient/PatientService';
import {
  getUserDepartmentOrFacilityesOptions
} from 'utils/UtilsOrganizationDropdowns';
import { checkStringHasValue, getMaxDate, getMinDate, validateCombinedConditions } from 'utils/Utils';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'typeface-roboto';
import '../../assets/styles/components/_patientinfopage.scss';
import { Button } from '@EHDS/core';
import { AlertIds, AlertTypes, setAlert, removeAlert } from '../alert/AlertReducer';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import { ReactComponent as Close } from 'assets/svgs/icon-popup-close.svg';
import { Attribute } from 'models/metaData/MetaData';
import ComponentFactory from 'features/componentFactory/PatientInfo/ComponentFactory';
import PatientDynamicSearch from './PatientDynamicSearch';
import { LineItemType } from 'features/paymentDashboard/LineItemComponent';
import { useUserUtils } from 'utils/useUserUtils';
import { LineItem as LineItemModel } from "../../models/LineItem";
import { usePahAlert } from 'features/paymentPlan/hooks/usePahAlert';
import { MultipatientGridSelectorPopup } from './MultiPatientGridSelectorPopup';
import { PatientModel, GetPatientRequest } from '../../models/PatientModel';
import MultiSelect, { MultiSelectOption } from '../../components/select/MultiSelect';
import { getOrganizationOption, getOrganizationOptions } from '../../pages/Search/simpleSearch/OrganizationUserSearch';
import { useOrganizations } from '../organizations/hooks/useOrganizations';
import { OrganizationLevelDocument } from '../../models/OrganizationLevelDocument';

const popupFooterNoResult = [
  {
    type: EnumPopupButtonsType.submit,
    style: EnumPopupButtonsStyle.tertiary,
    text: 'Create New Patient',
    icon: 'person_add'
  },
  {
    type: EnumPopupButtonsType.cancel,
    style: EnumPopupButtonsStyle.secondary,
    text: 'Refine Search Criteria',
  },

];

const getFacilityOption = (organization: OrganizationLevelDocument) => getOrganizationOption(organization, o => o.name);
export function PatientInfo(props: {
  isOpen?: boolean;
  isPreview: boolean;
  close: () => void;
  onNext: () => void;
  onClickAttribute?: (type?: LineItemType, attr?: Attribute, value?: any) => void,
  externalAttributes?: Attribute[];
  title?: string;
}) {
  const { isOpen, isPreview, close, onClickAttribute, externalAttributes, title } = props;
  const openClass = isOpen ? 'eds-modal.open' : '';
  const previewClass = isPreview ? 'preview' : '';
  let history = useHistory();
  const { getErrorMessage } = usePahAlert();

  const dispatch = useDispatch();

  const { useGetFacilities } = useOrganizations();
  const state = useTypedSelector(s => s.patientInfo);
  const isProcessing = useTypedSelector(s => s.services.calls.getPatients?.isProcessing);
  const stateValue = useTypedSelector(s => s.patientInfo?.value);
  const [isNoResultPopupOpen, setIsNoResultPopupOpen] = useState(false);
  const [isSelectedFacilityDepartment, setIsSelectedFacilityDepartment] = useState(false);
  const [openField, setOpenField] = useState<keyof LineItemModel>();
  const { getOrganizations } = useGetOrganizations();
  const { getLoggedUserOrganizations, getDefaultFacility, getDefaultDepartment} = useUserUtils();
  const userOrganisations = getLoggedUserOrganizations();
  const defaultFacility = getDefaultFacility();
  const defaultDepartment = getDefaultDepartment();
  const organizations = useTypedSelector(s => s.organizations?.value);
  const departments = organizations.filter(org => org.organizationLevelType === OrganizationLevelTypes.Department)
  const [patientsResult, setPatientsResult] = useState<PatientModel[]>();
  const [selectedPatient, setSelectedPatient] = useState<PatientModel>()
  const allFacilities = useGetFacilities();
  const userFacilityOptions = getUserDepartmentOrFacilityesOptions(allFacilities, userOrganisations)
  const selectedFacilitySummary = userOrganisations?.find(org => org.organizationLevel_Path === stateValue?.facility?.path);
  const userDepartmentOptions = selectedFacilitySummary ? getUserDepartmentOrFacilityesOptions(organizations, [selectedFacilitySummary], departments) : [];
  const facilityOptions = getOrganizationOptions(userFacilityOptions, getFacilityOption);
  const departmentOptions = stateValue?.facility ? getOrganizationOptions(userDepartmentOptions) : [];

  const dropDownChangeHandler = (
    selectedOption: MultiSelectOption,
    propertyName: keyof PatientInfoModel,
  ) => {
    const updatedValue = organizations.find(e => e.path === selectedOption.value);
    dispatch(
      setPatientInfoState({
        ...stateValue,
        [propertyName]: updatedValue
      })
    );
  };

  const changeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let propertyName = event.target.name;
    let value = event.target.value;
    dispatch(setPatientInfoState({ ...stateValue, [propertyName]: value }));
  };

  const changeDateHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let propertyName = event.target.name;
    let value = event.target.value;
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    dispatch(setPatientInfoState({ ...stateValue, [propertyName]: value }));
  };

  const changeDateHandlerBlur = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let propertyName = event.target.name;
    let value = event.target.value;

    if (
      moment(value).isBefore(getMinDate()) ||
      moment(value).isAfter(getMaxDate())
    ) {
      alert(
        `Date Out of Range Value should be from ${getMinDate()} to ${getMaxDate()}`
      );
      value = '';
    }

    dispatch(setPatientInfoState({ ...stateValue, [propertyName]: value }));
  };

  const searchPatient = () => {
    const patientQuery: GetPatientRequest = {
      firstName: stateValue?.firstName,
      lastName: stateValue?.lastName,
      mrn: stateValue?.mrn,
      dateOfBirth: stateValue?.dateOfBirth,
      accountNo: stateValue?.accountNo,
      guarantorAccountNo: stateValue?.guarantorId,
      secondaryMRN: stateValue?.secondaryMRN,
      consolidationId: stateValue?.consolidationId,
      secondaryConId: stateValue?.secondaryConId
    }

    let phaQuery = new URLSearchParams();
    phaQuery.set('FirstName', stateValue?.firstName || '');
    phaQuery.set('LastName', stateValue?.lastName || '');
    phaQuery.set('Mrn', stateValue?.mrn || '');
    phaQuery.set('DateOfBirth', stateValue?.dateOfBirth || '');
    phaQuery.set('GuarantorAccountNo', stateValue?.guarantorId || '');
    phaQuery.set('AccountNo', stateValue?.accountNo || '');
    phaQuery.set('SecondaryMrn', stateValue?.secondaryMRN || '');
    phaQuery.set('ConsolidationId', stateValue?.consolidationId || '');
    phaQuery.set('secondaryConId', stateValue?.secondaryConId || '');

    retrievePatientData(phaQuery, stateValue.facility?.clientId ?? '', patientQuery);
  };

  useEffect(() => {
    getOrganizations();
  }, [state?.result]);


  useEffect(() => {
    if (stateValue?.facility?.path != undefined && stateValue?.department?.path != undefined) {
      setIsSelectedFacilityDepartment(true);
    } else {
      setIsSelectedFacilityDepartment(false);
    }
  }, [stateValue?.facility?.id, stateValue?.department?.id]);

  useEffect(() => {
    if(isOpen && organizations.length>0){
      if (defaultFacility && defaultDepartment) {
        dropDownChangeHandler(getFacilityOption(defaultFacility), "facility");
        dropDownChangeHandler(getOrganizationOption({...defaultDepartment, organizationLevelType : OrganizationLevelTypes.Department}), "department")
      } 
    }
    
  },[isOpen,organizations, defaultFacility,defaultDepartment]);


  let modalElement = document.getElementById('patientInfoModal');

  const patientAlert = { id: AlertIds.PlanDetailsAlert, type: AlertTypes.Error, message: `At least one of the optional fields must be filled.`, dismissable: true };

  const handleSingleResult = (id?: string) => {
    const facilityId = stateValue?.facility?.id || '';
    const departmentId = stateValue?.department?.id || '';
    history.push(`/payment/${id}?facility=${facilityId}&department=${departmentId}`);
    dispatch(setOpenPatientInfoModal(false));
    localStorage.setItem('typedAccountNumber',  stateValue?.accountNo ?? '');
    localStorage.setItem('patientInfo', JSON.stringify(stateValue));
    window.location.reload();
  };

  const [isMultiPatientSelectModalOpen, setIsMultiPatientSelectModalOpen] = useState(false);

  const handleMultipleResults = () => {
    setIsMultiPatientSelectModalOpen(true);
  };

  const closeNoResultPopup = () => {
    setIsNoResultPopupOpen(false);
    if (modalElement) {
      modalElement.style.filter = '';
    }
  };

  const isPatientInfoValid = () => {
    return stateValue.dateOfBirth !== '' || stateValue.guarantorId !== '' || stateValue.mrn !== '' || stateValue.accountNo !== '';
  };


  const validateAndPatientSearch = () => {
    if (!validateSearchInfo()) {
      dispatch(setAlert({ ...patientAlert, type: AlertTypes.Error, message: errorMessage, dismissable: true }));
      return;
    }
    if (!isPatientInfoValid()) {
      dispatch(setAlert(patientAlert));
      return;
    }
    searchPatient();
  };

  const getPatientIfMrnExists = (mrnExists: boolean, response: any): [any, string] => {
    if (!mrnExists && response.result?.records.length === 0) {          
      return [null, ''];
    }
    dispatch(onReceivePatientData(response));
    return [response.result?.records, response.result?.records[0].id];
  }

  const retrievePatientData = async (phaQuery: URLSearchParams, clientId: string, patientQuery: GetPatientRequest) => {
    let phaResult = null;
    let patient = null;
    let patientid = '';

    const accountNumber = phaQuery.get("AccountNo");
    const mrn = phaQuery.get("Mrn");

    if (validateCombinedConditions(accountNumber, !mrn)) {
        phaResult = await getEncounters(phaQuery, clientId);
        if (phaResult) {
          const extractedMrn = phaResult[0].mrn;
            if (extractedMrn) {
                phaQuery.set("Mrn", extractedMrn);
                patientQuery = buildPatientQuery(patientQuery, extractedMrn);
            }
        }
    }

    phaResult = await getEncounters(phaQuery, clientId);
    const patientResponse = await getPatientsPOSTService(patientQuery);
    setPatientsResult(patientResponse?.result?.records);

    if (patientResponse?.result && patientResponse?.result?.records?.length > 0) {
        if (patientResponse.result.records.length > 1) {
          handleMultipleResults();
          return;
        } else {
            const mrnExists = phaQuery.get("Mrn")?.toLowerCase() === patientResponse.result.records[0].mrn?.toLowerCase();
            [patient, patientid] = getPatientIfMrnExists(mrnExists, patientResponse);
        }
    }

    if (!phaResult && !patient) {
      setIsNoResultPopupOpen(true); 
        return;
    }

    if (phaResult && !patient) {
      patientid = await createPatient(phaResult);
    }

    handleSingleResult(patientid);
}

const getEncounters = async (phaQuery: URLSearchParams, clientId: string) => {
    try {
        const response = await GetPatientEncountersService({ clientId }, phaQuery);
        if (response.result) {
            return response.result;
        } else if (response.errorResponse && response.errorResponse.status === 500) {
            getErrorMessage();
        }
    } catch (error) {
        console.error(error);
    }
    return null;
}

const buildPatientQuery = (patientQuery: GetPatientRequest, mrn: string) => {
  return {
    firstName: patientQuery.firstName, 
    lastName: patientQuery.lastName, 
    mrn: mrn, 
    dateOfBirth: patientQuery.dateOfBirth
  }
}


  const validateSearchInfo = () => {
    return (
      stateValue?.firstName !== '' &&
      stateValue?.lastName !== '' &&
      stateValue?.facility?.id !== '' &&
      stateValue?.department?.id !== ''
    );
  };

  async function createPatient(pahInfo?: any) {
    let patientid = ''
    const patientInfo = pahInfo ? 
    { ...stateValue, 
      mrn: checkStringHasValue(pahInfo?.[pahInfo?.length -1]?.mrn),
      accountNo: "",
      secondaryMRN: checkStringHasValue(pahInfo?.[pahInfo?.length -1]?.secondaryMRN), 
      secondaryConId: checkStringHasValue(pahInfo?.[pahInfo?.length -1]?.secondaryConId) 
    } : stateValue;
    
    if (!isPatientInfoValid()) {
      dispatch(setAlert(patientAlert));
    }
    const response = await createPatientService(patientInfo);
    if (response.err) {
      dispatch(setAlert({ ...patientAlert, message: `Creating patient failed with error ${response.errorResponse?.status}.` }));
    }

    if (response.result) {
      patientid = response.result.id;
      dispatch(onReceivePatientData({ result: [response.result] }));
    }

    closeNoResultPopup();
    return patientid;
  };
  const addPatient = async () => {
    await createPatient().then(response => {
      if (response != '') {
        handleSingleResult(response);
      }
    });
  }

  const onClickComponentAttribute = (attr?: Attribute, value?: any) => {
    setOpenField(attr?.name)
    onClickAttribute && onClickAttribute(undefined, attr, value);
  };

  const popupChildren = <></>;
  const errorMessage = 'Please complete the required information to continue.';
  const attributes = externalAttributes ?? [];

  function buildRows(rowsNumber: number) {
    if (!isPreview) {
      return [];
    }

    const rows = [];
    let currentRow: Attribute[] = [];

    attributes.forEach(attribute => {
      currentRow.push(attribute);

      if (currentRow.length === rowsNumber) {
        rows.push([...currentRow]);
        currentRow.length = 0;
      }
    });

    if (currentRow.length > 0) {
      rows.push([...currentRow]);
    }

    return rows;
  }

  const components = buildRows(2).map((row, index) => {
    return (
      <div className="flexRow"
        key={index}
      >
        {row.map((attribute) => {
          const modifiers = `line-item-attribute ${attribute.name}`;
          const currentAttribute = attribute.name === openField || false;

          return (
            <div className={`attribute-container rowItem rowItemSize eds4 ${isPreview && currentAttribute ? 'line-item-selected' : ''}`}
              key={`${attribute.name}-container`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClickComponentAttribute && onClickComponentAttribute(attribute, 'value')
              }}
            >
              <ComponentFactory
                attribute={attribute}
                key={`${attribute.name}-attribute`}
                value={attribute.placeholderText ? attribute.placeholderText : ''}
                onClick={value => onClickComponentAttribute(attribute, value)}
                modifiers={modifiers}
              />
            </div>
          );
        })}
      </div>
    );
  });

  function getDynamicFields() {
    return isPreview ? components :
      <><div>
        <PatientDynamicSearch facilityPath={stateValue?.facility?.path} changeHandler={changeHandler} changeDateHandler={changeDateHandler} changeDateHandlerBlur={changeDateHandlerBlur} stateValue={stateValue} ></PatientDynamicSearch>
      </div></>
  }

  function getSearchFieldsTitle() {
    return isPreview || isSelectedFacilityDepartment ?
      <>
        <div className="patient-information">{title ?? "PATIENT INFORMATION"}</div>
        {getDynamicFields()}
      </> : <></>
  }

  const getFacilityValue = () => {
    if (stateValue?.facility) {
      return getFacilityOption(stateValue.facility);
    } 
    return defaultFacility ?  getFacilityOption(defaultFacility) : null;
  };

  const getDepartmentValue = () => {
    if (stateValue?.department) {
      return getOrganizationOption(stateValue.department);
    } 
    return defaultDepartment ? getOrganizationOption({
        ...defaultDepartment,
        organizationLevelType: OrganizationLevelTypes.Department})
      : null;
    };

  return (
    <div className='modal-selected'>
      <section className={`eds-modal ${openClass} ${previewClass}`} id="patientInfoModal">
        <div
          className="eds-modal_#container"
          style={{ top: isPreview ? 0 : 100, padding: 0, borderRadius: 0 }}
        >
          {!isPreview ?
            <header
              className="eds-modal_#header headerTitle"
            >
              <div className="col titleText" >PaymentSafe®</div>
              <div className="col text-right headerCloseButton" onClick={() => { dispatch(removeAlert(patientAlert)); close(); localStorage.removeItem('shouldRedirectToPlans'); }}>
                <Close />
              </div>
            </header> : null}
          <div className="eds-modal_#content">
            <div className="patientInfoPageContainer">
              <div className="patientInfoFormContainer">
                {!isPreview ?
                  <div>
                    <div className='required-fields-warning'>* Required Fields. Please enter all required fields and at least one of the optional fields: </div>
                    <div className="user-location">USER LOCATION</div>
                    <div className="flexRow organization-selector">
                      <MultiSelect
                        label={"*Facility:"}
                        name={"facility"}
                        options={facilityOptions}
                        onChange={(option?: MultiSelectOption | null) => {
                          option && dropDownChangeHandler(option, "facility")
                        }}
                        values={getFacilityValue()}
                        multiple={false}
                        placeholder="Please select facility"
                        disableCloseOnSelect={false}
                      />
                      <MultiSelect
                        label={"*Department:"}
                        name={"department"}
                        options={departmentOptions}
                        onChange={(option?: MultiSelectOption | null) => {
                          option && dropDownChangeHandler(option, "department")
                        }}
                        values={getDepartmentValue()}
                        multiple={false}
                        placeholder="Please select department"
                        disableCloseOnSelect={false}
                      />
                    </div>
                  </div> : null}
                {getSearchFieldsTitle()}
                <div className="flexRow alignItemsRight">
                  {!isPreview ?
                    <div className="btnItem">
                      <Button
                        className="eds-button eds-button.primary iconOnRight"
                        disabled={isProcessing}
                        onClick={validateAndPatientSearch}
                        iconName={'chevron_right'}
                      >
                        Next
                      </Button>
                    </div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isNoResultPopupOpen && (
        <Popup
          type="warning"
          text={"No Patient Record Found.\n Please, refine your search criteria or create new Patient."}
          fullHeight={false}
          children={popupChildren}
          footer={popupFooterNoResult}
          onClose={closeNoResultPopup}
          onSubmit={addPatient}
        />
      )}
      {isMultiPatientSelectModalOpen ? <MultipatientGridSelectorPopup
        closePopup={() => setIsMultiPatientSelectModalOpen(false)}
        onSubmit={() => {
          selectedPatient && handleSingleResult(selectedPatient.id);
        }}
        patients={patientsResult}
        selectedPatient={selectedPatient}
        setSelectedPatient={setSelectedPatient}
      /> : null}
    </div>
  );
};
