import { RemitConfigurationSettings, Setting } from "../../../../models/admin/RemitConfiguration";
import { GenericAttribute } from "models/metaData/MetaData";
import { DataType } from "models/metaData/MetaDataEnums";
import { required } from "features/paymentDashboard/Validators";
import useTypedValidator from "../../../../utils/useTypedValidator";
import { addSettingError, removeSettingError } from "../RemitConfigurationReducer";

export default function useSettingValidator(settingKey: keyof RemitConfigurationSettings, index: number,  setting?: Setting) {
  const attributes: GenericAttribute<Setting>[] = [
    { name: "name", dataType: DataType.String, validators: [required()], visible:true },
    { name: "function", dataType: DataType.String, validators: [required()], visible:true },
    { name: "dbField", dataType: DataType.String, validators: [required()], visible:true  },
  ];

  return useTypedValidator<Setting>({
    attributes,
    removeError: (props) => removeSettingError({ settingKey, index, ...props }),
    addError: (errors) => addSettingError({ settingKey, index, errors}),
    entity: setting,
    errors: setting?.metadata?.errors
  });
}