import React from 'react';
import { CSSProperties } from 'styled-components';
import { ReactComponent as Icon } from 'assets/svgs/paymentPlanIcons/icon-verified-user.svg';
import 'assets/styles/components/_authorizationModal.scss';

interface AuthorizationConfirmationPrintProps {
  ref?: string | ((instance: HTMLDivElement | null) => void);
  text: string;
  style?: CSSProperties;
}

export class AuthorizationConfirmationPrint extends React.PureComponent<AuthorizationConfirmationPrintProps> {
  private div!: HTMLDivElement;
  private setRef = (ref: HTMLDivElement) => (this.div = ref);

  public render() {
    const {
      text,
      style
    } = this.props;

    return (
      <div style={style as React.CSSProperties} className="print-authorization-container print-page" ref={this.setRef}>
        <div className="flex-row">
          <Icon />
        </div>
        <div className="line" />
        <div className="flex-row">
          {text}
        </div>
        <div className="line" />
      </div>
    );
  }
}
