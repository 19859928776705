import { Attribute } from 'models/metaData/MetaData';
import { ValidatorType } from 'models/metaData/MetaDataEnums';
import React from 'react';
import { getValidator } from 'utils/metadata/MetaDataUtils';
import { LineItem } from '../../../models/LineItem';
import { DropDown } from '../wrapperComponents/DropDown';

/** If the RangeValidator is present and its value is an array, present the attribute with a DropDown.*/
export function getComponentForRangeValidator(
  attribute: Attribute,
  value: any,
  onChange?: (value: any) => void,
  modifiers?: string,
  lineItem?: LineItem
) {
  const rangeValidator = getValidator(attribute, ValidatorType.RangeValidator);

  if ((rangeValidator && Array.isArray(rangeValidator.value)) || attribute.fieldType === 'DropDown') {
    return (
      <DropDown
        attribute={attribute}
        value={value}
        onChange={onChange}
        modifiers={modifiers}
        lineItem={lineItem}
      />
    );
  }
  return undefined;
}
