export enum EnumPlanStatus {
  None = 'None',
  Active = 'Active',
  Paused = 'Paused',
  Stopped = 'Stopped',
  Cancelled = 'Cancelled',
  Completed = 'Completed'
}

export enum EnumTermType {
  None = 'None',
  Weekly = 'Weekly',
  BiWeekly = 'BiWeekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annually = 'Annually'
}