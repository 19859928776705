import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dashboards } from './ConfigurationSelector';
import { Attribute, MetaData } from '../../../models/metaData/MetaData';
import { LineItem } from '../../../models/LineItem';
import { PatientModel } from '../../../models/PatientModel';
import { PatientPanelApiConfiguration } from '../../../services/PatientPanelService';
import { getAnnotations, getDataTypeByFieldName, mapValidators } from 'utils/UtilsPaymentPanel';

export interface State {
  configurations: {
    [key in keyof Dashboards]: MetaData;
  },
  patientConfigurations: {
    [key in keyof Dashboards]: PatientPanelApiConfiguration;
  }
}

export const initialState: State = {
  configurations: {},
  patientConfigurations: {}
};

const reducerSlice = createSlice({
  name: 'PaymentPanelReducer',
  initialState,
  reducers: {
    saveConfiguration(state, action: PayloadAction<{ key: keyof Dashboards, configuration: MetaData }>) {
      state.configurations[action.payload.key] = action.payload.configuration;
    },
    savePatientConfiguration(state, action: PayloadAction<{ key: keyof Dashboards, configuration: PatientPanelApiConfiguration }>) {
      state.patientConfigurations[action.payload.key] = action.payload.configuration;
    },
    saveConfigurationEntity(state, action: PayloadAction<{
      key?: keyof Dashboards,
      attributeName: keyof LineItem | keyof PatientModel,
      name: keyof Attribute,
      value?: string | boolean | Attribute['listOptions'],
      newCustomAttributeName?: string
    }>) {
      const { key, attributeName, name, value, newCustomAttributeName } = action.payload;

      if (!key) return;
      const configuration = state.configurations[key];
      const attributes = configuration?.entity?.attributes ?? [];
      const attributeIdx = !newCustomAttributeName ? attributes.findIndex(a => a.name === attributeName) : attributes.findIndex(a => a.newCustomFieldName === newCustomAttributeName);
      let attribute = attributes[attributeIdx];
      if (attribute) {
        attribute = {
          ...attribute,
          [name]: value,
        }
        attributes[attributeIdx] = {
          ...attribute,
          dataType: getDataTypeByFieldName(attribute.saveAs as keyof LineItem), 
          annotations: getAnnotations(attribute), 
          validators: mapValidators(attribute)
        };
        const entity = state.configurations[key]?.entity;
        state.configurations[key]!.entity = {
          name: key,
          ...entity,
          attributes
        };
      }
    },
  },
});

export const { saveConfiguration, saveConfigurationEntity, savePatientConfiguration } = reducerSlice.actions;
export default reducerSlice.reducer;
