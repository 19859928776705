import { Container, TabPane } from 'reactstrap';
import {useState,useEffect} from 'react';
import 'typeface-roboto';
import styles from './volumeSearch.module.scss';
import '../../../assets/styles/components/_searchpage.scss';
import { getAppSettings } from 'services/AppSettingsService';

export default function VolumeSearch() {
   
      const [powerReportingUrl, setPowerReportingUrl] = useState<string>('');

      async function getPowerReportingRef() :Promise<string>{
        const appSettings = await getAppSettings();
        const PRUrl= appSettings?.POWER_REPORTING_URL || "https://analytics2.experianhealth.com/";
        return (PRUrl.toString());
      }  
 
    // run effect once 
      useEffect(() => { 
        let isMounted =true;
        getPowerReportingRef().then((res)=>{
          if(isMounted) {
            setPowerReportingUrl(res)
          }});
        
         return()=> {
          isMounted=false
        };
      }, []);
      
  return (
    <>
      <TabPane className="tab-pane-content" tabId="4">
         <Container>       
            <div className= {styles.volumesearchtext}>
              <a href={powerReportingUrl} rel="noopener noreferrer" target="_blank"> <u>Power Reporting</u></a>
            </div>    
          </Container> 
      </TabPane>
    </>
  );
}
