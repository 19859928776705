import 'assets/styles/components/_cloneUserPermissionsConfirmation.scss';
import { Popup } from "components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";
import { permissionsMenuModes } from "../UsersTab/UserPermissionsMenu";
import PermissionsList from '../PermissionsList';
import { getPermissionsFilters } from '../PermissionsMenu';
import usePermissionsState from '../UsersTab/hooks/usePermissionsState';
import { TextArea } from '@EHDS/core';
import { logAdminActivity } from '../../../../services/PermissionsService';
import { useState } from 'react';
import { PermissionItem } from '../../../../models/Permission';

export interface UserInfo{
  user?: string,
  organization?: string,
  facility?: string,
  department?: string
}

export default (props: {
  close: () => void;
  onNext: () => Promise<boolean>;
  userInfo: UserInfo;
  mode: permissionsMenuModes;
  isUserTab?: boolean;
  selectedPermissions: string[];
}) => {
  const { close, onNext, userInfo, mode, isUserTab, selectedPermissions} = props;
  const [reason, setReason] = useState("");

  const footerButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel',
      disabled: false
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: `${mode == permissionsMenuModes.add ? "Add" : "Remove"} Permissions`,
      disabled: reason.length == 0
    },
  ];

  
  const { paymentPermissions, paymentPlansPermissions, generalLegderPermissions, pSafeAdminPermissions, epicPermissions } = usePermissionsState();
  const {
    paymentsPermissionsToShow,
    paymentPlansPermissionsToShow,
    generalLegderPermissionsToShow,
    pSafeAdminPermissionsToShow,
    epicPermissionsToShow
  } = getFilteredPermissions({
    paymentPermissions,
    paymentPlansPermissions,
    generalLegderPermissions,
    pSafeAdminPermissions,
    epicPermissions
  }, selectedPermissions);

  return (
  <Popup 
    header={
      <span className='select-remit-header'>
        PaymentSafe<sup>&reg;</sup>&nbsp;&nbsp;&nbsp;
      </span>
    }
    onClose={close}
    footer={footerButtons}
    onSubmit={async () => {
      if (await onNext()) {
        logAdminActivity(reason);
      }
    }}
    onClear={close}
    customClassNames={{footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
    >
      <div className="popupContent">
        <div className="rowItemPopup">         
          <div className="confirmationContainer">
            <span className="attribute_label">Confirm {mode === permissionsMenuModes.add ? "adding":"removing"} the following selected permissions:</span>
            <div className="flexrow permissions-preview">
              {paymentsPermissionsToShow.length ? <div className={"permissions-column"}>
                <div className={"permissions-title"}>Payments</div>
                <PermissionsList
                  permissions={paymentsPermissionsToShow}
                  readOnly={true}
                />
              </div> : null}
              {paymentPlansPermissionsToShow.length ? <div className={"permissions-column"}>
                <div className={"permissions-title"}>Payment Plans</div>
                <PermissionsList
                  permissions={paymentPlansPermissionsToShow}
                  readOnly={true}
                />
              </div> : null}
              {generalLegderPermissionsToShow.length ? <div className={"permissions-column"}>
                <div className={"permissions-title"}>Payment Plans</div>
                <PermissionsList
                  permissions={generalLegderPermissionsToShow}
                  readOnly={true}
                />
              </div> : null}
              {pSafeAdminPermissionsToShow.length ? <div className={"permissions-column"}>
                <div className={"permissions-title"}>Admin</div>
                <PermissionsList
                  permissions={pSafeAdminPermissionsToShow}
                  readOnly={true}
                />
              </div> : null}
              {epicPermissionsToShow.length ? <div className={"permissions-column"}>
                <div className={"permissions-title"}>Epic</div>
                <PermissionsList
                  permissions={epicPermissionsToShow}
                  readOnly={true}
                />
              </div> : null}
            </div>
            <div className="flexrow">
              <span className="attribute_label">{mode === permissionsMenuModes.add ? "To" : "From"}</span>
              <div className="information_attribute">
                {isUserTab ? <><span>User: </span><span className="attribute_label">{`${userInfo?.user ?? ''} `}</span></> : null}
                <span>Organization: </span><span className="attribute_label">{`${userInfo?.organization ?? ''} `}</span>
                <span>Facility: </span><span className="attribute_label">{`${userInfo?.facility ?? ''} `}</span>
                {userInfo?.department?.length ? <><span> Department: </span><span className="attribute_label">{`${userInfo.department} `}</span></> : null}
              </div>
            </div>
            <div className="flexRow full-width-input">
              <div className="attribute_label">Reason for change:</div>
              <TextArea value={reason} onChange={(e) => { setReason(e.target.value) }} />
            </div>
          </div>
        </div>              
      </div>
  </Popup>
  );
}


export function getFilteredPermissions(permissions: {
  paymentPermissions?: PermissionItem[],
  paymentPlansPermissions?: PermissionItem[],
  generalLegderPermissions?: PermissionItem[],
  pSafeAdminPermissions?: PermissionItem[],
  epicPermissions?: PermissionItem[]
},
  selectedPermissions?: string[]) {
  const { paymentPermissions, paymentPlansPermissions, generalLegderPermissions, pSafeAdminPermissions, epicPermissions } = permissions;
  const filterPermissions = getPermissionsFilters(permissionsMenuModes.view)

  const paymentsPermissionsToShow = filterPermissions(paymentPermissions ?? [], selectedPermissions ?? [])
  const paymentPlansPermissionsToShow = filterPermissions(paymentPlansPermissions ?? [], selectedPermissions ?? [])
  const generalLegderPermissionsToShow = filterPermissions(generalLegderPermissions ?? [], selectedPermissions ?? [])
  const pSafeAdminPermissionsToShow = filterPermissions(pSafeAdminPermissions ?? [], selectedPermissions ?? [])
  const epicPermissionsToShow = filterPermissions(epicPermissions ?? [], selectedPermissions ?? [])

  return {
    paymentsPermissionsToShow,
    paymentPlansPermissionsToShow,
    generalLegderPermissionsToShow,
    pSafeAdminPermissionsToShow,
    epicPermissionsToShow
  };
}
