import { Checkbox, Input, Radio } from "@EHDS/core";
import React, { useEffect, useState } from "react";
import { PredefinedData, RadioButtons, RemitConfigurationSettings, Setting, TrimStyleEnum } from "../../../../models/admin/RemitConfiguration";
import { EDS_Select } from '@EH/eh.eds.react';
import useSettingValidator from "./useSettingValidator";
import { MultiSelectOption } from "../../../../components/select/MultiSelect";
import { enumToSelectOptions, nameof, valueOrDefault } from "../../../../utils/Utils";
import { Popup } from "components/popup/Popup";
import { Autocomplete } from '@material-ui/lab';
import { Paper, TextField } from '@material-ui/core';
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";
import { RemitFileFieldDescription } from "models/RemitPreDefinedListsModel";
import '../../../../assets/styles/components/_settingForm.scss'
import { ScribanTemplateBuilderPopup } from "../popups/ScribanTemplateBuilderPopup";
import { useTypedSelector } from "app/rootReducer";

export default function SettingForm(props: {
  setting: Setting,
  onChange: (setting: Setting) => void,
  settingKey: keyof RemitConfigurationSettings,
  index: number,
  predefinedData: PredefinedData,
  remitAggregateSettings?: Setting[],
  remitFileFieldDescriptions?: RemitFileFieldDescription[]
}) {
  const { setting, onChange, settingKey, index, predefinedData, remitAggregateSettings, remitFileFieldDescriptions } = props;
  const radioButtonNames = {
    defaultValue: `defaultValueRb_${settingKey}${index}`,
    aggregateSettings: `aggregateSettingsRb_${settingKey}${index}`,
    conditionalSetting: `conditionalSettingRb_${settingKey}${index}`,
    predefinedFunction: `predefinedFunctionRb_${settingKey}${index}`
  }

  const remitFilePredefinedFunctionsDescriptions = useTypedSelector(s => s.remitConfiguration?.remitFilePredefinedFunctionsDescriptions);

  function onChangeSetting(name: keyof Setting, value: any) {
    validate(attributes.find(a => a.name == name), value)
    onChange({ ...setting, [name]: value });
  }

  function changeDbFieldConditionalSetting(dbField?: string, conditional?: string ) {
    const dbFieldKey = nameof<Setting>('dbField');
    validate(attributes.find(a => a.name == dbFieldKey, dbField))

    const conditionalKey = nameof<Setting>('conditional');
    validate(attributes.find(a => a.name == conditionalKey, conditional))

    onChange({
      ...setting,
      [dbFieldKey]: dbField,
      [conditionalKey]: conditional
    });
  }

  function dbFieldToConditional(value: string) {
    if (value === '') return ''
    return `{{ ${value} }}`
  }

  const { attributes, validate, getErrorMessage, errorClass } = useSettingValidator(settingKey, index, setting);

  const headerComponentName = "headersColumnSettings";
  const footerComponentName = "footerColumnSettings";

  const [openDBfieldPopup, setOpenDBfieldPopup] = useState(false)
  const [selectedDbFields, setSelectedDbFields] = useState<MultiSelectOption>()
  const [openConditionBuilderPopup, setOpenConditionBuilderPopup] = useState(false)
  
  useEffect(() => {
    if (setting.dbField && !setting.conditional) {
      onChangeSetting("conditional", dbFieldToConditional(setting.dbField));
    }
  }, [setting.dbField])

  const onDbFieldChange = (selected: MultiSelectOption) => {
    const option = selected;
    const optionValue = option?.value;
    
    if ((optionValue || optionValue === '') && setting.conditional) {
      setSelectedDbFields(selected);
      setOpenDBfieldPopup(!!setting.conditional);
      return;
    }
    const conditional = optionValue ? dbFieldToConditional(optionValue) : '';
    changeDbFieldConditionalSetting(optionValue, conditional);
  }

  const onCloseDBChange = () => {   
    setOpenDBfieldPopup(false)
  }

  const onSubmitDBChange = () => {
    (selectedDbFields?.value || selectedDbFields?.value ==='') && changeDbFieldConditionalSetting(
      selectedDbFields.value,
      dbFieldToConditional(selectedDbFields.value)
    );
    setOpenDBfieldPopup(false);
  }

  const changeRadio = (e: any) => {
    onChange({
      ...setting,
      selectedRadioButton: e.target.value
    });
  };


  const getAutocompleteOptionSelected = (option: MultiSelectOption, selected: MultiSelectOption) => {
    const value = valueOrDefault(selected.value, selected);
    return option.value == value;
  }

  const getAutocompleteOptionLabel = (option: MultiSelectOption) => (
    valueOrDefault(option.label, option)
  )

  const dbFieldOptions: MultiSelectOption[] = remitFileFieldDescriptions ? remitFileFieldDescriptions.map(f => {
    return { label: f.displayName, value: f.fieldName }
  }).sort((a, b) => a.label.localeCompare(b.label)) : []

  const dbFieldSelected = remitFileFieldDescriptions?.find(f =>
    setting?.dbField === f?.fieldName 
  )

  const defaultOption = { optionName: '', value: '' };

  const dbFieldOptionSelected: MultiSelectOption = dbFieldSelected 
  ? {label: dbFieldSelected?.displayName, value: dbFieldSelected?.fieldName } 
  : {label:'', value:''}

  const aggregateSettingsOptions = () => {
    let aggregateOptions = remitAggregateSettings?.map(a => ({ optionName: a.name, value: a.name }));
    if(aggregateOptions) {
      aggregateOptions.unshift(defaultOption);
      return aggregateOptions;
    }
    return [ defaultOption];
  };

  const trimStyleOptions = enumToSelectOptions(TrimStyleEnum);

  const isHeaderSetting = settingKey === headerComponentName;
  const isFooterSetting = settingKey === footerComponentName;

  const filePredefinedFunctionsDescriptionsToSelectOptions = () =>{
     let predefinedFunctionOptions = remitFilePredefinedFunctionsDescriptions?.filePredefinedFunctionsDescriptions?.map(filePredefinedFunctionsDescription => {
        return {
          optionName: filePredefinedFunctionsDescription.displayName,
          value: filePredefinedFunctionsDescription.fieldName
        }
      });
      if(predefinedFunctionOptions){
        predefinedFunctionOptions.unshift(defaultOption);
        return predefinedFunctionOptions.sort((a, b) => a.optionName.localeCompare(b.optionName));
      }
    
     return [defaultOption];
  };

  function isRadioButtonChecked(key: RadioButtons) {
    return setting.selectedRadioButton?.toString() === key.toString();
  }

  return <div>
    <div className="row">
      <div className="col-lg-4">
        <Input
          label="Name:"
          name={"name"}
          placeholder={"Enter name"}
          className={'rowItem rowItemSize eds4'}
          value={setting.name}
          onChange={(e) => onChangeSetting("name", e.target.value)}
        />
      </div>
      <div className="col-lg-2">
        <EDS_Select
          label="Trim Style:"
          name={"trimStyle"}
          placeholder={"Select trim style"}
          modifiers={'rowItem rowItemSize eds4'}
          value={trimStyleOptions.find(t => t.value.toString() === setting.trimStyle || t.optionName === setting.trimStyle)?.value}
          options={trimStyleOptions}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { onChangeSetting("trimStyle", e.target.value) }}
        />
      </div>
      <div className="col-lg-4">
        <Checkbox
          label="Capitalize:"
          name={"capitalize"}
          checked={setting.capitalize}
          onChange={(e) => { onChangeSetting("capitalize", !setting.capitalize) }}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <span className={`${errorClass && errorClass("name")}`}>
          {getErrorMessage && getErrorMessage("name")}
        </span>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-2">
        <Input
          label="Prefix:"
          name={"prefix"}
          placeholder={"Enter prefix"}
          className={'rowItem rowItemSize eds4'}
          value={setting.prefix}
          onChange={(e) => onChangeSetting("prefix", e.target.value)}
        />
      </div>       
      <div className="col-lg-2">
        <Input
          label="Postfix:"
          name={"postfix"}
          placeholder={"Enter postfix"}
          className={'rowItem rowItemSize eds4'}
          value={setting.postfix}
          onChange={(e) => onChangeSetting("postfix", e.target.value)}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-lg-2">
        <Radio
          label={"Default Value"}
          value={RadioButtons.defaultValue.toString()}
          name={radioButtonNames.defaultValue}
          checked={isRadioButtonChecked(RadioButtons.defaultValue)}
          onChange={changeRadio}
        />
      </div>
      {
        isFooterSetting && 
        <div className="col-lg-3">
          <Radio
            label={"Aggregate Settings"}
            value={RadioButtons.aggregateSettings.toString()}
            name={radioButtonNames.aggregateSettings}
            checked={isRadioButtonChecked(RadioButtons.aggregateSettings)}
            onChange={changeRadio}
          />
        </div>
      }
      {
        !isFooterSetting && !isHeaderSetting &&
        <div className="col-lg-2">
          <Radio
            label={"Conditional"}
            value={RadioButtons.conditionalSetting.toString()}
            name={radioButtonNames.conditionalSetting}
            checked={isRadioButtonChecked(RadioButtons.conditionalSetting)}
            onChange={changeRadio}
          />
        </div>
      }
      {
        (isFooterSetting || isHeaderSetting) &&
        <div className="col-lg-3">
          <Radio
            label={"Pre-defined Function"}
            value={RadioButtons.predefinedFunction.toString()}
            name={radioButtonNames.predefinedFunction}
            checked={isRadioButtonChecked(RadioButtons.predefinedFunction)}
            onChange={changeRadio}
          />
        </div>
      }      
    </div>

    <div className="row">
      {
        isFooterSetting && isRadioButtonChecked(RadioButtons.aggregateSettings) ?
          <div className="col-lg-4">
            <EDS_Select
              label="Aggregate Settings"
              name="aggregateSetting"
              modifiers={'rowItem rowItemSize eds4'}
              value={aggregateSettingsOptions().find(o => o.value === setting.aggregateSetting)?.value ?? null}
              options={aggregateSettingsOptions()}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { onChangeSetting("aggregateSetting", e.target.value) }}
             />
        </div> : null
      }
      {
        isRadioButtonChecked(RadioButtons.defaultValue) &&
        <div className="col-lg-4">
          <Input
            label="Default Value:"
            name={"defaultValue"}
            placeholder={"Enter a default value"}
            className={'rowItem rowItemSize eds4'}
            value={setting.defaultValue}
            onChange={(e) => onChangeSetting("defaultValue", e.target.value)}
          />
        </div>
      }
      {isRadioButtonChecked(RadioButtons.predefinedFunction) && (isHeaderSetting || isFooterSetting) &&
        <div className="col-lg-4">
          <EDS_Select
            label="Pre-defined Function:"
            name={"predefinedFunction"}
            placeholder={"Select a pre-defined function"}
            modifiers={'rowItem rowItemSize eds4'}
            value={setting.predefinedFunction}
            options={filePredefinedFunctionsDescriptionsToSelectOptions()}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { onChangeSetting("predefinedFunction", e.target.value) }}
          />
        </div>
      }
      {
        !isHeaderSetting && !isFooterSetting && isRadioButtonChecked(RadioButtons.conditionalSetting) &&
        <div className="col-lg-2 autocomplete-field">        

        <label>
          <span className="actocomplate-label">DB field:</span>
          <Autocomplete
            openOnFocus
            id={'dbField'}
            disableClearable={
              setting.dbField && setting.conditional ? false : true
            }
            options={dbFieldOptions}
            getOptionSelected={(option, value) => {             
              return option.value === value.value
            }
            }
            renderInput={params => (
              <TextField
                {...params}
                placeholder={'Select DB field'}
              />
            )}            
            value={dbFieldOptionSelected}            
            getOptionLabel={option =>
              `${option.label}`
            } 
            onChange={(event, newInputValue:any, reason) => {
              if (reason === 'clear') {
                onDbFieldChange({label: '', value: ''})
                return
              }
              onDbFieldChange(newInputValue)               
            }}
            PaperComponent={paperProps => (
              <Paper
                elevation={8}
                {...paperProps}
                className="customAutoComplete"
                style={{width: "260px"}}
              />
            )}
            freeSolo={true}
            forcePopupIcon={true}
          />
        </label> 
      </div>     
      }    
      {
        !isHeaderSetting && !isFooterSetting && isRadioButtonChecked(RadioButtons.conditionalSetting) &&
        <div className="col-lg-4 input-group-col">
        <label>
          <span className="label">Conditional:</span>
          <div className={`input-group`}>
            <input
              type="text"
              className={`form-control eds-input ${!setting.dbField ? 'input-eneabled' : 'input-disabled'}`}
              placeholder={"Enter conditional"}
              value={setting.conditional ?? ''}
              disabled={true}
            />
            <div className="input-group-append eds-input">
              <span
                className="input-group-text transparent-appended-search-icon"
                onClick={() => {
                  setOpenConditionBuilderPopup(true);
                }}
                >
                <i className={`material-icons eds-link`}>edit</i>
              </span>
            </div>
          </div>
        </label>
      </div>
      }
    </div>

    <div className="row">
      <div className="col-lg-2">
        <Input
          label="Max Length:"
          name={"maxLength"}
          placeholder={"Enter max length"}
          className={'rowItem rowItemSize eds4'}
          value={setting.maxLength?.toString()}
          onChange={(e) => {
            let value = e.target.value;
            value = value.replace(/\D/g, '');
            if (value === "" || +value < 0) {
              onChangeSetting("maxLength", "")
              return;
            }
            onChangeSetting("maxLength", value)
          }}
        />
      </div>
      <div className="col-lg-2">
        <Input
          label="Pad Left Characters:"
          name={"padLeftCharacters"}
          placeholder={"Enter pad left characters"}
          className={'rowItem rowItemSize eds4'}
          value={setting.padLeftCharacters}
          onChange={(e) => onChangeSetting("padLeftCharacters", e.target.value)}
        />
      </div>
      <div className="col-lg-2">
        <Input
          label="Pad Right Characters:"
          name={"padRigthCharacters"}
          placeholder={"Enter pad right characters"}
          className={'rowItem rowItemSize eds4'}
          value={setting.padRigthCharacters}
          onChange={(e) => onChangeSetting("padRigthCharacters", e.target.value)}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-lg-3 autocomplete-field">
      <label>
        <span className="actocomplate-label">.NET Formatting:</span>
        <Autocomplete
          openOnFocus
          id={'netFormating'}
          disableClearable
          options={predefinedData.netFormattings}
          getOptionSelected={getAutocompleteOptionSelected}
          value={setting.netFormating}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={'Enter .Net formating'}
            />
          )}
          getOptionLabel={getAutocompleteOptionLabel}
          onInputChange={(event, newInputValue) => {
            onChangeSetting("netFormating", newInputValue)
          }}
          PaperComponent={paperProps => (
            <Paper
              elevation={8}
              {...paperProps}
              className="customAutoComplete"
              style={{width: "560px"}}
            />
          )}
          freeSolo={true}
          forcePopupIcon={true}
        />
        </label>
      </div>
      <div className="col-lg-3 autocomplete-field">
        <label>
          <span className="actocomplate-label">Replace Regex:</span>
          <Autocomplete
            openOnFocus
            id={'replaceRegEx'}
            disableClearable
            options={predefinedData.replaceRegexes}
            getOptionSelected={getAutocompleteOptionSelected}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={'Enter replace regex'}
              />
            )}
            value={setting.replaceRegEx}
            getOptionLabel={getAutocompleteOptionLabel}
            onInputChange={(event, newInputValue) => {
              onChangeSetting("replaceRegEx", newInputValue)
            }}
            PaperComponent={paperProps => (
              <Paper
                elevation={8}
                {...paperProps}
                className="customAutoComplete"
                style={{width: "560px"}}
              />
            )}
            freeSolo={true}
            forcePopupIcon={true}
          />
        </label>
      </div>      
      <div className="col-lg-4">
        <Input
          label="Notes:"
          name={"notes"}
          placeholder={"Enter notes"}
          className={'rowItem rowItemSize eds4'}
          value={setting.notes}
          onChange={(e) => onChangeSetting("notes", e.target.value)}
        />
      </div>
      <div className="col-lg-2">
        <Checkbox
          label="Hide column"
          name={"hideColumnExpression"}
          checked={setting.hideColumnExpression}
          onChange={() => onChangeSetting("hideColumnExpression",  !setting.hideColumnExpression)}
        />
      </div>
    </div>

    <ScribanTemplateBuilderPopup
      isOpen={openConditionBuilderPopup}
      template={setting.conditional ?? ''}
      onSubmit={(value) =>{
        changeDbFieldConditionalSetting('', value);
        setOpenConditionBuilderPopup(false);
      }}
      onClose={() =>{
        setOpenConditionBuilderPopup(false);
      }}
      remitFileFieldDescriptions={remitFileFieldDescriptions}
    />

    {openDBfieldPopup && <Popup
      header={
        <span className='setting-db-filed-change-header'>
          PaymentSafe<sup>&reg;</sup>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp; DB field overwrite
        </span>
      }
      children={
        <span>Are you sure you want to overwrite an already entered condition?</span>
      }
      onClose={onCloseDBChange}
      onSubmit={onSubmitDBChange}
      customStyle={'setting-db-filed-change'}
      footer={
        [
          {
            type: EnumPopupButtonsType.cancel,
            style: EnumPopupButtonsStyle.primary,
            text: 'Cancel',
          },
          {
            type: EnumPopupButtonsType.submit,
            style: EnumPopupButtonsStyle.primary,
            text: 'Submit',
          },
        ]
      }
    />}
  </div>
}