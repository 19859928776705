import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import { getPatient } from "../../../services/patient/PatientService";
import { useCallService } from "../../../services/useCallService";
import { updateRecord } from "../PatientsReducer";

export function useGetPatient(id?: string){
  const dispatch = useDispatch();
  const callService = useCallService();
  const patient = useTypedSelector(s => s.patients.records.find(p => p.id === id));

  const callback = useCallback(async () => {
    callService("getPatient", async () => {
      if (!id) return;
      const response = await getPatient(id);
      if (response.result) {
        dispatch(updateRecord({ id: response.result.id, patient: response.result }));
      }
    });
  }, [dispatch, id]);

  const getPatientWhenNone = () => {
    if (!patient) {
      callback();
    }
  }

  return { getPatient: callback, getPatientWhenNone, patient };
}