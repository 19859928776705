import { MultiSelectOption } from "components/select/MultiSelect";
import { ApiRemitConfiguration, ApiSetting, RadioButtons, RemitConfiguration, RemitConfigurationMultiSelects, Setting } from "models/admin/RemitConfiguration";
import { RemitPreviewRequest } from "models/admin/RemitPreviewRequest";
import { breakFileName } from "utils/Utils";

export function mapMultiselectToValueArray(multiSelects: MultiSelectOption[]): string[] {
  return multiSelects.map(m => m.value);
}

export function mapMultiselect(key: keyof RemitConfigurationMultiSelects, remitConfiguration: RemitConfiguration){
  return mapMultiselectToValueArray(remitConfiguration?.[key] ?? [])
}

export function getSelectedDataSourceConfiguration(setting: Setting) {
  const dataSourceConfiguration: { [key in keyof Setting]?: any } = {
    dbField: undefined,
    conditional: undefined,
    aggregateSetting: undefined,
    defaultValue: undefined,
    predefinedFunction: undefined,
  };

  switch ((setting.selectedRadioButton ?? '').toString()) {
    case RadioButtons.aggregateSettings.toString():
      dataSourceConfiguration.aggregateSetting = setting.aggregateSetting;
      break;
    case RadioButtons.conditionalSetting.toString():
      dataSourceConfiguration.conditional = setting.conditional;
      dataSourceConfiguration.dbField = setting.dbField;
      break;
    case RadioButtons.defaultValue.toString():
      dataSourceConfiguration.defaultValue = setting.defaultValue;
      break;
    case RadioButtons.predefinedFunction.toString():
      dataSourceConfiguration.predefinedFunction = setting.predefinedFunction;
      break;
  }

  return dataSourceConfiguration;
}
 
export function mapSettingMultiselect(setting: Setting): ApiSetting {
  return{
    ...setting,
    facilities: setting.facilities?.map(s => s.value),
    cardBrands: setting.cardBrands?.map(s => s.value),
    transactionTypes: setting.transactionTypes?.map(s => s.value),
    tenderTypes: setting.tenderTypes?.map(s => s.value),
    dbFieldOptions: setting.dbFieldOptions?.map(s => s.value),
  }
}

export function mapSettingsMultiselect(settings: Setting[], clearUnused: boolean = true): ApiSetting[] {
  return settings.map(a => {
    let setting = a;
    if (clearUnused) {
      setting = {
        ...a,
        ...getSelectedDataSourceConfiguration(a)
      }
    }

    return mapSettingMultiselect(setting);
  })
}

export function mapRemitPreviewForApi(remitConfiguration: RemitConfiguration): RemitPreviewRequest {
  return {
    fileIdentifier: remitConfiguration.fileIdentifier,
    organization: remitConfiguration.organization,
    displayedClientName: remitConfiguration.displayedClientName,
    filePath: remitConfiguration.filePath,
    fileName: remitConfiguration.fileName,
    fileExtension: remitConfiguration.fileExtension,
    isTest: remitConfiguration.isTest,
    dateFormat: remitConfiguration.dateFormat,
    fixWidthFile: remitConfiguration.fixWidthFile,
    createWithoutRecords: remitConfiguration.createWithoutRecords,
    settledStatus: remitConfiguration.settledStatus,
    sortString: remitConfiguration.sortString,
    is835: remitConfiguration.is835,
    sqlRowFilter:remitConfiguration.sqlRowFilter,
    clientFilter: remitConfiguration.clientFilter,
    cardFilter: mapMultiselect("cardFilter", remitConfiguration),
    tenderFilter: mapMultiselect("tenderFilter", remitConfiguration),
    paymentSources: mapMultiselect("paymentSources", remitConfiguration),
    transactionFilter: mapMultiselect("transactionFilter", remitConfiguration),
    aggregateSettings: mapSettingsMultiselect(remitConfiguration.aggregateSettings, false),
    bodyColumnSettings: mapSettingsMultiselect(remitConfiguration.bodyColumnSettings),
    footerColumnSettings: mapSettingsMultiselect(remitConfiguration.footerColumnSettings),
    headersColumnSettings: mapSettingsMultiselect(remitConfiguration.headersColumnSettings),
  };
}

export function mapRemitConfigForApi(remitConfiguration: RemitConfiguration): ApiRemitConfiguration {
  const brokenFileName = breakFileName(remitConfiguration.fileName);
  return {
    ...remitConfiguration,
    ...brokenFileName,
    orderDesc: remitConfiguration.orderDesc || false,
    cardFilter: mapMultiselect("cardFilter", remitConfiguration),
    tenderFilter: mapMultiselect("tenderFilter", remitConfiguration),
    paymentSources: mapMultiselect("paymentSources", remitConfiguration),
    transactionFilter: mapMultiselect("transactionFilter", remitConfiguration),
    aggregateSettings: mapSettingsMultiselect(remitConfiguration.aggregateSettings, false),
    bodyColumnSettings: mapSettingsMultiselect(remitConfiguration.bodyColumnSettings),
    footerColumnSettings: mapSettingsMultiselect(remitConfiguration.footerColumnSettings),
    headersColumnSettings: mapSettingsMultiselect(remitConfiguration.headersColumnSettings),
  };
}
