import { OrganizationLevelReference } from "../models/OrganizationLevelDocument";
import { UserReference } from "../models/RemitScheduleModel";
import { callHttpApi } from "./callHttpApi";

export interface EpicTokenUserMapping {
  id?: string,
  mapKey?: string,
  tokenReference?: {
    id?: string,
    organizationReference?: OrganizationLevelReference
  }
  isActive?: boolean,
  createdBy?: UserReference
  createdDate?: string,
  updatedBy?: UserReference,
  updatedDate?: string,
}
export async function GetEpicTokenUserMappings(organizationPath?: string) {
  return callHttpApi<EpicTokenUserMapping[], string[]>(
    'post',
    `api/v1/EpicTokenMappings/epictokenmappingsbyorganizationpaths`,
    [organizationPath ?? ""]
  );
}

export async function UpdateEpicTokenUserMappings(mappings?: EpicTokenUserMapping[]) {
  return callHttpApi<EpicTokenUserMapping[], { update?: EpicTokenUserMapping[] }>(
    'put',
    `api/v1/EpicTokenMappings/UpdateEpicTokenMappings`,
    { update: mappings }
  );
}
export async function CreateEpicTokenUserMappings(mappings?: EpicTokenUserMapping[]) {
  return callHttpApi<EpicTokenUserMapping[], { create?: EpicTokenUserMapping[]}>(
    'post',
    `api/v1/EpicTokenMappings`,
    { create: mappings }
  );
}
export async function DeleteEpicTokenUserMappings(mappingIds?: string[]) {
  return callHttpApi<EpicTokenUserMapping[], string[]>(
    'delete',
    `api/v1/EpicTokenMappings/delete`,
    mappingIds
  );
}