import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FullOrganizationLevelDocument } from 'models/OrganizationLevelDocument';

interface State {
  lastUpdateDate: number,
  value: FullOrganizationLevelDocument[];
}

export const initialState: State = {
  lastUpdateDate: 0,
  value: []
};

const reducerSlice = createSlice({
  name: 'Organizations',
  initialState,
  reducers: {
    setOrganizations(state, action: PayloadAction<FullOrganizationLevelDocument[]>) {
      if (!state.value) {
        state.value = [];
      }
      state.lastUpdateDate = Date.now();
      state.value = action.payload;
    },
    setOrganization(state, action: PayloadAction<FullOrganizationLevelDocument>) {
      const organizationIdx = state.value.findIndex(o => o.id == action.payload.id);
      if (organizationIdx > -1) {
        state.value[organizationIdx] = action.payload;
      }
    }
  },
});

export const {
  setOrganizations,
  setOrganization
} = reducerSlice.actions;
export default reducerSlice.reducer;
