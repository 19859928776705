import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaymentTenderSettings {
  deviceSerialNumber: string;
  deviceId: string;
  deviceName: string;
}

export interface State {
  paymentTenderSettings: { value: PaymentTenderSettings };
}

export const initialState: State = {
  paymentTenderSettings: {
    value: {
      deviceSerialNumber: '',
      deviceId: '',
      deviceName: ''
    },
  }  
};

const reducerSlice = createSlice({
  name: 'PaymentTenderSettings',
  initialState,
  reducers: {
    setUsePaymentTenderSettingsState(
      state,
      action: PayloadAction<PaymentTenderSettings>
    ) {
      state.paymentTenderSettings.value = action.payload;
    }    
  },
});

export const {
  setUsePaymentTenderSettingsState,
} = reducerSlice.actions;
export default reducerSlice.reducer;
