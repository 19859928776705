import { Dashboards } from "../ConfigurationSelector";
import { useResetPaymentPanelConfiguration } from "./reset/useResetPaymentPanelConfiguration";

export function useResetConfiguration(key?: keyof Dashboards, configurationId?: string, selectedOrganizationPath?: string) {
  const { resetPaymentPanelConfiguration, resetGlPaymentPanelConfiguration, resetEcnPaymentPanelConfiguration } = useResetPaymentPanelConfiguration(key, configurationId, selectedOrganizationPath);
    async function resetConfiguration() {
        const services: { [key in keyof Dashboards]: () => void } = {
            PaymentDashboard: resetPaymentPanelConfiguration,
            PaymentPlanDashboard: resetPaymentPanelConfiguration,
            GlPaymentDashboard: resetGlPaymentPanelConfiguration,
            GlPaymentPlanDashboard: resetGlPaymentPanelConfiguration,
            PatientSearchPopupDashboard: resetPaymentPanelConfiguration,
            PaymentDashboardDraft: resetPaymentPanelConfiguration,
            PaymentPlanDashboardDraft: resetPaymentPanelConfiguration,
            GlPaymentDashboardDraft: resetGlPaymentPanelConfiguration,
            GlPaymentPlanDashboardDraft: resetGlPaymentPanelConfiguration,
            PatientSearchPopupDashboardDraft: resetPaymentPanelConfiguration,
            EcnPaymentDashboard: resetEcnPaymentPanelConfiguration,
            EcnPaymentDashboardDraft: resetEcnPaymentPanelConfiguration,
        }

        if (key && configurationId) {
            const service = services[key];
            service && service();
        }
    }

    return {
        resetConfiguration
    }
}