import React, { useEffect, useRef, useState } from 'react';
import { EDS_TextBox, EDS_Button } from '@EH/eh.eds.react';
import { ReactComponent as Plus } from 'assets/svgs/paymentPlanIcons/icon-add-row.svg';
import { ReactComponent as Minus } from 'assets/svgs/paymentPlanIcons/icon-remove-row.svg';
import { emailValidation } from 'utils/Utils';
import { Popup } from 'components/popup/Popup';
import 'assets/styles/components/_emailReceipt.scss';

const MAX_ALLOWED_ROWS = 3;

export function EmailReceipt(props: {
  defaultEmail?: string;
  emailReceiptSent: boolean;
  onClose: () => void;
  onSubmit: (data: string[]) => void;
  error?: string;
}) {
  const { defaultEmail, emailReceiptSent, onClose, onSubmit, error } = props;
  const [emails, setEmails] = useState<string[]>([defaultEmail || '']);
  const [rowError, setRowError] = useState<string>('');
  const invalidEmails = useRef<number[]>([]);
  const maxRowError = 'Max number of rows achieved.';
  const invalidFieldError = 'Invalid email.';

  useEffect(() => {
    if (emails.length === MAX_ALLOWED_ROWS) {
      setRowError(maxRowError);
    } else {
      setRowError('');
    }
  }, [emails.length]);

  const onChange = (index: number, value: string) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails([...updatedEmails]);
    verifyEmails(updatedEmails);
  };

  const addOrRemoveRow = (lastRow: boolean, lastAllowedRow: boolean, index: number) => {
    const emailsArray = [...emails];
    if (lastRow && !lastAllowedRow) {
      emailsArray.push('');
    } else {
      emailsArray.splice(index, 1);
      invalidEmails.current = [];
    }
    verifyEmails(emailsArray);
    setEmails(emailsArray);
  };

  const verifyEmails = (inputs: string[]) => {
    inputs.forEach((email: string, index: number) => {
      if (emailValidation(email)) {
        if (invalidEmails.current.includes(index)) {
          invalidEmails.current.splice(invalidEmails.current.indexOf(index), 1);
        }
      } else if (!invalidEmails.current.includes(index)) {
        invalidEmails.current.push(index);
      }
    })
    return !!invalidEmails.current.length;
  };

  const renderRows = (
    <>
      <div className="eds-field_#label content-label">Email address:</div>
      {emails.map((email, index = 0) => {
        const lastRow = index === emails.length - 1;
        const lastAllowedRow = index === MAX_ALLOWED_ROWS - 1;
        return (
          <div key={index}>
            <EDS_TextBox
              name={`email_${index}`}
              placeHolder={'Enter an email address'}
              modifiers={`field-width ${invalidEmails.current.includes(index) ? 'invalid-field' : ''}`}
              value={email}
              onChange={(e: React.ChangeEvent<HTMLFormElement>) => onChange(index, e.target.value)}
              type={'email'}
            />
            <button
              className="eds-link row-icon"
              onClick={() => {
                addOrRemoveRow(lastRow, lastAllowedRow, index);
              }}
            >
              {lastRow && !lastAllowedRow ? <Plus /> : <Minus />}
            </button>
          </div>
        )
      })}
      {(!!invalidEmails.current.length || rowError) && <div className="row-error">{invalidFieldError || rowError}</div>}
    </>
  )

  const popupChildrenEmail = (
    <div className={"email-receipt"}>
      <div className="email-receipt-content">
        {emailReceiptSent
          ? <>
            <div>An email has been sent to</div>
            {emails.map((email, index) => {
              return <div key={index}>{email}</div>
            })}
            <div className="thank-you-row">Thank you!</div>
            <div className="email-footer-button">
              <EDS_Button
                modifiers={'mr-3 eds-button eds-button.primary'}
                name={'close'}
                buttonText={'Close'}
                onClick={onClose}
              />
            </div>
          </>
          : <>
            <div>{renderRows}</div>
            <div className="email-footer-button">
              <EDS_Button
                modifiers={'mr-3 eds-button eds-button.primary'}
                name={'send'}
                buttonText={'Send'}
                disabled={verifyEmails(emails)}
                onClick={() => {
                  onSubmit(emails);
                }}
              />
            </div>
          </>
        }
      </div>
    </div>
  );

  return (
    <Popup
      header={emailReceiptSent ? 'Email Confirmation' : 'Email Receipt'}
      children={popupChildrenEmail}
      customStyle='email-receipt-container'
      onClose={onClose}
      bottomError={error && 'Email could not be sent, Please try again later'}
    />
  );
}
