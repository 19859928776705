export interface Client {
  id: string;
  name: string;
}

export interface AccessPartnerGrouping {
  id: string;
  value: string;
}

export interface ClientConfiguration {
  hierarchy: {
    tradingPartner: Client;
    clients: Client[];
  };
  generalSettings: {
    logo: string;
    id: string;
    description: string;
    tradingPartnerId: string;
    nationalProviderId: string;
    isEnabled: boolean;
    isSuppressedFromFacilityList: boolean;
    isSuppressedFromPatientFacingDisplay: boolean;
    hasAccessPartnerData: boolean;
    accessPartnerGrouping: AccessPartnerGrouping[];
    isDefaultAmountSetToZero: boolean;
    isDefaultUserDeptToAllInSearch?: boolean;
    showZeroBalances?: boolean;
  };
}

export interface IClientConfigurationResponse {
  hierarchy: {
    tradingPartner: Client;
    clients: Client[];
  };
  generalSettings: {
    logo: string;
    id: string;
    description: string;
    tradingPartnerId: string;
    nationalProviderId: string;
    isEnabled: boolean;
    isSuppressedFromFacilityList: boolean;
    isSuppressedFromPatientFacingDisplay: boolean;
    hasAccessPartnerData: boolean;
    accessPartnerGrouping: AccessPartnerGrouping[];
    isDefaultAmountSetToZero: boolean;
    isDefaultUserDeptToAllInSearch?: boolean;
    showZeroBalances?: boolean;
  };
}

export interface ClientFacilityConfiguration {
  id: string;
  isEnabled: boolean;
  isSuppressedFromFacilityList: boolean;
  isSuppressedFromPatientFacingDisplay: boolean;
  hasAccessPartnerData: boolean;
  accessPartnerGrouping: AccessPartnerGrouping[];
  logos: Logos[];
  organization: Organization;
  contactPerson: ContactPerson;
  description?: string;
  tradingPartnerId?: string;
  nationalProviderId?: string;
  isDefaultAmountSetToZero: boolean;
  isDefaultUserDeptToAllInSearch?: boolean;
  showZeroBalances?: boolean;
  userName?: string;
  password?: string;
  deviceSettings?: DeviceSettings;
  myChartSettings?: MyChartSettings;
  interConnectCredentialsVerified?: boolean;
  returnAuthCode?: boolean;
}

export interface DeviceSettings {
  interconnectUrl: string;
  authPrefix: string;
  isProduction: boolean;
  useNewEpicClientId: boolean;
}

export interface MyChartSettings {
  paymentPageUrl: string;
  authPrefix: string;
  isProduction: boolean;
}

export interface ContactPerson {
  address: ContactPersonAddress;
  phone1?: string;
  phone2?: string;
}

export interface ContactPersonAddress {
  addressAddress1?: string;
  addressAddress2?: string;
  state?: string;
  county?: string;
  zip?: string;
}
export interface Logos {
  logoId: string;
  createdDate: string;
  imageData: string;
  isSelected: boolean;
}

export interface Organization {
  id: string;
  name: string;
  path: string;
}

export enum ProcessStatus {
  Started = 1,
  Finished = 2,
  Failed = 3
}

export interface OrganizationSyncDetail {
  id: string,
  organization: Organization
  status: ProcessStatus,
  numberOfDepartmentsFound: number,
  numberOfDepartmentsSynced: number,
  numberOfUsersFound: number,
  numberOfUsersSynced: number,
  errors: [
    string
  ],
  startTime: Date,
  startedBy: {
    id: string,
    firstName: string,
    lastName: string,
    oneSourceUserId: string
  },
  endTime: Date
}
export interface GeneralConfiguration {
  organization?: Organization;
  facilityDescription?: string;
  tradingPartnerId?: number;
  isEnabled?: boolean;
  isSuppressedFromFacilityList?: boolean;
  isSuppressedFromPatientFacingDisplay?: boolean;
  hasAccessPartnerData?: boolean;
  facilityGroupId?: string;
  isDefaultAmountSetToZero?: boolean;
  isCombineHospitalUsers?: boolean;
  isDefaultUserDeptToAllInSearch?: boolean;
  showZeroBalances?: boolean;
}

export interface SaveClientFacilityConfiguration {
  id: string;
  isEnabled?: boolean;
  isSuppressedFromFacilityList?: boolean;
  isSuppressedFromPatientFacingDisplay?: boolean;
  hasAccessPartnerData?: boolean;
  accessPartnerGrouping?: AccessPartnerGrouping[];
  logos: Logos[];
  organization?: Organization;
  description?: string;
  tradingPartnerId?: string;
  nationalProviderId?: string;
  isDefaultAmountSetToZero?: boolean;
  isCombineHospitalUsers?: boolean;
  isDefaultUserDeptToAllInSearch?: boolean;
  showZeroBalances?: boolean;
}
