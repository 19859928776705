import { MultiSelectOption } from 'components/select/MultiSelect';
import React, { useEffect } from 'react'
import 'assets/styles/components/_recreationRemit.scss';
import { getRemitConfiguration } from './RemitRecreationReducer';
import { useDispatch } from 'react-redux';
import { RemitConfigurationRecord } from 'models/RemitConfigurationModel';
import { FullOrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { Autocomplete } from '@material-ui/lab';
import { Paper, TextField } from '@material-ui/core';
import { useTypedSelector } from 'app/rootReducer';
import { GetRemitConfigurationsQueryParams } from 'services/RemitService';

export default function RecreationRemit(props: {
  remitDateRecreation: string | undefined;
  setRemitDateRecreation: (value: string) => void;
  organization: FullOrganizationLevelDocument | undefined, 
  remitFileRecreationSelected: MultiSelectOption | null, 
  setRemitFileRecreationSelected: (value: MultiSelectOption | null) => void;
}) {  
  const {
    remitDateRecreation, 
    setRemitDateRecreation, 
    organization,     
    setRemitFileRecreationSelected
  } = props
  const dispatch = useDispatch();

  useEffect(() => {
    if(!organization?.path) return;

    const configLimit: number = 150;
    const remitConfigurationQuery: GetRemitConfigurationsQueryParams = {
       organizationPaths: [organization.path],
      limit: configLimit 
      };
    dispatch(getRemitConfiguration(remitConfigurationQuery));
  }, [dispatch, organization?.path]);  

  const remitConfiguration = useTypedSelector(s=> s.remitRecreationConfiguration.value) 

  const fileOption = (records: RemitConfigurationRecord[]) => {
    let remitFileOptions:{label: string, value: string}[] = []
    records.forEach((o) => {
      const remitOption = {
        label: `${o?.fileName}${o.fileExtension}`, value: o?.fileIdentifier
      }
      remitFileOptions.push(remitOption)
    })
    return remitFileOptions
  } 
  const options = remitConfiguration?.records && remitConfiguration.records.length > 0 ? fileOption(remitConfiguration.records) : [{label: "No files available", value:""}]

  const setDateHandler = (event:React.ChangeEvent<HTMLInputElement>) => {
   setRemitDateRecreation(event.target.value)
  } 

  return (
    <> 
    <span style={{ fontWeight: "bold"}}>Remit Data Recreation</span>
    <div className="content">
      <div className="recreation-form">
        <div className="column">
          <div className="control">  
            <label className="eds-field ">
              <div className="eds-field_#label">Date:</div>
              <div className="eds-field_#control eds-field_.eds-input eds-input"  data-testid="date-picker">
                <input
                  type="date"
                  name="dateOfRemitRecreation"
                  className="eds-input_#input"
                  value={remitDateRecreation}
                  onChange={setDateHandler}                 
                ></input>
              </div>
            </label>
          </div> 
        </div>
        <div className="column">
          <div className="control ">
          <div>
            <div className={'eds-field_#label'}>File:</div>
            <Autocomplete
              openOnFocus
              id={'autocomplete'}
              disableClearable
              options={options}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={'Select a file'}
                />
              )}
              getOptionLabel={option =>
                ` ${option.label}`
              }
              onChange={(event, option) => {
                setRemitFileRecreationSelected(option);
              }}
              PaperComponent={paperProps => (
                <Paper
                  elevation={8}
                  {...paperProps}
                  className="customAutoComplete"
                />
              )}
            />
          </div>
          </div> 
        </div>
      </div>
    </div>
  </>
  )

}
