import { FullOrganizationLevelDocument, OrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import { OrganizationLevelSummary } from 'models/OrganizationLevelSummary';

export function getFacilityOptions(organizations?: OrganizationLevelDocument[]) {
  const defaultOption = getDefaultOption('Please Select Facility', 0);
  if (!organizations) return [defaultOption];

  const facilities = organizations?.filter(o => 
    o.organizationLevelType == OrganizationLevelTypes.Facility
  );
  
  return getOptions(facilities, defaultOption);
}

export function getDepartmentOptions(organizations?: OrganizationLevelDocument[], facilityPaths?: (string|undefined)[]) {
  const defaultOption = getDefaultOption('Please Select Department', 0);
  if (!organizations) return [defaultOption];

  const departments = organizations?.filter(o => 
    o.organizationLevelType == OrganizationLevelTypes.Department &&
    facilityPaths && facilityPaths.find(facilityPath => facilityPath && o.path.startsWith(facilityPath))
  );

  return getOptions(departments, defaultOption);
}

function getOptions(organizations: OrganizationLevelDocument[], defaultOption: {optionName: string, value: any}) {  
  const options = organizations.map(f => {
    return { optionName: f.name, value: f.id};
  });
  options.sort((a, b) => {
    if (a.optionName.toLocaleLowerCase() > b.optionName.toLocaleLowerCase()) return 1;
    if (a.optionName.toLocaleLowerCase() < b.optionName.toLocaleLowerCase()) return -1;
    return 0;
  });
  options.unshift(defaultOption);
  return options;
}

function getDefaultOption(optionName: string, value?: any){
  return { optionName, value }
}

export const getUserDepartmentOrFacilityesOptions = (
  organizations:FullOrganizationLevelDocument[],
  userOrganisations:OrganizationLevelSummary[] | undefined, 
  departments?:FullOrganizationLevelDocument[]
  ) => {
  const userOrganisationsFiltered = organizations?.filter( org => userOrganisations?.find(({ organizationLevel_Id }) => org.id === organizationLevel_Id)) 
  if( departments ) {
    return departments?.filter( org => userOrganisationsFiltered?.find(({ path }) => org.path.includes(path) ))
  }
  return userOrganisationsFiltered
}