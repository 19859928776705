import React, { useState } from "react";
import { HeaderButton } from "../../../pages/Search/planSearch/paymentPlanDetailsHeader/HeaderButton";
import { EmailReceipt } from "../../emailReceipt/EmailReceipt";
import { ReactComponent as Mail } from 'assets/svgs/paymentPlanIcons/icon-mail.svg';
import { EmailModalInfo } from "../common/Header";
import { useTypedSelector } from "../../../app/rootReducer";
import { useDispatch } from "react-redux";
import { AlertIds, AlertTypes, setAlert } from "../../alert/AlertReducer";
import { sendEmailCreditTransaction, sendEmailTransactionDetails, sendEmailVoidTransaction } from "../../../services/TransactionsService";

export default function Email(props: { open: (info?: EmailModalInfo) => void, info?: EmailModalInfo }) {
  const { open, info } = props;
  const dispatch = useDispatch();
  const transaction = useTypedSelector(s => s.transactionDetails.transactionDetailsData?.transactionDetails);

  const [emailReceiptSent, setEmailReceiptSent] = useState(false);
  const closeEmailPopup = () => {
    open(undefined);
    setEmailReceiptSent(false);
  };

  const sendEmailReceipt = async (data: string[]) => {
    let emailService = sendEmailTransactionDetails;
    let transactionId = transaction?.id;
    if (info?.type === 'void') {
      emailService = sendEmailVoidTransaction;
      transactionId = info.transactionId;
    }
    if (info?.type === 'credit') {
      emailService = sendEmailCreditTransaction;
      transactionId = info.transactionId;
    }

    const result = transactionId && await emailService(transactionId, data);
    if (result && result.err) {
      dispatch(setAlert({ id: AlertIds.EmailAlert, type: AlertTypes.Error, message: result.err }));
    } else {
      setEmailReceiptSent(true);
    }
  };

  return <>
    <HeaderButton
      title="Send Email"
      icon={<Mail />}
      onClick={() => open({ type: 'details' })} />
    {info && <EmailReceipt
      defaultEmail={transaction?.notificationEmail}
      emailReceiptSent={emailReceiptSent}
      onClose={closeEmailPopup}
      onSubmit={sendEmailReceipt}
    />}
  </>
}