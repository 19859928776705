import { useEffect, useState } from 'react';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'typeface-roboto';
import '../../assets/styles/components/_landingpage.scss';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'utils/useHistory';
import { ReactComponent as Search } from 'assets/svgs/icon-search.svg';
import { ReactComponent as MakePayment } from 'assets/svgs/icon-pymt.svg';
import { ReactComponent as MakeGlPayment } from 'assets/svgs/icon-glpymt.svg';
import { ReactComponent as Settings } from 'assets/svgs/icon-admin.svg';
import { setSidepaneLink } from 'features/sidepane/SidepaneReducer';
import { jwtUserDetailDecoder } from 'utils/Utils';
import { GetUserDetailService } from 'services/UsersService';
import { useTypedSelector } from 'app/rootReducer';
import { resetPatientInfo, setPatientInfoState } from 'features/patientInfo/PatientInfoReducer';
import { setOpenPatientInfoModal } from '../../features/patients/PatientsReducer';
import { setOpenModal } from '../../pages/GLPaymentDashboard/GLPaymentDashboardReducer'
import { useGetOrganizations } from '../../features/organizations/hooks/useGetOrganizations';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import { setLoggedUser } from 'pages/Loginpage/reducers/LoginPageReducer';
import { AlertPopUp } from 'components/alert/alertPopUp';
import { AlertTypes } from 'features/alert/AlertReducer';
import { OrganizationLevelSummary } from 'models/OrganizationLevelSummary';

export default function Landingpage() {
  let dispatch = useDispatch();
  let history = useHistory();
  const patientInfoState = useTypedSelector(s => s.patientInfo?.value);
  const organizations = useTypedSelector(s => s.organizations?.value);
  const isProcessing = useTypedSelector(
    s => s.services?.calls?.getOrganizations?.isProcessing
  );
  const loggedUser = useTypedSelector(s => s.loginInfo?.loggedUser);

  const [isNoOrganizationPopupOpen, setIsNoOrganizationPopupOpen] = useState(
    true
  );
  const [enabledPaymentPermission, setEnabledPaymentPermission] = useState(false);
  const [enabledSearchPermission, setEnabledSearchPermission] = useState(false);
  const [enabledGLPaymentPermission, setEnabledGLPaymentPermission] = useState(false);
  const [enabledAdminPermission, setEnabledAdminPermission] = useState(false);
  const isModalOpen = useTypedSelector(s => s.patients?.modalOpen);
  const isGlModalOpen = useTypedSelector(s => s.glPaymentDashboard?.modalOpen);

  const { getOrganizations } = useGetOrganizations();
  const { havePermission, havePaymentPermission, haveGLPaymentPermission, haveSearchPermission, hasPermissionOrSuperAdmin } = useLoggedUserPermissions();
  const sspUserPermission = (org: OrganizationLevelSummary) => hasPermissionOrSuperAdmin(UserPermissions.SelfServicePortal, org.organizationLevel_Path)

  const clickHandler = (value: string) => {
    if (value === 'make-pymt') {
      dispatch(setOpenPatientInfoModal(true));
    }
    if(value === 'make-glpymt') {
      dispatch(setOpenModal(true));
    }
    dispatch(setSidepaneLink(value));
  };
  function useBreadcrumb(rootPath: string, name: string) {
    useEffect(() => {
      if(!loggedUser?.impersonatedOrganizationLevels?.length){
        GetUserDetails();
      }
      else{
        checkPermissions(loggedUser?.impersonatedOrganizationLevels ?? []);
      }
      dispatch(setBreadcrumbLinks([{ name: name, slug: rootPath }]));
      

      getOrganizations();
      return () => {
        dispatch(setBreadcrumbLinks([]));
      };
    }, [rootPath, name]);
  }
  useBreadcrumb('/landing', 'Home');

  useEffect(() => {
    if(!isModalOpen && !isGlModalOpen) {
     dispatch(setSidepaneLink('home'));
    }
  }, [isModalOpen,isGlModalOpen]);

  const GetUserDetails = async () => {
    if (localStorage.getItem('authToken') !== null) {
      const partialUserModel = jwtUserDetailDecoder(
        localStorage.getItem('authToken')
      );
      GetUserDetailService('', '', partialUserModel.realmUserId)
        .then(data => {
          const user = (data.result ?? [])[0];
          const permissions = user?.organizationLevels?.flatMap(item => item.permissions) ?? [];
          const organizationLevel = (user.organizationLevels ?? [])[0];
          const OrgId = organizationLevel?.organizationLevel_Id;
          checkPermissions(user?.organizationLevels ?? []);
          dispatch(
            setPatientInfoState({
              ...patientInfoState,
              organizationId: OrgId,
            })
            );          
         
          user && dispatch(            
            setLoggedUser(user)
          );
          if (permissions.length === 0 && !user.isSuperAdmin) {
            history.push('/no-permissions');
            return
          }
          history.push('/landing');
        })
        .catch(error => console.log(error));
      }
  };

  const closeNoOrganizationPopup = () => {
    setIsNoOrganizationPopupOpen(false);
  };

  const checkPermissions = (orgPaths: OrganizationLevelSummary[]) => {
    orgPaths?.forEach(org => {
      if(havePaymentPermission(org.organizationLevel_Path)) {
          setEnabledPaymentPermission(true);
      }

      if(haveSearchPermission(org.organizationLevel_Path)) {
        setEnabledSearchPermission(true);
      }

      if(haveGLPaymentPermission(org.organizationLevel_Path)) {
        setEnabledGLPaymentPermission(true);
      }

      if(havePermission(UserPermissions.FinancialAdministation, org.organizationLevel_Path) || sspUserPermission(org)) {
        setEnabledAdminPermission(true);
      }
    })
  }

  return (
    <div className="landing-page-container">
      <span className="landing-page-marque bolder">
        Welcome to PaymentSafe!
      </span>
      <span className="landing-page-marque-sub-head">
        WHAT DO YOU NEED TO DO TODAY?
      </span>

      <div className="landing-page-links-container row row-cols-sm-3 row-cols-md-4 row-cols-lg-3 row-cols-xl-5 row justify-content-center">
        
        {enabledPaymentPermission && 
        <a href="# " onClick={(e) => {
          e.preventDefault();
          dispatch(resetPatientInfo());
          clickHandler('make-pymt')
        }}>
          <div className="make-pymt">
            <MakePayment />
          </div>
          <span>
            <i className="no-italics">
              {' '}
              Make a <b>Payment</b>
            </i>
          </span>
        </a>
        }

        {enabledGLPaymentPermission && 
        <a href="# " onClick={(e) => {
          e.preventDefault();
          clickHandler('make-glpymt')
        }}>
          <div className="make-glpymt">
            <MakeGlPayment />
          </div>
          <span>
            <i className="no-italics">
              Make a <b>GL Payment</b>
            </i>
          </span>
        </a>
        }

        {enabledSearchPermission && 
        <Link to="/searchpayments" onClick={() => clickHandler('search')}>
          <div className="search">
            <Search />
          </div>
          <span>
            <i className="no-italics">
              <b>Search</b> Payments
            </i>
          </span>
        </Link>
        }

        {enabledAdminPermission &&
          <Link to="/admin-settings" onClick={() => clickHandler('admin')}>
            <div className="admin">
              <Settings />
            </div>
            <span>
              <i className="no-italics">
                PaymentSafe <b>Admin</b>
              </i>
            </span>
          </Link>
        }
      </div>
      {organizations?.length === 0 &&
        isNoOrganizationPopupOpen &&
        !isProcessing && (
          <AlertPopUp
            onClose={closeNoOrganizationPopup}
            alertType={AlertTypes.Warning}
            header={'You have no enabled facilities!'}
          ></AlertPopUp>
        )}
    </div>
  );
}