import React, { ChangeEvent, useEffect, useState } from "react";
import { EDS_Select } from '@EH/eh.eds.react';
import '../../../assets/styles/components/_addNewRemitModal.scss';
import { Input } from '@EHDS/core';
import { Popup } from "components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";

export const formatOptions = [
  { optionName: ".csv", value: ".csv" },
  { optionName: ".835", value: ".835" },
  { optionName: ".txt", value: ".txt" },
  { optionName: ".pcrr", value: ".pcrr" },
  { optionName: ".pcpa", value: ".pcpa" },
  { optionName: ".lckbx", value: ".lckbx" },
  { optionName: ".ERA", value: ".ERA" },
  { optionName: ".kbx", value: ".kbx" },
  { optionName: ".CTL", value: ".CTL" },
  { optionName: ".pmt", value: ".pmt" },
  { optionName: ".dat", value: ".dat" },
  { optionName: ".pmn", value: ".pmn" },
  { optionName: ".crr", value: ".crr" },
  { optionName: ".cpa", value: ".cpa" },
  { optionName: ".DUC", value: ".DUC" },
  { optionName: ".tgl", value: ".tgl" },
  { optionName: ".xsv", value: ".xsv" },
  { optionName: ".Z10", value: ".Z10" },
  { optionName: ".CGI", value: ".CGI" },
  { optionName: ".710", value: ".710" },
  { optionName: ".DON", value: ".DON" },
  { optionName: ".cx", value: ".cx" },
];

export default (props: {
  isOpen: boolean;
  close: () => void;
  onNext: (newName:{name: string, format: string}) => void;
  fileNames: string[];
  clientId?: string
}) => {
  const { close, onNext } = props;
  const [newName, setNewName] = useState({name:getDefaultFileName(), format: formatOptions[0].value});
  const [fileNameExistsStyle, setToggleFileNameExistsStyle] = useState("remit-hidden")
  const [fileNameExists, setToggleFileNameExists] = useState(false)
    
  useEffect(() => {
    validateFileName()
  }, [newName])

  function getDefaultFileName():string {
    if (!props.clientId)  
      return "";

    return `${props.clientId}_||yyyyMMdd||`;
  }
 
  const changeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value;
    setNewName({...newName, name:value})
  };

  const dropDownChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {    
    let selectElement = event.target;
    setNewName({...newName, format: selectElement.value})  
  };

  const next = () => {
    onNext(newName);
  };

  const footerButtons = [
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Add New',
      disabled: newName.name && !fileNameExists ? false : true
    },
  ];
  
  const validateFileName = () => {
    if (newName.name)
      setToggleFileNameExists(false);

    if (!props.fileNames)  
      return;

    if (props.fileNames.indexOf(`${newName.name}${newName.format}`)==-1){
      setToggleFileNameExistsStyle("remit-hidden");
      setToggleFileNameExists(false);
    }
    else{     
      setToggleFileNameExistsStyle("remit-visible");
      setToggleFileNameExists(true);
     }
  };
  
  return (
  <Popup 
    header="PaymentSafe®"
    onClose={close}
    title="Add New Remit Files"
    footer={footerButtons}
    onSubmit={next}
    customClassNames={{footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
    >
      <div className="popupContent">
        <div className="rowItemPopup">
          <Input
            label={'File Name'}
            name={'fileName'}
            placeholder={"New Remit"}
            className={'inputItem'}
            value={newName.name}
            onChange={changeHandler}
            onBlur={validateFileName}
          />
          <div className={fileNameExistsStyle}>
            <label className="invalid-field">This file name is already in use.</label>
          </div>        
        </div>
        <div className="rowItemPopup">
          <EDS_Select
            name="Format"
            label="Format"
            modifiers={'inputItem'}
            options={formatOptions}
            onChange={dropDownChangeHandler}
            value={newName.format ?? formatOptions[0]}
          ></EDS_Select>
        </div>              
      </div>
  </Popup>
  );
};
