import moment from 'moment';
import { displayAmount, formatDate } from 'utils/Utils';
import { PaymentPlanModel } from '../../../models';
import { displayTermType } from '../PlanUtils';
import { getStartDate } from '../PaymentPlan';

export default (props: PaymentPlanModel) => {
  const {
    startDate,
    paymentsRemaining,
    termType,
    paymentAmount,
    nextPaymentDate
  } = props;

  let termTypeDisplay = displayTermType(paymentsRemaining, termType);
  let termMultiplier = 1;
  if (termTypeDisplay?.startsWith('bi-week')) {
    termMultiplier = 2;
    termTypeDisplay = 'weeks';
  }

  return (
    <div className="row-item row-item-size-double">
      <div className="eds-heading eds-heading.mdplus-caps mb-1">
        Payment Plan Terms
      </div>
      <div className="row">
        <div className="col-7 font-weight-bold">Payment Terms:</div>
        <div className="col-5">
          {paymentsRemaining * termMultiplier} {termTypeDisplay}
        </div>
      </div>      
      {!nextPaymentDate && <div className="row">
        <div className="col-7 font-weight-bold">Payment Start Date:</div>
        <div className="col-5">{formatDate(moment(getStartDate(startDate)))}</div>
      </div>
      }
      <div className="row">
        <div className="col-7 font-weight-bold">Number of Payments:</div>
        <div className="col-5">{paymentsRemaining}</div>
      </div>
      <div className="row">
        <div className="col-7 font-weight-bold">Payment Amount:</div>
        <div className="col-5">${displayAmount(paymentAmount)}</div>
      </div>
      {nextPaymentDate &&
        <div className="row">
          <div className="col-7 font-weight-bold">Next Payment Date:</div>
          <div className="col-5">{formatDate(moment(nextPaymentDate))}</div>
        </div>
      }      
    </div>
  );
};
