import { useRef, useState } from "react";
import '../../../assets/styles/components/_dropDownEditorModal.scss';
import { Popup } from "components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";
import { EditorButton } from "../paymentPanel/EditorButton";
import { ReactComponent as IconAdd } from 'assets/svgs/admin/paymentPanel/add.svg';
import { ReactComponent as IconUp } from 'assets/svgs/admin/paymentPanel/up.svg';
import { ReactComponent as IconDown } from 'assets/svgs/admin/paymentPanel/down.svg';
import { ReactComponent as IconDelete } from 'assets/svgs/admin/paymentPanel/delete.svg';
import { ReactComponent as IconClear } from 'assets/svgs/admin/paymentPanel/clear.svg';
import { ReactComponent as IconEdit } from 'assets/svgs/admin/paymentPanel/edit.svg';

import { AgGridReact } from "ag-grid-react";
import { PaymentPanelDrowpDownItem } from "models/PaymentPanel";
import { GridApi, GridColumnsChangedEvent, GridReadyEvent} from "ag-grid-community";

export default (props: {
  close: () => void;
  onNext: (items:any[]) => void;
  dropDownNameProp?: string,
  items?:PaymentPanelDrowpDownItem[]
}) => {
  const { close, onNext } = props;
  const [rowData, setRowData] = useState<any>(props.items);
  const [selectedValue, setSelectedValue] = useState(null);

  const gridRef = useRef<AgGridReact>(null);

  const complete = () => {
    gridApi?.stopEditing();
    onNext(rowData);
  };

  const footerButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel',
      disabled: false
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Save List',
      disabled: false
    },
  ];

  function AddItem(){
    const newStore = rowData.slice();
    newStore.splice(0,0,{value:"Set value",label:"Set display"});
    setRowData(newStore);
  }

  function EditItem(){
    const newStore = rowData.slice();
    let result = newStore.findIndex((x: { value: null; })=>x.value==selectedValue)
    gridApi?.startEditingCell({rowIndex:result, colKey:"value"})
  }

  function MoveUp(){
    if (selectedValue)
    {
      const newStore = rowData.slice();
      for (let i = 0; i < newStore.length; i++) {
        if(newStore[i].value == selectedValue)
        {
          if((i-1)>=0){
            let currentValue = newStore[i].value
            let currentLabel = newStore[i].label
            let previousValue = newStore[i-1].value 
            let previousLabel = newStore[i-1].label

            newStore.splice(i-1,2);
            newStore.splice(i-1,0,{value:currentValue,label:currentLabel});
            newStore.splice(i,0,{value:previousValue,label:previousLabel});
            break;
          }
        }
      }

      setRowData(newStore);
    }
  }

  function MoveDown(){
    if (selectedValue)
    {
      const newStore = rowData.slice();
      for (let i = 0; i < newStore.length; i++) {
        if(newStore[i].value == selectedValue)
        {
          if((i+1)<newStore.length){
            let currentValue = newStore[i].value
            let currentLabel = newStore[i].label
            let nextValue = newStore[i+1].value 
            let nextLabel = newStore[i+1].label

            newStore.splice(i,2);
            newStore.splice(i,0,{value:nextValue,label:nextLabel});
            newStore.splice(i+1,0,{value:currentValue,label:currentLabel});
            break;
          }
        }
      }

      setRowData(newStore);
    }
  }

  function Delete(){
    if (selectedValue)
    {
      const newStore = rowData.slice();

      for (let i = 0; i < newStore.length; i++) {
        if(newStore[i].value == selectedValue)
        {
          newStore.splice(i,1);
          break;
        }
      }
      setRowData(newStore);
    }
  }

  const headerButtons = <div className={"header-buttons"}>
  <EditorButton 
    title={"Add Row"} icon={<IconAdd />} 
    onClick={
      () => AddItem()
    } 
    disabled={false}
  />

  <EditorButton 
      title={"Edit"} icon={<IconEdit />} 
      onClick={
        () => EditItem()
      } 
      disabled={false}
    />

  <EditorButton 
    title={"Move Up"} icon={<IconUp />} 
    onClick={
      () => MoveUp()
    } 
    disabled={false}
  />
  <EditorButton 
    title={"Move Down"} icon={<IconDown />} 
    onClick={
      () => MoveDown()
    } 
    disabled={false}
  />
  <EditorButton 
    title={"Delete"} icon={<IconDelete />} 
    onClick={
      () => Delete()
    } 
    disabled={false}
  />
  <EditorButton 
    title={"Clear List"} icon={<IconClear />} 
    onClick={
      () => {
        setRowData([]);
      }
    } 
    disabled={false}
  />
  </div>

  const headerColumns=[
    { label: 'Save Value', value:'value' },
    { label: 'Display Value', value: 'label' },
  ];

  const headerDefinitions = headerColumns.map((el, index) => ({
    headerName: el.label,
    field: el.value,
    sortable: false,
    resizable: true,
    filter: false,
    editable: true,
  }));

  function sizeColumnsToFit(params: GridReadyEvent | GridColumnsChangedEvent) {
    params.api.sizeColumnsToFit();
  }
    
  function getRowData():any[]{
    return rowData;
  }
  function onRowSelected(rows:any[]){
    if (rows?.length === 1)
    {
      setSelectedValue(rows[0].value);
    }
  };
  const [gridApi, setGridApi] = useState < GridApi | null>(null);
  function onGridReady(params: GridReadyEvent) {
    setGridApi(params.api);
  }
  return (
  <Popup 
    header="PaymentSafe®"
    onClose={close}
    title="CREATE CUSTOM SELECTION LIST"
    footer={footerButtons}
    onSubmit={complete}
    customClassNames={{footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
    >
      <div className="popupContent">
        <div className="rowItemPopup">
          <span><b>Label: </b></span><span>{props.dropDownNameProp}</span>      
        </div>
        <div className="rowItemPopup">
          {headerButtons}
        </div>
        <div className="rowItemPopup">
        <div className="ag-theme-alpine mt-3">
              <AgGridReact
                ref={gridRef}
                columnDefs={headerDefinitions}
                rowData={getRowData()}
                onGridReady={onGridReady}
                onGridColumnsChanged={sizeColumnsToFit}
                pagination={false}
                domLayout={'autoHeight'}
                gridAutoHeight={true}
                suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
                enableCellTextSelection={true}
                ensureDomOrder={true}
                rowSelection='single'
                onSelectionChanged={(e) => { onRowSelected(e.api.getSelectedRows()); }}
                />
            </div>
        </div>              
      </div>
  </Popup>
  );
};
