import { useRef, useState } from "react";

export default function useErrorStore() {
  const errorsRef = useRef() as any;

  const [errors, setErrors] = useState<{ [key: string]: { [key:string]: string } }>({});

  const addError = (errorsProps: { [key: string]: { [key: string]: string } }) => {
    const newErrors = {
      ...errorsRef.current,
      ...errorsProps,
    };

    setErrors(newErrors);
    errorsRef.current = newErrors;
  }

  const removeError = (props: { key: string, validatorType: string }) => {
    const { key, validatorType } = props;
    const updatedErrors = { ...errorsRef.current };
    
    if (errorsRef.current?.[key]?.[validatorType]) {
      delete updatedErrors[key][validatorType];
      setErrors({
        ...updatedErrors
      })
      errorsRef.current = updatedErrors;
    }
  }

  function getErrorMessageAsString() {
let errorString = '';
    for (const key in errorsRef.current) {
      for (const validatorType in errors[key]) {
        errorString += key + ': ' + errors[key][validatorType] + ' ';
      }
    }
    return errorString;

  }

  return {
    addError,
    removeError,
    errors,
    errorsRef,
    getErrorMessageAsString,
  }
}