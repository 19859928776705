import { OrganizationLevelDocument } from "../../../../models/OrganizationLevelDocument";
import { UserDetailModel } from "../../../../models/UserInfoAndRolesModel";
import PermissionsMenu from "../PermissionsMenu";
import useUpdatePermissions from "./hooks/useUpdatePermissions";
import { useMemo, useState } from "react";
import { getOrganizationNames } from "../OrganizationTab/OrganizationForm";
import { useOrganizations } from "../../../organizations/hooks/useOrganizations";
import { UserMultiSelectOption } from "pages/Search/simpleSearch/OrganizationUserSearch";

export enum permissionsMenuModes {
  add,
  remove,
  view
};
export default function UserPermissionsMenu(props: {
  user?: UserDetailModel,
  organizationPath?: string,
  selectedItems: Set<string | undefined>,
  handleChange: (id?: string) => void,
  clearSelection: () => void,
  tradingPartner?: OrganizationLevelDocument,
  organization?: OrganizationLevelDocument,
  selectedUsers?: UserMultiSelectOption[]
}) {
  const { user, organizationPath, clearSelection, organization,selectedUsers} = props;
  const { updatePermissions } = useUpdatePermissions();

  const selectedOrganization = user?.organizationLevels?.find(o => o.organizationLevel_Path === organizationPath);
  const userPermissions = selectedOrganization?.permissions?.map(p => p.toString()) ?? [];
  const  [updatedPermissions, setUpdatedPermissions] = useState<string[]>();

  const onSubmit = async (mode: permissionsMenuModes, permissions: Set<string | undefined>) => {
    const newPermissions = updateUserPermissions(mode, (updatedPermissions || userPermissions), permissions) ?? [];
    const response= !!(user && organizationPath && await updatePermissions(user, organizationPath, newPermissions));
    setUpdatedPermissions(newPermissions as string[]);
    clearSelection();
    return response;
  }

  const { organizations, getTradingPartnerByFacility, getFacilityByDepartment } = useOrganizations()

  const { departmentNames, facilityNames, tradingPartnerNames } = getOrganizationNames([organization?.id  ?? ''], organizations, getTradingPartnerByFacility, getFacilityByDepartment)

  useMemo(() => {
    setUpdatedPermissions(undefined);
  }, [user]);

  return <PermissionsMenu
    {...props}
    {...{
      selectedUsers: selectedUsers,
      onSubmit,
      userPermissions: updatedPermissions || userPermissions,
      userInfo: {
        department: departmentNames.join(", "),
        facility: facilityNames.join(", "),
        organization: tradingPartnerNames.join(", "),
      }
    }}
  />
}

function updateUserPermissions(mode: permissionsMenuModes, userPermissions: string[], selectedItems: Set<string | undefined>) {
  if (mode === permissionsMenuModes.add) {
    return [...userPermissions, ...Array.from(selectedItems)];
  }
  if (mode === permissionsMenuModes.remove) {
    return userPermissions.filter(p => !selectedItems.has(p));
  }
}