import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RemitScheduleConfig, RemitScheduleCreate, RemitScheduleResponse, RemitScheduleUpdate } from 'models/RemitScheduleModel';

import {
  GetRemitSchedule, SaveRemitSchedule, UpdateRemitSchedulesConfig,  
} from 'services/RemitScheduleService';
import { AppThunk } from 'app/rootReducer';
import { callService } from 'services/ServicesReducer';
import { extractRequestErrors } from 'utils/Utils';

export interface State {
  err?: string;
  value?: RemitScheduleConfig;
}

export const initialState: State = {
  err: '',
  value: undefined,
};

const reducerSlice = createSlice({
  name: 'RemitSchedule',
  initialState,
  reducers: {
      onReceiveRemitSchedule(
      state,
      action: PayloadAction<RemitScheduleResponse>
    ) {
      state.value = action.payload.result;
      state.err = action.payload.err;
    },
    clearError(state) {
      state.err = '';
    },
  },
});

export const { 
  onReceiveRemitSchedule,
  clearError,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getRemitSchedule(
  organizationPath: string,
  onDone?: () => void
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService('GetRemitSchedule', async () => {
      await GetRemitSchedule(organizationPath).then(data => {
        const normalizedHours = data?.result?.startHours?.map(h => (h < 1 || h > 24) ? 24 : h);
        dispatch(onReceiveRemitSchedule(normalizedHours ? {
          ...data,
          result: {
            ...data.result,
            startHours: normalizedHours ?? []
          }
        } : data));
        if (onDone) onDone();
      });
    }))
  };
}


export function updateRemitSchedule(
  id: string,
  remitSchedule: RemitScheduleUpdate,
  onDone?: (err?:string[]) => void
): AppThunk {
  return async dispatch => {
    dispatch(callService('UpdateRemitSchedulesConfig', async () => {
      const request = await UpdateRemitSchedulesConfig(id, remitSchedule);
      if (request && ((request?.err)||(request.errorResponse))){
        let errors = extractRequestErrors(request);
        if (onDone) onDone(errors);
      }
      else{
        if (onDone) onDone();
      }
    }))
  };
}


export function saveRemitSchedule(
  remitSchedule: RemitScheduleCreate,
  onDone?: (err?:string[]) => void
): AppThunk {
  return async dispatch => {
    dispatch(callService('SaveRemitScheduleConfig', async () => {
      const request = await SaveRemitSchedule(remitSchedule);
      if (request && request?.err) {
        let errors = extractRequestErrors(request);
        if (onDone) onDone(errors);
      }
      else{        
        dispatch(onReceiveRemitSchedule(request));
        if (onDone) onDone();
      }
    }))
  };
}