import React, { useEffect, useState, useCallback } from 'react';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import {
  getCreditCardConfig,
  clearGetCreditCardConfigError,
} from 'features/admin/creditCardConfig/CreditCardConfigReducer';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import CreditCardForm, {
  CreditCard
} from '../../features/admin/creditCardConfig/CreditCardForm';
import 'assets/styles/components/_clientConfiguration.scss';
import {
  AlertIds,
  AlertTypes,
  setAlert,
} from '../../features/alert/AlertReducer';
import ConfigurationTemplate from 'features/admin/clientConfiguration/ConfigurationTemplate';
import useCreditCardConfig from 'features/admin/creditCardConfig/useCreditCardConfig';
import { VerifyCredentials } from 'services/CreditCardService';
import { Config } from 'models/CreditCardConfigModel';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumbLinks([{ name: 'Credit Card Config', slug: rootPath }])
    );

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

function useConfig(organizationPath: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    if(!organizationPath) return
    dispatch(getCreditCardConfig(organizationPath));
  }, [dispatch]);
}

const providerOptions = [
  {
    optionName: 'Axia',
    value: '1',
  },
];

const controlCenterOptions = [
  {
    optionName: 'Skip',
    value: 'skip',
  },
  {
    optionName: 'Prompt',
    value: 'prompt',
  },
  {
    optionName: 'Print',
    value: 'print',
  },
];

export default function CreditCardConfig(
  props: Props = { rootPath: 'credit-card-config' }
) {
  const { rootPath } = props;
  useBreadcrumb(rootPath);
  const clientConfiguration = useTypedSelector(
    s => s.getClientFacilityConfiguration?.result
  );
  const clientOrganizationPath = clientConfiguration?.organization.path ?? '';

  useConfig(clientOrganizationPath);
  const dispatch = useDispatch();

  const [isSubmitting, setSubmitting] = useState(false);
  const [isVerifying, setVerifying] = useState(false);

  const isProcessing = useTypedSelector(s => s.services.calls.GetCreditCardConfig?.isProcessing);

  const creditCardConfig = useTypedSelector(
    s => s.creditCardConfig?.value
  );
  const err = useTypedSelector(s => s.creditCardConfig?.err);

  const { creditCardFormValues, saveCreditCardConfig } = useCreditCardConfig( clientOrganizationPath, creditCardConfig, clientConfiguration, );

  useEffect(() => {
    if (err?.length) {
      dispatch(setAlert({ ...configAlert, message: err }));
      dispatch(clearGetCreditCardConfigError());
    }

    return () => {
      dispatch(clearGetCreditCardConfigError());
    };
  }, [err]);
  const onSubmitCreditCardForm = (values: CreditCard) => {
    submitForm(values);
  };

  const verifyCredentials = async () => {
    setVerifying(true);
    const response = await VerifyCredentials(creditCardConfig as Config);
    setVerifying(false);
    if (response?.err || !response?.result?.credentialsVerified) {
      dispatch(
        setAlert({
          ...configAlert,
          type: AlertTypes.Error,
          message: `Gateway Credentials not Verified.`,
        })
      )
    } else {
      dispatch(
        setAlert({
          ...configAlert,
          type: AlertTypes.Success,
          message: `Gateway Credentials Verified.`,
          dismissable: true,
        })
      );
      dispatch(getCreditCardConfig(clientOrganizationPath));
    }
  };

  const configAlert = {
    id: AlertIds.CreditCardConfigAlert,
    type: AlertTypes.Error,
    message: '',
  };

  const submitForm = useCallback(async (
    values: CreditCard
  ) => {
      setSubmitting(true);
      let response = await Promise.all([
        saveCreditCardConfig(values)
      ]);

      dispatch(
        setAlert({
          ...configAlert,
          message: `Error Configuration not updated. }`,
        })
      );

      let [ result ] = response;
      if (result && result?.err) {
        const errorMessage = result.errorResponse?.data?.validationErrors
          ? result.errorResponse.data.validationErrors.map((error: any) => {
              return `${error.errorMessage} ${'\n'}`;
            })
          : result.errorResponse?.data?.title;

        dispatch(
          setAlert({
            ...configAlert,
            message: `Error Configuration not updated. ${errorMessage}`,
          })
        );
      } else {
        dispatch(
          setAlert({
            ...configAlert,
            type: AlertTypes.Success,
            message: `Success Configuration updated`,
            dismissable: true,
          })
        );
      }

      dispatch(getCreditCardConfig(clientOrganizationPath));
      setSubmitting(false);
    },
    [dispatch]
  );

  if (isProcessing || !clientOrganizationPath) {
    return <p>Getting Credit Card Config...</p>;
  } else {
    return (
      <div className="client-configuration">
        <ConfigurationTemplate title={'Credit Card Config'}>
          <CreditCardForm
            key={'creditCard'}
            formValues={creditCardFormValues}
            onSubmit={onSubmitCreditCardForm}
            providerOptions={providerOptions}
            controlCenterOptions={controlCenterOptions}
            submitting={isSubmitting}
            isCredentialsVerified={creditCardConfig? creditCardConfig.credentialsVerified : false}
            onVerify={verifyCredentials}
            verifying={isVerifying}
          />
        </ConfigurationTemplate>
      </div>
    );
  }
}
