import React, { useState } from 'react';
import { EDS_Switch } from '@EH/eh.eds.react';
import { FaSave } from 'react-icons/fa';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ReactComponent as Help } from 'assets/svgs/paymentPlanIcons/icon-help-outline.svg';
import { Placement } from 'react-bootstrap/esm/Overlay';
import 'assets/styles/components/_creditCardForm.scss';
import { GeneralConfiguration } from 'models/Client';
import { Organization } from 'models/Organization';
import { useOrganizations } from '../../organizations/hooks/useOrganizations';
import MultiSelect from '../../../components/select/MultiSelect';
import { TradingPartnerMultiselectOption, useGetFacilitesByTradingPartnerOptions, useGetOrganizationOptionWithTradingPartner } from '../../../pages/Search/simpleSearch/OrganizationUserSearch';
import ActionConfirmModal from '../ActionConfirmModal';
import { ReactComponent as ErrorIcon } from 'assets/svgs/admin/warning.svg';
import * as router from 'react-router-dom';
import { FullOrganizationLevelDocument } from 'models/OrganizationLevelDocument';

export interface ClientInfo {
  id: string;
  facilityDescription?: string;
  tradingPartnerId: string;
  isEnabled?: boolean;
  isSuppressedFromFacilityList?: boolean;
  isSuppressedFromPatientFacingDisplay?: boolean;
  isDefaultAmountSetToZero?: boolean;
  orgConfig?: Organization;
  isCombineHospitalUsers?: boolean;
  isDefaultUserDeptToAllInSearch?: boolean;
  showZeroBalances?: boolean;
}

interface SelectOption {
  optionName: string;
  value: string;
}

interface ClientConfigurationFormProps {
  formValues?: GeneralConfiguration;
  onSubmit: Function;
  submitting?: boolean;
  organizationOptions: SelectOption[];
  logoSelected?: string;
  accessPartnerGroupingUpdated: (props: any) => void;
}

function ActionConfirmationModal(props: {
  isOpen: boolean,
  onSubmit:() => void,
  onClose:() => void,
  isEnabled?: boolean
}) {
  const {isOpen, onSubmit, onClose, isEnabled} = props;

  const actionButtonText = isEnabled ? 'Enable' : 'Disable';
  const actionConfirmText = isEnabled
    ? 'Enabling the facility would restore the access of its users to the PaymentSafe features.'
    : 'Disabling the facility would block all its users from accessing any PaymentSafe features.';
  const actionTitle = <div><ErrorIcon/><h6>WARNING</h6></div>;

  return (
    <ActionConfirmModal
      isOpen={isOpen}
      isProcessing={false}
      actionTitle={actionTitle}
      actionButtonText={actionButtonText}
      actionConfirmText={actionConfirmText}
      actionCancelButtonText={'Cancel'}
      onActionConfirm={onSubmit}
      onClose={onClose}
      iconName={''}
      showCancelButton={true}
    />
  );
}

function WarningMessage (props: {showMessage?: boolean}){
  const element = <div className="warning-message">
      This facility is disabled. To enable it, use the toggle button below.
  </div>
  return props.showMessage ? element : null;
}

export default function ClientConfigurationForm(
  props: ClientConfigurationFormProps
) {
  const {
    formValues,
    onSubmit,
    submitting,
    logoSelected,
    accessPartnerGroupingUpdated,
  } = props;
  const { getOrganizationById, getOrganizationByPath } = useOrganizations();
  const params = router.useParams<{ clientId: string }>();

  const selectedFacilityId = params.clientId;
  const organization = getOrganizationById(selectedFacilityId)
  const [values, setFormValues] = useState(formValues);
  const [enabled, setEnabled] = useState(formValues?.isEnabled);
  const [facilityListEnabled, setFacilityListEnabled] = useState(
    formValues?.isSuppressedFromFacilityList
  );
  const [patientFacingEnabled, setPatientFacingEnabled] = useState(
    formValues?.isSuppressedFromPatientFacingDisplay
  );
  const [isDefaultAmountSetToZero, setIsDefaultAmountSetToZero] = useState(
    formValues?.isDefaultAmountSetToZero
  );
  const [partnerData, setAccessPartnerEnabled] = useState(!!organization?.accessPartnerGrouping);
  const [showConfirmationModal, setShowConfirmationModal] = useState<any>(false);
  const [selectedAPGFacilityOption, setSelectedAPGFacilityOption] = useState<FullOrganizationLevelDocument | undefined>();
  const [isCombineHospitalUsers, setIsCombineHospitalUsers] = useState(formValues?.isCombineHospitalUsers);
  const [isDefaultUserDeptToAllInSearch, setIsDefaultUserDeptToAllInSearch] = useState(formValues?.isDefaultUserDeptToAllInSearch);
  const [showZeroBalances, setShowZeroBalances] = useState(formValues?.showZeroBalances);
  
  const organizationParam = formValues?.organization;
  const onCheckBoxChange = (field: string, value: any, update: any) => {
    const mapFieldFunction = [
      {field: 'isEnabled', setFunction: setEnabled},
      {field: 'isSuppressedFromFacilityList', setFunction: setFacilityListEnabled},
      {field: 'isSuppressedFromPatientFacingDisplay', setFunction: setPatientFacingEnabled},
      {field: 'isDefaultAmountSetToZero', setFunction: setIsDefaultAmountSetToZero},
      {field: 'isCombineHospitalUsers', setFunction: setIsCombineHospitalUsers},
      {field: 'isDefaultUserDeptToAllInSearch', setFunction: setIsDefaultUserDeptToAllInSearch},
      {field: 'showZeroBalances', setFunction: setShowZeroBalances}
    ];
    const map = mapFieldFunction.find(m => m.field === field);
    map?.setFunction(value.target.checked);
    setFormValues((prevState?: GeneralConfiguration) => ({
      ...prevState,
      [field]: update,
    }));
  };

  const getPopoverContent = (content: string) => (
    <Popover id="popover-basic">
      <Popover.Content>{content}</Popover.Content>
    </Popover>
  );

  const PopoverClientElement = ({
    content,
    placement,
  }: {
    content: string;
    placement: Placement;
  }) => (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement}
      overlay={getPopoverContent(content)}
    >
      <Help />
    </OverlayTrigger>
  );

  const facilitiesOptions = useGetFacilitesByTradingPartnerOptions();

  const accessPartnerOrganization = getOrganizationById(organization?.accessPartnerGrouping);
  const getTradingPartnerOption = useGetOrganizationOptionWithTradingPartner();

  const onFormSubmit = () => {
    let newValues = {
      ...values,
      isEnabled: enabled,
      isSuppressedFromFacilityList: facilityListEnabled,
      isSuppressedFromPatientFacingDisplay: patientFacingEnabled,
      isDefaultAmountSetToZero: isDefaultAmountSetToZero,
      organization: organizationParam,
      isCombineHospitalUsers: isCombineHospitalUsers,
      isDefaultUserDeptToAllInSearch: isDefaultUserDeptToAllInSearch,
      showZeroBalances: showZeroBalances,
    };
    onSubmit(newValues);
  }

  const apgValues = () => {
  if (selectedAPGFacilityOption) return getTradingPartnerOption(selectedAPGFacilityOption) 
   else {
    if (accessPartnerOrganization) return getTradingPartnerOption(accessPartnerOrganization)
    else return null
   }
  } 
  
  return (
    <div>
      <WarningMessage 
        showMessage={!formValues?.isEnabled}
      />
      <div className="credit-card-form">
        <ActionConfirmationModal
          isOpen={showConfirmationModal}
          onSubmit={() =>{
            setShowConfirmationModal(false);
            onFormSubmit();
          }}
          onClose={() =>{
            setShowConfirmationModal(false);
          }}
          isEnabled={enabled} 
        />
        <div className="column client-column">
          <div className="control">
            <div className="defaultImage">{renderClientLogo(logoSelected)}</div>
          </div>
          <div className="control">
            <div className="switch-label">Trading Partner ID:</div>
            <div className="value">
              <div className='data'>
                {values?.organization?.path.split('|')[values?.organization?.path.split('|').length - 3]}
              </div>
            </div>
        </div>
        <div className="control">
          <span className='switch-label'>Enable Facility</span>
          <EDS_Switch
            name="isEnabled"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onCheckBoxChange('isEnabled', e, e.target.value);
            }}
            checked={enabled}
          />
        </div>
        <div className="control">
          <span className='switch-label'>Suppress Facility Patient Facing Display</span>
          <EDS_Switch
            name="isSuppressedFromPatientFacingDisplay"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onCheckBoxChange(
                'isSuppressedFromPatientFacingDisplay',
                e,
                e.target.value
              );
            }}
            checked={patientFacingEnabled}
            disabled={!formValues?.isEnabled}
          />
        </div>
        <div className="control">
          <span className='switch-label'>Set Default Payment Amount To Zero</span>
          <EDS_Switch
            name="isDefaultAmountSetToZero"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onCheckBoxChange(
                'isDefaultAmountSetToZero',
                e,
                e.target.value
              );
            }}
            checked={isDefaultAmountSetToZero}
            disabled={!formValues?.isEnabled}
          />
        </div>

        <div className="control">
          <span className='switch-label'>Combine Physician/Hospital Users</span>
          <EDS_Switch
            name="isCombineHospitalUsers"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onCheckBoxChange(
                'isCombineHospitalUsers',
                e,
                e.target.value
              );
            }}
            checked={isCombineHospitalUsers}
            disabled={!formValues?.isEnabled}
          />
        </div>

        <div className="control">
          <span className='switch-label'>Default to User/Dept instead of All Options preselected in Search Payment/Plans</span>
          <EDS_Switch
            name="isDefaultUserDeptToAllInSearch"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onCheckBoxChange(
                'isDefaultUserDeptToAllInSearch',
                e,
                e.target.value
              );
            }}
            checked={isDefaultUserDeptToAllInSearch}
            disabled={!formValues?.isEnabled}
          />
        </div>
        <div className="control">
          <span className='switch-label'>Access Partner Data</span>
          <EDS_Switch
            name="hasAccessPartnerData"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAccessPartnerEnabled(e.target.checked);
              if (organization && !e.target.checked) {
                const {accessPartnerGrouping, ...rest } = organization;
                accessPartnerGroupingUpdated(rest);
              }
            }}
            checked={partnerData}
            disabled={!formValues?.isEnabled}
          />
        </div>
        <div className="control">
          <span className='switch-label'>Send Zero Balance Flag on PAH Webservice Call</span>
          <EDS_Switch
            name="showZeroBalances"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onCheckBoxChange(
                'showZeroBalances',
                e,
                e.target.value
              );
            }}
            checked={showZeroBalances}
            disabled={!formValues?.isEnabled}
          />
        </div>
        {partnerData && (
          <div className="control control-partner-grouping">
            <span className='switch-label'>Access Partner Grouping:</span>
            <MultiSelect
              label=''
              name={"accessPartnerGrouping"}
              options={facilitiesOptions}
              onChange={(selected: TradingPartnerMultiselectOption | null) => {
                const selectedFacility = getOrganizationByPath(selected?.value);
                setSelectedAPGFacilityOption(selectedFacility)                
                if (organization && selectedFacility) {
                  accessPartnerGroupingUpdated({ ...organization, accessPartnerGrouping: selectedFacility.id });
                }
              }}
              values={apgValues()}
              groupBy={(o: TradingPartnerMultiselectOption) => o.tradingPartnerName.toUpperCase()}
              multiple={false}
              disabled={!formValues?.isEnabled}
              disableCloseOnSelect={false}
            />
            </div>
          )}
        </div>
        <div className="submit">
          <button
            disabled={submitting}
            className="action"
            name='saveButton'
            onClick={() => {
              const exec = formValues?.isEnabled != enabled 
                ? setShowConfirmationModal
                : onFormSubmit;
              exec(true);
            }}
          >
            <FaSave />
            <span className="text">Save</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export function renderClientLogo(logo: string | undefined){
  if (logo && logo.length) {
    const logoType =
      atob(logo).indexOf('<svg') !== -1
        ? 'data:image/svg+xml;base64,'
        : 'data:image/png;base64,';
    return <img src={`${logoType}${logo}`} alt={'Default logo'} />;
  } else {
    return (
      <div className="no-logo">
        <span>Facility does not have a logo set.</span>
      </div>
    );
  }
}