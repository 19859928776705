import { Organization } from 'models/Client';
import { callHttpApi } from './callHttpApi';
import { FullOrganizationLevelDocument, OrganizationByOneSourceId } from 'models/OrganizationLevelDocument';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';

interface FromOneSourceOrganizations {
  organizationsFromOneSource: {
    oneSourceId: number;
    friendlyName: string;
  }[]
}

export interface FromOneSourceOrganizationsSyncResult {
  historyId: string;
  oganizationReference: Organization;
}

export async function getOrganizationData(
  oganizationLevelType: OrganizationLevelTypes
) {
  return callHttpApi<FullOrganizationLevelDocument[]>(
    'get',
    `api/v1/Organizations?organizationLevelType=${oganizationLevelType}`
  );
}

export async function getClientOrganizationService(params: any) {
  return callHttpApi<FullOrganizationLevelDocument[]>(
    'get',
    `/api/v1/Organizations`,
    undefined,
    params
  );
}

export async function updateOrganization(data: FullOrganizationLevelDocument) {
  return callHttpApi<FullOrganizationLevelDocument, FullOrganizationLevelDocument>(
    'put',
    `/api/v1/Organizations/${data.id}`,
    data
  );
}

export async function getOrganizationByOneSourceIdService(oneSourceIds: URLSearchParams) {
  return callHttpApi<OrganizationByOneSourceId[]>(
    'get',
    `/api/v1/Organizations/FromOneSource?${oneSourceIds}`,
  );
}

export async function postOrganizationFromOneSourceIdService(data: FromOneSourceOrganizations) {
  return callHttpApi<FromOneSourceOrganizationsSyncResult[], FromOneSourceOrganizations>(
    'post',
    `api/v1/Organizations/FromOneSource`,
    data,
  );
}
