import { Attribute } from 'models/metaData/MetaData';
import { getLabel } from 'utils/metadata/MetaDataUtils';
import PaymentPlanDetailsLink from '../../paymentPlan/PaymentPlanDetailsLink';

export function Linkview(props: {
  attribute: Attribute;
  value: any;
  to: string;
  onClick?: (value: any) => void;
  modifiers?: string;
}) {
  const { attribute, modifiers } = props;
  let { value } = props;

  const label = getLabel(attribute);

  return (
    <div className={modifiers}>
      <div className={'line-item-linkview'}>
        <p className={'line-item-attribute-label'}>{label}</p>
        {value ? <PaymentPlanDetailsLink id={value} /> : '-'}
      </div>
    </div>
  );
}
