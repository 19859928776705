import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Label } from 'reactstrap';
import {
  EDS_Checkbox,
  EDS_Select,
  EDS_TextArea,
  EDS_TextBox,
} from '@EH/eh.eds.react';
import { useTypedSelector } from 'app/rootReducer';
import Footer from 'features/footer/Footer';
import { ApiTenderTypeEnum, checkReceivedHasValues, coalesce, nameof, stateOptions } from 'utils/Utils';
import { getAttribute } from 'utils/metadata/MetaDataUtils';
import { PlanOperations } from 'models/metaData/MetaDataEnums';
import BalanceHeader from '../paymentPlan/infoSections/BalanceHeader';
import { Wizard } from '../wizard/Wizard';
import * as PatientMailingInformation from './PatientMailingInformationReducer';
import usePatientMailingInformationValidator from './validators/usePatientMailingInformationValidator';
import 'assets/styles/components/_paymentPlan.scss';
import { OrganizationLevelDocument } from "../../models/OrganizationLevelDocument";
import { PaymentPlanModel } from '../../models';
import { distributePlanToPatientMailingInformation } from "../paymentPlan/PlanUtils";
import { AlertIds, AlertTypes, removeAlert, setAlert } from '../alert/AlertReducer';
import { NormalStepButtons } from '../paymentPlan/buttons/NormalStepButtons';
import { AddressFieldsConfiguration, PaymentConfiguration } from '../../models/PaymentsConfiguration';
import { isEditFinalStep } from '../planDetails/useEditPlanTerms';
import { EditFinalStepButtons } from '../paymentPlan/buttons/EditFinalStepButtons';
import { setIsPayingNow, setIsScreenDisabled } from '../paymentDashboard/PaymentDashboardReducer';

// regex to accept only numbers upto 5 digits
const zipCodeRegex = /^\d{0,5}$/;
const AMOUNT_BALANCE_DISCREPANCY_KEY = 'AmountBalanceDiscrepancy';
const ITEMS_REQUIRED_ERROR =
  'Please complete the required information to continue.';
const PAYMENT_PLAN_SELECTED = 'This invoice is in a payment plan.';

const alert = { id: AlertIds.GlPaymentAlert, type: AlertTypes.Error, message: '' };

export default (props: {
  total?: number;
  amount?: number;
  discount?: number;
  onBack?: () => void;
  onContinue?: (onSuccess?: ()=>void) => void;
  onCancel?: () => void;
  onCancelAddPlan?: () => void;
  wizard: Wizard;
  isGl?: boolean;
  patientId?: string;
  organization?: OrganizationLevelDocument;
  paymentPlan?: PaymentPlanModel;
  setGlPatientId?: (id: { id: string }) => void;
  selectedFacility?: { path?: string; id?: string};
  cancel?: () => void;
}) => {
  const { total, amount, discount, onContinue, onCancel, isGl = false, paymentPlan, selectedFacility, organization, onBack, wizard, cancel, patientId  } = props;

  const patientMailingInformation = useTypedSelector(
    i => i.patientMailingInformation?.value
  );

  const tenderType = patientMailingInformation.tenderType;

  const configuration: PaymentConfiguration | undefined = useTypedSelector(
    state => state.paymentDashboard.configurations[selectedFacility?.path ?? '']?.configuration
  );
  const isMailingAddressRequired = coalesce(true, configuration?.mailingAddressRequired);

  const settingMap: { [key in ApiTenderTypeEnum]?: keyof AddressFieldsConfiguration } = {
    [ApiTenderTypeEnum.CardDevice]: "creditCardAddressSettings",
    [ApiTenderTypeEnum.CardManual]: "creditCardAddressSettings",
    [ApiTenderTypeEnum.Cash]: "cashAddressSettings",
    [ApiTenderTypeEnum.ECheck]: "eCheckAddressSettings",
    [ApiTenderTypeEnum.PaperCheckAsECheck]: "moneyOrderAddressSettings",
    [ApiTenderTypeEnum.MoneyOrder]: "moneyOrderAddressSettings",
  }
  const settingKey: keyof AddressFieldsConfiguration | undefined = settingMap[tenderType as ApiTenderTypeEnum];

  const addressSetings = settingKey && configuration && configuration[settingKey];

  const { validate, validateAll, attributes, errorClass, getErrorMessage, formatLabel } = usePatientMailingInformationValidator(isGl, addressSetings);

  const isProcessing = useTypedSelector(s => s.services.calls.GetPaymentPlanConfiguration?.isProcessing);

  const paymentPlansLineItemsSelected = useTypedSelector(i => i.paymentPlanInfo?.paymentPlansLineItemsSelected) ?? [];
  const operation = useTypedSelector(state => state.paymentPlanInfo.operation);

  const discrepancies = useTypedSelector(
    s => s.paymentPlanInfo?.discrepancies
  );

  const dispatch = useDispatch();
  const openErrorMessage = (message: string) => {
    dispatch(setAlert({ ...alert, message }));
  };

  const getBillingInfo = () => {
    if(patientMailingInformation.firstName || patientMailingInformation.lastName) {
      return {
        billingFirstName: patientMailingInformation.firstName,
        billingLastName: patientMailingInformation.lastName,
        firstNameInput: "firstName" as keyof PatientMailingInformation.PatientMailingInformation,
        lastNameInput: "lastName" as keyof PatientMailingInformation.PatientMailingInformation
       }
    }
    if((!patientMailingInformation.billingFirstName || !patientMailingInformation.billingLastName) && patientMailingInformation.cardHolderName){
      const names = patientMailingInformation.cardHolderName.split(' ');
      const lastName = names.pop() || '';
      const firstName = names.join(' ');
      dispatch(
        PatientMailingInformation.setPatientMailingInformation({
          ...patientMailingInformation,
          billingFirstName:firstName,
          billingLastName:lastName
        })
      );
    }
    return {
      billingFirstName: patientMailingInformation.billingFirstName,
      billingLastName: patientMailingInformation.billingLastName,
      firstNameInput: "billingFirstName" as keyof PatientMailingInformation.PatientMailingInformation,
      lastNameInput: "billingLastName" as keyof PatientMailingInformation.PatientMailingInformation
    }
  }

  const closeErrorMessage = () => {
    dispatch(removeAlert(alert));
  };

  const selected = useTypedSelector(s => s.paymentPlanInfo.selected) || [];
  const isMailingInformationEdit = paymentPlan?.id && !selected.length || operation == PlanOperations.EditPlan;
  const isMailingInformationAddToPlan = (paymentPlan?.id && selected.length > 0 && operation === PlanOperations.AddToPlan);
  const billingInfo = getBillingInfo();

  const hasDiscrepancy = () => {
    return discrepancies?.hasOwnProperty(AMOUNT_BALANCE_DISCREPANCY_KEY) ?? false;
  }

  const getDiscrepancyMessage = () => {
    return discrepancies ? discrepancies[AMOUNT_BALANCE_DISCREPANCY_KEY] : '';
  }

 
  useEffect(() => {
    if (isMailingInformationEdit || isMailingInformationAddToPlan) {
      const { patientMailingInformation: patientMailingInfo } = distributePlanToPatientMailingInformation({
        plan: paymentPlan,
        mailingInformation: patientMailingInformation
      });
      dispatch(
        PatientMailingInformation.setPatientMailingInformation(patientMailingInfo)
      );
    }
  }, [paymentPlan, selected]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, propertyName: keyof PatientMailingInformation.PatientMailingInformation) => {
    let value = event.target.value;

    let valid = validateAll({ ...patientMailingInformation, [propertyName]: value }, true);
    if (valid) {
      closeErrorMessage()
    }

    dispatch(
      PatientMailingInformation.setPatientMailingInformation({
        ...patientMailingInformation,
        [propertyName]: value,
      })
    );
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>, name: keyof PatientMailingInformation.PatientMailingInformation) => {
    let value = stateOptions[event.target.selectedIndex].value;

    validate(getAttribute(attributes, name), value);
    let valid = validateAll({ ...patientMailingInformation, [name]: value }, true);
    if (valid) {
      closeErrorMessage()
    }

    dispatch(
      PatientMailingInformation.setPatientMailingInformation({
        ...patientMailingInformation,
        [name]: value,
      })
    );
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let propertyName = event.target.name;
    let value = event.target.checked;

    let valid = validateAll({ ...patientMailingInformation, isChecked: value }, true);
    if (valid) {
      closeErrorMessage()
    }

    dispatch(
      PatientMailingInformation.setPatientMailingInformation({
        ...patientMailingInformation,
        [propertyName]: value,
      })
    );
  };

  useEffect(() => {
    if (!isMailingAddressRequired) {
      let valid = validateAll({ ...patientMailingInformation, isChecked: true }, true);
      if (valid) {
        closeErrorMessage()
      }

      dispatch(
        PatientMailingInformation.setPatientMailingInformation({
          ...patientMailingInformation,
          facilityId: selectedFacility?.id,
          departmentId: organization?.id,
          isChecked: true,
        })
      );
    }
  }, [!isMailingAddressRequired]);

  useEffect(() => {
    dispatch(
      PatientMailingInformation.setPatientMailingInformation({
        ...patientMailingInformation,
        facilityId: selectedFacility?.id,
        departmentId: organization?.id,
        isChecked: true,
      })
    );
  }, []);

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>, name: keyof PatientMailingInformation.PatientMailingInformation) => {
    if (event.target.value === '' || zipCodeRegex.test(event.target.value)) {
      handleChange(event, name);
    }
  };

  const validatePayerInformation = () => {
    const restrictions = [
      {
        active: !validateAll(patientMailingInformation),
        text: ITEMS_REQUIRED_ERROR
      },
      {
        active: hasDiscrepancy(),
        text: getDiscrepancyMessage()
      },
      {
        active: !(operation===PlanOperations.AddToPlan || operation === PlanOperations.AddhockPayment) && (selected.filter(s=>s!=null).length>0 || paymentPlansLineItemsSelected.length>0),
        text: PAYMENT_PLAN_SELECTED
      },
    ]

    let restriction = restrictions.find(c => c.active);
    if (restriction) {
      openErrorMessage(restriction.text);
      return false;
    }
    closeErrorMessage();
    return true;
  }


  const onBlur = (name: keyof PatientMailingInformation.PatientMailingInformation) => {
    validate(getAttribute(attributes, name), patientMailingInformation[name]);
  }

  function showInputError(name: keyof PatientMailingInformation.PatientMailingInformation) {
    const message = getErrorMessage(name);
    return message.length ? <Label className={`error-message-required`}>
      {message}
    </Label> : null;
  }

  const fieldProps = {
    errorClass,
    showInputError,
    formatLabel,
    handleChange,
    onBlur
  };

  const isDisabled = useMemo(() => {
    return patientMailingInformation?.departmentId === '0' || !validateAll(patientMailingInformation, true);
  }, [patientMailingInformation, patientMailingInformation?.departmentId])

  let footerChildren = <NormalStepButtons
    isProcessing={isProcessing}
    cancel={onCancel}
    onBack={() => {
        dispatch(setIsPayingNow(false));
        dispatch(setIsScreenDisabled(false));
        onBack && onBack()
      }
    }
    onNext={() => {
      if (!validatePayerInformation() || !validateAll(patientMailingInformation)) {
        openErrorMessage(ITEMS_REQUIRED_ERROR);
        return;
      }
      onContinue && onContinue()
    }
    }
    disabled={isDisabled}
  />;

  if (isEditFinalStep(operation, wizard.state.currentStep)) {
    footerChildren = <EditFinalStepButtons
      cancel={cancel}
      disabled={false}
      onContinue={onContinue}
      total={total}
      paymentPlan={paymentPlan}
      patientId={patientId}
      selectedOrganizationPath={selectedFacility?.path}
    />
  }

  return (
    <>
      <div className="payment-plan-container">
        <div className="payment-plan-form-container">
          <BalanceHeader total={total} amount={amount} discount={discount} isGl={isGl}/>
          <div className="flex-row">
            <h4 className="row-item row-item-size-double eds-heading eds-heading.mdplus-caps">
              Payer Billing Information
            </h4>
          </div>
          <div className="flex-row">
            <div className="half-row">
              <EDS_TextBox
                modifiers={`row-item row-item-size-double ${errorClass("billingFirstName")}`}
                name={nameof<PatientMailingInformation.PatientMailingInformation>("billingFirstName")}
                label={formatLabel("billingFirstName", "First Name")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, billingInfo.firstNameInput) }}
                onBlur={() => onBlur("billingFirstName")}
                value={billingInfo.billingFirstName}
              ></EDS_TextBox>
              {showInputError("billingFirstName")}
            </div>
            <div style={{ width: '15px' }}></div>
            <div className="half-row">
              <PatientMailingInformationInput
                fieldName={billingInfo.lastNameInput}
                label={"Last Name"}
                value={billingInfo.billingLastName}
                {...fieldProps}
              />
            </div>
          </div>
          <div className="flex-row">
            <div className="full-row">
              <PatientMailingInformationInput
                fieldName={"billingAddressLine1"}
                label={"Address Line 1"}
                value={patientMailingInformation?.billingAddressLine1}
                {...fieldProps}
              />
            </div>
          </div>
          <div className="flex-row">
            <div className="full-row">
              <EDS_TextBox
                modifiers={`row-item row-item-size-double ${errorClass("billingAddressLine2")}`}
                name="billingAddressLine2"
                label={formatLabel("billingAddressLine2", "Address Line 2")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, "billingAddressLine2") }}
                onBlur={() => onBlur("billingAddressLine2")}
                value={patientMailingInformation?.billingAddressLine2}
              ></EDS_TextBox>
              {showInputError("billingAddressLine2")}
            </div>
          </div>
          <div className="flex-row">
            <div className="full-row">
              <EDS_TextBox
                modifiers={`row-item row-item-size-double ${errorClass("billingCity")}`}
                name="billingCity"
                label={formatLabel("billingCity", "City")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, "billingCity") }}
                onBlur={() => onBlur("billingCity")}
                value={patientMailingInformation?.billingCity}
              ></EDS_TextBox>
              {showInputError("billingCity")}
            </div>
          </div>
          <div className="flex-row">
            <div className="half-row">
              <EDS_Select
                modifiers={`${errorClass("billingState")}`}
                name="billingState"
                label={formatLabel("billingState", "State (Billing)")}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleStateChange(e, "billingState")}
                onBlur={() => onBlur("billingState")}
                options={stateOptions}
                value={patientMailingInformation?.billingState}
              ></EDS_Select>
              {showInputError("billingState")}
            </div>
            <div style={{ width: '15px' }}></div>
            <div className="half-row">
              <EDS_TextBox
                modifiers={`${errorClass("billingZipCode")}`}
                name="billingZipCode"
                label={formatLabel("billingZipCode", "Zip Code (Billing)")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleZipCodeChange(e, "billingZipCode")}
                onBlur={() => onBlur("billingZipCode")}
                value={patientMailingInformation?.billingZipCode}
              ></EDS_TextBox>
              {showInputError("billingZipCode")}
            </div>
          </div>
          <div className="flex-row">
            <div className="half-row">
              <EDS_TextBox
                modifiers={`${errorClass("billingPhoneNumber")}`}
                name="billingPhoneNumber"
                label={formatLabel("billingPhoneNumber", "Phone Number")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, "billingPhoneNumber") }}
                onBlur={() => onBlur("billingPhoneNumber")}
                value={patientMailingInformation?.billingPhoneNumber}
              ></EDS_TextBox>
              {showInputError("billingPhoneNumber")}
            </div>
            {isMailingAddressRequired ?
              <EDS_Checkbox
              modifiers="row-item row-item-size billing-info-checkbox"
              name="isChecked"
              label="Mailing Info same as Billing Info"
              onChange={handleCheckBoxChange}
              onBlur={() => onBlur("isChecked")}
              checked={patientMailingInformation?.isChecked}
            ></EDS_Checkbox> : null}
            
          </div>
          <div className="flex-row">
            <EDS_TextArea
              name="notes"
              label="Notes"
              modifiers="patient-mail-info-notes row-item row-item-size-double"
              heightModifier={'auto'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, "notes") }}
              value={patientMailingInformation?.notes}
            ></EDS_TextArea>
          </div>

          {(!patientMailingInformation?.isChecked && isMailingAddressRequired ) && (
            <>
              <div className="flex-row">
                <h4 className="row-item row-item-size-double eds-heading eds-heading.mdplus-caps">
                  Payer Mailing Information
                </h4>
              </div>
              <div className="flex-row">
                <div className="half-row">
                  <EDS_TextBox
                    modifiers={`row-item row-item-size-double ${errorClass("firstName")}`}
                    name={nameof<PatientMailingInformation.PatientMailingInformation>("firstName")}
                    label={formatLabel("firstName", "First Name")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, "firstName") }}
                    onBlur={() => onBlur("firstName")}
                    value={patientMailingInformation?.firstName}
                  ></EDS_TextBox>
                  {showInputError("firstName")}
                </div>
                <div style={{ width: '15px' }}></div>
                <div className="half-row">
                  <EDS_TextBox
                    modifiers={`row-item row-item-size-double ${errorClass("lastName")}`}
                    name={nameof<PatientMailingInformation.PatientMailingInformation>("lastName")}
                    label={formatLabel("lastName", "Last Name")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, "lastName") }}
                    onBlur={() => onBlur("lastName")}
                    value={patientMailingInformation?.lastName}
                  ></EDS_TextBox>
                  {showInputError("lastName")}
                </div>
              </div>

              <div className="flex-row">
                <div className="full-row">
                  <EDS_TextBox
                    modifiers={`row-item row-item-size-double ${errorClass("addressLine1")}`}
                    name={nameof<PatientMailingInformation.PatientMailingInformation>("addressLine1")}
                    label={formatLabel("addressLine1", "Address Line 1")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, "addressLine1") }}
                    onBlur={() => onBlur("addressLine1")}
                    value={patientMailingInformation?.addressLine1}
                  ></EDS_TextBox>
                  {showInputError("addressLine1")}
                </div>
              </div>
              <div className="flex-row">
                <div className="full-row">
                  <EDS_TextBox
                    modifiers={`row-item row-item-size-double ${errorClass("addressLine2")}`}
                    name="addressLine2"
                    label={formatLabel("addressLine2", "Address Line 2")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, "addressLine2") }}
                    onBlur={() => onBlur("addressLine2")}
                    value={patientMailingInformation?.addressLine2}
                  ></EDS_TextBox>
                  {showInputError("addressLine2")}
                </div>
              </div>
              <div className="flex-row">
                <div className="full-row">
                  <EDS_TextBox
                    modifiers={`row-item row-item-size-double ${errorClass("city")}`}
                    name="city"
                    label={formatLabel("city", "City")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, "city") }}
                    onBlur={() => onBlur("city")}
                    value={patientMailingInformation?.city}
                  ></EDS_TextBox>
                  {showInputError("city")}
                </div>
              </div>
              <div className="flex-row">
                <div className="half-row">
                  <EDS_Select
                    modifiers={`${errorClass("mailingState")}`}
                    name="mailingState"
                    label={formatLabel("mailingState", "Select State")}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleStateChange(e, "mailingState")}
                    onBlur={() => onBlur("mailingState")}
                    options={stateOptions}
                    value={patientMailingInformation?.mailingState}
                  ></EDS_Select>
                  {showInputError("mailingState")}
                </div>
                <div style={{ width: '15px' }}></div>
                <div className="half-row">
                  <EDS_TextBox
                    modifiers={`${errorClass("zipCode")}`}
                    name="zipCode"
                    label={formatLabel("zipCode", "Zip Code")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleZipCodeChange(e, "zipCode")}
                    onBlur={() => onBlur("zipCode")}
                    value={patientMailingInformation?.zipCode}
                  ></EDS_TextBox>
                  {showInputError("zipCode")}
                </div>
              </div>
              <div className="flex-row">
                <div className="full-row">
                  <EDS_TextBox
                    modifiers={`row-item row-item-size ${errorClass("phoneNumber")}`}
                    name="phoneNumber"
                    label={formatLabel("phoneNumber", "Phone Number")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, "phoneNumber") }}
                    onBlur={() => onBlur("phoneNumber")}
                    value={patientMailingInformation?.phoneNumber}
                  ></EDS_TextBox>
                  {showInputError("phoneNumber")}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer children={footerChildren} />
    </>
  );
};

export function PatientMailingInformationInput(props: {
  fieldName: keyof PatientMailingInformation.PatientMailingInformation,
  label: string,
  value?: string,
  showInputError: (fieldName: keyof PatientMailingInformation.PatientMailingInformation) => void,
  onBlur: (fieldName: keyof PatientMailingInformation.PatientMailingInformation) => void,
  errorClass: (fieldName: keyof PatientMailingInformation.PatientMailingInformation) => string,
  formatLabel: (fieldName: keyof PatientMailingInformation.PatientMailingInformation, label: string) => string
  handleChange: (event:React.ChangeEvent<HTMLInputElement>, fieldName: keyof PatientMailingInformation.PatientMailingInformation) => void
}) {
  const { fieldName, label, errorClass, formatLabel, handleChange, onBlur, value, showInputError } = props;
  return <>
    <EDS_TextBox
      modifiers={`row-item row-item-size-double ${errorClass(fieldName)}`}
      name={nameof<PatientMailingInformation.PatientMailingInformation>(fieldName)}
      label={formatLabel(fieldName, label)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e, fieldName) }}
      onBlur={() => onBlur(fieldName)}
      value={value}
    ></EDS_TextBox>
    {showInputError(fieldName)}
  </>
}
