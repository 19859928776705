import '../assets/styles/components/_paymentPreview.scss';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { useDispatch } from 'react-redux';
import PatientInformation from '../features/paymentPlan/infoSections/PatientInformation';
import CreatePaymentPlan from 'features/paymentPlan/CreatePaymentPlan';
import { usePaymentWizzard } from '../features/wizards/PaymentWizard';
import { useTypedSelector } from '../app/rootReducer';
import { ReactComponent as Edit } from 'assets/svgs/edit-pen.svg';
import * as router from 'react-router-dom';
import { useOrganizations } from '../features/organizations/hooks/useOrganizations';
import { useEffect, useMemo } from 'react';
import DashBoards from 'features/paymentDashboard/DashBoards';
import { PatientInfoModel } from 'features/patientInfo/PatientInfoReducer';
import { Dashboards } from 'features/admin/paymentPanel/ConfigurationSelector';
import { MetaData } from 'models/metaData/MetaData';
import { useLocalStorage } from 'utils/useLocalStorage';
import { saveConfiguration } from 'features/admin/paymentPanel/PaymentPanelReducer';
import { setPlanPanelConfiguration } from 'features/paymentPlan/PaymentPlanReducer';
import { checkReceivedHasValues, getValueOrDefault } from 'utils/Utils';

export default function () {
  let id = "testid";

  let { search } = router.useLocation()
  let queryParams = new URLSearchParams(search);

  let facilityId = queryParams.get("facility");
  let departmentId = queryParams.get("department");
  const { useGetOrganizationById } = useOrganizations();
  let facility = useGetOrganizationById(getValueOrDefault(facilityId, ''));
  let department = useGetOrganizationById(getValueOrDefault(departmentId, ''));
  const selectedDashboard = localStorage.getItem('selectedDashboard');
  const [selectedConfiguration] = useLocalStorage<MetaData>('paymentPanelState', 'selectedConfiguration', {});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadcrumbLinks([{ name: checkReceivedHasValues(isPaymentType(), 'Payment Preview', 'Payment Plan Preview'), slug: 'payment' }]));

    // on unload set breadcrumb links
    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [dispatch]);

  useMemo(() => {
    dispatchSelectedConfig();
  }, [selectedConfiguration]);

  const wizard = usePaymentWizzard();

  const selectedPlans = getValueOrDefault(useTypedSelector(s => s.paymentPlanInfo?.selected), []);

  const patient = {
    "id": 'testId',
    "firstName": '<First Name>',
    "lastName": '<Last Name>',
    "mrn": "<number>",
    "secondaryMrn": "<number>",
    "consolidationId": "<number>",
    "secondaryConId": "<number>",
    "guarantorAccountNo": "<number>",
    "dateOfBirth": "02/09/2023",
    "plans": [],
  };
  const refreshed = useTypedSelector(p => !!p.tokenInfo.refreshed);
  const isProcessing = useTypedSelector(s => s.services.calls.getPatient?.isProcessing);

  let patientInfo = useMemo(() => {
    return getPatientInfoComponent();
  }, [facility])

  let dashBoards = useMemo(() => {
    if (facility) {
      return <DashBoards
        key={facility.id}
        isGl={false}
        wizard={wizard}
        patientId={id}
        selectedFacility={facility}
        paymentType={isPaymentType()}
        isPreview={true}
      />
    }
  }, [facility, isPaymentType()]);

  function dispatchSelectedConfig() {
    if (selectedConfiguration && Object.keys(selectedConfiguration).length) {
      dispatch(saveConfiguration(
        {
          key: selectedDashboard as keyof Dashboards,
          configuration: {
            ...selectedConfiguration,
          }
      }));  
      dispatch(
        setPlanPanelConfiguration({ organizationPath: getValueOrDefault(facility?.path, ''), configuration: { ...selectedConfiguration } })
      );
    }
  }

  function getPatientInfoComponent() {
    if (facility)
      return <PatientInformation patientInfo={getPatientInformation()} radioVisible={false} />
  }

  function getPatientInformation(): PatientInfoModel {
    return {
      id: patient?.id, mrn: patient?.mrn, guarantorId: patient?.guarantorAccountNo,
      firstName: patient?.firstName, lastName: patient?.lastName, dateOfBirth: patient?.dateOfBirth,
      facility: facility,
      department: department,
      consolidationId: patient?.consolidationId,
      secondaryMRN: patient?.secondaryMrn,
      secondaryConId: patient?.secondaryConId
    }
  }

  function isPaymentType() {
    return ["PaymentDashboard", "PaymentDashboardDraft", "EcnPaymentDashboard", "EcnPaymentDashboardDraft"].includes(selectedDashboard as string);
  }

  function isPaymentPlanType() {
    return ["PaymentPlanDashboardDraft", "PaymentPlanDashboard"].includes(selectedDashboard as string);
  }

  function onEdit() {
    window.close();
  }

  function displayPatient() {
    if (patient) {
      return (
        <div className='dash'>
          <div className="header-bar">
            {checkReceivedHasValues(isPaymentType(), "PAYMENT PAGE PREVIEW", "PAYMENT PLAN PAGE PREVIEW")}
            <button
              onClick={onEdit}
              className="edit-button"
            >
              <Edit />
              <span className="edit-span">Edit</span>
            </button>
          </div>

          <div className="pane">

            <div className="left">
              {patientInfo}
              <div className="dashboards-container">
                {dashBoards}
              </div>
            </div>
            <div className="right">
              <CreatePaymentPlan
                wizard={wizard}
                ids={selectedPlans}
                patientId={id}
                organization={department}
                facilityInfo={facility}
                showNextPatientModal={true}
              />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>Patient not found</div>
      )
    }
  }


  return !refreshed || isProcessing ? (
    <div>Processing ...</div>
  ) : displayPatient()
}