import { ApiRemitConfiguration, RemitConfiguration } from "../models/admin/RemitConfiguration";
import { RemitPreviewRequest } from "../models/admin/RemitPreviewRequest";
import { callHttpApi } from "./callHttpApi";

export interface RemitHistorySearchCriteria {
  organizationPath: string,
  offset?: number,
  limit?: number,
}

export async function createRemitConfiguration(remitConfiguration: ApiRemitConfiguration) {
  return callHttpApi<RemitConfiguration, ApiRemitConfiguration>(
    'post',
    `/api/v1/RemitConfiguration`,
    remitConfiguration
  );
}

export async function updateRemitConfiguration(id: string, remitConfiguration: ApiRemitConfiguration) {
  return callHttpApi<RemitConfiguration, ApiRemitConfiguration>(
    'put',
    `/api/v1/RemitConfiguration/${id}`,
    remitConfiguration
  );
}

export async function getRemitHistory(remitHistorySearchCriteria: RemitHistorySearchCriteria) {
  return callHttpApi(
    'get',
    `/api/v1/RemitHistory`,
    undefined,
    remitHistorySearchCriteria
  )
}
export interface GetRemitConfigurationsQueryParams {
  organizationPaths?: string[],
  limit?: number,
  offset?: number,
}

export async function getRemitConfigurations(props: GetRemitConfigurationsQueryParams) {
  const params = new URLSearchParams();
  params.set("limit", props.limit?.toString() ?? '100');
  params.set("offset", props.offset?.toString() ?? '0');
  props.organizationPaths?.forEach(p => params.append('organizationPaths', p));

  return callHttpApi<{ records: RemitConfiguration[], total: number}>(
    'get',
    `/api/v1/RemitConfiguration`,
    undefined,
    params
  );
}

export interface CloneRemitConfiguration {
  originalConfigurationId?: string,
  organizationId: string,
  fileNameOverwrite: string,
  fileIdentifierOverwrite: string
}

export interface cloneRemitConfigrationsData {
  remitConfigIds: string[],
  bulkCloneDestinationOrganizations: CloneRemitConfiguration[]
}

export async function cloneRemitConfigurations(data?: cloneRemitConfigrationsData) {
  return callHttpApi<{remitConfigurations : RemitConfiguration[]}, cloneRemitConfigrationsData>(
    'post',
    `api/v1/RemitConfiguration/BulkClone`,
    data
  );
}

export async function getRemitConfigurationById(id: string) {
  return callHttpApi<RemitConfiguration>(
    'get',
    `/api/v1/RemitConfiguration/${id}`
  );
}

export async function DeleteFileConfiguration(fileConfigurationId: string) {
  return callHttpApi(
    'delete',
    `/api/v1/RemitConfiguration/${fileConfigurationId}`
  );
}

export async function getRemitPreviewService(remitPreviewRequest: RemitPreviewRequest) {
  return callHttpApi<string, RemitPreviewRequest>(
    'post',
    `/api/v1/Remit/preview`,
    remitPreviewRequest
  );
}