import '../assets/styles/components/_payment.scss';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { useDispatch } from 'react-redux';
import PatientInformation from '../features/paymentPlan/infoSections/PatientInformation';
import CreatePaymentPlan from 'features/paymentPlan/CreatePaymentPlan';
import { getWizardSteps, usePaymentWizzard } from '../features/wizards/PaymentWizard';
import { useTypedSelector } from '../app/rootReducer';
import { useGetPatient } from '../features/patients/hooks/useGetPatient';
import * as router from 'react-router-dom';
import { useGetPatientById } from '../features/patients/hooks/useGetPatientById';
import { useGetOrganizations } from 'features/organizations/hooks/useGetOrganizations';
import { useOrganizations } from '../features/organizations/hooks/useOrganizations';
import { useSetReducersInitialState } from 'utils/hooks/useSetReducersInitialState';
import { useScriptDangerous } from '../utils/hooks/useScriptDangerous';
import { useEffect, useMemo, useState } from 'react';
import DashBoards from 'features/paymentDashboard/DashBoards';
import { PatientInfoModel, setPatientInfoState } from 'features/patientInfo/PatientInfoReducer';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import { PatientEncounterModel } from 'models/PatientEncounterModel';
import { setIsPaymentType } from '../features/paymentDashboard/PaymentDashboardReducer';
import { setSidepaneLink } from 'features/sidepane/SidepaneReducer';

export default () => {
  let { id } = router.useParams<{id: string}>();
  const useScript = useScriptDangerous();
  const { havePermission } = useLoggedUserPermissions();
  const firstPaymentAmount = document.getElementById("paymentAmount0") as HTMLInputElement;

  useEffect(() => {
    if (firstPaymentAmount) {
      const script = `function test() {
        var firstPaymentAmount = document.getElementById("paymentAmount0");
        changeInputValue(firstPaymentAmount, "100");
        alert("payment amount changed")
      }
      window.testChange = test;`
      useScript(script);
    }

  }, [firstPaymentAmount]);

  const { search, state } = router.useLocation()
  const queryParams = new URLSearchParams(search);
  const phaInfo = state;
  const facilityId = queryParams.get("facility");
  const departmentId = queryParams.get("department");
  const { useGetOrganizationById } = useOrganizations();
  const facility = useGetOrganizationById(facilityId || '');
  const department = useGetOrganizationById(departmentId || '');
  const [showPlan, setShowPlan] = useState<boolean>(true);

  const { paymentPageReducers: setPaymentPageReducersInitialState } = useSetReducersInitialState();

  let typedAccountNumberResult: string | undefined;
  const [typedAccountNumber, setTypedAccountNumber] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadcrumbLinks([{ name: 'Make a Payment', slug: 'payment' }]));
    dispatch(setSidepaneLink('make-pymt'));

    // on unload set breadcrumb links
    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (facility && department) {
      setIsLoadingFacilityandDepartment(false);
    }
  }, [facility, department]);

  useEffect(() => {
    if (!facilityId && !departmentId) {
      setIsLoadingFacilityandDepartment(false);
    }

    typedAccountNumberResult = localStorage.getItem('typedAccountNumber') ?? undefined;
    if (typedAccountNumberResult) {
      setTypedAccountNumber(typedAccountNumberResult);
      localStorage.removeItem('typedAccountNumber');
    }
  }, []);

  const wizard = usePaymentWizzard();

  const selectedPlans =
    useTypedSelector(s => s.paymentPlanInfo?.selected) || [];
  const { patient } = useGetPatientById(id);
  const refreshed = useTypedSelector(p => !!p.tokenInfo.refreshed);
  const [isLoadingFacilityandDepartment, setIsLoadingFacilityandDepartment] = useState<boolean>(true);

  const isProcessing = useTypedSelector(s => s.services.calls.getPatient?.isProcessing);
  const { getPatient } = useGetPatient(id);
  const { getOrganizations } = useGetOrganizations();
  const isPaymentType = useTypedSelector(s => s.paymentDashboard.isPaymentType);
  const isPayingNow = useTypedSelector(s => s.paymentDashboard.isPayingNow);

  const onCreatePlanButtonClick = () => {
    dispatch(setIsPaymentType(false));
  }

  const onShowPlan = (showPlan: boolean) => {
    setShowPlan(showPlan);
  }

  useEffect(() => {
    wizard.selectStep(getWizardSteps(false)[0]);
    setPaymentPageReducersInitialState();
    !patient && getPatient();
    getOrganizations();
  }, [dispatch, id]);

  let dashBoards = useMemo(() => {
    if (facility) {
      return <DashBoards
        key={`${facility.id}-DashBoards`}
        isGl={false}
        wizard={wizard}
        patientId={id}
        selectedFacility={facility}
        paymentType={isPaymentType}
        preloadedLineItems={phaInfo as PatientEncounterModel[]}
        typedAccountNumber={typedAccountNumber}
        shouldShowPlan={onShowPlan}
      />
    }
  }, [facility, isPaymentType, id]);

  function getPatientInformation(): PatientInfoModel {
    return {
      id: patient?.id, mrn: patient?.mrn, guarantorId: patient?.guarantorAccountNo, accountNo: patient?.accountNo,
      firstName: patient?.firstName, lastName: patient?.lastName, dateOfBirth: patient?.dateOfBirth, facility: facility,
      department: department, secondaryMRN: patient?.secondaryMrn,consolidationId:patient?.consolidationId, secondaryConId: patient?.secondaryConId
    }
  }

  function grantedUser(): boolean {
    return havePermission(UserPermissions.FinancialAdministation, facility?.path) ||
      havePermission(UserPermissions.CreatePaymentPlan, facility?.path) ||
      havePermission(UserPermissions.EditPaymentPlan, facility?.path) ||
      havePermission(UserPermissions.SearchPlans, facility?.path) ||
      havePermission(UserPermissions.CancelPaymentPlan, facility?.path) ||
      havePermission(UserPermissions.ResumePausePaymentPlan, facility?.path) ||
      havePermission(UserPermissions.StartStopPaymentPlan, facility?.path) ||
      havePermission(UserPermissions.OverridePaymentPlanTiers, facility?.path)
  }

  function showPaymentComponent() {
    if (!refreshed || isProcessing || isLoadingFacilityandDepartment) {
      return (
        <div>Processing ...</div>
      )
    }

    if (patient) {
      if (facility && department) {
        return (
          <div className="pane">
            <div className="left">
              <PatientInformation
                patientInfo={getPatientInformation()}
                radioVisible={grantedUser()}
                shouldShowPlan={showPlan}
              />
              {isPayingNow ? <div className="payment_overlay"> </div> : null}
              <div className={`dashboards-container ${isPayingNow ? "content_overlay_fix": ""}`}>
                {dashBoards}
              </div>
            </div>
            <div className="right">
              <CreatePaymentPlan
                wizard={wizard}
                ids={selectedPlans}
                patientId={id}
                onCreatePlanButtonClick={onCreatePlanButtonClick}
                organization={department}
                facilityInfo={facility}
                showNextPatientModal={true}
              />
            </div>
          </div>
        )
      } else {
        return (
          <div>Facility or department not found</div>
        )
      }
    } else {
      return (
        <div>Patient not found</div>
      )
    }
  }

  return showPaymentComponent();
};