import { ReactComponent as LeftIcon } from 'assets/svgs/admin/left.svg'
import { ReactComponent as RightIcon } from 'assets/svgs/admin/right.svg'
import { ReactComponent as AddIcon } from 'assets/svgs/admin/plus-circle.svg'
import { ReactComponent as RemoveIcon } from 'assets/svgs/admin/minus-circle.svg'
import { LineItem } from '../../../models/LineItem'
import { Attribute } from '../../../models/metaData/MetaData'
import { CollapseButton, CollapsibleSection, Sections } from '../../../pages/Admin/PaymentPanelConfiguration'
import { useEffect, useState } from 'react'
import { MultiSelectOption } from '../../../components/select/MultiSelect'
import { useDispatch } from 'react-redux'
import { Dashboards } from './ConfigurationSelector'
import { saveConfiguration, saveConfigurationEntity } from './PaymentPanelReducer'
import { LineItemSelector } from 'features/admin/paymentPanel/LineItemSelector'
import { useSaveAsOptions } from './serviceHandlers/useSaveAsOptions'
import { useLoadFromOptions } from './serviceHandlers/useLoadFromOptions'
import { useTypedSelector } from '../../../app/rootReducer'
import { DataType } from 'models/metaData/MetaDataEnums'
import { enumToMultiSelectOptions, sortOptions } from 'utils/Utils'
import { PaymentPanelFieldType } from 'models/enums/EnumPaymentPanels'
import { useLineItemValidator } from 'features/paymentDashboard/validators/useLineItemValidator'

export interface LineItemConfigurationProps {
  openField?: keyof LineItem,
  newCustomAttributeName?: string,
  selectedDashboard?: keyof Dashboards,
  dashboardSettingsCollapsed?: Sections,
  collapseSection: (name: keyof Sections) => void,
  previewLineItem?: LineItem
}

export function LineItemConfiguration(props: LineItemConfigurationProps) {
  const { openField, newCustomAttributeName, selectedDashboard, dashboardSettingsCollapsed, collapseSection, previewLineItem } = props
  const dispatch = useDispatch();
  const [showField, setShowField] = useState<boolean>(true);

  useEffect(() => {
    if(selectedDashboard === "PatientSearchPopupDashboard" || selectedDashboard === "PatientSearchPopupDashboardDraft")
    {
      setShowField(false);
    }
  }, []);

  const configuration = useTypedSelector(s => (selectedDashboard && s.paymentPanel.configurations[selectedDashboard]));
  const attributes = configuration?.entity?.attributes ?? [];
  const currentAttribute = !newCustomAttributeName ? attributes.find(a => a.name === openField) : attributes.find(a => a.newCustomFieldName === newCustomAttributeName); 
  const currentEntity = configuration?.entity;
  const currentIndex = attributes.indexOf(currentAttribute!);
  const { validate } = useLineItemValidator(attributes);

  const setAttributeProperty = (attributeName: keyof LineItem, name: keyof Attribute, value: string | Attribute['listOptions'] | boolean | undefined, newCustomAttributeName?: string) => {
    dispatch(saveConfigurationEntity({ key: selectedDashboard, attributeName, name, value, newCustomAttributeName }))
  }

  useEffect(() => {
    validate(currentAttribute, currentAttribute?.placeholderText, false, previewLineItem);
  },[currentAttribute?.required]);

  function addNewField() {
      const updated = [...attributes];
      const customFieldData = updated.filter(x => x.name.includes("undefined") && x.newCustomFieldCount !== undefined);
      const customFieldCount = customFieldData.length === 0 ? 1 : (customFieldData[customFieldData.length - 1].newCustomFieldCount! + 1);
      const customName = "undefined" + customFieldCount as keyof LineItem;
      updated.push({
        name: customName,
        label: "",
        saveAs: "undefined" + customFieldCount,
        dataType: DataType.String,
        visible: true,
        newCustomFieldCount: customFieldCount,
        newCustomFieldName: "undefined" + customFieldCount,
        required: false
      });

      dispatch(saveConfiguration({
        key: selectedDashboard!,
          configuration: {
            ...configuration,
            entity: {
              ...currentEntity,
              name: "dummyName",
              attributes: updated
            },
            entities: []
          } 
      }))
  }

  function removeField() {
    const defaultFields = ['firstname', 'lastname', 'birthdate', 'guarantoraccountno','accountno'];
    if (configuration && selectedDashboard) {
      if(!defaultFields.includes(currentAttribute!.saveAs!.toLocaleLowerCase())) {
        let updated = [...attributes];
        const index = !newCustomAttributeName ? updated.findIndex(a => a.name === currentAttribute?.name) : updated.findIndex(a => a.newCustomFieldName === newCustomAttributeName);
        if (index > -1) {
          const deleted = updated.splice(index, 1)[0];
          let sortByField = currentEntity?.sortByField;
          let sortDescending = currentEntity?.sortDescending;
          if (currentEntity?.sortByField === deleted.name) {
            sortByField = undefined;
            sortDescending = undefined;
          }
          dispatch(saveConfiguration({
            key: selectedDashboard,
            configuration: {
              ...configuration,
              entity: {
                ...currentEntity,
                sortByField,
                sortDescending,
                name: "dummyName",
                attributes: updated
              }
            }
          }))
        }
      }
    }
  }
  
  const [fieldTypeOptions] = useState<MultiSelectOption[]>(enumToMultiSelectOptions(PaymentPanelFieldType));
  const [fieldTypeOnAddAccountOptions] = useState<MultiSelectOption[]>([...fieldTypeOptions]);



  const { saveAsOptions } = useSaveAsOptions(selectedDashboard);
  const { loadFromOptions } = useLoadFromOptions(selectedDashboard);

  const moveAttribute = (from: number, to: number) => {
    const length = attributes.length;
    const updated = [...attributes]
    if (to > -1 && to < length && from > -1 && from < length) {
      let element = updated.splice(from, 1)[0];
      updated.splice(to, 0, { ...element, position: to });

      dispatch(saveConfiguration(
        {
          key: selectedDashboard!, 
          configuration: {
            ...configuration,
            entity: {
              ...currentEntity,
              name: "dummyName",
              attributes: updated
            }
          }
      }))
    }
  }

  return <>
    <div className={"configuration-section configuration-border"}>
      <div className={"header"}>
        <div className={"title"}>
          <CollapseButton
            name={"field"}
            dashboardSettingsCollapsed={dashboardSettingsCollapsed}
            collapseSection={collapseSection}
          />
          Field Settings
        </div>
        <div className={"buttons"}>
          <LeftIcon onClick={() => moveAttribute(currentIndex, currentIndex - 1)} id='left-icon' />
          <RightIcon onClick={() => moveAttribute(currentIndex, currentIndex + 1)} id='right-icon'/>
          <AddIcon onClick={addNewField} />
          <RemoveIcon onClick={removeField} />
        </div>
      </div>
      <CollapsibleSection
        name={"field"}
        dashboardSettingsCollapsed={dashboardSettingsCollapsed}
      >
        <LineItemSelector 
          openField={openField} 
          newCustomAttributeName={newCustomAttributeName} 
          showField={showField} 
          loadFromOptions={sortOptions(loadFromOptions)} 
          saveAsOptions={sortOptions(saveAsOptions)} 
          fieldTypeOptions={sortOptions(fieldTypeOptions)} 
          fieldTypeOnAddAccountOptions={sortOptions(fieldTypeOnAddAccountOptions)} 
          currentAttribute={currentAttribute} 
          setAttributeProperty={setAttributeProperty} 
        />         
      </CollapsibleSection>
    </div>
  </>
}
