import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePaymentInformation } from './usePaymentInformation';
import { CreateTransactionResult } from 'services/transactions/models/CreateTransactionResult';
import { setTransactionStatus } from 'features/transactions/CurrentTransactionStatusReducer';
import { useCreateTransactionService } from './useCreateTransactionService';
import { useGetTransactionStatus } from 'utils/hooks/useGetTransactionStatus';
import { getServerLineItems } from 'features/paymentPlan/hooks/useGetServerLineItems';
import { ApiTenderTypeEnum, getBalance, valueOrDefault } from '../../utils/Utils';
import PaymentPlanModel, { OrganizationReference } from 'models/PaymentPlan';
import { PlanOperations } from 'models/metaData/MetaDataEnums';
import { useTypedSelector } from 'app/rootReducer';
import EnumTransactionStatusType from 'models/enums/EnumTransactionStatusType';
import EnumTransactionTenderType from 'models/enums/EnumTransactionTenderType';
import { PaymentModel } from 'models';
import { Value } from '../paymentDashboard/PaymentDashboardReducer';
import { useOrganizations } from '../organizations/hooks/useOrganizations';


/**
 * Creates a transaction then polls the server periodically for the status.
 * @param isGLPayment Whether it is a gl payment.
 * @param onSuccess Called when this asynchronus function ends.
 * @param onError Called on error. Provides a description of the error as an argument to the callback.
 * @param pollIntervalMS The interval for polling in milliseconds.
 */

const useGetServerPaymentModel = ( planOperation: PlanOperations, plan?: PaymentPlanModel) => {
  const isAddHoc = planOperation === PlanOperations.AddhockPayment;

  const getServerPaymentModel = (paymentModel: PaymentModel) => {
    return {
      ...paymentModel,
      details: getServerLineItems(
        (plan?.lineItems?.length === 0 || plan == undefined) ? paymentModel.details?.filter(item => item.isActive) : 
        plan?.lineItems?.filter(item => planOperation !== PlanOperations.AddhockPayment || Number(item.paymentAmount ?? item.amount) > 0)
        ,undefined, isAddHoc
      ),
      glDetails: getServerLineItems(paymentModel.glDetails?.filter(item => item.isActive)),
      tenderType:
        paymentModel?.tenderType == ApiTenderTypeEnum.ECheck
          ? EnumTransactionTenderType.eCheck
          : paymentModel?.tenderType,
      ...(plan && plan?.authorized ? {
        paymentPlanId: plan.id,
        totalPlanBalance: getBalance(plan.lineItems ?? []),
      } : { /* empty */ }),
      ...((plan?.billingInformation || plan?.mailingInformation) && plan?.authorized ? {
        billingInformation: plan.billingInformation,
        mailingInformation: plan.mailingInformation,
      } : { /* empty */ })
    }
  }
  return { getServerPaymentModel };
}

const onErrorAction = (props: {
  msg: string, 
  data?: any, 
  onError?: (msg: string, data?: any) => void
}) => {
  const { msg, data, onError } = props;
  if (msg === 'Cancelled') {
    return;
  }
  onError && onError(msg, data);
};

const isErrorStatusType = (statusType: EnumTransactionStatusType) => {
  return ([
      EnumTransactionStatusType.Cancelled, 
      EnumTransactionStatusType.Timeout, 
      EnumTransactionStatusType.Declined,
      EnumTransactionStatusType.Failed
    ].includes(statusType)
  )
}

export function useCreateTransactionAndGetStatus(
  isGLPayment?: boolean,
  onSuccess?: () => void,
  onError?: (msg: string, data?: any) => void,
  total?: number,
  department?: OrganizationReference,
  pollIntervalMS: number = 3000,
  plan?: PaymentPlanModel,
  tenderType?: ApiTenderTypeEnum,
  isMailingAddressRequired?: boolean
) {
  const dispatch = useDispatch();
  const { getPaymentModel } = usePaymentInformation(isGLPayment, total, department, plan, isMailingAddressRequired);
  const { createTransaction } = useCreateTransactionService(tenderType);
  const { getTransactionStatus } = useGetTransactionStatus({
    intervalMS: pollIntervalMS, transactionInTerminalState: true,
  });
  const planOperation = useTypedSelector(s => s.paymentPlanInfo?.operation);
  const { getServerPaymentModel } = useGetServerPaymentModel(planOperation, plan);
  const [hasError, setHasError] = useState(false);
  const { getOrganizationByPath } = useOrganizations();

  const action = useCallback(async (paymentDashboardValue: Value, organizationPath: string, transactionGroupId?: string) => {
    
    const organization = getOrganizationByPath(organizationPath);
    const paymentModel = getPaymentModel(paymentDashboardValue, organization);

    // create the transaction
    const data: CreateTransactionResult = await createTransaction(
      getServerPaymentModel({ ...paymentModel, transactionGroupId })
    );

    if (data?.err) {
      onErrorAction({ msg: data.err, data: data?.errorResponse, onError });
      return;
    }

    if (!data?.result) {
      onErrorAction({ msg: 'Server did not return any data for the transaction.', onError });
      return;
    }

    if (isErrorStatusType(data.result?.statusType)) {
      onErrorAction({ msg: valueOrDefault(data.result?.responseMessage, 'Transaction failed.'), onError });
      setHasError(true);
      return;
    }

    // get the status of the created transaction
    const res = await getTransactionStatus(data.result);

    if (res.err) {
      onErrorAction({ msg: res.err, onError });
      return;
    }

    dispatch(setTransactionStatus(res.result));
    onSuccess && onSuccess();
  }, [
    createTransaction,
    tenderType,
    getTransactionStatus,
    dispatch,
    onSuccess,
    onError,
  ]);

  return { action, hasError };
}

export default useCreateTransactionAndGetStatus;
export type CreateTransactionAndGetStatusType = ReturnType<
  typeof useCreateTransactionAndGetStatus
>;
