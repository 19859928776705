import { PaymentPlanModel} from 'models';
import { Operation } from 'rfc6902';
import { PaymentPlanCreationResultModel, PaymentPlanPauseModel, PaymentPlanResumeModel, ServerPaymentPlanModel } from 'models/PaymentPlan';
import { DistributionOrder, PaymentPlanConfiguration } from 'models/PaymentPlanConfiguration';
import { callHttpApi } from './callHttpApi';
import { BreakDownLineItem } from '../models/LineItem';
import { StopPlansRequestModel } from 'models/StopPlansRequestModel';

export interface CreatePaymentPlanResult {
  result?: PaymentPlanCreationResultModel;
  err?: string;
  errorResponse?: { data: { validationErrors: { errorMessage: string }[] } };
}

export interface GetPaymentPlansResult {
  total: number;
  limit: number;
  offset: number;
  records: PaymentPlanModel[];
}

export interface GetPlansResult {
  result?: GetPaymentPlansResult;
  err?: string;
  errorResponse?: {
    data?: {
      detail?: string
    }
  }
}

export interface GetPaymentPlansSummaryResult {
  activePlansSummary: { count: number; total: number; };
  pausedPlansSummary: { count: number; total: number; };
  stoppedPlansSummary: { count: number; total: number; };
  completedPlansSummary: { count: number; total: number; };
  cancelledPlansSummary: { count: number; total: number; };
  activeGlPlansSummary: { count: number; total: number; };
  pausedGlPlansSummary: { count: number; total: number; };
  stoppedGlPlansSummary: { count: number; total: number; };
  completedGlPlansSummary: { count: number; total: number; };
  cancelledGlPlansSummary: { count: number; total: number; };
  plansStatusSummaryTotal: { count: number; total: number; };
  newPlansSummary: { count: number; total: number; };
  editedPlansSummary: { count: number; total: number; };
  newGlPlansSummary: { count: number; total: number; };
  editedGlPlansSummary: { count: number; total: number; };
  newAndEditedPlansTotal: { count: number; total: number; };
  cardPlansSummary: { count: number; total: number; };
  eCheckPlansSummary: { count: number; total: number; };
  cardAndECheckPlansTotal: { count: number; total: number; };
  amexPlansSummary: { count: number; total: number; };
  discoverPlansSummary: { count: number; total: number; };
  masterCardPlansSummary: { count: number; total: number; };
  visaPlansSummary: { count: number; total: number; };
  otherCardsPlansSummary: { count: number; total: number; };
  cardPlansTotal: { count: number; total: number; };
  eCareNextPlansSummary: { count: number; total: number; };
  epicPlansSummary: { count: number; total: number; };
  epicHostedPaymentPagePlansSummary: { count: number; total: number; };
  externalTransactionPlansSummary: { count: number; total: number; };
  flatFileImportPlansSummary: { count: number; total: number; };
  ivrPlansSummary: { count: number; total: number; };
  kioskPlansSummary: { count: number; total: number; };
  paymentSafePlansSummary: { count: number; total: number; };
  patientFinancialNavigatorPlansSummary: { count: number; total: number; };
  patientSimplePlansSummary: { count: number; total: number; };
  patientSimpleQuickPayPlansSummary: { count: number; total: number; };
  registrationAcceleratorPlansSummary: { count: number; total: number; };
  oneSourcePlansSummary: { count: number; total: number };
  textToPayPlansSummary: { count: number; total: number };
  semafonePlansSummary: { count: number; total: number };
  otherPaymentSourcePlansSummary: { count: number; total: number; };
  paymentSourceSummaryTotal: { count: number; total: number; };
}

interface BreakdownRequest {
  paymentAmount: number,
  distributionOrder?: DistributionOrder,
  breakdownLineItems: BreakDownLineItem[]
}

export interface PaymentPlanPaymentTransactionResult {
  result?: PaymentPlanModel;
  err?: string;
  errorResponse?: { data: { validationErrors: { errorMessage: string }[] } };
}

export interface PaymentPlanExportResponseResult {
  result?: string;
  err?: string;
  errorResponse?: { data: string };
}

export interface PaymentPlanSearchModel {
  id?: string;
  legacyId?: string;
  organizationId?: string[];
  patientId?: string[];
  lineItemAccountNumber?: string;
  patientFirstName?: string;
  patientLastName?: string;
  payerFirstName?: string;
  payerLastName?: string;
  termType?: string[];
  tenderType?: string[];
  status?: string[];
  paymentsRemaining?: number;
  createdBy?: string;
  createdDateMin?: string;
  createdDateMax?: string;
  updatedBy?: string,
  updateDateMin?: string;
  updateDateMax?: string;
  nextPaymentDateMin?: string;
  nextPaymentDateMax?: string;
  amountMin?: number;
  amountMax?: number;
  balanceMin?: number;
  balanceMax?: number;
  offset?: number;
  limit?: number;
  tenderLastFourDigits?: string;
  cardExpirationDate?: string;
  transactionId?: string;
  organizationPaths?: string[];
  cardTypes?: string[];
  cardBrand?: string[];
  mrn?: string;
  guarantorAccountNo?: string;
  patientDateOfBirth?: string;
  patientAccountNumber?: string;
  userId?: string[];
  filter?: {
    id?: {
      filter?: string;
    };
    legacyId?: {
      filter?: string;
    };
    status?: {
      filter?: string;
    };
    paymentAmount?: {
      filter?: number;
      filterTo?: number;
    };
    balance?: {
      filter?: number;
      filterTo?: number;
    };
    termType?: {
      filter?: string;
    };
    paymentsRemaining?: {
      filter?: number;
      filterTo?: number;
    };
    tenderType?: {
      filter?: string;
    };
    cardBrand?: {
      filter?: string;
    };
    methodEndingIn?: {
      filter?: string;
    };
    createdDate?: {
      dateFrom?: string;
      dateTo?: string;
    };
    startDate?: {
      dateFrom?: string;
      dateTo?: string;
    };
    nextPaymentDate?: {
      dateFrom?: string;
      dateTo?: string;
    };
    createdBy?: {
      filter?: string;
    };
    updatedBy?: {
      filter?: string;
    };
    facilityName?: {
      filter?: string;
    };
    departamentName?: {
      filter?: string;
    };
    patientMRN?: {
      filter?: string;
    };
    patientFirstName?: {
      filter?: string;
    };
    patientLastName?: {
      filter?: string;
    };
    payerFirstName?: {
      filter?: string;
    };
    payerLastName?: {
      filter?: string;
    };
    patientDateOfBirth?: {
      filter?: string;
    };
    guarantorId?: {
      filter?: string;
    };
    patientAccountNumber?: {
      filter?: string;
    };
    paymentSource?: {
      filter?: string;
    };
    isGl?: {
      filter?: string;
    };
    notes?: {
      filter?: string;
    };
  },
  sort? : { [key: string]: string }
}

export async function CreatePaymentPlanService(
  data: ServerPaymentPlanModel
): Promise<CreatePaymentPlanResult> {
  return callHttpApi<PaymentPlanCreationResultModel, ServerPaymentPlanModel>(
    'post',
    `/api/v1/PaymentPlans`,
    data,
  );
}

export async function PatchPaymentPlanService(
  id: string,
  data: Operation[],
): Promise<CreatePaymentPlanResult> {
  return callHttpApi<PaymentPlanCreationResultModel, Operation[]>(
    'patch',
    `/api/v1/PaymentPlans/${id}`,
    data,
  );
}

export async function GetPaymentPlanService(id?: string) {
  if (!id) return;
  return callHttpApi<PaymentPlanModel>(
    'get',
    `/api/v1/PaymentPlans/${id}`,
  );
}

export async function getPaymentPlanSearchService(data: PaymentPlanSearchModel) {
  return callHttpApi<GetPaymentPlansResult, PaymentPlanSearchModel>(
    'post',
    `/api/v1/PaymentPlans/search`,
    data,
  );
}

export async function getPaymentPlanSearchSummaryService(data: PaymentPlanSearchModel) {
  return callHttpApi<GetPaymentPlansSummaryResult, PaymentPlanSearchModel>(
    'post',
    `/api/v1/PaymentPlans/summary`,
    data,
  );
}

export async function getPaymentPlanConfigurationService(organizationPath: string) {
  return callHttpApi<PaymentPlanConfiguration>(
    'get',
    `/api/v1/PaymentPlans/Configuration?organizationPath=${organizationPath}`,
  );
}

export async function savePaymentPlanConfigurationService(
  organizationPath: string,
  paymentPlanConfig: PaymentPlanConfiguration
) {
  return callHttpApi(
    'put',
    `/api/v1/PaymentPlans/Configuration/${organizationPath}`,
    paymentPlanConfig
  );
}

export async function pausePaymentPlanService(id?: string, data?: PaymentPlanPauseModel) {
  if (!id || !data) return;
  return  callHttpApi<PaymentPlanModel, PaymentPlanPauseModel>(
    'post',
    `/api/v1/PaymentPlans/${id}/pause`,
    data,
  );
}

export async function resumePaymentPlanService(id?: string, data?: PaymentPlanResumeModel) {
  if (!id || !data) return;
  return callHttpApi<PaymentPlanModel, PaymentPlanResumeModel>(
    'post',
    `/api/v1/PaymentPlans/${id}/resume`,
    data,
  );
}

export async function cancelPaymentPlanService(id?: string) {
  if (!id) return;
  return callHttpApi<PaymentPlanModel>(
    'post',
    `/api/v1/PaymentPlans/${id}/cancel`,
  );
}

export async function GetPaymentPlansByPatientId(
  id: string,
  organizationPath: string,
  validStatuses: string
) {
  return callHttpApi<PaymentPlanModel[]>(
    'get',
    `/api/v1/PaymentPlans/bypatient/${id}?OrganizationPath=${organizationPath}${validStatuses}`,
  );
}

export async function sendEmailsPaymentPlanService(id: string, data: string[]) {
  return callHttpApi<PaymentPlanModel, string[]>(
    'post',
    `/api/v1/PaymentPlans/${id}/senddetailsemail`,
    data,
  );
}

export async function breakDownLineItems(data: BreakdownRequest) {
  return callHttpApi<BreakDownLineItem[], BreakdownRequest>(
    'post',
    `api/v1/PaymentPlans/breakdowns/lineitems`,
    data,
  );
}

export async function makePaymentPlanPaymentTransaction(
  paymentPlanId: string
  ): Promise<PaymentPlanPaymentTransactionResult>{
  return callHttpApi<PaymentPlanModel>(
    'post',
    `api/v1/DebugPaymentPlans/${paymentPlanId}/payment`,
  );
}

export async function CreatePaymentPlansExcelFullReportService(
  data?: any
): Promise<any> {
  return callHttpApi<string, {}>(
    'post',
    '/api/v1/PaymentPlans/export',
    data,
    null,
    { 'Content-Type': 'application/json' }
  );
}

export async function CreateDownloadPaymentPlansExcelFullReportLinkService(
  id: string
): Promise<any> {
  return callHttpApi<string, PaymentPlanExportResponseResult>(
    'get',
    `/api/v1/PaymentPlans/export/${id}`
  );
}

export async function stopPaymentPlans(data: StopPlansRequestModel) {
  return callHttpApi<PaymentPlanModel[], StopPlansRequestModel>(
    'put',
    `/api/v1/PaymentPlans/stopall`,
    data,
  );
}

export async function restartPaymentPlans(organizationPath: string) {
  return callHttpApi<PaymentPlanModel[], {[key: string]: string}>(
    'put',
    `/api/v1/PaymentPlans/restartall`,
    { organizationPath },
  );
}

export async function deleteLineItemsService(id: string, data: string[]) {
  return callHttpApi<PaymentPlanModel, string[]>(
    'delete',
    `/api/v1/PaymentPlans/${id}/delete/lineitems`,
    data,
  );
}