import { EDS_TextBox } from '@EH/eh.eds.react';
import { PatientMailingInformation } from 'features/paymentInfo/PatientMailingInformationReducer';
import { Field } from 'features/singlePayment/Field';
import { TenderInfo } from 'models/PaymentPlan';

export function TenderCashCart (props: {
  source: PatientMailingInformation;
  paymentAmount?: number;
  total?: number;
  prepend: { prependType: string; prependValue: string; };
  formatLabel: (name: keyof TenderInfo, label: string) => string;
  errorClass: (name: keyof TenderInfo) => '' | 'invalid-field';
  showInputError: (name: keyof TenderInfo) => string | undefined;
  onBlur: (name: keyof TenderInfo) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, name: keyof TenderInfo) => void;
}) {
  const {
    source,
    paymentAmount,
    total,
    prepend,
    formatLabel,
    errorClass,
    showInputError,
    onBlur,
    handleChange,
  } = props;

  return (
    <>
      <div className="flex-row">
        <div className="full-row">
          <Field<TenderInfo>
            modifiers={'row-item row-item-size-double'}
            errorClass={errorClass}
            onBlur={onBlur}
            showInputError={showInputError}
            name={'amountTendered'}
            formatLabel={name => formatLabel(name, '*Amount Tendered')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'amountTendered')}
            prepend={prepend}
            value={(source.amountTendered || 0) > 0 ? source?.amountTendered : ''}
          />
        </div>
      </div>
      <div className="flex-row">
        <EDS_TextBox
          name="cashBack"
          label="Cash Back:"
          type={'text'}
          addPrepend={prepend}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'cashBack')}
          modifiers={'row-item row-item-size-double'}
          value={(source.amountTendered || 0) - (paymentAmount ?? total ?? 0)! > 0
            ? (Number(source.amountTendered) - (paymentAmount ?? total ?? 0)).toFixed(2)
            : ''
          }
        />
      </div>
    </>
  )
}