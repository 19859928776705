import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentModel } from 'models';
import { OrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import PaymentPlanModel from 'models/PaymentPlan';
import * as CellRenderers from '../../components/CellRenderers'

export interface GridConfig {
  label: string;
  field: keyof CustomSearchTransactionColumns | keyof CustomSearchPlanColumns;
  filter?: string;
  filterParams?: string;
  filterOptions?: string;
  valueFormatter?: string;
  headerTooltip?: string;
  cellRenderer?: keyof typeof CellRenderers;
  cellStyle?: string;
  colId?: string;
  sortable?: boolean;
  pinned?: string;
  width?: number;
  resizable?: boolean;
  suppressMovable?: boolean;
  getValue?: (
    row: PaymentPlanModel | PaymentModel,
    utils: {
      getFacilityByDepartment: (
        departmentPath: string
      ) => OrganizationLevelDocument | undefined;
    }
  ) => string | (number | undefined);
}

export interface CustomSearchTransactionColumns {
  id: boolean;
  transactionType: boolean;
  accountNumber: boolean;
  amount: boolean;
  balance: boolean;
  discount: boolean;
  discountPercent: boolean;
  gatewayReferenceNumber: boolean;
  tenderType: boolean;
  cardBrand: boolean;
  tenderMaskedAccount: boolean;
  createdDate: boolean;
  settledDate: boolean;
  paymentPlanId: boolean;
  facilityName: boolean;
  departmentName: boolean;
  createdBy: boolean;
  mrn: boolean;
  patientId: boolean;
  patientFirstName: boolean;
  patientLastName: boolean;
  patientDOB: boolean;
  patientType: boolean;
  payerFirstName: boolean;
  payerLastName: boolean;
  notes: boolean;
  glDescription: boolean;
  glNumber: boolean;
  glBillingDepartment: boolean;
  guarantorId: boolean;
  episode: boolean;
  authorizationCode: boolean;
  result: boolean;
  statusType: boolean;
  batchId: boolean;
  paymentSource: boolean;
  patientMrn: boolean;
  patientGuarantorAccountNo: boolean;
  patientDateOfBirth: boolean;
  billingId: boolean;
  resultCode: boolean;
  deviceSerialNumber: boolean;
  deviceName: boolean;
}

export interface CustomSearchPlanColumns {
  id: boolean;
  status: boolean;
  amount: boolean;
  balance: boolean;
  paymentAmount: boolean;
  termType: boolean;
  paymentsRemaining: boolean;
  tenderType: boolean;
  cardBrand: boolean;
  tenderMaskedAccount: boolean;
  createdDate: boolean;
  startDate: boolean;
  nextPaymentDate: boolean;
  finalPaymentDate: boolean;
  createdBy: boolean;
  updatedBy: boolean;
  facilityName: boolean;
  departmentName: boolean;
  mrn: boolean;
  patientId: boolean;
  patientFirstName: boolean;
  patientLastName: boolean;
  dateOfBirth: boolean;
  guarantorAccountNo: boolean;
  payerFirstName: boolean;
  payerLastName: boolean;
  notes: boolean;
  accountNo: boolean;
  isGL: boolean;
  paymentSource: boolean;
  patientMrn: boolean;
  patientGuarantorAccountNo: boolean;
  patientDateOfBirth: boolean;
  stalledDate: boolean;
}

export interface State {
  transactions: { value: CustomSearchTransactionColumns };
  plans: { value: CustomSearchPlanColumns };
}

export const initialState: State = {
  transactions: {
    value: {
      id: true,
      transactionType: true,
      accountNumber: true,
      amount: true,
      balance: true,
      discount: true,
      discountPercent: true,
      gatewayReferenceNumber: true,
      tenderType: true,
      cardBrand: true,
      tenderMaskedAccount: true,
      createdDate: true,
      settledDate: true,
      paymentPlanId: true,
      facilityName: true,
      departmentName: true,
      createdBy: true,
      mrn: true,
      patientId: true,
      patientFirstName: true,
      patientLastName: true,
      patientDOB: true,
      patientType: true,
      payerFirstName: true,
      payerLastName: true,
      notes: true,
      glDescription: true,
      glNumber: true,
      glBillingDepartment: true,
      guarantorId: true,
      episode: true,
      authorizationCode: true,
      result: true,
      statusType: true,
      batchId: true,
      paymentSource: true,
      patientMrn: true,
      patientGuarantorAccountNo: true,
      patientDateOfBirth: true,
      billingId: true,
      resultCode: true,
      deviceSerialNumber: true,
      deviceName: true
    },
  },
  plans: {
    value: {
      id: true,
      status: true,
      amount: true,
      balance: true,
      paymentAmount: true,
      termType: true,
      paymentsRemaining: true,
      tenderType: true,
      cardBrand: true,
      tenderMaskedAccount: true,
      createdDate: true,
      startDate: true,
      nextPaymentDate: true,
      finalPaymentDate: true,
      createdBy: true,
      updatedBy: true,
      facilityName: true,
      departmentName: true,
      mrn: true,
      patientId: true,
      patientFirstName: true,
      patientLastName: true,
      dateOfBirth: true,
      guarantorAccountNo: true,
      payerFirstName: true,
      payerLastName: true,
      notes: true,
      accountNo: true,
      isGL: true,
      paymentSource: true,
      patientMrn: true,
      patientGuarantorAccountNo: true,
      patientDateOfBirth: true,
      stalledDate: true
    },
  },
};

const reducerSlice = createSlice({
  name: 'CustomSearchTransactionColumns',
  initialState,
  reducers: {
    setCustomSearchColumnsState(
      state,
      action: PayloadAction<CustomSearchTransactionColumns>
    ) {
      state.transactions.value = action.payload;
    },
    setCustomSearchPlanColumnsState(
      state,
      action: PayloadAction<CustomSearchPlanColumns>
    ) {
      state.plans.value = action.payload;
    },
  },
});

export const {
  setCustomSearchColumnsState,
  setCustomSearchPlanColumnsState,
} = reducerSlice.actions;
export default reducerSlice.reducer;
