import { useCallService } from "../../../../services/useCallService";
import { getRemitPreviewService } from "../../../../services/RemitService";
import { setRemitPreview } from "../RemitConfigurationReducer";
import { useDispatch } from "react-redux";
import { RemitConfiguration} from "models/admin/RemitConfiguration";
import { mapRemitPreviewForApi } from "./RemitConfigurationMapper";

export function useGetRemitPreview () {
  const dispatch = useDispatch();
  const callService = useCallService();
  const getRemitPreview = (remitConfiguration: RemitConfiguration) => {
    const mapped = mapRemitPreviewForApi(remitConfiguration);
    callService("getRemitPreview", async () => {
      const response = await getRemitPreviewService(mapped);
      if (response.result) {
       dispatch(setRemitPreview(response.result));
      }
    });
  }
  
  return {
    getRemitPreview
  }
}