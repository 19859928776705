import { LogoManager as LogoManagerModel } from 'models/LogoManagerModel';
import { FaCheckSquare } from 'react-icons/fa';

interface LogoProps {
  logo: LogoManagerModel;
  isSelected: boolean;
  onClick: Function;
}

export default function Logo(props: LogoProps) {
  const { logo, isSelected, onClick } = props;
  const logoType =
    atob(logo.imageData).indexOf('<svg') !== -1
      ? 'data:image/svg+xml;base64,'
      : 'data:image/png;base64,';

  const selectedClassName = isSelected ? 'active' : '';
  const imageAlt = logo.isSelected
    ? 'Default logo'
    : `Available logo with ID ${logo.logoId}`;
  const imageTitle = logo.isSelected ? 'Default logo' : 'Select logo';

  return (
    <div
      className={`logo ${selectedClassName}`}
      title={imageTitle}
      onClick={() => onClick(logo.logoId)}
      data-testid="logo-div"
    >
      <img src={`${logoType}${logo.imageData}`} alt={imageAlt} />

      {logo.isSelected ? (
        <span className="checkbox">
          <FaCheckSquare />
        </span>
      ) : (
        ''
      )}
    </div>
  );
}
