import React, { useEffect, useState } from 'react';
import { useDropzone, FileWithPath, FileRejection } from 'react-dropzone';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { getClientConfiguration } from 'features/admin/ClientReducer';
import {
  getLogoDataForOrganization,
  uploadLogoForOrganization,
  deleteLogoForOrganization,
} from 'features/admin/logoManager/LogoManagerReducer';
import { LogoManager as LogoManagerModel } from 'models/LogoManagerModel';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import { EDS_Alert } from '@EH/eh.eds.react';
import 'assets/styles/components/_clientConfiguration.scss';
import ConfigurationTemplate from '../../features/admin/clientConfiguration/ConfigurationTemplate';
import Logo from '../../features/admin/logoManager/Logo';
import ActionConfirmModal from '../../features/admin/ActionConfirmModal';
import { ReactComponent as CloudIcon } from '../../assets/svgs/icon-cloud.svg';
import { ReactComponent as AddLogoIcon } from '../../assets/svgs/icon-plus.svg';
import { FaSave, FaTrash } from 'react-icons/fa';
import 'assets/styles/components/_logoManager.scss';
import { LogoManagerResponse, UpdateOrganizationLogo } from 'services/LogoManagerService';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadcrumbLinks([{ name: 'Logo Manager', slug: rootPath }]));

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}


export default function LogoManager(
  props: Props = { rootPath: 'logo-manager' }
) {
  const { rootPath } = props;
  const emptyMessage = { type: '', message: '' };
  const [alertMessage, setAlertMessage] = useState({ ...emptyMessage });
  const [selectedLogo, setSelectedLogo] = useState<LogoManagerModel>();
  const [selectedLogoId, setSelectedLogoId] = useState('');
  const [selectedLogoName, setSelectedLogoName] = useState('');
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isUpdateLogo, setIsUpdateLogo] = useState(false);
  const [selectedConfigurationId, setSelectedConfigurationId] = useState('');
  const [selectedOrganizationPath, setSelectedOrganizationPath] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [disableDeleteButton, setDisableDeleteButton] = useState(true);
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const isUploading = useTypedSelector(s => s.services.calls.UploadLogoForOrganization?.isProcessing);
  const isDeleting = useTypedSelector(s => s.services.calls.DeleteLogoForOrganization?.isProcessing);
  const clientConfiguration = useTypedSelector(
    s => s.getClientFacilityConfiguration?.result
  );
  const logos = useTypedSelector(s => s.logoManager?.logos);
  const dispatch = useDispatch();
  useBreadcrumb(rootPath);
  const organizationPath = clientConfiguration?.organization.path;
  
    
  useEffect(() => {
    if(organizationPath) {
      dispatch(getClientConfiguration(organizationPath))
      dispatch(getLogoDataForOrganization(organizationPath));
    } else return
  }, []);
    
  const isProcessing = useTypedSelector(s => s.services.calls.GetLogoForOrganization?.isProcessing);

  const onDropAccepted = (filesWithPath: FileWithPath[]) => {
    setFiles(filesWithPath);
    setIsUploadOpen(true);
  };

  const onDropRejected = (fileRejections:FileRejection[]) => {
    fileRejections.forEach((el) => el.errors.forEach((error) => {
      if (error.code === "too-many-files"){
        dispatch(
          setAlert({
            id: AlertIds.TooManyFiles,  
            type: AlertTypes.Error,
            message: `You cannot select more than one logo file.`,
            dismissable: false,}
          )
        )
        return
      }
      if (error.code === 'file-too-large'){
        dispatch(
          setAlert({
            id: AlertIds.FileIsTooBig,         
            type: AlertTypes.Error,
            message: `Logo size should not exceed 1MB.`,
            dismissable: false,
          })
        )
        return 
      }     
      if(error.code === 'file-invalid-type') {
        dispatch(
          setAlert({
            id: AlertIds.LogoManagerAlert,
            type: AlertTypes.Error,
            message: `The logo must be in PNG, JPG or GIF format.`,
            dismissable: false,
          })
        );
      }
    }))   
  };   

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: '.png, .jpg, .gif',
    maxFiles: 1,
    maxSize: 1000000,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected
  });

  const selectedFileName = files[0]?.name;

  const handleUpload = () => {
    if (clientConfiguration) {
      dispatch(uploadLogoForOrganization(clientConfiguration.id, files[0], (response?: LogoManagerResponse)=>{
        setIsUploadOpen(false);
        setDisableDeleteButton(true);
        setFiles([]);

        if (!response?.err) {
          dispatch(
            setAlert({
              id: AlertIds.LogoManagerAlert,
              type: AlertTypes.Success,
              message: `Logo uploaded successfully`,
              dismissable: true,
            })
          );
        }
        else {
          dispatch(
            setAlert({
              id: AlertIds.LogoManagerAlert,
              type: AlertTypes.Error,
              message: response?.err ?? 'Error uploading logo',
              dismissable: true,
            })
          );
        }
      })); 
    }
  };

  const logoAlert = {
    id: AlertIds.LogoManagerAlert,
    type: AlertTypes.Error,
    message: '',
  };

  const submitLogo = async () => {
    setIsUpdateLogo(true);
    let result;
    result = await UpdateOrganizationLogo(
      selectedConfigurationId,
      selectedLogoId
    );

    if (result && result?.err) {
      dispatch(
        setAlert({
          ...logoAlert,
          message: `Error Logo not updated. ${result.errorResponse.data}`,
        })
      );
      setIsUpdateModalOpen(false);
    } else {
      dispatch(
        setAlert({
          ...logoAlert,
          type: AlertTypes.Success,
          message: `Success Logo updated`,
          dismissable: true,
        })
      );
      setIsUpdateModalOpen(false);
    }
    dispatch(getLogoDataForOrganization(selectedOrganizationPath));
    setIsUpdateLogo(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteLogoForOrganization(selectedConfigurationId, selectedLogoId, (response?: LogoManagerResponse) => {
        setDisableDeleteButton(true);
        onDeleteClose();

        if (!response?.err) {
          dispatch(
            setAlert({
              id: AlertIds.LogoManagerAlert,
              type: AlertTypes.Success,
              message: `Logo deleted successfully` ,
              dismissable: true,
            })
            );
          dispatch(getLogoDataForOrganization(selectedOrganizationPath));
        }
        else {
          dispatch(
            setAlert({
              id: AlertIds.LogoManagerAlert,
              type: AlertTypes.Error,
              message: response?.err  ?? 'Error deleting logo',
              dismissable: true,
            })
          );
        }
      })
    )
   
  };

  const onDeleteClick = () => {
    setIsDeleteOpen(true);
  };

  const onDeleteClose = () => {
    setIsDeleteOpen(false);
    setSelectedLogo(undefined);
  };

  const onLogoClick = (logo: LogoManagerModel,
                       orgPath: string = '',
                       configId: string = '',) => {
    setSelectedLogo(logo);
    setSelectedLogoId(logo.logoId);
    setSelectedLogoName(logo.name)
    setDisableDeleteButton(false);
    setSelectedConfigurationId(configId);
    setSelectedOrganizationPath(orgPath);
  };

  /**
   * Renders the logos for a given organization in a grid view. The selected logo is rendered with a different border and checkmark.
   * @param clientLogos The list of logos to render
   * @param configId selected client id
   * @param orgPath selected client path
   * @returns The grid with the logos
   */
  const renderLogos = (
      orgPath: string = '',
      configId: string = '',
      clientLogos: LogoManagerModel[] = []
  ) => {
    return clientLogos.map((logo: LogoManagerModel, index: number) => {
      return (
        <Logo
          key={`logo-${index}`}
          isSelected={selectedLogo?.logoId === logo.logoId}
          logo={logo}
          onClick={() => {
            onLogoClick(logo, orgPath, configId);
          }}
        />
      );
    });
  };

  const showConfirmationPopup = (
    title: string,
    actionButtonText: string,
    message: string,
    confirmAction: () => void,
    closeAction: () => void,
    isOpen: boolean = false,
    isActionProcessing: boolean = false
  ) => {
    return (
      <ActionConfirmModal
        isOpen={isOpen}
        isProcessing={isActionProcessing}
        actionTitle={title}
        actionButtonText={actionButtonText}
        actionConfirmText={message}
        onActionConfirm={confirmAction}
        onClose={closeAction}
      />
    );
  };

  if (isProcessing || !logos) {
    return <p>Getting Logos...</p>;
  } else {
    return (
      <div className="client-configuration">
        {alertMessage.message && (
          <div className="error-message-wrapper" style={{ top: 6 }}>
            <EDS_Alert
              message={alertMessage.message}
              type={alertMessage.type}
              background="gray"
              shadow
              onClose={() => setAlertMessage(emptyMessage)}
            />
          </div>
        )}
        <ConfigurationTemplate title="Logo Manager">
          <div className="logo-manager">
            <div className="add-logo">
              <h3>Add Facility Logo</h3>

              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} data-testid="dropzone-input" />
                <p className="instructions">
                  <CloudIcon />
                  &nbsp;Drop file here, or{' '}
                  <a
                    href="# "
                    onClick={e => {
                      e.preventDefault();
                      open();
                    }}
                  >
                    browse.
                  </a>
                </p>
              </div>
              <p className="restrictions">
                The logo must be in PNG, JPG or GIF format, should not exceed 1MB and must be at
                least 250 pixels wide and 60 pixels tall.
              </p>
            </div>

            <div className="available-client-logos">
              <h3>Available Facility Logos</h3>

              <div className="grid">
                {renderLogos(organizationPath, clientConfiguration?.id, logos)}
                <div className="logo" onClick={open} title="Add new logo">
                  <AddLogoIcon />
                </div>
              </div>
            </div>

            <div className="actions">
              <button
                disabled={selectedLogo === undefined}
                className="action save"
                onClick={e => {
                  e.preventDefault();
                  setIsUpdateModalOpen(true);
                }}
              >
                <FaSave />
                <span className="text">Save</span>
              </button>

              <button
                disabled={disableDeleteButton}
                className="action delete"
                onClick={onDeleteClick}
                data-testid="trash-delete-button"
              >
                <FaTrash />
                <span className="text">Delete</span>
              </button>
            </div>
          </div>
        </ConfigurationTemplate>
        {showConfirmationPopup(
          'Upload Logo',
          'Upload',
          `Are you sure you want to upload the logo <strong>${selectedFileName}</strong>?`,
          handleUpload,
          () => {
            setIsUploadOpen(false);
            setFiles([]);
          },
          isUploadOpen,
          isUploading
        )}
        {showConfirmationPopup(
          'Delete Logo',
          'Confirm',
          `Are you sure you want to delete the logo <strong>${selectedLogoName}</strong>?`,
          handleDelete,
          onDeleteClose,
          isDeleteOpen,
          isDeleting
        )}
        <ActionConfirmModal
          isOpen={isUpdateModalOpen}
          isProcessing={isUpdateLogo}
          actionTitle="Update Logo"
          actionButtonText="OK"
          actionConfirmText={`Are you sure you want to update the logo?`}
          actionCancelButtonText={'Cancel'}
          onClose={() => {
            setIsUpdateModalOpen(false);
          }}
          onActionConfirm={submitLogo}
          showCancelButton={true}
        />
      </div>
    );
  }
}
