import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetPatientInfoFormMetaData } from 'services/patient/GetPatientMetadata';

import { PatientInfoMetaDataForm } from 'models/Metadata';
import { AppThunk } from 'app/rootReducer';
import { PatientModel } from 'models/PatientModel';
import {
  GetPatientsResult,
  getPatientsService,
} from 'services/patient/PatientService';
import { OrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { callService } from '../../services/ServicesReducer';

export interface PatientInfoModel {
  id?: string;
  facility?: OrganizationLevelDocument;
  facilityId?: number;
  department?: OrganizationLevelDocument;
  organizationId?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  mrn?: string;
  secondaryMRN?: string;
  consolidationId?:string;
  secondaryConId?: string;
  accountNo?: string;
  guarantorId?: string;
  metaData?: PatientInfoMetaDataForm;
  isGl?: boolean;
  guarantorAccountNo?: string;
  patientId?: string;
}

export type PatientsModel = PatientModel[];

export interface State {
  value: PatientInfoModel;
  patientQuery: string;
  result?: PatientsModel;
  err?: string;
}

export const initialState: State = {
  value: {
    facilityId: 0,
    organizationId: '', // TODO: This will be hardwired since we need better separation of concerns for the facility and clientId'
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    mrn: '',
    accountNo: '',
    guarantorId: '',
    patientId: '',
    consolidationId:'',
    secondaryMRN: '',
    secondaryConId: '',
  },
  patientQuery: '',
  result: undefined,
  err: '',
};

const reducerSlice = createSlice({
  name: 'PatientInfo',
  initialState,
  reducers: {
    setPatientInfoState(state, action: PayloadAction<PatientInfoModel>) {
      state.value = {...state.value, ...action.payload};
    },
    onRequest(state, action: PayloadAction<string>) {
      state.patientQuery = action.payload;
    },
    onReceivePatientData(state, action: PayloadAction<GetPatientsResult>) {
      state.result = action.payload.result;
      state.err = action.payload.err;
    },
    resetPatientInfo(state) {
      state.value = initialState.value
    }
  },
});

export function getPatientInfoForm(): AppThunk {
  return async (dispatch, getState) => {
    const metaDataResult = await GetPatientInfoFormMetaData();
    let state = getState();
    let pInfoState = Object.assign(
      {},
      state['patientInfo'] && state['patientInfo'].value,
      { metaData: metaDataResult.result }
    );
    if (metaDataResult.result) {
      dispatch(setPatientInfoState(pInfoState));
    }
  };
}

export function getPatients(
  patientQuery: string,
  onDone?: () => void
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(onRequest(patientQuery));
    dispatch(callService("getPatients", async () => {
      const response = await getPatientsService(patientQuery);
      if (response) {
        dispatch(onReceivePatientData(response));
        if (onDone) onDone();
      }
    }))
  };
}

export const {
  setPatientInfoState,
  onRequest,
  onReceivePatientData,
  resetPatientInfo
} = reducerSlice.actions;
export default reducerSlice.reducer;
