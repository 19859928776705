import { useDispatch } from "react-redux";
import { AlertIds, AlertTypes, setAlert } from "../../../alert/AlertReducer";
import { CreateEpicTokenUserMappings, DeleteEpicTokenUserMappings, GetEpicTokenUserMappings, UpdateEpicTokenUserMappings } from "../../../../services/EpicTokenUserMappingService";
import { EditableEpicTokenUserMapping } from "../../../../pages/Admin/Epic/EpicTokenUserMappingPage";
import { useEffect, useState } from "react";
import { useGenericRefStore } from "../departmentMappings/useGenericRefStore";
import { Metadata } from "../whitelist/WhitelistReducer";
import { OrganizationLevelDocument } from "../../../../models/OrganizationLevelDocument";
import { handleUpdateRequest } from "../../useEpicDepartmentMappings";
import { useOrganizations } from "../../../organizations/hooks/useOrganizations";


export function genericMappingUtils<T extends { id?: string, metadata?: Metadata }>(props: {
  setFilteredMappings: (mappings: T[]) => void,
  setFilter: (value: string) => void,
  filteredMappings: T[],
  setMappings: (mappings: T[]) => void,
  dataRef: { current: T[] }
}) {
  const { dataRef, filteredMappings, setFilter, setFilteredMappings, setMappings } = props;

  const storeMappings = (mappings: T[]) => {
    setFilteredMappings(mappings);
    setFilter("");
    setMappings(mappings);

    dataRef.current = mappings;
  }

  const stopEditting = (ids: string[]) => {
    const updated = [...filteredMappings]
    ids.forEach(id => {
      const idx = updated.findIndex(m => m.id === id)
      if (updated[idx]) {
        updated[idx].metadata = {
          ...updated[idx].metadata,
          isEditing: false
        };
      }
    })
    setFilteredMappings(updated);
  }

  return {
    storeMappings,
    stopEditting
  }
}

export function useEpicTokenUserMappings(
  filteredMappings: EditableEpicTokenUserMapping[],
  setFilteredMappings: (mappings: EditableEpicTokenUserMapping[])=>void,
  setFilter: (fliter: string) => void,
  selectedOrganization?: OrganizationLevelDocument,
) {
  const dispatch = useDispatch();

  const [mappings, setMappings] = useState<EditableEpicTokenUserMapping[]>([]);

  const { storeMapping, dataRef } = useGenericRefStore<EditableEpicTokenUserMapping>();

  const { stopEditting, storeMappings } = genericMappingUtils<EditableEpicTokenUserMapping>({
    dataRef,
    filteredMappings,
    setFilter,
    setFilteredMappings,
    setMappings
  });

  const { getFacilityTradingPartnerPath, getTradingPartnerByFacility } = useOrganizations();
  const tradingPartner = getTradingPartnerByFacility(selectedOrganization?.path);
  const tradingPartnerPath = getFacilityTradingPartnerPath(selectedOrganization?.path);
  useEffect(() => { 
    tradingPartnerPath && getMappings(tradingPartnerPath)
  }, [tradingPartnerPath]);

  const getMappings = async (path?: string) => {
    const response = await GetEpicTokenUserMappings(tradingPartnerPath);
    if (response.result) {
      storeMappings(response.result);
    }
  }

  const deleteMappings = async (selectedMappings: string[], mappings: EditableEpicTokenUserMapping[], path?: string) => {
    const mappingsToDelete : string[] = mappings.filter(m => m.id && !m.metadata?.tempId).map(m => selectedMappings.find(x => x == m.id)).filter(m => m !== undefined) as string[];
    const response = (mappingsToDelete.length > 0) && await DeleteEpicTokenUserMappings(mappingsToDelete);
    
    if ((response && response?.result) || mappingsToDelete.length === 0) {
      dispatch(setAlert({ id: AlertIds.EpicTokenMappingsUpdate, type: AlertTypes.Success, message: 'Epic token user mappings deleted', dismissable: true }));

      const response = await GetEpicTokenUserMappings(path);
      if (response.result) {
        const unselectedTempMappings = mappings.filter(m => m.metadata?.tempId && !selectedMappings.includes(m.metadata?.tempId ?? ""));
        
        storeMappings([]);
        window.setTimeout(() => storeMappings(unselectedTempMappings.concat(response.result ?? [])), 0)
     
      }
    } else {
      dispatch(setAlert({ id: AlertIds.EpicTokenMappingsUpdate, type: AlertTypes.Error, message: 'Could not delete mappings', dismissable: true }));
    }
  }

  const setTokenOrganization = (mapping: EditableEpicTokenUserMapping) => {
    return {
      ...mapping,
      tokenReference: {
        ...mapping.tokenReference,
        organizationReference: tradingPartner
      }
    }
  }

  const saveMappings = async (mappings: EditableEpicTokenUserMapping[], path?: string) => {
    const updateSucceeded =
      await handleUpdateRequest({
        requestCall: UpdateEpicTokenUserMappings,
        dispatch,
        mappings: mappings.filter(m => m.id && !m.metadata?.tempId).map(setTokenOrganization),
        stopEditting,
        successMessage: 'Epic token user mappings updated'
      });
    const createSucceeded = await createMappings(mappings.filter(m => !m.id || m.metadata?.tempId).map(setTokenOrganization));

    if (updateSucceeded && createSucceeded) {
      const response = await GetEpicTokenUserMappings(path);
      if (response.result) {
        storeMappings([]);
        window.setTimeout(() => storeMappings(response.result ?? []), 0)
      }
    }
  }

  const createMappings = async (mappings: EditableEpicTokenUserMapping[]) => {
    if (mappings.length) {
      const response = await CreateEpicTokenUserMappings(mappings);
      if (response.result) {
        dispatch(setAlert({ id: AlertIds.EpicTokenMappingsCreate, type: AlertTypes.Success, message: 'Epic token user mappings created', dismissable: true }));
        stopEditting(mappings.map(m => m.metadata?.tempId ?? ""))
      } else {
        dispatch(setAlert({ id: AlertIds.EpicTokenMappingsCreate, type: AlertTypes.Error, message: 'Could not create mappings', dismissable: true }));
        return false;
      }
    }

    return true;
  }

  return { mappings, getMappings, saveMappings, deleteMappings, dataRef, storeMapping }
}