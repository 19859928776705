import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Token {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

export interface State {
  value: Token;
  refreshed?: number,
}

export const getInitialState = (): State => ({
  value: {
    access_token: localStorage.getItem('authToken') || '',
    expires_in: 0,
    refresh_token: localStorage.getItem('refreshToken') || '',
    token_type: '',
  },
});

const reducerSlice = createSlice({
  name: 'TokenInfo',
  initialState: getInitialState(),
  reducers: {
    setTokenInformation(state, action: PayloadAction<Token>) {
      state.value = action.payload;

      state.refreshed = Date.now();
    },
  },
  extraReducers: builder => {
    builder.addCase('USER_LOGGED_OUT', (state, action) => {
      return getInitialState();
    });
  },
});

export const {
  setTokenInformation,
} = reducerSlice.actions;
export default reducerSlice.reducer;
