import PaymentPlanModel, { PauseHistory } from "models/PaymentPlan";
import { convertDateToUtc, convertUtcToLocalDate } from "utils/UtilsDateTime";

export function ConvertPaymentPlanDates() {

  const getPauseHistoriesWithDates = (
    pauseHistories: PauseHistory[], convert: (date: string | undefined, removeTime?: boolean) => string | undefined
    )  => 
    {
    return pauseHistories.map((ph) => {
      return {
          ...ph,
          pauseDate: convert(ph.pauseDate) ?? ph.pauseDate
        };
    });
  }
  
  const convertPaymentPlanDates = (
    paymentPlan: PaymentPlanModel, convert: (date: string | undefined, removeTime?: boolean) => string | undefined) => {    
  
    let plan: PaymentPlanModel = {
      ...paymentPlan,
      pauseHistories: getPauseHistoriesWithDates(paymentPlan.pauseHistories, convert)
    };

    (Object.keys(plan) as Array<keyof PaymentPlanModel>).forEach((key) => {
      if (plan[key] === undefined)
      {
        delete plan[key];
      }
    })

    return plan;
  }

  const getPaymentPlanWithLocalDates = (paymentPlan?: PaymentPlanModel) => {
    if (paymentPlan)
    {
      return  convertPaymentPlanDates(paymentPlan, convertUtcToLocalDate);
    }
    return paymentPlan;
  };

  const getPaymentPlansWithLocalDates = (paymentPlans?: PaymentPlanModel[]) => {
    if (paymentPlans)
    {
      const updatedPlans: PaymentPlanModel[] = [];
      paymentPlans.forEach((plan) => {
        const planLocalDates = getPaymentPlanWithLocalDates(plan);
        planLocalDates && updatedPlans.push(planLocalDates);
      });
      return updatedPlans;
    }
    return paymentPlans;
  };

  const getPaymentPlanWithUtcDates = (paymentPlan?: PaymentPlanModel) => {
    if (paymentPlan)
    {
      return  convertPaymentPlanDates(paymentPlan, convertDateToUtc);
    }
    return paymentPlan;
  };

  return { 
    getPaymentPlansWithLocalDates,
    getPaymentPlanWithUtcDates, 
    getPaymentPlanWithLocalDates, 
    getPauseHistoriesWithDates,
    convertPaymentPlanDates,
  };
}