import { Container, TabPane } from 'reactstrap';
import { useTypedSelector } from 'app/rootReducer';
import { SearchByPatientInformation } from './MainFielters/SearchByPatientInformation';
import { setSimpleSearchInfoState } from './SimpleSearchReducer';
import 'typeface-roboto';
import styles from './simpleSearch.module.scss';
import { useDispatch } from 'react-redux';
import OrganizationUserSearch, { getOrganizationOptions, useGetUserDepartmentsOptions } from '../simpleSearch/OrganizationUserSearch';
import { useEffect, useState } from 'react';
import { getUserDepartmentOrFacilityesOptions } from 'utils/UtilsOrganizationDropdowns';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { useUserUtils } from 'utils/useUserUtils';


export default function SimlpleSearch() {
  const dispatch = useDispatch();
  const state = useTypedSelector(p => p.simpleSearchInfo?.value);
  const { useGetFacilities } = useOrganizations();
  const allFacilities = useGetFacilities();
  const { getLoggedUserOrganizations } = useUserUtils();
  const facilities = allFacilities;
  const userOrganisations = getLoggedUserOrganizations();
  const userFacilityOptions =  getUserDepartmentOrFacilityesOptions(facilities, userOrganisations)
  const facilityOptions = getOrganizationOptions( userFacilityOptions);
  const getUserDepartmentsOptions = useGetUserDepartmentsOptions();
  const departmentsOptions = getUserDepartmentsOptions(userOrganisations);
  const [simpleSearchInitialized, setSimpleSearchInitialized] = useState(false); 
  const simpleSearchSaveSettingsChecked = useTypedSelector(s=> s.simpleSearchInfo?.saveSettings ?? false);
  
  useEffect(()=>{
    if (departmentsOptions?.length > 0 && !simpleSearchSaveSettingsChecked && !simpleSearchInitialized) {
      const currentSimpleState = {...state, facilities: facilityOptions, deparments: departmentsOptions};
      dispatch(setSimpleSearchInfoState(currentSimpleState));
      setSimpleSearchInitialized(true);
    }
  }, [departmentsOptions?.length]);

  return (
    <>
      <TabPane className="tab-pane-content" tabId="1">
        <Container>
          <OrganizationUserSearch
            preselected={true}
            state={state}
            setState={(organizationUserState) => dispatch(setSimpleSearchInfoState({ ...state, ...organizationUserState }))}
          />
          <div className={styles.searchByPatientInfoContainer}>
            <SearchByPatientInformation />
          </div>
        </Container>
      </TabPane>
    </>
  );
}