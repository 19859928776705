import { useDispatch } from 'react-redux';
import { getOrganizationData } from 'services/OrganizationService';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import { setOrganizations } from 'features/organizations/OrganizationsReducer';
import { useTypedSelector } from '../../../app/rootReducer';
import { useCallService } from '../../../services/useCallService';

export function useGetOrganizations(){
  const oneHour = 1 * 60 * 60 * 1000;
  const dispatch = useDispatch();
  const callService = useCallService();

  const lastUpdateDate = useTypedSelector(s => {
    return s.organizations.lastUpdateDate;
  });

  const getOrganizations = async (force: boolean = false) => {
    if (!force && (lastUpdateDate + oneHour) > Date.now())
      return;

    callService("getOrganizations", async () => {
      const responseFacilities = await getOrganizationData(OrganizationLevelTypes.Facility);
      const responseEnterprises = await getOrganizationData(OrganizationLevelTypes.Enterprise);
      const responseTradingPartners = await getOrganizationData(OrganizationLevelTypes.TradingPartner);
      const responseDepartments = await getOrganizationData(OrganizationLevelTypes.Department);

      const organizations = [
        ...responseEnterprises.result ?? [],
        ...responseFacilities.result ?? [],
        ...responseDepartments.result ?? [],
        ...responseTradingPartners.result ?? []
      ];
      dispatch(setOrganizations(organizations));
    });
  };


  return { getOrganizations };
}