import React from 'react';
import { EnumPopupType, EnumPopupButtonsType, EnumPopupButtonsStyle } from 'models/enums/EnumPopupType';
import { ReactComponent as Sign } from 'assets/svgs/icon-warning.svg';
import { ReactComponent as Close } from 'assets/svgs/icon-close.svg';
import style from './Popup.module.scss';
interface CustomStyle {
  footerButton?: string;
  container?: string;
  title?: string;
}

interface Props {
  type?: string;
  header?: string | React.ReactElement;
  title?: string;
  text?: string;
  children?: React.ReactNode;
  footer?: ButtonProps[];
  footNotes?: React.ReactElement | null;
  fullHeight?: boolean;
  fullWidth?: boolean;
  customStyle?: string;
  onClose: () => void;
  onPrint?: () => void;
  onClear?: () => void;
  onSubmit?: void | (() => void) | Promise<any> | any;
  bottomError?: string;
  customClassNames?: CustomStyle;
}

interface ButtonProps {
  type?: string;
  style?: EnumPopupButtonsStyle;
  text: string;
  disabled?: boolean;
  icon?: string;
}

const mapStyle = {
  [EnumPopupButtonsStyle.primary]: style.primaryButton,
  [EnumPopupButtonsStyle.secondary]: style.secondaryButton,
  [EnumPopupButtonsStyle.tertiary]: style.tertiaryButton,
};

const buildClasses = ( fullHeight:boolean, fullWidth:boolean ) => {
  let classes = '';
  if(fullHeight){
    classes += style.popupContainerFullHeight;
  }
  if(fullWidth){
    classes += style.popupContainerFullWidth;
  }
  return classes;
}

export const Popup: React.FC<Props> = props => {
  const {
    type=EnumPopupType.basic,
    header,
    title,
    text,
    children,
    footer,
    fullHeight=false,
    fullWidth=false,
    customStyle,
    onClose,
    onPrint,
    onClear,
    onSubmit,
    footNotes,
    bottomError,
    customClassNames
  } = props;

  const onClickButtonFunctionality = (button: ButtonProps) => {
    if (button.type === EnumPopupButtonsType.cancel) {
      onClose()
      return 
    } 
    if (button.type === EnumPopupButtonsType.clear) {
      onClear && onClear()
      return
    }
    if (button.type === EnumPopupButtonsType.print) {
      onPrint && onPrint()
      return
    }   
    onSubmit()
  }

  return (
    <div className={`${header == 'Email Receipt' || header == 'Email Confirmation' ? style.mailScreen : style.screen}`}>
      <div className={`${style.popupContainer}
        ${buildClasses(fullHeight, fullWidth)}
        ${customStyle ? customStyle : ''}
        ${customClassNames?.container ? style[customClassNames.container] : ''}
      `}>
        <header className={`${style.header} ${type === EnumPopupType.warning && style.headerWarning}`}>
          {type === EnumPopupType.warning && <Sign />}
          <div className={style.popupTextHeader}>{header || type.toUpperCase()}</div>
          <div className={style.closeIcon} onClick={onClose}><Close /></div>
        </header>
        <div className={style.content}>
          {title && <div className={`${customClassNames?.title ? style[customClassNames.title] ?? customClassNames.title : ''}`}>{title}</div>}
          {text && <div className={style.text}>{text}</div>}
          {children}
          {bottomError && <div className={style.errorMessage}>{bottomError}</div>}
        </div>
        {footer && <footer className={style.footer}>
          {footNotes ? <span className="footnotes">
            {footNotes}
          </span> : null}
          {footer.map((button: ButtonProps, i: number) => (
            <button            
              key={i}
              className={`${style.button}
                ${fullHeight || fullWidth || customStyle || customClassNames?.footerButton ? '' : style.fullWidthButton}
                ${button.style ? mapStyle[button.style] : style.primaryButton} ${customClassNames?.footerButton ? style[customClassNames.footerButton] : ''}
                ${button.disabled ? style.disabledButton : ''}`}
              onClick={() => {onClickButtonFunctionality(button)}}
              disabled={!!button.disabled}
            >
              {button.icon && <i className="material-icons eds-button_#icon">{button.icon}</i>}
              {button.text}
            </button>
          ))}
        </footer>}
      </div>
    </div>
  );
};