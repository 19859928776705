import { useState } from 'react';
import * as router from 'react-router-dom';
import {
  Column,
  GridApi,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
} from 'ag-grid-community';
import { PaymentPlanModel } from 'models';
import { PatientModel } from 'models/PatientModel';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import { FullOrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { GetTransactionResult } from 'models/GetTransactionResult';
import { UserDetailModel } from 'models/UserInfoAndRolesModel';
import { GetTransactionsRequest } from 'models/Transaction';
import { cardBrands, getSortOptions } from 'utils/Utils';
import { getPaymentPlanSearchService } from 'services/PaymentPlanService';
import { getTransactionsSearchService } from 'services/PaymentService';
import { PaymentPlanSearchInformation } from '../planSearch/PaymentPlanSearchReducer';
import {
  formatPaymentStatus,
  formatCurrency,
  formatTenderType,
  formatTermType,
  formatCreditCard,
  formatDateString,
  formatIsGl,
  formatTenderTypeTransaction,
  formatDateOfBirthString,
  formatPercent
} from './Results';
import { QueryDictionary, appendTimeStampMax, appendTimeStampMin, getSearchCriteriaBySearchId } from '../searchUtils';
import { GridConfig } from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';

export const getMapValueFormatter = (isTransaction: boolean = false): { [key: string]: (params: any) => string } => {
  return {
    paymentStatus: formatPaymentStatus,
    currency: formatCurrency,
    tenderType: isTransaction ? formatTenderTypeTransaction : formatTenderType,
    termType: formatTermType,
    creditCard: formatCreditCard,
    dateString: formatDateString,
    dateOfBirthString: formatDateOfBirthString,
    isGl: formatIsGl,
    percent: formatPercent
  }
};

export const getAgGridColumnFilter = (row: GridConfig) => {
  return row.filter ? mapFilters[row.filter] : 'agTextColumnFilter';
}

export const mapFilters: { [key: string]: string } = {
  applyMiniFilterWhileTyping: 'applyMiniFilterWhileTyping',
  agNumberColumnFilter: 'agNumberColumnFilter',
  agDateColumnFilter: 'agDateColumnFilter',
};

export const mapFilterOptionss: { [key: string]: string } = {
  applyMiniFilterWhileTyping: 'applyMiniFilterWhileTyping',
  agNumberColumnFilter: 'agNumberColumnFilter',
  agDateColumnFilter: 'agDateColumnFilter',
};

export const defaultFilterParams = {
  buttons: ['reset', 'apply'],
  suppressAndOrCondition: true,
  closeOnApply: true,
  filterOptions: ['contains'],
};

const equals = { filterOptions: ['equals'] };
const inRange = { filterOptions: ['inRange'] };

export const mapParams: { [key: string]: any } = {
  encryptedFieldsParams: equals,
  inRange,
  equals,
};

function groupBy (records: any, property: string){
  return records.reduce((acc: any, obj: any) => {
    let id = obj[property];
    
    if (!acc[id]) {
      acc[id] = obj;
    } else {
      acc[id] = {...obj, amount: acc[id].amount + obj.amount}; 
    }
    
    return acc;
  }, {})
}

export const updateDataDetailsGrid = (gridApi?: GridApi, records?: GetTransactionResult[] | undefined) => {
  gridApi?.showLoadingOverlay()
  const dataSource: IDatasource = {
    getRows(rowsParams: IGetRowsParams)
    {
      if (records?.length) {      
        let groupedObjects = groupBy(records, 'id');
        let newRecords = Object.values(groupedObjects);

        rowsParams.endRow = newRecords.length;
        rowsParams.successCallback(newRecords, newRecords.length);
      } else {
        rowsParams.successCallback([], 0);
      }
    }
  };
  gridApi?.setDatasource(dataSource);
  gridApi?.sizeColumnsToFit();
  gridApi?.hideOverlay()
}

function getQueryParam(key: keyof GetTransactionsRequest | keyof PaymentPlanSearchInformation, searchCriteria: QueryDictionary): any {
  return searchCriteria[key] ?? undefined;
}

function getQueryParamArray(key: keyof GetTransactionsRequest | keyof PaymentPlanSearchInformation, searchCriteria: QueryDictionary): any[] {
  const value = searchCriteria[key];

  if (!value) {
    return [];
  } else if (Array.isArray(value)) {
    return value;
  } else {
    return [value];
  }
}

const transformPaymentQueryParamsToObject = (searchCriteria: QueryDictionary) => {
  const props = {
    organizationPaths: getQueryParamArray('organizationPaths', searchCriteria),
    departmentPaths: getQueryParamArray('departmentPaths', searchCriteria),
    userId: getQueryParamArray('userId', searchCriteria),
    id: getQueryParam('id', searchCriteria),
    legacyId: getQueryParam('legacyId', searchCriteria),
    mrn: getQueryParam('mrn', searchCriteria),
    patientAccountNumber: getQueryParam('patientAccountNumber', searchCriteria),
    patientFirstName: getQueryParam('patientFirstName', searchCriteria),
    patientLastName: getQueryParam('patientLastName', searchCriteria),
    payerFirstName: getQueryParam('payerFirstName', searchCriteria),
    payerLastName: getQueryParam('payerLastName', searchCriteria),
    paymentPlanId: getQueryParam('paymentPlanId', searchCriteria),
    
    patientId: getQueryParam('patientId', searchCriteria),
    guarantorId: getQueryParam('guarantorId', searchCriteria),
    submittedDateTimeMin: getQueryParam('submittedDateTimeMin', searchCriteria),
    submittedDateTimeMax: getQueryParam('submittedDateTimeMax', searchCriteria),
    settledDateTimeMin: getQueryParam('settledDateTimeMin', searchCriteria),
    settledDateTimeMax: getQueryParam('settledDateTimeMax', searchCriteria),
    amountMin: Number(getQueryParam('amountMin', searchCriteria)) || undefined,
    amountMax: Number(getQueryParam('amountMax', searchCriteria)) || undefined,
    discountMin: Number(getQueryParam('discountMin', searchCriteria)) || undefined,
    discountMax: Number(getQueryParam('discountMax', searchCriteria)) || undefined,
    accountNumber: getQueryParam('accountNumber', searchCriteria),
    batchId: Number(getQueryParam('batchId', searchCriteria)) || undefined,
    paymentStatus: getQueryParamArray('paymentStatus', searchCriteria).join(',') || '',
    resultsStatus:getQueryParamArray('resultsStatus', searchCriteria).join(',') || '',
    tenderLastFourDigits: getQueryParam('tenderLastFourDigits', searchCriteria),
    authorizationCode: getQueryParam('authorizationCode', searchCriteria),
    deviceName: getQueryParam('deviceName', searchCriteria),
    deviceSerialNumber: getQueryParam('deviceSerialNumber', searchCriteria),
    glDescription: getQueryParam('glDescription', searchCriteria),
    transactionTypes: getQueryParamArray('transactionTypes', searchCriteria).join(',') || '',
    tenderTypes: getQueryParamArray('tenderTypes', searchCriteria).join(',') || '',
    cardEntryTypes: getQueryParamArray('cardEntryTypes', searchCriteria).join(',') || '',
    cardBrand: getQueryParamArray('cardBrand', searchCriteria),
  }
  return props;
}

const transformPlanQueryParamsToObject = (searchCriteria: QueryDictionary) => {
  const props = {
    organizationPaths: getQueryParamArray('organizationPaths', searchCriteria),
    departmentPaths: getQueryParamArray('departmentPaths', searchCriteria),
    userId: getQueryParamArray('userId', searchCriteria),
    mrn: getQueryParam('mrn', searchCriteria),
    patientAccountNumber: getQueryParam('patientAccountNumber', searchCriteria),
    patientFirstName: getQueryParam('patientFirstName', searchCriteria),
    patientLastName: getQueryParam('patientLastName', searchCriteria),
    payerFirstName: getQueryParam('payerFirstName', searchCriteria),
    payerLastName: getQueryParam('payerLastName', searchCriteria),
    guarantorAccountNo: getQueryParam('guarantorAccountNo', searchCriteria),
    createdDateMin: getQueryParam('createdDateMin', searchCriteria),
    createdDateMax: getQueryParam('createdDateMax', searchCriteria),
    nextPaymentDateMin: getQueryParam('nextPaymentDateMin', searchCriteria),
    nextPaymentDateMax: getQueryParam('nextPaymentDateMax', searchCriteria),
    amountMin: Number(getQueryParam('amountMin', searchCriteria)) || undefined,
    amountMax: Number(getQueryParam('amountMax', searchCriteria)) || undefined,
    id: getQueryParam('id', searchCriteria),
    status: getQueryParamArray('status', searchCriteria),
    transactionId: getQueryParam('transactionId', searchCriteria),
    tenderLastFourDigits: getQueryParam('tenderLastFourDigits', searchCriteria),
    tenderType: getQueryParamArray('tenderType', searchCriteria),
    cardBrand: getQueryParamArray('cardBrand', searchCriteria),
  }
  return props;
}

const getPaymentPlansResults = async (searchCriteria: QueryDictionary, props: any, sortModel: any) => {
  appendTimeStampMin(searchCriteria, 'createdDateMin');
  appendTimeStampMax(searchCriteria, 'createdDateMax');
  appendTimeStampMin(searchCriteria, 'nextPaymentDateMin');
  appendTimeStampMax(searchCriteria, 'nextPaymentDateMax');

  return getPaymentPlanSearchService({
    ...transformPlanQueryParamsToObject(searchCriteria),
    ...props,
    sort: getSortOptions(sortModel)
  })
    .then(response => response?.result)
}

const getPaymentsResults = async (searchCriteria: QueryDictionary, props: any, sortModel: any) => {
  appendTimeStampMin(searchCriteria, 'settledDateTimeMin');
  appendTimeStampMax(searchCriteria, 'settledDateTimeMax');
  return getTransactionsSearchService({
    ...transformPaymentQueryParamsToObject(searchCriteria),
    ...props,
    sort: Object.assign({}, ...sortModel.map((x: any) => ({ [x.colId]: x.sort })))
  })
    .then(response => response?.result)
}

const getFilteredSearchResults: { [key: string]: any } = {
  plan: getPaymentPlansResults,
  payment: getPaymentsResults
}

export const mapTransactionFilterNames: { [key: string]: string } = {
  // All fields which value is calculated via getValue in gridConfig file appear in grid data as indexes
  0: 'balance',
  1: 'discount',
  2: 'facilityName',
  3: 'departmentName',
  4: 'createdBy',
  5: 'notes',
  6: 'glDescription',
  7: 'glNumber',
  8: 'glBillingDepartment',
  tenderMaskedAccount: 'methodEndingIn',
  statusType: 'status',
}

export const mapPlanFilterNames: { [key: string]: string } = {
  0: 'createdBy',
  1: 'updatedBy',
  2: 'facilityName',
  3: 'departmentName',
  tenderMaskedAccount: 'methodEndingIn',
}

export function renameKeys(obj: any, newKeys: { [key: string]: string }) {
  if (!obj) return;
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export function getDataOrDefault(data: any) {
  return { 
    total: data?.total ?? 0, 
    records: data?.records ?? [] 
  };
}

export function getLastRow(dataLength: number, blockSize: number, totalRecords: number) {
  return dataLength < blockSize 
    ? totalRecords
    : -1;
}

export function getFilter(filterModel: any, columnsData: GridConfig[] | undefined, isPlan: boolean) {
  if (filterModel && Object.keys(filterModel).length) {
    return renameKeys(
      getFilterModel(filterModel, columnsData),
      isPlan ? mapPlanFilterNames : mapTransactionFilterNames);
  }
}

export function useUpdateData() {
  const [count, setCount] = useState<number>(0);
  const { search } = router.useLocation();
  let searchQueryParams = new URLSearchParams(search);
  const searchId = searchQueryParams.get('searchId') ?? '';
  const searchCriteria = getSearchCriteriaBySearchId(searchId);  
  const isPlan = window.location.pathname.replace('/', '') === 'plansSearchResult';

  const updateData = (params: GridReadyEvent, initialData: any, columnsData?: GridConfig[]) => {
    let data: any;
    let cacheRecords: GetTransactionResult[] | PaymentPlanModel[] = [];
    let totalRecords = 0;
    let fetchNextRecords = true;

    const dataSource: IDatasource = {
      async getRows(rowsParams: IGetRowsParams) {
        const blockSize = rowsParams.endRow - rowsParams.startRow;
        if (rowsParams.startRow == 0) {
          cacheRecords = [];
          totalRecords = 0;
          fetchNextRecords = true;
        }

        if (data) {
          const props = {
            limit: blockSize,
            offset: rowsParams.startRow,
            filter: getFilter(rowsParams.filterModel, columnsData, isPlan)
          };
          if (fetchNextRecords) {
            data = await getFilteredSearchResults[isPlan ? 'plan' : 'payment'](searchCriteria, props, rowsParams.sortModel);
          }
        } else {
          data = initialData;
        }

        const {total, records} = getDataOrDefault(data);
        totalRecords += records.length;
        cacheRecords.push(...records);
        fetchNextRecords = records.length > 0;
        const rowsThisPage = cacheRecords.slice(0, blockSize);
        cacheRecords = cacheRecords.slice(blockSize);

        setCount(total);
        setTimeout(() => {
          const lastRow = getLastRow(rowsThisPage.length, blockSize, totalRecords);
          rowsParams.successCallback(records, lastRow);
        }, 500);
      },
    };
    params.api.setDatasource(dataSource);
  };
  return { updateData, count };
};

export const renderPopup = (
  params: { value: string; data: { id: string }; setShowNotesPopup: (value: string) => void }
) => {
  const { value, setShowNotesPopup } = params;
  const div = document.createElement('div');
  div.innerText = params.value;
  div.className = 'notes-link';
  div.addEventListener('click', () => {
    setShowNotesPopup(value);
  });
  return div;
};

export const renderPatientData = (params: { value: string, data: PaymentPlanModel, column: Column, valueFormatted: any}, patients: PatientModel[]) => {
  return params.column.getColId() === 'dateOfBirth'
    ? patients.find((patient: PatientModel) => patient.id === params.data.patientId)?.dateOfBirth?.substring(0, 10) || ''
    : patients.find((patient: PatientModel) => patient.id === params.data.patientId)?.[params.column.getColId() as keyof PatientModel]
};

export const renderCardBrand = (params: { value?: string }) => {
  const cardBrand = cardBrands.find(b => b.propertyName === params.value?.toLocaleLowerCase());
  return cardBrand?.label ?? params.value;
};

export function getOrganizationsCount(type: OrganizationLevelTypes, organizations: FullOrganizationLevelDocument[], path?: string[] | null) {
  if (!path) return 0;
  return organizations
    .filter(organizaion => organizaion.organizationLevelType === type && path.includes(organizaion.path)).length;
}

export function getOrganizationName(type: OrganizationLevelTypes, organizations: FullOrganizationLevelDocument[], path?: string[] | null) {
  if (!path) return '';
  return organizations
    .filter(organizaion => organizaion.organizationLevelType === type && path.includes(organizaion.path))
    .map(o => o.name)
    .join(', ');
}

export function getUserName(users: UserDetailModel[], ids?: string[] | null) {
  if (!ids) return '';
  return users
    .filter(user => ids.includes(user.id))
    .map(user => `(${user.oneSourceUserId}) ${user.firstName ?? ''} ${user.lastName ?? ''}`.trim())
    .join(', ');
}

export function getFilterModel(filterModel?: {[key: string]: any}, columnsData?: GridConfig[]) {
  if (!filterModel) return;

  let model: any = {};
  Object.keys(filterModel).forEach((filterModelKey) => {
    const idx = filterModelKey.lastIndexOf('_');
    const field = idx  > 0 ? filterModelKey.substring(0, idx) : filterModelKey;
    const columnData = columnsData?.find(d => d.field == field || d.colId == field);
    const key = columnData?.field || filterModelKey;
    model[key] = filterModel[filterModelKey];
  });

  return model;
}

export function getFiltersData(key: string, keyType: string, filter: any, filterString: string, filterTo?: any, dateFrom?: string, dateTo?: string) {
  switch (keyType) {
    case 'contains':
    case 'equals':
    case 'starts with':
    case 'ends with':
      return `${key} = ${filter} -- `;
    case 'inrange':
      if(dateFrom && dateTo) {
        return `${key} : Between ${dateFrom} and ${dateTo} -- `;
      }
      return `${key} : Between ${filter} and ${filterTo} -- `;
    default:
      return 'Invalid filter';
  }
}