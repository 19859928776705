import { useTypedSelector } from "../../../app/rootReducer";

export default function useTier(isGl: boolean, total: number, organizationPath?: string) {

  const paymentPlanTier = useTypedSelector(s => {
    if (organizationPath) {
      const conf =
        s.paymentPlanInfo.configurations[organizationPath];
      return isGl
        ? conf?.configuration.tiers.GLTiers
        : conf?.configuration.tiers.BillTiers;
    }
  });

  let rangeTier =
    paymentPlanTier &&
    paymentPlanTier.find(tier => total && total >= tier.floor && total <= tier.ceiling);

  if (!rangeTier && paymentPlanTier && total > paymentPlanTier[paymentPlanTier?.length-1].ceiling) {
    // if amount is bigger than max tier ceiling
    rangeTier = paymentPlanTier[paymentPlanTier?.length-1];
  }

  return { rangeTier };
}