import { useState } from "react";
import { useDispatch } from "react-redux";
import { softDeletePaymentOnFile, SoftDeletePaymentOnFileResult } from "../../../services/patient/PatientService";
import { replacePaymentsOnFile } from "../../patients/PatientsReducer";
import { PaymentOnFile } from "../../../models/PatientModel";
import { useCallService } from "../../../services/useCallService";

export function useDeletePaymentsOnFile(patientId?: string, onError?: () => void, onSuccess?: () => void) {

  const dispatch = useDispatch()

  const callService = useCallService();

  function proccessResponse(response: SoftDeletePaymentOnFileResult) {
    const patient = response.result?.patient;
    if (patient) {
      onSuccess && onSuccess();
      dispatch(replacePaymentsOnFile({ patientId: patient.id, paymentsOnFile: patient.paymentsOnFile}));
    } else {
      onError && onError();
    }
  }

  async function deletePaymentsOnFile(methods?: PaymentOnFile[]) {
    if (patientId && methods) {
      callService("softDeletePaymentOnFile", async () => {
        const responses = await Promise.all(methods.map(method => softDeletePaymentOnFile(patientId, method.tokenId)));
        responses.forEach(response => {
          proccessResponse(response);
        })
      });
    }
  }
  return { deletePaymentsOnFile };
}