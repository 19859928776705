export enum EnumPopupType {
  basic = '',
  warning = 'warning',
}

export enum EnumPopupButtonsType {  
  submit = 'submit',
  print = 'print',
  cancel = 'cancel',
  clear = 'clear',
}

export enum EnumPopupButtonsStyle {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}
