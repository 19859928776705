import { useState } from "react";
import { addExternalAdresses, deleteExternalAdresses, updateExternalAdresses } from "../../../../services/WhitelistService";
import { useDispatch } from "react-redux";
import { EditableExternalAddress, removeExternalAddress, setExternalAddress } from "./WhitelistReducer";
import { AlertTypes, setAlert } from "../../../alert/AlertReducer";

export function useExternalAddress(onSuccess?: (idx?: number) => void) {
  const dispatch = useDispatch();
  const [address, setAddress] = useState<string>('');

  async function saveExternalAddress(externalAddress: EditableExternalAddress, index: number, messages?: {
    success: string,
    error: string
  }) {
    let response;
    if (!externalAddress.id) {
      response = await addExternalAdresses(externalAddress);
    } else {
      response = await updateExternalAdresses(externalAddress);
    }
    if (response.result) {
      externalAddress = {
        ...response.result,
        metadata: {
          ...externalAddress.metadata,
          isEditing: false
        }
      }

      dispatch(setExternalAddress({ externalAddress, index }));
      dispatch(setAlert({
        id: 'AddExternalAddress',
        message: messages?.success ?? "External Address saved successfully",
        type: AlertTypes.Success,
        dismissable: true
      }));
      onSuccess && onSuccess(index);
    } else {
      dispatch(setAlert({
        id: 'AddExternalAddress',
        message: messages?.error ?? "Could not save external address",
        type: AlertTypes.Error,
      }))
    }
  }

  async function deleteExternalAddress(id: string, idx: number) {
    if (id) {
      const response = await deleteExternalAdresses(id);
      if (response.result) {
        dispatch(removeExternalAddress(idx));
        dispatch(setAlert({
          id: 'DeleteExternalAddress',
          message: "The URL has been globally deleted",
          type: AlertTypes.Success,
          dismissable: true
        }));
        onSuccess && onSuccess();
      } else {
        dispatch(setAlert({
          id: 'DeleteExternalAddress',
          message: `Could not delete external address. 
          ${response.errorResponse?.data?.validationErrors?.map((e: any) => e.errorMessage).join(", ") ?? ""}`,
          type: AlertTypes.Error,
        }))
      }
    } else {
      window.setTimeout(()=>dispatch(removeExternalAddress(idx)), 0);
    }
  }

  return {
    address,
    setAddress,
    saveExternalAddress,
    deleteExternalAddress
  };
}