import { Attribute } from 'models/metaData/MetaData';
import { AnnotationType, DataType } from 'models/metaData/MetaDataEnums';
import { getAnnotation } from 'utils/metadata/MetaDataUtils';
import { getComponentByValidators } from '../factories/validators';
import { Date } from '../wrapperComponents/Date';
import { DateTime } from '../wrapperComponents/DateTime';
import { TextBox } from '../wrapperComponents/TextBox';
import { Linkview } from '../wrapperComponents/Linkview';
import { AutocompleteField } from '../wrapperComponents/AutocompleteField';
import { PaymentPanelFieldType } from 'models/enums/EnumPaymentPanels';

export function ComponentFactory(props: {
  attribute: Attribute;
  value: any;
  id?: string;
  onClick?: (value: any) => void;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  modifiers?: string;
  disabled?: boolean;
}) {
  const {
    attribute,
    value,
    onClick,
    onChange,
    onBlur,
    modifiers,
    id,
    disabled,
  } = props;

  const dataType = attribute.dataType;

  const getValidatorComponent = () => {
    if(attribute.fieldType === PaymentPanelFieldType.DropDown) {
      return (getComponentByValidators(attribute, value, onChange, modifiers) ?? <></>);     
    }

    return (
      <TextBox
        attribute={attribute}
        value={value}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        modifiers={modifiers}
        disabled={disabled}
      />
    )
  }
  
  if (
    (!getAnnotation(attribute, AnnotationType.Immutable)) ||
    (getAnnotation(attribute, AnnotationType.NoPreview))
  ) {
    switch (dataType) {
      case DataType.String:
      case DataType.Decimal:
      case DataType.Money:
        return ( getValidatorComponent() );
      case DataType.DateTime:
        return (
          <DateTime
            attribute={attribute}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            modifiers={modifiers}
          />
        );
      case DataType.Date:
        return (
          <Date
            attribute={attribute}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            modifiers={modifiers}
          />
        );
      case DataType.Autocomplete:
        return (
          <AutocompleteField
            attribute={attribute}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            modifiers={modifiers}
          />
        );
      default:
        return <></>;
    }
  }

  switch (dataType) {
    case DataType.Link:
      return (
        <Linkview
          attribute={attribute}
          value={value}
          onClick={onClick}
          modifiers={modifiers}
          to={'#'}
      />
      );
    default:
      return (
        <></>
      );
  }
}

export default ComponentFactory;
