import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as router from 'react-router-dom'
import { useHistory } from 'utils/useHistory';
import { useTypedSelector } from 'app/rootReducer';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import { HeaderButton } from 'pages/Search/planSearch/paymentPlanDetailsHeader/HeaderButton';
import { ReactComponent as Search } from 'assets/svgs/paymentPlanIcons/icon-search.svg';
import { GetPaymentPlanService } from 'services/PaymentPlanService';
import { GetUserDetailById } from 'services/UsersService';
import { EnumPlanStatus } from 'models/enums/EnumPaymentPlan';
import UserPermissions from 'models/enums/UserPermissions';
import { getLogoDataForOrganization } from 'features/admin/logoManager/LogoManagerReducer';
import Logo from 'features/Logo';
import { getUserName } from 'utils/Utils';
import { getTransactionDetails, setOpenTransactionModel, setTransactionCreator } from '../TransactionDetailsReducer';
import Void from '../popups/void/Void';
import Credit from '../popups/refund/Credit';
import Email from '../popups/Email';
import { Print } from '../popups/Print';
import 'assets/styles/components/_transactionDetailsModal.scss';
import EnumTransactionTenderType from 'models/enums/EnumTransactionTenderType';
import EnumTransactionType from 'models/enums/EnumTransactionType';
import { EnumTransactionStatusType } from 'models/enums';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { useEmailInfo, usePlanStatus, useStatusLoading } from './useHeaderState';

export interface EmailModalInfo {
  transactionId?: string,
  type: string
}

export function Header() {
  const dispatch = useDispatch();

  const transaction = useTypedSelector(s => s.transactionDetails.transactionDetailsData?.transactionDetails);
  const transactionId = useTypedSelector(s => s.transactionDetails.transactionId) ?? router.useParams<{ id: string }>().id;

  useEffect(() => {
    transactionId && dispatch(getTransactionDetails(transactionId, true));
  }, [transactionId]);

  useEffect(() => {
    transaction?.organization?.path && dispatch(getLogoDataForOrganization(transaction?.organization?.path));
  }, [dispatch, transaction?.organization?.path]);

  const { getFacilityByDepartment: useGetFacilityByDepartment,  } = useOrganizations();

  const creator = useTypedSelector(s => s.transactionDetails.transactionCreator);
  const logoManager = useTypedSelector(s => s.logoManager);
  const companyLogo = logoManager.logos?.find(logo => logo.isSelected);
  const facility = useGetFacilityByDepartment(transaction?.organization?.path);

  return <>
    <div className="flex-row">
      <div className="text-title">
        <span>PAYMENT INFORMATION</span>
      </div>
      <div className="header-buttons">
        <HeaderButtons />
      </div>
    </div>
    <hr />
    <div className="flex-row transaction-details-header">
      <div className="header-logo">
        <Logo src={companyLogo} />
      </div>
      <div className="flex-row header-title-data">
        <div>
          <span className="font-weight-bold mr-1">Created By:</span>
          <span className="mr-1">{getUserName(creator, true)}</span>
        </div>
        <div>
          <span className="font-weight-bold mr-1">Facility Name:</span>
          <span className="mr-1">{facility?.name}</span>
        </div>
        <div>
          <span className="font-weight-bold mr-1">Department Name:</span>
          <span className="mr-1">{transaction?.department?.name}</span>
        </div>
      </div>
    </div>
  </>
}

const HeaderButtons = () => {
  const transaction = useTypedSelector(s => s.transactionDetails.transactionDetailsData?.transactionDetails);
  const creator = useTypedSelector(s => s.transactionDetails.transactionCreator); 

  const { havePermission } = useLoggedUserPermissions();

  const dispatch = useDispatch();
  const history = useHistory();

  const [emailInfo, setEmailInfo] = useEmailInfo();

  const [statusLoading, setStatusLoading] = useStatusLoading();

  const onClose = () => {
    dispatch(setOpenTransactionModel(false));
  };

  const [loadedPlanStatus, setLoadedPlanStatus] = usePlanStatus();
  const loadPlan = async (planId: string) => {
    setStatusLoading(true)
    const response = await GetPaymentPlanService(planId);
    setLoadedPlanStatus(response?.result?.status)
    setStatusLoading(false)
  };
  let id = transaction?.paymentPlanId;

  const paymentGLPayment = useMemo(() => (
    transaction?.transactionType === EnumTransactionType.Payment ||
    transaction?.transactionType === EnumTransactionType.GLPayment
  ), [transaction?.transactionType]);

  const showVoid = useMemo(() => {

    return paymentGLPayment && 
    (
      transaction?.tenderType === EnumTransactionTenderType.Card ||
      transaction?.tenderType === EnumTransactionTenderType.eCheck
    ) && (
      transaction?.statusType === EnumTransactionStatusType.Submitted ||
      transaction?.statusType === EnumTransactionStatusType.Created ||
      transaction?.statusType === EnumTransactionStatusType.Processing
    );
  }, [transaction?.transactionType, transaction?.tenderType, transaction?.statusType]);

  const showCredit = useMemo(() => {

    const settledTransaction = transaction?.statusType === EnumTransactionStatusType.Settled;

    if (paymentGLPayment) {
      return settledTransaction;
    } else {

      return settledTransaction && (
        transaction?.transactionType === EnumTransactionType.RecurringPayment ||
        transaction?.transactionType === EnumTransactionType.RecurringGLPayment
      ) &&
      loadedPlanStatus === EnumPlanStatus.Completed 
    }
  }, [transaction?.transactionType, transaction?.statusType, loadedPlanStatus]);

  useEffect(() => {
    if (id) {
      loadPlan(id)
    }
    GetTransactionCreator();
  }, [transaction?.createdBy, id]);

  const GetTransactionCreator = async () => {
    if (!transaction?.createdBy)
      return;

    const resp = await GetUserDetailById(transaction?.createdBy.id);
    if (resp.result) {
      dispatch(setTransactionCreator(resp.result));
    }
  };

  let voidDisableHendler: boolean
  if (!statusLoading) {
      voidDisableHendler = !havePermission(UserPermissions.ProcessVoid, transaction?.organization?.path)
  } else {
    voidDisableHendler = statusLoading
  } 
  
  const creditDisableHendler = !havePermission(UserPermissions.ProcessCredit, transaction?.organization?.path)

  const getVoidCreditButton = () => {

    if (showVoid || showCredit) {
      return <>{ showVoid 
          ? <Void 
          disabled={voidDisableHendler} 
          planStatus={loadedPlanStatus} 
          transaction={transaction} 
          transactionCreator={creator} 
          setIsEmailPopupOpened={setEmailInfo} 
        />
        : <Credit 
          disabled={creditDisableHendler} 
          transaction={transaction} 
          transactionCreator={creator} 
          setIsEmailPopupOpened={setEmailInfo} 
        />}
      </>
    }
    return null;
  }

  return <>
    <HeaderButton
      title="New Search"
      icon={<Search />}
      disabled={false}
      onClick={() => { onClose(); history.push('/searchpayments') }} />
    <Email open={setEmailInfo} info={emailInfo} />
    { getVoidCreditButton() }
    <Print />
  </>
}