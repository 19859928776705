import { useDispatch } from 'react-redux';
import { setAlert, AlertIds, AlertTypes } from 'features/alert/AlertReducer';

export function usePahAlert() {
  const dispatch = useDispatch();

  function getErrorMessage() {
    return dispatch(setAlert({
      id: AlertIds.PAHResponse, type: AlertTypes.Error, message: 'PAH service is temporarily down. Unable to retrieve PAH records.', dismissable: false 
    }))
  }

  return { getErrorMessage };
}