import { callHttpApi } from './callHttpApi';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import {
  RefundTransaction,
  ReverseTransaction,
  ProcessTransactionResponse,
} from 'models/Transaction';
import { Generate } from 'models/HostedPaymentPage';
import { Row } from 'pages/Search/SearchSummaryDetails';
import { PaymentConfiguration } from '../models/PaymentsConfiguration';
import { VoidTransactionModel } from '../models/VoidTransactionModel';
import { ClientFacilityConfiguration } from '../models/Client';

export interface GetTransactionDetailsResult {
  result?: TransactionDetailsModel;
  err?: string;
}

export interface RefundTransactionResult {
  result?: ProcessTransactionResponse;
  err?: string;
}

export interface GenerateHostedPaymentPageResponse {
  hpp: string;
  v: string;
}

export interface GenerateHppResponse {
  generateHostedPaymentPageResponse: GenerateHostedPaymentPageResponse;
  remoteId: string;
}

export interface GenerateResponse {
  result?: GenerateHppResponse;
  err?: string;
}

export interface SearchSummaryData {
  mapSearchSummaryColumn1: Row[];
  mapSearchSummaryColumn2: Row[];
  mapSearchSummaryColumn3: Row[];
  mapSearchSummaryColumn4: Row[];
  mapSearchSummaryColumn5: Row[];
  mapSearchCriteriaColumn: Map<string, string>;
}

export interface SearchSummaryApiResult {
  result: SearchSummaryData;
  err?: string;
}
export interface AdjustResponseType {
  result: {
    GatewayIdentifier: string;
    V: string;
  };
  err: string;
}

export async function getTransactionService(
  transactionId: string
): Promise<GetTransactionDetailsResult> {
  return callHttpApi<TransactionDetailsModel>(
    'get',
    `api/v1/Transactions/${transactionId}/ping`
  );
}

export async function reverseTransactionService(
  data: ReverseTransaction
): Promise<RefundTransactionResult> {
  return callHttpApi<ProcessTransactionResponse, ReverseTransaction>(
    'post',
    `api/v1/Transactions/${data.Id}/reversal`,
    data
  );
}

export async function refundTransactionService(
  transactionId: string,
  data: RefundTransaction
): Promise<RefundTransactionResult> {
  return callHttpApi<ProcessTransactionResponse, RefundTransaction>(
    'post',
    `api/v1/Transactions/${transactionId}/refund`,
    data
  );
}

export async function generateHostedPaymentPageService(
  data: Generate
): Promise<GenerateResponse> {
  return callHttpApi<GenerateHppResponse, Generate>(
    'post',
    `api/v1/Transactions/hpp`,
    data
  );
}

export async function updatePaymentConfiguration(
  organizationPath: string,
  data: PaymentConfiguration
) {

  return callHttpApi<PaymentConfiguration, PaymentConfiguration>(
    'put',
    `api/v1/Transactions/Configuration/${organizationPath}`,
    data
  );
}

export async function getPaymentConfiguration(organizationPath: string, inherited: boolean) {
  return callHttpApi<{ paymentConfiguration: PaymentConfiguration }>(
    'get',
    `api/v1/Transactions/Configuration/${organizationPath}?inherited=${inherited}`
  );
}

export async function voidTransaction(data: VoidTransactionModel) {
  return callHttpApi<TransactionDetailsModel, VoidTransactionModel>(
    'post',
    `api/v1/Transactions/${data.id}/void`,
    data
  );
}

export async function sendEmailVoidTransaction(id: string, data: string[]) {
  return callHttpApi<{ status: number }, string[]>(
    'post',
    `api/v1/Transactions/${id}/email/void`,
    data
  );
}

export async function sendEmailTransactionDetails(id: string, data: string[]) {
  return callHttpApi<{ status: number }, string[]>(
    'post',
    `api/v1/Transactions/${id}/senddetailsemail`,
    data
  );
}

export async function sendEmailCreditTransaction(id: string, data: string[]) {
  return callHttpApi<{ status: number }, string[]>(
    'post',
    `api/v1/Transactions/${id}/email/credit`,
    data
  );
}

export async function getPaymentConfigurations(organizationPaths: string[]) {
  return callHttpApi<PaymentConfiguration[], string[]>(
    'post',
    '/api/v1/Transactions/Configuration/byorganizationpaths',
    organizationPaths
  );
}


export async function initializeEpicDevice(organizationPath: string) {
  return callHttpApi<ClientFacilityConfiguration>(
    'put',
    `/api/v1/Transactions/initializeepicdevice/${organizationPath}`,
  )
}