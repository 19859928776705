import { useRef } from "react";
import { getId } from "../../../../pages/Admin/Epic/EpicDepartmentMappingPage";
import { Metadata } from "../whitelist/WhitelistReducer";

export function useGenericRefStore<T extends { metadata?: Metadata }>() {
  const dataRef = useRef<T[]>([]);
  function storeMapping(
    value: T,
    id: string,
  ) {
    const idx = dataRef.current.findIndex((v) => getId(v) === id);
    if (idx === -1) {
      dataRef.current.push(value);
      return;
    }
    dataRef.current[idx] = {
      ...value,
      metadata: {
        ...value.metadata,
        idx
      }
    };
  }

  return {
    storeMapping,
    dataRef
  }

}