import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  mapTypesForSearchResults,
  mapCardBrandsForSearchResults,
  mapPaymentStatusToMultiSelectOptions,
  cardBrands,
  getTodaysDate,
  getYesterday,
} from 'utils/Utils';
import { MultiSelectOption } from '../../../components/select/MultiSelect';
import { DepartmentMultiselectOption, UserMultiSelectOption } from '../simpleSearch/OrganizationUserSearch';
import { tenderTypes, planStatusOptions } from './FilterData';

const yesterday = getYesterday();
const today = getTodaysDate();

export interface PaymentPlanSearchInformation {
  user?: string;
  userId?: string[];

  patientId?: string;
  mrn?: string;
  patientMrn?:string;
  patientGuarantorAccountNo?: string;
  patientDateOfBirth?: string;
  patientFirstName?: string;
  patientLastName?: string;
  patientAccountNumber?: string;
  accountNumber?: string;
  payerFirstName?: string;
  payerLastName?: string;
  guarantorId?: string;
  guarantorAccountNo?: string;

  createdDateMin?: string;
  createdDateMax?: string;
  nextPaymentDateMin?: string;
  nextPaymentDateMax?: string;
  amountMin?: number;
  amountMax?: number;

  id?: string;
  legacyId?: string;
  status?: MultiSelectOption[];
  transactionStatus?: string;
  transactionId?: string;
  tenderMaskedAccount?: string;
  tenderLastFourDigits?: string;

  organizationId?: string[];
  organizationPaths?: string[];
  departmentPaths?: string[];
  tenderType?: TreeFilterItem[];
  cardBrand?: TreeFilterItem[];

  facility?: string[];
  users: UserMultiSelectOption[];
  departments: DepartmentMultiselectOption[];
  facilities: MultiSelectOption[];
  resetSelection?: boolean;
}

export interface TreeFilterItem {
  label: string;
  isChecked: boolean;
  propertyName: string;
  parent?: string;
}

export interface FilterItem {
  displayName?: string;
  value?: any;
  displayValue?: any;
}

export interface State {
  value: PaymentPlanSearchInformation;
  saveSettings?: boolean;
  filters: {
    [key: string]: FilterItem;
  };
}

export const initialState: State = {
  value: {
    tenderType: [...mapTypesForSearchResults(tenderTypes), ...mapCardBrandsForSearchResults(cardBrands, 'Credit/Debit')],
    createdDateMin: yesterday,
    createdDateMax: today,
    users: [],
    departments: [],
    facilities: [],
    status : mapPaymentStatusToMultiSelectOptions(planStatusOptions),
    resetSelection: false,
  },
  saveSettings: false,
  filters: {},
};

const reducerSlice = createSlice({
  name: 'PaymentPlanSearch',
  initialState,
  reducers: {
    updateRecord(
      state,
      action: PayloadAction<PaymentPlanSearchInformation>
    ) {
      state.value = action.payload;
    },
    updateSaveSettings(state, action: PayloadAction<{[key: string]: boolean}>) {
      state.saveSettings = action.payload.saveSettings;
    },
    addFilter(
      state,
      action: PayloadAction<{
        name: string;
        filter: FilterItem;
      }>
    ) {
      const { name, filter } = action.payload;
      state.filters[name] = filter;
    },
    removeFilter(state, action: PayloadAction<string>) {
      const filterName = action.payload;
      delete state.filters[filterName];
      (state.value as any)[filterName] = (initialState.value as any)[
        filterName
      ];
    },
    clearFilters(state) {
      state.value = initialState.value;
      state.filters = initialState.filters;
    },
  },
});

export const {
  updateRecord,
  updateSaveSettings,
  addFilter,
  removeFilter,
  clearFilters,
} = reducerSlice.actions;

export default reducerSlice.reducer;
