import React, { useState } from 'react';
import {
  CheckBoxTree,
  CheckBoxNode,
} from 'components/checkBoxTree/CheckBoxTree';
import { useChangeHandlers } from '../usePaymentPlanSearchState';

export function useCheckBoxTree(
  group: string,
  checkBoxTree: CheckBoxTree,
  groupDisplayName?: string,
  setCheckboxTree?: (tree: CheckBoxTree) => any
) {
  const { handleChangeCheckBoxGroup } = useChangeHandlers();
  let [checkBoxTreeState, setCheckBoxTreeState] = useState(checkBoxTree);
  checkBoxTreeState = setCheckboxTree ? checkBoxTree : checkBoxTreeState;

  const handleCheckBoxTreeClear = () => {
    checkBoxTreeState.nodes.forEach(node => {
      handleChangeCheckBoxGroup(
        group,
        node.propertyName,
        false,
        groupDisplayName,
        node.label
      );
      if (node.children) {
        node.children.forEach(node =>
          handleChangeCheckBoxGroup(
            group,
            node.propertyName,
            false,
            groupDisplayName,
            node.label
          )
        );
      }

      let updatedTreeState = { ...checkBoxTreeState };
      updatedTreeState.nodes = [...updatedTreeState.nodes].map(parentNode => ({
        ...parentNode,
        isChecked: false,
        children: parentNode.children
          ? [...parentNode.children].map(childNode => ({
              ...childNode,
              isChecked: false,
            }))
          : undefined,
      }));

      setCheckboxTree ? setCheckboxTree(updatedTreeState) : setCheckBoxTreeState(updatedTreeState);
    });
  };

  const handleCheckBoxTreeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checkBoxNode: CheckBoxNode
  ) => {
    handleChangeCheckBoxGroup(
      group,
      checkBoxNode.propertyName,
      e.target.checked,
      groupDisplayName,
      checkBoxNode.label
    );
    if (checkBoxNode.children) {
      checkBoxNode.children.forEach(node =>
        handleChangeCheckBoxGroup(
          group,
          node.propertyName,
          e.target.checked,
          groupDisplayName,
          node.label
        )
      );
    }

    let updatedTreeState = { ...checkBoxTreeState };
    updatedTreeState.nodes = [...updatedTreeState.nodes].map(parentNode => {
      if (![parentNode.propertyName, ...(parentNode.children ?? []).map(child => child.propertyName)]
        .includes(checkBoxNode.propertyName)) {
          return parentNode;
      }

      const children = parentNode.children?.map(childNode => ({
        ...childNode,
        isChecked:
          checkBoxNode.propertyName === childNode.propertyName ||
            checkBoxNode.propertyName === parentNode.propertyName
            ? e.target.checked
            : childNode.isChecked,
      }));

      return {
        ...parentNode,
        isChecked:
          children?.length && children.find(c => c.isChecked) ||
            (checkBoxNode.isChecked && e.target.name !== parentNode.propertyName)
            ? true
            : e.target.checked,
        children
      }
    });

    setCheckboxTree ? setCheckboxTree(updatedTreeState) : setCheckBoxTreeState(updatedTreeState);
  };

  return {
    checkBoxTreeState,
    handleCheckBoxTreeChange,
    handleCheckBoxTreeClear,
  };
}
