import { Input } from '@EHDS/core';
import { PatientInfoModel } from 'features/patientInfo/PatientInfoReducer';
import { PaymentPanelFieldType } from 'models/enums/EnumPaymentPanels';
import { ChangeEvent } from 'react';
import { EDS_Select, EDS_Checkbox} from '@EH/eh.eds.react';
import { Label } from 'reactstrap';
import { GenericAttribute } from 'models/metaData/MetaData';

export function DisplayDynamicFields(elements: JSX.Element[]){
  let items = [];

  for (let i = 0; i < elements.length; i += 2) {
    if (i < elements.length) {
      items.push(getPairDiv(elements[i], elements[i + 1]));
    } else {
      items.push(elements[i]);
    }
  }
  return items;
}

export function addCustomFields(a: GenericAttribute<PatientInfoModel>,
  changeHandler: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)  => void,
  stateValue:PatientInfoModel
  ){
  switch (a.fieldType) {
    case PaymentPanelFieldType.TextInput:
      return (GetInputElement(a.name, "Please enter a value for "+ a.name, stateValue, changeHandler, a.label))
    case PaymentPanelFieldType.DropDown:
      return(GetDropDownElement(a.name, [], stateValue, changeHandler, a.label))
    case PaymentPanelFieldType.Label:
      return (GetLabelElement(a.label))
    case PaymentPanelFieldType.CheckBox:
      return (GetCheckboxElement(a.name, false, stateValue, changeHandler, a.label))
    default:
      return (<></>)
   }
}

function GetInputElement(name: keyof PatientInfoModel, placeholder:string, 
  stateValue:PatientInfoModel,
  changeHandler: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)  => void,
  label?:string, 
): JSX.Element{
  return (<Input
    label={label}
    name={name}
    placeholder={placeholder}
    className={'rowItem eds4 custom-field'}
    onChange={changeHandler}
    value={stateValue[name]?.toString() ?? ''}
  />)
}

function GetCheckboxElement(name: keyof PatientInfoModel, disabled:boolean, 
  stateValue:PatientInfoModel,
  changeHandler: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)  => void,
  label?:string): JSX.Element{
  return (
    <EDS_Checkbox
    disabled={disabled}
    name={name}
    checked={true}
    label={label}
    onChange={changeHandler}
    value={Boolean(stateValue[name]) || false}
  />
  )
}

function GetDropDownElement(name:keyof PatientInfoModel,options:[], 
  stateValue:PatientInfoModel,
  changeHandler: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)  => void,
  label?:string): JSX.Element{
  return (<EDS_Select
        name={name}
        label={label}
        modifiers={'row-item row-item-size fullRowLenght custom-field'}
        options={options}
        onChange={changeHandler}
        value={stateValue[name]?.toString() ?? ''}
      /> )
}

function GetLabelElement(label?:string): JSX.Element{
  return (<Label>{label}</Label>)
}

function getPairDiv(item1:JSX.Element, item2:JSX.Element): any {
  return (<div className="flexRow">{item1} {item2}</div>)
}
