import { Checkbox, TabbedContent } from "@EHDS/core";
import { useDispatch } from "react-redux";
import { AddressFieldsConfiguration, AddressSettings, PaymentConfiguration } from "../../../models/PaymentsConfiguration";
import { setConfiguration } from "../../paymentDashboard/PaymentDashboardReducer";
import { EDS_Switch } from '@EH/eh.eds.react';
import { useState } from "react";

export function DataRequiredForPayments(props: {
  configuration: PaymentConfiguration,
  organizationPath: string,
  openTab: number,
  setOpenTab: (index: number) => void,
}) {
  const { configuration, organizationPath, openTab, setOpenTab } = props;
  const [isRequired, setIsRequired] = useState(configuration?.mailingAddressRequired);
  const [isTabRefreshing, setIsTabRefreshing] = useState(false)
  const dispatch = useDispatch();

  const getTab = (specificProps: RequiredAddressFieldsProps, label: string) => {
    return {
      content: <RequiredAddressFields
        {...specificProps}
        configuration={configuration}
        organizationPath={organizationPath}
        onValueChange={() => {
          setIsTabRefreshing(true);
          window.setTimeout(() => { setIsTabRefreshing(false) }, 0)
        }
        }
      />,
      label} }

  const tabs = [
    getTab({
      isCityEditable: false,
      isPhoneNumberEditable: false,
      isStateEditable: false,
      isStreetAddressEditable: false,
      configurationKey: "creditCardAddressSettings",
    },
      'Credit Cards/eCN all tenders'),
    getTab({ configurationKey: "moneyOrderAddressSettings" }, 'Paper Checks/Money Orders'),
    getTab({ configurationKey: "eCheckAddressSettings" }, 'E-Checks'),
    getTab({ configurationKey: "cashAddressSettings" }, 'Cash'),
  ];

  return (
    <div className="client-controls">
      <div className="headerToggle">
        <div className="eds-heading eds-heading.mdplus-caps mb-1 dataRequired-header">Billing Data Required For Payments</div>
        <div>
          <span className="mr-2">Payer Mailing Address Required</span>
            <EDS_Switch
              name="ismailingAddressRequired"
              id="ismailingAddressRequired"
              onChange={(e:any) => {
                setIsRequired(e.target.checked);
                dispatch(
                  setConfiguration({
                    organizationPath,
                    configuration: {
                      ...configuration,
                      mailingAddressRequired : e.target.checked
                    }
                  })
                )
              }}
              checked={isRequired}
            />
            <br/>
            <span className={"eCN-requirement-label"}>*always visible on eCN</span>
          </div>
      </div>
      {isTabRefreshing || !configuration ? null : <TabbedContent
        tabs={tabs}
        initialTab={openTab}
        onTabClick={(index) => { setOpenTab(index) }}
        className={"required-data-tabs"}
      />}
      
    </div> );
}

export interface RequiredAddressFieldsProps {
  isCityEditable?: boolean,
  isStreetAddressEditable?: boolean,
  isStateEditable?: boolean,
  isPhoneNumberEditable?: boolean,
  organizationPath?: string,
  configuration?: PaymentConfiguration,
  configurationKey: keyof AddressFieldsConfiguration,
  onValueChange?: () => void
}

export function RequiredAddressFields(props: RequiredAddressFieldsProps) {
  const { configuration, configurationKey, organizationPath, isCityEditable = true, isStreetAddressEditable = true, isStateEditable = true, isPhoneNumberEditable = true, onValueChange } = props;
  const dispatch = useDispatch();

  const requiredDataConfiguration = configuration?.[configurationKey];

  function updateConfigurationData(field: keyof AddressSettings, value: boolean) {
    if (organizationPath) {
      onValueChange && onValueChange();
      dispatch(
        setConfiguration({
          organizationPath,
          configuration: {
            ...configuration,
            [configurationKey]: {
              ...field === "allAddressFieldsRequired" ?
                getUpdatedConfigurationForAllAddressFields(value, configurationKey) :
                getUpdatedConfiguration(field, value, requiredDataConfiguration)
            }
          }
        })
      )
    }
  }

  return <div className={"fields-form"}>
    <RequiredAddressField
      label={"All Address Fields:"}
      editable={true}
      onChange={(value: boolean) => { updateConfigurationData("allAddressFieldsRequired", value) }}
      className={"extra-large"}
      value={!!requiredDataConfiguration?.["allAddressFieldsRequired"]}
    />
    <RequiredAddressField
      label={"City:"}
      editable={isCityEditable}
      onChange={(value: boolean) => { updateConfigurationData("cityRequired", value) }}
      value={!!requiredDataConfiguration?.["cityRequired"]}
    />
    <RequiredAddressField
      label={"Zip Code:"}
      editable={true}
      onChange={(value: boolean) => { updateConfigurationData("zipRequired", value) }}
      className={"middle-large"}
      value={!!requiredDataConfiguration?.["zipRequired"]}
    />
    <RequiredAddressField
      label={"Street Address:"}
      editable={isStreetAddressEditable}
      onChange={(value: boolean) => { updateConfigurationData("streetAddressRequired", value) }}
      className={"extra-large"}
      value={!!requiredDataConfiguration?.["streetAddressRequired"]}
    />
    <RequiredAddressField
      label={"State:"}
      editable={isStateEditable}
      onChange={(value: boolean) => { updateConfigurationData("stateRequired", value) }}
      value={!!requiredDataConfiguration?.["stateRequired"]}
    />
    <RequiredAddressField
      label={"Phone #"}
      editable={isPhoneNumberEditable}
      onChange={(value: boolean) => { updateConfigurationData("phoneNumberRequired", value) }}
      value={!!requiredDataConfiguration?.["phoneNumberRequired"]}
    />
  </div>
}

export function RequiredAddressField(props: {
  label: string,
  editable?: boolean,
  value?: boolean,
  onChange: (value: boolean) => void,
  className?: string
}) {
  const { label, editable, value, onChange, className } = props;
  return <div className={className}>
    <span className={"fieldname-label"}>{label}</span>
    <span className={"checkbox-input"}>
      {editable ?
        <Checkbox label={"Required"} checked={!!value} onChange={(e) => { onChange(e.target.checked) }} /> :
        <span className={"checkbox-substitution-label"}>Required</span>
      }
    </span>
  </div>
}

export function getUpdatedConfiguration(field: keyof AddressSettings, value: boolean, requiredDataConfiguration?: AddressSettings) {
  const newConfiguarion = {
    ...requiredDataConfiguration,
    [field]: value
  };
  return {
    ...newConfiguarion,
    allAddressFieldsRequired: !(Object.keys(newConfiguarion) as (keyof AddressSettings)[]).find(key => key !== "allAddressFieldsRequired" && !newConfiguarion[key])
  }
}

export function getUpdatedConfigurationForAllAddressFields(value: boolean, configurationKey: keyof AddressFieldsConfiguration) {
  const valueIfFieldHasToBeAlwaysRequired = configurationKey === "creditCardAddressSettings" ? true : value;
  return {
    allAddressFieldsRequired: value,
    streetAddressRequired: valueIfFieldHasToBeAlwaysRequired,
    cityRequired: valueIfFieldHasToBeAlwaysRequired,
    stateRequired: valueIfFieldHasToBeAlwaysRequired,
    zipRequired: value,
    phoneNumberRequired: valueIfFieldHasToBeAlwaysRequired,
  }
}