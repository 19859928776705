import { useDispatch } from "react-redux";
import { UserToken, createToken, updateToken } from "../../../../services/TokenGUIDLookupService";
import { AlertIds, AlertTypes, setAlert } from "../../../alert/AlertReducer";

export const tokenAlert = {
  id: AlertIds.ClientConfiguration,
  type: AlertTypes.Error,
  message: '',
};

export default function useSaveToken(onSuccess: (updated: UserToken) => void) {
  const dispatch = useDispatch();
  async function saveToken(data: UserToken) {
    const service = data.id ? updateToken : createToken;
    const response = await service(data);
    if (response.result) {
      onSuccess(response.result);
      dispatch(
        setAlert({
          ...tokenAlert,
          type: AlertTypes.Success,
          message: `Token saved.`,
          dismissable: true,
        })
      );
    } else {
      dispatch(
        setAlert({
          ...tokenAlert,
          message: `Error Token not saved. ${response.errorResponse?.data?.detail ?? ""}`,
        })
      );
    }
  }
  return { saveToken };
}