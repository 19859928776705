import { Accordion, Button, Radio } from '@EHDS/core';
import { useEffect, useState } from 'react';
import { PermissionItem } from '../../../models/Permission';
import { UserDetailModel } from '../../../models/UserInfoAndRolesModel';
import AddOrRemovePermissions, {
  UserInfo,
} from './ConfirmationPopups/AddOrRemovePermissions';
import PermissionsList from './PermissionsList';
import usePermissionsState, { usePermissionsMenuMode } from './UsersTab/hooks/usePermissionsState';
import UserPermissions from 'models/enums/UserPermissions';
import { UserMultiSelectOption } from 'pages/Search/simpleSearch/OrganizationUserSearch';

enum permissionsMenuModes {
  add,
  remove,
  view,
}

export default function PermissionsMenu(props: {
  userPermissions?: string[];
  selectedItems: Set<string | undefined>;
  handleChange: (id?: string) => void;
  clearSelection: () => void;
  onSubmit: (
    mode: permissionsMenuModes,
    permissions: Set<string | undefined>
  ) => Promise<boolean>;
  noUser?: boolean;
  user?: UserDetailModel;
  userInfo?: UserInfo;
  selectedUsers?: UserMultiSelectOption[];
}) {
  const {
    userPermissions,
    selectedItems,
    handleChange,
    clearSelection,
    onSubmit,
    noUser,
    user,
    userInfo,
    selectedUsers
  } = props;
  const {
    paymentPermissions,
    paymentPlansPermissions,
    generalLegderPermissions,
    pSafeAdminPermissions,
    epicPermissions,
  } = usePermissionsState();

  const [mode, setMode] = usePermissionsMenuMode()

  const filterPermissions = getPermissionsFilters(
    noUser ? permissionsMenuModes.add : mode
  );

  const [noUserSelected, setNoUserSelected] = useState(true);

  useEffect(() => {
    setNoUserSelected(selectedUsers?.length === 0);
  }, [selectedUsers]);

  const readOnly = mode == permissionsMenuModes.view;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clearAllSelection, setclearAllSelection] = useState(false);

  return (
    <div>
      <h2 className="links-title">Permissions</h2>
      <div className="radio-group">
        {noUser ? null : (
          <Radio
            label="View"
            checked={mode === permissionsMenuModes.view}
            onChange={() => {
              setMode(permissionsMenuModes.view);
            }}
          />
        )}
        <Radio
          label="Add"
          checked={mode === permissionsMenuModes.add}
          onChange={() => {
            setMode(permissionsMenuModes.add);
            clearSelection();
          }}
        />
        <Radio
          label="Remove"
          checked={mode === permissionsMenuModes.remove}
          onChange={() => {
            setMode(permissionsMenuModes.remove);
            clearSelection();
          }}
        />
      </div>
      <div className="permissions-list">
        <Accordion
          accordionItems={[
            {
              content: GetPermissionList(
                filterPermissions,
                paymentPermissions,
                userPermissions,
                selectedItems,
                handleChange,
                readOnly,
                mode,
                clearAllSelection
              ),
              summary: 'Payments',
            },
            {
              content: GetPermissionList(
                filterPermissions,
                paymentPlansPermissions,
                userPermissions,
                selectedItems,
                handleChange,
                readOnly,
                mode,
                clearAllSelection
              ),
              summary: 'Payment Plans',
            },
            {
              content: GetPermissionList(
                filterPermissions,
                generalLegderPermissions,
                userPermissions,
                selectedItems,
                handleChange,
                readOnly,
                mode,
                clearAllSelection
              ),
              summary: 'General Ledger',
            },
            {
              content: GetPermissionList(
                filterPermissions,
                pSafeAdminPermissions,
                userPermissions,
                selectedItems,
                handleChange,
                readOnly,
                mode,
                clearAllSelection
              ),
              summary: 'Admin',
            },
            {
              content: GetPermissionList(
                filterPermissions,
                epicPermissions,
                userPermissions,
                selectedItems,
                handleChange,
                readOnly,
                mode,
                clearAllSelection
              ),
              summary: 'Epic',
            },
          ]}
        />
      </div>
      {readOnly ? null : (
        <div className="buttons">
          <Button
            onClick={() => {
              clearSelection();
              setclearAllSelection(true);
            }}
            disabled={!selectedItems.size}
          >
            Clear Selection
          </Button>
          <Button
            type="primary"
            onClick={() => setIsModalOpen(true)}
            disabled={noUserSelected}
          >
            {mode === permissionsMenuModes.add ? 'Add' : 'Remove'} Permissions
          </Button>
        </div>
      )}
      {isModalOpen ? (
        <AddOrRemovePermissions
          mode={mode}
          selectedPermissions={
            Array.from(selectedItems).filter(p => !!p) as string[]
          }
          close={() => {
            setIsModalOpen(false);
          }}
          onNext={async () => {
            setIsModalOpen(false);
            return await onSubmit(mode, selectedItems);
          }}
          userInfo={{
            user: `${user?.firstName} ${user?.lastName}`,
            ...userInfo,
          }}
          isUserTab={!noUser}
        />
      ) : null}
    </div>
  );
}

export function GetPermissionList(
  filterPermissions: (
    permissions: PermissionItem[],
    userPermissions: string[]
  ) => PermissionItem[],
  selectedPermissions: PermissionItem[] | undefined,
  userPermissions: string[] | undefined,
  selectedItems: Set<string | undefined>,
  handleChange: (id?: string) => void,
  readOnly: boolean,
  mode?: permissionsMenuModes,
  clearAllSelection?: boolean
) {

  const permissions = filterPermissions(
    selectedPermissions ?? [],
    userPermissions ?? []
  );
  const [isAllSelected, setIsAllSelected] = useState(false);

  const toggleSelectAll = () => {
    if (isAllSelected) {
      selectedItems.clear();
    } else {
      
      permissions.forEach(item => selectedItems.add(item.id));
    }
    setIsAllSelected(!isAllSelected);
  };

  useEffect(() => {
    if(selectedItems && selectedItems.size > 0) {
      selectedItems.clear();
      setIsAllSelected(false);
    } else if(selectedItems.size === 0) {
      setIsAllSelected(false);
    }
  }, [mode]);
  
  useEffect(() => {
    if(clearAllSelection) {
      setIsAllSelected(false);
    }
  }, [selectedItems]);

  return (
    <PermissionsList
      permissions={filterPermissions(
        selectedPermissions ?? [],
        userPermissions ?? []
      )}
      selectedItems={selectedItems}
      handleChange={handleChange}
      toggleSelectAll={toggleSelectAll}
      readOnly={readOnly}
      isAllSelected={isAllSelected}
    />
  );
}

export function getPermissionsFilters(mode: permissionsMenuModes) {
  return {
    [permissionsMenuModes.add]: (
      permissions: PermissionItem[],
      userPermissions: string[]
    ) => {
      return permissions.filter(p => p.id && !userPermissions.includes(p.id));
    },
    [permissionsMenuModes.remove]: (
      permissions: PermissionItem[],
      userPermissions: string[]
    ) => {
      return permissions.filter(p => p.id && userPermissions.includes(p.id));
    },
    [permissionsMenuModes.view]: (
      permissions: PermissionItem[],
      userPermissions: string[]
    ) => {
      const isAdmin = userPermissions.includes(UserPermissions.FinancialAdministation.toString());
      const selectedUserPermissioms = [...userPermissions];
      if(isAdmin && !selectedUserPermissioms.includes(UserPermissions.SelfServicePortal.toString())) {
        selectedUserPermissioms.push(UserPermissions.SelfServicePortal.toString());
      }
      return permissions.filter(p => p.id && selectedUserPermissioms.includes(p.id));
    },
  }[mode];
}
