import React, { useState, useEffect, useCallback, useRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { ReactComponent as Calendar } from 'assets/svgs/icon-calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { convertStringToDate } from 'utils/UtilsDateTime';
import styles from './dateRangeStyles.module.css';

type TypeDateRangeValue = [string | undefined, string | undefined] | undefined;

export type TypeChangeHandler = (date: Date | null, name?: string) => void;

function convertStringToDateRange(
  dates: TypeDateRangeValue,
  index: number
): Date | null {
  const dateString = dates && dates[index];
  return (dateString && convertStringToDate(dateString)) || null;
}

export function DateRange(props: {
  nameFrom?: string;
  nameTo?: string;
  label?: string;
  labelFirstField?: string,
  labelSecondField?: string,
  value?: TypeDateRangeValue;
  minDateFrom?: Date;
  minDateTo?: Date;
  maxDateFrom?: Date;
  maxDateTo?: Date;
  showTime?: boolean;
  showIcon?: boolean;
  showError?: boolean;
  className?: string;
  onChangeFrom?: (date: string | undefined, name?: string) => void;
  onChangeTo?: (date: string | undefined, name?: string) => void;
}) {
  const {
    nameFrom,
    nameTo,
    label,
    labelFirstField,
    labelSecondField,
    value,
    minDateFrom,
    minDateTo,
    maxDateFrom,
    maxDateTo,
    showTime,
    showIcon,
    showError,
    className,
    onChangeFrom,
    onChangeTo,
  } = props;

  const [dates, setDates] = useState<TypeDateRangeValue>(value);

  useEffect(() => {
    setDates(value);
  }, [value]);

  const formatDateToString = (date: Date | null) => moment(date).isValid()
    ? moment(date).format('YYYY-MM-DDTHH:mm:ss.000')
    : '';

  const handleChangeFrom = useCallback(
    (date: Date | null, e: React.SyntheticEvent<any> | undefined) => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }

      const dateString = formatDateToString(date);

      setDates([dateString, dates && dates[1]]);

      if (onChangeFrom) {
        onChangeFrom(dateString, nameFrom);
      }
    },
    [dates, nameFrom, onChangeFrom]
  );

  const handleChangeTo = useCallback(
    (date: Date | null, e: React.SyntheticEvent<any> | undefined) => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }

      const dateString = formatDateToString(date);

      setDates([dates && dates[0], dateString]);

      if (onChangeTo) {
        onChangeTo(dateString, nameTo);
      }
    },
    [dates, nameTo, onChangeTo]
  );

  const startDate = convertStringToDateRange(dates, 0) || null;
  const endDate = convertStringToDateRange(dates, 1);

  const error = (startDate && endDate && startDate > endDate) || showError;

  const errorColor = error ? '#E91313' : '';
  const backgroundErrorColor = error ? '#FFD6DD' : '';
  
  const dateFromPickerRef = useRef() as any;
  const dateToPickerRef= useRef() as any;

  const handleClickDateFromPickerIcon=()=>{ 
    dateFromPickerRef.current.setOpen(true);
  }
  const handleClickDateToPickerIcon=()=>{
    dateToPickerRef.current.setOpen(true);
  }
  return (
    <div className={className ?? ''}>
      <label
        style={{ width: '100%' }}
        className={`eds-field`}
        htmlFor={nameFrom}
        onClick={e => e.preventDefault()}
      >
        {!labelFirstField && !labelSecondField 
        ? <div className={`eds-field_#label`}>{label}</div> 
        : <div className="row">
          <div className="col-sm-6">
            <div className={`eds-field_#label`}>{labelFirstField}</div>
          </div>
          <div className="col-sm-6">
            <div className={`eds-field_#label`}>{labelSecondField}</div>
          </div>
        </div> }       
        <div className="row">
          <div className="col-sm-6">
            <div
              className={`eds-field_#control eds-field_.eds-input eds-input`}
              style={{
                borderColor: errorColor,
                backgroundColor: backgroundErrorColor,
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={handleChangeFrom}
                selectsStart
                showTimeSelect={showTime}
                startDate={startDate}
                endDate={endDate}
                isClearable={!showIcon}
                placeholderText='Date from'
                popperClassName={styles.dateRangePopper}
                className='eds-input_#input'
                maxDate={maxDateFrom}
                minDate={minDateFrom}
                shouldCloseOnSelect={true}
                closeOnScroll={true}
                name={nameFrom}
                dateFormat={showTime ? 'MM/d/yyyy h:mm aa' : 'MM/d/yyyy'}
                autoComplete='off'
                ref={dateFromPickerRef} 
              />
            </div>
            {showIcon && <span className={styles.calendarIcon} onClick = {()=>handleClickDateFromPickerIcon()}><Calendar /></span>}
          </div>
          <div className="col-sm-6">
            <div
              className={`eds-field_#control eds-field_.eds-input eds-input ${styles.inputWrapper}`}
              style={{
                borderColor: errorColor,
                backgroundColor: backgroundErrorColor,
              }}
            >
              <DatePicker
                selected={endDate}
                onChange={handleChangeTo}
                selectsEnd
                showTimeSelect={showTime}
                startDate={startDate}
                endDate={endDate}
                isClearable={!showIcon}
                placeholderText="Date to"
                popperClassName={styles.dateRangePopper}
                className="eds-input_#input"
                maxDate={maxDateTo}
                minDate={minDateTo}
                shouldCloseOnSelect={true}
                closeOnScroll={true}
                name={nameTo}
                dateFormat={showTime ? 'MM/d/yyyy h:mm aa' : 'MM/d/yyyy'}
                autoComplete='off'
                ref={dateToPickerRef} 
              />
            </div>
            {showIcon && <span className={styles.calendarIcon} onClick = {()=>handleClickDateToPickerIcon()}><Calendar /></span>}
          </div>
        </div>
      </label>
    </div>
  );
}

export default DateRange;
