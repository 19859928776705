import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as router from 'react-router-dom';
import moment from 'moment';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useTypedSelector } from 'app/rootReducer';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { GridConfig } from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { planToIncorporateLineItemInTransactionResult } from 'features/paymentPlan/maps/PlanToIncorporateLineItemInTransactionResult';
import { PaymentModel } from 'models';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import { GetTransactionsRequest } from 'models/Transaction';
import {
  getTransactionsSearchService,
  getTransactionsSearchSummaryService,
} from 'services/PaymentService';
import {
  CreateExcelFullReportService,
  DownloadExcelFullReportService,
  ReportType,
} from 'services/transactions/ExportTransactionService';
import { cardBrands, checkReceivedHasValues, getValueOrDefault, convertDateToUtc, filterEmptyFields } from 'utils/Utils';
import { formatDateFrom, formatDateTo, messages } from '../planSearch/PaymentPlansSearchResult';
import { transactionTypes, tenderTypes,paymentStatusOptions } from '../advanceSearch/FilterData';
import {
  SearchResultsTemplate,
  DataType,
  SearchMode,
  SummaryColumn,
} from '../SearchResultsTemplate/SearchResultsTemplate';
import {
  getMapValueFormatter,
  renderPopup,
  mapParams,
  renderCardBrand,
  getOrganizationName,
  getOrganizationsCount,
  getUserName,
  defaultFilterParams,
  renameKeys,
  mapTransactionFilterNames,
  getAgGridColumnFilter,
  getFiltersData,
} from '../SearchResultsTemplate/SearchResultUtils';
import { useCreateReport } from '../SearchResultsTemplate/useCreateReport';
import { SHOW_RESULTS_LIMIT } from '../SearchResultsTemplate/Header';
import { appendTimeStampMax, appendTimeStampMin, getSearchCriteriaBySearchId, setRetrievedUserSettings } from '../searchUtils';
import { data as columnsData } from './gridConfig';
import { setTransactionRecords } from './SimpleSearchReducer';
import '@experian/eds-styles/dist/eds-all.css';
import 'assets/styles/components/_searchresults.scss';
import { setCall } from 'services/ServicesReducer';
import { AlertIds, AlertTypes, removeAlert, setAlert } from 'features/alert/AlertReducer';
import { toolTipMessages } from '../advanceSearch/MainFilters/SearchByTransactionDetails';
import { getPaymentAmount, getBalanceAmount } from 'pages/Search/simpleSearch/gridConfig';
import { ReactComponent as ProgressIcon } from 'assets/svgs/progresswheel-icon.svg';
import EnumTransactionTenderType from 'models/enums/EnumTransactionTenderType';

export interface Filters {
  filter:any;
  filterTo?: any;
  dateFrom?: any;
  dateTo?: any;
  filterType: string;
  type: string;
}

export const applyFilters = (filters: any, appliedFilters?: Filters[]) => {
  if (appliedFilters) {
    let filterString: string = "";
    for (const key in appliedFilters) {
      const keyType = appliedFilters[key].type.toLowerCase();
      const keyFilter = appliedFilters[key].filter;
      const keyFilterTo = appliedFilters[key].filterTo;
      const dateFrom = appliedFilters[key].dateFrom;
      const dateTo = appliedFilters[key].dateTo;

      filterString += getFiltersData(key, keyType, keyFilter, filterString, keyFilterTo, dateFrom, dateTo); 
    }
    filters["AppliedFilters"] = filterString;
  }
  return filters;
}

const SimpleSearchResultsPage = () => {
  let { search } = router.useLocation();
  let searchQueryParams = new URLSearchParams(search);
  const searchId = searchQueryParams.get('searchId') ?? '';
  const searchCriteria = getSearchCriteriaBySearchId(searchId);

  const dispatch = useDispatch();
  const organizations = useTypedSelector(p => p.organizations?.value);
  const users = useTypedSelector(p => p.userInfo?.users || []);
  const customColumnsState = useTypedSelector(
    c => c.customSearchColumns?.transactions.value
  ) as { [key: string]: any };
  const pageSize = useTypedSelector(c => c.simpleSearchInfo?.pageSize);
  const isSimpleSearchActive = !getQueryParam('advancedSearch');

  const [loading, setLoading] = useState(false);
  const [noDataFound, setnoDataFound] = useState(false);
  const [serviceError, setError] = useState<string | boolean>(false);
  const [showNotesPopup, setShowNotesPopup] = useState('');
  const [summaryLoading, setSummaryLoading] = useState<boolean>(false);
  const [showCustomizeSearchColumns, setShowCustomizeSearchColumns] = useState(
    false
  );
  const [filter, setFilter] = useState();
  const { createReport } = useCreateReport();
  const { getFacilityByDepartment } = useOrganizations();
  const alerts = useTypedSelector(s => s.alerts?.alerts);
  const downloadAlert = alerts?.find(
    alert => alert.id === AlertIds.DownloadExcelFullReport
  );

  const [chosenColumns, setChosenColumns] = useState<GridConfig[]>([]);
    
  useEffect(() => {
    setRetrievedUserSettings(`transactionColumnOrder`, setChosenColumns, columnsData);
    dispatch(
      setBreadcrumbLinks([
        {
          name: `${
            isSimpleSearchActive
            ? 'Basic Search / Results Summary'
            : 'Advanced Search / Results Summary'
          }`,
          slug: 'simpleSearchResults',
        },
      ])
    );
  }, []);

  function getQueryParam(key: keyof GetTransactionsRequest): any {
    return searchCriteria[key] ?? undefined;
  }

  function getQueryParamAsCsv(key: keyof GetTransactionsRequest): string {
    return getQueryParamArray(key).join(',');
  }
  
  function getQueryParamArray(key: keyof GetTransactionsRequest): any[] {
    const value = searchCriteria[key];
  
    if (!value) {
      return [];
    } else if (Array.isArray(value)) {
      return value;
    } else {
      return [value];
    }
  }
  
  const headerData: DataType[] = [
    ...[
      {
        field: 'Facility',
        value: getOrganizationName(OrganizationLevelTypes.Facility, organizations, getQueryParamArray('organizationPaths')),
        type: checkReceivedHasValues(getOrganizationsCount(OrganizationLevelTypes.Facility, organizations, getQueryParamArray('organizationPaths')) > SHOW_RESULTS_LIMIT, 'popup' , ''),
    },
      {
        field: 'Department',
        value: getOrganizationName(OrganizationLevelTypes.Department, organizations, getQueryParamArray('departmentPaths')),
        type: checkReceivedHasValues(getOrganizationsCount(OrganizationLevelTypes.Department, organizations, getQueryParamArray('departmentPaths')) > SHOW_RESULTS_LIMIT, 'popup' , ''),
    },
      {
        field: 'User',
        value: getUserName(users, getQueryParamArray('userId')),
        type: checkReceivedHasValues(getQueryParamArray('userId').length > SHOW_RESULTS_LIMIT, 'popup', ''),
    },
      { field: 'MRN', value: getQueryParam('mrn') },
      { field: 'Account Number', value: getQueryParam('accountNumber') },
    ],
    ...(checkReceivedHasValues(isSimpleSearchActive
      , [
        { field: 'Transaction ID', value: getQueryParam('id') },
        { field: 'Submitted Date From', value: getQueryParam('submittedDateTimeMin') },
        { field: 'Submitted Date To', value: getQueryParam('submittedDateTimeMax') },
      ] , [])),
    ...(checkReceivedHasValues(!isSimpleSearchActive
      , [
        { field: 'Patient First Name', value: getQueryParam('patientFirstName') },
        { field: 'Patient Last Name', value: getQueryParam('patientLastName') },
        { field: 'Payer First Name', value: getQueryParam('payerFirstName') },
        { field: 'Payer Last Name', value: getQueryParam('payerLastName') },
        { field: 'Guarantor ID', value: getQueryParam('guarantorId') },
        {
          field: 'Submitted Date Range',
          value: `${getValueOrDefault(formatDateFrom(getQueryParam('submittedDateTimeMin')), '')} 
          ${getValueOrDefault(formatDateTo(getQueryParam('submittedDateTimeMax')), '')}`.trim(),
        },
        {
          field: 'Settled Date Range',
          value: `${formatDateFrom(getQueryParam('settledDateTimeMin')) ||
            ''} ${formatDateTo(getQueryParam('settledDateTimeMax')) ||
            ''}`.trim(),
        },
        {
          field: 'Payment Amount Range',
          value: `${getQueryParam('amountMin') || ''}
          ${getQueryParam('amountMin') && getQueryParam('amountMax') ? ' - ' : ''}
          ${getQueryParam('amountMax') || ''}`.trim(),
        },
        {
          field: 'Discount Amount Range',
          value: `${getQueryParam('discountMin') || ''}
          ${
            getQueryParam('discountMin') && getQueryParam('discountMax')
              ? ' - '
              : ''
          }
          ${getQueryParam('discountMax') || ''}`.trim(),
        },
        { field: 'Transaction ID', value: getQueryParam('id') },
        { field: 'Gateway Reference Number', value: getQueryParam('transactionReferenceId') },
        { field: 'Payment Plan ID', value: getQueryParam('paymentPlanId') },
        { field: 'Device Name', value: getQueryParam('deviceName') },
        { field: 'Device Serial Number', value: getQueryParam('deviceSerialNumber') },
        { field: 'Batch ID', value: getQueryParam('batchId') },
        { 
          field: 'Payment Status', 
          value: paymentStatusOptions.map((value) => {return value.value}).
                filter(type=>getQueryParamArray('paymentStatus').includes(type.replace(/ /g, '').toLowerCase())).join(', ') || 'All' },
        { field: 'Method Ending In', value: getQueryParam('tenderLastFourDigits') },
        { field: 'Authorization Code', value: getQueryParam('authorizationCode') },
        { field: 'GL Description', value: getQueryParam('glDescription') },
        {
          field: 'Transaction Types',
          value: transactionTypes
            .filter(type => getQueryParamArray('transactionTypes').includes(type.replace(/ /g, '').toLowerCase()))
            .join(', ') || transactionTypes.join(', ')
        },
        {
          field: 'Tender Type',
          value: tenderTypes.filter(type =>
            getQueryParamArray('tenderTypes').includes(type.replace(/ /g, '').toLowerCase()) ||
            (type === 'Credit/Debit' && getQueryParamArray('cardBrand') && getQueryParamArray('cardBrand').length > 0))
            .join(', ') || tenderTypes.join(', ')
        },
        {
          field: 'Card Brands',
          value: cardBrands.filter(card => getQueryParamArray('cardBrand').includes(card.propertyName)).map(c => c.label).join(', ')
        },
      ], [])),
  ];

  const mapCellRenderer: {[key: string] : (params: any) => HTMLAnchorElement | HTMLDivElement | any; } = {
    popup: params => renderPopup({ ...params, setShowNotesPopup }),
    cardBrand: params => renderCardBrand(params),
  };
  const closeNotesPopup = () => setShowNotesPopup('');

  const getToolTipMessage = (field: string) => {
    if (field === 'id') {
      return toolTipMessages.transaction;
    } else if (field === 'gatewayReferenceNumber') {
      return toolTipMessages.gatewayReferenceNumber;
    } else if (field === 'authorizationCode') {
      return toolTipMessages.authorizationCode;
    }
    return '';
  };
  const headerDefinitions = chosenColumns && chosenColumns?.map((row: GridConfig) => ({
    headerName: row.label,
    ...row.getValue
      ? { valueGetter: (params: { data: PaymentModel }) => row.getValue && row.getValue(params.data, { getFacilityByDepartment }), }
      : { field: row.field },
    hide: !customColumnsState[row.field],
    filter: getAgGridColumnFilter(row),
    filterParams: row.filterParams
      ? { ...defaultFilterParams, ...mapParams[row.filterParams] }
      : defaultFilterParams,
    valueFormatter: row.valueFormatter && getMapValueFormatter(true)[row.valueFormatter],
    cellRenderer: row.cellRenderer && (mapCellRenderer[row.cellRenderer] ?? row.cellRenderer),
    colId: row.colId ?? row.field,
    sortable: row.sortable,
    headerTooltip: getToolTipMessage(row.field),
    pinned: row.pinned,
    width: row.width,
    resizable: row.resizable ?? true,
    suppressMovable: row.suppressMovable
  }));

  const transformPaymentQueryParamsToObject = () => {
    const props = {
      organizationPaths: getQueryParamArray('organizationPaths'),
      departmentPaths: getQueryParamArray('departmentPaths'),
      userId: getQueryParamArray('userId'),
      id: getQueryParam('id'),
      mrn: getQueryParam('mrn'),
      patientFirstName: getQueryParam('patientFirstName'),
      patientLastName: getQueryParam('patientLastName'),
      payerFirstName: getQueryParam('payerFirstName'),
      payerLastName: getQueryParam('payerLastName'),
      patientId: getQueryParam('patientId'),
      guarantorId: getQueryParam('guarantorId'),
      submittedDateTimeMin: getQueryParam('submittedDateTimeMin'),
      submittedDateTimeMax: getQueryParam('submittedDateTimeMax'),
      settledDateTimeMin: getQueryParam('settledDateTimeMin'),
      settledDateTimeMax: getQueryParam('settledDateTimeMax'),
      amountMin: Number(getQueryParam('amountMin')) || undefined,
      amountMax: Number(getQueryParam('amountMax')) || undefined,
      discountMin: Number(getQueryParam('discountMin')) || undefined,
      discountMax: Number(getQueryParam('discountMax')) || undefined,
      accountNumber: getQueryParam('accountNumber'),
      batchId: Number(getQueryParam('batchId')) || undefined,
      paymentStatus: getQueryParamAsCsv('paymentStatus'),
      resultsStatus: getQueryParam('resultsStatus'),
      tenderLastFourDigits: getQueryParam('tenderLastFourDigits'),
      authorizationCode: getQueryParam('authorizationCode'),
      glDescription: getQueryParamArray('glDescription'),
      transactionTypes: getQueryParamAsCsv('transactionTypes'),
      tenderTypes: getQueryParamAsCsv('tenderTypes'),
      cardEntryTypes: getQueryParamAsCsv('cardEntryTypes'),
      cardBrand: getQueryParamArray('cardBrand'),
      transactionReferenceId: getQueryParam('transactionReferenceId'),
      gatewayReferenceNumber: getQueryParam('gatewayReferenceNumber'),
      paymentPlanId: getQueryParam('paymentPlanId'),
      deviceName: getQueryParam('deviceName'),
      deviceSerialNumber: getQueryParam('deviceSerialNumber'),
      billingId: getQueryParam('billingId'),
    }
    return props;
  }

  const removeZFromDate = (dateStr: string) => {
    return dateStr.replace('Z', '');
  };

  const convertDatesToUtc = (data: any) => {
    if (data.submittedDateTimeMin && data.submittedDateTimeMax) {
      data.submittedDateTimeMin = convertDateToUtc(removeZFromDate(data.submittedDateTimeMin));
      data.submittedDateTimeMax = convertDateToUtc(removeZFromDate(data.submittedDateTimeMax));
    }
    
    if (data.settledDateTimeMin && data.settledDateTimeMax) {
      data.settledDateTimeMin = convertDateToUtc(removeZFromDate(data.settledDateTimeMin));
      data.settledDateTimeMax = convertDateToUtc(removeZFromDate(data.settledDateTimeMax));
    }
    return data;
  }

  const getSearchTransactionData = useCallback(async () => {
    try {
      setLoading(true);
      appendTimeStampMin(searchCriteria, 'settledDateTimeMin');
      appendTimeStampMax(searchCriteria, 'settledDateTimeMax');
      
      let payload = {
        ...transformPaymentQueryParamsToObject(),
        limit: pageSize,
      };
      payload = convertDatesToUtc(payload);
      const data = filterEmptyFields(payload);

      const response = await getTransactionsSearchService(data);
      const searchRecords = planToIncorporateLineItemInTransactionResult(
        response.result?.records
      );
      const results = searchRecords.map(payment => ({ ...payment, amount: getPaymentAmount(payment), balance: getBalanceAmount(payment) }));
      dispatch(setTransactionRecords(results));
      setError(false);
      if (response.errorResponse?.data?.detail) {
        setError(response.errorResponse.data.detail);
      } else if (response.err) {
        setError(response.err);
      }

      setLoading(false);
      searchRecords?.length > 0
        ? setnoDataFound(false)
        : setnoDataFound(true);

      return response;
    } catch (e) {
      console.log(e);
    }
  }, [search, isSimpleSearchActive]);

  const groupSummaryData = (rawData: any) => {
    const data: SummaryColumn[][] = [
      filterSummaryData([
        { field: 'Transaction Summary', value: { count: 'Count', total: 'Total' }, format: 'title' },
        { field: 'Payment', value: rawData.paymentTransactionsSummary },
        { field: 'Void', value: rawData.voidTransactionsSummary },
        { field: 'Credit', value: rawData.creditTransactionsSummary },
        { field: 'Recurring Payment', value: rawData.recurringPaymentTransactionsSummary },
        { field: 'A/R Adjustments', value: rawData.systemUpdateServiceTransactionsSummary },
        { field: 'GL Payment', value: rawData.glPaymentTransactionsSummary },
        { field: 'GL Void', value: rawData.glVoidTransactionsSummary },
        { field: 'GL Credit', value: rawData.glCreditTransactionsSummary },
        { field: 'GL Recurring Payment', value: rawData.glRecurringPaymentTransactionsSummary },
        { field: 'Capture', value: rawData.captureTransactionsSummary },
        { field: 'Other', value: rawData.otherTransactionsSummary },
        { field: 'Total', value: rawData.transactionTypeSummaryTotal, format: 'total' },
      ]),
      filterSummaryData([
        { field: 'Tender Type Summary', value: { count: 'Count', total: 'Total' }, format: 'title' },
        { field: 'Credit Card', value: rawData.cardTransactionsSummary },
        { field: 'eCheck', value: rawData.eCheckTransactionsSummary },
        { field: 'Cash', value: rawData.cashTransactionsSummary },
        { field: 'Paper Check', value: rawData.paperCheckTransactionsSummary },
        { field: 'Money Order', value: rawData.moneyOrderTransactionsSummary },
        { field: 'Not Set', value: rawData.notSetTransactionsSummary },
        { field: 'Card Device', value: rawData.cardDeviceTransactionsSummary },
        { field: 'Other', value: rawData.otherTenderTypeTransactionsSummary },
        { field: 'Total', value: rawData.tenderTypeSummaryTotal, format: 'total' },
      ]),
      filterSummaryData([
        { field: 'Credit Card Summary', value: { count: 'Count', total: 'Total' }, format: 'title' },
        { field: 'American Express', value: rawData.amexTransactionsSummary },
        { field: 'Discover', value: rawData.discoverTransactionsSummary },
        { field: 'MasterCard', value: rawData.masterCardTransactionsSummary },
        { field: 'Visa', value: rawData.visaTransactionsSummary },
        { field: 'Other', value: rawData.otherCardsTransactionsSummary },
        { field: 'Total', value: rawData.cardBrandsSummaryTotal, format: 'total' },
      ]),
      filterSummaryData([
        { field: 'Credit Card Entry Type Summary', value: { count: 'Count', total: 'Total' }, format: 'title' },
        { field: 'Device', value: rawData.deviceTransactionsSummary },
        { field: 'Manual Entry (CNP)', value: rawData.manualEntryTransactionsSummary },
        { field: 'Other', value: rawData.otherEntryTypeTransactionsSummary },
        { field: 'Total', value: rawData.entryTypeSummaryTotal, format: 'total' },
      ]),
      filterSummaryData([
        { field: 'Discount Summary', value: { count: 'Count', total: 'Total' }, format: 'title' },
        { field: 'Payment', value: rawData.discountPaymentTransactionSummary },
        { field: 'GL Payment', value: rawData.discountGlPaymentTransactionSummary },
        { field: 'Total', value: rawData.discountTransactionSummaryTotal, format: 'total' },
      ]),
      filterSummaryData([
        { field: 'Payment Source Summary', value: { count: 'Count', total: 'Total' }, format: 'title' },
        { field: 'ECareNext', value: rawData.eCareNextTransactionSummary },
        { field: 'EPIC', value: rawData.epicTransactionSummary },
        { field: 'EpicHostedPaymentPage', value: rawData.epicHostedPaymentPageTransactionSummary },
        { field: 'ExternalTransaction', value: rawData.externalTransactionTransactionSummary },
        { field: 'FlatFileImportService', value: rawData.flatFileImportTransactionSummary },
        { field: 'IVR', value: rawData.ivrTransactionSummary },
        { field: 'Kiosk', value: rawData.kioskTransactionSummary },
        { field: 'PaymentSafe', value: rawData.paymentSafeTransactionSummary },
        { field: 'PatientFinancialNavigator', value: rawData.patientFinancialNavigatorTransactionSummary },
        { field: 'PatientSimple', value: rawData.patientSimpleTransactionSummary },
        { field: 'PatientSimple QuickPay', value: rawData.patientSimpleQuickPayTransactionSummary },
        { field: 'RegistrationAccelerator', value: rawData.registrationAcceleratorTransactionSummary },
        { field: 'OneSource', value: rawData.oneSourceTransactionSummary },
        { field: 'TextToPay', value: rawData.textToPayTransactionSummary },
        { field: 'Semafone', value: rawData.semafoneTransactionSummary },
        { field: 'Other', value: rawData.otherPaymentSourceTransactionSummary },
        { field: 'Total', value: rawData.paymentSourceSummaryTotal, format: 'total' },
      ]),
    ]
    return data;
  }

  const filterSummaryData = (summaryFields: SummaryColumn[]) => {
    const headerList = ['Transaction Summary', 'Tender Type Summary', 'Credit Card Summary', 'Credit Card Entry Type Summary', 
    'Payment Source Summary', 'Credit Card Entry Type Summary', 'Discount Summary', 'Total' ];
    return summaryFields.filter(row => Number(row.value?.count) > 0 || headerList.includes(row.field));
  }

  const updateSummary = (filter: any) => {
    setFilter(filter);
  }

  const getPaymentsSummaryService = useCallback(async () => {
    setSummaryLoading(true);
    try {
      appendTimeStampMin(searchCriteria, 'settledDateTimeMin');
      appendTimeStampMax(searchCriteria, 'settledDateTimeMax');

      const mappedKeysFilter = renameKeys(filter, mapTransactionFilterNames);
      let payload = { ...transformPaymentQueryParamsToObject(), filter: mappedKeysFilter };
      if(!payload.tenderTypes.includes(EnumTransactionTenderType.NotSet.toLocaleLowerCase()) && payload.tenderTypes.length > 0)
        payload.tenderTypes = payload.tenderTypes.concat(',' + EnumTransactionTenderType.NotSet.toLocaleLowerCase());
      payload = convertDatesToUtc(payload);
      
      const response = await getTransactionsSearchSummaryService(payload);
      setSummaryLoading(false);
      const grouped = response.result && groupSummaryData(response.result);

      return grouped || response;
    } catch (e) {
      console.log(e);
    }
  }, [search, filter]);

  const resetFiltersSummary = () => {
    setFilter(undefined);
  }

  function handleCustomColums() {
    setShowCustomizeSearchColumns(!showCustomizeSearchColumns);
  }
  
  const handleCreateReportResult = async (response: any) => {
    const report = await createReport(response.result); 

    try {
      const fileTooBig = report && report.contentSize > report.maxLimitSize;
      if (fileTooBig) {
        dispatch(setAlert({ 
          id: AlertIds.FileIsTooBig, 
          type: AlertTypes.Error, 
          message: messages.fileTooBig, 
          dismissable: false 
        }));
      } else {
        await DownloadExcelFullReportService(report.downloadLink, ReportType.payments, moment().format('MM-DD-YY'));
      }
    } catch (err) {
      dispatch(setAlert({ 
        id: AlertIds.GlPaymentAlert, 
        type: AlertTypes.Error, 
        message: 
        report.err 
      }));
    }
  }

  const isDownloading = useTypedSelector(s=> s.services.calls.DownloadExcelFullTransactionsReport?.isProcessing)
  const downloadExcelFull = async (sortModel: { colId: string, sort: any }[], appliedFilters?: Filters[]) => {
    if (isDownloading) return
    dispatch(setCall({ DownloadExcelFullTransactionsReport: { isProcessing: true } }));
    dispatch(setAlert({ id: AlertIds.DownloadExcelFullReport, type: AlertTypes.Info, message: "Your request is being processed. It will start downloading automatically when ready. Please do not exit the page while the export is in progress", dismissable: false  }))
    
    let filters: any = {};
    headerData.forEach(row => Object.assign(filters, row.field && row.value && {[row.field]: row.value}));
    appendTimeStampMin(searchCriteria, 'settledDateTimeMin');
    appendTimeStampMax(searchCriteria, 'settledDateTimeMax');

    filters = applyFilters(filters, appliedFilters);

    let payload = {
      ...transformPaymentQueryParamsToObject(),
      transactionExport: {
        transactionExportType: 'Excel',
        transactionExportDisplayFilter: filters,
      },
      sort: Object.assign({}, ...sortModel.map((x: any) => ({ [x.colId]: x.sort })))
    };
    payload = convertDatesToUtc(payload);

    const response = await CreateExcelFullReportService(payload);
    if (response?.result) {
      await handleCreateReportResult(response);
    }
    dispatch(setCall({ DownloadExcelFullTransactionsReport: { isProcessing: false } }));
    dispatch(removeAlert({id: AlertIds.DownloadExcelFullReport}))
  };

  const mapReport: { [key: string]: any } = {
    excelFull: downloadExcelFull,
  };

  const downloadReport = (type: string, sort: { colId: string, sort: any }[], filters?: Filters[]) => mapReport[type](sort, filters);

  const renderNotePopup = () => {
    return (
      <div className="notes-popup">
        <button
          className="button-close material-icons"
          onClick={closeNotesPopup}
        >
          close
        </button>
        <div>{showNotesPopup}</div>
      </div>
    );
  };

  function paymentProcessing() {
    if (downloadAlert) {
      return (
        <div className={`processingContainer`}>
          <span className={`processing-label paymentProcessing`}>
            <ProgressIcon />
            <span className="processingLabel"> Loading... Please Wait.</span>
          </span>
        </div>
      );
    }
  }

  return (
    <>
      {showNotesPopup && renderNotePopup()}
      {paymentProcessing()}
      {organizations?.length > 0 && (
        <SearchResultsTemplate
          breadcrumbs={{
            name: 'Advanced Search Results',
            slug: 'simplesearchresults',
          }}
          mode={SearchMode.transaction}
          headerData={headerData}
          resultsHeaderDefinitions={headerDefinitions}
          buttons={['clearFilters', 'newSearch', 'customize', 'exportFile', 'saveColumnOrder']}
          onClickCustomize={handleCustomColums}
          showCustomizeColumns={showCustomizeSearchColumns}
          columnsData={columnsData}
          getData={getSearchTransactionData}
          updateSummary={updateSummary}
          getSummaryData={getPaymentsSummaryService}
          resetFiltersSummary={resetFiltersSummary}
          loading={loading}
          summaryLoading={summaryLoading}
          noDataFound={noDataFound}
          error={serviceError}
          downloadReport={downloadReport}
          filterData={filter}
        />
      )}
    </>
  );
};

export default SimpleSearchResultsPage;
