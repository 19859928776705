import { ServerPaymentModel } from 'models/PaymentModel';
import { callHttpApi } from 'services/callHttpApi';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import { CreateTransactionResult } from './models/CreateTransactionResult';
import { maskECheckNumber } from 'utils/Utils';
import { mapCheckAccountType } from 'features/paymentPlan/PlanUtils';
import ECheckTransactionRequest from './models/ECheckTransactionRequest';

export async function createECheckTransactionService(
  payment: ServerPaymentModel
): Promise<CreateTransactionResult> {
    return callHttpApi<
      TransactionDetailsModel,
      ECheckTransactionRequest
    >(
      'post',
      `api/v1/Transactions/eCheck`,
      mapPaymentToProcessTransactionRequest(payment)
    );
}

function mapPaymentToProcessTransactionRequest(
  payment: ServerPaymentModel
): ECheckTransactionRequest {
    return {
      PatientAccountNumber: payment.patientAccountNumber,
      TenderMaskedAccount: maskECheckNumber(payment.typeECheck?.accountNumber),
      Amount: payment.paymentTotal,
      BillingInformation: payment.billingInformation,
      MailingInformation: payment.mailingInformation,
      CreatedDate: mapCreatedDate(payment),
      Discount: payment.discount,
      ECheckModel: {
        CheckAccountType: mapCheckAccountType(payment.typeECheck?.accountType),
        AccountNumber: payment.typeECheck?.accountNumber,
        RoutingNumber: payment.typeECheck?.routingNumber,
        NameOnCheck: `${payment.billingInformation?.firstName} ${payment.billingInformation?.lastName}`,
      },
      Organization: payment.organization,
      Department: payment.department,
      Facility: payment.facility,
      LineItems: payment.isGL ? payment.glDetails : payment.details,
      GuarantorId: payment.guarantorId,
      isGL: payment.isGL,
      Notes: payment.notes,
      MRN: payment.mrn,
      PatientId: payment.patientId,
      PatientFirstName: payment.patientFirstName,
      PatientLastName: payment.patientLastName,
      PatientDateOfBirth: payment.patientDateOfBirth,
      PaymentSource: payment.paymentSource,
      isPaymentMethodReusable: payment.isReusable,
      NotificationEmail: payment.notificationEmail,
      TenderAdditionalData: {
        RoutingNumber: maskECheckNumber(payment.typeECheck?.routingNumber) ?? '',
        FinancialInstitution: payment.typeECheck?.financialInstitution ?? '',
        AccountType: mapCheckAccountType(payment.typeECheck?.accountType),
      },
      paymentPlanId: payment.paymentPlanId,
      totalPlanBalance: payment.totalPlanBalance,
      TransactionGroupID: payment.transactionGroupId,
    };
}

function mapCreatedDate(payment: ServerPaymentModel): string | undefined {
  return payment.details && payment.details.length > 0
    ? payment.details[0].dateOfService
    : undefined;
}
