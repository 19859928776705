import { useEffect, useMemo, useState, useRef, Children, ReactNode } from 'react';
import { Box, Checkbox, LinearProgress, Paper, PaperProps, Popper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { EDS_Checkbox } from '@EH/eh.eds.react';
import '@experian/eds-styles/dist/eds-all.css';
import 'assets/styles/components/_multiselect.scss';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { Value, createFilterOptions } from '@material-ui/lab/useAutocomplete';

export interface MultiSelectOptionGeneric<T> {
  label: string;
  value: T;
  className?: string;
}

export interface MultiSelectOption extends MultiSelectOptionGeneric<string> { }
export interface MultiSelectOptionNone extends MultiSelectOptionGeneric<string | undefined> { }

const getPopperComponent = (popperProps: any, remit?: boolean) => {
  if (remit) {
    return <Popper {...popperProps} style={{ width: "fit-content" }} placement="bottom-start" />;
  }

  return <Popper {...popperProps} placement="bottom-start" />;
};

const isElement = (child: React.ReactNode): child is React.ReactElement =>
  (child as React.ReactElement)?.props !== undefined;

const isLeaf = (child: React.ReactNode): child is React.ReactText =>
  typeof child === 'string' || typeof child === 'number';

function extractText(children: React.ReactNode): React.ReactText[] {
  return Children.toArray(children).reduce<React.ReactText[]>(
    (previous, child) => {
      if (isElement(child)) {
        return [...previous, ...extractText(child.props.children)];
      }

      if (isLeaf(child)) {
        return [...previous, child];
      }

      return previous;
    },
    []
  );
}

const renderLoading = (children: ReactNode, inputValue: string) => {
  return extractText(children)[0] === 'No options' && !inputValue && (
    <div className="customCheckbox">
        <Box sx={{ width: '30%' }} style={{ float: 'left' }}>
          LOADING...
        </Box>
        <Box sx={{ width: '70%', marginTop: '12px' }}>
          <LinearProgress />
        </Box>
      </div>
    )
}

export default function MultiSelect<T, O extends MultiSelectOptionGeneric<V>, Multiple extends boolean, V>(props: {
  label: string,
  name?: keyof T,
  options: O[],
  onChange: (selected: Value<O, Multiple, false, false>, propertyName: keyof T) => void,
  values: Value<O, Multiple, false, false>,
  groupBy?: (option: O) => string,
  multiple: Multiple,
  disabled?: boolean,
  disableCloseOnSelect?: boolean
  wider?: boolean;
  searchCheckbox? :boolean;
  selectAll? :any;
  allSelected?: boolean;
  button?: string;
  remit?: boolean;
  getOptionDisabled?: (option: O) => boolean,
  placeholder?: string;
  disableClearable?: boolean,
  isSortingOptions?: boolean,
}) {
  const {
    label,
    options,
    onChange,
    values,
    name,
    groupBy,
    multiple,
    disabled,
    disableCloseOnSelect = true,
    wider,
    searchCheckbox,
    selectAll,
    allSelected,
    button,
    remit,
    getOptionDisabled,
    placeholder,
    disableClearable = true,
    isSortingOptions = false
  } = props;

  const [inputValue, setInputValue] = useState<string>('');

  const inputValueStore = (value: string) => {
    setInputValue(value);
  };

  useEffect(() => {
    if (!Array.isArray(values)) {
      setInputValue(values?.label ?? '');
    }
  }, [values]);

  const PopperComponent = useMemo(() => function (popperProps: any) {
    return getPopperComponent(popperProps, remit);
  }, [remit]);

  const selectAllRef = useRef(selectAll);

  useEffect(() => {
    selectAllRef.current = selectAll;
  }, [selectAll]);

  const sortOptions = (options: O[], selectedValues: any) => {
    if(selectedValues === undefined || !Array.isArray(values)) return options;
    const selectedValuesSet = new Set(selectedValues?.map((val: any) => val.value));
  
    const sortedOptions = [...options].sort((a, b) => {
      const aIsSelected = selectedValuesSet.has(a.value);
      const bIsSelected = selectedValuesSet.has(b.value);
  
      if (aIsSelected && !bIsSelected) {
        return -1;
      } else if (!aIsSelected && bIsSelected) {
        return 1;
      }
      return 0;
    });
  
    return sortedOptions;
  };

  const CustomPaper = useMemo(
    () => ({ children, ...paperProps }: PaperProps) => {
      return (
        <Paper elevation={8} {...paperProps}>
          <div
            className="customCheckbox"
            onMouseDown={(e: any) => e.preventDefault()}
          >
            <EDS_Checkbox 
              checked={allSelected}
              label={!allSelected ? button : 'Deselect All'}
              name={'selectAll'}
              onChange={() => selectAllRef.current(name, inputValue)}
            />
          </div>
          {renderLoading(children, inputValue)}
          {children}
        </Paper>
      );
    },
    [allSelected, button, name, inputValue]
  );

  const sortedOptions = isSortingOptions ? sortOptions(options, values) : options;

  const filterOptions = createFilterOptions<O>({
    ignoreCase: true,
    matchFrom: 'any',
    //The limit is the maximum number of options that will be displayed in the dropdown
    //This will cause a huge increase in performance to load the items in the dropdown
    //Select all and manual input search can still be used to select the rest of the items
    limit: 200
  });

  //Please be careful when adding custom components to the Material UI Autocomplete
  //Material UI Autocomplete requires its custom components to not refresh, achievable via useMemo
  return (
    <label className={`eds-field multiselect-label`}>
      <div className={'eds-field_#label'}>{label}</div>
      <Autocomplete
        filterOptions={filterOptions}
        PopperComponent={PopperComponent}
        openOnFocus
        id={'autocomplete'}
        classes={{
          inputRoot:  remit? 'eds-input remitAutocomplete' :'eds-input autocomplete',
          paper: `customAutoComplete ${wider? "widerDropdown" : ""}`
          }}
        onChange={async (e, selected) => {
          if (!Array.isArray(selected)) {
              setInputValue(selected?.label ?? "")
            }
            name && onChange(selected, name);
          }}
          onBlur={() => {
            if(multiple) {
              setInputValue("")
            }}}
        multiple={multiple}
        disableClearable={disableClearable}
        disableCloseOnSelect={disableCloseOnSelect}
        limitTags={1}
        value={values}
        inputValue={inputValue}
        options={sortedOptions}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option: O, value: O) => option.value == value?.value}
        renderOption={(option: O, state) => {
          return <div className={`eds-field ${option.className ?? ''}`} onClick={(e) => {
            e.preventDefault();
              }}>
          <div className={"field-control eds-control-list"} >
            <label className={"control eds-checkbox"} >
              {multiple ? <Checkbox classes={{ root: "checkbox" }} checkedIcon={<CheckOutlinedIcon />}
                checked={!!state.selected} /> : null}
              <span className={"checkbox-label search-option"} >{` ${option.label}`}</span>
                </label>
              </div>
            </div>
        }}
        renderInput={(params) => {
          return <TextField {...params} InputProps={{ ...params.InputProps, disableUnderline: true }} placeholder={placeholder}  onChange={(e)=> inputValueStore(e.target.value)} />
        }}

        renderTags={() => {
          if (Array.isArray(values)) {
            const moreTag = values?.length > 1 ? `and ${values.length - 1} more` : '';
            return <><span className="selected-label">{values[0]?.label}</span><span className={moreTag ? "more-options": ''}>{moreTag}</span></>
          } else {
            return values?.label;
          }
        }}
          groupBy={groupBy}
        disabled={disabled}
        PaperComponent={searchCheckbox ? CustomPaper : undefined}
        getOptionDisabled={getOptionDisabled}
      />
    </label>
  );
}