import React, { useState } from 'react';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { getTransactionInfo } from 'features/admin/TransactionInfoReducer';
import { getUserInfo } from 'features/admin/UserInfoReducer';
import { getPatientInfo } from 'features/admin/PatientAdminInfoReducer';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import { EDS_TextBox, EDS_Button, EDS_Accordion } from '@EH/eh.eds.react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ReactComponent as Help } from 'assets/svgs/paymentPlanIcons/icon-help-outline.svg';
import 'assets/styles/components/_transactionInfo.scss';
import { Placement } from 'react-bootstrap/esm/Overlay';
import Table from 'react-bootstrap/Table';
import { formatMoney, formatDate, getUserName, formatUtcDateString } from 'utils/Utils';
import moment from 'moment';
import { PaymentSearchModel } from 'services/PaymentService';
import { GetTransactionResult } from 'models/GetTransactionResult';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setBreadcrumbLinks([{ name: 'Transaction Info', slug: rootPath }])
    );

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

export default function TransactionInfo(
  props: Props = { rootPath: 'transaction-info' }
) {
  const { rootPath } = props;

  const initValues: { [key: string]: string } = {
    gatewayReferenceNumber: '',
    transactionId: '',
    billingId: ''
  }

  const [ values, setValues ] = useState(initValues);
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  useBreadcrumb(rootPath);

  const isProcessing = useTypedSelector(s => s.services.calls.GetTransactionInfoService?.isProcessing);

  const transactions = useTypedSelector(
    s => s.transactionInfo?.result?.records ?? []
  );

  const userDetail = useTypedSelector(s => s.userInfo?.result);
  const patientDetail = useTypedSelector(s => s.patientAdminInfo?.result);

  const setValuesState = (fieldName: string, value: string) => {
    setValues({ ...initValues, [fieldName]: value.trim() });
  }

  const getEnteredFieldNameValuePair = () => {
    return Object.entries(values).filter(([fieldName, value]) => !!value)[0];
  }

  const getEnteredValue = () => {
    const fieldNameValuePair = getEnteredFieldNameValuePair();
    return fieldNameValuePair && fieldNameValuePair[1];
  }

  const getPopoverContent = (content: string) => (
    <Popover id="popover-basic">
      <Popover.Content>{content}</Popover.Content>
    </Popover>
  );

  const PopoverElement = ({
    content,
    placement,
  }: {
    content: string;
    placement: Placement;
  }) => (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement}
      overlay={getPopoverContent(content)}
    >
      <Help />
    </OverlayTrigger>
  );

  const handleSubmit = () => {
    setSubmitted(false);
    const fieldNameValuePair = getEnteredFieldNameValuePair();

    const [ key, value ] = fieldNameValuePair;
    const fieldNameKeyMap: any = { transactionId: 'id' };
    const fieldName = fieldNameKeyMap[key] ?? key;
    
    const data: PaymentSearchModel = {
      searchAllTransactions: true,
      [fieldName]: value
    };
    dispatch(getTransactionInfo(data));
    setSubmitted(true);
  };

  const getUserDetail = (id?: string) => {
    dispatch(getUserInfo(id));
  };

  const getPatientDetail = (id?: string) => {
    dispatch(getPatientInfo(id));
  };

  const renderTransactionInfo = () => {
    if (isProcessing) {
      return '';
    } else if (submitted) {
      if (transactions.length) {
        return renderAccordionTable();
      } else {
        return <div className="server-error">{'No results found'}</div>;
      }
    }
  };

  const renderDashboardDataInfo = () => {
    return (
      <div className="transaction-info-table-container">
        <Table striped bordered>
          <thead>
            <tr>
              <th>Dashboard Row</th>
              <th>Creation Date</th>
              <th>Created By</th>
              <th>Account Number</th>
              <th>MRN</th>
              <th>Patient Name</th>
              <th>Patient DOB</th>
              <th>Patient Amount</th>
              <th>Pay Plan</th>
              <th>Pay Plan Amount</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(
              (row: GetTransactionResult, index: number) => {
                if (!patientDetail) {
                  getPatientDetail(row.patientId);
                }
                if (!userDetail) {
                  getUserDetail(row.createdBy?.id);
                }
                return (
                  <tr key={`${row.id}-${index}`}>
                    <td>{index}</td>
                    <td>{formatDate(moment.utc(row.createdDate))}</td>
                    <td>{getUserName(userDetail, true)}</td>
                    <td>{row.accountNumber}</td>
                    <td>{row.mrn}</td>
                    <td>{row.patientFirstName + ' ' + row.patientLastName}</td>
                    <td>
                      {formatDate(moment.utc(patientDetail?.dateOfBirth))}
                    </td>
                    <td>{formatMoney(`${row.amount}`)}</td>
                    <td>
                      {row.paymentPlanId === null
                        ? 'Not Set'
                        : row.paymentPlanId}
                    </td>
                    <td>
                      {row.paymentPlanId === null ? 'Not Set' : row.amount}
                    </td>
                    <td>{row.notes}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderOrganizationInfo = () => {
    return (
      <div className="transaction-info-table-container">
        <Table striped bordered>
          <thead>
            <tr>
              <th>Dashboard Row</th>
              <th>Client ID</th>
              <th>Payment Organization</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(
              (row: GetTransactionResult, index: number) => {
                return (
                  <tr key={`${row.id}-${index}`}>
                    <td>{index}</td>
                    <td>{row.organization?.id}</td>
                    <td>{row.organization?.name}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderPayerInfo = () => {
    return (
      <div className="transaction-info-table-container">
        <Table striped bordered>
          <thead>
            <tr>
              <th>Payer Name</th>
              <th>Address</th>
              <th>City</th>
              <th>State</th>
              <th>Postal Code</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(
              (row: GetTransactionResult, index: number) => {
                return (
                  <tr key={`${row.id}-${index}`}>
                    <td>
                      {`${row.billingInformation?.firstName} ${row.billingInformation?.lastName}`}
                    </td>
                    <td>{row.billingInformation?.addressLine1}</td>
                    <td>{row.billingInformation?.city}</td>
                    <td>{row.billingInformation?.state}</td>
                    <td>{row.billingInformation?.zipCode}</td>
                    <td>{row.billingInformation?.phone}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderPaymentPlanInfo = () => {
    return (
      <div className="transaction-info-table-container">
        <Table striped bordered>
          <thead>
            <tr>
              <th>Payment Plan ID</th>
              <th>Profile ID</th>
              <th>Active</th>
              <th>Term</th>
              <th>Payment Amount</th>
              <th>Balance</th>
              <th>Start Date</th>
              <th>Next Pay Date</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(
              (row: GetTransactionResult, index: number) => {
                return (
                  <tr key={`${row.id}-${index}`}>
                    <td>{'Not Set'}</td>
                    <td>{'Not Set'}</td>
                    <td>{'Not Set'}</td>
                    <td>{'Not Set'}</td>
                    <td>{'Not Set'}</td>
                    <td>{'Not Set'}</td>
                    <td>{'Not Set'}</td>
                    <td>{'Not Set'}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderAccordionTable = () => {
    const list = [
      {
        summary: 'Dashboard Data',
        content: renderDashboardDataInfo(),
      },
      {
        summary: 'Organization/Client Information',
        content: renderOrganizationInfo(),
      },
      {
        summary: 'Payer Information',
        content: renderPayerInfo(),
      },
      {
        summary: 'Payment Plan',
        content: renderPaymentPlanInfo(),
      },
    ];
    
    return (
      <div>
        <h4 className="transaction-info-subtitle">Transaction Log</h4>
        <div className="transaction-info-table-container transaction-info-log">
          <Table striped bordered>
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Billing ID</th>
                <th>Provider / Message</th>
                <th>Transaction Type</th>
                <th>Amount</th>
                <th>Tender</th>
                <th>Card Type</th>
                <th>Card Number</th>
                <th>Card Exp Date</th>
                <th>Settled</th>
                <th>Settled Date</th>
                <th>Updated Date</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map(
                (row: GetTransactionResult, index: number) => {
                  return (
                    <tr key={`${row.id}-${index}`}>
                      <td>{row.id}</td>
                      <td>{row.billingId ?? ''}</td>
                      <td>{row.result}</td>
                      <td>{row.transactionType}</td>
                      <td>{formatMoney(`${row.amount}`)}</td>
                      <td>{row.tenderType}</td>
                      <td>{(row.tenderAdditionalData as any)?.CardType ?? ''}</td>
                      <td>{row.tenderMaskedAccount}</td>
                      <td>{(row.tenderAdditionalData as any)?.ExpirationDate ?? ''}</td>
                      <td>{row.settledDate !== null ? 'Y' : 'N'}</td>
                      <td>{formatUtcDateString(row.settledDate)}</td>
                      <td>{formatUtcDateString(row.createdDate)}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        </div>
        <EDS_Accordion accordionItems={list} />
      </div>
    );
  };

  return (
    <>
      <div className="transaction-info">
        <div className="transaction-info-title">
          <h3>Transaction Info</h3>
        </div>

        <div className="transaction-info-controls">
          <div className="transaction-info-control">
            <EDS_TextBox
              modifiers="gatewayReferenceNumber"
              name="gatewayReferenceNumber"
              label="Gateway Reference Number:"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValuesState('gatewayReferenceNumber', e.target.value);
              }}
              value={values.gatewayReferenceNumber}
            ></EDS_TextBox>
            <span className="help">
              <PopoverElement
                placement="top"
                content="Gateway Reference Number Help Text"
              />
            </span>
          </div>

          <div className="transaction-info-control">
            <EDS_TextBox
              modifiers="transactionId"
              name="transactionId"
              label="Transaction ID:"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValuesState('transactionId', e.target.value);
              }}
              value={values.transactionId}
            ></EDS_TextBox>
            <span className="help">
              <PopoverElement
                placement="top"
                content="Transaction ID Help Text"
              />
            </span>
          </div>

          <div className="transaction-info-control">
            <EDS_TextBox
              modifiers="billingId"
              name="billingId"
              label="Billing ID:"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValuesState('billingId', e.target.value);
              }}
              value={values.billingId}
            ></EDS_TextBox>
            <span className="help">
              <PopoverElement
                placement="top"
                content="Billing ID Help Text"
              />
            </span>
          </div>

          <div className="transaction-info-submit">
            <EDS_Button
              modifiers={'eds-button eds-button.basic btn-cancel'}
              buttonText={'Submit'}
              disabled={ !getEnteredValue() || isProcessing }
              onClick={() => handleSubmit()}
            />
            <span className="help">
              <PopoverElement placement="right" content="Submit Help Text" />
            </span>
          </div>
        </div>

        <div className="transaction-info-results">
          {renderTransactionInfo()}
        </div>
      </div>
    </>
  );
}
