import { callHttpApi } from "./callHttpApi";

export interface TransactionLookupData {
  oneSourceUserId?: string;
  tokenUserGUID?: string;
  transactionId?: string;
  facilityId?: number;
  gatewayReferenceNumber?: string;
  paymentSource?: string;
  createdDateMin?: string;
  createdDateMax?: string;
  limit?: number;
  offset?: number;
  sort? : { [key: string]: string }
  filter? : any
}

export interface GetTransactionsApiResult {
  id: string,
  transactionId: string,
  facilityId: number,
  paymentSource: string,
  errorMessage: string,
  paymentOriginationResponse: string,
  paymentOriginationRequest: string,
  errorData: string,
  updatedDate: string,
  gatewayReferenceNumber: string,
  paymentGatewayRequest: string,
  paymentGatewayResponse: string,
  tokenUserGUID: string,
  createdDate: string,
  oneSourceUserId: string
}

export interface GetTransactionsResult {
  total: number;
  limit: number;
  records: GetTransactionsApiResult[];
}

export async function getTransactionsApiLogSearchService(data: TransactionLookupData){
  return callHttpApi<GetTransactionsResult, TransactionLookupData>(
    'post',
    '/api/v1/TransactionApiLog/search',
    data,
  );
}