import { GetTransactionsListModel } from 'models/GetTransactionsListModel';
import { callHttpApi } from './callHttpApi';
import { VoidTransactionResponse } from 'models/VoidTransactionResponse';
import { SearchSummaryResult } from 'models/SearchSummaryResult';

export interface GetTransactionsResult {
  result?: GetTransactionsListModel;
  err?: string;
  errorResponse?: {
    data?: {
      detail?: string
    }
  }
}

export interface PaymentSearchModel {
  id?: string;
  accountNumber?: string;
  amountMin?: number;
  amountMax?: number;
  authorizationCode?: string;
  batchId?: number;
  tenderLastFourDigits?: string;
  cardBrand?: string[];
  gatewayReferenceNumber?: string;
  glTransactionTypes?: string;
  guarantorId?: string;
  discountMin?: number;
  discountMax?: number;
  limit?: number;
  mrn?: string;
  offset?: number;
  patientIds?: string[];
  paymentPlanId?: string;
  deviceName?: string;
  deviceSerialNumber?: string;
  paymentStatus?: string;
  resultsStatus?: string;
  settledDateTimeMin?: string;
  settledDateTimeMax?: string;
  submittedDateTimeMin?: string;
  submittedDateTimeMax?: string;
  patientAccountNumber?: string;
  patientDateOfBirth?: string;
  patientFirstName?: string;
  patientLastName?: string;
  payerFirstName?: string;
  payerLastName?: string;
  tenderTypes?: string;
  transactionTypes?: string;
  cardEntryTypes?: string;
  userId?: string[];
  organizationPaths?: string[];
  notificationEmail?: string;
  cardTypes?: string[];
  glDescription?: string[];
  includeTokenizationTransactions?: boolean;
  departmentId?: string;
  episode?: string;
  facilityId?: string;
  searchAllTransactions ?: boolean;
  transactionReferenceId?: string;
  billingId?: string;
  filter?: {
    id?: {
      filter?: string;
    };
    amount?: {
      filter?: number;
      filterTo?: number;
    };
    authorizationCode?: {
      filter?: string;
    };
    batchId?: {
      filter?: number;
      filterTo?: number;
    };
    methodEndingIn?: {
      filter?: string;
    };
    cardBrand?: {
      filter?: string;
    };
    departmentName?: {
      filter?: string;
    };
    facilityName?: {
      filter?: string;
    };
    gatewayReferenceNumber?: {
      filter?: string;
    };
    transactionType?: {
      filter?: string;
    };
    guarantorId?: {
      filter?: string;
    };
    discount?: {
      dateFrom?: number;
      dateTo?: number;
    };
    mrn?: {
      filter?: string;
    };
    patientId?: {
      filter?: string;
    };
    paymentPlanId?: {
      filter?: string;
    };
    paymentStatus?: {
      filter?: string;
    };
    resultsStatus?: {
      filter?: string;
    };
    settledDate?: {
      dateFrom?: string;
      dateTo?: string;
    };
    submittedDate?: {
      dateFrom?: string;
      dateTo?: string;
    };
    patientAccountNumber?: {
      filter?: string;
    };
    patientDateOfBirth?: {
      filter?: string;
    };
    patientFirstName?: {
      filter?: string;
    };
    patientLastName?: {
      filter?: string;
    };
    payerFirstName?: {
      filter?: string;
    };
    payerLastName?: {
      filter?: string;
    };
    tenderType?: {
      filter?: string;
    };
    createdBy?: {
      filter?: string;
    };
    cardType?: {
      filter?: string;
    };
    glDescription?: {
      filter?: string;
    };
    paymentSource?: {
      filter?: string;
    };
    notes?: {
      filter?: string;
    };
    glNumber?: {
      filter?: string;
    };
    glBillingDepartment?: {
      filter?: string;
    };
  },
  sort? : { [key: string]: string }
}

export interface GetSummaryTransactionsResult {
  result?: SearchSummaryResult[];
  err?: string;
}

export interface VoidTransactionResult {
  result?: VoidTransactionResponse;
  err?: string;
}
export async function getTransactionsSearchService(
  data: PaymentSearchModel
): Promise<GetTransactionsResult> {
  return callHttpApi<GetTransactionsListModel, PaymentSearchModel>(
    'post',
    '/api/v1/Transactions/search',
    data,
  );
}

export async function getTransactionsSearchSummaryService(data: PaymentSearchModel) {
  return callHttpApi<GetSummaryTransactionsResult, PaymentSearchModel>(
    'post',
    '/api/v1/Transactions/summary',
    data,
  );
}