import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ValidatorType } from '../../../models/metaData/MetaDataEnums';
import { getAllValidators, getLabel } from '../../../utils/metadata/MetaDataUtils';
import { Attribute, Validator } from '../../../models/metaData/MetaData';
import { LineItemError } from '../../paymentDashboard/PaymentDashboardReducer';
import { getError } from 'utils/Utils';

export function AutocompleteField(props: {
  attribute: Attribute;
  value: any;
  onChange?: (value: any) => void;
  modifiers?: string;
  error?: boolean | string;
  onBlur?: (value: any) => void;
  setError?: (error: LineItemError) => void;
}) {
  const { attribute, value, onChange, onBlur, modifiers, error } = props;

  const label = getLabel(attribute);
  const required = attribute.required;

  const handleBlur = () => {
    onBlur && onBlur(value);
  }
  
  const options: string[] = [];
  const validators: Validator[] = getAllValidators(attribute, ValidatorType.RangeValidator) ?? [];
  for (const validator of validators.filter(v => v.value)) {
    for (const validatorValue of validator.value) {
      options.push(validatorValue);
    }
  }

  return (
    <div className="required-component">
      <label className={`eds-field ${modifiers}`} htmlFor={attribute.name}>
        <div className={'eds-field_#label'}>{label}</div>
        <Autocomplete
          openOnFocus
          id={'autocomplete'}
          disableClearable
          options={options}
          renderOption={(option: string) => {
            return <div id={`option${options.findIndex(o => o === option)}`}>
                  <span className={"search-option"} >{`${option}`}</span>
            </div>
          }}
          onChange={(_e, newValue) => onChange && onChange(newValue)}
          onInputChange={(_e, newValue) => onChange && onChange(newValue)}
          onBlur={handleBlur}
          value={value}
          classes={{ inputRoot: `${modifiers} ${getError(error, required) ? 'invalid-field' : ''} eds-input autocomplete` }}
          renderInput={(params) => (
            <TextField {...params}/>
          )}
        />
      </label>
      <div className={`${getError(error, required) ? 'error-message-required' : ''}`}>
        {getError(error, required)}
      </div>
    </div>);
}