import { useDispatch } from "react-redux";
import { UserDetailModel } from "../../../../../models/UserInfoAndRolesModel";
import { UpdatePermissions } from "../../../../../services/PermissionsService";
import { AlertIds, AlertTypes, setAlert } from "../../../../alert/AlertReducer";
import { setUser } from "../../../UserInfoReducer";

export default function useUpdatePermissions() {
  const dispatch = useDispatch();

  async function updatePermissions(user: UserDetailModel, organizationPath: string, newPermissions: (string | undefined)[]) {
    const organization = user.organizationLevels?.find(o =>
      o.organizationLevel_Path === organizationPath);

    if (!organization) return false;

    const organizationLevels = [{
      ...organization,
      permissions: newPermissions.filter(p => !!p) as string[]
    }];

    const response = await UpdatePermissions([{
      userId: user.id,
      organizationLevels
    }]);

    if (!response.err) {
      dispatch(setUser({
        ...user,
        organizationLevels
      }));

      dispatch(setAlert({
        id: AlertIds.UpdatePermissions,
        message: "Permissions updated",
        type: AlertTypes.Success,
        dismissable: true
      }))
      return true;
    } else {
      dispatch(setAlert({
        id: AlertIds.UpdatePermissions,
        message: "Could not save permissions",
        type: AlertTypes.Error,
        dismissable: true
      }))
    }
    return false
  }

  return { updatePermissions };
}