import { ProcessRemitFile, RemitConfiguration, RemitConfigurationResponse } from 'models/RemitConfigurationModel';
import { callHttpApi } from './callHttpApi';
import { GetRemitConfigurationsQueryParams } from './RemitService';

export async function GetRemitConfiguration(
  remitConfigurationQuery: GetRemitConfigurationsQueryParams
): Promise<RemitConfigurationResponse> {
  const params = new URLSearchParams(remitConfigurationQuery as any);
  return callHttpApi<RemitConfiguration>(
    'get',
    `/api/v1/RemitConfiguration`,
    undefined,
    params
  );
}

export async function ProcessRemit(remitProcessFile : ProcessRemitFile): Promise<RemitConfigurationResponse> {
  return callHttpApi(
    'post',
    `/api/v1/Remit`,
    remitProcessFile
  );
}

