import { BasicConfig } from "react-awesome-query-builder";
import { RemitDataRowModel } from "../../../../../models/RemitDataRowModel";


export const RowFilterFieldTypes: { [key in keyof RemitDataRowModel]: keyof BasicConfig["widgets"] } = {
  AccountCycle: "number",
  Amount: "number",
  BatchID: "number",
  CreatedDate: "datetime",
  DischargeDate: "datetime",
  Discount: "number",
  ExpirationDate: "datetime",
  GL_PaymentAmount: "number",
  GL_UnitCost: "number",
  GL_Units: "number",
  IsSettled: "boolean",
  NextPaymentDate: "datetime",
  PatientDiscount: "number",
  PatientBalance: "number",
  PaymentPlanTotalAmount: "number",
  PlanPaymentAmount: "number",
  PlanStartDate: "datetime",
  ServiceDate: "datetime",
  SettledDate: "datetime",
  TotalAmount: "number",
  PaymentSum: "number",
  CreditSum: "number",
  CurrentDate: "datetime",
};

