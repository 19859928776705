import { useDispatch } from "react-redux";
import { AlertIds, AlertTypes, setAlert } from "features/alert/AlertReducer";
import { DeleteFileConfiguration } from "services/RemitService";

const alertConfig = {
  id: AlertIds.RemitDelete,
  type: AlertTypes.Error,
  message: '',
};

export function useDeleteRemitConfiguration (){
  const dispatch = useDispatch();

  const useDeleteFile = async (id: string, close: any) => {
    const response = await DeleteFileConfiguration(id);
    if (!response.err) {
      close();
      dispatch(
        setAlert({
          ...alertConfig,
          type: AlertTypes.Success,
          message: "Remit file has been deleted.",
          dismissable: true,
        })
      );
    }
  }

  return { useDeleteFile };
}