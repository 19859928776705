import { PaymentConfiguration } from "../../../models/PaymentsConfiguration";
import { updatePaymentConfiguration } from "../../../services/TransactionsService";

export function useSavePaymentConfiguration(organizationPath?: string){
  async function savePaymentConfiguration(configuration: PaymentConfiguration) {
    if (organizationPath) {
      await updatePaymentConfiguration(organizationPath, configuration);
    }
  }

  return { savePaymentConfiguration };
}