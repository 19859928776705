import React, { useCallback, useRef, useState } from 'react';
import { EDS_Button } from '@EH/eh.eds.react';
import 'assets/styles/components/_checkScanner.scss';
import { ReactComponent as Scanner } from 'assets/svgs/icon-scanner.svg';
import { ReactComponent as ScannerDisabled } from 'assets/svgs/icon-scanner-disabled.svg';
import { ECheckModel } from './ECheckModel';
import { ModalContent } from './ModalContent';
import { parseCheckRawString } from './CheckRawStringParser';
import * as ErrorMessages from './ErrorMessages';
import UserPermissions from 'models/enums/UserPermissions';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';

export function CheckScannerModal(props: {
  onScanSucces?: (eCheckModel: ECheckModel) => void;
  onScanFailure?: () => void;
  organizationPath?: string;
}) {
  const { onScanSucces: onScanSuccess, onScanFailure, organizationPath } = props;

  const [showModal, setShowModal] = useState(false);
  const openClass = showModal ? 'eds-modal.open' : '';
  const nodeRef = useRef<HTMLDivElement>(null);

  const title = 'Scan Check';

  const { havePermission } = useLoggedUserPermissions();

  const paymentCheckScannerPermission = havePermission(UserPermissions.PaymentCheckScanner, organizationPath)

  function handleToggleModal() {
    if (!paymentCheckScannerPermission) return
    setShowModal(!showModal);
  }

  function handleCloseModal(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (!nodeRef.current || nodeRef.current.contains(e.target as Node)) {
      return;
    }

    setShowModal(false);
  }

  const onScanSuccessAction = (eCheckModel: ECheckModel) => {
    if (onScanSuccess) {
      onScanSuccess(eCheckModel);
    }

    setShowModal(false);
  };

  const onScanFailureAction = () => {
    if (onScanFailure) {
      onScanFailure();
    }
  };

  const [checkRawString, setCheckRawString] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const onApply = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {

      const { eCheckModel, error } = parseCheckRawString(checkRawString);

      if (error || !eCheckModel) {
        setErrorMessage(error);
        onScanFailureAction();
      } else if (!eCheckModel) {
        setErrorMessage(ErrorMessages.failedToReadRoutingNumber);
        onScanFailureAction();
      } else {
        setErrorMessage('');
        onScanSuccessAction(eCheckModel);
      }
    },
    [checkRawString, onScanSuccessAction, onScanFailureAction]
  );

  return (
    <>
      <div className={paymentCheckScannerPermission ? `scan-check`: `scan-check-disabled`} onClick={handleToggleModal}>
        {paymentCheckScannerPermission ? <Scanner /> : <ScannerDisabled /> } Scan Check
      </div>
      <section className={`eds-modal ${openClass}`} onClick={handleCloseModal}>
        <div className="eds-modal_#container" ref={nodeRef}>
          <button
            className={
              'eds-modal_#close eds-modal_.eds-link eds-link material-icons'
            }
            onClick={handleToggleModal}
          >
            close
          </button>
          <header className="eds-modal_#header">
            <h1 className="eds-modal_.title">{title}</h1>
          </header>
          <div className="eds-modal_#content">
            <ModalContent
              checkRawString={checkRawString}
              errorMessage={errorMessage}
              setCheckRawString={setCheckRawString}
            />
          </div>
          <footer className="eds-modal_#footer">
            <div className="check-scanner-modal-buttons">
              <EDS_Button
                buttonText={'Enter Manually'}
                modifiers={'eds-button eds-button.basic'}
                onClick={() => {
                  onScanFailureAction();
                  handleToggleModal();
                }}
              />
              <EDS_Button
                buttonText={'Apply'}
                modifiers={'eds-button eds-button.primary'}
                onClick={onApply}
              />
            </div>
          </footer>
        </div>
      </section>
    </>
  );
}
