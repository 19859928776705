import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTypedSelector } from '../../app/rootReducer';
import SelectClient from '../../features/admin/clientConfiguration/SelectClient';
import ActionConfirmModal from 'features/admin/ActionConfirmModal';
import { LineItemType } from '../../features/paymentDashboard/LineItemComponent';
import { ReactComponent as CloneIcon } from 'assets/svgs/clone.svg';
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';
import { ReactComponent as Collapse } from 'assets/svgs/admin/paymentPanel/collapse.svg';
import { ReactComponent as Expand } from 'assets/svgs/admin/paymentPanel/expand.svg';
import { ReactComponent as Up } from 'assets/svgs/admin/paymentPanel/up.svg';
import { ReactComponent as Down } from 'assets/svgs/admin/paymentPanel/down.svg';
import { ReactComponent as Delete } from 'assets/svgs/admin/paymentPanel/delete.svg';
import { Button } from '@EHDS/core';
import 'assets/styles/components/_paymentPanelConfiguration.scss';
import { Attribute, Mappings, MetaData } from '../../models/metaData/MetaData';
import { LineItem as LineItemModel } from '../../models/LineItem';
import ConfigurationSelector, {
  dashboardOptions,
  Dashboards,
  isDashboardDraft,
} from '../../features/admin/paymentPanel/ConfigurationSelector';
import * as router from 'react-router-dom';
import { useOrganizations } from '../../features/organizations/hooks/useOrganizations';
import { useUpdateConfiguration } from '../../features/admin/paymentPanel/serviceHandlers/useUpdateConfiguration';
import { useUpdatePatientConfiguration } from '../../features/admin/paymentPanel/serviceHandlers/useUpdatePatientConfiguration';
import SelectClientsModal from './Dashboard/SelectClientsModal';
import { DashboardConfiguration } from '../../features/admin/paymentPanel/DashboardConfiguration';
import {
  LineItemConfiguration,
  LineItemConfigurationProps,
} from '../../features/admin/paymentPanel/LineItemConfiguration';
import { MultiSelectOption } from 'components/select/MultiSelect';
import { useResetConfiguration } from '../../features/admin/paymentPanel/serviceHandlers/useResetConfiguration';
import useGetConfiguration from 'features/admin/paymentPanel/serviceHandlers/useGetConfiguration';
import { useDispatch } from 'react-redux';
import Previewer from 'features/admin/paymentPanel/Previewer';
import useClonePanelConfiguration from 'features/admin/paymentPanel/serviceHandlers/useClonePanelConfiguration';
import ClonePaymentPanelModal, {
  PaymentPanelCloneMultiselectOption,
} from 'features/admin/paymentPanel/ClonePaymentPanelModal';
import {
  AlertIds,
  AlertTypes,
  setAlert,
  setFixedMode,
} from 'features/alert/AlertReducer';
import { OrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { getOrganizationOption } from 'pages/Search/simpleSearch/OrganizationUserSearch';
import { PanelCloneRequest } from 'services/PaymentPanelService';
import { saveConfiguration } from 'features/admin/paymentPanel/PaymentPanelReducer';
import { useLocalStorage } from 'utils/useLocalStorage';
import { checkStringHasValue, getValueOrDefault } from 'utils/Utils';

enum Configurations {
  LineItem,
  LineItemField,
  PatientInfoField,
}

export interface Sections {
  field?: boolean;
  dashboard?: boolean;
  mapping?: boolean;
  payments?: boolean;
  paymentPlans?: boolean;
  generalLedger?: boolean;
  psafeAdmin?: boolean;
  epic?: boolean;
}

const messages = {
  saveLive: `Saving Live will override the existing settings. <br/> This operation cannot be undone. Proceed?`,
  saveDraft: `This will override the saved Draft version. <br/> Proceed?`,
  warningHeader: 'Warning',
  confirmButtonText: 'OK',
  cancelButtonText: 'Cancel',
};

export default function PaymentPanelConfiguration() {
  const organizations = useTypedSelector(s => s.organizations?.value);
  let { clientId, dashboardKey } = router.useParams<{
    clientId: string;
    dashboardKey: string;
  }>();

  const {
    getOrganizationById,
    useGetFacilities,
    getTradingPartnerByFacility,
  } = useOrganizations();
  const selectedOrganization = getOrganizationById(clientId);

  const [selectedDashboard, setSelectedDashboard] = useState<
    keyof Dashboards
  >();
  const [
    selectedDashboardCounterpartLive,
    setSelectedDashboardCounterpartLive,
  ] = useState<keyof Dashboards>();
  const [
    selectedDashboardCounterpartDraft,
    setSelectedDashboardCounterpartDraft,
  ] = useState<keyof Dashboards>();
  const [openResetConfirmation, setOpenResetConfirmation] = useState(false);
  const [configurationOpen, setConfigurationOpen] = useState<Configurations>();
  const [openField, setOpenField] = useState<keyof LineItemModel>();
  const [newCustomAttributeName, setNewCustomAttributeName] = useState<
    string
  >();
  const [isSelectClientOpen, setIsSelectClientOpen] = useState(false);
  const [selectedClients, setSelectedClients] = useState<MultiSelectOption[]>();
  const [mappedClients, setMappedClients] = useState<Mappings[]>();
  const configurations = useTypedSelector(s => s.paymentPanel.configurations);
  const configuration = selectedDashboard && configurations[selectedDashboard];
  const configurationCounterpartLive = useTypedSelector(
    s =>
      selectedDashboardCounterpartLive &&
      s.paymentPanel.configurations[selectedDashboardCounterpartLive]
  );
  const configurationCounterpartDraft = useTypedSelector(
    s =>
      selectedDashboardCounterpartDraft &&
      s.paymentPanel.configurations[selectedDashboardCounterpartDraft]
  );

  useEffect(() => {
    if (selectedDashboard) {
      const localStorageKey = `defaultConfig-${selectedDashboard}`;
      const savedConfig = localStorage.getItem(localStorageKey);
      if (!savedConfig && configuration) {
        localStorage.setItem(localStorageKey, JSON.stringify(configuration));
      }
    }
  }, [selectedDashboard, configuration]); 

  const attributes = getValueOrDefault(configuration?.entity?.attributes, []);
  const { getPanelConfiguration } = useGetConfiguration(
    selectedOrganization?.path
  );
  const previewLineItem: LineItemModel = {
    id: 'previewLineItemId',
    amount: 0,
    isEditing: true,
    isNew: true,
    isActive: false,
  };

  const { updateConfiguration } = useUpdateConfiguration(
    selectedDashboard,
    selectedOrganization?.path
  );
  const { updatePatientConfiguration } = useUpdatePatientConfiguration(
    selectedDashboard
  );
  const { resetConfiguration } = useResetConfiguration(
    selectedDashboard,
    configuration?.id,
    selectedOrganization?.path
  );
  const dispatch = useDispatch();
  const tradingPartnerPath = getTradingPartnerByFacility(
    checkStringHasValue(selectedOrganization?.path)
  );
  const facilityId = selectedOrganization?.id;
  const [
    selectedConfiguration = {},
    setSelectedConfiguration,
  ] = useLocalStorage<MetaData>(
    'paymentPanelState',
    'selectedConfiguration',
    {}
  );
  const allFacilities = useGetFacilities();
  const clientOptions = useGetFacilities(
    organization =>
      getTradingPartnerByFacility(organization.path) === tradingPartnerPath ||
      (isNotEmptyString(selectedOrganization!.accessPartnerGrouping) &&
        organization.accessPartnerGrouping ===
          selectedOrganization?.accessPartnerGrouping)
  );

  useEffect(() => {
    if (dashboardKey) {
      const existingDashboard = dashboardOptions.find(
        dashboard => dashboard.value === dashboardKey
      );
      if (existingDashboard) {
        onChangeConfiguration(existingDashboard.value);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(setFixedMode(true));
    setOrganizationCloneOptions(getCloneOrganizationOptions());
    return () => {
      dispatch(setFixedMode(false));
    };
  }, []);

  const getOptions = (clientList: Mappings[]) => {
    return clientList.map(x => {
      const option = clientOptions.find(y => y.path == x.organization?.path);
      if (option) {
        return {
          label: `${option.clientId} (${option.name})`.replace(/"/g, ''),
          value: option.path,
        } as MultiSelectOption;
      }
      return x;
    });
  };

  useEffect(() => {
    if (configuration?.mappings) {
      const updatedSelectedClients = getOptions(configuration?.mappings);
      setSelectedClients(updatedSelectedClients as MultiSelectOption[]);
      setMappedClients(configuration.mappings);
    }
  }, [configuration]);

  useEffect(() => {
    if (mappedClients) {
      const updatedSelectedClients = getOptions(mappedClients);
      setSelectedClients(updatedSelectedClients as MultiSelectOption[]);
    }
  }, [mappedClients]);

  function isNotEmptyString(stringValue: string | undefined): boolean {
    return !!stringValue;
  }

  const [header, setHeader] = useState('');
  const [color, setColor] = useState('');
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [selectedClientOrder, setSelectedClientOrder] = useState<number | null>(
    null
  );
  const [isClonePaymenPanelModal, setIsClonePaymenPanelModal] = useState(false);
  const [organizationCloneOptions, setOrganizationCloneOptions] = useState<
    PaymentPanelCloneMultiselectOption[]
  >();
  const [selectedCloneOrganizations, setSelectedCloneOrganizations] = useState<
    PaymentPanelCloneMultiselectOption[]
  >();
  const { clonePanel } = useClonePanelConfiguration();

  const getTradingPartnerLabel = (organization?: OrganizationLevelDocument) =>
    `(${organization?.tradingPartnerId}) ${organization?.name}`;
  const getFacilityLabel = (organization: OrganizationLevelDocument) =>
    `(${organization.clientId}) ${organization.name}`;

  function getCloneOrganizationOptions(): PaymentPanelCloneMultiselectOption[] {
    const options: PaymentPanelCloneMultiselectOption[] = [];
    allFacilities.forEach(f => {
      const tradingPartnerName = getTradingPartnerLabel(
        getTradingPartnerByFacility(f.path)
      );
      const option = {
        ...getOrganizationOption(f, o => getFacilityLabel(o)),
        tradingPartnerName,
      };
      options.push(option);
    });
    return options;
  }

  const enrichedAttributes = [...attributes];

  const selectedFieldIdx = enrichedAttributes.findIndex(
    a => a.name === openField
  );
  if (selectedFieldIdx > -1) {
    const selectedFieldAttribute = { ...enrichedAttributes[selectedFieldIdx] };
    if (selectedFieldAttribute) {
      selectedFieldAttribute.className =
        'selected ' + selectedFieldAttribute.className;
      enrichedAttributes[selectedFieldIdx] = selectedFieldAttribute;
    }
  }

  const resetDashboard = () => {
    setOpenResetConfirmation(true);
  };

  const selectClient = useMemo(() => <SelectClient />, [organizations]);

  const [dashboardSettingsCollapsed, setDashboardSettingsCollapsed] = useState<
    Sections
  >();

  const collapseSection = (name: keyof Sections) => {
    setDashboardSettingsCollapsed({
      ...dashboardSettingsCollapsed,
      [name]: !dashboardSettingsCollapsed?.[name],
    });
  };

  const onChangeConfiguration = (dashboard: keyof Dashboards) => {
    setMappedClients([]);
    setSelectedDashboard(dashboard);
    
    getPanelConfiguration(dashboard).finally(() => setConfigurationOpen(0));
    if (
      dashboard === 'PaymentDashboard' ||
      dashboard === 'PaymentPlanDashboard' ||
      dashboard === 'PaymentDashboardDraft' ||
      dashboard === 'PaymentPlanDashboardDraft'
    ) {
      const dashboardCounterpartLive = getCounterpartDashboard(
        dashboard as string,
        false
      );
      setSelectedDashboardCounterpartLive(dashboardCounterpartLive);
      getPanelConfiguration(dashboardCounterpartLive);
      const dashboardCounterpartDraft = getCounterpartDashboard(
        dashboard as string,
        true
      );
      setSelectedDashboardCounterpartDraft(dashboardCounterpartDraft);
      getPanelConfiguration(dashboardCounterpartDraft);
    }
  };

  const onPreview = () => {
    localStorage.setItem('selectedDashboard', selectedDashboard ?? '');
    setSelectedConfiguration(configuration as MetaData);
    switch (selectedDashboard) {
      case 'PaymentDashboard':
      case 'PaymentDashboardDraft': {
        window.open(`/payment-preview?facility=${facilityId}`, '_blank');
        break;
      }
      case 'PaymentPlanDashboard':
      case 'PaymentPlanDashboardDraft': {
        window.open(`/payment-plan-preview?facility=${facilityId}`, '_blank');
        break;
      }
      case 'GlPaymentDashboard':
      case 'GlPaymentDashboardDraft':{
        window.open(`/gl-payment-preview?facility=${facilityId}`, '_blank');
        break;
      }
      case 'GlPaymentPlanDashboard':
      case 'GlPaymentPlanDashboardDraft': {
        window.open(`/gl-payment-plan-preview?facility=${facilityId}`, '_blank');
        break;
      }
      case 'EcnPaymentDashboard':
      case 'EcnPaymentDashboardDraft': {
        window.open(`/ecn-payment-preview?facility=${facilityId}`, '_blank');
        break;
      }
    }
  };

  function saveAsExistsInAttributes(
    attributes: Attribute[],
    saveAsName: string
  ) {
    return attributes?.findIndex(attribute =>
      attribute.saveAs?.includes(saveAsName)
    );
  }

  const renderConfirmationModal = () => {
    return (
      <ActionConfirmModal
        isOpen={openSaveModal}
        actionTitle={messages.warningHeader}
        actionButtonText={messages.confirmButtonText}
        actionConfirmText={isDraft ? messages.saveDraft : messages.saveLive}
        iconName={''}
        showCancelButton={true}
        actionCancelButtonText={messages.cancelButtonText}
        useWarningStyle={true}
        onActionConfirm={() => {
          const config = {
            ...configuration,
            mappings: mappedClients ? mappedClients : [],
          };
          const counterPartInfo = {
            selectedDashboardCounterpart: isDraft
              ? selectedDashboardCounterpartDraft
              : selectedDashboardCounterpartLive,
            configurationCounterpart: isDraft
              ? configurationCounterpartDraft
              : configurationCounterpartLive,
            updateConfiguration,
            isDraft,
            attributesFromMainPanel: verifyAttributes(attributes),
            isCounterPart: true,
          };
          const hasAccountNumber = saveAsExistsInAttributes(attributes, 'accountNumber') != -1;
          const hasAmount = saveAsExistsInAttributes(attributes, 'amount') != -1;

          if (
            selectedDashboard === 'PatientSearchPopupDashboard' ||
            selectedDashboard === 'PatientSearchPopupDashboardDraft'
          ) {
            updatePatientConfiguration(config, !isDraft);
          } else if ((hasAccountNumber && hasAmount) || 
            (['GlPaymentDashboard','GlPaymentDashboardDraft'].includes(selectedDashboard!))) {
            updateCounterpartConfiguration(
              selectedDashboard,
              config,
              counterPartInfo
            );
            updateConfiguration(config, !isDraft, selectedDashboard);
          } else {
            getErrorMessage();
          }
          getPanelConfiguration(selectedDashboard!).finally(() => setConfigurationOpen(0));
          onChangeConfiguration(selectedDashboard!);
          setOpenSaveModal(false);
        }}
        onClose={() => {
          setOpenSaveModal(false);
        }}
      />
    );
  };

  function getErrorMessage() {
    const hasAccountNumber = saveAsExistsInAttributes(attributes, 'accountNumber') == -1;

    dispatch(
      setAlert({
        id: AlertIds.PaymentDashboardConfiguration,
        type: AlertTypes.Error,
        message: hasAccountNumber ? `Account Number is a required field in Payment Panel` : `There always must be one field that is with Save As = 'amount'`,
      })
    );
  }

  function openSelectClientsModal() {
    return isSelectClientOpen ? (
      <SelectClientsModal
        close={() => {
          setIsSelectClientOpen(false);
        }}
        onNext={selectedClients => {
          setIsSelectClientOpen(false);
          const updatedSelectedClients = selectedClients.map((x, index) => {
            const option = clientOptions.find(y => y.path == x.value);
            if (option) {
              return {
                organization: {
                  id: option.id,
                  name: option.name,
                  path: option.path,
                },
                order: index + 1,
              };
            }
            return x;
          });
          setSelectedClients(selectedClients);
          setMappedClients(updatedSelectedClients as Mappings[]);
          dispatch(
            saveConfiguration({
              key: selectedDashboard!,
              configuration: {
                ...configuration,
                mappings: updatedSelectedClients as Mappings[],
              },
            })
          );
        }}
        orgPath={selectedOrganization?.path ?? ''}
        currentSelectedClients={selectedClients}
      />
    ) : null;
  }

  function showHeaderText() {
    if (patientInfoShowHide()) {
      return <div className={'facility-header'}>PATIENT SEARCH SETTINGS</div>;
    }
    return (
      <div className={'facility-header'}>{header ? header : '<Facility>'}</div>
    );
  }

  const handleHeaderUpdate = (headerText: string, colorCode: string) => {
    setHeader(headerText);
    setColor(colorCode);
  };

  const patientInfoShowHide = (): boolean => {
    if (
      selectedDashboard === 'PatientSearchPopupDashboard' ||
      selectedDashboard === 'PatientSearchPopupDashboardDraft'
    )
      return true;
    else return false;
  };

  const applyDashboardClass = () => {
    if (configurationOpen === undefined) return '';

    return patientInfoShowHide()
      ? 'dashboard-fitContent-open'
      : 'dashboard-open';
  };

  const onResetConfiguration = () => {
    if (!configuration?.id) {
      dispatch(
        setAlert({
          id: AlertIds.ErrorResetConfigurationAlert,
          type: AlertTypes.Error,
          message: 'This configuration has already been reset.',
        })
      );
    } else {
      resetConfiguration();
    }
    setOpenResetConfirmation(false);
  };

  const onPaymentPanelClone = async () => {
    setIsClonePaymenPanelModal(false);

    if (!configuration!.id) {
      dispatch(
        setAlert({
          id: AlertIds.ErrorClonePaymentPanelAlert,
          type: AlertTypes.Error,
          message:
            'The Facility you are trying to clone from is using the default panel configuration for this panel type. Please select a different Facility.',
        })
      );
    } else {
      const cloneRequest: PanelCloneRequest = {
        panelSettingId: configuration!.id,
        destinationOrganizationPaths: selectedCloneOrganizations?.map(
          p => p.value
        ),
      };
      const errors: any[] = await clonePanel(selectedDashboard!, cloneRequest);

      errors?.forEach(err => {
        dispatch(
          setAlert({
            id: `${AlertIds.ErrorClonePaymentPanelAlert}${err.PropertyName}`,
            type: AlertTypes.Error,
            message: `${err.errorMessage} - ${err.PropertyName}`,
          })
        );
      });
      setSelectedCloneOrganizations(undefined);
    }
  };

  const handleSelectClient = (index: number) => {
    setSelectedClientOrder(index);
  };

  const handleMoveUp = () => {
    if (selectedClientOrder === null || selectedClientOrder === 1) return;

    const newClients = [...(mappedClients as Mappings[])];
    const selectedClientIndex = newClients.findIndex(
      client => client.order === selectedClientOrder
    );
    const selectedClient = newClients[selectedClientIndex];
    const aboveClient = newClients.find(
      client => client.order === selectedClientOrder - 1
    );

    newClients[selectedClientIndex] = {
      ...selectedClient,
      order: selectedClientOrder - 1,
    };
    newClients[newClients.indexOf(aboveClient!)] = {
      ...aboveClient!,
      order: selectedClientOrder,
    };

    const sortedNewMapping = [...newClients]?.sort(
      (a, b) => a?.order - b?.order
    );
    setSelectedClientOrder(selectedClientOrder - 1);
    setMappedClients(sortedNewMapping);
    dispatch(
      saveConfiguration({
        key: selectedDashboard!,
        configuration: {
          ...configuration,
          mappings: sortedNewMapping,
        },
      })
    );
  };

  const handleMoveDown = () => {
    if (
      selectedClientOrder === null ||
      selectedClientOrder === (mappedClients || []).length
    )
      return;

    const newClients = [...(mappedClients as Mappings[])];
    const selectedClientIndex = newClients.findIndex(
      client => client.order === selectedClientOrder
    );
    const selectedClient = newClients[selectedClientIndex];
    const belowClient = newClients.find(
      client => client.order === selectedClientOrder + 1
    );

    newClients[selectedClientIndex] = {
      ...selectedClient,
      order: selectedClientOrder + 1,
    };
    newClients[newClients.indexOf(belowClient!)] = {
      ...belowClient!,
      order: selectedClientOrder,
    };

    const sortedNewMapping = [...newClients]?.sort(
      (a, b) => a?.order - b?.order
    );
    setSelectedClientOrder(selectedClientOrder + 1);
    setMappedClients(sortedNewMapping);
    dispatch(
      saveConfiguration({
        key: selectedDashboard!,
        configuration: {
          ...configuration,
          mappings: sortedNewMapping,
        },
      })
    );
  };

  const handleDelete = () => {
    if (selectedClientOrder === null) return;

    const newClients = (mappedClients as Mappings[]).filter(
      client => client.order !== selectedClientOrder
    );
    const updatedClients = newClients.map((client, index) => ({
      ...client,
      order: index + 1,
    }));

    setMappedClients(updatedClients);
    setSelectedClientOrder(null);
    dispatch(
      saveConfiguration({
        key: selectedDashboard!,
        configuration: {
          ...configuration,
          mappings: updatedClients,
        },
      })
    );
  };

  const showClientList = () => {
    if (mappedClients) {
      const sortedOrder = [...mappedClients]?.sort(
        (a, b) => a?.order - b?.order
      );

      return sortedOrder ? (
        <div className="client-row">
          <div className="client-box">
            {sortedOrder.length > 0 ? (
              sortedOrder.map(client => (
                <div
                  key={client?.organization?.id}
                  className={`client-option ${
                    selectedClientOrder === client.order
                      ? 'selected-option'
                      : ''
                  }`}
                  onClick={() => handleSelectClient(client.order)}
                >
                  <span>
                    {client?.organization?.name} ({client?.order})
                  </span>
                </div>
              ))
            ) : (
              <div style={{ padding: 10 }}>Please select clients to map</div>
            )}
          </div>
          <div style={{ width: '30%' }}>
            <div style={{ paddingBottom: 5 }}>Reorder Facility Panels</div>
            <div className={'mapping-action'} onClick={handleMoveUp}>
              <Up />
              Move Up
            </div>
            <div className={'mapping-action'} onClick={handleMoveDown}>
              <Down />
              Move Down
            </div>
            <div className={'mapping-action'} onClick={handleDelete}>
              <Delete />
              Delete
            </div>
          </div>
        </div>
      ) : null;
    }
  };

  return (
    <div className={'payment-panel-configuration'}>
      <div className="client-configuration">
        <div>{openSelectClientsModal()}</div>
        <div className="client-controls">{selectClient}</div>
      </div>
      <div className={'select-menu'}>
        <span className={'heading'}>Edit</span>

        <span style={{ paddingRight: 10 }}>
          Control-click to open it in new tab{' '}
        </span>
        <ConfigurationSelector
          selectedDashboard={selectedDashboard}
          onChangeConfig={onChangeConfiguration}
        />
        <span
          style={{ paddingLeft: 10, color: 'white', cursor: 'pointer' }}
          onClick={onPreview}
        >
          Preview Page
        </span>
      </div>
      {configurationOpen !== undefined ? showHeaderText() : null}
      <div
        id={'dashboard-preview'}
        className={applyDashboardClass()}
        onClick={() => {
          setConfigurationOpen(Configurations.LineItem);
          setOpenField(undefined);
        }}
      >
        {selectedDashboard ? (
          <Previewer
            selectedDashboard={selectedDashboard}
            title={header}
            onClickAttribute={(_type?: LineItemType, attribute?: Attribute) => {
              setConfigurationOpen(Configurations.LineItemField);
              if (attribute && attribute.name) {
                setOpenField(attribute.name);
                setNewCustomAttributeName(attribute.newCustomFieldName);
              }
            }}
            previewLineItem={previewLineItem}
            externalAttributes={enrichedAttributes}
            color={color}
            isPreview={true}
            openField={openField}
            newCustomAttributeName={newCustomAttributeName}
          />
        ) : null}
      </div>
      {configurationOpen !== undefined ? (
        <div className={'configuration'}>
          <div className={'configuration-section configuration-border'}>
            <div className={'header'}>
              <div>
                <CollapseButton
                  name={'dashboard'}
                  dashboardSettingsCollapsed={dashboardSettingsCollapsed}
                  collapseSection={collapseSection}
                />
                Panel Settings
              </div>
            </div>
            <CollapsibleSection
              name="dashboard"
              dashboardSettingsCollapsed={dashboardSettingsCollapsed}
            >
              <DashboardConfiguration
                onHeaderUpdate={handleHeaderUpdate}
                configuration={configuration}
                dashboardName={selectedDashboard}
              />
            </CollapsibleSection>
          </div>
          <ConfigurationBuilder
            selected={configurationOpen}
            lineItemConfigurationProps={{
              selectedDashboard,
              openField,
              newCustomAttributeName,
              collapseSection,
              dashboardSettingsCollapsed,
              previewLineItem,
            }}
          />
          {selectedDashboard === 'PatientSearchPopupDashboard' ||
          selectedDashboard === 'PatientSearchPopupDashboardDraft' ? null : (
            <div className={'configuration-section'}>
              <div className={'header'}>
                <CollapseButton
                  name={'mapping'}
                  dashboardSettingsCollapsed={dashboardSettingsCollapsed}
                  collapseSection={collapseSection}
                />
                Mapping
              </div>
              <CollapsibleSection
                name={'mapping'}
                dashboardSettingsCollapsed={dashboardSettingsCollapsed}
              >
                <div className="client-row">
                  <div style={{ width: '20%' }}>
                    <div>
                      <Button
                        className={'select-clients-button'}
                        onClick={() => {
                          setIsSelectClientOpen(true);
                        }}
                      >
                        Select Client(s)
                      </Button>
                    </div>
                  </div>
                  <div style={{ width: '80%' }}>{showClientList()}</div>
                </div>
              </CollapsibleSection>
            </div>
          )}
        </div>
      ) : null}
      <div className={'row footer-actions'}>
        <div className={'row actions-row'}>
          {selectedDashboard ? (
            <div
              onClick={e => {
                e.preventDefault();
                setIsClonePaymenPanelModal(true);
              }}
            >
              <div>
                <CloneIcon />
                <span className="clone-link">Clone Panel Settings</span>
              </div>
            </div>
          ) : null}
          {isDashboardDraft(selectedDashboard) ? (
            <div>
              <button className={'eds-link row-icon'} onClick={resetDashboard}>
                <span className="new-facility-button">
                  <ResetIcon /> Reset Panel
                </span>
              </button>
            </div>
          ) : null}
        </div>
        {selectedDashboard && (
          <div className={'butons-row'}>
            <Button
              data-testid="btnSaveLive"
              onClick={() => {
                setIsDraft(false);
                setOpenSaveModal(true);
              }}
            >
              Save Live
            </Button>
            <Button
              data-testid="btnSaveDraft"
              onClick={() => {
                setIsDraft(true);
                setOpenSaveModal(true);
              }}
              type="primary"
            >
              Save Draft
            </Button>
          </div>
        )}
      </div>
      {renderConfirmationModal()}
      <ActionConfirmModal
        isOpen={openResetConfirmation}
        actionTitle={'Reset Panel'}
        actionButtonText={'Confirm'}
        actionConfirmText={
          'Are you sure you want to reset the panel back to the default configuration?'
        }
        onActionConfirm={onResetConfiguration}
        onClose={() => {
          setOpenResetConfirmation(false);
        }}
      />
      {isClonePaymenPanelModal ? (
        <ClonePaymentPanelModal
          close={() => setIsClonePaymenPanelModal(false)}
          isOpen={true}
          organizationOptions={organizationCloneOptions}
          disabledOption={selectedOrganization?.path}
          onChangeSelectedValues={setSelectedCloneOrganizations}
          selectedValues={selectedCloneOrganizations}
          onPaymentPanelClone={onPaymentPanelClone}
        />
      ) : null}
    </div>
  );
}

function ConfigurationBuilder(props: {
  selected: Configurations;
  lineItemConfigurationProps: LineItemConfigurationProps;
}) {
  const { selected, lineItemConfigurationProps } = props;
  if (selected === Configurations.LineItemField) {
    return <LineItemConfiguration {...lineItemConfigurationProps} />;
  }
  return null;
}

export function CollapsibleSection(props: {
  dashboardSettingsCollapsed?: Sections;
  name: keyof Sections;
  children: ReactNode;
}) {
  const { name, children, dashboardSettingsCollapsed } = props;
  return !dashboardSettingsCollapsed?.[name] ? <>{children}</> : null;
}

export function CollapseButton(props: {
  name: keyof Sections;
  dashboardSettingsCollapsed?: Sections;
  collapseSection: (name: keyof Sections) => void;
}) {
  const { name, collapseSection, dashboardSettingsCollapsed } = props;
  return (
    <span onClick={() => collapseSection(name)}>
      {dashboardSettingsCollapsed?.[name] ? <Expand /> : <Collapse />}
    </span>
  );
}

export function getCounterpartDashboard(
  key: string,
  isDraft?: boolean
): keyof Dashboards {
  const liveCounterparts: { [key: string]: string } = {
    PaymentDashboard: 'PaymentPlanDashboard',
    PaymentPlanDashboard: 'PaymentDashboard',
    PaymentDashboardDraft: 'PaymentPlanDashboard',
    PaymentPlanDashboardDraft: 'PaymentDashboard',
  };

  const draftCounterparts: { [key: string]: string } = {
    PaymentDashboard: 'PaymentPlanDashboardDraft',
    PaymentPlanDashboard: 'PaymentDashboardDraft',
    PaymentDashboardDraft: 'PaymentPlanDashboardDraft',
    PaymentPlanDashboardDraft: 'PaymentDashboardDraft',
  };

  const counterparts = isDraft ? draftCounterparts : liveCounterparts;
  const counterpart = counterparts[key];
  let returnedValue = counterpart as keyof Dashboards;
  return returnedValue;
}

export function updateCounterpartConfiguration(
  selectedDashboard?: keyof Dashboards,
  config?: any,
  counterPartInfo?: any
) {
  const {
    selectedDashboardCounterpart,
    configurationCounterpart,
    updateConfiguration,
    isDraft,
    attributesFromMainPanel,
    isCounterPart,
  } = counterPartInfo;

  if (
    selectedDashboard === 'PaymentDashboard' ||
    selectedDashboard === 'PaymentPlanDashboard' ||
    selectedDashboard === 'PaymentDashboardDraft' ||
    selectedDashboard === 'PaymentPlanDashboardDraft'
  ) {
    if (
      attributesFromMainPanel == -1 &&
      config &&
      configurationCounterpart &&
      selectedDashboardCounterpart &&
      updateConfiguration
    ) {
      const configCounterpart = {
        ...configurationCounterpart,
        mappings: config.mappings,
      };
      updateConfiguration(
        configCounterpart,
        !isDraft,
        selectedDashboardCounterpart,
        isCounterPart
      );
    }
  }
}

export function verifyAttributes(attributes?: Attribute[]) {
  return attributes?.findIndex(attribute =>
    attribute.saveAs?.includes('undefined')
  );
}
