import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/rootReducer';
import {
  GetAllPermissionsResult,
  getAllPermissionsService,
} from 'services/PermissionsService';
import { PermissionItem } from 'models/Permission';
import PermissionState from 'models/enums/PermissionState';
import { callService } from '../../services/ServicesReducer';

export interface State {
  permissions: PermissionItem[];
  err?: string;
}

export const initialState: State = {
  permissions: [],
  err: '',
};

const reducerSlice = createSlice({
  name: 'GlobalPermissions',
  initialState,
  reducers: {
    onReceive(state, action: PayloadAction<GetAllPermissionsResult>) {
      state.permissions = action.payload.permissions;
      if (state.permissions)
        state.permissions.forEach(p => (p.state = PermissionState.Inactive));
      state.err = action.payload.err;
    },
    clearResult(state) {
      state.permissions = [];
      state.err = '';
    },
  },
});

export const { onReceive, clearResult } = reducerSlice.actions;
export default reducerSlice.reducer;

export function getAllPermissions(onDone?: () => void): AppThunk {
  return async (dispatch, getState) => {
    const permissions = getState().globalPermissions.permissions;
    if (permissions && permissions.length > 0) return;
    dispatch(callService("getAllPermissionsService", async () => {
      const response = await getAllPermissionsService();
      onReceive(response);
      if (onDone) onDone();
    }))
  };
}
