import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/rootReducer';
import { EnumCardBrand } from 'models/enums/EnumCardBrand';
import { RemitFileFieldDescription, RemitFilePredefinedFunctionsDescription } from 'models/RemitPreDefinedListsModel';
import { PredefinedDataType, RemitPredefinedData } from 'models/RemitPredefinedDataModel';
import { GetRemitFileFieldDescriptions, GetRemitFilePredefinedFunctionsDescriptions } from 'services/RemitPreDefinedListsService';
import { GetRemitPredefinedData } from 'services/RemitPredefinedDataService';
import { PaymentSourceEnum, RadioButtons, RemitConfiguration, RemitConfigurationSettings, Setting, TransactionTypesEnum, TransactionTypesEnumLabels } from '../../../models/admin/RemitConfiguration';
import { ErrorsMessages } from '../../../utils/useTypedValidator';
import { enumToMultiSelectOptions, runCallbackOnDateInPastAsync, TenderTypeEnum, TenderTypeLabels, TimeInMiliSeconst } from '../../../utils/Utils';

export interface State {
  remitConfiguration: RemitConfiguration,
  remitConfigurations: RemitConfiguration[],
  remitPredefinedData: {
    date?: number,
    predefinedData?: RemitPredefinedData[]
  },
  remitFileFieldDescriptions: {
    date?: number,
    fileFieldDescriptions?: RemitFileFieldDescription[]
  },
  errors: ErrorsMessages,
  remitPreview: string,
  remitFilePredefinedFunctionsDescriptions: {
    date?: number,
    filePredefinedFunctionsDescriptions?: RemitFilePredefinedFunctionsDescription[]
  },
}

export const defaultMetadata = {
  metadata: { isOpen: false, errors: {} },
  selectedRadioButton: RadioButtons.defaultValue
};

export const initialState: State = {
  remitConfiguration: {
    fileIdentifier: "New_Remit_File",
    dateFormat: "yyMMdd",
    cardFilter: enumToMultiSelectOptions(EnumCardBrand),
    transactionFilter: enumToMultiSelectOptions(TransactionTypesEnum, TransactionTypesEnumLabels),
    tenderFilter: enumToMultiSelectOptions(TenderTypeEnum, TenderTypeLabels),
    paymentSources: enumToMultiSelectOptions(PaymentSourceEnum),
    aggregateSettings: [{ name: "default aggregate setting", ...defaultMetadata, position: 1}],
    bodyColumnSettings: [{ name: "default body setting", ...defaultMetadata, position: 1 }],
    footerColumnSettings: [{ name: "default footer setting", ...defaultMetadata, position: 1 }],
    headersColumnSettings: [{ name: "default header setting", ...defaultMetadata, position: 1 }]
  },
  remitConfigurations: [],
  remitPredefinedData: {},
  remitFileFieldDescriptions: {},
  errors: {},
  remitPreview : "",
  remitFilePredefinedFunctionsDescriptions:{}
};

const reducerSlice = createSlice({
  name: 'RemitConfiguration',
  initialState,
  reducers: {
    setRemitPreview(state, action:PayloadAction<string>){
      state.remitPreview = action.payload;
    },
    setRemitConfiguration(state, action: PayloadAction<RemitConfiguration>) {
      state.remitConfiguration = action.payload;
    },
    setRemitConfigurations(state, action: PayloadAction<RemitConfiguration[]>) {
      state.remitConfigurations = action.payload;
    },
    addSetting(state, action: PayloadAction<{ key: keyof RemitConfigurationSettings, index: number, value: Setting }>) {
      const { key, index, value } = action.payload
      if (index < 0 || index > state.remitConfiguration[key].length) return;
      state.remitConfiguration[key].splice(index+1, 0, { ...value, position: index+2} );
    },
    removeSetting(state, action: PayloadAction<{ key: keyof RemitConfigurationSettings, index: number }>) {
      const { key, index } = action.payload;
      if (index < 0 || index > state.remitConfiguration[key].length) return;
      state.remitConfiguration[key].splice(index, 1);
      state.remitConfiguration[key].forEach((setting, idx) => {
        setting.position = idx+1
      })
    },
    toggleIsOpen(state, action: PayloadAction<{ key: keyof RemitConfigurationSettings, index: number}>) {
      const { key, index } = action.payload;
      if (index < 0 || index > state.remitConfiguration[key].length) return;
      if (!state.remitConfiguration[key][index].metadata) {
        state.remitConfiguration[key][index].metadata = {};
      }
      const isOpen = state.remitConfiguration[key][index].metadata!.isOpen;
      state.remitConfiguration[key][index].metadata!.isOpen = !isOpen;
    },
    moveSetting(state, action: PayloadAction<{ key: keyof RemitConfigurationSettings, from: number, to: number }>) {
      const { key, from, to } = action.payload;
      const length = state.remitConfiguration[key].length;
      if (to > -1 && to < length &&
        from > -1 && from < length) {
        state.remitConfiguration[key][to].position = from+1;
        let element = state.remitConfiguration[key].splice(from, 1)[0];
        state.remitConfiguration[key].splice(to, 0, { ...element, position: to+1 });
      }
    },
    setSetting(state, action: PayloadAction<{ key: keyof RemitConfigurationSettings, index: number, setting: Setting }>) {
      const { key, index, setting } = action.payload;
      if (!state.remitConfiguration[key][index]) return;
      const metadata = state.remitConfiguration[key][index].metadata;
      state.remitConfiguration[key][index] = {
        ...setting,
        metadata
      };
    },
    addSettingError(state, action: PayloadAction<{ settingKey: keyof RemitConfigurationSettings, index: number, errors: ErrorsMessages }>) {
      const { settingKey, index, errors } = action.payload;
      if (!state.remitConfiguration[settingKey][index]) return;
      if (!state.remitConfiguration[settingKey][index].metadata) {
        state.remitConfiguration[settingKey][index].metadata = {};
      }
      state.remitConfiguration[settingKey][index].metadata!.errors = errors;
    },
    removeSettingError(state, action: PayloadAction<{
      settingKey: keyof RemitConfigurationSettings, index: number, key: string, validatorType: string
    }>) {
      const { settingKey, index, key, validatorType } = action.payload;
      const setting = state.remitConfiguration[settingKey][index];
      if (!setting) return;
      const fieldErrors = setting.metadata?.errors?.[key];
      if (fieldErrors) {
        delete fieldErrors[validatorType];
      }
    },
    addError(state, action: PayloadAction<ErrorsMessages>) {
      state.errors = {
        ...state.errors,
        ...action.payload,
      }
    },
    removeError(state, action: PayloadAction<{
      key: string,
      validatorType: string
    }>) {
      const { key, validatorType } = action.payload;
      const error = state.errors[key];
      if (error) {
        delete error[validatorType];
      }
    },
    setRemitPredefinetData(state, action: PayloadAction<RemitPredefinedData[]>) {
      state.remitPredefinedData = {
        date: Date.now(),
        predefinedData: action.payload
      }
    },
    setRemitFileFieldDescriptions(state, action: PayloadAction<RemitFileFieldDescription[]>) {
      state.remitFileFieldDescriptions = {
        date: Date.now(),
        fileFieldDescriptions: action.payload
      }
    },
    setRemitFilePredefinedFunctionsDescriptions(state, action: PayloadAction<RemitFilePredefinedFunctionsDescription[]>) {
      state.remitFilePredefinedFunctionsDescriptions = {
        date: Date.now(),
        filePredefinedFunctionsDescriptions: action.payload
      }
    },
  },
});

export const {
  setRemitConfiguration,
  setRemitConfigurations,
  addSetting,
  removeSetting,
  toggleIsOpen,
  moveSetting,
  setSetting,
  addSettingError,
  removeSettingError,
  addError,
  removeError,
  setRemitPredefinetData,
  setRemitFileFieldDescriptions,
  setRemitFilePredefinedFunctionsDescriptions,
  setRemitPreview
} = reducerSlice.actions;
export default reducerSlice.reducer;


export function getRemitPredefinedData(): AppThunk {
  return async (dispatch, getState) => {
    const remitPredefinedData = getState().remitConfiguration.remitPredefinedData;
    await runCallbackOnDateInPastAsync(async () => {
      const responses = await Promise.all([
        GetRemitPredefinedData(PredefinedDataType.Formatting),
        GetRemitPredefinedData(PredefinedDataType.ReplaceRegex),
      ]);

      const predefinedData = responses.filter(res => !res?.err && res?.result)
      .map(res => res.result)
      .reduce((prevValue, currValue) => [...prevValue!, ...currValue!], []);      

      if (predefinedData?.length) {
        dispatch(setRemitPredefinetData(predefinedData));
      }
    }, remitPredefinedData?.date, TimeInMiliSeconst.OneHour);
  };
}

export function getRemitFileFieldDescriptions(): AppThunk {
  return async (dispatch, getState) => {
    const remitFileFieldDescriptions = getState().remitConfiguration.remitFileFieldDescriptions;

    await runCallbackOnDateInPastAsync(async () => {
      const response = await GetRemitFileFieldDescriptions();

      if (!response.err && response.result?.length) {
        dispatch(setRemitFileFieldDescriptions(response.result));
      }
    }, remitFileFieldDescriptions?.date, TimeInMiliSeconst.OneHour);
  };
}

export function getRemitFilePredefinedFunctionsDescriptions(): AppThunk {
  return async (dispatch, getState) => {
    const remitFilePredefinedFunctionsDescriptions = getState().remitConfiguration.remitFilePredefinedFunctionsDescriptions;
    await runCallbackOnDateInPastAsync(async () => {
      const response = await GetRemitFilePredefinedFunctionsDescriptions();

      if (response.result?.length) {
        dispatch(setRemitFilePredefinedFunctionsDescriptions(response.result));
      }
    }, remitFilePredefinedFunctionsDescriptions?.date, TimeInMiliSeconst.OneHour);
  };
}