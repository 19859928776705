import { RemitConfiguration } from "../../../../models/admin/RemitConfiguration";
import { GenericAttribute, GenericValidator } from "models/metaData/MetaData";
import { DataType, ValidatorType } from "models/metaData/MetaDataEnums";
import { required } from "features/paymentDashboard/Validators";
import useTypedValidator from "../../../../utils/useTypedValidator";
import { useTypedSelector } from "../../../../app/rootReducer";
import { addError, removeError } from "../RemitConfigurationReducer";
import { breakFileName } from "../../../../utils/Utils";

export const allowedExtensions = [".txt", ".835", ".csv", ".ERA", ".kbx", ".CTL", ".pmt", ".dat", ".pmn", ".crr", ".cpa", ".DUC", ".tgl", ".xsv", ".Z10", ".CGI", ".710", ".DON", ".cx", ".pcrr", ".pcpa", ".lckbx"];

export default function useGeneralSettingValidator(configuration?: RemitConfiguration) {
  const remitConfigurations = useTypedSelector(s => s.remitConfiguration.remitConfigurations);
  const filteredConfigurations = remitConfigurations?.filter(x=>x.id!==configuration?.id);
  const remitFileNames = filteredConfigurations?.map(remitConf=>remitConf?.fileName!)
  const errors = useTypedSelector(s => s.remitConfiguration.errors);
  const attributes: GenericAttribute<RemitConfiguration>[] = [
    { name: "fileIdentifier", dataType: DataType.String, validators: [required(), fileIdentifierValidator()], visible:true},
    {
      name: "fileName", dataType: DataType.String, validators: [required(), fileName(), endsInExtension(allowedExtensions), fileNameExists()], visible:true
    },
  ];

  function fileNameExists<T>(): GenericValidator<T> {
    return {
      name: ValidatorType.FileNameValidator,
      custom: (props) => {
        const { value, onFail, onPass } = props;
        if (!value) {
          return onPass && onPass();
        }

        const brokenFileName = breakFileName(value?.toString())    
        if (remitFileNames.indexOf(`${brokenFileName.fileName}${brokenFileName.fileExtension}`)==-1){
          return onPass && onPass();
        }
        onFail && onFail();
      },
      message: `File name should be unique`
    }
  }

  function fileName<T>(): GenericValidator<T> {
    return {
      name: ValidatorType.FileNameValidator,
      custom: (props) => {
        const { value, onFail, onPass } = props;
        const { fileName: name } = breakFileName(value?.toString());
        
        if (name?.length) {
          return onPass && onPass();
        }
        onFail && onFail();
      },
      message: `Invalid file name`
    }
  }

  function endsInExtension<T>(extensions: string[]): GenericValidator<T> {
    return {
      name: ValidatorType.FileIdentifierValidator,
      custom: (props) => {
        const { value, onFail, onPass } = props;
        const { fileExtension } = breakFileName(value?.toString())
        if (fileExtension && extensions.includes(fileExtension)) {
          return onPass && onPass();
        }
        onFail && onFail();
      },
      message: `The entered extension is not allowed. See a list of allowed file extensions in the tooltip.`
    }
  }

  function fileIdentifierValidator<T>(): GenericValidator<T> {
    return {
      name: ValidatorType.FileIdentifierValidator,
      custom: (props) => {
        const { value, onFail, onPass } = props;
        if (filteredConfigurations.find(c => c.fileIdentifier == value)) {
          return onFail && onFail();
        }
        onPass && onPass();
      },
      message: "Must be unique"
    }
  }

  return useTypedValidator({
    attributes,
    removeError,
    addError,
    entity: configuration,
    errors
  });
}