import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import IdleTimeoutModal from './IdleTimeoutModal';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import { GetRefreshToken, GetUserDetailService } from 'services/UsersService';
import { setTokenInformation } from 'pages/Loginpage/reducers/TokenInfoReducer';
import { setLoggedUser } from 'pages/Loginpage/reducers/LoginPageReducer';
import { jwtUserDetailDecoder } from 'utils/Utils';
import { useCallService } from '../../services/useCallService';

export default (props: {
  idleTimeOut: number;
  tokenRefreshTimeout: number;
  logOut: () => void;
}) => {
  const callService = useCallService();
  const defaultSeconds = 60;
  const dispatch = useDispatch();
  const [shouldHandleAction, setShouldHandleAction] = useState(false);
  const isProcessing = useTypedSelector(s => s.services.calls.getRefreshToken?.isProcessing);
  const loggedUser = useTypedSelector(s => s.loginInfo.loggedUser);
  const [isTimeout, setIsIdleTimeout] = useState(false);
  const [seconds, setSeconds] = useState(defaultSeconds);
  const [modal, setModal] = useState(false);
  const { logOut, idleTimeOut, tokenRefreshTimeout } = props;
  const calculateTokenRefreshesAt = () => {
    return new Date(Date.now() + tokenRefreshTimeout);
  };
  const [tokenRefreshessAt, setTokenRefreshesAt] = useState(
    calculateTokenRefreshesAt
  );

  const handleOnIdle = (event: any) => {
    if (!isTimeout && !modal) {
      setIsIdleTimeout(true);
      setSeconds(defaultSeconds);
      setModal(true);
    }
  };

  const handleOnActive = (event: any) => {
    setIsIdleTimeout(false);
  };

  useEffect(() => {
    if (shouldHandleAction) {
      setShouldHandleAction(false);
      handleOnAction();
    }
  }, [shouldHandleAction]);

  const handleOnAction = () => {
    if (new Date(Date.now()) > tokenRefreshessAt && !modal) {
      extendSession();
    }

    setIsIdleTimeout(false);
  };

  function updateTokenState(trimToken: string) {
    dispatch(
      setTokenInformation({
          access_token: trimToken,
          expires_in: 899,
          refresh_token: trimToken,
          token_type: 'bearer',
      })
    );
  }

  async function refreshToken() {
    if (isProcessing) return;
    callService("getRefreshToken", async () => {
      const response = await GetRefreshToken()
      if (response.result) {
        const decodedToken = atob(response.result);
        let tokenSeparator = '|';
        const returnedToken = decodedToken.substring(
          0,
          decodedToken.lastIndexOf(tokenSeparator) + tokenSeparator.length
        );
        const token = decodedToken.substring(returnedToken.length);
        const trimToken = token
          .replace("'", '')
          .replace('"', '')
          .trim();
        const partialUserModel = jwtUserDetailDecoder(trimToken);

        GetUserDetailService('', '', partialUserModel.realmUserId)
        .then((userResponse) =>{
          const newUserDetails = (userResponse.result ?? [])[0];
          if(JSON.stringify(newUserDetails) !== JSON.stringify(loggedUser)){
            dispatch(setLoggedUser(newUserDetails));
          }
        });

        localStorage.setItem('authToken', trimToken);
        localStorage.setItem('refreshToken', trimToken);
        updateTokenState(trimToken);
        setTokenRefreshesAt(calculateTokenRefreshesAt());
      } else {
        alert && alert('There was a problem while extending the session or it expired.');
        logOut();
        //for local use of authentication
        //const authURL = 'https://localhost:5000/api/v1/Authentication/send';

        const authURL = window?.location?.origin + '/api/v1/Authentication/send';
        window?.location?.replace(`${authURL}?returnUrl=${window.location.href}`);
      }
    })
  }

  useEffect(() => {
    let interval: any;
    if (modal && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (modal) {
      onLogOut();
    }
    return () => {
      clearInterval(interval);
    };
  }, [modal, seconds]);

  useIdleTimer({
    timeout: idleTimeOut,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: () => setShouldHandleAction(true),
    debounce: 500,
  });

  const onLogOut = () => {
    setModal(false);
    logOut();
  };

  const extendSession = async () => {
    setModal(false);
    await refreshToken();
  };

  useEffect(() => {
    handleOnAction();
  }, []);

  return (
    <div>
      <IdleTimeoutModal
        seconds={seconds}
        isOpen={modal}
        logOut={onLogOut}
        extendSession={extendSession}
      />
    </div>
  );
};
