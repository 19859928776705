import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../../app/rootReducer';
import UserPermissions from '../../../../../models/enums/UserPermissions';
import { PermissionItem } from '../../../../../models/Permission';
import { GetPermissions } from '../../../../../services/PermissionsService';
import { setPermissions } from '../../UsersAndPermissionsReducer';
import { getAdminPermissions } from 'utils/Utils';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import { permissionsMenuModes } from '../UserPermissionsMenu';
export function usePermissionsMenuMode() {
  const modeState = useState<permissionsMenuModes>(
    permissionsMenuModes.view
  );
  return modeState
}

export const labels = {
  checkAllPayment: 'checkAllPayment',
  checkAllPaymentPlans: 'checkAllPaymentPlans',
  checkAllGeneralLedger: 'checkAllGeneralLedger',
  checkAllPSafeAdmin: 'checkAllPSafeAdmin',
  checkAllEpic: 'checkAllEpic',
};

export default function usePermissionsState() {
  const dispatch = useDispatch();
  const { hasPermissionOrSuperAdmin } = useLoggedUserPermissions();
  const [selectedItems, setSelectedItems] = useState(
    new Set<string | undefined>()
  );
  const permissions = useTypedSelector(s => s.usersAndPermissions.permissions);
  const sspUserPermission = hasPermissionOrSuperAdmin(UserPermissions.SelfServicePortal);
  const isAdmin = hasPermissionOrSuperAdmin(UserPermissions.FinancialAdministation);

  const retrievePermissions = async () => {
    const response = await GetPermissions();

    if (response.result) {
      dispatch(
        setPermissions({
          permissions: response.result.map(p => ({
            ...p,
            id: UserPermissions[p.id]?.toString(),
          })),
        })
      );
    }
  };

  const paymentPermissions = permissions?.filter(
    item => item.groupName === 'Payments'
  );
  const paymentPlansPermissions = permissions?.filter(
    item => item.groupName === 'Payment Plans'
  );
  const generalLegderPermissions = permissions?.filter(
    item => item.groupName === 'General Ledger (GL)'
  );
  const pSafeAdminPermissions = getAdminPermissions(isAdmin, sspUserPermission, permissions);

  const epicPermissions = permissions?.filter(
    item => item.groupName === 'Epic'
  );

  const selectAllPermissions = (
    newSelectedItems: Set<string | undefined>,
    itemKey?: string
  ) => {
    let permissions: PermissionItem[] | undefined = [];

    if (itemKey == labels.checkAllPayment) {
      permissions = paymentPermissions;
    } else if (itemKey == labels.checkAllPaymentPlans) {
      permissions = paymentPlansPermissions;
    } else if (itemKey == labels.checkAllGeneralLedger) {
      permissions = generalLegderPermissions;
    } else if (itemKey == labels.checkAllPSafeAdmin) {
      permissions = pSafeAdminPermissions;
    } else if (itemKey == labels.checkAllEpic) {
      permissions = epicPermissions;
    }

    permissions?.map(item => {
      if (!newSelectedItems.has(item.id)) {
        newSelectedItems.add(item.id);
      } else {
        newSelectedItems.delete(item.id);
      }
      return item;
    });

    setSelectedItems(newSelectedItems);
  };

  const handleChange = (itemKey?: string) => {
    const newSelectedItems = new Set(selectedItems);
    const allLabels = [
      labels.checkAllPayment,
      labels.checkAllPaymentPlans,
      labels.checkAllGeneralLedger,
      labels.checkAllPSafeAdmin,
      labels.checkAllEpic,
    ];
    if (allLabels.includes(itemKey!)) {
      selectAllPermissions(newSelectedItems, itemKey);
    } else {
      if (!newSelectedItems.has(itemKey)) {
        newSelectedItems.add(itemKey);
      } else {
        newSelectedItems.delete(itemKey);
      }
      setSelectedItems(newSelectedItems);
    }
  };

  const clearSelection = () => {
    setSelectedItems(new Set());
  };

  return {
    clearSelection,
    retrievePermissions,
    selectedItems,
    handleChange,
    paymentPermissions,
    paymentPlansPermissions,
    generalLegderPermissions,
    pSafeAdminPermissions,
    epicPermissions,
    selectAllPermissions,
  };
}
