import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getBalance } from 'features/paymentPlan/useTotal';
import { LineItem, LineItems } from 'models/LineItem';
import { MetaData } from 'models/metaData/MetaData';
import { ValidatorType } from '../../models/metaData/MetaDataEnums';
import { GlDisplayField, PaymentConfiguration } from '../../models/PaymentsConfiguration';
import { displayAmount } from '../../utils/Utils';
import { v4 as uuid } from 'uuid';
export interface Value {
  paymentTotal: number;
  lineItems: LineItems;
  isGl: boolean;
}

export type LineItemError = {
  [key: string]: { [key: string]: string };
};

export interface Errors {
  [key: string]: LineItemError,
}

export interface State {  
  values: { [organization : string] : Value };
  panelConfigurations: {
    [key: string]: { 
      configuration: MetaData | undefined
    };
  },
  configurations: {
    [key: string]: { 
      date: number; 
      reload: boolean;
      configuration: PaymentConfiguration 
    };
  },
  isPaymentType: boolean,
  isStay: boolean,
  isPayingNow?: boolean;
  isScreenDisabled?: boolean;
  errors: Errors; 
}

export const initialState: State = {
    values: {
      
    },
    panelConfigurations: {},
    configurations: {},
    errors: {},
    isPaymentType: true,
    isStay: false,
    isPayingNow: false,
    isScreenDisabled: false
};

function updatePaymentTotal(state: State, organizationPath: string) {
  const lineItems = state.values[organizationPath] ? state.values[organizationPath]?.lineItems : [];
  // calculate total amount
  const totalAmount =   lineItems.reduce(
    (a, v) => (a + getBalance({...v}, state.values[organizationPath].isGl)) , 0
  );

  if(state.values[organizationPath]){
    state.values[organizationPath].paymentTotal = + displayAmount(totalAmount);
  }
}

const reducerSlice = createSlice({
  name: 'PaymentDashboard',
  initialState,
  reducers: {
    setPaymentDashboardState(state, action: PayloadAction<{ values: { [organization : string] : Value }; organizationPath: string; }>) {
      state.values = action.payload.values;
    },
    setPaymentDashboardInitialState(state, action: PayloadAction<{ value: Value; organizationPath: string; }>) {
      const values = state.values;
      if(action.payload.organizationPath && values[action.payload.organizationPath]){
        values[action.payload.organizationPath]= action.payload.value;
        state.values = values;
      }
    },
    addPaymentDashboardState(state, action: PayloadAction<{ value: Value; organizationPath: string; }>) {
      const values = state.values;
      if(action.payload.organizationPath && !values[action.payload.organizationPath]){
        values[action.payload.organizationPath]= action.payload.value;
        state.values = values;
      }
    },
    setLineItemAttribute(
      state,
      action: PayloadAction<{
        lineItemId?: string;
        attributeName: keyof LineItem;
        value: any;
        organizationPath: string;
      }>
    ) {
      const { lineItemId, attributeName, value, organizationPath } = action.payload;
      let lineItemIdx = state.values[organizationPath]?.lineItems.findIndex(l => l.id === lineItemId);
      let lineItem = state.values[organizationPath]?.lineItems[lineItemIdx];
      if (lineItem) {
        lineItem[attributeName] = value;
        state.values[organizationPath].lineItems[lineItemIdx] = lineItem
      }
      updatePaymentTotal(state, organizationPath);
    },
    updateLineItem(
      state,
      action: PayloadAction<{
        organizationPath: string;
        lineItemId?: string;
        value: LineItem;
      }>
    ) {
      const { lineItemId, value } = action.payload;
      let lineItemIdx = state.values[action.payload.organizationPath].lineItems.findIndex(l => l.id === lineItemId);
      let lineItem = state.values[action.payload.organizationPath].lineItems[lineItemIdx];
      if (lineItem) {
        lineItem = {
          ...lineItem,
          ...value
        }
        state.values[action.payload.organizationPath].lineItems[lineItemIdx] = lineItem
      }

      updatePaymentTotal(state, action.payload.organizationPath);
    },
    addBlankLineItem(
      state,
      action: PayloadAction<{
        organizationPath: string;
      }>
    ) {
      const id =uuid();
      state.values[action.payload.organizationPath]?.lineItems?.unshift({
        id,
        amount: -0,
        units: 1,
        isActive: true,
        isEditing: true,
        isContracted: true,
        isNew: true,
        pahAccount: false
      });
      updatePaymentTotal(state, action.payload.organizationPath);
    },
    addLineItem(state, action: PayloadAction<{ organizationPath : string; lineItem : LineItem}>) {
      const lineItem = action.payload.lineItem;
      state.values[action.payload.organizationPath].lineItems.push(lineItem);
      updatePaymentTotal(state, action.payload.organizationPath);
    },
    removeLineItem(state, action: PayloadAction<{ id : string | undefined; organizationPath: string; }>) {
      const index = state.values[action.payload.organizationPath]?.lineItems.findIndex(l => l.id === action.payload.id);
      if (index > -1) {
        state.values[action.payload.organizationPath]?.lineItems.splice(index, 1);
        updatePaymentTotal(state, action.payload.organizationPath);
      }
    },
    replaceLineItems(state, action: PayloadAction<{ lineItems : LineItem[]; organizationPath: string;}>) {
      if(state.values[action.payload.organizationPath]){
        state.values[action.payload.organizationPath].lineItems = action.payload.lineItems;
      }
      updatePaymentTotal(state, action.payload.organizationPath);
    },
    addError(state, action: PayloadAction<{ id?: string, error: LineItemError }>) {
      if (action.payload.id) {
        state.errors[action.payload.id] = {
          ...state.errors[action.payload.id],
          ...action.payload.error
        }
      }
    },
    removeError(state, action: PayloadAction<{ id?: string, key: keyof LineItem, validatorType: ValidatorType }>) {
      if (action.payload.id) {
        const lineItemErrors = state.errors[action.payload.id];
        if (lineItemErrors && lineItemErrors[action.payload.key]) {
          delete lineItemErrors[action.payload.key][action.payload.validatorType];
        }
      }
    },
    setConfiguration(
      state,
      action: PayloadAction<{
        organizationPath: string;
        configuration: PaymentConfiguration;
      }>
    ) {
      state.configurations = {
        ...state.configurations,
        [action.payload.organizationPath]: {
          date: Date.now(),
          reload: false,
          configuration: action.payload.configuration,
        },
      };
    },
    setPanelConfiguration(
      state,
      action: PayloadAction<{
        organizationPath: string;
        configuration: MetaData | undefined;
      }>
    ) {
      if(action.payload.configuration?.entity?.organization?.path ){
        state.panelConfigurations = {
          ...state.panelConfigurations,
          [action.payload.configuration?.entity?.organization?.path]: {
            configuration: action.payload.configuration,
          },
        };
      }
    },
    setIsGl(state, action: PayloadAction<{ organizationPath: string; isGl : boolean }>) {
      if(state.values[action.payload.organizationPath]){
        state.values[action.payload.organizationPath].isGl = action.payload.isGl;
      }
    },
    setConfigurationReload(state, action: PayloadAction<{
      organizationPath: string,
      reload: boolean
    }>) {
      state.configurations = {
        ...state.configurations,
        [action.payload.organizationPath]: {
          ...state.configurations[action.payload.organizationPath],
          reload: action.payload.reload,
        },
      };
    },
    setGlFields(
      state,
      action: PayloadAction<{
        organizationPath: string;
        glFields: GlDisplayField[];
      }>
    ) {
      const { organizationPath, glFields } = action.payload;
      const configurationData = state.configurations[organizationPath];
      const configuration = configurationData.configuration;
      state.configurations = {
        ...state.configurations,
        [organizationPath]: {
          ...configurationData,
          configuration: {
            ...configuration,
            glDisplayFields: glFields
          }
        },
      };
    },
    setIsPaymentType(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isPaymentType = action.payload
    },
    setIsStay(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isStay = action.payload
    },
    setIsPayingNow: (
      state,
      action: PayloadAction<boolean>) => {
        state.isPayingNow = action.payload;
      },
    setIsScreenDisabled: (
      state,
      action: PayloadAction<boolean>) => {
        state.isScreenDisabled = action.payload;
      },
    setInitialState: () => initialState,
  },
});

export const {
  setPaymentDashboardState,
  setPaymentDashboardInitialState,
  addPaymentDashboardState,
  setLineItemAttribute,
  updateLineItem,
  addBlankLineItem,
  addLineItem,
  removeLineItem,
  replaceLineItems,
  addError,
  removeError,
  setConfiguration,
  setPanelConfiguration,
  setIsGl,
  setConfigurationReload,
  setInitialState,
  setGlFields,
  setIsPaymentType,
  setIsStay,
  setIsPayingNow,
  setIsScreenDisabled
} = reducerSlice.actions;
export default reducerSlice.reducer;
