import { useDispatch } from "react-redux";
import { useTypedSelector } from "../app/rootReducer"
import { setLoggedUser } from "../pages/Loginpage/reducers/LoginPageReducer";
import { ImpersonateUserService, RemoveImpersonateUserService } from "../services/UsersService";
import { UserDetailModel } from "models/UserInfoAndRolesModel";
import { OrganizationLevelDocument } from "models/OrganizationLevelDocument";

export const useUserUtils = () => {
  const users = useTypedSelector(s => s.userInfo?.users);
  const loggedUser = useTypedSelector(s => s.loginInfo?.loggedUser);

  const getUserById = (id: string) => {
    return users?.find(u => u.id == id);
  }

  const getUserByOneSourceId = (oneSourceId: string) => {
    return users?.find(u => u.oneSourceUserId == oneSourceId);
  }

  const redirectUserToStoredUrl = () => {
    const redirectUrl = localStorage?.getItem("redirectUrl");
    if (redirectUrl) {
      window?.location?.replace(redirectUrl);
    }
  }

  const redirectUserToHome = () => {
    window?.location?.replace(`/landing`);
  }

  const redirectUserToNoPermissions = () => {
    window.location.replace(`/no-permissions`);
  }

  const getLoggedUserOrganizations = () => {
    return loggedUser?.impersonatedUserOneSourceId ? loggedUser?.impersonatedOrganizationLevels : loggedUser?.organizationLevels;
  }

  const getDefaultFacility = () => {
    return loggedUser?.defaultFacility as OrganizationLevelDocument;
  }

  const getDefaultDepartment = () => {
    return loggedUser?.defaultDepartment as OrganizationLevelDocument;
  }

  return {
    getUserById,
    getUserByOneSourceId,
    redirectUserToStoredUrl,
    redirectUserToHome,
    redirectUserToNoPermissions,
    getLoggedUserOrganizations,
    getDefaultFacility,
    getDefaultDepartment
  }
}

export const useImpersonateUser = () => {
  const dispatch = useDispatch();

  //do not pass id to end current impersonation
  return async (impersonationId?: string): Promise<UserDetailModel | undefined> => {
    const data = await ImpersonateUserService(impersonationId ?? "\"\"");
    const impersonatedUser = data.result?.userDetail;
    impersonatedUser && dispatch(setLoggedUser(impersonatedUser));

    return Promise.resolve(impersonatedUser);
  }
}

export const useRemoveImpersonateUser = () => {
  const dispatch = useDispatch();

  //do not pass id to end current impersonation
  return async (adminOneSourceId?: string) => {
    const data = await RemoveImpersonateUserService(adminOneSourceId ?? "");
    const adminUser = data.result?.userDetail;
    adminUser && dispatch(setLoggedUser(adminUser));
    window?.location?.replace(`/landing`);
  }
}