import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTypedSelector } from 'app/rootReducer';
import { setSidepaneLink } from 'features/sidepane/SidepaneReducer';
import { GetUserSettings, PutUserSettings } from 'services/UsersService';
import Searchpage from './Searchpage';
import SimpleSearch from './simpleSearch/Simplesearch';
import AdvanceSearch from './advanceSearch/AdvanceSearch';
import PaymentPlanSearch from './planSearch/PaymentPlanSearch';
import VolumeSearch from './volumeSearch/Volumesearch';
import { updateRecord as planSetSettings, updateSaveSettings, initialState as paymentPlanInitialState } from './planSearch/PaymentPlanSearchReducer';
import { setAdvanceSearchInformation, setSaveSettings as advanceSetSettings, initialState as advancedInitialState } from './advanceSearch/AdvanceSearchReducer';
import { setOrganizationUSerSavedSettings, setSaveSettings, setSimpleSearchInfoState, initialState as simpleInitialState } from './simpleSearch/SimpleSearchReducer';
import { setLoggedUserSettings } from 'pages/Loginpage/reducers/LoginPageReducer';
import { setCustomSearchColumnsState, setCustomSearchPlanColumnsState } from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';
import { getOrganizationOptions, useGetUserDepartmentsOptions, useGetUsersOptions } from './simpleSearch/OrganizationUserSearch';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { getUserDepartmentOrFacilityesOptions } from 'utils/UtilsOrganizationDropdowns';
import { AlertIds, AlertTypes, setAlert } from '../../features/alert/AlertReducer';
import { ReactComponent as Close } from 'assets/svgs/icon-popup-close.svg';
import "../../assets/styles/components/_searchPaymentsPage.scss";
import { useUserUtils } from 'utils/useUserUtils';
import { getPaymentPlanConfigurationService } from 'services/PaymentPlanService';
import { UserSettings } from 'models/UserInfoAndRolesModel';
import { useHistory } from '../../utils/useHistory';
import { fetchPaymentPlanConfig } from 'utils/Utils';


const extractElementsIds = (elements: any) => {
  return elements.map((u: any) => u.value);
}

const extractElements = (elementsIds: any, allElements: any) => {
  return allElements.filter((u: any) => elementsIds.includes(u.value));
}

export const extractUserData = (userSettings: any, users: any, departments: any, facilities: any, simpleSearchSaveSettingsChecked?: boolean, advancedSearchSaveSettingsChecked?: boolean, paymentPlanSearchSaveSettingsChecked?: boolean) => {
  const newUserSettings = {...userSettings};
  const initialSearchCriteria = {facilities: facilities, departments: departments, users: []};
  if(userSettings.simpleSearchCriteria){
    const storedSimpleSearchCriteria = {facilities: extractElements(userSettings.simpleSearchCriteria.facilities, facilities), departments: extractElements(userSettings.simpleSearchCriteria.departments, departments), users: extractElements(userSettings.simpleSearchCriteria.users, users)};
    const selectedSimpleSearchCriteria = simpleSearchSaveSettingsChecked ? storedSimpleSearchCriteria : initialSearchCriteria;
    newUserSettings.simpleSearchCriteria = {
        ...userSettings.simpleSearchCriteria,
        facilities: selectedSimpleSearchCriteria.facilities,
        departments: selectedSimpleSearchCriteria.departments,
        users: selectedSimpleSearchCriteria.users
      }
  }
  if(userSettings.advancedSearchCriteria){
    const storedAdvancedSearchCriteria = {facilities: extractElements(userSettings.advancedSearchCriteria.facilities, facilities), departments: extractElements(userSettings.advancedSearchCriteria.departments, departments), users: extractElements(userSettings.advancedSearchCriteria.users, users)};
    const selectedAdvancedSearchCriteria = advancedSearchSaveSettingsChecked ? storedAdvancedSearchCriteria : initialSearchCriteria;
    newUserSettings.advancedSearchCriteria = {
        ...userSettings.advancedSearchCriteria,
        facilities: selectedAdvancedSearchCriteria.facilities,
        departments: selectedAdvancedSearchCriteria.departments,
        users: selectedAdvancedSearchCriteria.users
      }
  }
  if(userSettings.planSearchCriteria){
    const storedPlanSearchCriteria = {facilities: extractElements(userSettings.planSearchCriteria.facilities, facilities), departments: extractElements(userSettings.planSearchCriteria.departments, departments), users: extractElements(userSettings.planSearchCriteria.users, users)};
    const selectedPlanSearchCriteria = paymentPlanSearchSaveSettingsChecked ? storedPlanSearchCriteria : initialSearchCriteria;
    newUserSettings.planSearchCriteria = {
        ...userSettings.planSearchCriteria,
        facilities: selectedPlanSearchCriteria.facilities,
        departments: selectedPlanSearchCriteria.departments,
        users: selectedPlanSearchCriteria.users
      }
  }
  return newUserSettings;
}

export const useGetUserSettings = () => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem('id');
  const users = useTypedSelector(s => s.userInfo.users);
  const loggedUser = useTypedSelector(s => s.loginInfo.loggedUser);
  const userSettings = loggedUser?.userSettings;
  const simpleSearchInfo = useTypedSelector(s => s.simpleSearchInfo);
  const advanceSearchInfo = useTypedSelector(s => s.advanceSearchInfo);
  const paymentPlanSearchState = useTypedSelector(s => s.paymentPlanSearchInfo);
  const organizations = useTypedSelector(p => p.organizations?.value);
  const { useGetFacilities } = useOrganizations();
  const allFacilities = useGetFacilities();
  const { getLoggedUserOrganizations } = useUserUtils();
  const facilities = allFacilities;
  const userOrganisations = getLoggedUserOrganizations();
  const userFacilityOptions =  getUserDepartmentOrFacilityesOptions(facilities, userOrganisations)
  const facilityOptions = getOrganizationOptions( userFacilityOptions);
  const getUserDepartmentsOptions = useGetUserDepartmentsOptions();
  const departmentsOptions = getUserDepartmentsOptions(userOrganisations);
  const { getUsersOptions, searchUsers } = useGetUsersOptions();
  const usersOptions = getUsersOptions([], users);
  const simpleSearchSaveSettingsChecked = useTypedSelector(s=> s.simpleSearchInfo?.saveSettings ?? false);
  const advancedSearchSaveSettingsChecked = useTypedSelector(s=> s.advanceSearchInfo?.saveSettings ?? false);
  const paymentPlanSearchSaveSettingsChecked = useTypedSelector(s=> s.paymentPlanSearchInfo?.saveSettings ?? false);


  const validateSession = (userSaved: any) => {
    if(sessionStorage.simpleSearchCriteria || sessionStorage.advancedSearchCriteria || sessionStorage.planSearchCriteria){
      if(sessionStorage.simpleSearchCriteria && !simpleSearchSaveSettingsChecked){
        let data = JSON.parse(sessionStorage.simpleSearchCriteria);
        dispatch(setSimpleSearchInfoState({...simpleSearchInfo.value ,...userSaved.simpleSearchCriteria, ...data}));
      }
      if(sessionStorage.advancedSearchCriteria && !advancedSearchSaveSettingsChecked){
        let data = JSON.parse(sessionStorage.advancedSearchCriteria);
        dispatch(setAdvanceSearchInformation({...advanceSearchInfo.value ,...userSaved.advancedSearchCriteria, ...data}));
      }
      if(sessionStorage.planSearchCriteria && !paymentPlanSearchSaveSettingsChecked){
        let data = JSON.parse(sessionStorage.planSearchCriteria);
        dispatch(planSetSettings({...paymentPlanSearchState.value ,...userSaved.planSearchCriteria, ...data}));
      }
    }
  };
  
  const getUserSettings = useCallback( async (prioritize: boolean = false) => {
    if(!organizations?.length && !prioritize){
      return;
    }
    const userSettingsRequest = GetUserSettings(userId!);
    const response = await userSettingsRequest;
    if (response?.result) {
      const userSettingsFromDb = JSON.parse(response.result?.replace(/\\"/g, '"')?? "");
      const userList = await searchUsers(facilityOptions?.map(facility => facility.value), departmentsOptions?.map(department => department.value));
      const activeUsers = userList?.filter(u => u.isActive);
      const selectedUserList = getUsersOptions(activeUsers ?? [], users);
      const userSaved = extractUserData(userSettingsFromDb, selectedUserList, departmentsOptions, facilityOptions, simpleSearchSaveSettingsChecked, advancedSearchSaveSettingsChecked, paymentPlanSearchSaveSettingsChecked);
      dispatch(setLoggedUserSettings(userSaved));
      if(userSaved?.transactionsResultGrid){
        dispatch(setCustomSearchColumnsState(userSaved.transactionsResultGrid));
      }
      if(userSaved?.planResultsGrid){
        dispatch(setCustomSearchPlanColumnsState(userSaved.planResultsGrid));
      }
      validateSession(userSaved);
    }
  }, [
      organizations?.length,
      userId, 
      simpleSearchInfo?.value?.facilities?.length, 
      advanceSearchInfo?.value?.facilities?.length, 
      paymentPlanSearchState?.value?.facilities?.length, 
      simpleSearchInfo?.value, 
      advanceSearchInfo?.value, 
      paymentPlanSearchState?.value, 
      usersOptions, 
      departmentsOptions, 
      facilityOptions
    ]);
  return {
    getUserSettings,
    userSettings,
    userId
  }
}

export default function SearchPaymentspage() {
  const location = useLocation();
  const userId = localStorage.getItem('id');
  const openClass =
    location.pathname === '/searchpayments' ? 'eds-modal.open' : '';

  const history = useHistory();
  const dispatch = useDispatch();

  const activeTab = useTypedSelector(p => p.simpleSearchInfo?.activeTab);
  const simpleSearchInfo = useTypedSelector(s => s.simpleSearchInfo);
  const advanceSearchInfo = useTypedSelector(s => s.advanceSearchInfo);
  const paymentPlanSearchState = useTypedSelector(s => s.paymentPlanSearchInfo);

  const simpleSearchSaveSettingsChecked = useTypedSelector(s=> s.simpleSearchInfo.saveSettings);
  const advancedSearchSaveSettingsChecked = useTypedSelector(s=> s.advanceSearchInfo.saveSettings);
  const paymentPlanSearchSaveSettingsChecked = useTypedSelector(s=> s.paymentPlanSearchInfo.saveSettings);

  const mapSubmitLinks: { [key: string]: { link: string, state: any, method: any } } = {
    '1': { link: '/simplesearchresults', state: simpleSearchInfo, method: setSaveSettings },
    '2': { link: '/simplesearchresults', state: advanceSearchInfo, method: advanceSetSettings },
    '3': { link: '/plansSearchResult', state: paymentPlanSearchState, method: updateSaveSettings },
  };

  const searchAlert = { id: AlertIds.SearchAlert, type: AlertTypes.Error, message: 'Amount To should be greater than From' };
  const { useGetFacilities } = useOrganizations();
  const allFacilities = useGetFacilities();
  const facilities = allFacilities;
  const { getLoggedUserOrganizations } = useUserUtils();
  const userOrganisations = getLoggedUserOrganizations();
  const userFacilityOptions =  getUserDepartmentOrFacilityesOptions(facilities, userOrganisations)
  const facilityOptions = getOrganizationOptions( userFacilityOptions);
  const getUserDepartmentsOptions = useGetUserDepartmentsOptions();
  const departmentsOptions = getUserDepartmentsOptions(userOrganisations);
  const users = useTypedSelector(s => s.userInfo.users);
  const { getUsersOptions } = useGetUsersOptions();
  const usersOptions = getUsersOptions([], users);
  const [userSettingsFromDb, setUserSettingsFromDb] = useState<any>({});
  const {getUserSettings, userSettings} = useGetUserSettings();
  const accessVolumeSearch = localStorage.getItem('isPowerReportingAccessible') === 'true';
  const [facilitiesWithNullRecord, setFacilitiesWithNullRecord] = useState<any>([]);
  const [showPlanSearch, setShowPlanSearch] = useState<boolean>(true);

  const getUserSettingsFromDb = async () => {
    const response = await GetUserSettings(userId!);
    if(response?.result){
      const userSettingsDb = JSON.parse(response.result?.replace(/\\"/g, '"')?? "");
      setUserSettingsFromDb(userSettingsDb);
    }
  }

  useEffect(() => {
    getUserSettingsFromDb();
    getUserSettings();
  }, [])

  useEffect(() => {
    const fetchAllPaymentPlanConfigs = async () => {
      const planConfigs = await Promise.all(userFacilityOptions.map(facility => fetchPaymentPlanConfig(facility.path)));

      const filteredFacilityWithNullRecord = userFacilityOptions
        .filter((facility, index) => planConfigs[index]?.disablePlansRecord == null);

      setFacilitiesWithNullRecord(filteredFacilityWithNullRecord);
      if(filteredFacilityWithNullRecord && filteredFacilityWithNullRecord.length>0){
        setShowPlanSearch(true);
      }else{
        setShowPlanSearch(false);
      }
    };

    if (userFacilityOptions.length > 0) {
      fetchAllPaymentPlanConfigs();
    }
  }, []);

  const validateSimpleSearchTab = (data: any) => {
    if(simpleSearchSaveSettingsChecked){
      data = {
        ...userSettingsFromDb,
        simpleSearchCriteria: { 
          facilities: extractElementsIds(simpleSearchInfo.value.facilities),
          departments: extractElementsIds(simpleSearchInfo.value.departments),
          users: extractElementsIds(simpleSearchInfo.value.users)
        }
      };
      sessionStorage.setItem('simpleSearchCriteria', JSON.stringify({
        mrn: simpleSearchInfo?.value?.mrn,
        accountNumber: simpleSearchInfo?.value?.accountNumber,
        id: simpleSearchInfo?.value?.id,
        submittedDateTimeMin: simpleSearchInfo?.value?.submittedDateTimeMin,
        submittedDateTimeMax: simpleSearchInfo?.value?.submittedDateTimeMax
      }));
      return data;
    } else {
      sessionStorage.removeItem('simpleSearchCriteria')
    }
    if(simpleSearchInfo.value.facilities.length < 1){
      dispatch(setAlert(searchAlert));
    }
  }

  const validateAdvancedSearchTab = (data: any) => {
    if(advancedSearchSaveSettingsChecked){
      data = {
        ...userSettingsFromDb,
        advancedSearchCriteria: { 
          facilities: extractElementsIds(advanceSearchInfo.value.facilities),
          departments: extractElementsIds(advanceSearchInfo.value.departments),
          users: extractElementsIds(advanceSearchInfo.value.users),
          transactionTypes: advanceSearchInfo.value.transactionTypes,
          tenderTypes: advanceSearchInfo.value.tenderTypes,
          cardEntryTypes: advanceSearchInfo.value.cardEntryTypes,
          paymentStatus: advanceSearchInfo?.value?.paymentStatus
        }
      }
      sessionStorage.setItem('advancedSearchCriteria', JSON.stringify({
        mrn: advanceSearchInfo?.value?.mrn,
        guarantorId: advanceSearchInfo?.value?.guarantorId,
        patientId: advanceSearchInfo?.value?.patientId,
        patientFirstName: advanceSearchInfo?.value?.patientFirstName,
        patientLastName: advanceSearchInfo?.value?.patientLastName,
        payerFirstName: advanceSearchInfo?.value?.payerFirstName,
        payerLastName: advanceSearchInfo?.value?.payerLastName,
        submittedDateTimeMax: advanceSearchInfo?.value?.submittedDateTimeMax,
        submittedDateTimeMin: advanceSearchInfo?.value?.submittedDateTimeMin,
        settledDateTimeMax: advanceSearchInfo?.value?.settledDateTimeMax,
        settledDateTimeMin: advanceSearchInfo?.value?.settledDateTimeMin,
        paymentAmountFrom: advanceSearchInfo?.value?.paymentAmountFrom,
        paymentAmountTo: advanceSearchInfo?.value?.paymentAmountTo,
        discountAmountFrom: advanceSearchInfo?.value?.discountAmountFrom,
        discountAmountTo: advanceSearchInfo?.value?.discountAmountTo,
        transactionID: advanceSearchInfo?.value?.transactionID,
        transactionReferenceId: advanceSearchInfo?.value?.transactionReferenceId,
        accountNumber: advanceSearchInfo?.value?.accountNumber,
        authorizationCode: advanceSearchInfo?.value?.authorizationCode,
        batchID: advanceSearchInfo?.value?.batchID,
        creditCard: advanceSearchInfo?.value?.creditCard,
        paymentStatus: advanceSearchInfo?.value?.paymentStatus,
        resultsStatus: advanceSearchInfo?.value?.resultsStatus,
        paymentPlanId: advanceSearchInfo?.value?.paymentPlanId,
        deviceName: advanceSearchInfo?.value?.deviceName,
        deviceSerialNumber: advanceSearchInfo?.value?.deviceSerialNumber,
        glDescription: advanceSearchInfo?.value?.glDescription,
      }));
      return data;
    } else {
      sessionStorage.removeItem('advancedSearchCriteria')
    }
    if(advanceSearchInfo.value.facilities.length < 1){
      dispatch(setAlert(searchAlert));
    }
  }

  const validatePlanSearchTab = (data: any) => {
    if(paymentPlanSearchSaveSettingsChecked){
      data = {
        ...userSettingsFromDb,
        planSearchCriteria: { 
          facilities: extractElementsIds(paymentPlanSearchState.value.facilities),
          departments: extractElementsIds(paymentPlanSearchState.value.departments),
          users: extractElementsIds(paymentPlanSearchState.value.users),
          tenderTypes: paymentPlanSearchState.value.tenderType,
          status: paymentPlanSearchState?.value?.status
        }
      }
      sessionStorage.setItem('planSearchCriteria', JSON.stringify({
        mrn: paymentPlanSearchState?.value?.mrn,
        guarantorAccountNo: paymentPlanSearchState?.value?.guarantorId,
        patientFirstName: paymentPlanSearchState?.value?.patientFirstName,
        patientLastName: paymentPlanSearchState?.value?.patientLastName,
        payerFirstName: paymentPlanSearchState?.value?.payerFirstName,
        payerLastName: paymentPlanSearchState?.value?.payerLastName,
        amountMax: paymentPlanSearchState?.value?.amountMax,
        amountMin: paymentPlanSearchState?.value?.amountMin,
        createdDateMax: paymentPlanSearchState?.value?.createdDateMax,
        createdDateMin: paymentPlanSearchState?.value?.createdDateMin,
        nextPaymentDateMax: paymentPlanSearchState?.value?.nextPaymentDateMax,
        nextPaymentDateMin: paymentPlanSearchState?.value?.nextPaymentDateMin,
        status: paymentPlanSearchState?.value?.status,
        tenderMaskedAccount: paymentPlanSearchState?.value?.tenderMaskedAccount,
        transactionId: paymentPlanSearchState?.value?.transactionId,
        tenderType: paymentPlanSearchState?.value?.tenderType,
    }));
    return data;
    } else {
      sessionStorage.removeItem('planSearchCriteria')
    }
    if(paymentPlanSearchState.value.facilities.length < 1){
      dispatch(setAlert(searchAlert));
    }
  }
  
  const onSearchButtonClick = async (e: React.MouseEvent<HTMLButtonElement>, tabIndex: string, query: string = "") => {
    e.preventDefault();

    if(tabIndex){
      let data;
      switch(tabIndex){
        case "1":
          data = validateSimpleSearchTab(data);
          break;
        case "2":
          data = validateAdvancedSearchTab(data);
          break;
        case "3":
          data = validatePlanSearchTab(data);
          break;
      }
      if( (tabIndex === "1" && simpleSearchSaveSettingsChecked) || (tabIndex === "2" && advancedSearchSaveSettingsChecked) || (tabIndex === "3" && paymentPlanSearchSaveSettingsChecked)){
        await PutUserSettings(userId!, JSON.stringify(data).replace(/"/g, '\\"'));
      }
    }

    history.push(mapSubmitLinks[activeTab].link + "?" + query);
    dispatch(setSidepaneLink('search'));
    let data = {
      facilities: facilityOptions,
      departments: departmentsOptions,
      users: usersOptions
    }
    
    if (!simpleSearchSaveSettingsChecked) {
      dispatch(setSimpleSearchInfoState({
        ...data,
        submittedDateTimeMin: simpleInitialState.value.submittedDateTimeMin,
        submittedDateTimeMax: simpleInitialState.value.submittedDateTimeMax 
      }));
    }
    if (!advancedSearchSaveSettingsChecked) {
      dispatch(setAdvanceSearchInformation({
        ...data, 
        tenderTypes: advancedInitialState.value.tenderTypes, 
        transactionTypes: advancedInitialState.value.transactionTypes,
        cardEntryTypes: advancedInitialState.value.cardEntryTypes,
        submittedDateTimeMin: advancedInitialState.value.submittedDateTimeMin,
        submittedDateTimeMax: advancedInitialState.value.submittedDateTimeMax,
        paymentStatus: advancedInitialState.value.paymentStatus
      }));
    }
    if (!paymentPlanSearchSaveSettingsChecked) {
      dispatch(planSetSettings({
        ...data, 
        tenderType: paymentPlanInitialState.value.tenderType,
        createdDateMin: paymentPlanInitialState.value.createdDateMin,
        createdDateMax: paymentPlanInitialState.value.createdDateMax,
        status: paymentPlanInitialState.value.status
      }));
    }
  }

  const onSave = (tabIndex: string) => {
    switch(tabIndex){
      case "1":
        if(simpleSearchSaveSettingsChecked){
          dispatch(setSaveSettings({saveSettings: false}));
          return;
        }
        dispatch(setSaveSettings({saveSettings: true}))
        break;
      case "2":
        if(advancedSearchSaveSettingsChecked){
          dispatch(advanceSetSettings({saveSettings: false}));
          return;
        }
        dispatch(advanceSetSettings({saveSettings: true}))
        break;
      case "3":
        if(paymentPlanSearchSaveSettingsChecked){
          dispatch(updateSaveSettings({saveSettings: false}));
          return;
        }
        dispatch(updateSaveSettings({saveSettings: true}))
        break;
    }
  };

  const onToggle = () => {
    const savedSettingsIsChecked = mapSubmitLinks[activeTab]?.state.saveSettings;
    const { facilities, users, departments, billingId } = simpleSearchInfo.value;
    dispatch(setOrganizationUSerSavedSettings({
      tabIndex: activeTab,
      settings: savedSettingsIsChecked ? { facilities, users, departments, billingId } : undefined
    }))
  }

  const onReset = async (tabIndex: string, userSettingsFromDb: UserSettings | undefined) => {
    let data;
    switch(tabIndex){
      case "1":
        sessionStorage.removeItem('simpleSearchCriteria')
        dispatch(setSaveSettings({saveSettings: false}));
        data = {
          ...userSettingsFromDb,
          simpleSearchCriteria: {
            facilities: [],
            departments: [],
            users: extractElementsIds(usersOptions)
          }
        };
        dispatch(setSimpleSearchInfoState({
          ...simpleInitialState.value,
          facilities: facilityOptions,
          departments: departmentsOptions,
          users: usersOptions,
          resetSelection: true
        }))
        break;
      case "2":
        sessionStorage.removeItem('advancedSearchCriteria')
        dispatch(advanceSetSettings({saveSettings: false}));
        data = {
          ...userSettingsFromDb,
          advancedSearchCriteria: {
            facilities: extractElementsIds(facilityOptions),
            departments: extractElementsIds(departmentsOptions),
            users: extractElementsIds(usersOptions)
          }
        };
        dispatch(setAdvanceSearchInformation({
          ...advancedInitialState.value,
          facilities: facilityOptions,
          departments: departmentsOptions,
          users: usersOptions,
          resetSelection: true
        }))
        break;
      case "3":
        sessionStorage.removeItem('planSearchCriteria')
        dispatch(updateSaveSettings({saveSettings: false}));
        data = {
          ...userSettingsFromDb,
          planSearchCriteria: {
            facilities: extractElementsIds(facilityOptions),
            departments: extractElementsIds(departmentsOptions),
            users: extractElementsIds(usersOptions)
          }
        };
        dispatch(planSetSettings({
          ...paymentPlanInitialState.value,
          facilities: facilityOptions,
          departments: departmentsOptions,
          users: usersOptions,
          resetSelection: true
        }))
        break;
      default:
        return;
    }
    await PutUserSettings(userId!, JSON.stringify(data).replace(/"/g, '\\"'));
  }

  const onClose = () => {
    history.push('/landing');
    if (!simpleSearchSaveSettingsChecked) {
      dispatch(
        setSimpleSearchInfoState({
          ...simpleSearchInfo.value,
          facilities: facilityOptions,
          departments: departmentsOptions,
          users: []
        })
      )
    }
  };

  return (
    <section className={`eds-modal ${openClass}`}>
      <div
        className="eds-modal_#container searchContainer">
        <header
          className="eds-modal_#header searchHeader">
          <div
            className="eds-modal_.title headerSearchContainer">
            <div className="headerSearchText">
              <span>PaymentSafe® | Search</span>              
            </div>
            <div className="text-right headerSearchCloseButton" onClick={onClose}>
              <Close />
            </div>
          </div>
        </header>
        <div
          className="eds-modal_#content searchContentContainer">
          <Searchpage
            onReset={onReset}
            onClose={onClose}
            onSave={onSave}
            rootPath={'searchpayments'}
            breadcrumb={'Search Payments'}
            searchButtonClick={onSearchButtonClick}
            onToggle={onToggle}
            accessVolumeSearch={accessVolumeSearch}
            showPlanSearch = {showPlanSearch}
            userSettings={userSettings}
          >
            {facilities.length ? <>
            <SimpleSearch />
            <AdvanceSearch />
            <PaymentPlanSearch filteredFacilities = {facilitiesWithNullRecord}/>
            {accessVolumeSearch ? <VolumeSearch /> : null}
            </> : "Processing..."}
          </Searchpage>
        </div>
      </div>
    </section>
  );
}
