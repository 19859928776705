import { Attribute } from 'models/metaData/MetaData';
import { ComponentType } from 'models/metaData/MetaDataEnums';
import { getComponentType, getMutableComponentType } from 'utils/metadata/MetaDataUtils';
import { DropDown } from '../wrapperComponents/DropDown';
import { CheckBox } from '../wrapperComponents/CheckBox';
import { CheckImage } from '../wrapperComponents/CheckImage';
import { Date } from '../wrapperComponents/Date';
import { TextBox } from '../wrapperComponents/TextBox';
import { LineItemError } from 'features/paymentDashboard/PaymentDashboardReducer';
import { Hidden } from '../wrapperComponents/Hidden';
import moment from 'moment';
import { convertDateToLocaleDateString } from 'utils/UtilsDateTime';
import { LineItem } from 'models/LineItem';

function getValueOrDefault(componentType : ComponentType, value: any){
  switch(componentType)
  {
    case ComponentType.TextInput:
      return typeof value === 'string' || typeof value === 'number' ? value : '';
    case ComponentType.DropDown:
      return value as Attribute['listOptions'] ? value : [];
    case ComponentType.CheckBox:
      return typeof value === 'boolean' ? value : false;
    case ComponentType.Calendar:
      const validDate = moment(value, 'YYYY-MM-DD', true).isValid() ? moment(value, 'YYYY-MM-DD').toDate() : moment().toDate();
      return convertDateToLocaleDateString(validDate, 'fr-CA');
    default:
      return value;
  }
}

export function getComponentByComponentType(props: {
  attribute: Attribute,
  lineItem: LineItem,
  value: any,
  id?: string,
  setError?: (error: LineItemError) => void,
  onChange?: (value: any) => void,
  onBlur?: (value: any) => void,
  modifiers?: string,
  disabled?: boolean,
  error?: string,
  isEditing?: boolean,
  isPreview?: boolean,
  isPaymentOrPreviewPage?: boolean
}) {
  const {
    attribute,
    value,
    id,
    setError,
    onChange,
    onBlur,
    modifiers,
    disabled,
    error,
    isEditing = true,
    isPreview = false,
    isPaymentOrPreviewPage = false,
    lineItem
  } = props;
  const componentType =  isEditing ? getComponentType(attribute) : getMutableComponentType(attribute);
  const validValue = getValueOrDefault(componentType, value); 
  
  switch (componentType) {
    case ComponentType.DropDown:
      return (
        <DropDown
          attribute={attribute}
          value={validValue}
          onChange={onChange}
          modifiers={modifiers}
        />
      );
    case ComponentType.CheckBox:
      return (
        <CheckBox
          attribute={attribute}
          value={validValue}
          onChange={onChange}
          modifiers={modifiers}
        />
      );
    case ComponentType.CheckImage:
      return (
        <CheckImage 
          attribute={attribute} 
          lineItem={lineItem}
          onChange={onChange}
        />
      );
    case ComponentType.TextInput:
      return (
        <TextBox
          attribute={attribute}
          value={validValue}
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          modifiers={modifiers}
          error={error}
          setError={setError}
          disabled={disabled}
        />
      );
    case ComponentType.Calendar:
      return (
        <Date
          attribute={attribute}
          value={validValue}
          isFormattedDate={true}
          onChange={onChange}
          onBlur={onBlur}
          modifiers={modifiers}
          error={error}
          setError={setError}
        />
      );
    case ComponentType.Hidden:
      return (
        <Hidden
          label={attribute.label}
          modifiers={modifiers}
          isPreview={isPreview}
          isPaymentOrPreviewPage={isPaymentOrPreviewPage}
        />
      )
    default:
      return undefined;
  }
}
