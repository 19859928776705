import { checkReceivedHasValues } from "utils/Utils";
import { useTypedSelector } from "../../../app/rootReducer";

export function PatientDetails() {
  const transaction = useTypedSelector(s => s.transactionDetails.transactionDetailsData?.transactionDetails);
  const firstName = checkReceivedHasValues(transaction?.billingInformation?.firstName, transaction?.billingInformation?.firstName, transaction?.mailingInformation?.firstName);
  const lastName = checkReceivedHasValues(transaction?.billingInformation?.lastName, transaction?.billingInformation?.lastName, transaction?.mailingInformation?.lastName);

  return <div className="card">
    <div className="card-header">
      <span>Patient/Payer Detail</span>
    </div>
    <div className="card-content">
      <div className="row p-2">
        <div className="col-lg-4">
          <div className="row">
            <div className="font-weight-bold width-150">Patient First Name:</div>
            <div className="col">{transaction?.patientFirstName}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Patient Last Name:</div>
            <div className="col">{transaction?.patientLastName}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">MRN:</div>
            <div className="col">{transaction?.mrn}</div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row">
            <div className="font-weight-bold width-150">Payer First Name:</div>
            <div className="col">{firstName}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Payer Last Name:</div>
            <div className="col">{lastName}</div>
          </div>

          <div className="row">
            <div className="font-weight-bold width-150">Payer Address:</div>
            <div className="col">{transaction?.billingInformation?.addressLine1}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Payer Phone:</div>
            <div className="col">{transaction?.billingInformation?.phone}</div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row">
            <div className="font-weight-bold width-150">Payer Email:</div>
            <div className="col">{transaction?.notificationEmail}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Guarantor ID:</div>
            <div className="col">{transaction?.guarantorId}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150 float-left">Notes: </div>
            <div className="text-highlighter padding-left-15">{transaction?.notes}</div>
          </div>
        </div>
      </div>
    </div>
  </div>;
}