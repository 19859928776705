import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenGuidLookupResponse } from 'models/TokenGuidLookupResponse';
import {
  getTokenGUIDLookupService,
  GetTokenGUIDLookupResponse,
} from 'services/TokenGUIDLookupService';
import { AppThunk } from 'app/rootReducer';

export interface State {
  err?: string;
  result?: TokenGuidLookupResponse;
}

export const initialState: State = {
  err: undefined,
  result: undefined,
};

const reducerSlice = createSlice({
  name: 'TokenGUIDLookup',
  initialState,
  reducers: {
    onReceiveTokenGUIDLookup(
      state,
      action: PayloadAction<GetTokenGUIDLookupResponse>
    ) {
      state.result = action.payload.result;
      state.err = action.payload.err;
    },
    setTokenGUIDLookup(
      state,
      action: PayloadAction<GetTokenGUIDLookupResponse>
    ) {
      state.result = action.payload.result;
    },
    resetTokenGUIDLookup(state) {
      state.result = initialState.result;
      state.err = initialState.err;
    },
    clearError(state) {
      state.err = initialState.err;
    },
  },
});

export const { onReceiveTokenGUIDLookup, clearError } = reducerSlice.actions;

export default reducerSlice.reducer;

export function getTokenGUIDLookup(params: any, onDone?: () => void): AppThunk {
  return async dispatch => {
    dispatch(resetTokenGUIDLookup());
    getTokenGUIDLookupService(params).then(data => {
      dispatch(onReceiveTokenGUIDLookup(data));
      if (onDone) onDone();
    });
  };
}

export function cleanError(): AppThunk {
  return dispatch => {
    dispatch(clearError());
  };
}

export const {
  setTokenGUIDLookup,
  resetTokenGUIDLookup,
} = reducerSlice.actions;
