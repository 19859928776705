import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import 'assets/styles/components/_globalLink.scss';

interface GlobalLinkProps {
  isDisabled: boolean;
  to: string;
  disabledTitle?: string;
  children?: ReactNode;
}

export default function GlobalLink(props: GlobalLinkProps) {
  const { isDisabled, to, disabledTitle, children, ...rest } = props;

  return (
    <Link
      className={isDisabled ? 'global-link-disabled' : ''}
      to={isDisabled ? '#' : to ?? ""}
      title={isDisabled ? disabledTitle : ''}
      {...rest}
    >
      {children}
    </Link>
  );
}

GlobalLink.defaultProps = {
  isDisabled: false,
  disabledTitle: '',
};
