import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  EDS_Button,
} from '@EH/eh.eds.react';
import { useTypedSelector } from 'app/rootReducer';
import { PlanOperations, ValidatorType } from 'models/metaData/MetaDataEnums';
import { Wizard } from '../../wizard/Wizard';
import { useOnCreateOrUpdatePlan } from '../../paymentPlan/useOnCreatePlan';
import { useGetPatientById } from '../../patients/hooks/useGetPatientById';
import 'assets/styles/components/_paymentPlan.scss';
import { OrganizationLevelDocument } from "../../../models/OrganizationLevelDocument";
import { replaceLineItems, setConfiguration, setLineItemAttribute, removeLineItem as removePaymentLineItem, setIsPayingNow, setIsPaymentType } from '../../paymentDashboard/PaymentDashboardReducer';
import { PaymentPlanModel } from '../../../models';
import { setOperation, updateRecord } from "../../paymentPlan/PaymentPlanReducer";
import { getWizardSteps } from '../../wizards/PaymentWizard';
import { useActiveAddhockPlan } from '../../paymentPlan/hooks/useActiveAddhockPlan';
import { AlertIds, AlertTypes, setAlert } from '../../alert/AlertReducer';
import UserPermissions from 'models/enums/UserPermissions';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import { getAppSettings } from 'services/AppSettingsService';
import { PaymentConfiguration } from '../../../models/PaymentsConfiguration';
import { LineItem } from '../../../models/LineItem';
import { Dispatch } from 'redux';
import { ApiTenderTypeEnum, checkStringHasValue } from '../../../utils/Utils';
import useTenderValidator from '../validators/useTenderValidator';
import { getAttribute } from '../../../utils/metadata/MetaDataUtils';
import { mapPatientMailingInformationToTenderInfo } from '../Tender';
import { PatientModel } from '../../../models/PatientModel';
import useGetConfiguration from 'features/admin/paymentPanel/serviceHandlers/useGetConfiguration';
import { EditStartStepButtons } from './EditStartButtons';
import { getNextStepSlug } from '../../planDetails/useEditPlanTerms';


const ERROR_MESSAGE = 'An error occured. Please try again later.';
const AMOUNT_TOO_MUCH = 'Account Balance Due should be less or equal to Account Balance';
const AMOUNT_NOT_EQUAL = 'Account Balance Due should be equal to Account Balance';

const alert = { id: AlertIds.GlPaymentAlert, type: AlertTypes.Error, message: '' };

export function getConfPath(
  organizationPath?: string,
  patientPath?: string | null,
  isGl?: boolean,
) {
  let confPath: string = ''

  if (isGl && organizationPath) {
    confPath = organizationPath
  }

  if (!isGl && patientPath) {
    confPath = patientPath
  }

  return confPath;
}

export function usePayNowClickHandler(props: { organizationPath?: string }) {
  const { organizationPath } = props;
  const configuration = useTypedSelector(
    state => state.paymentDashboard.configurations[organizationPath ?? '']?.configuration
  );
  const operation = useTypedSelector(state => state.paymentPlanInfo.operation);
  const isAddHoc = operation === PlanOperations.AddhockPayment;
  const dispatch = useDispatch();

  function onPayNowClick(
    lineItems: LineItem[],
    openErrorMessage: (message: string) => void,
    closeErrorMessage: () => void,
    onContinue?: () => void,
    isGl?: boolean,
    isPaymentType?: boolean,
    hasUnauthorizedPaymentPlans: boolean = false,
    hasActiveLineItem: boolean = false,
    cancelPlanProcess?: () => void
  ) {
    if (lineItems.findIndex(l => l.isActive && (l.amount - (l.discount || 0)) > (l.balance || 0)) > -1) {
      openErrorMessage(AMOUNT_TOO_MUCH);
      return;
    }

    if (!isAddHoc){
      dispatch(setOperation(PlanOperations.NotSet));
    }

    closeErrorMessage();
    onContinue && onContinue();
    addGlField(
      configuration,
      lineItems,
      dispatch,
      isGl,
      organizationPath,
    );

    if(!isPaymentType && hasUnauthorizedPaymentPlans && hasActiveLineItem){ // potential payment in background, so return to payment process
      dispatch(setIsPaymentType(true));
      cancelPlanProcess && cancelPlanProcess();
    }

    dispatch(setIsPayingNow(true));
  };
  return {
    onPayNowClick
  }
}

export async function setGLDummyPatient(
  setGlPatientId?: (id: { id: string }) => void,
) {
  const appSettings = await getAppSettings();
  setGlPatientId && setGlPatientId({ id: appSettings?.GL_PATIENT_ID || "6278be9f2a6e88a72c94efdb" })
}

export function useCreatePlanClickHandler(props: { organizationPath?: string, patient?: PatientModel, isPlan?: boolean }) {
  const { organizationPath, patient, isPlan } = props;
  const configuration = useTypedSelector(
    state => state.paymentDashboard.configurations[organizationPath ?? '']?.configuration
  );
  const dispatch = useDispatch();
  const patientMailingInformation = useTypedSelector(
    i => i.patientMailingInformation?.value
  );

  const {
    validateAll,
    attributes,
    getErrorMessage
  } = useTenderValidator(mapPatientMailingInformationToTenderInfo(patientMailingInformation), patient?.paymentsOnFile, false, organizationPath, isPlan);

  const getPaymentOnFileErrorMessage = () => {
    const attribute = getAttribute(attributes, 'method');

    if (attribute?.validators) {
      const validator = attribute.validators.find(
        v => v.name == ValidatorType.PaymentOnFileExpiredValidator
      );

      const error = getErrorMessage(attribute.name);
      if (validator?.message && error.includes(validator.message)) {
        return validator.message;
      }
    }
  };

  function onCreatePlanClick(
    lineItems: LineItem[],
    openErrorMessage: (message: string) => void,
    closeErrorMessage: () => void,
    onCreatePlan: () => void,
    isGl?: boolean,
  ) {
    if (!validateAll(mapPatientMailingInformationToTenderInfo(patientMailingInformation))) {
      const message = getPaymentOnFileErrorMessage();
      if (message) {
        openErrorMessage(
          'This payment method has expired. Please select a different payment method as the preferred method.'
        );
      } else {
        openErrorMessage(
          'Please complete the required information to continue.'
        );
      }
      return;
    }

    if (lineItems.findIndex(l => l.isActive && l.amount != l.balance) > -1) {
      openErrorMessage(AMOUNT_NOT_EQUAL);
    }

    const discountOnCreate = lineItems.filter(lineItem => lineItem.discount && lineItem.isActive);
    if (discountOnCreate.length > 0) {
      openErrorMessage('Error: Create or Add to plan is prohibited for discounted invoices. You can proceed to a single payment or remove the discount.')
    }

    if (discountOnCreate.length === 0) {
      closeErrorMessage()
      if (isGl) {
        setGLDummyPatient()
      }

      dispatch(setOperation(PlanOperations.CreatePlan));
      onCreatePlan();
      addGlField(
        configuration,
        lineItems,
        dispatch,
        isGl,
        organizationPath,
      );
    }
  }

  return { onCreatePlanClick }

}

export function addGlField(
  configuration: PaymentConfiguration,
  lineItems: LineItem[],
  dispatch: Dispatch<any>,
  isGl?: boolean,
  organizationPath?: string,
) {
  if (isGl) {
    if (organizationPath) {
      let updated = {
        ...configuration,
        glDisplayFields: [
          ...(configuration.glDisplayFields ?? [])
        ]
      };

      lineItems.forEach(lineItem => {
        const fieldIdx = configuration.glDisplayFields?.find(config => config.description == lineItem.description);
        if (!fieldIdx && updated.glDisplayFields) {
          updated.glDisplayFields.push({
            ...lineItem,
            description: lineItem.description ?? '',
            glNumber: lineItem.glNumber ?? '',
            unitCost: lineItem.unitCost ?? '',
            billingDepartment: lineItem.billingDepartment ?? '',
          });
        }
      });

      dispatch(setConfiguration({ organizationPath, configuration: updated }));
    } else {
      alert && dispatch(setAlert({ ...alert, message: "Could not save new gl item to configuration." }));
    }
  }
}

export function onAddToPlanClick(
  onUpdatePlan: () => void,
  configuration: PaymentConfiguration,
  lineItems: LineItem[],
  dispatch: Dispatch<any>,
  isGl?: boolean,
  organizationPath?: string,
) {
  onUpdatePlan();
  addGlField(
    configuration,
    lineItems,
    dispatch,
    isGl,
    organizationPath,
  );
}

export function NewPlanFooterChildren(props: {
  selectedOrganizationPath?: string,
  organizationPath?: string,
  patientPath?: string | null,
  isGl?: boolean,
  total?: number,
  openErrorMessage: (message: string) => void,
  onCreatePlanButtonClick?: () => void,
  closeErrorMessage: () => void,
  onContinue?: () => void,
  onContinuePlan?: () => void,
  wizard: Wizard,
  onCancelAddPlan?: () => void,
  cancelPlanProcess?: () => void,
  paymentPlan?: PaymentPlanModel,
  disabledNewPlanCreation?: boolean,
  organization?: OrganizationLevelDocument,
  patientId?: string,
  tenderType?: string
}) {
  const { isGl, organizationPath, patientPath, selectedOrganizationPath, total, closeErrorMessage, openErrorMessage, onCreatePlanButtonClick, onContinue, onContinuePlan, cancelPlanProcess, wizard, patientId, organization, paymentPlan, disabledNewPlanCreation, tenderType } = props;
  const isProcessing = useTypedSelector(s => s.services.calls.GetPaymentPlanConfiguration?.isProcessing);
  const lineItems = useTypedSelector(s => s.paymentDashboard?.values[selectedOrganizationPath ?? '']?.lineItems);
  const paymentPlanRecords = useTypedSelector(s => s.paymentPlanInfo.records) || [];
  const paymentDashboardValues = useTypedSelector(s => s.paymentDashboard?.values);
  const { havePermission } = useLoggedUserPermissions();
  const dispatch = useDispatch();
  const selectedPlans = useTypedSelector(s => s.paymentPlanInfo.selected);
  const confPath = getConfPath(organizationPath, patientPath, isGl);
  const planSelected = useTypedSelector(s => s.paymentPlanInfo.records?.find(x => selectedPlans?.some(y => y === x.id))) ?? null;
  const configurationPaymentPlanAttributes = useTypedSelector(s => s.paymentPanel.configurations.PaymentPlanDashboard?.entity?.attributes) ?? [];
  const paymentPanelConfiguration = useTypedSelector(s => s.paymentDashboard?.panelConfigurations?.[organizationPath ?? '']?.configuration);
  const isPaymentType = useTypedSelector(s => s.paymentDashboard.isPaymentType)
  const paymentPlanAttributes = [...configurationPaymentPlanAttributes];
  const paymentAttributes = paymentPanelConfiguration ? paymentPanelConfiguration.entity?.attributes : [];
  const paymentPlanConfiguration = useTypedSelector(
    state => state.paymentPlanInfo.configurations[confPath]?.configuration
  );
  const { getPanelConfiguration } = useGetConfiguration(organizationPath || '');

  useEffect(() => {
    isPaymentType && getPanelConfiguration("PaymentPlanDashboard")
  }, [isPaymentType]);

  const hasActiveLineItem = Object.values(paymentDashboardValues).some(dashboard =>
    dashboard.lineItems.length > 0 && dashboard.lineItems.some(x =>
      x.isActive && !x.isEditing && (x.amount ?? 0) > 0
    )
  )

  const hasActivePaymentPlan = Object.values(paymentPlanRecords).some(plan =>
    plan.isActive || (plan.lineItems!.length > 0 && plan.lineItems!.some(x =>
      x.isActive && !x.isEditing && (x.amount ?? 0) > 0
    )
    ))

  const hasUnauthorizedPaymentPlans = Object.values(paymentPlanRecords).some(plan => plan.authorized === false);

  const verifyTenderType = () => {

    if(!tenderType) return true;

    if ([ApiTenderTypeEnum.Cash, ApiTenderTypeEnum.MoneyOrder, ApiTenderTypeEnum.PaperCheckAsECheck].map(t => t.toString()).includes(tenderType)) {
      return true;
    }
    return isPaymentType ? !hasActiveLineItem : !hasUnauthorizedPaymentPlans;
  }

  const createPPButton = (
      (verifyTenderType()
      || !havePermission(UserPermissions.CreatePaymentPlan, confPath)
      || planSelected?.status)
  )

  const allowPayNow = () => (
    isPaymentType ? hasActiveLineItem : (hasActivePaymentPlan || hasUnauthorizedPaymentPlans)
  );

  const singlePButton = (!allowPayNow()
    || !tenderType
  );

  const { patient } = useGetPatientById(patientId);

  const { onCreatePlan } = useOnCreateOrUpdatePlan(
    {
      wizard,
      isGl,
      total,
      patient,
      organization,
      plan: paymentPlan,
    }
  );

  const isPlan = !!paymentPlan;

  const { onPayNowClick } = usePayNowClickHandler({ organizationPath });
  const { onCreatePlanClick } = useCreatePlanClickHandler({ organizationPath, patient, isPlan });
  const getListOrDefault = (list: any) => {
    return list && list.length > 0 ? list : [];
  }

  function moveLineItemsToPlan(selectedPlan: PaymentPlanModel) {
    let activeLineItems = lineItems.filter(x => x.isActive);
    if (activeLineItems?.length) {
      let updatedLineItems = [...(getListOrDefault(selectedPlan?.lineItems))];

      activeLineItems.forEach(lineitem =>
        updatedLineItems?.unshift(lineitem)
      );

      let updatedPlan = { ...selectedPlan, lineItems: updatedLineItems };
      dispatch(
        updateRecord({
          id: selectedPlan?.id,
          paymentPlan: updatedPlan,
        })
      );
    }

    activeLineItems.forEach(lineitem =>
      dispatch(removePaymentLineItem({ id: lineitem.id, organizationPath: organizationPath ?? '' }))
    );

  }
  const addPlanAttributes = () => {
    let itemList = [];
    const lineItemList = removePaymentAttributes()
    for (const lineItem of lineItemList) {
      let selectedLineItem: any = null;
      for (const attr of paymentPlanAttributes) {
        const attribute = lineItem[attr.name];

        if (!lineItem.pahAccount && !attribute) {
          dispatch(setLineItemAttribute({ lineItemId: lineItem._id, attributeName: attr.name, value: '', organizationPath: organizationPath ?? '' }));
          selectedLineItem = selectedLineItem ? { ...selectedLineItem, [attr.name]: '' } : { ...lineItem, [attr.name]: '' }
        }
      }
      itemList.push(selectedLineItem ?? lineItem);
    }
    dispatch(replaceLineItems({ lineItems: itemList, organizationPath: organizationPath ?? '' }));
  }

  const removePaymentAttributes = () => {
    if(isGl){
      return lineItems;
    }
    const excludedValues = ['amount', 'balance'];
    const attributeNames = paymentPlanAttributes.map(attr => attr.saveAs);
    const attributesToRemove = paymentAttributes?.filter(val => !attributeNames.includes(val.saveAs) && !excludedValues.includes(checkStringHasValue(val.saveAs)));
    const newList = lineItems.map((lineItem) => {
      const updatedLineItem = { ...lineItem };
      attributesToRemove?.forEach(attr => {
        if (updatedLineItem[attr.name]) {
          delete updatedLineItem[attr.name];
        }
      });
      return updatedLineItem;
    });
    return newList;
  };

  return <div className="footer-buttons">
    {!disabledNewPlanCreation ?
      <EDS_Button
        modifiers={'eds-button eds-button.basic'}
        name={'createPlan'}
        buttonText={isProcessing ? 'Processing ...' : 'Create Payment Plan'}
        onClick={() => onCreatePlanClick(
          lineItems,
          openErrorMessage,
          closeErrorMessage,
          () => {
            addPlanAttributes();
            onContinuePlan && onContinuePlan();
            onCreatePlan();
            if (onCreatePlanButtonClick)
              onCreatePlanButtonClick();
          },
          isGl,
        )}
        disabled={createPPButton}
      />
      : null}
    <EDS_Button
      modifiers={'eds-button eds-button.primary'}
      name={'createPlan'}
      buttonText="Pay Now"
      onClick={() => onPayNowClick(
        lineItems,
        openErrorMessage,
        closeErrorMessage,
        onContinue,
        isGl,
        isPaymentType,
        hasUnauthorizedPaymentPlans,
        hasActiveLineItem,
        cancelPlanProcess
      )}
      disabled={singlePButton}
    />
  </div>
};

export function AddToPlanFooterChildren(props: {
  wizard: Wizard,
  onCancelAddPlan?: () => void,
  paymentPlan?: PaymentPlanModel,
  organization?: OrganizationLevelDocument,
  isGl?: boolean,
  patientId?: string,
  selectedOrganizationPath?: string,
  total?: number,
}) {
  const { wizard, isGl, onCancelAddPlan, organization, patientId, paymentPlan, selectedOrganizationPath, total } = props;

  const { patient } = useGetPatientById(patientId);

  const result = useOnCreateOrUpdatePlan(
    {
      wizard,
      isGl,
      total,
      patient,
      organization,
      plan: paymentPlan,
    }
  );
  const { onUpdatePlan, error } = result;
  const isProcessing = useTypedSelector(s => s.services.calls.GetPaymentPlanConfiguration?.isProcessing);
  const dispatch = useDispatch();
  const lineItems = useTypedSelector(s => s.paymentDashboard?.values[selectedOrganizationPath ?? '']?.lineItems);
  const operation = useTypedSelector(s => s.paymentPlanInfo.operation);
  const openErrorMessage = (message: string) => {
    dispatch(setAlert({ ...alert, message }));
  };

  useEffect(() => {
    if (error) {
      openErrorMessage(ERROR_MESSAGE);
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [error]);

  const facility = useTypedSelector(
    state => state.glPaymentDashboard?.value.facility
  );
  const organizationPath = facility?.path;

  const configuration = useTypedSelector(
    state => state.paymentDashboard.configurations[organizationPath ?? '']?.configuration
  );

  return <div className="footer-buttons">
    <div className="footer-buttons footer-justify-start">
      <EDS_Button
        modifiers={'mr-2 eds-button eds-button.basic'}
        name={'cancel'}
        buttonText={'Cancel'}
        onClick={onCancelAddPlan}
      />
    </div>
    <div className="footer-buttons footer-justify-end">
      <EDS_Button
        modifiers={'eds-button eds-button.primary button-reverse'}
        name={'updatePlan'}
        buttonText={isProcessing ? 'Processing ...' : 'Next'}
        onClick={() => onAddToPlanClick(
          () => onUpdatePlan(operation),
          configuration,
          lineItems,
          dispatch,
          isGl,
          organizationPath,
        )}
        disabled={!total}
        iconName={'chevron_right'}
      />
    </div>
  </div>
};

export function PlanFooterChildren(props: {
  wizard: Wizard,
  openErrorMessage: (message: string) => void,
  onCreatePlanButtonClick?: () => void,
  closeErrorMessage: () => void,
  onCancelAddPlan?: () => void,
  paymentPlan?: PaymentPlanModel,
  disabledNewPlanCreation?: boolean,
  organization?: OrganizationLevelDocument,
  isGl?: boolean,
  patientId?: string,
  onCancel?: () => void,
  organizationPath?: string,
  patientPath?: string | null,
  total?: number,
  selectedOrganizationPath?: string,
  onContinue?: () => void,
  onContinuePlan?: () => void,
  tenderType?: string,
  cancel?: () => void,
}) {
  const { closeErrorMessage, openErrorMessage, onCreatePlanButtonClick, wizard, isGl, onCancel, onCancelAddPlan, onContinue, onContinuePlan,cancel, organization, organizationPath, patientId, patientPath, paymentPlan, disabledNewPlanCreation = false, selectedOrganizationPath, total, tenderType } = props;

  const { patient } = useGetPatientById(patientId);
  const result = useOnCreateOrUpdatePlan(
    {
      wizard,
      isGl,
      total,
      patient,
      organization,
      plan: paymentPlan,
    }
  );
  const { onUpdatePlan } = result;


  const selected = useTypedSelector(s => s.paymentPlanInfo.selected) || [];
  const operation = useTypedSelector(state => state.paymentPlanInfo.operation);

  if (paymentPlan?.id && [PlanOperations.EditPlanTerms, PlanOperations.EditPlanDetails].includes(operation)) {
    const nextStepSlug = getNextStepSlug(operation, wizard.state.currentStep?.slug);
    return <EditStartStepButtons
      cancel={onCancel}
      disabled={false}
      onNext={() => {
        onContinuePlan && onContinuePlan();
      }
  }
    />
  }

  const isMailingInformationAddToPlan = (paymentPlan?.id && selected.length > 0 && operation === PlanOperations.AddToPlan);

  if (isMailingInformationAddToPlan) return <AddToPlanFooterChildren {...{
    wizard,
    onCancelAddPlan,
    paymentPlan,
    organization,
    isGl,
    patientId,
    selectedOrganizationPath,
    total
  }} />
  return <NewPlanFooterChildren {...{
    selectedOrganizationPath,
    organizationPath,
    patientPath,
    isGl,
    total,
    closeErrorMessage,
    openErrorMessage,
    onCreatePlanButtonClick,
    onContinue,
    onContinuePlan,
    wizard,
    onCancelAddPlan,
    cancel,
    paymentPlan,
    disabledNewPlanCreation,
    organization,
    patientId,
    tenderType,
  }} />
}