import { useEffect } from "react";

/**
 * This is the wide gate to JS injection
 * It is only intended to run scripts from BE
 * Avoid use with scripts from user input
 */
export function useScriptDangerous() {
  const script = document.createElement('script');
  useEffect(() => {
    script.async = true;
    script.type = 'text/javascript';

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (scriptDangerous: string) => {
    script.text = scriptDangerous;
    document.body.appendChild(script);
  }
}