import axios from 'axios';
import { PermissionItem, ServicePermissionItem } from 'models/Permission';
import { sleep } from 'utils';
import { OrganizationLevelSummary } from '../models/OrganizationLevelSummary';
import { callHttpApi } from './callHttpApi';

interface UpdatePermissionsModel {
  userId: string,
  organizationLevels: OrganizationLevelSummary[]
}

interface BulkCloneDestinationUser{
  organizationPaths: string[],
  userId: string
}

export interface ClonePermissionsModel {
  bulkCloneDestinationUsers: BulkCloneDestinationUser[],
  originUserOrganizationPath: string,
  originUserId: string
}

export interface OrganizationPermissionsModel {
  organizationPaths: string[],
  permissions: string[],
}

export async function UpdatePermissions(data: UpdatePermissionsModel[]) {
  return callHttpApi<undefined, UpdatePermissionsModel[]>(
    'put',
    `api/v1/Users/UpdatePermissions`,
    data
  );

}

export async function AddPermissions(data: OrganizationPermissionsModel) {
  return callHttpApi<undefined, OrganizationPermissionsModel>(
    'put',
    `api/v1/Users/AddPermissions`,
    data
  );
}

export async function RemovePermissions(data: OrganizationPermissionsModel) {
  return callHttpApi<undefined, OrganizationPermissionsModel>(
    'delete',
    `api/v1/Users/permissions`,
    data
  );
}

export async function ClonePermissions(data: ClonePermissionsModel) {
  return await callHttpApi<undefined, ClonePermissionsModel>(
    'post',
    `/api/v1/Users/BulkClone`,
    data
  );
}

export async function GetPermissions() {
  return callHttpApi<ServicePermissionItem[], string>(
    'get',
    `/api/v1/Permissions`
  );
}

export interface GetAllPermissionsResult {
  permissions: PermissionItem[];
  err?: string;
}

export interface UpdatePermissionModel {
  userId: string;
  organizationLevels: OrganizationLevelSummary[];
}

const FakeApiCalls = true;
const fakeDelayMs = 100;

export async function updatePermissions(data: UpdatePermissionModel[]) {
  return await callHttpApi<UpdatePermissionModel[], UpdatePermissionModel[]>(
    'put',
    `/api/v1/Users/UpdatePermissions`,
    data
  );
}

export async function logAdminActivity(comment: string) {
  return await callHttpApi(
    'post',
    `/api/v1/Admin/LogAdminActivity?comment=${encodeURIComponent(comment)}`,
  );
}

export async function getAllPermissionsService(): Promise<
  GetAllPermissionsResult
> {
  if (FakeApiCalls) {
    await sleep(fakeDelayMs);
    return {
      permissions: getFakePermissions(),
      err: '',
    };
  }
  try {
    let data;
    const axiosRes = await axios({
      method: 'get',
      url: '/api/v2/PermissionsV2',
    });
    data = axiosRes.data;

    if (!data) {
      return {
        err: 'Failed to complete request.',
        permissions: [],
      };
    }
    return {
      permissions: data,
      err: '',
    };
  } catch (err) {
    return {
      err: err.message,
      permissions: [],
    };
  }
}

function getFakePermissions(): PermissionItem[] {
  return [
    {
      id: '1',
      name: 'Add',
      state: 0,
      groupName: 'ModifyRoleTypes',
    },
    {
      id: '2',
      name: 'Update',
      state: 0,
      groupName: 'ModifyRoleTypes',
    },
    {
      id: '4',
      name: 'Create',
      state: 0,
      groupName: 'ModifyRoleTypes',
    },
    {
      id: '8',
      name: 'Delete',
      state: 0,
      groupName: 'ModifyRoleTypes',
    },
    {
      id: '1',
      name: 'Add',
      state: 0,
      groupName: 'ModifyPermissionTypes',
    },
    {
      id: '2',
      name: 'Update',
      state: 0,
      groupName: 'ModifyPermissionTypes',
    },
    {
      id: '4',
      name: 'Create',
      state: 0,
      groupName: 'ModifyPermissionTypes',
    },
    {
      id: '8',
      name: 'Delete',
      state: 0,
      groupName: 'ModifyPermissionTypes',
    },
    {
      id: '1',
      name: 'AcceptCash',
      state: 0,
      groupName: 'AcceptPaymentTypes',
    },
    {
      id: '2',
      name: 'AcceptPaperCheck',
      state: 0,
      groupName: 'AcceptPaymentTypes',
    },
    {
      id: '4',
      name: 'AcceptMoneyOrder',
      state: 0,
      groupName: 'AcceptPaymentTypes',
    },
    {
      id: '1',
      name: 'AcceptGLCash',
      state: 0,
      groupName: 'AcceptGLPaymentTypes',
    },
    {
      id: '2',
      name: 'AcceptGLPaperCheck',
      state: 0,
      groupName: 'AcceptGLPaymentTypes',
    },
    {
      id: '4',
      name: 'AcceptGLMoneyOrder',
      state: 0,
      groupName: 'AcceptGLPaymentTypes',
    },
  ];
}
