import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';

export interface State {
  status?: TransactionDetailsModel;
}

export const initialState: State = {};

const reducerSlice = createSlice({
  name: 'ConfirmPayment',
  initialState,
  reducers: {
    setTransactionStatus(
      state,
      action: PayloadAction<TransactionDetailsModel | undefined>
    ) {
      state.status = action.payload;
    },
  },
});

export const { setTransactionStatus } = reducerSlice.actions;
export default reducerSlice.reducer;
