import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../app/rootReducer";
import { LineItem } from "../../../../models/LineItem";
import { ValidatorType } from "../../../../models/metaData/MetaDataEnums";
import { PaymentConfiguration } from "../../../../models/PaymentsConfiguration";
import { getGlPaymentPanelConfiguration, getPaymentPanelConfiguration, getPaymentPlanPanelConfiguration, PaymentPanelApiConfiguration, PaymentPanelApiConfigurationFieldSetting, getDefaultPaymentPanelConfiguration, getDefaultGLPaymentPanelConfiguration, getDefaultPatientSearchPanelConfiguration, getDefaultPaymentPlansPaymentPanelConfiguration, getDefaultEcnPaymentPanelConfiguration, getEcnPaymentPanelConfiguration } from "../../../../services/PaymentPanelService"
import { nameof } from "../../../../utils/Utils";
import { Dashboards } from "../ConfigurationSelector"
import { saveConfiguration } from "../PaymentPanelReducer";
import { getAnnotations, getDataTypeByFieldName, mapValidators } from "utils/UtilsPaymentPanel";
import { DistributionOrder } from "models/enums/EnumPaymentPanels";

export default function useGetConfiguration(organizationPath?: string) {
  const dispatch = useDispatch();
  const { mapAttributes } = useMapAttributes(organizationPath);
  async function getPanelConfiguration(key: keyof Dashboards) {
    const services: { [key in keyof Dashboards]: () => Promise<PaymentPanelApiConfiguration | undefined> } = {
      PaymentDashboard: async () => {
        return getDashboardConfig(
          getPaymentPanelConfiguration,
          getDefaultPaymentPanelConfiguration,
          false
        );
      },
      PaymentDashboardDraft: async () => {
        return getDashboardConfig(
          getPaymentPanelConfiguration,
          getDefaultPaymentPanelConfiguration,
          true
        );
      },
      GlPaymentDashboard: async () => {
        return getDashboardConfig(
          getGlPaymentPanelConfiguration,
          getDefaultGLPaymentPanelConfiguration,
          false
        );
      },
      GlPaymentDashboardDraft: async () => {
        return getDashboardConfig(
          getGlPaymentPanelConfiguration,
          getDefaultGLPaymentPanelConfiguration,
          true
        );
      },
      PaymentPlanDashboard: async () => {
        return getDashboardConfig(
          getPaymentPlanPanelConfiguration,
          getDefaultPaymentPlansPaymentPanelConfiguration,
          false
        );
      },
      PaymentPlanDashboardDraft: async () => {
        return getDashboardConfig(
          getPaymentPlanPanelConfiguration,
          getDefaultPaymentPlansPaymentPanelConfiguration,
          true
        );
      },
      PatientSearchPopupDashboard: async () => {
        return getDashboardConfig(
          getDefaultPatientSearchPanelConfiguration,
          getDefaultPatientSearchPanelConfiguration,
          false
        );
      },
      PatientSearchPopupDashboardDraft: async () => {
        return getDashboardConfig(
          getDefaultPatientSearchPanelConfiguration,
          getDefaultPatientSearchPanelConfiguration,
          true
        );
      },
      EcnPaymentDashboard: async () => {
        return getDashboardConfig(
          getEcnPaymentPanelConfiguration,
          getDefaultEcnPaymentPanelConfiguration,
          false
        );
      },
      EcnPaymentDashboardDraft: async () => {
        return getDashboardConfig(
          getEcnPaymentPanelConfiguration,
          getDefaultEcnPaymentPanelConfiguration,
          true
        );
      }
    }

    const service = services[key];
    if (service) {
      const configuration = await service();
      configuration && dispatch(saveConfiguration({
        key, configuration: {
          ...configuration,
          entities: [],
          entity:
          {
            name: key,
            attributes: mapAttributes(configuration.fieldSettings),
            organization: configuration.organization,
            sortByField: configuration.sortByField?.length ? configuration.sortByField : undefined,
            sortDescending: configuration.sortDescending,
            distributionOrder: configuration.distributionOrder ?? DistributionOrder.None,
            colorCode: configuration.colorCode,
            panelTitle: configuration.panelTitle,
            addRowEnabled:configuration.addRowEnabled
          },
          mappings: configuration.mappings
        }
      }));  
    }
  }

  async function getDefaultPaymentConfiguration(isDraft?: boolean) {
    const response = await getDefaultPaymentPanelConfiguration(organizationPath, isDraft);
    return response.result;
  }

  async function getDefaultPaymentPlanConfiguration(isDraft?: boolean) {
    const response = await getDefaultPaymentPlansPaymentPanelConfiguration(organizationPath, isDraft);
    return response.result;
  }

  async function getDefaultGlPaymentConfiguration(isDraft?: boolean) {
    const response = await getDefaultGLPaymentPanelConfiguration(organizationPath, isDraft);
    return response.result;
  }

  async function getDefaultPatientConfiguration(isDraft?: boolean) {
    const response = await getDefaultPatientSearchPanelConfiguration(organizationPath, isDraft);
    return response.result;
  }

  async function getAllPaymentPanels(orgPaths?: string[]){
    const response = await getPaymentPanelConfiguration(orgPaths);   
    return response.result;
  }
  
  async function getDefaultEcnPaymentConfiguration(isDraft?: boolean) {
    const response = await getDefaultEcnPaymentPanelConfiguration(organizationPath, isDraft);
    return response.result;
  }

  async function getDashboardConfig(
    getDashboardConfiguration: Function,
    getDefaultDashboardConfiguration: Function,
    isDraft?: boolean
  ) {
    const response = await getDashboardConfiguration([organizationPath ?? '']);
    if (response.result?.length > 0) {
      const config = getDraftAndLiveConfiguration(response.result);
      return isDraft? config.draftConfiguration: config.configuration;
    }
    else {
      const defaultConfig = await getDefaultDashboardConfiguration(organizationPath ?? '', isDraft);
      const defaultResponse = getDefaultConfiguration(defaultConfig.result);
      return defaultResponse.configuration;
    }
  }
  return {
    getAllPaymentPanels,
    getPanelConfiguration,
    getDefaultPaymentConfiguration,
    getDefaultPaymentPlanConfiguration,
    getDefaultGlPaymentConfiguration,
    getDefaultEcnPaymentConfiguration,
    getDefaultPatientConfiguration
  }
}

export function useMapAttributes(organizationPath?: string) {
  const paymentConfiguration = useTypedSelector(s => s.paymentDashboard
    ?.configurations[organizationPath ?? '']?.configuration)
  const mapAttributes = (fieldSettings: PaymentPanelApiConfigurationFieldSetting[]) => {
      const { autocompleteConfiguration, validator } = getAutocomplete(paymentConfiguration);
      return fieldSettings.map(f => ({
        ...f,
        name: f.saveAs ?? 'amount',
        dataType: getDataTypeByFieldName(f.saveAs),
        validators: mapValidators(f, validator),
        autocomplete: f.saveAs === 'description' || f.saveAs === 'gLDescriptionList' ? autocompleteConfiguration : undefined,
        annotations: getAnnotations(f),
        visibleOnReceipt:f.visibleOnReceipt,
      }));
  }

  return {
    mapAttributes
  }
}

function getAutocomplete(configuration?: PaymentConfiguration) {
  return {
    autocompleteConfiguration: {
      key: nameof<LineItem>("description"),
      configuration: configuration?.glDisplayFields ?? []
    },
    validator: {
      name: ValidatorType.RangeValidator,
      value: configuration?.glDisplayFields?.filter(field => !!field.description).map(field => field.description) ?? []
    }
  }
}

function getDraftAndLiveConfiguration(configurations?: PaymentPanelApiConfiguration[]) {
  const configuration = configurations?.find(c => !c.isDraft);
  const draftConfiguration = configurations?.find(c => c.isDraft);
  const ids = {
    draftId: draftConfiguration?.id,
    liveId: configuration?.id
  };

  return {
    configuration: { ...configuration, ...ids, fieldSettings: configuration?.fieldSettings ?? [] },
    draftConfiguration: { ...draftConfiguration, ...ids, fieldSettings: draftConfiguration?.fieldSettings ?? [] },
  };
}

function getDefaultConfiguration(configuration?: PaymentPanelApiConfiguration) {

  const draftConfiguration = configuration;
  const ids = {
    draftId: draftConfiguration?.id,
  };

  return {
    configuration: { ...draftConfiguration, ...ids, fieldSettings: draftConfiguration?.fieldSettings ?? [] },
  };
}