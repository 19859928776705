import { useChangeHandlers } from '../useAdvanceSearchState';
import { TextInput } from './TextInput';

export function SearchByPatientPayer() {
  const { handleChangeTextBox, setAdvancedSearchInfoState, state } = useChangeHandlers();

  return (
    <div className="row" style={{ alignItems: 'flex-end' }}>
      <TextInput
        label={'MRN:'}
        name={'mrn'}
        placeHolder={'Enter MRN'}
        value={state.mrn}
        onChange={e => handleChangeTextBox(e, 'MRN')}
      />
      <TextInput
        label={'Patient First Name:'}
        name={'patientFirstName'}
        placeHolder={'Enter patient first name'}
        value={state.patientFirstName}
        onChange={e => handleChangeTextBox(e, 'Patient First Name', true)}
      />
      <TextInput
        label={'Patient Last Name:'}
        name={'patientLastName'}
        placeHolder={'Enter patient last name'}
        value={state.patientLastName}
        onChange={e => handleChangeTextBox(e, 'Patient Last Name', true)}
      />

      <div className="w-100" />

      <TextInput
        label={'Payer First Name:'}
        name={'payerFirstName'}
        placeHolder={'Enter payer first name'}
        value={state.payerFirstName}
        onChange={e => handleChangeTextBox(e, 'Payer First Name', true)}
      />
      <TextInput
        label={'Payer Last Name:'}
        name={'payerLastName'}
        placeHolder={'Enter payer last name'}
        value={state.payerLastName}
        onChange={e => handleChangeTextBox(e, 'Payer Last Name', true)}
      />
      <TextInput
        label={'Guarantor ID:'}
        name={'guarantorId'}
        placeHolder={'Enter guarantor ID'}
        value={state.guarantorId}
        onChange={e => handleChangeTextBox(e, 'Guarantor ID')}
      />
      <TextInput
        label={'Patient ID:'}
        name={'patientId'}
        placeHolder={'Enter patient ID'}
        value={state.patientId}
        onChange={e => handleChangeTextBox(e, 'Patient ID')}
      />
    </div>
  );
}
