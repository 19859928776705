import React, { Ref } from 'react';
import {
  EDS_TextBox,
} from '@EH/eh.eds.react';
import { Input } from 'reactstrap';

export function Field<T>(props: {
  name: keyof T;
  formatLabel?: (name: keyof T) => string;
  errorClass?: (name: keyof T, model?: T) => string;
  onBlur?: (name: keyof T) => any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, name: keyof T) => any;
  showInputError?: (name: keyof T, model?: T) => any;
  value: any;
  prepend?: any;
  modifiers?: string;
  inputRef?: Ref<HTMLInputElement>;
  model?: T,
  label?: string,
  placeholder?: string,
}) {

  const { name, formatLabel, onChange, value, modifiers, errorClass, onBlur, showInputError, inputRef, prepend, model, label, placeholder} = props

  return <>
    <EDS_TextBox
      name={name}
      label={formatLabel ? formatLabel(name) : label ?? ''}
      type={'text'}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, name)}
      value={value}
      addPrepend={prepend}
      modifiers={`${modifiers ?? ''} ${errorClass ? errorClass(name, model) : ''}`}
      onBlur={() => onBlur && onBlur(name)}
      inputRef={inputRef}
      placeholder={placeholder}
    />
    <span className={`${errorClass && errorClass(name) ? 'error-message-required' : ''}`}>
      { showInputError && showInputError(name, model)}
    </span>
  </>
}