import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../app/rootReducer";

interface CallMetadata {
  isProcessing: boolean
}

export interface State {
  calls: {
    GetDevices?: CallMetadata,
    GetPaymentPlanConfiguration?: CallMetadata,
    GetPaymentConfiguration?: CallMetadata,
    GetClientConfiguration?: CallMetadata, 
    GetCreditCardConfig?: CallMetadata, 
    UpdateDeviceManagerForClient?: CallMetadata,
    GetDeviceManagerClients?: CallMetadata,
    GetLogoForOrganization?: CallMetadata,
    UploadLogoForOrganization?: CallMetadata,
    DeleteLogoForOrganization?: CallMetadata, 
    DeletePaymentPaymentPanel?: CallMetadata,
    DeletePaymentPlansPaymentPanel?: CallMetadata, 
    DeleteGlPaymentPaymentPanel?: CallMetadata,
    GetPatientById?: CallMetadata,
    GetTransactionInfoService?: CallMetadata,
    GetUserById?: CallMetadata,
    GetWebServiceError?: CallMetadata,
    getAllPermissionsService?: CallMetadata,
    getRefreshToken?: CallMetadata,
    createTransaction?: CallMetadata,
    getMerchantConfigService?: CallMetadata,
    merchantConfigService?: CallMetadata,
    configureLineItemsMetadata?: CallMetadata,
    getOrganizations?: CallMetadata,
    getPatients?: CallMetadata,
    getPatient?: CallMetadata,
    DownloadExcelFullTransactionsReport?: CallMetadata,
    DownloadExcelFullPaymentPlansReport?: CallMetadata,
    createOrUpdatePaymentPlan?: CallMetadata,
    getPaymentPlansByPatientId?: CallMetadata,
    getPatientEncounters?: CallMetadata,
    addPaymentOnFile?: CallMetadata,
    softDeletePaymentOnFile?: CallMetadata,
    createCardToken?: CallMetadata,
    searchUser?: CallMetadata,
    refundTransaction?: CallMetadata,
    voidTransaction?: CallMetadata,
    getTransaction?: CallMetadata,
    GetRemitSchedule?: CallMetadata,
    UpdateRemitSchedulesConfig?: CallMetadata,
    SaveRemitScheduleConfig?: CallMetadata,
    RemitConfiguration?: CallMetadata,
    ProcessRemit?: CallMetadata,
    saveRemitConfiguration?: CallMetadata,
    getRemitConfigurationsByClient?: CallMetadata,
    getRemitConfigurationById?: CallMetadata,
    parseScribanTemplate?: CallMetadata,
    getRemitPreview?:CallMetadata,
    cloneRemitConfigurations?:CallMetadata,
    getPaymentPanelRegExTemplates?:CallMetadata,
    UpdateReceiptManager?:CallMetadata,
    GetReceiptManager?:CallMetadata,
    DeleteReceiptManager?:CallMetadata,
    clonePanelConfiguration?:CallMetadata,
    GetPaymentReceipt?:CallMetadata,
    GetPaymentPlanReceipt?: CallMetadata,
    GetTokenGUIDLookup?: CallMetadata,
  }
}

export const initialState: State = {
  calls: {}
}

const reducerSlice = createSlice({
  name: 'Organizations',
  initialState,
  reducers: {
    setCall(state, action: PayloadAction<State["calls"]>) {
      state.calls = { ...state.calls, ...action.payload };
    },
  },
});

export const {
  setCall
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function callService<T>(key: keyof State["calls"], callback: ()=>Promise<T>): AppThunk {
  return async dispatch => {
    dispatch(setCall({ [key]: { isProcessing: true } }));
    await callback();
    dispatch(setCall({ [key]: { isProcessing: false } }));
  };
}
