export enum EnumTransactionStatusType {
  Unknown = 'Unknown',
  Created = 'Created',
  Processing = 'Processing',
  Cancelled = 'Cancelled',
  Timeout = 'Timeout',
  Submitted = 'Submitted',
  Declined = 'Declined',
  Settled = 'Settled',
  Failed = 'Failed',
  Error = 'Error'
}

export default EnumTransactionStatusType;
