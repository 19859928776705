import { EDS_Accordion } from '@EH/eh.eds.react';
import { SearchByPatientPayer } from './SearchByPatientPayer';
import { SearchByDateAmount } from './SearchByDateAmount';
import { SearchByTransactionDetails } from './SearchByTransactionDetails';
import styles from '../advanceSearch.module.scss';

type AccordionContents = {
  summary: string;
  isOpen?: boolean;
  content: JSX.Element;
  modifiersContainerOpen?: string;
}[];

function getAccordionItems(
  setShowAlert: any
): AccordionContents {
  // Define the accordion content for each group
  // initially empty
  const accordionContents: AccordionContents = [];

  accordionContents.push({
    summary: 'Patient/Payer',
    isOpen: true,
    content: <SearchByPatientPayer />,
  });

  accordionContents.push({
    summary: 'Date and Amount',
    isOpen: true,
    modifiersContainerOpen: styles.accordionContainerSearchByDateAmount,
    content: <SearchByDateAmount />,
  });

  accordionContents.push({
    summary: 'Transaction Details',
    isOpen: true,
    content: (
      <SearchByTransactionDetails
        setShowAlert={setShowAlert}
      />
    ),
  });

  return accordionContents;
}

export function MainFilters(props: {
  setShowAlert: any;
}) {
  const { setShowAlert } = props;
  return (
    <EDS_Accordion
      accordionItems={getAccordionItems(setShowAlert)}
    />
  );
}
