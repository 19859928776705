import { useTypedSelector } from "app/rootReducer";
import { OrganizationLevelSummary } from "models/OrganizationLevelSummary";
import UserPermissions from "models/enums/UserPermissions";

export function useLoggedUserPermissions() {
  const loggedUser = useTypedSelector(s => s.loginInfo?.loggedUser);

  const filterUserOrganizationLevelsPermissions = (organizationlevels?: OrganizationLevelSummary[], organizationPath?: string) => {
    const organizationLevels = organizationPath?.length ? organizationlevels?.filter(o=> o.organizationLevel_Path === organizationPath) : organizationlevels;
    return organizationLevels?.map(org => org.permissions?.map((item) => Number(item))).flat();
  }

  const havePermission = (permission?: number, organizationPath?: string): boolean => {
    if (loggedUser?.impersonatedUserOneSourceId) {
      return checkImpersonatedUserPermissions(permission, organizationPath);
    }

    if (loggedUser?.isSuperAdmin) return true;

    return checkUserPermissions(permission, organizationPath);
  };

  const hasPermissionOrSuperAdmin = (permission?: number, organizationPath?: string) => {
    if (loggedUser?.impersonatedUserOneSourceId) {
      return checkImpersonatedUserPermissions(permission, organizationPath);
    }

    return checkUserPermissions(permission, organizationPath);
  };

  const checkImpersonatedUserPermissions = (permission?: number, organizationPath?: string) => {
    const userPermissions = filterUserOrganizationLevelsPermissions(loggedUser?.impersonatedOrganizationLevels, organizationPath);
    return !!permission && !!userPermissions?.includes(permission);
  }

  const checkUserPermissions = (permission?: number, organizationPath?: string) => {
    const userPermissions = filterUserOrganizationLevelsPermissions(loggedUser?.organizationLevels, organizationPath)
    return !!permission && !!userPermissions?.includes(permission);
  }

  const havePermissions = (permissions?: number[], organizationPath?: string) => {
    return !!permissions?.length && permissions.every(p => havePermission(p, organizationPath));
  }

  const haveAnyPermission = (desiredPermissions: number[], organizationPath?: string) => {
    return desiredPermissions.some(p => havePermission(p, organizationPath));
  }
  
  const havePaymentPermission = (organizationPath?: string) => {
    return haveAnyPermission([
      UserPermissions.MakePayment,
      UserPermissions.PaymentCheckScanner,
      UserPermissions.PaymentCash,
      UserPermissions.PaymetCreditCardMannual, 
      UserPermissions.PaymentCreditCardDevice,
      UserPermissions.PaymenteCheck,
      UserPermissions.PaymentMoneyOrder,
      UserPermissions.PaymentPaperCheck,
      UserPermissions.PaymentMethodOnFile,
      UserPermissions.ProcessCredit,
      UserPermissions.ProcessVoid
    ],
    organizationPath);
  }
  
  const haveGLPaymentPermission = (organizationPath?: string) => {
    return havePermission(UserPermissions.GLPaymentAccess, organizationPath);
  }
  
  const haveSearchPermission = (organizationPath?: string) => {
    return haveAnyPermission([
      UserPermissions.Search,
      UserPermissions.SearchPlans],
      organizationPath);
  }

  const haveSSPPermission = (organizationPath?: string) => {
    const desiredPermissions = [ UserPermissions.SelfServicePortal, UserPermissions.FinancialAdministation ];
    return desiredPermissions.some(p => hasPermissionOrSuperAdmin(p, organizationPath));
  }

  return { havePermission, 
    havePermissions, 
    havePaymentPermission,
    haveGLPaymentPermission,
    haveSearchPermission,
    haveSSPPermission,
    hasPermissionOrSuperAdmin
  };
}