import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'utils/useHistory';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { EDS_TextBox, EDS_TextArea } from '@EH/eh.eds.react';
import DatePicker from 'react-datepicker';
import { ReactComponent as Calendar } from 'assets/svgs/icon-calendar.svg';
import { useTypedSelector } from 'app/rootReducer';
import { ReactComponent as Search } from 'assets/svgs/paymentPlanIcons/icon-search.svg';
import { ReactComponent as Mail } from 'assets/svgs/paymentPlanIcons/icon-mail.svg';
import { ReactComponent as Edit } from 'assets/svgs/paymentPlanIcons/icon-edit.svg';
import { ReactComponent as EditDisabled } from 'assets/svgs/paymentPlanIcons/icon-edit-disabled.svg';
import { ReactComponent as Pause } from 'assets/svgs/paymentPlanIcons/icon-pause.svg';
import { ReactComponent as PauseDisabled } from 'assets/svgs/paymentPlanIcons/icon-pause-disabled.svg';
import { ReactComponent as Resume } from 'assets/svgs/paymentPlanIcons/icon-play.svg';
import { ReactComponent as Cancel } from 'assets/svgs/paymentPlanIcons/icon-cancel.svg';
import { ReactComponent as CancelDisabled } from 'assets/svgs/paymentPlanIcons/icon-cancel-disabled.svg';
import { ReactComponent as Print } from 'assets/svgs/paymentPlanIcons/icon-print.svg';
import { useGetConfiguration } from 'features/paymentPlan/hooks/useGetConfiguration';
import { useGetPaymentPlanById } from 'features/paymentPlan/hooks/useGetPaymentPlanById';
import { useOnCreateOrUpdatePlan } from 'features/paymentPlan/useOnCreatePlan';
import { updateRecord, addBackup, setOpenedPlanId, changeSelected, setSelectedPlans } from 'features/paymentPlan/PaymentPlanReducer';
import { Wizard } from 'features/wizard/Wizard';
import { EmailReceipt } from 'features/emailReceipt/EmailReceipt';
import {
  EnumPlanStatus,
  EnumTermType,
} from 'models/enums/EnumPaymentPlan';
import { PaymentPlanConfiguration } from 'models/PaymentPlanConfiguration';
import PaymentPlanModel, { PauseHistory } from 'models/PaymentPlan';
import {
  EnumPopupType,
  EnumPopupButtonsType,
  EnumPopupButtonsStyle,
} from 'models/enums/EnumPopupType';
import {
  cancelPaymentPlanService,
  pausePaymentPlanService,
  resumePaymentPlanService,
  sendEmailsPaymentPlanService,
} from 'services/PaymentPlanService';
import { Popup } from 'components/popup/Popup';
import { ApiTenderTypeEnum, displayAmount, formatDate, getBalance, getUserName } from 'utils/Utils';
import { HeaderButton } from './HeaderButton';
import 'assets/styles/components/_paymentPlanDetails.scss';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { PlanOperations } from 'models/metaData/MetaDataEnums';
import { getTermTypePeriods } from 'features/paymentPlan/PlanUtils';
import { AlertIds, AlertTypes, removeAlert, setAlert } from '../../../../features/alert/AlertReducer';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import { getLogoDataForOrganization } from 'features/admin/logoManager/LogoManagerReducer';
import { useEditPlanTerms } from '../../../../features/planDetails/useEditPlanTerms';
import { useGetPatient } from '../../../../features/patients/hooks/useGetPatient';
import TransactionDetailsPrint from 'features/transactionDetails/TransactionDetailsPrint';
import { useGetReceipts } from 'features/admin/receipts/useGetReceipts';
import { ServiceCallResponse } from '../../../../services/callHttpApi';

const title = 'PAYMENT PLAN INFORMATION';

const popupFooterCancel = [
  {
    type: EnumPopupButtonsType.submit,
    style: EnumPopupButtonsStyle.secondary,
    text: 'Yes, cancel the record',
  },
  {
    type: EnumPopupButtonsType.cancel,
    style: EnumPopupButtonsStyle.primary,
    text: 'Do not cancel',
  },
];

const validations: {
  [k: string]: (
    plan: PaymentPlanModel,
    configuration?: PaymentPlanConfiguration,
  ) => boolean } = {
  statusIsCancelled: (plan: PaymentPlanModel) => {
    if (!plan) return false;
    return plan.status === EnumPlanStatus.Cancelled;
  },
  statusIsCompleated: (plan?: PaymentPlanModel) => {
    if (!plan) return false;
    return plan.status === EnumPlanStatus.Completed;
  },
  statusIsPaused: (plan?: PaymentPlanModel) => {
    if (!plan) return false;
    return plan.status === EnumPlanStatus.Paused;
  },  
  dueDateIsToday: (plan?: PaymentPlanModel) => {
    if (!plan) return false;
    const nextPaymentDate = moment(plan.nextPaymentDate);
    const dateToday = moment();

    return nextPaymentDate.isSame(dateToday, 'days');
  },
  pausesPerYearExceeded: (plan?: PaymentPlanModel, configuration?: PaymentPlanConfiguration) => {
    if (!plan) return false;
    const skipsForYear = getSkipsForYear(plan.pauseHistories);
    const maxPausesPerYear = configuration?.maximumNumberOfPausesPerYear ?? 0;

    return maxPausesPerYear > 0 && skipsForYear >= maxPausesPerYear;
  },
  finalPaymentDateInPast: (plan: PaymentPlanModel) => {
    const dateToday = moment();
    return !!plan.finalPaymentDate && moment(plan.finalPaymentDate).isBefore(dateToday);
  },  
}

const validationMessages = {
    pausesPerYearExceeded: 'The payment plan has reached the limit of maximum allowed skips per year!',
    dueDateIsToday: 'Payment plan cannot be cancelled on Payment Due Date',
    cancelPausedPlan: 'Cancel Plan is restricted on Paused Payment Plan.',
    editPausedPlan: 'Edit Plan is restricted on Paused Payment Plan.',
    skipLimitReached: 'Skip limit has been reached.',
    skipsAlreadyHaveBeenDone: 'You are not allowed to override skips which already have been done.',
    resumptionDateInPast: 'The Resumption Date is in the past. The skip cannot be reduced.',
    finalPaymentDateInPast: 'Cancel Payment Plan is restricted when final payment date is in the past.',
}

function getPaymentDate (date?: string | moment.Moment, termType?: string | EnumTermType, skips: number = 1) {
  if (!termType) return date;
  const termTypePeriod = getTermTypePeriods(termType);
  return moment(date).add(skips * termTypePeriod.count,
    termTypePeriod.duration as moment.unitOfTime.DurationConstructor)
}

function getSkipsForYear(pauseHistories?: PauseHistory[]) {
  const pausesForYear = pauseHistories?.filter(x => moment(x.pauseDate).year() == moment().year()) ?? [];
  return pausesForYear.reduce((skips, pause) => skips + pause.pauseDuration, 0);
}

function renderCancelIcon(isCancelDisabled: boolean, havePermission: boolean) {
  return isCancelDisabled || !havePermission ? <CancelDisabled /> : <Cancel />;
}

function renderPauselIcon(isCancelDisabled: boolean, havePermission: boolean) {
  return isCancelDisabled || !havePermission ? <PauseDisabled /> : <Pause />;
}

function renderEditlIcon(isCancelDisabled: boolean, havePermission: boolean) {
  return isCancelDisabled || !havePermission ? <EditDisabled /> : <Edit />;
}

const defaultErrorMessage = 'An error occured.';
const pausePlanErrorMessage = 'Plan not paused.';

const resumePlanErrorMessage = 'Plan not resumed.';
const planDetailsAlert = { id: AlertIds.PlanDetailsAlert, type: AlertTypes.Error, message: defaultErrorMessage };
export default function (props: {
  isEditing: boolean;
  setIsEditing: (flag: boolean) => void;
  isGl: boolean;
  wizard: Wizard;
  id?: string;
}) {
  const { isEditing, setIsEditing, isGl, wizard, id } = props;
  const history = useHistory();
  const [isPlanPausedOpen, setIsPlanPausedOpen] = useState(false);
  const [isPlanResumedOpen, setIsPlanResumedOpen] = useState(false);
  const [isEmailPopupOpened, setIsEmailPopupOpened] = useState(false);
  const [emailReceiptSent, setEmailReceiptSent] = useState(false);
  const [isPlanCanceled, setIsPlanCanceled] = useState(false);
  const [isCancelDisabled, setCancelDisabled] = useState(false);
  const [isEditDisabled, setEditDisabled] = useState(false);
  const [isPauseDisabled, setPauseDisabled] = useState(false);
  const [printReceiptUrl, setPrintReceiptUrl] = useState<string>('');

  const cancelPlanErrorMessage = defaultErrorMessage + ' Plan not canceled.';

  const pausePlanSuccessMessage = 'Plan successfully paused.';
  const resumePlanSuccessMessage = 'Payment plan status will be active on resumption date.';
  const fillOutAllFieldsMessage = 'Please fill out all required fields to continue.';
  const hasCancelError = false;

  const [nextPaymentDate, setNextPaymentDate] = useState<Date>(new Date());

  const dispatch = useDispatch();
  const { havePermission } = useLoggedUserPermissions();

  const { printPaymentPlanReceipt } = useGetReceipts();

  const paymentPlanRecord = useTypedSelector(s => {
    return s.paymentPlanInfo?.records?.find(x => x.id == id);
  });

  const { useGetDepartmentByPath, getFacilityByDepartment } = useOrganizations();
  const department = useGetDepartmentByPath(paymentPlanRecord?.department?.path);
  const facility = getFacilityByDepartment(paymentPlanRecord?.organization?.path);  

  const hasEditPermission = havePermission(UserPermissions.EditPaymentPlan, facility?.path);
  const hasPausePermission = havePermission(UserPermissions.ResumePausePaymentPlan, facility?.path);
  const hasCancelPermission = havePermission(UserPermissions.CancelPaymentPlan, facility?.path);

  const logoManager = useTypedSelector(s => s.logoManager);

  useEffect(() => {
    paymentPlanRecord?.organization?.path && dispatch(getLogoDataForOrganization(paymentPlanRecord?.organization?.path));
  }, [dispatch, paymentPlanRecord?.organization?.path]);

  const processingConfiguration = useTypedSelector(s => s.services.calls.GetPaymentPlanConfiguration)
  const { getPlanConfiguration } = useGetConfiguration(facility?.path || '');
  useEffect(() => {
    getPlanConfiguration();
  }, [paymentPlanRecord?.organization?.path]);

  const paymentPlanConfiguration = useTypedSelector(s => {
    if (facility?.path) {
      const conf =
        s.paymentPlanInfo.configurations[facility?.path || ''];
      return conf?.configuration;
    }
  });

  const { getPatientWhenNone } = useGetPatient(paymentPlanRecord?.patientId);

  const { onUpdatePlan, error } = useOnCreateOrUpdatePlan(
    {
      wizard,
      isGl,
      plan: paymentPlanRecord,
      onSuccess: () => {
        setIsEditing(true);
        getPatientWhenNone();
      },
      total: paymentPlanRecord?.amount
    }
  );

  const { paymentPlan: selectedPlan } = useGetPaymentPlanById(id);

  const pauseHistoryRecord = [...(selectedPlan?.pauseHistories || [])].sort((a, b) => {
      const aPauseDate = moment(a.pauseDate); 
      const bPauseDate = moment(b.pauseDate);

      if (aPauseDate == bPauseDate) {
        return 0; 
      }else if (aPauseDate < bPauseDate) {
        return 1;
      }
      return -1;
    })[0];

  const skipsForYear = getSkipsForYear(selectedPlan?.pauseHistories);

  const closeCancelPopup = () => {
    setIsPlanCanceled(false);
  };

  const openCancelPopup = () => {
    setIsPlanCanceled(true);
  };

  const closePausePopup = () => {
    setIsPlanPausedOpen(false);
  };

  const closeResumePopup = () => {
    setIsPlanResumedOpen(false);
  };

  const closeEmailPopup = () => {
    setIsEmailPopupOpened(false);
    setEmailReceiptSent(false);
  };

  const closeError = () => {
    dispatch(removeAlert(planDetailsAlert));
  }

  useEffect(() => {
    if (error) {
      dispatch(setAlert(planDetailsAlert));
    }
    let stopAllPlans = paymentPlanConfiguration?.stopAllPlansRecord?.stoppedBy ? true : false;
    let planCancelled = paymentPlanRecord && validations.statusIsCancelled(paymentPlanRecord) || false;
    let planCompleted = paymentPlanRecord && validations.statusIsCompleated(paymentPlanRecord) || false;
    const nextPamentDateIsTomorrow = isDateTodayOrTomorrow(selectedPlan?.nextPaymentDate);
    const buttonIsDisabled = (planCompleted || planCancelled || stopAllPlans || nextPamentDateIsTomorrow);
    setCancelDisabled(buttonIsDisabled);
    setEditDisabled(buttonIsDisabled);
    setPauseDisabled(buttonIsDisabled);
  }, [error, paymentPlanRecord, paymentPlanConfiguration])

  useEffect(() => {
    return () => {
      //on unload clear selected plans
      dispatch(setSelectedPlans([]));
    }
  }, [])

  const cancelPaymentPlan = async () => {
    const result = await cancelPaymentPlanService(id);
    if (result?.err) {
      dispatch(setAlert({ ...planDetailsAlert, message: cancelPlanErrorMessage}));
      return;
    }

    paymentPlanRecord && dispatch(
      updateRecord({
        id: paymentPlanRecord.id,
        paymentPlan: {
          ...paymentPlanRecord,
          status: EnumPlanStatus.Cancelled,
        },
      })
    );
    
    setCancelDisabled(true);
    setIsPlanCanceled(false);
  };

  const openPrintPreview = async()=>{ 
    const receiptURL = await printPaymentPlanReceipt(selectedPlan?.id ,'english');
    if(receiptURL){
      setPrintReceiptUrl( receiptURL)
    } 
  };

  const pauseAlert = { id: AlertIds.PauseAlert, type: AlertTypes.Error, message: validationMessages.skipLimitReached };

  const pausePaymentPlan = async () => {

    const data = {pauseDuration: selectedPlan?.skips || 1,
      pauseTermType: selectedPlan?.termType,
      pauseNotes: selectedPlan?.pauseNotes
    }
    const result = await pausePaymentPlanService(id, data)

    dispatchPauseResult(dispatch, result, selectedPlan);
    setIsPlanPausedOpen(false);
    dispatch(setAlert({ ...planDetailsAlert, type: AlertTypes.Success, message: pausePlanSuccessMessage, dismissable: true }));
  };

  const resumeAlert = { id: AlertIds.PauseAlert, type: AlertTypes.Error, message: "validationMessages.skipLimitReached" };

  const resumePaymentPlan = async () => {
    if (!selectedPlan?.nextPaymentDate && !isDateTomorrowOrAfter(nextPaymentDate)) {
      dispatch(setAlert({ ...planDetailsAlert, message: fillOutAllFieldsMessage }));
      return;
    }
    closeError();

    const data = {
      pauseDuration: selectedPlan?.skips ?? pauseHistoryRecord.pauseDuration,
      pauseNotes: selectedPlan?.pauseNotes ?? pauseHistoryRecord.pauseNotes,
      nextPaymentDateForSystemPaused: !selectedPlan?.nextPaymentDate ? moment.utc(nextPaymentDate).toISOString() : null,
    };

    const result = await resumePaymentPlanService(id, data)
    dispatchResumeResult(dispatch, result, selectedPlan, id);
   
    setIsPlanResumedOpen(false);
    dispatch(setAlert({ ...planDetailsAlert, type: AlertTypes.Info, message: resumePlanSuccessMessage, dismissable: true }));
  };

  const displayTenderType: {
    [key: string]: string
  } = {
    [String(ApiTenderTypeEnum.CardDevice)]: `Credit/Debit Device ${selectedPlan?.tenderMaskedAccount || '****'}`,
    [String(ApiTenderTypeEnum.CardManual)]: `Credit/Debit Manual Entry ${selectedPlan?.tenderMaskedAccount || '****'}`,
    [String(ApiTenderTypeEnum.ECheck)]: `eCheck ${selectedPlan?.tenderMaskedAccount || '****'}`,
    [String(ApiTenderTypeEnum.PaperCheckAsECheck)]: 'Paper Check',
  }

  const sendEmailReceipt = async (data: string[]) => {
    const result = selectedPlan?.id && await sendEmailsPaymentPlanService(selectedPlan?.id, data);

    if (result && result.err) {
      console.log(result.err);
    } else {
      setEmailReceiptSent(true);
    }

  };

  const onEditClick = useCallback((operation: PlanOperations) => {
    closeError();
    if (paymentPlanRecord && validations.statusIsPaused(paymentPlanRecord)) {
      dispatch(setAlert({ ...planDetailsAlert, message: validationMessages.editPausedPlan}));
      return;
    }

    //set which plan is open
    if (paymentPlanRecord?.id) {
      dispatch(changeSelected({ id: paymentPlanRecord?.id, isSelected: true }))
    }

    onUpdatePlan(operation);
  }, [paymentPlanRecord, isEditDisabled]);

  const onCancelClick = useCallback(() => {
    closeError();
    if (!paymentPlanRecord) {
      dispatch(setAlert(planDetailsAlert));
      return;
    }

    const validationGroup = [
      {validation: validations.statusIsPaused, message: validationMessages.cancelPausedPlan},
      {validation: validations.dueDateIsToday, message: validationMessages.dueDateIsToday},
      {validation: validations.finalPaymentDateInPast, message: validationMessages.finalPaymentDateInPast},
    ]
    const activeValidators = validationGroup.filter(x => x.validation(paymentPlanRecord));

    if (activeValidators.length > 0) {
      dispatch(setAlert({ ...planDetailsAlert, message: activeValidators[0].message }));
      return;
    }
    openCancelPopup();
  }, [paymentPlanRecord, isCancelDisabled]);

  const onPauseClick = useCallback(() => {
    closeError();
    if (!paymentPlanRecord) {
      dispatch(setAlert(planDetailsAlert));
      return;
    }

    if (validations.pausesPerYearExceeded(paymentPlanRecord, paymentPlanConfiguration)) {
      dispatch(setAlert({ ...planDetailsAlert, message: validationMessages.pausesPerYearExceeded }));
      return;
    }
    setIsPlanPausedOpen(true);
  }, [paymentPlanRecord, paymentPlanConfiguration, isPauseDisabled]);

  const onResumeClick = useCallback(() => {
    closeError();

    if (!pauseHistoryRecord) {
      dispatch(setAlert(planDetailsAlert));
      return;
    }
    dispatch(removeAlert(resumeAlert));

    setIsPlanResumedOpen(true);
  }, [paymentPlanRecord, paymentPlanConfiguration]);

  const handleSkipsChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newSkips = Number(event.target.value);
    const maxSkipsPerYear = paymentPlanConfiguration?.maximumNumberOfPausesPerYear;

    if (!selectedPlan || newSkips < 0 || (maxSkipsPerYear && newSkips > maxSkipsPerYear)) return;

    dispatch(removeAlert(pauseAlert));

    dispatch(
      updateRecord({
        id: selectedPlan.id,
        paymentPlan: {
          ...selectedPlan,
          skips: newSkips,
        },
      })
    );
  };

  const handleChangeNotes = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!selectedPlan) return;
    dispatch(
      updateRecord({
        id: selectedPlan.id,
        paymentPlan: {
          ...selectedPlan,
          pauseNotes: event.target.value,
        },
      })
    );
  };

  const popupChildrenCancel = (
    <div className="error-message">{'Error'}</div>
  )

  const getPopupPauseResumeChildren = () => {

    let skips, resumptionDate, pauseNotes;
    if (selectedPlan?.status == EnumPlanStatus.Paused && pauseHistoryRecord) {
      skips = selectedPlan?.skips ?? pauseHistoryRecord.pauseDuration
      skips = skips > -1 ? skips : 0;
    
      const previousDate = getPaymentDate(selectedPlan?.nextPaymentDate, pauseHistoryRecord.pauseTermType, pauseHistoryRecord.pauseDuration * -1);
      resumptionDate = getPaymentDate(previousDate, pauseHistoryRecord.pauseTermType, skips);
      pauseNotes = selectedPlan?.pauseNotes ?? pauseHistoryRecord.pauseNotes;
    } else {
      skips = selectedPlan?.skips || 0;
      resumptionDate = getPaymentDate(selectedPlan?.nextPaymentDate, selectedPlan?.termType, skips);
      pauseNotes = selectedPlan?.pauseNotes;
    }
    const paymentsRemaining = (selectedPlan?.originalPaymentsRemaining ?? 0) - (selectedPlan?.paymentsRemaining ?? 0);
    return  <>
        <div className="row">
          <div className="row-item eds-heading eds-heading.mdplus-caps mt-2 mb-3">
            {'Payment location'.toUpperCase()}
          </div>
        </div>
        <div className="flex-row">
          <div className="column-6">
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Facility:</div>
              <div className="col-6 row-data">{facility?.name}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Department:</div>
              <div className="col-6 row-data">{department?.name}</div>
            </div>
          </div>
        </div>  
        <div className="row">
          <div className="row-item eds-heading eds-heading.mdplus-caps mt-4 mb-3">
            {'Payment plan details'.toUpperCase()}
          </div>
        </div>
        <div className="flex-row">
          <div className="column-6">
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Payment Plan Profile ID:</div>
              <div className="col-6 row-data">{selectedPlan?.id}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Payer First Name (Billing):</div>
              <div className="col-6 row-data">{selectedPlan?.billingInformation?.firstName}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Payer Last Name (Billing):</div>
              <div className="col-6 row-data">{selectedPlan?.billingInformation?.lastName}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Plan Term:</div>
              <div className="col-6 row-data">{selectedPlan?.termType ?? EnumTermType.None}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Plan Last Modified By:</div>
              <div className="col-6 row-data">{getUserName(selectedPlan?.updatedBy, true)}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Plan Last Modified On:</div>
              <div className="col-6 row-data">{selectedPlan?.updatedDate}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Payment Plan Start Date:</div>
              <div className="col-6 row-data">{moment(selectedPlan?.startDate).utc().format('MM/DD/YYYY')}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Next Payment Date:</div>
              <div className="col-6 row-data">{selectedPlan?.nextPaymentDate ? moment(selectedPlan?.nextPaymentDate).utc().format('MM/DD/YYYY') : ''}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Final Payment Date:</div>
              <div className="col-6 row-data">{moment(selectedPlan?.finalPaymentDate).format('MM/DD/YYYY')}</div>
            </div>
            <div className="row">
            <div className="col-6 row-data eds-field_#label">Total Plan Balance:</div>
            <div className="col-6 row-data">${displayAmount(getBalance(selectedPlan?.lineItems??[]))}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Number of Payments:</div>
              <div className="col-6 row-data">{selectedPlan?.originalPaymentsRemaining}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Payments Processed:</div>
              <div className="col-6 row-data">{paymentsRemaining > -1 ? paymentsRemaining : '-'}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Payments Remaining:</div>
              <div className="col-6 row-data">{selectedPlan?.paymentsRemaining}</div>
            </div>
            <div className="row">
              <div className="col-6 row-data eds-field_#label">Tender Type:</div>
              <div className="col-6 row-data">{selectedPlan?.tenderType ? displayTenderType[selectedPlan.tenderType] : ApiTenderTypeEnum.Undefined}</div>
            </div>
            {!selectedPlan?.nextPaymentDate && (
              <PaymentResumptionDatePicker nextPaymentDate={nextPaymentDate} onDateChange={setNextPaymentDate} />
            )}
          </div>

          {selectedPlan?.nextPaymentDate && (
          <div className="column-4">
            <div className="flex-row">
              <EDS_TextBox
                name="skips"
                type="number"
                label="Number of Payment Skips:"
                modifiers={'short-field'}
                value={skips}
                onChange={handleSkipsChange}
                min={0}
                max={paymentPlanConfiguration?.maximumNumberOfPausesPerYear}                
              />
              <div className="skips eds-field_#label">
                {`${skipsForYear}/${paymentPlanConfiguration?.maximumNumberOfPausesPerYear}`}
              </div>
            </div>
            <div className="italic-note">{`${paymentPlanConfiguration?.maximumNumberOfPausesPerYear} skips allowed per year`}</div>
            <label className="payment-date-label">
              <div className="eds-field_#label">Payment Resumption Date:</div>
            </label>
            <div className={`eds-field_#control`}>
              <div>{formatDate(moment(resumptionDate).utc())}</div>
            </div>
          </div>
          )}
        </div>
        <div className="pause-notes mt-3">
          <label className="eds-field_#label">Notes</label>
          <EDS_TextArea
            name="pauseNotes"
            modifiers={'full-width-area'}
            heightModifier={'auto'}
            onChange={handleChangeNotes}
            value={pauseNotes}
          ></EDS_TextArea>
        </div>
      </>;
  };

  const popupChildrenPause = getPopupPauseResumeChildren();
  const popupChildrenResume = getPopupPauseResumeChildren();

  const getPopupPauseResumeFooter = (btnCancelName: string, btnSubmitName: string) => {
    return [
      {
        type: EnumPopupButtonsType.cancel,
        style: EnumPopupButtonsStyle.secondary,
        text: btnCancelName,
      },
      {
        type: EnumPopupButtonsType.submit,
        style: EnumPopupButtonsStyle.primary,
        text: btnSubmitName,
      },
    ];
  };

  const popupFooterPause = getPopupPauseResumeFooter('Cancel', 'Apply Pause');
  const popupFooterResume = getPopupPauseResumeFooter('Cancel', 'Apply Resume');

  const editButtonTitle = processingConfiguration?.isProcessing ? 'Processing...' : 'Edit Plan';

  const newSearch = () => {
    dispatch(setOpenedPlanId()); 
    history.push('/searchpayments');
  }

  const { editPlanTerms, editPlanDetails, showEditDropdown, setShowEditDropdown  } = useEditPlanTerms()

  return processingConfiguration?.isProcessing ? (<div>Processing...</div>) : (
    <div className="header-wrapper">
      {isPlanCanceled && <Popup
        type={EnumPopupType.warning}
        title='Are you sure you want to cancel this record?'
        text='This action cannot be undone.'
        children={hasCancelError && popupChildrenCancel}
        footer={popupFooterCancel}
        onClose={closeCancelPopup}
        onSubmit={cancelPaymentPlan}
      />}
      {isPlanPausedOpen && <Popup
        type={EnumPopupType.basic}
        header="Pause Plan"
        fullHeight={true}
        customStyle="modal-custom-width-size"
        children={popupChildrenPause}
        footer={popupFooterPause}
        onClose={closePausePopup}
        onSubmit={pausePaymentPlan}
      />}
      {isPlanResumedOpen && <Popup
        type={EnumPopupType.basic}
        header="Resume Plan"
        fullHeight={true}
        customStyle="modal-custom-resume-size"
        children={popupChildrenResume}
        footer={popupFooterResume}
        onClose={closeResumePopup}
        onSubmit={resumePaymentPlan}
      />}
      {isEmailPopupOpened && <EmailReceipt
        defaultEmail={selectedPlan?.notificationEmail}
        emailReceiptSent={emailReceiptSent}
        onClose={closeEmailPopup}
        onSubmit={sendEmailReceipt}
      />}
      <div className="page-title">{title}</div>
      <div className="header-buttons">
        <HeaderButton
          title="New Search"
          icon={<Search />}
          onClick={newSearch} />
        <HeaderButton
          title="Send Email"
          icon={<Mail />}
          onClick={() => {
            setIsEmailPopupOpened(true);
            closeError();
          }} />
        <div className={"header-button dropdownContent"}><HeaderButton
          title={editButtonTitle}
          icon={ renderEditlIcon(isEditDisabled, hasEditPermission) }
          disabled={
            isEditing 
            || processingConfiguration?.isProcessing
            || isEditDisabled 
            || !hasEditPermission
          }
          onClick={() => setShowEditDropdown(!showEditDropdown)} />
          <EditDropdown
            showEditDropdown={showEditDropdown}
            onEditClick={onEditClick}
            editPlanDetails={editPlanDetails}
            editPlanTerms={editPlanTerms}
          />
          </div>
        {selectedPlan?.status === EnumPlanStatus.Paused
          ? (<HeaderButton
            title="Resume Plan"
            icon={<Resume />}
            disabled={!hasPausePermission}
            onClick={onResumeClick} />)
          : (<HeaderButton
            title="Pause Plan"
            icon= { renderPauselIcon(isPauseDisabled, hasPausePermission) }
            disabled={isPauseDisabled || !hasPausePermission}
            onClick={onPauseClick} />)
        }
        <HeaderButton
          title="Cancel Plan"
          icon={ renderCancelIcon(isCancelDisabled, hasCancelPermission) }
          disabled={isCancelDisabled || !hasCancelPermission}
          onClick={onCancelClick} />
        <HeaderButton
          title="Print Preview"
          icon={<Print />}
          onClick={() => {openPrintPreview && openPrintPreview(); closeError();}} />
      </div>

      {printReceiptUrl && <TransactionDetailsPrint receiptUrl={printReceiptUrl}/> }
    </div>
  );
}

export function EditDropdown(props: {
  showEditDropdown: boolean,
  onEditClick: (operation: PlanOperations) => void,
  editPlanTerms: (callback: (operation: PlanOperations) => void) => void,
  editPlanDetails: (callback: (operation: PlanOperations) => void) => void,
}) {
  const { showEditDropdown, onEditClick, editPlanTerms, editPlanDetails } = props;
  return showEditDropdown ?
    <div className="edit-popover eds-popover reduced-padding no-margin" role="popover">
      < div onClick={() => editPlanTerms(onEditClick)
      } >
        Edit Plan Terms
      </div >
      <div onClick={() => editPlanDetails(onEditClick)} >
        Edit Payment Method
      </div>
    </div >
    : null
}

const isDateTomorrowOrAfter = (date: Date | undefined) => {
  const tomorrow = moment().add(1, 'days').startOf('day');
  const isDateValid = date && moment(date).isSameOrAfter(tomorrow);
  return isDateValid;
}

const isDateTodayOrTomorrow = (date: string | undefined) => {
  if (date === undefined) return false;
  const today = moment().utc().startOf('day');
  const currentDate = moment(date).utc().startOf('day');
  return (currentDate.isSame(today) || currentDate.isSame(today.add(1, 'day')));
}

const PaymentResumptionDatePicker = (props: {
  nextPaymentDate?: Date, 
  onDateChange: (selectedDate: Date) => void,
}) => {
  const { nextPaymentDate, onDateChange } = props;

  const datePickerRef = useRef() as any;

  const handleClickDatePickerIcon=()=>{
    datePickerRef.current.setOpen(true);
  }

  return (
    <div className="row">
      <div className="col-6 row-data eds-field_#label">
        <label>Payment Resumption Date:</label>
      </div>
      <div className="col-6 row-data">
        <div className={`eds-field_#control eds-field_.eds-input eds-input`}>
          <div className="react-datepicker__date-wrapper">
              <DatePicker
                selected={isDateTomorrowOrAfter(nextPaymentDate) ? nextPaymentDate : moment(nextPaymentDate).utc().add(1, 'days').toDate()}
                onChange={onDateChange}
                dateFormat="yyyy-MM-dd"
                name={'nextPaymentDate'}
                maxDate={moment().utc().add(6, 'months').toDate()}
                minDate={moment().utc().add(1, 'days').toDate()}
                className="eds-input_#input"
                customInput={<input className={'date-input'} type="date" />}
                required={true}
                isClearable={false}
                popperPlacement="top-end"
                ref={datePickerRef}
              />
            </div>
            <div className="react-datepicker__calendar-icon-wrapper">
              <span className="calendarIcon" onClick ={()=>handleClickDatePickerIcon()}>
                <Calendar />
              </span>
            </div>
        </div>
      </div>
    </div>
  );
};

function dispatchPauseResult(dispatch: any, result?: ServiceCallResponse<PaymentPlanModel>, selectedPlan?: PaymentPlanModel ) {
  if (result?.err) {
    dispatch(setAlert({ ...planDetailsAlert, message: `${result.errorResponse.data.validationErrors[0].errorMessage} ${pausePlanErrorMessage}` }));
    return;
  }
  if (selectedPlan?.id && result?.result) {
    dispatch(updateRecord({
      id: selectedPlan.id,
      paymentPlan: {
        ...selectedPlan,
        status: EnumPlanStatus.Paused,
        pauseHistories: result.result?.pauseHistories ?? [],
        nextPaymentDate: result.result?.nextPaymentDate,
        finalPaymentDate: result.result?.finalPaymentDate,
      }
    }));
    dispatch(addBackup(result.result));
  }
}
function dispatchResumeResult(dispatch: any, result?: ServiceCallResponse<PaymentPlanModel>, selectedPlan?: PaymentPlanModel, id?: string ) {
  if (result?.err) {
    dispatch(setAlert({ ...planDetailsAlert, message: `${result.errorResponse.data.validationErrors[0].errorMessage} ${resumePlanErrorMessage}` }));
    return;
  }

  result?.result && dispatch(
    updateRecord({
      id,
      paymentPlan: {
        ...result.result,
      },
    })
  );
}