import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as router from 'react-router-dom';
import moment from 'moment';
import { useTypedSelector } from 'app/rootReducer';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import {
  CustomSearchPlanColumns,
  GridConfig,
  setCustomSearchPlanColumnsState,
} from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';
import { cardBrands, convertDateToUtc, checkReceivedHasValues, filterEmptyFields } from 'utils/Utils';
import { PaymentPlanModel } from 'models';
import { PatientModel } from 'models/PatientModel';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import {
  CreatePaymentPlansExcelFullReportService,
  getPaymentPlanSearchService,
  getPaymentPlanSearchSummaryService,
  GetPaymentPlansSummaryResult,
} from 'services/PaymentPlanService';
import {
  SearchResultsTemplate,
  DataType,
  SearchMode,
  SummaryColumn,
} from '../SearchResultsTemplate/SearchResultsTemplate';
import {
  renderPatientData,
  renderPopup,
  mapParams,
  renderCardBrand,
  getMapValueFormatter,
  getOrganizationName,
  getOrganizationsCount,
  getUserName,
  defaultFilterParams,
  mapPlanFilterNames,
  renameKeys,
  getAgGridColumnFilter,
} from '../SearchResultsTemplate/SearchResultUtils';
import { SHOW_RESULTS_LIMIT } from '../SearchResultsTemplate/Header';
import { appendTimeStampMax, appendTimeStampMin, getSearchCriteriaBySearchId, setRetrievedUserSettings } from '../searchUtils';
import { PaymentPlanSearchInformation } from './PaymentPlanSearchReducer';
import { tenderTypesIds , planStatusOptions} from './FilterData';
import { data as columnsData } from './gridConfig';
import 'assets/styles/components/_searchPlansResults.scss';
import { DownloadExcelFullReportService, ReportType } from 'services/transactions/ExportTransactionService';
import { useCreateReport } from '../SearchResultsTemplate/useCreateReport';
import { setCall } from 'services/ServicesReducer';
import { AlertIds, AlertTypes, removeAlert, setAlert } from 'features/alert/AlertReducer';
import { ReactComponent as ProgressIcon } from 'assets/svgs/progresswheel-icon.svg';

const formatDate = (string: string) => moment(string).format('YYYY-MM-DDTHH:mm:ss.000')
export const formatDateFrom = (string: string | undefined | null) => string && `from ${formatDate(string)}`;
export const formatDateTo = (string: string | undefined | null) => string && `to ${formatDate(string)}`;
export const messages = {
  fileTooBig: "The file's size exceeds the allowed limit of 16MB. Please refine your search parameters."
};

export function PaymentPlansSearchResult() {
  let { search } = router.useLocation()
  let searchQueryParams = new URLSearchParams(search);
  const searchId = searchQueryParams.get('searchId') ?? '';
  const searchCriteria = getSearchCriteriaBySearchId(searchId);

  const { getFacilityByDepartment } = useOrganizations();
  const dispatch = useDispatch();
  const [showNotesPopup, setShowNotesPopup] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [summaryLoading, setSummaryLoading] = useState<boolean>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [error, setError] = useState<string | boolean>(false);
  const [filter, setFilter] = useState();

  const [showCustomizeSearchColumns, setShowCustomizeSearchColumns] = useState<boolean>(false);
  const pageSize = useTypedSelector(c => c.simpleSearchInfo?.pageSize);
  const customColumnsState = useTypedSelector(
    c => c.customSearchColumns?.plans.value
    ) as { [key: string]: any };
  const organizations = useTypedSelector(p => p.organizations?.value);
  const users = useTypedSelector(p => p.userInfo?.users || []);
  const alerts = useTypedSelector(s => s.alerts?.alerts);
  const downloadAlert = alerts?.find(
    alert => alert.id === AlertIds.DownloadExcelFullReport
  );

  const [chosenColumns, setChosenColumns] = useState<GridConfig[]>([]);
  
  useEffect(() => {
    setRetrievedUserSettings(`planColumnOrder`, setChosenColumns, columnsData); 
  }, []);

  function handleCustomColums() {
    setShowCustomizeSearchColumns(!showCustomizeSearchColumns);
  }

  function getQueryParam(key: keyof PaymentPlanSearchInformation): any {
    return searchCriteria[key] ?? undefined;
  }

  function getQueryParamArray(key: keyof PaymentPlanSearchInformation): any[] {
    const value = searchCriteria[key];
  
    if (!value) {
      return [];
    } 
    
    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  }

  const patiendFields = ['patientId', 'mrn', 'patientMrn', 'patientFirstName', 'patientLastName', 'accountNumber', 'patientGuarantorAccountNo'];
  const hasPatientField = patiendFields.filter(el => searchCriteria[el] ? true : false);
  let patientParams = !!hasPatientField.length && '?' + hasPatientField.map(param => `${param}=${searchCriteria[param]}`).join('&');
  patientParams = patientParams && patientParams.replace('guarantorId', 'guarantorAccountNo').replace('accountNumber', 'accountNo');
  const patients: PatientModel[] = [];

  const transformPlanQueryParamsToObject = () => {
    const props = {
      organizationPaths: getQueryParamArray('organizationPaths'),
      departmentPaths: getQueryParamArray('departmentPaths'),
      userId: getQueryParamArray('userId'),
      mrn: getQueryParam('mrn'),
      patientAccountNumber: getQueryParam('patientAccountNumber'),
      patientFirstName: getQueryParam('patientFirstName'),
      patientLastName: getQueryParam('patientLastName'),
      payerFirstName: getQueryParam('payerFirstName'),
      payerLastName: getQueryParam('payerLastName'),
      guarantorAccountNo: getQueryParam('guarantorAccountNo'),
      createdDateMin: getQueryParam('createdDateMin'),
      createdDateMax: getQueryParam('createdDateMax'),
      nextPaymentDateMin: getQueryParam('nextPaymentDateMin'),
      nextPaymentDateMax: getQueryParam('nextPaymentDateMax'),
      amountMin: Number(getQueryParam('amountMin')) || undefined,
      amountMax: Number(getQueryParam('amountMax')) || undefined,
      id: getQueryParam('id'),
      status: getQueryParamArray('status'),
      transactionId: getQueryParam('transactionId'),
      tenderLastFourDigits: getQueryParam('tenderLastFourDigits'),
      legacyId: getQueryParam('legacyId'),
      tenderType: getQueryParamArray('tenderType'),
      cardBrand: getQueryParamArray('cardBrand'),
    }
    return props;
  }

  const getFilteredPaymentPlansService = useCallback(async () => {
    setLoading(true);
    try {
      appendTimeStampMin(searchCriteria, 'nextPaymentDateMin');
      appendTimeStampMax(searchCriteria, 'nextPaymentDateMax');

      const plan = transformPlanQueryParamsToObject();

      let data = {
        ...plan, 
        userId: plan.userId.length > 1000 ? [] : plan.userId,
        limit: pageSize,
        createdDateMin: plan.createdDateMin ? convertDateToUtc(plan.createdDateMin) : "",
        createdDateMax: plan.createdDateMax ? convertDateToUtc(plan.createdDateMax) : "",
        organizationPaths: plan.organizationPaths.map(path => path.split('|').slice(0, 4).join('|') + '|')
      };

      const payload = filterEmptyFields(data);
      const response = await getPaymentPlanSearchService(payload);
      setError(false);
      if (response?.errorResponse?.data?.detail) {
        setError(response.errorResponse.data.detail);
      } else if (response?.err) {
        setError(response.err);
      }

      setLoading(false);
      response && response.result && response.result.records.length > 0
        ? setNoDataFound(false)
        : setNoDataFound(true);

      return response;
    } catch (e) {
      console.log(e);
    }
  }, [search, patientParams]);

  const groupSummaryData = (rawData: GetPaymentPlansSummaryResult) => {
    const data: SummaryColumn[][] = [
      filterSummaryData([
        { field: 'Plan Status Summary', value: { count: 'Count', total: 'Plan Balance' }, format: 'title' },
        { field: 'Payment Plans Active', value: rawData.activePlansSummary },
        { field: 'Payment Plans Paused', value: rawData.pausedPlansSummary },
        { field: 'Payment Plans Stopped', value: rawData.stoppedPlansSummary },
        { field: 'Payment Plans Completed', value: rawData.completedPlansSummary },
        { field: 'Payment Plans Canceled', value: rawData.cancelledPlansSummary },
        { field: 'GL Plans Active', value: rawData.activeGlPlansSummary },
        { field: 'GL Plans Paused', value: rawData.pausedGlPlansSummary },
        { field: 'GL Plans Stopped', value: rawData.stoppedGlPlansSummary },
        { field: 'GL Plans Completed', value: rawData.completedGlPlansSummary },
        { field: 'GL Plans Canceled', value: rawData.cancelledGlPlansSummary },
        { field: 'Total', value: rawData.plansStatusSummaryTotal, format: 'total' },
      ]),
      filterSummaryData([
        { field: 'Plan Changes within the Period', value: { count: 'Count', total: 'Plan Balance' }, format: 'title' },
        { field: 'Payment Plans newly Created', value: rawData.newPlansSummary },
        { field: 'Payment Plans Edited', value: rawData.editedPlansSummary },
        { field: 'GL Plans newly Created', value: rawData.newGlPlansSummary },
        { field: 'GL Plans Edited', value: rawData.editedGlPlansSummary },
        { field: 'Total', value: rawData.newAndEditedPlansTotal, format: 'total' },
      ]),
      filterSummaryData([
        { field: 'Tender Type Summary', value: { count: 'Count', total: 'Total' }, format: 'title' },
        { field: 'Credit Card', value: rawData.cardPlansSummary },
        { field: 'eCheck', value: rawData.eCheckPlansSummary },
        { field: 'Total', value: rawData.cardAndECheckPlansTotal, format: 'total' },
      ]),
      filterSummaryData([
        { field: 'Credit Card Summary', value: { count: 'Count', total: 'Total' }, format: 'title' },
        { field: 'American Express', value: rawData.amexPlansSummary },
        { field: 'Discover', value: rawData.discoverPlansSummary },
        { field: 'MasterCard', value: rawData.masterCardPlansSummary },
        { field: 'Visa', value: rawData.visaPlansSummary },
        { field: 'Other', value: rawData.otherCardsPlansSummary },
        { field: 'Total', value: rawData.cardPlansTotal, format: 'total' },
      ]),
      filterSummaryData([
        { field: 'Payment Source Summary', value: { count: 'Count', total: 'Total' }, format: 'title' },
        { field: 'ECareNext', value: rawData.eCareNextPlansSummary },
        { field: 'EPIC', value: rawData.epicPlansSummary },
        { field: 'EpicHostedPaymentPage', value: rawData.epicHostedPaymentPagePlansSummary },
        { field: 'ExternalTransaction', value: rawData.externalTransactionPlansSummary },
        { field: 'FlatFileImportService', value: rawData.flatFileImportPlansSummary },
        { field: 'IVR', value: rawData.ivrPlansSummary },
        { field: 'Kiosk', value: rawData.kioskPlansSummary },
        { field: 'PaymentSafe', value: rawData.paymentSafePlansSummary },
        { field: 'PatientFinancialNavigator', value: rawData.patientFinancialNavigatorPlansSummary },
        { field: 'PatientSimple', value: rawData.patientSimplePlansSummary },
        { field: 'PatientSimple QuickPay', value: rawData.patientSimpleQuickPayPlansSummary },
        { field: 'RegistrationAccelerator', value: rawData.registrationAcceleratorPlansSummary },
        { field: 'OneSource', value: rawData.oneSourcePlansSummary },
        { field: 'TextToPay', value: rawData.textToPayPlansSummary },
        { field: 'Semafone', value: rawData.semafonePlansSummary },
        { field: 'Other', value: rawData.otherPaymentSourcePlansSummary },
        { field: 'Total', value: rawData.paymentSourceSummaryTotal, format: 'total' },
      ]),
    ]
    return data;
  }

  const filterSummaryData = (summaryFields: SummaryColumn[]) => {
    const headerList = ['Plan Status Summary', 'Plan Changes within the Period', 'Tender Type Summary', 'Credit Card Summary', 
    'Payment Source Summary', 'Total' ];
    return summaryFields.filter(row => Number(row.value?.count) > 0 || headerList.includes(row.field));
  }

  const updateSummary = (customFilter: any) => {
    setFilter(customFilter);
  }

  const getPaymentPlansSummaryService = useCallback(async () => {
    setSummaryLoading(true);
    try {
      appendTimeStampMin(searchCriteria, 'nextPaymentDateMin');
      appendTimeStampMax(searchCriteria, 'nextPaymentDateMax');

      const mappedKeysFilter = renameKeys(filter, mapPlanFilterNames);
      const response = await getPaymentPlanSearchSummaryService({ ...transformPlanQueryParamsToObject(), filter: mappedKeysFilter });
      setSummaryLoading(false);
      const grouped = response && response.result && groupSummaryData(response.result)

      return grouped || response;
    } catch (e) {
      console.log(e);
    }

  }, [search, filter, patientParams]);

  const headerData: DataType[] = [
    {
      field: 'Facility',
      value: getOrganizationName(OrganizationLevelTypes.Facility, organizations, getQueryParamArray('organizationPaths')),
      type: checkReceivedHasValues(getOrganizationsCount(OrganizationLevelTypes.Facility, organizations, getQueryParamArray('organizationPaths')) > SHOW_RESULTS_LIMIT, 'popup' , ''),
    },
    {
      field: 'Department',
      value: getOrganizationName(OrganizationLevelTypes.Department, organizations, getQueryParamArray('organizationPaths')),
      type: checkReceivedHasValues(getOrganizationsCount(OrganizationLevelTypes.Department, organizations, getQueryParamArray('organizationPaths')) > SHOW_RESULTS_LIMIT, 'popup' , ''),
    },
    {
      field: 'User',
      value: getUserName(users, getQueryParamArray('userId')),
      type: checkReceivedHasValues(getQueryParamArray('userId').length > SHOW_RESULTS_LIMIT, 'popup', ''),
    },
    { field: 'MRN', value: getQueryParam('mrn') },
    { field: 'Patient Account No', value: getQueryParam('patientAccountNumber') },
    { field: 'Patient First Name', value: getQueryParam('patientFirstName') },
    { field: 'Patient Last Name', value: getQueryParam('patientLastName') },
    { field: 'Payer First Name', value: getQueryParam('payerFirstName') },
    { field: 'Payer Last Name', value: getQueryParam('payerLastName') },
    { field: 'Guarantor ID', value: getQueryParam('guarantorAccountNo') },
    {
      field: 'Submitted Date Range', value:
        `${formatDateFrom(getQueryParam('createdDateMin')) ||
        ''} ${formatDateTo(getQueryParam('createdDateMax')) ||
        ''}`.trim()
    },
    {
      field: 'Next Payment Date Range', value:
        `${formatDateFrom(getQueryParam('nextPaymentDateMin')) ||
        ''} ${formatDateTo(getQueryParam('nextPaymentDateMax')) ||
        ''}`.trim()
    },
    {
      field: 'Payment Amount Range', value:
        `${getQueryParam('amountMin') || ''}
        ${getQueryParam('amountMin') && getQueryParam('amountMax') ? ' - ' : '' }
        ${getQueryParam('amountMax') || ''}`.trim()
    },
    { field: 'Plan ID', value: getQueryParam('id') },
    { field: 'Plan Status', 
      value: planStatusOptions.map((value) => {return value.value}).
             filter(type=>getQueryParamArray('status').includes(type)).join(', ') || 'All' },
    { field: 'Legacy ID', value: getQueryParam('legacyId') },
    { field: 'Transaction ID', value: getQueryParam('transactionId') },
    { field: 'Method Ending In', value: getQueryParam('tenderLastFourDigits') },
    {
      field: 'Tender Types', value:
        tenderTypesIds.filter(type => getQueryParamArray('tenderType').includes(type.id.toString())).map(c => c.label).join(', ')
    },
    {
      field: 'Card Brands', value:
        cardBrands.filter(card => getQueryParamArray('cardBrand').includes(card.propertyName)).map(c => c.label).join(', ')
    },
  ];

  const closeNotesPopup = () => setShowNotesPopup('');

  const mapCellRenderer = (key: string) => {
    const cellRenderer = {
      popup: (params: any) => renderPopup({ ...params, setShowNotesPopup }),
      patientData: (params: any) => renderPatientData(params, patients),
      cardBrand: (params: any) => renderCardBrand(params),
    }[key];
    return cellRenderer ?? key
  };

  const headerDefinitions = chosenColumns && chosenColumns?.map((row: GridConfig) => ({
    headerName: row.label,
    ...row.getValue
      ? { valueGetter: (params: { data: PaymentPlanModel }) => row.getValue && row.getValue(params.data, { getFacilityByDepartment }), }
      : { field: row.field },
    hide: !customColumnsState[row.field],
    filter: getAgGridColumnFilter(row),
    filterParams: row.filterParams
      ? { ...defaultFilterParams, ...mapParams[row.filterParams] }
      : defaultFilterParams,
    valueFormatter: row.valueFormatter && getMapValueFormatter()[row.valueFormatter],
    cellRenderer: row.cellRenderer && mapCellRenderer(row.cellRenderer),
    cellStyle: row.cellStyle && { textAlign: row.cellStyle },
    colId: row.colId ?? row.field,
    sortable: row.sortable,
    pinned: row.pinned,
    width: row.width,
    resizable: row.resizable ?? true,
    suppressMovable: row.suppressMovable
  }));

  const resetFiltersSummary = () => {
    setFilter(undefined);
  }

  const renderNotePopup = () => {
    return (
      <div className='notes-popup'>
        <button className='button-close material-icons' onClick={closeNotesPopup}>close</button>
        <div>{showNotesPopup}</div>
      </div>
    )
  }
  const isDownloading = useTypedSelector(s=> s.services.calls.DownloadExcelFullPaymentPlansReport?.isProcessing)

  const downloadExcelFull = async () => {
    if (isDownloading) return
    dispatch(setCall({ DownloadExcelFullPaymentPlansReport: { isProcessing: true } }));
    dispatch(setAlert({ 
      id: AlertIds.DownloadExcelFullReport, 
      type: AlertTypes.Info, message: "Your request is being processed. It will start downloading automatically when ready. Please do not exit the page while the export is in progress", 
      dismissable: false  
    }));
    appendTimeStampMin(searchCriteria, 'createdDateMin');
    appendTimeStampMax(searchCriteria, 'createdDateMax');
    appendTimeStampMin(searchCriteria, 'nextPaymentDateMin');
    appendTimeStampMax(searchCriteria, 'nextPaymentDateMax');

    const response = await CreatePaymentPlansExcelFullReportService({
      ...transformPlanQueryParamsToObject(),
      paymentPlanExportType: 2
    });

    if (response.result) {
      const report = await createReport(response.result,true);

      if (report) {
        if (!report.err) {
          const fileTooBig = report.contentSize > report.maxLimitSize ?? false;
          if (fileTooBig) {
            dispatch(setAlert({ 
              id: AlertIds.FileIsTooBig, 
              type: AlertTypes.Error, 
              message: messages.fileTooBig, 
              dismissable: false 
            }));
          } else {
            await DownloadExcelFullReportService(report.downloadLink, ReportType.paymentPlans, moment().format('MM-DD-YY'));
          }
        }
        else {
          dispatch(setAlert({ 
            id: AlertIds.GlPaymentAlert, 
            type: AlertTypes.Error, 
            message: 
            report.err 
          }));
        }
      }
      }

    dispatch(setCall({ DownloadExcelFullPaymentPlansReport: { isProcessing: false } }));
    dispatch(removeAlert({id: AlertIds.DownloadExcelFullReport}))
  };

  const mapReport: { [key: string]: any } = {
    excelFull: downloadExcelFull,
    /* TODO: backend not ready yet
    excelFiltered: () => {}, [BE]STEL-3714 [FE]STEL-3719
    pdfFull: () => {}, [BE]STEL-3716 [FE]STEL-3720
    pdfFiltered: () => {}, [BE]STEL-3717 [FE]STEL-3721
    */
  };

  const downloadReport = (type: string) => mapReport[type]();

  const { createReport } = useCreateReport();

  function paymentProcessing() {
    if (downloadAlert) {
      return (
        <div className={`processingContainer`}>
          <span className={`processing-label paymentProcessing`}>
            <ProgressIcon />
            <span className="processingLabel"> Loading... Please Wait.</span>
          </span>
        </div>
      );
    }
  }

  return (
    <>
      {paymentProcessing()}
      {showNotesPopup && renderNotePopup()}
      {organizations?.length > 0 && (
        <SearchResultsTemplate
          breadcrumbs={{
            name: 'Plan Search /  Results Summary',
            slug: 'plansSearchResult',
          }}
          mode={SearchMode.plan}
          headerData={headerData}
          resultsHeaderDefinitions={headerDefinitions}
          buttons={[
            'clearFilters',
            'newSearch',
            'customize',
            'setStatus',
            'exportFile',
            'saveColumnOrder'
          ]}
          onClickCustomize={handleCustomColums}
          showCustomizeColumns={showCustomizeSearchColumns}
          columnsData={columnsData}
          getData={getFilteredPaymentPlansService}
          updateSummary={updateSummary}
          getSummaryData={getPaymentPlansSummaryService}
          resetFiltersSummary={resetFiltersSummary}
          loading={loading}
          summaryLoading={summaryLoading}
          noDataFound={noDataFound}
          error={error}
          downloadReport={downloadReport}
          filterData={filter}
        />
      )}
    </>
  );
}
