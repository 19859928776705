import React, { useState } from 'react';

import {
  EDS_Select,
  EDS_TextBox,
  EDS_TextArea,
  EDS_Switch,
  EDS_Button
} from '@EH/eh.eds.react';
import { FaSave } from 'react-icons/fa';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ReactComponent as Help } from 'assets/svgs/paymentPlanIcons/icon-help-outline.svg';
import { Placement } from 'react-bootstrap/esm/Overlay';
import 'assets/styles/components/_creditCardForm.scss';
import { ReactComponent as VerifiedIcon } from 'assets/svgs/paymentPlanIcons/icon-verified.svg';
import { ReactComponent as DisabledVerifiedIcon } from 'assets/svgs/paymentPlanIcons/icon-verified-disabled.svg';

export interface CreditCard {
  id: string;
  clientId: number;
  providerId: string;
  configName: string;
  tbApiId: string;
  tbApiToken: string;
  merchantId: string;
  merchantPass: string;
  notes?: string;
  tbMid: string;
  tbTid: string;
  tbReceiptId: string;
  paymentFormSourceId: string;
  isProduction: boolean;
}

interface SelectOption {
  optionName: string;
  value: string;
}

interface CreditCardFormProps {
  formValues: CreditCard;
  onSubmit: Function;
  submitting: boolean;
  providerOptions: SelectOption[];
  controlCenterOptions: SelectOption[];
  isCredentialsVerified: boolean;
  onVerify: any;
  verifying: boolean;
}

export default function CreditCardForm(props: CreditCardFormProps) {
  const {
    formValues,
    onSubmit,
    submitting,
    providerOptions,
    isCredentialsVerified,
    onVerify,
    verifying
  } = props;
  const [values, setFormValues] = useState(formValues);
  const onFieldChange = (fieldName: string, value: any) => {
    setFormValues((prevState: CreditCard) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const getPopoverContent = (content: string) => (
    <Popover id="popover-basic">
      <Popover.Content>{content}</Popover.Content>
    </Popover>
  );

  const getVerificationStatus = () => {
      if(isCredentialsVerified){
        return (
          <span className='verifiedText'>Gateway Credentials Verified</span>
        )
      }else{
        return (
          <span className='unVerifiedText'>Gateway Credentials have to be Verified</span>
        )
      }
  }

  const getIcon = () => {
    if(!isCredentialsVerified){
      return <VerifiedIcon className="icon"/>
    }else if(isCredentialsVerified){
      return <DisabledVerifiedIcon className="icon"/>
    }else{
      return ;
    }
  }

  const PopoverElement = ({
    content,
    placement,
  }: {
    content: string;
    placement: Placement;
  }) => (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement}
      overlay={getPopoverContent(content)}
    >
      <Help />
    </OverlayTrigger>
  );

  return (
    <div className="credit-card-form">
      <div className="column">
        <div className="control">
          <EDS_Select
            name="providerId"
            label="Select Provider:"
            options={providerOptions}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('providerId', e.target.value);
            }}
            value={values.providerId}
          ></EDS_Select>
          <span className="help">
            <PopoverElement placement="top" content="Provider help text" />
          </span>
        </div>

        <div className="control">
          <EDS_TextBox
            modifiers="configName"
            name="configName"
            label="Axia Config Name:"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('configName', e.target.value);
            }}
            value={values.configName}
          ></EDS_TextBox>
          <span className="help">
            <PopoverElement
              placement="top"
              content="Axia config name help text"
            />
          </span>
        </div>

        <div className="control">
          <EDS_TextBox
            modifiers="tbApiId"
            name="tbApiId"
            label="Control Center API ID:"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('tbApiId', e.target.value);
            }}
            value={values.tbApiId}
          ></EDS_TextBox>
          <span className="help">
            <PopoverElement placement="top" content="Help text" />
          </span>
        </div>
        <div className="control">
          <EDS_TextBox
            modifiers="tbApiToken"
            name="tbApiToken"
            label="Control Center API Token:"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('tbApiToken', e.target.value);
            }}
            value={values.tbApiToken}
          ></EDS_TextBox>
          <span className="help">
            <PopoverElement placement="top" content="Help text" />
          </span>
        </div>

        <div className="control">
          <EDS_TextBox
            modifiers="merchantId"
            name="merchantId"
            label="Source Key:"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('merchantId', e.target.value);
            }}
            value={values.merchantId}
          ></EDS_TextBox>
          <span className="help">
            <PopoverElement placement="top" content="Help text" />
          </span>
        </div>
        <div className="control">
          <EDS_TextBox
            modifiers="merchantPass"
            name="merchantPass"
            label="Source Pin:"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('merchantPass', e.target.value);
            }}
            value={values.merchantPass}
          ></EDS_TextBox>
          <span className="help">
            <PopoverElement placement="top" content="Help text" />
          </span>
        </div>
        <div className="verificationContainer">
          {getVerificationStatus()}
          <EDS_Button
            modifiers={'eds-button eds-button.basic verifyButton'}
            name={'verify'}
            ariaLabel={'Verify Gateway Credentials'}
            buttonText={verifying ? 'Processing ...' : 'Verify Gateway Credentials'}
            onClick={onVerify}
            disabled={
              isCredentialsVerified}
            iconName={getIcon()}
          />
        </div>
      </div>

      <div className="column">
        <div className="control">
          <EDS_TextBox
            modifiers="tbMid"
            name="tbMid"
            label="Control Center Merchant ID (MID):"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('tbMid', e.target.value);
            }}
            value={values.tbMid}
          ></EDS_TextBox>
          <span className="help">
            <PopoverElement placement="top" content="Help text" />
          </span>
        </div>
        <div className="control">
          <EDS_TextBox
            modifiers="tbTid"
            name="tbTid"
            label="Control Center Trading Partner ID (TID):"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('tbTid', e.target.value);
            }}
            value={values.tbTid}
          ></EDS_TextBox>
          <span className="help">
            <PopoverElement placement="top" content="Help text" />
          </span>
        </div>
        <div className="control">
          <EDS_TextBox
            modifiers="tbReceiptId"
            name="tbReceiptId"
            label="Control Center Receipt ID:"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('tbReceiptId', e.target.value);
            }}
            value={values.tbReceiptId}
          ></EDS_TextBox>
          <span className="help">
            <PopoverElement placement="top" content="Help text" />
          </span>
        </div>
        <div className="control">
          <EDS_TextBox
            modifiers="paymentFormSourceId"
            name="paymentFormSourceId"
            label="Payment Form Source ID (Required for CNP):"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('paymentFormSourceId', e.target.value);
            }}
            value={values.paymentFormSourceId}
          ></EDS_TextBox>
          <span className="help">
            <PopoverElement placement="top" content="Help text" />
          </span>
        </div>
        <div className="control">
          <EDS_TextArea
            modifiers="notes"
            name="notes"
            label="Notes"
            heightModifier={160}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onFieldChange('notes', e.target.value);
            }}
            value={values.notes}
          ></EDS_TextArea>
        </div>
        <div className="control">
          <EDS_Switch
            name="IsProduction"
            label="Use Production Gateway"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onFieldChange('isProduction', e.target.checked)
            }}
            checked={values.isProduction}
          />
        </div>
      </div>

      <div className="submit">
        <button
          disabled={submitting}
          className="action"
          onClick={() => onSubmit(values)}
        >
          <FaSave />
          <span className="text">Save</span>
        </button>
      </div>
    </div>
  );
}
