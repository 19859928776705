import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTypedSelector } from '../app/rootReducer';
import 'assets/styles/components/_paymentPlan.scss';
import { useDispatch } from 'react-redux';
import { useGetPatients } from '../features/patients/hooks/useGetPatients';

export default function (props: {}) {
  const dispatch = useDispatch();
  const { isProcessing, getPatients } = useGetPatients();
  
  useEffect(() => {
    getPatients();
  }, [dispatch]);
  const patients = useTypedSelector(s => s.patients.records);
  return isProcessing ? (<div>Processing ...</div>):
    (<div className = { 'body-wrapper' }>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Regular</th>
        </tr>
      </thead>
      <tbody>
        {patients?.map((patient, index) => (<tr key={index}>
          <td>
              {patient.id}
          </td>
          <td>
            <Link to={`/payment/${patient.id}`} >
              Make Payment
            </Link>
          </td>
        </tr>))}
      </tbody>
    </table>
  </div>);
}