import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SidepaneState {
  currentLink?: string;
}

export const initialState: SidepaneState = {
  currentLink: 'home'
};

const reducerSlice = createSlice({
  name: 'Sidepane',
  initialState,
  reducers: {
    setSidepaneLink(state, action: PayloadAction<string>) {
      state.currentLink = action.payload;
    },
  },
})

export const { setSidepaneLink } = reducerSlice.actions;
export default reducerSlice.reducer;