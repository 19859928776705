import {
  ClientConfiguration,
  ClientFacilityConfiguration,
  GeneralConfiguration,
} from 'models/Client';
import { sleep } from 'utils';
import { callHttpApi } from './callHttpApi';

export interface GetClientConfigurationResponse {
  result?: ClientConfiguration;
  err?: string;
}

export interface GetClientFacilityConfigurationResponse {
  result?: ClientFacilityConfiguration;
  err?: string;
}

// TODO: use this once actual endpoint is implemented
// export async function GetClientConfiguration(
//   clientId: string
// ): Promise<GetClientConfigurationResponse> {
//   return await callHttpApi<ClientConfiguration>(
//     'get',
//     `/api/v1/Client/${clientId}/Configuration`,
//     undefined
//   );
// }

export async function getClientConfigurationService(params: any) {
  return callHttpApi<ClientFacilityConfiguration>(
    'get',
    `/api/v1/Organizations/Configuration`,
    undefined,
    params
  );
}

export async function GetClientConfigurations(orgPaths: string[]) {
  return callHttpApi<ClientFacilityConfiguration[], string[]>(
    'post',
    `/api/v1/Organizations/Configuration/ByOrganizationPaths`,
    orgPaths
  );
}

export async function GetClientConfiguration(
  clientId: string
): Promise<GetClientConfigurationResponse> {
  await sleep(200);
  return {
    result: getMockedClientConfiguration(),
    err: undefined,
  };
}

function getMockedClientConfiguration(): ClientConfiguration {
  return {
    hierarchy: {
      tradingPartner: {
        id: '120001',
        name: 'SSM Healthcare',
      },
      clients: [
        {
          id: '120011',
          name: 'SSM Health - St. Joseph West',
        },
        {
          id: '120021',
          name: 'SSM Cardinal Glennon',
        },
        {
          id: '120031',
          name: 'SSM Rehabilitation Network',
        },
        {
          id: '120032',
          name: 'SSM Cedar Spring',
        },
        {
          id: '120032',
          name: 'SSM Cedar Spring - Weldon',
        },
      ],
    },
    generalSettings: {
      logo: '',
      id: '120032',
      description: 'SSM Healthcare Group',
      tradingPartnerId: '120001',
      nationalProviderId: '1234567890',
      isEnabled: true,
      isSuppressedFromFacilityList: true,
      isSuppressedFromPatientFacingDisplay: true,
      isDefaultAmountSetToZero: true,
      hasAccessPartnerData: false,
      accessPartnerGrouping: [
        {
          id: '123',
          value: 'Grouping 1',
        },
        {
          id: '456',
          value: 'Grouping 2',
        },
        {
          id: '789',
          value: 'Grouping 3',
        },
      ],
    },
  };
}

export async function SaveClientConfiguration(
  clientConfig: GeneralConfiguration
) {
  return callHttpApi(
    'put',
    `/api/v1/Organizations/Configuration`,
    clientConfig
  );
}
