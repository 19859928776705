import { useEffect, useState } from "react";
import '../../../assets/styles/components/_regExEditorModal.scss';
import { Popup } from "components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";
import { EDS_TextBox, EDS_TextArea, EDS_Select} from '@EH/eh.eds.react';
import { useGetRegExTemplates } from "./serviceHandlers/useGetRegExTemplates";

export default (props: {
  close: () => void;
  onNext: (formula?:string) => void;
  formula?:string
}) => {
  const { regExTemplates } = useGetRegExTemplates();
  const { close, onNext } = props;
  const [regExFormula, setRegExFormula] = useState(props.formula);
  const [regExData, setRegExData] = useState("");
  const [regExResult, setRegExResult] = useState(false);

  useEffect(() => {
    checkTestData();
  }, [regExFormula, regExData])

  function displayRegExFormulaResult(){
    if (regExFormula == undefined || regExFormula=="")
      return "";

    if (IsValidRegEx(regExFormula))
      return;
      
    return <div className="formula-invalid">
      <span>Invalid RegEx formula</span>
    </div>;
  }

  function IsValidRegEx(formula?:string):boolean{
      if (formula == undefined || formula=="")
        return false;

      try {
       let regex = new RegExp(formula);
        return true;
      } catch(e) {
          return false;
      }
  }
  
  function getDataStyle() {
    if (regExData == ""){
      return "row-item row-item-size fullRowLenght"
    }

    if (regExResult == true){
      return "row-item row-item-size fullRowLenght valid-data"
    }

    if (regExResult == false){
      return "row-item row-item-size fullRowLenght invalid-data"
    }
  }

  const complete = () => {
    onNext(regExFormula);
  };

  const footerButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel',
      disabled: false
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Apply',
      disabled: false
    },
  ];

  function checkTestData(){
    if(IsValidRegEx(regExFormula)){
      let regex = new RegExp(regExFormula ?? "");
      setRegExResult(regex.test(regExData));  
    }
  }

  function evaluateRegEx(testData: string) {
    setRegExData(testData);
  }
  
  const dropDownChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setRegExFormula(event.target.value);
  };
  return (
  <Popup 
    header="PaymentSafe®"
    onClose={close}
    title="RegEx BUILDER"
    footer={footerButtons}
    onSubmit={complete}
    customClassNames={{footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
    >
      <div className="popupContent">
        <div className="rowItemPopup">
        <EDS_Select
            name="template"
            label="Template"
            modifiers={'row-item row-item-size fullRowLenght'}
            options={regExTemplates}
            onChange={dropDownChangeHandler}
          />     
        </div>
        <div className="rowItemPopup">
          <EDS_TextArea
              label="Formula:"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setRegExFormula(e.target.value)
              }}
              value={regExFormula}
              heightModifier={160}
              modifiers={'row-item row-item-size fullRowLenght textAreaMarginBottom'}
            ></EDS_TextArea>
            {displayRegExFormulaResult()}
        </div>
        <div className={"rowItemPopup testdata-row"}>
            <EDS_TextBox
              label="Test data:"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                evaluateRegEx(e.target.value)
              }}
              modifiers={getDataStyle()}
            ></EDS_TextBox>
        </div>              
      </div>
  </Popup>
  );
};
