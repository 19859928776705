import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import { UserDetailModel } from "../../../models/UserInfoAndRolesModel";
import OrganizationUserSearch, { useGetUsersOptions } from "../../../pages/Search/simpleSearch/OrganizationUserSearch";
import useOrganizationsUsersState, { UserFormState } from "./hooks/useOrganizationsUsersState";
import OrganizationsSelector from "./OrganizationsSelector";
import { setOrganizationsAndUsers, setTradingPartner } from "./UsersAndPermissionsReducer";
import usePermissionsState from "./UsersTab/hooks/usePermissionsState";
import UserList from "./UsersTab/UserList";
import UserPermissionsMenu from "./UsersTab/UserPermissionsMenu";
import OrganizationLevelTypes from "models/enums/OrganizationLevelTypes";

export default function UserForm(props: UserFormState) {
  const { organizations, sspUserPermission, isAdmin, resetSelection } = props
  const { users } = useGetUsersOptions();
  const organizationsUsersState = useOrganizationsUsersState();
  const { retrievePermissions, selectedItems, handleChange, clearSelection } = usePermissionsState();
  const { clearState } = organizationsUsersState;
  const dispatch = useDispatch();
  const state = useTypedSelector(s => s.usersAndPermissions);
  const tradingPartner = useTypedSelector(s => s.usersAndPermissions.tradingPartner);
  const facilityList = organizations?.filter(o =>
    o.path.includes(tradingPartner?.value ?? '') && o.organizationLevelType === OrganizationLevelTypes.Facility
  );
  useEffect(() => {
    retrievePermissions();
  }, []);

  useEffect(() => {
    dispatch(setOrganizationsAndUsers({
      ...state,
      users: []
    }));
  }, [state.tradingPartner?.value, state.facilities, state.departments]);

  useMemo(() => {
    if(resetSelection) {
      clearState()
    }
  }, [resetSelection]);

  const [selectedUserAndOrganization, setSelectedUserAndOrganization] = useState<{ user: UserDetailModel, organizationPath: string }>();

  const showCustomOrganizationSelector = () => {
    if(isAdmin || !sspUserPermission) {
      return (
        <OrganizationsSelector
        setSelectedOrganization={(selected) => dispatch(setTradingPartner(selected))}
        selectedOrganization={tradingPartner}
      />
      )
    }
  }

  return <div>
    <div className="organization-search">
      {showCustomOrganizationSelector()}
      <div>
        <OrganizationUserSearch
          {...{ facilities: facilityList, users }}
          state={state}
          setState={organizationUserState => {
            dispatch(setOrganizationsAndUsers({ ...state, ...organizationUserState }));
          }}
          initialize={false}
          showError={false}
          refreshUserTable={true}
        />
       </div>
    </div>
    <div className="user-form">
      <div className="user-list">
      <h2 className="links-title"> </h2>
        <UserList users={state.users.map(o => o.user)}
          onChangeSelected={(user: UserDetailModel, organizationPath: string) => {           
            setSelectedUserAndOrganization({ user: (users.find(u => u.id === user.id) ?? user), organizationPath })
          }}
        />
      </div>
      <div className="permissions-menu">
        <UserPermissionsMenu
          user={selectedUserAndOrganization?.user}
          organizationPath={selectedUserAndOrganization?.organizationPath}
          selectedItems={selectedItems}
          handleChange={handleChange}
          clearSelection={clearSelection}
          tradingPartner={organizations.find(t => t.path === tradingPartner?.value)}
          organization={organizations.find(f => f.path === selectedUserAndOrganization?.organizationPath)}
          selectedUsers= {state.users}  
        />
      </div>
    </div>
  </div>
}