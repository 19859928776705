import { ServerPaymentModel } from 'models/PaymentModel';
import { callHttpApi } from 'services/callHttpApi';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import { CreateTransactionResult } from './models/CreateTransactionResult';
import CardEntryRequest from './models/CardEntryRequest';

export async function CreateCreditDebitEntryTransaction(
  payment: ServerPaymentModel
): Promise<CreateTransactionResult> {
  return callHttpApi<TransactionDetailsModel, CardEntryRequest>(
    'post',
    `api/v1/Transactions/cardEntry`,
    mapPaymentToCardEntryRequest(payment)
  );
}

function mapPaymentToCardEntryRequest(
  payment: ServerPaymentModel
): CardEntryRequest {
  return {
    organization: {
      id: payment?.organization?.id || '',
      name: payment?.organization?.name || '',
      path: payment?.organization?.path || '',
    },
    department: payment?.department,
    facility: payment?.facility,
    cardData: {
      cardNumber: payment?.typeCreditDebitEntry?.cardNumber?.replace(/ /g, ''),
      expirationDate: payment?.typeCreditDebitEntry?.expiration,
      cvv: payment?.typeCreditDebitEntry?.cvc,
      nameOnCard: payment?.typeCreditDebitEntry?.cardHolderName,
    },
    lineItems: payment.isGL ? payment.glDetails : payment.details,
    patientAccountNumber: payment?.patientAccountNumber,
    amount: payment?.paymentTotal,
    billingInformation: payment?.billingInformation,
    mailingInformation: payment?.mailingInformation,
    notes: payment?.notes,
    discount: payment?.discount,
    episode: payment?.episode,
    guarantorId: payment?.guarantorId,
    isGL: payment?.isGL,
    mrn: payment?.mrn,
    patientId: payment?.patientId,
    patientFirstName: payment?.patientFirstName,
    patientLastName: payment?.patientLastName,
    patientDateOfBirth: payment?.patientDateOfBirth,
    paymentSource: payment?.paymentSource,
    isPaymentMethodReusable: payment?.isReusable,
    notificationEmail: payment?.notificationEmail,
    paymentPlanId: payment.paymentPlanId,
    totalPlanBalance: payment.totalPlanBalance,
    TransactionGroupID: payment.transactionGroupId,
  }
}
