import { Checkbox, Input } from "@EHDS/core";
import React, { useState } from "react";
import { RemitConfigurationSettings, Setting, FunctionEnum, TransactionTypesEnum, FunctionDataOperationEnum } from "../../../../models/admin/RemitConfiguration";
import { EDS_Select } from '@EH/eh.eds.react';
import useSettingValidator from "./useSettingValidator";
import MultiSelect from "../../../../components/select/MultiSelect";
import { enumToMultiSelectOptions, enumToSelectOptions, cardBrandEnum, TenderTypeEnum, TenderTypeLabels, enumToTenderTypeMultSelectOptions, sortAscByLabel } from "../../../../utils/Utils";
import { useTypedSelector } from "app/rootReducer";
import { getOrganizationOptions } from "pages/Search/simpleSearch/OrganizationUserSearch";
import { getUserDepartmentOrFacilityesOptions } from "utils/UtilsOrganizationDropdowns";
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { AlertIds, AlertTypes, setAlert } from "../../../alert/AlertReducer";
import { useDispatch } from "react-redux";
import { RemitFileFieldDescription } from "../../../../models/RemitPreDefinedListsModel";

export function settingIsInUse(name: string, footerSettings?: Setting[]) {
  return !!footerSettings?.find(o => o.aggregateSetting === name);
}

export default function AggregateSettingForm(props: { setting: Setting, onChange: (setting: Setting) => void, settingKey: keyof RemitConfigurationSettings, index: number, footerSettings?: Setting[], remitFileFieldDescriptions?: RemitFileFieldDescription[] }) {
  const { setting, onChange, settingKey, index, footerSettings, remitFileFieldDescriptions} = props;
  const { attributes, validate, getErrorMessage, errorClass } = useSettingValidator(settingKey, index, setting);
  const dispatch = useDispatch();
  const { useGetFacilities } = useOrganizations()
  const facilities = useGetFacilities();
  const userOrganisations = useTypedSelector(s => s.loginInfo.loggedUser?.organizationLevels)
  const userFacilityOptions = getUserDepartmentOrFacilityesOptions(facilities, userOrganisations)
  const facilityOptions = getOrganizationOptions(userFacilityOptions);
  const [errorExtraOperation, setErrorExtraOperation] = useState<boolean>(false);
  const [errorExtraOperationValue, setErrorExtraOperationValue] = useState<boolean>(false);
  const cardBrandOptions = enumToMultiSelectOptions(cardBrandEnum);
  const tenderTypeOptions = enumToTenderTypeMultSelectOptions(TenderTypeEnum, TenderTypeLabels);
  const transactionTypeOptions = enumToMultiSelectOptions(TransactionTypesEnum);
  const functionDataOperationOptions = enumToSelectOptions(FunctionDataOperationEnum);

  const selectedFunctionValue = setting.function?.toString() as keyof typeof FunctionEnum;
  const functionValue = +FunctionEnum[selectedFunctionValue] || +selectedFunctionValue;

  function fileFieldDescriptionsToSelectOptions(allRemitFileFieldDescriptions?: RemitFileFieldDescription[]) {
    return allRemitFileFieldDescriptions?.map(remitFileFieldDescription => {
      return {
        optionName: remitFileFieldDescription.displayName,
        value: remitFileFieldDescription.fieldName,
      }
    }).sort((a, b) => a.optionName.localeCompare(b.optionName));
  }

  function  onChangeSetting(name: keyof Setting, value: any) {
    validate(attributes.find(a => a.name == name), value)
    onChange({ ...setting, [name]: value });

    if(name === "functionExtraOperation"){
      if(value !== '0' && !setting.functionExtraOperationValue){ 
        setErrorExtraOperation(true)
      } else {
        if(setting.functionExtraOperationValue == 0){
          setErrorExtraOperationValue(true)
        }
        setErrorExtraOperation(false) 
      }
    }else if(name === 'functionExtraOperationValue' && value == '0'){
      setErrorExtraOperationValue(true)
    }else{
      setErrorExtraOperation(false)
      setErrorExtraOperationValue(false)
    }
  }

  return <div>
    <div className="row">
      <div className="col-lg-4">
        <Input
          label="*Name:"
          name={"name"}
          className={'rowItem rowItemSize eds4'}
          value={setting.name}
          onChange={(e) => onChangeSetting("name", e.target.value)}
          onFocus={(e) => {
            if (settingIsInUse(setting.name, footerSettings)) {
              e.target.blur();
              dispatch(
                setAlert({
                  id: AlertIds.EditRemitConfig,
                  type: AlertTypes.Error,
                  message: "Cannot change setting name - it is in use"
                })
              );
            }
          }}
        />
      </div>
      <div className="col-lg-4">
        <EDS_Select
          label="*Function:"
          name={"function"}
          modifiers={'rowItem rowItemSize eds4'}
          value={functionValue}
          options={enumToSelectOptions(FunctionEnum)}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { onChangeSetting("function", e.target.value) }}
        />
      </div>
      <div className="col-lg-4"> 
        <EDS_Select
          label="*DB Field:"
          name={"dbField"}
          modifiers={'rowItem rowItemSize eds4'}
          value={setting.dbField}
          options={fileFieldDescriptionsToSelectOptions(remitFileFieldDescriptions)}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { onChangeSetting("dbField", e.target.value) }}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <span className={`${errorClass && errorClass("name")}`}>
          {getErrorMessage && getErrorMessage("name")}
        </span>
      </div>
      <div className="col-lg-4">
        <span className={`${errorClass && errorClass("function")}`}>
          {getErrorMessage && getErrorMessage("function")}
        </span>
      </div>
      <div className="col-lg-4">
        <span className={`${errorClass && errorClass("dbField")}`}>
          {getErrorMessage && getErrorMessage("dbField")}
        </span>
      </div>
    </div>

    <div className="row">
    <div className="col-lg-4">
        <Input
          label="Account number starts with:"
          name={"accountNumberStarts"}
          className={'rowItem rowItemSize eds4'}
          value={setting.accountNumberStarts}
          onChange={(e) => onChangeSetting("accountNumberStarts", e.target.value)}
        />
      </div>
      <div className="col-lg-4">
        <MultiSelect
          label="Facility Filter:"
          name={"facilities"}
          values={facilityOptions.map((o) => {
            return {
              label: o.label,
              value: setting.facilities?.find(f => o.value == f.value)?.value ?? ""
            }
          }).filter(x => x.value) ?? []}
          options={facilityOptions}
          onChange={(selected, propertyName) => { onChangeSetting(propertyName, selected)}}
          multiple={true}
          remit={true}
        />
      </div>
      <div className="col-lg-4">
        <MultiSelect
          label={"Card Filter"}
          name={"cardBrands"}
          values={setting.cardBrands?.map((o) => {
            return {
              label: o.label,
              value: cardBrandOptions.find(c => o.label == c.label)?.value ?? ""
            }
          }).filter(x => x.value).sort((a,b) => a.label.localeCompare(b.label)) ?? []}
          options={sortAscByLabel(cardBrandOptions)}
          onChange={(selected, propertyName) => { onChangeSetting(propertyName, selected)}}
          multiple={true}
          remit={true}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-lg-4">
        <MultiSelect
          label="Tender Filter:"
          name={"tenderTypes"}
          values={setting.tenderTypes?.map((o) => {
            return {
              label: tenderTypeOptions.find(c => o.label == c.originalLabelName)?.label ?? "",
              value: tenderTypeOptions.find(c => o.label == c.originalLabelName)?.value ?? ""
            }
          }).filter(x => x.value) ?? []}
          options={sortAscByLabel(tenderTypeOptions)}
          onChange={(selected, propertyName) => 
            { 
              const selectedOptions = selected.map((o) => {
                return {
                  label: tenderTypeOptions.find(c => o.label == c.label)?.originalLabelName,
                  value: o.value
                }
              });
              onChangeSetting(propertyName, selectedOptions)
            }
          }
          multiple={true}
          remit={true}
        />
      </div>
      <div className="col-lg-4">
        <MultiSelect
          label="Transaction Filter:"
          name={"transactionTypes"}
          values={setting.transactionTypes?.map((o) => {
            return {
              label: o.label,
              value: transactionTypeOptions.find(c => o.label == c.label)?.value ?? ""
            }
          }).filter(x => x.value) ?? []}
          options={sortAscByLabel(transactionTypeOptions)}
          onChange={(selected, propertyName) => { onChangeSetting(propertyName, selected)}}
          multiple={true}
          remit={true}
        />
      </div>
      <div className="col-lg-4">
        <Checkbox
          label="Has Discount:"
          name={"discount"}
          checked={setting.discount}
          onChange={(e) => { onChangeSetting("discount", !setting.discount) }}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-lg-4">
        <EDS_Select
          label="Function Data Operation:"
          name={"functionDataOperationOptions"}
          modifiers={'rowItem rowItemSize eds4'}
          value={functionDataOperationOptions.find(o => o.optionName.toString() == setting.functionExtraOperation?.toString() ||o.value.toString() == setting.functionExtraOperation?.toString())?.value}
          options={functionDataOperationOptions}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { onChangeSetting("functionExtraOperation", e.target.value) }}
        />
      </div>
      <div className="col-lg-4">
        <Input
          label="Function Extra Operation Value:"
          name={"functionExtraOperationValue"}
          className={'rowItem rowItemSize eds4'}
          value={setting.functionExtraOperationValue?.toString()}
          onChange={(e) => onChangeSetting("functionExtraOperationValue", e.target.value)}
          type={"number"}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-4">
        <span style={{color: 'red'}}>
          {errorExtraOperation && 'Required'}
          {errorExtraOperationValue && 'Value should be different than 0'}
        </span>
      </div>
    </div>
  </div>
}