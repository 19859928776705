import { useState } from "react";
import { EmailModalInfo } from "./Header";
import { EnumPlanStatus } from "../../../models/enums/EnumPaymentPlan";

export function useEmailInfo() {
  const state = useState<EmailModalInfo>();
  return state;
}

export function usePlanStatus() {
  return useState<EnumPlanStatus | undefined>()
}

export function useStatusLoading() {
  return useState(false);
}