import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/rootReducer';
import {
  getUserRolesForOrgIdService,
  SearchUserResult,
} from 'services/SearchUserService';
import UserDetailModels from 'models/UserInfoAndRolesModel';
import { updateUserRoleService } from 'services/UsersService';
import { PermissionItem } from 'models/Permission';
import Role from 'models/Role';
import { callService } from '../../services/ServicesReducer';

export interface State {
  userQuery: string;
  result?: UserDetailModels;
  err?: string;
}

export const initialState: State = {
  userQuery: '',
  result: undefined,
  err: '',
};

const reducerSlice = createSlice({
  name: 'UserInfoAndRoles',
  initialState,
  reducers: {
    onRequest(state, action: PayloadAction<string>) {
      state.userQuery = action.payload;
    },
    onReceiveUserData(state, action: PayloadAction<SearchUserResult>) {
      state.result = action.payload.result;
      state.err = action.payload.err;
    },
    clearResult(state) {
      state.result = undefined;
      state.err = '';
    },
  },
});

export const {
  onReceiveUserData,
  onRequest,
  clearResult,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getUserRolesForOrgId(
  userQuery: string,
  onDone?: () => void
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(onRequest(userQuery));
    getUserRolesForOrgIdService(userQuery).then(data => {
      dispatch(onReceiveUserData(data));
      if (onDone) onDone();
    });
  };
}

export function updateUserPermissions(
  userId: string,
  role: Role,
  permissions: PermissionItem[],
  onDone?: () => void
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService("searchUser", async () => {
      await updateUserRoleService(userId, role, permissions);
      dispatch(getUserRolesForOrgId(initialState.userQuery, undefined));
      if (onDone) onDone();
    }))
  };
}
