import { useMemo } from 'react';
import SelectClient from 'features/admin/clientConfiguration/SelectClient';
import { useTypedSelector } from 'app/rootReducer';


export default function ConfigurationTemplate(props: { title: string, headerButtons?: JSX.Element, children: JSX.Element | JSX.Element[], titleModifer?: string, subTitle?: string }) {
  const { title, children, headerButtons, titleModifer, subTitle } = props;
  const organizations = useTypedSelector(s => s.organizations?.value);
  const chosenTitleModifier = titleModifer ? ` ${titleModifer}` : '';

  const selectClient = useMemo(()=>(
    <SelectClient />
  ), [organizations]);

  return <div className="client-configuration">
    <div className="client-controls">
      {selectClient}
    </div>
    <div className="content">
      <div className="config-header">
        <div className={`title${chosenTitleModifier}`}>
          <div className='title-container'>
            <h2>{title}</h2>
            {subTitle ? <p className='organization-detail'>{subTitle}</p> : <></> }
          </div>
          {headerButtons}
        </div>
      </div>
      {children}
    </div>
  </div>
}