import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnumTermType } from 'models/enums/EnumPaymentPlan';
import { EnumPaymentSource } from 'models/enums/EnumPaymentSource';
import moment from 'moment';
import { getTenderTypePaymentPlanOptions, getTomorrow } from 'utils/Utils';
import { ValidatorType, PlanOperations } from '../../models/metaData/MetaDataEnums';
import PaymentPlanModel, { PaymentPlanLineItiemsSelected, TenderInfo } from '../../models/PaymentPlan';
import { PaymentPlanConfiguration, Tier } from '../../models/PaymentPlanConfiguration';
import { MetaData } from 'models/metaData/MetaData';
import { LineItem } from 'models/LineItem';

export const mockTermsOptions: TermTypeOption[] = [
  { value: EnumTermType.Weekly, optionName: 'Weekly', duration: 'weeks', display: 'week', },
  { value: EnumTermType.BiWeekly, optionName: 'Bi-Weekly', duration: 'weeks', display: 'bi-week', },
  { value: EnumTermType.Monthly, optionName: 'Monthly', duration: 'months', display: 'month', },
  { value: EnumTermType.Quarterly, optionName: 'Quarterly', duration: 'months', display: 'quarter', },
  { value: EnumTermType.Annually, optionName: 'Annually', duration: 'years', display: 'year', },
];

export interface PlanErrors {
  [label: string]: { [validatorType: string]: string };
}

export interface TermTypeOption {
  value: string;
  optionName: string;
  duration?: moment.unitOfTime.DurationConstructor;
  display?: string;
}

export interface State {
  limit?: number;
  offset?: number;
  records?: PaymentPlanModel[];
  selected?: (string | null)[];
  paymentPlansLineItemsSelected?: PaymentPlanLineItiemsSelected[];
  backups?: { [key: string]: PaymentPlanModel };
  value: PaymentPlanModel;
  planPanelConfigurations: {
    [key: string]: { 
      configuration: MetaData | undefined
    };
  },
  active?: string;
  configurations: {
    [key: string]: {
      date: number;
      reload: boolean,
      configuration: PaymentPlanConfiguration
    };
  };
  discrepancies?: {
    [key: string]: string;
  };
  errors: PlanErrors;
  operation: PlanOperations;
  openedPlanId?: string
}

export const initialPlan: PaymentPlanModel = {
  id: null,
  isActive: false,
  paymentsRemaining: 1,
  termType: EnumTermType.Monthly,
  paymentSource: EnumPaymentSource.PaymentSafe,
  startDate: getTomorrow(),
  lineItems: [],
  pauseHistories: [],
  tender: {
    device:{},
    type: getTenderTypePaymentPlanOptions()[0]?.value,
    accountType: 'checking',
  },
  isGl: false,
  paymentAmount: "0",
  walletId: 'mock data',
  organization: {
    id: '',
    path: '',
    name: ''
  },
  tenderType: getTenderTypePaymentPlanOptions()[0]?.value,
  isOpen:false
};

export const initialState: State = {
  value: initialPlan,
  planPanelConfigurations: {},
  backups: {},
  records: [],
  selected: [],
  paymentPlansLineItemsSelected: [],
  configurations: {},
  discrepancies: {},
  errors: {},
  operation: PlanOperations.NotSet,
};

const reducerSlice = createSlice({
  name: 'PaymentPlan',
  initialState,
  reducers: {
    toggleRecordSelected(state, action: PayloadAction<{ id?: string | null }>) {
      if (!action.payload.id) return;
      if (!state.selected) {
        state.selected = [];
      }
      const idx = state.selected?.findIndex(id => {
        return id == action.payload.id;
      });
      if (idx > -1) {
        state.selected.splice(idx, 1);
      } else {
        state.selected.push(action.payload.id);
      }
    },
    changeSelected(
      state,
      action: PayloadAction<{ id: string | null; isSelected: boolean }>
    ) {
      if (!state.selected) {
        state.selected = [];
      }
      const idx = state.selected?.findIndex(id => {
        return id == action.payload.id;
      });
      if (idx == -1 && action.payload.isSelected) {
        state.selected.push(action.payload.id);
      } else if (idx > -1 && !action.payload.isSelected) {
        state.selected.splice(idx, 1);
      }
    },
    resetSelected(state) {
      state.selected = [];
    },
    reset(
      state,
      action: PayloadAction<{ index?: number; id?: string | null }>
    ) {
      if (
        !action.payload.id ||
        action.payload.index == undefined ||
        !state.records ||
        !state.records[action.payload.index] ||
        !state.backups
      )
        return;
      if (!state.backups[action.payload.id]) {
        delete state.records[action.payload.index];
      } else {
        state.records[action.payload.index] = {
          ...state.backups[action.payload.id],
        };
      }
    },
    setSelectedPlans(state, action: PayloadAction<(string | null)[]>) {
      state.selected = action.payload
    },
    addBackup(state, action: PayloadAction<PaymentPlanModel>) {
      if (!state.backups) {
        state.backups = {};
      }
      if (!action.payload.id) return;
      state.backups[action.payload.id] = { ...action.payload };
    },
    replaceRecords(state, action: PayloadAction<PaymentPlanModel[]>) {
      const orgPath =action.payload.length>0?action.payload[0].organization?.path:'';
      state.records =state.records?.filter(rec=>rec.organization?.path!==orgPath);
      state.records = state.records?.concat(action.payload);
    },
    addRecord(state, action: PayloadAction<PaymentPlanModel>) {
      if (!state.records) {
        state.records = [];
      }
      state.records.push(action.payload);
    },
    updateRecord(
      state,
      action: PayloadAction<{
        id?: string | null;
        paymentPlan: PaymentPlanModel;
      }>
    ) {
      if (!state.records) {
        state.records = [];
      }
      const idx = state.records.findIndex(
        (plan: PaymentPlanModel) => plan.id == action.payload.id
      );
      if (idx >= 0) {
        state.records[idx] = action.payload.paymentPlan;
      } else {
        state.records.push(action.payload.paymentPlan);
      }
    },
    deactiveAllPlans(state){
      if (!state.records) {
        state.records = [];
      }

      state.records.forEach(plan=> {
        plan.isActive = false;
        plan.isOpen = false;
        plan.paymentAmount = "0";
        plan.lineItems?.forEach(item=>{
          item.isActive = false;
        })
      });
    },
    activatePlanLineItem(state, action: PayloadAction<{
      paymentPlanId: string;
      lineItemId: string;
    }>
    ){
      if (!state.records) {
        state.records = [];
      }
      const idx = state.records.findIndex(
        (plan: PaymentPlanModel) => plan.id == action.payload.paymentPlanId
      );

      if (idx >= 0) {
        const lidx = state.records[idx].lineItems?.findIndex((lineitem:LineItem) => lineitem.id == action.payload.lineItemId);
        if (lidx! >= 0) {
          state.records[idx].lineItems![lidx!].isActive = true;
        }
      }
    },
    expandPlanLineItems(state, action: PayloadAction<{
      paymentPlanId: string;
    }>
    ){
      if (!state.records) {
        state.records = [];
      }
      const idx = state.records.findIndex(
        (plan: PaymentPlanModel) => plan.id == action.payload.paymentPlanId
      );

      if (idx >= 0) {
        state.records[idx].isOpen = !state.records[idx].isOpen;
      }
    },
    resetPaymentPlansLineItemsSelected(
      state
    ) {
      state.paymentPlansLineItemsSelected = [];
    },
    setConfiguration(
      state,
      action: PayloadAction<{
        organizationPath: string;
        configuration: PaymentPlanConfiguration;
      }>
    ) {
      state.configurations = {
        ...state.configurations,
        [action.payload.organizationPath]: {
          date: Date.now(),
          reload: false,
          configuration: action.payload.configuration,
        },
      };
    },
    addDiscrepancy(
      state,
      action: PayloadAction<{
        key: string;
        message: string;
      }>
    ) {
      state.discrepancies = {
        ...state.discrepancies,
        [action.payload.key]: action.payload.message,
      };
    },
    removeDiscrepancy(
      state,
      action: PayloadAction<string>
    ) {
      if (state.discrepancies)
      {
        delete state.discrepancies[action.payload];
      }
    },
    addError(state, action: PayloadAction<PlanErrors>) {
      state.errors = {
        ...state.errors,
        ...action.payload
      }
    },
    removeError(state, action: PayloadAction<{ key: keyof PaymentPlanModel | keyof TenderInfo, validatorType: ValidatorType }>) {
      if (state.errors[action.payload.key]) {
        delete state.errors[action.payload.key][action.payload.validatorType];
      }
    },
    resetErrors(state) {
      state.errors = {};
    },
    setOperation(state, action: PayloadAction<PlanOperations>) {
      state.operation = action.payload;
    },
    resetOperation(state) {
      state.operation = PlanOperations.NotSet;
    },
    setOpenedPlanId(state, action: PayloadAction<string | undefined>) {
      state.openedPlanId = action.payload;
    },
    setConfigurationReload(state, action: PayloadAction<{
      organizationPath: string,
      reload: boolean
    }>) {
      state.configurations = {
        ...state.configurations,
        [action.payload.organizationPath]: {
          ...state.configurations[action.payload.organizationPath],
          reload: action.payload.reload,
        },
      };
    },
    setPlanPanelConfiguration(
      state,
      action: PayloadAction<{
        organizationPath: string;
        configuration: MetaData | undefined;
      }>
    ) {
      if(!state.planPanelConfigurations[action.payload.configuration?.entity?.organization?.path!]){
        state.planPanelConfigurations = {
          ...state.planPanelConfigurations,
          [action.payload.configuration?.entity?.organization?.path!]: {
            configuration: action.payload.configuration,
          },
        };
      }
    },
    setPaymentPlanTierFields(
      state,
      action: PayloadAction<{
        organizationPath: string;
        tierFields: Tier[];
      }>
    ) {
      const { organizationPath, tierFields } = action.payload;
      state.configurations[organizationPath].configuration.tiers = {
        GLTiers: tierFields,
        BillTiers: tierFields
      }
    },
    setInitialState: () => initialState,
  },
});

export const {
  toggleRecordSelected,
  changeSelected,
  reset,
  addBackup,
  replaceRecords,
  updateRecord,
  addRecord,
  setConfiguration,
  addDiscrepancy,
  removeDiscrepancy,
  resetSelected,
  addError,
  removeError,
  resetErrors,
  setOperation,
  resetOperation,
  setOpenedPlanId,
  setConfigurationReload,
  setInitialState,
  setPlanPanelConfiguration,
  setSelectedPlans,
  setPaymentPlanTierFields,
  deactiveAllPlans, 
  activatePlanLineItem,
  expandPlanLineItems,
} = reducerSlice.actions;
export default reducerSlice.reducer;
