import { useMemo, useState } from "react";
import { MultiSelectOption } from "../../../../components/select/MultiSelect";
import { ApiSelectOptions, getGlPaymentLoadFromSelectOptionsService, getPaymentLoadFromSelectOptionsService } from "../../../../services/PaymentPanelService";
import { mapOptions } from "./useSaveAsOptions";
import { Dashboards } from "../ConfigurationSelector";
import { ServiceCallResponse } from "services/callHttpApi";

export function useLoadFromOptions(dashboard?: keyof(Dashboards)): { loadFromOptions: MultiSelectOption[] } {
  const [loadFromOptions, setLoadFromOptions] = useState<MultiSelectOption[]>([]);
  useMemo(async () => {
    let response: ServiceCallResponse<ApiSelectOptions> = {};
    switch(dashboard){
      case "GlPaymentDashboard":
      case "GlPaymentDashboardDraft":
      case "GlPaymentPlanDashboard":
      case "GlPaymentPlanDashboardDraft":{
        response = await getGlPaymentLoadFromSelectOptionsService();
        break;
      }
      case "PaymentDashboard":
      case "PaymentDashboardDraft":
      case "PaymentPlanDashboard":
      case "PaymentPlanDashboardDraft":
      case "EcnPaymentDashboard":
      case "EcnPaymentDashboardDraft": {
        response = await getPaymentLoadFromSelectOptionsService();
        break;
      }
    }

    setLoadFromOptions(mapOptions(response?.result ?? []));
  }, []);

  return { loadFromOptions }
}