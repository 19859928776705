export enum EnumCardBrand {
  NotSet = 'NotSet',
  AmericanExpress = 'AmericanExpress',
  DinerSClub = 'DinerSClub',
  Discover = 'Discover',
  Enroute = 'Enroute',
  Jcb = 'Jcb',
  MasterCard = 'MasterCard',
  Visa = 'Visa',
  Unknown = 'Unknown',
  UnionPay = 'UnionPay',
  Debit = 'Debit',
  Gsb = 'Gsb'
}
