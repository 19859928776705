import { useDispatch } from "react-redux";
import { GenericAttribute } from "models/metaData/MetaData";
import { DataType, ValidatorType } from "models/metaData/MetaDataEnums";
import { validateGeneric } from "features/paymentDashboard/Validators";
import { Dispatch } from "redux";

export interface ErrorsMessages {
  [label: string]: { [validatorType: string]: string };
}

export default function useTypedValidator<T>(props: {
  attributes: GenericAttribute<T>[],
  removeError: (props: { key: string, validatorType: string }) => any,
  addError: (errors: { [key: string]: { [key: string]: string } }) => any,
  entity?: T,
  errors?: { [key: string]: { [key: string]: string } },
  dispatchable?: boolean,
  getKeySuffix?: (entity?: T) => string,
}) {
  const { attributes, entity, removeError, addError, errors, dispatchable = true, getKeySuffix } = props;
  const dispatch = useDispatch();


  function validateAll(validOnly: boolean = false, currentEntity?: T) {
    let valid = true;
    const entityToCheck = currentEntity ?? entity;

    entityToCheck && attributes.forEach(attribute => {
      let result = validate(attribute, entityToCheck[attribute.name], validOnly, currentEntity);
      valid = valid && result;
    });
    return valid;
  }

  const { errorRemover, errorHandler } = getErrorHandlers(removeError, addError, dispatchable, dispatch);

  function validate(attr?: GenericAttribute<T>, value?: any, validOnly: boolean = false, currentEntity?: T): boolean {
    return validateGeneric({
      onPass: (key: keyof T, validatorType: ValidatorType) => {
        let suffexedKey = key.toString();
        if (getKeySuffix) suffexedKey = suffexedKey + getKeySuffix(currentEntity);
        errorRemover({ key: suffexedKey, validatorType })
      },
      onFail: (key: keyof T, validatorType: ValidatorType, message?: string) => {
        let suffexedKey = key.toString();
        if (getKeySuffix) suffexedKey = suffexedKey + getKeySuffix(currentEntity);
        errorHandler({
          [suffexedKey]: { [validatorType]: message || '' }
        })
      },
      attr,
      value,
      validOnly,
      entity: currentEntity,
    })
  }

  const getAttribute = (name: keyof T): GenericAttribute<T> => attributes.find(a => a.name === name) ?? { name, dataType: DataType.String };

  return {
    validate, validateAll, attributes, getAttribute,
    errorClass: (name: string, currentErrors?: ErrorsMessages) => errorClass(name, currentErrors, errors),
    getErrorMessage: (name: string, currentErrors?: ErrorsMessages) => getErrorMessage(name, currentErrors, errors)
  }
}

export function getErrorHandlers(
  removeError: (props: { key: string, validatorType: string }) => any,
  addError: (errors: { [key: string]: { [key: string]: string } }) => any,
  dispatchable: boolean,
  dispatch: Dispatch<any>) {
  let errorHandler = addError;
  let errorRemover = removeError;
  if (dispatchable) {
    errorHandler = (errorsToStore) => dispatch(addError(errorsToStore));
    errorRemover = (error) => dispatch(removeError(error));
  }

  return { errorHandler, errorRemover }
}

export function getErrorMessage(name: string, currentErrors?: ErrorsMessages, errors?: ErrorsMessages) {
  return Object.values((currentErrors ?? errors)?.[name] ?? {}).join(',');
}

export function errorClass(name: string, currentErrors?: ErrorsMessages, errors?: ErrorsMessages) {
  return Object.values((currentErrors ?? errors)?.[name] ?? {}).length ? 'invalid-field' : '';
}