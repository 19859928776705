import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Value {
  name: string;
  amount: number;
}

export interface State {
  value: Value;
}

export const initialState: State = {
  value: {
    name: '',
    amount: 0,
  },
};

const reducerSlice = createSlice({
  name: 'Dashboard',
  initialState,
  reducers: {
    setExampleState(state, action: PayloadAction<Value>) {
      state.value = action.payload;
    },
  },
});

export const { setExampleState } = reducerSlice.actions;
export default reducerSlice.reducer;
