
import { useDispatch } from 'react-redux';
import { useCallService } from 'services/useCallService';
import {clonePaymentPanel, PanelCloneRequest, PanelConfigurationType} from '../../../../services/PaymentPanelService';
import { AlertIds, AlertTypes, setAlert } from '../../../alert/AlertReducer';
import { Dashboards } from '../ConfigurationSelector';

export default function useClonePanelConfiguration() {
  const callService = useCallService();
  const dispatch = useDispatch();

  const getPanelType = (dashboard: keyof Dashboards): PanelConfigurationType => {
    switch(dashboard) {
      case "PaymentDashboard":
      case "PaymentDashboardDraft":{
        return PanelConfigurationType.PaymentPaymentPanel;
      }
      case "GlPaymentDashboard":
      case "GlPaymentDashboardDraft":{
        return PanelConfigurationType.GlPaymentPaymentPanel;
      }
      case "PaymentPlanDashboard":
      case "PaymentPlanDashboardDraft":{
        return PanelConfigurationType.PaymentPlansPaymentPanel;
      }
      case "PatientSearchPopupDashboard":
      case "PatientSearchPopupDashboardDraft":{
        return PanelConfigurationType.PatientSearchPanel;
      }
      case "EcnPaymentDashboard":
      case "EcnPaymentDashboardDraft":{
        return PanelConfigurationType.EcnPaymentPanel;
      }
      default:
        return PanelConfigurationType.PaymentPaymentPanel;
    }
  }

  const clonePanel = (dashboard: keyof Dashboards, data: PanelCloneRequest) => {
    return callService('clonePanelConfiguration', async () => {
      const response = await clonePaymentPanel(getPanelType(dashboard), data);
      if (response.hasOwnProperty('result')) {
        dispatch(
          setAlert({
            id: AlertIds.ClonePaymentPanelAlert,
            type: AlertTypes.Success,
            message: 'Panel Settings have been cloned to the selected facilities panel',
            dismissable: true,
          })
        );
      }
      if (response.errorResponse?.data?.validationErrors) {
        return response.errorResponse.data.validationErrors;
      }else if(response.errorResponse) {
        return [{
          errorMessage: response.errorResponse?.statusText,
          PropertyName:  response.errorResponse?.status
          }
        ]
      }

      return [];
    });
  };

  return {
    clonePanel: clonePanel
  };
}
