import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as VoidIcon } from 'assets/svgs/paymentIcons/void.svg';
import { Popup } from '../../../../components/popup/Popup';
import { EnumPlanStatus } from '../../../../models/enums/EnumPaymentPlan';
import { EnumPopupButtonsStyle, EnumPopupButtonsType, EnumPopupType } from '../../../../models/enums/EnumPopupType';
import { TransactionDetailsModel } from '../../../../models/TransactionDetailsModel';
import { UserDetailModel } from '../../../../models/UserInfoAndRolesModel';
import { HeaderButton } from '../../../../pages/Search/planSearch/paymentPlanDetailsHeader/HeaderButton';
import { displayAmount } from '../../../../utils/Utils';
import { AlertIds, AlertTypes, removeAlert, setAlert } from '../../../alert/AlertReducer';
import { useVoidPayment } from '../../../singlePayment/hooks/useVoidPayment';
import { EmailModalInfo } from '../../common/Header';
import { CommonPopupChildren } from '../common/CommonPopupChildren';
import { Accounts } from './Accounts';
import { useVoidWizzard } from './VoidWizard';
import isTransactionAmountValid from '../common/isTransactionAmountValid'
import TransactionDetailsPrint from 'features/transactionDetails/TransactionDetailsPrint';
import { useTypedSelector } from 'app/rootReducer';
import { getLogoDataForOrganization } from 'features/admin/logoManager/LogoManagerReducer';
import { useGetReceipts } from 'features/admin/receipts/useGetReceipts';

export default function Void(props: { disabled?: boolean, planStatus?: EnumPlanStatus, transaction?: TransactionDetailsModel, transactionCreator?: UserDetailModel, setIsEmailPopupOpened: (info: EmailModalInfo) => any }) {
  const { disabled = false, planStatus, transaction, transactionCreator, setIsEmailPopupOpened } = props;
  const dispatch = useDispatch();
  const wizard = useVoidWizzard();
  const [notesOnVoid, setNotesOnVoid] = useState<string>();
  const [isVoidPopupOpen, setIsVoidPopupOpen] = useState(false);
  const voidAlert = { id: AlertIds.VoidAlert, type: AlertTypes.Error, message: "Could not void transaction" };

  const isProcessing = useTypedSelector(s => s.services.calls.voidTransaction?.isProcessing);
  const { voidPayment, voidId } = useVoidPayment(
    transaction ? { ...transaction, notes: notesOnVoid } : undefined,
    () => {
      dispatch(removeAlert(voidAlert));
      wizard.next()
    },
    (message) => 
      dispatch(setAlert({
        ...voidAlert, 
        message: message ?? voidAlert.message
      }))
    );

  useEffect(() => {
    transaction?.organization?.path && dispatch(getLogoDataForOrganization(transaction?.organization?.path));
  }, [dispatch]);

  const { printPaymentReceipt } = useGetReceipts();
  const [printReceiptUrl, setPrintReceiptUrl] = useState<string>('');
  const openPrintPreview = async()=>{ 
    const receiptURL = await printPaymentReceipt(voidId,'english');
    if(receiptURL){
      setPrintReceiptUrl( receiptURL)
    } 
  };

  const onClickVoid = () => {
    if (planStatus && planStatus == EnumPlanStatus.Active || !planStatus) {
      if (isTransactionAmountValid(transaction)) {
        dispatch(setAlert({
          ...voidAlert, 
          message: `Issue Void is not allowed since maximum allowed amount for Issue Void/Credit has been reached.`
        }));
        return
      }
      wizard.selectStep(wizard.state.steps[0]);
      setIsVoidPopupOpen(true);
    } else {
      dispatch(setAlert({
        ...voidAlert,
        message: `This transaction against a payment plan cannot be voided. Payment plan status is ${planStatus}`
      }));
    }
  }

  const logoManager = useTypedSelector(s => s.logoManager);
  const companyLogo = logoManager.logos?.find(logo => logo.isSelected);

  const footerVoid = [
    { type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel'
    },
    { type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: isProcessing ? 'Processing...' : 'Confirm Void',
      disabled: isProcessing
    },
  ]
  const footerSubmit = [
    { type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.tertiary,
      text: 'Email',
      icon: 'email'
    },
    { type: EnumPopupButtonsType.print,
      style: EnumPopupButtonsStyle.tertiary,
      text: 'Print',
      icon: 'print'
    },
    { type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Done'
    },
  ]

  return <>
    <HeaderButton
      title="Issue Void"
      disabled={disabled}
      icon={<VoidIcon />}
      onClick={onClickVoid}
    />
    {isVoidPopupOpen && <Popup
    type={EnumPopupType.basic}
    header="PaymentSafe®"
    fullHeight={true}
    children={
      <CommonPopupChildren
        transaction={transaction}
        transactionCreator={transactionCreator}
        isCompleted={wizard.getCurrentStepIndex() == 1}
        accounts={<Accounts
          accounts={transaction?.lineItems}
          isCompleted={wizard.getCurrentStepIndex() == 1}
        />}
        notes={notesOnVoid}
        onChangeNotes={(value?: string) => { setNotesOnVoid(value) }}
        showNotes={true}
        logo={companyLogo}
      />
    }
    footer={wizard.getCurrentStepIndex() == 0 ? footerVoid : footerSubmit}
    footNotes={wizard.getCurrentStepIndex() == 0 ? <>
      <p>You are about to issue a void payment in the amount of ${displayAmount(transaction?.amount)}.</p>
      <p>If it is correct, click Submit. Otherwise, click Cancel to return to the previous screen.</p>
    </> : null}
    onClose={() => setIsVoidPopupOpen(false)}
    onPrint= {() => { openPrintPreview && openPrintPreview() }}
    onSubmit={() => { wizard.getCurrentStepIndex() == 0 ? voidPayment() : setIsEmailPopupOpened({ type: 'void', transactionId: voidId }) }}
  />
  }
      {printReceiptUrl && <TransactionDetailsPrint receiptUrl={printReceiptUrl}/> }
  </>
}