import React from 'react';
import { Row } from 'reactstrap';
import { EDS_TextBox, EDS_Accordion } from '@EH/eh.eds.react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'app/rootReducer';
import moment from 'moment';
import {
  SimpleSearchInformation,
  setSimpleSearchInfoState,
} from 'pages/Search/simpleSearch/SimpleSearchReducer';
import styles from '../simpleSearch.module.scss';
import DateRange from 'components/dateRange/DateRange';
import { convertStringToDate } from 'utils/UtilsDateTime';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ReactComponent as Help } from 'assets/svgs/paymentPlanIcons/icon-help-outline.svg';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { toolTipMessages } from 'pages/Search/advanceSearch/MainFilters/SearchByTransactionDetails';

const getPopoverContent = (content: string) => (
  <Popover id="popover-basic">
    <Popover.Content>{content}</Popover.Content>
  </Popover>
);

export const PopoverClientElement = ({
  content,
  placement,
}: {
  content: string;
  placement: Placement;
}) => (
  <OverlayTrigger
    trigger={['hover', 'focus']}
    placement={placement}
    overlay={getPopoverContent(content)}
  >
    <Help />
  </OverlayTrigger>
);

export function SearchByPatientInformation() {
  const dispatch = useDispatch();
  const state = useTypedSelector(p => p.simpleSearchInfo?.value);
  const setState = (param: SimpleSearchInformation) => {
    dispatch(setSimpleSearchInfoState(param));
  };
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let propertyName = event.target.name;
    let value = event.target.value.trim();
    setState({ ...state, [propertyName]: value });
  };
  const changeDateHandler = (
    date: string,
    field: string,
    ) => {
    setState({ ...state, [field]: moment(date).isValid() ?  moment(date).format('YYYY-MM-DDTHH:mm:ss.000') : '' });
  };

  return (
    <EDS_Accordion
      modifiers='accordionStyle'
      accordionItems={[
        {
          summary: 'Patient/Transaction Information',
          isOpen: true,
          modifiersContainerOpen: styles.accordionContainerSearchByDate,
          content: (
            <Row>
              <div className="col-lg-4">
                <EDS_TextBox
                  label={'MRN:'}
                  name={'mrn'}
                  placeHolder={'Enter MRN'}
                  modifiers={styles.filterItem}
                  onChange={changeHandler}
                  value={state?.mrn}
                />
              </div>
              <div className="col-lg-4">
                <EDS_TextBox
                  label={'Account Number:'}
                  name={'accountNumber'}
                  placeHolder={'Enter account number'}
                  modifiers={styles.filterItem}
                  onChange={changeHandler}
                  value={state?.accountNumber}
                />
              </div>
              <div className="col-lg-4">
                <EDS_TextBox
                  label={'Transaction ID:'}
                  name={'id'}
                  placeHolder={'Enter transaction ID'}
                  modifiers={styles.filterItem}
                  onChange={changeHandler}
                  value={state?.id}
                />
                <span className={styles.help}>
                  <PopoverClientElement
                    placement="top"
                    content={toolTipMessages.transaction}
                  />
                </span>
              </div>
              <div className="col-lg-8 pt-lg-3">
                <DateRange
                  labelFirstField="Payment Submitted Date From:"
                  labelSecondField="Payment Submitted Date To:"
                  nameFrom="submittedDateTimeMin"
                  nameTo="submittedDateTimeMax"
                  value={[state.submittedDateTimeMin, state.submittedDateTimeMax]}
                  maxDateFrom={(state.submittedDateTimeMax && convertStringToDate(state.submittedDateTimeMax)) || new Date()}
                  minDateTo={(state.submittedDateTimeMin && convertStringToDate(state.submittedDateTimeMin)) || undefined}
                  maxDateTo={new Date()}
                  showIcon
                  onChangeFrom={date =>
                    changeDateHandler(date || '', 'submittedDateTimeMin')
                  }
                  onChangeTo={date =>
                    changeDateHandler(date ||'', 'submittedDateTimeMax')
                  }
                />
              </div>
            </Row>
          ),
        },
      ]}
    />
  );
}
