import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum AlertTypes {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Info = 'info',
}

export interface Alert {
  id: string;
  type: AlertTypes;
  message: string;
  dismissable?: boolean;
}

export enum AlertIds {
  VoidAlert = 'voidAlert',
  CreditAlert = 'CreditAlert',
  EmailAlert = 'EmailAlert',
  PauseAlert = 'PauseAlert',
  PausePlanAlert = 'PausePlanAlert',
  LineItemAlert = 'LineItemAlert',
  PlanAlert = 'PlanAlert',
  PaymentOnFileAlert = 'PaymentOnFileAlert',
  PlanCreatedAlert = 'PlanCreatedAlert',
  PaymentCompleteAlert = 'PaymentCompleteAlert',
  TransactionDetailsAlert = 'TransactionDetailsAlert',
  CreditCardConfigAlert = 'CreditCardConfigAlert',
  GUIDLookupAlert = 'GUIDLookupAlert',
  WebServiceErrorAlert = 'WebServiceErrorAlert',
  GlPaymentAlert = 'GlPaymentAlert',
  SearchAlert = 'SearchAlert',
  PlanDetailsAlert = 'PlanDetailsAlert',
  PatientAlert = 'PlanDetailsAlert',
  LogoManagerAlert = 'LogoManagerAlert',
  ClientConfiguration = 'ClientConfiguration',
  DeviceManagerAlert = 'DeviceManagerAlert',
  OrganizationAlert = 'OrganizationAlert',
  NewFacilityAlert = 'NewFacilityAlert',
  DownloadExcelFullReport = 'DownloadExcelFullReport',
  FileIsTooBig = 'FileIsTooBig',
  TooManyFiles = 'TooManyFiles',
  RemitRecreation = 'RemitRecreation',
  PaymentPlanConfigurationManagerAlert = 'PaymentPlanConfigurationManagerAlert',
  ScribanTemplateError = 'ScribanTemplateError',
  RemitDelete = 'RemitDelete',
  EditRemitConfig = 'EditRemitConfig',
  CloneRemitConfigurationsAlert = 'CloneRemitConfigurationsAlert',
  ClonePermissionsAlert = 'ClonePermissionssAlert',
  PaymentDashboardConfiguration = 'PaymentDashboardConfiguration',
  ClonePaymentPanelAlert = 'ClonePaymentPaneAlert',
  ErrorClonePaymentPanelAlert = 'ErrorClonePaymentPanelAlert',
  ErrorResetConfigurationAlert = 'ErrorResetConfigurationAlert',
  PaymentConfiguration = 'PaymentConfiguration',
  NoPAHAccountsAlert = 'NoPAHAccountsAlert',
  PAHResponse = 'PAHResponse',
  AddPermissions = 'AddPermissions',
  DeletePermissions = 'DeletePermissions',
  UpdatePermissions = 'UpdatePermissions',
  OrganizationSync = 'OrganizationSync',
  AddOrRemoveFacilities = 'AddOrRemoveFacilities',
  NewFacilityMaxPingAlert = 'NewFacilityMaxPingAlert',
  ReceiptManagerAlert = 'ReceiptManagerAlert',
  PrintReceiptAlert = 'PrintReceiptAlert',
  AddExternalAddress = 'AddExternalAddress',
  EpicTokenMappingsUpdate = 'EpicTokenMappingsUpdate',
  EpicTokenMappingsCreate = 'EpicTokenMappingsCreate',
  EpicDepartmentMappingsUpdate = 'EpicDepartmentMappingsUpdate',
  EpicDepartmentMappingsCreate = 'EpicDepartmentMappingsCreate',
  TransactionLogs = 'TransactionLogs'
}

export interface AlertState {
  alerts: Alert[];
  fixedModeOn: boolean;
}

export const initialState: AlertState = {
  alerts: [],
  fixedModeOn: false,
};

const reducerSlice = createSlice({
  name: 'Alerts',
  initialState,
  reducers: {
    setAlerts(state, action: PayloadAction<Alert[]>) {
      state.alerts = action.payload;
    },
    setAlert(state, action: PayloadAction<Alert>) {
      const alert = action.payload;
      const idx = state.alerts.findIndex(a => a.id === alert.id);
      if (idx > -1) {
        state.alerts[idx] = alert;
      } else {
        state.alerts.push(alert);
      }
    },
    removeAlert(state, action: PayloadAction<{ id: string }>) {
      const idx = state.alerts.findIndex(a => a.id === action.payload.id);
      if (idx > -1) {
        state.alerts.splice(idx, 1);
      }
    },
    removeAllAlerts(state) {
      state.alerts = [];
    },
    setFixedMode(state, action: PayloadAction<boolean>) {
      state.fixedModeOn = action.payload;
    }
  },
});

export const { setAlerts, setAlert, removeAlert, removeAllAlerts, setFixedMode } = reducerSlice.actions;
export default reducerSlice.reducer;
