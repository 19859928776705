import { useState } from "react";
import { useDispatch } from "react-redux";
import { PredefinedData, RemitConfigurationSettings, Setting } from "models/admin/RemitConfiguration";
import AddNewRemitConfigurationSetting from "./AddNewRemitConfigurationSetting";
import { addSetting, removeSetting, toggleIsOpen, moveSetting, setSetting } from "./RemitConfigurationReducer";
import RemoveRemitConfigurationSetting from "./RemoveRemitConfigurationSetting";
import { SettingComponent } from "./Setting";
import { settingIsInUse } from "./forms/AggregateSettingForm";
import { AlertIds, AlertTypes, setAlert } from "../../alert/AlertReducer";
import { RemitFileFieldDescription } from "models/RemitPreDefinedListsModel";

export default function Settings(props: {
  settings: Setting[],
  settingKey: keyof RemitConfigurationSettings,
  canBeEmpty?: boolean,
  predefinedData: PredefinedData,
  aggregateSettings: Setting[],
  footerSettings: Setting[],
  remitFileFieldDescriptions?: RemitFileFieldDescription[],
  onChangeEvent: () => void,
  createSetting: (settingKey: keyof RemitConfigurationSettings, name: string) => Setting
}) {
  const { settings, settingKey, canBeEmpty = true, predefinedData, aggregateSettings, footerSettings, remitFileFieldDescriptions, onChangeEvent,createSetting } = props;
  const dispatch = useDispatch();
  const [addIndex, setAddIndex] = useState<number | null>(null);
  const [removeIndex, setRemoveIndex] = useState<number | null>(null);
  const add = (name: string) => {
    if (addIndex != null) {
      onChangeEvent();
      dispatch(addSetting({ index: addIndex, key: settingKey, value: createSetting(settingKey, name), }))
      setAddIndex(null)
    }
  }

  const remove = (setting: Setting) => {
    if (settingKey === 'aggregateSettings' && settingIsInUse(setting.name, footerSettings)) {
      dispatch(
        setAlert({
          id: AlertIds.EditRemitConfig,
          type: AlertTypes.Error,
          message: "Cannot delete aggregate setting - it is in use"
        })
      );
      return;
    }
    if (removeIndex != null) {
      onChangeEvent();
      dispatch(removeSetting({ index: removeIndex, key: settingKey, }));
      setRemoveIndex(null)
    }
  }

  const removable = canBeEmpty || settings.length > 1

  return <div className={'remitSettings'}>
    {settings.map((s, index) => {
      return <SettingComponent
        key={index}
        setting={s}
        onAdd={() => setAddIndex(index)}
        onRemove={removable ? () => setRemoveIndex(index) : null}
        isOpen={!!s.metadata?.isOpen}
        toggleIsOpen={() => { dispatch(toggleIsOpen({ index, key: settingKey, })) }}
        moveUp={() => { dispatch(moveSetting({ from: index, to: index - 1, key: settingKey, })) }}
        moveDown={() => { dispatch(moveSetting({ from: index, to: index + 1, key: settingKey, })) }}
        onChange={(setting: Setting) => {
          onChangeEvent();
          dispatch(setSetting({ key: settingKey, index, setting }))
        }}
        settingKey={settingKey}
        index={index}
        predefinedData={predefinedData}
        aggregateSettings={aggregateSettings}
        footerSettings={footerSettings}
        remitFileFieldDescriptions={remitFileFieldDescriptions}
      />
    })}
    {addIndex != null ? <AddNewRemitConfigurationSetting
      close={() => { setAddIndex(null) }}
      onNext={add}
    /> : null}
    {removeIndex != null ? <RemoveRemitConfigurationSetting
      close={() => { setRemoveIndex(null) }}
      onNext={() => remove(settings[removeIndex])}
    /> : null}
  </div>
}