import { useDispatch } from "react-redux";
import { RemovePermissions } from "../../../../../services/PermissionsService";
import { AlertIds, AlertTypes, setAlert } from "../../../../alert/AlertReducer";

export default function useRemovePermissions() {
  const dispatch = useDispatch();

  async function removePermissions(organizationPaths: string[], permissions: string[]) {
    const response = await RemovePermissions({
      organizationPaths,
      permissions
    });

    if (!response.err) {
      dispatch(setAlert({
        id: AlertIds.UpdatePermissions,
        message: "Permissions updated",
        type: AlertTypes.Success,
        dismissable: true
      }))
      return true;
    } else {
      dispatch(setAlert({
        id: AlertIds.UpdatePermissions,
        message: "Could not save permissions",
        type: AlertTypes.Error,
        dismissable: true
      }))
    }
    return false;
  }

  return { removePermissions };
}