import EnumResultStatusType from 'models/enums/EnumResultStatusType';
import EnumTransactionStatusType from 'models/enums/EnumTransactionStatusType';

export const paymentStatusOptions = [
  ...Object.values(EnumTransactionStatusType)
    .filter(key => isNaN(key as any))
    .map(option => ({ value: option, optionName: option })),
];

export const resultStatusOptions = [
  { value: '', optionName: 'Select All' },
  ...Object.values(EnumResultStatusType)
    .filter(key => isNaN(key as any))
    .map(option => ({ value: option, optionName: option })),
];

export enum TransactionTypesEnum {
  Payment = 'Payment',
  Void = 'Void',
  Credit = 'Credit',
  RecurringPayment = 'Recurring Payment',
  GlPayment = 'GL Payment',
  GlVoid = 'GL Void',
  GlCredit = 'GL Credit',
  RecurringGlPayment = 'Recurring GL Payment',
  SystemUpdaterService = 'SystemUpdaterService'
}

export const transactionTypes = Object.values(TransactionTypesEnum);
export const glTransactionTypes = ['GL Payment', 'GL Credit', 'GL Void'];

export const tenderTypes = [
  'Cash', 
  'Credit/Debit',
  'eCheck',
  'Paper Check',
  'Money Order',
  'Card Device',
  'Unknown',
  'Not Set'
];
export const cardEntryTypes = [
  'Manual',
  'Device',
];