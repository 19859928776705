import ConfigurationTemplate from "../../../features/admin/clientConfiguration/ConfigurationTemplate";
import { Input } from "@EHDS/core";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState } from "react";
import { Metadata } from "../../../features/admin/epic/whitelist/WhitelistReducer";
import { useEpicDepartmentMappings } from "../../../features/admin/useEpicDepartmentMappings";
import { ChangeDetectionStrategyType } from "ag-grid-react/lib/changeDetectionService";
import { useParams } from "react-router";
import { useOrganizations } from "../../../features/organizations/hooks/useOrganizations";
import { ColDef, ICellRendererParams, SelectionChangedEvent } from "ag-grid-community";
import { GenericAttribute } from "../../../models/metaData/MetaData";
import TextBox from "../../../features/componentFactory/wrapperComponents/TextBox";
import MultiSelect, { MultiSelectOption } from "../../../components/select/MultiSelect";
import { getOrganizationOption, getOrganizationOptions } from "../../Search/simpleSearch/OrganizationUserSearch";
import { EpicDepartmentMapping } from "../../../services/EpicDepartmentMappingService";
import { DataType } from "../../../models/metaData/MetaDataEnums";
import { OrganizationLevelDocument, OrganizationLevelReference } from "../../../models/OrganizationLevelDocument";
import 'assets/styles/components/_epicDepartmentMapping.scss'
import { AgGridReact as AgGridReactRef } from "ag-grid-react/lib/agGridReact";
import { useDepartmentMappingValidator } from "../../../features/admin/epic/departmentMappings/useDepartmentMappingValidator";
import MappingsActionbar from "../../../features/admin/epic/tokenMappings/MappingsActionbar";
import { MappingActions } from "../../../features/admin/epic/tokenMappings/MappingActions";
import { getElementFromPath } from "utils/Utils";

export default function EpicDepartmentMappingPage() {
  let { clientId } = useParams<{ clientId: string }>();
  const { getOrganizationById, getDepartments, getFacilityTradingPartnerPath, getTradingPartnerByFacility } = useOrganizations();
  let selectedOrganization = getOrganizationById(clientId);
  const tradingPartner = getTradingPartnerByFacility(selectedOrganization?.path);
  const tradingPartnerPath = getFacilityTradingPartnerPath(selectedOrganization?.path);

  const departments = getDepartments();
  let filteredDepartments: OrganizationLevelDocument[] = [];
  if (tradingPartner != undefined) {
    filteredDepartments = departments.filter(d => d.path.includes(tradingPartnerPath!));
  }
  const departmentOptions = getOrganizationOptions(filteredDepartments, t =>
    getOrganizationOption(t, o => `(${getElementFromPath(o.path, 2) ?? ""}) ${o.name}`)
  );

  const [filter, setFilter] = useState("");
  const [filteredMappings, setFilteredMappings] = useState<EditableEpicDepartmentMapping[]>([]);
  const { mappings, deleteMappings, dataRef, saveMappings, storeMapping } = useEpicDepartmentMappings(
    filteredMappings,
    setFilteredMappings,
    setFilter,
    selectedOrganization,
  );

  const [selectedMappings, setSelectedMappings] = useState<string[]>([]);

  const { validate, validateAll, getErrorMessage, getAttribute } = useDepartmentMappingValidator(getUpdatedMappings());

  function getUpdatedMappings() {
    const updated = [...filteredMappings]
    dataRef.current.forEach((mapping: EditableEpicDepartmentMapping) => {
      const idx = updated.findIndex(m => getId(m) === getId(mapping))
      if (updated[idx]) {
        updated[idx] = {
          ...updated[idx],
          ...mapping
        }
      }
    });
    return updated;
  }

  function getData(mappingId: string) {
    return dataRef.current.find(d => getId(d) === mappingId) ?? filteredMappings.find(m => getId(m) === mappingId);
  }
  function setDepartment(departmentPath: string, mappingId: string) {
    const department = departments.find(d => d.path === departmentPath)
    const data = getData(mappingId);

    department && storeMapping({ ...data, department }, mappingId);
  }

  const createNewMapping = (tempId: string) => {
    return { id: tempId, mapKey: "", organization: tradingPartner, metadata: { tempId: tempId, isEditing: true } }
  }

  function setMapKey(mapping: EditableEpicDepartmentMapping, mapKey: string) {
    const oldData = dataRef.current.find(t => getId(t) === getId(mapping));
    storeMapping({ ...oldData, mapKey }, getId(mapping));
  }

  const { onDeleteAction, onEditAction, onSaveAction, onAddNewAction } = MappingActions({
    dataRef,
    deleteMappings: (ids: string[]) => { deleteMappings(ids, filteredMappings, tradingPartnerPath) },
    filteredMappings,
    getUpdatedMappings,
    saveMappings: (mappings: EditableEpicDepartmentMapping[]) => { saveMappings(mappings, tradingPartnerPath) },
    selectedMappings,
    setFilteredMappings,
    setSelectedMappings,
    validateAll,
    createNewMapping
  });

  return <ConfigurationTemplate
    title="Epic Department Mapping"
    subTitle={selectedOrganization && tradingPartner ? `(for Organization: ${tradingPartner?.name} ${tradingPartner?.tradingPartnerId})`: ''}
    headerButtons={<MappingsActionbar
      selectedMappings={selectedMappings}
      addNewMappings={onAddNewAction}
      onSaveAction={onSaveAction}
      onDelete={onDeleteAction}
      onEdit={onEditAction}
    />
    }
  >
    <>
      <div className="search-row">
        <Input
          className={"search-input"}
          label="Search:"
          placeholder="Map key or department..."
          value={filter}
          onChange={e => {
            const value = e.target.value;
            setFilter(value);
            setFilteredMappings(mappings.filter(m => m.mapKey?.includes(value) || m.department?.name.includes(value)));
          }}
        /></div>
      <MappingGrid
        rowData={filteredMappings}
        setSelectedMappings={setSelectedMappings}

        columnDefs={[{
          headerName: "Map Key",
          field: 'mapKey',
          cellRenderer: 'EditCell',
          cellRendererParams: () => {
            return {
              setMapKey,
              storeMapping,
              validate: (value?: any, currentEntity?: EditableEpicDepartmentMapping) => {
                validate(getAttribute("mapKey"), value, false, currentEntity)
              },
              getErrorMessage,
              dataRef,
            }
          },
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          minWidth: 300
        },
        {
          headerName: "Department Name",
          field: 'department',
          editable: false,
          cellRenderer: "DepartmentDropdownCell",
          cellRendererParams: {
            departmentOptions,
            setDepartment
          },
          suppressSizeToFit: true,
          resizable: false,
          flex: 1,
          }]}

        frameworkComponents={{
          DepartmentDropdownCell,
          EditCell,
        }}
      />
    </>
  </ConfigurationTemplate>
}

export function MappingGrid<T>(props: {
  columnDefs: ColDef[],
  rowData: T[],
  setSelectedMappings: (selected: string[]) => void,
  frameworkComponents: any
}) {
  const { columnDefs, rowData, setSelectedMappings, frameworkComponents } = props;

  const gridRef = useRef<AgGridReactRef>(null);

  const [pageSize, setPageSize] = useState(25)

  return rowData?.length ? <div className="ag-theme-alpine default-checkboxes">
    <AgGridReact
      ref={gridRef}
      getRowNodeId={(data: any) => data.id}
      rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
      rowHeight = {50}
      columnDefs={columnDefs}
      defaultColDef={{
        suppressMovable: true,
        sortable: false,
        resizable: false,
        filter: false,
        editable: false,
        suppressKeyboardEvent: (params: any) => true
      }}
      suppressDragLeaveHidesColumns={true}
      rowData={rowData}
      domLayout={'autoHeight'}
      gridAutoHeight={true}
      suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
      enableCellTextSelection={true}
      ensureDomOrder={true}

      suppressRowClickSelection={true}
      suppressCellSelection={true}

      disableStaticMarkup={true}

      frameworkComponents={frameworkComponents}

      rowSelection={'multiple'}


      onSelectionChanged={(e: SelectionChangedEvent) => {
        setSelectedMappings(
          e.api.getSelectedRows().map((mapping: EpicDepartmentMapping) => mapping.id ?? "")
        );
      }}

      pagination={true}
      paginationPageSize={pageSize}
    />
    <MultiSelect
      label={"Rows per page: "}
      multiple={false}
      options={[5, 10, 25].map(o => ({ value: o, label: o.toString() }))}
      onChange={(option) => {
        setPageSize(option?.value ?? 25)
        gridRef.current?.api?.paginationSetPageSize(option?.value ?? 25);
      }}
      name={"pageSize"}
      values={{ value: pageSize, label: pageSize.toString() }}
    />
  </div> : <>No mappings available</>
}


export interface GenericEditCellProps<T> extends ICellRendererParams {

  storeTokenMapping: (data: T, index: string) => void;
  data: T,
  attribute: GenericAttribute<T>,
  commitWorkingCopies: () => void,
  validateAll: (validOnly: boolean, currentData: T, idx: number) => boolean,
  getErrorMessage: (key: string) => string,
  departmentOptions: MultiSelectOption[],
  setDepartment: (departmentPath: string, mappingId: string) => void,
  setMapKey: (mapping: EditableEpicDepartmentMapping, mapKey: string) => void,
  validate: (value: any, data: T) => void,
  dataRef: any,
}
export interface EditableEpicDepartmentMapping extends EpicDepartmentMapping {
  metadata?: Metadata,
  errors?: string;
  organization?: OrganizationLevelReference;
}

export function EditCell(params: GenericEditCellProps<EditableEpicDepartmentMapping>) {
  const { value, data, setMapKey, validate, getErrorMessage } = params;
  const [inputValue, setInputValue] = useState<string>(value ?? null);

  return data.metadata?.isEditing ? <div className={"url-edit"} >
    <TextBox
      value={inputValue}
      attribute={{ name: "mapKey", dataType: DataType.String }}
      onChange={value => {
        const updated = { ...data, mapKey: value };

        setInputValue(value);
        setMapKey(updated, value);
        validate(value, updated);
      }}
      noLabel={true}
      error={getErrorMessage("mapKey" + getId(data))}
    />
  </div> : <div>{value}</div>
}

export function getId(data: EditableEpicDepartmentMapping) {
  return data?.id ?? data?.metadata?.tempId ?? ""
}
export function DepartmentDropdownCell(params: GenericEditCellProps<EditableEpicDepartmentMapping>) {
  const { value, data, setDepartment, departmentOptions } = params;
  const option = departmentOptions.find(o => o.value === data.department?.path) ?? null
  const [selectedOption, setSelectedOption] = useState<MultiSelectOption | null>(option);

  return data.metadata?.isEditing ? <div className={"url-edit"} >
    <MultiSelect
      label=""
      multiple={false}
      options={departmentOptions}
      onChange={(selected: MultiSelectOption | null) => {
        setSelectedOption(selected)
        setDepartment(selected?.value ?? "", getId(data));
      }}
      values={selectedOption}
      disableCloseOnSelect={false}
      name={"tokenReference"}
    />
  </div> : <div>{value?.name}</div>
}
