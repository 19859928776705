import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'app/rootReducer';
import { setSimpleSearchInfoState } from 'pages/Search/simpleSearch/SimpleSearchReducer';
import { EDS_Button, EDS_Checkbox } from '@EH/eh.eds.react';
import SearchBox from 'components/searchBox/searchBox';
import {
  CollapseButton,
  CollapsibleSection,
  Sections,
} from 'pages/Admin/PaymentPanelConfiguration';
import 'assets/styles/components/_permissionForm.scss';
import OrganizationUserSearch from 'pages/Search/simpleSearch/OrganizationUserSearch';
import { UserSelector } from '../OrganizationUserManagement/OrganizationUserManagement';
import { UserDetailModel } from 'models/UserInfoAndRolesModel';
import { PermissionItem } from 'models/Permission';
import { GetPermissions } from 'services/PermissionsService';
import { GetUsers } from 'services/UsersService';
import { getAdminPermissions, nameof } from 'utils/Utils';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { MultiSelectOption } from 'components/select/MultiSelect';
import UserPermissions from 'models/enums/UserPermissions';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import OrganizationsSelector from '../../../features/admin/UsersAndPermissions/OrganizationsSelector';
import { OrganizationLevelSummary } from 'models/OrganizationLevelSummary';
import useOrganizationsUsersState from 'features/admin/UsersAndPermissions/hooks/useOrganizationsUsersState';

const labels = {
  permissions: 'PERMISSIONS',
  users: 'USERS',
  payment: 'PAYMENTS',
  paymentPlan: 'PAYMENT PLANS',
  generalLedger: 'GENERAL LEDGER(GL)',
  pSafeAdmin: 'ADMIN',
  epic: 'EPIC',
  applyButton: 'Apply to',
  submit: 'Submit',
  clear: 'Clear Selection',
  showSelectedPermissions: 'Show users with selected permissions only',
  findPermissions: 'Find Permissions...',
};

export default function PermissionsManagement(props: {
  prePermissions?: PermissionItem[];
  isAdmin?: boolean;
  sspUserPermission?: boolean;
  loggedUserOrganizationLevel?: OrganizationLevelSummary | null;
}) {
  const { prePermissions, isAdmin, sspUserPermission, loggedUserOrganizationLevel } = props;
  const dispatch = useDispatch();
  const {
    useGetFacilities,
    getOrganizationWithFallback,
    getFacilityTradingPartnerPath,
    organizations
  } = useOrganizations();
  const state = useTypedSelector(p => p.simpleSearchInfo?.value);
  const organizationsUsersState = useOrganizationsUsersState();
  const { clearState } = organizationsUsersState;
  
  const [fieldCollapsed, setFieldCollapsed] = useState<Sections>();
  const [showSelectedUsers, setShowSelectedUsers] = useState(false);
  const [permissions, setPermissions] = useState(prePermissions);
  const [searchResults, setSearchResults] = useState(prePermissions);
  const [users, setUsers] = useState<UserDetailModel[]>();
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [showUserSection, setShowUserSection] = useState(false);
  const [showUserTable, setShowUserTable] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [
    selectedOrganization,
    setSelectedOrganization,
  ] = useState<MultiSelectOption | null>();

  useMemo(() => {
    clearState();
  }, []);

  const facilities = useGetFacilities(o =>
    o.path.includes(selectedOrganization?.value ?? '')
  );
  const paymentPermissions = searchResults?.filter(
    item => item.groupName === 'Payments'
  );
  const paymentPlansPermissions = searchResults?.filter(
    item => item.groupName === 'Payment Plans'
  );
  const generalLegderPermissions = searchResults?.filter(
    item => item.groupName === 'General Ledger (GL)'
  );
  const pSafeAdminPermissions = getAdminPermissions(isAdmin, sspUserPermission, searchResults);

  const epicPermissions = searchResults?.filter(
    item => item.groupName === 'Epic'
  );


  const handleSelectAllCheckBox = () => {
    paymentPermissions?.unshift({
      id: 'checkAllPayment',
      name: 'Select All',
      groupName: 'Payments',
      state: 0,
    });
    paymentPlansPermissions?.unshift({
      id: 'checkAllPaymentPlans',
      name: 'Select All',
      groupName: 'PaymentsPlans',
      state: 0,
    });
    generalLegderPermissions?.unshift({
      id: 'checkAllGeneralLedger',
      name: 'Select All',
      groupName: 'General Ledger (GL)',
      state: 0,
    });
    pSafeAdminPermissions?.unshift({
      id: 'checkAllPSafeAdmin',
      name: 'Select All',
      groupName: 'Admin',
      state: 0,
    });
    epicPermissions?.unshift({
      id: 'checkAllEpic',
      name: 'Select All',
      groupName: 'Epic',
      state: 0,
    });
  };

  handleSelectAllCheckBox();
  const collapseSection = (name: keyof Sections) => {
    setFieldCollapsed({
      ...fieldCollapsed,
      [name]: !fieldCollapsed?.[name],
    });
  };

  const mapResponse = (result: any) => result.map((p: any) => ({
    ...p,
    id: p.id ? UserPermissions[p.id]?.toString() : '',
  }));

  const getPermissions = async () => {
    const response = await GetPermissions();
    if (response.result) {
      const result = mapResponse(response.result);
      setPermissions(result);
      setSearchResults(result);
    }
  };

  const getUsers = async () => {
    const response = await GetUsers();

    if (response.result) {
      setUsers(response.result);
    }
  };

  useEffect(() => {
    getPermissions();
  }, [state.facilities, state.departments]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    filterPermissions();
    filterUsers();
  }, [showUserSection, showSelectedUsers, showUserTable]);

  useEffect(() => {
    if(!isAdmin && sspUserPermission) {
      const path = getFacilityTradingPartnerPath(loggedUserOrganizationLevel?.organizationLevel_Path ?? '');
      const tradingPartner = organizations.filter(tP => tP.path === path && tP.organizationLevelType)[0] ?? '';
      setSelectedOrganization({label: tradingPartner.name ?? '', value: tradingPartner.path ?? ''});
    }
  }, [sspUserPermission])

  const selectAllPermissions = (
    newSelectedItems: Set<unknown>,
    itemKey?: string
  ) => {
    setSelectedItems(
      handleSelectAllPermissions(
        newSelectedItems,
        itemKey,
        paymentPermissions,
        paymentPlansPermissions,
        generalLegderPermissions,
        pSafeAdminPermissions,
        epicPermissions
      )
    );
  };

  const filterPermissions = () => {
    if (showUserSection) {
      const checkedPermissions = permissions?.filter(permission => {
        if (selectedItems.has(permission.id)) {
          return permission;
        }
        return null;
      });
      setPermissions(checkedPermissions);
    }
  };

  const searchPermissions = () => {
    if (!showUserSection) {
      if (permissions && permissions.length > 0 && searchText !== '') {
        const checkedPermissions = permissions.filter(
          permission => permission.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
        );
        setSearchResults(checkedPermissions);
      } else {
        setSearchResults(permissions ?? []);
      }
    }
  };

  const clearAllDropdowns = () => {
    dispatch(
      setSimpleSearchInfoState({
        ...state,
        facilities: [],
        departments: [],
        users: [],
      })
    );
    setSelectedOrganization(null);
    setShowUserTable(false);
    setShowSelectedUsers(false);
    getUsers();
  };

  const filterUsers = () => {
    if(!showUserTable) return;

    const selectedFacilities = state.facilities.map(facility => facility.value);
    const selectedDeparments = state.departments.map(deparment => deparment.value);
    const selectedPermissions = permissions?.map(permission => permission.id);

    let userList: UserDetailModel[] = [];
    
    users?.forEach(user => {
      const organizationLevels = user.organizationLevels;
      organizationLevels?.forEach(org => {
        if (
          selectedFacilities.includes(org.organizationLevel_Path) ||
          selectedDeparments.includes(org.organizationLevel_Path)
        ) {
          userList.push({ ...user, organizationLevels: [org] });
        }
      });
    });

    if (showSelectedUsers) {
      userList = userList.filter(user => {
        const filteredOrgLevels = user.organizationLevels?.filter(org =>
          org.permissions?.some(p =>
            selectedPermissions?.includes(p.toString())
          )
        );

        return filteredOrgLevels?.length ?? false;
      });
    }
    
    setUsers(userList);
  };

  const headerDefinitions = [
    {
      headerName: 'Last Name',
      field: nameof<UserDetailModel>('lastName'),
      sortable: true,
      resizable: true,
      valueGetter: (params: { data?: UserDetailModel }) => {
        return params.data?.lastName;
      },
      checkboxSelection: true,
      headerCheckboxSelection: true,
      maxWidth: 300,
    },
    {
      headerName: 'First Name',
      field: nameof<UserDetailModel>('firstName'),
      sortable: true,
      resizable: true,
      valueGetter: (params: { data?: UserDetailModel }) => {
        return params.data?.firstName;
      },
      maxWidth: 300,
    },
    {
      headerName: 'OneSource User ID',
      field: nameof<UserDetailModel>('oneSourceUserId'),
      resizable: true,
      minWidth: 250,
    },
    {
      headerName: 'Organization',
      field: nameof<UserDetailModel>('organizationLevels'),
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 250,
      valueGetter: (params: { data?: UserDetailModel }) => {
        const path  = params.data?.organizationLevels?.find(o => true)?.organizationLevel_Path ?? '';
        return getOrganizationWithFallback(path, OrganizationLevelTypes.TradingPartner)?.name ?? '-';
      },
    },
    {
      headerName: 'Facility',
      field: nameof<UserDetailModel>('organizationLevels'),
      sortable: true,
      filter: true,
      resizable: true,
      maxWidth: 300,
      valueGetter: (params: { data?: UserDetailModel }) => {
        const path  = params.data?.organizationLevels?.find(o => true)?.organizationLevel_Path ?? '';
        return getOrganizationWithFallback(path, OrganizationLevelTypes.Facility)?.name ?? '-';
      },
    },
    {
      headerName: 'Department',
      field: nameof<UserDetailModel>('organizationLevels'),
      sortable: true,
      filter: true,
      resizable: true,
      valueGetter: (params: { data?: UserDetailModel }) => {
        const path  = params.data?.organizationLevels?.find(o => true)?.organizationLevel_Path ?? '';
        return getOrganizationWithFallback(path, OrganizationLevelTypes.Department)?.name ?? '-';
      },
    },
    {
      headerName: 'Permission',
      field: nameof<UserDetailModel>('permissions'),
      hide: true,
      minWidth: 250,
      valueGetter: () => {
        const checkedPermissions = permissions?.filter(permission => {
          if (selectedItems.has(permission.id)) {
            return permission;
          }
          return '';
        });

        return checkedPermissions?.length
          ? checkedPermissions.map(f => f?.name).join(', ')
          : '';
      },
    },
  ];

  const selectedPermissions = Array.from(selectedItems).map(id =>
    permissions?.find(p => p.id == id)
  );
  
  const showCustomOrganizationSelector = () => {
    if(isAdmin || !sspUserPermission) {
      return (
        <div className='organization-container'>
          <OrganizationsSelector
                setSelectedOrganization={setSelectedOrganization}
                selectedOrganization={selectedOrganization}
          />
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <p className="permission-header">{labels.permissions}</p>
        <SearchBox
          className="permission-searchbox"
          placeholder={labels.findPermissions}
          onClick={searchPermissions}
          value={searchText}
          onChange={setSearchText}
          name="permissionSearchBox"
        ></SearchBox>
      </div>
      <div className="permission-form row">
        <div className="column">
          <div className="control section">
            <CollapseButton
              name={'payments'}
              dashboardSettingsCollapsed={fieldCollapsed}
              collapseSection={collapseSection}
            />
            {labels.payment}
          </div>
          <CollapsibleSection
            name={'payments'}
            dashboardSettingsCollapsed={fieldCollapsed}
          >
            {paymentPermissions?.map(item => {
              return renderCheckboxElement(
                selectedItems.has(item.id),
                'payments-permission',
                item.id,
                item.name,
                item.description,
                () =>
                  handleChange(
                    item.id,
                    selectAllPermissions,
                    setSelectedItems,
                    selectedItems
                  )
              );
            })}
          </CollapsibleSection>
        </div>

        <div className="column">
          <div className="control section">
            <CollapseButton
              name={'paymentPlans'}
              dashboardSettingsCollapsed={fieldCollapsed}
              collapseSection={collapseSection}
            />
            {labels.paymentPlan}
          </div>

          <CollapsibleSection
            name={'paymentPlans'}
            dashboardSettingsCollapsed={fieldCollapsed}
          >
            {paymentPlansPermissions?.map(item => {
              return renderCheckboxElement(
                selectedItems.has(item.id),
                'payments-plans-permission',
                item.id,
                item.name,
                item.description,
                () =>
                  handleChange(
                    item.id,
                    selectAllPermissions,
                    setSelectedItems,
                    selectedItems
                  )
              );
            })}
          </CollapsibleSection>
        </div>

        <div className="column">
          <div className="control section">
            <CollapseButton
              name={'generalLedger'}
              dashboardSettingsCollapsed={fieldCollapsed}
              collapseSection={collapseSection}
            />
            {labels.generalLedger}
          </div>
          <CollapsibleSection
            name={'generalLedger'}
            dashboardSettingsCollapsed={fieldCollapsed}
          >
            {generalLegderPermissions?.map(item => {
              return renderCheckboxElement(
                selectedItems.has(item.id),
                'general-ledger-permission',
                item.id,
                item.name,
                item.description,
                () =>
                  handleChange(
                    item.id,
                    selectAllPermissions,
                    setSelectedItems,
                    selectedItems
                  )
              );
            })}
          </CollapsibleSection>
        </div>
        <div className="column">
          <div className="control section">
            <CollapseButton
              name={'psafeAdmin'}
              dashboardSettingsCollapsed={fieldCollapsed}
              collapseSection={collapseSection}
            />
            {labels.pSafeAdmin}
          </div>
          <CollapsibleSection
            name={'psafeAdmin'}
            dashboardSettingsCollapsed={fieldCollapsed}
          >
            {pSafeAdminPermissions?.map(item => {
              return renderCheckboxElement(
                selectedItems.has(item.id),
                'psafe-admin-permission',
                item.id,
                item.name,
                item.description,
                () =>
                  handleChange(
                    item.id,
                    selectAllPermissions,
                    setSelectedItems,
                    selectedItems
                  )
              );
            })}
          </CollapsibleSection>
        </div>
        <div className="w-100" />
        <div className="column" style={{ paddingTop: '20px' }}>
          <div className="control section">
            <CollapseButton
              name={'epic'}
              dashboardSettingsCollapsed={fieldCollapsed}
              collapseSection={collapseSection}
            />
            {labels.epic}
          </div>
          <CollapsibleSection
            name={'epic'}
            dashboardSettingsCollapsed={fieldCollapsed}
          >
            {epicPermissions?.map(item => {
              return renderCheckboxElement(
                selectedItems.has(item.id),
                'epic-permission',
                item.id,
                item.name,
                item.description,
                () =>
                  handleChange(
                    item.id,
                    selectAllPermissions,
                    setSelectedItems,
                    selectedItems
                  )
              );
            })}
          </CollapsibleSection>
        </div>
      </div>

      <div className="button-section">
        <EDS_Button
          modifiers={'eds-button eds-button.primary button-reverse'}
          buttonText={labels.applyButton}
          disabled={selectedItems.size === 0 || showUserSection}
          onClick={() => setShowUserSection(true)}
          iconName={'chevron_right'}
        />
      </div>

      {showUserSection ? (
        <div>
          <p className="permission-header">{labels.users}</p>
          <div className='selection-container extra-wide-inputs'>
            {showCustomOrganizationSelector()}
            <OrganizationUserSearch
              {...{ facilities }}
              tradingPartner={selectedOrganization?.value ?? ''}
              permissionSection={true}
              state={state}
              setState={organizationUserState =>
                dispatch(
                  setSimpleSearchInfoState({ ...state, ...organizationUserState })
                )
              }
            />
          </div>

          <label className="show-users-checkbox">
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={''}
              disabled={showUserTable}
              checked={showSelectedUsers}
              onChange={() => setShowSelectedUsers(prevState => !prevState)}
            />
            <span className={`eds-checkbox_#label`}>
              {labels.showSelectedPermissions}
            </span>
          </label>

          <EDS_Button
            modifiers={
              'eds-button eds-button.primary button-reverse submit-button'
            }
            buttonText={labels.submit}
            disabled={showUserTable}
            onClick={() => setShowUserTable(true)}
          />

          <EDS_Button
            modifiers={
              'eds-button eds-button.basic button-reverse clear-button'
            }
            buttonText={labels.clear}
            disabled={false}
            onClick={clearAllDropdowns}
          />
        </div>
      ) : (
        <></>
      )}
      {showUserTable ? (
        <div className="user-table">
          <UserSelector
            users={users?.filter(user => user?.isActive)}
            permissionSection={true}
            headers={headerDefinitions}
            permissions={excludeCheckAllPermissions(selectedPermissions)}
            facilities={state.facilities.map(facility => facility.value)}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export const excludeCheckAllPermissions = (selectedPermissions: (PermissionItem | undefined)[]) => {
  const excludedIds = ['checkAllPayment', 'checkAllPaymentPlans', 'checkAllGeneralLedger', 'checkAllPSafeAdmin', 'checkAllEpic'];

  return selectedPermissions.filter(per => per?.id && !excludedIds.includes(per.id));
}

export function handleSelectAllPermissions(
  newSelectedItems: Set<unknown>,
  itemKey?: string,
  paymentPermissions?: PermissionItem[],
  paymentPlansPermissions?: PermissionItem[],
  generalLegderPermissions?: PermissionItem[],
  pSafeAdminPermissions?: PermissionItem[],
  epicPermissions?: PermissionItem[]
) {
  let permissions: PermissionItem[] | undefined = [];

  switch (itemKey) { 
    case 'checkAllPayment':
      permissions = paymentPermissions;
      break;
    case 'checkAllPaymentPlans':
      permissions = paymentPlansPermissions;
      break;
    case 'checkAllGeneralLedger':
      permissions = generalLegderPermissions;
      break;
    case 'checkAllPSafeAdmin':
      permissions = pSafeAdminPermissions;
      break;
    case 'checkAllEpic':
      permissions = epicPermissions;
      break;
  }

  if (newSelectedItems.has(itemKey)) {
    permissions?.forEach(item => {
      newSelectedItems.delete(item.id);
    });
  } else {
    permissions?.forEach(item => {
      newSelectedItems.add(item.id);
    });
  }

  return newSelectedItems;
}

export function handleChange(
  itemKey?: string,
  selectAllPermissions?: any,
  setSelectedItems?: any,
  selectedItems?: any
) {
  const newSelectedItems = new Set(selectedItems);
  if (
    itemKey == 'checkAllPayment' ||
    itemKey == 'checkAllPaymentPlans' ||
    itemKey == 'checkAllGeneralLedger' ||
    itemKey == 'checkAllPSafeAdmin' ||
    itemKey == 'checkAllEpic'
  ) {
    selectAllPermissions(newSelectedItems, itemKey);
  } else {
    if (!newSelectedItems.has(itemKey)) {
      newSelectedItems.add(itemKey);
    } else {
      newSelectedItems.delete(itemKey);
    }
    setSelectedItems(newSelectedItems);
  }
}

export function renderCheckboxElement(
  isChecked: boolean,
  inputName?: string,
  itemId?: string,
  itemName?: string,
  itemDescription?: string,
  handleChange?: any
) {
  return (
    <div key={itemId}>
      <EDS_Checkbox
        modifiers="row-item row-item-size checkbox-columns management-checkbox"
        name={inputName}
        label={itemDescription ? itemDescription : itemName}
        onChange={() => handleChange()}
        checked={isChecked}
      ></EDS_Checkbox>
    </div>
  );
}
