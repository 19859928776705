import { TransactionDetailsModel } from "models/TransactionDetailsModel"
import { isSuccessedTransaction } from 'utils/Utils';

export default function isTransactionAmountValid(transaction: TransactionDetailsModel | undefined){
  const completedRelatedTransactions= transaction?.relatedTransactions?.filter(
    relatedTansaction => isSuccessedTransaction(relatedTansaction.statusType)
  )
  const totalAmountOfRelatedTransactions = completedRelatedTransactions?.reduce(
    (sum, relatedTansaction) => sum + Number(relatedTansaction.amount || 0),
    0)
  if(transaction?.amount && totalAmountOfRelatedTransactions) {
    if (transaction?.amount - totalAmountOfRelatedTransactions <= 0) {
      return true
    }
    else return false
  } 
}