import { useDispatch } from "react-redux";
import { PatientPanelApiConfiguration, updatePatientPanelConfigurationDraftService, updatePatientPanelConfigurationService } from "../../../../../services/PatientPanelService";
import { AlertIds, AlertTypes, setAlert } from "../../../../alert/AlertReducer";
import { Dashboards } from "../../ConfigurationSelector";
import { saveConfiguration } from "../../PaymentPanelReducer";
import { mapPatientPanelConfiguration } from "../mapPaymentPanelConfiguration";

export function useUpdatePatientPanelConfiguration(key?: keyof Dashboards) {
  const dispatch = useDispatch();
  async function updatePatientPanelConfiguration(configuration?: PatientPanelApiConfiguration) {
    const data = verifyAttributes(configuration);
    if(data) {
      const response = await updatePatientPanelConfigurationService(configuration);
      saveUpdatedConfiguration(response);
    }
  }

  async function updatePatientPanelConfigurationDraft(configuration?: PatientPanelApiConfiguration) {
    const data = verifyAttributes(configuration);
    if(data) {
      const response = await updatePatientPanelConfigurationDraftService(configuration);
      saveUpdatedConfiguration(response);
    }
  }

  function saveUpdatedConfiguration(response?: { result?: PatientPanelApiConfiguration, err?: any, errorResponse?: any }) {
    const configuration = response?.result;
    key && configuration && dispatch(saveConfiguration({
      key, configuration: mapPatientPanelConfiguration(key, configuration)
    }));

    if (response?.err) {
      const validationErrs = response?.errorResponse?.data?.validationErrors;
      if (validationErrs?.length) {
        validationErrs.forEach((x: any, i: number) => {
          if (x.errorMessage)
            dispatch(setAlert(getErrorAlert(x.errorMessage.replace(/[^a-zA-Z ]/g, ""), i)));
        })
      }
      else {
        dispatch(setAlert(getErrorAlert(response.err)));
      }
    } else {
      dispatch(setAlert(getSuccessAlert("Successfully updated Patient Panel Configuration")));
    }
  }

  function verifyAttributes (configuration?: PatientPanelApiConfiguration) {
    if (configuration) {
      const fieldSettings = configuration.fieldSettings ?? [];

      for(const attr of fieldSettings) {
        if(!attr.saveAs || attr.saveAs.includes('undefined')) {
          dispatch(setAlert(getErrorAlert('Save as should not be empty')));
          return false;
        }
      }
      return true;
    }
  }

  return {
    updatePatientPanelConfiguration,
    updatePatientPanelConfigurationDraft
  };
}

function getErrorAlert(message: string, index?: number) {
  return {
    id: AlertIds.PaymentDashboardConfiguration + (index ? "_" + index : ""),
    type: AlertTypes.Error,
    message,
    dismissable: false
  }
}

function getSuccessAlert(message: string) {
  return {
    id: AlertIds.PaymentDashboardConfiguration,
    type: AlertTypes.Success,
    message,
    dismissable: true
  }
}