export enum EnumProcessTransactionType {
  NotSet = 0,
  Unknown = 1,
  Card = 2,
  ACH = 3,
  Cash = 4,
  MoneyOrder = 5,
  PaperCheck = 6
}

export default EnumProcessTransactionType;
