import '../../assets/styles/components/_dashBoards.scss';
import Bills from '../paymentPlan/Bills';
import { useTypedSelector } from '../../app/rootReducer';
import { Wizard } from '../wizard/Wizard';
import { setPlanPanelConfiguration, updateRecord, setOpenedPlanId } from '../paymentPlan/PaymentPlanReducer';
import { FullOrganizationLevelDocument } from '../../models/OrganizationLevelDocument';
import { getWizardSteps } from '../wizards/PaymentWizard';
import useGetConfiguration from "../admin/paymentPanel/serviceHandlers/useGetConfiguration";
import { useOrganizations } from '../organizations/hooks/useOrganizations';
import PaymentPlanModel, { OrganizationReference } from '../../models/PaymentPlan';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addPaymentDashboardState } from 'features/paymentDashboard/PaymentDashboardReducer';
import { EDS_Accordion } from '@EH/eh.eds.react';
import { useUserUtils } from 'utils/useUserUtils';
import { getUserDepartmentOrFacilityesOptions } from 'utils/UtilsOrganizationDropdowns';
import { useGetPatientById } from 'features/patients/hooks/useGetPatientById';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import { PaymentPlanSearchModel, getPaymentPlanSearchService } from 'services/PaymentPlanService';
import { PopoverElement } from 'pages/Admin/OrganizationUserManagement/OrganizationUserManagement';
import { setOpenPatientInfoModal } from 'features/patients/PatientsReducer';
import { setPatientInfoState } from 'features/patientInfo/PatientInfoReducer';
import { getPatientInformation } from 'utils/Utils';
import { Button } from 'reactstrap';
import { usePahAlert } from './hooks/usePahAlert';

export default function PaymentPlanDashboard(props: { patientId?: string, isGl?: boolean, wizard: Wizard, selectedFacility: FullOrganizationLevelDocument, paymentType: boolean, isPreview?: boolean,planConfiguration?:any, typedAccountNumber?: string, accountNumberFound?: boolean }) {
  const { isGl, wizard, patientId, selectedFacility, paymentType, isPreview = false, planConfiguration, typedAccountNumber, accountNumberFound } = props;
  const { getLoggedUserOrganizations } = useUserUtils();
  const userOrganizations = getLoggedUserOrganizations();
  const organizations = useTypedSelector(s => s.organizations?.value);
  let userFacilityOptions = getUserDepartmentOrFacilityesOptions(organizations, userOrganizations);
  userFacilityOptions = userFacilityOptions?.filter(o => o.organizationLevelType == OrganizationLevelTypes.Facility);
  const { patient } = useGetPatientById(patientId);
  const [plansInOtherFacilities, setPlansInOtherFacilities] = useState<FullOrganizationLevelDocument[]>([]);
  const [retrievedPlansInOtherFacilities, setRetrievedPlansInOtherFacilities] = useState<PaymentPlanModel[]>([]);
  const [accordionAlreadyAssignedPlan, setAccordionAlreadyAssignedPlan] = useState(false);
  const { havePermission } = useLoggedUserPermissions();
  const { getErrorMessage } = usePahAlert();
  const paymentDashboardValues = useTypedSelector(s => s.paymentDashboard?.values);
  
  const organizationPath = selectedFacility.path;

  const loadedPlanPanelConfiguration = useTypedSelector(s => (s.paymentPlanInfo?.planPanelConfigurations?.[organizationPath]?.configuration));
  const name: string = isPreview ? loadedPlanPanelConfiguration?.entity?.panelTitle as string : selectedFacility.name;
  const selectedOrganization: OrganizationReference = { name: name, path: selectedFacility.path, id: selectedFacility.id };

  const { getPanelConfiguration } = useGetConfiguration(organizationPath);
  const { useGetOrganizationById } = useOrganizations();

  const GetClientId = (organizationId?: string) => {
    return useGetOrganizationById(organizationId || '')?.clientId;
  }

  const panelConfiguration = useTypedSelector(s => (s.paymentPanel.configurations['PaymentPlanDashboard']))

  useMemo(() => {

    return getPanelConfiguration("PaymentPlanDashboard");

  }, [loadedPlanPanelConfiguration, patientId]);

  const dispatch = useDispatch();

  useMemo(() => {

    if (panelConfiguration && Object.keys(panelConfiguration).length && !loadedPlanPanelConfiguration) {
      dispatch(
        setPlanPanelConfiguration({ organizationPath: organizationPath ?? '', configuration: { ...panelConfiguration } })
      );
    }

  }, [panelConfiguration]);

  const mappedFacilities = useMemo(() => {

    const facilities = [{ organization: selectedOrganization }];

    if (loadedPlanPanelConfiguration?.mappings?.length && loadedPlanPanelConfiguration?.mappings?.length != 0)
      return facilities.concat(loadedPlanPanelConfiguration?.mappings as ({ organization: OrganizationReference }[]))
    else
      return facilities;

  }, [loadedPlanPanelConfiguration]);

  useEffect(() => {
    mappedFacilities?.forEach(facility => {
      dispatch(addPaymentDashboardState({ value: { paymentTotal: 0, lineItems: [], isGl: Boolean(isGl) }, organizationPath: facility?.organization?.path ?? '' }));
    });
  }, [mappedFacilities]);

  const getAddRowEnabled = () => {
    if (loadedPlanPanelConfiguration && loadedPlanPanelConfiguration.entity) {
      return loadedPlanPanelConfiguration.entity.addRowEnabled;
    }
  }

  const mappedFacilitiesDashboards = useMemo(() => mappedFacilities?.map((mapped, index) =>
   {
    const key = `paymentPlan-bills-${index}`;

    if(planConfiguration){
      const newFacility = planConfiguration.find((a:any)=> a.organization.path === mapped.organization.path);
      if(newFacility?.disablePlansRecord != null){
        return(
          <div className='disabled-container' key={key}>
            <span className='title-label'>{mapped?.organization?.name}</span>
            <span className='disabled-label'>This facility has not been configured to create payment plans</span>
            </div>);
      }
    }
    return (
      loadedPlanPanelConfiguration ?
        <Bills
          key={key}
          isGl={isGl}
          wizard={wizard}
          patientId={patientId}
          clientId={GetClientId(mapped?.organization?.id)}
          organizationPath={mapped?.organization?.path}
          organizationName={mapped?.organization?.name}
          onContinue={() => wizard.selectStep(getWizardSteps(false)[5])}
          isPayment={paymentType}
          isPreview={isPreview}
          addRowEnabled={getAddRowEnabled()}
          typedAccountNumber={typedAccountNumber}
          accountNumberFound={accountNumberFound}
        /> : <></>
    );

  }), [organizationPath, mappedFacilities, paymentType, patientId]);
  
  const getPaymentPlansFromOtherFacilities = async (selectedPlanSearchModel: PaymentPlanSearchModel) => {
    await getPaymentPlanSearchService(selectedPlanSearchModel).then((response) => {
      if (response.result) {
        setRetrievedPlansInOtherFacilities(response?.result?.records);
      } else if(response.errorResponse && response.errorResponse.status === 500) {
        getErrorMessage();
      }
    })
  }

  const openPatientInfoPlan = (currentFacility?: FullOrganizationLevelDocument) => {
    const chosenPatient = getPatientInformation(currentFacility, patient);
    dispatch(
      setPatientInfoState({
        ...chosenPatient
      })
    );
    localStorage.setItem('shouldRedirectToPlans', `true`);
    dispatch(setOpenPatientInfoModal(true));
  };

  const  planExistsInFacilities = (itemId?: string) => {
    let result = false;
    mappedFacilities.forEach((currentFacility) => {
      paymentDashboardValues[currentFacility?.organization?.path]?.lineItems?.forEach((lineItem) => {
        if (lineItem?.id?.toString()?.toUpperCase() === itemId?.toString()?.toUpperCase()) {
          result = true;
          return result;
        }
      });
    });
    return result;
  }

  function onClickAccordionPlan() {
    if (userFacilityOptions?.length > 0 && !accordionAlreadyAssignedPlan) {
      const filteredplansInOtherFacilities =  userFacilityOptions.filter((u: any) => mappedFacilities.every(x => x.organization.id !== u.id && (havePermission(UserPermissions.MakePayment, u?.path)) && (havePermission(UserPermissions.CreatePaymentPlan, u?.path))));
      setPlansInOtherFacilities(filteredplansInOtherFacilities);
      const organizationPaths = filteredplansInOtherFacilities.map((facility: FullOrganizationLevelDocument) => facility.path);
      const selectedPlanSearchModel: PaymentPlanSearchModel = { "organizationPaths": organizationPaths, "patientId": [ patientId ?? '' ], "status": ["Active","Paused"] }
      getPaymentPlansFromOtherFacilities(selectedPlanSearchModel);
      setAccordionAlreadyAssignedPlan(true);
    }
  }

  const  displayLineElementPlan = (mapped: PaymentPlanModel[]) => {
    return <>
      {mapped?.map((valuePlanEncounter) => {
        const isInFacility = planExistsInFacilities(valuePlanEncounter.id ?? '');
        return(isInFacility ? <></> :      
        <div key={`${valuePlanEncounter.id}`} className='line-item line-item-plan bg-light'>
             <div className='line-item-attribute line-item-attribute-plan'>
                <p className='line-item-attribute-label'>Plan ID</p>
                <p><a className={'link-transaction-id'} href={`/planDetails/${valuePlanEncounter.id}`} target={"_blank"} rel="noreferrer" onClick={(e: any) => {
                    e.preventDefault();
                    dispatch(updateRecord({ id: valuePlanEncounter?.id, paymentPlan: { ...valuePlanEncounter } }))
                    dispatch(setOpenedPlanId(valuePlanEncounter?.id ?? ``));
                  }}>{valuePlanEncounter.id ?? ''}
                  </a>
                </p>
              </div>
            <div className="line-item-attribute line-item-attribute-plan">
              <p className="line-item-attribute-label">Facility Name</p>
              <p>{valuePlanEncounter?.organization?.name}</p>
            </div>
            <div className="line-item-attribute line-item-attribute-plan">
              <p className="line-item-attribute-label">Department Name</p>
              <p>{valuePlanEncounter?.department?.name}</p>
            </div>
            <div className="line-item-attribute line-item-attribute-plan">
              <p className="line-item-attribute-label">Balance</p>
              <p>{valuePlanEncounter?.balance}</p>
            </div>
            <div className="line-item-attribute line-item-attribute-plan">
              <p className="line-item-attribute-label">Payment Amount</p>
              <p>{`$${valuePlanEncounter?.amount}`}</p>
            </div>
        </div>
        )
      })}
    </>
  }

  const paymentsInOtherFacilitiesContentPlan = () => {
    return <>
      {plansInOtherFacilities?.map((mapped, index) => {
        const key = `plans-in-other-facilities-${index}`;
        const facilityName = mapped.name;
        const plansForCurrentFacility = retrievedPlansInOtherFacilities?.filter((plan) => plan?.organization?.path === mapped.path);
        return (plansForCurrentFacility.length > 0 ?
          <div key={key}>
            <div className='payments-in-other-facilities__header'>
              <div>
                <span>{facilityName}</span>
                <span className="help">
                  <PopoverElement>
                    To make payments to this facility, navigate to Access Records.
                  </PopoverElement>
                </span>
              </div>
              <button
                type="button"
                className="eds-button eds-button.basic mr-2"
                onClick={() => {
                  openPatientInfoPlan(mapped);
                }}
              >
                <i className="material-icons eds-button_#icon">search</i>
                <span className="eds-button_#label">Access Records</span>
              </button>
            </div>
            <div className='payments-in-other-facilities__body'>
              {displayLineElementPlan(plansForCurrentFacility)}
            </div>
          </div>
          :
          <></>
        );})}
    </>
  }

  const accordionPlansInOtherFacilities = () => {
    return (
      <EDS_Accordion
        accordionItems={[
          {
            summary: (
              <Button className={'payment-in-other-facilities-button'}
                typeof='button'
                onClick={() => { onClickAccordionPlan() }}>
                Active plans in other facilities
              </Button>
            ),
            isOpen: false,
            content: paymentsInOtherFacilitiesContentPlan(),
          },
        ]}
        modifiers="payments-in-other-facilities"
      />
    );
  }
  
  return <>
    {mappedFacilitiesDashboards}
    {!isGl ? <> {accordionPlansInOtherFacilities()} </> : <></>}
  </>;
}