export enum EnumTransactionTenderType {
  NotSet = 'NotSet',
  Unknown = 'Unknown',
  Card = 'Card',
  eCheck = 'eCheck',
  Cash = 'Cash',
  MoneyOrder = 'MoneyOrder',
  PaperCheck = 'PaperCheck' 
}

export default EnumTransactionTenderType;