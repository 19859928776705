import { useHistory } from 'utils/useHistory';
import { OrganizationLevelDocument } from '../../../models/OrganizationLevelDocument';
import { TradingPartnerMultiselectOption, useGetFacilitesByTradingPartnerOptions, useGetOrganizationOptionWithTradingPartner } from '../../../pages/Search/simpleSearch/OrganizationUserSearch';
import MultiSelect from '../../../components/select/MultiSelect';
import * as router from 'react-router-dom';
import { useOrganizations } from '../../organizations/hooks/useOrganizations';
import OrganizationLevelTypes from '../../../models/enums/OrganizationLevelTypes';
import { useGetPaymentConfiguration } from 'features/singlePayment/hooks/useGetPaymentConfiguration';
import { useEffect } from 'react';

export const getLabel = (organization: OrganizationLevelDocument) => `(${organization.clientId}) ${organization.name}`;
export default function SelectClient() {
  let history = useHistory();
  let location = router.useLocation()
  let { clientId } = router.useParams<{ clientId: string }>();
  const { getOrganizationById, getFacilityByPath, getFacilityWithFallback } = useOrganizations();
  let selectedOrganization = getOrganizationById(clientId);
  if (selectedOrganization?.organizationLevelType !== OrganizationLevelTypes.Facility) {
    selectedOrganization = getFacilityWithFallback(selectedOrganization?.path);
  }
  const facilitiesOptions = useGetFacilitesByTradingPartnerOptions(getLabel, selectedOrganization);
  const getTradingPartnerOption = useGetOrganizationOptionWithTradingPartner(getLabel);
  const { getConfiguration } = useGetPaymentConfiguration(selectedOrganization?.path ?? '');
  useEffect(() => {getConfiguration()}, [selectedOrganization?.path])

  return <div className="actions">
    <MultiSelect
      label={"Select Facility:"}
      name={"user"}
      options={facilitiesOptions}
      onChange={(option?: TradingPartnerMultiselectOption | null) => {
        if (option) {
          const organization = getFacilityByPath(option.value);
          if (organization && clientId) {
            const newPath = location.pathname.replace(clientId, organization.id);
            history.push(newPath);
          }
        }
      }}
      values={selectedOrganization ? getTradingPartnerOption(selectedOrganization) : null}
      groupBy={(o: TradingPartnerMultiselectOption) => o.tradingPartnerName.toUpperCase()}
      multiple={false}
      wider={true}
    />
  </div>
}