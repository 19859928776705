import { PaymentPanelFieldType } from "models/enums/EnumPaymentPanels";
import { DataType } from "models/metaData/MetaDataEnums";
import { useDispatch } from "react-redux";
import { getPatientPanelConfigurationService, PatientPanelApiConfiguration } from "../../../../services/PatientPanelService";
import { Dashboards } from "../ConfigurationSelector"
import { savePatientConfiguration } from "../PaymentPanelReducer";

export default function useGetPatientConfiguration(organizationPath?: string) {
  const dispatch = useDispatch();
  async function getConfiguration(key: keyof Dashboards) {
    const services: { [key in keyof Dashboards]: () => Promise<PatientPanelApiConfiguration | undefined> } = {
      PatientSearchPopupDashboard: async () => {
        const response = await getPatientPanelConfigurationService(false, organizationPath ?? '');
        const { configuration } = getLiveConfiguration(response.result);
        return configuration;
      },
      PatientSearchPopupDashboardDraft: async () => {
        const response = await getPatientPanelConfigurationService(true, organizationPath ?? '');
        const { draftConfiguration } = getDraftConfiguration(response.result);
        return draftConfiguration;
      },
    }

    const service = services[key];
    if (service) {
      const configuration = await service();
      configuration && dispatch(savePatientConfiguration({
        key, configuration: {
          ...configuration,
          attributes: configuration.fieldSettings?.filter(f => f.fieldType != PaymentPanelFieldType.Hidden).map(f => ({
             ...f,
             name: f.saveAs ?? "lastName",
             dataType: DataType.String,
          })),
        }
      }))
    }
  }

  return {
    getConfiguration
  }
}

function getLiveConfiguration(configuration?: PatientPanelApiConfiguration) {
  const ids = {
    draftId: '',
    liveId: configuration?.id
  };

  return {
    configuration: { ...configuration, ...ids, fieldSettings: configuration?.fieldSettings ?? [] },
    draftConfiguration: { },
  };
}

function getDraftConfiguration(draftConfiguration?: PatientPanelApiConfiguration) {
  const ids = {
    draftId: draftConfiguration?.id,
    liveId:''
  };

  return {
    configuration: {},
    draftConfiguration: { ...draftConfiguration, ...ids, fieldSettings: draftConfiguration?.fieldSettings ?? [] },
  };
}