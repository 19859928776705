
import { callHttpApi } from './callHttpApi';
import { getLocalTimezone } from 'utils/UtilsDateTime';

export async function GetPaymentPlanReceipt(PaymentPlanId: string, Language: string):Promise<any> {
  const params = new URLSearchParams();
  params.append('PaymentPlanId', PaymentPlanId)
  params.append('Language', Language)
  params.append('LocalTimeZone', getLocalTimezone())
  return callHttpApi<any>(
    'get',
    `/api/v1/PaymentPlans/Receipt`,
    undefined,
    params,
    {},
    'blob'
  );
  }
  export async function GetPaymentReceipt(TransactionId: string, Language: string) :Promise<any>{
    const params = new URLSearchParams();
    params.append('TransactionId', TransactionId)
    params.append('Language', Language)
    params.append('LocalTimeZone', getLocalTimezone())
    return callHttpApi<any>(
      'get',
      `/api/v1/Transactions/Receipt`,
      undefined,
      params,
      {},
      'blob'
    );
  }

  