import { GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useState } from "react";
import { Popup } from "../../../components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType, EnumPopupType } from "../../../models/enums/EnumPopupType";
import { OrganizationLevelDocument } from "../../../models/OrganizationLevelDocument";
import PaymentPlanModel, { TenderInfo } from "../../../models/PaymentPlan";
import { nameof } from "../../../utils/Utils";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '@experian/eds-styles/dist/eds-all.css';
import { setSelectedPlans } from "../PaymentPlanReducer";
import { useDispatch } from "react-redux";
import { Radio } from "@EHDS/core";
import { EnumPlanStatus } from "models/enums/EnumPaymentPlan";
import { setIsScreenDisabled } from "features/paymentDashboard/PaymentDashboardReducer";


export function PaymentPlansSelectionModal(props: {
  isOpen: boolean,
  onClose: () => void,
  organization?: OrganizationLevelDocument,
  paymentPlans?: PaymentPlanModel[],
  onSubmit:(selectedPlan: PaymentPlanModel) => void,
}) {
  const { isOpen, onClose, organization, paymentPlans, onSubmit } = props;
  const [selected, setSelected] = useState<PaymentPlanModel>();
  const activePlans = paymentPlans?.filter(x=>x.status==EnumPlanStatus.Active);
  const organizationName = paymentPlans != undefined && paymentPlans?.length > 0 ? paymentPlans[0].organization?.name : '';
  const dispatch = useDispatch();
  function onGridReady(params: GridReadyEvent) {
    if (paymentPlans) {
      params.api.setRowData(activePlans ?? []);
      params.api.sizeColumnsToFit();
    }
  }

  const footerButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel',
      disabled: false
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Add to Plan',
      disabled: !selected
    },
  ];

  function RadioButtonCell(params: {
    value: TenderInfo;
    data: PaymentPlanModel;
    
  }) {
  
    let { data } = params;

    const onChange = () => {
      setSelected(data);
      dispatch(setSelectedPlans([data.id]))
    };
    
    return RadioButtonComponent({data, onChange})
  }

  return isOpen ?
    <Popup
      header="PaymentSafe®"
      type={EnumPopupType.basic}
      onClose={onClose}
      customClassNames={{ container: "large-popup" }}
      footer={footerButtons}
      onSubmit={() => {selected && onSubmit(selected); dispatch(setIsScreenDisabled(true));}}
    >
      <div className={"eds-heading eds-heading.mdplus-caps mb-1"}>
        {`PLANS FOR ${organizationName}`}
      </div>
      {activePlans ?
        <div className={'section ag-theme-alpine'}>
          <AgGridReact
            frameworkComponents={{
              MethodCell: MethodCell,
              RadioButtonCell: RadioButtonCell
            }}
            columnDefs={headerDefinitions}
            onGridReady={onGridReady}
            domLayout={'autoHeight'}
            rowSelection={'single'}
            suppressRowClickSelection={true}
            onSelectionChanged={(e: SelectionChangedEvent) => {
              const plan = e.api.getSelectedRows().map((plan: PaymentPlanModel) => plan)[0];
              setSelected(plan);
              dispatch(setSelectedPlans([plan.id]))
            }}
            suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
            disableStaticMarkup={true}
          />
        </div> : "No active plans available"}
    </Popup> : null
}

const headerDefinitions = [
  {
    headerName: 'Plan ID',
    field: nameof<PaymentPlanModel>('id'),
    resizable: true,
    minWidth: 180,
    cellRenderer: 'RadioButtonCell',
  },
  {
    headerName: 'Plan Balance',
    minWidth: 120,
    field: nameof<PaymentPlanModel>('balance'),
  },
  {
    headerName: 'Payment Amount',
    minWidth: 150,
    field: nameof<PaymentPlanModel>('paymentAmount'),
  },
  {
    headerName: 'Plan Term Type',
    minWidth: 140,
    field: nameof<PaymentPlanModel>('termType'),
  },
  {
    headerName: 'Payments Remaining',
    minWidth: 180,
    field: nameof<PaymentPlanModel>('paymentsRemaining'),
  },
  {
    headerName: 'Payment method',
    minWidth: 150,
    field: nameof<PaymentPlanModel>('tenderAdditionalData'),
    cellRenderer: 'MethodCell',
  },
];

export function MethodCell(params: {
  value: TenderInfo;
  data: PaymentPlanModel;
}) {

  let { data } = params

  return (
    <span>
      {data.tenderMaskedAccount ? `...${data.tenderMaskedAccount.slice(-4)}` : "-"}
    </span> 
  );
}

export function RadioButtonComponent(params: {
  data: PaymentPlanModel;
  onChange: () => void;
}) {

  let { data, onChange } = params

  return (
    <div>
      <Radio
        name="paymentPlan"
        value={data.id || ""}
        onChange={onChange}
      />
      <span>{data.id}</span>
    </div>
  );
}