import { useState, useCallback, useEffect } from "react";
import {
  Query,
  Builder,
  JsonGroup,
  Config,
  ImmutableTree,
  BuilderProps,
  Utils
} from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/styles.css";
import "antd/dist/antd.css";
import "../../../../../assets/styles/components/_rowfilterform.scss"
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import { useTypedSelector } from "../../../../../app/rootReducer";
import { RowFilterFieldTypes } from "./RowFilterFieldTypes";
import { Button } from "@EHDS/core";
const InitialConfig = AntdConfig;
//@ts-ignore
let config: Config = {
  ...InitialConfig,
  fields: {}
};

//Leaving this for reference
//TODO remove code with ticket #(will be added when created)
//{
//  confirmation: {
//    label: "Confirmation",
//      type: "treemultiselect",
//        fieldSettings: {
//      listValues: [
//        {
//          title: "all", value: "all", children: [
//            { title: "1924171693", value: "1924171693" },
//            { title: "1920594689", value: "1920594689" },
//            { title: "1922949787", value: "1922949787" },
//          ]
//        }
//      ]
//    }
//  },
//}

config.operators.starts_with.jsonLogic = 'starts_with';
config.operators.ends_with.jsonLogic = 'ends_with';

const createOperator = (
  label: string,
  labelForFormat: string,
  jsonLogic: string,
  reversedOp?: string
) => ({
  label,
  reversedOp,
  labelForFormat,
  jsonLogic,
  cardinality: 1,
});

config.operators.length = createOperator(
  'length',
  'Length',
  'length',
  'not_equal'
);
config.types.text.widgets.text.operators?.push('length');

config.operators.length_greater_than = createOperator(
  'length greater than',
  'Length greater than',
  'length_greater_than'
);
config.types.text.widgets.text.operators?.push('length_less_than');

config.operators.length = createOperator(
  'length less than',
  'Length less than',
  'length_less_than'
);
config.types.text.widgets.text.operators?.push('length_greater_than');

export function RowFilterForm(props: {
  onSave: (value: string) => void;
}) {
  const { onSave} = props;
  const fileFieldDescriptions = useTypedSelector(s => s.remitConfiguration.remitFileFieldDescriptions.fileFieldDescriptions)
  const previosValue = useTypedSelector(s => s.remitConfiguration.remitConfiguration.sqlRowFilter);
  const test = useTypedSelector(s => s.remitConfiguration.remitConfiguration);
  const [jsonText, setJsonText] = useState("")
  fileFieldDescriptions?.map(f => {
    config.fields[f.fieldName] = {
      label: f.displayName,
      type: RowFilterFieldTypes[f.fieldName] ?? "text",
    }
  });

  const queryValue: JsonGroup = { id: Utils.uuid(), type: "group" };
  let saved;
  if (previosValue) {
    saved = Utils.loadFromJsonLogic(JSON.parse(previosValue), config);
  }

  const tree = Utils.checkTree(saved ?? Utils.loadTree(queryValue), config);
  const [state, setState] = useState({
    tree,
    config: config
  });

  useEffect(() => {
    const formatted = Utils.jsonLogicFormat(state.tree, state.config);
    setJsonText(JSON.stringify(formatted.logic));
  }, [])

  const onChange = useCallback((immutableTree: ImmutableTree, configuration: Config) => {
    setState(prevState => ({ ...prevState, tree: immutableTree, config: configuration }));
    const formatted = Utils.jsonLogicFormat(immutableTree, configuration);
    setJsonText(JSON.stringify(formatted.logic));
  }, []);

  const onSaveClicked = () => {
    const formatted = Utils.jsonLogicFormat(state.tree, state.config);
    onSave(JSON.stringify(formatted.logic) || "");
  }

  const renderBuilder = useCallback((props: BuilderProps) => (
    <div className="query-builder-container">
      <div className="query-builder">
        <Builder {...props} />
      </div>
    </div>
  ), []);

  return (
    <div>
      <Query
        {...config}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
      <div className="json-preview">
        <span><b>JSON Logic: </b></span>
        {jsonText}
        </div>
      <div className="query-builder-result">
        <Button onClick={onSaveClicked}>Save</Button>
      </div>
    </div>
  );
}