import { useState, useMemo } from 'react';
import createNextState from 'immer';

export interface WizardStep {
  name: string;
  slug?: string;
}

export interface WizardState {
  steps: WizardStep[];
  currentStep?: WizardStep | null;
}

export const initialState: WizardState = {
  steps: [],
  currentStep: null,
};

function isActive(state: WizardState) {
  const currentStep = getCurrentStepIndex(state);
  const numberOfSteps = state.steps.length;
  return currentStep > 0 && currentStep < numberOfSteps - 1;
}

function getCurrentStepIndex(state: WizardState) {
  if (!state.steps) return -1;
  return state.steps.findIndex(s => s.name === state.currentStep?.name);
}

function selectStep(state: WizardState, step: WizardStep | undefined | null) {
  state.currentStep = step;
}

function next(state: WizardState) {
  // if it is null, keep at it
  if (!state.currentStep) {
    state.currentStep = state.steps[0];
    return;
  }
  const currentStepIndex = getCurrentStepIndex(state);

  // if it is the last item, keep at it
  // otherwise go one step next
  if (currentStepIndex < state.steps.length - 1)
    state.currentStep = state.steps[currentStepIndex + 1];
}

function prev(state: WizardState) {
  if (state.currentStep) {
    const currentStepIndex = getCurrentStepIndex(state);

    // if it is the first item, keep at it
    if (currentStepIndex === 0) {
      // state.currentStep = null;
      return;
    }
    // otherwise go one step back
    state.currentStep = state.steps[currentStepIndex - 1];
  }
}

/**
 * Custom react hook that maintains state of type {@link WizardState}
 * and exposes functionality to interact with it
 * @param initState The state that the wizard's internal state is set to initially
 * @returns an object of type {@link Wizard}
 */
export let useWizard = (initState?: WizardState) => {
  const [state, setState] = useState(initState || initialState);

  const wizard = useMemo(() => {
    const setImmutableState = (fn: (s: WizardState) => void) => {
      setState(prevState =>
        createNextState(state, state => {
          fn(state);
        })
      );
    };

    return {
      state,
      selectStep: (step: WizardStep | undefined | null) =>
        setImmutableState(s => selectStep(s, step)),
      next: () => setImmutableState(s => next(s)),
      prev: () => setImmutableState(s => prev(s)),
      getCurrentStepIndex: () => getCurrentStepIndex(state),
      isActive: () => isActive(state),
    };
  }, [state]);
  return wizard;
};

export type Wizard = ReturnType<typeof useWizard>;
