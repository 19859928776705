import { useState } from 'react';
import { EDS_Accordion } from '@EH/eh.eds.react';
import { sortAsc } from 'utils/Utils';
import { DataType } from './SearchResultsTemplate';
import 'assets/styles/components/_searchPlansResults.scss';

export const SHOW_RESULTS_LIMIT = 10;

export function Header(props: Readonly<{
  data: DataType[];
  filterData?: any;
  columnsData?: any;
}>) {
  const { data, filterData, columnsData } = props;
  const [showSearchResultsHeaderPopup, setShowSearchResultsHeaderPopup] = useState('');

  function renderData(headerData: DataType[]) {
    if (!headerData.length) return null;
    return headerData.map((row: DataType, index: number) => {
      return getRendererData(row, index);
    })
  }
  
  function getRendererData(row: DataType, index: number) {
      if(row.field === 'Batch ID') return null;
      const itemsCount = row.value?.split(', ').length;
      return (
        row.value && <div className="flex-row" key={index}>
          {row.field && <div className="field-title">{row.field}:</div>}
          <div
            className={`field-value${row.type ? ' search-criteria-' + row.type : ''}`}
            onClick={row.type === 'popup' ? () => setShowSearchResultsHeaderPopup(row.value ?? '') : undefined}
            >
              {row.type === 'popup' && itemsCount && itemsCount > SHOW_RESULTS_LIMIT
                ? `${row.value?.split(', ').slice(0, SHOW_RESULTS_LIMIT).join(', ')} and ${itemsCount - SHOW_RESULTS_LIMIT} more...`
                : row.value
              }
          </div>
        </div>
      )
  }

  function renderFilteredData(headerData: DataType[]) {
    const filters = Object.entries(headerData).map((e) => ( { [e[0]]: e[1] } ));
    if (!filters.length) return null;
    return filters.map((row: any, index: number) => {
      let key = Object.keys(row)[0];
      let result = columnsData.find((obj : any) => {
        return obj.field === key;
      })
      return (
        row && <div className="flex-row" key={index}>
          {row && <div className="field-filter-title">{result.label}:</div>}
          <div>
              {row[key].filter}{row[key].filterTo && ` -  ${row[key].filterTo}`}
          </div>
        </div>
      )
    })
  }

  const renderSearchResultsHeaderPopup = () => {
    return (
      <div className="notes-popup header-popup" data-testid='search-result-popup'>
        <button
          className="button-close material-icons"
          onClick={() => setShowSearchResultsHeaderPopup('')}
        >
          close
        </button>
        {sortAsc(showSearchResultsHeaderPopup.split(', ')).map((row, index) => <div key={index}>{row};</div>)}
      </div>
    );
  }

  return (
    <div className="header-wrapper">
      {showSearchResultsHeaderPopup && renderSearchResultsHeaderPopup()}
      <EDS_Accordion
        accordionItems={[{
          summary: 'Search criteria',
          isOpen: false,
          content: renderData(data),
        }]}
        modifiers='accordion-search-results'
      />
      {filterData && <EDS_Accordion
        accordionItems={[{
          summary: 'Applied filters',
          isOpen: false,
          content: renderFilteredData(filterData),
        }]}
        modifiers='accordion-search-results'
      />}
    </div>
  );
}
