import { useEffect, useState } from 'react';
import { ReactComponent as Search } from 'assets/svgs/icon-search.svg';
import { ReactComponent as MakePayment } from 'assets/svgs/icon-pymt.svg';
import { ReactComponent as MakeGlPayment } from 'assets/svgs/icon-glpymt.svg';
import { ReactComponent as Settings } from 'assets/svgs/icon-admin.svg';
import { ReactComponent as Home } from 'assets/svgs/icon-home.svg';
import { ReactComponent as Collapse } from 'assets/svgs/collapse.svg';
import { setSidepaneLink } from 'features/sidepane/SidepaneReducer';
import 'assets/styles/components/_sidepane.scss';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTypedSelector } from 'app/rootReducer';
import { setOpenPatientInfoModal } from '../patients/PatientsReducer';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import { resetPatientInfo } from '../patientInfo/PatientInfoReducer';
import { setOpenModal } from 'pages/GLPaymentDashboard/GLPaymentDashboardReducer';
import { OrganizationLevelSummary } from 'models/OrganizationLevelSummary';

interface Props {
  leftMenuWidth?: number;
  organizationLevels?: OrganizationLevelSummary[];
}
export default (props: Props) => {
  const sidepaneState = useTypedSelector(s => s.sidepane?.currentLink);

  let dispatch = useDispatch();
  const [enabledPaymentPermission, setEnabledPaymentPermission] = useState(false);
  const [enabledSearchPermission, setEnabledSearchPermission] = useState(false);
  const [enabledGLPaymentPermission, setEnabledGLPaymentPermission] = useState(false);
  const [enabledAdminPermission, setEnabledAdminPermission] = useState(false);
  const { havePermission, havePaymentPermission, haveGLPaymentPermission, haveSearchPermission, hasPermissionOrSuperAdmin } = useLoggedUserPermissions();
  const sspUserPermission = (org: OrganizationLevelSummary) => hasPermissionOrSuperAdmin(UserPermissions.SelfServicePortal, org.organizationLevel_Path)

  const clickHandler = (value: string) => {
    dispatch(setSidepaneLink(value));
  };

  useEffect(() => {
    checkPermissions(props.organizationLevels ?? []);
  });

  const collapse = () => {
    const sidePanel = document.getElementById('sidepane')!;
    const toggleButton = document.getElementById('toggle-button')!;
    toggleButton.style.transform = 'rotate(180deg)';
    toggleButton.style.transition = 'all 0.3s ease-out';
    sidePanel.style.display = 'none';
    sidePanel.style.transition = 'all 0.3s ease-out';
  };
  const expand = () => {
    const sidePanel = document.getElementById('sidepane')!;
    const toggleButton = document.getElementById('toggle-button')!;
    toggleButton.style.transform = 'rotate(0deg)';
    toggleButton.style.transition = 'all 0.3s ease-out';
    sidePanel.style.display = 'block';
    sidePanel.style.transition = 'all 0.3s ease-out';
  };

  const checkPermissions = (orgPaths: OrganizationLevelSummary[]) => {
    orgPaths?.forEach(org => {
      if(havePaymentPermission(org.organizationLevel_Path)) {
          setEnabledPaymentPermission(true);
      }

      if(haveSearchPermission(org.organizationLevel_Path)) {
        setEnabledSearchPermission(true);
      }

      if(haveGLPaymentPermission(org.organizationLevel_Path)) {
        setEnabledGLPaymentPermission(true);
      }

      if(havePermission(UserPermissions.FinancialAdministation, org.organizationLevel_Path) || sspUserPermission(org)) {
        setEnabledAdminPermission(true);
      }

    })
  }

  function onToggle() {
    const sidePanel = document.getElementById('sidepane')!;
    if (sidePanel?.style.display === 'none') {
      expand();
    } else {
      collapse();
    }
  }
  function getClassName(paneState?: string, section?: string ){
    return (paneState === section ? `${section} active` : section);
  }
  return (

    <>
      <div className="sidepane-container" id="sidepane">
        <Link to="/landing">
          <div
            className={getClassName(sidepaneState, 'home')}
            onClick={() => clickHandler('home')}
          >
            <Home />
          </div>
        </Link>
        {enabledPaymentPermission && 
        <a
          href="# "
          onClick={e => e.preventDefault()}
        >
          <div
            className={getClassName(sidepaneState, 'make-pymt')}
            onClick={() => {
              dispatch(resetPatientInfo());
              dispatch(setOpenPatientInfoModal(true));
              clickHandler('make-pymt');
            }}
          >
            <MakePayment />
          </div>
        </a>
        }

        {enabledGLPaymentPermission && 
        <a
          href="# "
          onClick={e => e.preventDefault()}          
        >
          <div
            className={getClassName( sidepaneState, 'make-glpymt')}
            onClick={() => {
              dispatch(setOpenModal(true));
              clickHandler('make-glpymt');
            }}
          >
            <MakeGlPayment />
          </div>
        </a>
        }

        {enabledSearchPermission && 
        <Link
          to="/searchpayments" >
          <div
            className={getClassName(sidepaneState, 'search')}
            onClick={() => clickHandler('search')}
          >
            <Search />
          </div>
        </Link>
        }

        {enabledAdminPermission && (
          <Link to="/admin-settings">
            <div
              className={getClassName(sidepaneState, 'admin')}
              onClick={() => clickHandler('admin')}
            >
              <Settings />
            </div>
          </Link>
        )}
        
      </div>
      <div id="toggle-button" className="togglebtn" onClick={onToggle}>
        <Collapse />
      </div>
    </>
  );
};
