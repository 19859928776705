export enum DataType {
  String = 'String',
  Boolean = 'Boolean',
  DateTime = 'DateTime',
  Date = 'Date',
  Decimal = 'Decimal',
  Icon = 'Icon',
  Money = 'Money',
  Link = 'Link',
  Autocomplete = 'Autocomplete',
  Hidden = 'Hidden',
  Password = 'Password'
}

export enum AnnotationType {
  Label = 'Label',
  ValueType = 'ValueType',
  ComponentType = 'ComponentType',
  Immutable = 'Immutable',
  Mutable = 'Mutable',
  NoPreview = 'NoPreview'
}

export enum ValueType {
  Percent = 'Percent',
  Discount = 'Discount',
}

export enum ComponentType {
  DropDown = 'DropDown',
  CheckBox = 'CheckBox',
  TextInput = 'TextInput',
  Calendar = 'Calendar',
  Hidden = 'Hidden',
  CheckImage = 'CheckImage'
}

export enum ValidatorType {
  RangeValidator = 'RangeValidator',
  MinValidator = 'MinValidator',
  MaxValidator = 'MaxValidator',
  DateTimeValidator = 'DateTimeValidator',
  RequiredValidator = 'RequiredValidator',
  DecimalValidator = 'DecimalValidator',
  LengthValidator = 'LengthValidator',
  AmountToTotalValidator = 'AmountToTotalValidator',
  FormatValidator = 'FormatValidator',
  RegularExpressionValidator = 'RegularExpressionValidator',
  TierValidator = 'TierValidator',
  EmailValidator = 'EmailValidator',
  EqualValidator = 'EqualValidator',
  PaymentOnFileRequiredValidator = 'PaymentOnFileRequiredValidator',
  MinPaymentAmountValidator = 'MinPaymentAmountValidator',
  PaymentOnFileExpiredValidator = 'PaymentOnFileExpiredValidator',
  AddhocAmount = 'AddhocAmount',
  FileIdentifierValidator = "FileIdentifierValidator",
  FileNameValidator = "FileNameValidator",
  DuplicateValidator = "DuplicateValidator",
  CardTypeValidator = "CardTypeValidator",
  UrlValidator = "UrlValidator",
}

export enum EntityType {
  LineItem = 'LineItem',
  PlanLineItem = 'PlanLineItem',
  PlanItem = 'PlanItem',
}

export enum PlanOperations {
  NotSet = 'NotSet',
  CreatePlan = 'CreatePlan',
  EditPlan = 'EditPlan',
  EditPlanTerms = 'EditPlanTerms',
  EditPlanDetails = 'EditPlanDetails',
  AddToPlan = 'AddToPlan',
  AddhockPayment = 'AddhockPayment',
}
