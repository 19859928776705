import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeviceManager, DeviceManagerClient } from 'models/DeviceManagerModel';
import {
  UpdateDeviceManagerForClient,
  GetDeviceManagerClients,
  DeviceManagerResponse,
  DeviceManagerClientsResponse,
} from 'services/DeviceManagerService';
import { AppThunk } from 'app/rootReducer';
import { callService } from 'services/ServicesReducer';

export interface State {
  err?: string;
  deviceManagerList?: DeviceManager[];
  deviceManagerClients?: DeviceManagerClient[];
}

export const initialState: State = {
  err: '',
  deviceManagerList: undefined,
  deviceManagerClients: undefined,
};

const reducerSlice = createSlice({
  name: 'DeviceManager',
  initialState,
  reducers: {
    onReceiveDeviceDataForClient(
      state,
      action: PayloadAction<DeviceManagerResponse>
    ) {
      state.deviceManagerList = action.payload.result;
      state.err = action.payload.err;
    },
    onReceiveDeviceDataClients(
      state,
      action: PayloadAction<DeviceManagerClientsResponse>
    ) {
      state.deviceManagerClients = action.payload.result;
      state.err = action.payload.err;
    },
    setDeviceDataForClient(
      state,
      action: PayloadAction<DeviceManagerResponse>
    ) {
      state.deviceManagerList = action.payload.result;
    },
  },
});

export const {
  onReceiveDeviceDataForClient,
  onReceiveDeviceDataClients,
  setDeviceDataForClient,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function updateDeviceManagerForClient(organizationPath?: string): AppThunk {
  return async dispatch => {
    dispatch(callService('UpdateDeviceManagerForClient', async () => {
      const path = organizationPath ? organizationPath : '';
      const response = await UpdateDeviceManagerForClient([path])
      dispatch(onReceiveDeviceDataForClient(response));
    }));
  };
}

export function getDeviceManagerClients(organizationPath?: string): AppThunk {
  return async dispatch => {
    dispatch(callService('GetDeviceManagerClients', async () => {
      const response = await GetDeviceManagerClients(organizationPath)
      dispatch(onReceiveDeviceDataClients(response));
    }));
  };
}
