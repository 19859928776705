import { LineItem } from "../../../../models/LineItem";
import { displayAmount } from "../../../../utils/Utils";
import '../void/Accounts.scss'

export interface AccountsProps {
  accounts?: LineItem[];
  isCompleted?: boolean;
  refundTransactionAccounts?: LineItem[];
  lineitems?:  LineItem[];
}

export const commonColumnHeaders = (amountField: keyof LineItem = 'amount', idLabel: string = 'Account/Invoice Number') => [
  {
    headerName: idLabel,
    field: 'id',
    sortable: true,
    filter: true,
    resizable: true,
    headerClass: 'customHeader',
    cellStyle: {color: '#333333'},
  },
  {
    headerName: 'Original Payment Amount',
    field: amountField,
    sortable: true,
    filter: true,
    resizable: true,
    headerClass: 'customHeader',
    cellStyle: {color: '#333333'},
    cellRenderer: (params: { value: string; }) => {
      return "$" + displayAmount(params.value);
    },    
    }
  ];