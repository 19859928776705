import React, { useEffect, useState } from 'react';
import ConfigurationTemplate from '../../../features/admin/clientConfiguration/ConfigurationTemplate';
import { HeaderButton } from '../../Search/planSearch/paymentPlanDetailsHeader/HeaderButton';
import { ReactComponent as Select } from 'assets/svgs/admin/remit/Select.svg';
import { ReactComponent as DropDown } from 'assets/svgs/admin/remit/DropDown.svg';
import { ReactComponent as Save } from 'assets/svgs/admin/remit/Save.svg';
import { ReactComponent as AddNew } from 'assets/svgs/admin/remit/AddNew.svg';
import { ReactComponent as Clone } from 'assets/svgs/admin/remit/Clone.svg';
import { ReactComponent as Delete } from 'assets/svgs/admin/remit/Delete.svg';
import { ReactComponent as HidePreview } from 'assets/svgs/admin/remit/icon-hidepreview.svg';
import { EDS_Accordion } from '@EH/eh.eds.react';
import 'assets/styles/components/_clientConfiguration.scss';
import 'assets/styles/components/_remitConfiguration.scss';
import AddNewRemitModal from './AddNewRemitModal';
import Settings from '../../../features/admin/remitConfiguration/Settings';
import { useTypedSelector } from '../../../app/rootReducer';
import {
  initialState as RemitInitialState,
  addSetting,
  getRemitFileFieldDescriptions,
  getRemitPredefinedData,
  setRemitConfiguration,
  getRemitFilePredefinedFunctionsDescriptions,
  setRemitPreview,
  defaultMetadata,
} from '../../../features/admin/remitConfiguration/RemitConfigurationReducer';
import AddNewRemitConfigurationSetting from '../../../features/admin/remitConfiguration/AddNewRemitConfigurationSetting';
import * as router from 'react-router-dom';
import { useSaveRemitConfiguration } from 'features/admin/remitConfiguration/serviceHandlers/useSaveRemitConfiguration';
import { ReactComponent as AddIcon } from 'assets/svgs/admin/remitConfig/add_circle_outline.svg';
import { useDispatch } from 'react-redux';
import {
  FunctionEnum,
  RemitConfigurationSettings,
  Setting,
  SettledStatusEnum,
} from 'models/admin/RemitConfiguration';
import { breakFileName, nameof } from 'utils/Utils';
import { useGetRemitConfigurations } from '../../../features/admin/remitConfiguration/serviceHandlers/useGetRemitConfigrations';
import { useOrganizations } from '../../../features/organizations/hooks/useOrganizations';
import GeneralFileSettingsForm from '../../../features/admin/remitConfiguration/forms/GeneralFileSettingsForm';
import { RemitConfiguration } from '../../../models/admin/RemitConfiguration';
import { useRemitPredefinedData } from 'features/admin/remitConfiguration/hooks/useRemitPredefinedData';
import { OrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { OrganizationReference } from 'models/PaymentPlan';
import SelectExistingRemitConfigModal from './SelectExistingRemitConfigModal';
import { RemitFileFieldDescription } from 'models/RemitPreDefinedListsModel';
import DeleteRemitModal from './DeleteRemitModal';
import { CloneRemitConfigurationModal } from '../../../features/admin/remitConfiguration/cloneRemitConfiguration/CloneRemitConfigurationModal';
import { useBlocker } from 'react-router-dom';
import { useGetRemitPreview } from '../../../features/admin/remitConfiguration/serviceHandlers/useGetRemitPreview';
import { useGetRemitConfigurationById } from '../../../features/admin/remitConfiguration/serviceHandlers/useGetRemitConfigurationById';
import { useCloneRemitConfigurations } from 'features/admin/remitManagement/useCloneRemitConfigurations';
import {
  cloneRemitConfigrationsData,
  CloneRemitConfiguration,
} from '../../../services/RemitService';
import { AlertPopUp } from '../../../components/alert/alertPopUp';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';

const RemitConfigurationSettingKey = {
  HeadersColumnSettings: nameof<RemitConfigurationSettings>(
    'headersColumnSettings'
  ),
  BodyColumnSettings: nameof<RemitConfigurationSettings>('bodyColumnSettings'),
  FooterColumnSettings: nameof<RemitConfigurationSettings>(
    'footerColumnSettings'
  ),
  AggregateSettings: nameof<RemitConfigurationSettings>('aggregateSettings'),
};

export function createNewSetting(
  settingKey: keyof RemitConfigurationSettings,
  name: string,
  dbField?: string
): Setting {
  const setting: Setting = {
    name,
    ...defaultMetadata,
  };

  if (settingKey === 'aggregateSettings') {
    setting.function = FunctionEnum.Sum;
    setting.dbField = dbField;
  }

  return setting;
}

export default function RemitConfig() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const shouldPreventLeaving =
    hasChanges && !!localStorage.getItem('chipUserId');
  const [isSelectExistingOpen, setIsSelectExistingOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const remitConfiguration = useTypedSelector(
    s => s.remitConfiguration.remitConfiguration
  );
  const remitFileFieldDescriptions = useTypedSelector(
    s => s.remitConfiguration.remitFileFieldDescriptions
  );
  const remitFileNames = useTypedSelector(s =>
    s.remitConfiguration.remitConfigurations?.map(
      remitConf => remitConf?.fileName!
    )
  );
  const [togglePreview, setTogglePreview] = useState(false);
  const remitPreviewContent = useTypedSelector(
    s => s.remitConfiguration.remitPreview
  );
  const params = router.useParams<{ clientId: string }>();
  const { search } = router.useLocation();
  const query = new URLSearchParams(search);
  const isOpen = query.get('isOpen');
  const remitConfigurationId = query.get('remitConfigurationId');
  const [doShowAlert, setDoShowAlert] = useState(false);
  const [alertPopUpType, setalertPopUpType] = useState(AlertTypes.Info);
  const [alertPopUpContent, setalertPopUpContent] = useState(['']);
  const [alertPopUpHeader, setalertPopUpHeader] = useState('');
  const { getRemitConfiguration } = useGetRemitConfigurationById();

  const selectedFacilityId = params.clientId;
  const { getOrganizationById } = useOrganizations();
  const selectedFacility = getOrganizationById(selectedFacilityId);
  const isSaving = useTypedSelector(
    s => s.services.calls.saveRemitConfiguration?.isProcessing
  );
  const { saveRemitConfiguration } = useSaveRemitConfiguration();
  const [accorditionKey, setAccorditionKey] = useState<any>(null);
  const { getRemitConfigurations } = useGetRemitConfigurations();
  const { clone, getClonedRemitConfig } = useCloneRemitConfigurations();
  const fileFieldDescriptions =
    remitFileFieldDescriptions?.fileFieldDescriptions;
  useEffect(() => {
    if (remitConfigurationId) {
      getRemitConfiguration(remitConfigurationId);
    }
  }, [remitConfigurationId]);

  const { getRemitPreview } = useGetRemitPreview();
  const { getPredefinedData } = useRemitPredefinedData();
  const predefinedData = getPredefinedData();
  const [remitPreviewText, setToggleRemitPreviewText] = useState('Preview');
  const [remitPreviewStyle, setToggleRemitPreviewStyle] = useState(
    'remit-hidden'
  );
  const [remitDetailStyle, setToggleRemitDetailStyle] = useState(
    'remit-visible'
  );

  useEffect(() => {
    const selectedOrganizationPaths = selectedFacility?.path
      ? [selectedFacility.path]
      : undefined;
    getRemitConfigurations({ organizationPaths: selectedOrganizationPaths });
    dispatch(getRemitPredefinedData());
    dispatch(getRemitFileFieldDescriptions());
    dispatch(getRemitFilePredefinedFunctionsDescriptions());
    setIsEditorOpen(!!isOpen);
    setTogglePreview(true);
  }, []);

  useEffect(() => {
    if (shouldPreventLeaving) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [shouldPreventLeaving]);

  function getOrganizationRegerence(
    organization?: OrganizationLevelDocument
  ): OrganizationReference | undefined {
    return (
      organization && {
        id: organization.id,
        name: organization.name,
        path: organization.path,
      }
    );
  }

  function createConfigurationDataToClone(
    destinationClientId: string,
    fileName: string,
    fileIdentifier: string
  ) {
    if (remitConfiguration.id && fileIdentifier) {
      const remitConfigurationToClone: CloneRemitConfiguration = {
        originalConfigurationId: remitConfiguration.id,
        organizationId: destinationClientId,
        fileNameOverwrite: fileName,
        fileIdentifierOverwrite: fileIdentifier,
      };

      const remitConfigIds: string[] = [remitConfiguration.id];
      const bulkCloneDestinationOrganizations: CloneRemitConfiguration[] = [
        remitConfigurationToClone,
      ];

      const remitConfigDataToClone: cloneRemitConfigrationsData = {
        remitConfigIds: remitConfigIds,
        bulkCloneDestinationOrganizations: bulkCloneDestinationOrganizations,
      };

      return remitConfigDataToClone;
    }
  }

  function getFirstFileFieldDescriptionFieldName(
    allRemitFileFieldDescriptions: RemitFileFieldDescription[] = []
  ) {
    return (
      allRemitFileFieldDescriptions[0] &&
      allRemitFileFieldDescriptions[0].fieldName
    );
  }

  function createSetting(
    settingKey: keyof RemitConfigurationSettings,
    name: string
  ): Setting {
    return createNewSetting(
      settingKey,
      name,
      getFirstFileFieldDescriptionFieldName(fileFieldDescriptions)
    );
  }

  function openAddNewRemitModal() {
    return isModalOpen ? (
      <AddNewRemitModal
        clientId={selectedFacility?.clientId}
        fileNames={remitFileNames}
        close={() => {
          setIsModalOpen(false);
        }}
        isOpen={true}
        onNext={fileName => {
          dispatch(
            setRemitConfiguration({
              ...RemitInitialState.remitConfiguration,
              fileName: `${fileName.name}${fileName.format}`,
              filePath: (selectedFacility?.clientId ?? '') + '\\',
              clientFilter: [selectedFacility?.path ?? ''],
              createWithoutRecords: true,
              orderDesc: false,
              settledStatus: SettledStatusEnum[SettledStatusEnum.Setled],
              organization: getOrganizationRegerence(selectedFacility),
              sortString: getFirstFileFieldDescriptionFieldName(
                fileFieldDescriptions
              ),
              aggregateSettings: RemitInitialState.remitConfiguration.aggregateSettings.map(
                s => {
                  return {
                    ...s,
                    dbField: getFirstFileFieldDescriptionFieldName(
                      fileFieldDescriptions
                    ),
                    function: FunctionEnum.Sum,
                  };
                }
              ),
              headersColumnSettings:
                RemitInitialState.remitConfiguration.headersColumnSettings,
              bodyColumnSettings:
                RemitInitialState.remitConfiguration.bodyColumnSettings,
              footerColumnSettings:
                RemitInitialState.remitConfiguration.footerColumnSettings,
            })
          );
          dispatch(setRemitPreview(''));
          setIsModalOpen(false);
          setIsEditorOpen(true);
        }}
      />
    ) : null;
  }

  function openSelectExistingRemitModal() {
    return isSelectExistingOpen ? (
      <SelectExistingRemitConfigModal
        close={() => {
          setIsSelectExistingOpen(false);
        }}
        isOpen={true}
        onNext={existingRemitConfiguration => {
          dispatch(setRemitConfiguration(existingRemitConfiguration));
          setIsSelectExistingOpen(false);
          setIsEditorOpen(true);
          dispatch(setRemitPreview(''));
        }}
        orgPath={selectedFacility?.path ?? ''}
      />
    ) : null;
  }

  function openDeleteModal() {
    return isDeleteModalOpen ? (
      <DeleteRemitModal
        close={() => {
          setIsDeleteModalOpen(false);
        }}
        fileId={remitConfiguration.id}
        onSubmit={() => {
          setIsEditorOpen(false);
        }}
      />
    ) : null;
  }

  const onAddNewSetting = (name: string) => {
    dispatch(
      addSetting({
        index: 0,
        key: accorditionKey,
        value: createSetting(accorditionKey, name),
      })
    );
    setAccorditionKey(null);
  };

  const triggerPreviewToggle = () => {
    if (togglePreview) {
      getRemitPreview(remitConfiguration);
      setToggleRemitPreviewStyle('remit-visible');
      setToggleRemitDetailStyle('remit-hidden');
      setToggleRemitPreviewText('Hide Preview');
    } else {
      setToggleRemitPreviewStyle('remit-hidden');
      setToggleRemitDetailStyle('remit-visible');
      setToggleRemitPreviewText('Preview');
    }

    setTogglePreview(!togglePreview);
  };

  function showRemitPreview() {
    const previewList = [
      {
        summary: 'REMIT PREVIEW',
        content: <div className="remit-preview">{remitPreviewContent}</div>,
      },
    ];

    return (
      <div className={remitPreviewStyle}>
        <EDS_Accordion accordionItems={previewList} modifiers="detailsRemit" />
      </div>
    );
  }

  const returnHidePreviewIcon = () => {
    return !togglePreview ? <HidePreview /> : null;
  };

  function DisplayPopUpAlert(
    alertContent: string[],
    type: AlertTypes,
    header: string
  ) {
    setDoShowAlert(true);
    setalertPopUpContent(alertContent);
    setalertPopUpType(type);
    setalertPopUpHeader(header);
  }

  function ResetPopUpAlert() {
    setalertPopUpContent([]);
    setalertPopUpType(AlertTypes.Info);
    setalertPopUpHeader('');
    setDoShowAlert(false);
  }

  function getErrorAlert(message: string, index?: number) {
    return {
      id: AlertIds.EditRemitConfig + (index ? "_" + index : ""),
      type: AlertTypes.Error,
      message,
      dismissable: false
    }
  }
  
  function getSuccessAlert(message: string) {
    return {
      id: AlertIds.EditRemitConfig,
      type: AlertTypes.Success,
      message,
      dismissable: true
    }
  }

  function displayAlert() {
    return doShowAlert ? (
      <div>
        <AlertPopUp
          onClose={() => {
            ResetPopUpAlert();
          }}
          alertType={alertPopUpType}
          content={alertPopUpContent}
          header={alertPopUpHeader}
        ></AlertPopUp>
      </div>
    ) : null;
  }

  async function SaveRemitConfiguration() {
    const saveResult = await saveRemitConfiguration(remitConfiguration);
    if (saveResult?.err) {
      const validationErrs = saveResult?.errorResponse?.data?.validationErrors;
      if (validationErrs?.length) {
        validationErrs.forEach((x: any, i: number) => {
          if (x.errorMessage)
            dispatch(setAlert(getErrorAlert(x.errorMessage.replace(/[^a-zA-Z ]/g, ""), i)));
        })
      }
      else {
        dispatch(setAlert(getErrorAlert(saveResult.err)));
      }
     
    } else {
      setHasChanges(false);
      dispatch(setAlert(getSuccessAlert("Remit Configuration saved successfully!")));
    }
  }

  const AccorditionSummary = (props: {
    summary: string;
    onAdd: () => void;
    visible?: boolean;
  }) => {
    const { summary, onAdd, visible } = props;

    return (
      <span className="remit-accordition-summary">
        <span className="summary">
          <span>{summary}</span>
          {visible ? (
            <span>
              <AddIcon
                onClick={(
                  event: React.MouseEvent<SVGSVGElement, MouseEvent>
                ) => {
                  event.stopPropagation();
                  onAdd();
                }}
              />
            </span>
          ) : null}
        </span>
      </span>
    );
  };

  const brokenFileName = breakFileName(remitConfiguration.fileName);
  let destinationId = '';
  let remitConfigId = '';

  async function onCloneRemitConfiguration(props: {
    fileIdentifier: string;
    fileName: string;
    fileExtension: string;
    destinationClientId: string;
  }) {
    const { fileIdentifier, fileName, destinationClientId } = props;
    const remitConfigurationsDataToClone = createConfigurationDataToClone(
      destinationClientId,
      fileName,
      fileIdentifier
    );
    if (destinationClientId && fileName && remitConfigurationsDataToClone) {
      const cloned = await clone(remitConfigurationsDataToClone);

      if (
        cloned &&
        cloned.remitConfigIds &&
        cloned.remitConfigIds.length == 1
      ) {
        const response = await getClonedRemitConfig(cloned);

        if (response && response.remitConfigId && response.destinationId) {
          destinationId = response.destinationId;
          remitConfigId = response.remitConfigId;
        }
      }

      const errors: {
        PropertyName: string;
        errorMessage: string;
      }[] = cloned;
      if (!remitConfigId || !destinationId) {
        errors.forEach(err => {
          dispatch(
            setAlert({
              dismissable: true,
              id: `${AlertIds.CloneRemitConfigurationsAlert}${err.PropertyName}`,
              type: AlertTypes.Error,
              message: `${err.errorMessage} - ${err.PropertyName}`,
            })
          );
        });
      } else {
        setIsCloneModalOpen(false);
        window.location.replace(
          `/admin-settings/${destinationId}/remit-configuration?isOpen=true&remitConfigurationId=${remitConfigId ??
            ''}`
        );
      }
    }
  }

  const onChangeEvent = () => setHasChanges(true);

  const list = [
    {
      summary: 'GENERAL FILE SETTINGS',
      content: (
        <GeneralFileSettingsForm
          configuration={remitConfiguration}
          onChange={(configuration: RemitConfiguration) => {
            onChangeEvent();
            dispatch(setRemitConfiguration(configuration));
          }}
          client={selectedFacility}
          fileFieldDescriptions={
            remitFileFieldDescriptions.fileFieldDescriptions
          }
        />
      ),
    },
    {
      summary: (
        <AccorditionSummary
          summary={'Header Settings'.toUpperCase()}
          onAdd={() => {
            setAccorditionKey(
              RemitConfigurationSettingKey.HeadersColumnSettings
            );
          }}
          visible={!remitConfiguration.headersColumnSettings.length}
        />
      ),
      content: (
        <Settings
          settings={remitConfiguration.headersColumnSettings}
          settingKey={RemitConfigurationSettingKey.HeadersColumnSettings}
          predefinedData={predefinedData}
          aggregateSettings={remitConfiguration.aggregateSettings}
          footerSettings={remitConfiguration.footerColumnSettings}
          remitFileFieldDescriptions={
            remitFileFieldDescriptions?.fileFieldDescriptions
          }
          onChangeEvent={onChangeEvent}
          createSetting={createSetting}
        />
      ),
    },
    {
      summary: 'Body Settings'.toUpperCase(),
      content: (
        <Settings
          settings={remitConfiguration.bodyColumnSettings}
          settingKey={RemitConfigurationSettingKey.BodyColumnSettings}
          predefinedData={predefinedData}
          remitFileFieldDescriptions={
            remitFileFieldDescriptions?.fileFieldDescriptions
          }
          canBeEmpty={false}
          aggregateSettings={remitConfiguration.aggregateSettings}
          footerSettings={remitConfiguration.footerColumnSettings}
          onChangeEvent={onChangeEvent}
          createSetting={createSetting}
        />
      ),
    },
    {
      summary: (
        <AccorditionSummary
          summary={'Footer Settings'.toUpperCase()}
          onAdd={() => {
            setAccorditionKey(
              RemitConfigurationSettingKey.FooterColumnSettings
            );
          }}
          visible={!remitConfiguration.footerColumnSettings.length}
        />
      ),
      content: (
        <Settings
          settings={remitConfiguration.footerColumnSettings}
          settingKey={RemitConfigurationSettingKey.FooterColumnSettings}
          predefinedData={predefinedData}
          aggregateSettings={remitConfiguration.aggregateSettings}
          footerSettings={remitConfiguration.footerColumnSettings}
          remitFileFieldDescriptions={
            remitFileFieldDescriptions?.fileFieldDescriptions
          }
          onChangeEvent={onChangeEvent}
          createSetting={createSetting}
        />
      ),
    },
    {
      summary: (
        <AccorditionSummary
          summary={'Aggregate Settings'.toUpperCase()}
          onAdd={() => {
            setAccorditionKey(RemitConfigurationSettingKey.AggregateSettings);
          }}
          visible={!remitConfiguration.aggregateSettings.length}
        />
      ),
      content: (
        <Settings
          settings={remitConfiguration.aggregateSettings}
          settingKey={RemitConfigurationSettingKey.AggregateSettings}
          predefinedData={predefinedData}
          aggregateSettings={remitConfiguration.aggregateSettings}
          footerSettings={remitConfiguration.footerColumnSettings}
          remitFileFieldDescriptions={
            remitFileFieldDescriptions?.fileFieldDescriptions
          }
          onChangeEvent={onChangeEvent}
          createSetting={createSetting}
        />
      ),
    },
  ];

  const headerButtons = (
    <div className={'header-buttons'}>
      <HeaderButton
        title={'Select'}
        icon={<Select />}
        onClick={() => {
          setIsSelectExistingOpen(true);
        }}
        secondary={
          <HeaderButton
            icon={<DropDown />}
            onClick={undefined}
            className={'secondary'}
          />
        }
        disabled={false}
      />
      <HeaderButton
        title={'Add New'}
        icon={<AddNew />}
        onClick={() => {
          setIsModalOpen(true);
        }}
        disabled={false}
      />
      <HeaderButton
        title={'Clone'}
        icon={<Clone />}
        onClick={() => setIsCloneModalOpen(true)}
        disabled={!isEditorOpen}
      />
      <HeaderButton
        title={isSaving ? 'Processing...' : 'Save'}
        icon={<Save />}
        onClick={async () => {
          SaveRemitConfiguration();
        }}
        disabled={!isEditorOpen}
      />
      <HeaderButton
        title={'Delete'}
        icon={<Delete />}
        onClick={() => {
          setIsDeleteModalOpen(true);
        }}
        disabled={!remitConfiguration.id}
      />
    </div>
  );

  const blocker = useBlocker(({ currentLocation, nextLocation }) =>
    shouldPreventLeaving && currentLocation.pathname !== nextLocation.pathname);

  useEffect(() => {
    if (blocker.state === "blocked") {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('You have unsaved changes, are you sure you want to leave?')) {
        blocker.proceed()
      } else {
        blocker.reset()
      }
    }
  }, [blocker.state])

  return (
    <ConfigurationTemplate
      title={'Remit Configuration'}
      headerButtons={headerButtons}
    >
      <>
        {!isEditorOpen ? (
          <p className={'remit-message'}>
            Please select the Remit file to configure. To create new Remit file,
            use "Add new" or "Clone" one of the existing files.
          </p>
        ) : (
          <>
            <div className="longRow">
              <div className="fileNameRow">
                <p className="fileLabel">File: </p>
                <p className="fileName">{remitConfiguration.fileName}</p>
              </div>
              <div className="flexRow">
                <button
                  className="eds-button eds-button.basic"
                  onClick={() => {
                    triggerPreviewToggle();
                  }}
                >
                  {returnHidePreviewIcon()}
                  {remitPreviewText}
                </button>
              </div>
            </div>
            {showRemitPreview()}
            <div className={remitDetailStyle}>
              <EDS_Accordion accordionItems={list} modifiers="detailsRemit" />
            </div>
          </>
        )}
        <div className="remitAccordionContainer">
          {openAddNewRemitModal()}
          {openDeleteModal()}
        </div>
        <div>{openSelectExistingRemitModal()}</div>
        <div>{displayAlert()}</div>
        {accorditionKey ? (
          <AddNewRemitConfigurationSetting
            close={() => {
              setAccorditionKey(null);
            }}
            onNext={onAddNewSetting}
          />
        ) : null}
        {isCloneModalOpen ? (
          <CloneRemitConfigurationModal
            originalFileName={brokenFileName.fileName}
            originalFileType={brokenFileName.fileExtension}
            originalFileIdentifier={remitConfiguration.fileIdentifier}
            close={() => {
              setIsCloneModalOpen(false);
            }}
            onNext={onCloneRemitConfiguration}
          />
        ) : null}
      </>
    </ConfigurationTemplate>
  );
}
