import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import 'typeface-roboto';
import { EDS_Button } from '@EH/eh.eds.react';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { AlertIds, AlertTypes, removeAlert, setAlert } from 'features/alert/AlertReducer';
import { useTypedSelector } from 'app/rootReducer';
import { getRequestSearchId, getPlanSearchId, hasMissingMandatoryFields, errorMessage } from './searchUtils';
import '@experian/eds-styles/dist/eds-all.css';
import '../../assets/styles/components/_searchpage.scss';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import { setActiveTab, setSimpleSearchInfoState, initialState as simpleInitialState } from 'pages/Search/simpleSearch/SimpleSearchReducer';
import { setAdvanceSearchInformation, initialState as advancedInitialState} from './advanceSearch/AdvanceSearchReducer';
import { updateRecord as planSetSettings, initialState as paymentPlanInitialState } from './planSearch/PaymentPlanSearchReducer';
import { UserSettings } from 'models/UserInfoAndRolesModel';
import { getOrganizationOptions, useGetUserDepartmentsOptions } from './simpleSearch/OrganizationUserSearch';
import { getUserDepartmentOrFacilityesOptions } from 'utils/UtilsOrganizationDropdowns';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { useUserUtils } from 'utils/useUserUtils';

interface Props {
  children?: React.ReactNode;
  rootPath: string;
  breadcrumb: string;
  searchButtonClick: (event: React.MouseEvent<HTMLButtonElement>, tabIndex: string, query?: string) => void;
  onReset?: (tabIndex: string, userSettings: UserSettings | undefined) => void;
  onClose?: () => void;
  onToggle?: () => void;
  onSave?: (tabIndex: string) => void;
  accessVolumeSearch: boolean;
  showPlanSearch: boolean;
  userSettings?: UserSettings;
}

interface Tab {
  label: string; 
  permissions: UserPermissions[];
}

function useBreadcrumb(rootPath: string, name: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadcrumbLinks([{ name: name, slug: rootPath }]));

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch, name]);
}

let tabs = [
  { label: 'Simple Search', permissions: [UserPermissions.Search] }, 
  { label: 'Advanced Search', permissions: [UserPermissions.Search] }, 
  { label: 'Plan Search', permissions: [UserPermissions.Search, UserPermissions.SearchPlans]}, 
  { label: 'Volume Search', permissions: [UserPermissions.Search]}
];


export default function Searchpage(props: Props) {
  const dispatch = useDispatch();

  const {
    children,
    searchButtonClick,
    rootPath,
    breadcrumb,
    onReset,
    onClose,
    onSave,
    onToggle,
    accessVolumeSearch,
    showPlanSearch,
    userSettings
  } = props;

  useBreadcrumb(rootPath, breadcrumb);

  const { havePermissions } = useLoggedUserPermissions();

  const activeTab = useTypedSelector(p => p.simpleSearchInfo?.activeTab);
  const paymentPlanSearchInfo = useTypedSelector(s => s.paymentPlanSearchInfo || []);
  const simpleSearchInfo = useTypedSelector(s => s.simpleSearchInfo || []);
  const advanceSearchInfo = useTypedSelector(s => s.advanceSearchInfo || []);
  const simpleSearchSaveSettingsChecked = useTypedSelector(s=> s.simpleSearchInfo?.saveSettings ?? false);
  const advancedSearchSaveSettingsChecked = useTypedSelector(s=> s.advanceSearchInfo?.saveSettings ?? false);
  const paymentPlanSearchSaveSettingsChecked = useTypedSelector(s=> s.paymentPlanSearchInfo?.saveSettings ??false);
  
  const validationAlert = { id: AlertIds.SearchAlert, type: AlertTypes.Warning, message: '', dismissable: true  };
  const { useGetFacilities } = useOrganizations();
  const allFacilities = useGetFacilities();
  const facilities = allFacilities;
  const { getLoggedUserOrganizations } = useUserUtils();
  const userOrganizations = getLoggedUserOrganizations();
  const userFacilityOptions =  getUserDepartmentOrFacilityesOptions(facilities, userOrganizations);
  const facilityOptions = getOrganizationOptions( userFacilityOptions);
  const getUserDepartmentsOptions = useGetUserDepartmentsOptions();
  const departmentsOptions = getUserDepartmentsOptions(userOrganizations);
  
  const openErrorMessage = (message: string) => {
    dispatch(setAlert({ ...validationAlert, message: errorMessage(message) }));
  };

  const closeErrorMessage = () => {
    dispatch(removeAlert(validationAlert));
  };


  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      onToggle && onToggle();
      dispatch(setActiveTab(tab));
      closeErrorMessage();
    }
  };

  const saveSettingsCheckboxValue: { [key: string]: boolean } = {
    1: !!simpleSearchInfo.saveSettings,
    2: !!advanceSearchInfo.saveSettings,
    3: !!paymentPlanSearchInfo.saveSettings,
  };

  function getSettingsCheckbox(tabIndex: string) {
    switch(tabIndex){
      case "1":
        return simpleSearchSaveSettingsChecked;
      case "2":
        return advancedSearchSaveSettingsChecked;
      case "3":
        return paymentPlanSearchSaveSettingsChecked;
      }
  }

  const mandatoryFields: { [key: string]: any } = {
    1: { 'Facility': !!simpleSearchInfo.value?.facilities.length, 'Department': !!simpleSearchInfo.value?.departments.length, },
    2: {
      'Facility': !!advanceSearchInfo.value?.facilities.length,
      'Department': !!advanceSearchInfo.value?.departments.length,
      'Transaction type': !!advanceSearchInfo.value?.transactionTypes?.find(transaction => transaction.isChecked),
      'Tender type': !!advanceSearchInfo.value?.tenderTypes?.find(tender => tender.isChecked),
      'Card Entry Type': !!advanceSearchInfo.value?.cardEntryTypes?.find(cardEntryType => cardEntryType.isChecked),
    },
    3: {
      'Facility': !!paymentPlanSearchInfo.value?.facilities.length,
      'Department': !!paymentPlanSearchInfo.value?.departments.length,
      'Tender type': !!paymentPlanSearchInfo.value?.tenderType?.find(tender => tender.isChecked),
    },
  };

  const mapSubmitFunction: { [key: string]: () => string } = {
    1: () => getRequestSearchId(simpleSearchInfo, simpleSearchInfo.value),
    2: () => getRequestSearchId(simpleSearchInfo, advanceSearchInfo.value),
    3: () => getPlanSearchId(paymentPlanSearchInfo?.value, paymentPlanSearchInfo.value),
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (hasMissingMandatoryFields(mandatoryFields[activeTab])) {
      openErrorMessage(hasMissingMandatoryFields(mandatoryFields[activeTab]));
      return;
    }

    let query = '';
    const submitFunction = mapSubmitFunction[activeTab];
    if (submitFunction) {
      query = submitFunction();
    }

    searchButtonClick(e, activeTab, query)
    closeErrorMessage();
    resetSelectedOptions();
  };

  const resetSelectedOptions = () => {
    if (!simpleSearchSaveSettingsChecked) {
      dispatch(setSimpleSearchInfoState({ ...simpleSearchInfo.value, facilities: facilityOptions, departments: departmentsOptions }));
    }
    if (!advancedSearchSaveSettingsChecked) {
      dispatch(setAdvanceSearchInformation({ ...advanceSearchInfo.value, facilities: facilityOptions, departments: departmentsOptions }));
    }
    if (!paymentPlanSearchSaveSettingsChecked) {
      dispatch(planSetSettings({ ...paymentPlanSearchInfo.value, facilities: facilityOptions, departments: departmentsOptions }));
    }
  };
  
  useEffect(() => {
    if (!accessVolumeSearch) {
      tabs = tabs.filter(tab => tab.label !== 'Volume Search');
    } else if (!tabs.some(tab => tab.label === 'Volume Search')) {
      const volumeSearchTab = { label: 'Volume Search', permissions: [UserPermissions.Search] };
      tabs = [...tabs, volumeSearchTab];
    }

    if (!showPlanSearch) {
      tabs = tabs.filter(tab => tab.label !== 'Plan Search');
    } else if (!tabs.some(tab => tab.label === 'Plan Search')) {
      const planSearchTab = { label: 'Plan Search', permissions: [UserPermissions.Search, UserPermissions.SearchPlans] };
      tabs = [...tabs, planSearchTab];
    }
  }, [showPlanSearch, accessVolumeSearch]);


  useEffect(() => {
    switch(activeTab){
      case "1":
        if (!simpleSearchSaveSettingsChecked) {
          dispatch(setSimpleSearchInfoState({
            ...simpleInitialState.value,
            facilities: facilityOptions,
            departments: departmentsOptions,
            users: []
          }))
        }
        break;
      case "2":
        if (!advancedSearchSaveSettingsChecked) {
          dispatch(setAdvanceSearchInformation({
            ...advancedInitialState.value,
            facilities: facilityOptions,
            departments: departmentsOptions,
            users: []
          }));     
        }
        break;
      case "3":
        if (!paymentPlanSearchSaveSettingsChecked) {
          dispatch(planSetSettings({
            ...paymentPlanInitialState.value,
            facilities: facilityOptions,
            departments: departmentsOptions,
            users: []
          }));
        }
        break;
    }
  },[activeTab, showPlanSearch]);

  return (
    <div className="search-page-container">
      <Nav tabs>
        {tabs.map(
          (tab, index) => {
            const tabIndex = index + 1;
            const enabled = havePermissions(tab.permissions);
            return (<>
              { enabled ? <NavItem key={index} className="sass-nav-link">
                <NavLink
                  disabled={!enabled}
                  className={classnames({ active: +activeTab === tabIndex && enabled })}
                  onClick={() => {
                    toggle(tabIndex.toString());
                  }}
                >
                  {tab.label}
                </NavLink>
              </NavItem> : null}
            </>
            );
          }
        )}
      </Nav>
      <TabContent className="tab-content-container" activeTab={activeTab}>
        {children}
      </TabContent>

      <div className="searchPageFooter" >
        <div className='actionsContainer'>
          <label>
            <input
              className={'eds-checkbox_#input'}
              type={(activeTab=='4')?'hidden':'checkbox'}
              name={''}
              onChange={() => onSave && onSave(activeTab)}
              checked={getSettingsCheckbox(activeTab)}
            />

            <span className={`eds-checkbox_#label`} hidden={(activeTab=='4')?true:false}>{'Save Settings'}</span>
          </label>
          <EDS_Button
            modifiers={(activeTab=='4')?'hidden':'eds-button eds-button.basic'}
            buttonText={'Reset'}
            onClick={() => onReset && onReset(activeTab, userSettings)}
          />
          <EDS_Button
            modifiers={'eds-button eds-button.basic btn-cancel'}
            buttonText={'Cancel'}
            onClick={onClose}
          />
          <EDS_Button modifiers={(activeTab=='4')?'hidden':'eds-button.primary'} buttonText={'Submit'} onClick={onSubmit} />
      </div>
      </div>
    </div>
  );
}


