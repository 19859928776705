import { PatientEncounterModel } from 'models/PatientEncounterModel';
import { useState } from 'react';
import { useTypedSelector } from '../../../app/rootReducer';
import { PatientModel } from '../../../models/PatientModel';
import { GetPatientEncountersService } from '../../../services/patient/PatientService';
import { useCallService } from '../../../services/useCallService';
import { usePahAlert } from './usePahAlert';
import { getValueOrDefault } from 'utils/Utils';

export function useLineItems(props: {
  patientId?: string;
  organizationPath?: string
  clientId?: string;
  accountNo?: string;
  onError?: () => void;
  onSuccess?: (encounters?: PatientEncounterModel[]) => void;
  patient?: PatientModel;
}) {
  const { patientId, organizationPath, clientId, accountNo, onError, onSuccess } = props;
  const patient = useTypedSelector(s => s.patients.records.find(p => p.id === patientId));
  const clientConfiguration = useTypedSelector(
    s => s.getClientFacilityConfiguration?.result
  );
  const callService = useCallService();
  const { getErrorMessage } = usePahAlert();
  const [isProcessing, setIsProcessing] = useState(false)
  async function retrieve(preloadedLineItems?: PatientEncounterModel[]) {

    if (preloadedLineItems){
     return preloadedLineItems;
    }

    if (!(clientId && patientId)) return;

    return callService("getPatientEncounters", async () => {
      setIsProcessing(true);
      let queryParams = buildQueryParams(patient,clientConfiguration?.showZeroBalances);

      let response = await GetPatientEncountersService({ patientId, clientId }, queryParams);
      if (response.result) {
        onSuccess && onSuccess(response.result);
        setIsProcessing(false);
        return response.result.map(encounter => ({ ...encounter, pahAccount: true }));
      } else {
        if(response.errorResponse?.status === 500) {
          getErrorMessage();
        }
        onError && onError();
      }
      setIsProcessing(false);
    });
  }

  let lineItems = useTypedSelector(s => s.paymentDashboard?.values?.[organizationPath ?? '']?.lineItems || []);

  return { lineItems, retrieve, isProcessing };
}

function buildQueryParams(patient?: PatientModel,showZeroBalances?: boolean) {
  let queryParams = new URLSearchParams();

  if (patient) {
    queryParams.set('FirstName', getValueOrDefault(patient?.firstName, ''));
    queryParams.set('LastName', getValueOrDefault(patient?.lastName,''));
    queryParams.set('Mrn', getValueOrDefault(patient?.mrn, ''));
    queryParams.set('DateOfBirth', getValueOrDefault(patient?.dateOfBirth, ''));
    queryParams.set('GuarantorAccountNo', getValueOrDefault(patient?.guarantorAccountNo, ''));
    queryParams.set('AccountNo', getValueOrDefault(patient?.accountNo, ''));
    queryParams.set('ShowZeroBalances', getValueOrDefault(showZeroBalances, false));
  }
  return queryParams;
}