import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useGetRemitConfigurations } from '../../../features/admin/remitConfiguration/serviceHandlers/useGetRemitConfigrations';
import * as router from 'react-router-dom';
import { useOrganizations } from '../../../features/organizations/hooks/useOrganizations';
import { useTypedSelector } from '../../../app/rootReducer';
import MultiSelect, {
  MultiSelectOption,
} from '../../../components/select/MultiSelect';
import { Button } from '@EHDS/core';
import {
  getOrganizationOption,
  getOrganizationOptions,
} from '../../Search/simpleSearch/OrganizationUserSearch';
import { useCloneRemitConfigurations } from '../../../features/admin/remitManagement/useCloneRemitConfigurations';
import { useDispatch } from 'react-redux';
import { CloneRemitConfiguration } from '../../../services/RemitService';
import {
  AlertIds,
  AlertTypes,
  setAlert,
  setFixedMode,
} from '../../../features/alert/AlertReducer';
import { RemitConfiguration } from '../../../models/admin/RemitConfiguration';
import { Popup } from '../../../components/popup/Popup';
import {
  EnumPopupButtonsStyle,
  EnumPopupButtonsType,
} from '../../../models/enums/EnumPopupType';
import { OrganizationLevelDocument } from '../../../models/OrganizationLevelDocument';
import { AgGridReact } from 'ag-grid-react';
import {
  GridApi,
  GridColumnsChangedEvent,
  GridReadyEvent,
  ICellRendererParams,
  IDatasource,
  IGetRowsParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { nameof } from '../../../utils/Utils';
import { useLocalStorage } from 'utils/useLocalStorage';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { useValueRef } from '../../../features/gridCells/useValueRef';

export function useGetClientDisplayName() {
  const { getOrganizationByPath } = useOrganizations();

  return {
    getClientDisplayName: (path?: string) => {
      const organization = getOrganizationByPath(path);
      return `${organization?.clientId || ''} ${organization?.name || ''}`;
    },
  };
}

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setBreadcrumbLinks([{ name: 'Remit Management', slug: rootPath }])
    );

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

export const getLabel = (organization: OrganizationLevelDocument) =>
  `(${organization.tradingPartnerId}) ${organization.name}`;
export const getChildLabel = (organization: OrganizationLevelDocument) =>
  `(${organization.clientId}) ${organization.name}`;

export interface GenericInputCellProps<T> extends ICellRendererParams {
  isEditor: boolean;
  onChange: (
    idx: number,
    field: keyof T,
    value: string,
    rowData: T[]
  ) => {};
  onBlurCallback?: (
    idx: number,
    field: keyof T,
    value: string,
    rowData: T[]
  ) => {};
  agGridReact: {
    props: {
      rowData: T[];
    };
  };
  originateFrom: string;
}

export default function RemitManagement(
  props: Props = { rootPath: 'remit-management' }
) {
  const { rootPath } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFixedMode(true));
    return () => {
      dispatch(setFixedMode(false));
    };
  }, []);
  useBreadcrumb(rootPath);

  const organizations = useTypedSelector(s => s.organizations.value);

  const [isConfirmationPopUpOpen, setIsConfirmationPopUpOpen] = useState(false);

  const [selectedSources, setSelectedSources] = useState<RemitConfiguration[]>(
    []
  );
  const [cloned = [], setCloned] = useLocalStorage<CloneRemitConfiguration[]>(
    'remitManagementState',
    'cloned',
    []
  );
  const [selectedDestinations = [], setSelectedDestinations] = useLocalStorage<
    CloneRemitConfiguration[]
  >('remitManagementState', 'selectedDestinations', []);

  const [destinations, setDestinations] = useLocalStorage<MultiSelectOption[]>(
    'remitManagementState',
    'destinations',
    []
  );
  const { clone } = useCloneRemitConfigurations();

  const { useGetTradingPartners, removeEndSegments } = useOrganizations();
  const { getClientDisplayName } = useGetClientDisplayName();

  const tradingPartners = useGetTradingPartners();
  const filterOrganizationOptions: MultiSelectOption[] = [];
  tradingPartners.forEach(t => {
    filterOrganizationOptions.push(getOrganizationOption(t, o => getLabel(o)));
    const children = organizations.filter(o => {
      const path = removeEndSegments(o.path, 1);
      return path === t.path;
    });
    children.forEach(c => {
      filterOrganizationOptions.push({
        className: 'child-option',
        ...getOrganizationOption(c, o => getChildLabel(o)),
      });
    });
  });

  const [
    selectedOrganizations = [],
    setSelectedOrganizations,
  ] = useLocalStorage<MultiSelectOption[]>(
    'remitManagementState',
    'selectedOrganizations',
    []
  );
  const [
    selectedOrganizationsThrotle = [],
    setSelectedOrganizationsThrotle,
  ] = useLocalStorage<MultiSelectOption[]>(
    'remitManagementState',
    'selectedOrganizationsThrotle',
    []
  );

  const { getRemitConfigurations } = useGetRemitConfigurations();

  const onTradingPartnerSubmit = async () => {
    setSelectedOrganizations(selectedOrganizationsThrotle);
  };

  const organizationOptions = getOrganizationOptions(organizations, o => ({
    label: `(${o.clientId}) ${o.name}`,
    value: o.id,
  }));

  const onChangeDestinations = (values: MultiSelectOption[]) => {
    setDestinations(values);
    updateCloned(selectedSources, values);
  };

  const updateCloned = (
    sourceValues: RemitConfiguration[],
    destinationValues: MultiSelectOption[]
  ) => {
    const newCloned: CloneRemitConfiguration[] = [];
    sourceValues.forEach(c => {
      destinationValues.forEach(d => {
        const editted = cloned.find(
          cl =>
            cl.organizationId === d.value && cl.originalConfigurationId === c.id
        );
        newCloned.push(
          editted ?? {
            originalConfigurationId: c.id,
            organizationId: d.value,
            fileIdentifierOverwrite: c.fileIdentifier ?? '',
            fileNameOverwrite: c.fileName ?? '',
          }
        );
      });
    });
    setCloned(newCloned);
    destinationRemitGridApi?.setRowData(newCloned);
    destinationRemitGridApi?.redrawRows();
    destinationRemitGridApi?.refreshCells({ force: true });
  };

  const changeDestination = (
    idx: number,
    field: keyof CloneRemitConfiguration,
    value: string,
    all?: CloneRemitConfiguration[]
  ) => {
    const newCloned = all ? [...all] : [...cloned];
    const updated = cloned[idx];
    if (updated) {
      updated[field] = value;
      newCloned[idx] = updated;
      setCloned(newCloned);
    }
  };

  const onClone = async () => {
    setIsConfirmationPopUpOpen(false);
    const errors: {
      PropertyName: string;
      errorMessage: string;
    }[] = await clone({
      remitConfigIds: selectedSources.map(c => c.id ?? ''),
      bulkCloneDestinationOrganizations: selectedDestinations,
    });
    if (errors) {
      errors.forEach(err => {
        dispatch(
          setAlert({
            id: `${AlertIds.CloneRemitConfigurationsAlert}${err.PropertyName}`,
            type: AlertTypes.Error,
            message: `${err.errorMessage} - ${err.PropertyName}`,
          })
        );
      });
    }
  };

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [
    destinationRemitGridApi,
    setDestinationRemitGridApi,
  ] = useState<GridApi | null>(null);
  const pageSize = 20;
  function onGridReady(params: GridReadyEvent) {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    const dataSource: IDatasource = {
      async getRows(rowsParams: IGetRowsParams) {
        const result = await getRemitConfigurations({
          organizationPaths: selectedOrganizations.map(o => o.value),
          limit: pageSize,
          offset: rowsParams.startRow,
        });

        if (result) {
          rowsParams.successCallback(
            result.records,
            result.total < rowsParams.startRow + pageSize ? result.total : -1
          );
        }
      },
    };
    params.api.setDatasource(dataSource);
  }

  function onDestinationGridReady(params: GridReadyEvent) {
    setDestinationRemitGridApi(params.api);
    params.api.sizeColumnsToFit();
  }

  function sizeColumnsToFit(params: GridReadyEvent | GridColumnsChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  function clearGrids() {
    gridApi?.setRowData([]);
    destinationRemitGridApi?.setRowData([]);
  }

  function clearAllSelections() {
    setSelectedOrganizations([]);
    setSelectedOrganizationsThrotle([]);
    setSelectedSources([]);
    onChangeDestinations([]);
    clearGrids();
  }

  interface InputCellProps extends GenericInputCellProps<CloneRemitConfiguration> { }

  const InputCell = forwardRef((params: InputCellProps, ref) => {
    const { rowIndex, colDef, onChange, isEditor } = params;
    const field = colDef.field as keyof CloneRemitConfiguration;
    const [value, setValue] = useValueRef(params.value, ref);
    const rowData = params.agGridReact.props.rowData;
    const refInput = useRef<HTMLInputElement>(null);
    useEffect(() => {
      refInput.current?.focus();
    }, []);
    return (
      <div
        className={`eds-field_#control eds-field_.eds-input eds-input ${
          isEditor ? 'editor-field' : ''
        }`}
      >
        <input
          className={'eds-input_#input'}
          ref={refInput}
          value={value}
          onChange={e => {
            const inputValue = e.target.value;
            colDef.field && onChange(rowIndex, field, inputValue, rowData);
            setValue(inputValue);
          }}
          style={{ width: '100%' }}
        />
      </div>
    );
  });

  return (
    <div className="client-configuration">
      <div className="content">
        <div className="config-header">
          <div className="title no-underline">
            <h2>Remit Management</h2>
          </div>
        </div>
        <div className="trading-controls">
          <div className="actions">
            <MultiSelect
              disableCloseOnSelect={true}
              label="Trading Partner/Facilities:"
              options={filterOrganizationOptions}
              onChange={selected => {
                setSelectedOrganizationsThrotle(selected);
              }}
              multiple={true}
              name="tradingPartner"
              values={selectedOrganizationsThrotle ?? []}
            />
            <Button
              className={'submit-button'}
              onClick={onTradingPartnerSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
        <div className={'header-row row'}>
          <p className={'heading'}>Source Remit Files</p>
        </div>
        {selectedOrganizations.length ? (
          <div className="ag-theme-alpine default-checkboxes source-grid">
            <AgGridReact
              columnDefs={[
                {
                  headerName: 'Client',
                  valueGetter: c => c.data?.organization?.path,
                  headerCheckboxSelection: true,
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection: true,
                  valueFormatter: (params: ValueFormatterParams) =>
                    getClientDisplayName(params.value),
                },
                {
                  headerName: 'File Identifier',
                  field: nameof<RemitConfiguration>('fileIdentifier'),
                },
                {
                  headerName: 'File Name',
                  field: nameof<RemitConfiguration>('fileName'),
                },
                {
                  headerName: 'Is Test',
                  field: nameof<RemitConfiguration>('isTest'),
                  width: 75,
                },
                {
                  headerName: 'Remit Configuration',
                  field: nameof<RemitConfiguration>('id'),
                  cellRenderer: 'RemitConfigurationLinkCell',
                },
              ]}
              frameworkComponents={{
                RemitConfigurationLinkCell,
              }}
              defaultColDef={{
                sortable: false,
                resizable: true,
                filter: false,
              }}
              suppressDragLeaveHidesColumns={true}
              onGridReady={onGridReady}
              onGridColumnsChanged={sizeColumnsToFit}
              pagination={false}
              rowModelType={'infinite'}
              suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
              enableCellTextSelection={true}
              ensureDomOrder={true}
              rowSelection={'multiple'}
              onSelectionChanged={e => {
                setSelectedSources(e.api.getSelectedRows());
              }}
              suppressRowClickSelection={true}
              disableStaticMarkup={true}
              singleClickEdit={true}
              cacheBlockSize={pageSize}
            />
          </div>
        ) : null}
        <div className={'row buttons-row'}>
          <div className={'button-group'}>
            <MultiSelect
              label={'Clone Destination'}
              options={organizationOptions}
              onChange={onChangeDestinations}
              name={'destination'}
              values={destinations}
              multiple={true}
            />
            <Button
              onClick={() => {
                onChangeDestinations([]);
              }}
            >
              Clear
            </Button>
          </div>
        </div>
        <div className={'header-row row'}>
          <p className={'heading'}>Destination Remit</p>
        </div>
        {cloned.length ? (
          <>
            <div className="ag-theme-alpine default-checkboxes destination-grid">
              <AgGridReact
                columnDefs={[
                  {
                    headerName: 'Client',
                    field: nameof<CloneRemitConfiguration>('organizationId'),
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true,
                    valueFormatter: (params: ValueFormatterParams) =>
                      getClientDisplayName(params.value),
                  },
                  {
                    headerName: 'File Identifier',
                    field: nameof<CloneRemitConfiguration>(
                      'fileIdentifierOverwrite'
                    ),
                    cellRenderer: 'InputCell',
                    cellEditor: 'InputCell',
                    editable: true,
                    cellEditorParams: {
                      onChange: changeDestination,
                      isEditor: true,
                    },
                  },
                  {
                    headerName: 'File Name',
                    field: nameof<CloneRemitConfiguration>('fileNameOverwrite'),
                    cellRenderer: 'InputCell',
                    cellEditor: 'InputCell',
                    editable: true,
                    cellEditorParams: {
                      onChange: changeDestination,
                      isEditor: true,
                    },
                  },
                ]}
                frameworkComponents={{
                  InputCell,
                }}
                defaultColDef={{
                  sortable: false,
                  resizable: true,
                  filter: false,
                }}
                onGridReady={onDestinationGridReady}
                onGridColumnsChanged={sizeColumnsToFit}
                rowData={cloned}
                pagination={false}
                suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
                enableCellTextSelection={true}
                ensureDomOrder={true}
                rowSelection={'multiple'}
                onSelectionChanged={e => {
                  setSelectedDestinations(e.api.getSelectedRows());
                }}
                suppressRowClickSelection={true}
                disableStaticMarkup={true}
                singleClickEdit={true}
              />
            </div>
          </>
        ) : null}
        <div className={'row buttons-row'}>
          <div className={'button-group'}>
            <Button
              onClick={() => {
                clearAllSelections();
              }}
            >
              Clear
            </Button>
            <Button
              type={'primary'}
              onClick={() => setIsConfirmationPopUpOpen(true)}
            >
              Clone
            </Button>
          </div>
        </div>
      </div>
      {isConfirmationPopUpOpen ? (
        <Popup
          header="PaymentSafe®"
          onClose={() => setIsConfirmationPopUpOpen(false)}
          title="Clone Remit Configuration"
          footer={[
            {
              type: EnumPopupButtonsType.cancel,
              style: EnumPopupButtonsStyle.secondary,
              text: 'Cancel',
            },
            {
              type: EnumPopupButtonsType.submit,
              style: EnumPopupButtonsStyle.primary,
              text: 'Confirm',
            },
          ]}
          onSubmit={onClone}
          customClassNames={{
            footerButton: 'height30',
            container: 'autoHeightWidth',
            title: 'title',
          }}
        >
          Do you want to continue cloning the remit configurations?
        </Popup>
      ) : null}
    </div>
  );
}

export function RemitConfigurationLinkCell(params: ICellRendererParams) {
  const { value } = params;
  const data = params.data as RemitConfiguration;
  const organizationPath = data?.organization?.path;
  const { getOrganizationByPath } = useOrganizations();
  const organization = getOrganizationByPath(organizationPath);
  return organization?.id ? (
    <router.Link
      to={`/admin-settings/${organization.id}/remit-configuration?isOpen=true&remitConfigurationId=${value ??
        ''}`}
    >
      View Configuration
    </router.Link>
  ) : (
    <div></div>
  );
}
