import '@experian/eds-styles/dist/eds-all.css';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import 'assets/styles/components/_authorizationModal.scss';
import React from 'react';
import 'typeface-roboto';
import { Popup } from '../../../components/popup/Popup';
import { EnumPopupButtonsStyle, EnumPopupButtonsType, EnumPopupType } from '../../../models/enums/EnumPopupType';

export const Confirmation = (props: {
  onClose: () => void;
  onAgree: () => void;
  closeOnAgree?: boolean;
  question?: string;
  explanation?: string;
  confirmationButtonText?: string;
  cancelButtonText?: string;
}) => {
  const { onClose, onAgree, closeOnAgree = true, question, explanation, confirmationButtonText, cancelButtonText } = props;

  return <Popup
    customStyle={"popup"}
    type={EnumPopupType.warning}
    onSubmit={async () => {
      onAgree();
      closeOnAgree && onClose();
    }}
    onClose={onClose}
    footer={[
      { text: confirmationButtonText ?? 'Confirm', type: EnumPopupButtonsType.submit, style: EnumPopupButtonsStyle.secondary },
      { text: cancelButtonText ?? 'Cancel', type: EnumPopupButtonsType.cancel },
    ]}
  >
    <div className="title">
      {question}
    </div>
    <div className="content">
      {explanation}
    </div>
  </Popup>;
};
