import React from 'react';
import { EDS_TextBox } from '@EH/eh.eds.react';
import styles from '../planSearch.module.scss';

export function InputField(props: {
  type?: string;
  label?: string;
  name: string;
  placeHolder: string;
  value?: string | number | undefined;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
}) {
  const {
    type = "text",
    label,
    name,
    placeHolder,
    value,
    className,
    onChange,
    error,
  } = props;

  return (
    <div className={className ? className : 'col-md-4'}>
      <EDS_TextBox
        type={type}
        label={label}
        name={name}
        placeHolder={placeHolder}
        modifiers={`${styles.filterItem} ${error ? styles.invalidField : ''}`}
        onChange={onChange}
        value={value || ''}
      />
    </div>
  );
}
