import axios from 'axios';
export interface ServiceCallResponse<T>{ result?: T; err?: string; errorResponse?: any }
export async function callHttpApi<T, D = undefined>(
  method: 'get' | 'post' | 'put' | 'delete' | 'patch',
  url: string,
  data?: D,
  params?: any,
  headers?: any,
  responseType?: any,
): Promise<ServiceCallResponse<T>> {
  try {
    const axiosRes = await axios({
      params,
      method,
      data,
      url,
      headers: {
        ...headers,
        Authorization: 'Bearer ' + localStorage.getItem('authToken'),
      },
      responseType,
    });
    const result = axiosRes.data;

    return {
      result,
    };
  } catch (err) {
    return {
      err: err.message,
      errorResponse: err.response,
    };
  }
}
