import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatientModel, PaymentOnFile } from 'models/PatientModel';

export interface State {
  patientSelectedPaymentsOnFile: number[];
  records: PatientModel[];
  modalOpen: boolean;
}

export const initialState: State = {
  records: [],
  modalOpen: false,
  patientSelectedPaymentsOnFile: []
};

const reducerSlice = createSlice({
  name: 'Patients',
  initialState,
  reducers: {
    replaceRecords(state, action: PayloadAction<PatientModel[]>) {
      state.records = action.payload;
    },
    updateRecord(
      state,
      action: PayloadAction<{ id: string; patient: PatientModel }>
    ) {
      if (!state.records) {
        state.records = [];
      }
      const idx = state.records.findIndex(
        (patient: PatientModel) => patient.id === action.payload.id
      );
      if (idx >= 0) {
        state.records[idx] = action.payload.patient;
        
      } else {
        state.records.push(action.payload.patient);
      }
    },
    replacePaymentsOnFile(
      state, 
      action: PayloadAction<{ patientId: string; paymentsOnFile?: PaymentOnFile[] }>
      ) {
        const idx = state.records.findIndex(
          (patient: PatientModel) => patient.id === action.payload.patientId
        );
        if (idx >= 0) {
          state.records[idx].paymentsOnFile = action.payload.paymentsOnFile;
        }
    },
    updatePlans(
      state, 
      action: PayloadAction<{ patientId: string; organizationPath : string; patientPlans: (string | null)[] }>
      ) {
        const idx = state.records.findIndex(
          (patient: PatientModel) => patient.id === action.payload.patientId
        );
        if (idx >= 0) {          
          if(!state.records[idx].plans?.[action.payload.organizationPath]){
            const plans : Record<string , (string | null)[]> = {  };
            plans[action.payload.organizationPath] = action.payload.patientPlans;
            state.records[idx].plans = plans;  
           }
           else{
            const plans : Record<string , (string | null)[]> =  state.records[idx].plans;
            plans[action.payload.organizationPath] = action.payload.patientPlans;
            state.records[idx].plans = plans;
           }
        }
    },
    setOpenPatientInfoModal(state, action: PayloadAction<boolean>) {
      state.modalOpen = action.payload;
    },
    setPatientSelectedPaymentsOnFile(state, action: PayloadAction<number[]>) {
      state.patientSelectedPaymentsOnFile = action.payload;
    },
  },
});

export const {
  replaceRecords,
  updateRecord,
  setOpenPatientInfoModal,
  setPatientSelectedPaymentsOnFile,
  replacePaymentsOnFile,
  updatePlans
} = reducerSlice.actions;
export default reducerSlice.reducer;
