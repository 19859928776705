import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { EDS_Button, EDS_Select } from '@EH/eh.eds.react';
import { useTypedSelector } from 'app/rootReducer';
import Print from 'features/print/print';
import { EmailReceipt } from 'features/emailReceipt/EmailReceipt';
import Footer from 'features/footer/Footer';
import { displayAmount, ApiTenderTypeEnumLabels, ApiTenderTypeEnum, maskECheckNumber, formatDate, getBalance as getLineItemsBalance, languageOptions, checkReceivedHasValues, getValueOrDefault, checkStringHasValue } from 'utils/Utils';
import { convertDateToDisplayString, convertStringToDate } from 'utils/UtilsDateTime';
import { LineItem } from 'models/LineItem';
import { PaymentPlanModel } from 'models';
import { PlanOperations } from 'models/metaData/MetaDataEnums';
import BalanceHeader from './infoSections/BalanceHeader';
import { getBalance } from './useTotal';
import { displayTermType, getRowValue, getTenderType } from './PlanUtils';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'assets/styles/components/_paymentPlan.scss';
import { sendEmailsPaymentPlanService } from 'services/PaymentPlanService';
import { useGetPatientById } from 'features/patients/hooks/useGetPatientById';
import { CreditCardAdditionalData } from 'models/PaymentPlan';
import { useDispatch } from 'react-redux';
import { AlertIds, AlertTypes, setAlert } from '../alert/AlertReducer';
import { EnumTermType } from 'models/enums/EnumPaymentPlan';
import { PopoverClientElement } from 'pages/Search/simpleSearch/MainFielters/SearchByPatientInformation';
import { toolTipMessages } from 'pages/Search/advanceSearch/MainFilters/SearchByTransactionDetails';
import { getReceiptManager } from 'features/admin/receiptManager/ReceiptManagerReducer';
import { ReceiptManagerResponse } from 'services/ReceiptManagerService';
import { ReceiptManager } from 'models/ReceiptManagerModel';
import { getEmptyModel } from 'pages/Admin/ReceiptManager';
import { getLogoDataForOrganization } from 'features/admin/logoManager/LogoManagerReducer';
import useGetConfiguration from '../admin/paymentPanel/serviceHandlers/useGetConfiguration';
import TransactionDetailsPrint from 'features/transactionDetails/TransactionDetailsPrint';
import {useGetReceipts} from 'features/admin/receipts/useGetReceipts';

const messagePlanCreated = 'Payment plan has been created.';
const messagePlanUpdated = 'Payment plan has been updated.';

export interface ParamTypes {
  id: string;
}

export default (props: {
  done?: () => void;
  paymentPlan?: PaymentPlanModel;
  total?: number;
  amount?: number;
  discount?: number;
  patientId?: string;
  facilityInfo?: any;
}) => {
  const dispatch = useDispatch();
  const payerInfo = useTypedSelector(s => s.patientMailingInformation?.value);
  const planOperation = useTypedSelector(s => s.paymentPlanInfo?.operation);

  const { done, paymentPlan, total, discount, patientId, facilityInfo } = props;
  const { getPanelConfiguration } = useGetConfiguration(facilityInfo?.path);

  const configuration = useTypedSelector(s => s.paymentDashboard.panelConfigurations[getValueOrDefault(facilityInfo.path, '')]?.configuration);
  const paymentAttributes = getValueOrDefault(configuration?.entity?.attributes, []);

  const message = checkReceivedHasValues(planOperation === PlanOperations.CreatePlan, messagePlanCreated, messagePlanUpdated);

  const [printReceiptUrl, setPrintReceiptUrl] = useState<string>('');
  const [showNotesPopup, setShowNotesPopup] = useState(false);

  const [isEmailPopupOpened, setIsEmailPopupOpened] = useState(false);
  const [emailReceiptSent, setEmailReceiptSent] = useState(false);
  const [emailError, setEmailError] = useState<string>('');
  const [language, setLanguage] = useState<string>('english');
  const [receiptManagerValues, setReceiptManagerValues] = useState<ReceiptManager>({});

  const { printPaymentPlanReceipt } = useGetReceipts();

  const handlePrint = async()=>{ 
    const receiptURL = await printPaymentPlanReceipt(paymentPlan?.id , language);
    if(receiptURL){
      setPrintReceiptUrl( receiptURL)
    }
  };

  const planCreatedAlert = { id: AlertIds.PlanCreatedAlert, type: AlertTypes.Success, message, dismissable: true };
  useEffect(() => {
    dispatch(setAlert(planCreatedAlert));
    paymentPlan?.organization?.path && dispatch(getLogoDataForOrganization(paymentPlan?.organization?.path));
    loadReceiptManagerConfiguration(getValueOrDefault(paymentPlan?.organization?.path, ''))
    getPanelConfiguration("PaymentPlanDashboard")
  }, []);

  useEffect(() => {
    loadReceiptManagerConfiguration(getValueOrDefault(facilityInfo?.path, ''));
  }, [dispatch, facilityInfo?.path]);

  const { patient } = useGetPatientById(patientId);

  const formatBoldValue = (string: string) => {
    const delimiterIndex = string.lastIndexOf('.');
    const boldPart = string.substring(0, delimiterIndex);
    const restPart = string.substring(delimiterIndex);
    return (<>
      <span className='value-bold'>{boldPart}</span>
      {restPart}
    </>);
  };

  const loadReceiptManagerConfiguration = (organizationPath: string) => {
    dispatch(getReceiptManager(organizationPath, (response?: ReceiptManagerResponse) => {
      if (response && !response.err && response.result !== undefined) {
        setReceiptManagerValues(getValueOrDefault(response.result, getEmptyModel()));
      }
    }));
  }

  const sendEmailReceipt = async (data: string[]) => {
    const result = paymentPlan?.id && await sendEmailsPaymentPlanService(paymentPlan?.id, data);

    if (result && result.err) {
      setEmailError(result.err);
      return;
    } 
      setEmailReceiptSent(true);
  };

  const closeEmailPopup = () => {
    setIsEmailPopupOpened(false);
    setEmailReceiptSent(false);
  };

  const openNotesPopup = () => setShowNotesPopup(true);
  const closeNotesPopup = () => setShowNotesPopup(false);
  const renderPopup = (value: string) => (
    <div className='notes-popup'>
      <button className='button-close material-icons' onClick={closeNotesPopup}>close</button>
      <div className=''>{value}</div>
    </div>
  );

  const tenderType = getTenderType(paymentPlan, patient);

  const showLanguageSelect = (hasMultilingualUnifiedReceipts?: boolean) => {
    if(hasMultilingualUnifiedReceipts){
      return (
        <EDS_Select
              name={'language'}
              label={''}
              options={languageOptions}
              value={language}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setLanguage(e.target.value)}
          />
      )
    }
  }

  const footerChildren = (
    <div className="footer-buttons">
      <div className="left-side-buttons">
        <EDS_Button
          modifiers={'mr-3 eds-button eds-button.tertiary'}
          name={'email'}
          iconName={'email'}
          buttonText={'Email'}
          onClick={() => setIsEmailPopupOpened(true)}
        />
        <Print className="print-button" handleClick={handlePrint} ariaLabel="Print" />
      </div>
      {
       showLanguageSelect(receiptManagerValues.generalSettings?.hasMultilingualUnifiedReceipts)
      }
      <div className="btnItem next-button">
        <EDS_Button
          modifiers={'mr-3 eds-button eds-button.primary done-button'}
          name={'done'}
          buttonText={'Done'}
          onClick={done}
        />
      </div>
    </div>
  );

  const getPaymentPlanTableHeaderRows = () => {
    return (
      <tr>
        <th>Collection Date</th>
        <th>Account Number</th>
        <th>Payment Amount</th>
      </tr>
    )
  }

  const getPaymentPlanBodyRows = () => {
    return paymentPlan?.lineItems?.map((row: LineItem) => (
      <tr key={row._id}>
        <td>{moment(row.collectionDate).format('MM/DD/YYYY')}</td>
        <td>{row.accountNumber ?? ''}</td>
        <td>${getBalance({ ...row, isActive: true }, paymentPlan?.isGl, planOperation === PlanOperations.AddToPlan).toFixed(2)}</td>
      </tr>
    ))
  }

  const getGlPlanTableHeaderRows = () => {
    const attributeNames = paymentAttributes?.map((attr: any) => attr.label);

    return <tr className="table-header"> 
        {attributeNames?.map((header: string) => (
          <th key={header} className="cell">
            <div>
              {header}
            </div>
          </th>
        ))}
      </tr>
  }


  const getGlTableBodyRows = () => {
    return paymentPlan?.lineItems?.map((lineItem) => (
      <tr key={lineItem._id}>
        {paymentAttributes?.map((attr: any) => (
          <td key={attr.name}>{getRowValue(lineItem, attr.name, getValueOrDefault(paymentPlan?.isGL, false))}</td>
        ))}
      </tr>
    ));
  }

  let termTypeDisplay = displayTermType(getValueOrDefault(paymentPlan?.paymentsRemaining, 1), getValueOrDefault(paymentPlan?.termType, EnumTermType.None));
  let termMultiplier = 1;
  if (termTypeDisplay?.startsWith('bi-week')) {
    termMultiplier = 2;
    termTypeDisplay = 'weeks';
  }

  const paymentTerm = `${(getValueOrDefault(paymentPlan?.paymentsRemaining, 1)) * termMultiplier} ${termTypeDisplay}`
  const firstName = checkReceivedHasValues(paymentPlan?.billingInformation?.firstName, paymentPlan?.billingInformation?.firstName, paymentPlan?.mailingInformation?.firstName);
  const lastName = checkReceivedHasValues(paymentPlan?.billingInformation?.lastName, paymentPlan?.billingInformation?.lastName, paymentPlan?.mailingInformation?.lastName);
  
  return (
    <>
      <div className="payment-plan-container">
        <div className="payment-plan-form-container">
          <BalanceHeader total={getLineItemsBalance(getValueOrDefault(paymentPlan?.lineItems, []))} amount={paymentPlan?.amount} discount={discount} isGl={paymentPlan?.isGl}/>
          <div className="flex-row">
            <div className="row-item row-item-size-double">
              <div className="eds-heading eds-heading.lg-caps mb-1">Payment Plan Created</div>
            </div>
          </div>
          <div className="flex-row">
            <div className="row-item row-item-size-double">
              <div className="eds-heading eds-heading.mdplus-caps mb-1">Payment Information</div>
              <div className="row">
                <div className="col-5 confirmation-label">Status:</div>
                <div className="col-7">{paymentPlan?.status}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Plan ID:</div>
                <div className="col-7">{paymentPlan?.id}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">
                  Transaction ID:
                  <span className="help">
                    <PopoverClientElement
                      placement="top"
                      content={toolTipMessages.transaction}
                    />
                  </span>
                </div>
                <div className="col-7">{paymentPlan?.walletId}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Created On:</div>
                <div className="col-7">{convertDateToDisplayString(convertStringToDate(checkStringHasValue(paymentPlan?.createdDate)))}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Tender Type:</div>
                <div className="col-7">{ApiTenderTypeEnumLabels[tenderType]}</div>
              </div>
              {tenderType === ApiTenderTypeEnum.ECheck
                ? <>
                  {paymentPlan?.tender?.routingNumber && <div className="row">
                    <div className="col-5 confirmation-label">Routing Number:</div>
                    <div className="col-7">{maskECheckNumber(paymentPlan?.tender?.routingNumber)}</div>
                  </div>}
                  <div className="row">
                    <div className="col-5 confirmation-label">Account Number:</div>
                    <div className="col-7">{paymentPlan?.tenderMaskedAccount}</div>
                  </div>
                  {paymentPlan?.tender?.financialInstitution && <div className="row">
                    <div className="col-5 confirmation-label">Financial Institution:</div>
                    <div className="col-7">{paymentPlan?.tender?.financialInstitution}</div>
                  </div>}
                </>
                : <>
                  <div className="row">
                    <div className="col-5 confirmation-label">Card Type:</div>
                    <div className="col-7">{paymentPlan?.cardBrand}</div>
                  </div>
                  <div className="row">
                    <div className="col-5 confirmation-label">Card Number:</div>
                    <div className="col-7">{paymentPlan?.tenderMaskedAccount}</div>
                  </div>
                  <div className="row">
                    <div className="col-5 confirmation-label">Expiration Date:</div>
                    <div className="col-7">{moment((paymentPlan?.tenderAdditionalData as CreditCardAdditionalData)?.ExpirationDate).format('MM/YY')}</div>
                  </div>
                  <div className="row">
                    <div className="col-5 confirmation-label">Payment Amount:</div>
                    <div className="col-7">${displayAmount((getValueOrDefault(total, 0)) / (getValueOrDefault(paymentPlan?.paymentsRemaining, 1)))}/{displayTermType(1, getValueOrDefault(paymentPlan?.termType, EnumTermType.None))}</div>
                  </div>
                  <div className="row">
                    <div className="col-5 confirmation-label">Next Payment Date:</div>
                    <div className="col-7">{formatDate(moment(paymentPlan?.nextPaymentDate))}</div>
                  </div>
                  <div className="row">
                    <div className="col-5 confirmation-label">Payment Term:</div>
                    <div className="col-7">{paymentTerm}</div>
                  </div>
                  <div className="row">
                    <div className="col-5 confirmation-label">Balance:</div>
                    <div className="col-7">${displayAmount(total)}</div>
                  </div>
              </>}
            </div>
          </div>
          <hr />
          <div className="flex-row">
            <div className="row-item row-item-size-double">
              <div className="eds-heading eds-heading.mdplus-caps mb-1">Payment Plan Summary</div>
            </div>
          </div>
          <div className="flex-row">
            <table className={'row-item row-item-size-double payment-summary-table'}>
              <thead>
              {
                checkReceivedHasValues(paymentPlan?.isGL, getGlPlanTableHeaderRows(), getPaymentPlanTableHeaderRows())
              }
              </thead>
              <tbody>
                {
                  checkReceivedHasValues(paymentPlan?.isGL, getGlTableBodyRows(), getPaymentPlanBodyRows())
                }
              </tbody>
            </table>
          </div>
          <div className="flex-row">
            <div className="row-item row-item-size-double">
              <div className="eds-heading eds-heading.mdplus-caps right-align">Payment Total: ${formatBoldValue(displayAmount(total))}</div>
            </div>
          </div>
          <hr />
          <div className="flex-row">
            <div className="row-item row-item-size-double">
              <div className="eds-heading eds-heading.mdplus-caps mb-1">Payer Information</div>
              <div className="row">
                <div className="col-5 confirmation-label">Payer First Name:</div>
                <div className="col-7">{firstName}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Payer Last Name:</div>
                <div className="col-7">{lastName}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Address:</div>
                <div className="col-7">{paymentPlan?.billingInformation?.addressLine1}, {paymentPlan?.billingInformation?.addressLine2}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">City:</div>
                <div className="col-7">{paymentPlan?.billingInformation?.city}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">State:</div>
                <div className="col-7">{paymentPlan?.billingInformation?.state}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Zip:</div>
                <div className="col-7">{paymentPlan?.billingInformation?.zipCode}</div>
              </div>
              {showNotesPopup && payerInfo?.notes && renderPopup(payerInfo?.notes)}
              <div className="row">
                <div className="col-5 confirmation-label">Notes:</div>
                <div className="col-7 notes-content" onClick={openNotesPopup}>{payerInfo?.notes}</div>
              </div>
            </div>
          </div>
          <p>"Thank you for your payment" message from admin.</p>

          {isEmailPopupOpened && <EmailReceipt
            defaultEmail={paymentPlan?.notificationEmail}
            emailReceiptSent={emailReceiptSent}
            onClose={closeEmailPopup}
            onSubmit={sendEmailReceipt}
            error={emailError}
          />}

        {printReceiptUrl && <TransactionDetailsPrint receiptUrl={printReceiptUrl}/> }
        </div>
      </div>
      <Footer children={footerChildren} />
    </>
  );
};
