import { Metadata } from "../whitelist/WhitelistReducer";
import { v4 as uuid } from 'uuid';

export function MappingActions<T extends { id?: string, metadata?: Metadata }>(props: {
    dataRef: { current: T[] },
    validateAll: (validOnly: boolean, data: T) => boolean,
    setFilteredMappings: (mappings: T[]) => void,
    saveMappings: (mappings: T[]) => void,
    getUpdatedMappings: () => T[],
    filteredMappings: T[],
    selectedMappings: string[],
    setSelectedMappings: (selected: string[]) => void,
    deleteMappings: (mappings: string[]) => void,
    createNewMapping: (tempId: string) => T
}
) {
    const { dataRef, deleteMappings, filteredMappings, getUpdatedMappings, saveMappings, selectedMappings, setFilteredMappings, setSelectedMappings, validateAll, createNewMapping } = props;

    const onSaveAction = () => {
        const touched = dataRef.current.filter(m => m.metadata?.isEditing);
        let valid = true;
        touched.forEach(m => (valid = valid && validateAll(false, m)));
        if (valid) {
            saveMappings([...touched ?? []]);
        } else {
            setFilteredMappings([]);
            window.setTimeout(() => { setFilteredMappings(getUpdatedMappings()) }, 0);
        }
    }

    const onEditAction = () => {
        const updated = [...filteredMappings]
        selectedMappings.forEach(id => {
            const idx = updated.findIndex(m => m.id === id)
            if (updated[idx]) {
                updated[idx].metadata = {
                    ...updated[idx].metadata,
                    isEditing: true
                };
            }
            setFilteredMappings(updated);
        })
    }

    const onDeleteAction = () => {
        deleteMappings(selectedMappings)
        setSelectedMappings([]);
    }

    const onAddNewAction = (addCount: number) => {
        const newMappings = [];
        for (let i = 0; i < addCount; i++) {
            newMappings.push(createNewMapping(uuid()));
        }
        setFilteredMappings([]);
        const updatedMappings = [...newMappings, ...getUpdatedMappings()]
        setFilteredMappings(updatedMappings);
        dataRef.current = updatedMappings;
    }

    return {
        onEditAction,
        onSaveAction,
        onDeleteAction,
        onAddNewAction
    }
}