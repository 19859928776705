import { useMemo } from 'react';
import { useTypedSelector } from '../../app/rootReducer';
import { PaymentPlanModel } from '../../models';
import { LineItem } from '../../models/LineItem';
import { calculateBalance, calculateLineItemPatientBalance, LineItemType } from 'features/paymentDashboard/LineItemComponent';
import { getAmount as  getLineItemsAmount, getBalance as getLineItemsBalance, getGlBalance as getLineItemsGlBalance} from '../../utils/Utils';

function getRegularBalance(lineItem?: LineItem, isAddToPlan?: boolean) {
  if (lineItem?.isActive) {
    if (isAddToPlan) {
      return lineItem?.balance ?? 0;
    }

    const value = +(lineItem.amount || 0) - (lineItem.discount || 0);
    if (value && !isNaN(value)) {
      return value;
    }
  }
  return 0;
}

function getRegularPaymentAmount(lineItem?: LineItem, isAddToPlan?: boolean) {
  if (lineItem?.isActive) {
    if (isAddToPlan) {
      return lineItem?.balance ?? 0;
    }

    const value = +(lineItem.paymentAmount || lineItem.amount) - (lineItem.discount || 0);
    if (!isNaN(value)) {
      return value;
    }
  }
  return 0;
}

function getGlBalance(lineItem?: LineItem) {
  if (lineItem?.isActive) {
    let value = +(Number(lineItem.glUnits) || 0) * +(lineItem.unitCost || 0);
    let discount = lineItem.discount ? lineItem.discount : 0;
    value = value - discount;
    return isNaN(value) ? 0 : value;
  }
  return 0;
}

export function getBalance(lineItem?: LineItem, isGl = false, isAddToPlan = false) {
  return isGl ? getGlBalance(lineItem) : getRegularBalance(lineItem, isAddToPlan);
}

export function getPaymentAmountBalance(lineItem?: LineItem, isGl = false, isAddToPlan = false) {
  return isGl ? getGlBalance(lineItem) : getRegularPaymentAmount(lineItem, isAddToPlan);
}

export function useTotal(
  paymentPlan?: PaymentPlanModel,
  isGl: boolean = false,
  forPlan: boolean = false,
  forAll: boolean = false
) {
  const dashboards = useTypedSelector(s => s.paymentDashboard?.values);
  const values = Object.keys(dashboards).map((key) => { return dashboards[key] });
  const lineItemsList = values.map((mapped) => { return mapped?.lineItems|| [] });
  let paymentPlanTotal = useMemo(() => {
    return (paymentPlan?.lineItems || []).reduce(
      (sum, lineItem) => sum + Number(lineItem?.balance || 0),
      0
    );
  }, [paymentPlan?.lineItems]);

  const total = useMemo(() => {
    if (lineItemsList) {  
      return lineItemsList?.reduce((totalSum, lineItemList) => totalSum + lineItemList.reduce(
        (sum, lineItem) => sum + getBalance(lineItem, isGl),
        0
      ),0);  
    }
    return 0;
  }, [lineItemsList, isGl]);

  if (forAll){
    return paymentPlanTotal + total;
  } 
  return forPlan && paymentPlanTotal ? paymentPlanTotal : total;
}

function getRegularGrossBalance(lineItem?: LineItem) {
  if (lineItem?.isActive) {
    const value = Number(lineItem.amount || 0);
    if (value && !isNaN(value)) {
      return value;
    }
  }
  return 0;
}

function getGlGrossBalance(lineItem?: LineItem) {
  if (lineItem?.isActive) {
    const value = Number(lineItem.glUnits ?? 0) * Number(lineItem.unitCost || 0);
    return isNaN(value) ? 0 : value;
  }
  return 0;
}

export function getGrossBalance(lineItem: LineItem, isGl = false) {
  return isGl ? getGlGrossBalance(lineItem) : getRegularGrossBalance(lineItem);
}

export function useGrossTotal(
  paymentPlan?: PaymentPlanModel,
  isGl = false,
  forPlan?: boolean
) {
  const dashboards = useTypedSelector(s => s.paymentDashboard?.values);
  const values = Object.keys(dashboards).map((key) => { return dashboards[key] });
  const lineItemsList = values.map((mapped) => { return mapped?.lineItems|| [] });
  const paymentPlanTotal = useMemo(() => {
    return (paymentPlan?.lineItems ?? []).reduce(
      (sum, lineItem) => sum + Number(lineItem?.amount || 0),
      0
    );
  }, [paymentPlan?.lineItems]);

  const total = useMemo(() => {
    if (lineItemsList) {   
      return lineItemsList?.reduce((totalSum, lineItemList) => totalSum + lineItemList.reduce(
        (sum, lineItem) => sum + getGrossBalance(lineItem, isGl),
        0
      ),0);  
      }
      return 0;
  }, [lineItemsList, isGl]);

  if (!isGl){
    return forPlan ? paymentPlanTotal : total
  } 
  return paymentPlanTotal + total;
}

export function getTotalSum(
  lineItems: (LineItem | null)[],
  paymentPlans: PaymentPlanModel[],
  property: "balance" | "amount",
  selectedPaymentPlans: (string | null)[],
  forPlan: boolean = false,
  isCreatePlan: boolean = false,
  isEditing:boolean=false,
  isGl:boolean=false
) {
  let lineItemsSum = lineItems
  .filter(item => !item?.isEditing && item?.isActive)
  .reduce(
      (sum, lineItem) => sum + Number(lineItem?.[property] || 0),
      0
    );
  let paymentPlansLineItemsSum = paymentPlans?.filter(item => !item?.isActive) .reduce(
    (sum, paymentPlan) =>  Number(sum) + Number(getLineItemsAmount(paymentPlan.lineItems?.filter(item => item?.isActive) ?? [])),
    0
  );
  let paymentPlansSum = paymentPlans.filter(plan => plan?.isActive).reduce(
    (sum, paymentPlan) =>  Number(sum) + Number(paymentPlan?.paymentAmount ?? 0),
    0
  );
  
  //In case of adding a new account to an existing payment plan
  //Use balance of line items as this reflects the real value after a payment was made on the paymen plan
  let addToPlanLineItemsSum = 0;
  if (isGl) {
    addToPlanLineItemsSum = paymentPlans?.filter(item => selectedPaymentPlans?.includes(item?.id)).reduce(
      (sum, paymentPlan) =>  Number(sum) + Number(getLineItemsGlBalance(paymentPlan.lineItems ?? [])),
      0
    );
  } else {
    addToPlanLineItemsSum = paymentPlans?.filter(item => selectedPaymentPlans?.includes(item?.id)).reduce(
      (sum, paymentPlan) =>  Number(sum) + Number(getLineItemsBalance(paymentPlan.lineItems ?? [])),
      0
    );
  }

  //Adhoc payments
  let planTotal = paymentPlansLineItemsSum + paymentPlansSum;
  if (isCreatePlan){
    planTotal = (paymentPlansLineItemsSum + paymentPlansSum)+lineItemsSum;
  }

  if (isEditing || (isCreatePlan && paymentPlans?.filter(item => item?.id && selectedPaymentPlans?.includes(item?.id)).length > 0)){
    planTotal = addToPlanLineItemsSum ;
  }

  return forPlan ? planTotal : lineItemsSum
}

export function getBalanceSumForPayment(
  lineItems: (LineItem | null)[]
) {
  return lineItems
  .filter(item => !item?.isEditing &&  (item?.isActive || item?.pahAccount))
  .reduce(
      (sum, lineItem) => sum + Number(lineItem?.balance || 0),
      0
    );
}

export function getBalanceSumForPlan(
    paymentPlans: PaymentPlanModel[],
    selectedPaymentPlans: (string | null)[],
  ) {
    const sumFunction = getLineItemsBalance;    
    let plansToSum = selectedPaymentPlans.length > 0 ? paymentPlans.filter(item => selectedPaymentPlans?.includes(item?.id)) : paymentPlans;

    let paymentPlansSum = plansToSum?.filter(item => item.isActive).reduce(
      (sum, paymentPlan) =>  Number(sum) + Number(paymentPlan?.balance ?? 0),
      0
    );

    let paymentPlansLineItemsSum = plansToSum?.filter(item => !item.isActive).reduce(
      (sum, paymentPlan) =>  Number(sum) + Number(sumFunction(paymentPlan.lineItems?.filter(item => item?.isActive)??[])),
      0
    );

    //Adhoc payments
    let planTotal = paymentPlansSum + paymentPlansLineItemsSum;

    return planTotal;
}

export function getAccountBalanceForAddToPlan(
  paymentPlans: PaymentPlanModel[],
  selectedPaymentPlans: (string | null)[],
) {
  return paymentPlans?.filter(item => item?.id && selectedPaymentPlans?.includes(item?.id)).reduce(
    (sum, paymentPlan) =>  Number(sum) + Number(paymentPlan.balance ?? 0),
    0
  );
}

export function getTotalLineItemAmount(
  lineItems: (LineItem | null)[],
  isGl?: boolean,
  isPlan?:boolean){

  let type = isGl ? LineItemType.Gl : LineItemType.Normal;

  if (isPlan) {
    type = LineItemType.Plan;
  }

  const lineItemsBalanceDueSum = lineItems
  .filter(item => !item?.isEditing && item?.isActive)
  .reduce(
    (sum, lineItem) => sum + (calculateBalance(type, lineItem)),
    0
  );
return lineItemsBalanceDueSum;
}

export function getTotalDiscount(
  lineItems: (LineItem | null)[],
  paymentPlans: PaymentPlanModel[],
  forPlan: boolean = false
) {
  let lineItemsDiscountSum = lineItems
    .filter(item => (item?.isActive || item?.pahAccount) && !item?.isEditing)
    .reduce(
      (sum, lineItem) => sum + Number(lineItem?.discount || 0),
      0
    );
  let paymentPlansDiscountSum = paymentPlans.reduce(
    (sum, paymentPlan) => sum + (paymentPlan.lineItems?.filter(item => item.isActive).reduce(
      (sumOfLineItems, lineItem) => sumOfLineItems + Number(lineItem.discount || 0),
    0) || 0),
    0
  );
  return forPlan ? paymentPlansDiscountSum : lineItemsDiscountSum
}

export function getTotalBalanceDue(
  lineItems: (LineItem | null)[],
  paymentPlans: PaymentPlanModel[],
  isGl: boolean = false,
  forPlan: boolean = false,
  isNewPlan:boolean = false,
  selectedPaymentPlans:(string | null)[] = []
) {
  let plansToSum = selectedPaymentPlans.length > 0 ? paymentPlans.filter(item => selectedPaymentPlans?.includes(item?.id)) : paymentPlans;
  let type = isGl ? LineItemType.Gl : LineItemType.Normal;

  if (forPlan && !isNewPlan) {
    type = LineItemType.Plan;
  }
  const lineItemsBalanceDueSum = lineItems
    .filter(item => (item?.isActive) && !item?.isEditing)
    .reduce(
      (sum, lineItem) => sum + (calculateBalance(type, lineItem)), 0
    );
  if (!isGl && !forPlan){
    return lineItemsBalanceDueSum
  } 
  const paymentPlansBalanceDueSum = isGl ? 0 : plansToSum.reduce(
    (sum, paymentPlan) => sum + (calculateBalance(LineItemType.Plan, {amount: 0, lineItems: paymentPlan.lineItems}) || 0),
    0
  );
  return forPlan && !isNewPlan ? paymentPlansBalanceDueSum : lineItemsBalanceDueSum;
}

export function getAccountBalanceDue(
  lineItems: (LineItem | null)[],
) {
  const lineItemsBalanceDueSum = lineItems
    .filter(item => (item?.isActive) && !item?.isEditing)
    .reduce(
      (sum, lineItem) => sum + (calculateLineItemPatientBalance(lineItem)), 0
    );

  return lineItemsBalanceDueSum;
}

export function getShowDiscount(paymentAmount: number | undefined, discountValue: number) {
  return paymentAmount && paymentAmount > 0 && discountValue > 0;
}

export function getPaymentBalance(isPayment: boolean, isEditing: boolean | undefined, totalValue: number | undefined, paymentPlanActiveTotalBalance: number) {
  return isPayment || isEditing ? totalValue : paymentPlanActiveTotalBalance;
}

export function getPaymentAmount(isPayment: boolean, isEditing: boolean | undefined, totalValue: number | undefined, paymentPlanActiveTotalAmount: number) {
  return isPayment || isEditing ? totalValue : paymentPlanActiveTotalAmount;
}

export function getDiscountAmount(isPayment: boolean, isEditing: boolean | undefined, discountValue: number | undefined, paymentPlanActiveTotalDiscount: number) {
  return isPayment || isEditing ? discountValue : paymentPlanActiveTotalDiscount;
}