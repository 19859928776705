import { GenericAttribute } from "../../../../models/metaData/MetaData";
import { DataType } from "../../../../models/metaData/MetaDataEnums";
import { EditableEpicTokenUserMapping } from "../../../../pages/Admin/Epic/EpicTokenUserMappingPage";
import { useGenericGridFormValidator } from "../../../../utils/useGenericGridFormValidator";
import { required } from "../../../paymentDashboard/Validators";
import { duplicateValidator } from "../../glList/useGlListManagerValidator";

export const useTokenUserMappingValidator = (mappings: EditableEpicTokenUserMapping[]) => {

  const attributes: GenericAttribute<EditableEpicTokenUserMapping>[] = [
    { name: "mapKey", dataType: DataType.String, validators: [required(), duplicateValidator(mappings, "mapKey", "id")] },
  ];
  const props = useGenericGridFormValidator(attributes);
  return { ...props };
}