import { ServerPaymentModel } from 'models/PaymentModel';
import { callHttpApi } from 'services/callHttpApi';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import { CreateTransactionResult } from './models/CreateTransactionResult';
import {
  EnumTransactionStatusType,
} from 'models/enums';
import MoneyOrderTransactionRequest from './models/MoneyOrderTransactionRequest';
import EnumTransactionTenderType from 'models/enums/EnumTransactionTenderType';
import { EnumTransactionType } from 'models/enums/EnumTransactionType';

const fakeRequestData = false;
const fakeResponseData = false;

export async function CreateMoneyOrderTransaction(
  payment: ServerPaymentModel
): Promise<CreateTransactionResult> {
  if (!fakeResponseData) {
    return callHttpApi<
      TransactionDetailsModel,
      MoneyOrderTransactionRequest
    >(
      'post',
      `api/v1/Transactions/payment`,
      mapPaymentToMoneyOrderTransactionRequest(payment)
    );
  } else {
    return {
      result: {
        id: '5f3ac6b01ecb544c3cf91a65',
        transactionType: EnumTransactionType.Payment,
        amount: payment.paymentTotal,
        statusType: EnumTransactionStatusType.Submitted,
        gatewayReferenceNumber: '000002',
      },
    };
  }
}

function mapPaymentToMoneyOrderTransactionRequest(
  payment: ServerPaymentModel
): MoneyOrderTransactionRequest {
  if (!fakeRequestData) {
    return {
      PatientAccountNumber: payment.patientAccountNumber,
      TenderMaskedAccount: payment.typeMoneyOrder?.serialNumber,
      Amount: payment.paymentTotal,
      PaymentClientId: payment.clientID,
      Organization: payment.organization,
      GuarantorId: payment.guarantorId,
      PaymentSource: payment.paymentSource,
      ProcessTransactionType: EnumTransactionTenderType.MoneyOrder,
      PayerFirstName: payment.billingInformation?.firstName,
      PayerLastName: payment.billingInformation?.lastName,
      MRN: payment.mrn,
      PatientId: payment.patientId,
      PatientFirstName: payment.patientFirstName,
      PatientLastName: payment.patientLastName,
      PatientDateOfBirth: payment.patientDateOfBirth,
      BillingInformation: payment.billingInformation,
      MailingInformation: payment.mailingInformation,
      Notes: payment.notes,
      IsGL: payment.isGL,
      LineItems: payment.isGL ? payment.glDetails : payment.details,
      Discount: payment.discount,
      NotificationEmail: payment.notificationEmail,
      TenderAdditionalData: {
        PostOffice: payment.typeMoneyOrder?.postOffice ?? '',
        IssueDate: payment.typeMoneyOrder?.issueDate ?? '',
      },
      paymentPlanId: payment.paymentPlanId,
      totalPlanBalance: payment.totalPlanBalance,
      Department: payment.department,
      Facility: payment.facility,
      TransactionGroupID: payment.transactionGroupId,
    };
  } else {
    return {
      Amount: 100,
    };
  }
}
