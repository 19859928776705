import { useTypedSelector } from "app/rootReducer";
import { PatientModel } from "../../../models/PatientModel";

export function useGetPatientById(id?: string ) {
  let patientIdx = useTypedSelector(s =>
    s.patients?.records?.findIndex(
      (p: PatientModel) => {
        return p.id == id
      }
    ));

  let patient = useTypedSelector(s => {
    if (patientIdx != undefined) {
      return (s.patients?.records || [])[patientIdx]
    }
  });

  return { patient, patientIdx};
}