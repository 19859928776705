import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'typeface-roboto';
import '../../../assets/styles/components/_AddRemoveFacilityModal.scss';
import { Button } from '@EHDS/core';
import { AlertIds, AlertTypes, setAlert } from '../../../features/alert/AlertReducer';
import { ReactComponent as Close } from 'assets/svgs/icon-popup-close.svg';
import {MultiSelectOption} from 'components/select/MultiSelect';
import { useOrganizations } from "../../../features/organizations/hooks/useOrganizations";
import { FullOrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { DepartmentMultiselectOption } from '../../Search/simpleSearch/OrganizationUserSearch';
import { UserDetailModel } from 'models/UserInfoAndRolesModel';
import { EDS_TextArea } from '@EH/eh.eds.react';
import {AddRemoveFacilityRequestModel,AddFacilityToUserService,RemoveFacilityToUserService} from '../../../services/UsersService';
import { OrganizationReference } from 'models/PaymentPlan';
import { checkReceivedHasValues, valueOrDefault } from 'utils/Utils';

export default (props: {
  isOpen?: boolean;
  isAdd?:boolean;
  SelectedFacilities: MultiSelectOption[];
  SelectedDepartments : DepartmentMultiselectOption[];
  SelectedUsers: UserDetailModel[];
  close: () => void;
  searchDataCallBack: () => void;
}) => {
  const { isOpen,isAdd, SelectedFacilities,SelectedDepartments,SelectedUsers, close, searchDataCallBack } = props;

  const dispatch = useDispatch();
  const openClass = checkReceivedHasValues(isOpen, 'eds-modal.open', '');
  const { getOrganizationByPath } = useOrganizations();

  const facilitiesToShow = SelectedFacilities.map( (facility)=> {  
   const facilityDocument  = getOrganizationByPath(facility?.value);
   return facilityDocument;
  })

  const departmentsToShow = SelectedDepartments.map( (department)=> {  
    const departmentDocument  = getOrganizationByPath(department?.value);
    return departmentDocument;
  })
  
  const [reasonForChangeNotes, setReasonForChangeNotes] = useState("");

  const useAddFacilityToUserService = () => {
    return async (addFacility: AddRemoveFacilityRequestModel) => {
      const response = await AddFacilityToUserService(addFacility);
      if (!response.err) {
        dispatch(setAlert({
          id: AlertIds.AddOrRemoveFacilities,
          message: "Facilities and Departments have been added to the selected users",
          type: AlertTypes.Success,
          dismissable:true
        }));
        searchDataCallBack();
      }
    }
  }

  const useRemoveFacilityToUserService = () => {
    return async (addFacility: AddRemoveFacilityRequestModel) => {
      const response = await RemoveFacilityToUserService(addFacility);
      if (!response.err) {
        dispatch(setAlert({
          id: AlertIds.AddOrRemoveFacilities,
          message: "Facilities and Departments have been removed from the selected users",
          type: AlertTypes.Success,
          dismissable:true
        }));
        searchDataCallBack();
      }
    }
  }

  const AddFacilityService =useAddFacilityToUserService();
  const RemoveFacilityService =useRemoveFacilityToUserService();
  const Confirm =()=>
  {
    const Organizations = [...facilitiesToShow.map(org => { return MapOrgLevelToOrgRef(org) }), ...departmentsToShow.map(org => { return MapOrgLevelToOrgRef(org) })]   
    const UserIds =SelectedUsers.map((user)=>{return user.id});
    isAdd ? AddFacilityService({UserIds,Organizations}) : RemoveFacilityService({UserIds,Organizations});
    close();
  }

   function MapOrgLevelToOrgRef(Facility?: FullOrganizationLevelDocument): OrganizationReference {
    return {
      id: valueOrDefault(Facility?.id, ''),
      path: valueOrDefault(Facility?.path, ''),
      name: valueOrDefault(Facility?.name, '')
    };
  }
  
  return (
    <div>
      <section className={`eds-modal AddRemoveConfirmInfoModal ${openClass}`}>
        <div className="eds-modal_#container ConfirmModalContainer" > 
          <header className="eds-modal_#header headerTitle">           
            <div className="col titleText" >PaymentSafe®</div>             
            <div className="col text-right headerCloseButton" onClick={()=>{close()}}>                
               <Close />
            </div>
          </header> 
          <div className="eds-modal_#content">
          <div className="AddRemoveConfirmInfoPageContainer">
              <div className="AddRemoveConfirmInfoFormContainer">
                <div>            
                  <div className="user-location">
                    {checkReceivedHasValues(isAdd, 'CONFIRM ADDING THE FOLLOWING FACILITIES AND DEPARTMENTS', 'CONFIRM REMOVING THE FOLLOWING FACILITIES AND DEPARTMENTS')}
                  </div>
                  <br/>
                    <table className ="ConfirmationModalTable">
                      <thead>
                        <tr>
                          <th>Facility</th>
                          <th>Department</th>
                        </tr>
                      </thead>
                      <tbody className ="ConfirmationModalTableBody">
                      {
                          facilitiesToShow.map((facility,index)=>(
                          
                          <tr key={facility?.clientId} className= "ConfirmationTableRow">
                            <td>{facility?.name}</td>
                            <td>
                              {SelectedDepartments.map((department, index)=>
                              {
                                if(department.facilityName===facility?.name)
                                {
                                  return (<div key = {department.value}>{department.label}</div>)
                                }
                              })}
                            </td>
                          </tr>))
                      }
                      </tbody>
                    </table>
                  <br/>            
                    <table className ="ConfirmationModalTable">
                      <thead>
                          <tr>
                            <th> To User:</th>
                          </tr>
                      </thead> 
                      <tbody className= "ConfirmationUserTableRow">
                          { SelectedUsers.map((user, index)=> (
                            <tr key = {user.id}>
                            <td> {user.firstName + ' '+ user.lastName}</td>
                            </tr>))
                          }
                      </tbody>
                    </table>
                  <br/>
                  <div><b> Reason for Change </b></div>
                    <EDS_TextArea
                      name="reasonForChangeNotes"
                      modifiers={'full-width-area'}
                      heightModifier={'auto'}
                      onChange={(e: React.ChangeEvent<HTMLFormElement>) => setReasonForChangeNotes(e.target.value)}
                      value={reasonForChangeNotes}
                    ></EDS_TextArea>          
                  <div className='row confirmModalRow'>
                    <div className="ConfirmbtnItem">
                      <Button className="eds-button eds-button.primary iconOnRight"
                          onClick={()=>close()}
                          iconName={'chevron_right'}>
                          Cancel
                      </Button>
                      <Button className="eds-button eds-button.primary iconOnRight Confirmfooterbutton"
                        onClick={Confirm}
                        iconName={'chevron_right'}>
                        Confirm
                      </Button>
                    </div> 
                  </div>
                </div>          
              </div>
            </div>
          </div>
        </div>
      </section>    
    </div>
  ); 
  
};
