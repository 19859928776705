import { OrganizationReference } from "models/PaymentPlan";
import { DistributionOrder, PaymentPanelDataType, PaymentPanelFieldType, PaymentPanelType } from "models/enums/EnumPaymentPanels";
import { LineItem } from "../models/LineItem";
import { callHttpApi } from "./callHttpApi";

export interface PaymentPanelApiConfigurationFieldSetting {
  label?: string,
  saveAs?: keyof LineItem,
  dataType?: PaymentPanelDataType,
  visible: boolean,
  cssStyles?: string,
  format?: string,
  maxLength?: number,
  regex?: string,
  required?: boolean,
  validationErrorMessage?: string,
  loadFrom?: string,
  fieldType?: PaymentPanelFieldType,
  fieldTypeOnAddAccount?: PaymentPanelFieldType,
  placeholderText?: string,
  listOptions?: {
    displayValue: number | string,
    savedValue: number | string,
    order: number
  }[],
  position?: number,
    visibleOnReceipt?:boolean;
}

export interface PaymentPanelApiConfiguration {
  id?: string,
  organization?: {
    id: string,
    name: string,
    path: string
  },
  type?: PaymentPanelType,
  isDraft?: boolean,
  panelTitle?: string,
  colorCode?: string,
  fieldSettings:
  PaymentPanelApiConfigurationFieldSetting[],
  addRowEnabled?: boolean,
  sortByField?: keyof LineItem,
  sortDescending?: boolean,
  distributionOrder?: DistributionOrder
  mappings?: PaymentPanelMappingSettings[]
}
export interface PaymentPanelApiConfigurationGroup extends PaymentPanelApiConfiguration{
  panelTypes?: PanelTypes[]
}
export interface PanelTypes {
  label?: string,
  value?: string,
  type?: PaymentPanelType,
  isDraft?: string
}

export interface RegexTemplateOptions extends Array<PaymentPanelRegexTemplate> { }
export interface PaymentPanelRegexTemplate {
  description: string,
  regExRule:string
}

export interface PaymentPanelSelectOption {
  optionName: string,
  value:string
}
export interface PaymentPanelMappingSettings{
  organization?: OrganizationReference,
  order: number
}
export interface PanelCloneRequest{
  panelSettingId?: string,
  destinationOrganizationPaths?: string[]
}

export enum PanelConfigurationType {
  PaymentPaymentPanel = "PaymentPaymentPanel",
  PaymentPlansPaymentPanel = "PaymentPlansPaymentPanel",
  GlPaymentPaymentPanel = "GlPaymentPaymentPanel",
  PatientSearchPanel = "PatientSearchPanel",
  EcnPaymentPanel = "EcnPaymentPaymentPanel"
}

export async function clonePaymentPanel(panelType: PanelConfigurationType, payload?: PanelCloneRequest) {
  return callHttpApi<PanelCloneRequest, PanelCloneRequest>(
    'post',
    `api/v1/${panelType}/BulkClone`,
    payload,
  );
}

export async function getPaymentPanelConfiguration(organizationPaths?: string[]) {
  const params = new URLSearchParams();
  organizationPaths?.forEach(p => params.append('organizationPaths', p));

  return callHttpApi<PaymentPanelApiConfiguration[]>(
    'get',
    '/api/v1/PaymentPaymentPanel',
    undefined,
    params
  );
}

export async function getEcnPaymentPanelConfiguration(organizationPaths?: string[]) {
  const params = new URLSearchParams();
  organizationPaths?.forEach(p => params.append('organizationPaths', p));

  return callHttpApi<PaymentPanelApiConfiguration[]>(
    'get',
    '/api/v1/EcnPaymentPaymentPanel',
    undefined,
    params
  );
}

function getDefaultConfigurations(organizationPath?: string, isDraft?: boolean, service?:string){
  const params = new URLSearchParams();
  params.append("organizationPath", organizationPath ?? '');
  params.append("isDraft", isDraft?.toString() ?? 'false');

  return callHttpApi<PaymentPanelApiConfiguration>(
    'get',
    `/api/v1/${service}/ForOrganization`,
    undefined,
    params
  );
}

export async function getDefaultPaymentPanelConfiguration(organizationPath?: string, isDraft?: boolean) {
  return getDefaultConfigurations(organizationPath,isDraft,'PaymentPaymentPanel');
}

export async function getDefaultGLPaymentPanelConfiguration(organizationPath?: string, isDraft?: boolean) {
  return getDefaultConfigurations(organizationPath,isDraft,'GlPaymentPaymentPanel');
}

export async function getDefaultPatientSearchPanelConfiguration(organizationPath?: string, isDraft?: boolean) {
  return getDefaultConfigurations(organizationPath,isDraft,'PatientSearchPanel');
}

export async function getDefaultPaymentPlansPaymentPanelConfiguration(organizationPath?: string, isDraft?: boolean) {
  return getDefaultConfigurations(organizationPath,isDraft,'PaymentPlansPaymentPanel');
}

export async function getDefaultEcnPaymentPanelConfiguration(organizationPath?: string, isDraft?: boolean) {
  return getDefaultConfigurations(organizationPath,isDraft,'EcnPaymentPaymentPanel');
}


export async function getGlPaymentPanelConfiguration(organizationPaths?: string[]) {
  const params = new URLSearchParams();
  organizationPaths?.forEach(p => params.append('organizationPaths', p));

  return callHttpApi<PaymentPanelApiConfiguration[]>(
    'get',
    '/api/v1/GlPaymentPaymentPanel',
    undefined,
    params
  );
}

export async function getPaymentPlanPanelConfiguration(organizationPaths?: string[]) {
  const params = new URLSearchParams();
  organizationPaths?.forEach(p => params.append('organizationPaths', p));

  return callHttpApi<PaymentPanelApiConfiguration[]>(
    'get',
    '/api/v1/PaymentPlansPaymentPanel',
    undefined,
    params
  );
}

export async function updatePaymentPanelConfigurationDraftService(configuration?: PaymentPanelApiConfiguration) {
  return callHttpApi<PaymentPanelApiConfiguration, PaymentPanelApiConfiguration>(
    'put',
    '/api/v1/PaymentPaymentPanel',
    configuration,
  );
}

function updatePaymentConfigurationsService(configuration?: PaymentPanelApiConfiguration, service?:string){
  return callHttpApi<PaymentPanelApiConfiguration, PaymentPanelApiConfiguration>(
    'put',
    `/api/v1/${service}`,
    configuration,
  );
}

export async function updatePaymentPanelConfigurationService(configuration?: PaymentPanelApiConfiguration) {
  return updatePaymentConfigurationsService(configuration,'PaymentPaymentPanel');
}

export async function updatePaymentPlansPaymentPanelConfigurationService(configuration?: PaymentPanelApiConfiguration) {
  return updatePaymentConfigurationsService(configuration,'PaymentPlansPaymentPanel');
}

export async function updateGlPaymentPanelConfigurationService(configuration?: PaymentPanelApiConfiguration) {
  return updatePaymentConfigurationsService(configuration,'GlPaymentPaymentPanel');
}

export async function updatePatientSearchPanelConfigurationService(configuration?: PaymentPanelApiConfiguration) {
  return updatePaymentConfigurationsService(configuration,'PatientSearchPanel');
}

export async function updateEcnPaymentPanelConfigurationService(configuration?: PaymentPanelApiConfiguration) {
  return updatePaymentConfigurationsService(configuration,'EcnPaymentPaymentPanel');
}

export interface ApiSelectOption {
  fieldName: keyof LineItem,
  displayName: string
}

export interface ApiSelectOptions extends Array<ApiSelectOption> { }

export async function getPaymentSaveAsSelectOptionsService() {
  return callHttpApi<ApiSelectOptions>(
    'get',
    '/api/v1/PatientPaymentPreDefinedLists/PatientPaymentSaveAsFieldDescriptions',
  );
}

export async function getPaymentLoadFromSelectOptionsService() {
  return callHttpApi<ApiSelectOptions>(
    'get',
    '/api/v1/PatientPaymentPreDefinedLists/PatientPaymentFieldDescriptions',
  );
}

export async function getSearchSaveAsSelectOptionsService() {
  return callHttpApi<ApiSelectOptions>(
    'get',
    '/api/v1/PatientPaymentPreDefinedLists/PatientPaymentSearchFieldDescriptions',
  );
}

export async function getGlPaymentSaveAsSelectOptionsService() {
  return callHttpApi<ApiSelectOptions>(
    'get',
    '/api/v1/GlPaymentPreDefinedLists/GlPaymentSaveAsFieldDescriptions',
  );
}

export async function getGlPaymentLoadFromSelectOptionsService() {
  return callHttpApi<ApiSelectOptions>(
    'get',
    '/api/v1/GlPaymentPreDefinedLists/GlPaymentFieldDescriptions',
  );
}

export async function DeletePaymentPaymentPanel(id?: string) {
  return callHttpApi<PaymentPanelApiConfiguration, PaymentPanelApiConfiguration>(
    'delete',
    `/api/v1/PaymentPaymentPanel/${id}`
  );
}

export async function DeletePatientPanel(id?: string) {
  return callHttpApi<PaymentPanelApiConfiguration, PaymentPanelApiConfiguration>(
    'delete',
    `/api/v1/PatientSearchPanel/${id}`
  );
}

export async function DeleteEcnPaymentPanel(id?: string) {
  return callHttpApi<PaymentPanelApiConfiguration, PaymentPanelApiConfiguration>(
    'delete',
    `/api/v1/EcnPaymentPaymentPanel/${id}`
  );
}

export async function DeletePaymentPlansPaymentPanel(id?: string) {
  return callHttpApi<PaymentPanelApiConfiguration, PaymentPanelApiConfiguration>(
    'delete',
    `/api/v1/PaymentPlansPaymentPanel/${id}`
  );
}

export async function DeleteGlPaymentPaymentPanel(id?: string) {
  return callHttpApi<PaymentPanelApiConfiguration, PaymentPanelApiConfiguration>(
    'delete',
    `/api/v1/GlPaymentPaymentPanel/${id}`
  );
}

export async function getPaymentPanelRegExTemplates() {
  return callHttpApi<PaymentPanelRegexTemplate[]>(
    'get',
    'api/v1/RegExTemplates/GetRegExTemplates',
  );
}