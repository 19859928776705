import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { EDS_Button } from '@EH/eh.eds.react';
import { useTypedSelector } from 'app/rootReducer';
import Footer from 'features/footer/Footer';
import { useCreateTransactionAndGetStatus } from 'features/makePaymentButton/useCreateTransactionAndGetStatus';
import BalanceHeader from 'features/paymentPlan/infoSections/BalanceHeader';
import PayerBillingInformation from 'features/paymentPlan/infoSections/PayerBillingInformation';
import PayerMailingInformation from 'features/paymentPlan/infoSections/PayerMailingInformation';
import PaymentPlanModel, { OrganizationReference, PayerInformation } from 'models/PaymentPlan';
import {
  ApiTenderTypeEnum,
  ApiTenderTypeEnumLabels,
  checkNumberHasValue,
  checkReceivedHasValues,
  coalesce,
  displayAmount,
  formatDate,
  maskCardNumber,
  maskECheckNumber,
  valueOrDefault,
} from 'utils/Utils';
import SingleAuthorizationConfirmation from './SingleAuthorizationConfirmation';
import '@experian/eds-styles/dist/eds-all.css';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '../../assets/styles/components/_paymentPlan.scss';
import { PlanOperations } from '../../models/metaData/MetaDataEnums';
import { distributePlanToPatientMailingInformation } from '../paymentPlan/PlanUtils';
import { useDispatch } from 'react-redux';
import { PatientMailingInformation, setPatientMailingInformation } from '../paymentInfo/PatientMailingInformationReducer';
import { AlertIds, AlertTypes, setAlert } from '../alert/AlertReducer';
import { PaymentOnFile } from 'models/PatientModel';
import EnumTransactionTenderType from 'models/enums/EnumTransactionTenderType';
import { ReactComponent as ProgressIcon } from 'assets/svgs/progresswheel-icon.svg';
import { PaymentConfiguration } from 'models/PaymentsConfiguration';
import { LineItem } from '../../models/LineItem';
import { v4 as uuid } from 'uuid';
import { Value } from '../paymentDashboard/PaymentDashboardReducer';
import { getServerLineItems } from 'features/paymentPlan/hooks/useGetServerLineItems';

interface TenderTypeProps {
  type?: string | ApiTenderTypeEnum;
  total?: number;
  amount?: number;
  isGL?: boolean;
  method?: PaymentOnFile;
}

interface MoneyOrderTenderTypeProps extends TenderTypeProps {
  moneyOrderAmount?: number;
  cashBack?: number;
  moneyOrderIssueDate?: string;
  moneyOrderPostOffice?: string;
  moneyOrderSerialNumber?: string;
}

interface ECheckTenderTypeProps extends TenderTypeProps {
  paperCheckNumber?: string;
  routingNumber?: string;
  accountNumber?: string;
  financialInstitution?: string;
  tenderMaskedAccount?: string;
}

interface PaperCheckTenderTypeProps extends TenderTypeProps {
  paperCheckNumber?: string;
  financialInstitution?: string;
  amountTendered?: number;
  total?: number;
}

interface CreditDebitTenderTypeProps extends TenderTypeProps {
  cardNumber?: string;
  cardType?: string;
  maskedCardNumber?: string;
  expirationDate?: string;
  cvc?: string;
}

interface CashTenderTypeProps {
  type?: string | number;
  amountTendered?: number;
  cashBack?: number;
}

export default (props: {
  isGl: boolean;
  onContinue?: () => void;
  onBack?: () => void;
  cancel?: () => void;
  total?: number;
  amount?: number;
  discount?: number;
  isGL?: boolean;
  organization?: OrganizationReference;
  selectedOrganizationPath?: string;
  singlePaymentOnFileTenderType?: { type: string | EnumTransactionTenderType | undefined };
  paymentAmount?: number,
  plan?: PaymentPlanModel
}) => {
  const {
    onContinue,
    onBack,
    total,
    paymentAmount,
    amount,
    discount,
    cancel,
    isGL,
    organization,
    selectedOrganizationPath = "",
    singlePaymentOnFileTenderType,
    plan
  } = props;
  const patientMailingInformation = useTypedSelector(
    i => i.patientMailingInformation?.value
  );
  const paymentDashboards = useTypedSelector(i => i.paymentDashboard?.values);
  const paymentDashboardInfo = paymentDashboards[selectedOrganizationPath];

  const isPaymentType = useTypedSelector(s => s.paymentDashboard.isPaymentType)
  const operation = useTypedSelector(state => state.paymentPlanInfo.operation);
  const isAddHoc = operation === PlanOperations.AddhockPayment;

  const configuration: PaymentConfiguration | undefined = useTypedSelector(
    state => state.paymentDashboard?.configurations?.[selectedOrganizationPath]?.configuration
  );
  const isMailingAddressRequired = coalesce(true, configuration?.mailingAddressRequired);

  const [isPaymentProcessing, setIsPaymentProcessing] = useState<boolean>(false);
  const alert = { id: AlertIds.GlPaymentAlert, type: AlertTypes.Error, message: '' };
  const openErrorMessage = (message: string) => {
    dispatch(setAlert({ ...alert, message }));
  };

  const isProcessing = useTypedSelector(s => s.services.calls.createTransaction?.isProcessing);
  const totalAmount = (isAddHoc ? paymentAmount : total) || 0;

  const {
    action: createTransactionAndGetStatus,
    hasError,
  } = useCreateTransactionAndGetStatus(
    isGL,
    onContinue,
    openErrorMessage,
    valueOrDefault(paymentAmount, total),
    organization,
    3000,
    plan,
    patientMailingInformation.tenderType as ApiTenderTypeEnum,
    isMailingAddressRequired
  );

  const back = () => {
    onBack?.();
  };

  const next = async () => {
    setOpenAuthorizationModal(false);
    setIsPaymentProcessing(true);

    if (isPaymentType) {
      savePaymentTransaction();
    } else {
      savePlanTransaction();
    }

    setIsPaymentProcessing(false);
  };


  async function savePaymentTransaction() {
    const selectedOrganizationPaths = Object.keys(paymentDashboards).filter(key =>
      !!paymentDashboards[key].lineItems.find(checkLineItemIsActive)
    );
    const transactionGroupId = checkReceivedHasValues(selectedOrganizationPaths.length > 1, uuid(), '');
    let promisesArray = selectedOrganizationPaths.map(organizationPath =>
      createTransactionAndGetStatus(paymentDashboards[organizationPath], organizationPath, transactionGroupId)
    )
    await Promise.all([promisesArray]);
  }
  function GetPlanAdhocAmount(){
    if (plan?.addhocAmount){
      return checkNumberHasValue(Number(plan?.addhocAmount));
    }

    return plan?.lineItems?.filter(x=>x.isActive).reduce((sum, l) => Number(sum) + (Number(checkNumberHasValue(l.amount))), 0) ?? 0;
  }

  async function savePlanTransaction() {
    let amount = valueOrDefault(paymentAmount, checkNumberHasValue(total));
    amount = checkReceivedHasValues(isAddHoc, GetPlanAdhocAmount(), amount);

    const path = valueOrDefault(plan?.organization?.path, '');
    const serverLineItems = getServerLineItems(plan?.lineItems, plan?.patientId, isAddHoc);
    const value: Value = {
      lineItems: valueOrDefault(serverLineItems, []), isGl: valueOrDefault(isGL, false), paymentTotal: amount
    }
    await createTransactionAndGetStatus(value, path);
  }

  let tenderInfo: TenderTypeProps;

  switch (patientMailingInformation.tenderType) {
    case ApiTenderTypeEnum.PaperCheckAsECheck:
    case ApiTenderTypeEnum.ECheck:
      tenderInfo = {
        type: patientMailingInformation.tenderType,
        accountNumber: maskECheckNumber(patientMailingInformation.accountNumber),
        routingNumber: maskECheckNumber(patientMailingInformation.routingNumber),
        financialInstitution: patientMailingInformation.financialInstitution,
        paperCheckNumber: patientMailingInformation.paperCheckNumber,
        amountTendered: total!,
      } as TenderTypeProps;
      break;
    case ApiTenderTypeEnum.CardDevice:
    case ApiTenderTypeEnum.CardManual:
      tenderInfo = {
        type: patientMailingInformation.tenderType,
        cardType: patientMailingInformation.cardType,
        cardNumber: checkReceivedHasValues(patientMailingInformation.tenderType === ApiTenderTypeEnum.CardDevice,patientMailingInformation.deviceSerialNumber, patientMailingInformation.cardNumber),
        maskedCardNumber: patientMailingInformation.maskedCardNumber,
        cvc: patientMailingInformation.cvc,
        expirationDate: patientMailingInformation.expiration,
      } as TenderTypeProps;
      break;
    case ApiTenderTypeEnum.MoneyOrder:
      tenderInfo = {
        type: patientMailingInformation.tenderType,
        moneyOrderAmount: patientMailingInformation.moneyOrderAmount,
        moneyOrderIssueDate: patientMailingInformation.moneyOrderIssueDate,
        moneyOrderPostOffice: patientMailingInformation.moneyOrderPostOffice,
        moneyOrderSerialNumber: patientMailingInformation.moneyOrderSerialNumber,
        total: total,
        cashBack: checkNumberHasValue(patientMailingInformation.moneyOrderAmount) - totalAmount,
      } as TenderTypeProps;
      break;
    default:
      tenderInfo = {
        type: patientMailingInformation.tenderType,
        amountTendered: patientMailingInformation.amountTendered,
        amount: paymentDashboardInfo.paymentTotal,
        cashBack: checkNumberHasValue(patientMailingInformation.amountTendered) - totalAmount,
      } as TenderTypeProps;
      break;
  }

  const billingAddress: PayerInformation = getBillingAddress(patientMailingInformation);

  const mailingAddress: PayerInformation = getMailingAddress(patientMailingInformation);

  const [openAuthorizationModal, setOpenAuthorizationModal] = useState(false);

  const openAuthorizationPopup = async () => {
    switch (tenderInfo.type) {
      case ApiTenderTypeEnum.Cash:
      case ApiTenderTypeEnum.MoneyOrder:
      case ApiTenderTypeEnum.PaperCheckAsECheck:
        await next();
        break;
      default:
        setOpenAuthorizationModal(true);
        break;
    }
  };

  const closeAuthorizationPopup = () => {
    setOpenAuthorizationModal(false);
  };


  const dispatch = useDispatch();
  useEffect(() => {
    if (operation == PlanOperations.AddhockPayment) {
      const { patientMailingInformation: patientMailingInfo } = distributePlanToPatientMailingInformation({
        plan,
        mailingInformation: patientMailingInformation
      });
      dispatch(
        setPatientMailingInformation(patientMailingInfo)
      );
    }
  }, [plan, operation]);

  const patient = useTypedSelector(state => state.patients.records[0])
  const method = tenderInfo.type === ApiTenderTypeEnum.SavedOnFile ? patient.paymentsOnFile?.filter(p => p.tokenId === patientMailingInformation.paymentFileTokenId)[0] : undefined

  function paymentProcessing() {
    if (isPaymentProcessing) {
      return (
        <div className={`processingContainer`}>
          <span
            className={`processing-label paymentProcessing`}
          >
            <ProgressIcon />
            <span className='processingLabel'> Processing Payment... Please Wait.</span>

          </span>
        </div>
      )
    }

  };

  return (
    <>
      {paymentProcessing()}

      <SingleAuthorizationConfirmation
        organization={organization} //facility needs to be reassigned to a key/value pair?
        onNext={next}
        tenderInfo={tenderInfo}
        singlePaymentOnFileTenderType={singlePaymentOnFileTenderType}
        isOpen={openAuthorizationModal}
        close={closeAuthorizationPopup}
      />
      <div className="payment-plan-container height-calc">
        <div className="payment-plan-form-container">
          <BalanceHeader
            total={paymentAmount ?? total}
            amount={amount}
            discount={discount}
          />

          <div className="flex-row">
            <TenderType
              {...Object.assign({}, tenderInfo, { total, amount, isGL, method })}
            />
          </div>
          <div className="flex-row">
            <PayerBillingInformation payerInformation={billingAddress} mailingInformation={mailingAddress} />
          </div>
          {isMailingAddressRequired && (
            <div className="flex-row">
              <PayerMailingInformation payerInformation={mailingAddress} />
            </div>
          )}
          <div className='row'>
            <div className="col-6 font-weight-bold eds-field_#label">Notes</div>
            <div className="col-6">{patientMailingInformation?.notes}</div>
          </div>
        </div>
      </div>
      <Footer>
        <div className="footer-buttons">
          <EDS_Button
            modifiers={'mr-3 eds-button eds-button.basic'}
            name={'back'}
            buttonText={'Back'}
            onClick={back}
            iconName={'chevron_left'}
          />
          <div className="btnItem next-button">
            <EDS_Button
              modifiers={'mr-3 eds-button eds-button.basic'}
              name={'cancel'}
              buttonText={'Cancel'}
              onClick={cancel}
            />
            <button
              className="eds-button eds-button.primary"
              onClick={openAuthorizationPopup}
              disabled={isProcessing || hasError}
            >
              {true ? (
                <React.Fragment>
                  <span className="eds-button_#label">Submit</span>
                </React.Fragment>
              ) : (
                <span className="eds-button_#label">Processing ...</span>
              )}
            </button>
          </div>
        </div>
      </Footer>
    </>
  );
};

const ECheckTenderType = (props: ECheckTenderTypeProps) => {
  const { routingNumber, accountNumber, financialInstitution, method } = props;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-6 font-weight-bold">Routing Number:</div>
        <div className="col-6">{method ? method.routingNumber : maskECheckNumber(routingNumber)}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Account Number:</div>
        <div className="col-6">{method ? method.maskedAccount : maskECheckNumber(accountNumber)}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Financial Institution:</div>
        <div className="col-6">{method ? method.financialInstitution : financialInstitution}</div>
      </div>
    </React.Fragment>
  );
};

const CreditDebitTenderType = (props: CreditDebitTenderTypeProps) => {
  const { cardNumber, expirationDate, method } = props;
  return (
    <React.Fragment>
      {method?.cardBrand && (
        <div className="row">
          <div className="col-12 pr-0">
            <div className="row">
              <div className="col-6 font-weight-bold">Card Type:</div>
              <div className="col-6 pl-2">
                {method.cardBrand}
              </div>
            </div>
          </div>
        </div>)
      }
      <div className="row">
        <div className="col-12 pr-0">
          <div className="row">
            <div className="col-6 font-weight-bold">Card Number:</div>
            <div className="col-6 pl-2">
              {method ? method.maskedAccount : (cardNumber && maskCardNumber(cardNumber))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Expiration Date:</div>
        <div className="col-6">{moment.utc(method ? method.cardExpirationDate : expirationDate).format('MM/YY')}</div>
      </div>
    </React.Fragment>
  );
};

const MoneyOrderTenderType = (props: MoneyOrderTenderTypeProps) => {
  const {
    moneyOrderSerialNumber,
    moneyOrderPostOffice,
    moneyOrderAmount,
    moneyOrderIssueDate,
    cashBack
  } = props;
  const date = moneyOrderIssueDate
    ? formatDate(moment.utc(moneyOrderIssueDate))
    : 'NA';
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-6 font-weight-bold">Serial Number:</div>
        <div className="col-6">{moneyOrderSerialNumber}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Issue Date:</div>
        <div className="col-6">{date}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Post Office:</div>
        <div className="col-6">{moneyOrderPostOffice}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Amount Tendered:</div>
        <div className="col-6">
          ${parseFloat((0 || moneyOrderAmount) + '').toFixed(2)}
        </div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Cash Back:</div>
        <div className="col-6">
          ${displayAmount(cashBack)}
        </div>
      </div>
    </React.Fragment>
  );
};
const PaperCheckTenderType = (props: PaperCheckTenderTypeProps) => {
  const { paperCheckNumber, financialInstitution } = props;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-6 font-weight-bold">Check Number:</div>
        <div className="col-6">{paperCheckNumber}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Financial Institution:</div>
        <div className="col-6">{financialInstitution}</div>
      </div>
    </React.Fragment>
  );
};

const displayPaymentType = (type: string | undefined, method: PaymentOnFile | undefined) => {
  if (!type) return;
  if ([String(ApiTenderTypeEnum.CardDevice), String(ApiTenderTypeEnum.CardManual), String(ApiTenderTypeEnum.ECheck)].includes(type)) return ApiTenderTypeEnumLabels[type];
  if (type === ApiTenderTypeEnum.SavedOnFile) return method?.tenderType === EnumTransactionTenderType.Card ? 'Credit/Debit Manual Entry' : method?.tenderType;
  if (type === ApiTenderTypeEnum.PaperCheckAsECheck) return 'Paper Check';
  if (type === ApiTenderTypeEnum.MoneyOrder) return 'Money Order';
  if (type === ApiTenderTypeEnum.Cash) return 'Cash';
  return type;
}

const TenderType = (props: TenderTypeProps) => {
  const { type, method } = props;
  return (
    <div className="row-item row-item-size-double">
      <div className="eds-heading eds-heading.mdplus-caps mb-1">
        Tender Type
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Payment Type:</div>
        <div className="col-6">{displayPaymentType(type, method)}
        </div>
      </div>
      {type == ApiTenderTypeEnum.MoneyOrder && (
        <MoneyOrderTenderType {...props} />
      )}
      {type === ApiTenderTypeEnum.Cash && <CashTenderType {...props} />}
      {type == ApiTenderTypeEnum.ECheck && (
        <ECheckTenderType {...props} />
      )}
      {type == ApiTenderTypeEnum.CardManual && (
        <CreditDebitTenderType {...props} />
      )}
      {type === ApiTenderTypeEnum.PaperCheckAsECheck && <PaperCheckTenderType {...props} />}
      {type === ApiTenderTypeEnum.SavedOnFile &&
        (method?.tenderType && (method?.tenderType === EnumTransactionTenderType.eCheck
          ? <ECheckTenderType {...props} /> : <CreditDebitTenderType {...props} />)
        )}

    </div>
  );
};

const CashTenderType = (props: CashTenderTypeProps) => {
  const { amountTendered, cashBack } = props;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-6 font-weight-bold">Amount Tendered:</div>
        <div className="col-6">${displayAmount(amountTendered)}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Cash Back:</div>
        <div className="col-6">${displayAmount(cashBack)}</div>
      </div>
    </React.Fragment>
  );
};


export function getBillingAddress(patientMailingInformation: PatientMailingInformation) {
  return {
    addressLine1: patientMailingInformation?.billingAddressLine1,
    addressLine2: patientMailingInformation?.billingAddressLine2,
    city: patientMailingInformation?.billingCity,
    phone: patientMailingInformation?.billingPhoneNumber,
    firstName: checkReceivedHasValues(patientMailingInformation?.billingFirstName, patientMailingInformation?.billingFirstName, patientMailingInformation?.firstName),
    lastName: checkReceivedHasValues(patientMailingInformation?.billingLastName, patientMailingInformation?.billingLastName, patientMailingInformation?.lastName),
    state: patientMailingInformation?.billingState,
    zipCode: patientMailingInformation?.billingZipCode,
  };
}

export function getMailingAddress(patientMailingInformation: PatientMailingInformation) {
  return patientMailingInformation?.isChecked
    ? {
      ...getBillingAddress(patientMailingInformation),
    }
    : {
      addressLine1: patientMailingInformation?.addressLine1,
      addressLine2: patientMailingInformation?.addressLine2,
      city: patientMailingInformation?.city,
      phone: patientMailingInformation?.phoneNumber,
      firstName: patientMailingInformation?.firstName,
      lastName: patientMailingInformation?.lastName,
      state: patientMailingInformation?.mailingState,
      zipCode: patientMailingInformation?.zipCode,
    };
}

export function checkLineItemIsActive(lineItem: LineItem) {
  return lineItem.isActive && !lineItem.isEditing;
}