import { useCallback } from 'react';
import { useTypedSelector } from 'app/rootReducer';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import { OrganizationLevelDocument } from '../../../models/OrganizationLevelDocument';

export function useOrganizations() {

  const organizations = useTypedSelector(s => s.organizations.value);

  const useGetTradingPartners = useCallback(() => {
    return organizations.filter(o => o.organizationLevelType === OrganizationLevelTypes.TradingPartner);
  }, [organizations]);

  const useGetFacilities = useCallback((filter?: (o: OrganizationLevelDocument) => boolean) => {
    return organizations.filter(o => o.organizationLevelType === OrganizationLevelTypes.Facility && (!filter || filter(o)));
  }, [organizations]);

  const getDepartments = (filter?: (o: OrganizationLevelDocument) => boolean) => {
    return organizations.filter(o => o.organizationLevelType === OrganizationLevelTypes.Department && (!filter || filter(o)));
  }

  const useGetDepartments = useCallback(getDepartments, [organizations]);

  const getOrganizationById = (id?: string) => {
    if (!id) return;
    return organizations.find(o => o.id == id);
  }

  const getOrganizationByPath = (path?: string) => {
    if (!path) return;
    return organizations.find(o => o.path == path);
  }

  const useGetOrganizationById = useCallback(getOrganizationById, [organizations]);

  const useGetDepartmentByPath = useCallback((path?: string) => {
    if (!path) return;
    return organizations.find(o => o.organizationLevelType === OrganizationLevelTypes.Department && o.path === path);
  }, [organizations]);

  const getFacilityByPath = useCallback((path?: string) => {
    if (!path) return;
    return organizations.find(o => o.organizationLevelType === OrganizationLevelTypes.Facility && o.path === path);
  }, [organizations]);

  const getDepartmentByPath = useCallback((path?: string) => {
    if (!path) return;
    return organizations.find(o => o.organizationLevelType === OrganizationLevelTypes.Department && o.path === path);
  }, [organizations]);

  const getTradingPartnerByPath = (path?: string) => {
    if (!path) return;
    return organizations.find(o => o.organizationLevelType === OrganizationLevelTypes.TradingPartner && o.path === path);
  };

  const removeEndSegments = (path?: string, n: number = 0) => {
    if (path) {
      const parts = path.split('|')
        parts.splice(parts.length - 1 - n, n);
      return parts.join('|');
    }
  }

  const getFacilityByDepartment = (departmentPath?: string) => {
    if (!departmentPath) return;
    const paths = departmentPath.split('|').filter(i => i);
    if(paths.length === 3){
      return getFacilityByPath(departmentPath);
    }
    const path = removeEndSegments(departmentPath, 1);
    return getFacilityByPath(path);
  }

  const getTradingPartnerByFacility = (facilityPath?: string) => {
    if (!facilityPath) return;
    const path = removeEndSegments(facilityPath, 1);

    return getTradingPartnerByPath(path);
  }

  const getFacilityTradingPartnerPath = (facilityPath?: string) => {
    if (!facilityPath) return;
    return removeEndSegments(facilityPath, 1);
  }

  const getFacilityWithFallback = (path?: string) => {
    return getFacilityByDepartment(path) ?? getFacilityByPath(path);
  }

  const getTradingPartnerWithFallback = (path?: string) => {
    return getTradingPartnerByFacility(path) ?? getTradingPartnerByPath(path);
  }

  const getOrganizationWithFallback = (path: string, levelType: OrganizationLevelTypes) => {
    const paths = path.split('|').filter(i => i).length;

    switch (levelType) {
      case OrganizationLevelTypes.Department:
        if (paths > 4) {
          path = removeEndSegments(path, paths - 4) ?? path;
        }

        return getDepartmentByPath(path);
      case OrganizationLevelTypes.Facility:
        if (paths > 3) {
          path = removeEndSegments(path, paths - 3) ?? path;
        }

        return getFacilityWithFallback(path);
      case OrganizationLevelTypes.TradingPartner:
        if (paths > 2) {
          path = removeEndSegments(path, paths - 2) ?? path;
        }

        return getTradingPartnerWithFallback(path);
      default:
        return getOrganizationByPath(path);
    }
  }

  return {
    organizations,
    useGetTradingPartners,
    useGetFacilities,
    getDepartments,
    useGetDepartments,
    useGetOrganizationById,
    useGetDepartmentByPath,
    getFacilityByPath,
    removeEndSegments,
    getFacilityByDepartment,
    getOrganizationById,
    getTradingPartnerByFacility,
    getOrganizationByPath,
    getFacilityTradingPartnerPath,
    getFacilityWithFallback,
    getOrganizationWithFallback,
  };
}
