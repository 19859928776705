import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import {
  EnumTransactionStatusType,
} from 'models/enums';
import { RelatedTransactionsModel } from 'models/RelatedTransactionsModel';
import { EnumTransactionType } from 'models/enums/EnumTransactionType';

export function buildTransactionStatus(
  transactionDetails: TransactionDetailsModel
): string {
  let status = transactionDetails.statusType ? String(transactionDetails.statusType) : '';
  
  if (!transactionDetails.relatedTransactions) {
    return status;
  }

  let relatedtransaction: RelatedTransactionsModel;
  let statusType: EnumTransactionStatusType = EnumTransactionStatusType.Unknown;
  let totalAmount: number = 0;

  if (transactionDetails.relatedTransactions.length === 0) {
    return status;
  }

  for (
    let count = 0;
    count < transactionDetails.relatedTransactions.length;
    count++
  ) {
    relatedtransaction = transactionDetails.relatedTransactions[count];
    statusType = relatedtransaction.statusType;
    totalAmount += relatedtransaction.amount;

    if (statusType !== EnumTransactionStatusType.Submitted) {
      continue;
    }
    
    if (relatedtransaction.transactionType === EnumTransactionType.Void) {
      return 'This Payment has been Voided';
    }

    if (relatedtransaction.transactionType === EnumTransactionType.Credit ||
      relatedtransaction.transactionType === EnumTransactionType.GLCredit
    ) {
      if (transactionDetails.amount !== totalAmount) {
        status = 'Refunded';
      } else {
        return 'Full Credit has been issued against this transaction';
      }
    }
  }

  return status;
}
