import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'app/rootReducer';
import { Attribute } from 'models/metaData/MetaData';
import { AnnotationType, DataType } from 'models/metaData/MetaDataEnums';
import { LineItem } from 'models/LineItem';
import { LineItemType } from 'features/paymentDashboard/LineItemComponent';
import { getAnnotation } from 'utils/metadata/MetaDataUtils';
import { addError, LineItemError } from '../paymentDashboard/PaymentDashboardReducer';
import { getComponentByComponentType } from './factories/componentType';
import { getComponentByValidators } from './factories/validators';
import { Date } from './wrapperComponents/Date';
import { DateTime } from './wrapperComponents/DateTime';
import { TextBox } from './wrapperComponents/TextBox';
import { Preview } from './wrapperComponents/Preview';
import { Linkview } from './wrapperComponents/Linkview';
import { AutocompleteField } from './wrapperComponents/AutocompleteField';
import '../../assets/styles/components/_componentFactory.scss';
import { HiddenAttribute } from './wrapperComponents/HiddenAttribute';
import { PaymentPanelFieldType } from 'models/enums/EnumPaymentPanels';

function getComponentByDataType(props: {
  dataType: DataType,
  attribute: Attribute,
  value: any,
  id?: string,
  setError?: (error: LineItemError) => void,
  onChange?: (value: any) => void,
  onBlur?: (value: any) => void,
  modifiers?: string,
  disabled?: boolean,
  error?: string,
  isEditing?: boolean
}){
  const {
    dataType,
    attribute,
    value,
    id,
    setError,
    onChange,
    onBlur,
    modifiers,
    disabled,
    error,
  } = props;
  
    switch (dataType) {
      case DataType.Boolean:
      case DataType.String:
      case DataType.Decimal:
      case DataType.Money:
        return(
          <TextBox
            attribute={attribute}
            value={value}
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            modifiers={modifiers}
            error={error}
            setError={setError}
            disabled={disabled}
          />
        );
      case DataType.DateTime:
        return(
          <DateTime
            attribute={attribute}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            modifiers={modifiers}
            error={error}
            setError={setError}
          />
        );
      case DataType.Date:
        return(
          <Date
            attribute={attribute}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            modifiers={modifiers}
            error={error}
            setError={setError}
          />
        );
      case DataType.Autocomplete:
        return(
          <AutocompleteField
            attribute={attribute}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            modifiers={modifiers}
            error={error}
            setError={setError}
          />
        );
      default:
        return <></>;
    };
}

function getNotRenderedValue(dataType : DataType, value: any, visible?: boolean, isPaymentOrPreviewPage?: boolean){
  if (dataType === DataType.Hidden) {
    return null;
  }

  if (dataType === DataType.Icon) {
    return value;
  }

  if (!visible && isPaymentOrPreviewPage) {
    return null;
  }

  return undefined;
}

function showHideMessage(visible?: boolean, modifiers?: string, isPreview?: boolean, dataType? : PaymentPanelFieldType ) {
  if(!visible && dataType !== PaymentPanelFieldType.Hidden){
    return (
      <HiddenAttribute
        modifiers={modifiers}
        isPreview={isPreview}
      />
    )
  }
}

export function ComponentFactory(props: {
  attribute: Attribute;
  lineItem: LineItem;
  isPreview?: boolean;
  lineItemType?: LineItemType;
  isBalanceZero?: boolean;
  isEditing?: boolean;
  value: any;
  id?: string;
  onClick?: (value: any) => void;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  modifiers?: string;
  disabled?: boolean;
  isPaymentOrPreviewPage?: boolean;
}) {
  const {
    attribute,
    value,
    onClick,
    onChange,
    onBlur,
    modifiers,
    isEditing,
    lineItem,
    isPreview,
    isBalanceZero,
    id,
    disabled,
    isPaymentOrPreviewPage
  } = props;

  const dataType = attribute.dataType;
  const visible = attribute.visible;
  const fieldTypeOnAddAccount = attribute.fieldTypeOnAddAccount;
  const dispatch = useDispatch();

  const error = useTypedSelector(s => {
    const lineItemErrors = s.paymentDashboard.errors[lineItem.id || ''];
    const errors = lineItemErrors && lineItemErrors[attribute.name];
    return errors && Object.values(errors).join(", ");
  });
  
  const notRenderedValue = getNotRenderedValue(dataType, value, visible, isPaymentOrPreviewPage);
  if(notRenderedValue !== undefined){
    return notRenderedValue;
  }

  const setError = (error: LineItemError) => { dispatch(addError({ id: lineItem.id, error: error })) };

  if (
    (isEditing && !getAnnotation(attribute, AnnotationType.Immutable)) ||
    (getAnnotation(attribute, AnnotationType.NoPreview) && !isBalanceZero)
     ) 
  {
    if(lineItem?.pahAccount && attribute.dataType === DataType.Money)
    {
      return (
        <>
          {
            getComponentByComponentType(
              {
                attribute: attribute,
                lineItem: lineItem, 
                value: value, 
                id: id, 
                setError: setError, 
                onChange: onChange, 
                onBlur: onBlur, 
                modifiers: modifiers, 
                disabled: disabled, 
                error: error, 
                isEditing: isEditing,
                isPreview: isPreview,
                isPaymentOrPreviewPage: isPaymentOrPreviewPage
              }
            ) ||
            getComponentByValidators(attribute, value, onChange, modifiers, lineItem) || (
            <TextBox
              attribute={attribute}
              value={value}
              id={id}
              onChange={onChange}
              onBlur={onBlur}
              modifiers={modifiers}
              error={error}
              setError={setError}
              disabled={disabled}
            />
            )
          }
          {showHideMessage(visible, modifiers, isPreview, fieldTypeOnAddAccount)}
        </>
      );
    }

    return (
      <>
        {
          getComponentByComponentType(
            {
              attribute: attribute,
              lineItem: lineItem, 
              value: value, 
              id: id, 
              setError: setError, 
              onChange: onChange, 
              onBlur: onBlur, 
              modifiers: modifiers, 
              disabled: disabled, 
              error: error, 
              isEditing: isEditing,
              isPreview: isPreview,
              isPaymentOrPreviewPage: isPaymentOrPreviewPage
            }) 
            || getComponentByValidators(attribute, value, onChange, modifiers, lineItem) 
            || getComponentByDataType(
                {
                  dataType: dataType,
                  attribute: attribute, 
                  value: value, 
                  id: id, 
                  setError: setError, 
                  onChange: onChange, 
                  onBlur: onBlur, 
                  modifiers: modifiers, 
                  disabled: disabled, 
                  error: error, 
                  isEditing: isEditing
                }
            )
        }
        {showHideMessage(visible, modifiers, isPreview, fieldTypeOnAddAccount)}
      </>
    );
       
  } else if(getAnnotation(attribute, AnnotationType.Mutable)){
    return (
      <>
        {
          getComponentByComponentType(
            { 
              attribute: attribute, 
              lineItem: lineItem,
              value: value, 
              id: id, 
              setError: setError, 
              onChange: onChange, 
              onBlur: onBlur, 
              modifiers: modifiers, 
              disabled: disabled, 
              error: error, 
              isEditing: isEditing,
              isPreview: isPreview,
              isPaymentOrPreviewPage: isPaymentOrPreviewPage,
            }
          ) ||
          <Preview
            attribute={attribute}
            value={value}
            modifiers={modifiers}
            lineItem={lineItem}
          />
        } 
        {showHideMessage(visible, modifiers, isPreview, fieldTypeOnAddAccount)}
      </>
      
    )
  }

  if(dataType === DataType.Link)
  {
    return (
      <>
        {
          <Linkview
            attribute={attribute}
            value={value}
            onClick={onClick}
            modifiers={modifiers}
            to={'#'}
          />
        }
        {showHideMessage(visible, modifiers, isPreview, fieldTypeOnAddAccount)}
      </>
    );
  }
  else
  {
    return (
      <>
        {
          <Preview
            attribute={attribute}
            value={value}
            modifiers={modifiers}
            lineItem={lineItem}
          />
        }
        {showHideMessage(visible, modifiers, isPreview, fieldTypeOnAddAccount)}
      </>
    );
  }  
}

export default ComponentFactory;
