import { DateRange } from 'components/dateRange/DateRange';
import { NumberRange } from 'components/NumberRange';
import { convertStringToDate } from 'utils/UtilsDateTime';
import { useChangeHandlers } from '../useAdvanceSearchState';
import styles from '../advanceSearch.module.scss';

export function SearchByDateAmount() {
  const {
    state,
    handleChangeTextBox,
    handleChangeDateRange,
  } = useChangeHandlers();

  return (
    <div className="row" style={{ alignItems: 'flex-end' }}>
      <div className={`col-md-7 ${styles.dateRangeContainer}`}>
        <DateRange
          label="Submitted Date:"
          nameFrom="submittedDateTimeMin"
          nameTo="submittedDateTimeMax"
          value={[state.submittedDateTimeMin, state.submittedDateTimeMax]}
          maxDateFrom={new Date()}
          minDateTo={(state.submittedDateTimeMin && convertStringToDate(state.submittedDateTimeMin)) || undefined}
          maxDateTo={new Date()}
          showTime={true}
          showIcon
          onChangeFrom={(date, name) =>
            handleChangeDateRange(date, name, 'Submitted Date From')
          }
          onChangeTo={(date, name) =>
            handleChangeDateRange(date, name, 'Submitted Date To')
          }
        />
        <DateRange
          label="Settled Date:"
          nameFrom="settledDateTimeMin"
          nameTo="settledDateTimeMax"
          value={[state.settledDateTimeMin, state.settledDateTimeMax]}
          maxDateFrom={new Date()}
          minDateTo={(state.settledDateTimeMin && convertStringToDate(state.settledDateTimeMin)) || undefined}
          maxDateTo={new Date()}
          showIcon
          onChangeFrom={(date, name) =>
            handleChangeDateRange(date, name, 'Settled Date From')
          }
          onChangeTo={(date, name) =>
            handleChangeDateRange(date, name, 'Settled Date To')
          }
        />
      </div>

      <div className="col-md-5">
        <NumberRange
          nameFrom="paymentAmountFrom"
          nameTo="paymentAmountTo"
          valueFrom={state.paymentAmountFrom}
          valueTo={state.paymentAmountTo}
          label="Payment Amount Range:"
          onChangeFrom={e => handleChangeTextBox(e, 'Payment Amount From')}
          onChangeTo={e => handleChangeTextBox(e, 'Payment Amount To')}
        />
        <NumberRange
          nameFrom="discountAmountFrom"
          nameTo="discountAmountTo"
          valueFrom={state.discountAmountFrom}
          valueTo={state.discountAmountTo}
          label="Discount Amount Range:"
          onChangeFrom={e => handleChangeTextBox(e, 'Discount Amount From')}
          onChangeTo={e => handleChangeTextBox(e, 'Discount Amount To')}
        />
      </div>
    </div>
  );
}
