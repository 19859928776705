import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/rootReducer';
import { callService } from 'services/ServicesReducer';
import { GetTransactionsResult, getTransactionsSearchService, PaymentSearchModel } from 'services/PaymentService';
import { GetTransactionsListModel } from 'models/GetTransactionsListModel';

export interface State {
  err?: string;
  result?: GetTransactionsListModel;
}

export const initialState: State = {
  err: '',
  result: undefined,
};

const reducerSlice = createSlice({
  name: 'TransactionInfo',
  initialState,
  reducers: {
        onReceiveTransactionInfo(
      state,
      action: PayloadAction<GetTransactionsResult>
    ) {
      state.result = action.payload.result;
      state.err = action.payload.err;
    },
    setTransactionInfo(
      state,
      action: PayloadAction<GetTransactionsResult>
    ) {
      state.result = action.payload.result;
    },
    resetTransactionInfo(state) {
      state.result = initialState.result;
      state.err = initialState.err;
    },
  },
});

export const {
 
  onReceiveTransactionInfo,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getTransactionInfo(data: PaymentSearchModel, onDone?: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService('GetTransactionInfoService', async () => {
      await getTransactionsSearchService(data).then(response => {
        dispatch(onReceiveTransactionInfo(response));
        if (onDone) onDone();
      });
    }))  
  };
}

export const {
  setTransactionInfo,
  resetTransactionInfo,
} = reducerSlice.actions;
