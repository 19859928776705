import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { setSidepaneLink } from 'features/sidepane/SidepaneReducer';
import { GridConfig } from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';
import { GetPlansResult } from 'services/PaymentPlanService';
import { GetTransactionsResult } from 'services/PaymentService';
import { Header } from './Header';
import { Results } from './Results';
import { Summary } from './Summary';
import 'assets/styles/components/_searchPlansResults.scss';
import { Filters } from '../simpleSearch/SimpleSearchResultsPage';

export interface DataType {
  field?: string;
  value: string | undefined | null;
  type?: string;
}

export enum SearchMode {
  plan = 'plan',
  transaction = 'transaction',
}

export interface SummaryColumn{
  field: string;
  value?: {
    count: number | string;
    total: number | string;
  };
  format?: string;
}

export function SearchResultsTemplate(props: {
  breadcrumbs: { name: string; slug: string };
  mode: SearchMode;
  headerData: DataType[];
  resultsHeaderDefinitions: any;
  buttons: string[];
  onClickCustomize?: () => void;
  showCustomizeColumns?: boolean;
  columnsData?: GridConfig[];
  getData: () => Promise<GetPlansResult | GetTransactionsResult | undefined>;
  updateSummary: (filter: any) => void;
  getSummaryData: () => Promise<any>;
  resetFiltersSummary: () => void;
  loading?: boolean;
  summaryLoading?: boolean;
  noDataFound?: boolean;
  error?: string | boolean;
  downloadReport?: (type: string, sort: { colId: string, sort: any }[], appliedFilters?: Filters[]) => void;
  filterData?: any;
}) {
  const {
    breadcrumbs,
    mode,
    headerData,
    resultsHeaderDefinitions,
    buttons,
    onClickCustomize,
    showCustomizeColumns,
    columnsData,
    getData,
    updateSummary,
    getSummaryData,
    resetFiltersSummary,
    loading,
    summaryLoading,
    noDataFound,
    error,
    downloadReport,
    filterData
  } = props;
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('0');
  const[count, setCount] = useState<number>();

  useEffect(() => {
    dispatch(setSidepaneLink('search'));
    dispatch(setBreadcrumbLinks([breadcrumbs]));
  }, []);

  const toggleActiveTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const getCount = (count: number) => {
    setCount(count);
  }

  return (
    <div className="search-results-container">
      <Header data={headerData} filterData={filterData} columnsData={columnsData} />
      <Nav tabs>
        {[`Results ${loading ? '' : '(' + count + ' total)'}`, 'Summary'].map(
          (item, index) => {
            return (
              <NavItem key={index} className="search-nav-link">
                <NavLink
                  className={classnames({ active: Number(activeTab) === index })}
                  onClick={() => toggleActiveTab(index.toString())}
                >
                  {item}
                </NavLink>
              </NavItem>
            );
          }
        )}
      </Nav>
      <TabContent className="search-tab-content-container" activeTab={activeTab}>
        <Results
          mode={mode}
          headerDefinitions={resultsHeaderDefinitions.filter((column : any) => !column.hide)}
          buttons={buttons}
          onClickCustomize={onClickCustomize}
          showCustomizeColumns={showCustomizeColumns}
          columnsData={columnsData}
          getData={getData}
          getCount={getCount}
          updateSummary={updateSummary}
          resetFiltersSummary={resetFiltersSummary}
          loading={loading}
          noDataFound={noDataFound}
          error={error}
          downloadReport={downloadReport}
        />
        <Summary
          getData={getSummaryData}
          loading={summaryLoading}
        />
      </TabContent>
    </div>
  );
}
