import { LineItem } from "../../../models/LineItem";

export function planToServerLineItem(lineItem: LineItem, patientId?: string, isAdhoc?:boolean): LineItem {
  return {
    amount:  getAmount(lineItem, isAdhoc),
    patientId: patientId ?? lineItem.patientId,
    balance: getBalance(lineItem, isAdhoc),
    accountNumber: lineItem.accountNumber ?? ''
  };

  function getAmount(lineItem: LineItem, isAdhoc?:boolean){
    if (isAdhoc)
    {
      return lineItem.paymentAmount ?? lineItem.amount;
    }
    return lineItem.amount
  }

  function getBalance(lineItem: LineItem, isAdhoc?:boolean){
    return isAdhoc ? (lineItem.balance ?? 0) : lineItem.amount - (lineItem.discount ?? 0); 
  }
}
