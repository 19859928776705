import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import { setGatewayReferenceNumber, setOpenTransactionModel, setPaymentMethod, setTransactionAmount, setTransactionId } from "../../transactionDetails/TransactionDetailsReducer";

export function useSetOpenedTransaction() {
  const dispatch = useDispatch();
  const searchTransactionResultsRecords = useTypedSelector(s => s.simpleSearchInfo.records);

  function setOpenedTransaction(id: string) {
    dispatch(setTransactionId({ id }));
    const matchedTransaction = searchTransactionResultsRecords?.find(
      (t: { id: string }) =>
        t.id?.toLowerCase() === id.toLowerCase()
    );
    dispatch(
      setTransactionAmount(matchedTransaction ? matchedTransaction.amount : 0)
    );
    dispatch(
      setPaymentMethod(
        matchedTransaction ? matchedTransaction.tenderType : ''
      )
    );
    dispatch(
      setGatewayReferenceNumber(
        matchedTransaction ? matchedTransaction.gatewayReferenceNumber : ''
      )
    );
    dispatch(setOpenTransactionModel(true));
  }

  return setOpenedTransaction;
}