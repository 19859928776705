import { EDS_Select } from '@EH/eh.eds.react';
import { useTypedSelector } from 'app/rootReducer';
import { Popup } from 'components/popup/Popup';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';
import { PatientMailingInformation, setPatientMailingInformation } from 'features/paymentInfo/PatientMailingInformationReducer';
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from 'models/enums/EnumPopupType';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import { setGLPaymentInfoState } from 'pages/GLPaymentDashboard/GLPaymentDashboardReducer';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'utils/useHistory';
import { getDepartmentOptions, getFacilityOptions, getUserDepartmentOrFacilityesOptions } from 'utils/UtilsOrganizationDropdowns';
import { getAttribute } from 'utils/metadata/MetaDataUtils';
import usePatientMailingInformationValidator from '../../features/paymentInfo/validators/usePatientMailingInformationValidator';
import { setOpenModal } from '../../pages/GLPaymentDashboard/GLPaymentDashboardReducer';
import { useUserUtils } from 'utils/useUserUtils';

const FacilitySelectorModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getLoggedUserOrganizations } = useUserUtils();
  const state = useTypedSelector(s => s.glPaymentDashboard?.value); 
  const patientMailingInformation = useTypedSelector(s => s.patientMailingInformation?.value);
  const organizations = useTypedSelector(s => s.organizations?.value);  
  const userOrganisations = getLoggedUserOrganizations();
  const departments = organizations.filter(org => org.organizationLevelType === OrganizationLevelTypes.Department);
  const isProcessing = useTypedSelector(s => s.services.calls.getOrganizations?.isProcessing);
  const [chosenFacility, setChosenFacility] = useState<any>()
  const [chosenDepartment, setChosenDepartment] = useState<any>()
  const [selectedFacilityElement, setSelectedFacilityElement] = useState<any>()
  const [selectedDepartmentElement, setSelectedDepartmentElement] = useState<any>()
  const [facilityName, setFacilityName] =  useState<any>()
  const [departmentName, setDepartmentName] =  useState<any>() 
  const userFacilityOptions =  getUserDepartmentOrFacilityesOptions(organizations, userOrganisations)
  const userDepartmentOptions =  getUserDepartmentOrFacilityesOptions(organizations, userOrganisations, departments)

  const footerButtons = [
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Submit',
    },
  ];

  useEffect(()=>{
    if(state?.facility && state?.department){
      setChosenFacility(state?.facility)
      setChosenDepartment(state?.department)
    }
  }, [])
  

  const departmentOptions = useMemo(() =>
    getDepartmentOptions(userDepartmentOptions, [chosenFacility?.path]),
    [userDepartmentOptions, chosenFacility?.id]
  );

  const facilityOptions = useMemo(() => 
    getFacilityOptions(userFacilityOptions),
    [userFacilityOptions]
  );

  const errorMessage = 'Please complete the required information to continue.';
  const glPaymentAlert = { id: AlertIds.GlPaymentAlert, type: AlertTypes.Error, message: errorMessage, dismissable: true  };
  
  const isFacilityInfoValid = () => {
      if(state?.facility?.id == '' || state?.department?.id == '') {
        return false;
      } else {
        return true; 
      }
    };

  const submitHandler = () => {
    if(!isFacilityInfoValid()){
      dispatch(setAlert(glPaymentAlert));
      return;
    }
    if(selectedFacilityElement && selectedDepartmentElement){
      let selectedFacilityOption = selectedFacilityElement.options[selectedFacilityElement.selectedIndex];
      let selectedDepartmentOption = selectedDepartmentElement.options[selectedDepartmentElement.selectedIndex];
    
      dispatch(
        setGLPaymentInfoState({
          ...state,
          [selectedFacilityElement.name]: organizations.find(o => o.id === selectedFacilityOption.value),
          [selectedDepartmentElement.name]: organizations.find(o => o.id === selectedDepartmentOption.value),
        })
      );
      dispatch(
        setPatientMailingInformation({
          ...patientMailingInformation,
          [facilityName]: selectedFacilityOption.value,
          [departmentName]: selectedDepartmentOption.value
        })
      );
      validate(getAttribute(attributes, facilityName), selectedFacilityOption.value);
      validate(getAttribute(attributes, departmentName), selectedDepartmentOption.value);

      dispatch(setOpenModal(false));
      history.push(`/glpayment?facility=${state?.facility?.id || ''}&department=${state?.department?.id || ''}`);
    } else if(selectedFacilityElement){
      let propertyName = selectedFacilityElement.name;
      let optionIndex = selectedFacilityElement.selectedIndex;
      let selectedOption = selectedFacilityElement.options[optionIndex];
    
      dispatch(
        setGLPaymentInfoState({
          ...state,
          [propertyName]: organizations.find(o => o.id === selectedOption.value),
        })
      );
      dispatch(
        setPatientMailingInformation({
          ...patientMailingInformation,
          [facilityName]: selectedOption.value,
        })
      );
      validate(getAttribute(attributes, facilityName), selectedOption.value);
    } else if(selectedDepartmentElement){
      let propertyName = selectedDepartmentElement.name;
      let optionIndex = selectedDepartmentElement.selectedIndex;
      let selectedOption = selectedDepartmentElement.options[optionIndex];
    
      dispatch(
        setGLPaymentInfoState({
          ...state,
          [propertyName]: organizations.find(o => o.id === selectedOption.value),
        })
      );
      dispatch(
        setPatientMailingInformation({
          ...patientMailingInformation,
          [departmentName]: selectedOption.value
        })
      );
      validate(getAttribute(attributes, facilityName), selectedOption.value);
    }
  }

  const dropDownChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
    name: keyof PatientMailingInformation,
  ) => {
    let selectElement = event.target;
    let optionIndex = selectElement.selectedIndex;
    let selectedOption = selectElement.options[optionIndex];
    let selected = organizations.find(o => o.id === selectedOption.value);

    if(name == "facilityId" && selected){
      setChosenFacility(selected);
      setSelectedFacilityElement(selectElement);
      setFacilityName(name);
    }
    if(name == "departmentId" && selected){
      setChosenDepartment(selected);
      setSelectedDepartmentElement(selectElement);
      setDepartmentName(name);
    }
    dispatch(
      setGLPaymentInfoState({
        ...state,
        [selectElement.name]: organizations.find(o => o.id === selectedOption.value),
      })
    );
  };

  const { validate, getErrorMessage, errorClass, attributes } = usePatientMailingInformationValidator(true);
  return (
    <Popup 
      header="PaymentSafe®"
      onClose={() => dispatch(setOpenModal(false))}
      title="USER LOCATION"
      footer={footerButtons}
      onSubmit={() => submitHandler()}
      customClassNames={{footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
    >
      <div className="facility-form">
        <div className='facility-form-select'>
        <EDS_Select
          name="facility"
          label="*Facility:"
          modifiers={`col-12 ${errorClass("facilityId")}`}
          options={facilityOptions}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => dropDownChangeHandler(e, 'facilityId')}
          value={chosenFacility?.id}
          disabled={isProcessing}
        ></EDS_Select>
        </div>
        <div className='facility-form-select'>
        <EDS_Select
          name="department"
          label="*Department:"
          modifiers={`col-12 ${errorClass("departmentId")}`}
          options={departmentOptions}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => dropDownChangeHandler(e, 'departmentId')}
          value={chosenDepartment?.id}
          disabled={isProcessing}
        ></EDS_Select>
        </div>
      </div>
    </Popup>
  )
}

export default FacilitySelectorModal