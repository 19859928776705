import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetailModel, UserSettings } from 'models/UserInfoAndRolesModel';

export interface LoginInformation {
  userName: string;
  password: string;
  email?: string;
  chipUserId: string;
  isFormValid?: boolean;
  loggedIn: boolean;
  realmUserId?: string;
}

export interface State {
  value: LoginInformation;
  loggedUser?: UserDetailModel;
}

export const getInitialState = (): State => ({
  value: {
    userName: localStorage.getItem('userName') || '',
    password: '',
    chipUserId: localStorage.getItem('chipUserId') || '',
    isFormValid: false,
    loggedIn: localStorage.getItem('authToken') !== null,
  },
});

const reducerSlice = createSlice({
  name: 'LoginInformation',
  initialState: getInitialState(),
  reducers: {
    setLoginInformation(state, action: PayloadAction<LoginInformation>) {
      state.value = action.payload;
    },
    setLoggedUser(state, action: PayloadAction<UserDetailModel>) {
      state.loggedUser = action.payload;
    },
    setLoggedUserSettings(state, action: PayloadAction<UserSettings>) {
      state.loggedUser!.userSettings = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase('USER_LOGGED_OUT', (state, action) => {
      return getInitialState();
    });
  },
});

export const {
  setLoginInformation,
  setLoggedUser,
  setLoggedUserSettings
} = reducerSlice.actions;
export default reducerSlice.reducer;