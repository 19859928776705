import { Navigate } from 'react-router-dom';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';


export function PermissionedRoute(props: {
  component?: JSX.Element,
  redirect: string,
  permissions?: number[]}) {

  const { component, redirect, permissions } = props;
  const { havePermissions, haveSSPPermission } = useLoggedUserPermissions();

  const permission = permissions?.includes(UserPermissions.SelfServicePortal) ? haveSSPPermission() : havePermissions(permissions);

  return permission ? component ?? null : < Navigate to = { redirect } /> ;
}