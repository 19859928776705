import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import MultiSelect, { MultiSelectOption } from "../../../components/select/MultiSelect";
import { setUsers } from "../../../features/admin/UserInfoReducer";
import { AlertIds, AlertTypes, setAlert } from "../../../features/alert/AlertReducer";
import { useOrganizations } from "../../../features/organizations/hooks/useOrganizations";
import { FullOrganizationLevelDocument, OrganizationLevelDocument } from "../../../models/OrganizationLevelDocument";
import { UserDetailModel } from "../../../models/UserInfoAndRolesModel";
import { GetUsers, SearchUsers } from "../../../services/UsersService";
import { OrganizationLevelSummary } from "models/OrganizationLevelSummary";
import { SimpleSearchInformation, OrganizationUserSettings } from "./SimpleSearchReducer";
import { getUserDepartmentOrFacilityesOptions } from "../../../utils/UtilsOrganizationDropdowns"
import styles from './organizationUserSearch.module.scss';
import OrganizationLevelTypes from "models/enums/OrganizationLevelTypes";
import { useOrganizationUserSearchState } from "./useOrganizationUserSearch";
import { useLocation } from "react-router";
import { useLoggedUserPermissions } from "app/hooks/useLoggedUserPermissions";
import UserPermissions from "models/enums/UserPermissions";
import { useUserUtils } from 'utils/useUserUtils';
import { fetchPaymentPlanConfig, getDepartmentsEnabled, getFacilitiesWithPaymentPlansEnabled } from "utils/Utils";

export interface DepartmentMultiselectOption extends MultiSelectOption { facilityName: string }
export interface UserMultiSelectOption extends MultiSelectOption { user: UserDetailModel }

const getGeneralContainerStyle = (permissionSection?: boolean, sspUserPermission?: boolean) => {

  if(sspUserPermission) {
    return styles.sppWrapper;
  } else if(permissionSection){
    return styles.customWrapper;
  }
  return styles.wrapper;
}

export default function OrganizationUserSearch(props: {
  facilities?: FullOrganizationLevelDocument[],
  users?: UserDetailModel[],
  initialize?: boolean,
  showError?: boolean,
  permissionSection?: boolean,
  addRemoveFacility?:boolean,
  state: OrganizationUserSettings,
  setState: (state: OrganizationUserSettings) => void,
  noUser?: boolean,
  refreshUserTable?: boolean,
  preselected?: boolean,
  tradingPartner?: string
}) {
  const { noUser, refreshUserTable, facilities: filteredFacilities, users: filteredUsers, initialize = true, showError = true, setState, state, permissionSection, addRemoveFacility = false, preselected, tradingPartner} = props;

  const dispatch = useDispatch();
  const { useGetFacilities } = useOrganizations();
  const simpleSearchSaveSettingsChecked = useTypedSelector(s=> s.simpleSearchInfo?.saveSettings ?? false);
  const advancedSearchSaveSettingsChecked = useTypedSelector(s=> s.advanceSearchInfo?.saveSettings ?? false);
  const paymentPlanSearchSaveSettingsChecked = useTypedSelector(s=> s.paymentPlanSearchInfo?.saveSettings ?? false);
  const usersAndPermissions = useTypedSelector(s => s.usersAndPermissions);

  const location = useLocation();
  const notAdminPage = !location.pathname.includes('/admin-settings');

  const [allSelected, setAllSelected] = useState<boolean>(!!preselected);
  const [allSelectedUsers, setAllSelectedUsers] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<UserDetailModel[]>();
  
  const [departmentList, setDepartmentList] = useState<DepartmentMultiselectOption[] | undefined>(undefined);
  const [findUsers, setFindUsers] = useState<boolean>(true);

  const { hasPermissionOrSuperAdmin } = useLoggedUserPermissions();
  const sspUserPermission = !hasPermissionOrSuperAdmin(UserPermissions.FinancialAdministation) && hasPermissionOrSuperAdmin(UserPermissions.SelfServicePortal);
  
  const allFacilities = useGetFacilities();
  const facilities = allFacilities;
  const { getLoggedUserOrganizations } = useUserUtils();
  const userOrganizations = getLoggedUserOrganizations();
  const activeTab = useTypedSelector(p => p.simpleSearchInfo?.activeTab);
  const userFacilityOptions =  getUserDepartmentOrFacilityesOptions(facilities, userOrganizations)
  const facilityOptions = getOrganizationOptions(getFacilitiesWithPaymentPlansEnabled(userFacilityOptions, filteredFacilities));
  const { getUsersOptions, searchUsers } = useGetUsersOptions();

  const [facilityList, setFacilityList] = useState<MultiSelectOption[] | undefined>(undefined);

  let usersOptions = getUsersOptions(usersList ?? [], filteredUsers);
  const getDepartmentsOptions = useGetDepartmentsOptions();
  const getFilteredDepartments = useGetFilteredDepartments(filteredFacilities);
  const departmentsOptions = getDepartmentsOptions(getFilteredDepartments(facilityList ?? state.facilities));
  const changeSelectedDepartment = useChangeSelectedDepartment(props);
  const changeSelectedUser = useChangeSelectedUser(props);

  const [initialStateSet, setInitialState] = useState(false);
  
  const defaultDepartments = getDepartmentsEnabled(state.departments, departmentsOptions)
  
  useEffect(() => {
    if(facilities.length) {
      setState({
        facilities: facilityOptions,
        departments: [],
        users: [],
        resetSelection: false
      })
    }
  }, [])

  useEffect(() => {
    if(state?.resetSelection){
      setFacilityList(facilityOptions);
      setDepartmentList(state?.departments);
    }
  }, [state?.facilities])
  
  useEffect(() => {
    if (preselected && state?.facilities?.length > 0 && state?.departments?.length > 0 && !initialStateSet) {
      setFacilityList(facilityOptions);
      setDepartmentList(state.departments);
      setInitialState(true);
      setAllSelected(true);
    }

  }, [state?.facilities?.length, state?.departments?.length])
  
  useMemo(() => {
    setFacilityList([]);
    setDepartmentList([]);
    setAllSelected(false);
  }, [usersAndPermissions.tradingPartner?.value, tradingPartner]);

  useEffect(()=> {
    const tabCheckboxMap: { [key: string]: boolean } = {
      '1': simpleSearchSaveSettingsChecked,
      '2': advancedSearchSaveSettingsChecked,
      '3': paymentPlanSearchSaveSettingsChecked,
    };
    
    const viewUsers = usersAndPermissions ? usersAndPermissions.facilities.length : [];
    if (notAdminPage && tabCheckboxMap[activeTab] || viewUsers) {
       setFacilityList(facilityOptions);
       setDepartmentList(state.departments);
    }
   }, [activeTab])

  const openErrorMessage = (message: string) => {
    const errorMessage = `Please select at least one ${message} to proceed`;
    dispatch(setAlert({ id: AlertIds.SearchAlert, type: AlertTypes.Warning, message: errorMessage, dismissable: true }));
  };

  useEffect(() => {
    if (findUsers && state.facilities?.length && state.departments?.length) {
      searchUsers(state.facilities?.map(facility => facility.value), state.departments?.map(department => department.value))
        .then(users => {
          if (users){
            const activeUsers = users.filter(u => u.isActive);
            setUsersList(activeUsers);
            refreshUserOptions();        
          }
        });
    }
  }, [findUsers, state.departments && state.departments.length, state.facilities && state.facilities.length])

  useEffect(() => {
    const fetchAllPaymentPlanConfigs = async () => {
      const planConfigs = await Promise.all(userFacilityOptions.map(facility => fetchPaymentPlanConfig(facility.path)));
    
      const filteredFacilityWithNullRecord = userFacilityOptions
        .filter((facility, index) => planConfigs[index]?.disablePlansRecord == null);
    
      setFacilityList(getOrganizationOptions(filteredFacilityWithNullRecord));
    };
    
    if (state.facilities?.length > 0 && activeTab == '3') {
      fetchAllPaymentPlanConfigs();
    } else {
      setFacilityList(facilityOptions);
    }
  }, [activeTab]);

  const changeSelectedFacility = (selected: MultiSelectOption[], propertyName: keyof SimpleSearchInformation) => {
    const facilitiesAsDepartments = selected.filter(o => selected.some(d => d.value.includes(o.value)));
    const departments = getDepartmentsOptions(getFilteredDepartments(selected));
    const selectedDepartments = departments.concat(facilitiesAsDepartments as DepartmentMultiselectOption[]);

    setState({
      facilities: selected,
      departments: selectedDepartments,
      users: [],
      resetSelection: false
    })

    if(facilityOptions.length === selected.length){
      setAllSelected(true)
      setAllSelectedUsers(true);
    }else{
      setAllSelected(false)
      //make all others selected false
      setAllSelectedUsers(false);
    }

    if(selected) {
      setFacilityList(selected)
      setDepartmentList(selectedDepartments);
      setFindUsers(true)
    }
    else {
      setFacilityList([]);
    }
    
    if (propertyName === "facilities" && !selected.length) {
      showError && openErrorMessage('Facility');
    }
  };

  const useChangeSelectedDep = () => {
    const { userFacilitiesAPG, withoutRecalculateAndSetState } = useOrganizationUserSearchState(props);
    return (selected: DepartmentMultiselectOption[], propertyName: keyof SimpleSearchInformation) => {
      const facilitiesSelect = filteredFacilities ? filteredFacilities : userFacilitiesAPG    
      const selectedFacilities = facilitiesSelect
        .filter(f => selected.find(d => d.value.includes(f.path)))
        .map(f => ({ value: f.path, label: f.name }));
      
      const facilitiesAsDepartments = selectedFacilities.filter(o => selected.some(d => d.value.includes(o.value)));
      const selectedDepartments = selected.concat(facilitiesAsDepartments as DepartmentMultiselectOption[]);
      withoutRecalculateAndSetState({ facilities: selectedFacilities, departments: selectedDepartments });
  
      if(selected.length){
        setDepartmentList(selected);
        setFindUsers(true);
      }
      else{
        setDepartmentList([]);
      }

      if (propertyName === "departments" && !selected.length) {
        showError && openErrorMessage('Departments');
      }
    }
  }
  
  const useChangeSelectedUsers = () => {
    const { userFacilitiesAPG, users, withoutRecalculateAndSetState } = useOrganizationUserSearchState(props);
  
    return (selected: UserMultiSelectOption[], propertyName: keyof SimpleSearchInformation) => {
      const selectedFacilities = userFacilitiesAPG
        .filter(f => selected.find(u => users.find(user => user.id == u.value)?.organizationLevels?.find(o => f.path.includes(o.organizationLevel_Path))))
        .map(f => ({ value: f.path, label: f.name }));
      withoutRecalculateAndSetState({ facilities: selectedFacilities, users: selected });

      if(usersOptions.length === selected.length){
        setAllSelectedUsers(true)
      }else{
        setAllSelectedUsers(false)
      }
    }
  }


  const refreshUserOptions = () => {

    if(refreshUserTable && state.users.length) {
      setState({
        ...state,
        users: []
      })
    }
  }

  const changeSelectedDep = useChangeSelectedDep();
  const changeSelectedUsers = useChangeSelectedUsers();

  const selectAll = (propertyName: any, inputValue?: string) => {
    switch(propertyName){
      case 'facilities':
        if(allSelected){
          changeSelectedFacility([], propertyName);
          setAllSelected(false)
          setFacilityList([])
        }else{
          changeSelectedFacility(facilityOptions, propertyName);
          setAllSelected(true)
          setFacilityList(facilityOptions)
        }
        break;
      case 'departments':
        if (defaultDepartments?.length === departmentsOptions?.length){
          setState({
            facilities: state.facilities,
            departments: [],
            users: state.users,
            resetSelection: false
          });
          setDepartmentList([])
        }else{
          changeSelectedDepartment(departmentsOptions, propertyName);
          setFindUsers(true)
          setDepartmentList(departmentsOptions)
        }
        break;
      case 'users':
        if(allSelectedUsers){
          setState({
            facilities: state.facilities,
            departments: state.departments,
            users: [],
            resetSelection: false
          });
          setAllSelectedUsers(false)
        }else{
          let foundUsers = usersOptions;
          if(inputValue) {
            foundUsers = usersOptions.filter(value => value.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
          }
          changeSelectedUser(foundUsers, propertyName);
          setAllSelectedUsers(true)
        }
        break;
    }
  }

  return <div className={`${getGeneralContainerStyle(permissionSection, sspUserPermission)}`} id="general-container">
    <div className={`${permissionSection ? styles.customColumn : styles.multiselect}`} id="facility-container">
      {!!facilityList || !preselected ? <MultiSelect
        label={"Facilities:"}
        name={"facilities"}
        options={facilityOptions}
        onChange={changeSelectedFacility}
        values={facilityList ?? []}
        multiple={true}
        searchCheckbox={true}
        selectAll={selectAll}
        allSelected={facilityList?.length === facilityOptions?.length}
        button={'Select All'}
      /> : "Processing..."}
    </div>
    <div className={styles.multiselect}>
      {!!departmentList || !preselected ? <MultiSelect
        label={"Departments:"}
        name={"departments"}
        options={departmentsOptions}
        onChange={changeSelectedDep}
        values={departmentList ?? []}
        groupBy={(o: DepartmentMultiselectOption) => o.facilityName.toUpperCase()}
        multiple={true}
        searchCheckbox={true}
        selectAll={selectAll}
        allSelected={defaultDepartments?.length === departmentsOptions?.length}
        button={'Select All'}
      /> : "Processing..."}
    </div>
    {permissionSection || addRemoveFacility || noUser
      ? 
      <></>
      :
      <>
      <div className={styles.multiselect}>
        <MultiSelect
          label={"Users:"}
          name={"users"}
          options={usersOptions}
          onChange={changeSelectedUsers}
          values={state.users}
          multiple={true}
          searchCheckbox={true}
          selectAll={selectAll}
          allSelected={state.users?.length === usersOptions?.length}
          button={'Select All'} />
      </div>
      </>
    }
  </div>;
}

export function useGetFilteredDepartments(filteredFacilities?: (OrganizationLevelDocument|undefined)[]) {
  const { getDepartments, useGetFacilities } = useOrganizations();
  const facilities = useGetFacilities();

  return (selectedFacilities?: (MultiSelectOption | undefined)[]) => getDepartments((department) => {
    if (selectedFacilities && selectedFacilities.length) {
      return !!selectedFacilities.find(facility =>
        facility && department.path.includes(facility.value)
      )
    }
    return !!(filteredFacilities ?? facilities).find(facility =>
      facility && department.path.includes(facility.path)
    )
  });
}

export function useGetUserDepartmentsOptions() {
  const { useGetDepartments, organizations } = useOrganizations();
  const getDepartmentsOptions = useGetDepartmentsOptions();
  const departments = useGetDepartments();

  return (userOrganizations: OrganizationLevelSummary[] | undefined) => {
    let userDepartmentOptions = getUserDepartmentOrFacilityesOptions(organizations, userOrganizations, departments)
    const orgsAsDepartments = organizations?.filter(o => o.organizationLevelType === OrganizationLevelTypes.Facility && userDepartmentOptions.some(d => d.path.includes(o.path)));
    userDepartmentOptions = userDepartmentOptions.concat(orgsAsDepartments ?? []);
    return getDepartmentsOptions(userDepartmentOptions)
  }
}
  

export function useGetDepartmentsOptions( ) {
  const { getFacilityByDepartment } = useOrganizations();
  return (departments: OrganizationLevelDocument[]) => {
    const departmentOptions = getOrganizationOptions(departments);
    return departmentOptions.map(o => ({ ...o, facilityName: getFacilityByDepartment(o.value)?.name ?? '' })).sort((a, b) => {
      if (a.facilityName > b.facilityName) return 1;
      if (a.facilityName < b.facilityName) return -1;
      return 0;
    });
  }
}

export function useGetUsersOptions() {
  const dispatch = useDispatch();
  const users = useTypedSelector(s => s.userInfo.users) ?? [];

  const getUsers = async (onSuccess: (users: UserDetailModel[]) => void) => {
    const response = await GetUsers();
    if (response.result?.length) {
      const validUsers = response.result.filter(u => !!u.oneSourceUserId);
      dispatch(setUsers(validUsers));
      onSuccess && onSuccess(validUsers);
    } else {
      dispatch(setAlert({ id: AlertIds.SearchAlert, type: AlertTypes.Warning, message: "Could not load any users.", dismissable: true  }))
    }
  }

  const searchUsers = async (selectedFacilitiesPaths?: string[], selectedDeparmentsPaths?: string[]) => {
    const selectedOrgPaths = selectedFacilitiesPaths && selectedDeparmentsPaths ? selectedFacilitiesPaths.concat(selectedDeparmentsPaths) : [];
    if (!selectedOrgPaths.length) return;
    const data = {
      organizationPaths: selectedOrgPaths,
      offset: 0,
    }
    const response = await SearchUsers(data);
    if (response.result?.records.length) {
      return response.result?.records.filter(u => !!u.oneSourceUserId);   
    } else {
      dispatch(setAlert({ id: AlertIds.SearchAlert, type: AlertTypes.Warning, message: "Could not load any users.", dismissable: true  }))
    }
  }

  const getUsersOptions = (usersList: UserDetailModel[], filteredUsers?: UserDetailModel[]) => {
    const usersByFacilities: UserDetailModel[] = [];
    usersByFacilities.push(...usersList ?? filteredUsers);

    return usersByFacilities?.map(getUserOption)
  }

  return { getUsers, searchUsers, getUsersOptions, users }
}

export function getOrganizationOptions<T extends MultiSelectOption>(organizations: OrganizationLevelDocument[], map?: (o: OrganizationLevelDocument) => T) {
  const mappingFunction = map ?? getOrganizationOptionMapper;
  return organizations.map(mappingFunction)
    .sort((a, b) => {
      if (a.label > b.label) return 1;
      if (b.label > a.label) return -1;
      return 0;
    }) as T[];
}

const getOrganizationOptionMapper = (organization: OrganizationLevelDocument) => getOrganizationOption(organization);

const getDefaultOrganizationLabel = (organization: OrganizationLevelDocument) => {
  if (organization.organizationLevelType === OrganizationLevelTypes.Department) {
    return organization.name;
  }

  if (organization.organizationLevelType === OrganizationLevelTypes.TradingPartner) {
    return `(${organization.tradingPartnerId}) ${organization.name}`;
  }

  return `${organization.clientId} (${organization.name})`;
}

export const getOrganizationOption = (organization: OrganizationLevelDocument, getLabel?: (organization: OrganizationLevelDocument) => string) => {
  getLabel ??= getDefaultOrganizationLabel;
  return {
    value: organization.path,
    label: getLabel(organization).replace(/"/g, '')
  }
}

export const useGetOrganizationOptionWithTradingPartner = (getLabel?: (organization: OrganizationLevelDocument) => string) => {
  const { getTradingPartnerByFacility } = useOrganizations();
  return (organization: OrganizationLevelDocument) => {
    const tradingPartnerName = getTradingPartnerByFacility(organization.path)?.name;
    return {
      ...getOrganizationOption(organization, getLabel),
      tradingPartnerName: tradingPartnerName?.length ? tradingPartnerName : 'N/A'
    }
  }
}

export interface TradingPartnerMultiselectOption extends MultiSelectOption { tradingPartnerName: string }
export function useGetFacilitesByTradingPartnerOptions(getLabel?: (organization: OrganizationLevelDocument) => string, selectedFacility?: OrganizationLevelDocument): TradingPartnerMultiselectOption[] {
  const { useGetFacilities, getTradingPartnerByFacility } = useOrganizations();
  const selectedTradingPartner = getTradingPartnerByFacility(selectedFacility?.path);
  const getOrganizationOptionWithTradingPartner = useGetOrganizationOptionWithTradingPartner(getLabel);
  const facilities = useGetFacilities();
  const facilityOptions = getOrganizationOptions(facilities, getOrganizationOptionWithTradingPartner);
  return facilityOptions.sort((a, b) => {
    if (a.tradingPartnerName == b.tradingPartnerName) return 0;
    if (a.tradingPartnerName != selectedTradingPartner?.name &&
      (a.tradingPartnerName > b.tradingPartnerName || b.tradingPartnerName == selectedTradingPartner?.name)) return 1;
    return -1;
  });
}

export const getUserOption = (user: UserDetailModel) => {
  return {
    value: user.id,
    label: `(${user.oneSourceUserId}) ${user.firstName ?? ''} ${user.lastName ?? ''}`.trim(),
    user
  }
}

export const useChangeSelected = (props?: {
  facilities?: FullOrganizationLevelDocument[],
  departments?: FullOrganizationLevelDocument[],
  users?: UserDetailModel[],
  state: OrganizationUserSettings,
  setState: (state: OrganizationUserSettings) => void
}) => {
  const { withoutRecalculateAndSetState } = useOrganizationUserSearchState(props);

  return (selected: MultiSelectOption[], propertyName: keyof SimpleSearchInformation) => {
    withoutRecalculateAndSetState({ facilities: selected });
  }
}

const useChangeSelectedDepartment = (props?: {
  facilities?: FullOrganizationLevelDocument[],
  departments?: FullOrganizationLevelDocument[],
  users?: UserDetailModel[],
  state: OrganizationUserSettings,
  setState: (state: OrganizationUserSettings) => void
}) => {
  const { facilities} = props ?? {};
  const { userFacilitiesAPG, recalculateAndSetState } = useOrganizationUserSearchState(props);
  return (selected: DepartmentMultiselectOption[], propertyName: keyof SimpleSearchInformation) => {
    const facilitiesSelect = facilities ? facilities : userFacilitiesAPG    
    facilitiesSelect
      .filter(f => selected.find(d => d.value.includes(f.path)))
      .forEach(f => ({ value: f.path, label: f.name }));
    recalculateAndSetState('departments');
  }
}

const useChangeSelectedUser = (props?: {
  facilities?: FullOrganizationLevelDocument[],
  departments?: FullOrganizationLevelDocument[],
  users?: UserDetailModel[],
  state: OrganizationUserSettings,
  setState: (state: OrganizationUserSettings) => void
}) => {
  const { withoutRecalculateAndSetState } = useOrganizationUserSearchState(props);

  return (selected: UserMultiSelectOption[], propertyName: keyof SimpleSearchInformation) => {
    withoutRecalculateAndSetState({ users: selected });
  }
}