import { useState } from 'react';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import 'assets/styles/components/_clientConfiguration.scss';
import ConfigurationTemplate from '../../features/admin/clientConfiguration/ConfigurationTemplate';
import 'assets/styles/components/_deviceManager.scss';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';
import { formatDate } from 'utils/Utils';
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { HeaderButton } from 'pages/Search/planSearch/paymentPlanDetailsHeader/HeaderButton';
import { ReactComponent as Save } from 'assets/svgs/admin/remit/Save.svg';
import AdminSubSettingsTabs from 'features/adminSettingsTabs/AdminSubSettingTabs';
import ScheduleRemit, { TimeSelected } from 'features/admin/remitService/ScheduleRemit';
import RecreationRemit from 'features/admin/remitService/RecreationRemit';
import * as router from 'react-router-dom';
import { getRemitSchedule, saveRemitSchedule, updateRemitSchedule } from 'features/admin/remitService/RemitScheduleReducer';
import {  processRemit } from 'features/admin/remitService/RemitRecreationReducer';
import { MultiSelectOption } from 'components/select/MultiSelect';
import moment from 'moment';
import ActionConfirmModal from 'features/admin/ActionConfirmModal';
import { RemitConfigurationResponse } from 'models/RemitConfigurationModel';
import RemitConfigurationHistory from 'features/admin/remitService/RemitConfigurationHistory';
import RunHistoryRemit from 'features/admin/remitService/RunHistoryRemit';
import { AlertPopUp } from 'components/alert/alertPopUp';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

const defaultHistoryDepth = 14;
export default function RemitService(
  props: Props = { rootPath: 'remit-service' }
) {
  
  const remitSchedule = useTypedSelector(s => s.remitSchedule?.value);  
  const params = router.useParams<{ clientId: string }>();

  const { getOrganizationById } = useOrganizations();

  const selectedFacilityId = params.clientId;
  const organization = getOrganizationById(selectedFacilityId)
  const loggedUser = useTypedSelector(s=> s.loginInfo.loggedUser)

  const [timeSelected, setTimeSelected] = useState<TimeSelected[]>()
  const [historyDepth, setHistoryDepth] = useState<undefined | number>(defaultHistoryDepth)
  const [remitDateRecreation, setRemitDateRecreation] = useState<string>()
  const [remitFileRecreationSelected, setRemitFileRecreationSelected] = useState<MultiSelectOption | null>(null)
  const [isRecreateModalOpen, setIsRecreateModalOpen] = useState<boolean>(false)
  const [isRecreationInProcess, setIsRecreationInProcess] = useState<boolean>(false)
  const [doShowAlert, setDoShowAlert] = useState(false)
  const [alertPopUpType, setalertPopUpType] = useState(AlertTypes.Info);
  const [alertPopUpContent, setalertPopUpContent] = useState([""]);
  const [alertPopUpHeader, setalertPopUpHeader] = useState("");
  
  const remitConfiguration = useTypedSelector(
    s => s.remitRecreationConfiguration?.value?.records
  );

  const dispatch = useDispatch();
  
  const initialTabSelections = [
    { isActive: false, tabName: 'Schedule Remit'},
    { isActive: false, tabName: 'Run History' },
    { isActive: false, tabName: 'Configuration History' },
    { isActive: false, tabName: 'Recreation' },
  ]
  
  
  const [subTabSelections, setSubTabSelections] = useState(
    [
      { isActive: true, tabName: 'Schedule Remit'},
      { isActive: false, tabName: 'Run History' },
      { isActive: false, tabName: 'Configuration History' },
      { isActive: false, tabName: 'Recreation' },
    ]
  );
  function DisplayPopUpAlert(alertContent:string[], type : AlertTypes, header:string)
  {
    setDoShowAlert(true);
    setalertPopUpContent(alertContent);
    setalertPopUpType(type);
    setalertPopUpHeader(header);
  }

  function ResetPopUpAlert()
  {
    setalertPopUpContent([]);
    setalertPopUpType(AlertTypes.Info);  
    setalertPopUpHeader("");
    setDoShowAlert(false);
  }

  function displayAlert() {
    return doShowAlert ? (
      <div>
        <AlertPopUp onClose={()=>{ResetPopUpAlert()}} alertType={alertPopUpType} content ={alertPopUpContent} header= {alertPopUpHeader}></AlertPopUp>
      </div>
   ):null;
  }

  const onSaveScheduleRemit = () => {
    let timeSelectedValue: number[] = []
      if (!timeSelected) return
      timeSelected.forEach((el)=> timeSelectedValue.push(+el.value))     
      
      if(!organization?.path) return
      if (remitSchedule?.id && timeSelectedValue.length > 0){
        const updateData = {       
          startHours: timeSelectedValue,
          historyInDays: historyDepth ?? defaultHistoryDepth
        }
        dispatch(updateRemitSchedule(remitSchedule?.id, updateData, processUpdateRemitSchedule));          
      }
      
      if (!remitSchedule?.id && timeSelectedValue.length > 0) {
        const saveData = {
          organization: {
            id: organization?.id,
            name: organization?.name,
            path: organization?.path
          },
          startHours: timeSelectedValue,
          historyInDays: historyDepth ?? defaultHistoryDepth
        }
        dispatch(saveRemitSchedule(saveData, processUpdateRemitSchedule))       
      }
    }
    function getErrorAlert(message: string, index?: number) {
      return {
        id: AlertIds.EditRemitConfig + (index ? "_" + index : ""),
        type: AlertTypes.Error,
        message,
        dismissable: false
      }
    }
    
    function getSuccessAlert(message: string) {
      return {
        id: AlertIds.EditRemitConfig,
        type: AlertTypes.Success,
        message,
        dismissable: true
      }
    }

    const processUpdateRemitSchedule = (errors?:string[])=>{
      if (errors){
        errors.forEach((x: any, i: number) => {
          if (x.errorMessage)
            dispatch(setAlert(getErrorAlert(x.errorMessage.replace(/[^a-zA-Z ]/g, ""), i)));
        })
      }
      else{
        if (organization)
          dispatch(getRemitSchedule(organization?.path))     
          dispatch(setAlert(getSuccessAlert("Remit Service Configuration saved successfully!")));
      }
    }

    const onRecreateRemit = () => { 
      setIsRecreationInProcess(true) 
      
      if(!organization?.path || !remitConfiguration || !remitDateRecreation) return
      const remitConfig = remitConfiguration[0];
      const config = remitConfiguration.filter(conf => conf.fileIdentifier === remitFileRecreationSelected?.value)[0]
      const newRemitConfiguration =  {
        id: config.id ,
        fileName: config.fileName ,
        fileIdentifier: config.fileIdentifier ,
        filePath: config.filePath ,
        fileExtension: config.fileExtension,
        organization: config.organization,
        createWithoutRecords: remitConfig.createWithoutRecords
      } 
           
      const recreateData = {
        remitConfiguration: newRemitConfiguration,
        history: 0,
        recreateRemit: true,
        startDate: `${formatDate(moment(remitDateRecreation))} 00:00:00`,
        endDate: `${formatDate(moment(remitDateRecreation))} 23:59:59`,
        userDetail: {
          id: loggedUser?.id,
          firstName: loggedUser?.firstName,
          lastName: loggedUser?.lastName,
          oneSourceUserId: loggedUser?.oneSourceUserId
        }
      }
      dispatch(processRemit(recreateData, (data: RemitConfigurationResponse) => {
        if (!data.err) {
            dispatch(
              setAlert({
                id: AlertIds.RemitRecreation,  
                type: AlertTypes.Success,
                message: `Recreating this remit file has started.` ,
                dismissable: true,
              })
            );
            return
        }
        dispatch(
          setAlert({
            id: AlertIds.RemitRecreation,  
            type: AlertTypes.Error,
            message: data.err ,
            dismissable: false,
          })
        );
      }))

      setIsRecreationInProcess(false) 
      setIsRecreateModalOpen(false);
    }

    const content = [
      { 
        tabName: 'Schedule Remit',
        content:
        <ScheduleRemit 
          timeSelected={timeSelected} 
          setTimeSelected={setTimeSelected}
          organization={organization}
          historyDepth={historyDepth}
          setHistoryDepth={setHistoryDepth}
        />,
        headerButtons: 
        <HeaderButton 
          title={"Save Changes"} 
          icon={<Save />} 
          onClick={onSaveScheduleRemit} 
          disabled={false}
        /> 
      },
      { 
        tabName: 'Recreation', 
        content:
        <RecreationRemit 
          remitDateRecreation={remitDateRecreation} 
          setRemitDateRecreation={setRemitDateRecreation}
          organization={organization}
          remitFileRecreationSelected={remitFileRecreationSelected}
          setRemitFileRecreationSelected={setRemitFileRecreationSelected}
        />, 
        headerButtons: 
        <HeaderButton 
          title={"Recreate"} 
          icon={<Save />} 
          onClick={() => {
            if(!remitDateRecreation || !remitFileRecreationSelected) return
            setIsRecreateModalOpen(true);
          }} 
          disabled={false}
        />,
        conformationModal:
        <ActionConfirmModal
          isOpen={isRecreateModalOpen}
          isProcessing={isRecreationInProcess}
          actionTitle="Recreate"
          actionButtonText="OK"
          actionConfirmText={`Are you sure you want to recreate this remit file for that day?`}
          actionCancelButtonText={'Cancel'}
          onClose={() => {
            setIsRecreateModalOpen(false);
          }}
          onActionConfirm={onRecreateRemit}
          showCancelButton={true}
        />
      },
      { tabName: 'Run History', content: <RunHistoryRemit organizationPath={organization?.path}/>},
      { tabName: 'Configuration History', 
        content: 
        <RemitConfigurationHistory 
          organization={organization}
        /> 
      }
    ]

    const renderContent = content.find(c=> c?.tabName === subTabSelections.find(tab=> tab.isActive)?.tabName)

    const headerButtons = <div className={"header-buttons"}>    
      {renderContent?.headerButtons}
    </div>

    return (
      <div className="client-configuration">
        <ConfigurationTemplate title="Remit Service" headerButtons={headerButtons}>
          <AdminSubSettingsTabs
            tabSelections={subTabSelections}
            initialTabSelections={initialTabSelections}
            onClick={newTabSelections => {
            setSubTabSelections(newTabSelections);
          }}
          />
          <div>
            { renderContent?.content }
            {displayAlert()}
          </div>
            { renderContent?.conformationModal ? renderContent?.conformationModal : <></> }
        </ConfigurationTemplate>
      </div>
    );
}
