import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'utils/useHistory';
import { TabPane } from 'reactstrap';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { ReactComponent as ClearFilters } from 'assets/svgs/icon-clear-filters.svg';
import { ReactComponent as ClearFiltersDisabled } from 'assets/svgs/icon-clear-filters-disabled.svg';
import { ReactComponent as Tools } from 'assets/svgs/icon-tools.svg';
import { ReactComponent as SettingsDisabled } from 'assets/svgs/icon-settings-disabled.svg';
import { ReactComponent as Search } from 'assets/svgs/icon-search-red.svg';
import { ReactComponent as Settings } from 'assets/svgs/icon-settings.svg';
import { ReactComponent as File } from 'assets/svgs/icon-file-copy.svg';
import Loading from 'assets/gifs/loading.gif';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import { EnumTermType } from 'models/enums/EnumPaymentPlan';
import { ApiTenderTypeEnum, formatAmount, formatUtcDateString as formatUtcDateFromString } from 'utils/Utils';
import { GetPlansResult } from 'services/PaymentPlanService';
import { GetTransactionsResult } from 'services/PaymentService';
import CustomizeSearchColumns from 'features/customizeSearchResultColumns/customizeSearchColumns';
import { GridConfig } from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';
import { PaymentPlanDetailsModal } from 'features/paymentPlan/PaymentPlanDetailsLink';
import * as CellRenderers from 'components/CellRenderers';
import { HeaderButton } from '../planSearch/paymentPlanDetailsHeader/HeaderButton';
import { getFilterModel, useUpdateData } from './SearchResultUtils';
import { SearchMode } from './SearchResultsTemplate';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'assets/styles/components/_searchPlansResults.scss';
import UserPermissions from 'models/enums/UserPermissions';
import { useTypedSelector } from 'app/rootReducer';
import { OrganizationLevelSummary } from 'models/OrganizationLevelSummary';
import { Filters } from '../simpleSearch/SimpleSearchResultsPage';
import { useGetUserSettings } from '../SearchPaymentspage';
import { useDispatch } from 'react-redux';
import { FaSave } from 'react-icons/fa';
import { PutUserSettings } from 'services/UsersService';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';
import { setLoggedUserSettings } from 'pages/Loginpage/reducers/LoginPageReducer';

export function formatCurrency(params: any) {
  return params.value ? `$${formatAmount(params.value.toFixed(2))}` : '';
}

export function formatPaymentStatus(params: any) {
  return params?.value ?? '';
}

export function formatTenderType(params: any) {
  if (!params.value) return '';
  const cardEntryTypes = [String(ApiTenderTypeEnum.CardDevice), String(ApiTenderTypeEnum.CardManual)];
  return  cardEntryTypes.includes(params.value) ? 'Card' : params.value;
}

export function formatTenderTypeTransaction(params: any) {
  return params.value ?? '';
}

export function formatTermType(params: any) {
  let termType = params?.value ?? '';
  if (termType === EnumTermType.BiWeekly) {
    termType = 'Bi-Weekly';
  }
  return termType;
}

export function formatCreditCard(params: any) {
  return params.value ? params.value.slice(-4) : '';
}

export function formatDateString(params: any) {
  return params.value ? moment.utc(params.value).format('MM/DD/YYYY hh:mm:ss A') : '';
}

export function formatDateOfBirthString(params: any) {
  return formatUtcDateFromString(params.value);
}

export function formatIsGl(params: any) {
  return params.value ? 'GL Plan' : 'Payment Plan';
}

export function formatPercent(params: any){
  return params.value ? `${params.value}%` : '';
}

export const ExportButton = (props: {
  noDataFound?: boolean,
  downloadReport?: (type: string, filters?: Filters[]) => void,
  appliedFilters?: Filters[]
}) => {
  const {
    noDataFound,
    downloadReport,
    appliedFilters
  } = props;

  const [exportDropdown, setExportDropdown] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(true);

  const isDownloadingTReport = useTypedSelector(s => s.services.calls.DownloadExcelFullTransactionsReport?.isProcessing);  
  const isDownloadingPReport = useTypedSelector(s => s.services.calls.DownloadExcelFullPaymentPlansReport?.isProcessing);  
  const disabledExport = noDataFound || isDownloadingTReport || isDownloadingPReport ? 'excel-disabled' : ''
  const ref = useRef() as any;
  
  const hideExportMenu = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
      setExportDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', hideExportMenu, true);
    return () => {
      document.removeEventListener('click', hideExportMenu, true);
    };
  }, []);

  const renderExportDropdown =
    (
      <div className='popup-export' ref={ref} >
      {isVisible ? <>
        {/* TODO: erase excel-disabled styles in _searchPlansResults.scss when no more needed! */}
        <div className={`${disabledExport}`} onClick={() => {
          !noDataFound && downloadReport && downloadReport('excelFull', appliedFilters);
          setExportDropdown(false);
        }}>MS Export Excel - Full</div>
        <div className="excel-disabled"
          // downloadReport('excelFiltered')
        >MS Export Excel - Filtered</div>
        <div className="excel-disabled"
          // downloadReport('pdfFull')
        >PDF Report - Full</div>
        <div className="excel-disabled"
          // downloadReport('pdfFiltered')
        >PDF Report - Filtered</div>
      </> : null }
      </div>
    );

  const showExportDropdown = () => {
    if(isVisible) {
      return renderExportDropdown;
    }
  }

  return (
    <>
      {exportDropdown && showExportDropdown()}
      <HeaderButton
        className={'flex-column'}
        title={'Export File'}
        icon={<File />}
        secondary={<i className="material-icons dropdown-arrow-file">arrow_drop_down</i>}
        onClick={() => {
          setExportDropdown(!exportDropdown);
          setIsVisible(true)
        }}
      />
    </>
  )
}

function LoadingTextRender() {
  return <div>
    <img src={`${Loading}`} alt='Loading...'></img>
    <span>{' Loading...'}</span>
  </div>;
}

export const LinkRenderer = (params: { value: string; data?: { id: any } }) => {
  return params.data?.id
    ? CellRenderers.LinkRenderer(params)
    : LoadingTextRender();
}

export const PlanLinkRenderer = (params: { value: string; data?: { id: any } }) => {
  return params.data?.id
    ? CellRenderers.PlanLinkRenderer(params)
    : LoadingTextRender();
}

export function Results(props: {
  mode: SearchMode;
  headerDefinitions: any;
  buttons: string[];
  onClickCustomize?: () => void;
  showCustomizeColumns?: boolean;
  columnsData?: GridConfig[];
  getData: () => Promise<GetPlansResult | GetTransactionsResult | undefined>;
  getCount: (count: number) => void;
  updateSummary: (filter: any) => void;
  resetFiltersSummary: () => void;
  loading?: boolean;
  noDataFound?: boolean;
  error?: string | boolean;
  downloadReport?: (type: string, sort: { colId: string, sort: any }[], filters?: Filters[]) => void;
}) {
  const {
    mode,
    headerDefinitions,
    buttons,
    onClickCustomize,
    showCustomizeColumns,
    columnsData,
    getData,
    getCount,
    updateSummary,
    resetFiltersSummary,
    loading,
    noDataFound,
    error,
    downloadReport
  } = props;
  const history = useHistory();
  const pageSize = useTypedSelector(c => c.simpleSearchInfo?.pageSize);
  const loggedUser = useTypedSelector(s => s.loginInfo?.loggedUser);
  const { havePermission } = useLoggedUserPermissions();
  const [headers, setHeaders] = useState<any[]>([]);
  const [settingsPermission, setEnabledSettingPermission] = useState(false);
  const [sort, setSort] = useState<{colId: string, sort: any}[]>();

  const [isClearAllFiltersDisabled, setIsClearAllFiltersDisabled] = useState<boolean>(true);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [appliedFilters, setAppliedFilters] = useState<Filters[]>();
  const {getUserSettings, userSettings, userId} = useGetUserSettings();
  let dispatch = useDispatch();
  const transactionColumnOrder = 'transactionColumnOrder';
  const planColumnOrder = 'planColumnOrder';
  const organizations = useTypedSelector(p => p.organizations?.value);

  const resetFiltersSearch = () => {
    gridApi && gridApi.setFilterModel(null);
    setIsClearAllFiltersDisabled(true);
  }

  const { updateData, count } = useUpdateData();

  useEffect(() => {
    getCount(count || 0);
    checkPermissions(loggedUser?.organizationLevels ?? []);
  }, [count])

  useEffect(() => {
    if (!userSettings) {
      getUserSettings(true);
    }
  }, [organizations])

  const checkPermissions = (orgPaths: OrganizationLevelSummary[]) => {
    orgPaths?.forEach(org => {
      if(havePermission(UserPermissions.StartStopPaymentPlan, org.organizationLevel_Path)) {
          setEnabledSettingPermission(true);
      }
    })
  }

  const onsaveColumnOrder = async () => {
    const currentMode = mode === SearchMode.transaction ? transactionColumnOrder : planColumnOrder;
    const columnOrderState = localStorage.getItem(currentMode);
    if (columnOrderState && userSettings) {
      const jsonOrderState = {...userSettings, [currentMode]: JSON.parse(columnOrderState)};
      await PutUserSettings(userId!, JSON.stringify(jsonOrderState).replace(/"/g, '\\"'))
        .then((response) => {
          if (response.result) {
            const accountNumberAlert = { id: AlertIds.SearchAlert, type: AlertTypes.Success, message: `Column order saved` };
            dispatch(setLoggedUserSettings({...userSettings, [currentMode]: JSON.parse(columnOrderState)}));
            dispatch(setAlert(accountNumberAlert));
          } else if (response.err) {
            const accountNumberAlert = { id: AlertIds.SearchAlert, type: AlertTypes.Error, message: `Error saving column order: ${response.err}` };
            dispatch(setAlert(accountNumberAlert));
          }
        })
    }
  }

  const onMoveColumn = (params: any) => {
    const columnOrderState = JSON.stringify(params.columnApi.getColumnState());
    if (mode === SearchMode.transaction) {
      localStorage.setItem(transactionColumnOrder, columnOrderState);
    } else if (mode === SearchMode.plan) {
      localStorage.setItem(planColumnOrder, columnOrderState);
    }
  }

  const buttonDefinitions = [
    {
      name: 'saveColumnOrder',
      renderButton:
      <HeaderButton
        className={'flex-column flex-column--save-icon'}
          title={'Save Column Order'}
          key={'saveColumnOrder'}
        icon={<FaSave />}
        onClick={() => {onsaveColumnOrder()}}
      />
    },
    {
      name: 'clearFilters',
      renderButton:
      <HeaderButton
        className={'flex-column clear-filters-button'}
        title={'Clear Columns Filters'}
        icon={isClearAllFiltersDisabled ? <ClearFiltersDisabled /> : <ClearFilters />}
        disabled={isClearAllFiltersDisabled}
        key={'clearFilters'}
        onClick={() => {
          resetFiltersSummary();
          resetFiltersSearch();
        }}
      />
    },
    {
      name: 'newSearch',
      renderButton:
      <HeaderButton
        className={'flex-column'}
        title={'New Search'}
          icon={<Search />}
          key={'newSearch'}
        onClick={() => history.push('/searchpayments')}
      />
    },
    {
      name: 'customize',
      renderButton:
      <HeaderButton
        className={'flex-column'}
          title={'Customize'}
          key={'customize'}
        icon={<Tools />}
        onClick={onClickCustomize}
      />
    },
    {
      name: 'exportFile',
      renderButton:
        <ExportButton
          key={'exportFile'}
          noDataFound={noDataFound}
          downloadReport={(type: string, filters?: Filters[]) => downloadReport && downloadReport(type, sort ?? [], filters)}
          appliedFilters={appliedFilters}
      />
    }
  ]

  useEffect(() => {
    setHeaders(headerDefinitions.map((e:any) => ({...e, colId: ''})))
    setTimeout(()=> { setHeaders([...headerDefinitions])}, 0);
  }, [headerDefinitions.length])

  function onGridReady(params: GridReadyEvent) {
    localStorage.removeItem(transactionColumnOrder);
    localStorage.removeItem(planColumnOrder);  
    setGridApi(params.api);
    getData()
      .then(response => response?.result)
      .then((data) => data && updateData(params, data, columnsData));
  }

  function onFilterChanged(params: GridReadyEvent) {
    const filterModel = getFilterModel(params.api.getFilterModel(), columnsData);
    if (filterModel) {
      setAppliedFilters(filterModel);
    }
    updateSummary(filterModel);
    setIsClearAllFiltersDisabled(!(!!Object.keys(filterModel).length));
  }

  return (
    <TabPane className="body-wrapper" tabId="0">
      <PaymentPlanDetailsModal />
      <div className="action-line">
        {/* <EDS_TextBox
          name="searchString"
          placeHolder="Type here to filter..."
          id="quickFilter"
        /> */}
        <div className="row">
          {buttonDefinitions
            .filter(btn => buttons.includes(btn.name))
            .map((button) => {
              return button.renderButton
            })
          }
        </div>
      </div>
      <div className="ag-theme-alpine results-table">
        {loading && <div data-testid="loading">Loading...</div>}
        {noDataFound
          ? error
            ? <div className="server-error">{`Server error while doing the search: ${error}`}</div>
            : <div>No results found</div>
          : <AgGridReact
            columnDefs={headers}
            rowModelType={'infinite'}
            rowSelection={'multiple'}
            onGridReady={onGridReady}
            onFilterChanged={onFilterChanged}
            onSortChanged={params => setSort(params.api.getSortModel())}
            defaultColDef={{
              resizable: true,
              sortable: true,
              filter: true,
              filterParams: headerDefinitions.filterParams,
              pinned: headerDefinitions.pinned,
            }}
            suppressDragLeaveHidesColumns={true}
            suppressRowClickSelection={true}
            suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
            suppressMenuHide={true}
            cacheBlockSize={pageSize}
            getRowNodeId={(item) => item.id}
            components={{
              loadingCellRenderer: function (params) {
                if (params.value !== undefined) {
                  return params.value;
                } else {
                  return `<img src='${Loading}'>`;
                }
              },
            }}
            enableCellTextSelection={true}
            // ensureDomOrder={true}
            frameworkComponents={{
              LinkRenderer,
              PlanLinkRenderer
            }}
            tooltipShowDelay={500}
            onDragStopped={(params) => onMoveColumn(params)}
          />}
      </div>
      {showCustomizeColumns
        ? <CustomizeSearchColumns
          data={columnsData}
          searchPlan={mode === SearchMode.plan}
          onClose={onClickCustomize}
        />
        : null}
    </TabPane>
  );
}
