import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetailModel, UserSettings } from 'models/UserInfoAndRolesModel';
import { GetUserDetailById, GetUserByIdResult } from 'services/UsersService';
import { AppThunk } from 'app/rootReducer';
import { callService } from '../../services/ServicesReducer';

export interface State {
  err?: string;
  result?: UserDetailModel;
  users?: UserDetailModel[];
}

export const initialState: State = {
  err: '',
  result: undefined,
};

const reducerSlice = createSlice({
  name: 'UserInfo',
  initialState,
  reducers: {
    onReceiveUserInfo(state, action: PayloadAction<GetUserByIdResult>) {
      state.result = action.payload.result;
      state.err = action.payload.err;
    },
    setUserInfo(state, action: PayloadAction<GetUserByIdResult>) {
      state.result = action.payload.result;
    },
    resetUserInfo(state) {
      state.result = initialState.result;
      state.err = initialState.err;
    },
    setUsers(state, action: PayloadAction<UserDetailModel[]>) {
      const mergedUsers = action.payload.map(u => {
        const originalUser = state.users?.find(o=> o.id === u.id);
        return {...u, userSettings: originalUser?.userSettings}
      })
      state.users = mergedUsers;
    },
    setUserSettings(state, action: PayloadAction<{id: string | null, userSettings: UserSettings}>) {
      const idx = state.users?.findIndex(u => u.id === action.payload.id);
      if(idx && state.users?.[idx]){
        state.users[idx].userSettings = action.payload.userSettings;
      }
    },
    setUser(state, action: PayloadAction<UserDetailModel>) {
      const idx = state.users?.findIndex(u => u.id === action.payload.id);
      if (idx && state.users?.[idx]) {
        state.users[idx] = action.payload;
      }
    }
  },
});

export const { onReceiveUserInfo, setUsers, setUser, setUserSettings } = reducerSlice.actions;
export default reducerSlice.reducer;

export function getUserInfo(params: any, onDone?: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService("GetUserById", async () => {
      const response = await GetUserDetailById(params);
      dispatch(onReceiveUserInfo(response));
      if (onDone) onDone();
    }))
  };
}

export const { setUserInfo, resetUserInfo } = reducerSlice.actions;
