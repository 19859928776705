import { ReactComponent as Edit } from "assets/svgs/admin/tokenUserMapping/edit.svg";
import { ReactComponent as Add } from "assets/svgs/admin/tokenUserMapping/add.svg";
import { ReactComponent as Delete } from "assets/svgs/admin/tokenUserMapping/delete.svg";
import { ReactComponent as Save } from "assets/svgs/admin/tokenUserMapping/save.svg";
import { useState } from "react";
import { Metadata } from "../whitelist/WhitelistReducer";
import { Popup } from "../../../../components/popup/Popup";
import { Input } from "@EHDS/core";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "../../../../models/enums/EnumPopupType";

export default function MappingsActionbar<T extends { id?: string, metadata?: Metadata }>(props: {
  selectedMappings: string[],
  onDelete: () => void,
  onSaveAction: () => void,
  addNewMappings: (count: number) => void
  onEdit: () => void
}) {
  const { selectedMappings, onDelete, onSaveAction, addNewMappings, onEdit } = props;

  const [showAddNewPopup, setShowAddNewPopup] = useState(false);
  const [addCount, setAddCount] = useState(1);
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] = useState(false);

  return <div className="header-buttons">
    <div className={"header-button"} onClick={onEdit}>
      <Edit className="header-icon-secondary"/>
      <span className="text"> Edit  List </span>
    </div>
    <div className={"header-button"} onClick={() => { setShowAddNewPopup(true) }}>
      <Add className="header-icon-secondary"/>
      <span className="text"> Add New </span>
    </div>
    <div className={"header-button"} onClick={() => {
      selectedMappings.length && setShowDeleteConfirmationPopup(true);
    }}>
      <Delete className="header-icon-secondary"/>
      <span className="text">Remove</span>
    </div>
    <div className={"header-button"} onClick={onSaveAction}>
      <Save className="header-icon-secondary"/>
      <span className="text">Save</span>
    </div>
    {showAddNewPopup ? <Popup
      header={"Add New Token"}
      onClose={() => { setShowAddNewPopup(false) }}
      children={<div className={"add-new"}>
        Add 
        <Input type={"number"} value={addCount.toString()} onChange={e => {
        const value = e.target.value;
        if (Number(value) > 0 && Number(value) <= 25) {
          setAddCount(Number(value));
        }
      }} />
         new tokens
      </div>}

      onSubmit={() => {
        addNewMappings(addCount);
        setShowAddNewPopup(false);
      }}

      footer={[
        {
          text: "Add",
          type: EnumPopupButtonsType.submit,
          style: EnumPopupButtonsStyle.primary,
        },
        {
          text: "Cancel",
          type: EnumPopupButtonsType.cancel,
          style: EnumPopupButtonsStyle.secondary,
        }
      ]}
    /> : null}
    {showDeleteConfirmationPopup ? <Popup
      onClose={() => { setShowDeleteConfirmationPopup(false) }}
      children={<div>Are you sure you want to delete the selected mappings?</div>}
      onSubmit={() => {
        onDelete();
        setShowDeleteConfirmationPopup(false);
      }}
      footer={[
        {
          text: "Delete",
          type: EnumPopupButtonsType.submit,
          style: EnumPopupButtonsStyle.primary,
        },
        {
          text: "Cancel",
          type: EnumPopupButtonsType.cancel,
          style: EnumPopupButtonsStyle.secondary,
        }
      ]}
    /> : null}
  </div>
}