import { Config } from 'models/CreditCardConfigModel';
import { callHttpApi } from './callHttpApi';

export interface GetCreditCardResponse {
  result?: Config;
  err?: string;
}

export async function GetCreditCardConfig(
  organizationPath: string
): Promise<GetCreditCardResponse> {
  const params = { OrganizationPath: organizationPath };
  return callHttpApi<Config>(
    'get',
    `/api/v1/Merchant`,
    undefined,
    params
  );
}

export async function SaveCreditCardConfig(creditCard: Config) {
  return callHttpApi(
    'post',
    `/api/v1/Merchant/Configuration`,
    creditCard
  );
}

export async function UpdateCreditCardConfig(
  clientId: string,
  creditCard: Config
) {
  return callHttpApi(
    'put',
    `/api/v1/Merchant/Configuration/${clientId}`,
    creditCard
  );
}

export async function VerifyCredentials(
  creditCard: Config
): Promise<GetCreditCardResponse> {
  return callHttpApi(
    'put',
    `/api/v1/Merchant/VerifyCredentials`,
    creditCard
  );
}
