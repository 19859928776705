import { useState } from 'react';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'typeface-roboto';
import '../../../assets/styles/components/_AddRemoveFacilityModal.scss';
import {  Button } from '@EHDS/core';
import { ReactComponent as Close } from 'assets/svgs/icon-popup-close.svg';
import { useOrganizations } from "../../../features/organizations/hooks/useOrganizations";
import { DefaultOrganizationLevel, UserDetailModel } from 'models/UserInfoAndRolesModel';
import { getOrganizationOption, getOrganizationOptions } from '../../Search/simpleSearch/OrganizationUserSearch';
import { AddDefaultsToUsersService } from '../../../services/UsersService';
import { useDispatch } from 'react-redux';
import { AlertIds, AlertTypes, setAlert } from '../../../features/alert/AlertReducer';
import { FullOrganizationLevelDocument } from '../../../models/OrganizationLevelDocument';
import MultiSelect, { MultiSelectOption } from '../../../components/select/MultiSelect';
import OrganizationLevelTypes from '../../../models/enums/OrganizationLevelTypes';

export default function SelectDefaultFacilityAndDepartmentModal(props: {
  isOpen?: boolean;
  selectedUsers: UserDetailModel[];
  close: () => void;
  searchDataCallBack: () => void;
}) {
  const { isOpen, close, selectedUsers, searchDataCallBack } = props;
  const [isModelOpen, setIsModelOpen] = useState(isOpen);
  const openClass = isModelOpen ? 'eds-modal.open' : '';

  const [selectedFacility, setSelectedFacility] = useState<MultiSelectOption | null>(null)
  const [selectedDepartment, setSelectedDepartment] = useState<MultiSelectOption | null>(null)

  const { getOrganizationById, getOrganizationByPath } = useOrganizations();
  let userOrganizations: FullOrganizationLevelDocument[] = [];
  
  selectedUsers.forEach(user => {
    if (user.organizationLevels) {
      userOrganizations = [
        ...userOrganizations,
        ...user.organizationLevels.map(o => getOrganizationById(o.organizationLevel_Id)).filter(o => !!o) as FullOrganizationLevelDocument[]
      ]
    }
  })

  let uniquePathUserOrganizations: FullOrganizationLevelDocument[] = userOrganizations.filter((organization, index, self) =>
    self.findIndex(t => t === organization) === index
  );

  const facilityOptions = getOrganizationOptions(
    uniquePathUserOrganizations.filter(o => o.organizationLevelType === OrganizationLevelTypes.Facility),
    t => getOrganizationOption(t)
  );

  const departmentOptions = getOrganizationOptions(
    uniquePathUserOrganizations.filter(o => o.organizationLevelType === OrganizationLevelTypes.Department),
    t => getOrganizationOption(t)
  );

  const onClose = () => {
    setIsModelOpen(false);
    close();
  }

  const setDefaultFacilityAndDepartment = useSetDefaultFacilityAndDepartment();

  const onSubmit = () => {
    setDefaultFacilityAndDepartment(
      searchDataCallBack,
      selectedUsers,
      getOrganizationByPath(selectedFacility?.value),
      getOrganizationByPath(selectedDepartment?.value)
    );
    close();
  }

  return (
    <div>
      <section className={`eds-modal AddRemoveInfoModal ${openClass}`}>
<div className="eds-modal_#container" style={{ top: 100, padding: 0, borderRadius: 0, overflow: 'visible' }}>
          <header className="eds-modal__header headerTitle">
            <div className="col titleText" >PaymentSafe®</div>
            <div className="col text-right headerCloseButton" onClick={() => { close() }}>
              <Close />
            </div>
          </header>
          <div className="eds-modal__content ">
            <div className="AddRemoveInfoPageContainer">
              <div className="AddRemoveInfoFormContainer">
                <div>
                  <div className="user-location">{`ADD DEFAULT FACILITIES/DEPARTMENTS TO USERS (${selectedUsers.length})`}</div>
                  <div className="organization-user-search user-location default-facility-selection">
                    <MultiSelect
                      disableCloseOnSelect={false}
                      label="Facility:"
                      options={facilityOptions}
                      onChange={selected => setSelectedFacility(selected)}
                      multiple={false}
                      name="facilities"
                      values={selectedFacility}
                      disableClearable={false}
                    />
                    <MultiSelect
                      disableCloseOnSelect={false}
                      label="Department:"
                      options={departmentOptions}
                      onChange={selected => setSelectedDepartment(selected)}
                      multiple={false}
                      name="departments"
                      values={selectedDepartment}
                      disableClearable={false}
                    />
                </div>
                <div className="AddRemoveInfoButtonContainer">
                  <Button
                    className="AddRemoveInfoButton"
                    onClick={() => { onClose() }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={`eds-button eds-button.primary primary-button ${!selectedFacility || !selectedDepartment ? "disabled" : ""}`}
                    onClick={() => { onSubmit() }}
                    disabled={!selectedFacility || !selectedDepartment}
                  >
                    Add Facilities/Departments
                  </Button>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const useSetDefaultFacilityAndDepartment = () => {
  const dispatch = useDispatch();
  return async (searchDataCallBack: () => void, selectedUsers: UserDetailModel[], facility?: DefaultOrganizationLevel, department?: DefaultOrganizationLevel, users?: UserDetailModel[]) => {
    const response = await AddDefaultsToUsersService({
      UserIds: selectedUsers.map(u => u.id),
      DefaultFacility: facility,
      DefaultDepartment: department
    });
    if (!response.err) {
      dispatch(setAlert({
        id: AlertIds.AddOrRemoveFacilities,
        message: "Default Facilities and Departments have been added to the selected users",
        type: AlertTypes.Success,
        dismissable: true
      }));
      searchDataCallBack();
    }
  }
}