import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { GetTransactionsRequest } from 'models/Transaction';
import { GetUserSettings, PutUserSettings } from 'services/UsersService';
import { MultiSelectOption } from '../../components/select/MultiSelect';
import { ApiTenderTypeEnum, checkNumberHasValue, checkReceivedHasValues, checkStringHasValue } from '../../utils/Utils';
import {
  AdvanceSearchInformation,
  TreeFilterItem,
} from './advanceSearch/AdvanceSearchReducer';
import { PaymentPlanSearchInformation } from './planSearch/PaymentPlanSearchReducer';
import {
  State as SimpleSearchState,
  SimpleSearchInformation,
} from './simpleSearch/SimpleSearchReducer';
import { GridConfig } from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';

export type Dictionary<T> = { [key: string]: T | T[] };

export type QueryDictionary = Dictionary<string>;

export function addToDictionary<T>(dictionary: Dictionary<T>, key: string, value: T): void {
  const existingValue = dictionary[key];
  
  if (!existingValue) {
    dictionary[key] = value;
  } else if (Array.isArray(existingValue)) {
    existingValue.push(value);
  } else {
    dictionary[key] = [existingValue, value];
  }
}

export function getSearchCriteriaBySearchId(searchId: string): QueryDictionary {
  const rawItem = sessionStorage.getItem(searchId);
  if (rawItem) {
    return JSON.parse(rawItem);
  }
  return {};
}

function buildData(
  simpleSearchState: SimpleSearchState,
  advanceSearchInfo: AdvanceSearchInformation,
  limit?: number,
  offset?: number
) {
  const simpleSearchInfo = simpleSearchState.value;
  let searchInfo:
    | SimpleSearchInformation
    | AdvanceSearchInformation = simpleSearchInfo;
  let data: GetTransactionsRequest = {};

  const notAllAreChecked = (items: TreeFilterItem[]) => {
    return items.find(item => !item.isChecked);
  };

  if (simpleSearchState.activeTab === '2') {
    searchInfo = advanceSearchInfo;
    data = {
      advancedSearch: true,
      facilities: advanceSearchInfo.facilities,
      departments: advanceSearchInfo.departments,
      users: advanceSearchInfo.users,
      patientFirstName: (checkStringHasValue(advanceSearchInfo?.patientFirstName)).trim(),
      patientLastName: (checkStringHasValue(advanceSearchInfo?.patientLastName)).trim(),
      patientAccountNumber: checkStringHasValue((advanceSearchInfo?.patientAccountNumber)).trim(),
      payerFirstName: (checkStringHasValue(advanceSearchInfo?.payerFirstName)).trim(),
      payerLastName: (checkStringHasValue(advanceSearchInfo?.payerLastName)).trim(),
      guarantorId: advanceSearchInfo?.guarantorId,
      patientId: advanceSearchInfo?.patientId,
      submittedDateTimeMin: advanceSearchInfo?.submittedDateTimeMin,
      submittedDateTimeMax: advanceSearchInfo?.submittedDateTimeMax,
      settledDateTimeMin: advanceSearchInfo?.settledDateTimeMin,
      settledDateTimeMax: advanceSearchInfo?.settledDateTimeMax,
      amountMin: advanceSearchInfo?.paymentAmountFrom,
      amountMax: advanceSearchInfo?.paymentAmountTo,
      discountMin: advanceSearchInfo?.discountAmountFrom,
      discountMax: advanceSearchInfo?.discountAmountTo,
      id: advanceSearchInfo?.transactionID,
      batchId: Number(advanceSearchInfo?.batchID),
      accountNumber: advanceSearchInfo?.accountNumber,
      tenderLastFourDigits: advanceSearchInfo?.creditCard,
      authorizationCode: advanceSearchInfo?.authorizationCode,
      paymentStatus: advanceSearchInfo?.paymentStatus?.map((status)=> {return status.value;}),
      resultsStatus: checkReceivedHasValues(
        advanceSearchInfo?.resultsStatus?.value && advanceSearchInfo?.resultsStatus.value === 'All', undefined, advanceSearchInfo?.resultsStatus?.value),
      tenderTypes: checkReceivedHasValues(advanceSearchInfo?.tenderTypes,
        advanceSearchInfo?.tenderTypes?.filter(
              t =>
                t.isChecked &&
                ((t.parent !== 'Credit/Debit' && t.label !== 'Credit/Debit') ||
                  t.label === 'Credit/Debit')
            )
            .map(t =>
              t.propertyName === 'credit/debit' ? 'card' : t.propertyName
            ), undefined),
      cardBrand: checkReceivedHasValues(advanceSearchInfo?.tenderTypes,
         advanceSearchInfo?.tenderTypes?.filter(type => type.parent === 'Credit/Debit' && type.isChecked).map(t => t.propertyName),
         undefined),
      transactionTypes:
      checkReceivedHasValues(advanceSearchInfo?.transactionTypes && notAllAreChecked(advanceSearchInfo?.transactionTypes),
          advanceSearchInfo?.transactionTypes?.filter(t => t.isChecked).map(t => t.propertyName), undefined),
      cardEntryTypes:
        checkReceivedHasValues(advanceSearchInfo?.cardEntryTypes, advanceSearchInfo?.cardEntryTypes?.filter(t => t.isChecked).map(t => t.propertyName), undefined),
      glTransactionTypes: advanceSearchInfo?.glTransactionTypes,
      glDescription: advanceSearchInfo?.glDescription?.value,
      transactionReferenceId: advanceSearchInfo?.transactionReferenceId,
      gatewayReferenceNumber: advanceSearchInfo?.gatewayReferenceNumber,
      paymentPlanId: advanceSearchInfo?.paymentPlanId,
      deviceName: advanceSearchInfo?.deviceName,
      deviceSerialNumber: advanceSearchInfo?.deviceSerialNumber,
      billingId: advanceSearchInfo.billingId,
    };
  }

  data = {
    limit: checkNumberHasValue(limit),
    offset: checkNumberHasValue(offset),
    facilities: searchInfo?.facilities,
    departments: searchInfo?.departments,
    users: searchInfo?.users,
    mrn: checkStringHasValue(searchInfo?.mrn),
    patientId: checkStringHasValue(searchInfo?.patientId),
    accountNumber: simpleSearchInfo?.accountNumber,
    confirmationId: simpleSearchInfo?.patientConfirmationID,
    patientAccountNumber: simpleSearchInfo?.patientAccountNumber,
    id: simpleSearchInfo?.id,
    submittedDateTimeMin: simpleSearchInfo?.submittedDateTimeMin,
    submittedDateTimeMax: simpleSearchInfo?.submittedDateTimeMax,
    ...data,
  };
  
  return data;
}

export function getRequestSearchId(
  simpleSearchState: SimpleSearchState,
  advanceSearchInfo: AdvanceSearchInformation,
  limit?: number,
  offset?: number
) {
  const searchId = uuidv4();

  const dictionary = getRequestSearch(simpleSearchState, advanceSearchInfo, limit, offset);

  sessionStorage.setItem(searchId, JSON.stringify(dictionary));

  const result = new URLSearchParams();
  result.append('searchId', searchId);
  return result.toString();
}

function buildInitialDictionaryFromData(data: GetTransactionsRequest)
{
  const dictionary: { [key: string]: any[] } = {};

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.forEach(item => addToDictionary(dictionary, key, item));
      }
    } else if (value !== undefined && value !== null) {
      addToDictionary(dictionary, key, value);
    }
  });  

  return dictionary;
}

function getRequestSearch(
  simpleSearchState: SimpleSearchState,
  advanceSearchInfo: AdvanceSearchInformation,
  limit?: number,
  offset?: number
) {
  const data = buildData(simpleSearchState, advanceSearchInfo, limit, offset);
  
  const dictionary = buildInitialDictionaryFromData(data);
  
  const request = Object.entries(data)
    .filter(([key, value]) => {
      return Array.isArray(value) ? !!value.length && value : value;
    })
    .reduce((o: any, [key, value]) => {
      o[key] = value;
      return o;
    }, {});

  if (request.cardBrand) {
    request.cardBrand.forEach((cardBrand: string) =>
      addToDictionary(dictionary, 'cardBrand', cardBrand)
    );
  }

  if (request.facilities) {
    let facilities = [];
    if(request.departments){
      facilities = request.facilities.filter((f: MultiSelectOption) =>
        request.departments?.find((d: MultiSelectOption) => d.value.startsWith(f.value))
      );
    } else {
      facilities = request.facilities;
    }
    facilities.forEach((f: MultiSelectOption) =>
      addToDictionary(dictionary, 'organizationPaths', f.value)
    );
  }

  if (request.departments) {
    request.departments.forEach((department: MultiSelectOption) =>
      addToDictionary(dictionary, 'departmentPaths', department.value)
    );
  }
  delete dictionary['users'];
  if (request.users) {
    request.users?.forEach((u: MultiSelectOption) =>
      addToDictionary(dictionary, 'userId', u.value)
    );
  }

  return dictionary;
}

export function getPlanSearchId(
  paymentPlanSearchInfo: PaymentPlanSearchInformation,
  simpleSearchInfo: SimpleSearchInformation
) {
  const searchId = uuidv4();

  const dictionary = getPlanSearch(paymentPlanSearchInfo, simpleSearchInfo);

  sessionStorage.setItem(searchId, JSON.stringify(dictionary));

  const result = new URLSearchParams();
  result.append('searchId', searchId);
  return result.toString();
}

function mapSimpleSearchInfo(
  dictionary: { [key: string]: any[] }, 
  simpleSearchInfo: SimpleSearchInformation
) {
  if (simpleSearchInfo.facilities) {
    simpleSearchInfo.facilities.forEach(facility =>
      addToDictionary(dictionary, 'organizationPaths', facility.value)
    );
  }
  if (simpleSearchInfo.departments) {
    simpleSearchInfo.departments.forEach(department =>
      addToDictionary(dictionary, 'departmentPaths', department.value)
    );
  }
  if (simpleSearchInfo.users) {
    simpleSearchInfo.users.forEach(user =>
      addToDictionary(dictionary, 'userId', user.value)
    );
  }
}

function getPlanSearch(
  paymentPlanSearchInfo: PaymentPlanSearchInformation,
  simpleSearchInfo: SimpleSearchInformation
) {
  const dictionary: { [key: string]: any[] } = {};

  mapSimpleSearchInfo(dictionary, simpleSearchInfo);

  const cardBrands =
    paymentPlanSearchInfo?.tenderType?.filter(
      (type) => type.parent === 'Credit/Debit' && type.isChecked
    ) ?? [];
  cardBrands.forEach((brand) =>
    addToDictionary(dictionary, 'cardBrand', brand.propertyName)
  );

  paymentPlanSearchInfo?.tenderType
    ?.filter(
      (type) =>
        !type.parent &&
        (type.isChecked || (type.label === 'Credit/Debit' && cardBrands?.length))
    )
    .forEach((type) => {
      if (type.label === 'eCheck') {
        addToDictionary(dictionary, 'tenderType', ApiTenderTypeEnum.ECheck);
      }
      if (type.label === 'Credit/Debit') {
        addToDictionary(dictionary, 'tenderType', ApiTenderTypeEnum.CardDevice);
        addToDictionary(dictionary, 'tenderType', ApiTenderTypeEnum.CardManual);
      }
    });

  paymentPlanSearchInfo?.status?.forEach((status: MultiSelectOption) =>
    addToDictionary(dictionary, 'status', status.value)
  );

  (['transactionStatus', 'accountNumber', 'patientGuarantorAccountNo', 'tenderMaskedAccount'] as Array<keyof PaymentPlanSearchInformation>).forEach((key: keyof PaymentPlanSearchInformation) => {
    paymentPlanSearchInfo[key] && addToDictionary(dictionary, key, paymentPlanSearchInfo[key]);
  });

  const filteredValues = [
    'organizationId',
    'tenderType',
    'transactionStatus',
    'cardBrand',
    'results',
    'facilities',
    'facility',
    'accountNumber',
    'patientGuarantorAccountNo',
    'tenderMaskedAccount',
    'user',
    'users',
    'status'
  ];
  Object.entries(paymentPlanSearchInfo)
    .filter(([key]) => !filteredValues.includes(key))
    .forEach(([key, value]) => {
      value && addToDictionary(dictionary, key, value.toString());
    });

  return dictionary;
}

let userSettings = {} as { [key: string]: any };

export const getUserSettings = async (mode: string) => {
  const userId = localStorage.getItem('id');
  if (!userId) return;
  const response = (await GetUserSettings(userId)) as { [key: string]: any };
  if (response.result) {
    const settings = JSON.parse(response.result);
    userSettings = settings;
    return settings[mode];
  }
};

export const setUserSettings = async (mode: string, settings: any) => {
  if (!settings) return;
  const userId = localStorage.getItem('id');
  userSettings[mode] = settings;
  const json = JSON.stringify(userSettings).replace(/"/g, '\\"');
  userId && (await PutUserSettings(userId, json));
};

export const appendTimeStampMin = (searchCriteria: QueryDictionary, field: string) => {
  if (!searchCriteria[field]) return;
  searchCriteria[field] = moment.utc(searchCriteria[field]).startOf('day').format();
};

export const appendTimeStampMax = (searchCriteria: QueryDictionary, field: string) => {
  if (!searchCriteria[field]) return;
  searchCriteria[field] = moment.utc(searchCriteria[field]).endOf('day').format();
};

export const hasMissingMandatoryFields = (fields: { [key: string]: string }) => {
  return Object.keys(fields).filter(field => !fields[field]).join(', ') || '';
};

export const errorMessage = (message: string) => `Please select at least one ${message} to proceed`;

export const setRetrievedUserSettings = async (mode: string, setChosenColumns: any, columnsData: GridConfig[]) => {
  const retrievedColumnOrder = await getUserSettings(mode);
  if (retrievedColumnOrder) {
    const sortedColumnHeaders = retrievedColumnOrder?.map((userSettingsElement : GridConfig) => {return columnsData.find((headerDefinitionsElement:GridConfig) => userSettingsElement.colId === headerDefinitionsElement.colId)});
    const remainingColumnHeaders = columnsData.filter((headerDefinitionsElement: GridConfig) => !retrievedColumnOrder?.map((userSettingsElement : any) => userSettingsElement.colId).includes(headerDefinitionsElement.colId));
    const joinedColumnHeaders = sortedColumnHeaders?.concat(remainingColumnHeaders);
    setChosenColumns(joinedColumnHeaders);
  } else {
    setChosenColumns(columnsData);
  }
}
