import { useEffect, useState } from 'react';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { getClientConfiguration } from 'features/admin/ClientReducer';
import {
  updateDeviceManagerForClient,
  getDeviceManagerClients,
  setDeviceDataForClient,
} from 'features/admin/deviceManager/DeviceManagerReducer';
import { DeviceManager as DeviceManagerModel } from 'models/DeviceManagerModel';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import 'assets/styles/components/_clientConfiguration.scss';
import ConfigurationTemplate from '../../features/admin/clientConfiguration/ConfigurationTemplate';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import 'assets/styles/components/_deviceManager.scss';
import { Placement } from 'react-bootstrap/esm/Overlay';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ReactComponent as Help } from 'assets/svgs/paymentPlanIcons/icon-help-outline.svg';
import { UpdateDeviceManagerForClient } from 'services/DeviceManagerService';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';
import { isNotEmptyString } from 'utils/Utils';
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { useCallService } from 'services/useCallService';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

interface ClientOption {
  path: string;
  name: string;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadcrumbLinks([{ name: 'Device Manager', slug: rootPath }]));

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

function useClient(organizationPath: string = '') {
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (organizationPath != '') {
      dispatch(getClientConfiguration(organizationPath));
    }
  }, [clientId, dispatch]);
}

function useDeviceManagerData(organizationPath: string = '') {
  const dispatch = useDispatch();
  useEffect(() => {
    if (organizationPath != '') {
      dispatch(updateDeviceManagerForClient(organizationPath));
      dispatch(getDeviceManagerClients(organizationPath));
    }
  }, [organizationPath, dispatch]);
}

export default function DeviceManager(
  props: Props = { rootPath: 'device-manager' }
) {
  const { rootPath } = props;
  const isProcessingUpdateDeviceManagerForClient = useTypedSelector(s => 
    s.services.calls.UpdateDeviceManagerForClient?.isProcessing
  );
  const isProcessingGetDeviceManageClients = useTypedSelector(s => 
    s.services.calls.GetDeviceManagerClients?.isProcessing
  );
  const clientConfiguration = useTypedSelector(
    s => s.getClientFacilityConfiguration?.result
  );
  const deviceManagerList = useTypedSelector(
    s => s.deviceManager?.deviceManagerList
  );
  const deviceManagerClients = useTypedSelector(
    s => s.deviceManager?.deviceManagerClients
  );
  const filteredDeviceManagerClients = deviceManagerClients?.filter(
    item => isNotEmptyString(item.path)
  );

  const { getOrganizationByPath } = useOrganizations();
  const clientConfigurationOrganization = getOrganizationByPath(clientConfiguration?.organization?.path);

  const callService = useCallService();

  useBreadcrumb(rootPath);
  const clientDefaultOption = {
    path: clientConfigurationOrganization?.path || 'Organization Path Not Set',
    name: clientConfigurationOrganization?.name || 'Organization Name Not Set'
  }
  const clientList: ClientOption[] = [clientDefaultOption];

  const [clientSelection, setClientSelection] = useState(clientList);
  useClient(clientConfigurationOrganization?.path);
  useDeviceManagerData(clientConfigurationOrganization?.path);
  const dispatch = useDispatch();

  const columnNames = ['Name', 'Serial Number', 'Model', 'Device Type'];

  const getClientOptions = () => {
    if (filteredDeviceManagerClients && filteredDeviceManagerClients.length) {
      return filteredDeviceManagerClients;
    } else {
      return [];
    }
  };

  const getPopoverContent = (content: string) => (
    <Popover id="popover-basic">
      <Popover.Content>{content}</Popover.Content>
    </Popover>
  );

  const PopoverElement = ({
    content,
    placement,
  }: {
    content: string;
    placement: Placement;
  }) => (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement}
      overlay={getPopoverContent(content)}
    >
      <Help />
    </OverlayTrigger>
  );

  const deviceAlert = {
    id: AlertIds.DeviceManagerAlert,
    type: AlertTypes.Error,
    message: '',
  };


  const onSyncDevice = async (organizationPaths: any) => {
    dispatch(setDeviceDataForClient({ result: [] }));
    const result = await callService("UpdateDeviceManagerForClient", () => UpdateDeviceManagerForClient(organizationPaths));

    if (result && result?.err) {
      dispatch(
        setAlert({
          ...deviceAlert,
          message: `Error Device not updated`,
        })
      );
    } else {
      dispatch(
        setAlert({
          ...deviceAlert,
          type: AlertTypes.Success,
          message: `Success Device updated`,
          dismissable: true,
        })
      );
      dispatch(setDeviceDataForClient({ result: result.result }));
    }
  };

  const renderDeviceManagerList = () => {
    if (deviceManagerList && deviceManagerList.length) {
      return (
        <table
          className="table table-striped table-bordered"
          data-testid="device-manager-table"
        >
          <thead>
            <tr>
              {columnNames.map(name => (
                <th key={`${name}`}>{name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {deviceManagerList.map(
              (item: DeviceManagerModel, index: number) => (
                <tr key={`${item.id}-${index}`}>
                  <td>{item.friendlyName}</td>
                  <td>{item.serialNumber}</td>
                  <td>{item.model}</td>
                  <td>{item.deviceType}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      );
    } else if (isProcessingUpdateDeviceManagerForClient){
      return <span>Getting Device Manager List...</span>;
    } else {
      return <span>There are no device manager elements to display.</span>;
    }
  };

  if (isProcessingGetDeviceManageClients) {
    return <p>Getting Device Manager List...</p>;
  } else {
    return (
      <div className="client-configuration">
        <ConfigurationTemplate title="Device Manager">
          <div className="device-manager">
            <div className="content">
              <div className="title-wrapper">
                <span className="title-content">
                  Save/Sync Facility Selection:
                  <span className="help">
                    <PopoverElement placement="top" content="Help Text" />
                  </span>
                </span>
              </div>

              <div className="autocomplete-wrapper">
                <Autocomplete
                  multiple
                  id="autocomplete-clients"
                  data-testid="autocomplete-clients"
                  options={getClientOptions()}
                  getOptionLabel={(option: ClientOption) => option.name}
                  getOptionSelected={(
                    option: ClientOption,
                    value: ClientOption
                  ) => option.path === value.path}
                  value={clientSelection}
                  onChange={(event, newValue: any) => {
                    setClientSelection(newValue);
                  }}
                  filterSelectedOptions
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Available Facilities"
                    />
                  )}
                />
              </div>

              {renderDeviceManagerList()}
            </div>

            <div className="actions">
              <button
                className="action sync"
                data-testid="btn-sync"
                onClick={e => {
                  e.preventDefault();
                  const clientMap = clientSelection.map(
                    (client: any) => client.path
                  );
                  onSyncDevice(clientMap);
                }}
                disabled={isProcessingUpdateDeviceManagerForClient}
              >
                <FaCloudDownloadAlt />
                <span className="text">Sync</span>
              </button>
            </div>
          </div>
        </ConfigurationTemplate>
      </div>
    );
  }
}
