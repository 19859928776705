
export enum TemplateType {
  Scriban='Scriban',
}

export interface ParseRequest {
  template: string,
  type: TemplateType,
}

export interface Template {
  template: string,
  errors?: string[],
}

export interface ParseResponse {
  result?: Template;
  err?: string;
}