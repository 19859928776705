import React from 'react';

export function NumberRange(props: {
  nameFrom: string;
  nameTo: string;
  label: string;
  valueFrom?: number;
  valueTo?: number;
  onChangeFrom?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTo?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const {
    nameFrom,
    nameTo,
    label,
    valueFrom,
    valueTo,
    onChangeFrom,
    onChangeTo,
  } = props;
  return (
    <label className={`eds-field`} htmlFor={nameFrom}>
      <div className={`eds-field_#label`}>{label}</div>
      <div className="row">
        <div className="col-sm-6">
          <div className={`eds-field_#control eds-field_.eds-input eds-input`}>
            <input
              className={'eds-input_#input'}
              id={nameFrom}
              type={'number'}
              name={nameFrom}
              onChange={onChangeFrom}
              placeholder={'From'}
              value={valueFrom || ''}
              min={0}
            />
          </div>
        </div>

        <div className="col-sm-6">
          <div className={`eds-field_#control eds-field_.eds-input eds-input`}>
            <input
              className={'eds-input_#input'}
              id={nameTo}
              type={'number'}
              name={nameTo}
              onChange={onChangeTo}
              placeholder={'To'}
              value={valueTo || ''}
              min={0}
            />
          </div>
        </div>
      </div>
    </label>
  );
}
