import {
  EDS_Checkbox
} from '@EH/eh.eds.react';
import { PermissionItem } from "../../../models/Permission";
import 'assets/styles/components/_permissionsList.scss';

export default function PermissionsList(props: {
  permissions?: PermissionItem[],
  selectedItems?: Set<string|undefined>,
  handleChange?: (id?: string) => void,
  readOnly?: boolean,
  toggleSelectAll?: () => void,
  isAllSelected?: boolean
}) {
  const { permissions, selectedItems, handleChange, toggleSelectAll, readOnly = false, isAllSelected } = props;

  const handleSelectAllChange = () => {
    toggleSelectAll && toggleSelectAll();
  };

  const shouldShowSelectAll = () => {
    if (!readOnly && (permissions?.length ?? 0) > 0) 
      return true
    else 
      return false
  }

  const showPermissionsList = () => {
    if (readOnly && (permissions?.length == 0)) {
      return (
        <span className={`eds-checkbox_#label custom-margin-class no-permissions`}>User has no permissions</span>
      )

    }   
    return permissions?.map((item) => (
      <div key={item.id}>
        {readOnly ? 
        <div className="organization-checkbox-columns">
          <span className={`eds-checkbox_#label custom-margin-class`}>{item.name}</span>
        </div> : (
          <EDS_Checkbox
            modifiers="row-item row-item-size checkbox-columns"
            name={item.name}
            label={item.name}
            onChange={() => handleChange && handleChange(item.id)}
            checked={selectedItems?.has(item.id)}
          ></EDS_Checkbox>
          )
        }
      </div>
    )) ?? "No permissions found"
  }

    return (
    <div>
      {shouldShowSelectAll() && (
        <div>
          <EDS_Checkbox
            modifiers="row-item row-item-size checkbox-columns"
            name='select-all'
            label='Select All'
            onChange={() => handleSelectAllChange()}
            checked={isAllSelected}
            data-testid={`selectAll-${permissions?.[0]?.id ?? ''}`}
          ></EDS_Checkbox>
        </div>
      )}
      {showPermissionsList()}
    </div>
  );
}