import { CollapsibleSection } from "../../../components/collapsibleSection/CollapsibleSection"
import { ReactComponent as AddIcon } from 'assets/svgs/admin/remitConfig/add_circle_outline.svg'
import { ReactComponent as RemoveIcon } from 'assets/svgs/admin/remitConfig/remove_circle_outline.svg'
import { ReactComponent as ArrowUp } from 'assets/svgs/admin/remitConfig/arrow_up.svg'
import { ReactComponent as ArrowDown } from 'assets/svgs/admin/remitConfig/arrow_down.svg'
import { ReactComponent as ArrowUpMove } from 'assets/svgs/admin/remitConfig/arrow_up_move.svg'
import { ReactComponent as ArrowDownMove } from 'assets/svgs/admin/remitConfig/arrow_down_move.svg'
import SettingForm from "./forms/SettingForm"
import AggregateSettingForm from "./forms/AggregateSettingForm"
import { PredefinedData, RemitConfigurationSettings, Setting } from "../../../models/admin/RemitConfiguration"
import { RemitFileFieldDescription } from "models/RemitPreDefinedListsModel"

export const SettingComponent = (props: {
  setting: Setting,
  onAdd: () => void,
  onRemove: null | (() => void),
  isOpen?: boolean,
  toggleIsOpen: () => void,
  moveUp: () => void,
  moveDown: () => void,
  onChange: (setting: Setting) => void,
  settingKey: keyof RemitConfigurationSettings,
  index: number,
  predefinedData: PredefinedData,
  aggregateSettings: Setting[],
  footerSettings: Setting[],
  remitFileFieldDescriptions?: RemitFileFieldDescription[],
}) => {

  const styles = {
    arrows: {
      marginRight: "10px"
    },
    editable: {
      marginRight: "5px"
    },
    headerName: {
      paddingLeft: "10px"
    },
    buttons: {
      paddingTop: "5px"
    }
  }

  const {
    setting,
    onAdd,
    onRemove,
    isOpen,
    toggleIsOpen,
    moveUp,
    moveDown,
    onChange,
    settingKey,
    index,
    predefinedData,
    remitFileFieldDescriptions,
    aggregateSettings,
    footerSettings,
  } = props;

  const Arrow = isOpen ? ArrowUp : ArrowDown;

  return <CollapsibleSection
    summary={<>
      <Arrow onClick={() => toggleIsOpen()} />
      <div style={styles.headerName} className={'headerText'} onClick={() => toggleIsOpen()}>{setting.name}</div>
      <div className={'buttons'}>
        <ArrowUpMove onClick={() => moveUp()} style={styles.arrows} />
        <ArrowDownMove onClick={() => moveDown()} style={styles.arrows} />
        <AddIcon onClick={() => onAdd()} style={styles.editable} />
        <RemoveIcon className={onRemove ? "" : "disabled"} onClick={() => onRemove && onRemove()} style={styles.editable} />
      </div>
    </>}
    isOpen={!!isOpen}   
  >
    {settingKey === 'aggregateSettings' && <AggregateSettingForm setting={setting} onChange={onChange} settingKey={settingKey} index={index} footerSettings={footerSettings} remitFileFieldDescriptions={remitFileFieldDescriptions} />}
    {settingKey !== 'aggregateSettings' && <SettingForm setting={setting} onChange={onChange} settingKey={settingKey} index={index} predefinedData={predefinedData} remitAggregateSettings={aggregateSettings} remitFileFieldDescriptions={remitFileFieldDescriptions} />}
  </CollapsibleSection>
}