import ExpArtWork from 'assets/exp-art-work.png';
import "./NoPermissions.scss"

export default function NoPermissions() { 

  return (
    <div className="landing-page-container">    
      <div className="experian-art-work">
        <img src={ExpArtWork}/>
      </div>
      <div className="landing-page-links-container row justify-content-center">
        <p className='no-permission-text'>
          Sorry, you do not have required permission to access PaymentSafe at<br/> this time, please contact your Onesource system administrator.
        </p>
      </div>
      <footer className='no-permission-footer'>© Experian Information Solutions. All rights reserved.</footer>
    </div>
  );
}
