import { UserToken, getTokensList } from "../../../../services/TokenGUIDLookupService"

export function useGetTokens(save: (tokens: UserToken[]) => void) {
  async function getTokens(organizationPath?: string) {
    const response = await getTokensList(organizationPath);
    if (response.result) {
      save(response.result.records);
    }
  }

  return {getTokens}
}