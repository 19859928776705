import { EnumTransactionStatusType } from 'models/enums';
import { GetTransactionResult } from 'models/GetTransactionResult';
import { useMemo, useState } from 'react';
import { getTransactionsSearchService, PaymentSearchModel } from 'services/PaymentService';

const successedPaymentTransactionTypes = [
  EnumTransactionStatusType.Settled,
  EnumTransactionStatusType.Submitted,
];

const failedPaymentTransactionTypes = [
  EnumTransactionStatusType.Cancelled,
  EnumTransactionStatusType.Declined,
  EnumTransactionStatusType.Unknown,
  EnumTransactionStatusType.Timeout,
  EnumTransactionStatusType.Failed
];

const paymentTransactionTypes = [...successedPaymentTransactionTypes, ...failedPaymentTransactionTypes];

export function useGetPaymentPlanPaymentTransactions(
  paymentPlanId?: string | null,
) {
  const [transactions, setTransactions] = useState<GetTransactionResult[]>([]);

  const successed = useMemo(() => {
    return transactions.filter(t => successedPaymentTransactionTypes.map(s => s.toString()).includes(t.statusType));
  }, [transactions]);

  const failed = useMemo(() => {
    return transactions.filter(t => failedPaymentTransactionTypes.map(f => f.toString()).includes(t.statusType));
  }, [transactions]);

  const load = async () => {
    if (paymentPlanId) {
      const request: PaymentSearchModel = {
        limit: 200000,
        paymentPlanId: paymentPlanId,
        paymentStatus: paymentTransactionTypes
          .map(t => t.toString())
          .reduce((prev, curr) => prev.concat(',', curr), '')
          .substring(1)
      };
    
      const response = await getTransactionsSearchService(request);
      if (response.result?.records.length) {
        setTransactions(response.result.records);
      }
    }
  };
  return { transactions, successed, failed, load };
}