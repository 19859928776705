import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientFacilityConfiguration } from 'models/Client';
import {
  getClientConfigurationService,
  GetClientFacilityConfigurationResponse,
} from 'services/ClientService';
import { AppThunk } from 'app/rootReducer';
import { callService } from 'services/ServicesReducer';

export interface State {
  err?: string;
  result?: ClientFacilityConfiguration;
}

export const initialState: State = {
  err: '',
  result: undefined,
};

const reducerSlice = createSlice({
  name: 'GetClientConfiguration',
  initialState,
  reducers: {
   
    onReceiveGetClientConfiguration(
      state,
      action: PayloadAction<GetClientFacilityConfigurationResponse>
    ) {
      state.result = action.payload.result;
      state.err = action.payload.err;
    },
    setGetClientConfiguration(
      state,
      action: PayloadAction<GetClientFacilityConfigurationResponse>
    ) {
      state.result = action.payload.result;
    },
    resetGetClientConfiguration(state) {
      state.result = initialState.result;
      state.err = initialState.err;
    },
  },
});

export const {
   onReceiveGetClientConfiguration,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getClientConfiguration(
  params: any,
  onDone?: (data?: ClientFacilityConfiguration) => void
): AppThunk {
  return async (dispatch, _getState) => {
    dispatch(callService('GetClientConfiguration', async () => {
        await getClientConfigurationService(params).then(data => {
          dispatch(onReceiveGetClientConfiguration(data));
          if (onDone) onDone(data?.result);
        });
    }));    
  };
}

export const {
  setGetClientConfiguration,
  resetGetClientConfiguration,
} = reducerSlice.actions;
