import { LineItemSpecific } from "../../../models/LineItem";
import { PatientEncounterModel } from "../../../models/PatientEncounterModel";

export function PlanToPatientLineItemMap(patientEncounter: PatientEncounterModel): LineItemSpecific {
  return {
    dateOfService: patientEncounter.serviceDate,
    accountNumber: patientEncounter.accountNo,
    patientBalance: patientEncounter.patientBalance || 0,
    amount: patientEncounter.patientAmtDue || 0,
    balance: patientEncounter.patientBalance || 0,
    discount: + (patientEncounter.custom1 || 0),
  };
}
