/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import { EnumPlanStatus } from '../../models/enums/EnumPaymentPlan';
import { LineItemComponent, LineItemType } from './LineItemComponent';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { Attribute } from '../../models/metaData/MetaData';
import { changeSelected, deactiveAllPlans, expandPlanLineItems, resetOperation, resetSelected, setOperation, updateRecord } from '../paymentPlan/PaymentPlanReducer';
import { setOpenedPlanId } from 'features/paymentPlan/PaymentPlanReducer';
import { ReactComponent as Timer } from 'assets/svgs/planStatuses/timer.svg';
import { ReactComponent as Stop } from 'assets/svgs/planStatuses/stop.svg';
import { ReactComponent as Pause } from 'assets/svgs/planStatuses/pause.svg';
import { checkReceivedHasValues, displayAmount, displayBalanceAmount } from 'utils/Utils';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useBreakDownLineItems } from './hooks/useBreakDownLineItems';
import { PlanOperations } from 'models/metaData/MetaDataEnums';

export default function PaymentPlanComponent(props: {
  wizardActive?: boolean,
  paymentPlanId?: string | null;
  onClickAttribute?: (type: LineItemType, attr?: Attribute, value?: any) => void,
  planAdhoc?: boolean,
  organizationPath?: string;
}) {
  const { wizardActive = false, paymentPlanId, onClickAttribute, planAdhoc, organizationPath } = props;
  let paymentPlan = useTypedSelector(s => s.paymentPlanInfo.records?.find(p => p.id == paymentPlanId));
  const dispatch = useDispatch();

  const { useGetDepartmentByPath, getFacilityByDepartment: useGetFacilityByDepartment } = useOrganizations();
  const department = useGetDepartmentByPath(paymentPlan?.department?.path);
  const facility = useGetFacilityByDepartment(paymentPlan?.organization?.path);

  const paymentConfiguration = useTypedSelector(s => s.paymentDashboard?.panelConfigurations?.[facility?.path ?? '']?.configuration);
  const paymentPlanPanelConfiguration = useTypedSelector(s => (s.paymentPlanInfo?.planPanelConfigurations?.[facility?.path ?? '']?.configuration));
  const lineItemAttributes = paymentPlan?.isOpen ? paymentPlanPanelConfiguration?.entity?.attributes ?? [] : paymentConfiguration?.entity?.attributes ?? [];

  const [enablePaymentAmount, setEnablePaymentAmount] = useState(false);
  const [updatedBreakdownLineItems, setUpdatedBreakdownLineItems] = useState(Array());
  const [adHocAmount, setAdHocAmount] = useState(0.0);
  const operation = useTypedSelector(state => state.paymentPlanInfo.operation);
  const isAddHoc = operation === PlanOperations.AddhockPayment;
  const isScreenDisabled = useTypedSelector(s => s.paymentDashboard?.isScreenDisabled);
  const screenDisabledClasses = checkReceivedHasValues(isScreenDisabled, 'payment_overlay payment_overlay--lighter-color content_overlay_fix' , '');
  
  useEffect(() => {
      dispatch(deactiveAllPlans());
  }, [])
  
  useEffect(() => {
    if (enablePaymentAmount) {
      setEnablePaymentAmount(false);
      dispatch(updateRecord({ id: paymentPlan?.id, paymentPlan: { ...paymentPlan!, paymentAmount: '0', isActive: false } }))

    }
    if (isAddHoc){
      dispatch(deactiveAllPlans());
      dispatch(resetSelected());
      dispatch(resetOperation());
      setUpdatedBreakdownLineItems([]);
    }
  }, [planAdhoc])

  useEffect(() => {
    if (operation == PlanOperations.NotSet){
      dispatch(deactiveAllPlans());
      dispatch(resetSelected());
      dispatch(resetOperation());
      setUpdatedBreakdownLineItems([]);
    }
  }, [operation])

  const lineItemComponents = paymentPlan?.lineItems?.map((lineItem, index) => {
    let updateLineItem = {
      ...lineItem,
      isContracted: true,
      amount: lineItem.amount
    }

    const key = `${lineItem.id}-${index}-paymentPlan`;

    function getBreakDownAmount(accountNumber: string | undefined): number {
      const paymentAdhoc = updatedBreakdownLineItems.find(item => item.accountNumber === accountNumber);
      return paymentAdhoc?.paymentAmount;
    }

    return (
      <LineItemComponent
        key={key}
        lineItem={updateLineItem}
        lineItemId={lineItem.id}
        type={LineItemType.Sub}
        paymentPlanId={paymentPlan?.id}
        onClickAttribute={onClickAttribute}
        externalAttributes={lineItemAttributes}
        isPaymentOrPreviewPage={true}
        isPayment={false}
        planAdhoc={planAdhoc}
        adhocAmount={getBreakDownAmount(updateLineItem.accountNumber)}
        planSelected={enablePaymentAmount}
        organizationPath={organizationPath}
      />
    )
  });

  let borderClass = "";
  let containerClass = "";
  if (paymentPlan?.status == EnumPlanStatus.Active) {
    borderClass = 'border-color-active';
    containerClass = 'background-color-active';
  }

  if (paymentPlan?.status == EnumPlanStatus.Paused) {
    borderClass = 'border-color-paused';
    containerClass = 'background-color-paused';
  }

  if (paymentPlan?.status == EnumPlanStatus.Stopped) {
    borderClass = 'border-color-stopped';
    containerClass = 'background-color-stopped';
  }

  const getPlanStatus = (status: EnumPlanStatus) => {
    if (status === EnumPlanStatus.Active)
      return <Timer />;
    else if (status === EnumPlanStatus.Paused)
      return <Pause />
    else if (status === EnumPlanStatus.Stopped || status === EnumPlanStatus.Cancelled)
      return <Stop />
  }
 
  const UpdatePaymentPlanIsActive = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEnablePaymentAmount(e.target.checked);
      setAdHocAmount(0);
      dispatch(deactiveAllPlans());
      dispatch(updateRecord({ id: paymentPlan?.id, paymentPlan: { ...paymentPlan!, isActive: e.target.checked, isOpen: !e.target.checked ? false : paymentPlan?.isOpen } }));
      setUpdatedBreakdownLineItems([]);
      dispatch(resetSelected());
      dispatch(changeSelected({ id: paymentPlan!.id, isSelected: true }));
      if(e.target.checked){
        dispatch(setOperation(PlanOperations.AddhockPayment));
      }
      else{
        dispatch(resetOperation());
      }
    },
    [dispatch, paymentPlan]
  );

  let timeout = useRef<number>();

  let breakDownCallback = async (paymentAmount: string) => {
    const updatedLineItems = await breakDown(+paymentAmount);
    if (updatedLineItems && paymentPlan) {
      setUpdatedBreakdownLineItems(updatedLineItems);
      dispatch(updateRecord({
        id: paymentPlan.id,
        paymentPlan: {
          ...paymentPlan,
          addhocAmount: + paymentAmount,
          lineItems: updatedLineItems,
          isOpen:true,
        }
      }))
    }
    else {
      setUpdatedBreakdownLineItems([]);
    }
  }

  const displayadHocBreakDown = (value: number) => {
    const balanceValue = displayBalanceAmount(displayAmount(value));
    return (
      <>
        <span>-$</span>
        {balanceValue.firstPart}
        <span>{balanceValue.lastPart}</span>
      </>
    );
  };

  const getAdHocPaymentAmount = () => {
    return planAdhoc && paymentPlan?.isActive && adHocAmount > 0 ? (
      <>
        <p className={'line-item-balance-adhoc'}>
          {displayadHocBreakDown(adHocAmount ?? 0)}
        </p>
      </>
    ) : null
  }

  useEffect(() => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      if (adHocAmount > 0) {
        breakDownCallback(adHocAmount.toString());
      }
    }, 1000)

  }, [adHocAmount])

  const getListOrDefault = (list: any) => {
    return list && list.length > 0 ? list : [];
  }

  const { breakDown } = useBreakDownLineItems({
    lineItems: getListOrDefault(paymentPlan?.lineItems),
    organizationPath,
    paymentPlan: paymentPlan
  });

  const changeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    let value = event.target.value;
    let amount = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    setAdHocAmount(amount);
    dispatch(updateRecord({ id: paymentPlan?.id, paymentPlan: { ...paymentPlan!, paymentAmount: amount.toString() } }))
  };

  if (!paymentPlan?.id) return null;

  function expandLineItems() {
    dispatch(expandPlanLineItems({ paymentPlanId: paymentPlan?.id ?? ''}))
  }

  return (
    <div className={`payment-plan-list-item line-item ${borderClass} ${screenDisabledClasses}`}>
      <div className={`list-item-container ${containerClass}`}>
        <div className="bg-light line-item">
          <div className="tools">
            <div style={{ display: planAdhoc ? 'block' : 'none' }}>
              <input
                className={'eds-checkbox_#input'}
                type={'checkbox'}
                checked={paymentPlan.isActive}
                onChange={(e) => { UpdatePaymentPlanIsActive(e); }}
                data-testid="enablePaymentAmount"
              />
            </div>
            <div className="status-icon" id="status-icon">
              {getPlanStatus(paymentPlan.status as EnumPlanStatus)}
            </div>
          </div>
          <div className="line-item-container">
            <div className="line-item-attributes preview">
              <div className='line-item-attribute'>
                <p className='line-item-attribute-label'>Plan ID</p>
                <p className='line-item-attribute-label'><a className={'link-transaction-id'} href={`/planDetails/${paymentPlan.id}`} target={"_blank"} rel="noreferrer" onClick={(e: any) => {
                  e.preventDefault();
                  dispatch(setOpenedPlanId(paymentPlan?.id!));
                }}>{paymentPlan.id ?? ''}</a></p>
              </div>
              <div className='line-item-attribute'>
                <p className='line-item-attribute-label'>Facility Name</p>
                <p> {facility?.name}</p>
              </div>
              <div className='line-item-attribute'>
                <p className='line-item-attribute-label'>Department Name</p>
                <p className='line-item-attribute-departament'> {department?.name}</p>
              </div>
              <div className='line-item-attribute'>
                <p className='line-item-attribute-label'>Payment Amount</p>
                <div className="eds-field_#control eds-field_.eds-input eds-input" style={{ display: paymentPlan.isActive ? 'block' : 'none' }}>
                  <input
                    className="eds-input_#input"
                    onChange={changeHandler}
                    value={paymentPlan.paymentAmount}
                    data-testid="paymentAmount"

                  ></input>
                </div>
                <div className="error-message-required divider" style={{ display: (adHocAmount > (paymentPlan.balance ?? 0) && paymentPlan.isActive) ? 'block' : 'none' }}>Maximum ${paymentPlan.balance} allowed.</div>
              </div>
            </div>
          </div>
          <div className="line-item-buttons">
            <div className='line-item-attribute'>
              <p className='line-item-attribute-label'>Total Plan Balance</p>
              <p className="line-item-balance">${displayAmount(paymentPlan.balance)}</p>
              {enablePaymentAmount ? getAdHocPaymentAmount() : null}
            </div>
          </div>
        </div>
      </div>
      {paymentPlan.isOpen && !!paymentConfiguration ? lineItemComponents : ''}
      <div className='expand-button-container'>
        <i
          className={`eds-button_#icon material-icons`}
          role={"img"}
          aria-label={'expand'}
          onClick={() => {
            expandLineItems();
          }}
        >
          {paymentPlan.isOpen ? 'expand_less' : 'expand_more'}
        </i>
      </div>
    </div>
  );
}
