import { useEffect, useState } from 'react';
import { TabPane } from 'reactstrap';
import { checkReceivedHasValues, displayAmount, getValueOrDefault } from 'utils/Utils';
import { SummaryColumn } from './SearchResultsTemplate';
import 'assets/styles/components/_searchPlansResults.scss';

export function Summary(props: {
  getData: () => Promise<any>;
  loading?: boolean;
}) {
  const { getData, loading } = props;
  const [data, setData] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    getData()
      .then((result: any) => result?.length
        ? setData(result)
        : result && setError(result.err))
  }, [getData])

  const getSummaryBody = () => {
    return data.map((column: SummaryColumn[][], index = 0) => {
      return (
        <div key={index} className="summary-column">
          {column.map((row: any) => {
            const fields = ['Credit', 'Void', 'GL Void', 'GL Credit'];
            const isTitle = row?.format === 'title'
            const isTotal = row?.format === 'total'
            const isVoidOrCredit = fields.includes(row.field);
            return (
              <div className={`flex-row ${isTitle ? 'column-title' : checkReceivedHasValues(isTotal,'column-total', '')}`} key={row?.field}>
                <div className="flex-50">{row.field}</div>
                <div className="flex-10">{row.value?.count}</div>
                <div className={`flex-30 right-aligned ${checkReceivedHasValues(isVoidOrCredit && row.value.total > 0,'payments', '')}`} >
                {`${checkReceivedHasValues(isVoidOrCredit && row.value.total > 0,'-', '')} ${checkReceivedHasValues(isTitle, row.value?.total, '$' + displayAmount(getValueOrDefault(row.value?.total, 0)))}`} </div>
              </div>
            )
          })}
        </div>
      )
    })
  }
  
  return (
    <TabPane className="tab-search-content" tabId="1">
      {loading ? <div data-testid="loading" className="results-loading">Processing Summary screen... Please wait while loading</div>
          : checkReceivedHasValues(!!data.length,
            <div className="summary-results-wrapper">
              <div className='summary-title col-12'>Search Results Summary</div>
              {getSummaryBody()}
            </div>,
            checkReceivedHasValues(
              error, 
              <div className="server-error">{`Server error while doing the search: ${error}`}</div>, 
              <div>No results found</div>
            )
          )
        }
    </TabPane>
  );
}
