import React, { useEffect, useState, useMemo} from 'react';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import 'assets/styles/components/_clientConfiguration.scss';
import ConfigurationTemplate from 'features/admin/clientConfiguration/ConfigurationTemplate';
import { useGetPaymentConfiguration } from 'features/singlePayment/hooks/useGetPaymentConfiguration';
import { updatePaymentConfiguration } from 'services/TransactionsService';
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { FaSave } from 'react-icons/fa';
import { PaymentConfiguration, AllowedPaymentCardTypes, cardTypes } from 'models/PaymentsConfiguration';
import 'assets/styles/components/_acceptedPayments.scss';
import {
  AlertIds,
  AlertTypes,
  setAlert,
} from '../../features/alert/AlertReducer';
import { setConfigurationReload } from 'features/paymentDashboard/PaymentDashboardReducer';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumbLinks([{ name: 'Accepted Payments', slug: rootPath }])
    );

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

export default function AcceptedPayments(
  props: Props = { rootPath: 'accepted-payments' }
) {
  const { rootPath } = props;
  useBreadcrumb(rootPath);
  const dispatch = useDispatch();

  const clientConfiguration = useTypedSelector(
    s => s.getClientFacilityConfiguration?.result
  );
  const organizationPath = clientConfiguration?.organization.path ?? '';
  const isProcessing = useTypedSelector(s => s.services.calls.GetPaymentConfiguration?.isProcessing);
  const { getConfiguration: getPaymentConfiguration } = useGetPaymentConfiguration(organizationPath || '');

  const paymentConfiguration = useTypedSelector(s => s.paymentDashboard?.configurations[organizationPath]?.configuration);
  const paymentConfigurations = useTypedSelector(s => s.paymentDashboard?.configurations[organizationPath]);
  const { getOrganizationByPath } = useOrganizations();
  const organization = getOrganizationByPath(paymentConfiguration?.organization?.path);

  const {id,name, path } = organization ?? {id :'', name: '', path : paymentConfiguration?.organization?.path?? ''};
  const [paymentConfigValues, setPaymentConfigValues] =useState<PaymentConfiguration>(paymentConfiguration);
  const [isConfigLoading, setIsConfigLoading] = useState(false);

  
  const [alertShown, setAlertShown] = useState(false);
  //onChange show alert, set flag and save configuration
  const onChange = (paymentConfigValues: PaymentConfiguration) => {
    setPaymentConfigValues(paymentConfigValues);
    setAlertShown(true);
    if (!alertShown) {
      dispatch(setAlert({
        id: AlertIds.PaymentConfiguration,
        type: AlertTypes.Info,
        message: "Please note that these changes do not apply to existing recurring payments.",
      }));
    }
  }

  useMemo(async() => {
    if(!organizationPath) { 
      return;
     }
     setIsConfigLoading(true);
     await getPaymentConfiguration();
     setIsConfigLoading(false);
  }, [organizationPath,paymentConfigurations?.reload]);

  useEffect(()=>{
    if(isConfigLoading === false && paymentConfiguration!== undefined)
    {
      setPaymentConfigValues(paymentConfiguration);
    }
  },[isConfigLoading])
  async function onSubmit(paymentConfigurationValues :PaymentConfiguration) {

    const response = await updatePaymentConfiguration(organizationPath, {...paymentConfiguration,                      
      hasCreditCardAllowed : paymentConfigurationValues?.hasCreditCardAllowed,
      hasCardDeviceAllowed: paymentConfigurationValues?.hasCardDeviceAllowed,
      hasCashAllowed:paymentConfigurationValues?.hasCashAllowed,
      hasECheckAllowed:paymentConfigurationValues?.hasECheckAllowed,
      hasMoneyOrderAllowed:paymentConfigurationValues?.hasMoneyOrderAllowed,
      hasPaperCheckAllowed: paymentConfigurationValues?.hasPaperCheckAllowed,
      organization: { id, name, path },
      isVisaAllowed: paymentConfigurationValues?.isVisaAllowed,
      isMasterCardAllowed: paymentConfigurationValues?.isMasterCardAllowed,
      isAmexAllowed: paymentConfigurationValues?.isAmexAllowed,
      isDiscoverAllowed: paymentConfigurationValues?.isDiscoverAllowed,
      isDinersClubAllowed: paymentConfigurationValues?.isDinersClubAllowed,
      isJcbAllowed: paymentConfigurationValues?.isJcbAllowed,
      isUnionPayAllowed: paymentConfigurationValues?.isUnionPayAllowed, 
     })
     if (!response.err) {
        dispatch(setConfigurationReload({ organizationPath: organizationPath, reload: true }));
        dispatch(
          setAlert({
            id: AlertIds.PaymentConfiguration,
            type: AlertTypes.Success,
            message: `Configuration saved successfully.`,
            dismissable: true
          })
        );
     }
     else{
        dispatch(setAlert({
          id: AlertIds.PaymentConfiguration,
          type: AlertTypes.Error,
          message: "Could not save configuration.",
          dismissable: true
        }));
     }
  }

  if (isProcessing || !organizationPath || !paymentConfiguration) {
    return <p>Getting Payment configuration...</p>;
  }
  else{
    return (
      <div className="client-configuration">
        <ConfigurationTemplate title={'Accepted Payments'}>        
          <span className="text"> Providers that are not already configured are not listed as an option </span>
          <div className="submit">
            <button
              className="action"
              name='saveButton'
              onClick={() => onSubmit(paymentConfigValues)}
            >
              <FaSave />  
              <span className="text">Save</span>          
            </button>
          </div>
          <div className ="row acceptedpaymentsrow">
          <div className ="col-md-3">
            <br/>
            <label className='checkbox-columns acceptedpaymentslabel'>
              <input
                className={'eds-checkbox_#input'}
                type={'checkbox'}
                name={'payments-hasCreditCardAllowed'}
                checked={paymentConfigValues?.hasCreditCardAllowed}
                onChange={() => {
                  onChange({...paymentConfigValues, hasCreditCardAllowed : !paymentConfigValues?.hasCreditCardAllowed})                        
                    }}
              />
              <span className={`eds-checkbox_#label`}>Accept Credit Cards</span> 
            </label>
          </div>
          <div className ="col-md-3">
            <label className='checkbox-columns acceptedpaymentslabel'>
              <input
                className={'eds-checkbox_#input'}
                type={'checkbox'}
                name={'payments-hasCardDeviceAllowed'}
                checked={paymentConfigValues?.hasCardDeviceAllowed}
                  onChange={() => {
                    onChange({ ...paymentConfigValues, hasCardDeviceAllowed: !paymentConfigValues?.hasCardDeviceAllowed })
                    }}                  
              />
              <span className={`eds-checkbox_#label`}>Accept Card Device</span>
            </label>
          </div>
          <div className ="col-md-3">
            <label className='checkbox-columns acceptedpaymentslabel'>
              <input
                className={'eds-checkbox_#input'}
                type={'checkbox'}
                name={'payments-hasCashAllowed'}
                checked={paymentConfigValues?.hasCashAllowed}
                onChange={() => {
                  onChange({...paymentConfigValues, hasCashAllowed : !paymentConfigValues?.hasCashAllowed})
                    }}
              />
              <span className={`eds-checkbox_#label`}>Accept Cash</span>
            </label>
          </div>
          <div className ="col-md-3">
            <label className='checkbox-columns acceptedpaymentslabel'>
              <input
                className={'eds-checkbox_#input'}
                type={'checkbox'}
                name={'payments-hasPaperCheckAllowed'}
                checked={paymentConfigValues?.hasPaperCheckAllowed}
                onChange={() => {
                  onChange({...paymentConfigValues, hasPaperCheckAllowed : !paymentConfigValues?.hasPaperCheckAllowed})
                    }}
              />
              <span className={`eds-checkbox_#label`}>Accept Paper Checks</span>
            </label>
          </div>
          <div className ="col-md-3">
            <label className='checkbox-columns acceptedpaymentslabel'>
              <input
                className={'eds-checkbox_#input'}
                type={'checkbox'}
                name={'payments-hasECheckAllowed'}
                checked={paymentConfigValues?.hasECheckAllowed}
                onChange={() => {
                  onChange({...paymentConfigValues, hasECheckAllowed : !paymentConfigValues?.hasECheckAllowed})
                    }}
              />
              <span className={`eds-checkbox_#label`}>Accept eChecks</span>
            </label>
          </div>
          <div className ="col-md-3">
            <label className='checkbox-columns acceptedpaymentslabel'>
              <input
                className={'eds-checkbox_#input'}
                type={'checkbox'}
                name={'payments-hasMoneyOrderAllowed'}
                checked={paymentConfigValues?.hasMoneyOrderAllowed}
                onChange={() => {
                  onChange({...paymentConfigValues, hasMoneyOrderAllowed : !paymentConfigValues?.hasMoneyOrderAllowed})
                    }}
              />
              <span className={`eds-checkbox_#label`}>Accept Money Order</span>
            </label>
          </div>   
          </div> 
          <div className="title">
            <h2>{"Accepted Card Brands"}</h2>
          </div>
          <div className={`row acceptedpaymentsrow ${paymentConfigValues?.hasCardDeviceAllowed || paymentConfigValues?.hasCreditCardAllowed ? "" : "grayed-out"}`}>
              {cardTypes.map((cardType, index) => {
                //return a checkbox for each card type
                return (
                  <div className="col-md-3" key={cardType}>
                    <label className='checkbox-columns acceptedpaymentslabel'>
                      <input
                        className={'eds-checkbox_#input'}
                        type={'checkbox'}
                        name={`payments-${cardType}`}
                        checked={paymentConfigValues?.[cardType]}
                        onChange={() => {
                          onChange({ ...paymentConfigValues, [cardType]: !paymentConfigValues?.[cardType] })
                        }}
                        disabled={!paymentConfigValues?.hasCardDeviceAllowed && !paymentConfigValues?.hasCreditCardAllowed }
                      />
                      <span className={`eds-checkbox_#label`}>{getLabel(cardType)}</span>
                    </label>
                  </div>
                );
              })}
            </div>
        </ConfigurationTemplate>
      </div>
    );
    }
}

const getLabel = (cardType: keyof AllowedPaymentCardTypes) => {
  //return the label from a map object for each card type
  const cardTypeLabels: { [key in keyof AllowedPaymentCardTypes]: string } = {
      isVisaAllowed: 'Visa',
      isMasterCardAllowed: 'MasterCard',
      isAmexAllowed: 'American Express',
      isDiscoverAllowed: 'Discover',
      isDinersClubAllowed: 'Diners Club',
      isJcbAllowed: 'JCB',
      isUnionPayAllowed: 'Union Pay',
    };
  return cardTypeLabels[cardType];
}
