import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../app/rootReducer";
import { FullOrganizationLevelDocument } from "../../../../models/OrganizationLevelDocument";
import { useOrganizations } from "../../../organizations/hooks/useOrganizations";
import { setOrganizationsAndUsers, setTradingPartner } from "../UsersAndPermissionsReducer";

export interface OrganizationsUsersProps {
  facilities?: FullOrganizationLevelDocument[],
  clearState: ()=>void,
  organizations: FullOrganizationLevelDocument[],
  useGetTradingPartners: () => FullOrganizationLevelDocument[],
  sspUserPermission?: boolean
}

export interface UserFormState extends OrganizationsUsersProps {
  isAdmin?: boolean,
  resetSelection?: boolean
}

export default function useOrganizationsUsersState(): OrganizationsUsersProps {
  const dispatch = useDispatch();
  const selectedOrganization = useTypedSelector(s => s.usersAndPermissions.tradingPartner);

  const { organizations, useGetFacilities, useGetTradingPartners } = useOrganizations();
  const facilities = useGetFacilities().filter(o =>
    !selectedOrganization?.value || o.path.startsWith(selectedOrganization.value)
  );

  const initialState = {
    departments: [],
    facilities: [],
    users: [],
  };

  const clearState = () => {
    dispatch(setTradingPartner(null));
    dispatch(setOrganizationsAndUsers(initialState));
  }

  return {
    facilities,
    clearState,
    organizations,
    useGetTradingPartners,
  }
}