import moment from 'moment';
import { formatDateWithTime, getAmount, getBalance, IsRefunded } from 'utils/Utils';
import { convertUtcToLocalDate } from 'utils/UtilsDateTime';
import { useTypedSelector } from '../../../app/rootReducer';
import PaymentPlanDetailsLink, { PaymentPlanDetailsModal } from '../../paymentPlan/PaymentPlanDetailsLink';
import { buildTransactionStatus } from '../builders/BuildTransactionStatus';

export default function TransactionDetails() {
  const transaction = useTypedSelector(s => s.transactionDetails.transactionDetailsData?.transactionDetails);
  const lineitems = useTypedSelector(s => s.transactionDetails.transactionDetailsData?.transactionDetails?.lineItems);

  function getAmountInfo(){
    if (IsRefunded(transaction?.transactionType)){
      const originalAmount = transaction?.relatedTransactions && transaction?.relatedTransactions[0].lineItems ? 
      getBalance(transaction?.relatedTransactions[0].lineItems) : getAmount(lineitems ?? []);
      return (
        <><div className="row">
          <div className="font-weight-bold width-150">Refunded Amount:</div>
          <div className="col">{`$-${transaction?.amount?.toFixed(2)}`}</div>
        </div><div className="row">
            <div className="font-weight-bold width-150">Original Amount:</div>
            <div className="col">{`$${originalAmount.toFixed(2)}`}</div>
          </div></>
      )
    }
    return <><div className="row"><div className="font-weight-bold width-150">Payment Amount:</div><div className="col">{`$${transaction?.amount?.toFixed(2)}`}</div></div></>;       
  }

  function getBilledInfo(){
    const discount = transaction?.discount ? transaction?.discount : 0
    const originalAmount = transaction?.amount as number + discount;
    return <><div className="row"><div className="font-weight-bold width-150">Billed Amount:</div><div className="col">{`$${originalAmount.toFixed(2)}`}</div></div></>;       
  }

  return <div className="card">
    <PaymentPlanDetailsModal />
    <div className="card-header">
      <span>Payment Detail</span>
    </div>
    <div className="card-content">
      <div className="row p-2">
        <div className="col-lg-4">
          <div className="row">
            <div className="font-weight-bold width-150">Gateway Reference Number:</div>
            <div className="col">{transaction?.gatewayReferenceNumber}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Plan ID:</div>
            <div className="col">
              { transaction?.paymentPlanId && <PaymentPlanDetailsLink id={transaction?.paymentPlanId} /> }
            </div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Transaction ID:</div>
            <div className="col">{transaction?.id}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Billing ID:</div>
            <div className="col">{transaction?.billingId}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Episode:</div>
            <div className="col">{transaction?.episode}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Authorization Code:</div>
            <div className="col">{transaction?.authorizationCode}</div>
          </div>
        </div>
        <div className="col-lg-4">
          {getBilledInfo()}
          {getAmountInfo()}    
          <div className="row">
            <div className="font-weight-bold width-150">Discount Amount:</div>
            <div className="col">${transaction?.discount}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Payment Date/Time:</div>
            <div className="col">{formatDateWithTime(moment(convertUtcToLocalDate(transaction?.createdDate)))}</div>
          </div>      
          <div className="row">
            <div className="font-weight-bold width-150">Transaction Type:</div>
            <div className="col">{transaction?.transactionType}</div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150">Tender Type:</div>
            <div className="col">{transaction?.tenderType}</div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row">
            <div className="font-weight-bold width-150">Payment Status:</div>
            <div className="col">{transaction ? buildTransactionStatus(transaction) : ''}
            </div>
          </div>
          <div className="row">
            <div className="font-weight-bold width-150 float-left">Provider Message: </div>
            <div className="text-highlighter padding-left-15">{transaction?.statusMessage}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
}