import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addBlankLineItem, setIsGl, setPanelConfiguration, replaceLineItems, setIsPaymentType, removeLineItem as removePaymentLineItem } from '../paymentDashboard/PaymentDashboardReducer';
import { EDS_Button, EDS_TextBox } from '@EH/eh.eds.react';
import { useTypedSelector } from '../../app/rootReducer';
import LineItems from '../paymentDashboard/LineItems';
import { Wizard } from '../wizard/Wizard';
import { useOnCreateOrUpdatePlan } from './useOnCreatePlan';
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/styles/components/_bills.scss';
import { useGetPaymentPlanById } from './hooks/useGetPaymentPlanById';
import { useEncounters } from './hooks/useEncounters';
import { changeSelected, replaceRecords, resetSelected, setOperation, updateRecord } from './PaymentPlanReducer';
import { distributeAmount } from '../paymentPlan/useDistribution';
import { EnumPlanStatus } from 'models/enums/EnumPaymentPlan';
import OrganizationLevelTypes from '../../models/enums/OrganizationLevelTypes';
import { PlanOperations } from 'models/metaData/MetaDataEnums';
import { useActiveAddhockPlan } from './hooks/useActiveAddhockPlan';
import { useGetConfiguration } from '../paymentPlan/hooks/useGetConfiguration';
import useGetPanelConfiguration from "../admin/paymentPanel/serviceHandlers/useGetConfiguration";
import { AlertIds, AlertTypes, removeAlert, setAlert } from '../alert/AlertReducer';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import { deleteLineItemsService } from 'services/PaymentPlanService';
import { Popup } from 'components/popup/Popup';
import {
  EnumPopupType,
  EnumPopupButtonsType,
  EnumPopupButtonsStyle,
} from 'models/enums/EnumPopupType';
import { LineItem, LineItems as LineItemsInterface } from 'models/LineItem';
import { setPlanPanelConfiguration } from '../paymentPlan/PaymentPlanReducer';
import { useGetPaymentConfiguration } from 'features/singlePayment/hooks/useGetPaymentConfiguration';
import { getClientConfiguration } from 'features/admin/GetClientConfigurationReducer';
import { ClientFacilityConfiguration } from 'models/Client';
import { ReactComponent as Distribute } from 'assets/svgs/paymentIcons/icon-distribute.svg';
import { DistributionOrder } from 'models/enums/EnumPaymentPanels';
import { isNumber } from 'validate.js';
import { PaymentPlansSelectionModal } from './popUps/PaymentPlansSelectionModal';
import { PaymentPlanModel } from '../../models';
import { OrganizationLevelDocument } from '../../models/OrganizationLevelDocument';
import { Radio } from '@EHDS/core';
import { Dashboards } from 'features/admin/paymentPanel/ConfigurationSelector';
import { checkReceivedHasValues, checkStringHasValue } from 'utils/Utils';
import LoadingProgress from 'components/loading/LoadingProgress';

const AMOUNT_BALANCE_DISCREPANCY_KEY = 'AmountBalanceDiscrepancy'

const RestrictionMessages = {
  onPlanPausedOrStopped: 'An Account/Invoice cannot be added to a paused or stopped plan.',
}

const popupFooterRemoveLineItem = [
  {
    type: EnumPopupButtonsType.submit,
    style: EnumPopupButtonsStyle.secondary,
    text: 'Yes, remove the record',
  },
  {
    type: EnumPopupButtonsType.cancel,
    style: EnumPopupButtonsStyle.primary,
    text: 'Do not remove',
  },
];

export default function Bills(props: {
  isGl?: boolean;
  wizard: Wizard;
  patientId?: string;
  clientId?: string;
  organizationPath?: string;
  organizationName?: string;
  onContinue?: () => void;
  isPayment?: boolean;
  addhockLineItems?: LineItemsInterface;
  addRowEnabled?: boolean,
  isPreview?: boolean,
  typedAccountNumber?: string,
  accountNumberFound?: boolean,
  selectedOrganization?: OrganizationLevelDocument,
  isStay?: boolean;
}) {
  const { isGl, wizard, patientId, organizationPath, organizationName, clientId, onContinue, isPayment, addhockLineItems, addRowEnabled, isPreview, typedAccountNumber, accountNumberFound, selectedOrganization, isStay } = props;
  const dispatch = useDispatch();

  const operation = useTypedSelector(s => s.paymentPlanInfo.operation);
  const allowAddToPlan = useTypedSelector(s => s.paymentPlanInfo?.configurations?.[organizationPath ?? '']?.configuration?.allowAddToPlan) ?? false;
  const isScreenDisabled = useTypedSelector(s => s.paymentDashboard?.isScreenDisabled);
  const screenDisabledClasses = checkReceivedHasValues(isScreenDisabled, 'bills-container-header payment_overlay payment_overlay--transparent content_overlay_fix', 'bills-container-header');

  useEffect(() => {
    if (organizationPath) {
      getPlanConfiguration();
    }
  }, [organizationPath]);

  const getDashboardType = () => {
    if (isGl)
      return 'GlPaymentDashboard';
    else if (isPayment)
      return 'PaymentDashboard';
    else
      return 'PaymentPlanDashboard';
  }
  const { getConfiguration: getPaymentConfiguration } = useGetPaymentConfiguration(checkStringHasValue(organizationPath));
  const [isLoading, setIsLoading] = useState(true);

  useMemo(async () => {
    setIsLoading(true);
    await getPaymentConfiguration();
    setIsLoading(false);
  }, [organizationPath]);

  useEffect(() => {
    if (!isLoading && !isPreview) {
      getPanelConfiguration(getDashboardType());
    }
  }, [isLoading]);

  const selectedDashboard = !isPreview ? getDashboardType() : localStorage.getItem('selectedDashboard') as keyof Dashboards;
  
  const panelConfiguration = useTypedSelector(s => (s.paymentPanel.configurations[selectedDashboard]));
  const paymentPanelConfiguration = useTypedSelector(s => !isPreview ? s.paymentDashboard?.panelConfigurations?.[checkStringHasValue(organizationPath)]?.configuration : undefined);
  const distributionOrder = getDistributionOrderValue(paymentPanelConfiguration?.distributionOrder);
  const [distributionAmount, setDistributionAmount] = useState('');
  const [adHocRadioOption, setAdHocRadioOption] = useState(true);

  useEffect(() => {
    if (isPayment || isGl)
      dispatch(setPanelConfiguration({ organizationPath: organizationPath ?? '', configuration: { ...panelConfiguration } }));
    else
      dispatch(setPlanPanelConfiguration({ organizationPath: organizationPath ?? '', configuration: { ...panelConfiguration } }));
  }, [panelConfiguration]);

  const { getPanelConfiguration } = useGetPanelConfiguration(organizationPath);

  const patientInfo = useTypedSelector(s => s.patientInfo);
  const allowPAHSync = useTypedSelector(s => s.paymentPlanInfo?.configurations?.[organizationPath ?? '']?.configuration?.allowPAHSync);
  const { isProcessing: fetchingEncounters, lineItems, fetchEncounters } = useEncounters(
    patientId, organizationPath, clientId, patientInfo.value.accountNo
  );

  const [clientConfiguration, setClientConfiguration] = useState<ClientFacilityConfiguration | undefined>();
  useEffect(() => {
    if (!organizationPath) return;

    dispatch(getClientConfiguration(
      { OrganizationPath: organizationPath },
      (data?: ClientFacilityConfiguration) => {
        setClientConfiguration(data);
      }));
  }, [organizationPath]);

  const paymentPlansSelected = useTypedSelector(s => s.paymentPlanInfo?.selected) || [];
  const paymentPlansRecords = useTypedSelector(s => s.paymentPlanInfo?.records) || [];
  const billsLineItems = useTypedSelector(s => s.paymentDashboard?.values?.[organizationPath ?? '']?.lineItems || []);
  const discrepancies = useTypedSelector(s => s.paymentPlanInfo?.discrepancies);
  const facility = useTypedSelector(s => s.glPaymentDashboard?.value.facility);
  const { paymentPlan } = useGetPaymentPlanById((paymentPlansSelected || [])[0]);
  const [isLineItemRemoved, setIsLineItemRemoved] = useState(false);
  const [isLineItemRemoveDisabled, setLineItemRemoveDisabled] = useState(false);
  const defaultErrorMessage = 'An error occured.';
  const removeLineItemErrorMessage = defaultErrorMessage + ' Record not removed.';
  const paymentAlert = { id: AlertIds.GlPaymentAlert, type: AlertTypes.Error, message: '' };
  const dashboards = useTypedSelector(s => s.paymentDashboard?.values);
  const filteredPaymentPlanRecords = paymentPlansRecords.filter(x => (x.organization?.path)?.includes(organizationPath ?? '', 0));
  const checkLineItems = () => {
    const values = Object.keys(dashboards).map((key) => {
      return dashboards[key]?.lineItems?.map((item: LineItem) => ({ ...item, organizationPath: key }));
    });
    const lineItemList = values?.flat() ?? [];
    const hasDifferentOrganizationPaths = lineItemList.find(item => item.organizationPath !== organizationPath && item.isActive && (item.balance || 0) > 0);

    if (hasDifferentOrganizationPaths) return false;

    const availableLineItems = lineItems?.find(l => l && l.isActive && (l.balance || 0) > 0 && (l?.discount ?? 0) === 0);

    return filteredPaymentPlanRecords.length > 0 && availableLineItems;
  };

  const openErrorMessage = (message: string) => {
    dispatch(setAlert({ ...paymentAlert, message }));
  };

  const closeErrorMessage = () => {
    dispatch(removeAlert(paymentAlert));
  };
  const active = checkLineItems();

  const { onAddToPlan } = useOnCreateOrUpdatePlan(
    {
      wizard,
      isGl,
      plan: paymentPlan,
      onSuccess: () => { /* empty function */ },
      organization: paymentPlan?.organization && {
        ...paymentPlan.organization,
        organizationLevelType: OrganizationLevelTypes.Department
      }
    }
  );

  const facilityPath = checkStringHasValue(facility?.path);

  const { getPlanConfiguration } = useGetConfiguration(organizationPath ?? '');

  const hasDiscrepancy = () => {
    return discrepancies?.hasOwnProperty(AMOUNT_BALANCE_DISCREPANCY_KEY) ?? false;
  }

  const getDiscrepancyMessage = () => {
    return discrepancies ? discrepancies[AMOUNT_BALANCE_DISCREPANCY_KEY] : '';
  }

  const { havePermission } = useLoggedUserPermissions();

  const closeLineItemRemovedPopup = () => {
    setIsLineItemRemoved(false);
  };

  const openLineItemRemovedPopup = () => {
    setIsLineItemRemoved(true);
  };

  useEffect(() => {
    if (operation !== PlanOperations.AddhockPayment) {
      if ((paymentPlansSelected.filter(id => !!id)?.length || 0) > 1) {
        openErrorMessage('Select only 1 plan to proceed');
      } else {
        closeErrorMessage();
      }
    }
  }, [paymentPlansSelected.length]);

  useEffect(() => {
    if (operation === PlanOperations.AddToPlan) {
      return
    };
    dispatch(resetSelected())
    return () => {
      dispatch(replaceRecords([]));
    }
  }, []);

  useEffect(() => {
    dispatch(replaceRecords([]));
  }, []);
  
  useEffect(() => {
    if ( isStay ||
        (
          !isGl && 
          organizationPath && 
          isPayment && 
          allowPAHSync !== undefined &&
          paymentPlan?.authorized !== false
        )
      ) {
      fetchEncounters(undefined, allowPAHSync);
    }
  }, [patientId, allowPAHSync, isStay]);

  useEffect(() => {
    if (facilityPath) {
      getPlanConfiguration();
    }
  }, [facilityPath]);

  useEffect(() => {
    if (billsLineItems.length === 0 && isGl) {
      dispatch(addBlankLineItem({ organizationPath: checkStringHasValue(organizationPath) }));
    }
  }, [panelConfiguration]);

  useEffect(() => {
    if (lineItems?.length === 0 && !isGl && !fetchingEncounters) {
      onAddPayment();
    }
  }, [lineItems, fetchingEncounters, paymentPlansRecords, isPayment])

  useEffect(() => {
    const stepIndex = wizard.getCurrentStepIndex();
    if (stepIndex != 0 || !hasDiscrepancy()) {
      closeErrorMessage();
    }
    dispatch(setIsGl({ isGl: !!isGl, organizationPath: checkStringHasValue(organizationPath) }));
  }, [wizard, discrepancies]);

  useEffect(() => {
    if (lineItems?.length === 0 && !fetchingEncounters && paymentPlansRecords.length === 0 && !addRowEnabled) {
      renderAlert();
    }
  }, []);

  const [isAddToPlanModalOpen, setIsAddToPlanModalOpen] = useState(false);
  const getListOrDefault = (list: any) => {
    return list && list.length > 0 ? list : [];
  }

  function moveLineItemsToPlan(selectedPlan: PaymentPlanModel) {
    let activeLineItems = lineItems.filter(x => x.isActive);
    if (activeLineItems?.length) {
      let updatedLineItems = [...(getListOrDefault(paymentPlan?.lineItems))];
      const activeLineItemsTotalBalance = activeLineItems.reduce((total, lineItem) => total + (lineItem.balance ?? 0), 0);
      const activeLineItemsTotalAmount = activeLineItems.reduce((total, lineItem) => total + (lineItem.amount ?? 0), 0);
      const balance = (selectedPlan.balance ?? 0) + activeLineItemsTotalBalance;
      const paymentAmount = balance / selectedPlan.paymentsRemaining;

      activeLineItems.forEach(lineitem =>
        updatedLineItems?.push(lineitem)
      );

      let updatedPlan = {
        ...selectedPlan,
        lineItems: updatedLineItems,
        balance: balance,
        amount: (selectedPlan.amount ?? 0) + activeLineItemsTotalAmount,
        paymentAmount: paymentAmount?.toString(),
      };
      dispatch(
        updateRecord({
          id: selectedPlan.id,
          paymentPlan: updatedPlan,
        })
      );
    }

    activeLineItems.forEach(lineitem =>
      dispatch(removePaymentLineItem({ id: lineitem.id, organizationPath: organizationPath ?? '' }))
    );

  }
  const onAddPlanClick = (selectedPlan: PaymentPlanModel) => {
    let restrictions: { active: boolean, text: string }[] = [
      {
        active: hasDiscrepancy(),
        text: getDiscrepancyMessage()
      },
      {
        active: EnumPlanStatus.Paused === paymentPlan?.status,
        text: RestrictionMessages.onPlanPausedOrStopped
      },
      {
        active: EnumPlanStatus.Stopped === paymentPlan?.status,
        text: RestrictionMessages.onPlanPausedOrStopped
      }
    ];

    let restriction = restrictions.find(c => c.active);
    if (restriction) {
      openErrorMessage(restriction.text);
    }
    else {
      if (lineItems.find(l => l.discount && l.isActive)) {
        openErrorMessage("Create or Add to plan is prohibited for discounted invoices. You can proceed to a single payment or remove the discount.")
      }
      else {
        setIsAddToPlanModalOpen(false);
        closeErrorMessage();
        dispatch(setOperation(PlanOperations.AddToPlan));
        dispatch(setIsPaymentType(false));
        onAddToPlan(() => {
          window.setTimeout(() => wizard.selectStep(wizard.state.steps[2]), 0) // go to plan editor step
          moveLineItemsToPlan(selectedPlan);
        });
      }
    }
  };

  const onAddhockPaymentClick = () => {
    closeErrorMessage();
    if (planWithAmount) {
      dispatch(changeSelected({ id: planWithAmount.id, isSelected: true }));
      dispatch(setOperation(PlanOperations.AddhockPayment));
      onContinue && onContinue();
    }
  };

  function onAddPayment() {
    if (!onEditLineItems()) return;
    dispatch(addBlankLineItem({ organizationPath: organizationPath ?? '' }))
  }

  function getDistributionOrderValue(distributionOrder?: DistributionOrder) {

    if (!distributionOrder) {
      return DistributionOrder.None;
    }
    return (isNumber(Number(distributionOrder)) ? distributionOrder : DistributionOrder[distributionOrder ?? DistributionOrder.None])
  }

  function onDistributeAmount() {
    const parsedDistributionAmount = parseFloat(distributionAmount ?? '0');
    if (parsedDistributionAmount !== 0) {
      const distributedAmountLineItems = distributeAmount(billsLineItems, parsedDistributionAmount, distributionOrder);
      dispatch(replaceLineItems({ lineItems: distributedAmountLineItems, organizationPath: organizationPath ?? '' }));
    }
  }

  function onEditLineItems() {
    if (isGl && !facility?.id) {
      dispatch(setAlert({ ...paymentAlert, type: AlertTypes.Warning, message: "Please select facility and department", dismissable: true }))
      return false;
    }
    closeErrorMessage();
    return true;
  }

  let listOfItems = useMemo(() => {
    if (lineItems.length || paymentPlansRecords.length) {
      return (
        <LineItems
          key={`${organizationPath}-LineItems`}
          isGl={isGl}
          wizardActive={wizard.isActive()}
          organizationPath={organizationPath}
          patientId={patientId}
          onEditLineItems={onEditLineItems}
          isPayment={isPayment}
          clientConfiguration={clientConfiguration}
          isPreview={isPreview}
          typedAccountNumber={typedAccountNumber}
          accountNumberFound={accountNumberFound}
          planAdhoc={adHocRadioOption && !isPayment}
        />
      );
    }
  }, [organizationPath, isPayment, lineItems, paymentPlansRecords, patientId, adHocRadioOption]);

  const planWithAmount = useActiveAddhockPlan();

  const planDetailsAlert = { id: AlertIds.PlanDetailsAlert, type: AlertTypes.Error, message: defaultErrorMessage };

  const removeLineItem = async () => {
    if (paymentPlansSelected.length === 1) {
      const planLineItemsSelected = paymentPlan?.lineItems?.filter(lineItem => lineItem.isActive && lineItem.isRemovable);
      const result = paymentPlan?.id && await deleteLineItemsService(paymentPlan?.id, planLineItemsSelected?.map(l => l.accountNumber ?? '') ?? []);

      if (result && result.err) {
        dispatch(setAlert({ ...planDetailsAlert, message: removeLineItemErrorMessage }));
        return;
      }

      const updatedPlan = result && result.result;
      paymentPlan && updatedPlan && dispatch(
        updateRecord({
          id: paymentPlan.id,
          paymentPlan: {
            ...updatedPlan,
            isOpen: true,
          },
        })
      );

      setLineItemRemoveDisabled(true);
      setIsLineItemRemoved(false);
      return;
    }

    dispatch(setAlert({ ...planDetailsAlert, message: removeLineItemErrorMessage }));
  };

  const renderAlert = () => {
    dispatch(setAlert({
      id: AlertIds.NoPAHAccountsAlert,
      type: AlertTypes.Info,
      message: "There are no open accounts for this patient.",
      dismissable: true
    }));
  };

  function onChangeRadio(value: boolean) {
    setAdHocRadioOption(value);
  }

  const displayPlanAddControls = () => {
    return (
      <>
        <div className={`${screenDisabledClasses}`}>
          <div className='bills-container-facility bills-container-adhocRadio'>
            <Radio
              label='Plan AdHoc Payment'
              value={"true"}
              checked={adHocRadioOption}
              onChange={(e) => onChangeRadio(true)}
            />
            <Radio
              label='Plan Line Items(s) AdHoc Payment'
              value={"false"}
              checked={!adHocRadioOption}
              onChange={(e) => onChangeRadio(false)}
              className='bills-container-adhocRadioMargin'
            />
          </div>
          <div className="bills-buttons">
            <EDS_Button
              buttonText={'Remove from Plan'}
              disabled={wizard.isActive() || !lineItemIsRemovableCheck}
              onClick={onRemoveLineItemClick}
            />
          </div>
        </div>
      </>
    );
  };

  const displayAddControls = () => {
    return (
      <>
        {addRowEnabled ?
          <>
            <EDS_Button
              buttonText={'Add Account'}
              modifiers={'eds-button eds-button.basic'}
              disabled={wizard.isActive() || fetchingEncounters}
              onClick={onAddPayment}
            />
            {!isGl && allowAddToPlan ?
              <>
                <EDS_Button
                  modifiers={'eds-button eds-button.basic'}
                  buttonText='Add to Plan'
                  disabled={!active}
                  onClick={() => setIsAddToPlanModalOpen(true)}
                />
                <PaymentPlansSelectionModal
                  isOpen={isAddToPlanModalOpen}
                  onClose={() => setIsAddToPlanModalOpen(false)}
                  organization={selectedOrganization}
                  paymentPlans={filteredPaymentPlanRecords}
                  onSubmit={onAddPlanClick}
                />
              </>
              : null
            }
          </> : null
        }
        {isGl || isPayment || !addRowEnabled ? null :
          <>
            <EDS_Button
              buttonText={'Adhoc Payment'}
              disabled={wizard.isActive() || !planWithAmount || lineItemPaymentAmountCheck}
              onClick={onAddhockPaymentClick}
            />
          </>
        }
      </>
    );
  };

  const displayLineItems = () => {
    if (addRowEnabled) {
      return (
        <div className="lineitems-container">
          {fetchingEncounters ? <LoadingProgress /> : listOfItems}
        </div>
      )
    }
  };

  const onRemoveLineItemClick = useCallback(() => {
    openLineItemRemovedPopup();
  }, [paymentPlan, isLineItemRemoveDisabled]);

  const lineItemPaymentAmountCheck = planWithAmount?.lineItems?.find(x => {
    const addhockLineItem = addhockLineItems?.find(al => al.accountNumber === x.accountNumber);
    return addhockLineItem && x.amount < (addhockLineItem.amount ?? 0);
  })

  let lineItemsRemovable = false;
  if (paymentPlansSelected.length === 1) {
    let planLineItemsSelected = paymentPlan?.lineItems?.filter(lineItem => lineItem.isActive);
    let selectedNotRemovable = planLineItemsSelected?.find(lineItem => !lineItem.isRemovable);
    lineItemsRemovable = !!planLineItemsSelected?.length && !selectedNotRemovable;
  }

  const lineItemIsRemovableCheck = lineItemsRemovable && havePermission(UserPermissions.EditPaymentPlan, organizationPath);

  return (
    <>
      <div className="bills-container">
        <div className="bills-container-header">
          <div className="bills-container-facility">
            {organizationName}
          </div>
          <div className="bills-buttons">
            {isLineItemRemoved &&
              <Popup
                type={EnumPopupType.warning}
                title='Are you sure you want to remove this record?'
                text='This action cannot be undone.'
                footer={popupFooterRemoveLineItem}
                onClose={closeLineItemRemovedPopup}
                onSubmit={removeLineItem}
              />
            }
            {isPayment && (distributionOrder !== DistributionOrder.None && !isGl) ?
              <>
                <EDS_TextBox
                  label={''}
                  name={'distributeAmount'}
                  value={distributionAmount}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const value = e.target.value;
                    setDistributionAmount(value)
                  }}
                  placeHolder={'Enter amount...'}
                />
                <button
                  className="eds-button eds-button.basic"
                  onClick={onDistributeAmount}
                  aria-label={'distributeAmount'}
                >
                  <Distribute id={'distribute-icon'} />
                  <span className="eds-button_#label">Distribute</span>
                </button>
              </> : null
            }
            {shouldDisplayAddControlls(isPayment, fetchingEncounters) ? displayAddControls() : null}
          </div>
        </div>
        {!isPayment && !isGl ? displayPlanAddControls() : null}
        {displayLineItems()}
      </div>
      {/* Dashboard footer: */}
    </>
  );
}

function shouldDisplayAddControlls(isPayment?: boolean, fetchingEncounters?: boolean){
  return isPayment && !fetchingEncounters;
}
