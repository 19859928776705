import { useState } from "react";
import { Checkbox, Input } from '@EHDS/core';
import { ReactComponent as Edit } from 'assets/svgs/admin/paymentPanel/edit.svg';
import MultiSelect, { MultiSelectOption } from "components/select/MultiSelect";
import DropDownEditor from "./DropDownEditor";
import RegexEditor from "./RegexEditor";
import { shouldHideCheckbox, shouldHideCheckImageOrLabel, shouldHideDropdown, shouldHideHidden, shouldHideTextInput } from "utils/Utils";
import { ComponentType } from 'models/metaData/MetaDataEnums'
import { LineItem } from "models/LineItem";
import { Attribute } from "models/metaData/MetaData";
import { PaymentPanelFieldType } from "models/enums/EnumPaymentPanels";

export interface LineItemSelectorProps {
  openField?: keyof LineItem,
  showField?: boolean,
  loadFromOptions: MultiSelectOption[],
  saveAsOptions: MultiSelectOption[],
  fieldTypeOptions: MultiSelectOption[],
  fieldTypeOnAddAccountOptions: MultiSelectOption[],
  currentAttribute?: Attribute,
  newCustomAttributeName?: string,
  setAttributeProperty: (attributeName: keyof LineItem, name: keyof Attribute, value: string | Attribute['listOptions'] | boolean | undefined, newCustomAttributeName?: string) => void
}

const formats : Record<string, string> = {
  'Date (mm/dd/yyyy)': 'mm/dd/yyyy',
  'Currency ($0.00, -$0.00)' : '$0.00',
  'Currency No Placeholders ($.00, -$.00)': '$.00',
  'Decimal (0.00, -0.00)': '0.00',
  'Decimal No Placeholders (.00, -.00)': '.00',
  'Percentage' : '%'
}

function getFormatOptions(formats : Record<string, string>){
  let formatOptions = [];
  for (const key of Object.keys(formats)) {
    formatOptions.push({label: key, value: formats[key]});
  }
  return formatOptions;
}

export function LineItemSelector(props: LineItemSelectorProps) {
  const { openField, newCustomAttributeName, showField, loadFromOptions, saveAsOptions, fieldTypeOptions, fieldTypeOnAddAccountOptions, currentAttribute, setAttributeProperty } = props;
  const [formatOptions] = useState<MultiSelectOption[]>(getFormatOptions(formats));
  const [doShowDropdownEditor, setDoShowDropdownEditor] = useState(false);
  const [doShowRegExEditor, setDoShowRegExEditor] = useState(false);
  const [dropDownNameLabel, setDropDownNameLabel] = useState("");
  const [dropDownItems, setDropDownItems] = useState(Array());

  function shouldShow(field: string, component: any) {
    const selectedFieldType = currentAttribute?.fieldTypeOnAddAccount;
    switch (selectedFieldType) {
      case PaymentPanelFieldType.CheckBox:
        return getElementorDefault(!shouldHideCheckbox(field), component);
      case PaymentPanelFieldType.CheckImage:
      case PaymentPanelFieldType.Label:
        return getElementorDefault(!shouldHideCheckImageOrLabel(field), component);
      case PaymentPanelFieldType.DropDown:
        return getElementorDefault(!shouldHideDropdown(field), component);
      case PaymentPanelFieldType.Hidden:
        return getElementorDefault(!shouldHideHidden(field), component);
      case PaymentPanelFieldType.TextInput:
        return getElementorDefault(!shouldHideTextInput(field), component);
      default:
        return component;
    }
  }
  
  function showRequiredComponent() {
    const defaultFields = ['firstName', 'lastName', 'accountNumber'];
    if(defaultFields.includes(currentAttribute?.saveAs as string)) {
      return (
        shouldShow("required", 
        <Checkbox label="Required" checked={true} 
        />)
      )
    }
    return (
      shouldShow("required", 
      <Checkbox label="Required" checked={currentAttribute?.required} 
        onChange={(e) => {
          const checked = e.target.checked;
          openField && setAttributeProperty(openField, 'required', checked, newCustomAttributeName)}
        }
      />)
    )
  }

  function getElementorDefault(hide?: boolean, component?: any){
    return hide ? component : null;
  }

  function processOptionsEditor(){
    setDoShowDropdownEditor(false);
  }

  function createListOptions(items:any[])
  {
    let listOptions = items.map((x) => { return {displayValue : x.label, savedValue: x.value, order: 0}});
    return listOptions;
  }

  function setListOptionValues(items:any[])
  {
    let dropDownItems = items.map((x) => { return {label : x.displayValue.toString(), value: x.savedValue.toString()}});
    setDropDownItems(dropDownItems);
  }

  function buildOptionsString(items:any[])
  {
    let labels = items.map(x => x.displayValue);
    return labels.join();
  }

  function setRegex(formula:string)
  {
    setDoShowRegExEditor(false);
  }

  function processRegexEditor(){
    setDoShowRegExEditor(false);
  }

  function showOptionsEditor(dropDownNameProp?: string){
    const dropDownNameLabel = dropDownNameProp || "";
    setDoShowDropdownEditor(true);
    setDropDownNameLabel(dropDownNameLabel);  
  }

  function displayDropDownEditor() {
    if (doShowDropdownEditor) {
      return (
        <div>
          <DropDownEditor close={processOptionsEditor} onNext={(items:any[])=>{
              const listOptions = createListOptions(items || []);
              setListOptionValues(listOptions);
              setDoShowDropdownEditor(false);
              openField && setAttributeProperty(openField, 'listOptions', listOptions, newCustomAttributeName);
            }} 
            dropDownNameProp={dropDownNameLabel} items={dropDownItems}></DropDownEditor>
        </div>
     )
    }
  }
  
  function displayRegExEditor() {
    if (doShowRegExEditor) {
      return (
        <div>
          <RegexEditor close={processRegexEditor} onNext={(formula?:string)=>{
            const selectedFormula = formula ?? "";
              setRegex(selectedFormula);
              openField && setAttributeProperty(openField, 'regex', selectedFormula, newCustomAttributeName);
            }} 
            formula={currentAttribute?.regex ?? ""}></RegexEditor>
        </div>
     )
    }
  }

  function onChangeFieldTypeOnAddAccount(selected: MultiSelectOption | null){
    switch(selected?.value){
      case PaymentPanelFieldType.Calendar: {
        openField && setAttributeProperty(openField, 'format', 'mm/dd/yyyy', newCustomAttributeName);
        break;
      }
      case PaymentPanelFieldType.Label:
      case PaymentPanelFieldType.Hidden: {
        openField && setAttributeProperty(openField, 'required', false, newCustomAttributeName);
        break;
      }
    }

    openField && setAttributeProperty(openField, 'fieldTypeOnAddAccount', selected?.value, newCustomAttributeName);
  }

  return <>
      <div className={"row"}>
      {showField ?
            <MultiSelect label={"Load From"}
              name={"loadFrom"}
              options={loadFromOptions}
              onChange={(selected) => {
                openField && setAttributeProperty(openField, 'loadFrom', selected?.value, newCustomAttributeName);
              }}
              values={loadFromOptions.find(o => o.value === currentAttribute?.loadFrom) || null}
              multiple={false}
              disableClearable={false}
              disableCloseOnSelect={false}
            />:null}
            
            <MultiSelect label={"Save As"}
              name={"saveAs"}
              options={saveAsOptions}
              onChange={(selected) => {
                openField && setAttributeProperty(openField, 'saveAs', selected?.value, newCustomAttributeName);
              }}
              values={saveAsOptions.find(o => o.value === currentAttribute?.saveAs) || null}
              multiple={false} 
              disableClearable={false}
              disableCloseOnSelect={false}/>
            <MultiSelect 
              label={showField ? "Field Type after Submit" : "Field Type"} 
              name={"fieldType"}
              options={fieldTypeOptions}
              onChange={(selected) => {
                if(selected?.value === ComponentType.Calendar){
                  openField && setAttributeProperty(openField, 'format', 'mm/dd/yyyy', newCustomAttributeName);
                }
                openField && setAttributeProperty(openField, 'fieldType', selected?.value, newCustomAttributeName);
              }}
              values={fieldTypeOptions.find(o => o.value === currentAttribute?.fieldType) || null}
              disableClearable={false}
              disableCloseOnSelect={false}
              multiple={false} /> 
            {showField ?
              <MultiSelect 
                label={"Field Type On Add Account"} 
                name={"fieldTypeOnAddAccount"}
                options={fieldTypeOnAddAccountOptions}
                onChange={onChangeFieldTypeOnAddAccount}
                values={fieldTypeOnAddAccountOptions.find(o => o.value === currentAttribute?.fieldTypeOnAddAccount) || null}
                disableClearable={false}
                disableCloseOnSelect={false}
                multiple={false} /> 
            :null
        }
      </div>

      {showField ? shouldShow("list-options", 
        <div className={"row"}> 
          <Input label="List Options" placeholder="Click here to edit the options."
            onFocus={()=> {
                setListOptionValues(currentAttribute?.listOptions || []);
                showOptionsEditor(currentAttribute?.label);
              }
            } 
            value={buildOptionsString(currentAttribute?.listOptions || [])} />
        </div>) 
        : null
      }
        
        <div className={"row"}>
            {shouldShow("label", <Input label="Label"
              name="label"
              onChange={(e) => {
                const { value } = e.target;
                openField && setAttributeProperty(openField, 'label', value, newCustomAttributeName);
              }}
              value={currentAttribute?.label}
            />)
          }
            {shouldShow("format", 
              <MultiSelect 
                label={"Format"}
                name={"format"}
                options={formatOptions}
                onChange={(selected) => {                 
                  openField && setAttributeProperty(openField, 'format', selected?.value, newCustomAttributeName);                 
                }}
                values={formatOptions.find(o => o.value === currentAttribute?.format) || null}
                disableClearable={false}
                disableCloseOnSelect={false}
                multiple={false} />)
              }
            {shouldShow("max-length", <Input label="Max Length"
              onChange={(e) => {
                const { value } = e.target;
                openField && setAttributeProperty(openField, 'maxLength', value, newCustomAttributeName);
              }}
              value={currentAttribute?.maxLength?.toString() ?? ""}
            />)}
            {!showField ? <Input label="Placeholder Text" 
              onChange={(e) => {
                const { value } = e.target;
                openField && setAttributeProperty(openField, 'placeholderText', value);
              }}
              value={currentAttribute?.placeholderText ?? ""}
            /> : null}
            
            {
              showField ?
                <>
                {shouldShow("regex", 
                  <Input label="RegEx" 
                    onFocus={
                      ()=>setDoShowRegExEditor(true)
                    }
                    value={currentAttribute?.regex ?? ""} 
                  />)}
                {shouldShow("regex-test-data", <Input label="RegEx Test Data" />)}
                </>
              : null
            }
            
          </div>
          {
            showField ?
              <div className={"row row-align"}>
                {shouldShow("validation-error-message", 
                  <Input label="Validation Error Message"
                  onChange={(e) => {
                    const { value } = e.target;
                    openField && setAttributeProperty(openField, 'validationErrorMessage', value, newCustomAttributeName);
                  }}
                  value={currentAttribute?.validationErrorMessage} 
                />)}
                {shouldShow("visible", 
                  <Checkbox label="Show on Live Page" checked={currentAttribute?.visible} 
                    onChange={(e) => {
                      const checked  = e.target.checked;
                      openField && setAttributeProperty(openField, 'visible', checked, newCustomAttributeName)}
                    }
                />)}
                {showRequiredComponent()}
                {<a href="#" style ={{paddingBottom: 8}}><Edit /> Edit Css</a>}             
               </div> 
            : 
            <div style ={{marginLeft: '14px'}}> 
              {showRequiredComponent()}
            </div>
          }
          <div>          
            {displayDropDownEditor()}
          </div>
          <div>          
            {displayRegExEditor()}
          </div>
    </>
}