import { Attribute } from 'models/metaData/MetaData';
import { ReactComponent as CheckIcon } from 'assets/svgs/icon-check-v2.svg';
import {
  getLabel
} from 'utils/metadata/MetaDataUtils';
import { LineItem } from 'models/LineItem';
import { useEffect } from 'react';

export function CheckImage(props: {
  attribute: Attribute;
  lineItem: LineItem;
  onChange?: (value: any) => void;
}) {
  const { attribute, lineItem, onChange } = props;
  const label = getLabel(attribute);
  const loadFrom = attribute?.loadFrom ? attribute.loadFrom : '';
  const value = lineItem[loadFrom as keyof LineItem];
  let visibleCheck = false;

  switch (typeof value) {
    case 'boolean':
    case 'number':
      visibleCheck = value ? true : false;
      break;
    case 'string':
      visibleCheck =
        String(value)?.toLowerCase() !== 'false' && String(value)?.length > 0
          ? true
          : false;
  }
  
  useEffect(() => {
    onChange && onChange(visibleCheck ? 'True' : 'False');
  }, [visibleCheck]);

  return (
    <>
      <div className={'custom-eds-label'}>
        {label}
      </div>
      {visibleCheck ? 
        <CheckIcon id='check-icon'/> 
        : <></>
      }      
    </>
  );   
}
