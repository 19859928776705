import React from 'react';
import { displayAmount, displayBalanceAmount } from 'utils/Utils';
import { getShowDiscount, getTotalDiscount, getTotalLineItemAmount, getTotalSum } from '../../paymentPlan/useTotal';
import { useTypedSelector } from '../../../app/rootReducer';
import { PlanOperations } from '../../../models/metaData/MetaDataEnums';

export default ({
  total,
  amount,
  discount,
  isGl
}: {
  total?: number;
  amount?: number;
  discount?: number;
  isGl?: boolean;
}) => {
  const editingOperations = [PlanOperations.AddToPlan, PlanOperations.EditPlan, PlanOperations.EditPlanDetails, PlanOperations.EditPlanTerms];
  const isPaymentType = useTypedSelector(s => s.paymentDashboard.isPaymentType);
  const operation = useTypedSelector(s => s.paymentPlanInfo?.operation);
  const isEditing = editingOperations.some(x=>x==operation);

  const formatValue = (string: string) => {
    const balanceValue = displayBalanceAmount(string);
    return (
      <>
        <span className="value-payment-amount">{balanceValue.firstPart}</span>
        {balanceValue.lastPart}
      </>
    );
  };
  const paymentPlansRecords = useTypedSelector(s => s.paymentPlanInfo?.records) || [];
  const paymentPlansSelectedRecords = useTypedSelector(s => s.paymentPlanInfo?.selected) || [];

  const values = Object.values(useTypedSelector(s => s.paymentDashboard?.values || []));
  const lineItemsList = values?.map((mapped) => { return mapped.lineItems }) || [];
  const facilitiesLineItems = lineItemsList?.reduce((totalLineItems, lineItemList) => totalLineItems.concat(lineItemList), []);

  const totalLineItemAmount = getTotalLineItemAmount(facilitiesLineItems, isGl, !isPaymentType);
  const discountValue = discount || discount == 0 ? discount : (amount ?? 0) - (total ?? 0);
  const totalValue = !isEditing ? totalLineItemAmount : total;

  const isCreatePlan = operation === PlanOperations.CreatePlan;

  const totalPaymentAmount = getTotalSum(facilitiesLineItems, [], "amount", [], !isPaymentType, isCreatePlan, false, isGl);
  const paymentPlanActiveTotalAmount = getTotalSum(facilitiesLineItems, paymentPlansRecords, "amount", paymentPlansSelectedRecords, !isPaymentType, isCreatePlan, isEditing, isGl);
  const paymentPlanActiveTotalBalance = getTotalSum(facilitiesLineItems, paymentPlansRecords, "balance", paymentPlansSelectedRecords, !isPaymentType, isCreatePlan, isEditing, isGl);
  
  const paymentPlanActiveTotalDiscount = getTotalDiscount([], paymentPlansRecords, !isPaymentType);
  
  const discountAmount = isPaymentType ? discountValue : paymentPlanActiveTotalDiscount;
  const billedAmount = isPaymentType ? totalPaymentAmount : paymentPlanActiveTotalBalance;
  const paymentAmount = isPaymentType ? totalValue : paymentPlanActiveTotalAmount;

  const showDiscount = getShowDiscount(paymentAmount, discountValue);
  return (
    <React.Fragment>
      <div className="flex-row">
        <div className="row-item row-item-size-double mb-1">
          <p className="payment-amount">
            Billed Amount: <span>${displayAmount(billedAmount)}</span>
          </p>
        </div>
      </div>
      { showDiscount ? (
        <div className="flex-row">
          <div className="row-item row-item-size-double mb-1">
            <p className="payment-amount">
              Discount Amount: <span>-${displayAmount(discountAmount)}</span>
            </p>
          </div>
        </div>
      ) : null}
      <div className="flex-row">
        <div className="row-item row-item-size-double">
          <div className="payment-amount">
            Payment Amount:{' '}
            <span className="payment-total">
              ${formatValue(displayAmount(paymentAmount))}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};