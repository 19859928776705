import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { useGetPaymentConfiguration } from "features/singlePayment/hooks/useGetPaymentConfiguration";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import { LineItem } from "../../../models/LineItem";
import { Attribute, MetaData } from "../../../models/metaData/MetaData";
import { DataType, EntityType } from "../../../models/metaData/MetaDataEnums";
import { displayAmount, formatDate, IsRefunded, nameof } from "../../../utils/Utils";
import { TransactionDetailsModel } from "models/TransactionDetailsModel";
import { PaymentPlanModel } from "models";
import EnumTransactionType from "models/enums/EnumTransactionType";
import { setIsGl } from "../../paymentDashboard/PaymentDashboardReducer";

export const PAYMENT_DUE = 'Payment Due';

export enum Source {
  PaymentPlan = 1,
  Transaction = 2
}
export function GetAmountLabel(transactionType:any){
  if (IsRefunded(transactionType)){
    return 'Refunded Amount';
  }

  return 'Payment Amount';
}
export function useAccountDetails(data?: TransactionDetailsModel | PaymentPlanModel) {
  const dispatch = useDispatch();
  const { getFacilityByDepartment } = useOrganizations();
  const facility = getFacilityByDepartment(data?.organization?.path);
  const facilityPath = facility?.path ?? '';
  const lineItems = data?.lineItems ?? [];
    
  const configuration = useTypedSelector(
    s => s.paymentDashboard?.configurations[facilityPath]
  );
  const { getConfiguration } = useGetPaymentConfiguration(facilityPath);
  const isProcessing = useTypedSelector(s => s.services?.calls.GetPaymentConfiguration?.isProcessing);
  const isGl = getIsGl(data);
    
  useEffect(() => {
    if (isGl && !configuration && facilityPath && !isProcessing) {
      getConfiguration();
    }
  }, [isGl, facilityPath]);

  useEffect(() => {    
      dispatch(setIsGl({organizationPath: facilityPath,isGl}));
  }, [data, configuration]);
  
  return { isGl, lineItems };
}

export default useAccountDetails;

export function getIsTotalAmountAttribute(attribute?: Attribute, transactionType?: EnumTransactionType) {
  return attribute?.label === PAYMENT_DUE || 
    attribute?.label === GetAmountLabel(transactionType);
}

export function getIsGl(data?: TransactionDetailsModel | PaymentPlanModel) {
  if (!data) return false;

  let value;
  if ('isGl' in data) {
    value = data.isGl;
  }
  if ('isGL' in data) {
    value = data.isGL
  }
  return !!value;
}

export function getAttributes(lineItemsMetaData?: { metaData: MetaData}) {
  const attributes = lineItemsMetaData?.metaData?.entities?.find(
    entity => entity.name == EntityType.LineItem
  )?.attributes ?? []

  return attributes?.filter(attribute => !['isEditing', 'isActive']?.includes(attribute.name));
}

export function getTotalAmountAttribute(isGl?: boolean, transactionType?: EnumTransactionType) {
  return {
    name: nameof<LineItem>(isGl ? 'amount' : 'balance'),
    label: isGl ? GetAmountLabel(transactionType) : PAYMENT_DUE,
    dataType: DataType.Money,
    visible:true
  };
}

export function getCellValue(
  attribute: Attribute, 
  value: any, 
  currencySymbol: string = '$', 
  defaultOnParse = '-'
) {
  if (!value) return '';

  if (attribute.dataType === DataType.Date) {
    value = formatDate(moment(value))
  }
  if (attribute.dataType === DataType.Money) {
    const isNumber = !isNaN(parseFloat(value));
    value = isNumber ? `${currencySymbol}${displayAmount(value)}` : undefined;
  }
  return value || defaultOnParse;
}