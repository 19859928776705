import { Label } from 'reactstrap';
import { EDS_Select, EDS_Checkbox } from '@EH/eh.eds.react';
import classes from 'features/singlePayment/singlePaymentAuthorization.module.scss'
import { ApiTenderTypeEnum } from 'utils/Utils';

export function TenderCart(props: {
  name?: string;
  label?: string;
  value?: string | ApiTenderTypeEnum;
  options?: Object;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: () => void;
  showSaveForFutureUse?: boolean;
  saveForFutureUseStatus?: boolean;
  setSaveForFutureUseStatus?: (forFutureUseStatus: boolean) => void;
}) {
  const {
    name,
    label,
    value,
    options,
    error,
    onChange,
    onBlur,
    showSaveForFutureUse,
    saveForFutureUseStatus,
    setSaveForFutureUseStatus,
  } = props;

  return (
    <>
      <div className="flex-row">
        <div className="full-row">
          <EDS_Select
            name={name}
            label={label}
            modifiers={`row-item row-item-size-double ${error ? 'invalid-field' : ''}`}
            options={options}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          {error && <Label className={`error-message-required`}>{error}</Label>}
        </div>
      </div>
      {showSaveForFutureUse && <div className="flex-row">
      <EDS_Checkbox
          modifiers="eds-checkbox-save-to-wallet"
          name="saveForFutureUseCheckbox"
          label="Save device for future use"
          checked={saveForFutureUseStatus}
          data-testid="saveForFutureUseCheckbox"
          onChange={() => setSaveForFutureUseStatus && setSaveForFutureUseStatus(!saveForFutureUseStatus)}
        />
      </div>}
    </>
  )
}