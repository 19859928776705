import { useRef, useState } from 'react';
import { useTypedSelector } from 'app/rootReducer';
import PaymentPlanModel from 'models/PaymentPlan';
import { useReactToPrint } from 'features/print/ReactToPrint';
import { ApiTenderTypeEnum, checkReceivedHasValues, template } from 'utils/Utils';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'typeface-roboto';
import 'assets/styles/components/_authorizationModal.scss';
import { EnumTransactionTenderType } from 'models/enums/EnumTransactionTenderType';
import CommonAuthorizationConfirmation from 'features/commonAuthorizationConfirmation/CommonAuthorizationConfirmation';

export default (props: {
  paymentPlan?: PaymentPlanModel;
  paymentOnFileTenderType?: number | string
  isOpen: boolean;
  close: () => void;
  onNext: () => void;
}) => {
  const { paymentPlan, paymentOnFileTenderType, isOpen, close, onNext } = props;
  const openClass = isOpen ? 'eds-modal.open' : '';
  
  const [printing, setPrinting] = useState(false);
  const componentRef = useRef() as any;

  const organizationPath = paymentPlan?.organization?.path ?? '';
  const configuration = useTypedSelector(s => {
    if (organizationPath != undefined) {
      const conf = s.paymentPlanInfo?.configurations[organizationPath];
      return conf?.configuration;
    }
  });
  
  const tenderType = paymentPlan?.tender ? paymentPlan.tender.type : 'SavedOnFile';

  const tenderTypeToTemplate = {
  [ApiTenderTypeEnum.SavedOnFile]: checkReceivedHasValues(paymentOnFileTenderType == EnumTransactionTenderType.Card, 
    configuration?.templates.CardAuthorizationText, 
    configuration?.templates.CheckAuthorizationText),
  [ApiTenderTypeEnum.CardDevice]: checkReceivedHasValues(paymentPlan?.isGl, 
    configuration?.templates.GLCardAuthorizationText, 
    configuration?.templates.CardAuthorizationText),
  [ApiTenderTypeEnum.CardManual]: checkReceivedHasValues(paymentPlan?.isGl, 
    configuration?.templates.GLCardAuthorizationText, 
    configuration?.templates.CardAuthorizationText),
  [ApiTenderTypeEnum.ECheck]: checkReceivedHasValues(paymentPlan?.isGl, 
    configuration?.templates.GLCheckAuthorizationText, 
    configuration?.templates.CheckAuthorizationText),
  [ApiTenderTypeEnum.PaperCheckAsECheck]: checkReceivedHasValues(paymentPlan?.isGl, 
    configuration?.templates.GLCheckAuthorizationText, 
    configuration?.templates.CheckAuthorizationText),
};

let content = tenderTypeToTemplate[tenderType as keyof typeof tenderTypeToTemplate] || '';

const paragraphs = (
  <>
    {content.split('\n').map((paragraph: any) => (paragraph ? <p>{paragraph}</p> : ''))}
  </>
)

  const openPrintPreview = useReactToPrint({
    onBeforeGetContent: () => setPrinting(true),
    content: () => componentRef.current,
    onAfterPrint: () => setPrinting(false),
  });

  return (
    <>
    <CommonAuthorizationConfirmation
     openClass={openClass}
     close={close}
     paragraphs={paragraphs}
     onNext={onNext}
     printing={printing}
     componentRef={componentRef}
     content={content}
     openPrintPreview={openPrintPreview}
     customStyleButton={'authModalCloseButton'}
    />
    </>
  );
};
