import { useEffect, useMemo, useState } from "react";
import OrganizationUserSearch, { useGetUsersOptions } from "../../../../pages/Search/simpleSearch/OrganizationUserSearch";
import usePermissionsState from "../UsersTab/hooks/usePermissionsState";
import OrganizationPermissionsMenu from "./OrganizationPermissionsMenu";
import OrganizationsTree from "./OrganizationsTree";
import { ReactComponent as Clear } from 'assets/svgs/admin/usersAndPermissions/clear.svg';
import { ReactComponent as ViewUsers } from 'assets/svgs/admin/usersAndPermissions/view-users.svg';
import OrganizationsSelector from "../OrganizationsSelector";
import useOrganizationsUsersState, { OrganizationsUsersProps } from "../hooks/useOrganizationsUsersState";
import { useTypedSelector } from "../../../../app/rootReducer";
import { useDispatch } from "react-redux";
import { setOrganizationsAndUsers, setTradingPartner } from "../UsersAndPermissionsReducer";
import { OrganizationLevelDocument } from "../../../../models/OrganizationLevelDocument";
import OrganizationLevelTypes from "../../../../models/enums/OrganizationLevelTypes";
import { useOrganizations } from "../../../organizations/hooks/useOrganizations";
import { useLoggedUserPermissions } from "app/hooks/useLoggedUserPermissions";
import UserPermissions from "models/enums/UserPermissions";
import { useOrganizationFormState } from "./hooks/useOrganizationFormState";

interface OrganizationFormProps extends OrganizationsUsersProps { setTab: (index: number) => void };

export default function OrganizationForm(props: OrganizationFormProps) {
  const { setTab, organizations, useGetTradingPartners } = props;
  const tradingPartners = useGetTradingPartners();
  const { users } = useGetUsersOptions();
  const { retrievePermissions, selectedItems, handleChange, clearSelection } = usePermissionsState();
  const dispatch = useDispatch();
  const { getTradingPartnerByFacility, getFacilityTradingPartnerPath, getFacilityByDepartment, organizations: allOrganizations } = useOrganizations();
  const { hasPermissionOrSuperAdmin } = useLoggedUserPermissions();
  const state = useTypedSelector(s => s.usersAndPermissions);
  const tradingPartner = useTypedSelector(s => s.usersAndPermissions.tradingPartner);
  const filterSelected = [tradingPartner, ...state.facilities, ...state.departments];
  const loggedUser = useTypedSelector(s => s.loginInfo?.loggedUser);
  const sspUserPermission = hasPermissionOrSuperAdmin(UserPermissions.SelfServicePortal);
  const isAdmin = hasPermissionOrSuperAdmin(UserPermissions.FinancialAdministation);
  const loggedUserOrganizationLevel = loggedUser?.organizationLevels?.length ? loggedUser?.organizationLevels[0] : null;
  
  let organizationsToList = organizations;
  let tradingPartnersToList = tradingPartners.filter(o => o.path === tradingPartner?.value);
  if (filterSelected.length) {
    organizationsToList = allOrganizations.filter(o => filterSelected.find(f => f && (o.path === f.value || f.value.startsWith(o.path))));
  }
  const organizationsUsersState = useOrganizationsUsersState();
  const { clearState } = organizationsUsersState;
  const facilityList = organizations?.filter(o =>
    o.path.includes(tradingPartner?.value ?? '') && o.organizationLevelType === OrganizationLevelTypes.Facility
  );

  useEffect(() => {
    retrievePermissions();
  }, []);

  useMemo(() => {
    clearState();
  }, []);

  useEffect(() => {
    if(!isAdmin && sspUserPermission) {
      const path = getFacilityTradingPartnerPath(loggedUserOrganizationLevel?.organizationLevel_Path ?? '');
      const tradingPartner = tradingPartners.filter(tP => tP.path === path)[0] ?? '';
      dispatch(setTradingPartner({label: tradingPartner.name ?? '', value: tradingPartner.path ?? ''}));
    }
  }, [sspUserPermission])

  const [selected, setSelected] = useOrganizationFormState();

  const {
    facilityNames,
    tradingPartnerNames,
    departmentNames
  } = getOrganizationNames(selected, organizationsToList, getTradingPartnerByFacility, getFacilityByDepartment);

  const showCustomOrganizationSelector = () => {
    if(isAdmin || !sspUserPermission) {
      return (
        <OrganizationsSelector
          setSelectedOrganization={(selected) => dispatch(setTradingPartner(selected))}
          selectedOrganization={tradingPartner}
        />
      )
    }
  }

  return <div>
    <div className="organization-search extra-wide-inputs">
      {showCustomOrganizationSelector()}
      <div>
        <OrganizationUserSearch
          {...{ facilities: facilityList, users }}
          state={state}
          setState={organizationUserState => {
            dispatch(setOrganizationsAndUsers({ ...state, ...organizationUserState }));
          }}
          initialize={false}
          showError={false}
          noUser={true}
        />
      </div>
    </div>
    <div className="user-form">
      <div className="user-list">
        <div className="links-header">
          <div className="links-title-container">
            <h2 className="links-title">Organization</h2>
          </div>
          <div className="links-buttons">
            <span onClick={() => { setTab(1) }}> <ViewUsers /> View users </span>
            <span onClick={() => {
              setSelected([]);
            }}> <Clear /> Clear </span>
          </div>
        </div>
        <div className="organizations-tree-container">{
          filterSelected.length || tradingPartner  ? <OrganizationsTree {...{
            tradingPartners: tradingPartnersToList,
            organizations: organizationsToList,
            selected,
            setSelected
          }} /> : null}
        </div>
      </div>
      <div className="permissions-menu">
        <OrganizationPermissionsMenu
          organizationPaths={getOrganizationPaths(selected,organizationsToList)}
          selectedItems={selectedItems}
          handleChange={handleChange}
          clearSelection={clearSelection}
          noUser={true}
          userInfo={{
            organization: tradingPartnerNames.join(", "),
            facility: facilityNames.join(", "),
            department: departmentNames.join(", ")
          }}
        />
      </div>
    </div>
  </div>
}

export function getOrganizationNames(
  selected: string[],
  organizationsToList: OrganizationLevelDocument[],
  getTradingPartner: (facilityPath?: string) => OrganizationLevelDocument | undefined,
  getFacility: (departmentPath?: string) => OrganizationLevelDocument | undefined
) {
  const tradingPartners = new Set<string | undefined>();
  const facilities = new Set<string | undefined>();
  const departments = new Set<string | undefined>();
  selected.forEach(id => {
    const organization = organizationsToList.find(o => o.id == id);
    if (organization?.organizationLevelType === OrganizationLevelTypes.TradingPartner) {
      tradingPartners.add(organization.name);
    }
    if (organization?.organizationLevelType === OrganizationLevelTypes.Facility) {
      tradingPartners.add(getTradingPartner(organization.path)?.name);
      facilities.add(organization.name);
    }
    if (organization?.organizationLevelType === OrganizationLevelTypes.Department) {
      const facility = getFacility(organization?.path);
      tradingPartners.add(getTradingPartner(facility?.path)?.name);
      facilities.add(facility?.name);
      departments.add(organization.name)
    }
})
  return {
    tradingPartnerNames: Array.from(tradingPartners),
    facilityNames: Array.from(facilities),
    departmentNames: Array.from(departments),
  }
}

export function getOrganizationPaths(selected: string[], organizationsToList: OrganizationLevelDocument[]){
 let selectedOrganizationIds = selected;
 selected.forEach.call(selected,id=>
    {
      const organization = organizationsToList.find(o => o.id == id);
      if(organization?.path!==null && String(organization?.path).match(/\|/g)!==null && String(organization?.path).match(/\|/g)!.length===5)
      {
        let parts = organization?.path.split('|')!;
        parts.splice(parts.length - 1 - 1, 1);      
        let organizationPath = parts.join('|');
        const parentOrganization = organizationsToList.find(o => o.path == organizationPath);
        if (!selectedOrganizationIds.find(x=>x===parentOrganization?.id))
        {
          selectedOrganizationIds.push(parentOrganization?.id!);
        }
      }
    }
  );
  return selectedOrganizationIds;

}
