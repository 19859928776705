import { callHttpApi } from 'services/callHttpApi';

export interface ResponseResult {
  result?: string;
  err?: string;
  errorResponse?: { data: string };
}

export enum ReportType {
  payments = 'Transactions',
  paymentPlans = 'PaymentPlans'
}

export async function CreateExcelFullReportService(data?: any): Promise<any> {
  return callHttpApi<string, {}>(
    'post',
    '/api/v1/Transactions/export',
    data,
    null,
    { 'Content-Type' : 'application/json' },
  );
}

export async function CreateDownloadExcelFullReportLinkService(id: string): Promise<any> {
  return callHttpApi<string, ResponseResult>(
    'get',
    `/api/v1/Transactions/export/${id}`,
  )
}

export async function DownloadExcelFullReportService(string: string, mode: ReportType, date: string): Promise<any> {
  const Authorization = 'Bearer ' + localStorage.getItem('authToken')
  const response = await callHttpApi<string, any>(
    'get',
    string,
    null,
    null,
    {
      Authorization
    },
    'blob',
  )

  const url = window?.URL?.createObjectURL(new Blob([response.result as BlobPart]));
  const link = document?.createElement('a');
  if (link) {
    link.href = url;
    link?.setAttribute('download', `${mode}_Export_${date}.xlsx`);
    document?.body?.appendChild(link);
    link?.click();
  }
}
