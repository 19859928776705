import { useTypedSelector } from "../../../../app/rootReducer";
import { GenericAttribute, GenericValidator } from "../../../../models/metaData/MetaData";
import { DataType, ValidatorType } from "../../../../models/metaData/MetaDataEnums";
import { useGenericGridFormValidator } from "../../../../utils/useGenericGridFormValidator";
import { GenericValidatorProps, required } from "../../../paymentDashboard/Validators";
import { duplicateValidator } from "../../glList/useGlListManagerValidator";
import { EditableExternalAddress } from "./WhitelistReducer";

export const useWhitelistManagerValidator = () => {
  const urlList = useTypedSelector(s => s.whitelistManager.externalAddresses);
  const attributes: GenericAttribute<EditableExternalAddress>[] = [
    { name: "url", dataType: DataType.String, validators: [required(), duplicateValidator(urlList, "url", "id"), urlValidator<EditableExternalAddress>()] },
  ];
  const props = useGenericGridFormValidator(attributes);
  return { ...props };
}

export function urlValidator<T>(): GenericValidator<T> {
  return {
    name: ValidatorType.UrlValidator,
    custom: (props: GenericValidatorProps<T>) => {
      const { value, onFail, onPass, entity } = props;
      if (!entity) return onFail && onFail();

      if (value && validateUrl(value.toString())) return onPass && onPass();

      onFail && onFail();
    },
    message: "Invalid URL"
  }
}

function validateUrl(value: string) {
  const urlRegex = /https?:\/\/(www\.)?[-a-z0-9:%._\+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-a-z0-9():%_\+.~#?&/=]*)/gi;
  return urlRegex.test(value);
}