import axios from 'axios';
import { setTokenInformation } from 'pages/Loginpage/reducers/TokenInfoReducer';
import { setLoggedUser, setLoginInformation } from 'pages/Loginpage/reducers/LoginPageReducer';
import { useDispatch } from 'react-redux';
import { jwtUserDetailDecoder } from 'utils/Utils';
import { useTypedSelector } from '../app/rootReducer';
import { GetAccessToPowerReporting, GetUserDetailService } from './UsersService';
import { useImpersonateUser } from '../utils/useUserUtils';

export const useResponseInterceptor = async () => {
  const dispatch = useDispatch();
  const loginInfo = useTypedSelector(s => s.loginInfo.value);
  const impersonateUser = useImpersonateUser();

  const queryParameters = new URLSearchParams(window.location.search);
  const tokenBeforeDecode = queryParameters.get('token');
  const impersonationId = queryParameters.get('impersonationId');
  const redirectUrl = queryParameters.get('redirectUrl');
  const hasNoPermissions = queryParameters.has('hasNoPermissions');
  localStorage.setItem('redirectUrl', redirectUrl ?? '');


  if (tokenBeforeDecode && !loginInfo.loggedIn) {
    const decodedToken = atob(tokenBeforeDecode);
    let tokenSeparator = '|';
    const returnedToken = decodedToken.substring(
      0,
      decodedToken.lastIndexOf(tokenSeparator) + tokenSeparator.length
    );
    const token = decodedToken.substring(returnedToken.length);
    const trimToken = token
      .replace("'", '')
      .replace('"', '')
      .trim();

    localStorage.setItem('authToken', trimToken);
    localStorage.setItem('refreshToken', trimToken);

    if (trimToken) {
      let user;
      if (localStorage.getItem('authToken') !== null) {
        const partialUserModel = jwtUserDetailDecoder(
          localStorage.getItem('authToken')
        );
        const data = await GetUserDetailService('', '', partialUserModel.realmUserId);
        user = (data.result ?? [])[0];
        localStorage.setItem('name', user?.firstName);
        localStorage.setItem('chipUserId', partialUserModel.chipUserId);
        user && localStorage.setItem('id', user.id);
      }
      dispatch(
        setTokenInformation({
            access_token: trimToken,
            expires_in: 899,
            refresh_token: trimToken,
            token_type: 'bearer',
        })
      );

      dispatch(
        setLoginInformation({
            loggedIn: true,
            userName: '',
            password: '',
            email: '',
            chipUserId: '',
        })
      );

      user && dispatch(
        setLoggedUser(user)
      );
    }
  }

  if (impersonationId) {
    impersonateUser(impersonationId);
  }

  if (hasNoPermissions) {
    window.location.replace(`/no-permissions`)
  }

  if (!localStorage.getItem('authToken')) {
    //for local use of authentication
    //const authURL = 'https://localhost:5000/api/v1/Authentication/send';

    const authURL = window.location.origin + '/api/v1/Authentication/send';
    window.location.replace(`${authURL}?returnUrl=${window.location.href}`);
  }

  axios.interceptors.response.use(
    undefined,
    async (error: { response: { status: number } }) => {
      //for local use of authentication
      //const authURL = 'https://localhost:5000/api/v1/Authentication/send';

      const authURL = window.location.origin + '/api/v1/Authentication/send';
      if (!localStorage.getItem('authToken')) {
        window.location.replace(`${authURL}?returnUrl=${window.location.href}`);
      } else {
        return Promise.reject(error);
      }
    }
  );
};
