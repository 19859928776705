import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/rootReducer';
import { callService } from 'services/ServicesReducer';
import { ProcessRemitFile, RemitConfiguration, RemitConfigurationResponse } from 'models/RemitConfigurationModel';
import { GetRemitConfiguration, ProcessRemit } from 'services/RemitRecriationService';
import { GetRemitConfigurationsQueryParams } from 'services/RemitService';

export interface State {
  err?: string;
  value?: RemitConfiguration;
}

export const initialState: State = {
  err: '',
  value: undefined,
};

const reducerSlice = createSlice({
  name: 'RemitConfiguration',
  initialState,
  reducers: {
      onReceiveRemitConfiguration(
      state,
      action: PayloadAction<RemitConfigurationResponse>
    ) {
      state.value = action.payload.result;
      state.err = action.payload.err;
    },
    clearError(state) {
      state.err = '';
    },
  },
});

export const { 
  onReceiveRemitConfiguration,
  clearError,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getRemitConfiguration(
  remitConfigurationQuery: GetRemitConfigurationsQueryParams,
  onDone?: () => void
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService('RemitConfiguration', async () => {
      await GetRemitConfiguration(remitConfigurationQuery).then(data => {
        dispatch(onReceiveRemitConfiguration(data));
        if (onDone) onDone();
      });
    }))
  };
}

export function processRemit(
  remit: ProcessRemitFile,
  onDone?: (data: RemitConfigurationResponse) => void
): AppThunk {
  return async dispatch => {
    dispatch(callService('ProcessRemit', async () => {
      await ProcessRemit(remit).then(data => {
        if (onDone) onDone(data);
      });
    }))
  };
}
