import { SetStateAction, useEffect, useMemo, useState, useRef, Dispatch } from 'react';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import 'assets/styles/components/_clientConfiguration.scss';
import ConfigurationTemplate from '../../features/admin/clientConfiguration/ConfigurationTemplate';
import 'assets/styles/components/_paymentPlanConfiguration.scss';
import { EDS_Switch, EDS_TextArea, EDS_Select, EDS_Accordion, EDS_TextBox } from '@EH/eh.eds.react';
import { useGetConfiguration } from 'features/paymentPlan/hooks/useGetConfiguration';
import { Popup } from 'components/popup/Popup';
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from 'models/enums/EnumPopupType';
import { restartPaymentPlans, stopPaymentPlans, savePaymentPlanConfigurationService } from 'services/PaymentPlanService';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';
import { setConfiguration, setConfigurationReload, setPaymentPlanTierFields } from 'features/paymentPlan/PaymentPlanReducer';
import { ReactComponent as Sign } from 'assets/svgs/warning.svg';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import ActionConfirmModal from 'features/admin/ActionConfirmModal';
import { enumToMultiSelectOptions, multiSelectOptionsToSelectOptions, formatDateString, nameof } from 'utils/Utils';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridReadyEvent, ValueFormatterParams, ValueSetterParams } from 'ag-grid-community';
import { DistributionOrder, PaymentPlanTiersCalculatedBy, Tier as PaymentPlanTier, PaymentPlanConfiguration as PaymentPlanConfigurationModel, PlansRecord, Terms, DisablePlansRecord } from 'models/PaymentPlanConfiguration';
import { Radio } from '@EHDS/core';
import { HeaderButton } from 'pages/Search/planSearch/paymentPlanDetailsHeader/HeaderButton';
import { ReactComponent as Add } from 'assets/svgs/admin/paymentPlan/icon-add.svg';
import { ReactComponent as Edit } from 'assets/svgs/admin/paymentPlan/icon-edit.svg';
import { ReactComponent as EditDisabled } from 'assets/svgs/admin/paymentPlan/icon-edit-disabled.svg';
import { ReactComponent as Remove } from 'assets/svgs/admin/paymentPlan/icon-remove.svg';
import { ReactComponent as RemoveDisabled } from 'assets/svgs/admin/paymentPlan/icon-remove-disabled.svg';
import moment from 'moment';
import { usePaymentPlanTiersValidator } from "../../features/admin/paymentPlanTiers/usePaymentPlanTiersValidator";
import CustomCellRenderer from "../../features/admin/paymentPlanTiers/AggridCustomComponents/CustomCellRenderer";
import CellSelectEditor from "../../features/admin/paymentPlanTiers/AggridCustomComponents/CellSelectEditor";
import CellTextEditor from "../../features/admin/paymentPlanTiers/AggridCustomComponents/CellTextEditor";
import { errorMessage } from 'pages/Search/searchUtils';
import '../../assets/styles/components/_paymentPlanTiers.scss';

const deviceAlert = {
  id: AlertIds.PaymentPlanConfigurationManagerAlert,
  type: AlertTypes.Error,
  message: '',
};

const planConfigurationAlert = {
  id: AlertIds.PaymentPlanConfigurationManagerAlert,
  type: AlertTypes.Error,
  message: '',
};

const labels = {
  generalSettings: 'GENERAL SETTINGS',
  paymentDistributionOrder: '*PAYMENT DISTRIBUTION ORDER',
  planRelatedClientNotifications: 'PLAN RELATED CLIENT NOTIFICATIONS',
  paymentPlanTerms: 'PAYMENT PLAN TERMS',
  paymentPlanTiers: 'PAYMENT PLAN TIERS'
};

const termsValues = Object.values(Terms);

export default function PaymentPlanConfiguration() {
  const clientConfiguration = useTypedSelector(
    s => s.getClientFacilityConfiguration?.result
  );
  const clientOrganizationPath = clientConfiguration?.organization?.path ?? '';

  const isProcessionGetPaymentPlanConfiguration = useTypedSelector(s =>
    s.services.calls.GetPaymentPlanConfiguration?.isProcessing
  );

  const configuration = useTypedSelector(s =>
    s.paymentPlanInfo?.configurations[clientOrganizationPath]?.configuration
  );

  const loggedUser = useTypedSelector(p => p.loginInfo?.loggedUser);

  const paymentPlanTiers = getAllPaymentPlanTiers(configuration);
  const paymentPlanRef = useRef(paymentPlanTiers);

  const [configurationValues, setConfigurationValues] = useState<PaymentPlanConfigurationModel>(configuration);

  const [showDistributionConfirmationPopup, setShowDistributionConfirmationPopup] = useState(false);
  
  const [targetName, setTargetName] = useState('');

  const [targetValue, setTargetValue] = useState('');
  
  const [stopPlans, setStopPlans] = useState(false);

  const [stopPlansConfirmation, setStopPlansConfirmation] = useState(false);

  const [stopPlansConfirmationPopUp, setStopPlansConfirmationPopUp] = useState(false);

  const [restartPlansConfirmation, setRestartPlansConfirmation] = useState(false);

  const [isAddTierDisabled, setIsAddTierDisabled] = useState(false);

  const [isEditTierDisabled, setIsEditTierDisabled] = useState(true);

  const [isEditingTiers, setIsEditingTiers] = useState(false);

  const [isRemoveTierDisabled, setIsRemoveTierDisabled] = useState(true);

  const [disablePlans, setDisablePlans] = useState(false);

  const [disablePlansConfirmation, setDisablePlansConfirmation] = useState(false);

  const [disableGLPlans, setDisableGLPlans] = useState(false);

  const [disableGLPlansConfirmation, setDisableGLPlansConfirmation] = useState(false);

  const [notes, setNotes] = useState('');

  const [disabledConfiguration, setDisableConfiguration] = useState(false)

  const { getOrganizationByPath } = useOrganizations();

  const clientOrganization = getOrganizationByPath(clientOrganizationPath);

  const { getPlanConfiguration } = useGetConfiguration(clientOrganizationPath);

  const { havePermission } = useLoggedUserPermissions();

  const havePermissionStartStopPlan = havePermission(UserPermissions.StartStopPaymentPlan, clientOrganizationPath);

  const { validate, validateAll, getAttributes, getErrorMessage, errorClass } =  usePaymentPlanTiersValidator();

  function isValidData() {
    const tiersWithId = assignIdToTiers(paymentPlanTiers);
    return tiersWithId.filter((tier) => tier.isEditing).every((tier) => {  
      return validateAll(tier);
    });
  }

  function calculateValues(field : keyof PaymentPlanTier, updated: PaymentPlanTier) {
    if((field == 'maxNumberOfPayments' || field == 'floor') && updated.calculateBy == PaymentPlanTiersCalculatedBy.NumberOfPayments){
      if (updated.maxNumberOfPayments != 0)
        updated.minPaymentAmount = Number((updated.floor / updated.maxNumberOfPayments).toFixed(2));
    }

    if((field == 'minPaymentAmount' || field == 'floor') && updated.calculateBy == PaymentPlanTiersCalculatedBy.PaymentAmount){
      if (updated.minNumberOfPayments != 0)
        updated.maxNumberOfPayments = Math.ceil(updated.floor / updated.minPaymentAmount);
    }

    return updated;
  }

  function onChangeInputData(
    idx: number,
    field: keyof TierGridDataInterface,
    value: string,
    all?: TierGridDataInterface[]
  ) {
    if (clientOrganizationPath && all) {
      const newTierOptions = [...all];
      let updated = { ...all[idx] };
      if (updated) {
        updated = {
          ...updated,
          [field]: value
        };

        updated = calculateValues(field, updated);

        newTierOptions[idx] = updated;
        dispatch(setPaymentPlanTierFields({ organizationPath: clientOrganizationPath, tierFields: newTierOptions }));
      }
    }
  }

  const onInputCellSelectValueChanged = (selected:any, params:any) => {
    let newList: TierGridDataInterface[] = [];
    let updatedRow = {};
    newList = [
      ...newList,
      ...paymentPlanRef.current
    ];

    if(selected == PaymentPlanTiersCalculatedBy.NumberOfPayments){
      updatedRow = {
        ...params.data,
        ...{
         calculateBy: selected,
         minNumberOfPaymentsIsEditable: true,
         maxNumberOfPaymentsIsEditable: true,
         minPaymentAmountIsEditable : false
        }
      };
    }

    if(selected == PaymentPlanTiersCalculatedBy.PaymentAmount){
      updatedRow = {
        ...params.data,
        ...{
         calculateBy: selected,
         minNumberOfPaymentsIsEditable: true,
         maxNumberOfPaymentsIsEditable: false,
         minPaymentAmountIsEditable : true
        }
      };
    }

    newList[params.data.tId] = updatedRow as TierGridDataInterface;
    dispatch(setPaymentPlanTierFields({ organizationPath: clientOrganizationPath, tierFields: newList }));
  } 


  const columnTypes = {
    customCellRendererColumnType: {
      cellStyle: { textAlign: 'right' },
      cellRenderer: 'CustomCellRenderer',
      cellRendererParams: {
        errorMessage: errorMessage,
        errorClass: errorClass,
        getErrorMessage: getErrorMessage
      },
      valueSetter: (params:ValueSetterParams) => {
        const { colDef } = params;
        const rowIndex = params.node.rowIndex;
        const field = colDef.field as keyof TierGridDataInterface;
        let newList:TierGridDataInterface[] =  [];
        newList = [
          ...newList,
          ...paymentPlanRef.current
        ];
        
        colDef.field && onChangeInputData(rowIndex, field, params.newValue, newList);
    
        return true;        
      },
      editable: (params:any) => {
        const { data, colDef } = params;
        const field = colDef.field as keyof TierGridDataInterface;
        
        switch(field) {
          case 'minNumberOfPayments': 
            return data.isEditing && data.minNumberOfPaymentsIsEditable;

          case 'maxNumberOfPayments': 
            return data.isEditing && data.maxNumberOfPaymentsIsEditable;

          case 'minPaymentAmount': 
            return data.isEditing && data.minPaymentAmountIsEditable;

          default:
            return data.isEditing;
        }
      }
    },
    customCellTextEditorColumnType: {
      cellEditor: 'CellTextEditor',
      cellEditorParams: {
        errorMessage: errorMessage,
        clientOrganizationPath: clientOrganizationPath,
        getAttributes: getAttributes,
        validate: validate,
        validateAll: validateAll,
        errorClass: errorClass,
        getErrorMessage: getErrorMessage
      },
    },
    customCellSelectEditorColumnType: {
      cellEditor: 'CellSelectEditor',
      cellEditorParams: {
        onInputCellSelectValueChanged: onInputCellSelectValueChanged,
        errorMessage: errorMessage,
        clientOrganizationPath: clientOrganizationPath,
        errorClass: errorClass,
        getErrorMessage: getErrorMessage
      },
    }
  } 

  const headerDefinitions = [
    {
      resizable: true,
      maxWidth: 60,
      minWidth: 60,
      headerCheckboxSelection: !(disablePlans && disableGLPlans),
      checkboxSelection: !(disablePlans && disableGLPlans),
    },
    {
      headerName: 'Tier #',
      sortable: false,
      minWidth: 80,
      maxWidth: 80,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => `${params.node.rowIndex + 1}`
    },
    {
      headerName: 'Floor',
      field: nameof<PaymentPlanTier>('floor'),
      sortable: true,
      minWidth: 100,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: (params: ValueFormatterParams) => `$${params.value}`,
      type: ['customCellRendererColumnType', 'customCellTextEditorColumnType']
    },
    {
      headerName: 'Ceiling',
      field: nameof<PaymentPlanTier>('ceiling'),
      sortable: true,
      minWidth: 100,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => `$${params.value}`,
      type: ['customCellRendererColumnType', 'customCellTextEditorColumnType']
    },
    {
      headerName: 'Calculated by',
      field: nameof<PaymentPlanTier>('calculateBy'),
      sortable: false,
      minWidth: 150,
      resizable: true,
      type: ['customCellRendererColumnType', 'customCellSelectEditorColumnType']
    },
    {
      headerName: 'Min. # of Payments',
      field: nameof<PaymentPlanTier>('minNumberOfPayments'),
      sortable: false,
      minWidth: 200,
      resizable: true,
      type: ['customCellRendererColumnType', 'customCellTextEditorColumnType']
    },
    {
      headerName: 'Max. # of Payments',
      field: nameof<PaymentPlanTier>('maxNumberOfPayments'),
      sortable: false,
      minWidth: 200,
      resizable: true,
      type: ['customCellRendererColumnType', 'customCellTextEditorColumnType']
    },
    {
      headerName: 'Min. Payment Amount',
      field: nameof<PaymentPlanTier>('minPaymentAmount'),
      sortable: true,
      minWidth: 200,
      resizable: true,
      type: ['customCellRendererColumnType', 'customCellTextEditorColumnType']
    }
  ];

  const getDisableConfigurationClassName = () => {
    return disabledConfiguration ? 'configuration-disabled': '';
  }

  const getToggleLabel = (toggle: string) => {
    if(toggle === 'Non-GL') {
      return `${disablePlans ? 'Enable' : 'Disable'} New Plans Creation`;
    }
    if(toggle === 'GL') {
      return `${disableGLPlans ? 'Enable' : 'Disable'} New GL Plans Creation`;
    }
    if(toggle === 'Stop') {
      return `${stopPlans ? 'Re-start' : 'Stop'} All Existing Plans for this Facility`;
    }
  }

  const pageSizeOptions = [
    { optionName: '5', value: 5 },
    { optionName: '10', value: 10 }
  ];

  const [tierGridApi, setTierGridApi] = useState < GridApi | null>(null);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [currentPage, setCurrentPage] = useState(0);

  const onChangePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = pageSizeOptions[event.target.selectedIndex].value;
    setPageSize(value);
    if (tierGridApi) {
      tierGridApi.paginationSetPageSize(value);
    }
  }

  const onSelectionChanged = (selectedRowsCount?: number) => {
    const controlsEnabled = (selectedRowsCount ?? 0) === 0;
    setIsEditTierDisabled(!!controlsEnabled);
    setIsRemoveTierDisabled(!!controlsEnabled);
  }

  function onGridReady(params: GridReadyEvent) {
    const tiersWithId = assignIdToTiers(paymentPlanTiers);
    setTierGridApi(params.api);
    params.api.setRowData(tiersWithId);
    params.api.sizeColumnsToFit();
  }

  interface TierGridDataInterface extends PaymentPlanTier{

  }

  const addNewRow = () => {
    const newEmptyRowData: TierGridDataInterface = {
      tId: 0,
      floor: 0,
      ceiling: 0,
      calculateBy: '',
      minNumberOfPayments: 0,
      maxNumberOfPayments: 0,
      minPaymentAmount: 0,
      isEditing: true,
      minNumberOfPaymentsIsEditable: false,
      maxNumberOfPaymentsIsEditable: false,
      minPaymentAmountIsEditable: false,
    };

    dispatch(setPaymentPlanTierFields({ organizationPath: clientOrganizationPath, tierFields: [...[newEmptyRowData], ...paymentPlanTiers] }));
  }

  const editRows = () => {
    setIsEditingTiers(true);
    const selectedRows = tierGridApi?.getSelectedRows() ?? [];

    const editingPaymentPlanTiers: TierGridDataInterface[] = [...paymentPlanTiers];

    selectedRows.forEach((row) => {
      const tier: TierGridDataInterface = {
        ...row,
      };

      tier.isEditing = true;
      switch (tier.calculateBy) {
        case PaymentPlanTiersCalculatedBy.NumberOfPayments:
          tier.minNumberOfPaymentsIsEditable = true;
          tier.maxNumberOfPaymentsIsEditable = true;
          tier.minPaymentAmountIsEditable = false;
          break;

        case PaymentPlanTiersCalculatedBy.PaymentAmount:
          tier.minNumberOfPaymentsIsEditable = true;
          tier.maxNumberOfPaymentsIsEditable = false;
          tier.minPaymentAmountIsEditable = true;
          break;

        default:
          tier.minNumberOfPaymentsIsEditable = false;
          tier.maxNumberOfPaymentsIsEditable = false;
          tier.minPaymentAmountIsEditable = false;
          break;
      }
      editingPaymentPlanTiers[Number(tier.tId)] = tier;
    });

    dispatch(setPaymentPlanTierFields({ organizationPath: clientOrganizationPath, tierFields: editingPaymentPlanTiers }));
  };

  const removeRows = () => {
    const selectedRows = tierGridApi?.getSelectedRows() ?? [];
    const newPaymentPlanTiers: TierGridDataInterface[] = [...paymentPlanRef.current].filter(tier => 
      !selectedRows.includes(tier)
    );

    dispatch(setPaymentPlanTierFields({ organizationPath: clientOrganizationPath, tierFields: newPaymentPlanTiers}));
  }

  const tierControls = getPlanTierControls(isEditTierDisabled, isRemoveTierDisabled, isAddTierDisabled, addNewRow, editRows, removeRows);

  useEffect(() => {
    setDisableConfiguration(disablePlans && disableGLPlans);
    setIsAddTierDisabled(disablePlans && disableGLPlans);
    setIsEditTierDisabled(disablePlans && disableGLPlans);
    setIsRemoveTierDisabled(disablePlans && disableGLPlans);
  }, [disablePlans, disableGLPlans]);

  useEffect(() => {
    const tiersWithId = assignIdToTiers(paymentPlanTiers);
    tierGridApi?.setRowData(tiersWithId);
    paymentPlanRef.current = tiersWithId;
    tierGridApi?.redrawRows();
  }, [paymentPlanTiers]);

  useEffect(() => {
    const body = document.querySelector('body');
    if(isEditingTiers){
      body?.addEventListener('click', handleClickOutside, true);
    }

    return () => {
      body?.removeEventListener('click', handleClickOutside, true);
    };
  }, [isEditingTiers]);

  const handleClickOutside = (event:any) => {
    if(!isEditingTiers)
      return;

    const table = document.querySelector('.ag-root-wrapper');
    const target = event.target as Node;
    const elementTarget = event.target as HTMLElement;
    const dropdownClasses = ['checkbox-label search-option','MuiAutocomplete-option','MuiAutocomplete-listbox']

    if(!table?.contains(target) && !dropdownClasses.includes(elementTarget.className)) {
      setIsEditingTiers(false);
      resetTiers(paymentPlanRef.current, true);
    }
  };

  const assignIdToTiers = (tiers: PaymentPlanTier[]) => {
    const tiersWithId: PaymentPlanTier[] = [];

    tiers.forEach((tier, index) => {  
      const tierWithId: TierGridDataInterface = {
        ...tier,
      };
      tierWithId.tId = index;
      tiersWithId.push(tierWithId);
    });

    return tiersWithId;
  }

  useEffect(() => {
    if (clientOrganizationPath) {
      getPlanConfiguration();
    }
  }, [clientOrganizationPath]);

  useEffect(() => {
    setConfigurationValues({...configuration, organization: { id: clientOrganization?.id ?? '', name: clientOrganization?.name ?? '', path: clientOrganization?.path ?? '' }});
    setStopPlans(!!configuration?.stopAllPlansRecord);
    setDisablePlans(!!configuration?.disablePlansRecord);
    setDisableGLPlans(!!configuration?.disableGLPlansRecord);
    dispatch(setConfigurationReload({ organizationPath: clientOrganizationPath, reload: true }));
  }, [configuration]);

  const dispatch = useDispatch();

  const stopRestartPlans = async (isStop: boolean) => {
    let response;
    if (isStop) {
      response = await stopPaymentPlans({
        organization: clientOrganization as any,
        notes
      });
    } else {
      response = await restartPaymentPlans(clientOrganizationPath);
    }

    if (!response || response.err) {
      dispatch(
        setAlert({
          ...deviceAlert,
          message: `Error ${ isStop ? 'stopping' : 're-starting' } all plans.`,
        })
      );
      return;
    }

    dispatch(
      setAlert({
        ...deviceAlert,
        type: isStop ? AlertTypes.Info : AlertTypes.Success ,
        message: isStop ? 'Stopping all plans takes up to 48 hrs to complete.' : 'All plans re-started.',
        dismissable: true,
      })
    );

    dispatch(
      setConfiguration({
        organizationPath: clientOrganizationPath,
        configuration: {
          ...configuration,
          stopAllPlansRecord: isStop ? { notes, stoppedBy: loggedUser?.firstName ?? '', stoppedOn: moment().utc().format() } : undefined
        },
      })
    );
    setRestartPlansConfirmation(false);
    setStopPlansConfirmationPopUp(false);

  };

  const handleStopConfirmations = () => {
    setStopPlansConfirmation(false);
    setStopPlansConfirmationPopUp(true);
  };

  const disablePaymentPlans = (isGL: boolean) => {
    const disablePlansRecord : DisablePlansRecord = {
      disabledBy: loggedUser?.firstName ?? '',
      disabledOn: moment().utc().format()
    }
    if(!isGL) {
      setConfigurationValues({ ...configurationValues, disablePlansRecord: disablePlansRecord });
      setDisablePlansConfirmation(false);
      setDisablePlans(true);
    }
    else {
      setConfigurationValues({ ...configurationValues, disableGLPlansRecord: disablePlansRecord });
      setDisableGLPlansConfirmation(false);
      setDisableGLPlans(true);
    }
  };

  const onStopRestartPlansCheckBoxChange = (checked: boolean) => {
    if (checked) {
      setNotes('');
      setStopPlansConfirmation(true);
    } else {
      setRestartPlansConfirmation(true);
    }
  };

  const onDisablePlansCheckBoxChange = (checked: boolean) => {
    !checked && setConfigurationValues({ ...configurationValues, disablePlansRecord: undefined });
    !checked && setDisablePlans(false);
    setDisablePlansConfirmation(checked);    
  };

  const onDisableGLPlansCheckBoxChange = (checked: boolean) => {
    !checked && setConfigurationValues({ ...configurationValues, disableGLPlansRecord: undefined });
    !checked && setDisableGLPlans(false);
    setDisableGLPlansConfirmation(checked);    
  };

  const organizationLabel = useMemo(() => {
    const prefix = stopPlans ? '' : 'STOP ALL PLANS FOR ';
    const clientId = clientOrganization?.clientId ?? '';
    const organizationName = clientOrganization?.name ?? '';
    const name = clientId || organizationName;

    const label = clientId && organizationName
      ? `${clientId} (${organizationName})`
      : name;

    return `${prefix}${ label ? label.trim() : '(unknown)' }`.toUpperCase();
  }, [clientOrganization, stopPlans]);
  
  const confirmationModalDistributionOrder = getPopupForDistributionOrder((value: SetStateAction<PaymentPlanConfigurationModel>) => setConfigurationValues(value), configurationValues, setShowDistributionConfirmationPopup, showDistributionConfirmationPopup, targetName, targetValue);

  const confirmationPopUpStopPlans = getStopPlansConfirmationPopUp((value: boolean) => {stopRestartPlans(value)}, 
                                (value: boolean) => {setStopPlansConfirmationPopUp(value)}, setNotes, stopPlansConfirmationPopUp, notes, organizationLabel);

  const confirmationModalStopPlans = getPlansConfirmationModal((value : boolean) => {setStopPlansConfirmation(value)},
                                () => {handleStopConfirmations()}, false, true, stopPlansConfirmation, getConfirmationWarningText(false, true, disablePlans, disableGLPlans, stopPlans));

  const confirmationModalDisablePlans = getPlansConfirmationModal((value : boolean) => {setDisablePlansConfirmation(value)},
                                (value : boolean) => {disablePaymentPlans(value)}, false, false, disablePlansConfirmation, getConfirmationWarningText(false, false, false, false, stopPlans));

  const confirmationModalDisableGLPlans = getPlansConfirmationModal((value : boolean) => {setDisableGLPlansConfirmation(value)}, 
                                (value : boolean) => {disablePaymentPlans(value)}, true, false, disableGLPlansConfirmation, getConfirmationWarningText(true, false, false, false, stopPlans));

  const confirmationModalRestartPlansConfirmText = useMemo(() => {
    return `<div class="restart-confirmation-modal-title">${organizationLabel}</div>
      <div class="restart-confirmation-modal-text">Are you sure, you want to re-start all plans?</div>`
    ;
  }, [clientOrganization, stopPlans]);

  const confirmationModalRestartPlans = (
    restartPlansConfirmation ? 
      <ActionConfirmModal
        isOpen={true}
        actionTitle={`Re-start Plans`}
        actionButtonText={'Re-start'}
        actionConfirmText={confirmationModalRestartPlansConfirmText}
        onActionConfirm={() => {
          stopRestartPlans(false);
        }}
        onClose={()=> { setRestartPlansConfirmation(false) }}
      /> : null
  );

  const warningMessageStoppedPlans = getPlansWarningMessage(configurationValues?.stopAllPlansRecord, true, false, stopPlans);
  const warningMessageDisabledPlans = getPlansWarningMessage(configurationValues?.disablePlansRecord, false, false, disablePlans);
  const warningMessageDisabledGLPlans = getPlansWarningMessage(configurationValues?.disableGLPlansRecord, false, true, disableGLPlans);
 
  const handleTextBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {    
    setConfigurationValues({ ...configurationValues, [event.target.name]: (event.target.value ? event.target.value : '' ) });
   };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfigurationValues({ ...configurationValues, [event.target.name]: event.target.checked });
  };

  const maximumNumberOfPausesPerYearValue = configurationValues?.maximumNumberOfPausesPerYear ?? 0;

  const generalSettingOptions = (
    <>
      <div className='checkbox-payment-plan-configuration'>
        <div className='checkbox-payment-plan-configuration__column'>
          {paymentPlanConfigurationCheckbox(handleCheckBoxChange, `Allow Plan Date Change`, `Display date picker to modify payment plan date`, `allowPlanDateChange`, configurationValues?.allowPlanDateChange, disabledConfiguration)}
        </div>
      </div>
      <div className='checkbox-payment-plan-configuration'>
        <div className='checkbox-payment-plan-configuration__column'>
          {paymentPlanConfigurationCheckbox(handleCheckBoxChange, `Allow Add to Plan`,  `Show the 'Add to Plan' Button in the Make a Payment page`, `allowAddToPlan`, configurationValues?.allowAddToPlan, disabledConfiguration)}
        </div>
      </div>
      <div className="textbox-payment-plan-configuration">
      <div className="textbox-payment-plan-configuration__label">Max number of payment skips allowed in a payment plan</div>
      <div className="textbox-payment-plan-configuration__input">
          <EDS_TextBox
            name="maximumNumberOfPausesPerYear"
            value={maximumNumberOfPausesPerYearValue}
            onChange={handleTextBoxChange}
            disabled={disabledConfiguration}
          ></EDS_TextBox>         
        </div>
      </div>
      <div className='checkbox-payment-plan-configuration'>
        <div className='checkbox-payment-plan-configuration__column'>
          {paymentPlanConfigurationCheckbox(handleCheckBoxChange, `Do PAH adjustment for Payment Plans`,  `Payment Plan account balances will be updated from the PAH \n system. If the accounts are not found, Plans will auto complete.`, `allowPAHSync`, configurationValues?.allowPAHSync, disabledConfiguration, 'checkbox-payment-plan-configuration__description--white-space')}
        </div>
      </div>
    </>
  );

  const clientNotificationsOptions = (
    <>
      <div className="client-notifications">
        <div className="textbox-autopaused-notifications-email">
          <EDS_TextBox
            label={'Send Auto-Paused Plan Notifications to:'}
            name="autoPausedNotificationEmailAddress"
            value={configurationValues?.autoPausedNotificationEmailAddress}
            onChange={handleTextBoxChange}
            disabled={disabledConfiguration}
            placeHolder={'Enter email address'}
          ></EDS_TextBox>         
        </div>
      </div>
    </>
  );

  let defaulDistributiontArray: {optionName: string; value: string}[] = [{ optionName: "Select...", value: ""}];

  const [distributionOrderOption] = useState<{optionName: string, value: string}[]>(defaulDistributiontArray.concat(multiSelectOptionsToSelectOptions(enumToMultiSelectOptions(DistributionOrder))));

  const paymentDistributionOrderDropdown = (
    <>
      <div className='distribution-order'>
          <EDS_Select
            label={`Select Distribution Order`}
            name="distributionOrder"
            options={distributionOrderOption}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              handleDistributionOrderChange( setConfigurationValues, setTargetName, setTargetValue, setShowDistributionConfirmationPopup, configurationValues, e.target.name, e.target.value);
            }}
            value={configurationValues?.distributionOrder ?? ''} />
      </div>
    </>
  );

  const distributionOrderMessage = () => {
    if(!configurationValues?.distributionOrder) {
      return (
        <span className='distribution-order-label'>Distribution order is required</span>
      )
    }
  }

  const paymentPlanTiersGrid = (
    <>
      <div className='row'>
        <div className='control-section'>
          <div className={'header-buttons'}>
            { tierControls.map((button) => { return button.renderButton }) }
          </div> 
        </div>                                                      
      </div>               
      <div className="row paymentplan-tiers-container">
        <div className="flexRow">
          <div className={'section ag-theme-alpine default-checkboxes tiers-table'}>
            <AgGridReact
              columnDefs={headerDefinitions}
              columnTypes={columnTypes}
              gridOptions={{ rowHeight: 60 }}
              onGridReady={onGridReady}
              rowData={paymentPlanTiers}
              frameworkComponents= {
                {
                  CellSelectEditor,
                  CellTextEditor,
                  CustomCellRenderer
                }      
              }
              domLayout={'autoHeight'}
              rowSelection={'multiple'}
              singleClickEdit={true}
              suppressRowClickSelection={true}
              suppressMenuHide={true}
              suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
              pagination={true}
              suppressCellSelection={disabledConfiguration}
              cacheBlockSize={pageSize}
              paginationPageSize={pageSize}
              suppressPaginationPanel={true}
              onRowSelected={() => { onSelectionChanged(tierGridApi?.getSelectedRows().length ?? 0) }}
              onPaginationChanged={() => { setCurrentPage(tierGridApi?.paginationGetCurrentPage() ?? 0) }}
            />
            <div className={"paymentplan-tiers-pagination-container"}>
              <div className={"pages"}>
                {currentPage ? <span onClick={() => tierGridApi?.paginationGoToPreviousPage()}>{"|<"}</span> : null}
                <span className={"page-number"}>{currentPage + 1}</span>
                {tierGridApi && tierGridApi.paginationGetTotalPages() !== (currentPage + 1) ? <span onClick={() => tierGridApi.paginationGoToNextPage()}>{">|"}</span> : null}
              </div>
              <EDS_Select
                name="tiersPageSizeSelector"
                label="Rows per page:"
                modifiers={'row-item row-item-size-double'}
                options={pageSizeOptions}
                onChange={onChangePageSize}
                value={pageSize}
                aria-label={'tiersPageSizeSelector'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const paymentPlanTermsTable = (
    <>
      <div>
        <table className="data-table paymentplan-terms">
          <thead>
            <tr>
              <th>Terms Value</th>
              <th>Enable</th>                    
            </tr>
          </thead>
          <tbody>
            {
              Object.values(termsValues).map((value, index) => { 
                const key = `${index}-${value}`; 
                return(
                  <tr key={key}>
                    <td>{value}</td>
                    <td>
                      <Radio
                        onChange={() => {
                          setConfigurationValues({ ...configurationValues, defaultPaymentPlanTerm: value });
                        }}
                        disabled={disabledConfiguration}
                        checked={configurationValues?.defaultPaymentPlanTerm ?  configurationValues?.defaultPaymentPlanTerm === value.toString() : Terms.Monthly === value} />
                    </td>
                  </tr>
                );
              }) 
            }
          </tbody>
        </table>
      </div> 
    </>
  );  


  function resetTiers (tiers: PaymentPlanTier[], mustDispatch: boolean = false) {
    const resettedTiers: PaymentPlanTier[] = [];
    tiers.forEach((tier) => {
      tier = {
        ...tier,
        isEditing: false,
        minNumberOfPaymentsIsEditable: false,
        maxNumberOfPaymentsIsEditable: false, 
        minPaymentAmountIsEditable: false
      }
      resettedTiers.push(tier);
    });

    if (mustDispatch)
      dispatch(setPaymentPlanTierFields({ organizationPath: clientOrganizationPath, tierFields: resettedTiers }));

    return resettedTiers;
  }

  function reOrderAndResetTiers(configurationValues: PaymentPlanConfigurationModel) {
    const billTiers = [...(configurationValues?.tiers?.BillTiers ?? [])];

    if (billTiers) {
      billTiers.sort((a, b) => {
        return a.floor - b.floor;
      });
    }
    
    const resettedTiers = resetTiers(billTiers);
 
    dispatch(setConfiguration({ 
      organizationPath: clientOrganizationPath,
      configuration: {
        ...configurationValues,
        tiers: {
          BillTiers: resettedTiers,
          GLTiers: resettedTiers
        }
      }  
    }));
  }

  async function updatePaymentPlanConfiguration(configurationValues: PaymentPlanConfigurationModel) {
    reOrderAndResetTiers(configurationValues);
    if (configurationValues.distributionOrder) {
      const response = await savePaymentPlanConfigurationService(clientOrganizationPath, { ...configurationValues, maximumNumberOfPausesPerYear: maximumNumberOfPausesPerYearValue });
      if (response.err) {
        dispatch(setAlert({ ...planConfigurationAlert, message: "Error saving configuration" }));;
      }
      setIsAddTierDisabled(false);
      dispatch(setAlert({ ...planConfigurationAlert, dismissable: true, type: AlertTypes.Success, message: "Configuration has been saved successfully"}));
    } else {
      dispatch(setAlert({ ...planConfigurationAlert, message: 'Please select "Payment Distribution Order" in order to save configuration' }));
    }
  }
  
  if (isProcessionGetPaymentPlanConfiguration) {
    return <p>Getting Payment Plan Configuration...</p>;
  } else {
    return (
      <div className="payment-plan-configuration-container">
        <ConfigurationTemplate title="Payment Plans Configuration">
          <>
            { confirmationPopUpStopPlans }
            { confirmationModalStopPlans }
            { confirmationModalRestartPlans }
            { confirmationModalDisablePlans }
            { confirmationModalDisableGLPlans }
            { confirmationModalDistributionOrder }
          </>
          <div className='configuration-controls-container'>
            <div className='column'>
              <div className="control">
                <EDS_Switch
                  name="stopRestartAllPaymentPlansForThisFacility"
                  label={getToggleLabel('Stop')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onStopRestartPlansCheckBoxChange(e.target.checked);
                  }}
                  checked={stopPlans}
                  disabled={!havePermissionStartStopPlan}
                />
              </div>
            </div>          
            <div className='column'>
              <div className="column control">
                <EDS_Switch
                  name="disablePaymentPlansForThisFacility"
                  label={getToggleLabel('Non-GL')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onDisablePlansCheckBoxChange(e.target.checked);
                  }}
                  checked={disablePlans}
                />
              </div>
            </div>
            <div className='column'>
              <div className="control">
                <EDS_Switch
                  name="disableGLPaymentPlansForThisFacility"
                  label={getToggleLabel('GL')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onDisableGLPlansCheckBoxChange(e.target.checked);
                  }}
                  checked={disableGLPlans}
                />
              </div>             
            </div>           
            <div className='column'>
              <div className="control">
                <HeaderButton
                  title={'Save Configuration'}
                  icon={<Edit />}
                  onClick={() => {
                    if (isValidData())
                      updatePaymentPlanConfiguration(configurationValues)
                  }}
                  disabled={false}
                />               
              </div>             
            </div>
          </div>                    
          <div className={`configuration-sections-container ${getDisableConfigurationClassName()}`}>
            { warningMessageStoppedPlans }
            { warningMessageDisabledPlans }
            { warningMessageDisabledGLPlans }
            <div className='section'>
              <div className="header-wrapper">
                <EDS_Accordion
                  accordionItems={[
                    {
                      summary: labels.generalSettings,
                      isOpen: false,
                      content: generalSettingOptions,
                    },
                  ]}
                  modifiers="accordion-search-results"
                />
              </div>
            </div>
            <div className='section distribution-order-section'>
              <div className="header-wrapper distribution-order-header" >
                <EDS_Accordion
                  accordionItems={[
                    {
                      summary: `${labels.paymentDistributionOrder}`,
                      isOpen: false,
                      content: paymentDistributionOrderDropdown,
                    },
                  ]}
                  modifiers="accordion-search-results"
                />            
              </div>
              {distributionOrderMessage()}
            </div>
            <div className='section'>
              <div className="header-wrapper">
                <EDS_Accordion
                  accordionItems={[
                    {
                      summary: labels.planRelatedClientNotifications,
                      isOpen: false,
                      content: <>
                                <span className='description-payment-plan-client-notifications'>Configure the email address that should receive the Payment Plan related Client Communications.</span>
                                { clientNotificationsOptions }
                               </>                  
                    },
                  ]}
                  modifiers="accordion-search-results"
                />
              </div>
            </div>
            <div className='section'>
              <div className="header-wrapper">
                <EDS_Accordion
                  accordionItems={[
                    {
                      summary: labels.paymentPlanTiers,
                      isOpen: false,
                      content: paymentPlanTiersGrid,
                    },
                  ]}
                  modifiers="accordion-search-results"               
                />
              </div>
            </div>
            <div className='section'>
              <div className="header-wrapper">
                <EDS_Accordion
                  accordionItems={[
                    {
                      summary: labels.paymentPlanTerms,
                      isOpen: false,
                      content: <>
                                <span className='description-payment-plan-configuration'>Configure settings for payment plan payment terms and set the preferred default payment frequency.</span>
                                { paymentPlanTermsTable }
                               </>                  
                    },
                  ]}
                  modifiers="accordion-search-results"
                />
              </div>
            </div>                                                
          </div>  
        </ConfigurationTemplate>
      </div>    
    );
  }
}

function getAllPaymentPlanTiers(configuration?: PaymentPlanConfigurationModel){
  return configuration?.tiers?.BillTiers ?? [];
}

function getWarningMessageDate(record?: PlansRecord, isStop: boolean = true) {
  return `At ${formatDateString(isStop ? record?.stoppedOn : record?.disabledOn, 'MM/DD/YYYY LTS') ?? '(Unknown)'} `;
}

export function paymentPlanConfigurationCheckbox(handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void, checkboxLabel?: string, checkboxDescription?: string, handleCheckboxName?: string, configurationPropertyValue?: boolean, disabledConfiguration?: boolean, descriptionModifier?: string) {
  return (
      <>
      <input
        className={'eds-checkbox_#input checkbox-payment-plan-configuration__input'}
        type={'checkbox'}
        name={handleCheckboxName}
        aria-label={handleCheckboxName}
        checked={configurationPropertyValue ?? false}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleCheckBoxChange(event);
        }}
        disabled={disabledConfiguration ?? false}
      />
    <label className='checkbox-payment-plan-configuration__checkbox'>
      <span className='checkbox-payment-plan-configuration__span-container'>
        <span className={`eds-checkbox_#label checkbox-payment-plan-configuration__label`}>{checkboxLabel}</span>
        <span className={`checkbox-payment-plan-configuration__description ${descriptionModifier}`}>{checkboxDescription}</span>
      </span>
    </label>
    </>
  )
}

function getPlansWarningMessage(record?: PlansRecord, isStop: boolean = true, isGL: boolean = false, isVisible: boolean = false) {
  const planType = isGL ? 'GL plans ' : "'Non-GL' plans ";
  const disableMessage = `You will not be able to create new ${ isStop ? '' : planType } for this facility.`

  return(
    isVisible ?
      <div className="warning-message-plans-container">
        <div className='message'><Sign /><b>{`${isStop ? 'All existing payment plans are stopped for this facility. Plan configuration changes will affect only new plans.' : disableMessage } `}</b></div>
        <div className='details'>
          <span>{getWarningMessageDate(record, isStop)}</span>
          <span>{`${(isStop ? record?.stoppedBy : record?.disabledBy) ?? '(Unknown)'}`}</span>
        </div>
        <div className='notes'>{`Note: Please wait for a while for all the Payment Plans to be stopped. It takes a while to stop all plans for the facility.`}</div>
        {isStop &&
          <div className='notes'>{`Admin Note: ${record?.notes ?? ''}`}</div>
        }       
      </div> 
    : null
  );
}

function getPlanTierControls(isEditTierDisabled: boolean, isRemoveTierDisabled: boolean, isAddTierDisabled: boolean, addNewRow: () => void, editRows: () => void, removeRows:() => void) {
  const buttonDefinitions = [
    {
      name: 'addTier',
      renderButton:
      <HeaderButton
        title={'Add Tier'}
        icon={<Add />}
        disabled={isAddTierDisabled}
        key={'addTier'}
        onClick={() => addNewRow()}
      />
    },
    {
      name: 'editTier',
      renderButton:
      <HeaderButton
        title={'Edit'}
        icon={isEditTierDisabled ? <EditDisabled /> : <Edit/>}
        disabled={isEditTierDisabled}
        key={'editTier'}
        onClick={() => editRows()}
      />
    },
    {
      name: 'removeTier',
      renderButton:
      <HeaderButton
        title={'Remove Tier(s)'}
        icon={isRemoveTierDisabled ? <RemoveDisabled /> : <Remove />}
        disabled={isRemoveTierDisabled}
        key={'removeTier'}
        onClick={() => removeRows()}
      />
    }
  ];

  return buttonDefinitions;
}

function getPlansConfirmationModal(onClose : (value: boolean) => void, onSubmit : (value: boolean) => void, isGL : boolean, isStop: boolean, visible: boolean, warningText: string) { 
  const confirmationFooterButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel',
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Ok'
    }
  ];

  return (
    visible ?
    <Popup
        type= "warning"
        text = {warningText}
        fullHeight={false}
        footer={confirmationFooterButtons}
        onSubmit={() => {onSubmit(isGL || isStop)}}
        onClose={() => {onClose(false)}}
    /> : null
  );
}

export const handleDistributionOrderChange = (setConfigurationValues: (value: SetStateAction<PaymentPlanConfigurationModel>) => void, setTargetName: (value: SetStateAction<string>) => void, setTargetValue: (value: SetStateAction<string>) => void, setShowDistributionConfirmationPopup: (value: SetStateAction<boolean>) => void, configurationValues: PaymentPlanConfigurationModel, targetName: string, targetValue?: string) => {
  if (targetValue === "OldestFirst" || targetValue === "NewestFirst") {
    setTargetName(targetName);
    setTargetValue(targetValue);
    setShowDistributionConfirmationPopup(true);
  } else {
    if (targetValue) {
      setConfigurationValues({ ...configurationValues, [targetName]: targetValue });
    }
  }
}

export function getPopupForDistributionOrder(setConfigurationValues: (value: SetStateAction<PaymentPlanConfigurationModel>) => void, configurationValues: PaymentPlanConfigurationModel, setShowDistributionConfirmationPopup: (value: boolean) => void, showDistributionConfirmationPopup: boolean, targetName: string, targetValue: string) {
  const confirmationFooterButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel',
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Proceed'
    }];

  return (
    showDistributionConfirmationPopup ?
    <Popup
        type= "warning"
        text = {`The logic for this distribution strategy requires the Date of Service field to be transmitted in the Client feed.`}
        fullHeight={false}
        footer={confirmationFooterButtons}
        customStyle={'configuration-modal-payment-distribution'}
        onSubmit={() => {setConfigurationValues({ ...configurationValues, [targetName]: targetValue }); setShowDistributionConfirmationPopup(false);}}
        onClose={() => {setShowDistributionConfirmationPopup(false)}}
    /> : null
  );
}

function getStopPlansConfirmationPopUp(onSubmit: (value: boolean) => void, onClose: (value: boolean) => void, setNotes: Dispatch<SetStateAction<string>>, stopPlansConfirmation: boolean, notes: string, organizationLabel: string) {
  const footerButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel',
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Stop All Plans',
      disabled: !notes
    },
  ];
 
  return (
    stopPlansConfirmation ? <Popup
      onClose={() => {onClose(false)}}
      header={"PaymentSafe®"}
      title={organizationLabel}
      footer={footerButtons}
      customStyle={'configuration-modal-stop-plans-container'}
      customClassNames={{title: 'title', footerButton: 'height30'}}
      onSubmit={() => { onSubmit(true); }}
      children={
        <EDS_TextArea
          label={'Note:'}
          value={notes}
          modifiers="width97"
          heightModifier={256}
          placeHolder={'Please provide the reason for stopping all plans...'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNotes(e.target.value)
          }}
        />
      }
    /> : null
  );
}

function getConfirmationWarningText(isGL: boolean, isStop: boolean, disabledPlans: boolean, disabledGLPlans: boolean, stopped: boolean) {
  const enabledPlansText = `You still be able to create new ${disabledPlans ? 'GL plans' : ''}${disabledGLPlans ? 'non-GL plans' : ''} for this facility.`;
  if(isStop) {
    if(disabledPlans && disabledGLPlans) {
      return 'This will stop all existing plans for this facility. You will not be able to edit, process or add to the existing plans.'; 
    }
    
    return  `This will stop all existing plans for this facility. You will not be able to edit, process or add to the existing plans.\n${!disabledPlans && !disabledGLPlans ? 'You still be able to create new plans for this facility.' : enabledPlansText}`;
  }
  
  return `You will not be able to create new ${isGL ? 'GL plans' : 'plans'} for this facility.${stopped ? '' : '\nAll existing plans will work as usual.'}`;
}