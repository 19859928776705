import React, { useCallback } from 'react';
import {
  updateRecord,
  addFilter,
  removeFilter,
} from 'pages/Search/planSearch/PaymentPlanSearchReducer';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'app/rootReducer';

export function useChangeHandlers() {
  const dispatch = useDispatch();
  const state = useTypedSelector(p => p.paymentPlanSearchInfo?.value || {});
  const filters = useTypedSelector(p => p.paymentPlanSearchInfo?.filters || {});

  return useCallback(() => {
    const setState = (patch: { [key: string]: any }) => {
      dispatch(
        updateRecord({
          ...state,
          ...patch,
        })
      );
    };

    const setFilter = (
      name: string,
      value: any,
      displayName?: string,
      displayValue?: any
    ) => {
      if (Array.isArray(value) && value.length === 0) {
        dispatch(removeFilter(name));
      } else if (value) {
        dispatch(
          addFilter({
            name,
            filter: {
              displayName,
              value,
              displayValue,
            },
          })
        );
      } else {
        dispatch(removeFilter(name));
      }

      const newState = {} as any;
      newState[name] = value;
      setState(newState);
    };

    const handleChangeTextBox = (
      event: React.ChangeEvent<HTMLInputElement>,
      displayName?: string
    ) => {
      let propertyName = event.target.name;
      let value = event.target.value;
      setFilter(propertyName, value, displayName);
    };

    const handleChangeCheckBoxGroup = (
      propertyGroup: string,
      propertyName: string,
      checked: boolean,
      displayName?: string,
      optionDisplayValue?: string
    ) => {
      const displayValue = optionDisplayValue || propertyName;

      const groupArray = (state as any)[propertyGroup] || [];
      const currentState: string[] = [...groupArray];

      const currentFilterState =
        (filters as any)[propertyGroup]?.displayValue || [];
      const currentFilter: string[] = [...currentFilterState];

      if (checked) {
        setFilter(propertyGroup, [...currentState, propertyName], displayName, [
          ...currentFilter,
          displayValue,
        ]);
      } else {
        currentState.splice(currentState.indexOf(propertyName), 1);
        currentFilter.splice(currentFilter.indexOf(displayValue), 1);
        setFilter(propertyGroup, currentState, displayName, currentFilter);
      }
    };

    const handleChangeDropDown = (
      event: React.ChangeEvent<HTMLSelectElement>,
      displayName?: string
    ) => {
      let selectElement = event.target;
      let propertyName = selectElement.name;
      let optionIndex = selectElement.selectedIndex;
      let selectedOption = selectElement.options[optionIndex];
      setFilter(
        propertyName,
        selectedOption.value === '' ? undefined : selectedOption.value,
        displayName,
        selectedOption.text
      );
    };

    return {
      state,
      setState,
      setFilter,
      handleChangeTextBox,
      handleChangeCheckBoxGroup,
      handleChangeDropDown,
    };
  }, [dispatch, filters, state])();
}
