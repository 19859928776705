import { Attribute } from 'models/metaData/MetaData';
import { ValidatorType } from 'models/metaData/MetaDataEnums';
import React, { useCallback } from 'react';
import { getLabel, getValidator } from 'utils/metadata/MetaDataUtils';
import DatePicker from 'react-datepicker';
import './dateTime.scss';
import { convertDateToString, convertStringToDate } from 'utils/UtilsDateTime';
import { LineItemError } from '../../paymentDashboard/PaymentDashboardReducer';
import { getError } from 'utils/Utils';

export function DateTime(props: {
  attribute: Attribute;
  value: any;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  modifiers?: string;
  error?: boolean | string;
  setError?: (error: LineItemError) => void;
}) {
  const { attribute, value, onChange, onBlur, modifiers, error } = props;

  const label = getLabel(attribute);
  const name = attribute.name;
  const required = attribute.required;

  const dateTimeValidator = getValidator(
    attribute,
    ValidatorType.DateTimeValidator
  )?.value;

  const handleChange = useCallback(
    (someDate: Date) => {
      if (!onChange) {
        return;
      }

      const dateString = convertDateToString(someDate);
      onChange(dateString);
    },
    [onChange]
  );

  const handleBlur = () => {
      const dateString = convertDateToString(date);
      onBlur && onBlur(dateString);
    };

  const date = value && convertStringToDate(value);
  const maxDate =
    dateTimeValidator?.max && convertStringToDate(dateTimeValidator.max);
  const minDate =
    dateTimeValidator?.min && convertStringToDate(dateTimeValidator.min);
  const errorColor = error ? '#e91313' : '';
  const backgroundErrorColor = error ? '#ffd6dd' : '';

  return (
    <div className="required-component">
      <label className={`eds-field ${modifiers}`}>
        <div className="eds-field_#label">{label}</div>

        <div
          className={`eds-field_#control eds-field_.eds-input eds-input `}
          style={{
            borderColor: errorColor,
            backgroundColor: backgroundErrorColor,
            marginBottom: '5px'
          }}
        >
          <DatePicker
            selected={date}
            onChange={handleChange}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
            name={name}
            maxDate={maxDate}
            minDate={minDate}
            onBlur={handleBlur}
            className="eds-input_#input"
            isClearable
          />
        </div>
        <div className={`${getError(error, required) ? 'error-message-required' : ''}`}>
          {getError(error, required)}
        </div>
      </label>
      <div className={`${getError(error, required) ? 'error-message-required' : ''}`}>
        {getError(error, required)}
      </div>
    </div>
  );
}

export default DateTime;
