import axios from 'axios';
import { OrganizationReference } from 'models/PaymentPlan';
import { PermissionItem } from 'models/Permission';
import Role from 'models/Role';
import { UserDetailModel, DefaultOrganizationLevel } from 'models/UserInfoAndRolesModel';
import { transformToPermissionGroups } from 'utils/UtilsRolesAndPermissions';
import { callHttpApi } from './callHttpApi';

export interface UpdateUserResult {
  err?: string;
}

export interface GetUserResult {
  result?: UserDetailModel[];
  err?: string;
}

export interface GetUserByIdResult {
  result?: UserDetailModel;
  err?: string;
}

export interface CheckPowerReportingAccess {
  isPowerReportingAccessible: boolean;
}

export default interface GetUserDetailsRequest {
  FirstName?: string;
  LastName?: string;
  Email?: string;
}

export async function updateUserRoleService(
  userId: string,
  role: Role,
  permissions: PermissionItem[]
): Promise<UpdateUserResult> {
  try {
    const updateCommand: {
      id: string;
      permissionOverrides: Role[];
    } = {
      id: userId,
      permissionOverrides: [
        {
          ...role,
          permissionGroups: transformToPermissionGroups(permissions),
        },
      ],
    };
    let data;
    const axiosRes = await axios({
      method: 'put',
      url: '/api/v2/UsersV2',
      data: updateCommand,
    });
    data = axiosRes.data;
    return {
      err: '',
    };
  } catch (err) {
    return {
      err: err.message,
    };
  }
}

export async function GetUserDetailService(
  firstname?: string,
  lastname?: string,
  realmUserId?: string
): Promise<GetUserResult> {
  const firstNameNotSet = firstname === undefined || firstname.trim() === '';
  const lastNameNotSet = lastname === undefined || lastname.trim() === '';
  const realmUserIdNotSet = realmUserId === undefined || realmUserId.trim() === '';

  if (!firstNameNotSet || !lastNameNotSet || !realmUserIdNotSet) {
    return await callHttpApi<UserDetailModel[], GetUserDetailsRequest>(
      'get',
      `/api/v1/Users?${
        realmUserIdNotSet
          ? ''
          : `realmUserId=${realmUserId}
      ${
        lastNameNotSet
          ? ''
          : `&lastname=${lastname}
      ${firstNameNotSet ? '' : `&firstname=${firstname}`}`
      }`
      }`
    );
  }

  return new Promise((resolve, reject) =>
    reject('Either first name, last name, or email must be sent')
  );
}

export async function GetUserDetailById(
  id: string
): Promise<GetUserByIdResult> {
  return await callHttpApi<UserDetailModel, string>(
    'get',
    `/api/v1/Users/${id}`
  );
}

export async function GetRefreshToken() {
  return await callHttpApi<string>(
    'get',
    `/api/v1/Authentication/getrefreshtoken`
  );
}

export async function GetUserSettings(id: string) {
  return await callHttpApi<string, string>(
    'get',
    `/api/v1/Users/${id}/settings`,
  );
}

export async function GetAccessToPowerReporting(id: string) {
  return callHttpApi<CheckPowerReportingAccess, string>(
    'get',
    `/api/v1/Users/${id}/HasPowerReportingAccess`,
  );
}

export async function PutUserSettings(id: string, data: any) {
  return await callHttpApi<{ result?: any; err?: string; }, string>(
    'put',
    `/api/v1/Users/${id}/settings`,
    `"${data}"`,
    null,
    { 'Content-Type' : 'application/json-patch+json' },
  );
}

export async function GetUsers() {
  return await callHttpApi<UserDetailModel[], string>(
    'get',
    `/api/v1/Users`
  );
}

export async function updateUser(user: UserDetailModel) {
  return await callHttpApi<{ userDetail: UserDetailModel }, UserDetailModel>(
    'put',
    `/api/v1/Users/${user.id}`,
    user
  );
}

export interface UserSearchCriteria {
  firstName?: string,
  lastName?: string,
  organizationPaths?: string[],
  offset?: number,
  limit?: number,
  sort?: {[key: string]: string}
}

export interface PaginatedResult<T> {
  limit: number,
  offset: number,
  records: T[],
  total: number,
}

export async function SearchUsers(criteria?: UserSearchCriteria) {
  return await callHttpApi<PaginatedResult<UserDetailModel>, UserSearchCriteria>(
    'post',
    `/api/v1/Users/Search`,
    criteria
  );
}

interface ImpersonateUserResult {
  userDetail: UserDetailModel
}
interface ImpersonateUserResponse {
  result?: ImpersonateUserResult,
  err?: string;
}
export async function ImpersonateUserService(
  impersonationId?: string,
): Promise<ImpersonateUserResponse> {
  return callHttpApi<ImpersonateUserResult, string>(
      'put',
      `/api/v1/Users/impersonate`,
    impersonationId,
    null,
    { "Content-Type": "application/json" }
    );
}

export interface AddRemoveFacilityRequestModel
{
  UserIds:string[],
  Organizations :OrganizationReference[];
}

export interface AddDefaultstoUsersRequestModel
{
  UserIds: string[],
  DefaultFacility: DefaultOrganizationLevel | undefined;
  DefaultDepartment: DefaultOrganizationLevel | undefined;
}

export async function AddFacilityToUserService(
  data : AddRemoveFacilityRequestModel
) {
  return callHttpApi<{ result?: any; err?: string; }, AddRemoveFacilityRequestModel>(
      'put',
      `/api/v1/Users/AddOrganizations`,
    data,
    null,
    { "Content-Type": "application/json" }
    );
}

export async function RemoveFacilityToUserService(
  data : AddRemoveFacilityRequestModel
) {
  return callHttpApi<{ result?: any; err?: string; }, AddRemoveFacilityRequestModel>(
      'put',
      `/api/v1/Users/RemoveOrganizations`,
    data,
    null,
    { "Content-Type": "application/json" }
    );
}

export async function RemoveImpersonateUserService(
  adminOneSourceId?: string,
): Promise<ImpersonateUserResponse> {
  return callHttpApi<ImpersonateUserResult, string>(
      'delete',
      `/api/v1/Users/impersonate/${adminOneSourceId}`,
      undefined,
      null,
      { "Content-Type": "application/json" } 
    );
}

export async function AddDefaultsToUsersService(
  data : AddDefaultstoUsersRequestModel
) {
  return callHttpApi<{ result?: any; err?: string; }, AddDefaultstoUsersRequestModel>(
      'put',
      `/api/v1/Users/AddDefaultsToUsers`,
    data,
    null,
    { "Content-Type": "application/json" }
    );
}
