import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogoManager } from 'models/LogoManagerModel';
import {
  GetLogoDataForOrganization,
  UploadLogoForOrganization,
  LogoManagerResponse,
  DeleteLogoForOrganization,
} from 'services/LogoManagerService';
import { AppThunk } from 'app/rootReducer';
import { FileWithPath } from 'react-dropzone';
import { callService } from 'services/ServicesReducer';

export interface State {
  err?: string;
  logos?: LogoManager[];
}

export const initialState: State = {
  err: '',
  logos: undefined,
};

const reducerSlice = createSlice({
  name: 'LogoManager',
  initialState,
  reducers: {
    
    onReceiveLogoDataForOrganization(
      state,
      action: PayloadAction<LogoManagerResponse>
    ) {
      state.logos = action.payload.result;
      state.err = action.payload.err;
    },
    
    onReceiveUploadForOrganization(
      state,
      action: PayloadAction<LogoManagerResponse>
    ) {
      if(action.payload.result){
        state.logos = action.payload.result;
      }
      state.err = action.payload.err;
    },
    clearError(state) {
      state.err = '';
    },
  },
});

export const {
  onReceiveLogoDataForOrganization,
  onReceiveUploadForOrganization,
  clearError,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getLogoDataForOrganization(
  clientId: string,
  onDone?: () => void
): AppThunk {
  return async dispatch => {
    dispatch(callService('GetLogoForOrganization', async () => {
      const result = await GetLogoDataForOrganization(clientId);
      dispatch(onReceiveLogoDataForOrganization(result));
      if (onDone) onDone();
    }))
  };
}

export function uploadLogoForOrganization(
  clientId: string,
  file: FileWithPath,
  onDone?: (result?: LogoManagerResponse) => void
): AppThunk {
  return async dispatch => {
    dispatch(callService('UploadLogoForOrganization', async () => {
      const result = await UploadLogoForOrganization(clientId, file);
      dispatch(onReceiveUploadForOrganization(result));
      if (onDone) onDone(result);
    }))
  };
}

export function deleteLogoForOrganization(
  clientId: string,
  fileId: string,
  onDone?: (result?: LogoManagerResponse) => void
): AppThunk {
  return async dispatch => {
    dispatch(callService('DeleteLogoForOrganization', async () => {
      const result = await DeleteLogoForOrganization(clientId, fileId);
      if (onDone) onDone(result);
    }))
  };
}

export function clearLogoManagerError(): AppThunk {
  return dispatch => {
    dispatch(clearError()); 
  };
}
