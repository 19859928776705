import React, { useState } from 'react';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { getWebServiceError } from 'features/admin/WebServiceErrorReducer';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import {
  EDS_TextBox,
  EDS_Button,
  EDS_Select,
  EDS_Switch,
} from '@EH/eh.eds.react';
import Table from 'react-bootstrap/Table';
import { IWebServiceErrors } from 'models/WebServiceErrors';
import { formatDate } from 'utils/Utils';
import 'assets/styles/components/_webServiceError.scss';
import moment from 'moment';
import { Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { ReactComponent as Calendar } from 'assets/svgs/icon-calendar.svg';
import { AlertIds, AlertTypes, setAlert } from '../../features/alert/AlertReducer';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setBreadcrumbLinks([{ name: 'WebService Error', slug: rootPath }])
    );

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

export default function WebServiceError(
  props: Props = { rootPath: 'webService-error' }
) {
  const { rootPath } = props;

  const [messageType, setMessageType] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [clientId, setClientId] = useState('');
  const [transactionLogId, setTransactionLogId] = useState('');
  const [byError, setByError] = useState('');
  const [emptyFilter, setEmptyFilter] = useState('');
  const [isErrorParsing, setIsErrorParsing] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const webServiceErrorAlert = { id: AlertIds.WebServiceErrorAlert, type: AlertTypes.Error, message: 'Please complete the required information to continue.' };

  useBreadcrumb(rootPath);

  const messageOptions = [
    {
      key: 'all',
      optionName: 'All',
      value: 'all',
    },
    {
      key: '1001',
      optionName: 'eCN WS',
      value: '1001',
    },
    {
      key: '1002',
      optionName: 'EpicPaymentPage',
      value: '1002',
    },
    {
      key: '1003',
      optionName: 'EpicWS',
      value: '1003',
    },
    {
      key: '1004',
      optionName: 'IU WS',
      value: '1004',
    },
    {
      key: 'MessageType1',
      optionName: 'MessageType1',
      value: 'MessageType1',
    },
  ];

  const defaultSelect = 'All';

  const isProcessing = useTypedSelector(s => s.services.calls.GetWebServiceError?.isProcessing);
  const webServiceError = useTypedSelector(s => s.webServiceError?.result);

  const handleSubmit = () => {
    const params = {
      MessageType: messageType ? messageType : defaultSelect,
      StartDate: startDate,
      EndDate: endDate,
      ClientId: clientId ? clientId : undefined,
      TransactionId: transactionLogId ? transactionLogId : undefined,
      SearchErrorAndResponse: byError ? byError : undefined,
      EmptyFieldsFilter: emptyFilter ? emptyFilter : undefined,
      ErrorInformationParsingEnabled: isErrorParsing,
    };
    setSubmitted(false);
    dispatch(getWebServiceError(params));
    setSubmitted(true);
  };

  const renderWebServiceError = () => {
    if (isProcessing) {
      return '';
    } else if (submitted) {
      if (webServiceError && webServiceError.length) {
        return (
          <>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Transaction Log ID</th>
                  <th>Client ID</th>
                  <th>Date/Time</th>
                  <th>Error Message</th>
                </tr>
              </thead>
              <tbody>
                {webServiceError.map(
                  (row: IWebServiceErrors, index: number) => {
                    return (
                      <tr key={`${row.id}-${index}`}>
                        <td>{row.transactionId}</td>
                        <td>{row.clientId}</td>
                        <td>{formatDate(moment.utc(row.date))}</td>
                        <td>{row.errorMessage}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          </>
        );
      } else {
        return (    
        <div className="server-error">{"No results found"}</div>
        );
      }
    }
  };
  return (
    <>
      <div className="webService-error">
        <div className="webService-error-title">
          <h3>Webservice Error</h3>
        </div>
        <div className="webService-error-controls">
          <Row>
            <div className="col-lg-4">
              <EDS_Select
                name="messageType"
                label="*Message Type:"
                modifiers={'filterItem'}
                options={messageOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setMessageType(messageOptions[e.target.selectedIndex].value);
                }}
                value={messageType}
              ></EDS_Select>
            </div>
            <div className="col-lg-4">
              <label className="filterItem">
                <div className="eds-field_#label">*Start Date:</div>
                <div className="eds-field_#control eds-field_.eds-input eds-input">
                  <div className="full">
                    <DatePicker
                      onChange={(date: Date) => {
                        setStartDate(date);
                      }}
                      dateFormat="MM/dd/yyyy"
                      name="startDate"
                      className="eds-input_#input full"
                      selected={startDate}
                    />
                  </div>
                  <div className="react-datepicker__calendar-icon-wrapper">
                    <span className="calendarIcon">
                      <Calendar />
                    </span>
                  </div>
                </div>
              </label>
            </div>
            <div className="col-lg-4">
              <label className="filterItem">
                <div className="eds-field_#label">*End Date:</div>
                <div className="eds-field_#control eds-field_.eds-input eds-input">
                  <div className="full">
                    <DatePicker
                      onChange={(date: Date) => {
                        setEndDate(date);
                      }}
                      dateFormat="MM/dd/yyyy"
                      name="endDate"
                      className="eds-input_#input full"
                      selected={endDate}
                    />
                  </div>
                  <div className="react-datepicker__calendar-icon-wrapper">
                    <span className="calendarIcon">
                      <Calendar />
                    </span>
                  </div>
                </div>
              </label>
            </div>
            <div className="col-lg-4">
              <EDS_TextBox
                modifiers={'filterItem'}
                name="clientId"
                label="Facility ID:"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setClientId(e.target.value);
                }}
                value={clientId}
              ></EDS_TextBox>
            </div>
            <div className="col-lg-4">
              <EDS_TextBox
                modifiers={'filterItem'}
                name="transactionLogId"
                label="Transaction Log ID:"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTransactionLogId(e.target.value);
                }}
                value={transactionLogId}
              ></EDS_TextBox>
            </div>
            <div className="col-lg-4">
              <EDS_TextBox
                modifiers={'filterItem'}
                name="byError"
                label="Search by Error and Response:"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setByError(e.target.value);
                }}
                value={byError}
              ></EDS_TextBox>
            </div>
            <div className="col-lg-4">
              <EDS_TextBox
                modifiers={'filterItem'}
                name="emptyFilter"
                label="Empty Fields Filter:"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmptyFilter(e.target.value);
                }}
                value={emptyFilter}
              ></EDS_TextBox>
            </div>
            <div className="col-lg-4">
              <EDS_Switch
                modifiers="webServices-error-checkbox"
                name="isErrorParsing"
                label="Enable Error Information Parsing"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIsErrorParsing(e.target.checked);
                }}
                value={isErrorParsing}
              ></EDS_Switch>
            </div>
            <div className="col-lg-4">
              <div className="webService-error-submit">
                <EDS_Button
                  modifiers={'eds-button eds-button.primary'}
                  buttonText={'Submit'}
                  disabled={isProcessing}
                  onClick={
                    !startDate || !endDate
                      ? () => dispatch(setAlert(webServiceErrorAlert))
                      : () => handleSubmit()
                  }
                />
              </div>
            </div>
          </Row>
        </div>

        <div className="webService-error-results">
          {renderWebServiceError()}
        </div>
      </div>
    </>
  );
}
