import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GLPaymentLineItem } from 'models/GLPaymentItem';
import { LineItems } from 'models/LineItem';
import { OrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';

interface GLPaymentDashboard {
  paymentTotal: number;
  paymentType: string;
  lineItems: LineItems;
  facility?: OrganizationLevelDocument;
  department?: OrganizationLevelDocument;
}

export interface State {
  value: GLPaymentDashboard;
  glPaymentItems: GLPaymentLineItem[];
  modalOpen: boolean;
}

export const initialState: State = {
  value: {
    paymentTotal: 0,
    paymentType: '',
    lineItems: [{ amount: 0, isActive: true, id: '0', discount: 0 }],
    facility: {
      id: '',
      path: '|enterprice|facility|',
      name: 'Facility 1',
      organizationLevelType: OrganizationLevelTypes.Facility
    },
    department: {
      id: '',
      path: '|enterprice|facility|department|',
      name: 'Department 1',
      organizationLevelType: OrganizationLevelTypes.Department
    },
  },
  glPaymentItems: [],
  modalOpen: false,
};

export interface GLPaymentItem {
  id: number;
  unitCount: number;
  unitCost: number;
  discount: number;
  description: string;
  accountNumber: string;
  notes: string;
  department: string;
  paymentAmount: number;
}

const reducerSlice = createSlice({
  name: 'GLPaymentDashboard',
  initialState,
  reducers: {
    setGLPaymentInfoState(state, action: PayloadAction<GLPaymentDashboard>) {
      state.value = action.payload;
    },
    setGLPaymentItems(
      state,
      action: PayloadAction<GLPaymentLineItem[] | undefined>
    ) {
      if (action.payload === undefined) {
        state.glPaymentItems = [];
      } else {
        state.glPaymentItems = [...action.payload];
      }
    },
    resetDashboard(state) {
      state.value = initialState.value;
    },
    setInitialState: () => initialState,
    setOpenModal(state, action: PayloadAction<boolean>) {
      state.modalOpen = action.payload;
    },
  },
});

export const {
  setGLPaymentInfoState,
  setGLPaymentItems,
  resetDashboard,
  setInitialState,
  setOpenModal
} = reducerSlice.actions;
export default reducerSlice.reducer;