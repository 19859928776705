import {
  WizardStep,
  WizardState,
  useWizard,
  Wizard,
} from 'features/wizard/Wizard';

export enum VoidSteps {
  confirmation = 'confirmation',
  completed = 'completed',
}

export function getWizardSteps(): WizardStep[] {
  return [
    { name: 'confirm', slug: VoidSteps.confirmation },
    { name: 'completed', slug: VoidSteps.completed },
  ];
}

function getInitialWizardState(): WizardState {
  const steps = getWizardSteps();
  return {
    steps,
    currentStep: steps[0],
  };
}

export function useVoidWizzard(): Wizard {
  return useWizard(getInitialWizardState());
}
