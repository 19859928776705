import { EDS_Button } from '@EH/eh.eds.react';
import { useTypedSelector } from '../../../app/rootReducer';

export function EditStartStepButtons(props: {
  cancel?: () => void,
  onNext?: () => void,
  disabled?: boolean,
}) {
  const {
    cancel,
    disabled,
    onNext
  } = props;

  const isProcessing = useTypedSelector(s => s.services.calls.createOrUpdatePaymentPlan?.isProcessing);

  return <div className="footer-buttons">
    <div className="footer-flex">
      <EDS_Button
        modifiers={'mr-2 eds-button eds-button.basic'}
        name={'cancel'}
        buttonText={'Cancel'}
        onClick={cancel}
      />
      <EDS_Button
        modifiers={'eds-button eds-button.primary button-reverse'}
        name={'createPaymentPlan'}
        buttonText={isProcessing ? 'Processing' : 'Next'}
        onClick={onNext}
        iconName={'chevron_right'}
        disabled={disabled}
      />
    </div>
  </div >
}