import React from 'react';
import { CheckBoxTree } from 'components/checkBoxTree/CheckBoxTree';
import styles from '../planSearch.module.scss';
import { FilterCheckBoxes } from './FilterCheckBoxes';
import { TreeFilterItem, updateRecord } from '../PaymentPlanSearchReducer';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../app/rootReducer';

export function SideFilters(props: {
  tenderTypes?: TreeFilterItem[];
  }) {
  const { tenderTypes } = props;
  const planSearch = useTypedSelector(s => s.paymentPlanSearchInfo.value);
  const dispatch = useDispatch();

  const tenderTypesCheckBoxTree: CheckBoxTree = {
    nodes: tenderTypes?.filter(tenderType => !tenderType.parent).map(tenderType => ({
      ...tenderType,
      children:
        tenderType.label === 'Credit/Debit'
          ? tenderTypes.filter(ccType => ccType.parent === tenderType.label)
          : undefined,
    })) ?? [],
  };

  function getItemsFromTree(tree: CheckBoxTree) {
    const items: TreeFilterItem[] = []
    tree.nodes.forEach(node => {
      if (node.children) {
        node.children.forEach(child => items.push(child));
      }
      delete node.children;
      items.push(node);
    })

    return items;
  }

  return (
    <>
      <div className={styles.sideFiltersContainer}>
        <FilterCheckBoxes
          group="tenderTypes"
          groupDisplayName="*Tender Types"
          displayClearButton={true}
          checkBoxTree={tenderTypesCheckBoxTree}
          setCheckboxTree={(newTree: CheckBoxTree) => { dispatch(updateRecord({ ...planSearch, tenderType: getItemsFromTree(newTree) })) }}
        />
      </div>
    </>
  );
}
