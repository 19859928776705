import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { EDS_TextBox, EDS_Select, EDS_Button, EDS_Checkbox } from '@EH/eh.eds.react';
import { calculateFinalPaymentDate, checkNumberHasValue, checkReceivedHasValues, checkStringHasValue, displayAmount, formatDateString, formatMoney, template as getFilledTemplate, valueOrDefault } from 'utils/Utils';
import Footer from 'features/footer/Footer';
import { PaymentPlanModel } from '../../models';
import { mockTermsOptions, updateRecord } from './PaymentPlanReducer';
import BalanceHeader from './infoSections/BalanceHeader';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import '../../assets/styles/components/_paymentPlan.scss';
import { Label } from 'reactstrap';
import { getAttribute, getValidator } from '../../utils/metadata/MetaDataUtils';
import usePlanValidator from './validators/usePlanValidator';
import useTier from './hooks/useTier';
import { convertCurrencyToNumber, displayTermType } from './PlanUtils';
import { PlanOperations, ValidatorType } from '../../models/metaData/MetaDataEnums';
import DatePicker from 'react-datepicker';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { convertDateToString, convertDateToUtc } from '../../utils/UtilsDateTime';
import { useTypedSelector } from "app/rootReducer";
import { ReactComponent as Calendar } from 'assets/svgs/icon-calendar.svg';
import { AlertIds, AlertTypes, removeAlert, setAlert } from '../alert/AlertReducer';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import { EnumTermType } from 'models/enums/EnumPaymentPlan';
import { PaymentPlanConfiguration } from '../../models/PaymentPlanConfiguration';
import { EditFinalStepButtons } from './buttons/EditFinalStepButtons';
import { Wizard } from '../wizard/Wizard';
import { isEditFinalStep } from '../planDetails/useEditPlanTerms';

const PAYMENT_PLAN_TERMS = 'Payment plan terms';
const PAYMENT_TERMS = 'Payment Terms';
const PAYMENT_DATE = 'Payment Date';
const NUMBER_OF_PAYMENTS = 'Number of Payments';
const PAYMENT_AMOUNT = 'Payment Amount';
const PAYMENT_AUTHORIZATION = 'Payment Authorization';
const NOTIFICATION_EMAIL = 'Recurring Payment Notification Email (optional):';
const NOTIFICATION_EMAIL_TEXT = 'To send automatic notifications of upcoming recurring payments, payments made and other plan events, please enter a valid email address.';

export default (props: {
  onContinue?: () => void;
  onBack?: () => void;
  cancel?: () => void;
  total?: number;
  amount?: number;
  discount?: number;
  paymentPlanId?: string | null;
  wizard: Wizard;
  patientId?: string;
  selectedOrganizationPath?: string;
}) => {
  const dispatch = useDispatch();
  const { onContinue, total = 0, amount, onBack, cancel, paymentPlanId, wizard, patientId, selectedOrganizationPath } = props;
  let paymentPlan = useTypedSelector(s => s.paymentPlanInfo.records?.find(p => p.id == paymentPlanId)); 
  const operation = useTypedSelector(s => s.paymentPlanInfo.operation);
  const patientInfo = useTypedSelector(s => s.patientInfo.value);
  const { getFacilityByDepartment } = useOrganizations();
  const facility = getFacilityByDepartment(
    checkReceivedHasValues(paymentPlan?.organization?.path,
      paymentPlan?.organization?.path,
      patientInfo.department?.path)
  );

  const glPaymentDashboard = useTypedSelector(i => i.glPaymentDashboard?.value);
  const path = checkReceivedHasValues(paymentPlan?.isGl, glPaymentDashboard?.facility?.path, paymentPlan?.organization?.path);
  const paymentPlanConfiguration = useTypedSelector(
    state => state.paymentPlanInfo.configurations[valueOrDefault(facility?.path, '')]?.configuration
  );
  const { rangeTier } = useTier(!!paymentPlan?.isGl, total, path);
  const { havePermission } = useLoggedUserPermissions();
  const tierOverride = havePermission(UserPermissions.OverridePaymentPlanTiers, facility?.path);
  const { validate, validateAll, attributes, errorClass, getErrorMessage } = usePlanValidator(paymentPlan, rangeTier, tierOverride, paymentPlanConfiguration);
  const planOperation = useTypedSelector(i => i.paymentPlanInfo?.operation);
  const displayDate = checkReceivedHasValues(paymentPlan?.id, paymentPlan?.nextPaymentDate, getStartDate(paymentPlan?.startDate));
  const [selectedDisplayDate, setSelectedDisplayDate] = useState(checkReceivedHasValues(displayDate, getUTCDateFromCurrentDate(displayDate), null));
  const back = () => {
    if (onBack) {
      onBack();
    }
  };

  let termTypeDisplay = displayTermType(1, valueOrDefault(paymentPlan?.termType, EnumTermType.None));
  if (termTypeDisplay?.startsWith('bi-week')) {
    termTypeDisplay = '2 weeks';
  }

  const getTemplateByNumberOfPayments = (termType?: string, numberOfPayments?: number, templateOnePayment?: string,
    templateMultiplePayments?: string, templateMultiplePaymentsFotNotMonthlyTermTypes?: string, templateMultiplePaymentsForWeeklyAndBiWeeklyTermTypes?: string
  ) => {
    numberOfPayments ||= 1;

    let template: string | undefined

    if (numberOfPayments === 1) {
      template = templateOnePayment
    }

    else if (numberOfPayments > 1) {
      template = mapTemplate(termType, template,
        templateMultiplePaymentsForWeeklyAndBiWeeklyTermTypes,
        templateMultiplePayments,
        templateMultiplePaymentsFotNotMonthlyTermTypes);
    }

    return template || '';
  }

  function getUTCDateFromCurrentDate (date: string) {
    let newDate = moment(date).toDate();
    newDate = newDate && (new Date(newDate?.getUTCFullYear(), newDate?.getUTCMonth(), newDate?.getUTCDate()));
    return newDate;
  }

  function mapTemplate(termType: string | undefined,
    template: string | undefined,
    templateMultiplePaymentsForWeeklyAndBiWeeklyTermTypes: string | undefined,
    templateMultiplePayments: string | undefined,
    templateMultiplePaymentsFotNotMonthlyTermTypes: string | undefined) {
    switch (termType?.toLowerCase()) {
      case 'weekly':
      case 'biweekly':
        template = templateMultiplePaymentsForWeeklyAndBiWeeklyTermTypes;
        break;

      case 'monthly':
        template = templateMultiplePayments;
        break;

      case 'quarterly':
      case 'annually':
        template = templateMultiplePaymentsFotNotMonthlyTermTypes;
        break;
    }

    return template;
  }

  const getTemplate = (template: string) => {
    const planDate = checkReceivedHasValues(paymentPlan?.id, convertDateToString(selectedDisplayDate), getStartDate(paymentPlan?.startDate));

    return getFilledTemplate(template, {
      FacilityName: checkStringHasValue(facility?.name),
      PaymentAmount: displayAmount(paymentPlan?.paymentAmount),
      PaymentsRemaining: checkNumberHasValue(paymentPlan?.paymentsRemaining),
      StartDateDayOrdinal: formatDateString(planDate, 'Do'),
      StartDateOrdinal: formatDateString(planDate, 'MMMM Do, YYYY'),
      TermType: valueOrDefault(termTypeDisplay, '')
    });
  }

  const authorizationTextTemplate = getTemplateByNumberOfPayments(
    paymentPlan?.termType,
    paymentPlan?.paymentsRemaining,
    paymentPlanConfiguration?.templates?.PaymentAuthorizationText,
    paymentPlanConfiguration?.templates?.PaymentsAuthorizationText,
    paymentPlanConfiguration?.templates?.PaymentsAuthorizationTextFotNotMonthlyTermTypes,
    paymentPlanConfiguration?.templates?.PaymentsAuthorizationTextFotNotMonthlyTermTypes
  );

  const authorization_text = getTemplate(authorizationTextTemplate);

  const authorizationConfirmationTextTemplate = getTemplateByNumberOfPayments(
    paymentPlan?.termType,
    paymentPlan?.paymentsRemaining,
    paymentPlanConfiguration?.templates?.PaymentAuthorizationConfirmationText,
    paymentPlanConfiguration?.templates?.PaymentsAuthorizationConfirmationText,
    paymentPlanConfiguration?.templates?.PaymentsAuthorizationConfirmationTextForNotMonthlyTermTypes,
    paymentPlanConfiguration?.templates?.PaymentsAuthorizationTextForWeeklyAndBiWeeklyTermTypes
  );

  const plan_authorization_text_checkbox = getTemplate(authorizationConfirmationTextTemplate);

  const closeErrorMessage = () => {
    dispatch(removeAlert(planAlert))
  };
  const planAlert = { id: AlertIds.PlanAlert, type: AlertTypes.Error, message: "" }

  const dropDownChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!paymentPlan) return;
    let selectElement = event.target;
    let propertyName = selectElement.name;
    let optionIndex = selectElement.selectedIndex;
    let selectedOption = selectElement.options[optionIndex];
    dispatch(
      updateRecord({
        id: paymentPlan.id,
        paymentPlan: {
          ...paymentPlan,
          [propertyName]: selectedOption.value,
        },
      })
    );
  };

  const checkboxHandler = (event: React.ChangeEvent<HTMLInputElement>, propertyName: keyof PaymentPlanModel) => {
    if (!paymentPlan) return;

    dispatch(updateRecord({
      id: paymentPlan.id,
      paymentPlan: { ...paymentPlan, [propertyName]: !paymentPlan[propertyName] }
    }));

    let value = event.target.checked;
    if (value) {
      closeErrorMessage();
    }
  }

  const changeDateHandler = (date: Date, name: keyof PaymentPlanModel) => {
    if (!paymentPlan) return;
    setSelectedDisplayDate(date);
    dispatch(
      updateRecord({
        id: paymentPlan.id,
        paymentPlan: { ...paymentPlan, [name]: formatDateString(convertDateToString(date), 'MM/DD/YYYY')},
      })
    );
  };

  const changeDateHandlerBlur = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!paymentPlan) return;
    let currentDate = getCurrentDateBetweenLimits(event.target.value, getMinDate().toISOString(), getMaxDate().toISOString());
    validate(getAttribute(attributes, 'startDate'), currentDate);

    dispatch(
      updateRecord({
        id: paymentPlan.id,
        paymentPlan: {
          ...paymentPlan,
          startDate: moment(currentDate).format(),
          nextPaymentDate: moment(currentDate).format(),
        },
      })
    );
  };

  const numberOfPaymentsChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!paymentPlan) return;
    const propertyName = event.target.name;
    let value: number = +event.target.value < 1 ? 1 : +event.target.value;
    setnumberOfPaymentCycles(event.target.value);
    const recalculatedPaymentAmount = displayAmount((total || 0) / value);

    if (event.target.type == "range") {
      validate(getAttribute(attributes, "paymentAmount"), recalculatedPaymentAmount);
      validate(getAttribute(attributes, "paymentsRemaining"), value);
    }

    dispatch(
      updateRecord({
        id: paymentPlan.id,
        paymentPlan: {
          ...paymentPlan,
          [propertyName]: value,
          paymentAmount: recalculatedPaymentAmount,
        },
      })
    );
  };

  const decimalValidator = getValidator(getAttribute(attributes, "paymentAmount"), ValidatorType.DecimalValidator);

  const paymentAmountChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!paymentPlan) return;
    const value = event.target.value;

    const { left, right } = decimalValidator?.value || {};
    if (left && right && !new RegExp(`^\\d{0,${left}}(\\.\\d{0,${right}})?$`).test(value)) return;

    let numericValue = convertCurrencyToNumber(value);

    const recalculatedInstallments =
      (value && total && numericValue > 0 && Math.ceil(total / convertCurrencyToNumber(value))) || 0;
    
    const stringForPaymentsRemaining = valueOrDefault(recalculatedInstallments, paymentPlan.paymentsRemaining).toString();
    setnumberOfPaymentCycles(stringForPaymentsRemaining);
    
    dispatch(
      updateRecord({
        id: paymentPlan.id,
        paymentPlan: {
          ...paymentPlan,
          paymentAmount: value,
          paymentsRemaining:
            recalculatedInstallments || paymentPlan.paymentsRemaining,
        },
      })
    );
  };

  const notificationEmailChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!paymentPlan) return;
    const value = event.target.value;

    dispatch(
      updateRecord({
        id: paymentPlan.id,
        paymentPlan: {
          ...paymentPlan,
          notificationEmail: value,
        },
      })
    );
  };

  const { onContinueClick } = useOnContinue(validateAll, onContinue, paymentPlan);

  useEffect(() => {
    if (paymentPlan?.id) {
      validate(getAttribute(attributes, 'nextPaymentDate'), formatDateString(selectedDisplayDate, 'MM/DD/YYYY'));
      validate(getAttribute(attributes, 'paymentAmount'), paymentPlan?.paymentAmount);
      validate(getAttribute(attributes, 'paymentsRemaining'), paymentPlan?.paymentsRemaining);
    } else {
      validate(getAttribute(attributes, 'startDate'), paymentPlan?.startDate);
    }
  }, [paymentPlan]);

  useEffect(() => {
    startPaymentTermsDefaultValue(paymentPlan, paymentPlanConfiguration?.defaultPaymentPlanTerm, dispatch, planOperation);
  }, [])

  useEffect(() => {
    updateDisplayPaymentPlanEndDate(paymentPlan?.id, convertDateToString(selectedDisplayDate), getStartDate(paymentPlan?.startDate), paymentPlan?.termType, paymentPlan?.paymentsRemaining, setFinalPaymentDate);
  }, [paymentPlan?.id, paymentPlan?.nextPaymentDate, paymentPlan?.startDate, paymentPlan?.termType, paymentPlan?.paymentsRemaining]);

  const footerChildren = getFooterChildren({
    back,
    cancel,
    onContinueClick,
    paymentPlan,
    total,
    operation,
    wizard,
    patientId,
    selectedOrganizationPath,
    planOperation
  })

  function showInputError(name: keyof PaymentPlanModel) {
    const message = getErrorMessage(name);
    return message.length ? <Label className={`error-message-required`}>
      {message}
    </Label> : null;
  }

  const onBlur = (name: keyof PaymentPlanModel) => {
    if (name === "paymentAmount") {
      if (paymentPlan) {
        let paymentAmount = paymentPlan.paymentAmount;
        validate(getAttribute(attributes, "paymentAmount"), paymentAmount);
        validate(getAttribute(attributes, "paymentsRemaining"), paymentPlan && paymentPlan.paymentsRemaining);
        setEdittingPaymentAmount(false);
      }
      return;
    }
    if (name === "paymentsRemaining") {
      validate(getAttribute(attributes, "paymentAmount"), paymentPlan && paymentPlan.paymentAmount);
    }

    validate(getAttribute(attributes, name), paymentPlan && paymentPlan[name]);
  }

  const [edittingPaymentAmount, setEdittingPaymentAmount] = useState(false);
  const [numberOfPaymentCycles, setnumberOfPaymentCycles] = useState(paymentPlan?.paymentsRemaining?.toString() ?? '1');
  const [finalPaymentDate, setFinalPaymentDate] = useState(paymentPlan?.finalPaymentDate);

  const dateField = checkReceivedHasValues(paymentPlan?.id, "nextPaymentDate", "startDate");

  function getMinDate() {
    return moment().add(1, 'day').toDate()
  }

  function getMaxDate() {
    const maxDayInFuture  = checkReceivedHasValues(paymentPlanConfiguration, paymentPlanConfiguration?.startDateMaxDaysOut, 60);
    return moment().add(checkReceivedHasValues(planOperation == PlanOperations.AddToPlan, 365, maxDayInFuture), 'days').toDate();
  }

  return (<>
    <div className="payment-plan-container">
      <div className="payment-plan-form-container">
        <BalanceHeader total={total} amount={amount} />
        <div className="flex-row">
          <div className="row-item eds-heading eds-heading.mdplus-caps mb-1">
            {PAYMENT_PLAN_TERMS.toUpperCase()}
          </div>
        </div>
        <div className="flex-row">
          <EDS_Select
            name="termType"
            label={`${PAYMENT_TERMS}:`}
            modifiers={'row-item row-item-size'}
            options={mockTermsOptions}
            onChange={dropDownChangeHandler}
            value={paymentPlan?.termType}
          />
          <div className={`eds-field row-item row-item-size ${errorClass(dateField)}`}>
            <div className="eds-field_#label">{PAYMENT_DATE}:</div>
            <div className={'eds-field_#control eds-field_.eds-input eds-input'} >
              <div className="react-datepicker-date-row position-left">
                <DatePicker
                  selected={selectedDisplayDate}
                  onChange={(date: Date) => changeDateHandler(date, dateField)}
                  onBlur={changeDateHandlerBlur}
                  dateFormat="yyyy-MM-dd"
                  name={dateField}
                  maxDate={getMaxDate()}
                  minDate={getMinDate()}
                  className="eds-input_#input"
                  customInput={<input className={"date-input"} type="date" />}
                  isClearable={false}
                  disabled={isDateInputDisabled(paymentPlan, paymentPlanConfiguration)}
                />
              </div>
              <div className="react-datepicker-clendar-icon">
                <span className={'calendarIcon'}><Calendar /></span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="half-row">
            <EDS_TextBox
              name="paymentsRemaining"
              label={`${NUMBER_OF_PAYMENTS}:`}
              modifiers={`${errorClass("paymentsRemaining")}`}
              placeHolder={'Please enter number of payments'}
              onChange={numberOfPaymentsChangeHandler}
              onBlur={() => onBlur("paymentsRemaining")}
              value={numberOfPaymentCycles}
            />
            {showInputError("paymentsRemaining")}
          </div>
          <div style={{ width: '15px' }}></div>
          <div className="half-row">
            <EDS_TextBox
              label={PAYMENT_AMOUNT}
              name="paymentAmount"
              modifiers={`${errorClass("paymentAmount")}`}
              placeHolder={'Please enter payment amount'}
              value={edittingPaymentAmount ? paymentPlan?.paymentAmount : formatMoney(paymentPlan?.paymentAmount, decimalValidator?.value.left || 12, decimalValidator?.value.right || 2)}
              onChange={paymentAmountChangeHandler}
              onBlur={() => onBlur("paymentAmount")}
              onFocus={() => setEdittingPaymentAmount(true)}
            />
            {showInputError("paymentAmount")}
          </div>
        </div>
        <div className="flex-row">
          <label className="eds-field row-item row-item-size-double">
            <div className="eds-field_#label left-label">
              {rangeTier?.minNumberOfPayments}
            </div>
            <div className="eds-field_#label right-label">
              {rangeTier?.maxNumberOfPayments}
            </div>
            <div className="slider-container">
              <input
                type="range"
                name="paymentsRemaining"
                className="slider"
                value={paymentPlan?.paymentsRemaining}
                onChange={numberOfPaymentsChangeHandler}
                min={rangeTier?.minNumberOfPayments}
                max={rangeTier?.maxNumberOfPayments}
              ></input>
            </div>
          </label>
        </div>
        <div className="flex-row">
          <label className="eds-field row-item row-item-size-double">
            {`Payment Plan End Date:  ${finalPaymentDate}`}
          </label>
        </div>
        <div className="flex-row">
          <div className="row-item eds-heading eds-heading.mdplus-caps mb-1">
            {PAYMENT_AUTHORIZATION.toUpperCase()}
          </div>
        </div>
        <div className="flex-row">
          <div className='authorization-text'>{authorization_text}</div>
        </div>
        <div className="flex-row">
          <EDS_Checkbox
            name={'authorized'}
            label={plan_authorization_text_checkbox}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => checkboxHandler(e, "authorized")}
            modifiers={'row-item row-item-size-double authorization-checkbox mb-4'}
            checked={!!paymentPlan?.authorized}
          />
        </div>
        <div className="flex-row">
          <EDS_TextBox
            label={NOTIFICATION_EMAIL}
            name="notificationEmail"
            placeHolder={'Enter email address'}
            value={paymentPlan?.notificationEmail}
            onChange={notificationEmailChangeHandler}
          />
          {showInputError("notificationEmail")}
        </div>
        <div className="flex-row">
          <div className='authorization-text'>{NOTIFICATION_EMAIL_TEXT}</div>
        </div>
      </div>
    </div>
    <Footer children={footerChildren} />
  </>);
};



export function startPaymentTermsDefaultValue(paymentPlan?: PaymentPlanModel, defaultPaymentPlanTerm?: string, dispatch?: any, planOperation?: PlanOperations) {
  if (!paymentPlan || planOperation == PlanOperations.EditPlan || planOperation == PlanOperations.AddToPlan) return;
  if (defaultPaymentPlanTerm && paymentPlan?.termType) {
    let result = defaultPaymentPlanTerm;

    switch (defaultPaymentPlanTerm) {
      case "None":
        result = EnumTermType.None;
        break;
      case "Weekly":
        result = EnumTermType.Weekly;
        break;
      case "Bi-Weekly":
        result = EnumTermType.BiWeekly;
        break;
      case "Monthly":
        result = EnumTermType.Monthly;
        break;
      case "Quarterly":
        result = EnumTermType.Quarterly;
        break;
      case "Annually":
        result = EnumTermType.Annually;
        break;
    }
    dispatch(
      updateRecord({
        id: paymentPlan.id,
        paymentPlan: {
          ...paymentPlan,
          termType: result,
        },
      })
    );
    return result;
  }
};

export function getStartDate(startDate: string | undefined) {
  const todayDate = moment().format('MM/DD/YYYY');
  startDate = moment(startDate).format('MM/DD/YYYY');
  return todayDate == startDate ? moment(startDate).add(1, 'day').toLocaleString() : startDate;
}

export function updateDisplayPaymentPlanEndDate(paymentPlanId: string | undefined | null, nextPaymentDate: string | undefined, startDate: string | undefined, termType: string | EnumTermType | undefined, paymentsRemaining: number | undefined, setFinalPaymentDate: any) {
  let result;
  let selectedNextPayment = paymentPlanId ? nextPaymentDate : startDate;

  if (paymentsRemaining && paymentsRemaining <= 1) {
    result = formatDateString(selectedNextPayment, 'MM/DD/YYYY');
  } else {
    result = paymentsRemaining ? calculateFinalPaymentDate(selectedNextPayment, startDate, termType, paymentsRemaining - 1) : undefined;
  }

  result && setFinalPaymentDate(result);
  return result;
}

function getCurrentDateBetweenLimits(currentDate: string, minDate: string, maxDate: string) {
  return (!currentDate || currentDate < minDate || currentDate > maxDate) ? minDate : currentDate;
}

function isDateInputDisabled(paymentPlan?: PaymentPlanModel, paymentPlanConfiguration?: PaymentPlanConfiguration) {
  return !!(paymentPlan?.id && !paymentPlanConfiguration?.allowPlanDateChange)
}

function getFooterChildren(props: {
  back: () => void,
  cancel?: () => void | undefined,
  onContinueClick: (onSuccess?: ()=>void) => void,
  paymentPlan?: PaymentPlanModel,
  total: number,
  operation: PlanOperations,
  wizard: Wizard,
  patientId?: string,
  selectedOrganizationPath?: string,
  planOperation: PlanOperations
}
) {
  const { back, cancel, onContinueClick, paymentPlan, total, operation, wizard, patientId, selectedOrganizationPath, planOperation } = props;
  const disabled = !paymentPlan?.paymentsRemaining ||
    !paymentPlan?.startDate ||
    paymentPlan.termType === EnumTermType.None;

  let footerButtons = (
    <div className="footer-buttons">
      <EDS_Button
        modifiers={'eds-button eds-button.basic'}
        name={'back'}
        buttonText={'Back'}
        onClick={back}
        iconName={'chevron_left'}
        disabled={![PlanOperations.EditPlan, PlanOperations.AddToPlan, PlanOperations.CreatePlan].includes(planOperation)}
      />
      <div className="footer-flex">
        <EDS_Button
          modifiers={'mr-2 eds-button eds-button.basic'}
          name={'cancel'}
          buttonText={'Cancel'}
          onClick={cancel}
        />
        <EDS_Button
          modifiers={'eds-button eds-button.primary button-reverse'}
          name={'next'}
          ariaLabel={'Next'}
          buttonText={'Next'}
          onClick={onContinueClick}
          disabled={disabled}
          iconName={'chevron_right'}
        />
      </div>
    </div>
  );


  if (isEditFinalStep(operation, wizard.state.currentStep)) {
    footerButtons = <EditFinalStepButtons
      cancel={cancel}
      disabled={disabled}
      onContinue={onContinueClick}
      total={total}
      paymentPlan={paymentPlan}
      patientId={patientId}
      selectedOrganizationPath={selectedOrganizationPath}
    />
  }

  return footerButtons;
}

function useOnContinue(validateAll: (plan: PaymentPlanModel) => boolean, onContinue?: () => void, paymentPlan?: PaymentPlanModel) {
  const dispatch = useDispatch();
  const payment_plan_terms_not_accepted = `Please agree to the payment plan terms to continue.`;
  const payment_plan_required = `Plan editing has failed.`;

  const closeErrorMessage = () => {
    dispatch(removeAlert(planAlert))
  };
  const planAlert = { id: AlertIds.PlanAlert, type: AlertTypes.Error, message: "" }
  const openErrorMessage = (message: string) => {
    dispatch(setAlert({ ...planAlert, message }))
  };

  const onContinueClick = (onSuccess?: () => void) => {
    if (!paymentPlan?.authorized) {
      openErrorMessage(payment_plan_terms_not_accepted);
      return;
    }

    if (!validateAll(paymentPlan)) {
      openErrorMessage(payment_plan_required);
      return;
    }

    closeErrorMessage();
    if (typeof onSuccess === 'function') {
      onSuccess();
    } else {
      onContinue && onContinue();
    }
  };

  return { onContinueClick }
}