import { GridConfig } from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';
import { getUserName } from 'utils/Utils';
import { convertDateToDisplayString, convertStringToDate } from 'utils/UtilsDateTime';

export const data: GridConfig[] = [
  { label: 'Plan ID', colId: '_id', sortable: true, field: 'id', cellRenderer: 'PlanLinkRenderer', pinned: 'left', suppressMovable: true, resizable: false, width: 220 },
  { label: 'Plan Status', colId: 'Status', sortable: true, field: 'status' },
  { label: 'Plan Balance', colId: 'Balance', sortable: true, field: 'balance', filter: 'agNumberColumnFilter', filterParams: 'inRange', valueFormatter: 'currency', cellStyle: 'right' },
  { label: 'Payment Amount', colId: 'PaymentAmount', sortable: true, field: 'paymentAmount', filter: 'agNumberColumnFilter', filterParams: 'inRange', valueFormatter: 'currency', cellStyle: 'right' },
  { label: 'Plan Term Type', colId: 'TermType', sortable: true, field: 'termType', valueFormatter: 'termType' },
  { label: 'Payments Remaining', colId: 'PaymentsRemaining', sortable: true, field: 'paymentsRemaining', filter: 'agNumberColumnFilter', filterParams: 'inRange', cellStyle: 'center ' },
  { label: 'Tender Type', colId: 'TenderType', sortable: true, field: 'tenderType', valueFormatter: 'tenderType' },
  { label: 'Card Brand', colId: 'CardBrand', sortable: true, field: 'cardBrand', cellRenderer: 'cardBrand' },
  { label: 'Method Ending In', colId: 'TenderMaskedAccount', sortable: false, field: 'tenderMaskedAccount', valueFormatter: 'creditCard' },
  { label: 'Plan Created Date', colId: 'CreatedDate', sortable: true, field: 'createdDate', filter: 'agDateColumnFilter', filterParams: 'inRange', valueFormatter: 'dateString', getValue: (plan) => plan && 'createdDate' in plan ? convertDateToDisplayString(convertStringToDate(plan?.createdDate ?? '')) : '' },
  { label: 'Plan Start Date', colId: 'StartDate', sortable: true, field: 'startDate', filter: 'agDateColumnFilter', filterParams: 'inRange', valueFormatter: 'dateString' },
  { label: 'Plan Next Payment Date', colId: 'NextPaymentDate', sortable: true, field: 'nextPaymentDate', filter: 'agDateColumnFilter', filterParams: 'inRange', valueFormatter: 'dateString' },
  { label: 'Plan Final Payment Date', colId: 'FinalPaymentDate', sortable: true, field: 'finalPaymentDate', filter: 'agDateColumnFilter', filterParams: 'inRange', valueFormatter: 'dateString' },
  { label: 'Plan Stalled Date', colId: 'StalledDate', sortable: true, field: 'stalledDate', filter: 'agDateColumnFilter', filterParams: 'inRange', valueFormatter: 'dateString' },
  { label: 'Plan Created by User', colId: 'CreatedBy.FirstName', sortable: true, field: 'createdBy', getValue: (plan) => plan && 'createdBy' in plan ? getUserName(plan.createdBy, true) : '' },
  { label: 'Plan Last Modified by User', colId: 'UpdatedBy.FirstName', sortable: true, field: 'updatedBy', getValue: (plan) => plan && 'updatedBy' in plan ? getUserName(plan.updatedBy, true) : '' },
  { label: 'Facility Name', colId: 'Organization.Name', sortable: true, field: 'facilityName', getValue: (plan, utils) => plan?.organization?.path ? utils.getFacilityByDepartment(plan?.organization?.path)?.name ?? '' : '' },
  { label: 'Department Name', colId: 'Department.Name', sortable: true, field: 'departmentName', getValue: (plan) => plan?.department?.name ?? '' },
  { label: 'MRN', colId: 'PatientMrn', sortable: true, field: 'patientMrn' },
  { label: 'Patient First Name', colId: 'PatientFirstName', sortable: true, field: 'patientFirstName' },
  { label: 'Patient Last Name', colId: 'PatientLastName', sortable: true, field: 'patientLastName' },
  { label: 'Patient Date of Birth', colId: 'PatientDateOfBirth', sortable: true, field: 'patientDateOfBirth', filter: 'agDateColumnFilter', filterParams: 'inRange' , valueFormatter: 'dateOfBirthString' },
  { label: 'Guarantor ID', colId: 'PatientGuarantorAccountNo', sortable: true, field: 'patientGuarantorAccountNo' },
  { label: 'Payer First Name', colId: 'PayerFirstName', sortable: true, field: 'payerFirstName' },
  { label: 'Payer Last Name', colId: 'PayerLastName', sortable: true, field: 'payerLastName' },
  { label: 'Notes', colId: 'Notes', sortable: true, field: 'notes', cellRenderer: 'popup' },
  { label: 'Plan Type', colId: 'IsGL', sortable: true, field: 'isGL', valueFormatter: 'isGl' },
  { label: 'Payment Source', colId: 'PaymentSource', sortable: true, field: 'paymentSource' },
];
