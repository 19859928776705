import {
  EDS_Select,
  EDS_Switch
} from '@EH/eh.eds.react';
import moment from "moment";
import "assets/styles/components/_transactionLookup.scss";
import DateRange from "components/dateRange/DateRange";
import { convertStringToDate } from "utils/UtilsDateTime";
import { useState } from "react";
import { InputField } from "pages/Search/planSearch/MainFilters/InputField";
import { Button } from "@EHDS/core";
import { AgGridReact } from "ag-grid-react";
import { GridReadyEvent, IGetRowsParams, GridApi, IDatasource } from "ag-grid-community";
import { useDispatch } from 'react-redux';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';
import { buildCriteria, filterEmptyFields, formatDateWithTime, getSortOptions, getValueOrDefault } from 'utils/Utils';
import { EnumPaymentSource } from 'models/enums/EnumPaymentSource';
import { getTransactionsApiLogSearchService, GetTransactionsApiResult } from "services/TransactionApiService";

interface TransactionLookupData {
  [key: string]: any;
}

const paymentOriginationOptions = [
  { value: '', optionName: 'Select' },
  { value: EnumPaymentSource.EPIC, optionName: 'Epic' },
  { value: EnumPaymentSource.ECareNext, optionName: 'ECN' },
  { value: EnumPaymentSource.PaymentSafe, optionName: 'PaymentSafe' },
];


const commonColumnfilter = {
  filter: 'agTextColumnFilter',
  filterParams: {
    buttons: ['reset', 'apply'],
    suppressAndOrCondition: true,
    closeOnApply: true,
    filterOptions: ['contains'],
  },
};

const headerDefinitions = [
  { headerName: 'Transaction ID', field: 'transactionId', sortable: true, resizable: true, ...commonColumnfilter, maxWidth: 160 },
  { headerName: 'Facility ID', field: 'facilityId', sortable: true, ...commonColumnfilter, resizable: true, maxWidth: 160 },
  { headerName: 'Date', colId: 'createdDate', field: 'createdDate', sortable: true, resizable: true,
    cellRenderer: (params: { value: string; data: { id: any } }) => { 
      if(params.value) {
        return formatDateWithTime(moment.utc(params.value));
      }
      return '-';
    }
  },
  { headerName: 'Error Message', field: 'errorMessage', resizable: true },
  { headerName: 'Error Data', field: 'errorData',  resizable: true, cellRenderer: 'textCell', minWidth: 450 },
  { headerName: 'Payment Origination Response Sent', field: 'paymentOriginationRequest', autoHeight: true, resizable: true, cellRenderer: 'textCell', minWidth: 350 },
  { headerName: 'Payment Origination Response Received', field: 'paymentOriginationResponse', autoHeight: true, resizable: true, cellRenderer: 'textCell', minWidth: 350 },
  { headerName: 'Payment Gateway Response Sent', field: 'paymentGatewayRequest', resizable: true, autoHeight: true, cellRenderer: 'textCell', minWidth: 350 },
  { headerName: 'Payment Gateway Response Received', field: 'paymentGatewayResponse', resizable: true, autoHeight: true, cellRenderer: 'textCell', minWidth: 350 },
];

export function TransactionLookup() {
    const dispatch = useDispatch();
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [transactionData, setTransactionData] = useState<TransactionLookupData>();    
    const [showErrorMessages, setShowErrorMessages] = useState(true);
    const [showTable, setShowTable] = useState(false);
    const [missingRequiredFields, setMisingRequiredFields] = useState(false);
    
    const isDisabled = !transactionData?.facilityId || !transactionData?.filter?.paymentSource?.filter || !transactionData?.createdDateMin || !transactionData?.createdDateMax;

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      let propertyName = event.target.name;
      let value = event.target.value.trim();

      if(propertyName === 'paymentSource') {
        setTransactionData({ ...transactionData, filter: { [propertyName] : {filterType: 'text', type: 'contains', filter: value} } });
      } else {
        setTransactionData({ ...transactionData, [propertyName]: value });
      }
    };

    const changeDateHandler = (
      date: string,
      field: string,
      ) => {
      setTransactionData({ ...transactionData, [field]: moment(date).isValid() ?  moment(date).format('YYYY-MM-DDTHH:mm:ss.000') : '' });
    };

    function onGridReady(params: GridReadyEvent) {
      setGridApi(params.api);
      updateData(filterFailedTransactions, renderAlert, params.api, transactionData);
    }
    
    const renderAlert = () => {
      dispatch(setAlert({
        id: AlertIds.TransactionLogs,
        type: AlertTypes.Error,
        message: "No logs found",
        dismissable: true
      }));
    };

    const filterFailedTransactions = (transactionLogs: GetTransactionsApiResult[]) => {
      if(showErrorMessages) {
        return transactionLogs.filter(transaction => transaction.errorMessage);
      }
      return transactionLogs;
    };

    function searchForTransactions() {
      if(isDisabled) {
        dispatch(setAlert({
          id: AlertIds.TransactionLogs,
          type: AlertTypes.Error,
          message: "Please enter all required fields",
          dismissable: true
        }));
        setMisingRequiredFields(true);
      } else {
        setShowTable(false);
        window.setTimeout(() => setShowTable(true), 0);
        setMisingRequiredFields(false);
      }
    }

    const showTransactionTable = () => {
      if(!showTable) return null;

      return (
        <div className="transaction-table">
          <span className="table-header">WEBSERVICE LOG</span>
          <div className="ag-theme-alpine table-container">
            <AgGridReact
              columnDefs={headerDefinitions}
              frameworkComponents={{
                textCell: TextCell
              }}
              onGridReady={onGridReady}
              pagination={false}
              domLayout={'autoHeight'}
              rowModelType={'infinite'}
              gridAutoHeight={true}
              suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
              enableCellTextSelection={true}
              suppressMenuHide={true}
              ensureDomOrder={true}
              suppressDragLeaveHidesColumns={true}
              rowHeight={120}
            />
          </div>
        </div>
      )
    };

    return (
        <div>
            <h1 className="title">WebService Error Logging Tool</h1>

            <div className="controls">
              <EDS_Select
                data-testid="payment-source-select"
                name="paymentSource"
                label="*Payment Origination:"
                options={paymentOriginationOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  changeHandler(e)
                }}
                value={transactionData?.paymentSource}
                modifiers={`control-item ${!transactionData?.filter?.paymentSource?.filter && missingRequiredFields ? 'invalidField' : ''}`}
              ></EDS_Select>
              <DateRange
                className='date-range'
                labelFirstField="*Start Date:"
                labelSecondField="*End Date:"
                nameFrom="createdDateMin"
                nameTo="createdDateMax"
                value={[transactionData?.createdDateMin, transactionData?.createdDateMax]}
                minDateTo={(transactionData?.createdDateMin && convertStringToDate(transactionData?.createdDateMin)) || undefined}
                maxDateTo={new Date()}
                showTime={true}
                showError={(!transactionData?.createdDateMin || !transactionData?.createdDateMax) && missingRequiredFields}
                showIcon
                onChangeFrom={date =>
                  changeDateHandler(getValueOrDefault(date, ''), 'createdDateMin')
                }
                onChangeTo={date =>
                  changeDateHandler(getValueOrDefault(date, ''), 'createdDateMax')
                }
              />

              <InputField
                label="Gateway Reference Number:"
                name="gatewayReferenceNumber"
                placeHolder="Enter Gateway Reference Number"
                value={transactionData?.gatewayReferenceNumber}
                onChange={e => changeHandler(e)}
                className="input-field gateway"
              />

            </div>

            <div className="controls">
            <InputField
                label="User ID:"
                name="oneSourceUserId"
                placeHolder="Enter User ID"
                value={transactionData?.oneSourceUserId}
                onChange={e => changeHandler(e)}
                className="input-field"
              />

              <InputField
                label="GUID:"
                name="tokenUserGUID"
                placeHolder="Enter GUID"
                value={transactionData?.tokenUserGUID}
                onChange={e => changeHandler(e)}
                className="input-field"
                
              />

              <InputField
                label="Transaction ID:"
                name="transactionId"
                placeHolder="Enter Transaction ID"
                value={transactionData?.transactionId}
                onChange={e => changeHandler(e)}
                className="input-field"
              />

              <InputField
                label="*Facility ID:"
                name="facilityId"
                placeHolder="Enter Facility ID"
                value={transactionData?.facilityId}
                onChange={e => changeHandler(e)}
                className={`input-field noArrows`}
                type='number'
                error={!transactionData?.facilityId && missingRequiredFields}
              />
            </div>

            <div className="buttons-container">
            <span className='switch-label'>Show Error Messages Only</span>
            <EDS_Switch
              name="showErrorMessages"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
               setShowErrorMessages(e.target.checked);
              }}
              checked={showErrorMessages}
              modifiers={'toggle-button toggle-container'}
            />

            <Button onClick={() => searchForTransactions()}>
              <span className="title-button-text"> Submit </span>
            </Button>
            </div>

            <div className="divide-line"></div>
            {showTransactionTable()}
        </div>
    )
}

export function updateData( 
  filterFailedTransactions: (transactionLogs: GetTransactionsApiResult[]) => GetTransactionsApiResult[], 
  renderAlert: () => void, 
  gridApi?: GridApi, 
  transactionData?: TransactionLookupData) 
  {
  gridApi?.showLoadingOverlay();
  const dataSource: IDatasource = {
    async getRows(rowsParams: IGetRowsParams) {

      const response = await getTransactionsApiLogSearchService({
        ...filterEmptyFields(transactionData || {}),
        ...(buildCriteria && filterEmptyFields(buildCriteria(rowsParams))),
        sort: getSortOptions(rowsParams.sortModel),
      });

      if (response.result && response.result?.records.length) {
        const records = response.result?.records;

        const startRow = rowsParams.startRow;
        const endRow = rowsParams.endRow;
        const results = filterFailedTransactions(records).slice(startRow, endRow);
        setTimeout(() => {
          rowsParams.successCallback(results, results.length);
        }, 500);

      } else {
        gridApi?.showNoRowsOverlay();
        rowsParams.successCallback([], 0);
        renderAlert();
      }
    }
  }
  gridApi?.setDatasource(dataSource);
  gridApi?.hideOverlay();
}

interface TextCellRendererProps {
  value: string;
}

export const TextCell: React.FC<TextCellRendererProps> = ({ value }) => {
  const formattedText = value && value.length > 50 ? value.match(/.{1,50}/g)?.join('\n') : value;

  return (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {getValueOrDefault(formattedText, '-')}
    </div>
  );
};