import React from 'react';
import styles from './checkBoxTree.module.css';

export interface CheckBoxNode {
  propertyName: string;
  label: string;
  isChecked: boolean;
  alternateName?: string;
  alternateDisplayName?: string;
  children?: CheckBoxNode[];
}

export interface CheckBoxTree {
  nodes: CheckBoxNode[];
}

function renderNode(
  node: CheckBoxNode,
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    checkBoxNode: CheckBoxNode
  ) => void
) {
  return (
    <label
      className={styles.checkBoxNode}
      key={node.propertyName}
      htmlFor={node.propertyName}
      aria-label={node.label}
    >
      <input
        className={'eds-checkbox_#input'}
        type={'checkbox'}
        checked={node.isChecked}
        name={node.propertyName}
        data-testid={node.propertyName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, node)}
      />
      <span className={`eds-checkbox_#label`}>{node.label}</span>

      {node.children ? (
        <div className={styles.childrenCheckBoxContainer}>
          {node.children.map(element => renderNode(element, onChange))}
        </div>
      ) : null}
    </label>
  );
}

export function CheckBoxTreeComponent(props: {
  tree: CheckBoxTree;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    checkBoxNode: CheckBoxNode
  ) => void;
}) {
  const { tree, onChange } = props;

  const checkboxes = tree.nodes.map(node => renderNode(node, onChange));

  return <div className={styles.checkBoxTreeContainer}>{checkboxes}</div>;
}
