import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PermissionState from 'models/enums/PermissionState';
import { PermissionItem } from 'models/Permission';

export interface State {
  value: PermissionItem[];
}

export const initialState: State = {
  value: [],
};

const reducerSlice = createSlice({
  name: 'PermissionsComponent',
  initialState,
  reducers: {
    /** Replaces all the permission data items.*/
    setPermissions(state, action: PayloadAction<PermissionItem[]>) {
      state.value = action.payload;
    },
    /** Sets the next state for a permission data item by toggling its currrent state*/
    togglePermission(state, action: PayloadAction<PermissionItem>) {
      const permission = action.payload;
      if (!permission.id) return;
      const curStateIndex = state.value.findIndex(
        p => p.name === permission.name && p.groupName === permission.groupName
      );
      state.value[curStateIndex].state =
        permission.state === PermissionState.Active
          ? PermissionState.Inactive
          : PermissionState.Active;
    },
    /** Sets the next state for a permission data item. */
    setPermissionState(
      state,
      action: PayloadAction<{
        permission: PermissionItem;
        nextState: PermissionState;
      }>
    ) {
      const { permission, nextState } = action.payload;
      if (!permission.name) return;
      const curStateIndex = state.value.findIndex(
        p => p.name === permission.name && p.groupName === permission.groupName
      );
      state.value[curStateIndex].state = nextState;
    },
  },
});

export const {
  setPermissions,
  togglePermission,
  setPermissionState,
} = reducerSlice.actions;
export default reducerSlice.reducer;
