import { Attribute } from 'models/metaData/MetaData';
import { DataType, ValueType } from 'models/metaData/MetaDataEnums';
import moment from 'moment';
import {
  getLabel,
  getValueType,
} from 'utils/metadata/MetaDataUtils';
import { LineItem } from '../../../models/LineItem';
import { displayAmount, formatDate, roundDecimalNumber } from '../../../utils/Utils';

function getDataTypeValue(dataType : DataType, prepend: string, value: any){
  if (dataType === DataType.String) {
    value = `${prepend !== '%' ? prepend : ''}${value}${prepend === '%' ? prepend : ''}`;
  }

  if (dataType === DataType.Money) {
    value = !isNaN(value) ? `$${displayAmount(value)}` : '-';
  }

  if (dataType === DataType.Date) {
    value = formatDate(moment.utc(value));
  }

   return value;
}

function getPrepend(format?: string){
  switch(format) { 
    case '$0.00':
    case '$.00':{
      return '$'; 
    } 
    case '0.00':
    case '.00':
    case 'mm/dd/yyyy': { 
      return ''; 
    }
    case '%':{
      return '%';
    }
    default: { 
      return ''; 
    } 
  }
};

export function Preview(props: {
  attribute: Attribute;
  value: any;
  lineItem: LineItem;
  onChange?: (value: any) => void;
  modifiers?: string;
}) {
  const { attribute, modifiers } = props;
  let { value } = props;

  const label = getLabel(attribute);
  const dataType = attribute.dataType;
  const valueType = getValueType(attribute);
  const prepend = getPrepend(attribute.format);

  if (value) {
    value = getDataTypeValue(dataType, prepend, value);
    if (valueType === ValueType.Discount) {
      value = `-${displayAmount(value)}`;
    }
    else if(valueType === ValueType.Percent){
      value = `${roundDecimalNumber(parseFloat(value), 2)}%`;
    }
     
    if ((attribute.format === '.00' || attribute.format === '$.00') && Math.trunc(parseFloat(value.replace('$',''))) < 1) {
      const splitedNumber = (value + "").split(".");
      value = `${prepend}.${splitedNumber[1] ?? ''}`;
    }    
  }
  
  return (
    <div className={modifiers?.replace('required-field', '')}>
      <p className={'line-item-attribute-label'}>{label}</p>
      <p>{value ? value : '-'}</p>
    </div>
  );
}
