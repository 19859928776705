import React from 'react';
import 'bootstrap/scss/bootstrap.scss';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'assets/styles/components/_transactionDetails.scss';
import {  AgGridReact } from 'ag-grid-react';
import { displayAmount } from '../../../../utils/Utils';
import { AccountsProps, commonColumnHeaders } from '../common/CommonColumnHeaders';
import './Accounts.scss'

export function Accounts(props: AccountsProps): JSX.Element {
  const { accounts, isCompleted } = props;

  if (!accounts) {
    return <></>;
  }

  const headerDefinitions = commonColumnHeaders();

  if (isCompleted) headerDefinitions.push({
    headerName: 'Void Amount',
    headerClass: 'customHeader',
    field: 'amount',
    sortable: true,
    filter: true,
    resizable: true,
    cellStyle: {color: '#333333'},
    cellRenderer: (params: { value: string; }) => {
      return `($${displayAmount(params.value)})`;
    }
  });

  return (
    <div className="ag-theme-alpine accounts-grid">      
      <AgGridReact
        gridAutoHeight={true}
        defaultColDef={{ flex: 1 }}
        columnDefs={headerDefinitions}
        rowData={accounts}
        suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
        pinnedBottomRowData={[{
          id: 'TOTAL:',
          amount: displayAmount(accounts.reduce((sum, l) => sum + l.amount, 0 )), 
        }]} 
        rowClassRules={{
          'customPinnedRowStyle': function (params) {
            const id = params.data.id;
            return id === "TOTAL:";
          },
        }}         
      />          
    </div>
  );
}
