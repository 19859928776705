import {
  EnumTransactionStatusType,
} from 'models/enums';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import {
  TransactionDetailsField,
  TransactionDetailsUIData,
} from '../TransactionDetailsReducer';
import { buildTransactionStatus } from './BuildTransactionStatus';

export function buildTransactionDetailsUIData(
  transactionDetails: TransactionDetailsModel,
): TransactionDetailsUIData {
  let mapPaymentLocation: TransactionDetailsField[] = [];
  mapPaymentLocation.push({
    name: 'Facility',
    type: 'text',
    value: 'Fake Experian Health',
  });
  mapPaymentLocation.push({
    name: 'Department',
    type: 'text',
    value: 'Fake Customer Support',
    isEditable: true,
  });
  mapPaymentLocation.push({
    name: 'User',
    type: 'text',
    value: 'Fake Scott McKissak',
  });

  let mapPaymentDetailsColumn1: TransactionDetailsField[] = [];
  mapPaymentDetailsColumn1.push({
    name: 'Original Transaction ID',
    type: 'transactionId',
    value: transactionDetails.originalTransactionId,
  });
  mapPaymentDetailsColumn1.push({
    name: 'Transaction ID',
    type: 'transactionId',
    value: transactionDetails.id,
  });
  mapPaymentDetailsColumn1.push({
    name: 'Authorization Code',
    type: 'text',
    value: 'Fake 896426',
  });
  mapPaymentDetailsColumn1.push({
    name: 'Date/Time',
    type: 'text',
    value: '',
  });
  mapPaymentDetailsColumn1.push({
    name: 'Transaction Type',
    type: 'text',
    value: transactionDetails.transactionType,
  });
  mapPaymentDetailsColumn1.push({
    name: 'Tender Type',
    type: 'text',
    value: transactionDetails.paymentMethod,
  });
  mapPaymentDetailsColumn1.push({
    name: 'Patient ID',
    type: 'text',
    value: transactionDetails.patientId,
  });
  mapPaymentDetailsColumn1.push({
    name: 'Patient Name',
    type: 'text',
    value: `${transactionDetails.patientFirstName} ${transactionDetails.patientLastName}`,
    isEditable: true,
  });
  mapPaymentDetailsColumn1.push({
    name: 'Patient Date of Birth',
    type: 'date',
    value: '',
    isEditable: true,
  });
  mapPaymentDetailsColumn1.push({
    name: 'Patient Notes',
    type: 'text',
    value: 'Fake Notes',
  });

  let mapPaymentDetailsColumn2: TransactionDetailsField[] = [];
  mapPaymentDetailsColumn2.push({
    name: 'Payer Name',
    type: 'text',
    value: 'Fake Vinnie Caliudo',
    isEditable: true,
  });
  mapPaymentDetailsColumn2.push({
    name: 'Payer Address',
    type: 'text',
    value: 'Fake 123 Plook St.',
    isEditable: true,
  });
  mapPaymentDetailsColumn2.push({
    name: '---PayerAddressSecondLine',
    type: 'text',
    value: 'Fake Kanoga Park, CA 12345',
    isEditable: true,
  });
  mapPaymentDetailsColumn2.push({
    name: 'Payer Phone #',
    type: 'tel',
    value: '724.527.2761',
    isEditable: true,
  });
  mapPaymentDetailsColumn2.push({
    name: 'Payer Email',
    type: 'email',
    value: 'fake.somename@example.com',
    isEditable: true,
  });
  mapPaymentDetailsColumn2.push({
    name: 'Payment Amount',
    type: 'text',
    value: transactionDetails.amount + '',
  });
  mapPaymentDetailsColumn2.push({
    name: 'Status',
    type: 'text',
    value: buildTransactionStatus(transactionDetails),
  });
  mapPaymentDetailsColumn2.push({
    name: 'Provider Message',
    type: 'text',
    value: 'Fake Transaction Approved',
  });

  let relatedTransactions = buildRelatedTransactions(transactionDetails);

  return {
    transactionDetails: transactionDetails,
    paymentLocation: {
      name: 'Payment Location',
      fields: mapPaymentLocation,
    },

    paymentDetailsColumn1: {
      name: 'Payment Details Column 1',
      fields: mapPaymentDetailsColumn1,
    },
    paymentDetailsColumn2: {
      name: 'Payment Details Column 2',
      fields: mapPaymentDetailsColumn2,
    },
    relatedTransactions,
  };
}

function buildRelatedTransactions(transactionDetails: TransactionDetailsModel) {
  let relatedTransactions = transactionDetails.relatedTransactions || [];

  return relatedTransactions.map(t => ({
    ...t,
    statusType: EnumTransactionStatusType[t.statusType],
  }));
}
