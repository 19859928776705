import { forwardRef, useRef, useEffect } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Tier as PaymentPlanTier,PaymentPlanTiersCalculatedBy } from 'models/PaymentPlanConfiguration';
import { useValueRef } from '../../../gridCells/useValueRef';
import MultiSelect, { MultiSelectOption } from '../../../../components/select/MultiSelect';
import { enumToMultiSelectOptions } from 'utils/Utils';

interface GenericInputCellProps<T> extends ICellRendererParams {
  isEditor: boolean;
  onChange: (
    idx: number,
    field: keyof T,
    value: string,
    rowData: T[]
  ) => {};
  agGridReact: {
    props: {
      rowData: T[];
    };
  };
}

interface TierGridDataInterface extends PaymentPlanTier{
}

interface ValidatedRenderCell extends GenericInputCellProps<TierGridDataInterface> {
  errorMessage?: string,
  clientOrganizationPath: string,
  onInputCellSelectValueChanged: (selected: any, params:any) => void,
  errorClass: (p: string) => string,
  getErrorMessage: (p: string) => string
}

export default forwardRef((params: ValidatedRenderCell, ref) => {
  const { colDef,data, onInputCellSelectValueChanged,errorClass, getErrorMessage } = params;
  const field = colDef.field as keyof TierGridDataInterface;
  const [value, setValue] = useValueRef(params.value, ref);
  const refInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    refInput.current?.focus();
  }, []);

  const options = enumToMultiSelectOptions(PaymentPlanTiersCalculatedBy);
  const onValueChange = (inputValue: MultiSelectOption) => {  
    setValue(inputValue.label);
    onInputCellSelectValueChanged(inputValue?.value, params);
  }

  return (
    <>
      <MultiSelect
        data-testid={field + params.node.rowIndex}
        label={"label"}
        name={field}
        options={options}
        onChange={
          onValueChange
        }
        values={value}
        multiple={false}
        searchCheckbox={false}
        disableCloseOnSelect={true}
      />
      <div className={errorClass(field + data.tId)}>{getErrorMessage(field + data.tId)}</div>
    </>
  );
});