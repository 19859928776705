import ConfigurationTemplate from "../../../features/admin/clientConfiguration/ConfigurationTemplate";
import { useEffect, useState } from "react";
import { Metadata } from "../../../features/admin/epic/whitelist/WhitelistReducer";
import { useEpicTokenUserMappings } from "../../../features/admin/epic/tokenMappings/useEpicTokenUserMappings";
import { ICellRendererParams } from "ag-grid-community";
import { GenericAttribute } from "../../../models/metaData/MetaData";
import TextBox from "../../../features/componentFactory/wrapperComponents/TextBox";
import MultiSelect, { MultiSelectOption } from "../../../components/select/MultiSelect";
import { DataType } from "../../../models/metaData/MetaDataEnums";
import 'assets/styles/components/_epicDepartmentMapping.scss'
import { useTokenUserMappingValidator } from "../../../features/admin/epic/tokenMappings/useTokenUserMappingValidator";
import { EpicTokenUserMapping } from "../../../services/EpicTokenUserMappingService";
import { useGetTokens } from "../../../features/admin/epic/userTokenManagement/useGetTokens";
import { UserToken } from "../../../services/TokenGUIDLookupService";
import { useParams } from "react-router";
import { useOrganizations } from "../../../features/organizations/hooks/useOrganizations";
import { MappingActions } from "../../../features/admin/epic/tokenMappings/MappingActions";
import MappingsActionbar from "../../../features/admin/epic/tokenMappings/MappingsActionbar";
import { Input } from "@EHDS/core";
import { MappingGrid } from "./EpicDepartmentMappingPage";
import { OrganizationLevelReference } from "../../../models/OrganizationLevelDocument";

export default function EpicTokenUserMappingPage() {
  let { clientId } = useParams<{ clientId: string }>();
  const { getOrganizationById, getFacilityTradingPartnerPath, getTradingPartnerByFacility } = useOrganizations();
  let selectedOrganization = getOrganizationById(clientId);
  const tradingPartner = getTradingPartnerByFacility(selectedOrganization?.path);

  const [tokens, setTokens] = useState<UserToken[]>([])
  const { getTokens } = useGetTokens(setTokens);

  const tradingPartnerPath = getFacilityTradingPartnerPath(selectedOrganization?.path);
  useEffect(() => {
    if (tradingPartnerPath) {
      getTokens(tradingPartnerPath)
    }
  }, [tradingPartnerPath])


  const tokenReferenceOptions = tokens.map(t => ({ value: t.id, label: `${t.id} (${getOrganizationById(t.organizationReference?.id)?.clientId ?? ""})` }));

  const [filter, setFilter] = useState("");
  const [filteredMappings, setFilteredMappings] = useState<EditableEpicTokenUserMapping[]>([]);
  function getUpdatedMappings() {
    const updated = [...filteredMappings]
    dataRef.current.forEach(mapping => {
      const idx = updated.findIndex(m => getId(m) === getId(mapping))
      if (updated[idx]) {
        updated[idx] = {
          ...updated[idx],
          ...mapping
        }
      }
    });
    return updated;
  }

  const { mappings, deleteMappings, dataRef, storeMapping, saveMappings } = useEpicTokenUserMappings(
    filteredMappings,
    setFilteredMappings,
    setFilter,
    selectedOrganization,
  );

  const [selectedMappings, setSelectedMappings] = useState<string[]>([]);

  const { validate, validateAll, getErrorMessage, getAttribute } = useTokenUserMappingValidator(getUpdatedMappings());

  function setTokenUserReference(mapping: EditableEpicTokenUserMapping, tokenReferenceId: string) {
    const tokenReference = tokens.find(r => r.id === tokenReferenceId);
    const oldData = dataRef.current.find(t => getId(t) === getId(mapping));
    tokenReference && storeMapping({ ...oldData, tokenReference }, getId(mapping));
  }

  function setMapKey(mapping: EditableEpicTokenUserMapping, mapKey: string) {
    const oldData = dataRef.current.find(t => getId(t) === getId(mapping));
    storeMapping({ ...oldData, mapKey }, getId(mapping));
  }

  const createNewMapping = (tempId: string) => {
    return { id: tempId, mapKey: "", facility: selectedOrganization, organization: tradingPartner, metadata: { tempId: tempId, isEditing: true } }
  }

  const { onDeleteAction, onEditAction, onSaveAction, onAddNewAction  } = MappingActions({
    dataRef,
    deleteMappings: (ids: string[]) => { deleteMappings(ids, filteredMappings, tradingPartnerPath) },
    filteredMappings,
    getUpdatedMappings,
    saveMappings: (mappings: EditableEpicTokenUserMapping[]) => { saveMappings(mappings, tradingPartnerPath) },
    selectedMappings,
    setFilteredMappings,
    setSelectedMappings,
    validateAll,
    createNewMapping
  });

  return <ConfigurationTemplate
    title="Epic User Token Mapping"
    headerButtons={<MappingsActionbar
      selectedMappings={selectedMappings}
      addNewMappings={onAddNewAction}
      onSaveAction={onSaveAction}
      onDelete={onDeleteAction }
      onEdit={onEditAction}
    />}
  >
    <>
      <div className="search-row">
        <Input
          className={"search-input"}
          label="Search:"
          placeholder="Map key or department..."
          value={filter}
          onChange={e => {
            const value = e.target.value;
            setFilter(value);
            setFilteredMappings(mappings.filter(m => m.mapKey?.includes(value) || m.tokenReference?.id?.includes(value)));
          }}
        />
      </div>
      <MappingGrid
        rowData={filteredMappings}
        setSelectedMappings={setSelectedMappings}

        columnDefs={[{
          headerName: "Map Key",
          field: 'mapKey',
          cellRenderer: 'EditCell',
          cellRendererParams: () => {
            return {
              setMapKey,
              storeMapping,
              validate: (value?: any, currentEntity?: EditableEpicTokenUserMapping) => {
                validate(getAttribute("mapKey"), value, false, currentEntity)
              },
              getErrorMessage,
            }
          },
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          minWidth: 300
        },
        {
          headerName: "Token User GUID",
          field: 'token',
          editable: false,
          cellRenderer: "TokenReferenceDropdownCell",
          cellRendererParams: {
            tokenReferenceOptions,
            setTokenUserReference
          },
          suppressSizeToFit: true,
          resizable: false,
          flex: 1,
        }]}

        frameworkComponents={{
          TokenReferenceDropdownCell,
          EditCell,
        }}
      />
    </>
  </ConfigurationTemplate>
}

export interface GenericEditCellProps<T> extends ICellRendererParams {

  storeMapping: (data: T, index: string) => void;
  data: T,
  attribute: GenericAttribute<T>,
  commitWorkingCopies: () => void,
  validateAll: (validOnly: boolean, currentData: T, idx: number) => boolean,
  getErrorMessage: (key: string) => string,
  tokenReferenceOptions: MultiSelectOption[],
  setTokenUserReference: (mapping: EditableEpicTokenUserMapping, tokenReferenceId: string) => void,
  setMapKey: (mapping: EditableEpicTokenUserMapping, mapKey: string) => void,
  validate: (value: any, data: T) => void,
  dataRef: any,
}
export interface EditableEpicTokenUserMapping extends EpicTokenUserMapping {
  metadata?: Metadata,
  errors?: string;
  organization?: OrganizationLevelReference;
}

export function EditCell(params: GenericEditCellProps<EditableEpicTokenUserMapping>) {
  const { value, data, validate, getErrorMessage, setMapKey } = params;
  const [inputValue, setInputValue] = useState<string>(value ?? null);

  return data.metadata?.isEditing ? <div className={"url-edit"} >
    <TextBox
      value={inputValue}
      attribute={{ name: "mapKey", dataType: DataType.String }}
      onChange={value => {
        const updated = { ...data, mapKey: value };
        setInputValue(value);
        setMapKey(updated, value);
        validate(value, updated);
      }}
      noLabel={true}
      error={getErrorMessage("mapKey" + getId(data))}
    />
  </div> : <div>{value}</div>
}

export function getId(data: EditableEpicTokenUserMapping) {
  return data?.id ?? data?.metadata?.tempId ?? ""
}
export function TokenReferenceDropdownCell(params: GenericEditCellProps<EditableEpicTokenUserMapping>) {
  const { data, setTokenUserReference, tokenReferenceOptions } = params;
  const option = tokenReferenceOptions.find(o => o.value === data.tokenReference?.id) ?? null
  const [selectedOption, setSelectedOption] = useState<MultiSelectOption | null>(option);

  return data.metadata?.isEditing ? <div className={"url-edit"} >
    <MultiSelect
      label=""
      multiple={false}
      options={tokenReferenceOptions}
      onChange={(selected: MultiSelectOption | null) => {
        setSelectedOption(selected)
        setTokenUserReference(data, selected?.value ?? "");
      }}
      values={selectedOption}
      disableCloseOnSelect={false}
      name={"tokenReference"}
    />
  </div> : <div>{data.tokenReference?.id}</div>
}
