import { GridColumnsChangedEvent, GridReadyEvent, RowNode } from "ag-grid-community";
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { calculateBalance, LineItemType } from "features/paymentDashboard/LineItemComponent";
import { PaymentPlanModel } from "models";
import { LineItem } from "models/LineItem";
import { Attribute } from "models/metaData/MetaData";
import { TransactionDetailsModel } from "models/TransactionDetailsModel";
import EnumTransactionType from 'models/enums/EnumTransactionType';
import useAccountDetails, { getIsTotalAmountAttribute, getCellValue, Source, getTotalAmountAttribute, getIsGl } from "./hooks/useAccountDetails";
import { getNegativeNumber, getAmount, getBalance, checkReceivedHasValues,nameof } from "utils/Utils";
import { useTypedSelector } from "../../app/rootReducer";
import useGetConfiguration from "../admin/paymentPanel/serviceHandlers/useGetConfiguration";
import { useEffect } from "react";
import { useOrganizations } from "../organizations/hooks/useOrganizations";
import { reformatDate } from "utils/UtilsDateTime";

const getDashboard = (source: Source, isGl?: boolean) => {
  if(source === Source.PaymentPlan) {
    return "PaymentPlanDashboard"
  } else if (isGl) {
    return "GlPaymentDashboard"
  } else {
    return "PaymentDashboard"
  }
}

export function AccountDetails(props: {
  source: Source,
  data?: TransactionDetailsModel | PaymentPlanModel,
}) {
  const { source, data } = props;
  const { isGl, lineItems} = useAccountDetails(data);
  const totalAmountAttribute = getTotalAmountAttribute(isGl, data?.transactionType);
  const { getFacilityByDepartment } = useOrganizations();
  const path = data?.department?.path && checkReceivedHasValues(data.department.path.trim() !== '', data?.department?.path, data?.organization?.path);
  const facility = getFacilityByDepartment(path);
  const { getPanelConfiguration } = useGetConfiguration(facility?.path);
  const panelDashboard = getDashboard(source, isGl);
 
  useEffect(() => {
    if(facility?.path){
      getPanelConfiguration(panelDashboard);
    }
  }, [isGl, facility?.path]);

  const configurationAttributes = useTypedSelector(s => isGl ?
    s.paymentPanel.configurations.GlPaymentDashboard?.entity?.attributes : s.paymentPanel?.configurations?.[panelDashboard]?.entity?.attributes
  ) ?? [];
  
  const attributes = [...configurationAttributes];
  attributes.push(totalAmountAttribute);  
  const currencySymbol = checkReceivedHasValues(source == Source.PaymentPlan, '', undefined);

  const getHeaderName = (attr: Attribute) => {
    if(attr.saveAs !== 'amount') {
      return attr.label;   
    }
    return 'Original Amount';
  }

  const headerDefinitions = attributes.map((attr, index) => ({
      headerName: getHeaderName(attr),
      field: attr.name,
      sortable: false,
      resizable: true,
      filter: false,
      cellRenderer: (params: { data: LineItem, node: RowNode }) => {   
        const isRowPinned = params.node.isRowPinned();
        if(attr.dataType == 'Date' && !isRowPinned){ //hack to format correctly date in AG grid
          return reformatDate(params.data[attr.name],'MM/DD/YYYY')
        }

        return checkReceivedHasValues(params.node.rowPinned,
          getPinnedCellValue(attr, params.data, index, data?.transactionType),
         getTableCellValue(attr, params.data, isGl, currencySymbol, data?.transactionType))
      }
    })
  );

  function sizeColumnsToFit(params: GridReadyEvent | GridColumnsChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  
  const rowData = lineItems?.map(lineItem => ({...lineItem, id: data?.id}));
  const isMoreThanOneLineItem = data?.lineItems && data?.lineItems?.length > 1;

  return configurationAttributes.length ? (
    <div className="ag-theme-alpine">
      <AgGridReact
        columnDefs={headerDefinitions}
        onGridReady={sizeColumnsToFit}
        onGridColumnsChanged={sizeColumnsToFit}
        rowData={rowData}
        pagination={false}
        domLayout={'autoHeight'}
        gridAutoHeight={true}
        suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
        enableCellTextSelection={true}
        ensureDomOrder={true}
        rowPinned="bottom"
        pinnedBottomRowData={checkReceivedHasValues(isMoreThanOneLineItem, getPinnedRowData(source, data), undefined)}
      />
    </div>
  ) : <div>No results found</div>;
}

export function getIsCreditVoid(transactionType?: EnumTransactionType) {
  return transactionType == EnumTransactionType.Void || transactionType == EnumTransactionType.Credit;
}

export function getTotalAmount(
  lineItem: LineItem, 
  isGl?: boolean, 
  transactionType?: EnumTransactionType
) {
  let lineItemType = LineItemType.Sub;
  if (isGl) {
    const isGlCreditVoid = 
      transactionType == EnumTransactionType.GLCredit || 
      transactionType == EnumTransactionType.GLVoid
    ;
    lineItemType = checkReceivedHasValues(isGlCreditVoid, LineItemType.GlCreditVoid, LineItemType.Gl);
  }
  let value: any = calculateBalance(lineItemType, lineItem);

  const isCreditVoid = getIsCreditVoid(transactionType);
  if (isCreditVoid) {
    value = getNegativeNumber(value);
  }
  return value;
}

export function getTableCellValue(
  attribute: Attribute, 
  lineItem?: LineItem, 
  isGl?: boolean, 
  currencySymbol?: string,
  transactionType?: EnumTransactionType
) {
  if (!lineItem) return '';
  let value = lineItem[attribute.name];

  const isTotalAmountAttribute = getIsTotalAmountAttribute(attribute, transactionType);
  if (isTotalAmountAttribute && isGl) {
    value = getTotalAmount(lineItem, isGl, transactionType);
  }
  return getCellValue(attribute, value, currencySymbol);
}

export function getPinnedRowData(
  source: Source, 
  data?: TransactionDetailsModel | PaymentPlanModel, 
) {
  if (!data) return;
  const isGl = getIsGl(data);
  
  if (source == Source.PaymentPlan && !isGl && 'balance' in data) {
    const rowData: any = {};
    rowData[nameof<LineItem>('amount')] = data?.lineItems && getAmount(data.lineItems);
    rowData[nameof<LineItem>('balance')] = data?.lineItems && getBalance(data.lineItems);
    return [rowData];
  }
}

export function getPinnedCellValue(attribute: Attribute, lineItem: LineItem, index?: number, transactionType?: EnumTransactionType) {
  if (!index) return 'Total';

  const isTotalAmountAttribute = getIsTotalAmountAttribute(attribute, transactionType);
  if (isTotalAmountAttribute) {
    return getCellValue(attribute, lineItem[attribute.name]);
  }
}