import { MultiSelectOption } from 'components/select/MultiSelect';
import { PredefinedData } from 'models/admin/RemitConfiguration';
import { PredefinedDataType } from 'models/RemitPredefinedDataModel';
import { useTypedSelector } from '../../../../app/rootReducer';

export function useRemitPredefinedData() {
  let remitPredefinedData = useTypedSelector(s =>
      s.remitConfiguration.remitPredefinedData
  );

  const getMultiSelectOptions = (type: PredefinedDataType, arr: any[] = []): MultiSelectOption[] => {
    return arr.filter(s => s.type == type).map(s => {
      return { label: s.value, value: s.value }
    });
  }

  const getPredefinedData = (): PredefinedData => {
    return {
      netFormattings: getMultiSelectOptions(PredefinedDataType.Formatting, remitPredefinedData?.predefinedData),
      replaceRegexes: getMultiSelectOptions(PredefinedDataType.ReplaceRegex, remitPredefinedData?.predefinedData),
    };
  };

  return { getPredefinedData };
}
