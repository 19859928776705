enum UserPermissions {
  None,
  FinancialAdministation = 102,
  MakePayment = 118,
  CreatePaymentPlan = 122,
  EditPaymentPlan = 125,
  Search = 137,
  SearchPlans = 138,
  GLPaymentAccess = 139,
  PaymentCash = 366,
  PaymetCreditCardMannual = 367,
  PaymentCreditCardDevice = 368,
  PaymenteCheck = 369,
  PaymentMoneyOrder = 370,
  PaymentPaperCheck = 371,
  PaymentCheckScanner = 372,
  PaymentMethodOnFile = 373,
  ProcessCredit = 374,
  ProcessVoid = 375,
  CancelPaymentPlan = 376,
  ResumePausePaymentPlan = 377,
  StartStopPaymentPlan = 378,
  OverridePaymentPlanTiers = 379,
  SelfServicePortal = 380,
  EpicCCManualEntry = 334,
  EpicCCDevice = 338,
  EpicECheck = 337,
  EpicApplePay = 389,
  EpicMethodOnFile = 340
}
export default UserPermissions;
