import { useCallback, useEffect, useRef } from 'react';
import { CreateDownloadPaymentPlansExcelFullReportLinkService } from 'services/PaymentPlanService';
import { CreateDownloadExcelFullReportLinkService } from 'services/transactions/ExportTransactionService';
import { sleep } from 'utils';

export function useCreateReport(
  intervalMS: number = 3000,
  maxRetryCount: number = 50,
) {
  const messageMaxRetryReach = 'Reach max retry count.';
  const isCancelled = useRef(false);
  const cancel = useCallback(() => {
    isCancelled.current = true;
  }, []);

  useEffect(() => {
    return () => {
      cancel();
    };
  }, [cancel]);

  const createReport = useCallback(async(
    id: string = '',
    isPaymentPlanReport: boolean = false,
  ): Promise<any> => {
    let retryCount = 0;

    while (!isCancelled.current) {
      let response = isPaymentPlanReport
        ? await CreateDownloadPaymentPlansExcelFullReportLinkService(id)
        : await CreateDownloadExcelFullReportLinkService(id);

      if (!response) {
        cancel();
      }
      if (response && response.err) {
        return { err: response.err };
      }
      if (response && response.result) {
        return response.result;
      }

      retryCount++;
      if (retryCount === maxRetryCount) {
        return { err: messageMaxRetryReach };
      }

      await sleep(intervalMS);
    }

    return {
      err: 'Cancelled',
    };

  }, [intervalMS, CreateDownloadExcelFullReportLinkService]);

  return {
    createReport,
    cancel,
  }
}

export default useCreateReport;