export function HiddenAttribute(props: {
  modifiers?: string;
  isPreview?: boolean;
  isPaymentPagePreview?: boolean
}) {
  const { isPreview = false, isPaymentPagePreview = false } = props;

  return (
    <>
      <div className= { isPreview && !isPaymentPagePreview ? 'hidden-field' : 'hidden-field-hide' }>
        <div className={'hidden-field-message'}>
          <p>Invisible on Live Page</p>
        </div>
      </div>     
    </>   
  );
}