import {GetPaymentPlanReceipt, GetPaymentReceipt} from '../../../services/ReceiptService';
import { useCallService } from 'services/useCallService';
import { useDispatch } from 'react-redux';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';

export function useGetReceipts () {
  
  const callService = useCallService();
  const dispatch = useDispatch();

  const printPaymentReceipt = (id?:string | null , language?:string) => {
    return callService("GetPaymentReceipt", async () => {
      const response = await GetPaymentReceipt(id ?? '' , language ?? 'english');
      if(!response || response.err){
       dispatch(setAlert(getErrorAlert(response.errorMessage)));
       return "";
      }
    const blob = new Blob([response.result], { type: 'application/pdf' });
    return window?.URL?.createObjectURL(blob);

    });
  }

  const printPaymentPlanReceipt = (id?:string | null, language?:string) => {
    return callService("GetPaymentPlanReceipt", async () => {
      const response = await GetPaymentPlanReceipt(id ??'', language ?? 'english');
      if(!response || response.err){
       dispatch(setAlert(getErrorAlert(response.errorMessage)));
       return "";
      }
    const blob = new Blob([response.result], { type: 'application/pdf' });
    return window?.URL?.createObjectURL(blob);

    });
  }

  return {
    printPaymentReceipt,
    printPaymentPlanReceipt
  }
 
};
function getErrorAlert(message: string) {
  return {
    id: AlertIds.PrintReceiptAlert,
    type: AlertTypes.Error,
    message : message || 'Error printing receipt',
    dismissable: true
  }
}
