import { useState } from 'react';
import { useTypedSelector } from 'app/rootReducer';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'typeface-roboto';
 import '../../../assets/styles/components/_AddRemoveFacilityModal.scss';
import {  Button } from '@EHDS/core';
import { ReactComponent as Close } from 'assets/svgs/icon-popup-close.svg';
import { useOrganizations } from "../../../features/organizations/hooks/useOrganizations";
import { UserDetailModel } from 'models/UserInfoAndRolesModel';
import ConfirmationModal from './AddRemoveFacilityConfirmationModal';
import { SimpleSearchInformation } from 'pages/Search/simpleSearch/SimpleSearchReducer';
import OrganizationUserSearch from '../../Search/simpleSearch/OrganizationUserSearch';
import { Link } from 'react-router-dom';

export default (props: {
  isOpen?: boolean;
  isAdd: boolean;
  selectedUsers :UserDetailModel[];
  close: () => void;
  searchDataCallBack: () => void;
}) => {
  const { isOpen, isAdd, close,selectedUsers, searchDataCallBack} = props;
  const [isModelOpen, setIsModelOpen] = useState(isOpen);
  const openClass = isModelOpen ? 'eds-modal.open' : '';

  const simpleSearchState =useTypedSelector(p=>p.simpleSearchInfo?.value);
  const [AddRemoveFacilityState, setAddRemoveFacilityState] = useState<SimpleSearchInformation>({...simpleSearchState, facilities:[] , departments :[]});

  const { useGetFacilities } = useOrganizations(); 
  const allFacilities = useGetFacilities();
  const facilities = allFacilities;

  function AddFacilityOrDepartment() {
    if(AddRemoveFacilityState.departments?.length>0){
      setShowConfirmationModal(true);
    }
  };
 
  const[showConfirmationModal,setShowConfirmationModal] = useState(false);

  const onClose = ()=>{
    setShowConfirmationModal(false);
    setIsModelOpen(false);
    close();
  }
  
  return (
    <div>
      <section className={`eds-modal AddRemoveInfoModal ${openClass}`}>
        <div className="eds-modal_#container" style={{ top:100, padding: 0, borderRadius: 0, overflow:'visible'}}> 
          <header className="eds-modal_#header headerTitle">           
            <div className="col titleText" >PaymentSafe®</div>             
            <div className="col text-right headerCloseButton" onClick={()=>{close()}}>                
               <Close />
            </div>
          </header> 
          <div className="eds-modal_#content ">
            <div className="AddRemoveInfoPageContainer">
              <div className="AddRemoveInfoFormContainer">
                <div>            
                  <div className="user-location">{isAdd ? `ADD FACILITIES/DEPARTMENTS TO USERS (${selectedUsers.length})` : `REMOVE FACILITIES/DEPARTMENTS FROM USERS (${selectedUsers.length})`}</div>     
                    <div className= "organization-user-search user-location">
                      <OrganizationUserSearch
                        {...{ facilities,AddRemoveFacilityState }}
                        state={AddRemoveFacilityState}
                        setState={organizationUserState => {

                          setAddRemoveFacilityState({ ...AddRemoveFacilityState, ...organizationUserState })
                          
                        }}
                        addRemoveFacility ={true}
                        initialize={false}
                        showError={false}
                      />
                    </div>         
                    <div className="btnItem">
                      <Button
                          className="eds-button eds-button.primary iconOnRight"
                          onClick={()=>close()}
                          iconName={'chevron_right'}
                        >
                          Cancel
                      </Button>
                      
                      <Button
                        className={`eds-button eds-button.primary iconOnRight footerbutton ${ AddRemoveFacilityState.departments?.length>0 ? '':'disbaled'}`}
                        onClick={  AddFacilityOrDepartment }
                        iconName={'chevron_right'}
                      >
                      {isAdd? 'Add Facility/ Department' : 'Remove Facility/ Department'}
                      {showConfirmationModal? 
                      <ConfirmationModal 
                      isOpen ={showConfirmationModal} 
                      isAdd ={isAdd}
                      SelectedFacilities = {AddRemoveFacilityState.facilities}
                      SelectedDepartments ={AddRemoveFacilityState.departments/*SelectedDepartments*/} 
                      SelectedUsers ={selectedUsers}
                      close ={()=>{onClose()}}
                      searchDataCallBack = {searchDataCallBack}  
                       /> : 
                      <></>
                      }
                      </Button>
                    </div>             
                    <div className ="AddRemoveFacilityMessage"> 
                    <br/>  This doesn't automatically add permissions to these facilities. Go to 
                    <Link to='/admin-settings/users-and-permissions'>
                      <span>&nbsp; Users & Permissions &nbsp; </span>
                    </Link> 
                    to edit.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  ); 
  
};
