import { useState } from "react";
import { useDispatch } from "react-redux";
import { PlanOperations } from "../../models/metaData/MetaDataEnums";
import { setIsPaymentType } from "../paymentDashboard/PaymentDashboardReducer";
import { setOperation } from "../paymentPlan/PaymentPlanReducer";
import { WizardStep } from "../wizard/Wizard";
import { PaymentSteps } from "../wizards/PaymentWizard";

export const EditPlanTermSteps: PaymentSteps[] = [
  PaymentSteps.plan
]

export const EditPlanDetailsSteps: PaymentSteps[] = [
  PaymentSteps.singlePaymentTender,
  PaymentSteps.payer
]
export function getNextStepSlug(operation: PlanOperations, slug?: string) {
  let steps = EditPlanTermSteps;
  if (operation === PlanOperations.EditPlanDetails) {
    steps = EditPlanDetailsSteps;
  }
  const idx = steps.findIndex(s => s === slug);
  if (idx > -1) {
    return steps[idx + 1];
  }
}

export function isEditFinalStep(operation: PlanOperations, currentStep?: WizardStep | null) {
  if (![PlanOperations.EditPlanTerms, PlanOperations.EditPlanDetails].includes(operation)) return false;
  let steps = EditPlanDetailsSteps;
  if (PlanOperations.EditPlanTerms === operation) {
    steps = EditPlanTermSteps;
  }
  return Boolean(steps[steps.length - 1] === currentStep?.slug);
}

export function useEditPlanTerms() {
  const [showEditDropdown, setShowEditDropdown] = useState(false);
  const dispatch = useDispatch()

  function editPlanTerms(editPlanClicked: (operation: PlanOperations) => void) {
    dispatch(setOperation(PlanOperations.EditPlanTerms));
    editPlanClicked(PlanOperations.EditPlanTerms);
    setShowEditDropdown(false);
    dispatch(setIsPaymentType(false));
  }

  function editPlanDetails(editPlanClicked: (operation: PlanOperations) => void) {
    dispatch(setOperation(PlanOperations.EditPlanDetails));
    editPlanClicked(PlanOperations.EditPlanDetails);
    setShowEditDropdown(false);
    dispatch(setIsPaymentType(false));
  }

  return {
    setShowEditDropdown,
    showEditDropdown,
    editPlanTerms,
    editPlanDetails
  }
}