import React, { ChangeEvent, useState } from "react";
import '../../../assets/styles/components/_addNewRemitModal.scss';
import { Input } from '@EHDS/core';
import { Popup } from "components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";

export default (props: {
  close: () => void;
  onNext: (name: string) => void;
}) => {
  const { close, onNext } = props;
  const [name, setName] = useState<string>('');
  
  const changeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value;
    setName(value)
  };

  const next = () => {
    onNext(name);
  };

  const footerButtons = [
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Add New',
      disabled: name ? false : true
    },
  ];

  return (
  <Popup 
    header="PaymentSafe®"
    onClose={close}
    title="Add New Setting"
    footer={footerButtons}
    onSubmit={next}
    customClassNames={{footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
    >
      <div className="popupContent">
        <div className="rowItemPopup">
        <Input
          label={'Name'}
          name={'name'}
          placeholder={"New Setting"}
          className={'inputItem'}
          value={name}
          onChange={changeHandler}
        />
        </div>             
      </div>
  </Popup>
  );
};