import * as React from 'react';
import Header from './Header';
import Breadcrumb from 'features/breadcrumb/Breadcrumb';
import Sidepane from 'features/sidepane/Sidepane';
import { useTypedSelector } from 'app/rootReducer';
import { PatientInfo } from '../patientInfo/PatientInfo';
import { useDispatch } from 'react-redux';
import { setSidepaneLink } from '../sidepane/SidepaneReducer';
import { setOpenPatientInfoModal } from '../patients/PatientsReducer';
import FacilitySelectorModal from '../../features/facilitySelectorModal/FacilitySelectorModal';
import TransactionDetailsModal from 'features/transactionDetails/TransactionDetailsModal';
import { Alert } from '../alert/Alert';
import { useHistory } from 'utils/useHistory';
import { Outlet } from 'react-router';
interface Props {
  children?: React.ReactNode;
  leftMenuWidth: number;
}
export default (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginInfo = useTypedSelector(p => p.loginInfo);
  const stateTransactionDetails = useTypedSelector(s => s.transactionDetails);
  const isFacilityModalOpen = useTypedSelector(
    s => s.glPaymentDashboard.modalOpen
  );
  const links = useTypedSelector(s => s.breadcrumb?.links);
  const isModalOpen = useTypedSelector(s => s.patients.modalOpen);

  const closePatientInfoModal = () => {
    dispatch(setOpenPatientInfoModal(false));
  };

  const onPatientInfoNext = () => {
    dispatch(setOpenPatientInfoModal(false));
    history.push('/patients');
    dispatch(setSidepaneLink('make-pymt'));
  };

  const permissions =
    loginInfo?.loggedUser?.organizationLevels?.flatMap(
      item => item.permissions
    ) ?? [];

  let alerts = useTypedSelector(s => s.alerts.alerts);
  let fixedModeOn = useTypedSelector(s => s.alerts.fixedModeOn);
  alerts = [...(alerts || [])].sort((a, b) => {
    if (a.dismissable && !b.dismissable) return 1;
    if (!a.dismissable && b.dismissable) return -1;
    return 0;
  });

  const [addTopAlertClass, setTopAlertClass] = React.useState<boolean>(false);

  window.onscroll = () => {
    if (window.scrollY > 20) {
      setTopAlertClass(true);
    } else {
      setTopAlertClass(false);
    }
  };

  let sidepane: any = null;
  let patientInfo: any = null;
  let headerHeight: string = '100%';
  let headerBackground: string | undefined =
    'linear-gradient(180deg, #FFFFFF 0%, #ACCBEE 100%), #FFFFFF';
  let headerOverflow: string | undefined = 'auto';

  if (loginInfo.value.loggedIn) {
    headerHeight = 'calc(100% - 64px)';
    headerBackground = undefined;
    headerOverflow = undefined;

    sidepane = (
      <Sidepane
        leftMenuWidth={props.leftMenuWidth}
        organizationLevels={loginInfo?.loggedUser?.organizationLevels}
      />
    );
    patientInfo = (
      <PatientInfo
        close={closePatientInfoModal}
        isPreview={false}
        isOpen={isModalOpen}
        onNext={onPatientInfoNext}
      />
    );
  }

  let breadcrumb: any = null;
  let searchPaymentspage: any = null;

  if (
    permissions.length !== 0 ||
    loginInfo?.loggedUser?.impersonatedOrganizationLevels?.length !== 0
  ) {
    breadcrumb = <Breadcrumb />;

    const transactionDetailsModal = stateTransactionDetails?.isOpenModal ? (
      <TransactionDetailsModal />
    ) : null;

    searchPaymentspage = (
      <>
        {sidepane}
        {patientInfo}
        {isFacilityModalOpen && <FacilitySelectorModal />}
        {transactionDetailsModal}
      </>
    );
  }

  return (
    <>
      <Header />
      {!loginInfo.loggedUser ? null : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            minHeight: headerHeight,
            background: headerBackground,
            overflow: headerOverflow,
          }}
          data-testid="user-loggedin"
        >
          {searchPaymentspage}
          <div style={{ flexGrow: 20 }} className="main-panel">
            <div className={getAlertsClass(fixedModeOn, addTopAlertClass)}>
              {alerts.map(alert => (
                <Alert alert={alert} />
              ))}
            </div>
            {breadcrumb}
            <div
              style={{
                height: links?.length > 0 ? 'calc(100% - 49px)' : '100%',
              }}
            >
            <Outlet />
              {props.children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export function getAlertsClass(
  fixedModeOn: boolean,
  addTopAlertClass?: boolean
) {
  return `alerts-wrapper ${fixedModeOn ? 'fixed-mode-on' : ''} ${
    addTopAlertClass ? 'top-alert-position' : ''
  }`;
}
