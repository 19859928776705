import 'bootstrap/scss/bootstrap.scss';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'assets/styles/components/_transactionDetails.scss';
import { displayAmount, getAmount } from '../../../../utils/Utils';
import { LineItem } from '../../../../models/LineItem';
import { useDispatch } from 'react-redux';
import { updateAccount } from '../../TransactionDetailsReducer';
import { AccountsProps } from '../common/CommonColumnHeaders';
import { Label } from 'reactstrap';
import { Table } from '@EHDS/core';
import useRefundValidator from './useRefundValidator';
import { Field } from '../../../singlePayment/Field';
import './RefundAccounts.css';

export function RefundAccounts(props: AccountsProps): JSX.Element {
  const { accounts, lineitems, isCompleted, refundTransactionAccounts } = props;
  const dispatch = useDispatch();
  const { errorClass, getErrorMessage, validate, getAttributes, validateAll } = useRefundValidator();

  if (!accounts) {
    return <></>;
  }

  function showInputError(name: keyof LineItem, model?: LineItem) {
    const message = getErrorMessage(name, model);
    return message.length ? (
      <Label className={`error-message-required`}>{message}</Label>
    ) : null;
  }

  const getCommonFields = (a: LineItem) => {
    const originalAccount = lineitems?.find(account => account.id === a.id);
    return [
      { content: a.id },
      { content: '$' + displayAmount(originalAccount?.amount) }
    ]
  }

  const getCommonFieldsCompleted = (a: LineItem) => {
    const originalAccount = lineitems?.find(account => account.id === a.id);
    return [
      { content: a.id },
      { content: '$' + displayAmount(originalAccount?.amount) },
      { content: '$' + displayAmount(a.amount) },
      { content: a.notes }
    ]
  }

  const columnHeaders =
    [
      {
        content: "Account Number"
      },
      {
        content: "Original Payment Amount"
      },
      {
        content: 'Credit Amount'
      },
      {
        content: 'Notes'
      }
    ];

  const originalAccountsSum = getAmount(lineitems ?? []);

  const footer = [
    {
      content: 'TOTAL'
    },
    {
      content: "$" + displayAmount(originalAccountsSum)
    },
    {
      content: "($" + displayAmount(accounts.reduce((sum, l) => ((+sum) + (+l.amount)), 0)) + ")"
    },
    {
      content: ''
    }
  ];

  const footerIsCompleted = [
    {
      content: 'TOTAL'
    },
    {
      content: "$" + displayAmount(originalAccountsSum)
    },
    {
      content: "($" + (refundTransactionAccounts ? displayAmount(refundTransactionAccounts.reduce((sum, l) => ((+sum) + (+l.amount)), 0)) + ")" : '') 
    },
    {
      content: ''
    }
  ];

  const prepend = {
    prependType: 'text',
    prependValue: '$',
  };

  const moneyRegex = /^[\d][.\d]*(,\d+)?$/;

  return (
    <div className="table_style">

    <div className="ag-theme-alpine accounts-grid">
      {isCompleted && refundTransactionAccounts ? <div><Table
        columnHeaders={columnHeaders}
        tableData={[
          ...refundTransactionAccounts.map((a) => [
            ...getCommonFieldsCompleted(a)            
          ]),
          footerIsCompleted
        ]}
      />  </div>: <Table
        columnHeaders={columnHeaders}
        tableData={[
          ...accounts?.map((a, index) => [
            ...getCommonFields(a),
            {
              content: <Field<LineItem> 
                name={'amount'}
                formatLabel={() => ''}
                value={(a.amount ?? 0).toString()}
                modifiers={'row-item row-item-size-double '}
                errorClass={errorClass}                
                onChange={(e) => {
                  if(e.target.value === '' || moneyRegex.test(e.target.value)) {
                    const value = e.target.value;
                    validate(a, getAttributes(a).find(acc => acc.name === "amount"), value, true);
                    dispatch(updateAccount({ idx: index, key: 'amount', value }))
                  }
                }}
                onBlur={() => {
                  validateAll(a, false)
                }}
                showInputError={showInputError}
                model={a}
                prepend={prepend}
              />
            },
            {
              content: <Field<LineItem>
                name={'notes'}
                formatLabel={() => ''}
                value={a.notes ?? ''}
                onChange={(e) => {
                  const value = e.target.value;
                  dispatch(updateAccount({ idx: index, key: 'notes', value }))
                }}
                onBlur={() => {
                  validateAll(a, false)
                }}
                model={a}
              />
            }
          ]) ?? [],
          footer
        ]}
      />}
    </div>
    </div>
  );
}