export enum EnumPaymentSource {
  Unknown = 'Unknown',
  NotSet = 'NotSet',
  PaymentSafe = 'PaymentSafe',
  PatientSimple = 'PatientSimple',
  ECareNext = 'ECareNext',
  Kiosk = 'Kiosk',
  EPIC = 'EPIC',
  IVR = 'IVR',
  PatientSimpleQuickPay = 'PatientSimpleQuickPay',
  PatientFinancialNavigator = 'PatientFinancialNavigator',
  FlatFileImportService = 'FlatFileImportService',
  ExternalTransaction = 'ExternalTransaction',
  EpicHostedPaymentPage = 'EpicHostedPaymentPage',
  RegistrationAccelerator = 'RegistrationAccelerator',
  OneSource = 'OneSource',
  TextToPay = 'TextToPay',
  Semafone = 'Semafone'
}