import { Radio } from '@EHDS/core';
import { PatientInfoModel } from 'features/patientInfo/PatientInfoReducer';
import { setOpenPatientInfoModal } from 'features/patients/PatientsReducer';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'utils/useHistory';
import { useTypedSelector } from '../../../app/rootReducer';
import { checkReceivedHasValues, checkStringHasValue, formatDate, valueOrDefault } from '../../../utils/Utils';
import useGetPatientConfiguration from '../../admin/paymentPanel/serviceHandlers/useGetPatientConfiguration';
import { setIsPaymentType } from '../../paymentDashboard/PaymentDashboardReducer';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import { LineItem } from '../../../models/LineItem';
import { setTenderType } from 'features/paymentInfo/PatientMailingInformationReducer';
export default (props:{ 
  patientInfo:PatientInfoModel, 
  radioVisible:boolean,
  shouldShowPlan?:boolean,
  }) => {
  let {
    id,
    facility,
    department,
  } = props.patientInfo;

  let dispatch = useDispatch();
  let history = useHistory();
  const openPatientInfo = () => {
    history.push(
      `/payment/${id}?facility=${valueOrDefault(facility?.id, '')}&department=${valueOrDefault(department?.id, '')}`
    );
    dispatch(setOpenPatientInfoModal(true));
  };

  const { getConfiguration } = useGetPatientConfiguration(facility?.path);
  const radioOption = useTypedSelector(s => s.paymentDashboard.isPaymentType);

  useEffect(() => {
    getConfiguration('PatientSearchPopupDashboard');
  }, [])

  const configuration = useTypedSelector(s => s.paymentPanel.patientConfigurations.PatientSearchPopupDashboard);
  const loadedPanelConfiguration = useTypedSelector(s => (s.paymentDashboard?.panelConfigurations?.[checkStringHasValue(facility?.path)]?.configuration));
  const attributes = configuration?.attributes;
  const isMultiple = loadedPanelConfiguration?.mappings?.length != 0;
  const values = Object.values(useTypedSelector(s => s.paymentDashboard?.values || []));
  const lineItemsList = values?.map((mapped) => { return mapped.lineItems }) || [];
  const facilitiesLineItems = lineItemsList?.reduce((totalLineItems,lineItemList) => totalLineItems.concat(lineItemList.filter(isLineItemActive)), []);
  const multiple = 'Multiple';
  let records = useTypedSelector(s => s.paymentPlanInfo?.records);
  let totalPaymentPlans = valueOrDefault(records?.filter(x => x.id != null)?.length, 0);
  const showRadioButtons = totalPaymentPlans > 0;
  let totalPayments = valueOrDefault(facilitiesLineItems.length, 0);
  let firstName = "";
  let lastName = "";
  let mrn = "";
  let guarantorId = "";
  let dateOfBirth: moment.MomentInput;
  let facilityName = checkReceivedHasValues(isMultiple, multiple, facility?.name);
  let departmentName = checkReceivedHasValues(isMultiple, multiple, department?.name);
  let accountNo = "";
  let consolidationId = "";
  let secondaryMrn = "";
  let secondaryConId = "";
  const [mainPanelTitle, setMainPanelTitle] = useState('');

  const { havePermission } = useLoggedUserPermissions();
  const showPaymentPlan = havePermission(UserPermissions.CreatePaymentPlan);
  const { radioVisible, shouldShowPlan } = props;

  const processAttribute = (
    attribute: any,
    patientInfo: PatientInfoModel,
    facility?: { name: string },
    department?: { name: string },
    isMultiple?: boolean,
    multiple?: any
  ) => {
    if (!attribute.visible) return;
  
    const name = attribute.name.toLowerCase();
  
    const actions: { [key: string]: () => void } = {
      firstname: () => (firstName = checkStringHasValue(patientInfo.firstName)),
      lastname: () => (lastName = checkStringHasValue(patientInfo.lastName)),
      dateofbirth: () => (dateOfBirth = patientInfo.dateOfBirth),
      birthdate: () => (dateOfBirth = patientInfo.dateOfBirth),
      mrn: () => (mrn = checkStringHasValue(patientInfo.mrn)),
      guarantorid: () => (guarantorId = checkStringHasValue(patientInfo.guarantorId)),
      guarantoraccountno: () => (guarantorId = checkStringHasValue(patientInfo.guarantorId)),
      facility: () => (facilityName = checkReceivedHasValues(isMultiple, multiple, facility?.name)),
      department: () => (departmentName = checkReceivedHasValues(isMultiple, multiple, department?.name)),
      accountno: () => (accountNo = checkStringHasValue(patientInfo.accountNo)),
      secondarymrn: () => (secondaryMrn = checkStringHasValue(patientInfo.secondaryMRN)),
      secondaryconid: () => (secondaryConId = checkStringHasValue(patientInfo.secondaryConId)),
      consolidationid: () => (consolidationId = checkStringHasValue(patientInfo.consolidationId)),
    };
  
    if (actions[name]) {
      actions[name]();
    }
  };

  attributes?.forEach(attribute => {
    processAttribute(attribute, props.patientInfo, facility, department, isMultiple, multiple);
  })

  useEffect(() => {
    if (loadedPanelConfiguration?.entity?.panelTitle && loadedPanelConfiguration?.entity?.panelTitle !== mainPanelTitle && mainPanelTitle === ''){
      setMainPanelTitle(loadedPanelConfiguration?.entity?.panelTitle);
    }
  }, [loadedPanelConfiguration])

  function getDob(){
    return checkReceivedHasValues(dateOfBirth != null, formatDate(moment.utc(dateOfBirth)), '');
  }

  function onChangeRadio(value: boolean) {
    dispatch(setIsPaymentType(value));
    dispatch(setTenderType({tenderValue:""}));
  }

  return (
    <div className="patient_information_container">
      <div className="d-flex justify-content-between">
        <div className="eds-heading eds-heading.mdplus-caps mb-1">
          {mainPanelTitle ?? "Patient Information"}
        </div>
        
        <div className="div_payment_type">
          {showRadioButtons ? 
          <div className={checkReceivedHasValues(radioVisible, "payment_type_enabled", "payment_type_disable")}>
            <Radio
                label={`Payments(${totalPayments})`}
                value={"true"}
                checked={radioOption}
                onChange={(e) => onChangeRadio(true)}
            />
            {checkReceivedHasValues(showPaymentPlan && shouldShowPlan, <Radio
                label={`Payment Plans(${totalPaymentPlans})`}
                value={"false"}
                checked={!radioOption}
                onChange={(e) => onChangeRadio(false)}
            />, null )}
            </div>
              : null
            }
            
          <button
            className="eds-button eds-button.basic mr-2"
            onClick={openPatientInfo}
          >
            <i className="material-icons eds-button_#icon">search</i>
            <span className="eds-button_#label">Patient</span>
          </button>
        </div>
      </div>
      {firstName || lastName ? <div className="patient_name">
        {firstName} {lastName}
      </div> : null}
      <div className="patient_information_row">
        {getPatientInfo(getDob(), 'Date of Birth:')}
        {getPatientInfo(consolidationId, 'Consolidation Id:')}
        {getPatientInfo(facilityName, 'Facility:')}     
        {getPatientInfo(departmentName, 'Department:')}  
        {getPatientInfo(mrn, 'MRN:')}
        {getPatientInfo(secondaryMrn, 'Secondary MRN:')}
        {getPatientInfo(guarantorId, 'Guarantor ID:')}
        {getPatientInfo(secondaryConId, 'Secondary Consolidation Id:')} 
      </div>
    </div>
  );
};

export function isLineItemActive(lineItem?: LineItem){
  return !lineItem?.isEditing || lineItem?.pahAccount
}

const getPatientInfo = (attribute: any, label: string) => {
  if(!attribute) {
    return null;
  }
  return (
    <div className="patient_information_attribute">
      <span className="attribute_label">{label}</span>
      {attribute}
    </div>
  );
}