import MultiSelect, { MultiSelectOption } from "../../../components/select/MultiSelect";
import { getLabel } from "../../../pages/Admin/OrganizationUserManagement/OrganizationUserManagement";
import { getOrganizationOption, getOrganizationOptions } from "../../../pages/Search/simpleSearch/OrganizationUserSearch";
import { useOrganizations } from "../../organizations/hooks/useOrganizations";

export default function OrganizationsSelector(props: {
  setSelectedOrganization: (selected: MultiSelectOption | null) => void,
  selectedOrganization?: MultiSelectOption | null,
}) {
  const { setSelectedOrganization, selectedOrganization } = props;
  const { useGetTradingPartners } = useOrganizations();
  const tradingPartners = useGetTradingPartners();
  const tradingPartnerOptions = getOrganizationOptions(tradingPartners, t =>
    getOrganizationOption(t, o => getLabel(o))
  );

  return <div className={"organization-selector"}>
    <MultiSelect
      label={"Organizations:"}
      name={"tradingPartner"}
      options={tradingPartnerOptions}
      onChange={(selected) => {
        setSelectedOrganization(selected)
      }}
      values={selectedOrganization ?? null}
      multiple={false}
      searchCheckbox={false}
      disableCloseOnSelect={false}
    />
  </div>
}