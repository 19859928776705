import React, { useCallback } from 'react';
import 'assets/styles/components/_checkScanner.scss';

export function ModalContent(props: {
  setCheckRawString: (string: string) => void;
  checkRawString: string;
  errorMessage?: string;
}) {

  const { setCheckRawString, checkRawString, errorMessage } = props;
  const onChangeHandler = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const rawString = ev.target.value;
      setCheckRawString(rawString);
    }, [setCheckRawString] );

  return (
    <form className="check-scanner-modal-content">
      <label htmlFor="echeck-input">Scan your echeck:</label>
      <input
        id="echeck-input"
        data-testid="echeck-input"
        className="check-input"
        value={checkRawString}
        onChange={onChangeHandler}
      />
      <div className="error-message">{errorMessage || ''}</div>
    </form>
  );
}
