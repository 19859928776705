import { useDispatch } from "react-redux";
import { LineItem } from "../../../../models/LineItem";
import { useTypedSelector } from "../../../../app/rootReducer";
import { GenericAttribute } from "../../../../models/metaData/MetaData";
import { DataType, ValidatorType } from "../../../../models/metaData/MetaDataEnums";
import { range, decimal, validateGeneric } from "../../../paymentDashboard/Validators";
import { addError, removeError } from "../../../paymentDashboard/PaymentDashboardReducer";

export default function useRefundValidator() {
  const dispatch = useDispatch();

  const getAttributes: (lineItem: LineItem) => GenericAttribute<LineItem>[] = (lineItem: LineItem) => [
    { name: "amount", dataType: DataType.Decimal, validators: [ decimal(12, 2), range({ min: 0, max: lineItem.balance })], visible:true },
  ];

  function validateAll(lineItem?: LineItem, validOnly: boolean = false) {
    let valid = true;
    lineItem && getAttributes(lineItem).forEach(attribute => {
      let result = validate(lineItem, attribute, lineItem[attribute.name], validOnly);
      valid = valid && result;
    });
    return valid;
  }

  function validate(lineItem?: LineItem, attr?: GenericAttribute<LineItem>, value?: any, validOnly: boolean = false): boolean {
    if (!attr || !lineItem) return false;
    return validateGeneric({
      onPass: (key: keyof LineItem, validatorType: ValidatorType) => {
        dispatch(removeError({ id: lineItem?.id, key: attr.name, validatorType }));
      },
      onFail: (key: keyof LineItem, validatorType: ValidatorType, message?: string) => {
        dispatch(addError({
          id: lineItem?.id || '',
          error: {
            [attr.name]: { [validatorType]: message || '' }
          }
        }))
      },
      attr,
      value,
      validOnly
    })
  }

  const errors = useTypedSelector(s => s.paymentDashboard.errors);

  function getErrorMessage(name: keyof LineItem, lineItem?: LineItem) {
    const accountErrors = errors[lineItem?.id ?? ''];
    const error = accountErrors && accountErrors[name];
    return (error && Object.values(error).join(', ')) ?? '';
  }

  function errorClass(name: keyof LineItem, model?: LineItem) {
    const accountErrors = errors[model?.id ?? ''];
    const error = accountErrors && accountErrors[name];
    return error && Object.values(error).length ? 'invalid-field' : '';
  }

  return { validate, validateAll, getAttributes, errorClass, getErrorMessage }
}