import { PatientInfo as PatientInfoPreview } from "../../../features/patientInfo/PatientInfo";
import { Attribute } from "../../../models/metaData/MetaData";
import { Dashboards } from "../../../features/admin/paymentPanel/ConfigurationSelector";
import { LineItem } from "models/LineItem";
import { LineItemComponent as LineItemPreview, LineItemType } from "../../../features/paymentDashboard/LineItemComponent";

export default function Previewer(props: {
  selectedDashboard?: keyof Dashboards,
  onClickAttribute?: (type?: LineItemType, attr?: Attribute, value?: any) => void,
  previewLineItem?: LineItem;
  externalAttributes?: Attribute[];
  color?: string;
  isPreview?: boolean;
  openField?: keyof LineItem;
  newCustomAttributeName?: string
  title?: string;
}) {
  const { selectedDashboard, onClickAttribute, previewLineItem, externalAttributes, color, isPreview, openField, newCustomAttributeName, title } = props;

  return (
    <>
      {selectedDashboard === 'PatientSearchPopupDashboard' || selectedDashboard === 'PatientSearchPopupDashboardDraft' ?
        <PatientInfoPreview
          isPreview={isPreview ?? true}
          isOpen={true} close={() => { /* Empty function  */ }}
          onNext={() => { /* Empty function  */ }}
          externalAttributes={externalAttributes}
          onClickAttribute={onClickAttribute} title={title}
        /> :
        <LineItemPreview
          key={previewLineItem?.id}
          lineItem={previewLineItem ?? { amount: 0 }}
          lineItemId={"previewLineItemId"}
          type={LineItemType.Normal}
          organizationPath={"previewOrganizationPath"}
          onClickAttribute={onClickAttribute}
          externalAttributes={externalAttributes}
          borderColor={color}
          isPreview={true}
          openField={openField}
          selectedCustomField={newCustomAttributeName}
          isPaymentOrPreviewPage={false}
          isPayment={true}
          planAdhoc={true}
        />
      }
    </>
  );
};