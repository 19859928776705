import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { useDispatch } from 'react-redux';
import { useCallService } from 'services/useCallService';
import {
  cloneRemitConfigrationsData,
  cloneRemitConfigurations,
  getRemitConfigurations as getRemitConfigurationsService,
} from '../../../services/RemitService';
import { RemitConfiguration } from 'models/admin/RemitConfiguration';
import { AlertIds, AlertTypes, setAlert } from '../../alert/AlertReducer';

export function useCloneRemitConfigurations() {
  const callService = useCallService();
  const dispatch = useDispatch();
  const { getOrganizationById } = useOrganizations();

  const clone = (data: cloneRemitConfigrationsData) => {
    return callService('cloneRemitConfigurations', async () => {
      const response = await cloneRemitConfigurations(data);
      if (response.hasOwnProperty('result')) {
        dispatch(
          setAlert({
            id: AlertIds.CloneRemitConfigurationsAlert,
            type: AlertTypes.Success,
            message: 'Successfully cloned remit configurations',
            dismissable: true,
          })
        );
      }

      if (response.errorResponse?.data?.validationErrors) {
        return response.errorResponse.data.validationErrors;
      }

      if (data.remitConfigIds.length == 1) {
        return data;
      }
    });
  };

  const getClonedRemitConfig = async (data: cloneRemitConfigrationsData) => {
    const selectedFacility = getOrganizationById(
      data.bulkCloneDestinationOrganizations[0].organizationId
    );
    const params = {
      organizationPaths: [
        selectedFacility && selectedFacility.path ? selectedFacility.path : '',
      ],
    };
    const destinationId =
      selectedFacility && selectedFacility.id ? selectedFacility.id : '';
    let remitConfig: RemitConfiguration[] = [];
    const response = await getRemitConfigurationsService(params);
    if (response.result) {
      remitConfig = response.result.records.filter(
        remit =>
          remit.fileIdentifier ===
            data.bulkCloneDestinationOrganizations[0].fileIdentifierOverwrite &&
          remit.fileName ===
            data.bulkCloneDestinationOrganizations[0].fileNameOverwrite
      );
    }
    return {
      destinationId,
      remitConfigId: remitConfig.length ? remitConfig[0].id : '',
    };
  };

  return {
    clone,
    getClonedRemitConfig,
  };
}
