import { EDS_Select, EDS_TextBox } from '@EH/eh.eds.react';
import React ,{ useState } from 'react';
import styles from '../advanceSearch.module.scss';
import { paymentStatusOptions, resultStatusOptions } from '../FilterData';
import { useChangeHandlers } from '../useAdvanceSearchState';
import { TextInput } from './TextInput';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { AlertIds, AlertTypes, removeAlert, setAlert } from '../../../../features/alert/AlertReducer';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../app/rootReducer';
import { getGlDescriptionOptions } from 'pages/Search/advanceSearch/AdvanceSearchReducer';
import { equalStringArrays,mapPaymentStatusToMultiSelectOptions } from 'utils/Utils';
import { PopoverClientElement } from 'pages/Search/simpleSearch/MainFielters/SearchByPatientInformation';
import MultiSelect, { MultiSelectOption } from "../../../../components/select/MultiSelect";

export const toolTipMessages = {
  transaction: 'Also known as Remote ID on the Gateway',
  gatewayReferenceNumber: 'Also known as Transaction ID on the Gateway',
  authorizationCode: 'Also known as Auth Code on the Gateway'
};

export function SearchByTransactionDetails(props: {
  setShowAlert: any;
}) {
  const {
    handleChangeTextBox,
    handleChangeDropDown,
    ChangeMultiSelectOpion,
    state,
  } = useChangeHandlers();
  const { setShowAlert } = props;

  const { getFacilityByPath } = useOrganizations();
  
  const selectedFacilitiesPaths = useTypedSelector(s => s.advanceSearchInfo.selectedFacilitiesPaths);
  const glDescriptionOptions = useTypedSelector(s => s.advanceSearchInfo.glDescriptionOptions);
  const selectedFacilities = useTypedSelector(s => s.simpleSearchInfo.value.facilities);
  const facilities =  selectedFacilities.map(f => getFacilityByPath(f.value));
  const facilitiesPaths = facilities.filter(f => f).map(f => f?.path ?? '');

  const dispatch = useDispatch();
  const searchAlert = { id: AlertIds.SearchAlert, type: AlertTypes.Warning, message: 'Please complete the required information to continue.', dismissable: true  };

  const openErrorMessage = (isSetAlert: boolean) => {
    if (isSetAlert) {
      setShowAlert(true);
      dispatch(setAlert(searchAlert));
    } else {
      setShowAlert(false);
      dispatch(removeAlert(searchAlert));
    }
  };

  const GetGlDescriptionOptions = () => {
    if (!equalStringArrays(facilitiesPaths, selectedFacilitiesPaths)) {
      dispatch(getGlDescriptionOptions(facilitiesPaths));
    }
  };
  
  GetGlDescriptionOptions();

  const selectedPaymentStatus = useTypedSelector(p => p.advanceSearchInfo?.value?.paymentStatus || []);
  const PaymentStatusMultiOptions = mapPaymentStatusToMultiSelectOptions(paymentStatusOptions);
  const [allSelectedPaymentStatus, setAllSelectedPaymentStatus] = useState<boolean>(true);
  const ChangeSelectedPaymentStatus=(selected: MultiSelectOption[], propertyName:string)=>{
    ChangeMultiSelectOpion(selected,propertyName);  
      if(PaymentStatusMultiOptions.length===selected.length){
        setAllSelectedPaymentStatus(true)
      }
      else{
        setAllSelectedPaymentStatus(false)
      }    
  }
  const selectAllPaymentStatus = ()=>{ 
  if(allSelectedPaymentStatus){ 
    ChangeMultiSelectOpion([],"paymentStatus");
    setAllSelectedPaymentStatus(false);
  }
  else{ 
    ChangeMultiSelectOpion(PaymentStatusMultiOptions,"paymentStatus");
    setAllSelectedPaymentStatus(true)
  }
 }
  return (
    <div className='row' style={{ alignItems: 'flex-end' }}>
      <div className="col-md-4">
        <EDS_TextBox
          label={'Transaction ID:'}
          name={'transactionID'}
          placeHolder={'Enter transaction ID'}
          modifiers={`${styles.filterItem}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextBox(e, 'Transaction ID')}
          value={state.transactionID || ''}
        />
        <span className={`${styles.toolTip} ${styles.transaction}`}>
          <PopoverClientElement
            placement="top"
            content={toolTipMessages.transaction}
          />
        </span>
      </div>
      <div className="col-md-4">
        <MultiSelect
          label={"Payment Status:"}
          name={"paymentStatus"}       
          options={PaymentStatusMultiOptions}
          onChange={ChangeSelectedPaymentStatus}
          values={selectedPaymentStatus??[]}
          multiple={true}
          searchCheckbox={true}
          selectAll={selectAllPaymentStatus}
          allSelected={allSelectedPaymentStatus}
          button={'All'}
          remit = {true}
        />
      </div>
      <div className="col-md-4">
        <EDS_Select
          name="resultsStatus"
          label="Results Status:"
          modifiers={`${styles.filterItem}`}
          options={resultStatusOptions}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            handleChangeDropDown(e, 'Results Status')
          }
          value={state?.resultsStatus?.value || ''}
        />
      </div>
      <div className="w-100" />

      <div className="col-md-4">
        <EDS_TextBox
          label={'Original Gateway Reference Number:'}
          name={'gatewayReferenceNumber'}
          placeHolder={'Enter gateway reference number'}
          modifiers={`${styles.filterItem}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextBox(e, 'Gateway Reference Number')}
            value={state.gatewayReferenceNumber || ''}
        />
        <span className={`${styles.toolTip} ${styles.transactionReference}`}>
          <PopoverClientElement
            placement="top"
            content={toolTipMessages.gatewayReferenceNumber}
          />
        </span>
      </div>
     

      <TextInput
        label={'Account Number:'}
        name={'accountNumber'}
        placeHolder={'Enter invoice number'}
        value={state.accountNumber}
        onChange={e => handleChangeTextBox(e, 'Account Number')}
      />
      <TextInput
        label={'Method Ending In:'}
        name={'creditCard'}
        placeHolder={'Enter credit card number'}
        onChange={e => handleChangeTextBox(e, 'Credit Card')}
        value={state.creditCard || ''}
      />
      <div className="w-100" />
      <div
        className="col-md-4"
        onClick={() => {
          openErrorMessage(!selectedFacilities.length);
        }}
      >
        <EDS_Select
          name="glDescription"
          label="GL Description:"
          modifiers={`${styles.filterItem}`}
          options={glDescriptionOptions}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            handleChangeDropDown(e, 'GL Description');
          }}
          value={state?.glDescription?.value || ''}
        />
      </div>

      

      <TextInput
        label={'Batch ID:'}
        name={'batchID'}
        placeHolder={'Enter batch ID'}
        onChange={e => handleChangeTextBox(e, 'Batch ID')}
        value={state.batchID || ''}
      />
      <div className="col-md-4">
        <EDS_TextBox
            label={'Authorization Code:'}
            name={'authorizationCode'}
            placeHolder={'Enter authorization code'}
            modifiers={`${styles.filterItem}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextBox(e, 'Authorization Code')}
            value={state.authorizationCode || ''}
          />
          <span className={`${styles.toolTip} ${styles.authorizationCode}`}>
            <PopoverClientElement
              placement="top"
              content={toolTipMessages.authorizationCode}
            />
          </span>
      </div>
      <div className="w-100" />
      <TextInput
        label={'Payment Plan ID:'}
        name={'paymentPlanId'}
        placeHolder={'Enter payment plan ID'}
        onChange={e => handleChangeTextBox(e, 'Payment Plan ID')}
        value={state.paymentPlanId || ''}
      />
      <TextInput
        label={'Device Name:'}
        name={'deviceName'}
        placeHolder={'Enter device name'}
        onChange={e => handleChangeTextBox(e, 'Device Name')}
        value={state.deviceName || ''}
      />
      <TextInput
        label={'Device Serial Number:'}
        name={'deviceSerialNumber'}
        placeHolder={'Enter device serial number'}
        onChange={e => handleChangeTextBox(e, 'Device Serial Number')}
        value={state.deviceSerialNumber || ''}
      />
      </div>
  );
}
