import { MyChartSettings } from "../../../../models/Client";
import { GenericAttribute } from "../../../../models/metaData/MetaData";
import { DataType } from "../../../../models/metaData/MetaDataEnums";
import useErrorStore from "../../../../utils/useErrorStore";
import useTypedValidator from "../../../../utils/useTypedValidator";
import { required } from "../../../paymentDashboard/Validators";
import { urlValidator } from "../whitelist/useWhitelistManagerValidator";

export const useMyChartSettingsValidator = () => {
  const attributes: GenericAttribute<MyChartSettings>[] = [
    { name: "paymentPageUrl", label: "Epic External Payment Page URL (MyChart)", dataType: DataType.String, validators: [required(), urlValidator<MyChartSettings>()] },
  ];
  const { addError, removeError, errorsRef, getErrorMessageAsString} = useErrorStore();

  const typedSelectorProps = useTypedValidator({
    attributes,
    errors: errorsRef.current,
    removeError,
    addError,
    dispatchable: false,
  }
  );

  return {
    ...typedSelectorProps,
    getErrorMessage: (key: string) => {
      return typedSelectorProps.getErrorMessage(key, errorsRef.current);
    },
    errorClass: (key: string) => {
      return typedSelectorProps.errorClass(key, errorsRef.current);
    },
    errorsRef,
    getErrorMessageAsString,
  }
}