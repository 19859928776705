import MultiSelect, { MultiSelectOptionGeneric } from "../../../components/select/MultiSelect"

export interface Dashboards {
  PaymentDashboard?: string,
  PaymentPlanDashboard?: string,
  GlPaymentDashboard?: string,
  GlPaymentPlanDashboard?: string,
  PatientSearchPopupDashboard?: string,
  PaymentDashboardDraft?: string,
  PaymentPlanDashboardDraft?: string,
  GlPaymentDashboardDraft?: string,
  GlPaymentPlanDashboardDraft?: string,
  PatientSearchPopupDashboardDraft?: string,
  EcnPaymentDashboard?: string,
  EcnPaymentDashboardDraft?: string,
}

export const isDashboardDraft = (key?: keyof Dashboards) => {
  return key?.endsWith("Draft");
}

interface DashboardOption extends MultiSelectOptionGeneric<keyof Dashboards> {
  isDraft?: boolean
}

export const dashboardOptions: DashboardOption[] = [
  { label: "Payment Panel", value: "PaymentDashboard" },
  { label: "Payment Plan Panel", value: "PaymentPlanDashboard" },
  { label: "GL Payment Panel", value: "GlPaymentDashboard" },
  { label: "GL Payment Plan Panel", value: "GlPaymentPlanDashboard" },
  { label: "Patient Search Popup", value: "PatientSearchPopupDashboard" },
  { label: "ECN Payment Panel", value: "EcnPaymentDashboard" },
  { label: "Payment Dashboard (draft)", value: "PaymentDashboardDraft" },
  { label: "Payment Plan Dashboard (draft)", value: "PaymentPlanDashboardDraft" },
  { label: "GL Payment Dashboard (draft)", value: "GlPaymentDashboardDraft" },
  { label: "GL Payment Plan Dashboard (draft)", value: "GlPaymentPlanDashboardDraft" },
  { label: "Patient Search Popup (draft)", value: "PatientSearchPopupDashboardDraft" },
  { label: "ECN Payment Panel (draft)", value: "EcnPaymentDashboardDraft" },
]

export default function ConfigurationSelector(props: {
  selectedDashboard?: keyof Dashboards,
  onChangeConfig?: (selectedValue: any) => void
}) {
  const { selectedDashboard, onChangeConfig } = props;
  const onChange = (value: keyof Dashboards) => {
    onChangeConfig && onChangeConfig(value);
  }

  return <MultiSelect label=""
    options={dashboardOptions}
    onChange={(option) => onChange(option?.value as keyof Dashboards)}
    values={dashboardOptions.find(o => o.value === selectedDashboard) ?? null}
    name={"dashboard"} multiple={false} disableCloseOnSelect={false} />
}