import { EDS_Button } from '@EH/eh.eds.react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../app/rootReducer';
import { PaymentPlanModel } from '../../../models';
import { AlertIds, AlertTypes, setAlert } from '../../alert/AlertReducer';
import { useCreateOrUpdatePaymentPlan } from '../hooks/useCreateOrUpdatePaymentPlan';
import { resetOperation } from '../PaymentPlanReducer';

export function EditFinalStepButtons(props: {
  cancel?: () => void,
  disabled?: boolean,
  onContinue?: (onSuccess?: ()=>void) => void;
  total?: number;
  paymentPlan?: PaymentPlanModel;
  patientId?: string;
  selectedOrganizationPath?: string;
}) {
  const {
    cancel,
    disabled,
    paymentPlan,
    patientId,
    selectedOrganizationPath,
    onContinue
  } = props;

  const isPaymentType = useTypedSelector(s => s.paymentDashboard.isPaymentType)
  const paymentPlansRecords = useTypedSelector(s => s.paymentPlanInfo?.records) || [];
  const plan = paymentPlansRecords.filter(p => p.id == paymentPlan?.id)[0];
  const planLineItems =  plan?.lineItems || [];
  const lineItems = useTypedSelector(s => s.paymentDashboard?.values?.[selectedOrganizationPath ?? '']?.lineItems || []);
  const isProcessing = useTypedSelector(s => s.services.calls.createOrUpdatePaymentPlan?.isProcessing);
  const dispatch = useDispatch();

  const { createPaymentPlan } = useCreateOrUpdatePaymentPlan(
    {
      paymentPlan,
      lineItems: isPaymentType ? lineItems : planLineItems,
      onSuccess: () => {
      dispatch(setAlert({ id: AlertIds.PlanAlert, type: AlertTypes.Success, message: 'Payment Plan updated successfully' }))
      dispatch(resetOperation());
      cancel && cancel();
      },
      onError: () => dispatch(setAlert({ id: AlertIds.PlanAlert, type: AlertTypes.Error, message: 'Could not create Payment Plan' })),
      patientId,
      selectedOrganizationPath
    }
  );

  return <div className="footer-buttons">
    <div className="footer-flex">
      <EDS_Button
        modifiers={'mr-2 eds-button eds-button.basic'}
        name={'cancel'}
        buttonText={'Cancel'}
        onClick={cancel}
      />
      <EDS_Button
        modifiers={'eds-button eds-button.primary button-reverse'}
        name={'createPaymentPlan'}
        buttonText={isProcessing ? 'Processing' : 'Save'}
        onClick={() => onContinue && onContinue(createPaymentPlan)}
        iconName={'chevron_right'}
        disabled={disabled}
      />
    </div>
  </div >
}