import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatientModel } from 'models/PatientModel';
import {
  getPatientById,
  GetPatientByIdResult,
} from 'services/patient/PatientService';
import { AppThunk } from 'app/rootReducer';
import { callService } from 'services/ServicesReducer';

export interface State {
  err?: string;
  result?: PatientModel;
}

export const initialState: State = {
  err: '',
  result: undefined,
};

const reducerSlice = createSlice({
  name: 'PatientInfo',
  initialState,
  reducers: {
    onReceivePatientInfo(state, action: PayloadAction<GetPatientByIdResult>) {
      state.result = action.payload.result;
      state.err = action.payload.err;
    },
    setPatientInfo(state, action: PayloadAction<GetPatientByIdResult>) {
      state.result = action.payload.result;
    },
    resetPatientInfo(state) {
      state.result = initialState.result;
      state.err = initialState.err;
    },
  },
});

export const {
  onReceivePatientInfo,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getPatientInfo(params: any, onDone?: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService('GetPatientById', async () => {
      await getPatientById(params).then(data => {
        dispatch(onReceivePatientInfo(data));
        if (onDone) onDone();
      });
    }))    
  };
}

export const { setPatientInfo, resetPatientInfo } = reducerSlice.actions;
