import React, { useEffect, useState, useRef } from 'react';
import { Outlet, Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { useDispatch } from 'react-redux';
import { getAllPermissions } from 'features/globalPermissions/GlobalPermissionsReducer';
import { useTypedSelector } from 'app/rootReducer';
import ClientConfiguration from './Admin/ClientConfiguration';
import CreditCardConfig from './Admin/CreditCardConfig';
import AcceptedPayments from './Admin/AcceptedPayments';
import DeviceManager from './Admin/DeviceManager';
import LogoManager from './Admin/LogoManager';
import TransactionInfo from './Admin/TransactionInfo';
import TokenGuidLookup from './Admin/TokenGUIDLookup';
import WebServiceError from './Admin/WebServiceError';
import AddFacility from './Admin/AddFacility';
import 'assets/styles/components/_globalTools.scss';
import 'assets/styles/components/_adminSettings.scss';
import { ReactComponent as ClientConfigurationIcon } from 'assets/svgs/domain.svg';
import { ReactComponent as RolesIcon } from 'assets/svgs/person_pin.svg';
import { ReactComponent as OrganizationIcon } from 'assets/svgs/org_icon.svg';
import GlobalTools from '../features/admin/globalToolsMenu/GlobalTools';
import RemitConfig from './Admin/Remit/RemitConfig';
import ClientModalInfo from './Admin/ClientModalInfo';
import OrganizationUserManagement from './Admin/OrganizationUserManagement/OrganizationUserManagement';
import * as router from 'react-router-dom';
import { useOrganizations } from '../features/organizations/hooks/useOrganizations';
import { useGetOrganizations } from '../features/organizations/hooks/useGetOrganizations';
import { getClientConfiguration } from '../features/admin/GetClientConfigurationReducer';
import RemitService from './Admin/RemitService';
import PaymentPlanConfiguration from './Admin/PaymentPlanConfiguration';
import RemitManagement from './Admin/Remit/RemitManagement';
import PaymentPanelConfiguration from './Admin/PaymentPanelConfiguration';
import GlListManager from './Admin/GlListManager';
import UsersAndPermissions from './Admin/UsersAndPermissions/UsersAndPermissions';
import { setFixedMode } from '../features/alert/AlertReducer';
import ReceiptManager from './Admin/ReceiptManager';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import UserPermissions from 'models/enums/UserPermissions';
import WhitelistManager from './Admin/Epic/WhitelistManager';
import IntetgrationSettings from './Admin/Epic/IntegrationSettings';
import UserTokenManagement from './Admin/Epic/UserTokenManagement';
import { setSidepaneLink } from 'features/sidepane/SidepaneReducer';
import EpicDepartmentMappingPage from './Admin/Epic/EpicDepartmentMappingPage';
import EpicTokenUserMappingPage from './Admin/Epic/EpicTokenUserMappingPage';
import { TransactionLookup } from './Admin/Epic/TransactionLookup';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
  onWidthUpdate?: any;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setBreadcrumbLinks([{ name: 'Admin Settings', slug: rootPath }]));

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

function usePath() {
  const location = router.useLocation();
  const pathname = location.pathname;
  return pathname;
}

export default function AdminSettings(props: Props) {
  const pathname = usePath();
  const { rootPath } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isPaymentModalOpen = useTypedSelector(s => s.patients?.modalOpen);
  const isGlModalOpen = useTypedSelector(s => s.glPaymentDashboard?.modalOpen);
  const myElement = useRef(null);
  useBreadcrumb(rootPath);
  // fetch all the permissions from backend
  const dispatch = useDispatch();
  const { hasPermissionOrSuperAdmin } = useLoggedUserPermissions();

  useEffect(() => {
    dispatch(setFixedMode(true));
    const sidePanel = document.getElementById('left-menu')!;
    if (myElement.current) {
      props.onWidthUpdate(sidePanel.clientWidth);
    }
    dispatch(getAllPermissions());
  }, []);

  useEffect(() => {
    if(!isPaymentModalOpen && !isGlModalOpen) {
     dispatch(setSidepaneLink('admin'));
    }
  }, [isPaymentModalOpen,isGlModalOpen]);


  const { getOrganizations } = useGetOrganizations();

  useEffect(() => {
    getOrganizations(true);
  }, []);

  let isLoading = useTypedSelector(
    s => s.services.calls.getAllPermissionsService?.isProcessing
  );
  if (isLoading) return <div>Loading...</div>;

  const sspUserPermission = hasPermissionOrSuperAdmin(UserPermissions.SelfServicePortal);
  const isAdmin = hasPermissionOrSuperAdmin(UserPermissions.FinancialAdministation);

  function openClientInfoModal() {
    return isModalOpen ? (
      <ClientModalInfo
        close={closeFacilityInfoModal}
        isOpen={true}
        onNext={closeFacilityInfoModal}
      />
    ) : null;
  }

  const closeFacilityInfoModal = () => {
    setIsModalOpen(false);
  };

  const showSettingLinks = (permission: boolean, component: JSX.Element) => {
    if(!isAdmin && permission) return null;
    return component;
  }

  return (
    <div className="container-fluid main-container">
      <div className="row h-100">
        <div className="col-sm-3 global-tools" ref={myElement} id="left-menu">
          <GlobalTools
            pathname={pathname}
          />
        </div>
        <div className="col-sm-9">
        <Outlet />
          <Routes>
            <Route path="/" element={
              <div className="admin-settings-container">
                <span className="admin-settings-title">
                  Welcome to PaymentSafe Admin!
                </span>
                <span className="admin-settings-subtitle">
                  What do you need to do today?
                </span>

                <div className="admin-settings-links-container row">
                  {showSettingLinks(sspUserPermission, <a
                    href="# "
                    onClick={e => {
                      e.preventDefault();
                      setIsModalOpen(true);
                    }}
                  >
                    <div className="icon">
                      <ClientConfigurationIcon />
                    </div>
                    <span>
                      <i>Facility Configuration</i>
                      <p>
                        Configure transaction, patient, physician and
                        communication
                      </p>
                    </span>
                  </a>)
                  }


                  <Link
                    to="/admin-settings/users-and-permissions"
                    onClick={() => {
                      /* empty function*/
                    }}
                  >
                    <div className="icon">
                      <RolesIcon />
                    </div>
                    <span>
                      <i>Roles &amp; Permissions</i>
                      <p>
                        Manage roles and Permission setup and assignment to
                        users
                      </p>
                    </span>
                  </Link>
                  {/* Unused links hidden*/}
                  {/* <Link
                    to="/"
                    onClick={() => {
                    }}
                  >
                    <div className="icon">
                      <PaymentsIcon />
                    </div>
                    <span>
                      <i>Payments</i>
                      <p>
                        Set up payment user, payer and facility configuration
                        settings
                      </p>
                    </span>
                  </Link> */}

                  {/* <Link
                    to="/"
                    onClick={() => {
                    }}
                  >
                    <div className="icon">
                      <PaymentPlansIcon />
                    </div>
                    <span>
                      <i>Payment Plans</i>
                      <p>
                        Set up payment plan user, payer and facility
                        configuration settings
                      </p>
                    </span>
                  </Link> */}

                  {/* <Link
                    to="/"
                    onClick={() => {
                    }}
                  >
                    <div className="icon">
                      <SearchIcon />
                    </div>
                    <span>
                      <i>Search</i>
                      <p>
                        Manage search criteria, required content and search
                        result data
                      </p>
                    </span>
                  </Link> */}

                  {/* <Link
                    to="/"
                    onClick={() => {
                    }}
                  >
                    <div className="icon">
                      <EpicIcon />
                    </div>
                    <span>
                      <i>Epic</i>
                      <p>
                        Set up Epic payment environment, credentials and device
                        services
                      </p>
                    </span>
                  </Link> */}

                  {/* <Link
                    to="/"
                    onClick={() => {
                    }}
                  >
                    <div className="icon">
                      <SemafoneIcon />
                    </div>
                    <span>
                      <i>Semafone</i>
                      <p>
                        Manage Semafone address, tenant, facility and account
                        IDs
                      </p>
                    </span>
                  </Link> */}

                  {showSettingLinks(sspUserPermission, <Link
                    to="/admin-settings/organization-user-management"
                    onClick={() => {
                      /* empty function*/
                    }}
                  >
                    <div className="icon">
                      <OrganizationIcon />
                    </div>
                    <span>
                      <i>Org. & User Mgmt.</i>
                      <p>Manage Organizations, Facilities and Users</p>
                    </span>
                  </Link>)
                  }

                  {/* Unused links hidden*/}
                  {/* <Link
                    to="/"
                    onClick={() => {
                    }}
                  >
                    <div className="icon">
                      <EcnIcon />
                    </div>
                    <span>
                      <i>eCN</i>
                      <p>
                        Customize logo, receipt, PAH manager, GL Codes and Remit
                      </p>
                    </span>
                  </Link> */}
                </div>
              </div>
            } />

            
            <Route path="/remit-management" element={<RemitManagement />} />
            <Route path="/users-and-permissions" element={<UsersAndPermissions />} />
            <Route path="/transaction-lookup" element={<TransactionLookup />} />
            <Route path="/transaction-info" element={<TransactionInfo rootPath="/admin-settings/transaction-info" />} />
            <Route path="/token-guid-lookup" element={<TokenGuidLookup rootPath="/admin-settings/token-guid-lookup" />} />

            
            <Route path="/webService-error" element={<WebServiceError rootPath="/admin-settings/webService-error" />} />
            <Route path="/add-new-facility" element={<AddFacility rootPath="/admin-settings/add-new-facility" />} />
            
            <Route path="/organization-user-management" element={<OrganizationUserManagement />} />
           
            <Route path="/:clientId/*" element={<ClientAdminSettings />} >
              
            </Route>
          </Routes>
        </div>
      </div>
      {openClientInfoModal()}
    </div>
  );
}

export const ClientAdminSettings = () => {
  const dispatch = useDispatch();
  const { getOrganizationById } = useOrganizations();

  let clientId: string | undefined;
  const params = router.useParams<{ clientId: string }>();
  clientId = params?.clientId;
  if (
    [
      'transaction-info',
      'token-guid-lookup',
      'webService-error',
      'add-new-facility',
      'organization-user-management',
    ].includes(clientId ?? "")
  ) {
    clientId = undefined;
  }

  let { search } = router.useLocation();
  let queryParams = new URLSearchParams(search);

  const previousFacilityId = queryParams.get('previousFacilityId');

  if (!clientId && previousFacilityId) {
    clientId = previousFacilityId;
  }
  const organization = getOrganizationById(clientId);

  useEffect(() => {
    if (organization) {
      const pathParam = { OrganizationPath: organization.path };
      dispatch(getClientConfiguration(pathParam));
    }
  }, [organization]);

  return <Routes>
    <Route path="client-configuration" element={<ClientConfiguration rootPath="/admin-settings/token-guid-lookup" />} />
    <Route path="/payment-panel-configuration/:dashboardKey?" element={<PaymentPanelConfiguration />} />
    <Route path="/accepted-payments" element={<AcceptedPayments rootPath="/admin-settings" />} />


    <Route path="/client-configuration/credit-card-config" element={<CreditCardConfig rootPath="/admin-settings" />} />
    <Route path="/client-configuration/device-manager" element={<DeviceManager rootPath="/admin-settings" />} />
    <Route path="/client-configuration/logo-manager" element={<LogoManager rootPath="/admin-settings" />} />
    <Route path="/client-configuration/payment-plan-configuration" element={<PaymentPlanConfiguration />} />
    <Route path="/client-configuration/remit-service" element={<RemitService rootPath="/admin-settings" />} />
    <Route path="/client-configuration/receipt-manager" element={<ReceiptManager rootPath="/admin-settings" />} />

    <Route path="/gl-list-manager" element={<GlListManager />} />
    <Route path="/epic/whitelist-manager" element={<WhitelistManager />} />
    <Route path="/epic/integration-settings" element={<IntetgrationSettings />} />
    <Route path="/epic-department-mapping" element={<EpicDepartmentMappingPage />} />
    <Route path="/epic-user-token-mapping" element={<EpicTokenUserMappingPage />} />

    <Route path="/remit-configuration" element={<RemitConfig />} />

    <Route path="/user-token-management" element={<UserTokenManagement />} />

  </Routes>
}
