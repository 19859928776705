export enum DataType {
  String = 'String',
  Boolean = 'Boolean',
  DateTime = 'DateTime',
  Decimal = 'Decimal',
}

export enum AnnotationType {
  Label = 'Label',
  Order = 'Order',
  ValueType = 'ValueType',
  ComponentType = 'ComponentType',
}

export enum ValidatorType {
  RangeValidator = 'RangeValidator',
  DateTimeValidator = 'DateTimeValidator',
}

export enum EntityType {
  LineItem = 'LineItem',
}
