import { ECheckModel } from './ECheckModel';
import * as ErrorMessages from './ErrorMessages';

/**
 * Parses echeck data from string formatted as  
' U123456789 T1234567890 1234 '
 * @param inputRawString The input string to parse from
 */
export function parseCheckRawString(
  inputRawString: string
): { error?: string; eCheckModel?: ECheckModel } {
  let eCheckModel: ECheckModel = {};
  let error: string;

  const route_length = 9;

  // Replace consecutive spaces with single space)
  let rawString = inputRawString.trim().replace(/\s\s+/g, ' ');

  let segments = rawString.split(' ');

  // If the input contains '?', return error
  if (inputRawString.indexOf('?') !== -1) {
    error = ErrorMessages.WrongCheckOrientation;
    return { error };
  }

  // Extract check data
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    if (segment.startsWith('U')) {
      // take only the digits. \D means all non digits.
      eCheckModel.route = segment.replace(/\D/g, '');
    } else if (segment.startsWith('T')) {
      eCheckModel.account = segment.replace(/\D/g, '');
    } else {
      eCheckModel.checkNo = segment.replace(/\D/g, '');
    }
  }

  // Make sure the extracted data is valid
  if (!eCheckModel?.route || eCheckModel?.route?.length !== route_length) {
    error = ErrorMessages.failedToReadRoutingNumber;
    return { error };
  } else if (!eCheckModel?.account) {
    error = ErrorMessages.failedToReadAccountNumber;
    return { error };
  } else if (!eCheckModel?.checkNo) {
    error = ErrorMessages.failedToReadCheckNumber;
    return { error };
  }

  return { eCheckModel };
}
