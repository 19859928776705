import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientConfiguration } from 'models/Client';
import {
  GetClientConfiguration,
  GetClientConfigurationResponse,
} from 'services/ClientService';
import { AppThunk } from 'app/rootReducer';
import { callService } from 'services/ServicesReducer';

export interface State {
  err?: string;
  clientConfiguration?: ClientConfiguration;
}

export const initialState: State = {
  err: '',
  clientConfiguration: undefined,
};

const reducerSlice = createSlice({
  name: 'Client',
  initialState,
  reducers: {    
    onReceiveClientConfiguration(
      state,
      action: PayloadAction<GetClientConfigurationResponse>
    ) {
      state.clientConfiguration = action.payload.result;
      state.err = action.payload.err;
     
    },
  },
});

export const {
  onReceiveClientConfiguration,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getClientConfiguration(
  clientId: string,
  onDone?: () => void
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService('GetClientConfiguration', async () => {
      await GetClientConfiguration(clientId).then(data => {
        dispatch(onReceiveClientConfiguration(data));
        if (onDone) onDone();
      });
    }))    
  };
}
