import {
  EDS_Select
} from '@EH/eh.eds.react';
import { Checkbox, Input, Radio } from "@EHDS/core";
import { DistributionOrder } from "models/enums/EnumPaymentPanels";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { enumToMultiSelectOptions, multiSelectOptionsToSelectOptions } from "utils/Utils";
import { isNumber } from 'validate.js';
import { Entity, MetaData } from "../../../models/metaData/MetaData";
import { Dashboards } from "./ConfigurationSelector";
import { saveConfiguration } from "./PaymentPanelReducer";
import { SketchPicker } from 'react-color'
import { ReactComponent as Collapse } from 'assets/svgs/admin/paymentPanel/collapse.svg';
import { ReactComponent as Expand } from 'assets/svgs/admin/paymentPanel/expand.svg';


export function DashboardConfiguration(props: { onHeaderUpdate: any, dashboardName?: keyof Dashboards, configuration?: MetaData }) {
  const { configuration, dashboardName } = props;
  const dispatch = useDispatch();
  const attributes = configuration?.entity?.attributes;
  const sortByOptions: {optionName: string, value?: string}[] = attributes?.map((a, index) => ({
    optionName: a.label ?? '',
    value: a.name === 'undefined' ? a.name + index : a.name
  })) ?? [];
  sortByOptions.splice(0, 0, { optionName: "None", value: undefined });
  const [distributionOrderOption] = useState<{optionName: string, value: string}[]>(multiSelectOptionsToSelectOptions(enumToMultiSelectOptions(DistributionOrder)));
  const currentEntity = configuration?.entity;
  const [header, setHeader] = useState('');
  const [color, setColor] = useState('#aaaaaa');
  const [isOpen, setIsOpen] = useState(false);
  const [sortDescending, setSortDescending] = useState(false);
  const sortByDescending = currentEntity?.sortDescending ?? sortDescending;

  function updateDashboardConfiguration(propertyName: keyof Entity, value: any) {
    if (dashboardName && configuration && configuration.entity) {
      dispatch(saveConfiguration({
        key: dashboardName,
        configuration: {
          ...configuration,
          entity: {
            ...configuration.entity,
            [propertyName]: value
          }
        },
      }));
    }
  }

  useEffect(() => {
    if(currentEntity){
      if(currentEntity?.panelTitle){
        setHeader(currentEntity.panelTitle);
      }
        
      if(currentEntity?.colorCode){
        setColor(currentEntity.colorCode);
      }
      onHeaderUpdate(currentEntity?.panelTitle as string,currentEntity?.colorCode as string);
    }
  }, [currentEntity?.panelTitle,currentEntity?.colorCode]);

  function getDistributionOrderValue() {
    
    if(!currentEntity?.distributionOrder){
      return DistributionOrder.None;
    }
   return (isNumber(Number(currentEntity?.distributionOrder)) ? currentEntity.distributionOrder: DistributionOrder[currentEntity.distributionOrder])
  }


  const getColorCodeValue = () => {
    if(!currentEntity?.colorCode){
      return "#aaaaaa";
    }
   return  currentEntity.colorCode;
  }

  const getHeaderTextValue = () => {
    if(!currentEntity?.panelTitle){
      return "<Facility>";
    }
   return  currentEntity.panelTitle;
  }

  const handleOpenColorPicker = () => {
    setIsOpen(!isOpen);
  }

  const sortOptions = (options: {optionName: string, value?: string}[]) => {
    return options.sort((a, b) => a.optionName.localeCompare(b.optionName));
  }

  const onHeaderUpdate = (headerVal: string, colorVal: string) => {
    setHeader(headerVal);
    setColor(colorVal);
    props.onHeaderUpdate(headerVal, colorVal);
  };

  const getDistributionOrder = () => {
    const dashboards = ['PaymentPlanDashboard', 'GlPaymentPlanDashboard', 'PaymentPlanDashboardDraft', 'GlPaymentPlanDashboardDraft'];
    const name = dashboardName ?? '';
    if(!dashboards.includes(name)) {
      return (
        <div className='mx-1 distribution-order'>
          <EDS_Select
            label="Distribution Order"
            name="distributionOrder"
            options={sortOptions(distributionOrderOption)}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              updateDashboardConfiguration("distributionOrder", e.target.value);
            } }
            value={getDistributionOrderValue()} />
        </div>
      )
    }
  }
  return <>
 
  <div className={"d-flex px-3 mx-0 flex-wrap panel-setting"} >
    <div className={"col-md-3 px-0 mx-0"}  >
    <Input label="Header text" 
        name="headerText"
        onChange={e => {
          const { value } = e.target;
          updateDashboardConfiguration('panelTitle', value);
          onHeaderUpdate(value, color);
        }}
        value={getHeaderTextValue()}
      />
    </div>
      {(dashboardName !== 'PatientSearchPopupDashboard' && dashboardName !== "PatientSearchPopupDashboardDraft")?
      <>
      <div className={"col-md-5 px-0 mx-0"} >
      <div>
          <div className='d-flex align-items-baseline flex-wrap'>
            <div className='mx-1 enable-account'>
                  <Checkbox label="Enable Add Account" checked= {currentEntity?.addRowEnabled} onChange={e => {
                  const { checked } = e.target;
                  updateDashboardConfiguration('addRowEnabled', checked);
                }}/>
            </div>
            <div className='mx-1 sort-by'>
            <EDS_Select
            label="Sort By"
            name="sortByField"
            options={sortOptions(sortByOptions)}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              updateDashboardConfiguration("sortByField", e.target.value);
            } }
            value={currentEntity?.sortByField} />
            </div>
            <div className='mx-1 asc-dsc'>
            <Radio
            label={"Ascending"}
            onChange={() => {
              updateDashboardConfiguration("sortDescending", false);
              setSortDescending(false);
            } }
            checked={!sortByDescending} />
            </div>
            <div className='mx-1 asc-dsc'>
            <Radio
            label={"Descending"}
            onChange={() => {
              updateDashboardConfiguration("sortDescending", true);
              setSortDescending(true);
            } }
            checked={sortByDescending} />
            </div>
          </div>
          </div>
          </div>
          <div className={"col-md-4 px-0 mx-0"} >
          <div className='d-flex flex-wrap'>
            {getDistributionOrder()}
            <div className='mx-1 color-label'>
                <span className={'color-label'}>
                  Color Code
                </span>
                <div className={'color-input'} onClick={handleOpenColorPicker} id="color-picker" >
                  <div style={{ backgroundColor: color }} className={'color-selected'} id="selected-color">
            </div>
            {!isOpen ? <Expand /> : <Collapse />}
                  {isOpen && <div className={'color-picker'}>
              <SketchPicker
                width='200px'
                color={getColorCodeValue()}
                      presetColors={[]}
                      onChangeComplete={(e: any) => {
                        const value = e.hex;
                  updateDashboardConfiguration('colorCode', e.hex);
                  onHeaderUpdate(header, value);
                      } }
              />
            </div>}
            </div>
        </div>
          </div>
      </div>
      </> : null }
  </div>
  </>
}