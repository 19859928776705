import { PatientInfoModel } from 'features/patientInfo/PatientInfoReducer';
import { PatientInfoMetaDataForm } from 'models/Metadata';
import { PatientEncounterModel } from 'models/PatientEncounterModel';
import { PatientModel, PaymentOnFile, GetPatientRequest } from 'models/PatientModel';
import { callHttpApi } from 'services/callHttpApi';
import getFakeMetaDatForPatientInfoForm from 'utils/metadata/MetadataPatientInfo';
import { CreatePatientRequest } from './models/CreatePatientRequest';
import { CreatePatientResult } from './models/CreatePatientResult';

export type PatientsModel = PatientModel[];

export interface RecordsPatientsModel {
  total: number;
  limit: number;
  offset: number;
  records: PatientsModel;
}

export interface GetPatientsResult {
  result?: PatientsModel;
  err?: string;
}

export interface GetPatientResult {
  result?: PatientModel;
  err?: string;
}

export interface SoftDeletePaymentOnFileResult {
  result?: { patient: PatientModel };
  err?: string;
}

export async function getPatientsService(patientQuery?: string) {
  return callHttpApi<PatientsModel>(
    'get',
    `/api/v1/Patient${patientQuery ?? ''}`
  );
}

export async function getPatientsPOSTService(patientQuery?: GetPatientRequest) {
  return callHttpApi<RecordsPatientsModel, GetPatientRequest>(
    'post',
    `/api/v1/Patient/search`,
    patientQuery
  );
}

export async function getPatient(id: string): Promise<GetPatientResult> {
  return callHttpApi<PatientModel>('get', `api/v1/Patient/${id}`);
}

export interface GetPatientByIdResult {
  result?: PatientModel;
  err?: string;
}

export interface PInfoMetaDataResult {
  result?: PatientInfoMetaDataForm;
  err?: string;
}

export interface GetPatientEncountersResult {
  result?: PatientEncounterModel[];
  err?: string;
  errorResponse?: any
}

export interface GetPatientEncountersRequest {
  clientId: string;
  patientId?: string;
}

export async function getPatientById(
  patientId: string
): Promise<GetPatientByIdResult> {
  return callHttpApi<PatientModel>('get', `api/v1/Patient/${patientId}`);
}

export async function GetPatientInfoFormMetaData(): Promise<
  PInfoMetaDataResult
> {
  return {
    result: getFakeMetaDatForPatientInfoForm(),
  };
}

export async function GetPatientEncountersService(
  data: GetPatientEncountersRequest,
  queryParams?: URLSearchParams
): Promise<GetPatientEncountersResult> {
  let url = `api/v1/Patient/encounters/${data.clientId}`;
  return callHttpApi<PatientEncounterModel[]>('get', url, undefined, queryParams);
}

export async function softDeletePaymentOnFile(
  id: string,
  tokenId: string
): Promise<SoftDeletePaymentOnFileResult> {
  return callHttpApi<{ patient: PatientModel }>(
    'delete',
    `api/v1/Patient/${id}/paymentTokens/${tokenId}`
  );
}

export async function createPatientService(patientInfoModel: PatientInfoModel) {
  return callHttpApi<PatientModel, CreatePatientRequest>(
    'post',
    `api/v1/Patient`,
    mapPatientInfoModelToPatientModel(patientInfoModel)
  );
}

export async function addPaymentOnFileService(
  patientId: string,
  paymentOnFile: PaymentOnFile
): Promise<CreatePatientResult> {
  return callHttpApi<{ patient: PatientModel }, PaymentOnFile>(
    'post',
    `api/v1/Patient/${patientId}/paymentTokens`,
    paymentOnFile
  );
}

function mapPatientInfoModelToPatientModel(
  patientInfoModel: PatientInfoModel
): CreatePatientRequest {
  return {
    firstName: patientInfoModel?.firstName,
    lastName: patientInfoModel?.lastName,
    dateOfBirth:
    patientInfoModel?.dateOfBirth ?? null,
    mrn: patientInfoModel?.mrn,
    guarantorAccountNo: patientInfoModel?.guarantorId,
    isGl: patientInfoModel?.isGl,
    accountNo: patientInfoModel?.accountNo,
    secondaryMRN: patientInfoModel?.secondaryMRN,
    consolidationId: patientInfoModel?.consolidationId,
    secondaryConId: patientInfoModel?.secondaryConId,
  };
}
