import { LineItem } from "./LineItem";
import { OrganizationReference } from "./PaymentPlan";
import { Tiers } from "./PaymentPlanConfiguration";
import { CreditCardBrand } from "./enums/CreditCardBrand";

export interface GlDisplayField extends LineItem{
  glId?: string,
  glNumber?: string,
  glUnits?: string,
  description?: string,
  unitCost?: number,
  billingDepartment?: string,
  amount: number,
  edited?: boolean,
  email?: string,
}

export interface AddressSettings {
  allAddressFieldsRequired?: boolean,
  streetAddressRequired?: boolean,
  cityRequired?: boolean,
  stateRequired?: boolean,
  zipRequired?: boolean,
  phoneNumberRequired?: boolean,
}

export interface PaymentConfiguration extends AddressFieldsConfiguration, AllowedPaymentCardTypes {
  id?: string,
  organization?: OrganizationReference,
  templates?: {
    [key: string]: string,
  },
  toggles?: {
    [key: string]: boolean | undefined,
  },
  strings?: {
    [key: string]: string,
  },
  glDisplayFields?: GlDisplayField[],
  hasCardDeviceAllowed?:boolean,
  hasCardSwipeAllowed?:boolean,
  hasCashAllowed?:boolean,
  hasCreditCardAllowed?:boolean,
  hasECheckAllowed?:boolean,
  hasMoneyOrderAllowed?:boolean,
  hasPaperCheckAllowed?: boolean,
  mailingAddressRequired?: boolean,
  tiers?: Tiers,
}

export interface AllowedPaymentCardTypes {
  isVisaAllowed?: boolean,
  isMasterCardAllowed?: boolean,
  isAmexAllowed?: boolean,
  isDiscoverAllowed?: boolean,
  isDinersClubAllowed?: boolean,
  isJcbAllowed?: boolean,
  isUnionPayAllowed?: boolean,
}

//all card types from allowedPaymentCardTypes
export const cardTypes: (keyof AllowedPaymentCardTypes)[] = ['isVisaAllowed', 'isMasterCardAllowed', 'isAmexAllowed', 'isDiscoverAllowed', 'isDinersClubAllowed', 'isJcbAllowed', 'isUnionPayAllowed'];
export const cardTypesToCardBrandMap: { [key in keyof AllowedPaymentCardTypes]: CreditCardBrand } = { 
  isVisaAllowed: CreditCardBrand.Visa, 
  isMasterCardAllowed: CreditCardBrand.MasterCard,
  isAmexAllowed: CreditCardBrand.AmericanExpress, 
  isDiscoverAllowed: CreditCardBrand.Discover, 
  isDinersClubAllowed: CreditCardBrand.DinerSClub, 
  isJcbAllowed: CreditCardBrand.Jcb, 
  isUnionPayAllowed: CreditCardBrand.UnionPay 
};

export interface AddressFieldsConfiguration {
  creditCardAddressSettings?: AddressSettings,
  moneyOrderAddressSettings?: AddressSettings,
  eCheckAddressSettings?: AddressSettings,
  cashAddressSettings?: AddressSettings
}