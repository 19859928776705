import { initializeEpicDevice } from "../../../../services/TransactionsService";

export function useVerifyInterconnect(
  onSuccess: () => void,
  onFail: (errorMessage?: string) => void,
) {
  const verifyInterconnect = async (organizationPath?: string) => {
    if (!organizationPath) {
      onFail("No organization path");
      return;
    }

    const response = await initializeEpicDevice(organizationPath);
    if (response.err) {
      onFail(response.err);
      return;
    }

    if (response.result) {
      const isInterconnectVerified = response.result.interConnectCredentialsVerified;
      if (isInterconnectVerified) {
        onSuccess();
        return;
      }
    }

    onFail();
  }

  return { verifyInterconnect };
}
