import { useTypedSelector } from '../app/rootReducer';
import { useSetOpenedTransaction } from '../features/searchTransaction/hooks/useSetOpenedTransaction';
import { AccountDetails } from '../features/accountDetails/AccountDetails';
import { Header } from '../features/transactionDetails/common/Header';
import { PatientDetails } from '../features/transactionDetails/common/PatientDetails';
import TransactionDetails from '../features/transactionDetails/common/TransactionDetails';
import RelatedTransactions from '../features/transactionDetails/RelatedTransactions';
import { Source } from 'features/accountDetails/hooks/useAccountDetails';

export default () => {
  const refreshed = useTypedSelector(p => !!p.tokenInfo.refreshed);
  const stateTransactionDetails = useTypedSelector(s => s.transactionDetails);

  const setOpenedTransaction = useSetOpenedTransaction();
  const onDone = () => {
    if (stateTransactionDetails.parentId) {
      setOpenedTransaction(stateTransactionDetails.parentId);
    }
  };

  const transactionDetails = stateTransactionDetails?.transactionDetailsData?.transactionDetails;

  return !refreshed ? <div> "Processing..." </div>:
    <div>
        <div className="eds-modal_#content payment-details-container">
          <div className="">
            <div className="payment-details-header">
              <Header />
              <PatientDetails />
              <TransactionDetails />
              <div className="card">
                <div className="card-header">
                  <span>Related Transactions</span>
                </div>
                <div className="card-content">
                  <RelatedTransactions relatedTransactionArray={ stateTransactionDetails?.transactionDetailsData?.relatedTransactions} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="eds-modal_#footer">
          <hr />
          <div className="text-right mr-4 mb-2">
            <button className="eds-button eds-button.basic" onClick={onDone}>
              <span className="button-label">Done</span>
            </button>
          </div>
        </div>
    </div>;
};