import { Box, LinearProgress } from "@material-ui/core";


export default function LoadingProgress() {

  return (
    <div className="customCheckbox">
      <Box sx={{ width: '30%' }} style={{ float: 'left' }}>
        LOADING...
      </Box>
      <Box sx={{ width: '70%', marginTop: '12px' }}>
        <LinearProgress />
      </Box>
    </div>
  )
}