import { ServerPaymentModel } from 'models/PaymentModel';
import { callHttpApi } from 'services/callHttpApi';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import { CreateTransactionResult } from './models/CreateTransactionResult';
import CreditCardDeviceRequest from './models/CreditCardDeviceRequest';
import { EnumTransactionStatusType } from 'models/enums';
import { EnumTransactionType } from 'models/enums/EnumTransactionType';

const fakeRequestData = false;
const fakeResponseData = false;

export async function CreateCreditCardDeviceTransaction(
  payment: ServerPaymentModel
): Promise<CreateTransactionResult> {
  if (!fakeResponseData) {
    return callHttpApi<TransactionDetailsModel, CreditCardDeviceRequest>(
      'post',
      `api/v1/Transactions/cardDevice`,
      mapPaymentToCreditCardDeviceRequest(payment)
    );
  } else {
    return {
      result: {
        id: '5f3ac6b01ecb544c3cf91a65',
        transactionType: EnumTransactionType.Payment,
        amount: 100.0,
        statusType: EnumTransactionStatusType.Processing,
        gatewayReferenceNumber: '262434',
      },
    };
  }
}

function mapPaymentToCreditCardDeviceRequest(
  payment: ServerPaymentModel
): CreditCardDeviceRequest {
  if (!fakeRequestData) {
    return {
      PatientAccountNumber: payment.patientAccountNumber,
      Amount: payment.paymentTotal,
      Device: {
        id: payment.typeCreditDebitEntry?.deviceId,
        name: payment.typeCreditDebitEntry?.deviceName,
        serialNumber: payment.typeCreditDebitEntry?.deviceSerialNumber
      },
      LineItems: payment.isGL ? payment.glDetails : payment.details,
      CustomerFirstName: payment.billingInformation?.firstName,
      CustomerLastName: payment.billingInformation?.lastName,
      CreatedDate: mapCreatedDate(payment),
      Discount: payment.discount,
      Episode: payment.episode,
      GuarantorId: payment.guarantorId,
      isGL: payment.isGL,
      MRN: payment.mrn,
      Organization: payment.organization,
      Department: payment.department,
      Facility: payment.facility,
      PatientId: payment.patientId,
      PatientFirstName: payment.patientFirstName,
      PatientLastName: payment.patientLastName,
      PatientDateOfBirth: payment.patientDateOfBirth,
      BillingInformation: payment.billingInformation,
      MailingInformation: payment.mailingInformation,
      Notes: payment.notes,
      PaymentClientId: payment.clientID,
      PaymentSource: payment.paymentSource,
      NotificationEmail: payment.notificationEmail,
      paymentPlanId: payment.paymentPlanId,
      totalPlanBalance: payment.totalPlanBalance,
      TransactionGroupID: payment.transactionGroupId,
      IsPaymentMethodReusable: payment?.isReusable,
    };
  } else {
    return {
      Amount: 100,
      Device: {       
        id: 'fake device id',
        name: 'fake device name', 
        serialNumber: '1240042946'
      },
      PatientAccountNumber: '1234',
      LineItems: [
        {
          id: '1',
          amount: 100,
          voidAmount: 10,
          isActive: true,
          notes: 'fakeNotes',
          data: {
            id: '1',
            discount: '5',
            dateOfService: '2020-09-25',
            unitCount: '10',
            unitCost: '100',
            description: 'fake description',
            accountNumber: 'fake account number',
            department: 'fake department',
          },
        },
        {
          id: '1',
          amount: 90,
          voidAmount: 9,
          isActive: false,
          notes: 'fakeNotes',
          data: {
            id: '1',
            discount: '3',
            dateOfService: '2020-09-09',
            unitCount: '9',
            unitCost: '90',
            description: 'fake description 2',
            accountNumber: 'fake account number 2',
            department: 'fake department 2',
          },
        },
      ],
      isGL: false,
    };
  }
}

function mapCreatedDate(payment: ServerPaymentModel): string | undefined {
  return payment.details && payment.details.length > 0
    ? payment.details[0].dateOfService
    : undefined;
}
