import '../../../assets/styles/components/_addNewRemitModal.scss';
import { Popup } from "components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";

export default (props: {
  close: () => void;
  onNext: () => void;
}) => {
  const { close, onNext } = props;

  const footerButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel',
      onClick: close
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Remove',
      onClick: onNext
    },
  ];

  return (
  <Popup 
    header="PaymentSafe®"
    onClose={close}
    title="Remove Setting"
    footer={footerButtons}
    onSubmit={onNext}
    customClassNames={{footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
    >
      <div className="popupContent">
        <div className="rowItemPopup">
          Are you sure, you want to delete this item?
        </div>             
      </div>
  </Popup>
  );
};