import React, { ReactElement } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { EDS_TextArea } from '@EH/eh.eds.react';
import { OrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import { UserDetailModel } from 'models/UserInfoAndRolesModel';
import { ApiTenderTypeEnum, checkReceivedHasValues } from 'utils/Utils';
import Logo from 'features/Logo';
import { useOrganizations } from '../../../organizations/hooks/useOrganizations';
import { LogoManager } from 'models/LogoManagerModel';
import EnumTransactionTenderType from 'models/enums/EnumTransactionTenderType';

export function CommonPopupChildren(props: {
    department?: OrganizationLevelDocument,
    transaction?: TransactionDetailsModel,
    transactionCreator?: UserDetailModel,
    isCompleted?: boolean,
    accounts?: ReactElement,
    notes?: string,
    showNotes: boolean,
    logo?: LogoManager;
    onChangeNotes?: (value?: string) => void,
}) {

  const { getFacilityByDepartment } = useOrganizations();
  const { transaction, transactionCreator, isCompleted, accounts, notes, onChangeNotes, showNotes, logo} = props;

  const department = transaction?.organization;
  const facility = getFacilityByDepartment(transaction?.organization?.path ?? '');
  const firstName = checkReceivedHasValues(transaction?.billingInformation?.firstName, transaction?.billingInformation?.firstName, transaction?.mailingInformation?.firstName);
  const lastName = checkReceivedHasValues(transaction?.billingInformation?.lastName, transaction?.billingInformation?.lastName, transaction?.mailingInformation?.lastName);

    const displayTenderType: {
      [key: string]: string
    } = {
      [String(ApiTenderTypeEnum.CardDevice)]: `Card Number: **** **** **** ${transaction?.cardNumber || '****'}`,
      [String(ApiTenderTypeEnum.CardManual)]: 'Card Entry',
      [String(ApiTenderTypeEnum.ECheck)]: 'eCheck',
      [String(ApiTenderTypeEnum.Undefined)]: 'Not Set',
      [String(ApiTenderTypeEnum.PaperCheckAsECheck)]: 'Paper Check',    
      [String(ApiTenderTypeEnum.MoneyOrder)]: 'Money Order',
      [String(ApiTenderTypeEnum.Cash)]: 'Cash',
      [String(EnumTransactionTenderType.Card)]: 'Credit/Debit Manual Entry',
      [String(EnumTransactionTenderType.eCheck)]: 'eCheck',
    }

  return (
    <>
      <div className="row">
        <div className="row-item eds-heading eds-heading.mdplus-caps mt-2">
          {'Payment location'.toUpperCase()}
        </div>
        <div className="row logo">
          <Logo src={logo} />
        </div>
      </div>
      <div className="column-12">
        <div className="flex-row">
        
          <div className="column-6">
            <div className="row">
              <div className="col-4 row-data eds-field_#label pb-0">Facility: </div>
              <div className="col-8 row-data">{facility?.name}</div>
            </div>
            <div className="row">
              <div className="col-4 row-data eds-field_#label pb-0">Department: </div>
              <div className="col-8 row-data">{department?.name}</div>
            </div>
            <div className="row">
              <div className="col-4 row-data eds-field_#label pb-0">Created By: </div>
              <div className="col-8 row-data">{` ${transactionCreator?.firstName ?? ''} ${transactionCreator?.lastName ?? ''}`.trimEnd()}</div>
            </div>            
          </div>
          <div className="column-6">
            {
              isCompleted ? 
                <div className="row">
                  <div className="col-6 row-data void-status">Transaction Approved - Ref#: {transaction?.gatewayReferenceNumber}</div>
                </div> :
              null
            }
          </div>
        </div>
      </div>

      <div className="column-12">
        <div className="flex-row pb-20">
          <div className="column-6">
            <div className="row">
              <div className="row-item eds-heading eds-heading.mdplus-caps mt-2 mb-3">
                {'Patient/Payer Detail'.toUpperCase()}
              </div>
            </div>
            <div className="row pl-0">
              <div className="col-5 row-data eds-field_#label pb-0">Patient ID:</div>
              <div className="col-7 row-data">{transaction?.patientId}</div>
            </div>
            <div className="row">
              <div className="col-5 row-data eds-field_#label pb-0">MRN:</div>
              <div className="col-7 row-data">{transaction?.mrn}</div>
            </div>
            <div className="row">
              <div className="col-5 row-data eds-field_#label pb-0">Patient First Name:</div>
              <div className="col-7 row-data">{transaction?.patientFirstName}</div>
            </div>
            <div className="row">
              <div className="col-5 row-data eds-field_#label pb-0">Patient Last Name:</div>
              <div className="col-7 row-data">{transaction?.patientLastName}</div>
            </div>
            <div className="row">
              <div className="col-5 row-data eds-field_#label pb-0">Payer First Name:</div>
              <div className="col-7 row-data">{firstName}</div>
            </div>
            <div className="row">
              <div className="col-5 row-data eds-field_#label pb-0">Payer Last Name:</div>
              <div className="col-7 row-data">{lastName}</div>
            </div>
          </div>

          <div className="column-6">
            <div className="row">
              <div className="row-item eds-heading eds-heading.mdplus-caps mt-2 mb-3">
                {'Payment detail'.toUpperCase()}
              </div>
            </div>
          <div className="row">
            <div className="col-5 row-data eds-field_#label pb-0">Transaction ID:</div>
            <div className="col-7 row-data">{transaction?.id}</div>
          </div>
          <div className="row">
            <div className="col-5 row-data eds-field_#label pb-0">Transaction Status:</div>
            <div className="col-7 row-data">{transaction?.statusType}</div>
          </div>
          <div className="row">
            <div className="col-5 row-data eds-field_#label pb-0">Plan ID:</div>
            <div className="col-7 row-data">{transaction?.paymentPlanId}</div>
          </div>
          <div className="row">
            <div className="col-5 row-data eds-field_#label pb-0 center">Payment Date/Time:</div>
            <div className="col-7 row-data">{moment(transaction?.createdDate).tz('UTC').format('MM/DD/YYYY h:mma z')}</div>
          </div>
          <div className="row">
            <div className="col-5 row-data eds-field_#label pb-0">Transaction Type:</div>
            <div className="col-7 row-data">{transaction?.transactionType}</div>
          </div>
          <div className="row">
            <div className="col-5 row-data eds-field_#label pb-0 ">Tender Type:</div>
            <div className="col-7 row-data">{displayTenderType[transaction?.tenderType ?? '']}</div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 p-0">
      <div className="flex-row">
        <div className="col-12 pl-0 pr-20">
          {accounts}
        </div>
      </div> 
      {
        !showNotes ? null : 
        <div className="col-12 pt-3 pl-0 pr-20 ">
          <div className="flex-row">
              <EDS_TextArea
                  name="notes"
                  label="Notes"
                  modifiers="patient-mail-info-notes row-item row-item-size-double"
                  heightModifier={'auto'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onChangeNotes && onChangeNotes(e.target.value) }}
                  value={notes}
              ></EDS_TextArea>
          </div> 
        </div>
      }
    </div>    
    </>
  )  
}