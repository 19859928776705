import { Input } from '@EHDS/core';
import { PatientInfoModel } from 'features/patientInfo/PatientInfoReducer';
import { ChangeEvent, useEffect } from 'react';
import { useTypedSelector } from '../../app/rootReducer';
import { getMaxDate, getMinDate } from '../../utils/Utils';
import useGetPatientConfiguration from '../admin/paymentPanel/serviceHandlers/useGetPatientConfiguration';
import { addCustomFields, DisplayDynamicFields } from './PatientInfoUtils';

export default (props:{ 
  facilityPath?:string, 
  changeHandler: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)  => void,
  changeDateHandler : (event: React.ChangeEvent<HTMLInputElement>) => void,
  changeDateHandlerBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
  stateValue:PatientInfoModel
}) => {
  let {
    facilityPath,
    changeHandler,
    changeDateHandler,
    changeDateHandlerBlur,
    stateValue
  } = props;

  let mrnLabel = "";
  let guarantorIdLabel = "";
  let dateOfBirthLabel = "";
  let acctNoLabel = "";
  const { getConfiguration } = useGetPatientConfiguration(facilityPath);
  useEffect(() => {
    getConfiguration('PatientSearchPopupDashboard');
  }, [facilityPath])

  const configuration = useTypedSelector(s => s.paymentPanel.patientConfigurations?.PatientSearchPopupDashboard);
  const attributes = configuration?.attributes;
  let elements: JSX.Element[]=[];
  attributes?.forEach(a => {
     switch (a.name.toLowerCase()) {
        case "firstname":
          elements.push(<Input
            label="*Patient First Name:"
            name={'firstName'}
            placeholder={a.placeholderText || "Enter patient's name"}
            className={'rowItem eds4 custom-field'}
            value={stateValue?.firstName || ''}
            onChange={changeHandler}
          />);

          break;
        case "lastname":
          elements.push(<Input
            label={'*Patient Last Name:'}
            name={'lastName'}
            placeholder={a.placeholderText || "Enter patient's name"}
            className={'rowItem eds4 custom-field'}
            value={stateValue?.lastName || ''}
            onChange={changeHandler}
          />);
          break;
        case "birthdate":
          dateOfBirthLabel = a.label as string;
          elements.push(
          <label className="eds-field rowItem custom-field">
            <div className="eds-field_#label">{`${dateOfBirthLabel || "Date of Birth"}:`}</div>
            <div className="eds-field_#control eds-field_.eds-input eds-input">
              <input
                type="date"
                name="dateOfBirth"
                className="eds-input_#input"
                value={stateValue?.dateOfBirth}
                onChange={changeDateHandler}
                max={getMaxDate()}
                min={getMinDate()}
                onBlur={changeDateHandlerBlur}
              ></input>
            </div>
          </label>)
          break;
        case "mrn":
          mrnLabel = a.label as string;
          elements.push(<Input
            label={`${mrnLabel || "MRN"}:`}
            name={'mrn'}
            placeholder={a.placeholderText || "Please enter patient's MRN"}
            className={'rowItem eds4 custom-field'}
            value={stateValue?.mrn}
            onChange={changeHandler}
          />)
          break;
        case "guarantoraccountno":
          guarantorIdLabel = a.label as string;
          elements.push(<Input
            label={`${guarantorIdLabel || "Guarantor ID"}:`}
            name={'guarantorId'}
            placeholder={a.placeholderText || 'Please enter Guarantor ID'}
            className={'rowItem eds4 custom-field'}
            value={stateValue?.guarantorId}
            onChange={changeHandler}
          />)
          break;
        case "accountno":
          acctNoLabel = a.label as string;
          elements.push(<Input
            label={`${acctNoLabel || "Account Number"}:`}
            name={'accountNo'}
            placeholder={a.placeholderText || 'Please enter account number'}
            className={'rowItem eds4 custom-field'}
            value={stateValue?.accountNo}
            onChange={changeHandler}
          />)
          break;
        default:
          elements.push(addCustomFields(a, changeHandler, stateValue));
      }
    })

  return (
    <>
      {DisplayDynamicFields(elements)}
    </>
  );
};
