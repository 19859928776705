import { LineItem } from "../../../models/LineItem";
import { planToServerLineItem } from '../maps/PlanToServerLineItem';

export function getServerLineItems(lineItems?: LineItem[], patientId?: string, isAdhoc?:boolean) {
  return lineItems?.map((lineItem) => {
    return {
      ...lineItem,
      ...planToServerLineItem(lineItem, patientId, isAdhoc)
    };
  });
}