import { ClientMetadata } from 'models/Metadata';
import {
  DataType,
  AnnotationType,
  ValidatorType,
  EntityType,
} from 'models/enums/Metadata';

export default function getFakeMetaDataRegularLineItemsForClient(): ClientMetadata {
  return {
    path: '|100001|',
    orgLevelId: '100001',
    application: 'ProjectStella',
    name: 'PaymentLineItems',
    description: 'Model for creating Line Items for the Payment',
    version: '1.0',
    modifiedTime: new Date(),
    isHidden: false,
    entities: [
      {
        name: EntityType.LineItem,
        attributes: [
          {
            name: 'Balance',
            description: 'Line Item Balance',
            dataType: DataType.Decimal,
            annotations: [
              {
                name: AnnotationType.Label,
              },
              {
                name: AnnotationType.Order,
              },
              {
                name: AnnotationType.ValueType,
              },
            ],
          },
          {
            name: 'balance',
            description: 'Line Item Balance Due',
            dataType: DataType.Decimal,
            annotations: [
              {
                name: AnnotationType.Label,
              },
              {
                name: AnnotationType.Order,
              },
              {
                name: AnnotationType.ValueType,
              },
            ],
          },
          {
            name: 'DateOfService',
            description: 'Line Item Date of Service',
            dataType: DataType.DateTime,
            validators: [
              {
                name: ValidatorType.DateTimeValidator,
                required: true,
              },
            ],
            annotations: [
              {
                name: AnnotationType.Label,
              },
              {
                name: AnnotationType.Order,
              },
            ],
          },
          {
            name: 'Discount',
            description: 'Line Item Discount',
            dataType: DataType.Decimal,
            validators: [
              {
                name: ValidatorType.RangeValidator,
                required: true,
              },
            ],
            annotations: [
              {
                name: AnnotationType.Label,
              },
              {
                name: AnnotationType.Order,
              },
              {
                name: AnnotationType.ValueType,
              },
            ],
          },
          {
            name: 'Notes',
            description: 'Line Item Notes',
            dataType: DataType.String,
            annotations: [
              {
                name: AnnotationType.Label,
              },
              {
                name: AnnotationType.Order,
              },
            ],
          },
          {
            name: 'isActive',
            description: 'is line item active',
            dataType: DataType.Boolean,
          },
        ],
      },
    ],
  };
}
