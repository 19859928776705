import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { Popup } from "../../components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType, EnumPopupType } from "../../models/enums/EnumPopupType";
import { nameof } from "../../utils/Utils";
import { SelectionChangedEvent } from "ag-grid-community";
import { PatientModel } from "../../models/PatientModel";

export function MultipatientGridSelectorPopup(props: {
  patients?: PatientModel[],
  selectedPatient?: PatientModel,
  setSelectedPatient: (patient?: PatientModel) => void,
  closePopup: () => void,
  onSubmit: () => void
}) {
  const { selectedPatient, setSelectedPatient, patients, closePopup, onSubmit } = props;

  const footerButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Refine Search Criteria',
      disabled: false
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Continue',
      disabled: !selectedPatient
    },
  ];

  return <div className={"multipatient-popup"}><Popup
    header="Multiple patient search results were found!"
    type={EnumPopupType.basic}
    customClassNames={{ container: "large-popup" }}
    footer={footerButtons}
    onClose={() => {
      closePopup();
      setSelectedPatient();
    }}
    title="Please select the desired patient to continue."
    onSubmit={() => {
      if (selectedPatient) {
        onSubmit();
        closePopup();
      }
    }}
  >{patients ? <>
    <p>You may choose to refine the search criteria or create a new patient with the criteria you have entered.</p>
    <div className={'popup-grid-container section ag-theme-alpine'}>
      <AgGridReact
        rowData={patients}
        defaultColDef={{
          resizable: true,
          minWidth: 180,
        }}
        columnDefs={headerDefinitions}
        rowSelection={'single'}
        suppressRowClickSelection={true}
        onSelectionChanged={(e: SelectionChangedEvent) => {
          const patient = e.api.getSelectedRows().map((patient: PatientModel) => patient)[0];
          setSelectedPatient(patient);
        }}
        suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
        disableStaticMarkup={true}

        pagination={true}

      />
    </div>
  </> : "No patietns found"}
  </Popup>
  </div>
}


const headerDefinitions = [
  {
    headerName: 'First Name',
    field: nameof<PatientModel>('firstName'),
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
  },
  {
    headerName: 'Last Name',
    field: nameof<PatientModel>('lastName'),
  },
  {
    headerName: 'MRN',
    field: nameof<PatientModel>('mrn'),
  },
  {
    headerName: 'Date of Birth',
    field: nameof<PatientModel>('dateOfBirth'),
  },
  {
    headerName: 'Guarantor ID',
    field: nameof<PatientModel>('guarantorAccountNo'),
  },
  {
    headerName: 'Account Number',
    field: nameof<PatientModel>('accountNo'),
  },
];