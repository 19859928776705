import { createECheckTransactionService } from 'services/transactions/CreateECheckTransactionService';
import PaymentModel, { ServerPaymentModel } from 'models/PaymentModel';
import { CreateCreditCardDeviceTransaction } from 'services/transactions/CreateCreditCardDeviceTransactionService';
import { useApi } from 'features/useApi';
import { CreateTransactionResult } from 'services/transactions/models/CreateTransactionResult';
import { CreateCashTransaction } from 'services/transactions/CreateCashTransactionService';
import { CreateMoneyOrderTransaction } from 'services/transactions/CreateMoneyOrderTransactionService';
import { CreatePaperCheckTransaction } from 'services/transactions/CreatePaperCheckTransactionService';
import { CreateCreditDebitEntryTransaction } from 'services/transactions/CreateCreditDebitEntryTransactionService';
import { CreatePaymentMethodOnFileTransaction } from 'services/transactions/CreatePaymentMethodOnFileTransactionService';
import { ApiTenderTypeEnum } from 'utils/Utils';

export function useCreateTransactionService(tenderType?: ApiTenderTypeEnum) {
  let createTransactionService = (_payment: ServerPaymentModel) =>
    Promise.resolve<CreateTransactionResult>({
      err: `Tender type '${tenderType}' is not supported.`,
    });

  if (tenderType === ApiTenderTypeEnum.SavedOnFile) {
    createTransactionService = CreatePaymentMethodOnFileTransaction;
  } else if (tenderType === ApiTenderTypeEnum.ECheck) {
    createTransactionService = createECheckTransactionService;
  } else if (tenderType === ApiTenderTypeEnum.CardDevice) {
    createTransactionService = CreateCreditCardDeviceTransaction;
  } else if (tenderType === ApiTenderTypeEnum.CardManual) {
    createTransactionService = CreateCreditDebitEntryTransaction;
  } else if (tenderType === ApiTenderTypeEnum.Cash){
    createTransactionService = CreateCashTransaction;
  } else if (tenderType === ApiTenderTypeEnum.MoneyOrder) {
    createTransactionService = CreateMoneyOrderTransaction;
  } else if (tenderType === ApiTenderTypeEnum.PaperCheckAsECheck) {
    createTransactionService = CreatePaperCheckTransaction;
  }
  const {
    state: stateCreateTransaction,
    sendRequest: createTransaction,
  } = useApi(createTransactionService, "createTransaction");

  return { stateCreateTransaction, createTransaction };
}
