import React from 'react';
import { EDS_Button } from '@EH/eh.eds.react';

export default (props: { className?: string; handleClick?: () => void, ariaLabel?: string}) => {
  const { className, handleClick, ariaLabel } = props;

  return (
    <div className={className}>
      <EDS_Button
        modifiers={'eds-button eds-button.tertiary'}
        ariaLabel={ariaLabel}
        iconName={'print'}
        buttonText={'Print'}
        onClick={handleClick}
      />
    </div>
  );
};
