import { EDS_Button } from '@EH/eh.eds.react';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { useGetOrganizations } from 'features/organizations/hooks/useGetOrganizations';
import DashBoards from 'features/paymentDashboard/DashBoards';
import { resetPatientMailingInformation } from 'features/paymentInfo/PatientMailingInformationReducer';
import CreatePaymentPlan from 'features/paymentPlan/CreatePaymentPlan';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as router from 'react-router-dom';
import { useSetReducersInitialState } from 'utils/hooks/useSetReducersInitialState';
import { useTypedSelector } from '../app/rootReducer';
import '../assets/styles/components/_payment.scss';
import { useOrganizations } from '../features/organizations/hooks/useOrganizations';
import { useGetPaymentConfiguration } from '../features/singlePayment/hooks/useGetPaymentConfiguration';
import { usePaymentWizzard } from '../features/wizards/PaymentWizard';
import { useScriptDangerous } from '../utils/hooks/useScriptDangerous';
import { setGLPaymentInfoState, setOpenModal } from './GLPaymentDashboard/GLPaymentDashboardReducer';
import { getAppSettings } from 'services/AppSettingsService';
import { replaceLineItems, setIsPayingNow, setIsPaymentType } from 'features/paymentDashboard/PaymentDashboardReducer';
import { checkStringHasValue, valueOrDefault } from 'utils/Utils';
import { replaceRecords, resetSelected, resetOperation } from 'features/paymentPlan/PaymentPlanReducer';

export default () => {
  const useScript = useScriptDangerous();

  const container = document.querySelectorAll("div.line-item-container")[0];
  useEffect(() => {
    if (container) {
      const script = `function testOption() {
        var b = document.querySelectorAll("div.line-item-container button")[0];
        changeOption(b, "option0");
        alert("option changed");
      }
      window.testChangeOption = testOption;`
      useScript(script);
    }
  }, [container]);
  const { search } = router.useLocation()
  const queryParams = new URLSearchParams(search);
  const facilityId = queryParams.get("facility");
  const departmentId = queryParams.get("department");
  const dispatch = useDispatch();
  const state = useTypedSelector(s => s.glPaymentDashboard?.value);
  const { useGetOrganizationById } = useOrganizations();
  const facility = useGetOrganizationById(valueOrDefault(facilityId, ''));
  const department = useGetOrganizationById(valueOrDefault(departmentId, ''));
  const { getOrganizations } = useGetOrganizations();
  const { getConfiguration } = useGetPaymentConfiguration(valueOrDefault(state?.facility?.path, ''));
  const isConfigurationProcessing = useTypedSelector(s => s.services.calls.GetPaymentConfiguration?.isProcessing)
  const [glPatientId, setGlPatientId] = useState<{ id: string }>()
  const [isConfigLoading, setIsConfigLoading] = useState(false)
  const wizard = usePaymentWizzard(true);
  const selectedPlans = valueOrDefault(useTypedSelector(s => s.paymentPlanInfo?.selected), []);
  const { glPaymentPageReducers: setGLPaymentPageReducersInitialState } = useSetReducersInitialState();
  const isPayingNow = useTypedSelector(s => s.paymentDashboard.isPayingNow);
  const isPaymentType = useTypedSelector(s => s.paymentDashboard.isPaymentType);

  const resetStateValues = () => {
    dispatch(resetPatientMailingInformation());
    dispatch(setIsPaymentType(true));
    dispatch(setIsPayingNow(false));
    dispatch(replaceLineItems({ lineItems: [], organizationPath: checkStringHasValue(facility?.path)}));
    dispatch(replaceRecords([]));
    dispatch(resetSelected());
    dispatch(resetOperation());
  };

  useEffect(() => {
    getOrganizations();
    dispatch(
      setBreadcrumbLinks([{ name: 'Make a GL Payment', slug: 'payment' }])
    );
    setGLDummyPatient(setGlPatientId);
    // on unload set breadcrumb links
    return () => {
      dispatch(setBreadcrumbLinks([]));
      setGLPaymentPageReducersInitialState();
    };
  }, [dispatch]);

  useEffect(() => {
    resetStateValues();
    dispatch(
      setGLPaymentInfoState({
        ...state,
        facility: facility,
        department: department
      }),
    )
  }, [facility]);

  useMemo(async () => {
    if (state?.facility?.id) {
      setIsConfigLoading(true)
      await getConfiguration();
      setIsConfigLoading(false)
    }
  }, [state?.facility?.id]);

  let dashBoards = useMemo(() => {
    if (facility && !isConfigLoading) {
      return <DashBoards
        key={facility.id}
        isGl={true}
        wizard={wizard}
        patientId={glPatientId?.id}
        selectedFacility={facility}
        paymentType={isPaymentType}
      />
    }
  }, [facility, isConfigLoading]);


  const onCreatePlanButtonClick = () => {
    dispatch(setIsPaymentType(false));
  }

  return facility && department ? (
    <>
      <div className="pane">
        <div className="left">
          <div className="facility-container">
            <div className="eds-heading eds-heading.mdplus-caps mb-1">
              User Location
            </div>
            <div className="facility-form">
              <div className="facility-form-two">
                <div>
                  Facility:
                  <span style={{ fontWeight: "bold", paddingRight: "30px" }}> {state?.facility?.name !== 'Facility 1' && state?.facility?.name}</span>
                </div>
                <div>
                  Department:
                  <span style={{ fontWeight: "bold" }}> {state?.department?.name !== 'Department 1' && state?.department?.name}</span>
                </div>
              </div>
              <div>
                <EDS_Button
                  modifiers={'eds-button eds-button.basic'}
                  buttonText={'Facility/Department'}
                  iconName={'search'}
                  onClick={() => dispatch(setOpenModal(true))}
                />
              </div>
            </div>
          </div>
          {isPayingNow ? <div className="payment_overlay"> </div> : null}
          <div className={`dashboards-container ${isPayingNow ? "content_overlay_fix": ""}`}>
            {dashBoards}
          </div>
        </div>
        <div className="right">
          {isConfigurationProcessing ? "Processing..." :
            <CreatePaymentPlan
              isGl={true}
              wizard={wizard}
              ids={selectedPlans}
              patientId={glPatientId?.id}
              organization={state.department}
              setGlPatientId={setGlPatientId}
              facilityInfo={state?.facility}
              showNextPatientModal={false}
              onCreatePlanButtonClick={onCreatePlanButtonClick}
            />
          }
        </div>
      </div>
    </>
  ) : (
    <div>Processing ...</div>
  )
};

export async function setGLDummyPatient(
  setGlPatientId?: (id: { id: string }) => void,
) {
  const appSettings = await getAppSettings();
  setGlPatientId && setGlPatientId({ id: appSettings?.GL_PATIENT_ID || "6278be9f2a6e88a72c94efdb" })
}