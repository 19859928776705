import { Popup } from "components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";
import { useDeleteRemitConfiguration } from 'features/admin/remitConfiguration/serviceHandlers/useDeleteRemitConfiguration'

const footerButtons = [
  {
    type: EnumPopupButtonsType.cancel,
    style: EnumPopupButtonsStyle.secondary,
    text: 'Cancel'
  },
  {
    type: EnumPopupButtonsType.submit,
    style: EnumPopupButtonsStyle.primary,
    text: 'Delete'
  },
];

export default (props: {
  close: () => void;
  fileId?: string;
  onSubmit: any;
}) => {
  const { close, fileId, onSubmit } = props;
  const { useDeleteFile } = useDeleteRemitConfiguration();

  const OnDelete = () => {
    useDeleteFile(fileId!, close)
    onSubmit();
  }
  
  return (
  <Popup 
    header="PaymentSafe®"
    onClose={close}
    title="DELETE REMIT FILE"
    footer={footerButtons}
    onSubmit={()=> { OnDelete() }}
    customClassNames={{footerButton: 'height30', container: 'deleteContainer', title: 'deleteTitle' }}
    >
       <div style={{lineHeight: "1.5", marginTop: "10px"}}>
          <div>
            Are you sure you want to delete this remit file? 
          </div>
          <div>
            This action cannot be undone.
          </div>
       </div>
  </Popup>
  );
};