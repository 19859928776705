import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MultiSelectOption } from '../../../components/select/MultiSelect';
import { PermissionItem } from '../../../models/Permission';
import { OrganizationUserSettings } from '../../../pages/Search/simpleSearch/SimpleSearchReducer';

export interface State extends OrganizationUserSettings {
  permissions?: PermissionItem[],
  tradingPartner?: MultiSelectOption | null,
}

export const initialState: State = {
  permissions: [],
  departments: [],
  facilities: [],
  users: [],
};

const reducerSlice = createSlice({
  name: 'UserInfo',
  initialState,
  reducers: {
    setPermissions(state, action: PayloadAction<{ permissions: PermissionItem[] }>) {
      state.permissions = action.payload.permissions
    },
    setOrganizationsAndUsers(state, action: PayloadAction<OrganizationUserSettings>) {
      state.facilities = action.payload.facilities;
      state.departments = action.payload.departments;
      state.users = action.payload.users;
    },
    setTradingPartner(state, action: PayloadAction<MultiSelectOption | null>) {
      state.tradingPartner = action.payload;
    }
  },
});

export const {
  setPermissions,
  setOrganizationsAndUsers,
  setTradingPartner
} = reducerSlice.actions;
export default reducerSlice.reducer;

