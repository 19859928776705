import { MultiSelectOption } from "components/select/MultiSelect";
import { PaymentSourceEnum, RadioButtons, Setting, TransactionTypesEnum } from "models/admin/RemitConfiguration";
import { EnumCardBrand } from "models/enums/EnumCardBrand";
import { useDispatch } from "react-redux";
import { useCallService } from "services/useCallService";
import { enumToMultiSelectOptions, enumToTenderTypeMultSelectOptions, TenderTypeEnum, TenderTypeLabels } from "utils/Utils";
import { getRemitConfigurationById } from "../../../../services/RemitService";
import { setRemitConfiguration } from "../RemitConfigurationReducer";

export function useGetRemitConfigurationById() {
  const callService = useCallService();
  const dispatch = useDispatch();

  const cardFilterOptions = enumToMultiSelectOptions(EnumCardBrand);
  const transactionTypeOptions = enumToMultiSelectOptions(TransactionTypesEnum);
  const tenderTypeOptions = enumToTenderTypeMultSelectOptions(TenderTypeEnum, TenderTypeLabels);
  const paymentSourceOptions = enumToMultiSelectOptions(PaymentSourceEnum);

  function getSelectedRadioButton(setting: Setting): RadioButtons {
    if (setting.predefinedFunction) return RadioButtons.predefinedFunction;
    if (setting.conditional) return RadioButtons.conditionalSetting;
    if (setting.aggregateSetting) return RadioButtons.aggregateSettings;
    return RadioButtons.defaultValue;
  }

  const getRemitConfiguration = async (id: string) => {
    callService("getRemitConfigurationById", async () => {
      const response = await getRemitConfigurationById(id);
      if (response.result) {
        const remitConfig = response.result;

        dispatch(setRemitConfiguration({
          ...remitConfig,
          fileName: `${remitConfig.fileName}${remitConfig.fileExtension}`,
          cardFilter: populateMultiSelectOptions(cardFilterOptions, remitConfig.cardFilter),
          transactionFilter: populateMultiSelectOptions(transactionTypeOptions, remitConfig.transactionFilter),
          paymentSources: populateMultiSelectOptions(paymentSourceOptions, remitConfig.paymentSources),
          tenderFilter: enumToMultiSelectOptions(remitConfig.tenderFilter ?? [])?.map((o) => {
            return {
              label: tenderTypeOptions.find(c => o.label == c.originalLabelName)?.originalLabelName ?? "",
              value: tenderTypeOptions.find(c => o.label == c.originalLabelName)?.value ?? ""
            }
          }).filter(x => x.value),
          aggregateSettings: remitConfig.aggregateSettings.map((ag) => {
            return {
              ...ag,
              facilities: enumToMultiSelectOptions(ag.facilities ?? []).map((x) => {
                return {
                  label: "",
                  value: x.label
                }
              }),
              cardBrands:  populateMultiSelectOptions(cardFilterOptions, ag.cardBrands),
              tenderTypes: enumToTenderTypeMultSelectOptions(ag.tenderTypes ?? []).map((o) => {
                return {
                  label: tenderTypeOptions.find(c => o.label == c.originalLabelName)?.originalLabelName ?? "",
                  value: tenderTypeOptions.find(c => o.label == c.originalLabelName)?.value ?? "",
                  originalLabelName: tenderTypeOptions.find(c => o.label == c.originalLabelName)?.originalLabelName ?? ""
                }
              }).filter(x => x.value),
              transactionTypes: populateMultiSelectOptions(transactionTypeOptions, ag.transactionTypes),
              selectedRadioButton: getSelectedRadioButton(ag),
            }
          }),
          bodyColumnSettings: remitConfig.bodyColumnSettings.map((b) => {
            return {
              ...b,
              dbField: b.conditional?.replace(/{{ /g, "").replace(/ }}/g, ""),
              selectedRadioButton: getSelectedRadioButton(b),
            }
          }),
          footerColumnSettings: remitConfig.footerColumnSettings.map(s => ({
            ...s,
            selectedRadioButton: getSelectedRadioButton(s),
          })),
          headersColumnSettings: remitConfig.headersColumnSettings.map(s => ({
            ...s,
            selectedRadioButton: getSelectedRadioButton(s),
          })),
          isTest: remitConfig.isTest,
        }));
      }
    });
  }

  return {
    getRemitConfiguration
  }
}

function populateMultiSelectOptions(optionsToMapFrom: MultiSelectOption[], optionsToMapTo?: MultiSelectOption[]): MultiSelectOption[]{
  return enumToMultiSelectOptions(optionsToMapTo ?? [])?.map((o) => {
    return {
      label: o.label,
      value: optionsToMapFrom.find(c => o.label == c.label)?.value ?? ""
    }
  }).filter(x => x.value);
}