import { useMemo, useState } from "react";
import { MultiSelectOption } from "../../../../components/select/MultiSelect";
import { ApiSelectOptions, getGlPaymentSaveAsSelectOptionsService, getPaymentSaveAsSelectOptionsService, getSearchSaveAsSelectOptionsService } from "../../../../services/PaymentPanelService";
import { Dashboards } from "../ConfigurationSelector";
import { ServiceCallResponse } from "services/callHttpApi";

export function useSaveAsOptions(dashboard?: keyof(Dashboards)) {
  const [saveAsOptions, setSaveAsOptions] = useState<MultiSelectOption[]>([]);
  useMemo(async () => {
    let response: ServiceCallResponse<ApiSelectOptions> = {};
    switch(dashboard){
      case "GlPaymentDashboard":
      case "GlPaymentDashboardDraft":
      case "GlPaymentPlanDashboard":
      case "GlPaymentPlanDashboardDraft":{
        response = await getGlPaymentSaveAsSelectOptionsService();
        break;
      }
      case "PaymentDashboard":
      case "PaymentDashboardDraft":
      case "PaymentPlanDashboard":
      case "PaymentPlanDashboardDraft":
      case "EcnPaymentDashboard":
      case "EcnPaymentDashboardDraft": {
        response = await getPaymentSaveAsSelectOptionsService();
        break;
      }
      case "PatientSearchPopupDashboard":
      case "PatientSearchPopupDashboardDraft":{
        response = await getSearchSaveAsSelectOptionsService();
        break;
      }
    }
    setSaveAsOptions(mapOptions(response?.result ?? []));
  }, []);

  return { saveAsOptions }
}

export function mapOptions(options: ApiSelectOptions) {
  return options.map(o => ({
    label: o.displayName,
    value: o.fieldName
  }));
}