import React from 'react';
import { useTypedSelector } from 'app/rootReducer';
import 'assets/styles/components/_breadcrumb.scss';

export default () => {
  const links = useTypedSelector(s => s.breadcrumb?.links);
  const loginState = useTypedSelector(s => s.loginInfo?.value);
  return (
    links && links.length && loginState.loggedIn? <div className='breadcrumb'>{links[0].name}</div> : null
  );
};
