import { CardTokenResponse } from 'models/CardTokenResponse';
import { callHttpApi } from 'services/callHttpApi';
import { CardDeviceTokenizationRequest } from './models/CardDeviceTokenizationRequest';

export async function CardDeviceTokenizationService(
  tokenRequest: CardDeviceTokenizationRequest
) {
  return callHttpApi<CardTokenResponse, CardDeviceTokenizationRequest>(
    'post',
    `/api/v1/Transactions/cardDeviceTokenization`,
    tokenRequest
  );
}
