import { useDispatch } from "react-redux";
import { PaymentPanelApiConfiguration, DeletePaymentPaymentPanel, DeleteGlPaymentPaymentPanel, DeletePaymentPlansPaymentPanel, DeleteEcnPaymentPanel, DeletePatientPanel } from "../../../../../services/PaymentPanelService";
import { AlertIds, AlertTypes, setAlert } from "../../../../alert/AlertReducer";
import { Dashboards, isDashboardDraft } from "../../ConfigurationSelector";
import { saveConfiguration } from "../../PaymentPanelReducer";
import { useMapPaymentPanelConfiguration } from "../mapPaymentPanelConfiguration";
import useGetConfiguration from "features/admin/paymentPanel/serviceHandlers/useGetConfiguration";

export function useResetPaymentPanelConfiguration(key?: keyof Dashboards, configurationId?: string, selectedOrganizationPath?: string) {
  const dispatch = useDispatch();
  const { 
    getDefaultPaymentConfiguration, 
    getDefaultPaymentPlanConfiguration, 
    getDefaultGlPaymentConfiguration, 
    getDefaultEcnPaymentConfiguration, 
    getDefaultPatientConfiguration 
  } = useGetConfiguration(selectedOrganizationPath);
  const { mapPaymentPanelConfiguration } = useMapPaymentPanelConfiguration(selectedOrganizationPath);

  async function resetPaymentPanelConfiguration() {
  let response: any;

  const deletePanels = {
    'PaymentPlanDashboardDraft': DeletePaymentPlansPaymentPanel,
    'PatientSearchPopupDashboardDraft': DeletePatientPanel,
    'default': DeletePaymentPaymentPanel
  };

  const getDefaultPanels = {
    'PaymentPlanDashboardDraft': getDefaultPaymentPlanConfiguration,
    'PatientSearchPopupDashboardDraft': getDefaultPatientConfiguration,
    'default': getDefaultPaymentConfiguration
  };

  const deletePanel = deletePanels[key as keyof typeof deletePanels] || deletePanels['default'];
  response = await deletePanel(configurationId);

  if (!response.err) {
    const getFunction = getDefaultPanels[key as keyof typeof getDefaultPanels] || getDefaultPanels['default'];
    response.result = await getFunction(isDashboardDraft(key));
  }

  saveUpdatedConfiguration(response);
}

  async function resetGlPaymentPanelConfiguration() {
    const response = await DeleteGlPaymentPaymentPanel(configurationId);

    if (!response.err) {
      response.result = await getDefaultGlPaymentConfiguration(isDashboardDraft(key));
    }
    
    saveUpdatedConfiguration(response);
  }

  async function resetEcnPaymentPanelConfiguration() {
    const response = await DeleteEcnPaymentPanel(configurationId);

    if (!response.err) {
      response.result = await getDefaultEcnPaymentConfiguration(isDashboardDraft(key));
    }
    
    saveUpdatedConfiguration(response);
  }

  function saveUpdatedConfiguration(response?: { result?: PaymentPanelApiConfiguration, err?: string }) {
    const configuration = response?.result;
    key && configuration && dispatch(saveConfiguration({
      key, configuration: mapPaymentPanelConfiguration(key, configuration)
    }));

    if (response?.err) {
      dispatch(setAlert(getErrorAlert(response.err)));
    } else {
      dispatch(setAlert(getSuccessAlert("Successfully reset Payment Panel Configuration")));
    }
  }

  return {
    resetPaymentPanelConfiguration,
    resetGlPaymentPanelConfiguration,
    resetEcnPaymentPanelConfiguration
  };
}

function getErrorAlert(message: string) {
  return {
    id: AlertIds.PaymentDashboardConfiguration,
    type: AlertTypes.Error,
    message,
    dismissable: false
  }
}

function getSuccessAlert(message: string) {
  return {
    id: AlertIds.PaymentDashboardConfiguration,
    type: AlertTypes.Success,
    message,
    dismissable: true
  }
}