import { useEffect, useState } from "react";
import { EDS_Select } from '@EH/eh.eds.react';
import '../../../assets/styles/components/_selectRemitModal.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '@experian/eds-styles/dist/eds-all.css';
import { Popup } from "components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";
import { useGetRemitConfigurations } from "features/admin/remitConfiguration/serviceHandlers/useGetRemitConfigrations";
import { useTypedSelector } from "app/rootReducer";
import { RemitConfiguration } from "models/admin/RemitConfiguration";
import { useGetRemitConfigurationById } from "features/admin/remitConfiguration/serviceHandlers/useGetRemitConfigurationById";
import { nameof, getUserName } from 'utils/Utils';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import {
  GridApi,
  GridReadyEvent,
  SelectionChangedEvent,
  ICellRendererParams,
  ValueFormatterParams
} from 'ag-grid-community';

export default (props: {
  isOpen: boolean;
  close: () => void;
  onNext: (existingRemitConfiguration: RemitConfiguration) => void;
  orgPath: string;
}) => {
  const configLimit: number = 150;
  const { close, onNext, orgPath } = props;  
  const remitConfigurations = useTypedSelector(s => s.remitConfiguration.remitConfigurations)
  const { getRemitConfigurations } = useGetRemitConfigurations();
  const { getRemitConfiguration } = useGetRemitConfigurationById();
  const [selectedConfigurationIds, setSelectedConfigurationIds] = useState<string[]>();
  const remitConfiguration = useTypedSelector(s => s.remitConfiguration.remitConfiguration);

  useEffect(() => {
    getRemitConfigurations({ organizationPaths: [orgPath], limit: configLimit });
  }, []);

  const choose = () => {
    if(selectedConfigurationIds){
      getRemitConfiguration(selectedConfigurationIds[0]);
    
      if(remitConfiguration){
        onNext(remitConfiguration);
      }
    } 
  };

  const footerButtons = [
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Select',
      disabled: selectedConfigurationIds?.length ? false : true
    },
  ];
  
  const headerDefinitions = [
    {
      headerName: 'Remit File Configuration Name',
      field: nameof<RemitConfiguration>('fileName'),
      minWidth: 180,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      filter: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true,
      }
    },
    {
      headerName: 'Test Configuration',
      field: nameof<RemitConfiguration>('isTest'),
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) => params.value ? "Yes" : "No"
    },
    {
      headerName: 'Last Update By',
      field: nameof<RemitConfiguration>('updatedBy'),
      sortable: false,
      cellRenderer: "RemitConfigurationCell",
    }
  ];

  const pageSizeOptions = [
    { optionName: '5', value: 5 },
    { optionName: '10', value: 10 },
    { optionName: '15', value: 15 },
    { optionName: '20', value: 20 },
  ];

  const [gridApi, setGridApi] = useState < GridApi | null>(null);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [currentPage, setCurrentPage] = useState(0);

  const onChangePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = pageSizeOptions[event.target.selectedIndex].value;
    setPageSize(value);
    if (gridApi) {
      gridApi.paginationSetPageSize(value);
    }
  }

  function onGridReady(params: GridReadyEvent) {
    setGridApi(params.api);
    params.api.setRowData(remitConfigurations);
    params.api.sizeColumnsToFit();
  }

  useEffect(() => {
    gridApi?.setRowData(remitConfigurations);
  }, [remitConfigurations]);

  return (
  <Popup 
    header={
      <span className='select-remit-header'>
        PaymentSafe<sup>&reg;</sup>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp; Select Remit File Configurations
      </span>
    }
    onClose={close}
    title="Select a Remit Configuration"
    footer={footerButtons}
    onSubmit={choose}
    customClassNames={{footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
    >
      <div className="popupContent">
        <div className="rowItemPopup">         
          <div className="selectRemitConfigContainer">
            <div className="flexRow">
              <div className={'section ag-theme-alpine'}>
                <AgGridReact
                  columnDefs={headerDefinitions}
                  onGridReady={onGridReady}
                  domLayout={'autoHeight'}
                  rowSelection={'single'}
                  suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
                  suppressDragLeaveHidesColumns={true}
                  suppressRowClickSelection={true}
                  suppressMenuHide={true}
                  onSelectionChanged={(e: SelectionChangedEvent) => {
                    setSelectedConfigurationIds(
                      e.api.getSelectedRows().map((remit: RemitConfiguration ) => remit.id!)
                    );
                  }}
                  frameworkComponents={{
                    RemitConfigurationCell
                  }}
                  defaultColDef={{
                    sortable: false,
                    resizable: true,
                    suppressMovable: true,
                    headerClass: 'custom-header-cell',
                  }}
                  pagination={true}
                  paginationPageSize={pageSize}
                  suppressPaginationPanel={true}
                  onPaginationChanged={() => { setCurrentPage(gridApi?.paginationGetCurrentPage() ?? 0) }}
                  disableStaticMarkup={true}
                />
                <div className={"pagination-container"}>
                  <div className={"pages"}>
                    {currentPage ? <span onClick={() => gridApi?.paginationGoToPreviousPage()}>{"|<"}</span> : null}
                    <span className={"page-number"}>{currentPage + 1}</span>
                    {gridApi && gridApi.paginationGetTotalPages() !== (currentPage + 1) ? <span onClick={() => gridApi.paginationGoToNextPage()}>{">|"}</span> : null}
                  </div>
                  <EDS_Select
                    name="configurationId"
                    label="Rows per page:"
                    modifiers={'row-item row-item-size-double'}
                    options={pageSizeOptions}
                    onChange={onChangePageSize}
                    value={pageSize}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>              
      </div>
  </Popup>
  );
}

export function RemitConfigurationCell(params: ICellRendererParams) {
  const data = params.data as RemitConfiguration;
  const updatedBy = data?.updatedBy;
  return getUserName(updatedBy);
}