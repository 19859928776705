import '../../assets/styles/components/_dashBoards.scss';
import { getTotalSum, getTotalDiscount, getTotalBalanceDue, getAccountBalanceDue, getTotalLineItemAmount, getBalanceSumForPlan, getAccountBalanceForAddToPlan} from '../paymentPlan/useTotal';
import { useTypedSelector } from '../../app/rootReducer';
import { Wizard } from '../wizard/Wizard';
import { displayAmount } from 'utils/Utils';
import { FullOrganizationLevelDocument } from '../../models/OrganizationLevelDocument';
import PaymentDashboard from '../paymentDashboard/PaymentDashboard';
import PaymentPlanDashboard from '../paymentPlan/PaymentPlanDashboard';
import { useMemo, useState, useEffect } from 'react';
import { PatientEncounterModel } from 'models/PatientEncounterModel';
import { useDispatch } from 'react-redux';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';
import PaymentPlanModel from 'models/PaymentPlan';
import { setIsPaymentType } from './PaymentDashboardReducer';
import { PlanOperations } from 'models/metaData/MetaDataEnums';
import { Attribute, MetaData } from 'models/metaData/MetaData';
import { LineItems } from 'models/LineItem';

export default function Dashboards(props: { patientId?: string, isGl?: boolean, wizard: Wizard, selectedFacility: FullOrganizationLevelDocument, paymentType: boolean, isPreview?: boolean, preloadedLineItems?: PatientEncounterModel[], typedAccountNumber?: string, shouldShowPlan?: (data: boolean) => void }) {
  const editingOperations = [PlanOperations.AddToPlan, PlanOperations.EditPlan, PlanOperations.EditPlanDetails, PlanOperations.EditPlanTerms];
  
  const { isGl, wizard, patientId, selectedFacility, shouldShowPlan, paymentType, isPreview, preloadedLineItems, typedAccountNumber } = props;

  const paymentPlansRecords = useTypedSelector(s => s.paymentPlanInfo?.records) || [];
  const paymentPlansSelectedRecords = useTypedSelector(s => s.paymentPlanInfo?.selected) || [];
  const organizationPath = selectedFacility?.path ?? '';
  const paymentPanelConfiguration = useTypedSelector(s => s.paymentDashboard?.panelConfigurations?.[organizationPath]?.configuration);
  const planPanelConfiguration = useTypedSelector(s => s.paymentPlanInfo?.planPanelConfigurations?.[organizationPath]?.configuration);
  const selectedPanelConfiguration = setConfiguration(paymentType, paymentPanelConfiguration, planPanelConfiguration);
  const configurationAttributes = selectedPanelConfiguration?.entity?.attributes;

  const [isBalanceConfigured, setIsBalanceConfigured] = useState<boolean>(checkConfiguredProperty(configurationAttributes, 'patientBalance'));
  const [isDiscountConfigured, setIsDiscountConfigured] = useState<boolean>(checkConfiguredProperty(configurationAttributes, 'discount'));
  const mappedFacilitiesConfigurations = useTypedSelector(s => paymentType ? s.paymentDashboard?.panelConfigurations : s.paymentPlanInfo?.planPanelConfigurations) ?? [];

  useEffect(() => {
    const mappedFacilities = Object.keys(mappedFacilitiesConfigurations);
    (!isBalanceConfigured || !isDiscountConfigured) && mappedFacilities?.forEach(mappedFacilityPath => {
      const mappedFacilityConfiguration = mappedFacilitiesConfigurations[mappedFacilityPath]?.configuration;
      const attributes = mappedFacilityConfiguration?.entity?.attributes ?? [];
      !isBalanceConfigured && setIsBalanceConfigured(checkConfiguredProperty(attributes, 'patientBalance'));
      !isDiscountConfigured && setIsDiscountConfigured(checkConfiguredProperty(attributes, 'discount'));
    });   
  }, [mappedFacilitiesConfigurations]);
  
  const values = Object.values(useTypedSelector(s => s.paymentDashboard?.values ?? []));
  const lineItemsList = values?.map((mapped) => { return mapped.lineItems }) || [];
  const isPaymentType = useTypedSelector(s => s.paymentDashboard.isPaymentType);

  const facilitiesLineItems = lineItemsList?.reduce((totalLineItems, lineItemList) => totalLineItems.concat(lineItemList), []);
  const operation = useTypedSelector(s => (s.paymentPlanInfo?.operation));
  const isCreatePlan = operation === PlanOperations.CreatePlan;
  const isEditing = editingOperations.some(x=>x==operation);
  const isAdhoc = operation == PlanOperations.AddhockPayment;
  const isAddToPlan = operation == PlanOperations.AddToPlan;
  const totalPaymentAmount = getTotalSum(facilitiesLineItems, [], "amount", [], !paymentType, isCreatePlan, isEditing);
  const paymentPlanActiveTotalAmount = getTotalSum(facilitiesLineItems, paymentPlansRecords, "amount", paymentPlansSelectedRecords, !isPaymentType, isCreatePlan, isEditing);
  const paymentPlanActiveTotalBalance = getTotalSum(facilitiesLineItems, paymentPlansRecords, "balance", paymentPlansSelectedRecords, !isPaymentType, isCreatePlan, isEditing);
 
  const paymentPlanActiveTotalDiscount = getTotalDiscount([], paymentPlansRecords, !isPaymentType);
  const isBalanceSet =  checkLineItemsBalance(paymentType, facilitiesLineItems, paymentPlansRecords, paymentPlansSelectedRecords);
  const isDiscountSet = facilitiesLineItems.filter(lineItem => !lineItem?.isEditing && lineItem?.isActive && lineItem?.discount).length > 0;
  
  const totalDiscount = getTotalDiscount(facilitiesLineItems, paymentPlansRecords, !paymentType);
  const totalBalanceDue = getTotalBalance();
  const accountBalance = getAccountBalanceDue(facilitiesLineItems);

  const discountAmount = isPaymentType ? totalDiscount : paymentPlanActiveTotalDiscount;
  const billedAmount = isPaymentType ? totalPaymentAmount : paymentPlanActiveTotalBalance;
  const paymentAmount = isPaymentType ? totalBalanceDue : paymentPlanActiveTotalAmount;
  const shouldShowBilledAmount = paymentType && (billedAmount !== paymentAmount);
  
  const totalLineItemAmount = getTotalLineItemAmount(facilitiesLineItems, isGl, !isPaymentType);
  const finalPaymentAmount = isPaymentType && facilitiesLineItems.filter(item => !item?.isEditing && item?.isActive).length > 0 ? totalLineItemAmount : paymentAmount;
  
  let totalPaymentPlans = paymentPlansRecords?.length || 0;
  let dispatch = useDispatch();

  const [accountNumberFound, setAccountNumberFound] = useState(false);
  const [planConfigs, setPlanConfigs] = useState([]);
 
  useEffect(() => {
    const shouldRedirectToPlans = localStorage.getItem('shouldRedirectToPlans') ?? undefined;
    if (totalPaymentPlans > 0 && typedAccountNumber) {
      let resultTest = paymentPlansRecords?.filter(plan =>
        plan?.lineItems?.some(lineItem => lineItem.accountNumber == typedAccountNumber));
      if (resultTest && resultTest?.length > 0) {
        setAccountNumberFound(true);
        dispatch(setIsPaymentType(false));
        checkNewAccountNumber(paymentPlansRecords, typedAccountNumber, dispatch);
      }
    } 
    else if (totalPaymentPlans > 0 && shouldRedirectToPlans && isPaymentType) {
      localStorage.removeItem('shouldRedirectToPlans');
      dispatch(setIsPaymentType(false));
    }
  }, [totalPaymentPlans])

  function getTotalBalance(){
    if (isAdhoc){
      return getBalanceSumForPlan(paymentPlansRecords, paymentPlansSelectedRecords);
    }

    if(isAddToPlan){
      return getAccountBalanceForAddToPlan(paymentPlansRecords, paymentPlansSelectedRecords);
    }

    return  getTotalBalanceDue(facilitiesLineItems, paymentPlansRecords, isGl, !paymentType, isCreatePlan, paymentPlansSelectedRecords);
  }

  const onPlanConfig = (data: any) => {
    setPlanConfigs(data);
  }

  const renderDashboard = useMemo(() => {
    return (paymentType || isGl ?
      <PaymentDashboard
        key={`${selectedFacility?.path}-PaymentDashboard`}
        isGl={isGl}
        wizard={wizard}
        patientId={patientId}
        selectedFacility={selectedFacility}
        paymentType={paymentType}
        isPreview={isPreview}
        preloadedLineItems={preloadedLineItems}
        shouldShowPlan={shouldShowPlan}
        planConfiguration={onPlanConfig}
      /> :
      <PaymentPlanDashboard
        key={`${selectedFacility?.path}-PaymentPlanDashboard`}
        isGl={false}
        wizard={wizard}
        patientId={patientId}
        selectedFacility={selectedFacility}
        paymentType={false}
        isPreview={isPreview}
        typedAccountNumber={typedAccountNumber}
        accountNumberFound={accountNumberFound}
        planConfiguration={planConfigs}
      />);
  }, [paymentType, patientId]);

  return <>
    {renderDashboard}
    <div className="line-items-total-container">
      <p className="total-balance-text">TOTAL:</p>
      <div className="flex-row">
        {isGl
          ? <span className="total-balance-text">${displayAmount(totalBalanceDue)}</span>
          : <>
            {displayBalanceAmount(paymentType, isBalanceConfigured, isBalanceSet, paymentType ? accountBalance : totalBalanceDue)}
            {displayBilledAmount(shouldShowBilledAmount, billedAmount)}
            {displayDiscountAmount(paymentType, isDiscountConfigured, isDiscountSet, discountAmount)}
            <div className="flex-column">
              <span className="label">Payment Amount:</span>
              <span className="total-balance-text">${displayAmount(finalPaymentAmount)}</span>
            </div>
          </>
        }
      </div>
    </div>
  </>;
}

export function checkNewAccountNumber(paymentPlans?: PaymentPlanModel[], newAccount?: string, dispatch?: any) {
  const accountNumberAlert = { id: AlertIds.LineItemAlert, type: AlertTypes.Info, message: `Account number '${newAccount}' is already part of a plan.` }
  let result: boolean;
  paymentPlans?.forEach((plan) => {
    result = false;
    plan.lineItems?.forEach((lineItem) => {
      if (lineItem?.accountNumber === newAccount) {
        result = true;
      }
    });
    if (result && newAccount && newAccount?.length > 0) {
      dispatch(setAlert(accountNumberAlert));
      return true;
    }
  })
  return false;
};

function displayBalanceAmount(paymentType: boolean, isBalanceConfigured: boolean, isBalanceSet: boolean, balanceAmount: number) {
  return <>
    {((paymentType && isBalanceConfigured && isBalanceSet) || !paymentType) && <div className="flex-column">
      <span className="label">Account Balance:</span>
      <span className="padding-bottom-5">${displayAmount(balanceAmount)}</span>
    </div>}
  </>;
}

function displayBilledAmount(shouldShowBilledAmount: boolean, billedAmount: number) {
  return <>
    {shouldShowBilledAmount && <div className="flex-column">
      <span className="label">Billed Amount:</span>
      <span className="padding-bottom-5">${displayAmount(billedAmount)}</span>
    </div>}
  </>;
}

function displayDiscountAmount(paymentType: boolean, isDiscountConfigured: boolean, isDiscountSet: boolean, discountAmount: number) {
  return <>
    {paymentType && isDiscountConfigured && isDiscountSet && <div className="flex-column">
      <span className="label">Discount Amount:</span>
      <span className="padding-bottom-5">{discountAmount !== 0 && '-'}${displayAmount(discountAmount)}</span>
    </div>}
  </>;
}

function checkConfiguredProperty(attributes: Attribute[] | undefined, property?: string) {
  return (attributes?.filter(attribute => attribute?.saveAs === property) ?? []).length > 0;
}

function checkLineItemsBalance(paymentType: boolean, facilitiesLineItems: LineItems, paymentPlansRecords: PaymentPlanModel[], paymentPlansSelectedRecords: (string | null)[]) {
  const lineItemsToCheck = paymentType ? facilitiesLineItems : paymentPlansRecords?.filter(plan => paymentPlansSelectedRecords?.includes(plan.id))[0]?.lineItems ?? [];
  return lineItemsToCheck?.filter(lineItem => !lineItem?.isEditing && lineItem?.isActive && lineItem?.patientBalance).length > 0;
}

function setConfiguration(paymentType?: boolean, paymentPanelConfiguration?: MetaData, planPanelConfiguration?: MetaData) : MetaData {
  return paymentType ? paymentPanelConfiguration ?? {} : planPanelConfiguration ?? {};
}