import 'assets/styles/components/_adminSettingsTabs.scss';

export interface AdminSubTab {
  isActive: boolean;
  tabName: string;
}


export default function AdminSubSettingsTabs(props: {
  tabSelections: AdminSubTab[];
  initialTabSelections: AdminSubTab[];
  onClick: (tabSelections: any) => void;
}) {

  const TAB_SELECTED_CLASSES =
    'eds-tab_#content eds-tab_.eds-link eds-link eds-link.selected';
  const TAB_UNSELECTED_CLASSES = 'eds-tab_#content eds-tab_.eds-link eds-link';
  const TABSET_SELECTED_CLASSES =
    'eds-tabset_.eds-tab eds-tab eds-tab.selected';
  const TABSET_UNSELECTED_CLASSES = 'eds-tabset_.eds-tab eds-tab';

  const { tabSelections, initialTabSelections, onClick } = props;  

  return (
    <ul className="eds-tabset" role="tablist" >
      {
        tabSelections.map((element:AdminSubTab) => { 
          return <>
             <li
               className={
                element.isActive
                   ? TABSET_SELECTED_CLASSES
                   : TABSET_UNSELECTED_CLASSES
               }
               role="presentation"
               onClick={() => {                 
                const newActiveTab = initialTabSelections.map((tab:AdminSubTab) => 
                  tab.tabName == element.tabName 
                  ? {...tab, isActive: true} 
                  : tab
                )
                onClick(newActiveTab);
               }}
             >
               <div
                 className={
                  element.isActive ? TAB_SELECTED_CLASSES : TAB_UNSELECTED_CLASSES
                 }
               >
                 <span className="eds-tab_#label">{element.tabName}</span>
               </div>
             </li>
           </> 
         })
      }      
    </ul>
  );
}
