import { OrganizationReference } from "models/PaymentPlan";
import { UserDetailModel } from "models/UserInfoAndRolesModel";
import { TenderTypeMultiselectOption } from "utils/Utils";
import { MultiSelectOption } from "../../components/select/MultiSelect";
import { ErrorsMessages } from "../../utils/useTypedValidator";

export enum TransactionTypesEnum {
  Unknown = 0,
  Payment = 1,
  Void = 2,
  Credit = 3,
  RecurringPayment = 6,
  GLPayment = 11,
  GLVoid = 12,
  GLCredit = 13,
  RecurringGLPayment = 21,
  Reverse = 31,
  Invalid = -1,
  TokenRequest = 50,
  TokenPayment = 51,
  Authorization = 70,
  SystemUpdaterService = 99,

}

export const ObsoleteTransactionTypesEnumLabels = {
  RecurringPaymentAdd : 'RecurringPaymentAdd',
  RecurringPaymentCancel : 'RecurringPaymentCancel',   
  RecurringPaymentReactivate : 'RecurringPaymentReactivate',
  RecurringPaymentModify : 'RecurringPaymentModify',
  RecurringPaymentInquiry : 'RecurringPaymentInquiry',  
  GLPlan : 'GLPlan',
  GLPlanCancel : 'GLPlanCancel',
  GLPlanModify : 'GLPlanModify',
  GLPlanReactivate : 'GLPlanReactivate',
  GLPlanInquiry : 'GLPlanInquiry',
}

export const TransactionTypesEnumLabels = {
  Unknown: "Unknown",
  Payment: "Payment",
  Void: "Void",
  Credit: "Credit",
  RecurringPayment: "Recurring Payment",
  GLPayment: "GL Payment",
  GLVoid: "GL Void",
  GLCredit: "GL Credit",
  RecurringGLPayment: "Recurring GL Payment",
  Reverse: "Reverse",
  Invalid: "Invalid",
  TokenRequest: "Token Request",
  TokenPayment: "Token Payment",
  Authorization: "Authorization",
  SystemUpdaterService: "A/R Adjustment"
}

export enum TrimStyleEnum {
  None, Left, Right
}

export enum FunctionEnum {
  Sum = 1,
  Count = 2
}

export enum FunctionDataOperationEnum {
  'none', '+', '-', '*', '/'
}

export enum PaymentSourceEnum {
  NotSet = 0, 
  PaymentSafe = 1, 
  PatientSimple = 2, 
  ECareNext = 3, 
  Kiosk = 4,
  EPIC = 5, 
  IVR = 6, 
  PatientSimpleQuickPay = 7, 
  PatientFinancialNavigator = 8, 
  FlatFileImportService = 9, 
  ExternalTransaction = 10, 
  EpicHostedPaymentPage = 11, 
  RegistrationAccelerator = 12,
  OneSource = 13, 
  TextToPay = 14, 
  Semafone = 15, 
  Unknown = -1
}

export const PaymentSourceEnumLabel= {
  NotSet: "Not Set",
  ECareNext: "ECare Next",
  PatientSimpleQuickPay: "PatientSimple Quick Pay",
  PatientFinancialNavigator: "Patient Financial Navigator",
  FlatFileImportService: "Flat File Import Service",
  ExternalTransaction: "External Transaction",
  EpicHostedPaymentPage: "Epic Hosted Payment Page",
  RegistrationAccelerator: "Registration Accelerator",
}

export enum SettledStatusEnum {
  None, Setled, NonSetled, All
}

export interface RemitConfigurationSettingMultiSelects {
  dbFieldOptions?: MultiSelectOption[],
  facilities?: MultiSelectOption[],
  cardBrands?: MultiSelectOption[],
  transactionTypes?: MultiSelectOption[],
  tenderTypes?: TenderTypeMultiselectOption[],
}

export interface ApiRemitConfigurationSettingMultiSelects {
  dbFieldOptions?: string[],
  facilities?: string[],
  cardBrands?: string[],
  transactionTypes?: string[],
  tenderTypes?: string[],
}

export interface SettingBase {
  name: string,
  trimStyle?: string,
  capitalize?: true,
  prefix?: string,
  defaultValue?: string,
  postfix?: string,
  conditional?: string,
  maxLength?: number,
  padLeftCharacters?: string,
  padRigthCharacters?: string,
  netFormating?: string,
  replaceRegEx?: string,
  hideColumnExpression?: boolean,
  notes?: string,
  position?: number,
  dbField?: string,
  metadata?: {
    isOpen?: boolean,
    errors?: ErrorsMessages
  },
  predefinedFunction?: string,
  function?: FunctionEnum,
  functionExtraOperation?: FunctionDataOperationEnum,
  functionExtraOperationValue?: number,
  accountNumberStarts?: string,
  discount?: boolean,
  aggregateSetting?: string,
  selectedRadioButton?: RadioButtons
}

export interface Setting extends SettingBase, RemitConfigurationSettingMultiSelects {}

export interface ApiSetting extends SettingBase, ApiRemitConfigurationSettingMultiSelects {}
export interface RemitConfigurationBase {
  id?: string,
  fileIdentifier?: string,
  organization?: OrganizationReference,
  displayedClientName?: string,
  filePath?: string,
  fileName?: string,
  fileExtension?: string,
  dateFormat?: string,
  fixWidthFile?: boolean,
  sqlRowFilter?: string,
  createWithoutRecords?: boolean,
  settledStatus?: string,
  sortString?: string,
  orderDesc?: boolean,
  is835?: boolean,
  departmentFilter?: string[],
  clientFilter?: string[],
  updatedBy?: UserDetailModel,
  isTest?: boolean
}

export interface RemitConfigurationMultiSelects {
  cardFilter?: MultiSelectOption[],
  tenderFilter?: MultiSelectOption[],
  paymentSources?: MultiSelectOption[],
  transactionFilter?: MultiSelectOption[]
}

export interface RemitConfigurationSettings {
  headersColumnSettings: Setting[],
  footerColumnSettings: Setting[],
  bodyColumnSettings: Setting[],
  aggregateSettings: Setting[]
}

export interface ApiRemitConfigurationSettings {
  headersColumnSettings: ApiSetting[],
  footerColumnSettings: ApiSetting[],
  bodyColumnSettings: ApiSetting[],
  aggregateSettings: ApiSetting[]
}

export enum RadioButtons {
  defaultValue,
  predefinedFunction,
  aggregateSettings,
  conditionalSetting
}

export interface RemitConfiguration extends RemitConfigurationBase, RemitConfigurationSettings, RemitConfigurationMultiSelects { }

export type ApiRemitConfigurationMultiSelects = {
  [key in keyof RemitConfigurationMultiSelects]: string[]
}

export interface ApiRemitConfiguration extends RemitConfigurationBase, ApiRemitConfigurationSettings, ApiRemitConfigurationMultiSelects { }

export interface PredefinedData {
  netFormattings: MultiSelectOption[],
  replaceRegexes: MultiSelectOption[],
}

export const PredefinedDateFormats: string[] = [
  "yyMMdd",
  "yyyyMMdd",
  "MMyyyy",
  "MMyy",
  "MMddyyyy",
  "MMddyy",
  "MMdd",
  "MM",
  "m",
  "yyyy",
  "yy",
  "dd",
  "MM\\dd\\yyyy",
  "MM/yyyy",
  "yyyy-mm-dd",
  "mm-dd-yyyy",
  "dd-mm-yyyy",
  "dd.mm.yyyy",
  "yyyy.dd.mm",
  "yyyy.mm.dd",
  "dd/mm/yyyy",
  "mm/dd/yyyy",
  "MM/dd/yyy",
  "MM/dd/yy",
  "yyyy/MM/dd",
  "yyyy/M/dd"
]

