import { useEffect, useRef, useState } from "react";
import { ReactComponent as MoneyBack } from 'assets/svgs/money_back.svg';
import { HeaderButton } from "../../../../pages/Search/planSearch/paymentPlanDetailsHeader/HeaderButton";
import { Popup } from "../../../../components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType, EnumPopupType } from "../../../../models/enums/EnumPopupType";
import { CommonPopupChildren } from "../common/CommonPopupChildren";
import { TransactionDetailsModel } from "../../../../models/TransactionDetailsModel";
import { RefundAccounts } from "./RefundAccounts";
import { useTypedSelector } from "../../../../app/rootReducer";
import { useRefundWizzard } from "./refundWizard";
import { UserDetailModel } from "../../../../models/UserInfoAndRolesModel";
import useRefundValidator from "./useRefundValidator";
import { AlertIds, AlertTypes, removeAlert, setAlert } from "../../../alert/AlertReducer";
import { useRefundTransaction } from "../../../singlePayment/hooks/useRefundTransaction";
import { useDispatch } from "react-redux";
import { EmailModalInfo } from "../../common/Header";
import isTransactionAmountValid from '../common/isTransactionAmountValid'
import  TransactionDetailsPrint from "features/transactionDetails/TransactionDetailsPrint";
import { getLogoDataForOrganization } from "features/admin/logoManager/LogoManagerReducer";
import { useGetReceipts } from 'features/admin/receipts/useGetReceipts';

export default function Credit(props: { disabled?: boolean, transaction?: TransactionDetailsModel, transactionCreator?: UserDetailModel, setIsEmailPopupOpened: (info: EmailModalInfo)=>any }) {
  const {disabled = false, transaction, transactionCreator, setIsEmailPopupOpened } = props;
  const dispatch = useDispatch();
  const accounts = useTypedSelector(s => s.transactionDetails.accounts);
  const lineitems = useTypedSelector(s => s.transactionDetails.transactionDetailsData?.transactionDetails?.lineItems);
  const [isCreditPopupOpen, setIsCreditPopupOpen] = useState(false);
  const refundWizard = useRefundWizzard();
  const { validateAll } = useRefundValidator();

  const { printPaymentReceipt } = useGetReceipts();
  const [printReceiptUrl, setPrintReceiptUrl] = useState<string>('');
  const openPrintPreview = async()=>{ 
    const receiptURL = await printPaymentReceipt(refundId,'english');
    if(receiptURL){
      setPrintReceiptUrl( receiptURL)
    } 
  };

  const logoManager = useTypedSelector(s => s.logoManager);
  const companyLogo = logoManager.logos?.find(logo => logo.isSelected);

  useEffect(() => {
    transaction?.organization?.path && dispatch(getLogoDataForOrganization(transaction?.organization?.path));
  }, [dispatch]);

  const creditAlert = {
    id: AlertIds.CreditAlert, type: AlertTypes.Error, message: "Error refund transaction" };
  const isRefundProcessing = useTypedSelector(s => s.services.calls.refundTransaction?.isProcessing);
  const { refundTransaction, refundId, refundTransactionRes } = useRefundTransaction(
    transaction,
    () => {
      dispatch(removeAlert(creditAlert))
      refundWizard.next()
    },
    () => dispatch(setAlert(creditAlert)));

    const onOpenCreditPopupWithAmountValidation =()=> {
      if (isTransactionAmountValid(transaction)) {
        dispatch(setAlert({
          ...creditAlert,
          message: `Issue Credit is not allowed since maximum allowed amount for Issue Void/Credit has been reached.`
        }));
        return
      }
      setIsCreditPopupOpen(true);
    }
    const footerRefund = [
      { type: EnumPopupButtonsType.cancel,
        style: EnumPopupButtonsStyle.secondary,
        text: 'Cancel' },
      { type: EnumPopupButtonsType.submit,
        style: EnumPopupButtonsStyle.primary,
        text: isRefundProcessing ? 'Processing...' : 'Confirm Credit',
        disabled: isRefundProcessing
      },
    ]
    const footerSubmit = [
      { type: EnumPopupButtonsType.submit,
        style: EnumPopupButtonsStyle.tertiary,
        text: 'Email',
        icon: 'email' },
      { type: EnumPopupButtonsType.print,
        style: EnumPopupButtonsStyle.tertiary,
        text: 'Print',
        icon: 'print' },
      { type: EnumPopupButtonsType.cancel,
        style: EnumPopupButtonsStyle.primary,
        text: 'Done'
      },
    ]

  return <>
    <HeaderButton
      disabled={disabled}
      title="Issue Credit"
      icon={<MoneyBack />}
      onClick={onOpenCreditPopupWithAmountValidation}
    />
    {isCreditPopupOpen && <Popup
      type={EnumPopupType.basic}
      header="PaymentSafe®"
      fullHeight={true}
      children={<CommonPopupChildren
        transaction={transaction}
        accounts={<RefundAccounts
          refundTransactionAccounts = {refundTransactionRes?.lineItems}
          accounts={accounts}
          lineitems={lineitems}
          isCompleted={refundWizard.getCurrentStepIndex() == 1}
        />}
        transactionCreator={transactionCreator}
        isCompleted={refundWizard.getCurrentStepIndex() == 1}
        notes={''}
        showNotes={false}
        logo={companyLogo}
      />}
      footer={refundWizard.getCurrentStepIndex() == 0 ? footerRefund : footerSubmit }
      onClose={() => setIsCreditPopupOpen(false)}
      onPrint= {() => { openPrintPreview && openPrintPreview() }}
      onSubmit={() => {
        if (refundWizard.getCurrentStepIndex() === 0) {
          let valid = true;
          let totalCreditAmount:number = 0
          accounts.forEach(a => {
            valid = valid && validateAll(a)
            totalCreditAmount += a.amount
          })       
          if (valid && totalCreditAmount > 0) {
            refundTransaction()
          } else {
            dispatch(setAlert({...creditAlert, message: "At least one account must be non-empty and be in the range from $1 to the Original Payment Amount." }))
          }          
        }else{
          setIsEmailPopupOpened({ type: 'credit', transactionId: refundId });
        }
      }}
    />
    }
      {printReceiptUrl && <TransactionDetailsPrint receiptUrl={printReceiptUrl}/> }
  </>
}