import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/rootReducer';
import { Device } from 'models/Device';
import { ApiTenderTypeEnum } from 'utils/Utils';
import { GetDevicesService, GetDevicesRequest } from 'services/DeviceService';
import { convertDateToString } from 'utils/UtilsDateTime';
import { ValidatorType } from '../../models/metaData/MetaDataEnums';
import { callService } from '../../services/ServicesReducer';

export interface PatientMailingInformation {
  firstName?: string;
  lastName?: string;
  billingFirstName?: string;
  billingLastName?: string;
  addressLine1?: string;
  billingAddressLine1?: string;
  billingAddressLine2?: string;
  addressLine2?: string;
  city?: string;
  billingCity?: string;
  mailingState?: string;
  billingState?: string;
  phoneNumber?: string;
  billingPhoneNumber?: string;
  zipCode?: string;
  billingZipCode?: string;
  mrn?: string;
  amountTendered?: number;
  cashBack?: number;
  isChecked?: boolean;
  notes?: string;
  notificationEmail?: string;
  paperCheckNumber: string;
  financialInstitution?: string;
  tenderType?: string | ApiTenderTypeEnum;
  originalTenderType?: string;
  onCompletePayment?: () => void;
  moneyOrderAmount: number;
  moneyOrderSerialNumber?: string;
  moneyOrderPostOffice?: string;
  moneyOrderIssueDate?: string;
  cardHolderName?: string;
  cardNumber?: string;
  maskedCardNumber?: string;
  cvc?: string;
  cardType?: string;
  expiration?: string;
  routingNumber?: string;
  confirmRoutingNumber?: string;
  accountNumber?: string;
  confirmAccountNumber?: string;
  isReusable?: boolean;
  wasECheckScannerApplied: boolean;
  accountType?: string;
  createdDate: string;
  email?: string;
  deviceSerialNumber?: string;
  deviceName?: string;
  deviceId?: string;
  paymentFileTokenId?: string;
  facilityId?: string;
  departmentId?: string;
  cardBrand?: string;
}

export interface PatientMailingInformationError {
  [label: string]: { [validatorType: string]: string };
}

export interface State {
  value: PatientMailingInformation;
  devices: Device[];
  errors: PatientMailingInformationError;
}

export const initialState: State = {
  value: {
    firstName: '',
    lastName: '',
    billingFirstName: '',
    billingLastName: '',
    addressLine1: '',
    billingAddressLine1: '',
    addressLine2: '',
    billingAddressLine2: '',
    city: '',
    billingCity: '',
    mailingState: '',
    billingState: '',
    phoneNumber: '',
    billingPhoneNumber: '',
    zipCode: '',
    billingZipCode: '',
    mrn: '',
    amountTendered: 0,
    cashBack: 0,
    isChecked: false,
    notificationEmail: '',
    notes: '',
    paperCheckNumber: '',
    financialInstitution: undefined,
    tenderType: '',
    moneyOrderIssueDate: '',
    moneyOrderPostOffice: '',
    moneyOrderSerialNumber: '',
    moneyOrderAmount: 0,
    cardHolderName: '',
    cardNumber: '',
    cardType: '',
    maskedCardNumber: '',
    cvc: '',
    expiration: '',
    routingNumber: '',
    confirmRoutingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    isReusable: false,
    wasECheckScannerApplied: false,
    accountType: 'checking',
    createdDate: convertDateToString(new Date()) as string,
    deviceSerialNumber: '',
    deviceName: '',
    deviceId: '',
    paymentFileTokenId: '',
    email: '',
    onCompletePayment: () => { /* empty function */ },
  },
  devices: [],
  errors: {},
};

const reducerSlice = createSlice({
  name: 'PatientMailingInformation',
  initialState,
  reducers: {
    setPatientMailingInformation(
      state,
      action: PayloadAction<PatientMailingInformation>
    ) {
      state.value = action.payload;
    },
    setDevices(state, action: PayloadAction<Device[]>) {
      state.value = {
        ...state.value,
        
      };
      state.devices = action.payload;
    },
    setPatientMailingInformationState(state, action: PayloadAction<State>) {
      state.value = action.payload.value;
    },
    setAccountNumber(
      state,
      action: PayloadAction<{
        name: string;
        value: { value?: string; mask?: string };
      }>
    ) {
      (state.value as any)[action.payload.name] = action.payload.value;
    },
    setTenderType(
      state,
      action: PayloadAction<{
        tenderValue?: string;
      }>
    ) {
      state.value.tenderType = action.payload.tenderValue;
    },
    addError(state, action: PayloadAction<PatientMailingInformationError>) {
      state.errors = {
        ...state.errors,
        ...action.payload,
      };
    },
    removeError(
      state,
      action: PayloadAction<{
        key: keyof PatientMailingInformation;
        validatorType: ValidatorType;
      }>
    ) {
      if (state.errors[action.payload.key]) {
        delete state.errors[action.payload.key][action.payload.validatorType];
      }
    },
    resetPatientMailingInformation(state) {   
      state.value = {
        ...initialState.value, 
        departmentId: state.value.departmentId, 
        facilityId: state.value.facilityId
      };
    },
    setInitialState: () => initialState,
  },
});

export const {
  setPatientMailingInformation,
  setAccountNumber,
  setDevices,
  setPatientMailingInformationState,
  addError,
  removeError,
  resetPatientMailingInformation,
  setInitialState,
  setTenderType
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getDevices(OrganizationPath: string): AppThunk {
  return async dispatch => {
    const getDevicesRequest: GetDevicesRequest = {
      OrganizationPath,
    };
    dispatch(callService('GetDevices', async () => {
      const DevicesResult = await GetDevicesService(getDevicesRequest)
      const result = DevicesResult.result || [];
      dispatch(setDevices(result));
    }));
  };
}
