import { TokenGuidLookupResponse } from 'models/TokenGuidLookupResponse';
import { callHttpApi } from './callHttpApi';
import { UserReference } from '../models/RemitScheduleModel';

export interface GetTokenGUIDLookupResponse {
  result?: TokenGuidLookupResponse;
  err?: string;
}

export async function getTokenGUIDLookupService(guid: string) {
  return callHttpApi<TokenGuidLookupResponse>(
    'get',
    `/api/v1/TokenUser/${guid}`
  );
}

export interface GetTokensListResponse {
  total: number;
  limit: number;
  offset: number;
  records: UserToken[];
}

export interface UserTokenUserReference {
  id?: string;
  firstName?: string;
  lastName?: string;
  oneSourceUserId?: string;
}

export interface UserToken {
  id?: string;
  organizationReference?: {
    id: string;
    name: string;
    path: string;
  };
  clientHostID?: string;
  displayErrorMessage?: string;
  oneSourceUserName?: string;
  user?: UserTokenUserReference;
  createdBy?: UserReference;
  createdDate?: string;
  updatedBy?: UserReference;
  updatedDate?: string;
}

export interface DeletedToken {
  deleted?: boolean;
}

export async function getTokensList(organizationPath?: string) {
  const params = new URLSearchParams();
  params.set("limit", '1000');

  return callHttpApi<GetTokensListResponse>(
    'get',
    `/api/v1/TokenUser?organizationPaths[]=${organizationPath}`,
    undefined,
    params
  )
}

export async function updateToken(data: UserToken) {
  return callHttpApi<UserToken, UserToken>(
    'put',
    `/api/v1/TokenUser/${data.id}`,
    data
  )
}

export async function createToken(data: UserToken) {
  return callHttpApi<UserToken, UserToken>(
    'post',
    `/api/v1/TokenUser`,
    data
  )
}

export async function deleteToken(id: string) {
  return callHttpApi<DeletedToken>(
    'delete',
    `/api/v1/TokenUser/${id}`,
  )
}

export async function getTokenUserMappingByOneSourceUserName(oneSourceUserName: string) {
  return callHttpApi<TokenGuidLookupResponse>(
    'get',
    `/api/v1/TokenUser/${oneSourceUserName}/GetTokenUserMappingByOneSourceUserName`
  );
}