import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../app/rootReducer';
import { HeaderButton } from '../../../pages/Search/planSearch/paymentPlanDetailsHeader/HeaderButton'
import  TransactionDetailsPrint  from '../TransactionDetailsPrint'
import { ReactComponent as PrintIcon } from 'assets/svgs/paymentPlanIcons/icon-print.svg';
import { getLogoDataForOrganization } from 'features/admin/logoManager/LogoManagerReducer';
import { useGetReceipts } from 'features/admin/receipts/useGetReceipts';

export function Print() {
  const transaction = useTypedSelector(s => s.transactionDetails.transactionDetailsData?.transactionDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    transaction?.organization?.path && dispatch(getLogoDataForOrganization(transaction?.organization?.path));
  }, [dispatch, transaction?.organization?.path]);

  const { printPaymentReceipt } = useGetReceipts();
  const [printReceiptUrl, setPrintReceiptUrl] = useState<string>('');
  const openPrintPreview = async()=>{ 
    const receiptURL = await printPaymentReceipt(transaction?.id , 'english');
    if(receiptURL){
      setPrintReceiptUrl( receiptURL)
    }
  };

  return <>
    <HeaderButton
      title='Print Preview'
      icon={<PrintIcon />}
      onClick={() => { openPrintPreview && openPrintPreview() }}
    />
     {printReceiptUrl && <TransactionDetailsPrint receiptUrl={printReceiptUrl}/> }
  </>
}