import { Attribute } from 'models/metaData/MetaData';
import { LineItem } from '../../../models/LineItem';
import { getComponentForRangeValidator } from './rangeValidator';

export function getComponentByValidators(
  attribute: Attribute,
  value: any,
  onChange?: (value: any) => void,
  modifiers?: string,
  lineItem?: LineItem
) {
  // implementation note:
  // getComponentForValidator1(attribute) || getComponentForValidator2(attribute)...
  return getComponentForRangeValidator(attribute, value, onChange, modifiers, lineItem);
}
