import { useDispatch } from "react-redux";
import { decimal, required, validateGeneric, minimum, minPaymentAmountValidatorOnTier } from "../../../features/paymentDashboard/Validators";
import { GenericAttribute } from "../../../models/metaData/MetaData";
import { DataType, ValidatorType } from "../../../models/metaData/MetaDataEnums";
import { Tier as PaymentPlanTier } from "../../../models/PaymentPlanConfiguration";
import useErrorStore from "../../../utils/useErrorStore";
import { getErrorHandlers, getErrorMessage, errorClass } from "../../../utils/useTypedValidator";

export interface ErrorsMessages {
  [label: string]: { [validatorType: string]: string };
}

export function usePaymentPlanTiersValidator() {
  const dispatch = useDispatch();

  const getAttributes: (paymentPlanTier: PaymentPlanTier) => GenericAttribute<PaymentPlanTier>[] = (paymentPlanTier: PaymentPlanTier) => [
    { name: "floor", dataType: DataType.Decimal, validators: [required(),minimum({ min: 1 }), decimal(12,2)] },
    { name: "ceiling", dataType: DataType.Decimal, validators: [required(),minimum({min: paymentPlanTier.floor}), decimal(12,2)] },
    { name: "calculateBy", dataType: DataType.String, validators: [required()] },
    { name: "minNumberOfPayments", dataType: DataType.Decimal, validators: [required(),minimum({ min: 1 }), decimal(12,0)] },
    { name: "maxNumberOfPayments", dataType: DataType.Decimal, validators: [required(),minimum({ min: paymentPlanTier.minNumberOfPayments }), decimal(12,0)] },
    { name: "minPaymentAmount", dataType: DataType.Decimal, validators: [required(), minPaymentAmountValidatorOnTier({floor: paymentPlanTier.floor, minNumberOfPayments: paymentPlanTier.minNumberOfPayments}), decimal(12,2)] },
  ];

  const { addError, removeError, errorsRef, errors } = useErrorStore();

  function validateAll(paymentPlanTier?: PaymentPlanTier, validOnly: boolean = false) {
    let valid = true;
    paymentPlanTier && getAttributes(paymentPlanTier).forEach(attribute => {
      let result = validate(paymentPlanTier, attribute, paymentPlanTier[attribute.name], validOnly);
      valid = valid && result;
    });
    return valid;
  }

  function getKeySuffix(paymentPlanTier?: PaymentPlanTier) {
    return paymentPlanTier?.tId.toString() ?? '';
  }

  function validate(currentEntity: PaymentPlanTier,attr?: GenericAttribute<PaymentPlanTier>, value?: any, validOnly: boolean = false ): boolean {
    return validateGeneric({
      onPass: (key: keyof PaymentPlanTier, validatorType: ValidatorType) => {
        let suffexedKey = key.toString();
        if (getKeySuffix) suffexedKey = suffexedKey + getKeySuffix(currentEntity);
        errorRemover({ key: suffexedKey, validatorType })
      },
      onFail: (key: keyof PaymentPlanTier, validatorType: ValidatorType, message?: string) => {
        let suffexedKey = key.toString();
        if (getKeySuffix) suffexedKey = suffexedKey + getKeySuffix(currentEntity);
        errorHandler({
          [suffexedKey]: { [validatorType]: message || '' }
        })
      },
      attr,
      value,
      validOnly,
      entity: currentEntity,
    })
  }

  const { errorRemover, errorHandler } = getErrorHandlers(removeError, addError, false, dispatch);

  return {
    validate, validateAll, getAttributes,
    errorClass: (key: string) => errorClass(key, errorsRef.current,errors),
    getErrorMessage: (key: string) => getErrorMessage(key, errorsRef.current,errors)
  }
}