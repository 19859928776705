import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  NoPermissions,
  Landingpage,
  AdminSettingsPage,
  SearchPaymentspage,
  SimpleSearchResultsPage,
  PaymentPage,
  PaymentPreviewPage,
  GLPaymentPage,
  PatientsList,
} from 'pages';
import Layout from 'features/layout/Layout';
import { useTypedSelector } from 'app/rootReducer';
import PaymentPlanDetails from '../pages/Search/planSearch/PaymentPlanDetails';
import { PaymentPlansSearchResult } from '../pages/Search/planSearch/PaymentPlansSearchResult';
import { EDSThemeProvider } from '@EHDS/core';
import { useResponseInterceptor } from '../services/useInterceptor';
import PaymentDetails from '../pages/PaymentDetails';
import UserPermissions from 'models/enums/UserPermissions';
import { PermissionedRoute } from 'components/permissionedRoute/PermissionedRoute';
import GLPaymentPreviewPage from 'pages/GLPaymentPreviewPage';

const App = () => {
  const loginInfo = useTypedSelector(p => p.loginInfo.value);
  useResponseInterceptor();

  const [layoutHeight, setLayoutHeight] = useState(0);

  const handleWidthUpdate = (height: number) => {
    setLayoutHeight(height);
  };

  return (
    <Layout leftMenuWidth={layoutHeight}>
      <Routes>
        {
          <Route path="/" element={ loginInfo.loggedIn ? <Navigate to="/landing" /> : <div /> }>
          </Route>
        }
        <Route path="/landing" element={<Landingpage />} />
        <Route
          path="/no-permissions"
          element={<NoPermissions />}
        />
        <Route
          path="/searchpayments"
          element={<PermissionedRoute
          component={<SearchPaymentspage />}
          redirect={"/landing"}
          permissions={[UserPermissions.Search]}
        /> } />
        
        <Route
          path="/admin-settings/*"
          element={<PermissionedRoute
            component={<AdminSettingsPage
              onWidthUpdate={handleWidthUpdate}
              rootPath="/admin-settings"

            />}
            redirect={"/landing"}
            permissions={[UserPermissions.FinancialAdministation, UserPermissions.SelfServicePortal]}
          />}
        />

        <Route
          path="/simplesearchresults"
          element={<SimpleSearchResultsPage />}
        />
        <Route path="/patients" element={<PatientsList />} />
        <Route path="/payment/:id" element={<PaymentPage />} />
        <Route path="/payment-preview" element={<PaymentPreviewPage />} />
        <Route path="/payment-plan-preview" element={<PaymentPreviewPage />} />
        <Route path="/gl-payment-preview" element={<GLPaymentPreviewPage />} />
        <Route path="/gl-payment-plan-preview" element={<GLPaymentPreviewPage />} />
        <Route path="/ecn-payment-preview" element={<PaymentPreviewPage />} />

        <Route
          path="/glpayment"
          element={<PermissionedRoute
            component={<GLPaymentPage
            />}
            redirect={"/landing"}
            permissions={[UserPermissions.GLPaymentAccess]}
          />}
        />
        {/* <Route
          path="/clientConfigModal"
          component={() => <GetClientConfigModal providerOptions={[]} />}
          path="/clientConfigDetailsModal"
          component={() => <ClientConfigDetailsModal />}
        /> */}
        <Route
          path="/planDetails/:id"
          element={<PaymentPlanDetails />}
        />
        <Route
          path="/paymentDetails/:id"
          element={<PaymentDetails />}
        />
        <Route
          path="/plansSearchResult"
          element={<PaymentPlansSearchResult />}
        />
        <Route path="/" element={<div>Page not found!</div>} />
      </Routes>
    </Layout>
  );
};

export default () => {
  return (
    <EDSThemeProvider>
      <App />
    </EDSThemeProvider>
  );
};

function changeInputValue(element: HTMLInputElement, value: string) {
  element.focus();
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value'
  )?.set;
  nativeInputValueSetter?.call(element, value);

  const event = new Event('input', { bubbles: true });
  element.dispatchEvent(event);
  window.setTimeout(() => element.blur(), 0);
}

//@ts-ignore
window.changeInputValue = changeInputValue;

function changeOption(openButton: HTMLButtonElement, optionId: string) {
  openButton.click();
  const optionElement = document.getElementById(optionId);
  optionElement?.click();
  window.setTimeout(() => openButton.click(), 0);
}

//@ts-ignore
window.changeInputValue = changeInputValue;
//@ts-ignore
window.changeOption = changeOption;
