import { OrganizationLevelReference } from "../models/OrganizationLevelDocument";
import { callHttpApi } from "./callHttpApi";

export interface EpicDepartmentMapping {
  id?: string,
  mapKey?: string,
  department?: OrganizationLevelReference,
}
export async function GetEpicDepartmentMappings(organizationPath?: string) {
  return callHttpApi<EpicDepartmentMapping[], string[]>(
    'post',
    `api/v1/EpicDepartmentMapping/epicdepartmentmappingsbyorganizationpaths`,
    [organizationPath ?? ""]
  );
}

export async function UpdateEpicDepartmentMappings(mappings?: EpicDepartmentMapping[]) {
  return callHttpApi<EpicDepartmentMapping[], { update?: EpicDepartmentMapping[]}>(
    'put',
    `api/v1/EpicDepartmentMapping`,
    { update: mappings }
  );
}
export async function CreateEpicDepartmentMappings(mappings?: EpicDepartmentMapping[]) {
  return callHttpApi<EpicDepartmentMapping[], { create?: EpicDepartmentMapping[]}>(
    'post',
    `api/v1/EpicDepartmentMapping`,
    { create: mappings }
  );
}
export async function DeleteEpicDepartmentMappings(mappingIds?: string[]) {
  return callHttpApi<EpicDepartmentMapping[], string[]>(
    'delete',
    `api/v1/EpicDepartmentMapping/delete`,
    mappingIds
  );
}