import React from 'react';

import { ReactComponent as Alipay } from './svg/alipay.svg';
import { ReactComponent as Americanexpress } from './svg/amex.svg';
import { ReactComponent as Default } from './svg/default.svg';
import { ReactComponent as Diners } from './svg/diners.svg';
import { ReactComponent as Discover } from './svg/discover.svg';
import { ReactComponent as ECheck } from './svg/eCheck.svg';
import { ReactComponent as Elo } from './svg/elo.svg';
import { ReactComponent as Hipercard } from './svg/hipercard.svg';
import { ReactComponent as Jcb } from './svg/jcb.svg';
import { ReactComponent as Maestro } from './svg/maestro.svg';
import { ReactComponent as Mastercard } from './svg/mastercard.svg';
import { ReactComponent as Paypal } from './svg/paypal.svg';
import { ReactComponent as Security } from './svg/security-code.svg';
import { ReactComponent as Unionpay } from './svg/unionpay.svg';
import { ReactComponent as Verve } from './svg/verve.svg';
import { ReactComponent as Visa } from './svg/visa.svg';

export enum cardBrand {
  ALIPAY,
  AMERICANEXPRESS,
  DEBIT,
  DEFAULT,
  DINERS,
  DISCOVER,
  ECHECK,
  ELO,
  ENROUTE,
  GSB,
  HIPERCARD,
  JCB,
  MAESTRO,
  MASTERCARD,
  PAYPAL,
  SECURITY,
  UNIONPAY,
  VERVE,
  VISA,
}

const cardBrandsMap: { [key in cardBrand]: any } = {
  [cardBrand.ALIPAY]: Alipay,
  [cardBrand.AMERICANEXPRESS]: Americanexpress,
  [cardBrand.DEFAULT]: Default,
  [cardBrand.DEBIT]: Default,
  [cardBrand.DINERS]: Diners,
  [cardBrand.DISCOVER]: Discover,
  [cardBrand.ECHECK]: ECheck,
  [cardBrand.ELO]: Elo,
  [cardBrand.ENROUTE]: Default,
  [cardBrand.GSB]: Default,
  [cardBrand.HIPERCARD]: Hipercard,
  [cardBrand.JCB]: Jcb,
  [cardBrand.MAESTRO]: Maestro,
  [cardBrand.MASTERCARD]: Mastercard,
  [cardBrand.PAYPAL]: Paypal,
  [cardBrand.SECURITY]: Security,
  [cardBrand.UNIONPAY]: Unionpay,
  [cardBrand.VERVE]: Verve,
  [cardBrand.VISA]: Visa,
};

interface CardIconProps {
  brand: cardBrand;
  width?: string;
  height?: string;
}

export function CardBrandIcon({
  brand = cardBrand.DEFAULT,
  ...props
}: CardIconProps) {
  const Icon = cardBrandsMap[brand];
  return <Icon {...props}></Icon>;
}
