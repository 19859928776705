import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { useGetOrganizations } from 'features/organizations/hooks/useGetOrganizations';
import DashBoards from 'features/paymentDashboard/DashBoards';
import CreatePaymentPlan from 'features/paymentPlan/CreatePaymentPlan';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as router from 'react-router-dom';
import { useTypedSelector } from '../app/rootReducer';
import '../assets/styles/components/_payment.scss';
import { useOrganizations } from '../features/organizations/hooks/useOrganizations';
import { usePaymentWizzard } from '../features/wizards/PaymentWizard';
import { setGLPaymentInfoState } from './GLPaymentDashboard/GLPaymentDashboardReducer';
import { ReactComponent as Edit } from 'assets/svgs/edit-pen.svg';
import { Dashboards } from 'features/admin/paymentPanel/ConfigurationSelector';
import { useLocalStorage } from 'utils/useLocalStorage';
import { MetaData } from 'models/metaData/MetaData';
import { saveConfiguration } from 'features/admin/paymentPanel/PaymentPanelReducer';
import { setPlanPanelConfiguration } from 'features/paymentPlan/PaymentPlanReducer';
import { getValueOrDefault } from 'utils/Utils';

export default () => {
  const { search } = router.useLocation()
  const queryParams = new URLSearchParams(search);
  const facilityId = queryParams.get("facility");
  const dispatch = useDispatch();
  const state = useTypedSelector(s => s.glPaymentDashboard?.value);
  const { useGetOrganizationById } = useOrganizations();
  const facility = useGetOrganizationById(facilityId || '');
  const { getOrganizations } = useGetOrganizations();
  const [glPatientId, setGlPatientId] = useState<{ id: string }>()
  const wizard = usePaymentWizzard();
  const selectedPlans = useTypedSelector(s => s.paymentPlanInfo?.selected) || [];
  const selectedDashboard = localStorage.getItem('selectedDashboard');
  const [selectedConfiguration] = useLocalStorage<MetaData>('paymentPanelState', 'selectedConfiguration', {});

  useEffect(() => {
    getOrganizations();
    dispatch(
      setBreadcrumbLinks([{ name: 'Make a GL Payment Preview', slug: 'payment' }])
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setGLPaymentInfoState({
        ...state,
        facility: facility
      }),
    )
  }, [facility]);

  useMemo(async () => {
    dispatchSelectedConfig();
  }, [facility]);

  function dispatchSelectedConfig() {
    if (selectedConfiguration && Object.keys(selectedConfiguration).length) {
      dispatch(saveConfiguration(
        {
          key: selectedDashboard as keyof Dashboards,
          configuration: {
            ...selectedConfiguration,
          }
        }));
        dispatch(
          setPlanPanelConfiguration({ organizationPath: getValueOrDefault(facility?.path, ''), configuration: { ...selectedConfiguration } })
        );
    }
  }

  function onEdit() {
    window.close();
  }

  const dashBoards = useMemo(() => {
    if (facility) {
      return <DashBoards
        key={facility.id}
        isGl={true}
        wizard={wizard}
        selectedFacility={facility}
        paymentType={true}
        isPreview={true}
      />
    }
  }, [facility]);

  return facility ? (
    <div className='dash'>
      <div className="header-bar">
        GL PAYMENT PAGE PREVIEW
        <button
          onClick={onEdit}
          className="edit-button"
        >
          <Edit />
          <span className="edit-span">Edit</span>
        </button>
      </div>
      <div className="pane">
        <div className="left">
          <div className="facility-container">
            <div className="eds-heading eds-heading.mdplus-caps mb-1">
              User Location
            </div>
            <div className="facility-form">
              <div className="facility-form-two">
                <div>
                  Facility:
                  <span style={{ fontWeight: "bold", paddingRight: "30px" }}> {state?.facility?.name !== 'Facility 1' && state?.facility?.name}</span>
                </div>
                <div>
                  Department:
                  <span style={{ fontWeight: "bold" }}> Preview Department</span>
                </div>
              </div>
            </div>
          </div>
          {dashBoards}
        </div>
        <div className="right">
          <CreatePaymentPlan
            isGl={true}
            wizard={wizard}
            ids={selectedPlans}
            patientId={glPatientId?.id}
            organization={state.department}
            setGlPatientId={setGlPatientId}
            facilityInfo={state?.facility}
            showNextPatientModal={false}
          />
        </div>
      </div>
    </div>
  ) : (
    <div>Processing ...</div>
  )
};