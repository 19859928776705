import { GridConfig } from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';
import EnumTransactionType from 'models/enums/EnumTransactionType';
import { getUserName, formatDateWithTime } from 'utils/Utils';
import moment from 'moment';
import { convertUtcToLocalDate } from 'utils/UtilsDateTime';

export function getPaymentAmount(payment: any) {
  const lineItem = (payment?.lineItems ?? [])[0];
  return payment?.transactionType === EnumTransactionType.Credit ||
                      payment?.transactionType === EnumTransactionType.GLCredit 
                      ? lineItem?.amount * -1 ?? ''
                      : lineItem?.amount  ?? '';
}

export function getBalanceAmount(payment: any) {
  const lineItem = (payment?.lineItems ?? [])[0];
  return payment?.transactionType === EnumTransactionType.Credit ||
                      payment?.transactionType === EnumTransactionType.GLCredit 
                      ? lineItem?.balance * -1 ?? ''
                      : lineItem?.balance  ?? '';
}

export const data: GridConfig[] = [
  { label: 'Transaction ID', sortable: true, colId: 'TransactionSummary.Id', field: 'id', cellRenderer: 'LinkRenderer', pinned: 'left', suppressMovable: true, resizable: false, width: 220 },
  { label: 'Transaction Type', sortable: true, colId: 'TransactionSummary.transactionType', field: 'transactionType' },
  { label: 'Account Number', sortable: true, colId: 'LineItem.accountNumber', field: 'accountNumber' },
  { label: 'MRN', sortable: true, colId: 'TransactionSummary.mrn', field: 'mrn' },
  { label: 'Patient First Name', sortable: true, colId: 'TransactionSummary.patientFirstName', field: 'patientFirstName' },
  { label: 'Patient Last Name', sortable: true, colId: 'TransactionSummary.patientLastName', field: 'patientLastName' },
  { label: 'Patient Date of Birth', sortable: true, colId: 'TransactionSummary.patientDateOfBirth', field: 'patientDateOfBirth', filter: 'agDateColumnFilter', filterParams: 'inRange', valueFormatter: 'dateOfBirthString' },
  { label: 'Payment Amount', sortable: true, colId: 'LineItem.balance', field: 'balance', filter: 'agNumberColumnFilter', filterParams: 'inRange', valueFormatter: 'currency', cellStyle: 'right' },
  { label: 'Billed Amount', sortable: true, colId: 'LineItem.amount', field: 'amount', filter: 'agNumberColumnFilter', filterParams: 'inRange', valueFormatter: 'currency', cellStyle: 'right' },
  { label: 'Discount Amount', sortable: true, colId: 'LineItem.discount', field: 'discount', filter: 'agNumberColumnFilter', filterParams: 'inRange', valueFormatter: 'currency', getValue: (payment) => payment?.lineItems?.find(l => l?.accountNumber === payment?.accountNumber)?.discount },
  { label: 'Discount Percent', sortable: true, colId: 'LineItem.discountPercent', field: 'discountPercent', filter: 'agNumberColumnFilter', filterParams: 'inRange', valueFormatter: 'percent', getValue: (payment) => payment?.lineItems?.find(l => l?.accountNumber === payment?.accountNumber)?.discountPercent },
  { label: 'Payer First name', sortable: true, colId: 'TransactionSummary.payerFirstName', field: 'payerFirstName' },
  { label: 'Payer Last name', sortable: true, colId: 'TransactionSummary.payerLastName', field: 'payerLastName' },
  { label: 'Notes', sortable: true, colId: 'Notes', field: 'notes', cellRenderer: 'popup', getValue: (payment) => payment?.isGL && payment?.lineItems?.find(l => l?.accountNumber === payment?.accountNumber)?.notes ? payment?.lineItems?.find(l => l?.accountNumber === payment?.accountNumber)?.notes : payment?.notes || '' },
  { label: 'Gateway Reference Number', sortable: true, colId: 'TransactionSummary.gatewayReferenceNumber', field: 'gatewayReferenceNumber' },
  { label: 'Tender Type', sortable: true, colId: 'TransactionSummary.tenderType', field: 'tenderType', valueFormatter: 'tenderType' },
  { label: 'Card Brand', sortable: true, colId: 'TransactionSummary.cardBrand', field: 'cardBrand', cellRenderer: 'cardBrand' },
  { label: 'Method Ending In', sortable: false, colId: 'TransactionSummary.methodEndingIn', field: 'tenderMaskedAccount', valueFormatter: 'creditCard' },
  { label: 'Submitted Date', sortable: true, colId: 'TransactionSummary.createdDate', field: 'createdDate', filter: 'agDateColumnFilter', filterParams: 'inRange', valueFormatter: 'dateString', getValue: (payment) => payment && 'createdDate' in payment ? formatDateWithTime(moment(convertUtcToLocalDate(payment?.createdDate))) : '' },
  { label: 'Settled Date', sortable: true, colId: 'TransactionSummary.settledDate', field: 'settledDate', filter: 'agDateColumnFilter', filterParams: 'inRange', valueFormatter: 'dateString', getValue: (payment) => payment && 'settledDate' in payment ? formatDateWithTime(moment(convertUtcToLocalDate(payment?.settledDate))) : '' },
  { label: 'Payment Plan ID', sortable: true, colId: 'TransactionSummary.paymentPlanId', field: 'paymentPlanId' },
  { label: 'Facility Name', sortable: true, colId: 'TransactionSummary.organization.name', field: 'facilityName', getValue: (payment, utils) => payment?.organization?.path ? utils.getFacilityByDepartment(payment?.organization?.path)?.name ?? '' : '' },
  { label: 'Department Name', sortable: true, colId: 'TransactionSummary.department.name', field: 'departmentName', getValue: (payment) => payment?.department?.name ?? '' },
  { label: 'Created By', sortable: true, colId: 'TransactionSummary.createdBy.firstName', field: 'createdBy', getValue: (payment) => payment && 'createdBy' in payment ? getUserName(payment.createdBy, true) : '' },
  { label: 'Patient ID', sortable: true, colId: 'TransactionSummary.patientId', field: 'patientId' },
  { label: 'Patient Type', sortable: true, colId: 'TransactionSummary.patientType', field: 'patientType' },
  { label: 'GL Description', sortable: true, colId: 'LineItem.description', field: 'glDescription', getValue: (payment) => payment?.isGL ? payment?.lineItems?.find(l => l?.accountNumber === payment?.accountNumber)?.description : '' },
  { label: 'GL Number', sortable: true, colId: 'LineItem.glNumber', field: 'glNumber', getValue: (payment) => payment?.isGL ? payment?.lineItems?.find(l => l?.accountNumber === payment?.accountNumber)?.glNumber : '' },
  { label: 'GL Billing Department', sortable: true, colId: 'LineItem.billingDepartment', field: 'glBillingDepartment', getValue: (payment) => payment?.isGL ? payment?.lineItems?.find(l => l?.accountNumber === payment?.accountNumber)?.billingDepartment : '' },
  { label: 'Guarantor ID', sortable: true, colId: 'TransactionSummary.guarantorId', field: 'guarantorId' },
  { label: 'Episode', sortable: true, colId: 'TransactionSummary.episode', field: 'episode' },
  { label: 'Authorization Code', sortable: true, colId: 'TransactionSummary.authorizationCode', field: 'authorizationCode' },
  { label: 'Payment Status', sortable: true, colId: 'TransactionSummary.statusType', field: 'statusType', valueFormatter: 'paymentStatus' },
  { label: 'Batch ID', sortable: true, colId: 'TransactionSummary.batchId', field: 'batchId', filterParams: 'equals' },
  { label: 'Payment Source', sortable: true, colId: 'TransactionSummary.paymentSource', field: 'paymentSource' },
  { label: 'Result Status', sortable: true, colId: 'TransactionSummary.resultCode', field: 'resultCode' },
  { label: 'Device Name', sortable: true, colId: 'TransactionSummary.device.name', field: 'deviceName', getValue: (payment) => payment?.device ? payment.device.name : '' },
  { label: 'Device Serial Number', sortable: true, colId: 'TransactionSummary.device.serialNumber', field: 'deviceSerialNumber', getValue: (payment) => payment?.device ? payment.device.serialNumber : '' },
  
];
