import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'typeface-roboto';
import '../../assets/styles/components/_patientinfopage.scss';
import '../../assets/styles/components/_actionConfirmModal.scss';
import { Button } from '@EHDS/core';
import { FaSpinner } from 'react-icons/fa';
import { ReactComponent as WarningIcon } from 'assets/svgs/warning-v2.svg';
import { ReactComponent as InfoIcon } from 'assets/svgs/icon-info.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/warning-close-icon.svg';
import { checkReceivedHasValues } from 'utils/Utils';

export default (props: {
  isOpen: boolean;
  actionTitle: string | JSX.Element;
  actionConfirmText: string;
  actionButtonText: string;
  onActionConfirm: () => void;
  onSecondaryActionConfirm?: () => void;
  showCancelButton?: boolean;
  isSecondaryButton?: boolean;
  actionCancelButtonText?: string;
  onClose?: () => void;
  iconName?: string
  isProcessing?: boolean;
  useWarningStyle?: boolean;
  useInfoStyle?: boolean;
}) => {
  const {
    isOpen,
    actionTitle,
    actionConfirmText,
    actionButtonText,
    onActionConfirm,
    onSecondaryActionConfirm,
    showCancelButton,
    isSecondaryButton,
    actionCancelButtonText,
    onClose,
    iconName = 'chevron_right',
    isProcessing,
    useWarningStyle,
    useInfoStyle
  } = props;
  const openClass = checkReceivedHasValues(isOpen, 'eds-modal.open', '');
  const useDefaultStyle = useWarningStyle || useInfoStyle;

  function onModalCloseClick() {
    !isProcessing && onClose && onClose();
  }

  function getTitle() {
    let styleClass = '';
    let Icon = null;

    if (useWarningStyle) {
      styleClass = 'warning-text';
      Icon = WarningIcon;
    } else if (useInfoStyle) {
      styleClass = 'info-text';
      Icon = InfoIcon;
    }

    return (
      <div className={`col ${styleClass}`}>
        {Icon && <Icon className='warning-icon'/>}
        <b className={styleClass}>{actionTitle}</b>
      </div>
    );
  }

  function getStyleType(){
    if(useWarningStyle){
      return "warning-header";
    }
    if(useInfoStyle){
      return "info-header";
   }
    return "header"
  }

  return (
    <div>
      <section
        className={`eds-modal action-confirm-modal ${openClass}`}
        id="actionConfirmModal"
      >
        <div className="eds-modal-container eds-modal_#container">
          <header className={`eds-modal-${getStyleType()} eds-modal_#header`}>
            <div className="eds-modal-title eds-modal_.title row">
              {getTitle()}
              <div className="col text-right">
                {
                  checkReceivedHasValues(
                    useDefaultStyle,
                    <CloseIcon onClick={onModalCloseClick} />,
                    <i
                      data-testid="closeIcon"
                      onClick={onModalCloseClick}
                      className="material-icons icon-close"
                    >
                      cancel
                    </i>
                  )
                }
              </div>
            </div>
          </header>
          <div className="eds-modal-content eds-modal_#content">
            <div className="text">
              <p dangerouslySetInnerHTML={{ __html: actionConfirmText }} />
            </div>
            <div className={`button-container ${checkReceivedHasValues(useDefaultStyle, 'warning-button-container', '')}`}>
              {showCancelButton && <Button
                className={'eds-button eds-button.basic btn-cancel'}
                disabled={isProcessing}
                onClick={() => { 
                  if(isSecondaryButton && onSecondaryActionConfirm)
                  {
                    onSecondaryActionConfirm();
                  }else{
                    onModalCloseClick();
                  }
                }}
                iconName={iconName}
              >
                {checkReceivedHasValues(isProcessing,
                 (
                  <span>
                    <FaSpinner className="icon-spin" />
                  </span>
                ), (
                  actionCancelButtonText
                )
                )}
              </Button>}
              <Button
              className={`eds-button eds-button.primary btn-ok${checkReceivedHasValues(showCancelButton, ' iconOnRight', '')}`}
              disabled={isProcessing}
              type={checkReceivedHasValues(useDefaultStyle, 'primary', 'basic')}
              onClick={() => {
                if (!isProcessing) {
                  onActionConfirm();
                }
              }}
              iconName={iconName}
              >
              {checkReceivedHasValues(isProcessing, (
                <span>
                  <FaSpinner className="icon-spin" />
                </span>
              ), (
                actionButtonText
              ))}
            </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
