import { useDispatch } from 'react-redux';
import { setInitialState as setPaymentPlanReducerInitialState } from 'features/paymentPlan/PaymentPlanReducer';
import { setInitialState as setPatientMailingInformationReducerInitialState } from 'features/paymentInfo/PatientMailingInformationReducer';
import { setInitialState as setPaymentDashboardReducerInitialState } from 'features/paymentDashboard/PaymentDashboardReducer';
import { setInitialState as setGLPaymentDashboardReducerInitialState } from 'pages/GLPaymentDashboard/GLPaymentDashboardReducer';

export function useSetReducersInitialState() {
  const dispatch = useDispatch();

  const PaymentGLPaymentPageReducers = () => {
    dispatch(setPaymentPlanReducerInitialState());
    dispatch(setPatientMailingInformationReducerInitialState());
    dispatch(setPaymentDashboardReducerInitialState());
    dispatch(setGLPaymentDashboardReducerInitialState());
  }

  return {
    paymentPageReducers: PaymentGLPaymentPageReducers, 
    glPaymentPageReducers: PaymentGLPaymentPageReducers 
  };
}