import { parseISO } from 'date-fns';
import moment from 'moment';

export function convertDateToString(
  date?: Date | null
): string | undefined {
  return date?.toISOString();
}

export function convertDateToStringWithoutZuluTimeZone(
  date?: Date | null
): string | undefined {  
  return moment(date).format('YYYY-MM-DDTHH:mm:ss.000')
}

export function convertDateToDisplayString(
  date?: Date | null
): string | undefined {
  return date?.toLocaleString();
}

export function convertDateToLocaleDateString(
  date?: Date | null,
  locale?: string
): string | undefined {
  if (moment.utc(date).isValid()) {
    return date?.toLocaleDateString(locale);
  }
}

export function convertStringToDate(dateString: string): Date | undefined {
  if (moment.utc(dateString).isValid()) {
    return parseISO(dateString);
  }
}

export const getNextMonthDate = () => {
  let date = moment.utc();
  return date.startOf('month').toDate();
};

export function getNextMonthYearString(date?: string) {
  if (!date) return '';
  return moment.utc(date).format('MM/YY');
}

export function convertUtcToLocalDate (date: string | undefined) {
  const momentDate = moment(date)
  if (date && momentDate.isValid()){
    return momentDate.local().format()
  }
  return date;
}

export function convertDateToUtc (date : string | undefined, removeTime: boolean = false) {
  const momentDate = moment(date)
  if (date && momentDate.isValid()){
    if (removeTime)
    {
      momentDate.startOf('day');
    }

    return momentDate.format("Y-MM-DDT") + "00:00:00Z";
  }
  return date;
}

export function reformatDate(date: Date | undefined, format: string) {
  return moment(date)?.utc().format(format);  
}

export function getLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};