import React from 'react';
import { PayerInformation } from 'models/PaymentPlan';
import { checkReceivedHasValues } from 'utils/Utils';

export default (props: { payerInformation?: PayerInformation, mailingInformation?: PayerInformation }) => {
  const { payerInformation, mailingInformation } = props;
  const firstName = checkReceivedHasValues(payerInformation?.firstName, payerInformation?.firstName, mailingInformation?.firstName);
  const lastName = checkReceivedHasValues(payerInformation?.lastName, payerInformation?.lastName, mailingInformation?.lastName);
  
  return (
    <div className="row-item row-item-size-double">
      <div className="eds-heading eds-heading.mdplus-caps mb-1">
        Payer Billing Information
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">First Name</div>
        <div className="col-6">{firstName}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Last Name</div>
        <div className="col-6">{lastName}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Address Line 1:</div>
        <div className="col-6">{payerInformation?.addressLine1}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Address Line 2:</div>
        <div className="col-6">{payerInformation?.addressLine2 || '-'}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">City:</div>
        <div className="col-6">{payerInformation?.city}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">State:</div>
        <div className="col-6">{payerInformation?.state}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Zip:</div>
        <div className="col-6">{payerInformation?.zipCode}</div>
      </div>
      <div className="row">
        <div className="col-6 font-weight-bold">Phone:</div>
        <div className="col-6">{payerInformation?.phone}</div>
      </div>
    </div>
  );
};
