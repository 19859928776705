import { Ref, useImperativeHandle, useState } from "react";

export function useValueRef<T>(initialValue: T, ref: Ref<unknown>){
  const [value, setValue] = useState(initialValue);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      isCancelBeforeStart() {
        return false;
      },

      isCancelAfterEnd() {
        return false;
      },
    };
  });

  return [value, setValue];
}