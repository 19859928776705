import PaymentPlan from 'models/PaymentPlan';
import { BreakDownLineItem, LineItem } from '../../../models/LineItem';
import moment from 'moment';
import { formatDateWithTime } from 'utils/Utils';

export function planToBreakDownLineItem(
  lineItem?: LineItem, paymentPlan ?:PaymentPlan
): BreakDownLineItem {
  return {
    accountNumber: lineItem?.accountNumber ?? '',
    balance: lineItem?.balance ?? 0,
    createdDate: (lineItem?.dateOfService || paymentPlan?.createdDate || formatDateWithTime(moment.utc())),
    amount: lineItem?.amount ?? 0,
  };
}
