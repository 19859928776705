import { EDS_Checkbox } from '@EH/eh.eds.react';
import { Attribute } from 'models/metaData/MetaData';
import React, { useCallback, useState } from 'react';
import {
  getLabel
} from 'utils/metadata/MetaDataUtils';

export function CheckBox(props: {
  attribute: Attribute;
  value: any;
  onChange?: (value: any) => void;
  modifiers?: string;
}) {
  const { attribute, onChange, value, modifiers} = props;
  const label = getLabel(attribute);
  const name = attribute.name;
  const dataType = attribute.dataType;
  const [isChecked, setIsChecked] = useState(value);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if(!onChange){
        return;
      }

      setIsChecked(!isChecked);
      onChange(event.target.checked);
    },
    [dataType, onChange]
  );
  
  return (
    <>
    <div className={attribute.required && !isChecked ? 'required-field' : ''}>
      <div className={'custom-eds-label'}>
        {label}
      </div>
    </div>
      <EDS_Checkbox
        key={`${name}-checkbox`}
        modifiers={modifiers?.replace('required-field', '')}
        checked={isChecked}
        name={name}
        onChange={handleChange}
      />
    </>
  );   
}
