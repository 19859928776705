import { CardTokenResponse } from 'models/CardTokenResponse';
import { callHttpApi } from 'services/callHttpApi';
import { CardEntryTokenizationRequest } from './models/CardEntryTokenizationRequest';

export async function CardEntryTokenizationService(
  tokenRequest: CardEntryTokenizationRequest
) {
  return callHttpApi<CardTokenResponse, CardEntryTokenizationRequest>(
    'post',
    `/api/v1/Transactions/cardEntryTokenization`,
    tokenRequest
  );
}
