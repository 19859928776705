import ConfigurationTemplate from "../../features/admin/clientConfiguration/ConfigurationTemplate";
import { HeaderButton } from "../Search/planSearch/paymentPlanDetailsHeader/HeaderButton";
import { ReactComponent as Add } from 'assets/svgs/admin/glListManager/add.svg';
import { ReactComponent as Edit } from 'assets/svgs/admin/glListManager/edit.svg';
import { ReactComponent as Remove } from 'assets/svgs/admin/glListManager/remove.svg';
import { ReactComponent as Import } from 'assets/svgs/admin/glListManager/import.svg';
import { ReactComponent as Export } from 'assets/svgs/admin/glListManager/export.svg';
import { Button } from "@EHDS/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { GridColumnsChangedEvent, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import { Dispatch, SetStateAction, forwardRef, useCallback, useEffect, useRef, useState } from "react";
import * as router from 'react-router-dom';
import { useOrganizations } from "../../features/organizations/hooks/useOrganizations";
import { useTypedSelector } from "../../app/rootReducer";
import { useGetPaymentConfiguration } from "../../features/singlePayment/hooks/useGetPaymentConfiguration";
import { useDispatch } from "react-redux";
import { GlDisplayField, PaymentConfiguration } from "../../models/PaymentsConfiguration";
import { setGlFields } from "../../features/paymentDashboard/PaymentDashboardReducer";
import { GenericInputCellProps } from "./Remit/RemitManagement";
import { useValueRef } from "../../features/gridCells/useValueRef";
import { useSaveGlList } from "../../features/admin/glList/useSaveGlList";
import { useGlListManagerValidator } from "../../features/admin/glList/useGlListManagerValidator";
import { Popup } from "../../components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "../../models/enums/EnumPopupType";
import { AlertIds, AlertTypes, setAlert } from "../../features/alert/AlertReducer";
import { v4 as uuid } from 'uuid';
import TextBox from "../../features/componentFactory/wrapperComponents/TextBox";
import Papa from "papaparse";
import 'assets/styles/components/_glListManager.scss';
import 'assets/styles/components/_customHeaderCell.scss';
import { GenericAttribute } from "models/metaData/MetaData";
import { checkReceivedHasValues, checkStringHasValue, validateCombinedConditions, valueOrDefault } from "utils/Utils";
import { FullOrganizationLevelDocument } from "models/OrganizationLevelDocument";
interface ValidatedRenderCell<T> extends GenericInputCellProps<T> {
  errorMessage?: string
}

export default function GlListManager() {
  const dispatch = useDispatch();

  let { clientId } = router.useParams<{ clientId: string }>();
  const { getOrganizationById } = useOrganizations();
  const selectedOrganization = getOrganizationById(clientId);
  const organizationPath = checkStringHasValue(selectedOrganization?.path);
  const [ configExists, setConfigExists ] = useState(false);

  const onGetConfigurationError = (error?: string): void => {
    setConfigExists(false);
    dispatch(setAlert({
      id: AlertIds.PaymentConfiguration,
      message: valueOrDefault(error, "Could not get Facility's configuation"),
      type: AlertTypes.Error
    }))
  };
  const onGetConfigurationSuccess = ()=>{
    setConfigExists(true);
  };
  const { getConfiguration } = useGetPaymentConfiguration(organizationPath, onGetConfigurationError, onGetConfigurationSuccess);
  const configuration = useTypedSelector(s => s.paymentDashboard.configurations[organizationPath]?.configuration);

  useEffect(() => {
      getConfiguration(false);
  }, [configuration]);

  useEffect(() => {
    if(configuration) removeEmptyRows();  
  }, []);

  const glOptions: GlDisplayField[] = valueOrDefault(configuration?.glDisplayFields, []);
  const [selectedGlOption, setSelectedGlOption] = useState<string[]>();

  function sizeColumnsToFit(params: GridReadyEvent | GridColumnsChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  const resetEdit = () =>{
      const glFields = glOptions?.map((o) => ({
        ...o,
        edited: false
      }));
      resetGridData(glFields);
  }

  function onEditClick() {
      const glFields: GlDisplayField[] = glOptions.map(o => ({
        ...o,
        edited: !!selectedGlOption?.find(selected => selected === o.glId)
      }));
      resetGridData(glFields);
  }

  const resetGridData = (glFields: GlDisplayField[]) => {
      dispatch(setGlFields({ organizationPath, glFields: [] }));
      window.setTimeout(() => { dispatch(setGlFields({ organizationPath, glFields })) }, 0);
      setSelectedGlOption([]);
  }

  const { validate, validateAll, attributes, getErrorMessage, errorClass } = useGlListManagerValidator(glOptions);

  function onChangeGlOption(
    idx: number,
    field: keyof GlDisplayField,
    value: string,
    all?: GlDisplayField[]
  ) {
    if(all == undefined) return;
      const newGlOptions = [...all];
      let updated = { ...all[idx] };
      updated = {
        ...updated,
        [field]: value
      };
      newGlOptions[idx] = updated;
      dispatch(setGlFields({ organizationPath, glFields: newGlOptions }));
  }

  const InputCell = getInputCell(attributes, validate, errorClass, getErrorMessage, organizationPath);

  const RenderCell = getRenderCell(errorClass, getErrorMessage);

  const gridRef = useRef<AgGridReact>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onImportCSV = () => fileInputRef.current?.click();

  const onFileChange = (event:any) =>{
    event.preventDefault();
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results:any) {
        const rowsArray: any = [];
        results.data.map((d:any) => {
          rowsArray.push(Object.keys(d));
        });
        processFileRows(organizationPath, rowsArray, glOptions, results, dispatch);
      },
    });
  }

  const { saveGlList } = useSaveGlList(resetEdit);

  const onAddRowClick = () => {
    const glFields = [...glOptions];
    glFields.unshift({
      amount: 0,
      glId: uuid(),
      edited: true
    })
    dispatch(setGlFields({ organizationPath, glFields }));
  }

  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);

  const onRemoveRowClick = () => {
    const glFields: GlDisplayField[] = [];
    glOptions.forEach(o => {
      if (!selectedGlOption?.find(s => s === o.glId)) glFields.push(o);
    });
    dispatch(setGlFields({ organizationPath, glFields })); 

    setIsRemovePopupOpen(false);
  }

  const removeEmptyRows = () => {
    const glFields: GlDisplayField[] = glOptions.filter((gl: any) => !gl.edited);
    dispatch(setGlFields({ organizationPath, glFields })); 
  }

  const validateConfiguration = (configuration: PaymentConfiguration): boolean => {
    let isValid = true;
    configuration.glDisplayFields?.forEach(g => {
      isValid = validateCombinedConditions(validateAll(false, g), isValid);
    });
    return isValid;
  };

  const prepareConfigurationToSave = (configuration: PaymentConfiguration, selectedOrganization?: FullOrganizationLevelDocument): PaymentConfiguration => {
    return {
      ...configuration,
      organization: {
        id: valueOrDefault(selectedOrganization?.id, ''),
        name: valueOrDefault(selectedOrganization?.name, ''),
        path: valueOrDefault(configuration?.organization?.path, '')
      }
    };
  }

  const onSave = () => {
    const configurationToSave = prepareConfigurationToSave(configuration, selectedOrganization);
  
    if (!validateConfiguration(configurationToSave)) {
      dispatch(setAlert({
        id: AlertIds.PaymentConfiguration,
        message: "Configuration is not valid",
        type: AlertTypes.Error
      }));
      configurationToSave.glDisplayFields && resetGridData(configurationToSave.glDisplayFields);
      return;
    }
  
    saveGlList(configurationToSave);
  };
 
  const onExportCSV = useCallback(() => {
    gridRef?.current?.api?.exportDataAsCsv({fileName: "GL List Manager"});
  }, []);

  const addErrorMessage = (data: any, field?: string) => {
    if (field) {
      return getErrorMessage(field + data.glId);
    }
  }

  return (
    configExists ? 
    <ConfigurationTemplate title={'GL List Manager'}>
      {displayRemovePopUp(onRemoveRowClick, setIsRemovePopupOpen, isRemovePopupOpen,  selectedGlOption)}
      <div className={"header-buttons"}>
        <HeaderButton
          title={"Add Row"}
          icon={<Add />}
          onClick={() => {
            onAddRowClick()
          }}
          disabled={false}
        />
        <HeaderButton
          title={"Edit"}
          icon={<Edit />}
          onClick={() => {
            onEditClick()
          }}
          disabled={false}
        />
        <HeaderButton
          title={"Remove Item(s)"}
          icon={<Remove />}
          onClick={() => {
            setIsRemovePopupOpen(true);
          }}
          disabled={false}
        />
        <HeaderButton
          title={"Import List CSV"}
          icon={<Import />}
          onClick={() => {
            onImportCSV()
            //to be implemented in STEL-5220
          }}
          disabled={false}
        />
        <HeaderButton
          title={"Export List CSV"}
          icon={<Export />}
          onClick={() => {
            onExportCSV()
            //to be implemented in STEL-5220
          }}
          disabled={false}
        />
        <Button
          onClick={()=> onSave()}
        > Save </Button>
        <input ref={fileInputRef} type='file' hidden onChange={onFileChange} accept={'.csv'} data-testid='inputfile'></input>
      </div>
      {glOptions?.length ?
        <>
          <div className="ag-theme-alpine default-checkboxes">
            <AgGridReact
              ref={gridRef}
              deltaRowDataMode={true}
              getRowNodeId={(data:any) => data.glId}

              columnDefs={[{
                headerName: "GL Code",
                field: 'glNumber',
                headerCheckboxSelection: true,
                checkboxSelection: true,
              },
              {
                headerName: "GL Item Description",
                field: 'description',
              },
              {
                headerName: "Email",
                field: 'email',
              },
              {
                headerName: "Unit Cost",
                field: 'unitCost',
              }]}
              onGridReady={sizeColumnsToFit}
              onGridColumnsChanged={sizeColumnsToFit}
              defaultColDef={{
                sortable: false,
                resizable: true,
                filter: false,
                headerClass: 'custom-header-cell',
                cellClass: (params:any) => {
                  const { data, colDef } = params;
                  const field = colDef.field as keyof GlDisplayField;
                  return checkReceivedHasValues(params.data.edited, "", errorClass(field + data.glId));
                },
                editable: (params:any) => {
                  return params.data.edited
                },
                cellRenderer: 'RenderCell',
                cellEditor: 'InputCell',
                cellEditorParams: () => {
                  return {
                    onChange: onChangeGlOption,
                  }
                },
                cellRendererParams: (props: GenericInputCellProps<GlDisplayField>) => {
                  const { colDef, data } = props;
                  const field = colDef.field;
                  let errorMessage: string | undefined = undefined;
                  errorMessage = addErrorMessage(data, field?.toString());
                  return {
                    errorMessage
                  }
                  },
                }}
              suppressDragLeaveHidesColumns={true}
              rowData={glOptions}
              pagination={false}
              domLayout={'autoHeight'}
              gridAutoHeight={true}
              suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
              enableCellTextSelection={true}
              ensureDomOrder={true}
              rowSelection={'multiple'}
              suppressRowClickSelection={true}
              onSelectionChanged={(e: SelectionChangedEvent) => {
                setSelectedGlOption(
                  e.api.getSelectedRows().map((o) => o.glId)
                );
              }}

              frameworkComponents={{
                InputCell,
                RenderCell
              }}

              disableStaticMarkup={true}
              singleClickEdit={true}
            />
          </div> </> : <div>No entries found</div>}
    </ConfigurationTemplate>
    : <></>
  );
}

export const getInputCell = <T,>(attributes: GenericAttribute<T>[], 
  validate: (attribute?: GenericAttribute<T>, value?: any, validOnly?: boolean, data?: T) => boolean, 
  errorClass: (key: string) => "" | "invalid-field", 
  getErrorMessage : (key: string) => string, organizationPath?: string  
) => {

  return forwardRef((params: GenericInputCellProps<T>, ref) => {
    const { rowIndex, colDef, onChange, data, onBlurCallback, originateFrom } = params;
    const field = colDef.field as keyof T;
    const [value, setValue] = useValueRef(params.value, ref);
    const refInput = useRef<HTMLInputElement>(null);
  
    const rowData = params.agGridReact.props.rowData;
    const attribute = attributes.find(a => a.name === field);
    
    const onBlur = () => {
      validate(attribute, value, false, data);
      if (organizationPath) {
        const updatedData = [...rowData];
        updatedData[rowIndex] = {
          ...updatedData[rowIndex],
          [field]: value
        };

        onBlurCallback && onBlurCallback(rowIndex, field, value, rowData);
      }
    }
  
    const onChangeCellInput = (inputValue: string) => {
      colDef.field && onChange(rowIndex, field, inputValue, rowData);
      setValue(inputValue);
    }
  
    return (<>
      {attribute ?
        <TextBox
          value={value}
          attribute={attribute}
          onChange={onChangeCellInput}
          onBlur={() => onBlur()}
          inputRef={refInput}
          noLabel={true}
          id={`id_${String(attribute.name)}`}
          originateFrom={originateFrom ?? "GLListManager"}
        /> : null}
      <div className={errorClass(field?.toString() + data.glId)}>{getErrorMessage(field?.toString() + data.glId)}</div>
    </>
    );
  });
}

export const getRenderCell = (errorClass: (key: string) => "" | "invalid-field", getErrorMessage : (key: string) => string) => {
  return <T,>(params: ValidatedRenderCell<T>) => {
    const { data, value, colDef } = params;
    const field = colDef.field as keyof T;

    return (data.edited ?
      <><div className={`eds-field_#control eds-field_.eds-input eds-input`} >
        <input
          className={'eds-input_#input'}
          value={value}
          style={{ width: '100%' }}
        />

      </div>
        <div className={errorClass(field?.toString() + data.glId)}>{getErrorMessage(field?.toString() + data.glId)}</div>
      </> : <div>{value}</div>
    );
  };
}

const processFileRows = (organizationPath: string, rowsArray: any[], glOptions: GlDisplayField[], results: any, dispatch: Dispatch<any>) => {
  const compareArrays = (a:any, b:any) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  if(!compareArrays(rowsArray[0], ['GL Code', 'GL Item Description', 'Email', 'Unit Cost'])){
    dispatch(setAlert({
      id: AlertIds.PaymentConfiguration,
      message: "Invalid Rows and the Row Should be 'GL Code', 'GL Item Description', 'Email', 'Unit Cost'",
      type: AlertTypes.Error
    }))
    return;
  }
  if (organizationPath) {
    const glFields = [...glOptions];
    results.data.forEach((obj:any) => {
      glFields.push({
        amount: 0,
        glId: uuid(),
        glNumber: obj['GL Code'],
        description: obj['GL Item Description'],
        email: obj['Email'],
        unitCost: obj['Unit Cost']
      })
    });
    dispatch(setGlFields({ organizationPath, glFields }));
  }
}

const displayRemovePopUp = (onRemoveRowClick: () => void, setIsRemovePopupOpen: Dispatch<SetStateAction<boolean>>, isRemovePopupOpen?: boolean, selectedGlOption?: string[]) => {
  return (
    isRemovePopupOpen ? 
      <Popup
          header="PaymentSafe®"
          onClose={() => { setIsRemovePopupOpen(false) }}
          title="Remove Item(s)"
          footer={[
            {
              type: EnumPopupButtonsType.cancel,
              style: EnumPopupButtonsStyle.secondary,
              text: 'Cancel',
            },
            {
              type: EnumPopupButtonsType.submit,
              style: EnumPopupButtonsStyle.primary,
              text: 'Remove',
            },
          ]}
          onSubmit={selectedGlOption && onRemoveRowClick}
          customClassNames={{ footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
        >
          <div className="popupContent">
            <div className="rowItemPopup">
              Are you sure, you want to delete the selected item(s)?
            </div>
          </div>
      </Popup> : <></> 
    );
}

