import { PaymentPanelDataType, PaymentPanelFieldType, PaymentPanelType } from "models/enums/EnumPaymentPanels";
import { PatientInfoModel } from "../features/patientInfo/PatientInfoReducer";
import { GenericAttribute } from "../models/metaData/MetaData";
import { callHttpApi } from "./callHttpApi";

export interface PatientPanelApiConfigurationFieldSetting {
  label?: string,
  saveAs?: keyof PatientInfoModel,
  dataType?: PaymentPanelDataType,
  fieldType?: PaymentPanelFieldType,
  fieldTypeOnAddAccount:PaymentPanelFieldType,
  visible?: boolean,
  cssStyles?: string,
  format?: string,
  maxLength?: number,
  regex?: string,
  required?: boolean,
  validationErrorMessage?: string,
  placeholderText?: string,
  isDefaultField?: boolean
}

export interface PatientPanelApiConfiguration {
  id?: string,
  organization?: {
    id: string,
    name: string,
    path: string
  },
  type?: PaymentPanelType,
  isDraft?: boolean,
  panelTitle?: string,
  colorCode?: string,
  addRowEnabled?:boolean,
  fieldSettings?: PatientPanelApiConfigurationFieldSetting[],
  attributes?: GenericAttribute<PatientInfoModel>[];
}

export async function getPatientPanelConfigurationService(isDraft: boolean, organizationPath: string) {
  const params = new URLSearchParams();
  params.append('organizationPath', organizationPath)
  params.append('isDraft', isDraft.toString())

  return callHttpApi<PatientPanelApiConfiguration>(
    'get',
    '/api/v1/PatientSearchPanel/ForOrganization',
    undefined,
    params
  );
}

export async function updatePatientPanelConfigurationService(configuration?: PatientPanelApiConfiguration) {
  return callHttpApi<PatientPanelApiConfiguration, PatientPanelApiConfiguration>(
    'put',
    '/api/v1/PatientSearchPanel',
    configuration,
  );
}

export async function updatePatientPanelConfigurationDraftService(configuration?: PatientPanelApiConfiguration) {
  return callHttpApi<PatientPanelApiConfiguration, PatientPanelApiConfiguration>(
    'put',
    'api/v1/PatientSearchPanel',
    configuration,
  );
}
