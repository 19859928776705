import { useState } from 'react';
import { EDS_Button, EDS_Checkbox } from '@EH/eh.eds.react';
import { PatientMailingInformation } from 'features/paymentInfo/PatientMailingInformationReducer';
import { PaymentsOnFile } from 'features/paymentPlan/PaymentsOnFile';
import { PaymentPlanModel } from 'models';
import { ApiTenderTypeEnum } from 'utils/Utils';

export function TenderWallet(props: {
  isPlan?: boolean;
  paymentPlan?: PaymentPlanModel;
  tenderType?: string | number;
  paymentMethods: { optionName: string; value: string; }[];
  patient: any;
  patientMailingInformation: PatientMailingInformation;
  handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const {
    isPlan,
    paymentPlan,
    tenderType,
    paymentMethods,
    patient,
    patientMailingInformation,
    handleCheckBoxChange,
  } = props;

  const [showPaymentsOnFile, setShowPaymentsOnFile] = useState(false);

  const invalidDisplayTenderTypes = [ApiTenderTypeEnum.SavedOnFile, ApiTenderTypeEnum.Cash,ApiTenderTypeEnum.MoneyOrder,ApiTenderTypeEnum.PaperCheckAsECheck,ApiTenderTypeEnum.Undefined];
  const showSaveToWallet = tenderType && !invalidDisplayTenderTypes.some(t => t === tenderType);

  const invalidDisplayManageWallet = [ApiTenderTypeEnum.Cash,ApiTenderTypeEnum.MoneyOrder,ApiTenderTypeEnum.PaperCheckAsECheck,ApiTenderTypeEnum.Undefined];
  const showManageWalletButton = tenderType && !invalidDisplayManageWallet.some(t => t === tenderType) && paymentMethods?.length > 1;

  return (
    <div className="flex-row">
      {showManageWalletButton &&(
        <EDS_Button
          buttonText="Manage Wallet"
          modifiers="eds-button-manage-wallet row-item row-item-size eds-button.basic"
          onClick={() => setShowPaymentsOnFile(true)}
          ariaLabel="Manage Wallet"
        />
      )}
      {showSaveToWallet && (
        <EDS_Checkbox
          modifiers="eds-checkbox-save-to-wallet"
          name="saveToWallet"
          label="Save to Wallet"
          checked={isPlan ? paymentPlan?.tender?.isReusable : patientMailingInformation?.isReusable}
          onChange={handleCheckBoxChange}
        />
      )}
      {showPaymentsOnFile && (
        <PaymentsOnFile
          onClose={() => setShowPaymentsOnFile(false)}
          patient={patient}
          paymentInfo={isPlan ? undefined : patientMailingInformation}
        />
      )}
    </div>
  )
}