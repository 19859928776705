import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Config } from 'models/CreditCardConfigModel';
import {
  GetCreditCardConfig,
  GetCreditCardResponse,
} from 'services/CreditCardService';
import { AppThunk } from 'app/rootReducer';
import { callService } from 'services/ServicesReducer';

export interface State {
  err?: string;
  value?: Config;
}

export const initialState: State = {
  err: '',
  value: undefined,
};

const reducerSlice = createSlice({
  name: 'CreditCardConfig',
  initialState,
  reducers: {
      onReceiveCreditCardConfig(
      state,
      action: PayloadAction<GetCreditCardResponse>
    ) {
      state.value = action.payload.result;
      state.err = action.payload.err;
    },
    clearError(state) {
      state.err = '';
    },
  },
});

export const { 
  onReceiveCreditCardConfig,
  clearError,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function clearGetCreditCardConfigError(): AppThunk {
  return dispatch => {
    dispatch(clearError());
  };
}

export function getCreditCardConfig(
  organizationPath: string,
  onDone?: () => void
): AppThunk {
  return async (dispatch, _getState) => {
    dispatch(callService('GetCreditCardConfig', async () => {
      await GetCreditCardConfig(organizationPath).then(data => {
        dispatch(onReceiveCreditCardConfig(data));
        if (onDone) onDone();
      });
    }))
  };
}
