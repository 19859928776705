import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'app/rootReducer';
import { CheckBoxTree } from 'components/checkBoxTree/CheckBoxTree';
import { TreeFilterItem, setAdvanceSearchInformation } from '../AdvanceSearchReducer';
import { FilterCheckBoxes } from './FilterCheckBoxes';
import styles from '../advanceSearch.module.scss';

export function SideFilters(props: {
  transactionTypes: TreeFilterItem[];
  tenderTypes: TreeFilterItem[];
  cardEntryTypes: TreeFilterItem[];
}) {
  const { transactionTypes, tenderTypes, cardEntryTypes } = props;
  const advanceSearchInfo = useTypedSelector(s => s.advanceSearchInfo?.value || []);
  const dispatch = useDispatch();

  const transactionTypesCheckBoxTree: CheckBoxTree = {
    nodes: transactionTypes?.map(transactionType => ({
      ...transactionType,
    })) ?? [],
  };

  const tenderTypesCheckBoxTree: CheckBoxTree = {
    nodes: tenderTypes?.filter(tenderType => !tenderType.parent).map(tenderType => ({
      ...tenderType,
      children:
        tenderType.label === 'Credit/Debit'
          ? tenderTypes.filter(ccType => ccType.parent === tenderType.label)
          : undefined,
    })) ?? [],
  };

  const cardEntryTypesCheckBoxTree: CheckBoxTree = {
    nodes: cardEntryTypes?.map(cardEntryType => ({
      ...cardEntryType,
    })) ?? [],
  };

  function getItemsFromTree(tree: CheckBoxTree) {
    const items: TreeFilterItem[] = [];
    tree.nodes.forEach(node => {
      if (node.children) {
        node.children.forEach(child => items.push(child));
      }
      delete node.children;
      items.push(node);
    })

    return items;
  }

  return (
    <>
      <div className={styles.sideFiltersContainer}>
        <FilterCheckBoxes
          group="transactionTypes"
          groupDisplayName="*Transaction Types"
          displayClearButton={true}
          checkBoxTree={transactionTypesCheckBoxTree}
          setCheckboxTree={(newTree: CheckBoxTree) => { dispatch(setAdvanceSearchInformation({ ...advanceSearchInfo, transactionTypes: getItemsFromTree(newTree) })) }}
        />

        <hr />

        <FilterCheckBoxes
          group="tenderTypes"
          groupDisplayName="*Tender Types"
          displayClearButton={true}
          checkBoxTree={tenderTypesCheckBoxTree}
          setCheckboxTree={(newTree: CheckBoxTree) => {
            dispatch(setAdvanceSearchInformation({ ...advanceSearchInfo, tenderTypes: getItemsFromTree(newTree) }))
          }}
        />

        <hr />

        <FilterCheckBoxes
          group="cardEntryTypes"
          groupDisplayName="Card Entry Types"
          displayClearButton={false}
          checkBoxTree={cardEntryTypesCheckBoxTree}
          setCheckboxTree={(newTree: CheckBoxTree) => { dispatch(setAdvanceSearchInformation({ ...advanceSearchInfo, cardEntryTypes: getItemsFromTree(newTree) })) }}
        />
      </div>
    </>
  );
}
