import { ExternalAddress } from "../features/admin/epic/whitelist/WhitelistReducer";
import { callHttpApi } from "./callHttpApi";


export async function getAllExternalAdresses() {
  return callHttpApi<{ externalAddresses: ExternalAddress[] }>(
    'get',
    `/api/v1/Admin`,
  );
}export async function getMappedExternalAdresses(organizationPath: string) {
  return callHttpApi<ExternalAddress[]>(
    'get',
    `/api/v1/Admin/${organizationPath}/getexternaladdresses`,
  );
}

export async function addExternalAdresses(externalAddressRecord: ExternalAddress) {
  return callHttpApi<ExternalAddress, ExternalAddress>(
    'post',
    `/api/v1/Admin`,
    externalAddressRecord
  );
}
export async function updateExternalAdresses(externalAddressRecord: ExternalAddress) {
  return callHttpApi<ExternalAddress, ExternalAddress>(
    'put',
    `/api/v1/Admin`,
    externalAddressRecord
  );
}
export async function deleteExternalAdresses(id: string) {
  return callHttpApi<ExternalAddress, ExternalAddress>(
    'delete',
    `/api/v1/Admin/${id}/delete`,
  );
}