import { Popup } from "components/popup/Popup";
import MultiSelect, { MultiSelectOption } from "components/select/MultiSelect";
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";
import { useState } from "react";

export default (props: {
  close: () => void;
  onNext: (selectedClients: MultiSelectOption[]) => void;
  orgPath: string;
  currentSelectedClients: MultiSelectOption[] | undefined;
}) => {
  const { close, onNext, orgPath, currentSelectedClients } = props;
  const { useGetFacilities, getTradingPartnerByFacility, getOrganizationByPath } = useOrganizations();
  const selectedOrganization = getOrganizationByPath(orgPath);
  const tradingPartnerPath = getTradingPartnerByFacility(orgPath);
  const clientOptions = useGetFacilities(
    (organization) =>
        getTradingPartnerByFacility(organization.path) === tradingPartnerPath ||
        (isNotEmptyString(selectedOrganization!.accessPartnerGrouping) &&
        organization.accessPartnerGrouping === selectedOrganization?.accessPartnerGrouping))
    .map((o) => { return {label: (`${o.clientId} (${o.name})`).replace(/"/g, ''), value: o.path} });

  function isNotEmptyString(stringValue: string | undefined): boolean {
    return stringValue ? true : false;
  };

  const [ selectedClients, setSelectedClients ] = useState<MultiSelectOption[]>(currentSelectedClients ?? []);

  const choose = () => {
    onNext(selectedClients);
  };

  const footerButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.primary,
      text: 'Cancel',
      disabled:  false
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Select',
      disabled:  false
    }
  ];

  return (
    <Popup 
      header="PaymentSafe®"
      onClose={close}
      footer={footerButtons}
      onSubmit={choose}
      customClassNames={{footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
      >
        <div className="popupContent">
          <div className="rowItemPopup">         
                <div className="flexRow">
                <MultiSelect
                  label="Select Clients:"
                  name={"selectedClients"}
                  options={clientOptions}
                  multiple={true}
                  values={selectedClients}
                  onChange={ selectedOptions => { setSelectedClients(selectedOptions) }}
                />
              </div> 
          </div>              
        </div>
    </Popup>
    );
}