import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import { LineItem } from "../../../models/LineItem";
import { Attribute, Validator } from "../../../models/metaData/MetaData";
import { ValidatorType } from "../../../models/metaData/MetaDataEnums";
import { execute } from "../../paymentDashboard/Validators";
import { isEmpty } from 'validate.js';
import { addError, removeError } from "../PaymentDashboardReducer";

export function useLineItemValidator(attributes?: Attribute[]) {
  const dispatch = useDispatch();

  function validateAll(lineItem: LineItem, validOnly: boolean = false) {
    let valid = true;
    attributes && attributes
      .filter(a => a.name !== 'isActive' && a.name !== 'isEditing')
      .forEach(attribute => {
        const attributeName = attribute.name;
        let result = validate(attribute, lineItem[attributeName], validOnly, lineItem);
        valid = valid && result;
      });
    return valid;
  }

  function coreValidation(attr?: Attribute, lineItem?: LineItem) {
    if (!attr) return false;

    if (!attr.validators) return true;

    if (!attr.format) {
      dispatch(removeError({ id: lineItem?.id, key: attr.name, validatorType: ValidatorType.FormatValidator }));      
    }

    if (!attr.regex) {
      dispatch(removeError({ id: lineItem?.id, key: attr.name, validatorType: ValidatorType.RegularExpressionValidator }));     
    }

    if (!attr.required) {
      dispatch(removeError({ id: lineItem?.id, key: attr.name, validatorType: ValidatorType.RequiredValidator }));     
    }

    return true;
  }

  const validate = (attr?: Attribute, value?: any, validOnly: boolean = false, lineItem?: LineItem) => {
    let valid = coreValidation(attr, lineItem);

    attr?.validators?.forEach(validatorProp => {
      
      let validator = { ...validatorProp };
      const dataType = attr.dataType;
      if (attr.fieldTypeOnAddAccount === 'Calendar' && validatorProp.name === ValidatorType.RequiredValidator) {
        return;
      }

      const onPass = () => {
        dispatch(removeError({ id: lineItem?.id, key: attr.name, validatorType: validator.name }));
      };
      const onFail = (message?: string) => {
        valid = false;
        if (validOnly) return valid;
        dispatch(addError({
          id: lineItem?.id || '',
          error: {
            [attr.name]: { [validator.name]: message || '' }
          }
        }))
      }  
      
      validator = setValidator(validator, lineItem);

      execute({
        validator,
        value,
        dataType,
        onPass,
        onFail,
        entity: lineItem
      })
    });
    return valid;
  }

  const errorsState = useTypedSelector(s => s.paymentDashboard.errors);

  function getErrorState(lineItem?: LineItem,){
    return errorsState[lineItem?.id || ''];
  }

  function hasErrors(lineItem?: LineItem,) {
    return getErrorState(lineItem) && !isEmpty(Object.keys(errorsState));
  }

  function setValidator(validator : Validator, lineItem?: LineItem) {
    if (validator.name === ValidatorType.RangeValidator) {
      const message = validator.message ?? "Out of range";

      let maxKeyValueMap, minKeyValueMap;
      if (lineItem) {
        minKeyValueMap = Object.entries(lineItem).find((entry) => validator.value?.minFrom === entry[0]);
        maxKeyValueMap = Object.entries(lineItem).find((entry) => validator.value?.maxFrom === entry[0]);
      }
      
      validator = {
        ...validator,
        value: {
          min: minKeyValueMap ? minKeyValueMap[1] : validator.value.min,
          max: maxKeyValueMap ? maxKeyValueMap[1] : validator.value.max
        }
      }

      validator.message = message.replace('{min}', validator.value.min).replace('{max}',validator.value.max);
    }

    if (validator.name === ValidatorType.RequiredValidator) {
      validator.message = validator.message ?? 'Required';
    }

    if (validator.name === ValidatorType.DecimalValidator) {
      validator.message = validator.message ?? 'Format should be 12 digits before decimal point and 2 digits after';
    }

    return validator;
  }

  return { validate, validateAll, hasErrors }
}