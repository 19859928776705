import { useTypedSelector } from "app/rootReducer";
import PaymentPlanModel from "models/PaymentPlan";

export function useGetPaymentPlanById(id?: string | null) {
  let paymentPlanIdx = useTypedSelector(s =>
    s.paymentPlanInfo?.records?.findIndex(
      (plan: PaymentPlanModel) => {
        return plan.id == id
      }
    ));

  let paymentPlan = useTypedSelector(s => {
    if (paymentPlanIdx != undefined) {
      return (s.paymentPlanInfo?.records || [])[paymentPlanIdx]
    }
  });

  return { paymentPlan, paymentPlanIdx };
}
