
export function Hidden(props: {
  label?: string;
  modifiers?: string;
  isPreview?: boolean;
  isPaymentOrPreviewPage?: boolean
}) {
  const { isPaymentOrPreviewPage = false, label } = props;

  return (
    <>
      <div className= { !isPaymentOrPreviewPage ? 'hidden-field' : 'hidden-field-hide' }>
        <p className={'line-item-attribute-label custom-eds-label'}>{label ?? ''}</p>
        <p className='hidden-field-static'>-</p>
        <div className={'hidden-field-message'}>
          <p>Invisible on Live Page</p>
        </div>
      </div>     
    </>   
  );
}
