import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../app/rootReducer';
import { useCallService } from '../../../services/useCallService';
import { getPaymentConfiguration } from '../../../services/TransactionsService';
import { setConfiguration } from '../../paymentDashboard/PaymentDashboardReducer';
import { v4 as uuid } from 'uuid';

export function useGetPaymentConfiguration(
  organizationPath?: string,
  onError?: (error?: string) => void,
  onSuccess?: () => void
) {
  const dispatch = useDispatch();
  const callService = useCallService();

  const configurationPair: any = useTypedSelector(s => {
    if (!organizationPath) return;
    return s.paymentDashboard?.configurations[organizationPath];
  });

  const getConfiguration = async (inherited: boolean = true) => {
    if (!organizationPath) return;
    const oneHour = 1 * 60 * 60 * 1000;
    if (
      !(
        configurationPair?.date &&
        configurationPair.date + oneHour >= Date.now()
      ) ||
      configurationPair.reload
    ) {
      const response = await callService("GetPaymentConfiguration", () => getPaymentConfiguration(organizationPath, inherited));

      if (response.err || !response.result) {
        onError && onError(response.err);
        return;
      }
      const configuration = response.result.paymentConfiguration;
      dispatch(
        setConfiguration({
          organizationPath,
          configuration: {
            ...configuration,
            glDisplayFields: configuration.glDisplayFields?.map(g => ({
              ...g,
              glId: g.glId ?? uuid()
            }))
          }
        })
      );
    }
    onSuccess && onSuccess();
  };
  return { getConfiguration };
}
