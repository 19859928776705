import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import ConfigurationTemplate from "../../../features/admin/clientConfiguration/ConfigurationTemplate";
import { useTypedSelector } from "../../../app/rootReducer";
import { useWhitelistManagerValidator } from "../../../features/admin/epic/whitelist/useWhitelistManagerValidator";
import { useOrganizations } from "../../../features/organizations/hooks/useOrganizations";
import * as router from 'react-router-dom';
import { EditableExternalAddress, setExternalAddress, setExternalAddressIsEditing } from "../../../features/admin/epic/whitelist/WhitelistReducer";
import { GenericInputCellProps } from "../Remit/RemitManagement";
import { useEffect, useRef, useState } from "react";
import { useGetExternalAddresses } from "../../../features/admin/epic/whitelist/useGetExternalAddresses";
import { ReactComponent as AddIcon } from 'assets/svgs/admin/whitelistManager/circle-plus-icon.svg';
import { ReactComponent as Edit } from 'assets/svgs/admin/glListManager/edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/svgs/admin/whitelistManager/circle-minus.svg';
import { ReactComponent as AddMappingIcon } from 'assets/svgs/admin/whitelistManager/add-mapping.svg';
import { ReactComponent as Delete } from 'assets/svgs/delete.svg';
import { useDispatch } from "react-redux";
import { OrganizationLevelDocument } from "../../../models/OrganizationLevelDocument";
import "assets/styles/components/_whitelistManager.scss";
import { useExternalAddress } from "../../../features/admin/epic/whitelist/useExternalAddress";
import { ICellRendererParams } from "ag-grid-community";
import { ChangeDetectionStrategyType } from "ag-grid-react/lib/changeDetectionService";
import TextBox from "../../../features/componentFactory/wrapperComponents/TextBox";
import { GenericAttribute } from "../../../models/metaData/MetaData";
import { useRefStore } from "../../../features/admin/epic/whitelist/useRefStore";
import { Button } from "@EHDS/core";
import { v4 as uuid } from 'uuid';

export default function WhitelistManager() {
  const dispatch = useDispatch();
  let { clientId } = router.useParams<{ clientId: string }>();
  const { getOrganizationById } = useOrganizations();
  const selectedOrganization = getOrganizationById(clientId);

  const urlList = useTypedSelector(state => state.whitelistManager.externalAddresses);
  const { attributes, errorClass, getErrorMessage, validateAll } = useWhitelistManagerValidator();
  const { getExternalAdresses } = useGetExternalAddresses();
  const { saveExternalAddress, deleteExternalAddress } = useExternalAddress(() => { commitWorkingCopies()});

  const addNewExternalAddress = () => {
    dispatch(setExternalAddress({
      externalAddress: {
        isActive: true, mappedOrganizations: [], url: "", metadata: {
          isEditing: true,
          tempId: uuid(),
        }
      },
      unshift: true
    }));
  }

  useEffect(() => {
    getExternalAdresses();
  }, []);

  useEffect(() => {
    const columnDef = gridRef?.current?.api?.getColumnDef("url");
    if (columnDef) {
      columnDef.cellRendererParams = urlCellRendererParams;
    }
  }, [validateAll])

  const gridRef = useRef<AgGridReact>(null);

  const { storeExternalAddress, commitWorkingCopies } = useRefStore();

 const urlCellRendererParams = () => {
    return {
      saveExternalAddress,
      attribute: attributes.find(a => a.name === "url"),
      storeExternalAddress,
      commitWorkingCopies,
      validateAll,
      getErrorMessage
    }
  }

  const newEntry = urlList.findIndex(ea => !ea.id);

  return (
    <ConfigurationTemplate
      title={'External Whitelist Manager'}
      headerButtons={
        <div className={"header-buttons"} onClick={() => {
          if (newEntry == -1) {
            addNewExternalAddress()
          }
        }
        }>
          <div className="header-button">
            <AddIcon className="header-icon-secondary"/>
            <span className="text"> Add External Address </span>
          </div>
        </div>
      }
    >
      {urlList?.length ? <div className="ag-theme-alpine default-checkboxes">
        <AgGridReact
          ref={gridRef}
          getRowNodeId={(data: any) => data.id}
          rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
          rowHeight = {50}
          columnDefs={[{
            headerName: "",
            field: 'id',
            cellRenderer: 'StartEditingCell',
            cellRendererParams: () => {
              return { commitWorkingCopies }
            },
            width: 60,
          },
          {
            headerName: "External Address",
            field: 'url',
            editable: false,
            cellRenderer: "EditCell",
            cellRendererParams: urlCellRendererParams,
            suppressSizeToFit:true,
            resizable: false,
            flex: 1,
          },
          {
            headerName: "",
            field: 'id',
            cellRenderer: 'ActionsCell',
            cellRendererParams: () => {
              return {
                saveExternalAddress,
                organization: selectedOrganization,
                commitWorkingCopies
              }
            },
            width: 200,
          },
          {
            headerName: "",
            field: 'id',
            cellRenderer: 'DeleteCell',
            cellRendererParams: () => {
              return {
                deleteExternalAddress,
                commitWorkingCopies
              }
            },
            width: 150,
          }]}
          defaultColDef={{
            suppressMovable: true,
            sortable: false,
            resizable: false,
            filter: false,
            cellClass: (params: any) => {
              const { data, colDef } = params;
              const field = colDef.field as keyof EditableExternalAddress;
              return params.data.edited ? "" : errorClass(field + data.glId);
            },
            editable: false,
            cellRendererParams: (props: GenericInputCellProps<EditableExternalAddress>) => {
              const { colDef, data } = props;
              const field = colDef.field;
              let errorMessage: string | undefined = undefined;
              if (field) {
                errorMessage = getErrorMessage(field + data.glId);
              }
              return {
                errorMessage
              }
            },
            suppressKeyboardEvent: (params: any) => true 
          }}
          suppressDragLeaveHidesColumns={true}
          rowData={urlList}
          pagination={false}
          domLayout={'autoHeight'}
          gridAutoHeight={true}
          suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
          enableCellTextSelection={true}
          ensureDomOrder={true}

          suppressRowClickSelection={true}
          suppressCellSelection={true}

          

          frameworkComponents={{
            StartEditingCell,
            ActionsCell,
            DeleteCell,
            EditCell,
          }}

          disableStaticMarkup={true}
        />
      </div> : <div className="no-data">No data available</div>}
    </ConfigurationTemplate>
  );
}

export interface GenericEditCellProps<T> extends ICellRendererParams {
  
  saveExternalAddress: (data: T, index: number) => {};
  data: T,
  attribute: GenericAttribute<T>,
  storeExternalAddress: (
    idx: number,
    value: EditableExternalAddress,
  ) => void,
  commitWorkingCopies: () => void,
  validateAll: (validOnly: boolean, currentData: T, idx: number) => boolean,
  getErrorMessage: (key: string)=> string
}

export function EditCell(params: GenericEditCellProps<EditableExternalAddress>) {
  const { value, attribute, data, saveExternalAddress, storeExternalAddress, commitWorkingCopies, rowIndex, validateAll, getErrorMessage , } = params;
  const [inputValue, setInputValue] = useState<string>(value);

  return data.metadata?.isEditing ? <div className={"url-edit"} >
    <TextBox
      value={inputValue}
      attribute={attribute}
      onChange={value => {
        setInputValue(value);
        storeExternalAddress(rowIndex, { ...data, url: value });
      }}
      noLabel={true}
      error={getErrorMessage("url" + (data?.id ?? data?.metadata?.tempId ?? ""))}
    />
    <Button
      className={"update-button"}
      onClick={() => {
      const updated = { ...data, url: inputValue };
      
      if (validateAll(false, updated, rowIndex)) {
        saveExternalAddress(updated, rowIndex);
      } else {
        setInputValue("");
        window.setTimeout(() => setInputValue(updated.url), 0);
      }
      commitWorkingCopies();
    }}>
      Update
    </Button>
  </div> : value
}

export function StartEditingCell(params: GenericEditCellProps<EditableExternalAddress>) {
  const { data, commitWorkingCopies } = params;
  const dispatch = useDispatch();
  return (
    <div onClick={() => {

      dispatch(setExternalAddressIsEditing({
        id: data.id ?? "",
        isEditing: true
      }))
      commitWorkingCopies();
    }}>
      <Edit />
    </div>
  );
}

export interface GenericActionsCellProps<T> extends ICellRendererParams {
  isEditor: boolean;
  saveExternalAddress: (data: T, index: number, messages?: {
    success: string,
    error: string
  }) => {};
  data: T,
  agGridReact: {
    props: {
      rowData: T[];
    };
  };
  organization: OrganizationLevelDocument;
  dataRef: React.MutableRefObject<T[]>;
  commitWorkingCopies: () => void;
}

export function ActionsCell(
  params: GenericActionsCellProps<EditableExternalAddress>,
) {
  const { data, organization, saveExternalAddress, rowIndex, commitWorkingCopies } = params;

  const isMappedToCurrentFacility = data.mappedOrganizations.find(o => o.organizationReference?.path === organization?.path)
  return <div onClick={() => {
    saveExternalAddress(flipMapping(data, organization), rowIndex, {
      success: isMappedToCurrentFacility ? "External address removed successfully" : "External address added successfully",
      error: isMappedToCurrentFacility ? "Could not remove external addess" : "Could not add external address"
    })
    commitWorkingCopies();
  }
  }>
    {isMappedToCurrentFacility ?
      <div className="header-button">
        <RemoveIcon />
        <span className="title-button-text">Remove Mapping</span>
      </div> :
      <div className="header-button">
        <AddMappingIcon />
        <span className="title-button-text">Map to Facility</span>
      </div>}
  </div>;
}

export function flipMapping(externalAddress: EditableExternalAddress, organization: OrganizationLevelDocument) {
  const mappedOrganizations = [...externalAddress.mappedOrganizations ?? []];
  const idx = mappedOrganizations.findIndex(o => o.organizationReference?.path === organization?.path);
  if (idx !== -1) {
    mappedOrganizations.splice(idx, 1);
  } else {
    mappedOrganizations.push({ organizationReference: organization });
  }
  return {
    ...externalAddress,
    mappedOrganizations
  };
}


export interface GenericDeleteCellProps<T> extends ICellRendererParams {
  deleteExternalAddress: (id: string, idx: number) => void;
  commitWorkingCopies: () => void;
}
export function DeleteCell(
  params: GenericDeleteCellProps<EditableExternalAddress>,
) {
  const { value, deleteExternalAddress, commitWorkingCopies, rowIndex } = params;
  return (
    <div onClick={() => {
      deleteExternalAddress(value, rowIndex);
      commitWorkingCopies();
    }}>
      <div className="header-button">
        <Delete />
        <span className="title-button-text">Delete</span>
      </div>
    </div>
  );
}