import { PaymentPlanModel } from '../../models';
import { LineItem } from '../../models/LineItem';
import { mockTermsOptions, TermTypeOption } from './PaymentPlanReducer';
import { PatientMailingInformation } from "../paymentInfo/PatientMailingInformationReducer";
import * as PatientMailingInformationReducer from "../paymentInfo/PatientMailingInformationReducer";
import { EnumCheckAccountType } from 'models/enums';
import { ApiTenderTypeEnum, getValueOrDefault } from 'utils/Utils';
import { EnumTermType } from 'models/enums/EnumPaymentPlan';
import { PatientModel, PaymentOnFile } from 'models/PatientModel';
import EnumTransactionTenderType from 'models/enums/EnumTransactionTenderType';
import { getPaymentAmountBalance } from './useTotal';
import moment from 'moment';

export function findTermTypeOptionById(termType?: string): TermTypeOption | undefined {
  return mockTermsOptions.find(({ value }) => value === termType);
}

export function displayTermType(
  numberOfPayments: number,
  termType?: string
): string | undefined {
  const currentTermType = findTermTypeOptionById(termType);
  if (currentTermType) {
    return (
      findTermTypeOptionById(termType)?.display +
      (numberOfPayments && numberOfPayments > 1 ? 's' : '')
    );
  }

  return '';
}

export function distributeLineItemsToPlans(props: {
  lineItems?: LineItem[];
  plans?: PaymentPlanModel[];
  allowPAHSync?: boolean;
  facility?: string;
}) {
  const { lineItems = [], plans = [], allowPAHSync = false } = props;
  let lineItemsMap = updateLineItemsMap(lineItems);
  let updatedPlans = updatePlans(plans, lineItemsMap, allowPAHSync);
  let updatedLineItems = updateLineItems(lineItemsMap);
  
  return { lineItems: updatedLineItems, plans: updatedPlans };
}

const updateLineItemsMap = (lineItems: LineItem[]): { [key: string]: LineItem } => {
  let lineItemsMap: { [key: string]: LineItem } = {};
  lineItems.forEach(lineItem => {
    if (lineItem.accountNumber) {
      lineItemsMap[lineItem.accountNumber] = lineItem;
    }
  });
  return lineItemsMap;
}

const updatePlanLineItems = (plan: PaymentPlanModel, lineItemsMap: { [key: string]: LineItem }, allowPAHSync: boolean): void => {
  plan.lineItems?.forEach((planLineItem, index) => {
    if (!allowPAHSync) return;
    if (!planLineItem.accountNumber || !plan.lineItems) return;
    const lineItem = lineItemsMap[planLineItem.accountNumber] ?? plan.lineItems[index];
    plan.lineItems[index] = { ...lineItem };
    delete lineItemsMap[planLineItem.accountNumber];
  });
}

const updatePlanBalance = (plan: PaymentPlanModel): void => {
  plan.balance = plan.lineItems?.reduce((totalBalance, lineItem) => {
    return totalBalance + lineItem.balance!;
  }, 0);
}

const updatePlans = (plans: PaymentPlanModel[], lineItemsMap: { [key: string]: LineItem }, allowPAHSync: boolean): PaymentPlanModel[] => {
  const updatedPlans: PaymentPlanModel[] = [];
  plans.forEach(plan => {
      updatePlanLineItems(plan, lineItemsMap, allowPAHSync);
      updatePlanBalance(plan);
      updatedPlans.push(plan);
  });
  return updatedPlans;
}

const updateLineItems = (lineItemsMap: { [key: string]: LineItem }): LineItem[] => {
  const updatedLineItems: LineItem[] = [];
  Object.values(lineItemsMap).forEach(lineItem => {
    updatedLineItems.push(lineItem);
  });
  return updatedLineItems;
}

export function map<I, Diff>(input: I, diff: (b: I) => Diff) {
  return {
    ...input,
    ...diff(input),
  };
}

export const convertCurrencyToNumber = (value: string | number) => {
  let string = value.toString().replace(/,/g, '');
  let number: number = parseFloat(string);
  return number;
};

export function distributePlanToPatientMailingInformation(props: {
  plan?: PaymentPlanModel;
  mailingInformation?: PatientMailingInformation;
}) {
  const { plan, mailingInformation } = props;
  const patientMailingInformation = {
    ...PatientMailingInformationReducer.initialState.value,
    ...mailingInformation,
    billingFirstName: getValueOrDefault(mailingInformation?.firstName, plan?.billingInformation?.firstName),
    billingLastName: getValueOrDefault(mailingInformation?.lastName, plan?.billingInformation?.lastName),
    billingAddressLine1: getValueOrDefault(mailingInformation?.billingAddressLine1, plan?.billingInformation?.addressLine1),
    billingAddressLine2: getValueOrDefault(mailingInformation?.billingAddressLine2, plan?.billingInformation?.addressLine2),
    billingCity: getValueOrDefault(mailingInformation?.billingCity, plan?.billingInformation?.city),
    billingState: getValueOrDefault(mailingInformation?.billingState, plan?.billingInformation?.state),
    billingPhoneNumber: getValueOrDefault(mailingInformation?.billingPhoneNumber, plan?.billingInformation?.phone),
    billingZipCode: getValueOrDefault(mailingInformation?.billingZipCode, plan?.billingInformation?.zipCode),
    firstName: mailingInformation?.firstName ? mailingInformation?.firstName : plan?.mailingInformation?.firstName,
    lastName: mailingInformation?.lastName ? mailingInformation?.lastName : plan?.mailingInformation?.lastName,
    addressLine1: plan?.mailingInformation?.addressLine1,
    addressLine2: plan?.mailingInformation?.addressLine2,
    city: plan?.mailingInformation?.city ?  plan?.mailingInformation?.city : mailingInformation?.city,
    mailingState: plan?.mailingInformation?.state,
    phoneNumber: plan?.mailingInformation?.phone,
    zipCode: plan?.mailingInformation?.zipCode,
    notificationEmail: plan?.notificationEmail,
    cardHolderName: plan?.billingInformation?.firstName + ' ' + plan?.billingInformation?.lastName 
  };
  return { patientMailingInformation };
}

export function mapCheckAccountType(
  accountType: string | undefined
): EnumCheckAccountType {
  switch (accountType) {
    case 'savings':
      return EnumCheckAccountType.Savings;
    case 'checking':
      return EnumCheckAccountType.Checking;
    default:
      return EnumCheckAccountType.Unknown;
  }
}

export function getTermTypePeriods(termType: string | EnumTermType) {  
  const termTypePeriods = {
    [String(EnumTermType.None)]: { duration: 'weeks', count: 0 },
    [String(EnumTermType.Weekly)]: { duration: 'weeks', count: 1 },
    [String(EnumTermType.BiWeekly)]: { duration: 'weeks', count: 2 },
    [String(EnumTermType.Monthly)]: { duration: 'months', count: 1 },
    [String(EnumTermType.Quarterly)]: { duration: 'months', count: 3 },
    [String(EnumTermType.Annually)]: { duration: 'years', count: 1 },
  };
  return termTypePeriods[termType];
}

export function getTenderTypeFromPaymentOnFile(paymentOnFile?: PaymentOnFile) {
  const mapTenderType = {
    [String(EnumTransactionTenderType.Card)]: ApiTenderTypeEnum.CardManual,
    [String(EnumTransactionTenderType.eCheck)]: ApiTenderTypeEnum.ECheck,
  };
  
  const tenderType = paymentOnFile?.tenderType?.toString();
  return (tenderType && mapTenderType[tenderType]) || ApiTenderTypeEnum.Undefined;
}

export function getTenderType(paymentPlan?: PaymentPlanModel, patient?: PatientModel) {
  if (paymentPlan?.tender?.type == ApiTenderTypeEnum.SavedOnFile) {
    let tokenId = paymentPlan?.tender?.method;
    if (!!tokenId) {
      tokenId = paymentPlan?.walletId;
    }
    return getTenderTypeFromPaymentOnFile(
      patient?.paymentsOnFile?.find(p => p.tokenId == tokenId)
    );
  }

  return paymentPlan?.tender?.type || paymentPlan?.tenderType || ApiTenderTypeEnum.Undefined;
}

export const getRowValue = (lineItem: LineItem, attributeName: keyof LineItem, isGL:boolean) => {
  switch(attributeName) {
    case 'dateOfService':
      return moment.utc(lineItem.dateOfService).format('MM/DD/YYYY')
    case 'accountNumber':
      return getValueOrDefault(lineItem.accountNumber, '');
    case 'amount':
      return getPaymentAmountBalance({ ...lineItem, isActive: true }, isGL).toFixed(2);
    default:
      return lineItem[attributeName];
  }
}