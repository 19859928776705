import { useDispatch } from "react-redux";
import { useGetPatientById } from "../../patients/hooks/useGetPatientById";
import { distributeLineItemsToPlans, map } from "../PlanUtils";
import { useGetPlansByPatientId } from "./useGetPlansByPatientId";
import { useLineItems } from './useLineItems';
import { addBackup, replaceRecords as replacePlans } from "../PaymentPlanReducer";
import { updatePlans as updatePatientRecordPlans } from "features/patients/PatientsReducer";
import { replaceLineItems } from "../../paymentDashboard/PaymentDashboardReducer";
import { ConvertPaymentPlanDates } from "../ConvertPaymentPlanDates";
import { PlanToPatientLineItemMap } from "../maps/PlanToPatientLineItemMap";
import { LineItemSpecific } from "../../../models/LineItem";
import { PatientEncounterModel } from "../../../models/PatientEncounterModel";
import { useTypedSelector } from "../../../app/rootReducer";
import { useSortLineItems } from "./useSortLineItems";
import { v4 as uuid } from 'uuid';
import { useState } from "react";

export function useEncounters(patientId?: string, organizationPath?: string, clientId?: string, accountNo?: string) {
  const gettingPlans = useTypedSelector(s => s.services.calls.getPaymentPlansByPatientId?.isProcessing);
  const [gettingLineItems, setGettingLineItems] = useState<boolean>(false);
  const { getPlans } = useGetPlansByPatientId(patientId, organizationPath);
  const { lineItems, retrieve: retrieveLineItems, isProcessing } = useLineItems({ patientId, organizationPath, clientId, accountNo });
  const { patient } = useGetPatientById(patientId);
  const { getPaymentPlansWithLocalDates } = ConvertPaymentPlanDates();
  const dispatch = useDispatch();
  const { sortLineItems } = useSortLineItems(organizationPath);

  const fetchEncounters = async (preloadedLineItems?: PatientEncounterModel[], allowPAHSync?: boolean) => {
    setGettingLineItems(true);
    let [lineItems, plans] = await Promise.all([
      retrieveLineItems(preloadedLineItems),
      getPlans()
    ]);

    if (lineItems?.length) {
      for(const lineItem of lineItems) {
        lineItem.pahAccount = true;
      }
    }

    if (patientId && patient) 
    {
      plans = getPaymentPlansWithLocalDates(plans);
      let mappedLineItems = lineItems?.map(l => { return map<PatientEncounterModel, LineItemSpecific>(l, PlanToPatientLineItemMap) });
      
      mappedLineItems?.map((item)=>{
        if(item.id ==='' || item.id === undefined){
          item.id= uuid();
        }
      });
      let updated = distributeLineItemsToPlans({ plans, lineItems: mappedLineItems, allowPAHSync});

      dispatch(replaceLineItems( { lineItems: sortLineItems(updated.lineItems), organizationPath: organizationPath ?? '' }));
      dispatch(replacePlans(updated.plans));
      const patientPlans = updated.plans.map(plan => {
        dispatch(addBackup(plan));
        return plan.id
      });
      dispatch(updatePatientRecordPlans({ patientId: patientId, organizationPath : organizationPath ?? '', patientPlans: patientPlans }));
    }
    setGettingLineItems(false);
  };

  return {
    isProcessing: gettingPlans || gettingLineItems || isProcessing,
    lineItems,
    fetchEncounters,
  }
}