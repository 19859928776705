import { decimal, email, range, required } from "../../../features/paymentDashboard/Validators";
import { GenericAttribute, GenericValidator } from "../../../models/metaData/MetaData";
import { DataType, ValidatorType } from "../../../models/metaData/MetaDataEnums";
import { GlDisplayField } from "../../../models/PaymentsConfiguration";
import useErrorStore from "../../../utils/useErrorStore";
import useTypedValidator from "../../../utils/useTypedValidator";

export function useGlListManagerValidator(fields: GlDisplayField[]) {
  const attributes: GenericAttribute<GlDisplayField>[] = [
    { name: "glNumber", dataType: DataType.String, validators: [required(), duplicateValidator(fields, 'glNumber', 'glId')] },
    { name: "description", dataType: DataType.String, validators: [required()] },
    { name: "email", dataType: DataType.String, validators: [email()] },
    { name: "unitCost", dataType: DataType.Decimal, validators: [range({ min: 0 }), decimal(12,2)] },
  ];
  const { addError, removeError, errorsRef } = useErrorStore();

  const typedSelectorProps = useTypedValidator({
    attributes,
    errors: errorsRef.current,
    removeError,
    addError,
    dispatchable: false,
    getKeySuffix: (entity) => entity?.glId ?? ""
  }
  );

  return {
    ...typedSelectorProps,
    getErrorMessage: (key: string) => {
      return typedSelectorProps.getErrorMessage(key, errorsRef.current);
    },
    errorClass: (key: string) => {
      return typedSelectorProps.errorClass(key, errorsRef.current);
    },
  }
}

export function duplicateValidator<T>(entities: T[], field: keyof T, idKey: keyof T): GenericValidator<T> {
  return {
    name: ValidatorType.DuplicateValidator,
    custom: (props) => {
      const { value, onFail, onPass, entity } = props;
      if (!entity) return onFail && onFail();

      const entityWithSameValue = entities.find(e =>
        e[idKey] !== entity?.[idKey] && e[field] as any == value
      )
      if (entityWithSameValue) return onFail && onFail();

      onPass && onPass();
    },
    message: "Must be unique"
  }
}