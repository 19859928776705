export enum EnumTransactionType {
  Unknown = 'Unknown',
  Payment = 'Payment',
  Void = 'Void',
  Credit = 'Credit',
  RecurringPayment = 'RecurringPayment',
  GLPayment = 'GLPayment',
  GLVoid = 'GLVoid',
  GLCredit = 'GLCredit',
  RecurringGLPayment = 'RecurringGLPayment',
  Reverse = 'Reverse',
  Invalid = 'Invalid',
  TokenRequest = 'TokenRequest',
  TokenPayment = 'TokenPayment',
  Authorization = 'Authorization',
}


export default EnumTransactionType;
