import '@experian/eds-styles/dist/eds-all.css';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import 'assets/styles/components/_authorizationModal.scss';
import { useReactToPrint } from 'features/print/ReactToPrint';
import { OrganizationReference} from 'models/PaymentPlan';
import { useRef, useState } from 'react';
import 'typeface-roboto';
import { ApiTenderTypeEnum, template } from 'utils/Utils';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { useTypedSelector } from 'app/rootReducer';
import { EnumTransactionTenderType } from 'models/enums/EnumTransactionTenderType';
import CommonAuthorizationConfirmation from 'features/commonAuthorizationConfirmation/CommonAuthorizationConfirmation';
import { FullOrganizationLevelDocument } from '../../models/OrganizationLevelDocument';

interface TenderTypeProps {
  type?: string | number;
}

export default (props: {
  isOpen: boolean;
  close: () => void;
  onNext: () => void;
  organization: OrganizationReference | undefined;
  tenderInfo: TenderTypeProps
  singlePaymentOnFileTenderType?: {type: string | EnumTransactionTenderType | undefined}
}) => {
  const { organization, isOpen, close, onNext, tenderInfo, singlePaymentOnFileTenderType} = props;
  const openClass = isOpen ? 'eds-modal.open' : '';

  const { getFacilityByDepartment: useGetFacilityByPath } = useOrganizations();
  const facility = useGetFacilityByPath(organization?.path);

  const configuration = useTypedSelector(s => getConfiguration(s, facility));
  const [printing, setPrinting] = useState(false);
  const componentRef = useRef() as any;  
  // this needs to be put in a template for non-payment plan payments
  
  let content = getContent(tenderInfo, singlePaymentOnFileTenderType, configuration?.configuration?.templates);
  content = template(content ?? '', { FacilityName: facility?.name });
  const paragraphs = (
    <>
      {content?.split('\n').map((paragraph: any,index:any) => (paragraph ? <p key={index}>{paragraph}</p> : ''))}
    </>
  )

  const openPrintPreview = useReactToPrint({
    onBeforeGetContent: () => setPrinting(true),
    content: () => componentRef.current,
    onAfterPrint: () => setPrinting(false),
  }); 

  return (
    <CommonAuthorizationConfirmation
     openClass={openClass}
     close={close}
     paragraphs={paragraphs}
     onNext={onNext}
     printing={printing}
     componentRef={componentRef}
     content={content}
     openPrintPreview={openPrintPreview}
     customStyleButton={'singleAuthModalCloseButton'}
    />
  );
};

interface TemplateTypes {
  CardAuthorizationText: string;
  CheckAuthorizationText: string;
}

const getConfiguration = (s: any, facility: FullOrganizationLevelDocument | undefined) => {
  if (facility != undefined) {
    const conf = s.paymentDashboard?.configurations[facility.path];
    return conf;
  }
};

const getContent = (
  tenderInfo: TenderTypeProps, 
  singlePaymentOnFileTenderType: { type: string | EnumTransactionTenderType | undefined; } | undefined, 
  templates?: TemplateTypes
) => {
  switch (tenderInfo.type)
  {
    case ApiTenderTypeEnum.SavedOnFile:
      return singlePaymentOnFileTenderType?.type == EnumTransactionTenderType.Card
        ? templates?.CardAuthorizationText
        : templates?.CheckAuthorizationText;
    case ApiTenderTypeEnum.CardDevice:
    case ApiTenderTypeEnum.CardManual:
      return templates?.CardAuthorizationText;
    case ApiTenderTypeEnum.ECheck:
    case ApiTenderTypeEnum.PaperCheckAsECheck:
      return templates?.CheckAuthorizationText;
    default:
      return '';
  };
};