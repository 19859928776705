import { RemitScheduleConfig, RemitScheduleCreate, RemitScheduleResponse, RemitScheduleUpdate } from 'models/RemitScheduleModel';
import { callHttpApi } from './callHttpApi';


export async function GetRemitSchedule(
  organizationPath: string
): Promise<RemitScheduleResponse> {
  const params = { OrganizationPath: organizationPath };
  return callHttpApi<RemitScheduleConfig>(
    'get',
    `/api/v1/RemitSchedules`,
    undefined,
    params
  );
}

export async function SaveRemitSchedule(
  remitScheduleCreate : RemitScheduleCreate
  ):Promise<RemitScheduleResponse>{
  return callHttpApi(
    'post',
    `/api/v1/RemitSchedules`,
    remitScheduleCreate
  );
}

export async function UpdateRemitSchedulesConfig(
  id: string,
  remitSchedule: RemitScheduleUpdate
) {
  return callHttpApi(
    'put',
    `/api/v1/RemitSchedules/${id}`,
    remitSchedule
  );
}
