import { useDispatch } from "react-redux";
import { setCall, State } from 'services/ServicesReducer';

export function useCallService() {
    const dispatch = useDispatch();
    return async function callService<T>(key: keyof State["calls"], callback: () => Promise<T>) {
        dispatch(setCall({ [key]: { isProcessing: true } }));
        const result = await callback();
        dispatch(setCall({ [key]: { isProcessing: false } }));
        return result;
    }
}