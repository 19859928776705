import { callHttpApi } from 'services/callHttpApi';
import { Device } from 'models/Device';

const fakeresponse = false;

export interface GetDevicesResult {
  result?: Device[];
  err?: string;
}

export interface GetDevicesRequest {
  OrganizationPath: string;
}

export async function GetDevicesService(
  data: GetDevicesRequest
): Promise<GetDevicesResult> {
  if (!fakeresponse) {
    let url = `api/v1/Transactions/devices?`;

    const request = Object.entries(data)
      .filter(([key, value]) => value)
      .reduce((o: any, [key, value]) => {
        o[key] = value;
        return o;
      }, {});
    const queryString = new URLSearchParams(request as any);

    url = url + queryString.toString();
    return await callHttpApi<GetDevicesResult['result']>('get', url);
  } else {
    const fakeDevicesData: Device[] = [
      {
        id: '5f4d5a87d540a83b60a5a496',
        serialNumber: '1240042946',
        friendlyName: `Jei's device`,
      },
    ];
    let fakeResult: GetDevicesResult = {
      result: fakeDevicesData,
    };
    return fakeResult;
  }
}