import { ICellRendererParams } from 'ag-grid-community';
import { Tier as PaymentPlanTier } from 'models/PaymentPlanConfiguration';

interface GenericInputCellProps<T> extends ICellRendererParams {
  isEditor: boolean;
  onChange: (
    idx: number,
    field: keyof T,
    value: string,
    rowData: T[]
  ) => {};
  agGridReact: {
    props: {
      rowData: T[];
    };
  };
}

interface TierGridDataInterface extends PaymentPlanTier{
}

interface ValidatedRenderCell extends GenericInputCellProps<TierGridDataInterface> {
}

export default (params: ValidatedRenderCell) => {
  const { data , value, colDef } = params;
  const {errorClass, getErrorMessage } = colDef.cellRendererParams;

  const field = colDef.field as keyof TierGridDataInterface;
  const fields = data;
  let isFieldEditable = data.isEditing;

  if(field == 'minNumberOfPayments')
    isFieldEditable = isFieldEditable && fields.minNumberOfPaymentsIsEditable;

  if(field == 'maxNumberOfPayments')
  isFieldEditable = isFieldEditable && fields.maxNumberOfPaymentsIsEditable;

  if(field == 'minPaymentAmount')
  isFieldEditable = isFieldEditable && data.minPaymentAmountIsEditable;

  if(field == 'calculateBy') {
    const label = value;
  
    return (
      <>
        <div data-testid={field + params.node.rowIndex}>{label}</div>
        <div className={errorClass(field + data.tId)}>{getErrorMessage(field + data.tId)}</div>
      </>     
    );
  }

  let preppendCurrency='';
  if (field == 'floor' || field == 'ceiling' || field == 'minPaymentAmount') {
    preppendCurrency = '$';
  }

  return (isFieldEditable ?
    <><div className={`eds-field_#control eds-field_.eds-input eds-input`} >
      <input data-testid={field + params.node.rowIndex}
        className={'eds-input_#input'}
        value={value}
        style={{ width: '100%' }}
      />
    </div>
    <div className={errorClass(field + data.tId)}>{getErrorMessage(field + data.tId)}</div>
    </> : <div data-testid={field + params.node.rowIndex}>{preppendCurrency + value}</div>
  );
};