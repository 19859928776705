import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EDS_TextBox, EDS_Button, EDS_Checkbox } from '@EH/eh.eds.react';
import { ReactComponent as Plus } from 'assets/svgs/paymentPlanIcons/icon-add-row.svg';
import { ReactComponent as Separator } from 'assets/svgs/table-separator.svg';
import { ReactComponent as ProgressIcon } from 'assets/svgs/progresswheel-icon.svg';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { AlertIds, AlertTypes, removeAllAlerts, setAlert, setAlerts } from 'features/alert/AlertReducer';
import { getOrganizationByOneSourceIdService, postOrganizationFromOneSourceIdService, FromOneSourceOrganizationsSyncResult } from 'services/OrganizationService';
import { OrganizationByOneSourceId } from 'models/OrganizationLevelDocument';
import 'assets/styles/components/_adminSettings.scss';
import { getOrganizationSync } from 'services/OrganizationSyncService';
import { ProcessStatus } from 'models/Client';
import { sleep } from 'utils';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

const MAX_ALLOWED_ROWS = 10;

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumbLinks([{ name: 'Landing', slug: rootPath }])
    );

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

export default function AddFacility(
  props: Props = { rootPath: 'add-new-facility' }
  ) {
  const { rootPath } = props;
  useBreadcrumb(rootPath);

  const [facilityIds, setFacilityIds] = useState<string[]>(['']);
  const [invalidFields, setInvalidFields] = useState<number[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [result, setResult] = useState<OrganizationByOneSourceId[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const maxRowError = 'Max number of rows achieved.';
  const emptyFieldError = 'Facility ID should not be empty';
  const notFoundOneSourceIdError = 'Facility ID(s) not found in OneSource';
  const error = 'Facility cannot be added.';
  const emptyFriendlyNameError = 'Friendly Name should not be empty';
  const facilityNotSelectedError = 'At least one Facility should be selected';

  const dispatch = useDispatch();
  const alert = { id: AlertIds.NewFacilityAlert, type: AlertTypes.Warning, message: maxRowError };

  useEffect(() => {
    if (facilityIds.length === MAX_ALLOWED_ROWS) {
      dispatch(setAlert({ ...alert, dismissable: true }))
    }
  }, [facilityIds.length]);

  const onChange = (index: number, value: string) => {
    const updatedFacilities = [...facilityIds];
    const onlyDigitsAllowed = value.replace(/\D/g, '');
    updatedFacilities[index] = onlyDigitsAllowed;
    setFacilityIds([...updatedFacilities]);
  };

  const addRow = () => {
    const facilitiesArray = [...facilityIds];
    facilitiesArray.push('');
    setFacilityIds(facilitiesArray);
  };

  const verifyFields = (inputs: string[]) => {
    const emptyFields: number[] = [...invalidFields];
    inputs.forEach((value: string, index: number) => {
      if (!value && !invalidFields.includes(index)) {
        emptyFields.push(index)
      } else if (value && emptyFields.includes(index)) {
        emptyFields.splice(emptyFields.indexOf(index), 1);
      }
    })
    setInvalidFields(emptyFields);
  };

  const onFieldChange = (index: number, value: string) => {
    const updated: OrganizationByOneSourceId[] = [...result];
    updated[index].friendlyName = value;
    setResult([...updated])
  }

  const handleCheck = (index: number) => {
    const updated: OrganizationByOneSourceId[] = [...result];
    updated[index].checked = !updated[index].checked;
    setResult([...updated])
  }

  const onClear = () => {
    setFacilityIds(['']);
  };

  const onCancel = () => {
    setFacilityIds(['']);
    setResult([]);
    setShowTable(false);
  }

  const onSubmit = async () => {
    const allFilled = facilityIds.find(f => !!f);
    if (allFilled) {
      const oneSourceIds = new URLSearchParams();
      if (!!facilityIds.length) {
        facilityIds.forEach((facility: string) =>
          oneSourceIds.append('oneSourceIds', facility)
        );
      }
      const response = await getOrganizationByOneSourceIdService(oneSourceIds);
      const hasEmptyValue = response?.result?.find(obj => obj.name === null);
      const returnsEmptyObject = response?.result?.length === 0;
      
      if (response.err) {
        const errorMessage = response.errorResponse.data.validationErrors[0].errorMessage;
        dispatch(setAlert({...alert, type: AlertTypes.Error, message: errorMessage}));
        return;
      }
      if (response.result) {
        const newFacilities = response.result.map(facility => {
          return {
            ...facility,
            friendlyName: facility.name,
          }
        });
        setResult(newFacilities);
      }
      if (hasEmptyValue) {
        dispatch(setAlert({...alert, type: AlertTypes.Error, message: notFoundOneSourceIdError}));
        return;
      }
      if (returnsEmptyObject) {
        dispatch(setAlert({...alert, type: AlertTypes.Error, message: error}));
        return;
      }
      setShowTable(true);
    } else {
      verifyFields(facilityIds);
      dispatch(setAlert({...alert, type: AlertTypes.Error, message: emptyFieldError}))
    }
  };

  const onAddFacilities = async () => {
    const selectedFacilities = result.filter(facilityData => facilityData.checked);
    if (!selectedFacilities.length) {
      dispatch(setAlert({...alert, type: AlertTypes.Error, message: facilityNotSelectedError}))
    } else if (selectedFacilities.find(facility => !facility.friendlyName)) {
      dispatch(setAlert({...alert, type: AlertTypes.Error, message: emptyFriendlyNameError}))
    } else {
      setIsProcessing(true);
      const dataToSend = selectedFacilities.map(f => {
        return {
          oneSourceId: f.clientId,
          friendlyName: f.friendlyName,
        }
      })
      const response = await postOrganizationFromOneSourceIdService({ organizationsFromOneSource: dataToSend });
      if (response?.err) {
        dispatch(setAlert({...alert, type: AlertTypes.Error, message: response.err}));
        return;
      } else {
        dispatch(removeAllAlerts());
        await pingFacilitiesSync(response.result!);
      }
      setIsProcessing(false);
    }
  }

  const pingFacilitiesSync = async (fromOneSourceOrganizationsSyncResult: FromOneSourceOrganizationsSyncResult[]) =>{
    const historyIds = fromOneSourceOrganizationsSyncResult.map((org) => org.historyId).flat();
    if(!historyIds.length) {
      return;
    }

    const pingSleepIntervalMs = 3000;
    const maxPingAttempts: number = 20;
    let currentAttempt = 0;

    while (currentAttempt < maxPingAttempts){
      const organizationSyncHistories = await getOrganizationSync(historyIds);

      if(organizationSyncHistories.err){
        dispatch(setAlert({...alert, type: AlertTypes.Error, message: organizationSyncHistories.err}));
      }
      else {
        let allOrganizationsFinished = true;
        const alerts = organizationSyncHistories.result!.map((history) => {
          let alertMessage: string;
          let alertType: AlertTypes;

          switch(history.status) {
            case ProcessStatus.Started: {
              allOrganizationsFinished = false;
              alertMessage = `${history.organization.name}: In Progress.`;
              alertType = AlertTypes.Info;
              break;
            }
            case ProcessStatus.Finished: {
              alertType = AlertTypes.Success;
              alertMessage = `${history.organization.name}: ${history.numberOfDepartmentsSynced} Departments and ${history.numberOfUsersSynced} Users were synced.`;
              break;
            }
            case ProcessStatus.Failed: {
              alertType = AlertTypes.Error;
              alertMessage = `${history.organization.name}: ${history.errors.join(";")}`;
              break;
            }
          }

          return {...alert, type: alertType, message: alertMessage};
        });
        dispatch(setAlerts(alerts));

        if (allOrganizationsFinished) return;
      }

      if(++currentAttempt === maxPingAttempts){
        dispatch(setAlert({id: AlertIds.NewFacilityMaxPingAlert, type: AlertTypes.Error, message: "Reached maximum retries."}));
        return;
      }

      await sleep(pingSleepIntervalMs);
    }
  }

  const renderRows = (
    <>
      {facilityIds.map((facility, index) => {
        const limitReached = facilityIds.length === MAX_ALLOWED_ROWS;
        const isInvalid = invalidFields.includes(index);
        return (
          <div key={index}>
            <div className="eds-field_#label">Facility ID</div>
            <EDS_TextBox
              name={`facility_${index}`}
              placeHolder={'Enter facility ID'}
              modifiers={`field-width${isInvalid ? ' invalid-field' : ''}`}
              value={facility}
              onChange={(e: React.ChangeEvent<HTMLFormElement>) => onChange(index, e.target.value)}
            />
            <button
              className="eds-link row-icon"
              onClick={limitReached ? () => {/* empty function */} : addRow}
            >
              <span className="new-facility-button"><Plus /> Add Facility ID</span>
            </button>
            <div className="new-facility-note">Copy Facility ID from OneSource</div>
          </div>
        )
      })}
    </>
  );

  const headerTable = ['', 'Trading Partner ID', 'Facility', 'Friendly Name'];
  const renderTable = (
    <div className='gridWrapper'>
      <table>
        <thead>
          <tr>
            {headerTable && headerTable.map((title: string, index: number) => {
              return (
                <th key={index} scope='col'>
                  <div className='tableTitle'>{title}</div>
                  <span className="tableSeparator">
                    <Separator />
                  </span>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {result.map((row: any, index: number) => {
            return (
              <tr key={index}>
                <td>
                  <EDS_Checkbox
                    checked={!!row.checked}
                    onChange={() => handleCheck(index)}
                  />
                </td>
                <td>{row.tradingPartnerId}</td>
                <td>{row.clientId} {row.name}</td>
                <td><EDS_TextBox
                  modifiers="friendlyName"
                  name="friendlyName"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    onFieldChange(index, e.target.value);
                  }}
                  value={row.friendlyName} /></td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );

  const renderSubmitButtons = (
    <>
      <EDS_Button
        modifiers={'eds-button eds-button.secondary'}
        buttonText={'Clear'}
        onClick={onClear}
      />
      <EDS_Button
        modifiers={'eds-button eds-button.primary'}
        buttonText={'Submit'}
        onClick={onSubmit}
      />
    </>
  );

  const renderAddButtons = (
    <>
      <EDS_Button
        modifiers={'eds-button eds-button.secondary'}
        buttonText={'Cancel'}
        onClick={onCancel}
        disabled={isProcessing}
      />
      <EDS_Button
        modifiers={'eds-button eds-button.primary'}
        buttonText={'Add Facilities'}
        onClick={onAddFacilities}
        disabled={isProcessing}
      />
    </>
  );

  return (
    <div className="new-facility-container">
      <div className="new-facility-title">
        <h3>Add New Facility</h3>
        <div className="new-facility-note">You can add up to {MAX_ALLOWED_ROWS} facilities at a time.</div>
        { isProcessing ? 
            <span>
              <ProgressIcon />
              <span style={{marginLeft:'10px'}}> Syncing... Please Wait.</span> 
            </span>:<></>
        }
      </div>
      <div className="new-facility-search-container">
        {showTable ? renderTable : renderRows}
      </div>
      <div className="new-facility-buttons">
        {showTable ? renderAddButtons : renderSubmitButtons}
      </div>
    </div>
  );
}