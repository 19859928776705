import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EDS_Button, EDS_Accordion, EDS_Select } from '@EH/eh.eds.react';
import 'assets/styles/components/_clientConfiguration.scss';
import OrganizationUserSearch, {
  getOrganizationOption,
  getOrganizationOptions,
} from '../../Search/simpleSearch/OrganizationUserSearch';
import { UserDetailModel } from '../../../models/UserInfoAndRolesModel';
import { useTypedSelector } from '../../../app/rootReducer';
import { useImpersonateUser, useUserUtils } from '../../../utils/useUserUtils';
import { ReactComponent as ImpersonatingIcon } from 'assets/svgs/impersonating-icon.svg';
import { ReactComponent as Plus } from 'assets/svgs/paymentPlanIcons/icon-add-row.svg';
import { ReactComponent as Minus } from 'assets/svgs/paymentPlanIcons/icon-remove-row.svg';
import { ReactComponent as InfoIcon } from 'assets/svgs/icon-info.svg';
import { ReactComponent as SyncIcon } from 'assets/svgs/sync.svg';
import { ReactComponent as ProgressIcon } from 'assets/svgs/progresswheel-icon.svg';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { getSortOptions, nameof } from '../../../utils/Utils';
import { OrganizationLevelSummary } from '../../../models/OrganizationLevelSummary';
import {
  GridReadyEvent,
  SelectionChangedEvent,
  GridApi,
  IGetRowsParams,
  IDatasource,
} from 'ag-grid-community';
import { useOrganizations } from '../../../features/organizations/hooks/useOrganizations';
import OrganizationLevelTypes from '../../../models/enums/OrganizationLevelTypes';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import MultiSelect, {
  MultiSelectOption,
} from '../../../components/select/MultiSelect';
import { OrganizationLevelDocument } from '../../../models/OrganizationLevelDocument';
import { setSimpleSearchInfoState } from '../../Search/simpleSearch/SimpleSearchReducer';
import { useDispatch } from 'react-redux';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { updatePermissions } from 'services/PermissionsService';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';
import { PermissionItem } from 'models/Permission';
import UserPermissions from 'models/enums/UserPermissions';
import { Organization, ProcessStatus } from 'models/Client';
import {
  getOrganizationSync,
  syncOrganization,
} from 'services/OrganizationSyncService';
import { convertDateToDisplayString } from 'utils/UtilsDateTime';
import AddRemoveFacilityModal from './AddRemoveFacilityModal';
import { sleep } from 'utils';
import { permissionsMenuModes } from '../../../features/admin/UsersAndPermissions/UsersTab/UserPermissionsMenu';
import AddOrRemovePermissions from '../../../features/admin/UsersAndPermissions/ConfirmationPopups/AddOrRemovePermissions';
import {
  PaginatedResult,
  UserSearchCriteria,
} from '../../../services/UsersService';
import useSearchUsers from '../../../features/admin/OrganizationUserManager/useSearchUsers';
import SelectDefaultFacilityAndDepartmentModal from './SelectDefaultFacilityAndDepartmentModal';
import 'assets/styles/components/_customHeaderCell.scss';


export const getLabel = (organization: OrganizationLevelDocument) =>
  `(${organization.tradingPartnerId}) ${organization.name}`;

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setBreadcrumbLinks([
        { name: 'Organization & User Management', slug: rootPath },
      ])
    );

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

const commonColumnfilter = {
  filter: 'agTextColumnFilter',
  filterParams: {
    buttons: ['reset', 'apply'],
    suppressAndOrCondition: true,
    closeOnApply: true,
    filterOptions: ['equals'],
  },
};

const headerDefinitions = [
  {
    resizable: true,
    maxWidth: 50,
    checkboxSelection: true,
  },
  {
    headerName: 'User ID',
    field: nameof<UserDetailModel>('oneSourceUserId'),
    colId: 'OneSourceUserId',
    resizable: true,
    sortable: true,
    ...commonColumnfilter,
    minWidth: 180,
  },
  {
    headerName: 'First Name',
    field: nameof<UserDetailModel>('firstName'),
    colId: 'FirstName',
    sortable: true,
    resizable: true,
    ...commonColumnfilter,
  },
  {
    headerName: 'Last Name',
    field: nameof<UserDetailModel>('lastName'),
    colId: 'LastName',
    sortable: true,
    resizable: true,
    ...commonColumnfilter,
  },
  {
    headerName: 'Facility',
    field: nameof<UserDetailModel>('organizationLevels'),
    sortable: false,
    filter: false,
    resizable: true,
    cellRenderer: 'facilitiesCell',
  },
  {
    headerName: 'Department',
    field: nameof<UserDetailModel>('organizationLevels'),
    sortable: false,
    filter: false,
    resizable: true,
    cellRenderer: 'departmentCell',
  },
];

export default function OrganizationUserManagement(
  props: Props = { rootPath: 'organization-user-management' }
) {
  const { rootPath } = props;

  const dispatch = useDispatch();
  const [isUserSelectorOpen, setIsUserSelectorOpen] = useState(false);
  const state = useTypedSelector(p => p.simpleSearchInfo?.value);
  useBreadcrumb(rootPath);

  const [
    selectedOrganization,
    setSelectedOrganization,
  ] = useState<MultiSelectOption | null>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [lastSyncDate, setLastSyncDate] = useState('');
  const { useGetFacilities, useGetTradingPartners } = useOrganizations();

  const allFacilities = useGetFacilities();
  const tradingPartners = useGetTradingPartners();
  const tradingPartnerOptions = getOrganizationOptions(tradingPartners, t =>
    getOrganizationOption(t)
  );
  const facilities = useGetFacilities(o =>
    o.path.includes(selectedOrganization?.value ?? '')
  );
  const selectedFacility = state.facilities;
  function showAlert(message: string, isSuccess: boolean) {
    dispatch(
      setAlert({
        id: AlertIds.OrganizationSync,
        type: isSuccess ? AlertTypes.Success : AlertTypes.Error,
        message: message,
        dismissable: true,
      })
    );
  }
  function getReport(response: any) {
    if (response?.err) {
      return { message: response.err || 'Could not sync', isSuccess: false };
    } else if (
      response?.result &&
      response.result[0].status === ProcessStatus.Finished
    ) {
      setLastSyncDate(convertDateToDisplayString(new Date()) as string);
      return {
        message: `${response.result[0].numberOfDepartmentsSynced} Departments and ${response.result[0].numberOfUsersSynced} Users were synced.`,
        isSuccess: true,
      };
    } else if (
      response?.result &&
      response.result[0].status === ProcessStatus.Failed
    ) {
      return { message: response.result[0].errors?.[0], isSuccess: false };
    }
    else {
      return {
        message: `The process is taking longer than expected. Please check back later.`,
        isSuccess: false,
      };
    }
  }

  const startOrganizationSync = async () => {
    if (isProcessing || !selectedOrganization) return;
    if (selectedFacility.length > 1 || selectedFacility.length === 0) {
      showAlert('Please select one facility.', false);
      return;
    }
    setIsProcessing(true);
    const selectedOrg = allFacilities.find(
      x => x.path == selectedFacility[0].value
    );
    const syncData = {
      id: selectedOrg?.id,
      name: selectedOrg?.name,
      path: selectedOrg?.path,
    } as Organization;
    const response = await syncOrganization(syncData);
    if (!response.result) {
      setIsProcessing(false);
      showAlert(response.err || 'Could not sync', false);
      return;
    }

    let report;
    let limitAttempts = 15;
    let maxAttempts = 5;
    const intervalMS = 2500;
    let attemptCount = 0;

    while (attemptCount < maxAttempts) {
      report = await getOrganizationSync([response.result]);
      if (
        !report.result ||
        (report?.result && report.result[0].status !== ProcessStatus.Started)
      )
        break;
      attemptCount++;
      await sleep(intervalMS);
      
      if (attemptCount === maxAttempts &&
          maxAttempts <= limitAttempts && 
          report?.result[0]?.status === ProcessStatus.Started) {
            maxAttempts++;
      }
    }
    const result = getReport(report);
    showAlert(result?.message, result?.isSuccess as boolean);
    setIsProcessing(false);
  };

  function searchForUsers() {
    setIsUserSelectorOpen(false);
    window.setTimeout(() => setIsUserSelectorOpen(true), 0);
  }

  const organizationPaths =  state?.departments.length !== 0 ? state?.departments?.map(f => f.value) : state?.facilities?.map(f => f.value);

  return (
    <div className="client-configuration">
      <div className="content">
        <div className="header-content">
          <div className="title no-underline">
            <h2>Organization & User Management</h2>
          </div>
          {isProcessing ? (
            <span
              className={`processing-label sync-button`}
              onClick={startOrganizationSync}
            >
              <ProgressIcon />
              <span style={{ marginLeft: '10px' }}>
                {' '}
                Syncing... Please Wait.
              </span>
            </span>
          ) : (
            <></>
          )}
        </div>
        <div className="client-controls">
          <div className="actions">
            <MultiSelect
              disableCloseOnSelect={false}
              label="Organization:"
              options={tradingPartnerOptions}
              onChange={selected => {
                dispatch(
                  setSimpleSearchInfoState({
                    ...state,
                    facilities: [],
                    departments: [],
                    users: [],
                  })
                );
                setSelectedOrganization(selected);
              }}
              multiple={false}
              name="organization"
              values={selectedOrganization ?? null}
              disableClearable={false}
            />
          </div>
        </div>
        <div className={'organization-user-search'}>
          <OrganizationUserSearch
            facilities={facilities}
            state={state}
            setState={organizationUserState => {
              dispatch(
                setSimpleSearchInfoState({ ...state, ...organizationUserState })
              );
            }}
            initialize={false}
            showError={false}
            noUser={true}
            tradingPartner={selectedOrganization?.value}
          />
          <div className={'row buttons-row'}>
            <div className={'sync-button-group'}>
              <div className={'eds-field_#label sync-row'}>
                {lastSyncDate ? (
                  <span>Last sync on {lastSyncDate}</span>
                ) : (
                  <></>
                )}
                <span
                  className={`sync-button  ${
                    !isProcessing && selectedOrganization ? '' : 'disabled'
                  }`}
                  onClick={startOrganizationSync}
                >
                  <SyncIcon />
                  Sync Selected Facility from OneSource
                </span>
              </div>
              <EDS_Button
                modifiers={'eds-button eds-button.primary'}
                name={'select'}
                buttonText={'Submit'}
                onClick={() => searchForUsers()}
                disabled={!(state.facilities.length && state.departments.length)}
              />
            </div>
          </div>
        </div>
      </div>
      {isUserSelectorOpen ? (
        <div className="header-wrapper">
          <EDS_Accordion
            accordionItems={[
              {
                summary: 'Users',
                isOpen: true,
                content: (
                  <UserSelector
                    criteria={{
                      ...(organizationPaths.length
                        ? { organizationPaths }
                        : {}),
                    }}
                    headers={headerDefinitions}
                  />
                ),
              },
            ]}
            modifiers="accordion-search-results"
          />
        </div>
      ) : null}
    </div>
  );
}

export function UserSelector(props: {
  users?: UserDetailModel[];
  criteria?: UserSearchCriteria;
  headers: any;
  permissionSection?: boolean;
  permissions?: (PermissionItem | undefined)[];
  facilities?: string[];
  onLoaded?: () => void;
  onSelectionChanged?: () => void;
}) {
  const {
    users,
    criteria,
    headers,
    permissionSection,
    permissions,
    facilities,
    onLoaded,
    onSelectionChanged,
  } = props;
  const impersonateUser = useImpersonateUser();
  const dispatch = useDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const { redirectUserToHome, redirectUserToNoPermissions } = useUserUtils();
  const { search } = useSearchUsers();

  const pageSizeOptions = [
    { optionName: '25', value: 25 },
    { optionName: '50', value: 50 },
    { optionName: '75', value: 75 },
    { optionName: '100', value: 100 },
  ];

  const defaultPageSize = pageSizeOptions[0].value;

  const [selectedUsers, setSelectedUsers] = useState<UserDetailModel[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [isButtonDisable, setIsButtonDisable] = useState(true);

  useEffect(() => {
    setIsButtonDisable(selectedUsers?.length === 0);
  }, [selectedUsers]);

  const onChangePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = pageSizeOptions[event.target.selectedIndex].value;
    setPageSize(value);
    if (gridApi) {
      gridApi.paginationSetPageSize(value);
    }
  };

  const searchDataCallBack = useCallback(() => {
    if (gridApi) {
      const gotoPage = gridApi?.paginationGetCurrentPage();
      updateData(
        gridApi,
        search,
        { limit: pageSize, ...criteria },
        buildCriteria,
        gotoPage
      );
    }
  }, [gridApi, search, criteria, pageSize]);

  function onGridReady(params: GridReadyEvent) {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    if (users) {
      params.api.setDatasource({
        async getRows(rowsParams: IGetRowsParams) {
          if (users?.length) {
            const startRow = rowsParams.startRow;
            const endRow = rowsParams.endRow;
            const results = users.slice(startRow, endRow);
            setTimeout(() => {
              rowsParams.successCallback(results, users.length);
              onLoaded && onLoaded();
            }, 0);
          } else {
            rowsParams.successCallback([], 0);
          }
        },
      });
    } else {
      updateData(
        params.api,
        search,
        { limit: pageSize, ...criteria },
        buildCriteria
      );
    }
  }

  const impersonate = async () => {
    if (selectedUsers.length === 1 && selectedUsers[0]) {
      const impersonatedUser = await impersonateUser(
        selectedUsers[0]?.oneSourceUserId
      );

      const anyImpersonatedPermission = impersonatedUser?.impersonatedOrganizationLevels?.some(
        o => o.permissions?.length
      );
      if (!anyImpersonatedPermission) {
        redirectUserToNoPermissions();
        return;
      }

      const allPermissions = impersonatedUser?.impersonatedOrganizationLevels
        ?.map(org => org.permissions?.map(item => Number(item)))
        .flat();
      if (!allPermissions?.includes(UserPermissions.FinancialAdministation)) {
        redirectUserToHome();
      }
    }
  };

  const [AddFacilityClicked, setAddFacilityClicked] = useState(false);
  const [RemoveFacilityClicked, setRemoveFacilityClicked] = useState(false);
  const [DefaultFacilityClicked, setDefaultFacilityClicked] = useState(false);

  const AddFacility = () => {
    if (selectedUsers?.length > 0) setAddFacilityClicked(true);
  };
  const RemoveFacility = () => {
    if (selectedUsers?.length > 0) setRemoveFacilityClicked(true);
  };
  const onDefaultFacilityClick = () => {
    setDefaultFacilityClicked(true);
  }

  const renderAlert = (newPermission: boolean) => {
    dispatch(
      setAlert({
        id: newPermission
          ? AlertIds.AddPermissions
          : AlertIds.DeletePermissions,
        type: AlertTypes.Success,
        message: newPermission
          ? 'Permissions have been added to the selected users.'
          : 'Permissions have been deleted to the selected users.',
        dismissable: true,
      })
    );
  };

  const renderErrorAlert = (newPermission: boolean) => {
    dispatch(
      setAlert({
        id: newPermission
          ? AlertIds.AddPermissions
          : AlertIds.DeletePermissions,
        type: AlertTypes.Error,
        message: newPermission
          ? 'Could not add permissions to the selected users.'
          : 'Could not delete permissions to the selected users.',
        dismissable: true,
      })
    );
  };

  const onExportCSV = useCallback(() => {
    if (gridRef && gridRef.current && gridRef.current.api)
      gridRef.current.api.exportDataAsCsv({
        fileName: 'User List',
        onlySelected: true,
        allColumns: true,
      });
  }, []);

  const dontInclude = [
    'checkAllPayment',
    'checkAllPaymentPlans',
    'checkAllGeneralLedger',
    'checkAllPSafeAdmin',
    'checkAllEpic',
  ];
  permissions?.filter(permission => !dontInclude.includes(permission?.id!));
  const selectedPermissions = permissions?.map(permission => permission?.id);

  const mapPermissions = () => {
    const data: {
      userId: string;
      organizationLevels: OrganizationLevelSummary[];
    }[] = [];

    if (selectedPermissions) {
      selectedUsers.forEach(user => {
        const organizationLevels =
          user.organizationLevels?.map(organization => {
            selectedPermissions.forEach(permission => {
              if (permission) {
                organization.permissions?.push(permission);
              }
            });

            return {
              ...organization,
              permissions: organization.permissions ?? [],
            };
          }) ?? [];

        data.push({
          userId: user.id,
          organizationLevels: organizationLevels,
        });
      });
    }
    
    return data;
  };

  const [mode, setMode] = useState<permissionsMenuModes>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addPermission = async () => {
    const data = mapPermissions();
    const response = await updatePermissions(data);

    if (!response?.err) {
      renderAlert(true);
    }
    else {
      renderErrorAlert(true);
    }

    return !!response.err;
  };

  const removePermission = async () => {
    const mappedUsers = selectedUsers.map(user => ({
      ...user,
      organizationLevels: user.organizationLevels?.map((org: any) => ({
        ...org,
        permissions: org.permissions.filter((permission: string) => {
          if (!selectedPermissions?.includes(permission.toString())) {
            return permission;
          }
        }),
      })),
    }));

    const data = mappedUsers.map(user => {
      return {
        userId: user.id,
        organizationLevels: user.organizationLevels!,
      };
    });

    const response = await updatePermissions(data);

    if (!response?.err) {
      renderAlert(false);
    }
    else {
      renderErrorAlert(false);
    }

    return !!response.err;
  };

  function buildUsersRows(){
    const fullUserRows : UserDetailModel[] = [];
    users?.forEach(r => r.organizationLevels?.forEach(org => { fullUserRows.push({...r,organizationLevels: [{...org}]})}));
    return fullUserRows;
  }

  const gridAttributes = permissionSection
    ? {
        rowData: buildUsersRows(),
        animateRows: true,
      }
    : {
        rowModelType: 'infinite',
        cacheBlockSize: 25,
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        infiniteInitialRowCount: 1,
        maxBlocksInCache: 2,
      };

  return (
    <div>
      {!permissionSection ? (
        <div>
          <div>
            Note: Facility and Department list is accurate to the last time a
            user logged into PaymentSafe.
          </div>
          <div className="row row-buttons">
            <div className="row-item row-item-main">
              <span className="Details">
                Select only one user to impersonate or clone user
              </span>
            </div>
            <div className="row-item">
              <span
                className={`DefaultFacility-button`}
                onClick={onDefaultFacilityClick}
              >
                <Plus />
                Default Facility/Department
              </span>
              {DefaultFacilityClicked ? (
                <SelectDefaultFacilityAndDepartmentModal
                  selectedUsers={selectedUsers}
                  isOpen={DefaultFacilityClicked}
                  close={() => {
                    setDefaultFacilityClicked(false);
                  }}
                  searchDataCallBack={searchDataCallBack}
                />
              ) : null}
            </div>
            <div className="row-item">
              <span
                className={`AddFacility-button ${
                  selectedUsers.length > 0 ? '' : 'disbaled'
                }`}
                onClick={AddFacility}
              >
                <Plus />
                Add Facility
              </span>
              {AddFacilityClicked ? (
                <AddRemoveFacilityModal
                  isAdd={true}
                  selectedUsers={selectedUsers}
                  isOpen={AddFacilityClicked}
                  close={() => {
                    setAddFacilityClicked(false);
                  }}
                  searchDataCallBack={searchDataCallBack}
                />
              ) : null}
            </div>
            <div className="row-item">
              <span
                className={`RemoveFacility-button ${
                  selectedUsers.length > 0 ? '' : 'disbaled'
                }`}
                onClick={RemoveFacility}
              >
                <Minus />
                Remove Facility
              </span>
              {RemoveFacilityClicked ? (
                <AddRemoveFacilityModal
                  isAdd={false}
                  selectedUsers={selectedUsers}
                  isOpen={RemoveFacilityClicked}
                  close={() => {
                    setRemoveFacilityClicked(false);
                  }}
                  searchDataCallBack={searchDataCallBack}
                />
              ) : null}
            </div>
            <div className="row-item">
              <span
                className={`impersonate-button ${
                  selectedUsers.length === 1 ? '' : 'disbaled'
                }`}
                onClick={impersonate}
              >
                <ImpersonatingIcon />
                Impersonate User
              </span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={'section ag-theme-alpine default-checkboxes'}>
        <AgGridReact
          {...gridAttributes}
          ref={gridRef}
          frameworkComponents={{
            facilitiesCell: FacilitiesCell,
            departmentCell: DepartmentCell,
          }}
          defaultColDef={{
            sortable: false,
            resizable: true,
            headerClass: 'custom-header-cell',
          }}
          columnDefs={headers}
          onGridReady={onGridReady}
          domLayout={'autoHeight'}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          onSelectionChanged={(e: SelectionChangedEvent) => {
            setSelectedUsers(e.api.getSelectedRows());
            setTimeout(() => {
              onSelectionChanged && onSelectionChanged();
            }, 0);
          }}
          suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
          pagination={true}
          paginationPageSize={25}
          suppressDragLeaveHidesColumns={true}
        />
        <div className={'pagination-container'}>
          <EDS_Select
            name="userId"
            label="Rows per page:"
            modifiers={'row-item row-item-size-double'}
            options={pageSizeOptions}
            onChange={onChangePageSize}
            value={pageSize}
          />

          {permissionSection ? (
            <>
              <EDS_Button
                modifiers={
                  'eds-button eds-button.basic button-reverse clear-button'
                }
                buttonText="Add Permissions"
                disabled={isButtonDisable}
                onClick={() => {
                  setIsModalOpen(true);
                  setMode(permissionsMenuModes.add);
                }}
              />

              <EDS_Button
                modifiers={
                  'eds-button eds-button.basic button-reverse clear-button'
                }
                buttonText="Remove Permissions"
                disabled={isButtonDisable}
                onClick={() => {
                  setIsModalOpen(true);
                  setMode(permissionsMenuModes.remove);
                }}
              />

              <EDS_Button
                modifiers={
                  'eds-button.tertiary edit-button download-user-button clear-button'
                }
                buttonText="Download User List"
                disabled={isButtonDisable}
                onClick={onExportCSV}
                iconName={'edit'}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <PermisionsConfirmationModal
        {...{
          isModalOpen,
          mode,
          selectedPermissions,
          setIsModalOpen,
          setMode,
          addPermission,
          removePermission,
          facilities,
          users: selectedUsers,
        }}
      />
    </div>
  );
}

export function PermisionsConfirmationModal(props: {
  isModalOpen: boolean;
  mode?: permissionsMenuModes;
  selectedPermissions?: (string | undefined)[];
  setIsModalOpen: (isOpen: boolean) => void;
  setMode: (mode: permissionsMenuModes | undefined) => void;
  addPermission: () => Promise<boolean>;
  removePermission: () => Promise<boolean>;
  facilities?: string[];
  users: UserDetailModel[];
}) {
  const {
    isModalOpen,
    mode,
    selectedPermissions,
    setIsModalOpen,
    setMode,
    addPermission,
    removePermission,
    facilities,
    users,
  } = props;

  const {
    getOrganizationByPath,
    getTradingPartnerByFacility,
  } = useOrganizations();

  return isModalOpen && mode != undefined ? (
    <AddOrRemovePermissions
      mode={mode}
      selectedPermissions={
        (selectedPermissions?.filter(p => !!p) ?? []) as string[]
      }
      close={() => {
        setIsModalOpen(false);
      }}
      onNext={async () => {
        setMode(undefined);
        setIsModalOpen(false);
        if (mode === permissionsMenuModes.add) {
          return await addPermission();
        }
        if (mode === permissionsMenuModes.remove) {
          return await removePermission();
        }
        return false;
      }}
      userInfo={{
        user: users.map(u => `${u.firstName} ${u.lastName}`).join(', '),
        organization: getTradingPartnerByFacility(facilities?.[0])?.name,
        facility: facilities
          ?.map(path => getOrganizationByPath(path)?.name)
          .join(', '),
      }}
      isUserTab={true}
    />
  ) : null;
}

export function PopoverElement(props: { children: string }) {
  const { children } = props;
  return (
    <OverlayTrigger
      trigger={['hover', 'hover']}
      placement="top"
      overlay={
        <Popover id="popover-basic">
          <Popover.Content>{children}</Popover.Content>
        </Popover>
      }
    >
      <InfoIcon />
    </OverlayTrigger>
  );
}

export function FacilitiesCell(props: { value: OrganizationLevelSummary[], data?: UserDetailModel }) {
  const { data: user } = props;
  return (
    <OrganizationCell {...props} levelType={OrganizationLevelTypes.Facility}
      defaultOrganizationPath={user?.defaultFacility?.path}
    />
  );
}

export function DepartmentCell(props: { value: OrganizationLevelSummary[], data?: UserDetailModel }) {
  const { data: user } = props;
  return (
    <OrganizationCell
      {...props}
      levelType={OrganizationLevelTypes.Department}
      defaultOrganizationPath={user?.defaultDepartment?.path}
    />
  );
}

export function OrganizationCell(props: {
  value: OrganizationLevelSummary[];
  levelType: OrganizationLevelTypes;
  defaultOrganizationPath?: string;
}) {
  const { value, levelType, defaultOrganizationPath } = props;
  const { getOrganizationByPath } = useOrganizations();
  const organizations = (value ?? [])
    .map(o => getOrganizationByPath(o.organizationLevel_Path))
    .filter(o => o?.organizationLevelType === levelType);
  const organizationsString = getOrganizationString(organizations) 
  const length = 30;

  return (
    <div className="facilities-cell-wrapper">
      <span className="facilities-names"> {getOrganizationDisplay(organizations, defaultOrganizationPath)}</span>
      {organizationsString.length > length ? (
        <div className='popover-container'>
          <PopoverElement>{organizationsString}</PopoverElement>
        </div>
      ) : null}
    </div>
  );
}

export function getOrganizationString(
  organizations: (OrganizationLevelDocument|undefined)[]
) {
  return organizations.map(o => o?.name).join(', ');
}

export function getOrganizationDisplay(
  organizations: (OrganizationLevelDocument | undefined)[],
  defaultOrganizationPath?: string
) {
  return organizations.map((o, idx) => {
    const display = `${ o?.name }${ idx < organizations.length- 1 ? ", " : ""}`;
    if (o?.path === defaultOrganizationPath) {
      return <b>{display}</b>;
    } else {
      return display;
    }
  });
}

export function buildCriteria(rowsParams: IGetRowsParams) {
  let model: any = {};
  const filters = rowsParams.filterModel;
  Object.keys(filters).forEach(key => {
    model[key] = filters[key].filter;
  });
  return model;
}

export function updateData<T>(
  gridApi?: GridApi,
  search?: (
    criteria: UserSearchCriteria,
    refresh?: boolean
  ) => Promise<PaginatedResult<T> | undefined>,
  defaultCriteria?: UserSearchCriteria,
  criteriaBuilder?: (rowsParams: IGetRowsParams) => UserSearchCriteria,
  goToPage?: number
) {
  gridApi?.showLoadingOverlay();

  const dataSource: IDatasource = {
    async getRows(rowsParams: IGetRowsParams) {
      if (!search) return;
      const result = await search(
        {
          ...defaultCriteria,
          ...(criteriaBuilder && criteriaBuilder(rowsParams)),
          offset:
            (gridApi?.paginationGetCurrentPage() ?? 0) *
            (gridApi?.paginationGetPageSize() ?? 0),
          sort: getSortOptions(rowsParams.sortModel),
        },
        true
      );
     
      if (result) {
        const records = result.records;

        rowsParams.endRow = result.total;
        rowsParams.successCallback(records, result.total);
        goToPage && gridApi?.paginationGoToPage(goToPage)
      } else {
        rowsParams.successCallback([], 0);
      }
    },
  };
  gridApi?.setDatasource(dataSource);
  gridApi?.sizeColumnsToFit();
  gridApi?.hideOverlay();
}
