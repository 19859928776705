import { useEffect, useState } from "react";
import CheckboxTree, { Node } from 'react-checkbox-tree';
import { OrganizationLevelDocument } from "../../../../models/OrganizationLevelDocument";
import { ReactComponent as ArrowDown } from 'assets/svgs/admin/usersAndPermissions/organizationsTree/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'assets/svgs/admin/usersAndPermissions/organizationsTree/arrow-up.svg';
import { ReactComponent as CheckboxBase } from 'assets/svgs/admin/usersAndPermissions/organizationsTree/checkbox-base.svg';
import { ReactComponent as CheckboxPartial } from 'assets/svgs/admin/usersAndPermissions/organizationsTree/checkbox-partial.svg';
import { ReactComponent as CheckboxSelected } from 'assets/svgs/admin/usersAndPermissions/organizationsTree/checkbox-selected.svg';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

export default function OrganizationsTree(props: {
  tradingPartners?: OrganizationLevelDocument[],
  organizations?: OrganizationLevelDocument[],
  selected: string[],
  setSelected: (selected: string[]) => void
}) {
  const { tradingPartners, organizations, selected, setSelected } = props;
  const organizationsSet = Object.fromEntries(organizations?.map(o => [o.id, o]) ?? []);

  const nodes = getOrganizationTreeFromList(tradingPartners, Object.values(organizationsSet));
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    setExpanded(getNodeIds(nodes))
  }, [organizations?.length])

  return (
    <CheckboxTree

      id="organizations-tree"
      nodes={nodes}
      checked={selected}
      expanded={expanded}
      onCheck={(checked) => setSelected(checked)}
      onExpand={(expanded) => setExpanded(expanded)}
      icons={{
        check: <CheckboxSelected />,
        uncheck: <CheckboxBase />,
        halfCheck: <CheckboxPartial />,
        expandClose: <ArrowDown />,
        expandOpen: < ArrowUp />,
        parentClose: null,
        parentOpen: null,
        leaf: null
      }}
    />
  );

  function getOrganizationTreeFromList(current?: OrganizationLevelDocument[], organizations?: OrganizationLevelDocument[]) {
    const nodes: Node[] = [];
    current?.filter((c, i, self) => self.findIndex(c2 => (c2.path === c.path)) === i).forEach(c => {
      const subchildren = getSubChildren(c, organizations);
      nodes.push({
        value: c.id,
        label: c.name,
        children: subchildren?.length ? getOrganizationTreeFromList(subchildren, organizations) : undefined
      })
    });
    return nodes;
  }
}

function getSubChildren(organization: OrganizationLevelDocument, organizations?: OrganizationLevelDocument[]) {
  return organizations?.filter(o => o.organizationLevelType == (organization.organizationLevelType + 1) && o.path.startsWith(organization.path)).sort((a, b) => a.name.localeCompare(b.name));
}

function getNodeIds(nodes?: Node[]) {
  let ids: string[] = [];

  nodes?.forEach(({ value, children }) => {
    ids = [...ids, value, ...getNodeIds(children)];
  });

  return ids;
}
