import React, { useEffect, useState } from 'react';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import {
  getTokenGUIDLookup,
  cleanError,
} from 'features/admin/tokenGUIDLookup/TokenGUIDLookupReducer';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import { EDS_TextArea } from '@EH/eh.eds.react';
import 'assets/styles/components/_tokenGuidLookup.scss';
import SearchBox from 'components/searchBox/searchBox';
import { AlertIds, AlertTypes, setAlert } from '../../features/alert/AlertReducer';
import { Button } from '@EHDS/core';
import { useTokenGUIDLookup } from '../../features/admin/tokenGUIDLookup/useTokenGUIDLookup';
import { useOrganizations } from '../../features/organizations/hooks/useOrganizations';
import { valueOrDefault } from '../../utils/Utils';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setBreadcrumbLinks([{ name: 'User Token Lookup', slug: rootPath }])
    );

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

export default function TokenGUIDLookup(
  props: Props = { rootPath: 'token-guid-lookup' }
) {
  const { rootPath } = props;
  const [guid, setGuid] = useState('');
  const dispatch = useDispatch();
  const guidLookup = useTokenGUIDLookup();

  useBreadcrumb(rootPath);

  const tokenUserMapping = useTypedSelector(s => s.tokenGuidLookup?.result);
  const error = useTypedSelector(s => s.tokenGuidLookup?.err);

  const { getOrganizationById } = useOrganizations();
  const facility = getOrganizationById(tokenUserMapping?.organizationReference.id);

  const onSearch = () => {
    if (guid.trim().length) {
      guidLookup(guid);
    }
  };
  const guidLookupAlert = { id: AlertIds.GUIDLookupAlert, type: AlertTypes.Error, message: 'No client data found, please check entered GUID'};

  useEffect(() => {
    if (error) {
      dispatch(setAlert(guidLookupAlert));
      dispatch(cleanError());
    }
    return () => { dispatch(cleanError()); }
  }, [error])

  return (
    <div className="token-guid-lookup">
      <div className="token-guid-lookup-title">
        <h3>User Token Lookup</h3>
        <div className="search-box">
          <div className="eds-field_#label">Search for GUID:</div>
          <div className="search-container">
            <SearchBox
              placeholder="Enter Facility's Token GUID"
              className={'search-box'}
              onClick={onSearch}
              value={guid}
              onChange={setGuid}
              name="searcBoxField"
            ></SearchBox>
            <Button
              onClick={onSearch}
              disabled={false}
            >Submit</Button>
          </div>
        </div>
      </div>
      {tokenUserMapping ?
        <div className="main-container">
          <div className="lookup-container">
            <div className="client-info">
              <h2 className={"eds-heading eds-heading.mdplus-caps mb-1"}> {"user token details".toUpperCase()}</h2>
              <div className="client-info-row">
                <div className="label">Facility ID:</div>
                <div>{valueOrDefault(facility?.clientId, "")}</div>
              </div>
              <div className="client-info-row">
                <div className="label">Facility User ID (GUID):</div>
                <div id="clientIdValue">
                  <a href={`/admin-settings/${facility?.id}/client-configuration`}>
                    {valueOrDefault(facility?.id, "")}
                  </a>
                </div>
              </div>
              <div className="client-info-row">
                <div className="label">Facility Host IP Adddresses:</div>
                <div>{valueOrDefault(tokenUserMapping?.clientHostID, "")}</div>
              </div>
              <div className="client-info-row">
                <div className="label">Display Error Message:</div>
                <div>{valueOrDefault(tokenUserMapping?.displayErrorMessage, "")}</div>
              </div>

              <div className="client-info-row">
                <div className="label">OS User ID:</div>
                <div>{valueOrDefault(tokenUserMapping?.user.oneSourceUserId, "")}</div>
              </div>

              <div className="client-info-row">
                <div className="label">Token User Name:</div>
                <div>{`${valueOrDefault(tokenUserMapping?.user.firstName, "")} ${valueOrDefault(tokenUserMapping?.user.lastName , "")}`}</div>
              </div>
            </div>
          </div>
        </div> : null
      }
    </div>
  );
}
