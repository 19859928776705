import { GenericAttribute } from "../../../../models/metaData/MetaData";
import { DataType } from "../../../../models/metaData/MetaDataEnums";
import { UserToken, UserTokenUserReference } from "../../../../services/TokenGUIDLookupService";
import useErrorStore from "../../../../utils/useErrorStore";
import useTypedValidator from "../../../../utils/useTypedValidator";
import { required } from "../../../paymentDashboard/Validators";

export const useTokenValidator = () => {
  const attributes: GenericAttribute<UserToken>[] = [
    { name: "clientHostID", label: "Facility Host IP Addresses", dataType: DataType.String, validators: [required()] },
    { name: "displayErrorMessage", label: "Display Error Message", dataType: DataType.String, validators: [required()] },
    { name: "oneSourceUserName", label: "OS Auth Login UN", dataType: DataType.String, validators: [required()] },
  ];

  return useAttributesWithValidator(attributes);
}

export const useUserValidator = () => {
  const attributes: GenericAttribute<UserTokenUserReference>[] = [
    { name: "id", label: "Facility User ID", dataType: DataType.String, validators: [required()] },
  ];

  return useAttributesWithValidator(attributes);
}

export function useAttributesWithValidator<T>(attributes: GenericAttribute<T>[]) {
  const { addError, removeError, errorsRef, errors, getErrorMessageAsString } = useErrorStore();


  const typedSelectorProps = useTypedValidator({
    attributes,
    errors,
    removeError,
    addError,
    dispatchable: false,
  }
  );

  return {
    ...typedSelectorProps,
    getErrorMessage: (key: string) => {
      return typedSelectorProps.getErrorMessage(key, errors);
    },
    errorClass: (key: string) => {
      return typedSelectorProps.errorClass(key, errors);
    },
    errorsRef,
    getErrorMessageAsString,
  }
}