import { Popup } from 'components/popup/Popup';
import PaymentPlanDetails from 'pages/Search/planSearch/PaymentPlanDetails';
import { useTypedSelector } from '../../app/rootReducer';
import { useDispatch } from 'react-redux';
import { deactiveAllPlans, resetErrors, setOpenedPlanId } from './PaymentPlanReducer';
import 'assets/styles/components/_paymentPlan.scss';
import { resetPatientMailingInformation } from '../paymentInfo/PatientMailingInformationReducer';

export default function (props: {
  id: string;
}) {
  const { id } = props;
  const dispatch = useDispatch();
  const openPlanId = useTypedSelector(s => s.paymentPlanInfo.openedPlanId);
  const openTransaction = useTypedSelector(s => s.transactionDetails.isOpenModal);
  const openModal = openTransaction || openPlanId;

  return <a className={'link-transaction-id'} href={`/planDetails/${id}`} target={"_blank"} rel="noreferrer" onClick={(e: any) => {
    if (!openModal) {
      e.preventDefault();
      dispatch(setOpenedPlanId(id));
    }
  }}>{id ?? ''}</a>
}

export function PaymentPlanDetailsModal() {
  const id = useTypedSelector(s => s.paymentPlanInfo.openedPlanId);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(deactiveAllPlans());
    dispatch(setOpenedPlanId());
    dispatch(resetPatientMailingInformation());
    dispatch(resetErrors());
  };
  const popupChildren = (
    <PaymentPlanDetails
      paymentPlanId={id}
      onClose={onClose}
    />
  )

  return <>
    {id ?
      <div className="edit-payment-plan-modal">
        <Popup
          header="PaymentSafe | Payment Plan Details"
          fullWidth={true}
          children={popupChildren}
          onClose={onClose}
        />
      </div> : null
    }</>;
}