import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TabPane } from 'reactstrap';
import { useTypedSelector } from 'app/rootReducer';
import { AlertIds, AlertTypes, removeAlert, setAlert } from 'features/alert/AlertReducer';
import { MainFilters } from './MainFilters/MainFilters';
import { SideFilters } from './SideFilters/SideFilters';
import { setAdvanceSearchInformation } from './AdvanceSearchReducer';
import 'typeface-roboto';
import styles from './advanceSearch.module.scss';
import { errorMessage, hasMissingMandatoryFields } from '../searchUtils';
import OrganizationUserSearch, { getOrganizationOptions, useGetUserDepartmentsOptions } from '../simpleSearch/OrganizationUserSearch';
import { getUserDepartmentOrFacilityesOptions } from 'utils/UtilsOrganizationDropdowns';
import { useUserUtils } from 'utils/useUserUtils';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';

export default function AdvanceSearch() {
  const advanceSearchInfo = useTypedSelector(
    s => s.advanceSearchInfo?.value || []
  );
  const advanceSearchState = useTypedSelector(s => s.advanceSearchInfo);
  const { useGetFacilities } = useOrganizations();
  const allFacilities = useGetFacilities();
  const { getLoggedUserOrganizations } = useUserUtils();
  const facilities = allFacilities;
  const userOrganisations = getLoggedUserOrganizations();
  const userFacilityOptions =  getUserDepartmentOrFacilityesOptions(facilities, userOrganisations)
  const facilityOptions = getOrganizationOptions( userFacilityOptions);
  const getUserDepartmentsOptions = useGetUserDepartmentsOptions();
  const departmentsOptions = getUserDepartmentsOptions(userOrganisations);
  const [advancedSearchInitialized, setAdvancedSearchInitialized] = useState(false); 
  const advancedSearchSaveSettingsChecked = useTypedSelector(s=> s.advanceSearchInfo?.saveSettings ?? false);
  const dispatch = useDispatch();


  useEffect(()=>{
    if (departmentsOptions?.length > 0 && !advancedSearchSaveSettingsChecked && !advancedSearchInitialized) {
      const currentAdvancedState = {...advanceSearchInfo, facilities: facilityOptions, deparments: departmentsOptions};
      dispatch(setAdvanceSearchInformation(currentAdvancedState));    
      setAdvancedSearchInitialized(true);
    }
  }, [departmentsOptions?.length]);

  useEffect(() => {
      hasMissingMandatoryFields(mandatoryFields)
        ? dispatch(setAlert({
          id: AlertIds.SearchAlert,
          type: AlertTypes.Warning,
          message: errorMessage(hasMissingMandatoryFields(mandatoryFields)),
          dismissable: true 
        }))
        : closeErrorMessage();
  }, [
    advanceSearchInfo.facilities,
    advanceSearchInfo.transactionTypes,
    advanceSearchInfo.tenderTypes,
    advanceSearchInfo.cardEntryTypes,
  ]);


  const mandatoryFields: { [key: string]: any } = {
    'Facility': !!advanceSearchInfo.facilities,
    'Department': !!advanceSearchInfo.departments,
    'Transaction type': !!advanceSearchInfo.transactionTypes?.find(transaction => transaction.isChecked),
    'Tender type': !!advanceSearchInfo.tenderTypes?.find(tender => tender.isChecked),
  };

  const closeErrorMessage = () => {
    dispatch(removeAlert({ id: AlertIds.SearchAlert }));
  };

  return (
    <TabPane className="tab-pane-content" tabId="2">
      <div className={`container`}>
        <OrganizationUserSearch
          preselected={true}
          state={advanceSearchState.value}
          setState={(state) => { dispatch(setAdvanceSearchInformation({ ...advanceSearchState.value, ...state })) }}
        />
        <div style={{ marginTop: '20px' }} className={`row`}>
          <div className={`col-md-4`}>
            <SideFilters
              transactionTypes={advanceSearchInfo?.transactionTypes || []}
              tenderTypes={advanceSearchInfo?.tenderTypes || []}
              cardEntryTypes={advanceSearchInfo?.cardEntryTypes || []}
            />
          </div>
          <div className={[`col`, styles.mainFiltersContainer].join(' ')}>
            <MainFilters
              setShowAlert={() => { /* empty */ }}
            />
          </div>
        </div>
      </div>
    </TabPane>
  );
}
