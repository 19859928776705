import { AgGridReact } from 'ag-grid-react';
import { LineItems } from 'models/LineItem';
import { RelatedTransactionUIData } from './TransactionDetailsReducer';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'assets/styles/components/_transactionDetails.scss';
import { LinkRenderer } from '../../components/CellRenderers';
import EnumTransactionType from 'models/enums/EnumTransactionType';
import { getNegativeNumber } from 'utils/Utils';

// TODO add unit tests to verify UI snapshot and behavior of the transaction id links
// jira ticket # TBD
export function RelatedTransactions(props: {
  relatedTransactionArray?: RelatedTransactionUIData[];
}): JSX.Element {
  const { relatedTransactionArray } = props;

  if (!relatedTransactionArray) {
    return <></>;
  }

  const getAccountNumber = (params: { data: { lineItems: LineItems } }) => {
    return params.data.lineItems && params.data.lineItems[0].accountNumber || '';
  }

  const getAmount = (params: { value: number, data: { transactionType: string; }}) => {
    const isCreditVoid = 
      params.data.transactionType == EnumTransactionType.Credit || 
      params.data.transactionType == EnumTransactionType.Void ||
      params.data.transactionType == EnumTransactionType.GLCredit || 
      params.data.transactionType == EnumTransactionType.GLVoid
    ;
    let value = params.value;
    if (isCreditVoid) {
      value = getNegativeNumber(params.value) ?? 0;
    }
    return value.toString();
  }

  const headerDefinitions = [
    {
      headerName: 'Transaction ID',
      field: 'transactionId',
      sortable: true,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      resizable: true,
      cellRenderer: 'LinkRenderer',
    },
    {
      headerName: 'Account Number',
      field: 'accountNumber',
      valueFormatter: getAccountNumber,
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      valueFormatter: getAmount,
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: 'Transaction Type',
      field: 'transactionType',
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: 'Transaction Status',
      field: 'statusType',
      sortable: true,
      filter: true,
      resizable: true,
    },
  ];

  return (
    <div className="ag-theme-alpine">
      <AgGridReact
        gridAutoHeight={true}
        defaultColDef={{ flex: 1 }}
        columnDefs={headerDefinitions}
        rowData={relatedTransactionArray}
        suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
        frameworkComponents={{
          LinkRenderer,
        }}
        deltaRowDataMode={true}
        getRowNodeId={(data) => data.id}
      />
    </div>
  );
}

export default RelatedTransactions;

