import React from 'react';
import { ReactComponent as ErrorIcon } from 'assets/svgs/icon-error.svg';
import { ReactComponent as InfoIcon } from 'assets/svgs/icon-info.svg';
import { ReactComponent as WarningIcon } from 'assets/svgs/icon-warning.svg';
import { ReactComponent as SuccessIcon } from 'assets/svgs/icon-check.svg';
import { ReactComponent as Close } from 'assets/svgs/icon-close.svg';
import style from './alertPopup.module.scss';
import { AlertTypes } from 'features/alert/AlertReducer';

interface CustomStyle {
  container?: string;
}

interface Props {
  header?: string | React.ReactElement;
  content?: string[];
  full?: boolean;
  customStyle?: string;
  onClose: () => void;
  customClassNames?: CustomStyle;
  alertType: AlertTypes;
}

const buildClasses = (full: boolean) => {
  if (!full) return '';

  return style.popupContainerFullHeight + style.popupContainerFullWidth;
};

const buildRightPaneHeaderClasses = (alertType: AlertTypes) => {
  if (alertType === AlertTypes.Warning) {
    return style.headerWarning;
  }

  if (alertType === AlertTypes.Success) {
    return style.headerSuccess;
  }
  if (alertType === AlertTypes.Error) {
    return style.headerError;
  }

  return style.headerInfo;
};

const buildLeftPaneHeaderClasses = (alertType: AlertTypes) => {
  if (alertType === AlertTypes.Warning) {
    return style.backgroundWarning;
  }

  if (alertType === AlertTypes.Success) {
    return style.backgroundSuccess;
  }

  if (alertType === AlertTypes.Error) {
    return style.backgroundError;
  }

  return style.backgroundInfo;
};

const getIcon = (alertType: AlertTypes) => {
  if (alertType === AlertTypes.Warning) return <WarningIcon />;
  if (alertType === AlertTypes.Error) return <ErrorIcon />;
  if (alertType === AlertTypes.Success) return <SuccessIcon />;

  return <InfoIcon />;
};

const getErrorContent = (content?: string[]) => {
  return <ul>{content && content?.map(task => <li>{task}</li>)}</ul>;
};

export const AlertPopUp: React.FC<Props> = props => {
  const {
    alertType = AlertTypes.Info,
    header,
    content,
    full = false,
    onClose,
  } = props;

  return (
    <div className={style.screen}>
      <div
        className={`${style.popupContainer} 
        ${buildClasses(full)}}
      `}
      >
        <div
          className={`${style.leftPane} ${buildLeftPaneHeaderClasses(
            alertType
          )}`}
        >
          {getIcon(alertType)}
        </div>
        <div className={style.rightPane}>
          <header
            className={`${style.header} ${buildRightPaneHeaderClasses(
              alertType
            )}`}
          >
            <div className={style.popupTextHeader}>{header || alertType}</div>
            <div className={style.closeIcon} onClick={onClose}>
              <Close />
            </div>
          </header>
          {content != undefined ? (
            <div className={style.content}>{getErrorContent(content)}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
