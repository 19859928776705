import { CardTokenResponse } from 'models/CardTokenResponse';
import { callHttpApi } from 'services/callHttpApi';
import { ECheckTokenizationRequest } from './models/ECheckTokenizationRequest';

export async function ECheckTokenizationService(
  tokenRequest: ECheckTokenizationRequest
) {
  return callHttpApi<CardTokenResponse, ECheckTokenizationRequest>(
    'post',
    `/api/v1/Transactions/achTokenization`,
    tokenRequest
  );
}
