import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { TenderInfo } from 'models/PaymentPlan';
import { getNextMonthDate } from 'utils/UtilsDateTime';
import { ReactComponent as Calendar } from 'assets/svgs/paymentPlanIcons/icon-calendar.svg';
import 'assets/styles/components/_paymentPlan.scss';
import { ReactComponent as VisibilityOn } from 'assets/svgs/paymentPlanIcons/icon-eye-open.svg';
import { ReactComponent as VisibilityOff } from 'assets/svgs/paymentPlanIcons/icon-eye-closed.svg';
import { MouseEvent, useState } from 'react';

export function TenderCardManualCart(props: {
  isPlan?: boolean;
  source: any;
  popoverElement?: JSX.Element;
  formatLabel: (name: keyof TenderInfo, label: string) => string;
  errorClass: (name: keyof TenderInfo) => 'invalid-field' | '';
  onChangeCardData: (event: React.ChangeEvent<HTMLInputElement>, name: keyof TenderInfo) => void;
  handleNameChange: (event: React.ChangeEvent<HTMLInputElement>, name: keyof TenderInfo) => void
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, name: keyof TenderInfo) => void;
  handleDatePickerChange?: (date: Date | [Date, Date]) => void;
  changeDateHandler?: (date: Date, name: keyof TenderInfo, event?: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  showInputError: (name: keyof TenderInfo) => string | undefined;
  onBlur: any;
}) {
  const {
    isPlan,
    source,
    popoverElement,
    formatLabel,
    errorClass,
    handleChange,
    changeDateHandler,
    showInputError,
    onBlur,
  } = props;

  const config: { [key: string]: { [key in keyof TenderInfo]: keyof TenderInfo } } = {
    plan: {
      firstName: 'firstName',
      lastName: 'lastName',
      expirationDate: 'expiration',
      zipCode: 'zipCode',
    },
    payment: {
      firstName: 'billingFirstName',
      lastName: 'billingLastName',
      expirationDate: 'expiration',
      zipCode: 'billingZipCode',
    },
  };

  const getProp = (prop: keyof TenderInfo) => (isPlan ? config.plan[prop] : config.payment[prop]) ?? prop;

  const [isVisiblityOn, setIsVisibilityOn] = useState(false);
  const [focused, setFocused] = useState<keyof TenderInfo | undefined>();

  const getOnChange = (propertyName: keyof TenderInfo) => (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e, getProp(propertyName))
  }

  return (
    <>
      <div className="flex-row">
        <div className={'eds-field row-item row-item-size'}>
          <label
            className={`eds-field ${errorClass(getProp('firstName'))}`}
            htmlFor={getProp('firstName')}
          >
            <div className={'eds-field_#label'}>
              {formatLabel(getProp('firstName'), '*First Name')}
            </div>
            <div
              className={`eds-field_#control eds-field_.eds-input eds-input ${errorClass(
                getProp('firstName')
              )}`}
            >
              <input
                className={`eds-input_#input`}
                id={getProp('firstName')}
                type={'text'}
                name={getProp('firstName')}
                onChange={getOnChange('firstName')}
                onBlur={() => onBlur(getProp('firstName'))}
                value={source[getProp('firstName')] || ''}
                autoComplete={'off'}
              />
            </div>
            <span className={`${errorClass(getProp('firstName')) ? 'error-message-required' : ''}`}>
              {showInputError(getProp('firstName'))}
            </span>
          </label>
        </div>
        <div className={'eds-field row-item row-item-size'}>
          <label
            className={`eds-field ${errorClass(getProp('lastName'))}`}
            htmlFor={getProp('lastName')}
          >
            <div className={'eds-field_#label'}>
              {formatLabel(getProp('lastName'), '*Last Name')}
            </div>
            <div
              className={`eds-field_#control eds-field_.eds-input eds-input`}
            >
              <input
                className={`eds-input_#input`}
                id={getProp('lastName')}
                type={'text'}
                name={getProp('lastName')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e, getProp('lastName'))
                }}
                onBlur={() => onBlur(getProp('lastName'))}
                value={source[getProp('lastName')] || ''}
                autoComplete={'off'}
              />
            </div>
            <span className={`${errorClass(getProp('lastName')) ? 'error-message-required' : ''}`}>
              {showInputError(getProp('lastName'))}
            </span>
          </label>
        </div>
      </div>
      <div className="flex-row">
        <label
          className={`eds-field row-item row-item-size-double ${errorClass(
            'cardNumber'
          )}`}
          htmlFor={'cardNumber'}
        >
          <div className={'eds-field_#label'}>
            {formatLabel('cardNumber', '*Card Number')}
          </div>
          <div className="visibility-button-wrapper">
            <span onClick={(e: MouseEvent) => {
              e.preventDefault();
              setIsVisibilityOn(!isVisiblityOn);
            }}>
              {isVisiblityOn ? <VisibilityOn /> : <VisibilityOff />}
            </span>
          </div>
          <div
            className={`eds-field_#control eds-field_.eds-input eds-input`}
          >
            <InputMask
              className={`eds-input_#input ${!isVisiblityOn && focused != 'cardNumber' ? "visiblityOff" : ''}`}
              id={'cardNumber'}
              name={'cardNumber'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e, 'cardNumber')
              }}
              value={source.cardNumber}
              autoComplete={'off'}
              mask="9999 9999 9999 9999"
              maskChar=""
              onFocus={() => setFocused('cardNumber')}
              onBlur={() => { setFocused(undefined); onBlur('cardNumber') }}
            />
          </div>
          <span className={`${errorClass('cardNumber') ? 'error-message-required' : ''}`}>
            {showInputError('cardNumber')}
          </span>
        </label>
      </div>
      <div className="flex-row">
        <label
          className={`eds-field row-item row-item-size ${errorClass(
            'expirationDate'
          )}`}
          htmlFor={'expirationDate'}
        >
          <div className={'eds-field_#label'}>
            {formatLabel('expirationDate', '*Expiration Date')}
          </div>
          <div className="expiration-date-calendar-icon">
            <Calendar />
          </div>
          <div
            className={`eds-field_#control eds-field_.eds-input eds-input`}
          >
            <DatePicker
              onChange={(date: Date, e: React.ChangeEvent<HTMLInputElement>) => date && changeDateHandler && changeDateHandler(date, 'expiration', e)}
              showMonthYearPicker
              isClearable={false}
              className={`eds-input_#input`}
              minDate={getNextMonthDate()}
              shouldCloseOnSelect={true}
              name="expirationDate"
              dateFormat="MM/yy"
              onBlur={() => onBlur('expirationDate')}
              selected={source[getProp('expiration')] ? moment.utc(source[getProp('expiration')]).toDate() : null}
              placeholderText='MM/YY'
            />
          </div>
          <span className={`${errorClass('expiration') ? 'error-message-required' : ''}`}>
            {showInputError('expiration')}
          </span>
        </label>
        <label
          className={`eds-field row-item row-item-size ${errorClass(
            'cvc'
          )}`}
          htmlFor={'cvc'}
        >
          <div
            className={
              'eds-field_#label tender-card-manual-cvc-code-label'
            }
          >
            {formatLabel('cvc', '*CVC')}
          </div>
          <div className="tender-card-manual-cvc-code-wrapper">
            {popoverElement}
          </div>
          <div
            className={`eds-field_#control eds-field_.eds-input eds-input`}
          >
            <InputMask
              className={`eds-input_#input ${!isVisiblityOn && focused != 'cvc' ? "visiblityOff" : ''}`}
              id={'cvc'}
              name={'cvc'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e, 'cvc')
              }}
              value={source.cvc}
              autoComplete={'off'}
              mask="9999"
              maskChar=""
              onFocus={() => setFocused('cvc')}
              onBlur={() => { setFocused(undefined); onBlur('cvc') }}
            />
          </div>
          <span className={`${errorClass('cvc') ? 'error-message-required' : ''}`}>
            {showInputError('cvc')}
          </span>
        </label>
      </div>
    </>
  )
}