import { Input } from "@EHDS/core";
import { EDS_Select } from '@EH/eh.eds.react';
import React, { ChangeEvent, useState } from "react";
import { Popup } from "../../../../components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "../../../../models/enums/EnumPopupType";
import { formatOptions } from "../../../../pages/Admin/Remit/AddNewRemitModal";
import MultiSelect from "../../../../components/select/MultiSelect";
import { getOrganizationOptions } from "../../../../pages/Search/simpleSearch/OrganizationUserSearch";
import { useOrganizations } from "../../../organizations/hooks/useOrganizations";

interface CloneRemitConfigurationFormFields {
  fileIdentifier?: string,
  name?: string,
  fileFormat?: string,
  destinationClient?: string,
}

export function CloneRemitConfigurationModal(props: {
  originalFileName?: string;
  originalFileType?: string;
  originalFileIdentifier?: string;
  close: () => void;
  onNext: (state: {
    fileIdentifier: string,
    fileName: string,
    fileExtension: string,
    destinationClientId: string,
  }) => void;
}) {

  const { close, onNext, originalFileIdentifier, originalFileName, originalFileType } = props;
  const [state, setState] = useState<CloneRemitConfigurationFormFields>({
    fileIdentifier: originalFileIdentifier,
    name: originalFileName,
    fileFormat: originalFileType ?? formatOptions[0].value,
  });
  const { useGetFacilities, getFacilityByPath } = useOrganizations();
  const facilities = useGetFacilities();

  const changeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    let value = event.target.value;
    let name = event.target.name;
    setSingleStateProperty(name, value);
  };

  const next = () => {
    const destinationClient = getFacilityByPath(state.destinationClient);
    if (destinationClient && state.name && state.fileFormat && state.fileIdentifier) {
      onNext({
        fileIdentifier: state.fileIdentifier,
        fileName: state.name,
        fileExtension: state.fileFormat,
        destinationClientId: destinationClient.id,
      });
    }
  };

  const footerButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel',
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Confirm',
      disabled: !state.name || !state.destinationClient || !state.fileIdentifier
    },
  ];

  function setSingleStateProperty(name: string, value: any){
    setState({...state, [name]: value});
  }

  const facilityOptions = getOrganizationOptions(facilities);

  return (
    <Popup
      header="PaymentSafe®"
      onClose={close}
      title="Clone Remit Configuration"
      footer={footerButtons}
      onSubmit={next}
      customClassNames={{ footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
    >
      <div className="popupContent remitCloneModalContent">
        <div className="rowItemPopup">
          <Input
            label={'File Identifier:'}
            name={'fileIdentifier'}
            placeholder={"Enter File  Identifier"}
            className={'inputItem'}
            value={state.fileIdentifier}
            onChange={changeHandler}
          />
        </div>
        <div className="rowItemPopup">
          <Input
            label={'File Name:'}
            name={'name'}
            placeholder={"Enter File Name"}
            className={'inputItem'}
            value={state.name}
            onChange={changeHandler}
          />
        </div>
        <div className="rowItemPopup">
          <EDS_Select
            name="fileFormat"
            label="File Format"
            modifiers={'inputItem'}
            options={formatOptions}
            onChange={changeHandler}
            value={state.fileFormat ?? formatOptions[0]}
          ></EDS_Select>
        </div>
        <div className="rowItemPopup">
          <MultiSelect
            label={"Destination Client:"}
            name={"destinationClient"}
            options={facilityOptions}
            onChange={(selected, propertyName) => { setSingleStateProperty(propertyName, selected?.value) }}
            values={facilityOptions.find(o => o.value === state.destinationClient) ?? null}
            multiple={false}
            disableCloseOnSelect={false}
          />
        </div>
      </div>
    </Popup>
  );
}