import { PatientInfoModel } from "features/patientInfo/PatientInfoReducer";
import { PaymentPanelDataType, PaymentPanelFieldType, PaymentPanelType } from "models/enums/EnumPaymentPanels";
import { PatientPanelApiConfiguration } from "services/PatientPanelService";
import { MetaData } from "../../../../models/metaData/MetaData";
import { Dashboards } from "../ConfigurationSelector";
import { useUpdatePatientPanelConfiguration } from "./update/useUpdatePatientPanelConfiguration";

export function useUpdatePatientConfiguration(key?: keyof Dashboards) {
  const { updatePatientPanelConfiguration, updatePatientPanelConfigurationDraft } = useUpdatePatientPanelConfiguration(key);
  function updatePatientConfiguration(configuration?: MetaData, isLive: boolean = false) {

    const currentEntity = configuration?.entity;

    const liveServices: { [key in keyof Dashboards]: (configuration: PatientPanelApiConfiguration) => void } = {
      PatientSearchPopupDashboard: updatePatientPanelConfiguration,
      PatientSearchPopupDashboardDraft: updatePatientPanelConfiguration,
    }

    const draftServices: { [key in keyof Dashboards]: () => void } = {
      PatientSearchPopupDashboard: updatePatientPanelConfigurationDraft,
      PatientSearchPopupDashboardDraft: updatePatientPanelConfigurationDraft,
    }

    if (configuration && currentEntity && key) {
      const services = isLive ? liveServices : draftServices;
      const service = services[key];

      service && service({
        colorCode: currentEntity.colorCode,
        organization: currentEntity.organization,
        type: PaymentPanelType.PatientSearch,
        panelTitle: currentEntity.panelTitle ? currentEntity.panelTitle : "DummyTitle",
        isDraft: !isLive,
        id: configuration.id,
        fieldSettings: currentEntity.attributes.map(e => ({
          label: e.label,
          visible: !!e.visible,
          saveAs: e.saveAs as keyof PatientInfoModel,
          dataType: PaymentPanelDataType.Text,
          fieldType: e.fieldType,
          fieldTypeOnAddAccount: e.fieldTypeOnAddAccount ?? PaymentPanelFieldType.TextInput,
          placeholderText : e.placeholderText,
          format: e.format,
          maxLength: e.maxLength,
          visibleOnReceipt: !!e.visibleOnReceipt,
          required: !!e.required,
        }))
      });
    }
  }

  return {
    updatePatientConfiguration
  }
}
