import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWebServiceErrors } from 'models/WebServiceErrors';
import {
  getWebServiceErrorService,
  GetWebServiceErrorResponse,
} from 'services/WebServiceErrorService';
import { AppThunk } from 'app/rootReducer';
import { callService } from '../../services/ServicesReducer';

export interface State {
  err?: string;
  result?: IWebServiceErrors[];
}

export const initialState: State = {
  err: '',
  result: undefined,
};

const reducerSlice = createSlice({
  name: 'WebServiceError',
  initialState,
  reducers: {
    onReceiveWebServiceError(
      state,
      action: PayloadAction<GetWebServiceErrorResponse>
    ) {
      state.result = action.payload.result;
      state.err = action.payload.err;
    },
    setReceiveWebService(
      state,
      action: PayloadAction<GetWebServiceErrorResponse>
    ) {
      state.result = action.payload.result;
    },
    resetWebServiceError(state) {
      state.result = initialState.result;
      state.err = initialState.err;
    },
  },
});

export const {
  onReceiveWebServiceError,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getWebServiceError(params: any, onDone?: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService("GetWebServiceError", async () => {
      const response = await getWebServiceErrorService(params);
      dispatch(onReceiveWebServiceError(response));
      if (onDone) onDone();
    }))
  };
}

export const {
  setReceiveWebService,
  resetWebServiceError,
} = reducerSlice.actions;
