import { Template, ParseRequest, ParseResponse } from 'models/RemitTemplatesModel';
import { callHttpApi } from './callHttpApi';

export async function parseTemplate(data: ParseRequest): Promise<ParseResponse> {
  return callHttpApi<Template, ParseRequest>(
    'post',
    '/api/v1/RemitTemplates/parse',
    data
  );
}

export interface ConditionalTemplate {
  name: string;
  formula: string;
  id: string;
}

export async function getTemplates() {
  return callHttpApi<ConditionalTemplate[]>(
    'get',
    '/api/v1/RemitTemplates'
  );
}