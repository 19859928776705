import { useTypedSelector } from "../../../app/rootReducer";
import { LineItem } from "../../../models/LineItem";
import { toLowerCaseFirstLetter } from "../../../utils/Utils";

export function useSortLineItems(organizationPath?: string) {
  let configuration = useTypedSelector(s => s.paymentDashboard?.panelConfigurations?.[organizationPath ?? '']?.configuration?.entity);

  function sortLineItems(lineItemsToSort: LineItem[]) {
    const inactive = lineItemsToSort.filter(l => !l.isActive) ?? [];
    const active = lineItemsToSort.filter(l => l.isActive);
    let sortedLineItems = [...active];
    if (configuration) {
      const { sortByField, sortDescending } = configuration;
      if (sortByField) {
        sortedLineItems.sort((a, b) => sortBy(sortByField, !!sortDescending, a, b))
      }
    }
    return [ ...inactive, ...sortedLineItems];
  }

  function sortBy(sortByField: keyof LineItem, sortDescending: boolean, a: LineItem, b: LineItem) {
    const mappedSortByField = toLowerCaseFirstLetter(sortByField) as keyof LineItem;
    let first = a[mappedSortByField];
    let second = b[mappedSortByField];
    if (sortDescending) {
      first = b[mappedSortByField];
      second = a[mappedSortByField];
    }

    if (first > second) return 1;
    if (first < second) return -1;
    return 0;
  }

  return {
    sortLineItems,
    configuration
  }
}