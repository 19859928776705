import React, { useEffect, useState } from 'react';
import { EDS_PrimaryHeader } from '@EH/eh.eds.react';
import { Link, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'app/rootReducer';
import 'assets/styles/components/_header.scss';
import IdleTimeout from 'features/idleTimeout/IdleTimeout';
import { getAppSettings } from 'services/AppSettingsService';
import { BiExit } from 'react-icons/bi';
import { FaUserCircle } from 'react-icons/fa';
import { ReactComponent as ImpersonatorIcon } from 'assets/svgs/impersonator-icon.svg';
import ExperianLogo from 'assets/styles/ExperianLogo.svg';
import { useRemoveImpersonateUser, useUserUtils } from '../../utils/useUserUtils';
import { getUserName, valueOrDefault } from 'utils/Utils';
import { useGetUsersOptions } from "../../pages/Search/simpleSearch/OrganizationUserSearch";
import { GetAccessToPowerReporting, GetUserDetailService } from 'services/UsersService';

export const Header = ({ idleTimer = 0 }) => {
  const [idleTimeout, setIdleTimeout] = useState(idleTimer);
  const [tokenRefreshTimeout, setTokenRefreshTimeout] = useState(0);
  const [displayedUserName, setDisplayedUserName] = useState('');

  const { redirectUserToHome } = useUserUtils();

  const loginState = useTypedSelector(p => p.loginInfo?.value);
  const loggedUser = useTypedSelector(p => p.loginInfo?.loggedUser);
  const { getUsers } = useGetUsersOptions();
  const removeImpersonateUser = useRemoveImpersonateUser();
  const location = useLocation();

  useEffect(() => {
    return () => { 
      if(!location.pathname.includes('/admin-settings')){
        clearLocalStorage();
      } 
    }
  }, [location])

  const logout = () => {
    const chipUserId = localStorage && localStorage.getItem('chipUserId');
    if (localStorage && chipUserId) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('chipUserId');
      localStorage.removeItem('name');
      localStorage.removeItem('isPowerReportingAccessible');
      clearLocalStorage();
      const logoutURL = `${window?.location?.origin}/api/v1/Authentication/logout?chipUserId=${chipUserId}`;
      window?.location?.replace(logoutURL);
    }
  };

  async function loadSettings() {
    const appSettings = await getAppSettings();
    setIdleTimeout(appSettings?.IDLE_TIME_OUT || 600000);
    setTokenRefreshTimeout(appSettings?.TOKEN_REFRESH_TIMEOUT || 839000);
  }

  async function clearLocalStorage() {
    localStorage.removeItem('remitManagementState');
  }

  useEffect(() => {
    loadSettings();
    getUsers(() => {
      // This is intentional
    });
  }, []);

  useEffect(() => {
    if(loggedUser?.oneSourceUserId || loggedUser?.impersonatedUserOneSourceId) {
      getLoggedUserDetails();
    }
  }, [loggedUser?.oneSourceUserId, loggedUser?.impersonatedUserOneSourceId]);

  const getLoggedUserDetails = async () => {
    const userDetail = await GetUserDetailService('', '', valueOrDefault(loggedUser?.impersonatedUserOneSourceId, loggedUser?.oneSourceUserId));
    const user = userDetail?.result?.find(x => x.firstName);
    const powerReporting = await GetAccessToPowerReporting(user ? user.id : '');
    
    localStorage.setItem('isPowerReportingAccessible', JSON.stringify(valueOrDefault(powerReporting.result?.isPowerReportingAccessible, false)));
    
    setDisplayedUserName(getUserName(user));
  };

  const onEndImpersonation = async () => {
    if(loggedUser?.impersonatedUserOneSourceId){
      await removeImpersonateUser(); //not passing id will act as ending the current impersonation
    }
    if(window.location.pathname === '/no-permissions'){
      return;
    }
  }

  const UserIcon = (
    loggedUser?.impersonatedUserOneSourceId
      ?<div>
        <span>Impersonating: </span>
        <ImpersonatorIcon
          title={`Click to end Impersonation of ${ displayedUserName }`}
        /> 
      </div> 
      
      : <FaUserCircle />
  );

  const getHeaderStyle = (
    loggedUser?.impersonatedUserOneSourceId
      ? "impersonation-header" : ""
  );

  const logoutData = loginState.loggedIn ? (
    <div className="header">
      <div className="eds-card_.eds-toolbar eds-toolbar">
        <div className="eds-button eds-button.tertiary header-section user-name" onClick={onEndImpersonation}>
          { UserIcon }
          <span>{ displayedUserName }</span>
          {/* <AiFillCaretDown /> */}
        </div>
        <div className="eds-toolbar">
          {/* <button className="eds-button eds-button.tertiary header-section">
            <IoMdSettings />
            <span className="eds-button_#label">Settings</span>
          </button> */}
          <button
            className="eds-button eds-button.tertiary header-section header-section user-name"
            onClick={logout}
          >
            <BiExit />
            <span className="eds-button_#label">Logout</span>
          </button>
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div>
      {loginState.loggedIn && idleTimeout > 0 ? (
        <div>
          <IdleTimeout
            idleTimeOut={idleTimeout}
            tokenRefreshTimeout={tokenRefreshTimeout}
            logOut={logout}
          />
          <EDS_PrimaryHeader
            logo
            logoImageSrc={ExperianLogo}
            title="PaymentSafe®"
            LogoLinkComponentType={Link}
            slugLogo={'/landing'}
            children={logoutData}
            modifiers={`main-header ${getHeaderStyle}`}
          ></EDS_PrimaryHeader>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
