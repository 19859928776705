import { EDS_Button } from '@EH/eh.eds.react';

export function NormalStepButtons(props: {
  isProcessing?: boolean,
  onBack?: () => void,
  cancel?: () => void,
  onNext?: () => void,
  disabled?: boolean,
}) {
  const {
    onBack,
    cancel,
    isProcessing,
    onNext,
    disabled
  } = props;
  const back = () => {
    if (onBack) onBack();
  };

  return <div className="footer-buttons">
    <EDS_Button
      modifiers={'eds-button eds-button.basic'}
      name={'back'}
      buttonText={'Back'}
      onClick={back}
      iconName={'chevron_left'}
    />
    <div className="footer-flex">
      <EDS_Button
        modifiers={'mr-2 eds-button eds-button.basic'}
        name={'cancel'}
        buttonText={'Cancel'}
        onClick={cancel}
      />
      <EDS_Button
        modifiers={'eds-button eds-button.primary button-reverse'}
        name={'createPaymentPlan'}
        buttonText={isProcessing ? 'Processing' : 'Next'}
        onClick={onNext}
        iconName={'chevron_right'}
        disabled={disabled}
      />
    </div>
  </div >
}