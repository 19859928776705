import moment, { Moment } from 'moment';
import jwtDecode from 'jwt-decode';
import { LoginInformation } from 'pages/Loginpage/reducers/LoginPageReducer';
import { EnumTransactionStatusType } from 'models/enums';
import { CreditCardBrand } from 'models/enums/CreditCardBrand';
import { BaseUserDetailModel, UserDetailModel } from 'models/UserInfoAndRolesModel';
import EnumTransactionTenderType from 'models/enums/EnumTransactionTenderType';
import { MultiSelectOption } from '../components/select/MultiSelect';
import { LineItems } from '../models/LineItem';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import { ProcessTransactionResponse } from 'models/Transaction';
import { PaymentConfiguration } from 'models/PaymentsConfiguration';
import { EnumTermType } from 'models/enums/EnumPaymentPlan';
import { convertDateToLocaleDateString } from './UtilsDateTime';
import { DataType } from 'models/metaData/MetaDataEnums';
import { PermissionItem } from 'models/Permission';
import UserPermissions from 'models/enums/UserPermissions';
import { FullOrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { PatientModel } from 'models/PatientModel';
import { PatientInfoModel } from 'features/patientInfo/PatientInfoReducer';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import { ObsoleteTransactionTypesEnumLabels } from 'models/admin/RemitConfiguration';
import { IGetRowsParams } from 'ag-grid-community';
import { DepartmentMultiselectOption } from 'pages/Search/simpleSearch/OrganizationUserSearch';
import { getPaymentPlanConfigurationService } from 'services/PaymentPlanService';

export const RegexTemplates = {
  MonthDayYear: /^\d{2}\/\d{2}\/\d{4}$/,
  Amount: /^\d*(\d\.\d{1,6})?$/ // regex to accept only numbers and decimal point
}

export function formatCurrency(n: string) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatAmount(amount: any) {
  // format amount to show thousands separator ',' and decimal point '.'
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function unformatAmount(amount: any) {
  // remove amount format so operations can be performed correctly
  return amount.toString().replace(/,/g, '');
}

export function displayAmount(
  amount: any,
  alternative: any = 0,
  precision: number = 2
) {
  const unformattedAmount = unformatAmount(amount ?? 0);

  return amount
    ? formatAmount(
        (Math.ceil(+(unformattedAmount * 100).toFixed(1)) / 100).toFixed(precision)
      )
    : alternative.toFixed(precision);
}

export function displayBalanceAmount(string: string) {
  const delimiterIndex = String(string).lastIndexOf('.');
  const firstPart = string.substring(0, delimiterIndex);
  const lastPart = string.substring(delimiterIndex + 1);
  return { firstPart, lastPart };
}

let onlyNumbersRegex: RegExp;
export function regexForNumbersOnly(): RegExp {
  if (onlyNumbersRegex) {
    return onlyNumbersRegex;
  }
  // regex to accept only numbers
  onlyNumbersRegex = /^[0-9]*$/;
  return onlyNumbersRegex;
}

export function emailValidation(email: string) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

export function formatValidation(format: string, value: string) {
  switch(format) { 
    case '$0.00':
    case '0.00': 
    case '$.00':
    case '.00':
    case '%': {
      return RegexTemplates.Amount.test(value); 
    } 
    case 'mm/dd/yyyy': { 
      return RegexTemplates.MonthDayYear.test(value); 
    }
    default: { 
       return true; 
    } 
 }
}

export function regexValidation(regex: string, value: string){
  const testExpression = new RegExp(regex);
  return testExpression.test(value);
}

export function getDiscountedBalance(balance: number, discount: number) {
  // deduct discount% from balance
  return balance - balance * (discount / 100);
}

export function template(tpl: string, values: { [key: string]: any }) {
  const arr: string[] = [];
  let firstPos: number;
  let result: RegExpExecArray | null = /{{\s*(.*?)\s*}}/g.exec(tpl);

  while (result) {
    firstPos = result.index;
    if (firstPos !== 0) {
      arr.push(tpl.substring(0, firstPos));
      tpl = tpl.slice(firstPos);
    }

    arr.push(values[result[1]]?.toString());
    tpl = tpl.slice(result[0].length);
    result = /{{\s*(.*?)\s*}}/g.exec(tpl);
  }

  if (tpl) arr.push(tpl);

  return arr.join('');
}

export enum ApiTenderTypeEnum {
  Undefined = 'Undefined',
  SavedOnFile = 'SavedOnFile',
  Cash = 'Cash',
  CardDevice = 'CardDevice',
  CardManual = 'CardManual',
  ECheck = 'ECheck',
  PaperCheckAsECheck = 'PaperCheckAsECheck',
  MoneyOrder = 'MoneyOrder'
}

export enum TenderTypeEnum {
  NotSet,
  Unknown,
  Card,
  eCheck,
  Cash,
  MoneyOrder,
  PaperCheck
}

export const TenderTypeLabels = {
  NotSet: "Not Set",
  Unknown: "Unknown",
  Card: "Card",
  eCheck: "eCheck",
  Cash: "Cash",
  MoneyOrder: "Money Order",
  PaperCheck: "Paper Check",
}

export interface TenderTypeMultiselectOption extends MultiSelectOption { originalLabelName: string };

export const ApiTenderTypeEnumLabels: { [key: string]: string } = {
  [String(ApiTenderTypeEnum.SavedOnFile)]: 'Payment Method On File',
  [String(ApiTenderTypeEnum.CardDevice)]: 'Credit/Debit Device',
  [String(ApiTenderTypeEnum.CardManual)]: 'Credit/Debit Manual Entry',
  [String(ApiTenderTypeEnum.ECheck)]: 'eCheck',
};

export const ApiTransactionTenderTypeEnumLabels: { [key: string]: string } = {
  [String(EnumTransactionTenderType.Card)]: 'Credit/Debit Manual Entry',
  [String(EnumTransactionTenderType.eCheck)]: 'eCheck',
};

export interface PaymentPermissions {
  cashPermission?: boolean;
  moneyOrderPermission?: boolean;
  paperCheckPermission?: boolean;
  eCheckPermission?: boolean;
  creditCardManualPermission?: boolean;
  creditCardDevicePermission?: boolean;
  paymentMethodOnFilePermission?: boolean
}

export function getTenderTypePaymentOptions(  
  {
    isGl = false, 
    isAdhocPayment = false,    
    paymentPermissions,
    paymentMethodCount,
    paymentConfiguration,
    shouldShowMethodOnFile 
  }: { 
    isGl?: boolean;   
    isAdhocPayment? :boolean;
    paymentPermissions?: PaymentPermissions;
    paymentMethodCount?: number;
    paymentConfiguration?: PaymentConfiguration;
    shouldShowMethodOnFile?: boolean;
  } = {}) {

  let options: { key: string; optionName: string; value?: string }[] = [
    {
      key: 'Please Select An Option',
      optionName: 'Please Select An Option',
      value: "",
    }
  ];
  
  if (!isGl && paymentPermissions?.paymentMethodOnFilePermission && shouldShowMethodOnFile && (paymentMethodCount && paymentMethodCount > 1)) {
    options.push(
      {
        key: 'Payment Method On File',
        optionName: 'Payment Method On File',
        value: ApiTenderTypeEnum.SavedOnFile,
      }
    );
  }
  addOptions(options, paymentPermissions,paymentConfiguration, false,isAdhocPayment);
 
  return options;
}

export function getTenderTypePaymentPlanOptions(
  {
    isGl = false,     
    isAdhocPayment = false,    
    paymentPermissions,
    paymentMethodCount,
    paymentConfiguration,
    shouldShowMethodOnFile,
    isPlan
  }: { 
    isGl?: boolean; 
    isAdhocPayment? :boolean;
    paymentPermissions?: PaymentPermissions;
    paymentMethodCount?: number;
    paymentConfiguration?: PaymentConfiguration
    shouldShowMethodOnFile?: boolean;
    isPlan?: boolean
  } = {}) {
  let options: { key: string; optionName: string; value: string }[] = [{
    key: 'Please Select An Option',
    optionName: 'Please Select An Option',
    value: ApiTenderTypeEnum.Undefined,
  }];
  let planOptions : { key: string; optionName: string; value: string }[] = [];

  if (!isGl && paymentPermissions?.paymentMethodOnFilePermission && shouldShowMethodOnFile && (paymentMethodCount && paymentMethodCount > 1)) {
    options.push(
      {
        key: 'Payment Method On File',
        optionName: 'Payment Method On File',
        value: ApiTenderTypeEnum.SavedOnFile,
      }
    );
  }
  addOptions(options, paymentPermissions,paymentConfiguration, isPlan, isAdhocPayment, isGl);
  planOptions = options;

  if(!isAdhocPayment) {
    const excludedPayments = [ApiTenderTypeEnum.Cash, ApiTenderTypeEnum.MoneyOrder, ApiTenderTypeEnum.PaperCheckAsECheck];
    planOptions = options.filter(x => !excludedPayments.includes(x.value as ApiTenderTypeEnum));
  }

  return planOptions;
}

function addOptions(options : { key: string; optionName: string; value?: string }[],
  paymentPermissions?: PaymentPermissions,
  paymentConfiguration?: PaymentConfiguration,
  isPaymentPlan?: boolean,
  isAdhocPayment?: boolean,
  isGl?: boolean){

    if ((isAdhocPayment || isGl) && !isPaymentPlan) {
      options.push(
        { key: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.CardDevice], optionName: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.CardDevice], value: ApiTenderTypeEnum.CardDevice },
        { key: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.CardManual], optionName: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.CardManual], value: ApiTenderTypeEnum.CardManual },
        { key: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.ECheck], optionName: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.ECheck], value: ApiTenderTypeEnum.ECheck },
        { key: 'Cash', optionName: 'Cash', value: ApiTenderTypeEnum.Cash },
        { key: 'Paper Check', optionName: 'Paper Check', value: ApiTenderTypeEnum.PaperCheckAsECheck },
        { key: 'Money Order', optionName: 'Money Order', value: ApiTenderTypeEnum.MoneyOrder }
      );
      return;
    }

    if ( paymentPermissions?.creditCardDevicePermission && paymentConfiguration?.hasCardDeviceAllowed ) {
      options.push( 
        {
          key: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.CardDevice],
          optionName: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.CardDevice],
          value: ApiTenderTypeEnum.CardDevice,
        }
      );
    }
  
    if ( paymentPermissions?.creditCardManualPermission && paymentConfiguration?.hasCreditCardAllowed ) {
      options.push(
        {
          key: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.CardManual],
          optionName: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.CardManual],
          value: ApiTenderTypeEnum.CardManual,
        }
      );
    }
  
    if ( paymentPermissions?.eCheckPermission && paymentConfiguration?.hasECheckAllowed ) {
      options.push(
        {
          key: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.ECheck],
          optionName: ApiTenderTypeEnumLabels[ApiTenderTypeEnum.ECheck],
          value: ApiTenderTypeEnum.ECheck,
        }
      )
    }
    
    if ( paymentPermissions?.cashPermission && paymentConfiguration?.hasCashAllowed ) {
      options.push(
        {
          key: 'Cash', 
          optionName: 'Cash', 
          value: ApiTenderTypeEnum.Cash 
        }
      );
    } 
  
    if ( paymentPermissions?.paperCheckPermission && paymentConfiguration?.hasPaperCheckAllowed ) {
      options.push(
        { 
          key: 'Paper Check',
          optionName: 'Paper Check', 
          value: ApiTenderTypeEnum.PaperCheckAsECheck
        }
      )
    }
  
    if ( paymentPermissions?.moneyOrderPermission && paymentConfiguration?.hasMoneyOrderAllowed) {
      options.push(
        {
          key: 'Money Order',
          optionName: 'Money Order',
          value: ApiTenderTypeEnum.MoneyOrder,
        }
      )
    } 
 
}
export const stateOptions = [
  { value: '', optionName: 'Select State' },
  { value: 'Alabama', optionName: 'Alabama' },
  { value: 'Alaska', optionName: 'Alaska' },
  { value: 'Arizona', optionName: 'Arizona' },
  { value: 'Arkansas', optionName: 'Arkansas' },
  { value: 'California', optionName: 'California' },
  { value: 'Colorado', optionName: 'Colorado' },
  { value: 'Connecticut', optionName: 'Connecticut' },
  { value: 'Delaware', optionName: 'Delaware' },
  { value: 'Florida', optionName: 'Florida' },
  { value: 'Georgia', optionName: 'Georgia ' },
  { value: 'Hawaii', optionName: 'Hawaii' },
  { value: 'Idaho', optionName: 'Idaho' },
  { value: 'Illinois', optionName: 'Illinois' },
  { value: 'Indiana', optionName: 'Indiana' },
  { value: 'Iowa', optionName: 'Iowa' },
  { value: 'Kansas', optionName: 'Kansas' },
  { value: 'Kentucky', optionName: 'Kentucky' },
  { value: 'Louisiana', optionName: 'Louisiana' },
  { value: 'Maine', optionName: 'Maine' },
  { value: 'Maryland', optionName: 'Maryland' },
  { value: 'Massachusetts', optionName: 'Massachusetts' },
  { value: 'Michigan', optionName: 'Michigan' },
  { value: 'Minnesota', optionName: 'Minnesota' },
  { value: 'Mississippi', optionName: 'Mississippi' },
  { value: 'Missouri', optionName: 'Missouri' },
  { value: 'Montana', optionName: 'Montana' },
  { value: 'Nebraska', optionName: 'Nebraska' },
  { value: 'Nevada', optionName: 'Nevada' },
  { value: 'New Hampshire', optionName: 'New Hampshire' },
  { value: 'New Jersey', optionName: 'New Jersey' },
  { value: 'New Mexico', optionName: 'New Mexico' },
  { value: 'New York', optionName: 'New York' },
  { value: 'North Carolina', optionName: 'North Carolina' },
  { value: 'North Dakota', optionName: 'North Dakota' },
  { value: 'Ohio', optionName: 'Ohio' },
  { value: 'Oklahoma', optionName: 'Oklahoma' },
  { value: 'Oregon', optionName: 'Oregon' },
  { value: 'Pennsylvania', optionName: 'Pennsylvania' },
  { value: 'Rhode Island', optionName: 'Rhode Island' },
  { value: 'South Carolina', optionName: 'South Carolina' },
  { value: 'South Dakota', optionName: 'South Dakota' },
  { value: 'Tennessee', optionName: 'Tennessee' },
  { value: 'Texas', optionName: 'Texas' },
  { value: 'Utah', optionName: 'Utah' },
  { value: 'Vermont', optionName: 'Vermont' },
  { value: 'Virgina', optionName: 'Virgina' },
  { value: 'Washington', optionName: 'Washington' },
  { value: 'Washington, DC', optionName: 'Washington, DC' },
  { value: 'West Virginia', optionName: 'West Virginia' },
  { value: 'Wisconsin', optionName: 'Wisconsin' },
  { value: 'Wyoming', optionName: 'Wyoming' },
];

export function getStartOfMonth() {
  return moment()
    .clone()
    .startOf('month');
}

export function getTodaysDate() {
  return moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.000');
}

export function getOneYearFromNowToEndOfDay() {
  return moment().add(1, 'years').endOf('day').toString();
}

export const convertDateToUtc = (date?: string) => {
  const now = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.000');
  return now;
}

export const getTimeStampMin = (date? : string) => {
  return moment.utc(date).startOf('day').format();
};

export const getTimeStampMax = (date?: string) => {
  return moment.utc(date).endOf('day').format();
};

export function getYesterday() {
  return moment()
    .subtract(1, 'days')
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss.000');
}

export function getMaxDate() {
  return moment().format('YYYY-MM-DD');
}

export function getMinDate() {
  const currentDate = moment().utc();
  const minDate = currentDate.subtract(150, 'years');
  return minDate.format('YYYY-MM-DD');
}

export const userOptions = [
  { value: "1", label: 'Jonh Doe' },
];

export function getTomorrow() {
  return formatDate(moment().add(1, 'days'));
}

export function formatDate(date: Moment, format?: string) {
  return date.format(format ?? 'MM/DD/YYYY');
}

export function formatDateString(date?: string, format?: string) {
  return date 
    ? formatDate(moment(date), format)
    : '';
}

export function formatUtcDateString(date?: string, format?: string) {
  return date 
    ? formatDate(moment.utc(date), format)
    : '';
}

export function formatDateWithTime(date: Moment) {
  return date.isValid() ? date.format('MM/DD/YYYY hh:mm:ss A') : '';
}

export function jwtUserDetailDecoder(token: string | null): LoginInformation {
  return jwtDecode<LoginInformation>(token ?? '');
}

export function nameof<T>(name: keyof T) {
  return name;
}

export function formatMoney(
  value?: string,
  left: number = 12,
  right: number = 2
) {
  if (!value) return '0';

  value = String(value);
  let formated = '';
  if (value.indexOf('.') > 0) {
    const valueParts = value.split('.');

    const isNumberRegex = /[1-9]+/;
    const replaceZeroes = isNumberRegex.test(valueParts[0])
      ? { regex: /^0+/, replacement: '' }
      : { regex: /^0+$/, replacement: '0' };

    formated = `${formatCurrency(
      valueParts[0]
        .replace(replaceZeroes.regex, replaceZeroes.replacement)
        .replace(/\D/g, '')
        .substring(0, left || 12)
    )}.${formatCurrency(
      valueParts[1].replace(/\D/g, '').substring(0, right || 2)
    )}`;
  } else {
    formated = displayAmount(
      value
        .replace(/^0+/, '')
        .replace(/\D/g, '')
        .substring(0, left || 12)
    );
  }

  if (formated == '') {
    formated = '0';
  }

  return formated;
}

export function maskCardNumber(cardNumber?: string) {
  if (cardNumber) {
    let maskChar = '*';
    let visiblePart = cardNumber.padStart(4, maskChar);
    let mask = ''.padStart(4, maskChar);
    return `${mask} ${mask} ${mask} ${visiblePart.slice(
      visiblePart.length - 4
    )}`;
  }
  return cardNumber;
}

export function maskECheckNumber(eCheckNumber?: string) {
  if (eCheckNumber) {
    let maskChar = '*';
    let visiblePart = eCheckNumber.padStart(4, maskChar);
    let padLength = (eCheckNumber.length > 4 ? eCheckNumber?.length : 4) - 4;
    let mask = ''.padStart(padLength, maskChar);
    return `${mask}${visiblePart.slice(visiblePart.length - 4)}`;
  }
  return eCheckNumber;
}

export const mapTypesForSearchResults = (types: string[], parent?: string) => {
  return [
    ...types.map(transaction => ({
      label: transaction === 'SystemUpdaterService' ? 'A/R Adjustments' : transaction,
      propertyName: transaction?.replace(/ /g, '').toLowerCase(),
      isChecked: transaction === 'SystemUpdaterService' ? false : true,
      parent,
    })),
  ];
};

export const mapCardBrandsForSearchResults = (
  cards: { label: string; propertyName: string }[],
  parent?: string
) => {
  return [
    ...cards.map(card => ({
      label: card.label,
      propertyName: card.propertyName,
      isChecked: true,
      parent,
    })),
  ];
};

export function mapPaymentStatusToMultiSelectOptions<T extends MultiSelectOption>(statusOptions: any[], parent?: string):T[] {
  return statusOptions.map(statusOption => ({
      value: statusOption.value,
      label: statusOption.optionName
    })) as T[];
};

export function labelToId(label: string) {
  return label.replace(/ /g, '').toLowerCase();
}

export const cardBrands = [
  { propertyName: 'americanexpress', label: CreditCardBrand.AmericanExpress },
  { propertyName: 'dinersclub', label: CreditCardBrand.DinerSClub },
  { propertyName: 'discover', label: CreditCardBrand.Discover },
  { propertyName: 'mastercard', label: CreditCardBrand.MasterCard },
  { propertyName: 'unknown', label: CreditCardBrand.Unknown },
  { propertyName: 'visa', label: CreditCardBrand.Visa },
  { propertyName: 'notset', label: CreditCardBrand.NotSet },
];

//get card brand from card number
export function getCardBrand(cardNumber: string) {
  let cardTypeRegex =
    [
        { brand: CreditCardBrand.UnionPay, regex: /^(6[27]\d{14}|^(81\d{14,17}))$/ },
        { brand: CreditCardBrand.Visa, regex: /^4\d{12}(?:\d{3})?$/ },
        { brand: CreditCardBrand.MasterCard, regex: /^(?:5[1-5]\d{2}|222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[01]\d|2720)\d{12}$/ },
        { brand: CreditCardBrand.AmericanExpress, regex: /^3[47]\d{13}$/ },
        { brand: CreditCardBrand.DinerSClub, regex: /^3(?:0[0-5]|[68]\d)\d{11,13}$/ },
        { brand: CreditCardBrand.Discover, regex: /^6(?:011|5\d{2})\d{12}$/ },
        { brand: CreditCardBrand.Jcb, regex: /^(?:2131|1800|35\d{3})\d{11}$/ }
    ];
  let cardBrand = cardTypeRegex.find((brand) => brand.regex.test(cardNumber.split(" ").join("")))?.brand ?? CreditCardBrand.Unknown;

  return cardBrand;
}

export function roundDecimalNumber(
  value?: number,
  digitsAfterDecimalPlace?: number
) {
  if (value) {
    const exponent = 10 ** Math.round(digitsAfterDecimalPlace ?? 0);
    return Math.round(value * exponent) / exponent;
  }
  return value;
}

function checkIfEqual(a: string, b: string) {
  return b > a ? -1 : 0;
}
export function sortAsc(values: string[]) {
  return values.sort((a, b) => (a > b ? 1 : checkIfEqual(a, b)));
}

export function isNotEmptyString(value?: string) {
  return typeof value === 'string' && value.length > 0;
}

export function sortAscByLabel(values: any[]) {
  return values.sort((a, b) => a.label.localeCompare(b.label));
}

export function getUserName(user?: BaseUserDetailModel | UserDetailModel, oneSourcePrefix?: boolean) {
  const name = `${user?.firstName?.trim() ?? ''} ${user?.lastName?.trim() ?? ''}`.trim();
  if (!oneSourcePrefix) return name;
  
  const prefix = user?.oneSourceUserId
    ? `(${user.oneSourceUserId.trim()})`
    : '';

  return `${prefix} ${name}`.trim();
}

export function isSuccessedTransaction(statusType?: EnumTransactionStatusType) {
  const completeStatuses = [
    EnumTransactionStatusType.Submitted, 
    EnumTransactionStatusType.Settled
  ];
  return statusType != undefined && completeStatuses.includes(statusType);
}

export function removeDuplicates(array: any[], propertyName: string) {
  return array.filter((thing, index, self) =>
  index === self.findIndex((t) => (
    t[propertyName] === thing[propertyName]
  ))
  )  
}

export function equalStringArrays(arrayX?: any[], arrayY?: any[]) {
  if (Array.isArray(arrayX) && Array.isArray(arrayY)) {
    return arrayX.length == arrayY.length && arrayX.every(x => arrayY.includes(x));
  }
  return false;
}

export function cutString(string: string, length: number) {
  if (string.length > length) {
    return string.substring(0, length) + "...";
  }
  return string;
}

export function emptyStringsToNull(data: {[key: string]: any}) {  
  Object.keys(data).forEach((key) => {
    data[key] = data[key] === '' ? null : data[key];
  });
  return data;
}

export function getNegativeNumber(value?: number) {
  if (typeof value != 'number') return value;
  return -Math.abs(value);
}

export function enumToSelectOptions<E>(enumType: E, labelsEnum?: {[key in keyof E]: string}, numberToAdd: number = 0) {
  return enumToOptions(enumType, (number, key) => ({ optionName: (labelsEnum?.[key] ?? key.toString()), value: parseInt(number) + numberToAdd }));
}

export function enumToMultiSelectOptions<E>(enumType: E, labelsEnum?: { [key in keyof E]: string }): MultiSelectOption[] {
  return enumToOptions(enumType, (number, key: keyof E) => ({ label: (labelsEnum?.[key] ?? (key.toString()).replaceAll("_"," ")), value: number.toString() }))
}

export function enumToTenderTypeMultSelectOptions<E>(enumType: E, labelsEnum?: { [key in keyof E]: string }): TenderTypeMultiselectOption[] {
  return enumToOptions(enumType, (number, key: keyof E) => ({ label: (labelsEnum?.[key] ?? key.toString()), value: number.toString(), originalLabelName: key.toString() }))
}

export function enumToOptions<E, O>(enumType: E, mapper: (value: string, key: keyof E) => O):O[]  {
  return (Object.entries(enumType as Object) as [string, keyof E][]).filter((pair) => { 
    const [value, key] = pair;
    return typeof key === "string" && (enumType as Object).hasOwnProperty(value)
  }).map((pair) => {
    return mapper(...pair);
  })
}

export function multiSelectOptionsToSelectOptions(multiSelectOptions: MultiSelectOption[]) {
  return multiSelectOptions.map((option: MultiSelectOption) => {
    return {optionName: option.label, value: option.value}
  });
}

export function arrayToSelectOptions<E>(source: E[], optionNameProp: keyof E, valueProp: keyof E, skipEmpty?: boolean) {
  return source.filter(element => !skipEmpty || element[valueProp]).map((element: E) => {
    return {optionName: element[optionNameProp], value: element[valueProp]}
  });
}

export function breakFileName(fileName?: string) {
  const segments = fileName?.split('.')
  const fileExtension = `.${segments?.pop()}`;

  return {
    fileName: segments?.join('.'),
    fileExtension,
  }
}

export enum cardBrandEnum {
  NotSet,
  AmericanExpress,
  DinerSClub,
  Discover,
  Enroute,
  Jcb,
  MasterCard,
  Visa,
  Unknown,
  UnionPay,
  Debit,
  Gsb
}

export enum TimeInMiliSeconst {
  ThirtyMinutes = 30 * 60 * 1000,
  OneHour = 1 * 60 * 60 * 1000,
  TwoHour = 2 * 60 * 60 * 1000
}

export async function runCallbackOnDateInPastAsync<T>(callback: ()=>Promise<T>, date?: number, timeAddToDate: number = 0) {
  if (!date || date + timeAddToDate <= Date.now()) {
    await callback();
  }
}

export function getBalance(lineItems: LineItems) {
  return lineItems.reduce((sum: number, lineItem) => Number(sum) + Number(lineItem.balance ?? 0), 0);
}

export function getAmount(lineItems: LineItems) {
  return lineItems.reduce((sum: number, lineItem) => Number(sum) + Number(lineItem.amount ?? 0), 0);
}

export function getGlBalance(lineItems: LineItems) {
  return lineItems.reduce((sum: number, lineItem) => Number(sum) + (Number(lineItem.glUnits ?? 0) * Number(lineItem.unitCost ?? 0) - Number(lineItem.discount ?? 0)), 0);
}

export function copyToClipboard(text: string | number) {
  navigator.clipboard.writeText(text.toString())
}

export function valueOrDefault(value?: any, defaultValue?: any) {
  return value || defaultValue;
}

export function objectPropertyOrDefault<P = any>(object: any, propName: keyof P, defaultValue?: any) {
  if (object) {
    return valueOrDefault(object[propName], defaultValue);
  }
  return defaultValue;
}

export function extractRequestErrors(request:any) : string[]{
  let errors : string[] = [];
  if (request.err){
    errors = [request.err]
  }

  if (request.errorResponse?.data.errorMessage){
    errors = errors.concat([request.errorResponse?.data.errorMessage]);
  }

  if(request.errorResponse?.data.validationErrors){
    errors = errors.concat(request.errorResponse?.data.validationErrors?.map((x: { errorMessage: any; })=>x.errorMessage));
  }
     
  return errors;
}

export function getOriginalAmount(transactionDetails? : TransactionDetailsModel | ProcessTransactionResponse) : number{
  return transactionDetails?.lineItems ? getAmount(transactionDetails.lineItems) : 0;
}

export function IsRefunded(transactionType:any){
  if (transactionType?.toString().indexOf("Void")>-1||transactionType?.toString().indexOf("Credit")>-1){
    return true;
  }
    
  return false;
}

export function capitalizeFirstLetter(string?: string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

export function toLowerCaseFirstLetter(string?: string) {
  if (string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
}

export function shouldHideCheckbox(field: string) {
  return ['list-options', 'format', 'max-length', 'regex', 'regex-test-data', 'validation-error-message'].includes(field);
}

export function shouldHideCheckImageOrLabel(field: string) {
  return ['list-options', 'format', 'max-length', 'regex', 'regex-test-data', 'validation-error-message', 'required'].includes(field);
}

export function shouldHideDropdown(field: string) {
  return ['format', 'max-length', 'regex', 'regex-test-data', 'validation-error-message'].includes(field);
}

export function shouldHideHidden(field: string) {
  return ['list-options', 'format', 'max-length', 'regex', 'regex-test-data', 'validation-error-message', 'visible', 'required'].includes(field);
}

export function shouldHideTextInput(field: string) {
  return field === 'list-options';
}

export const verifyComponent = (checked?: boolean, component?: any) => {
  if(checked) {
    return component;
  }
}

export const languageOptions = [{ optionName: "English", value: "english" }, { optionName: "Spanish", value: "spanish" }, { optionName: "Chinese", value: "chinese" }, 
{ optionName: "French", value: "french" }, { optionName: "German", value: "german" }];

export function checkStringHasValue(value?: string) { 
  return value ?? '';
}

export function checkNumberHasValue(value?: number) { 
return value ?? 0;
}

export function getValueOrDefault(value: any, defaultValue: any) { 
  return value || defaultValue;
}

export function validateCombinedConditions(firstValue: any, secondValue:any) {
  return firstValue && secondValue;
}

export function getDefaultValueByDataType(dataType : DataType, value: any) {
  switch(dataType) {
    case DataType.String:
      return value || '';
    case DataType.Boolean:
      return value || false;
    case DataType.DateTime:
      const validDate = moment(value, 'YYYY-MM-DD', true).isValid() ? moment(value, 'YYYY-MM-DD').toDate() : moment().toDate();
      return convertDateToLocaleDateString(validDate, 'fr-CA');
    case DataType.Money:
      return value || 0;
    case DataType.Decimal:
      return value || 0.0;
    default:
      return value;
  }
}

export function checkReceivedHasValues(firstValue: any, secondValue: any, defaultValue: any) {
  return firstValue ? secondValue : defaultValue;
}

export function coalesce(defaultValue: any, ...values: any[]) {
  const nonNullValue = values.find(value => value !== null && value !== undefined);
  return nonNullValue !== undefined ? nonNullValue : defaultValue;
}


export function calculateFinalPaymentDate(currentNextPaymentDate: string | undefined, firstPaymentDate: string | undefined, pauseTermType: string | EnumTermType | undefined, pauseDuration: number | undefined) {
  
  if (!pauseDuration || !currentNextPaymentDate || !firstPaymentDate) {          
    return null;
  }

  const daysInWeek = 7;
  const monthlyPeriod = 1;
  const quarterlyPeriod = 3;

  let result = null;

  switch (pauseTermType) {
    case EnumTermType.Weekly:
      result =  moment(currentNextPaymentDate).add(daysInWeek * pauseDuration, 'days').toDate();
      break;
    case EnumTermType.BiWeekly:
      result = moment(currentNextPaymentDate).add((2 * daysInWeek) * pauseDuration, 'days').toDate();
      break;
    case EnumTermType.Monthly:
      result = AddMonthsToDate(currentNextPaymentDate, firstPaymentDate, monthlyPeriod * pauseDuration);
      break;
    case EnumTermType.Quarterly:
      result = AddMonthsToDate(currentNextPaymentDate, firstPaymentDate, quarterlyPeriod * pauseDuration);
      break;
    case EnumTermType.Annually:
      result = moment(currentNextPaymentDate).utc().add( pauseDuration, 'years').toDate();
      break;
    }
    return  result && moment(result).utc().format('MM/DD/YYYY').toString();
}

function AddMonthsToDate(previousPaymentDate: string, firstPaymentDate: string, numberOfMonths: number) {
  const firstPaymentDateDay = moment(firstPaymentDate).utc().toDate().getDay();
  
  let nextDate = moment(previousPaymentDate).utc().add(numberOfMonths, 'months').toDate();
  let maxDaysInMonthForNextDate = moment(previousPaymentDate).utc().daysInMonth();
  let expectedPaymentDay = Math.min(maxDaysInMonthForNextDate, firstPaymentDateDay);

  if (firstPaymentDateDay >= nextDate.getDay() && expectedPaymentDay >= nextDate.getDay()) {
    nextDate = moment(nextDate).utc().add(expectedPaymentDay - nextDate.getDay()).toDate();
  }

  return nextDate;
}

const getRequiredMessage = (error?: any, required?: boolean) => (error && required) ? 'Required' : error;

export const getError = (error?: any, required?: boolean) => error !== 'Required' ? error : getRequiredMessage(error, required);

export const getAdminPermissions = (isAdmin?: boolean, sspUserPermission?: boolean, searchResults?: PermissionItem[]) => {
  const adminPermissions = searchResults?.filter(item => item.groupName === 'Admin');

  if (isAdmin) {
    return adminPermissions;
  }

  const filteredPermissions = adminPermissions?.filter(item => item.id !== UserPermissions.FinancialAdministation.toString());

  if (!sspUserPermission) {
    return filteredPermissions?.filter(item => item.id !== UserPermissions.SelfServicePortal.toString());
  }

  return filteredPermissions;
}

export function getPatientInformation(chosenFacility?: FullOrganizationLevelDocument, patient?: PatientModel): PatientInfoModel {
  return {
    firstName: valueOrDefault(patient?.firstName, ''),
    lastName: valueOrDefault(patient?.lastName, ''),
    mrn: valueOrDefault(patient?.mrn, ''),
    guarantorId: valueOrDefault(patient?.guarantorAccountNo, ''),
    accountNo: valueOrDefault(patient?.accountNo, ''),
    dateOfBirth: valueOrDefault(patient?.dateOfBirth, ''),
    facility: chosenFacility,
    department: { id: '', name: '', path: '', organizationLevelType: OrganizationLevelTypes.Department },
    consolidationId: valueOrDefault(patient?.consolidationId, ''),
    secondaryMRN: valueOrDefault(patient?.secondaryMrn, ''),
    secondaryConId: valueOrDefault(patient?.secondaryConId, '')
  }
}

export const sortOptions = (options: MultiSelectOption[]) => {
  return options.sort((a, b) => a.label.localeCompare(b.label));
}

export const getOrganizationHeader = (
  getOrganizationWithFallback: (path: string, levelType: OrganizationLevelTypes) => FullOrganizationLevelDocument | undefined, 
  organizationLevelType: OrganizationLevelTypes
) => {
  if (organizationLevelType === OrganizationLevelTypes.Facility) {
    return {
      headerName: 'Facility',
      field: nameof<UserDetailModel>('organizationLevels'),
      sortable: true,
      filter: true,
      resizable: true,
      cellRenderer: 'facilitiesCell',
      valueGetter: (params: { data?: UserDetailModel }) => {
        const path  = params.data?.organizationLevels?.find(o => true)?.organizationLevel_Path ?? '';
        return getOrganizationWithFallback(path, OrganizationLevelTypes.Facility)?.name ?? '-';
      },
    }
  } else if(organizationLevelType === OrganizationLevelTypes.Department) {
    return {
      headerName: 'Department',
      field: nameof<UserDetailModel>('organizationLevels'),
      sortable: true,
      filter: true,
      resizable: true,
      valueGetter: (params: { data?: UserDetailModel }) => {
        const path  = params.data?.organizationLevels?.find(o => true)?.organizationLevel_Path ?? '';
        return getOrganizationWithFallback(path, OrganizationLevelTypes.Department)?.name ?? '-';
      },
    }
  }
  return {}
}

export const getElementFromPath = (path: string, levelType: number) => {
  return path.split('|')[path.split('|').length - levelType];
}

export const obsoleteTransactionTypes = [
  ObsoleteTransactionTypesEnumLabels.RecurringPaymentAdd,
  ObsoleteTransactionTypesEnumLabels.RecurringPaymentCancel,
  ObsoleteTransactionTypesEnumLabels.RecurringPaymentReactivate,
  ObsoleteTransactionTypesEnumLabels.RecurringPaymentModify,
  ObsoleteTransactionTypesEnumLabels.RecurringPaymentInquiry,
  ObsoleteTransactionTypesEnumLabels.GLPlan,
  ObsoleteTransactionTypesEnumLabels.GLPlanCancel,
  ObsoleteTransactionTypesEnumLabels.GLPlanModify,
  ObsoleteTransactionTypesEnumLabels.GLPlanReactivate,
  ObsoleteTransactionTypesEnumLabels.GLPlanInquiry,
];

export function buildCriteria(rowsParams: IGetRowsParams) {
  let model: any = {};
  const filters = rowsParams.filterModel;
  Object.keys(filters).forEach(key => {
    model[key] = filters[key].filter;
  });
  return model;
}

export function getSortOptions(sortModel: any) {
  const invertedSortColumns = ['IsGL'];

  const invertSortDirection = (sortDirection: string) => sortDirection === 'asc' ? 'desc' : 'asc';

  const sortOptions = Object.assign({}, ...sortModel.map((x: any) => ({ [x.colId]: x.sort })));

  Object.keys(sortOptions)
    .filter(key => invertedSortColumns.includes(key))
    .forEach(key => sortOptions[key] = invertSortDirection(sortOptions[key]));

  return sortOptions;
}

export function filterEmptyFields(obj: Record<string, any>) {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== '' && value !== null && value !== undefined));
};

export const getFacilitiesWithPaymentPlansEnabled = (userFacilityOptions: FullOrganizationLevelDocument[], filteredFacilities?: FullOrganizationLevelDocument[]) => {
  if(filteredFacilities?.length) {
    return userFacilityOptions.filter(f => filteredFacilities.find(o => o.path == f.path));
  }
  return userFacilityOptions;
}

export const getDepartmentsEnabled = (departments: DepartmentMultiselectOption[], departmentsOptions: { 
  facilityName: string;
  label: string;
  value: string;
  className?: string;
  }[]) => {
    if(departments?.length) {
      return departments?.filter(department => 
        departmentsOptions.some(option => option.value === department.value)
      );
    }
    return departments;
}

export async function fetchPaymentPlanConfig(path: string) {
  const response = await getPaymentPlanConfigurationService(path);
  return response?.result;
}

