import { useTypedSelector } from 'app/rootReducer';
import PaymentModel from 'models/PaymentModel';
import { PatientMailingInformation } from 'features/paymentInfo/PatientMailingInformationReducer';
import { useGetPatientById } from '../patients/hooks/useGetPatientById';
import * as router from 'react-router-dom';
import PaymentPlanModel, { OrganizationReference } from 'models/PaymentPlan';
import { EnumPaymentSource } from 'models/enums/EnumPaymentSource';
import { checkReceivedHasValues, getValueOrDefault } from 'utils/Utils';
import { Value } from '../paymentDashboard/PaymentDashboardReducer';
import { PlanOperations } from 'models/metaData/MetaDataEnums';

export const usePaymentInformation = (
  isGLPayment?: boolean,
  total?: number,
  department?: OrganizationReference,
  plan?: PaymentPlanModel,
  isMailingAddressRequired?: boolean
) => {
  const patientMailingInformation = useTypedSelector(
    p => p.patientMailingInformation?.value
  );
  
  const billingInformation = getPatientBilling(patientMailingInformation);
  let mailingInformation = getPatientMailing(patientMailingInformation);
  if (patientMailingInformation?.isChecked) {
    mailingInformation = billingInformation;
  }
  const patientInfo = useTypedSelector(s => s.patientInfo?.value);
  const operation = useTypedSelector(state => state.paymentPlanInfo.operation);
  const isAddHoc = operation === PlanOperations.AddhockPayment;
  let { id } = router.useParams<{
    id: string;
  }>();
  const { patient } = useGetPatientById(id);
  const mrn = useMrn(isGLPayment, patient?.mrn);
  const patientAccountNumber = useAccountNumber(isGLPayment, patient?.accountNo);

  const getPaymentModel = (paymentDashboard: Value, facility?: OrganizationReference) => {
    if (isAddHoc){
      total = paymentDashboard.paymentTotal;
    }
    
    const paymentModel: PaymentModel = {
      mrn,
      paymentTotal: getValueOrDefault(total, 0),
      billingInformation,
      patientId: patient?.id,
      patientFirstName: patient?.firstName,
      patientLastName: patient?.lastName,
      patientDateOfBirth: patient?.dateOfBirth,
      paymentPlanId: plan?.id,
      totalPlanBalance: plan?.balance,
      tenderType: checkReceivedHasValues(patientMailingInformation?.tenderType,
        patientMailingInformation?.tenderType,
        'fake tender type'),
      originalTenderType: patientMailingInformation.originalTenderType,
      notes: patientMailingInformation?.notes,
      department,
      organization: facility,
      typeCash: {
        amountTendered: patientMailingInformation?.amountTendered,
        cashBack: patientMailingInformation?.cashBack,
      },
      typePaperCheck: {
        checkNumber: patientMailingInformation?.paperCheckNumber,
        financialInstitution: patientMailingInformation?.financialInstitution,
      },
      typeMoneyOrder: {
        amountTendered: patientMailingInformation?.moneyOrderAmount,
        cashBack: patientMailingInformation?.cashBack,
        serialNumber: patientMailingInformation?.moneyOrderSerialNumber,
        postOffice: patientMailingInformation?.moneyOrderPostOffice,
        issueDate: patientMailingInformation?.moneyOrderIssueDate,
      },
      typeECheck: {
        routingNumber: patientMailingInformation?.routingNumber,
        accountNumber: patientMailingInformation?.accountNumber,
        accountType: patientMailingInformation?.accountType,
        financialInstitution: patientMailingInformation?.financialInstitution,
      },
      typeCreditDebitEntry: {
        cardHolderName: patientMailingInformation?.cardHolderName,
        cardNumber: patientMailingInformation?.cardNumber,
        cvc: patientMailingInformation?.cvc,
        expiration: patientMailingInformation?.expiration,
        deviceSerialNumber: patientMailingInformation?.deviceSerialNumber,
        deviceId: patientMailingInformation?.deviceId,
        deviceName: patientMailingInformation?.deviceName,
        isReusable: patientMailingInformation?.isReusable,
      },
      patientAccountNumber,
      details: paymentDashboard?.lineItems.map(lineItem => {
        return {
          ...lineItem,
          patientId: patientInfo.id,
          amount: checkReceivedHasValues(isAddHoc, Number(lineItem.paymentAmount), lineItem.amount)
        };
      }),
      glDetails: paymentDashboard?.lineItems.map(lineItem => {
        return {
          ...lineItem,
          amount: Number(lineItem.glUnits) * Number(lineItem.unitCost),
          patientId: getValueOrDefault(patientInfo.id, 'G/L'),
        };
      }),
      isGL: getValueOrDefault(isGLPayment, false),
      discount: getDiscountTotal(paymentDashboard),
      episode: 'fakeEpisode',
      guarantorId: patient?.guarantorAccountNo,
      paymentSource: EnumPaymentSource.PaymentSafe,
      paymentFileTokenId: checkReceivedHasValues(patientMailingInformation?.paymentFileTokenId,
        patientMailingInformation?.paymentFileTokenId,
        'fake payment file token id'),
      isReusable: patientMailingInformation?.isReusable,
      notificationEmail: patientMailingInformation?.notificationEmail,
    };

    if (isMailingAddressRequired) {
      paymentModel.mailingInformation = checkReceivedHasValues(patientMailingInformation?.isChecked, billingInformation, mailingInformation)
    }

    return paymentModel;
  }
  return {getPaymentModel}
};

function useMrn(
  isGLPayment: boolean | undefined,
  mrn: string | undefined
  ): string | undefined {
  const patientMailingInformation = useTypedSelector(
    p => p.patientMailingInformation?.value
  );

  return isGLPayment && patientMailingInformation?.mrn 
    ? patientMailingInformation?.mrn 
    : mrn;
}

function getPatientBilling(
  patientMailingInformation: PatientMailingInformation
) {
  return {
    firstName: checkReceivedHasValues(patientMailingInformation?.billingFirstName, patientMailingInformation?.billingFirstName, patientMailingInformation?.firstName),
    lastName: checkReceivedHasValues(patientMailingInformation?.billingLastName, patientMailingInformation?.billingLastName, patientMailingInformation?.lastName),
    addressLine1: patientMailingInformation?.billingAddressLine1,
    addressLine2: patientMailingInformation?.billingAddressLine2,
    city: patientMailingInformation?.billingCity,
    state: patientMailingInformation?.billingState,
    phone: patientMailingInformation?.billingPhoneNumber,
    zipCode: patientMailingInformation?.billingZipCode,
  };
}

function getPatientMailing(
  patientMailingInformation: PatientMailingInformation
) {
  return {
    firstName: patientMailingInformation?.firstName,
    lastName: patientMailingInformation?.lastName,
    addressLine1: patientMailingInformation?.addressLine1,
    addressLine2: patientMailingInformation?.addressLine2,
    city: patientMailingInformation?.city,
    state: patientMailingInformation?.mailingState,
    phone: patientMailingInformation?.phoneNumber,
    zipCode: patientMailingInformation?.zipCode,
  };
}

function getDiscountTotal(paymentDashboardValue: Value): number {
  return paymentDashboardValue?.lineItems.reduce(
    (a, c) => a + (c.discount || 0),
    0
  );
}

function useAccountNumber(
  isGLPayment: boolean | undefined,
  accountNumber: string | undefined
): string | undefined {
  const glPaymentDashboardValue = useTypedSelector(
    p => p.glPaymentDashboard?.value
  );
  return isGLPayment && glPaymentDashboardValue.lineItems[0].accountNumber 
    ? glPaymentDashboardValue.lineItems[0].accountNumber 
    : accountNumber;
}

export default usePaymentInformation;
