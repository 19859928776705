import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { EDS_Checkbox } from '@EH/eh.eds.react';
import { useTypedSelector } from 'app/rootReducer';
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from 'models/enums/EnumPopupType';
import { Popup } from 'components/popup/Popup';
import { GridConfig } from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';
import {
  setCustomSearchColumnsState,
  CustomSearchTransactionColumns,
  setCustomSearchPlanColumnsState,
  CustomSearchPlanColumns,
} from './customizeSearchColumnsReducer';
import '../../assets/styles/components/_searchresults.scss';
import { useGetUserSettings } from 'pages/Search/SearchPaymentspage';
import { PutUserSettings } from 'services/UsersService';
import { UserSettings } from 'models/UserInfoAndRolesModel';

const footerButtons = [
  {
    type: EnumPopupButtonsType.cancel,
    style: EnumPopupButtonsStyle.secondary,
    text: 'Cancel',
  },
  {
    type: EnumPopupButtonsType.submit,
    style: EnumPopupButtonsStyle.primary,
    text: 'Apply Filters',
  },
];

export default (props: {
    data?: GridConfig[];
    searchPlan: boolean;
    onClose: (() => void) | undefined;
}) => {
  const { data, searchPlan, onClose } = props;
  const dispatch = useDispatch();
  const transactionsState = useTypedSelector(c => c.customSearchColumns?.transactions.value);
  const plansState = useTypedSelector(c => c.customSearchColumns?.plans.value);
  const [columnStatus, setColumnStatus] = useState<{[key: string]: any}>(searchPlan ? plansState : transactionsState);
  const [savePreferences, setSavePreferences] = useState<boolean>(false);
  const {getUserSettings, userSettings, userId} = useGetUserSettings();

  const setTransactionsState = (param: CustomSearchTransactionColumns) => {
    dispatch(setCustomSearchColumnsState(param));
  };

  const setPlansState = (param: CustomSearchPlanColumns) => {
    dispatch(setCustomSearchPlanColumnsState(param));
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  const applyFilters = useCallback(async () => {
    let mode: keyof UserSettings;

    if(searchPlan){
      setPlansState({ ...plansState, ...columnStatus });
      mode = 'planResultsGrid';
    }
    else{
      setTransactionsState({ ...transactionsState, ...columnStatus });
      mode = 'transactionsResultGrid';
    }

    if(savePreferences){
      await PutUserSettings(userId!, JSON.stringify({
        ...userSettings,
        [mode]: columnStatus,
      }).replace(/"/g, '\\"'))
    }
    onClose && onClose();
  },[userSettings,plansState,columnStatus, savePreferences,searchPlan,userId]);

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let propertyName = event.target.name;
    let value = event.target.checked;
    setColumnStatus({ ...columnStatus, [propertyName]: value})
  };

  const isAllChecked = !Object.values(columnStatus).includes(false);

  const setAsDefaultCheckbox = (
    <label
      className="set-as-default-checkbox"
      htmlFor="setAsDefaultCheckbox"
      aria-label="Set as Default"
    >
      <input
        type="checkbox"
        checked={savePreferences}
        name="setAsDefaultCheckbox"
        data-testid="setAsDefaultCheckbox"
        onChange={(checked) => setSavePreferences(checked.target.checked)}
      />
      <span className={`eds-checkbox_#label`}>Set as Default</span>
    </label>
  )

  const bodyContent = (
    <div className="eds-modal_#content customize-column-body">
      <div>
      <EDS_Checkbox
        checked={isAllChecked}
        label={'Select All'}
        name={'selectAll'}
        onChange={()=>{
          let newData = {...columnStatus}
          Object.keys(newData).forEach(key => {
            if(key === 'id'){
              newData[key] = true;
              return;
            }
            newData[key] = !isAllChecked;
          });
          setColumnStatus({...newData});
        }}
      />
      </div>
      {data && data.map((row: GridConfig, index: number) => {
        if (row.field !== 'id') {
          return (
            <div key={index}>
              <EDS_Checkbox
                checked={columnStatus[row.field]}
                label={row.label}
                name={row.field}
                onChange={handleCheckBoxChange}
              />
            </div>
          )
        }
        return null;
      })}
    </div>
  );

  return (
    <Popup
      header='Customize Column View'
      fullHeight={true}
      customStyle='modal-customize-column-container'
      children={bodyContent}
      footer={footerButtons}
      footNotes={setAsDefaultCheckbox}
      onClose={handleCancel}
      onSubmit={applyFilters}
    />
  );
};
  