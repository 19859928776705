import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'typeface-roboto';
import { setAutoFreeze } from 'immer';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import reduxStore, { history } from 'app/reduxStore';
import App from 'app/App';
import * as serviceWorker from 'serviceWorker';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

setAutoFreeze(false);

const router = createBrowserRouter(createRoutesFromElements(<Route path='*' element={<App />} />));

ReactDOM.render(
  <Provider store={reduxStore}>
    <RouterProvider router={router} />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
