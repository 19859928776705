import { useState } from "react";
import { EDS_Select } from '@EH/eh.eds.react';
import '../../../assets/styles/components/_cloneUserPermissionsConfirmation.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '@experian/eds-styles/dist/eds-all.css';
import { Popup } from "components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import {
  GridApi,
  GridReadyEvent
} from 'ag-grid-community';

export interface UserInfo{
  user?: string,
  organization?: string,
  facility?: string,
  department?: string
}

export default (props: {
  isOpen: boolean;
  close: () => void;
  onNext: () => void;
  destinationUsersInfo: UserInfo[];
  originUserInfo: UserInfo;
}) => {
  const { close, onNext, destinationUsersInfo, originUserInfo } = props;

  const footerButtons = [
    {
      type: EnumPopupButtonsType.cancel,
      style: EnumPopupButtonsStyle.secondary,
      text: 'Cancel',
      disabled: false
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      text: 'Clone Permissions',
      disabled: false
    },
  ];
  
  const headerDefinitions = [
    {
      headerName: 'User',
      field: 'user',
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Organization',
      field: 'organization',
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Facility',
      field: 'facility',
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Department',
      field: 'department',
      sortable: true,
      resizable: true,
    },
  ];

  const pageSizeOptions = [
    { optionName: '3', value: 3 },
    { optionName: '5', value: 5 },
  ];

  const [gridApi, setGridApi] = useState < GridApi | null>(null);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [currentPage, setCurrentPage] = useState(0);

  const onChangePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = pageSizeOptions[event.target.selectedIndex].value;
    setPageSize(value);
    if (gridApi) {
      gridApi.paginationSetPageSize(value);
    }
  }

  function onGridReady(params: GridReadyEvent) {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }

  return (
  <Popup 
    header={
      <span className='select-remit-header'>
        PaymentSafe<sup>&reg;</sup>&nbsp;&nbsp;&nbsp;
      </span>
    }
    onClose={close}
    footer={footerButtons}
    onSubmit={onNext}
    onClear={close}
    customClassNames={{footerButton: 'height30', container: 'autoHeightWidth', title: 'title' }}
    >
      <div className="popupContent">
        <div className="rowItemPopup">         
          <div className="confirmationContainer">
            <span className="attribute_label">Confirm cloned permissions:</span>
            <div className="flexrow">
              <span className="attribute_label">Cloned from:</span>
              <div className="information_attribute">
                <span>User: </span><span className="attribute_label">{`${originUserInfo?.user ?? ''} `}</span>
                <span>Organization: </span><span className="attribute_label">{`${originUserInfo?.organization ?? ''} `}</span>
                <span>Facility: </span><span className="attribute_label">{`${originUserInfo?.facility ?? ''} `}</span>
                {originUserInfo?.department?.length ? <><span>Department: </span><span className="attribute_label">{`${originUserInfo?.department ?? ''} `}</span></> : null}
              </div>
            </div>
            <div className="flexRow">
              <span className="attribute_label">Cloned to:</span>
              <div className={'section ag-theme-alpine'}>
                <AgGridReact
                  columnDefs={headerDefinitions}
                  onGridReady={onGridReady}
                  domLayout={'autoHeight'}
                  rowData={destinationUsersInfo}
                  rowSelection={'none'}
                  suppressRowClickSelection={true}
                  suppressMenuHide={true}
                  suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
                  pagination={true}
                  paginationPageSize={pageSize}
                  suppressPaginationPanel={true}
                  onPaginationChanged={() => { setCurrentPage(gridApi?.paginationGetCurrentPage() ?? 0) }}
                  disableStaticMarkup={true}
                />              
                <div className={"pagination-container"}>
                  <div className={"pages"}>
                    {currentPage ? <span onClick={() => gridApi?.paginationGoToPreviousPage()}>{"|<"}</span> : null}
                    <span className={"page-number"}>{currentPage + 1}</span>
                    {gridApi && gridApi.paginationGetTotalPages() !== (currentPage + 1) ? <span onClick={() => gridApi.paginationGoToNextPage()}>{">|"}</span> : null}
                  </div>
                  <EDS_Select
                    name="configurationId"
                    label="Rows per page:"
                    modifiers={'row-item row-item-size-double'}
                    options={pageSizeOptions}
                    onChange={onChangePageSize}
                    value={pageSize}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>              
      </div>
  </Popup>
  );
}

