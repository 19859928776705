import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../app/rootReducer';
import { getPaymentPlanConfigurationService } from '../../../services/PaymentPlanService';
import { useCallService } from '../../../services/useCallService';
import { setConfiguration } from '../PaymentPlanReducer';

export function useGetConfiguration(
  organizationPath: string,
  onError?: () => void,
  onSuccess?: () => void
) {
  const dispatch = useDispatch();
  const callService = useCallService();
  let configurationPair = useTypedSelector(s => {
      return s.paymentPlanInfo?.configurations[organizationPath];
  });

  const getPlanConfiguration = async () => {
    const oneHour = 1 * 60 * 60 * 1000;

    if (
      !(
        configurationPair?.date ||
        configurationPair?.date + oneHour >= Date.now()
      ) || configurationPair.reload
    ) {

      const tier = await callService("GetPaymentPlanConfiguration", () => getPaymentPlanConfigurationService(organizationPath));
      if (tier.err || !tier.result) {
        onError && onError();
        return;
      }
      dispatch(
        setConfiguration({
          organizationPath,
          configuration: tier.result,
        })
      );
    }
    onSuccess && onSuccess();
    return true;
  };

  return { getPlanConfiguration };
}
