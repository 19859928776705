import { Attribute, GenericAttribute, MetaData } from 'models/metaData/MetaData';
import {
  AnnotationType,
  ComponentType,
  ValidatorType,
  ValueType,
} from 'models/metaData/MetaDataEnums';

export function getEntity(metaData: MetaData | undefined, name: string) {
  return metaData?.entities?.find(e => e.name === name);
}

export function getAnnotation<T>(attribute: GenericAttribute<T>, name: AnnotationType) {
  return attribute.annotations?.find(a => a.name === name);
}

export function getLabel<T>(attribute: GenericAttribute<T>) {
  return `${ attribute.label }:`;
}

export function getValueType<T>(attribute: GenericAttribute<T>) {
  return getAnnotation(attribute, AnnotationType.ValueType)?.value as ValueType;
}

export function getComponentType(attribute: Attribute) {
  return getAnnotation(attribute, AnnotationType.ComponentType)
    ?.value as ComponentType;
}

export function getMutableComponentType(attribute: Attribute) {
  return getAnnotation(attribute, AnnotationType.Mutable)
    ?.value as ComponentType;
}

export function getValidator<T>(attribute?: GenericAttribute<T>, name?: ValidatorType) {
  return attribute?.validators?.find(a => a.name === name);
}

export function getAllValidators<T>(attribute?: GenericAttribute<T>, name?: ValidatorType) {
  return attribute?.validators?.filter(a => a.name === name);
}

export function getAttribute<T>(attributes: GenericAttribute<T>[], name: keyof T) {
  return attributes.find(a => a.name === name);
}
