import { useDispatch } from "react-redux";
import { callService } from "../../../services/ServicesReducer";
import { onReceiveTokenGUIDLookup } from "./TokenGUIDLookupReducer";
import { getTokenGUIDLookupService } from "../../../services/TokenGUIDLookupService";

export function useTokenGUIDLookup() {
  const dispatch = useDispatch();
  return async (guid: string) => {
    dispatch(callService("GetTokenGUIDLookup", async () => {
      const response = await getTokenGUIDLookupService(guid);
      dispatch(onReceiveTokenGUIDLookup(response));
    }))
  }
}