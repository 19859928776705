import { useEffect, useState } from 'react';
import { EDS_Alert } from '@EH/eh.eds.react';
import { removeAlert, Alert as AlertInterface } from '../alert/AlertReducer';
import { useDispatch } from 'react-redux';
import '../../assets/styles/components/_alert.scss';

export function Alert(props: { alert: AlertInterface }) {

  const dispatch = useDispatch();

  const { alert } = props;
  const [expanded, setExpanded] = useState(false);
  const close = () => {
    dispatch(removeAlert(alert));
  };

  useEffect(() => {
    if (alert.dismissable) {
      window.setTimeout(close, 5 * 1000);
    }
  }, []);

  const maxLength = 190;

  const expandedMessage = expanded ? "Read less..." : "Read more>";

  return <EDS_Alert
    message={<div>
      {expanded ? alert.message : alert.message.slice(0, maxLength)}
      {alert.message.length < maxLength ?
        null :
        <span className={"expand-link"} onClick={() => setExpanded(!expanded)}>
          {expandedMessage}
        </span>
      }
    </div>}
    type={alert.type}
    addBorder
    makeGray
    addShadow
    onClose={close}
  />
}