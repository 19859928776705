import { ReactNode } from "react";
import 'assets/styles/components/_collapsibleSection.scss';

export const CollapsibleSection = (props: { summary: ReactNode, isOpen: boolean, children: ReactNode }) => {
  const { summary, isOpen, children } = props;

  return <div className="collapsibleSection">
    <div className="sectionHeader">{summary}</div>
    {isOpen ? <div className="content">{children}</div>: null}
  </div>
}