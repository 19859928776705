import React from 'react';
import styles from '../planSearch.module.scss';
import {
  CheckBoxTreeComponent,
  CheckBoxTree,
} from '../../../../components/checkBoxTree/CheckBoxTree';

import { useCheckBoxTree } from './useCheckBoxTree';

export function FilterCheckBoxes(props: {
  group: string;
  groupDisplayName?: string;
  checkBoxTree: CheckBoxTree;
  displayClearButton?: boolean;
  setCheckboxTree?: (tree: CheckBoxTree) => any
}) {
  const { group, groupDisplayName, checkBoxTree, displayClearButton, setCheckboxTree } = props;
  const {
    checkBoxTreeState,
    handleCheckBoxTreeChange,
    handleCheckBoxTreeClear,
  } = useCheckBoxTree(group, checkBoxTree, groupDisplayName, setCheckboxTree);

  return (
    <div className={styles.checkBoxContainer}>
      <div className={styles.groupDisplayNameContainer}>
        <span className={styles.groupDisplayName}>{groupDisplayName}</span>
        {displayClearButton ? (
          <span
            className={styles.clearCheckBoxFilters}
            onClick={handleCheckBoxTreeClear}
          >
            Clear
          </span>
        ) : null}
      </div>

      <CheckBoxTreeComponent
        tree={checkBoxTreeState}
        onChange={handleCheckBoxTreeChange}
      />
    </div>
  );
}
