import moment from "moment";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import { GenericAttribute, GenericValidator } from "../../../models/metaData/MetaData";
import { DataType, ValidatorType } from "../../../models/metaData/MetaDataEnums";
import { TenderInfo } from "../../../models/PaymentPlan";
import { getAttribute, getLabel } from "../../../utils/metadata/MetaDataUtils";
import { ApiTenderTypeEnum, getCardBrand } from "../../../utils/Utils";
import { date, equal, length, required, validateGeneric, validateDateRange, amountToTotal, GenericValidatorProps } from "../../paymentDashboard/Validators";
import { addError, removeError } from "../PaymentPlanReducer";
import { PaymentOnFile } from 'models/PatientModel';
import { EnumTransactionTenderType } from "models/enums/EnumTransactionTenderType";
import { CreditCardBrand } from "../../../models/enums/CreditCardBrand";
import { cardTypes, cardTypesToCardBrandMap } from "../../../models/PaymentsConfiguration";

export default function useTenderValidator(tender?: TenderInfo, paymentsOnFile?: PaymentOnFile[], singlePayment: boolean = false, selectedOrganizationPath?: string, isPlan: boolean = false, adhocAmount?: number, isAdhoc: boolean = false) {
  const dispatch = useDispatch();
  const minDate = moment().startOf('month').utc().toISOString();
  
  const paymentDashboardInfo = useTypedSelector(s => s.paymentDashboard?.values[selectedOrganizationPath ?? '']);

  const paymentConfiguration = useTypedSelector(s => s.paymentDashboard?.configurations[selectedOrganizationPath ?? '']?.configuration);
  const allowedCardTypes = cardTypes.filter(cardType => paymentConfiguration?.[cardType]).map(cardType => cardTypesToCardBrandMap[cardType])
  function totalUnitCostsLineItems() {
    let totalUnitCost: number = 0;
    const isGl: boolean = paymentDashboardInfo?.isGl;
    for (let i in paymentDashboardInfo?.lineItems) {
      let fullTotal = Number(paymentDashboardInfo?.lineItems[i].unitCost) * Number(isGl ? paymentDashboardInfo?.lineItems[i].glUnits : paymentDashboardInfo?.lineItems[i].units);
      const discount = paymentDashboardInfo?.lineItems[i].discount ? Number(paymentDashboardInfo?.lineItems[i].discount ?? 0) : 0;
      totalUnitCost += (fullTotal - discount);
    }
    return totalUnitCost;
  }

  function getTotalAmount() {
    if(isAdhoc){
      return adhocAmount; 
    }
    return totalUnitCostsLineItems() || paymentDashboardInfo?.paymentTotal;
  }

  function getAttributes() {
    const atributes: GenericAttribute<TenderInfo>[] = [];
    if (!singlePayment) {
      atributes.push({ name: "type", dataType: DataType.String, validators: [required()], visible:true});
    }
    
    if (tender?.type === ApiTenderTypeEnum.SavedOnFile) {
      atributes.push({
        name: "paymentFileTokenId", dataType: DataType.String, validators: [
          paymentOnFileRequiredValidator(),
          ...paymentsOnFile ? [paymentOnFileExpiredValidator<TenderInfo>()] : []], visible: true
      }
      );
    }

    if (tender?.type === ApiTenderTypeEnum.CardDevice) {
      atributes.push({ name: "deviceSerialNumber", dataType: DataType.String, validators: [required()], visible:true });
    }

    if (tender?.type === ApiTenderTypeEnum.CardManual) {
      checkCardManualPayment(atributes);
    }

    if (tender?.type === ApiTenderTypeEnum.ECheck) {
      checkECheckPayment(atributes, tender);
    }

    if (tender?.type === ApiTenderTypeEnum.PaperCheckAsECheck) {
      atributes.push({ name: "paperCheckNumber", dataType: DataType.String, validators: [required()], visible:true });
    }

    if (tender?.type === ApiTenderTypeEnum.MoneyOrder) {
      atributes.push({ name: "moneyOrderSerialNumber", dataType: DataType.String, validators: [required(), length({ max: 15 }),], visible:true });
      atributes.push({
        name: 'moneyOrderAmount',
        dataType: DataType.Money,
        validators: [
          required(),
          amountToTotal({ total: getTotalAmount()}) 
        ],
        visible:true
      });
      atributes.push({ name: "moneyOrderIssueDate", dataType: DataType.String, validators: [required(),], visible:true });
    }

    if (tender?.type === ApiTenderTypeEnum.Cash) {
      atributes.push({
        name: 'amountTendered',
        dataType: DataType.Money,
        validators: [
          required(),
          amountToTotal({ total: getTotalAmount()})     
        ],
        visible:true
      });
    }
    return atributes;
  };

  function checkCardManualPayment(atributes: GenericAttribute<TenderInfo>[]) {
    if (singlePayment) {
      atributes.push({ name: "billingZipCode", dataType: DataType.String, validators: [length({ min: 5, max: 5 })], visible:true });
    } else {
      atributes.push({ name: "firstName", dataType: DataType.String, validators: [required()], visible:true });
      atributes.push({ name: "lastName", dataType: DataType.String, validators: [required()], visible:true });
    }

    if(!isPlan) {
      atributes.push({ name: "billingFirstName", dataType: DataType.String, validators: [required()], visible:true });
      atributes.push({ name: "billingLastName", dataType: DataType.String, validators: [required()], visible:true });
    }
    atributes.push({ name: "cardNumber", dataType: DataType.String, validators: [required(), length({ max: 19 }), cardTypeValidator(allowedCardTypes)], visible: true });
    atributes.push({ name: "expirationDate", dataType: DataType.String, validators: [required(), date({ min: minDate })], visible:true });
    atributes.push({ name: "cvc", dataType: DataType.String, validators: [required(), length({ min: 3, max: 4 })], visible:true });
  }


  function checkECheckPayment(atributes: GenericAttribute<TenderInfo>[], tender: TenderInfo) {
    atributes.push({ name: "firstName", dataType: DataType.String, validators: [required(), length({max: 50})], visible:true });
    atributes.push({ name: "lastName", dataType: DataType.String, validators: [required(), length({max: 50})], visible:true });
    atributes.push({ name: "routingNumber", dataType: DataType.String, validators: [required(), length({ min: 9, max: 9 })], visible:true });
    atributes.push({ name: "confirmRoutingNumber", dataType: DataType.String, validators: [...tender.isPaperCheck ? [] : [required(), length({ min: 9, max: 9 }), equal(tender.routingNumber, "Routing Number")]], visible:true });
    atributes.push({ name: "financialInstitution", dataType: DataType.String, validators: [length({max: 50})], visible:true });
    atributes.push({ name: "accountNumber", dataType: DataType.String, validators: [required(), length({ min: 6, max: 17 }),], visible:true });
    atributes.push({ name: "confirmAccountNumber", dataType: DataType.String, validators: [...tender.isPaperCheck ? [] : [required(), length({ min: 6, max: 17 }), equal(tender.accountNumber, "Account Number")]], visible:true });
    atributes.push({ name: "accountType", dataType: DataType.String, validators: [required()], visible:true });
  }

  function paymentOnFileRequiredValidator<T>(): GenericValidator<T> {
    const validator = required();

    return {
      message: validator.message,
      name: ValidatorType.PaymentOnFileRequiredValidator,
      custom: (props: GenericValidatorProps<T>) => {
        const { value, onPass, onFail } = props;
        if (!value && validator?.value) {
          onFail && onFail();
          return;
        }

        const paymentOnFile = paymentsOnFile?.find(p => p.tokenId == value);
        if (paymentOnFile) {
          onPass && onPass();
        } else {
          onFail && onFail();
        }
      }
    }
  }

  //validate if used card is allowed
  function cardTypeValidator<T>(allowedCardTypes: (CreditCardBrand|undefined)[]): GenericValidator<T> {
    const validator = {
      message: 'Card type not allowed',
      name: ValidatorType.CardTypeValidator,
    }

    return {
      ...validator,
      custom: (props: GenericValidatorProps<T>) => {
        const { value, onPass, onFail } = props;
        if (value) {
          const brand = getCardBrand(value.toString());
          if (allowedCardTypes?.includes(brand)) {
            onPass && onPass();
          } else {
            onFail && onFail();
          }
        }
      }
    }
  }

  function paymentOnFileExpiredValidator<T>(): GenericValidator<T> {
    const validator = {
      message: 'Expired payment method',
      name: ValidatorType.PaymentOnFileExpiredValidator,
      value: {min: minDate},
    }

    return {
      ...validator,
      custom: (props: GenericValidatorProps<T>) => {
        const { value, onPass, onFail } = props;
        const paymentOnFile = paymentsOnFile?.find(p => p.tokenId == value);

        if (paymentOnFile?.tenderType == EnumTransactionTenderType.Card && paymentOnFile?.cardExpirationDate) {
          validateDateRange({
            value: paymentOnFile.cardExpirationDate,
            validator,
            dataType: DataType.String,
            onPass,
            onFail,
            }
          );
        }
      }
    }
  }

  function validateAll(tender?: TenderInfo, validOnly: boolean = false) {
    let valid = true;
    tender && getAttributes().forEach(attribute => {
      let result = validate(attribute, tender[attribute.name], validOnly);
      valid = valid && result;
    });
    return valid;
  }

  function validate(attr?: GenericAttribute<TenderInfo>, value?: any, validOnly: boolean = false): boolean {
    return validateGeneric({
      onPass: (key: keyof TenderInfo, validatorType: ValidatorType) => dispatch(removeError({ key, validatorType })),
      onFail: (key: keyof TenderInfo, validatorType: ValidatorType, message?: string) =>
        dispatch(addError({
          [key]: { [validatorType]: message || '' }
        })),
      attr,
      value,
      validOnly
    })
  }

  const errors = useTypedSelector(s => s.paymentPlanInfo.errors);

  function getErrorMessage(name: keyof TenderInfo) {
    return errors[name] && Object.values(errors[name]).length ?  Object.values(errors[name]).join(', ') : '';
  }

  function errorClass(name: keyof TenderInfo) {
    return errors[name] && Object.values(errors[name]).length ? 'invalid-field' : '';
  }

  function formatLabel(name: keyof TenderInfo, label: string) {
    const attribute = getAttribute(getAttributes(), name);
    return attribute ? getLabel({ ...attribute, label }) : label;
  }

  return { validate, validateAll, attributes: getAttributes(), errorClass, getErrorMessage, formatLabel}
}