import {
  WizardStep,
  WizardState,
  useWizard,
  Wizard,
} from 'features/wizard/Wizard';

export enum PaymentSteps {
  payer = 'payerdetails',
  plan = 'plandetails',
  tender = 'tenderinfo',
  authorization = 'authorization',
  created = 'created',
  singlePaymentTender = 'singlePaymentTender',
  singlePaymentAuthorization = 'singlePaymentAauthorization',
  singlePaymentComplete = 'singlePaymentComplete',
}

export function getWizardSteps(isGl: boolean): WizardStep[] {
  return [
    { name: 'Pay Now Tender Details', slug: PaymentSteps.singlePaymentTender },
    { name: (isGl ? 'GL ' : '') + 'Payer Details', slug: PaymentSteps.payer },
    { name: 'Plan Details', slug: PaymentSteps.plan },
    { name: 'Authorization', slug: PaymentSteps.authorization },
    {
      name: (isGl ? 'GL ' : '') + 'Payment Plan Created',
      slug: PaymentSteps.created,
    },
    {
      name: 'Pay Now Authorization',
      slug: PaymentSteps.singlePaymentAuthorization,
    }, //Authorization
    {
      name: 'Pay Now Payment Complete',
      slug: PaymentSteps.singlePaymentComplete,
    }, //Payment Complete
  ];
}

function getInitialWizardState(isGl: boolean): WizardState {
  const steps = getWizardSteps(isGl);
  return {
    steps,
    currentStep: steps[0],
  };
}

export function usePaymentWizzard(isGl = false): Wizard {
  return useWizard(getInitialWizardState(isGl));
}
