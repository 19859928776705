import { TabbedContent } from "@EHDS/core";
import UserForm from "../../../features/admin/UsersAndPermissions/UserForm";
import PermissionsManagement from "pages/Admin/UsersAndPermissions/PermissionsManagement";
import { useLoggedUserPermissions } from "app/hooks/useLoggedUserPermissions";
import UserPermissions from "models/enums/UserPermissions";
import "assets/styles/components/_usersAndPermissions.scss"
import ClonePermissions from "features/admin/UsersAndPermissions/CloneUserPermissions";
import OrganizationForm from "../../../features/admin/UsersAndPermissions/OrganizationTab/OrganizationForm";
import useOrganizationsUsersState from "../../../features/admin/UsersAndPermissions/hooks/useOrganizationsUsersState";
import { ReactElement, useEffect, useState } from "react";
import { useTypedSelector } from "app/rootReducer";

export default function UsersAndPermissions(props: { organizationPath?: string }){
  const { hasPermissionOrSuperAdmin } = useLoggedUserPermissions();
  const { organizationPath } = props
  const organizationsUsersState = useOrganizationsUsersState();
  const loggedUser = useTypedSelector(s => s.loginInfo?.loggedUser);
  const sspUserPermission = hasPermissionOrSuperAdmin(UserPermissions.SelfServicePortal, organizationPath);
  const isAdmin = hasPermissionOrSuperAdmin(UserPermissions.FinancialAdministation, organizationPath);
  const loggedUserOrganizationLevel = loggedUser?.organizationLevels?.length ? loggedUser?.organizationLevels[0] : null;

  let tabs: {
    content: ReactElement, label: string
  }[] = [
    ];

  const { clearState, organizations, useGetTradingPartners, facilities } = organizationsUsersState;

  const [tab, setTab] = useState<number>(0);
  const [resetSelection, setResetSelection] = useState(false);
  const [reset, setReset] = useState(false);
  useEffect(() => {
    if (reset) {
      window.setTimeout(() => { setReset(false) }, 300);
    }
  }, [reset])

  if (isAdmin || sspUserPermission) {
    tabs.push({
      content: <OrganizationForm {...organizationsUsersState}
        setTab={(index: number) => {
          setTab(index);
          setReset(true);
          setResetSelection(false);
        }}
      />,
      label: 'Organization'
    },
    {
      content: <UserForm
        {...{ clearState, organizations, useGetTradingPartners, facilities, sspUserPermission, isAdmin, resetSelection }} />,
      label: 'User'
    },
    {
      content: <PermissionsManagement isAdmin={isAdmin} sspUserPermission={sspUserPermission} loggedUserOrganizationLevel={loggedUserOrganizationLevel} />,
      label: 'Permission'
    },
    {
      content: <ClonePermissions hasSSPPermission={sspUserPermission} loggedUserOrganizationLevel={loggedUserOrganizationLevel} />,
      label: 'Clone Permissions'
    });
  }

  return <div id="test">
    {!reset ? <TabbedContent
      tabs={tabs}
      onTabClick={(i) => { 
        setTab(i);
        setResetSelection(true);
      }}
      initialTab={tab}
    /> : null}
  </div>
}