import { EDS_Button } from '@EH/eh.eds.react';

export default (props: {
  isOpen: boolean;
  seconds: number;
  logOut: () => void;
  extendSession: () => void;
}) => {
  const { isOpen, seconds, logOut, extendSession } = props;
  const openClass = isOpen ? 'eds-modal.open' : '';

  return <div>
      <section className={`eds-modal ${openClass}`}>
        <div className="eds-modal_#container" style={{ top: 300 }}>
          <div
            style={{
              marginLeft: -32,
              marginRight: -32,
              marginTop: -24,
              backgroundColor: '#fff1bd',
              top: 30,
            }}
          >
            <i
              className={'material-icons'}
              style={{
                fontSize: 14,
                paddingRight: 5,
                paddingLeft: 13,
                color: '#fdbc6f',
                cursor: 'pointer',
              }}
            >
              info
            </i>
            Your session is about to expire!
          </div>
          <div style={{ marginTop: 20 }}>
            <p className="eds-modal_#content">
              You will be logged out in {seconds} seconds.
            </p>
            <p className="eds-modal_#conten">Do you want to stay signed in?</p>
          </div>
          <hr />
          <footer className="eds-modal_#footer">
            <div className={'text-center'}>
              <EDS_Button
                buttonText={'No, Sign me out'}
                modifiers={'eds-button eds-button.basic'}
                onClick={logOut}
              />{' '}
              <EDS_Button
                buttonText={'Yes, Keep me signed in'}
                modifiers={'eds-button eds-button.primary'}
                onClick={extendSession}
              />
            </div>
          </footer>
        </div>
      </section>
    </div>;
};
