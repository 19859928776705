import { getPaymentPanelRegExTemplates, PaymentPanelSelectOption, RegexTemplateOptions } from "services/PaymentPanelService";
import { useMemo, useState } from "react";

export function useGetRegExTemplates() {
  const [regExTemplates, setRegExTemplates] = useState<PaymentPanelSelectOption[]>([]);
  useMemo(async () => {
    const response = await getPaymentPanelRegExTemplates();
    let options = mapRegExTemplateOptions(response.result ?? []);
    options.unshift({optionName:"", value:""});
    setRegExTemplates(options);
  }, []);

  return { regExTemplates }
}

function mapRegExTemplateOptions(options: RegexTemplateOptions) {
  return options.map(o => ({
    optionName: o.description,
    value: o.regExRule
  }));
}