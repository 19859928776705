import { useEffect, useState } from 'react';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import 'assets/styles/components/_clientConfiguration.scss';
import ConfigurationTemplate from '../../features/admin/clientConfiguration/ConfigurationTemplate';
import 'assets/styles/components/_receiptManager.scss';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import {
  EDS_Select,
  EDS_TextBox,
  EDS_Button
} from '@EH/eh.eds.react';
import * as router from 'react-router-dom';
import { deleteReceiptManager, getReceiptManager, updateReceiptManager } from 'features/admin/receiptManager/ReceiptManagerReducer';
import { ReceiptManagerResponse } from 'services/ReceiptManagerService';
import { ReceiptManager } from 'models/ReceiptManagerModel';
import useGetConfiguration from "features/admin/paymentPanel/serviceHandlers/useGetConfiguration";
import { Attribute } from '../../models/metaData/MetaData';
import { getLogoDataForOrganization } from 'features/admin/logoManager/LogoManagerReducer';
import { useUpdateConfiguration } from "../../features/admin/paymentPanel/serviceHandlers/useUpdateConfiguration";
import { saveConfigurationEntity } from 'features/admin/paymentPanel/PaymentPanelReducer';
import { Dashboards } from 'features/admin/paymentPanel/ConfigurationSelector';
import { GetClientConfigurations } from 'services/ClientService';
import { ContactPerson } from 'models/Client';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

export default function ReceiptManagerPage(
  props: Props = { rootPath: 'receipt-manager' }
) {
  const params = router.useParams<{ clientId: string }>();
  const { getOrganizationById } = useOrganizations();
  const selectedFacilityId = params.clientId;
  const organization = getOrganizationById(selectedFacilityId)
  const clientConfiguration = useTypedSelector(
    s => s.getClientFacilityConfiguration?.result
  );

  const isProcessing = useTypedSelector(s => s.services.calls.GetClientConfiguration?.isProcessing);
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [contactPerson, setContactPerson] = useState<ContactPerson>({ address: {}, phone1: '', phone2: '' });
  const organizationPath = clientConfiguration?.organization.path;
  const { getPanelConfiguration } = useGetConfiguration(organizationPath);

  const logos = useTypedSelector(s => s.logoManager?.logos);
  const [receiptManagerValues, setReceiptManagerValues] = useState<ReceiptManager>({});

  const [selectedDashboard] = useState<keyof Dashboards>();
  const { updateConfigurationByDashboard } = useUpdateConfiguration(selectedDashboard, organizationPath);
  const paymentConfiguration = useTypedSelector(s => s.paymentPanel.configurations["PaymentDashboard"]);
  const paymentGlConfiguration = useTypedSelector(s => s.paymentPanel.configurations["GlPaymentDashboard"]);
  const paymentPlanConfiguration = useTypedSelector(s => s.paymentPanel.configurations["PaymentPlanDashboard"]);
  const patientSearchPanelConfiguration = useTypedSelector(s => s.paymentPanel.configurations["PatientSearchPopupDashboard"]);
  const contactPersonPhoneNumbers = contactPerson ? { phone1: contactPerson.phone1, phone2: contactPerson.phone2 } : { phone1: '', phone2: '' };

  const dispatch = useDispatch();
  const logoOptions = logos?.map(logo => ({ optionName: logo.name, value: logo.logoId })) ?? [];
  logoOptions.splice(0, 0, { optionName: "Please select a logo", value: "" });

  useEffect(() => {
    if (organizationPath) {
      getPanelConfiguration("PaymentDashboard");
      getPanelConfiguration("GlPaymentDashboard");
      getPanelConfiguration("PaymentPlanDashboard");
      getPanelConfiguration("PatientSearchPopupDashboard");
      LoadReceiptManagerConfiguration(organizationPath);
      getContactPersonInfo(organizationPath);
    }
  }, []);

  useEffect(() => {
    if (receiptManagerValues) {
      if (!receiptManagerValues.generalSettings?.selectedOffSet) {
        onOffSetChange('0');
      }
    }
  }, [receiptManagerValues.generalSettings?.selectedOffSet]);

  function LoadReceiptManagerConfiguration(organizationPath: string) {
    setIsConfigLoading(true);
    dispatch(getLogoDataForOrganization(organizationPath));
    dispatch(getReceiptManager(organizationPath, (response?: ReceiptManagerResponse) => {
      if (!response?.err && response?.result !== undefined) {
        setReceiptManagerValues(response?.result || getEmptyModel());
        setIsConfigLoading(false);
      }
    }));
  }
  const configurationPaymentAttributes = useTypedSelector(s =>
    s.paymentPanel.configurations.PaymentDashboard?.entity?.attributes
  ) ?? [];

  const configurationGlPaymentAttributes = useTypedSelector(s =>
    s.paymentPanel.configurations.GlPaymentDashboard?.entity?.attributes
  ) ?? [];

  const configurationPatientSearchAttributes = useTypedSelector(s =>
    s.paymentPanel.configurations.PatientSearchPopupDashboard?.entity?.attributes
  ) ?? [];

  const configurationPaymentPlanAttributes = useTypedSelector(s =>
    s.paymentPanel.configurations.PaymentPlanDashboard?.entity?.attributes
  ) ?? [];

  let paymentPlanAttributes = [...configurationPaymentPlanAttributes];

  const getContactPersonInfo = async (organizationPath: string) => {
    const address = await GetClientConfigurations([organizationPath]);
    if (address.result) {
      setContactPerson(address.result[0].contactPerson);
    }
  }

  const paymentPlanComponents = paymentPlanAttributes
    .filter((a: Attribute) => a.name !== 'isActive' && a.name !== 'isEditing')
    .map((attribute: Attribute, index: number) => {
      const key = `${attribute.name}-${index}-paymentplans`;
      const divkey = `${attribute.name}-${index}-payment-container`;
      return (
        <div key={divkey} className="col-md-6 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={key}
              checked={attribute.visibleOnReceipt}
              onChange={() => { onChangeAttribute("PaymentPlanDashboard", attribute, !attribute.visibleOnReceipt) }}
            />
            <span className={`eds-checkbox_#label`}>{attribute.label}</span>
          </label>
        </div>
      )
    });

  let glPaymentAttributes = [...configurationGlPaymentAttributes];

  const glPaymentComponents = glPaymentAttributes
    .filter((a: Attribute) => a.name !== 'isActive' && a.name !== 'isEditing')
    .map((attribute: Attribute, index: number) => {
      const key = `${attribute.name}-${index}-gl`;
      const divkey = `${attribute.name}-${index}-payment-container`;
      return (
        <div key={divkey} className="col-md-6 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={key}
              checked={attribute.visibleOnReceipt}
              onChange={() => { onChangeAttribute("GlPaymentDashboard", attribute, !attribute.visibleOnReceipt) }}
            />
            <span className={`eds-checkbox_#label`}>{attribute.label}</span>
          </label>
        </div>
      )
    });

  let paymentAttributes = [...configurationPaymentAttributes];

  const paymentComponents = paymentAttributes
    .filter((a: Attribute) => a.name !== 'isActive' && a.name !== 'isEditing')
    .map((attribute: Attribute, index: number) => {
      const key = `${attribute.name}-${index}-payment`;
      const divkey = `${attribute.name}-${index}-payment-container`;
      return (
        <div key={divkey} className="col-md-6 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={key}
              checked={attribute.visibleOnReceipt}
              onChange={() => { onChangeAttribute("PaymentDashboard", attribute, !attribute.visibleOnReceipt) }}
            />
            <span className={`eds-checkbox_#label`}>{attribute.label}</span>
          </label>
        </div>
      )
    });

  let patientInfoAttributes = [...configurationPatientSearchAttributes];

  const patientInfoComponents = patientInfoAttributes
    .filter((a: Attribute) => a.name !== 'isActive' && a.name !== 'isEditing')
    .map((attribute: Attribute, index: number) => {
      const key = `${attribute.name}-${index}-patientinfo`;
      const divkey = `${attribute.name}-${index}-patientinfo-container`;
        return (
          <div key={divkey} className="col-md-3 auto-width">
            <label className='checkbox-columns auto-width'>
              <input
                className={'eds-checkbox_#input'}
                type={'checkbox'}
                name={key}
                checked={attribute.visibleOnReceipt}
                onChange={() => onChangeAttribute("PatientSearchPopupDashboard", attribute, !attribute.visibleOnReceipt)}
              />
              <span className={`eds-checkbox_#label`}>{attribute.label}</span>
            </label>
          </div>
        )
  });

  const onChangeAttribute = (panelkey: keyof Dashboards, attr?: Attribute, value?: any) => {
    if (!attr) return;

    dispatch(saveConfigurationEntity({ key: panelkey, attributeName: attr.name, name: "visibleOnReceipt", value: value, newCustomAttributeName: undefined }));
  };

  const offsetOptions = [{ optionName: "20px right", value: "0" }, { optionName: "50px right", value: "1" }, { optionName: "50px right, 5px down", value: "2" }];

  function UpdateConfigurationAttributes() {
    updateConfigurationByDashboard("PaymentDashboard", paymentConfiguration);
    updateConfigurationByDashboard("GlPaymentDashboard", paymentGlConfiguration);
    updateConfigurationByDashboard("PaymentPlanDashboard", paymentPlanConfiguration);
    updateConfigurationByDashboard("PatientSearchPopupDashboard", patientSearchPanelConfiguration);
  }

  const handleUpdate = () => {
    if (clientConfiguration) {
      UpdateConfigurationAttributes();
      let receiptManager = { ...receiptManagerValues, organization: { id: organization?.id, name: organization?.name, path: organization?.path } };
      dispatch(updateReceiptManager(organizationPath ?? '', receiptManager, (response?: ReceiptManagerResponse) => {
        if (!response?.err) {
          dispatch(
            setAlert({
              id: AlertIds.ReceiptManagerAlert,
              type: AlertTypes.Success,
              message: `Receipt configuration saved successfully`,
              dismissable: true,
            })
          );
        }
        else {
          dispatch(
            setAlert({
              id: AlertIds.ReceiptManagerAlert,
              type: AlertTypes.Error,
              message: response?.err ?? 'Error saving Receipt configuration',
              dismissable: true,
            })
          );
        }
      }));
    }
  };

  const handleReset = async () => {
    if (clientConfiguration) {
      paymentAttributes.forEach(attr => {
        dispatch(saveConfigurationEntity({ key: "PaymentDashboard", attributeName: attr.name, name: "visibleOnReceipt", value: false, newCustomAttributeName: undefined }));
      });
      glPaymentAttributes.forEach(attr => {
        dispatch(saveConfigurationEntity({ key: "GlPaymentDashboard", attributeName: attr.name, name: "visibleOnReceipt", value: false, newCustomAttributeName: undefined }));
      });
      paymentPlanAttributes.forEach(attr => {
        dispatch(saveConfigurationEntity({ key: "PaymentPlanDashboard", attributeName: attr.name, name: "visibleOnReceipt", value: false, newCustomAttributeName: undefined }));
      });
      patientInfoAttributes.forEach(attr => {
        dispatch(saveConfigurationEntity({ key: "PatientSearchPopupDashboard", attributeName: attr.name, name: "visibleOnReceipt", value: false, newCustomAttributeName: undefined }));
      });

      UpdateConfigurationAttributes();

      if (receiptManagerValues.id) {
        dispatch(deleteReceiptManager(receiptManagerValues.id ?? '', (response?: ReceiptManagerResponse) => {
          if (!response?.err) {
            SuccessfulReset();
          }
          else {
            dispatch(
              setAlert({
                id: AlertIds.ReceiptManagerAlert,
                type: AlertTypes.Error,
                message: response?.err ?? 'Error reseting Receipt configuration',
                dismissable: true,
              })
            );
          }
        }));
      }
      else {
        SuccessfulReset();
      }
    }

  };

  function SuccessfulReset() {
    LoadReceiptManagerConfiguration(organizationPath ?? "");
    dispatch(
      setAlert({
        id: AlertIds.ReceiptManagerAlert,
        type: AlertTypes.Success,
        message: `Receipt configuration reseted successfully`,
        dismissable: true,
      })
    );
  }

  function paymentPlanSummary() {
    return <div className='settings'>
      <h3>Payment Plan Summary (Table Columns)</h3>
      <div className="row receiptRow">
        {paymentPlanComponents}
      </div>
    </div>
  }

  function glPaymentSummary() {
    return <div className='settings'>
      <h3>GL Payment Summary (Table Columns)</h3>
      <div className="row receiptRow">
        {glPaymentComponents}
      </div>
    </div>
  }

  function paymentSummary() {
    return <div className='settings'>
      <h3>Payment Summary (Table Columns)</h3>
      <div className="row receiptRow">
        {paymentComponents}
      </div>
    </div>
  }

  function onOffSetChange(value: string) {
    let settings = { ...receiptManagerValues.generalSettings, selectedOffSet: value }
    setReceiptManagerValues({ ...receiptManagerValues, generalSettings: settings })
  }

  function generalSettings() {

    return <div className='settings'>
      <h3>General Settings</h3>
      <div className="row receiptRow">
        <div className="col-md-6 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-autoSendReceipt'}
              checked={receiptManagerValues?.generalSettings?.hasAutomaticallySendReceipt}
              onChange={() => {

                let settings = { ...receiptManagerValues.generalSettings, hasAutomaticallySendReceipt: !receiptManagerValues?.generalSettings?.hasAutomaticallySendReceipt }
                setReceiptManagerValues({ ...receiptManagerValues, generalSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Automatically Send Receipt After GL Payment</span>
          </label>
        </div>

        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-multilingualUnifiedReceipts'}
              checked={receiptManagerValues?.generalSettings?.hasMultilingualUnifiedReceipts}
              onChange={() => {
                let settings = { ...receiptManagerValues.generalSettings, hasMultilingualUnifiedReceipts: !receiptManagerValues?.generalSettings?.hasMultilingualUnifiedReceipts }
                setReceiptManagerValues({ ...receiptManagerValues, generalSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Multilingual Unified Receipts</span>
          </label>
        </div>
      </div>

      <div className="row receiptRow">
        <div className="col-md-6 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-autoSendReceipt'}
              checked={receiptManagerValues?.generalSettings?.hasOffsetMailingAddress}
              onChange={() => {
                let settings = { ...receiptManagerValues.generalSettings, hasOffsetMailingAddress: !receiptManagerValues?.generalSettings?.hasOffsetMailingAddress }
                setReceiptManagerValues({ ...receiptManagerValues, generalSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Offset Mailing Address:</span>
          </label>
        </div>
        <div className="col-md-6 auto-width">
          <div className='checkbox-columns auto-width value-padding'>
            <EDS_Select
              name="addressToEnvelop"
              label=""
              options={offsetOptions}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                onOffSetChange(e.target.value);
              }}
              value={receiptManagerValues?.generalSettings?.selectedOffSet}
            ></EDS_Select>
          </div>
        </div>
      </div>
    </div>
  }

  function facility() {

    function onLogoChange(value: string) {
      let settings = { ...receiptManagerValues.facilitySettings, selectedLogo: value }
      setReceiptManagerValues({ ...receiptManagerValues, facilitySettings: settings })
    }

    return <div className='settings'>
      <h3>Facility</h3>
      <div className="row receiptRow">
        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-facilityLogo'}
              checked={receiptManagerValues?.facilitySettings?.showFacilityLogo}
              onChange={() => {
                let settings = { ...receiptManagerValues.facilitySettings, showFacilityLogo: !receiptManagerValues?.facilitySettings?.showFacilityLogo }
                setReceiptManagerValues({ ...receiptManagerValues, facilitySettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Facility Logo</span>
          </label>
        </div>
        <div className="col-md-3 auto-width">
          <div className='checkbox-columns auto-width value-padding'>
            <EDS_Select
              name="facilityLogo"
              label=""
              options={logoOptions}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                onLogoChange(e.target.value);
              }}
              value={receiptManagerValues?.facilitySettings?.selectedLogo}
            ></EDS_Select>
          </div>
        </div>

        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-facilityCustomName'}
              checked={receiptManagerValues?.facilitySettings?.showFacilityNameOnReceipt}
              onChange={() => {
                let settings = { ...receiptManagerValues.facilitySettings, showFacilityNameOnReceipt: !receiptManagerValues?.facilitySettings?.showFacilityNameOnReceipt }
                setReceiptManagerValues({ ...receiptManagerValues, facilitySettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Use Custom Facility Name</span>
          </label>
        </div>
        <div className="col-md-3 auto-width">
          <EDS_TextBox
            modifiers="tbMid"
            name="tbMid"
            label=""
            placeHolder={"Type Facility Name"}
            disabled={!receiptManagerValues?.facilitySettings?.showFacilityNameOnReceipt ?? false}
            value={receiptManagerValues?.facilitySettings?.customFacilityName ?? ""}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              let settings = { ...receiptManagerValues.facilitySettings, customFacilityName: e.target.value }
              setReceiptManagerValues({ ...receiptManagerValues, facilitySettings: settings })
            }}
          ></EDS_TextBox>
        </div>

        <div className="col-md-3 auto-width">
          <label className='checkbox-column auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-facilityAddress'}
              checked={receiptManagerValues?.facilitySettings?.showFacilityAddress}
              onChange={() => {
                let settings = { ...receiptManagerValues.facilitySettings, showFacilityAddress: !receiptManagerValues?.facilitySettings?.showFacilityAddress }
                setReceiptManagerValues({ ...receiptManagerValues, facilitySettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Facility Address</span>
          </label>
        </div>
      </div>
      <div className="row receiptRow">
        <div className="col-md-3 auto-width">
          <span>Facility Phone Number</span>
        </div>
        <div className="col-md-3 auto-width">
          <EDS_TextBox
            modifiers="customFacilityPhoneNumber phone-number-field"
            name="customFacilityPhoneNumber"
            label=""
            placeHolder={"XXX-XXX-XXXX"}
            value={receiptManagerValues?.facilitySettings?.customFacilityPhoneNumber || contactPersonPhoneNumbers.phone1 || contactPersonPhoneNumbers.phone2}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              let settings = { ...receiptManagerValues.facilitySettings, customFacilityPhoneNumber: e.target.value }
              setReceiptManagerValues({ ...receiptManagerValues, facilitySettings: settings });
              setContactPerson({ ...contactPerson, phone1: e.target.value, phone2: e.target.value })
            }}
          ></EDS_TextBox>
        </div>
        {getWarningMessage(receiptManagerValues?.facilitySettings?.customFacilityPhoneNumber || contactPersonPhoneNumbers.phone1 || contactPersonPhoneNumbers.phone2)}
      </div>
    </div>
  }

  function payerInformation() {
    return <div className='settings'>
      <h3>Payer Information</h3>
      <div className="row receiptRow">
        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-payerName'}
              checked={receiptManagerValues?.payerSettings?.showPayerName}
              onChange={() => {
                let settings = { ...receiptManagerValues.payerSettings, showPayerName: !receiptManagerValues?.payerSettings?.showPayerName }
                setReceiptManagerValues({ ...receiptManagerValues, payerSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Payer Name</span>
          </label>
        </div>
        <div className="col-md-3 auto-width">
          <label className='checkbox-column auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-payerMailingAddress'}
              checked={receiptManagerValues?.payerSettings?.showPayerMailingAddress}
              onChange={() => {
                let settings = { ...receiptManagerValues.payerSettings, showPayerMailingAddress: !receiptManagerValues?.payerSettings?.showPayerMailingAddress }
                setReceiptManagerValues({ ...receiptManagerValues, payerSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Payer Mailing Address</span>
          </label>
        </div>
      </div>
    </div>
  }

  function getWarningMessage(showMessage?: string) {
    if (!showMessage) {
      return <div className="col-md-3 auto-width">
        <span className={'error-message-required'}>Merchant Compliance Phone Number is required to be present on receipt.</span>
      </div>
    }
  }

  function patientInfo() {
    if (!patientInfoComponents || patientInfoComponents.length === 0) {
      return null;
    }
    
    return (
      <div className='settings'>
        <h3>Patient Information</h3>
        <div className="row receiptRow">
          {patientInfoComponents}
        </div>
      </div>
    )
  }

  function paymentInfo() {
    return <div className='settings'>
      <h3>Payment Information</h3>
      <div className="row receiptRow">
        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-originalBalance'}
              checked={receiptManagerValues?.paymentSettings?.showOriginalBalance}
              onChange={() => {
                let settings = { ...receiptManagerValues.paymentSettings, showOriginalBalance: !receiptManagerValues?.paymentSettings?.showOriginalBalance }
                setReceiptManagerValues({ ...receiptManagerValues, paymentSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Original Balance</span>
          </label>
        </div>
        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-createdOnDate'}
              checked={receiptManagerValues?.paymentSettings?.showCreatedDate}
              onChange={() => {
                let settings = { ...receiptManagerValues.paymentSettings, showCreatedDate: !receiptManagerValues?.paymentSettings?.showCreatedDate }
                setReceiptManagerValues({ ...receiptManagerValues, paymentSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>"Created On" Date</span>
          </label>
        </div>
        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-settledOnDate'}
              checked={receiptManagerValues?.paymentSettings?.showSettledDate}
              onChange={() => {
                let settings = { ...receiptManagerValues.paymentSettings, showSettledDate: !receiptManagerValues?.paymentSettings?.showSettledDate }
                setReceiptManagerValues({ ...receiptManagerValues, paymentSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>"Settled On" Date</span>
          </label>
        </div>
        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-showUserName'}
              checked={receiptManagerValues?.paymentSettings?.showUserName}
              onChange={() => {
                let settings = { ...receiptManagerValues.paymentSettings, showUserName: !receiptManagerValues?.paymentSettings?.showUserName }
                setReceiptManagerValues({ ...receiptManagerValues, paymentSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Show User Name</span>
          </label>
        </div>
        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-discount'}
              checked={receiptManagerValues?.paymentSettings?.showDiscount}
              onChange={() => {
                let settings = { ...receiptManagerValues.paymentSettings, showDiscount: !receiptManagerValues?.paymentSettings?.showDiscount }
                setReceiptManagerValues({ ...receiptManagerValues, paymentSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Discount</span>
          </label>
        </div>
        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width value-padding'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-patientNotes'}
              checked={receiptManagerValues?.paymentSettings?.showPatientNotes}
              onChange={(e) => {
                let settings = { ...receiptManagerValues.paymentSettings, showPatientNotes: e.target.checked }
                setReceiptManagerValues({ ...receiptManagerValues, paymentSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Patient Notes</span>
          </label>
        </div>
      </div>
    </div>
  }


  function footer() {
    return <div className='settings'>
      <h3>Footer</h3>
      <div className="row receiptRow">
        <div className="col-md-3 auto-width">
          <label className='checkbox-columns auto-width'>
            <input
              className={'eds-checkbox_#input'}
              type={'checkbox'}
              name={'receiptManager-signatureLine'}
              checked={receiptManagerValues?.footerSettings?.showSignatureLine}
              onChange={() => {
                let settings = { ...receiptManagerValues.footerSettings, showSignatureLine: !receiptManagerValues?.footerSettings?.showSignatureLine }
                setReceiptManagerValues({ ...receiptManagerValues, footerSettings: settings })
              }}
            />
            <span className={`eds-checkbox_#label`}>Signature Line</span>
          </label>
        </div>
      </div>
    </div>
  }

  function renderSubmitButtons() {
    return <div className="footer-buttons">
      <EDS_Button
        modifiers={'eds-button eds-button.secondary'}
        buttonText={'Reset'}
        onClick={() => handleReset()}
      />
      <EDS_Button
        modifiers={'eds-button eds-button.primary'}
        buttonText={'Save'}
        onClick={() => handleUpdate()}
      />
    </div>
  }

  if (isProcessing || !organizationPath || isConfigLoading) {
    return <p>Getting Receipt Manager configuration...</p>;
  }
  else
    return (
      <div className="receipt-manager">
        <ConfigurationTemplate title="Receipt Manager">
          <div>
            {generalSettings()}
            {facility()}
            {payerInformation()}
            {patientInfo()}
            {paymentInfo()}
            {paymentSummary()}
            {glPaymentSummary()}
            {paymentPlanSummary()}
            {footer()}
            {renderSubmitButtons()}
          </div>
        </ConfigurationTemplate>
      </div>
    );
}
export function getEmptyModel(): ReceiptManager | (() => ReceiptManager) {
  return {
    generalSettings: { hasAutomaticallySendReceipt: false, hasMultilingualUnifiedReceipts: false, hasOffsetMailingAddress: false },
    facilitySettings: { showFacilityAddress: false, showFacilityLogo: false, showFacilityNameOnReceipt: false, showFacilityPhone: false },
    patientSettings: { showPatientDOB: false, showPatientId: false, showPatientName: false, showPatientPhone: false },
    paymentSettings: { showCreatedDate: false, showDiscount: false, showOriginalBalance: false, showSettledDate: false, showUserName: false, showPatientNotes: false, }
  }
}

