import { useDispatch } from "react-redux";
import { PaymentConfiguration } from "../../../models/PaymentsConfiguration";
import { updatePaymentConfiguration } from "../../../services/TransactionsService";
import { AlertIds, AlertTypes, setAlert } from "../../alert/AlertReducer";

export function useSaveGlList(onSuccess?: () => void) {
  const dispatch = useDispatch();
  async function saveGlList(configuration: PaymentConfiguration) {
    if (configuration.organization?.path) {
      const response = await updatePaymentConfiguration(configuration.organization?.path, configuration);
      if (!response.err) {
        dispatch(setAlert({
          id: AlertIds.PaymentConfiguration,
          type: AlertTypes.Success,
          message: "Saved successfully!",
          dismissable: true,
        }))
        onSuccess && onSuccess();
        return;
      }
    }
    dispatch(setAlert({
      id: AlertIds.PaymentConfiguration,
      type: AlertTypes.Error,
      message: "Could not save configuration.",
    }))
  }
  return { saveGlList };
}