import { IWebServiceErrors } from 'models/WebServiceErrors';
import { callHttpApi } from './callHttpApi';

export interface CreateWebServiceErrorResponse {
  result?: IWebServiceErrors;
  err?: string;
}

export interface GetWebServiceErrorResponse {
  result?: IWebServiceErrors[];
  err?: string;
}

export async function getWebServiceErrorService(
  params: any
): Promise<GetWebServiceErrorResponse> {
  return callHttpApi<IWebServiceErrors[]>(
    'get',
    `api/v1/Transactions/errors`,
    undefined,
    params
  );
}
