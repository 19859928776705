import { ReceiptManager } from '../models/ReceiptManagerModel';
import { callHttpApi } from './callHttpApi';

export interface ReceiptManagerResponse {
  result?: ReceiptManager;
  err?: string;
}

export async function UpdateReceiptManager(
  organizationPath: string, data?: ReceiptManager
): Promise<ReceiptManagerResponse> {
  const headers = {
    'Content-Type': 'application/json',
  };
  return callHttpApi(
    'put',
    `/api/v1/ReceiptConfiguration/${organizationPath}`,
    data,
    undefined,
    headers
  );
}

export async function GetReceiptManager(
  organizationPath: string
): Promise<ReceiptManagerResponse> {
  return callHttpApi<ReceiptManager>(
    'get',
    `/api/v1/ReceiptConfiguration/${organizationPath}`,
  );
}

export async function DeleteReceiptManager(id: string): Promise<ReceiptManagerResponse> {
  return callHttpApi<ReceiptManager>(
    'delete',
    `/api/v1/ReceiptConfiguration/${id}`
  );
}