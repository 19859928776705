import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/rootReducer';
import { callService } from 'services/ServicesReducer';
import { ReceiptManager } from 'models/ReceiptManagerModel';
import { ReceiptManagerResponse, DeleteReceiptManager, UpdateReceiptManager, GetReceiptManager } from 'services/ReceiptManagerService';

export interface State {
  err?: string;
  receiptManager?: ReceiptManager;
}

export const initialState: State = {
  err: '',
  receiptManager: undefined,
};

const reducerSlice = createSlice({
  name: 'ReceiptManager',
  initialState,
  reducers: {
    onReceiveReceiptData(
      state,
      action: PayloadAction<ReceiptManagerResponse>
    ) {
      state.receiptManager = action.payload.result;
      state.err = action.payload.err;
    },
   
    setReceiptDataForClient(
      state,
      action: PayloadAction<ReceiptManagerResponse>
    ) {
      state.receiptManager = action.payload.result;
    },
  },
});

export const {
  onReceiveReceiptData,
  setReceiptDataForClient
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function updateReceiptManager(organizationPath: string, data?: ReceiptManager,  onDone?: (result?: ReceiptManagerResponse) => void): AppThunk {
  return async dispatch => {
    dispatch(callService('UpdateReceiptManager', async () => {
      const response = await UpdateReceiptManager(organizationPath, data)
      dispatch(onReceiveReceiptData(response));
      if (onDone) onDone(response);
    }));
  };
}

export function getReceiptManager(organizationPath: string,  onDone?: (result?: ReceiptManagerResponse) => void): AppThunk {
  return async dispatch => {
    dispatch(callService('GetReceiptManager', async () => {
      const response = await GetReceiptManager(organizationPath)
      dispatch(onReceiveReceiptData(response));
      if (onDone) onDone(response);
    }));
  };
}

export function deleteReceiptManager(id: string,   onDone?: (result?: ReceiptManagerResponse) => void): AppThunk {
  return async dispatch => {
    dispatch(callService('DeleteReceiptManager', async () => {
      const result = await DeleteReceiptManager(id)
      if (onDone) onDone(result);
    }));
  };
}

