import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/rootReducer';
import { buildTransactionDetailsUIData } from './builders/BuildTransactionDetailsUIData';
import { getTransactionService } from 'services/TransactionsService';
import * as ErrorMessages from './ErrorMessages';
import {
  EnumTransactionStatusType,
  EnumTransactionCommandType,
} from 'models/enums';
import { MetaData } from 'models/metaData/MetaData';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import { UserDetailModel } from 'models/UserInfoAndRolesModel';
import { LineItem } from '../../models/LineItem';
import { RelatedTransactionsModel } from '../../models/RelatedTransactionsModel';

export interface State {
  transactionId?: string;
  parentId?: string;
  transactionAmount?: number;
  transactionCreator?: UserDetailModel;
  tenderType?: string;
  gatewayReferenceNumber?: string;
  transactionDetailsData?: TransactionDetailsUIData;
  originalTransactionDetailsData?: TransactionDetailsUIData;
  isEditMode: boolean;
  error?: string;
  isOpenModal?: boolean;
  accounts: LineItem[];
}

export interface TransactionDetailsUIData {
  transactionDetails?: TransactionDetailsModel;
  metaData?: MetaData;
  paymentLocation?: FieldSet;
  paymentDetailsColumn1?: FieldSet;
  paymentDetailsColumn2?: FieldSet;
  relatedTransactions?: RelatedTransactionUIData[];
}

export type FieldSet = {
  name: string;
  fields: TransactionDetailsField[];
};

export interface TransactionDetailsField {
  name: string;
  value?: string;
  isEditable?: boolean;
  type: 'text' | 'date' | 'email' | 'tel' | 'transactionId';
}

export interface RelatedTransactionsArray {
  rows: RelatedTransaction[];
}

export interface RelatedTransaction {
  transactionId: string;
  amount: number;
  transactionType: EnumTransactionCommandType;
  statusType: EnumTransactionStatusType;
}

export interface RelatedTransactionUIData {
  transactionId: string;
  amount: number;
  transactionType?: string;
  statusType: string;
}

export const initialState: State = {
  transactionId: undefined,
  parentId: '',
  transactionAmount: 0,
  tenderType: '',
  gatewayReferenceNumber: '',
  transactionDetailsData: {},
  originalTransactionDetailsData: {},
  accounts: [],
  isEditMode: false,
};

const reducerSlice = createSlice({
  name: 'TransactionDetails',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<string>) {
      console.log('error:', state.error);
      state.error = action.payload;
    },
    setTransactionId(state, action: PayloadAction<{ id: string, parentId?: string }>) {
      state.transactionId = action.payload.id;
      state.parentId = action.payload.parentId;
    },
    setTransactionAmount(state, action: PayloadAction<number>) {
      state.transactionAmount = action.payload;
    },
    setTransactionCreator(state, action: PayloadAction<UserDetailModel>) {
      state.transactionCreator = action.payload;
    },
    setPaymentMethod(state, action: PayloadAction<string>) {
      state.tenderType = action.payload;
    },
    setGatewayReferenceNumber(state, action: PayloadAction<string>) {
      state.gatewayReferenceNumber = action.payload;
    },
    setEditMode(state, action: PayloadAction<boolean>) {
      state.isEditMode = action.payload;
    },
    setTransactionDetailsData(
      state,
      action: PayloadAction<TransactionDetailsUIData>
    ) {
      state.transactionDetailsData = action.payload;
      state.originalTransactionDetailsData = state.transactionDetailsData;
    },
    clearState: () => initialState,
    cancelEdits(state) {
      state.transactionDetailsData = state.originalTransactionDetailsData;
      state.isEditMode = false;
    },
    saveEditsInternal(state) {
      state.originalTransactionDetailsData = state.transactionDetailsData;
      state.isEditMode = false;
    },
    setOpenTransactionModel(state, action: PayloadAction<boolean>) {
      state.isOpenModal = action.payload;
    },
    updateAccount(state, action: PayloadAction<{ idx: number, key: keyof LineItem, value: any }>) {
      const { idx, key, value } = action.payload;
      state.accounts[idx] = {
        ...state.accounts[idx],
        [key]: value
      };
    },
    updateAccounts(state, action: PayloadAction<{ accounts: LineItem[] }>){
      state.accounts = action.payload.accounts;
    },

    editField(
      state,
      action: PayloadAction<{
        fieldSet: FieldSet;
        index: number;
        value: string;
      }>
    ) {
      const { fieldSet, index, value } = action.payload;
      const data = state.transactionDetailsData;
      let targetFieldSet: FieldSet | undefined = undefined;

      if (!data) {
        return;
      }

      if (data.paymentLocation?.name === fieldSet.name) {
        targetFieldSet = data.paymentLocation;
      } else if (data.paymentDetailsColumn1?.name === fieldSet.name) {
        targetFieldSet = data.paymentDetailsColumn1;
      } else if (data.paymentDetailsColumn2?.name === fieldSet.name) {
        targetFieldSet = data.paymentDetailsColumn2;
      }

      if (targetFieldSet) {
        const field = targetFieldSet.fields[index];
        if (field) {
          field.value = value;
        }
      }
    },
  },
});

export const {
  setError,
  setTransactionDetailsData,
  setTransactionId,
  setTransactionAmount,
  setTransactionCreator,
  setPaymentMethod,
  setGatewayReferenceNumber,
  setEditMode,
  clearState,
  cancelEdits,
  editField,
  saveEditsInternal,
  setOpenTransactionModel,
  updateAccount,
  updateAccounts
} = reducerSlice.actions;

export default reducerSlice.reducer;

export function getTransactionDetails(transactionId: string, isModal: boolean = false): AppThunk | void {
  return async (dispatch, getState) => {
    // call api to fetch the transaction data
    const transactionDetailsResult = await getTransactionService(transactionId);

    if (transactionDetailsResult.err) {
      dispatch(setError(transactionDetailsResult.err));
      return;
    }

    if (!transactionDetailsResult.result) {
      dispatch(setError(ErrorMessages.failedToGetTransaction));
      return;
    }

    // build the ui model of the transaction details
    const transactionDetails = buildTransactionDetailsUIData(transactionDetailsResult.result);

    dispatch(setTransactionDetailsData(transactionDetails));
    dispatch(updateAccounts({
      accounts: transactionDetails.transactionDetails?.lineItems?.map(
        lineItem => {
          const maxAmount = getMaxAmount(lineItem.amount, transactionDetails.transactionDetails?.relatedTransactions, lineItem.accountNumber)
          return { ...lineItem, balance: maxAmount - (lineItem.discount ?? 0), amount: maxAmount - (lineItem.discount ?? 0) }
        }
      ) ?? []
    }));
  };
}

export function getRelatedLineItems(relatedTransactions?: RelatedTransactionsModel[], accountNumber?: string) {
  const lineItems: LineItem[] = [];
  if (!accountNumber || !relatedTransactions) return lineItems;
  relatedTransactions.forEach(transaction => {
    if (transaction.transactionType?.toString() == "Credit" && transaction.lineItems) {
      transaction.lineItems.forEach(lineItem => {
        if (lineItem.accountNumber === accountNumber) {
          lineItems.push(lineItem);
        }
      })
    }
  })
  return lineItems;
}

export function getMaxAmount(amount: number, relatedTransactions?: RelatedTransactionsModel[], accountNumber?: string) {
  const relatedLineItems = getRelatedLineItems(relatedTransactions, accountNumber);
  const maxAmount = amount + relatedLineItems.reduce((sum, lineItem) => { return sum + (lineItem.balance ?? 0) }, 0);
  return Math.max(maxAmount, 0);
}

export function saveEdits(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(saveEditsInternal());

    // call api to update the transaction data
  };
}
