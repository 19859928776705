import { RemitFileFieldDescription, RemitFileFieldDescriptionsResponse, RemitFilePredefinedFunctionsDescription, RemitFilePredefinedFunctionsDescriptionsResponse } from 'models/RemitPreDefinedListsModel';
import { callHttpApi } from './callHttpApi';

export async function GetRemitFileFieldDescriptions(): Promise<RemitFileFieldDescriptionsResponse> {
  return callHttpApi<RemitFileFieldDescription[]>(
    'get',
    '/api/v1/RemitPreDefinedLists/RemitFileFieldDescriptions'
  );
}

export async function GetRemitFilePredefinedFunctionsDescriptions(): Promise<RemitFilePredefinedFunctionsDescriptionsResponse> {
  return callHttpApi<RemitFilePredefinedFunctionsDescription[]>(
    'get',
    '/api/v1/RemitPreDefinedLists/RemitFilePredefinedFunctionsDescriptions'
  );
}