import React, { useCallback, useEffect, useState } from 'react';
import * as router from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BsXCircle } from 'react-icons/bs';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Operation } from 'rfc6902';
import moment from 'moment';
import { EDS_TextBox, EDS_Button } from '@EH/eh.eds.react';
import { useTypedSelector } from 'app/rootReducer';
import PaymentPlanModel from 'models/PaymentPlan';
import { EnumPlanStatus } from 'models/enums/EnumPaymentPlan';
import {
  mockTermsOptions,
  reset,
  updateRecord,
  initialPlan,
} from 'features/paymentPlan/PaymentPlanReducer';
import CreatePaymentPlan from 'features/paymentPlan/CreatePaymentPlan';
import {
  getWizardSteps,
  usePaymentWizzard,
} from 'features/wizards/PaymentWizard';
import { useGetPaymentPlanById } from 'features/paymentPlan/hooks/useGetPaymentPlanById';
import { ConvertPaymentPlanDates } from 'features/paymentPlan/ConvertPaymentPlanDates';
import { displayAmount, formatDate, getUserName, obsoleteTransactionTypes } from 'utils/Utils';
import { GetPaymentPlanService, PatchPaymentPlanService } from 'services/PaymentPlanService';
import { useGetOrganizations } from 'features/organizations/hooks/useGetOrganizations';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { useGetPatient } from 'features/patients/hooks/useGetPatient';
import { useGetPatientById } from 'features/patients/hooks/useGetPatientById';
import { useGetPaymentPlanPaymentTransactions } from 'features/paymentPlan/hooks/useGetPaymentPlanPaymentTransactions';
import { AlertIds, AlertTypes, setAlert } from 'features/alert/AlertReducer';
import Logo from 'features/Logo';
import Header from 'pages/Search/planSearch/paymentPlanDetailsHeader/PaymentPlanDetailsHeader';
import { updateDataDetailsGrid } from 'pages/Search/SearchResultsTemplate/SearchResultUtils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'assets/styles/components/_paymentPlan.scss';
import { LinkRenderer } from '../../../components/CellRenderers';
import { AccountDetails } from 'features/accountDetails/AccountDetails';
import { getLogoDataForOrganization } from 'features/admin/logoManager/LogoManagerReducer';
import { Source } from 'features/accountDetails/hooks/useAccountDetails';
import { useGetConfiguration } from 'features/paymentPlan/hooks/useGetConfiguration';
import { GetPatientEncountersService } from 'services/patient/PatientService';
import { PatientEncounterModel } from "../../../models/PatientEncounterModel";
import { PlanToPatientLineItemMap } from "features/paymentPlan/maps/PlanToPatientLineItemMap";
import { LineItemSpecific,LineItem } from "../../../models/LineItem";
import { map } from "features/paymentPlan/PlanUtils";
import { v4 as uuid } from 'uuid';
import { usePahAlert } from 'features/paymentPlan/hooks/usePahAlert';
import { useHistory } from '../../../utils/useHistory';

export interface ParamTypes {
  id: string;
}

interface configItem<T> {
  label: string;
  name?: keyof T;
  type: string;
}

const paymentPlanDetailsConfig: configItem<PaymentPlanModel>[] = [
  { label: 'Plan ID', name: 'id', type: 'preview' },
  { label: 'Payment Date', name: 'nextPaymentDate', type: 'day' },
  { label: 'Number of Payments', name: 'originalPaymentsRemaining', type: 'preview' },

  { label: 'Plan Term', type: 'termType' },
  { label: 'Plan Start Date', name: 'startDate', type: 'date' },
  { label: 'Plan Payments Processed', type: 'paymentsProcessed' },

  { label: 'Plan Status', name: 'status', type: 'status' },
  { label: 'Final Payment Date', name: 'finalPaymentDate', type: 'date' },
  { label: 'Plan Payments Failed', type: 'paymentsFailed' },

  { label: 'Next Payment Date', name: 'nextPaymentDate', type: 'date' },
  { label: 'Plan Payments Remaining', name: 'paymentsRemaining', type: 'preview' },

  { label: 'Plan Last Modified on', name: 'updatedDate', type: 'date' },
  { label: 'Payment Amount', name: 'amount', type: 'amount' },
  { label: '', type: 'none' },

  { label: 'Plan Last Modified by', name: 'updatedBy', type: 'updater' },

  { label: '', type: 'none' },
  { label: '', type: 'none' },
  { label: 'A/R Adjustment', type: 'aRAdjustmentCount' },

  { label: '', type: 'none' },
  { label: '', type: 'none' },
  { label: 'Adhoc Payment', type: 'adhocPaymentCount' },
  { label: 'Notifcation Email', name: 'notificationEmail', type: 'email' },
];

export default function (props: {
  paymentPlanId?: string;
  onClose?: () => void;
}) {
  let { id } = router.useParams<{id: string}>();
  const { paymentPlanId, onClose } = props;
  id = paymentPlanId ?? id;

  const dispatch = useDispatch();
  let { paymentPlan, paymentPlanIdx } = useGetPaymentPlanById(id);
  const [notificationEmail, setNotificationEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { getPaymentPlanWithLocalDates } = ConvertPaymentPlanDates();
  const { patient } = useGetPatientById(paymentPlan?.patientId);

  const { getOrganizations } = useGetOrganizations();
  const { useGetDepartmentByPath, getFacilityByDepartment } = useOrganizations();
  const { getErrorMessage } = usePahAlert();
  const department = useGetDepartmentByPath(paymentPlan?.department?.path);
  const facility = getFacilityByDepartment(paymentPlan?.organization?.path);

  const organizationPath = paymentPlan?.organization?.path;
  const { getPlanConfiguration } = useGetConfiguration(organizationPath ?? '');

  const isProcessing = useTypedSelector(s => s.services.calls.GetPaymentPlanConfiguration);
  const { getPatient } = useGetPatient(paymentPlan?.patientId ?? '');
  const { transactions, failed: failedPayments, load: loadPaymenPlanPaymentTransactions } = useGetPaymentPlanPaymentTransactions(paymentPlan?.id);
  const planBackup = useTypedSelector(s => paymentPlan?.id && s.paymentPlanInfo.backups ? s.paymentPlanInfo.backups[paymentPlan?.id] : id && s.paymentPlanInfo.backups ? s.paymentPlanInfo.backups[id] : undefined);

  const displayedPlanInfo = planBackup ? { ...planBackup, status: paymentPlan?.status } : paymentPlan;

  const logoManager = useTypedSelector(s => s.logoManager);
  const isProcessingLogo = useTypedSelector(s => s.services.calls.GetLogoForOrganization?.isProcessing);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();

  const companyLogo = isProcessingLogo ? undefined : logoManager.logos?.find(logo => logo.isSelected);

  const allowPAHSync = useTypedSelector(s => s.paymentPlanInfo?.configurations?.[paymentPlan?.organization?.path ?? '']?.configuration?.allowPAHSync);



  const extractTransactionsCount = (transactions: any) => {
    let uniqueTransactions: any[] = [];
    for (const transaction of transactions) {
      if (!uniqueTransactions.includes(transaction.id) && !obsoleteTransactionTypes.includes(transaction.transactionType)) {
        uniqueTransactions.push(transaction.id)
      }
    }
    return uniqueTransactions.length;
  }

  useEffect(() => {
    if (organizationPath) {
      getPlanConfiguration();
    }
  }, [organizationPath]);

  useEffect(() => {
    !patient && getPatient();
  }, [paymentPlan?.patientId]);

  useEffect(() => {
    loadPaymenPlanPaymentTransactions();
  }, [paymentPlan?.id]);

  useEffect(() => {
    getOrganizations();
    loadPlan();
    paymentPlan?.notificationEmail &&
      setNotificationEmail(paymentPlan?.notificationEmail);

    paymentPlan?.organization?.path && dispatch(getLogoDataForOrganization(paymentPlan?.organization?.path));
  }, [dispatch]);

  useEffect(() => {
    updateDataDetailsGrid(gridApi, transactions);
  }, [transactions]);
  
  useEffect(() => {
    if (allowPAHSync !== undefined && paymentPlan?.id && facility && !paymentPlanId) {
      if(allowPAHSync) {
        getEncounters();
      } 
    }
  }, [paymentPlan?.id, allowPAHSync, facility]);


  useEffect(() => {
    if (planBackup) {
      dispatch(updateRecord({ id: planBackup.id, paymentPlan: planBackup }));
    }
  }, [])

  const renderers: {
    [k: string]: (item: configItem<PaymentPlanModel>, paymentPlan?: PaymentPlanModel) => any;
  } = {
    none: () => {
      return '-';
    },
    preview: (item: configItem<PaymentPlanModel>, paymentPlan?: PaymentPlanModel) => {
      if (paymentPlan && item.name) {
        return paymentPlan[item.name];
      }
    },
    termType: (item: configItem<PaymentPlanModel>, paymentPlan?: PaymentPlanModel) => {
      if (paymentPlan) {
        return mockTermsOptions.find(option => option.value === paymentPlan.termType)?.optionName;
      }
    },
    paymentMonth: (item: configItem<PaymentPlanModel>, paymentPlan?: PaymentPlanModel) => {
      if (paymentPlan) {
        return moment(paymentPlan?.startDate).format('MMMM');
      }
    },
    status: (item: configItem<PaymentPlanModel>, paymentPlan?: PaymentPlanModel) => {
      if (paymentPlan) {
        let statusColor = '';
        switch (paymentPlan?.status) {
          case EnumPlanStatus.Active:
            statusColor = 'active';
            break;
          case EnumPlanStatus.Paused:
            statusColor = 'paused';
            break;
          case EnumPlanStatus.Stopped:
            statusColor = 'stopped';
            break;
          case EnumPlanStatus.Cancelled:
            statusColor = 'cancelled';
            break;
        }
        return (
          <span className={`status-color ${statusColor}`}>
            {paymentPlan?.status}
          </span>
        );
      }
    },
    date: (item: configItem<PaymentPlanModel>, paymentPlan?: PaymentPlanModel) => {
      if (paymentPlan && item.name && paymentPlan[item.name]) {
        return formatDate(moment.utc(paymentPlan[item.name]?.toString()));
      }
    },
    day: (item: configItem<PaymentPlanModel>, paymentPlan?: PaymentPlanModel) => {
      if (paymentPlan && item.name) {
        return moment(paymentPlan[item.name]?.toString()).format('Do');
      }
    },
    amount: (item: configItem<PaymentPlanModel>, paymentPlan?: PaymentPlanModel) => {
      if (paymentPlan && item.name) {
        const amount = paymentPlan.paymentAmount ?? 0;
        
        return displayAmount(amount);
      }
    },
    balance: (item: configItem<PaymentPlanModel>) => {
      if (paymentPlan && item.name) {
        return displayAmount(paymentPlan[item.name]);
      }
    },
    email: (item: configItem<PaymentPlanModel>, paymentPlan?: PaymentPlanModel) => {
      if (paymentPlan && item.name) {
        return (
          <span className="plan-item-email">
            <EDS_TextBox
              name={'notificationEmail'}
              placeHolder={'Notification Email'}
              value={notificationEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNotificationEmail(e.target.value);
              }}
            />
          </span>
        );
      }
    },
    paymentsProcessed: () => {
      return extractTransactionsCount(transactions);
    },
    paymentsFailed: () => {
      return failedPayments.length;
    },
    updater: () => {
      return getUserName(displayedPlanInfo?.updatedBy, true);
    },
    aRAdjustmentCount: ( ) => {
      return transactions?.filter((transaction: any) => transaction.transactionType === 'SystemUpdaterService').length;
    },
    adhocPaymentCount: () => {
      return transactions?.filter((transaction: any, index: number) => index === transactions.findIndex((t: any) => t.gatewayReferenceNumber === transaction.gatewayReferenceNumber && transaction.transactionType === 'Payment')).length;
    }
  };

  const planDetailsAlert = { id: AlertIds.PlanDetailsAlert, type: AlertTypes.Error, message: 'Payment not created!' };

  const getEncounters = useCallback(async () => {
    setIsLoading(true);
    let encountersResult: PatientEncounterModel[] = [];
    
    let pahQuery = new URLSearchParams();
    pahQuery.set('FirstName', patient?.firstName ?? '');
    pahQuery.set('LastName', patient?.lastName ?? '');
    pahQuery.set('Mrn', patient?.mrn ?? '');

    const response =  await GetPatientEncountersService({clientId: facility?.clientId ?? '' }, pahQuery);
    if (response.result && !response.err) {
      encountersResult = response.result.map(encounter => ({ ...encounter, pahAccount: true }));
      let mappedLineItems: LineItem[] = encountersResult?.filter(e => e.accountNo)
        .map(l => { return map<PatientEncounterModel, LineItemSpecific>(l, PlanToPatientLineItemMap) });
      let lineItemsMap: { [key: string]: LineItem } = {};
      let updatedLineItems =  paymentPlan?.lineItems ? [...paymentPlan?.lineItems]: [];

      mappedLineItems?.map((item)=>{
        if(item.id ==='' || item.id === undefined){
          item.id = uuid();
        }
      });

      if (mappedLineItems) {
        mappedLineItems.forEach(lineItem => {
          if (lineItem.accountNumber) {
            lineItemsMap[lineItem.accountNumber] = lineItem;
          }
        });
      }

      updatedLineItems.forEach((planLineItem, index) => {
        if (!planLineItem.accountNumber) return;
        const lineItem = lineItemsMap[planLineItem.accountNumber] ?? updatedLineItems[index];
        updatedLineItems[index] = { ...lineItem };
        delete lineItemsMap[planLineItem.accountNumber];
      });

      let resultPaymentPlan = {
        ...paymentPlan,
        lineItems: updatedLineItems     
      } as PaymentPlanModel;

      resultPaymentPlan = getPaymentPlanWithLocalDates(resultPaymentPlan) as PaymentPlanModel;

      dispatch(updateRecord({
        id: resultPaymentPlan.id,
        paymentPlan: {
          ...resultPaymentPlan,
        }
      }));
    } else if(response.errorResponse.status === 500) {
      getErrorMessage();
    }
    setIsLoading(false);
  }, [dispatch, paymentPlan, patient, facility, setIsLoading]);


  const save = useCallback(async () => {
    if (notificationEmail !== paymentPlan?.notificationEmail) {
      const patch: Operation[] = [{
        "op": "replace",
        "path": "/notificationEmail",
        "value": notificationEmail,
      }];
      const result = paymentPlan?.id &&
        await PatchPaymentPlanService(paymentPlan?.id, patch);

      if (result && result?.err) {
        dispatch(setAlert({ ...planDetailsAlert, message: `Plan not updated. ${result?.errorResponse?.data.validationErrors[0].errorMessage}` }))
      } else if (result && !result?.err && paymentPlan?.id) {
        dispatch(updateRecord({
          id: paymentPlan.id,
          paymentPlan: {
            ...paymentPlan,
            notificationEmail,
          },
        }));
        dispatch(setAlert({
          ...planDetailsAlert, type: AlertTypes.Success, message: 'Plan updated.', dismissable: true
        }))
      }
    }
  }, [dispatch, paymentPlan, notificationEmail]);

  const loadPlan = useCallback(async () => {
    setIsLoading(true);
    const response = await GetPaymentPlanService(id);
    const plan = getPaymentPlanWithLocalDates(response?.result);
    if (plan) {
      const tender = plan.tender ? plan.tender : initialPlan.tender;

      dispatch(
        updateRecord({
          id: plan.id,
          paymentPlan: {
            ...plan,
            tender,
          },
        })
      );
      setNotificationEmail(response?.result?.notificationEmail);
    }
    setIsLoading(false);
  }, [dispatch]);

  const history = useHistory();

  const wizard = usePaymentWizzard();

  useEffect(() => {
    wizard.selectStep(getWizardSteps(!!paymentPlan?.isGl)[0]);
  }, [dispatch]);

  const [isEditing, setIsEditing] = useState(false);

  const onCancel = useCallback(() => {
    setIsEditing(false);
    dispatch(reset({ index: paymentPlanIdx, id }));
  }, [dispatch, setIsEditing, paymentPlanIdx, id]);

  function onGridReady(params: GridReadyEvent) {
    setGridApi(params.api);
    updateDataDetailsGrid(params.api, transactions);
  } // end of onGridReady

  const headerDefinitions = [
    {
      headerName: 'Payment Date',
      field: 'createdDate',
      sortable: true,
      resizable: true,
      cellRenderer: (params: { value: string; data: { id: any } }) => {
        return moment.utc(params.value).format('MM/DD/YYYY');
      },
    },
    {
      headerName: 'Transaction ID',
      field: 'id',
      sortable: true,
      resizable: true,
      cellRenderer: "LinkRenderer",
    },
    {
      headerName: 'Payer Name',
      field: 'payerName',
      sortable: true,
      resizable: true,
      cellRenderer: (params: { data?: { payerFirstName?: string, payerLastName?: string } }) => {
        if (params.data) {
          const { payerFirstName, payerLastName } = params.data;
          const names = [];
          if (payerFirstName) names.push(payerFirstName);
          if (payerLastName) names.push(payerLastName);
          return names.join(' ');
        }
        return '';
      }
    },
    {
      headerName: 'Tender Type',
      field: 'tenderType',
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Transaction Type',
      field: 'transactionType',
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      sortable: true,
      resizable: true,
      cellRenderer: (params: { value: number }) => {
        return displayAmount(params.value);
      },
    },
    {
      headerName: 'Status',
      field: 'statusType',
      sortable: true,
      resizable: true,
    }
  ];

  return paymentPlan ? (
    <div className={'plan-details-wrapper'}>
      <div className={'plan-details'}>
        <Header
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          wizard={wizard}
          isGl={!!paymentPlan?.isGl}
          id={id}
        />
        <hr />
        <div className={'body-wrapper'}>
          <div className={'user-row'}>
            <div className="user-logo">
              <Logo src={companyLogo} />
            </div>
            <div className={'user-details-row'}>
              <LabeledRowItem label="Created By">{getUserName(paymentPlan.createdBy, true)}</LabeledRowItem>
              <LabeledRowItem label="Facility">{facility?.name}</LabeledRowItem>
              <LabeledRowItem label="Department">{department?.name}</LabeledRowItem>
            </div>
          </div>
          <div className={'header-row'}>Accounts in Payment Plan</div>
          <div className={'section ag-theme-alpine'}>
            <AccountDetails
              source={Source.PaymentPlan}
              data={paymentPlan}
            />
          </div>
          <div className={'header-row'}>Payment Plan Terms</div>
          <div className={'section'}>
            <DisplayRow>
              {paymentPlanDetailsConfig.map((item, index) => {
                if (displayedPlanInfo?.termType !== 'Monthly' && item.type === 'day') return <div className='row-item plan-item'></div>
                return (
                  <LabeledRowItem
                    key={item.label + index}
                    label={item.label}
                    modifiers={'plan-item'}
                  >
                    {renderers.hasOwnProperty(item.type)
                      ? renderers[item.type](item, displayedPlanInfo)
                      : ''}
                  </LabeledRowItem>
                );
              })}
              {paymentPlan.isStalled ?  <LabeledRowItem label={`Stalled Plan` } modifiers={'plan-item plan-item--stalled-item'} labelModifiers={'stalled-attribute-label'}
                  > Processing of this plan has been temporarily stalled since no account were found in last A/R file.
                </LabeledRowItem> : null}
            </DisplayRow>
          </div>
          <div className={'header-row'}>Payment Details</div>
          <div className={'section ag-theme-alpine'}>
            <AgGridReact
              columnDefs={headerDefinitions}
              onGridReady={onGridReady}
              pagination={false}
              rowModelType={'infinite'}
              domLayout={'autoHeight'}
              suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
              enableCellTextSelection={true}
              ensureDomOrder={true}
              frameworkComponents={{
                LinkRenderer,
              }}
            />
          </div>
          <div className={'buttons-row'}>
            <EDS_Button
              modifiers={'mr-3 eds-button eds-button.basic'}
              name={'cancel'}
              buttonText={'Cancel'}
              onClick={() => {
                onClose ? onClose() : history.goBack();
              }}
            />
            <EDS_Button
              modifiers={'mr-3 eds-button eds-button.primary'}
              name={'save'}
              buttonText={'Save'}
              onClick={save}
            />
          </div>
        </div>
      </div>
      {isEditing ? (
        <div className="edit-plan-dialog">
          <span className={'close-edit-button'} onClick={onCancel}>
            <BsXCircle />
          </span>
          <CreatePaymentPlan
            wizard={wizard}
            ids={[id]}
            onCancel={onCancel}
            patientId={paymentPlan.patientId}
            facilityInfo={facility}
            showNextPatientModal={false}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  ) : (
    <div> {isProcessing || isLoading ? 'Loading' : 'Could not find plan'}</div>
  );
}

function DisplayRow(props: Readonly<{ children: React.ReactNode }>) {
  const { children } = props;
  return <div className={'row display-row'}>{children}</div>;
}

function LabeledRowItem(props: Readonly<{
  label: string;
  children: React.ReactNode;
  modifiers?: string;
  labelModifiers?: string;
}>) {
  const { label, children, modifiers = '',labelModifiers = '' } = props;
  return (
    <div className={`row-item ${modifiers}`}>
      {label && <>
        <span className={`attribute-label ${labelModifiers}`}>{label}:</span>
        {children}
      </>}
    </div>
  );
}