import { useDispatch } from "react-redux";
import { PaymentPanelApiConfiguration, updateEcnPaymentPanelConfigurationService, updateGlPaymentPanelConfigurationService, updatePatientSearchPanelConfigurationService, updatePaymentPanelConfigurationDraftService, updatePaymentPanelConfigurationService, updatePaymentPlansPaymentPanelConfigurationService } from "../../../../../services/PaymentPanelService";
import { AlertIds, AlertTypes, setAlert } from "../../../../alert/AlertReducer";
import { Dashboards } from "../../ConfigurationSelector";
import { saveConfiguration } from "../../PaymentPanelReducer";
import { useMapPaymentPanelConfiguration } from "../mapPaymentPanelConfiguration";

export function useUpdatePaymentPanelConfiguration(key?: keyof Dashboards, organizationPath?: string) {
  const dispatch = useDispatch();
  async function updatePaymentPanelConfiguration(configuration?: PaymentPanelApiConfiguration, isCounterPart?: boolean) {
    const data = verifyAttributes(configuration);
    if(data) {
      const response = await updatePaymentPanelConfigurationService(configuration);
      saveUpdatedConfiguration(response, isCounterPart);
    }
  }

  async function updatePaymentPanelConfigurationDraft(configuration?: PaymentPanelApiConfiguration, isCounterPart?: boolean) {
    const data = verifyAttributes(configuration);
    if(data) {
      const response = await updatePaymentPanelConfigurationDraftService(configuration);
      saveUpdatedConfiguration(response, isCounterPart);
    }
  }

  async function updatePaymentPlansPaymentPanelConfiguration(configuration?: PaymentPanelApiConfiguration, isCounterPart?: boolean) {
    const data = verifyAttributes(configuration);
    if(data) {
      const response = await updatePaymentPlansPaymentPanelConfigurationService(configuration);
      saveUpdatedConfiguration(response, isCounterPart);
    }
  }

  async function updateGlPaymentPanelConfiguration(configuration?: PaymentPanelApiConfiguration) {
    const data = verifyAttributes(configuration);
    if(data) {
      const response = await updateGlPaymentPanelConfigurationService(configuration);
      saveUpdatedConfiguration(response);
    }
  }

  async function updatePatientSearchPanelConfiguration(configuration?: PaymentPanelApiConfiguration) {
    const data = verifyAttributes(configuration);
    if(data) {
      const response = await updatePatientSearchPanelConfigurationService(configuration);
      saveUpdatedConfiguration(response);
    }
  }

  async function updateEcnPaymentPanelConfiguration(configuration?: PaymentPanelApiConfiguration, isCounterPart?: boolean) {
    const data = verifyAttributes(configuration);
    if(data) {
      const response = await updateEcnPaymentPanelConfigurationService(configuration);
      saveUpdatedConfiguration(response, isCounterPart);
    }
  }

  const { mapPaymentPanelConfiguration } = useMapPaymentPanelConfiguration(organizationPath);

  function saveUpdatedConfiguration(response?: { result?: PaymentPanelApiConfiguration, err?: string, errorResponse?: any }, isCounterPart?: boolean) {
    const configuration = response?.result;
    if (key && configuration) {
      const config = { key, configuration: mapPaymentPanelConfiguration(key, configuration) };
      isCounterPart ? saveConfiguration(config) : dispatch(saveConfiguration(config));
    }

    if (response?.err) {
      const validationErrs = response?.errorResponse?.data?.validationErrors;
      if (validationErrs?.length) {
        validationErrs.forEach((x: any, i: number) => {
          if (x.errorMessage)
            dispatch(setAlert(getErrorAlert(x.errorMessage.replace(/[^a-zA-Z ]/g, ""), i)));
        })
      }
      else {
        dispatch(setAlert(getErrorAlert(response.err)));
      }
    } else {
      dispatch(setAlert(getSuccessAlert("Successfully updated Payment Panel Configuration")));
    }
  }

  function verifyAttributes (configuration?: PaymentPanelApiConfiguration) {
    if (configuration) {
      const fieldSettings = configuration.fieldSettings;

      for(const attr of fieldSettings) {
        if(!attr.saveAs || attr.saveAs.includes('undefined')) {
          dispatch(setAlert(getErrorAlert('Save as should not be empty')));
          return false;
        }
      }
      return true;
    }
  }

  return {
    updatePaymentPanelConfiguration,
    updatePaymentPanelConfigurationDraft,
    updatePaymentPlansPaymentPanelConfiguration,
    updateGlPaymentPanelConfiguration,
    updatePatientSearchPanelConfiguration,
    updateEcnPaymentPanelConfiguration
  };
}

function getErrorAlert(message: string, index?: number) {
  return {
    id: `${AlertIds.PaymentDashboardConfiguration}${index ?? "_" + index}`,
    type: AlertTypes.Error,
    message,
    dismissable: false
  }
}

function getSuccessAlert(message: string) {
  return {
    id: AlertIds.PaymentDashboardConfiguration,
    type: AlertTypes.Success,
    message,
    dismissable: true
  }
}