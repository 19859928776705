import MultiSelect from 'components/select/MultiSelect';
import React, { useEffect } from 'react'
import { EDS_TextBox } from '@EH/eh.eds.react';
import { useDispatch } from 'react-redux';
import { getRemitSchedule } from './RemitScheduleReducer';
import { FullOrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import moment from 'moment-timezone';
import { useTypedSelector } from 'app/rootReducer';

export interface TimeSelected {
  value: string;
  label: string;
}

export default function ScheduleRemit(props: {
  timeSelected: TimeSelected[] | undefined,
  setTimeSelected: (selected: TimeSelected[]) => void,
  organization: FullOrganizationLevelDocument | undefined, 
  historyDepth?: number,
  setHistoryDepth: (value?: number) => void,
}) {
  const dispatch = useDispatch();
  
  const {timeSelected, setTimeSelected, historyDepth, setHistoryDepth, organization} = props
  
  useEffect(() => {
    if(!organization?.path) return
    dispatch(getRemitSchedule(organization.path));
  }, [dispatch, organization?.path]); 

  const remitScheduleConfiguration = useTypedSelector(s => s.remitSchedule.value)    
  
  const time = (prefix: string, n: number = 12) => {
    const options = [];
    for( let i = 1; i <= n; i++ )  {
      const option = {
        value: `${ prefix === "PM" ? 12 + i : i }`,
        label: `${i}${prefix}`
      }
      options.push(option)
    }
    return options
  }  
  const timeOptions = time('AM').concat(time('PM')) 
     
  useEffect(()=>{
    if (!remitScheduleConfiguration?.startHours) return
    let selectedTimeFromConfig: any = []
    remitScheduleConfiguration?.startHours.forEach(el => {
      selectedTimeFromConfig.push(timeOptions.find(o => +o.value === el)) 
    })
    setTimeSelected(selectedTimeFromConfig)
    setHistoryDepth(remitScheduleConfiguration?.historyInDays)
  },[remitScheduleConfiguration])

  return (
    <>
      <div className="column ">
        <div className="control">
          <p style={{ fontWeight: "bold", marginBottom: '7px'}}>
            Time Zone
          </p>
          <p>
          Coordinated Universal Time (UTC+0 hours)
          </p>
        </div>
        <div className="control" style={{ width: '250px'}}>
          <MultiSelect
            label={"Schedule Runs:"}
            name={"time"}
            options={ timeOptions }
            onChange={setTimeSelected}
            values={timeSelected ? timeSelected : [] }
            multiple={true}
          />
        </div>
        <div className="control" style={{ width: '140px'}}>
          <EDS_TextBox
            modifiers="historyDepth"
            name="historyDepth"
            label="History Depth (days)*:"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const value = e.target.value
              if (isNaN(+value)) {
                return
              }
              setHistoryDepth(+value);
            }}
            value={historyDepth}
          ></EDS_TextBox>
        </div>
        { (historyDepth ?? 0) > 30 &&
          <div className="control">
            <span style={{color: 'red'}}>*</span><span>The Remit process will take longer if the history is set to more than 30 days.</span>
          </div>          
        }
      </div>
    </>
  )

}
