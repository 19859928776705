import { useState } from 'react';
import { useTypedSelector } from '../../../app/rootReducer';
import { LineItem } from '../../../models/LineItem';
import { breakDownLineItems } from '../../../services/PaymentPlanService';
import { planToBreakDownLineItem } from '../../paymentPlan/maps/PlanToBreakDownLineItem';
import PaymentPlan from 'models/PaymentPlan';

export function useBreakDownLineItems(props: {
  lineItems: LineItem[];
  organizationPath?: string;
  onError?: () => void;
  onSuccess?: () => void;
  paymentPlan?:PaymentPlan;
}) {
  const { lineItems, organizationPath, onError, onSuccess, paymentPlan } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const configuration = useTypedSelector(s => s.paymentPlanInfo.configurations[organizationPath ?? '']?.configuration)

  async function breakDown(paymentAmount: number) {
    setIsProcessing(true);
    let response = await breakDownLineItems({
      paymentAmount,
      distributionOrder: configuration?.distributionOrder,
      breakdownLineItems: lineItems.filter(l => Number(l.balance || 0) > 0).map(l => planToBreakDownLineItem(l, paymentPlan))
    });
    if (response.result) {
      onSuccess && onSuccess();
      setIsProcessing(false);
      return lineItems.map(l => {
        const updated = response.result?.find(u => u.accountNumber == l.accountNumber);

        return {
          ...l,
          paymentAmount: updated?.amount ?? 0,
          isActive: !!updated?.amount
        };
      })
    } else {
      onError && onError();
    }
    setIsProcessing(false);
  }

  return { isProcessing, breakDown };
}
