import { forwardRef, useRef, useEffect } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Tier as PaymentPlanTier } from 'models/PaymentPlanConfiguration';
import { GenericAttribute } from "../../../../models/metaData/MetaData";
import { useValueRef } from '../../../gridCells/useValueRef';
import TextBox from "../../../componentFactory/wrapperComponents/TextBox";

interface GenericInputCellProps<T> extends ICellRendererParams {
  isEditor: boolean;
  onChange: (
    idx: number,
    field: keyof T,
    value: string,
    rowData: T[]
  ) => {};
  agGridReact: {
    props: {
      rowData: T[];
    };
  };
}

interface TierGridDataInterface extends PaymentPlanTier{
}

interface ValidatedRenderCell extends GenericInputCellProps<TierGridDataInterface> {
  errorMessage?: string,
  clientOrganizationPath: string,
  getAttributes: (p: GenericAttribute<PaymentPlanTier>) => GenericAttribute<PaymentPlanTier>[],
  validate: (attr?: any, value?: any, validOnly?: boolean, currentEntity?: any) => void,
  validateAll: (p?: PaymentPlanTier) => boolean,
  errorClass: (p: string) => string,
  getErrorMessage: (p: string) => string
}

export default forwardRef((params: ValidatedRenderCell, ref) => {
  const { rowIndex, colDef, data, clientOrganizationPath, getAttributes, validate, errorClass, getErrorMessage, validateAll } = params;
  const field = colDef.field as keyof TierGridDataInterface;
  const [value, setValue] = useValueRef(params.value, ref);
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  const rowData = params.agGridReact.props.rowData;
  const attribute = getAttributes(data).find(a => a.name === field);
  const onBlur = () => {
    validate(data, attribute, value, false);
    if (clientOrganizationPath) {
      const updatedData = [...rowData];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [field]: value
      };
    }
  }

  const onChangeCellInput = (inputValue: string) => {
    validateAll(data);
    setValue(inputValue);
  }

  return (<>
    {attribute ?
      <TextBox
        value={value != 0 ? value : null}
        attribute={attribute}
        onChange={onChangeCellInput}
        onBlur={() => onBlur()}
        inputRef={refInput}
        noLabel={true}
        id={`id_${attribute.name}`}
        originateFrom={"GLListManager"}
      /> : null}
    <div className={errorClass(field + data.tId)}>{getErrorMessage(field + data.tId)}</div>
  </>
  );
});