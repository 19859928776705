import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { usePaymentInputs } from 'react-payment-inputs';
import moment from 'moment';
import { useTypedSelector } from 'app/rootReducer';
import Footer from 'features/footer/Footer';
import {
  getDevices,
  initialState,
  PatientMailingInformation,
  setPatientMailingInformation,
} from 'features/paymentInfo/PatientMailingInformationReducer';
import { useAddPaymentOnFile } from 'features/paymentPlan/hooks/useAddPaymentOnFile';
import * as PatientsReducer from 'features/patients/PatientsReducer';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { TenderCart } from 'features/tenderCarts/TenderCart';
import { TenderCardManualCart } from 'features/tenderCarts/TenderCardManualCart';
import { TenderECheckCart } from 'features/tenderCarts/TenderECheckCart';
import { TenderCashCart } from 'features/tenderCarts/TenderCashCart';
import { TenderPaperCheckCart } from 'features/tenderCarts/TenderPaperCheckCart';
import { TenderMoneyOrderCart } from 'features/tenderCarts/TenderMoneyOrderCart';
import { TenderWallet } from 'features/tenderCarts/TenderWallet';
import { PlanOperations, ValidatorType } from 'models/metaData/MetaDataEnums';
import PaymentPlanModel, { TenderInfo } from 'models/PaymentPlan';
import { EnumTransactionTenderType } from 'models/enums/EnumTransactionTenderType';
import { OrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { ReactComponent as Help } from 'assets/svgs/paymentPlanIcons/icon-help-outline.svg';
import { ReactComponent as ImgCardInfoRoutingAccountNumbers } from 'assets/svgs/img-card-info-routing-account-numbers.svg';
import cvcImage from 'assets/img-card-info-cvc.png';
import {
  ApiTenderTypeEnum,
  getTenderTypePaymentPlanOptions,
  getTenderTypePaymentOptions,
  nameof,
  PaymentPermissions,
  checkReceivedHasValues,
  getValueOrDefault
} from 'utils/Utils';
import { getAttribute, getValidator } from 'utils/metadata/MetaDataUtils';
import { convertDateToString } from 'utils/UtilsDateTime';
import { getUserSettings, setUserSettings } from 'pages/Search/searchUtils';
import { useGetPatientById } from '../patients/hooks/useGetPatientById';
import { ECheckModel } from '../paymentInfo/checkScanner/ECheckModel';
import { AlertIds, AlertTypes, removeAlert, setAlert } from '../alert/AlertReducer';
import { updateRecord } from './PaymentPlanReducer';
import BalanceHeader from './infoSections/BalanceHeader';
import AuthorizationConfirmation from './AuthorizationConfirmation';
import useCreateCardToken from './useCreateCardToken';
import useTenderValidator from './validators/useTenderValidator';
import { usePaymentMethodOptions } from './hooks/usePaymentMethodOptions';
import 'bootstrap/scss/bootstrap.scss';
import 'assets/styles/components/_paymentPlan.scss';
import { PaymentTenderSettings, setUsePaymentTenderSettingsState } from 'features/singlePayment/userTenderSettings/userTenderSettingsReducer';
import { useGetPaymentConfiguration } from 'features/singlePayment/hooks/useGetPaymentConfiguration';
import { useGetConfiguration } from 'features/paymentPlan/hooks/useGetConfiguration';
import UserPermissions from 'models/enums/UserPermissions';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import { ReactComponent as ProgressIcon } from 'assets/svgs/progresswheel-icon.svg';
import { PlanFooterChildren } from './buttons/InitialStepButtons';
import { Wizard } from '../wizard/Wizard';
import * as router from 'react-router-dom';
import { PaymentPlanConfiguration } from 'models/PaymentPlanConfiguration';
import { setIsScreenDisabled } from 'features/paymentDashboard/PaymentDashboardReducer';

const PopoverElement = (popover: JSX.Element) => {
  return (
    <OverlayTrigger
      trigger={['hover', 'hover']}
      placement="left"
      overlay={popover}
    >
      <Help />
    </OverlayTrigger>
  )
};

export default (props: {
  isPlan?: boolean;
  isGl?: boolean;
  total?: number;
  amount?: number;
  discount?: number;
  paymentAmount?: number;
  selectedOrganizationPath?: string;
  onCompletePayment?: () => void;
  onBack?: () => void;
  cancel?: () => void;
  paymentPlanId?: string | null;
  patientId?: string;
  organization?: OrganizationLevelDocument;
  setSinglePaymentOnFileTenderType?: (type: {
    type: string | EnumTransactionTenderType | undefined;
  }) => void;
  saveForFutureUseStatus?: boolean;
  setSaveForFutureUseStatus?: (forFutureUseStatus: boolean) => void;
  saveForFutureUse?: { deviceSerialNumber: string };
  setSaveForFutureUse?: (tenderSettings: PaymentTenderSettings) => void;
  wizard: Wizard;
  onCancelAddPlan?: () => void;
  onCreatePlanButtonClick?: () => void;
  onCancel?: () => void;
  isAdhocPayment?: boolean;
}) => {
  const dispatch = useDispatch();
  const {
    isPlan,
    isGl,
    total,
    amount,
    discount,
    paymentAmount,
    selectedOrganizationPath,
    onCompletePayment,
    paymentPlanId,
    patientId,
    organization,
    setSinglePaymentOnFileTenderType,
    saveForFutureUseStatus,
    setSaveForFutureUseStatus,
    saveForFutureUse,
    setSaveForFutureUse,
    wizard,
    onCancelAddPlan,
    onCancel,
    onCreatePlanButtonClick,
    cancel,
    isAdhocPayment=false
  } = props;

  const { patient } = useGetPatientById(patientId);
  let paymentPlan = useTypedSelector(s => s.paymentPlanInfo.records?.find(p => p.id == paymentPlanId));

  const { getFacilityWithFallback } = useOrganizations();
  const planOrganizationPath = checkReceivedHasValues(isPlan, paymentPlan?.organization?.path, organization?.path);
  const facility = getFacilityWithFallback(planOrganizationPath);
  const facilityPath = facility?.path;

  let { search } = router.useLocation()
  let queryParams = new URLSearchParams(search);
  let facilityId = queryParams.get("facility");
  const { getOrganizationById } = useOrganizations();

  const patientPath = facilityId && getOrganizationById(facilityId)?.path;
  const organizationPath = getValueOrDefault(facilityPath, (selectedOrganizationPath as string));
  const paymentConfiguration = useTypedSelector(i => i.paymentDashboard?.configurations[organizationPath]?.configuration);
  const paymentPlanConfiguration = useTypedSelector(s => s.paymentPlanInfo?.configurations[organizationPath]?.configuration);

  const disabledNewPlanCreation = validatePlanCreation(paymentPlanConfiguration, isGl);

  const alert = { id: AlertIds.GlPaymentAlert, type: AlertTypes.Error, message: '' };
  const openErrorMessage = (message: string) => {
    dispatch(setAlert({ ...alert, message }));
  };

  const closeErrorMessage = () => {
    dispatch(removeAlert(alert));
  };

  const processingAddPaymentOnFile = useTypedSelector(s => s.services.calls.addPaymentOnFile?.isProcessing);

  const {
    addPaymentOnFile,
  } = useAddPaymentOnFile(patientId, paymentPlan);

  const planAlert = { id: AlertIds.PlanAlert, type: AlertTypes.Error, message: 'Save to wallet failed.' };

  const { havePermission } = useLoggedUserPermissions();

  const paymentPermissions: PaymentPermissions = {
    cashPermission: havePermission(UserPermissions.PaymentCash, organizationPath),
    moneyOrderPermission: havePermission(UserPermissions.PaymentMoneyOrder, organizationPath),
    paperCheckPermission: havePermission(UserPermissions.PaymentPaperCheck, organizationPath),
    eCheckPermission: havePermission(UserPermissions.PaymenteCheck, organizationPath),
    creditCardManualPermission: havePermission(UserPermissions.PaymetCreditCardMannual, organizationPath),
    creditCardDevicePermission: havePermission(UserPermissions.PaymentCreditCardDevice, organizationPath),
    paymentMethodOnFilePermission: havePermission(UserPermissions.PaymentMethodOnFile, organizationPath)
  }

  const paymentMethods = usePaymentMethodOptions(patientId, paymentPlan);
  const paymentMethodCount = getValueOrDefault(paymentMethods?.length, 0);

  const [shouldShowMethodOnFile, setShouldShowMethodOnFile] = useState(true);

  const tenderTypeOptions = getTenderTypePaymentOptions({
    isGl,
    isAdhocPayment,
    paymentPermissions,
    paymentMethodCount,
    paymentConfiguration,
    shouldShowMethodOnFile
  });

  const paymentPlanOptions = getTenderTypePaymentPlanOptions({
    isGl,
    isAdhocPayment,
    paymentPermissions,
    paymentMethodCount,
    paymentConfiguration,
    shouldShowMethodOnFile,
    isPlan
  });

  const complete = async (plan: PaymentPlanModel) => {
    if (
      plan.tender?.isReusable &&
      plan.tender?.type === ApiTenderTypeEnum.CardDevice
    ) {
      const saved = await savePaymentMethod();
      if (!saved) {
        dispatch(setAlert(planAlert));
        return;
      }
    }

    let updatedPlan = {
      ...plan,
      authorized: true,
      walletId: getValueOrDefault(plan.tender?.method, plan.walletId),
    };

    dispatch(
      updateRecord({
        id: plan.id,
        paymentPlan: updatedPlan,
      })
    );

    if (onCompletePayment) onCompletePayment();
  };

  const savePaymentMethod = async () => {
    if (patientId) {
      const response = await addPaymentOnFile();
      const result = response?.result;
      if (result?.patient) {
        dispatch(
          PatientsReducer.updateRecord({
            id: patientId,
            patient: result.patient,
          })
        );
        return true;
      }
    }
  };

  const onError = (message: string) => {
    dispatch(setAlert({ ...planAlert, message: message || 'Payment method is declined.' }));
  };

  const patientMailingInformation = useTypedSelector(
    i => i.patientMailingInformation?.value
  );

  const getTenderInfo: () => TenderInfo = () => {
    const patientInfo = mapPatientMailingInformationToTenderInfo(patientMailingInformation);
    return {
      ...patientInfo,
      paymentFileTokenId: patientMailingInformation.paymentFileTokenId
    }
  };

  const processingCreateCardToken = useTypedSelector(s => s.services.calls.createCardToken?.isProcessing);
  const devices = useTypedSelector(i => i.patientMailingInformation?.devices);
  const device = devices.filter(d => d.serialNumber === paymentPlan?.tender?.deviceSerialNumber)
  let paymentPlanWithDevice: PaymentPlanModel | undefined = undefined
  if (paymentPlan) {
    paymentPlanWithDevice = {
      ...paymentPlan,
      device: {
        serialNumber: device[0]?.serialNumber,
        name: device[0]?.friendlyName,
        id: device[0]?.id
      },
      tender: {
        ...paymentPlan.tender,
        cardNumber: patientMailingInformation?.cardNumber,
        cardHolder: `${patientMailingInformation?.firstName} ${patientMailingInformation?.lastName}`,
        ...getTenderInfo(),
        isReusable: checkReceivedHasValues(patientMailingInformation?.isReusable, patientMailingInformation?.isReusable, paymentPlan.tender?.isReusable)
      }
    }
  }

  const {
    createCardToken,
  } = useCreateCardToken(paymentPlanWithDevice, complete, onError, undefined, patient, patientId, selectedOrganizationPath, paymentConfiguration?.mailingAddressRequired);

  const isProcessing = getValueOrDefault(processingAddPaymentOnFile, processingCreateCardToken);

  const [openAuthorizationModal, setOpenAuthorizationModal] = useState(false);

  const [paymentCardData, setPaymentCardData] = useState(
    getPaymentCardData(paymentPlan)
  );

  const paymentDashboardInfo = useTypedSelector(s => s.paymentDashboard?.values[getValueOrDefault(selectedOrganizationPath, '')]);

  const paymentTenderSettings = useTypedSelector(i => i.userTenderSetting?.paymentTenderSettings?.value);
  const [tenderType, setTenderType] = useState(
    getValueOrDefault(patientMailingInformation.tenderType, tenderTypeOptions[0].value)
  );

  const { getConfiguration } = useGetPaymentConfiguration(facilityPath);
  const { getPlanConfiguration } = useGetConfiguration(getValueOrDefault(facilityPath, ''));
  
  const totalAmount = isAdhocPayment && paymentPlan?.addhocAmount ? paymentPlan.addhocAmount : paymentAmount;
  const {
    validate,
    validateAll,
    attributes,
    errorClass,
    getErrorMessage,
    formatLabel,
  } = useTenderValidator(getTenderInfo(), patient?.paymentsOnFile, false, selectedOrganizationPath, isPlan, totalAmount as number, isAdhocPayment);

  useEffect(() => {
    getUserSettings('paymentTenderSettings').then(response => {
      dispatch(
        setUsePaymentTenderSettingsState({ ...response })
      );
    });
  }, []);

  useEffect(() => {
    if (!facilityPath) return;
    getConfiguration();
    getPlanConfiguration();

    if (paymentTenderSettings) {
      setSaveForFutureUse && setSaveForFutureUse(paymentTenderSettings)
    }

    dispatch(getDevices(getValueOrDefault(facility?.path, '')));

    if (paymentMethods?.length > 1) {
      if (isPlan) {
        updateTenderProperty('type', ApiTenderTypeEnum.SavedOnFile);
        updateTenderProperty(
          'method',
          checkReceivedHasValues(paymentPlan?.id, paymentPlan?.tender?.method, '')
        );
      } else {
        setState({ ...patientMailingInformation, tenderType: ApiTenderTypeEnum.SavedOnFile, paymentFileTokenId: '' });
        setTenderType(ApiTenderTypeEnum.SavedOnFile);
      }
    } else {
      updateTenderProperty('type', ApiTenderTypeEnum.Undefined);
    }
  }, [facilityPath]);

  useEffect(() => {
    if ((patientMailingInformation.deviceSerialNumber === '' && devices.length > 0) ||
      (paymentTenderSettings.deviceSerialNumber === patientMailingInformation.deviceSerialNumber && devices.length > 0)) {
      setSaveForFutureUseStatus && setSaveForFutureUseStatus(true)
    } else {
      setSaveForFutureUseStatus && setSaveForFutureUseStatus(false)
    }
  }, [devices, paymentTenderSettings.deviceSerialNumber]);

  useEffect(() => {
    if (patientMailingInformation?.deviceSerialNumber && patientMailingInformation?.deviceId && patientMailingInformation?.deviceName && saveForFutureUseStatus) {
      setSaveForFutureUse && setSaveForFutureUse({ deviceSerialNumber: patientMailingInformation?.deviceSerialNumber, deviceId: patientMailingInformation?.deviceId, deviceName: patientMailingInformation?.deviceName })
    }
  }, [saveForFutureUseStatus, patientMailingInformation?.deviceSerialNumber]);

  useEffect(() => {
    if (!paymentPlan?.tender?.deviceSerialNumber && !!devices.length) {
      updateTenderProperty('deviceSerialNumber', devices[0].serialNumber);
    }
  }, [devices, paymentPlan?.tender?.deviceSerialNumber])
  useEffect(() => {
    const tenderTypeOnFile = patient?.paymentsOnFile?.find(
      el => el.tokenId === paymentPlan?.tender?.method
    );
    setPaymentOnFileTenderType(tenderTypeOnFile?.tenderType);
  }, [paymentPlan?.tender?.method])

  useEffect(() => {
    if (patientMailingInformation.tenderType === ApiTenderTypeEnum.SavedOnFile &&
      patientMailingInformation.paymentFileTokenId) {
      updateTenderType(patientMailingInformation.paymentFileTokenId);
    }
  }, [patientMailingInformation.tenderType, patientMailingInformation.paymentFileTokenId])

  useEffect(() => {
    if (patientMailingInformation.tenderType === ApiTenderTypeEnum.CardDevice &&
      !patientMailingInformation.deviceSerialNumber &&
      !!devices.length) {

      const defaultDevice = getDefaultSelectedDevice();
      setState({
        ...patientMailingInformation,
        deviceSerialNumber: defaultDevice.serialNumber,
        deviceId: defaultDevice.id,
        deviceName: defaultDevice.friendlyName
      });
    }
  }, [patientMailingInformation.tenderType, patientMailingInformation.deviceSerialNumber, devices])


  useEffect(() => {
    if (paymentConfiguration) {
      const showMethod = paymentConfiguration?.hasCreditCardAllowed || paymentConfiguration?.hasECheckAllowed;
      setShouldShowMethodOnFile(!!showMethod);
    }
  }, [paymentConfiguration])

  useEffect(() => {
    if(isGl) {
      setTenderType(tenderTypeOptions[0].value);
    }
  }, []);

  useEffect(() => {
    if (isPlan && shouldShowMethodOnFile && patientMailingInformation.tenderType === '') {
      setState({ ...patientMailingInformation, tenderType: ApiTenderTypeEnum.SavedOnFile, paymentFileTokenId: ''});
      setTenderType(ApiTenderTypeEnum.SavedOnFile);
    }
  }, [isPlan]);

  const getDefaultSelectedDevice = () => {
    const loggedUserSavedDevice = devices.find(
      d => d.serialNumber === paymentTenderSettings?.deviceSerialNumber
    );
    return loggedUserSavedDevice ?? devices[0] ?? {
      id: '',
      serialNumber: '',
      friendlyName: ''
    };
  }

  const updateTenderType = (value: string) => {
    const paymentOnFile = patient?.paymentsOnFile?.find(
      p => p.tokenId === value
    );

    setSinglePaymentOnFileTenderType &&
      setSinglePaymentOnFileTenderType({ type: paymentOnFile?.tenderType });

    value && setState({
      ...patientMailingInformation,
      paymentFileTokenId: value,
      originalTenderType: paymentOnFile?.tenderType?.toString(),
      expiration: paymentOnFile?.cardExpirationDate,
      cardNumber: paymentOnFile?.maskedAccount,
      cardHolderName: paymentOnFile?.cardOwnerName,
      cardType: paymentOnFile?.cardType,
      accountNumber: paymentOnFile?.maskedAccount,
      routingNumber: paymentOnFile?.routingNumber,
      financialInstitution: paymentOnFile?.financialInstitution,
    });
  }

  const deviceTypeOptions = devices?.map((option, idx) => ({
    key: idx,
    optionName: option.friendlyName,
    value: option.serialNumber,
    deviceId: option.id
  }));

  const [paymentOnFileTenderType, setPaymentOnFileTenderType] = useState<
    string | number
  >();

  const dropDownChangeHandler = (
    selectElement: HTMLSelectElement,
    name: keyof TenderInfo
  ) => {
    let optionIndex = selectElement.selectedIndex;
    let selectedOption = selectElement.options[optionIndex];
    let value: string | ApiTenderTypeEnum = selectedOption.value;

    if (selectElement.selectedIndex < 1) {
      setState({ ...patientMailingInformation, paymentFileTokenId: '' });
      return;
    }

    validate(getAttribute(attributes, 'paymentFileTokenId'), value);

    let valid = validateAll({ ...paymentPlan?.tender, [name]: value }, true);
    if (valid) {
      closeErrorMessage();
    }

    if (name === 'paymentFileTokenId') {
      return updatePaymentOnFile(value);
    }

    updateTenderOnChange(isPlan, name, value);
    updateDeviceInfo(optionIndex);
  };

  function updateDeviceInfo(deviceIndex : number){
    let selectedDevice = deviceTypeOptions[deviceIndex];
    setState({
      ...patientMailingInformation,
      deviceSerialNumber: selectedDevice.value,
      deviceName: selectedDevice.optionName,
      deviceId: selectedDevice?.deviceId
    });
  };

  function updatePaymentOnFile(value: string) {
    updateTenderType(value.toString());
  };

  function updateTenderOnChange(isPlan: boolean | undefined, name: keyof TenderInfo, value: string) {
    isPlan
      ? updateTenderProperty(name, value)
      : updateTenderType(value.toString());
  };

  const onBlur = (name: keyof TenderInfo) => {
    const validationObject: TenderInfo = {
      ...paymentPlan?.tender,
      ...paymentCardData,
    };
    validate(
      getAttribute(attributes, name),
      getTenderInfo()[name]
    );
  };

  function updateTender(tenderInfo: TenderInfo) {
    if (!paymentPlan || !tenderInfo) return;
    dispatch(
      updateRecord({
        id: paymentPlan.id,
        paymentPlan: {
          ...paymentPlan,
          tender: {
            ...paymentPlan.tender,
            ...tenderInfo,
          },
        },
      })
    );
  }

  function updateTenderProperty(propertyName: keyof TenderInfo, value: any) {
    const lengthValidator = getValidator(
      getAttribute(attributes, propertyName),
      ValidatorType.LengthValidator
    );
    const max = lengthValidator?.value.max;
    if (max && value.length > max) return;
    validateAll({ ...paymentPlan?.tender, [propertyName]: value }, true);
    updateTender({ [propertyName]: value });
  }
  const next = () => {
    if (!paymentPlan) return;

    setOpenAuthorizationModal(false);
    dispatch(setIsScreenDisabled(true));
    tokenizeCard(paymentPlan, createCardToken, complete);
  };

  const openAuthorizationPopup = () => {
    if (!validateAll(getTenderInfo())) {
      openErrorMessage('Please complete the required information to continue.');
      return;
    }
    setOpenAuthorizationModal(true);
  };

  const closeAuthorizationPopup = () => {
    setOpenAuthorizationModal(false);
  };

  const { meta } = usePaymentInputs();

  const onSaveForFutureUse = () => {
    if (saveForFutureUseStatus) {
      setUserSettings('paymentTenderSettings', saveForFutureUse)
    } else return
  }

  const openWarningMessage = (message: string) => {
    dispatch(setAlert({ ...alert, type: AlertTypes.Warning, message, dismissable: true }));
  };

  const onNextPayment = () => {
    if (isGl) {
      if (tenderType === ApiTenderTypeEnum.Cash &&
        patientMailingInformation?.amountTendered &&
        (patientMailingInformation?.amountTendered < paymentDashboardInfo.lineItems.reduce((sum, l) => sum + (Number(l.unitCost) * Number(l.glUnits ?? 1) - Number(getValueOrDefault(l.discount, 0))), 0))) {
        openWarningMessage("Insufficient Funds");
        return;
      }
      if (tenderType === ApiTenderTypeEnum.MoneyOrder &&
        patientMailingInformation?.moneyOrderAmount &&
        (patientMailingInformation?.moneyOrderAmount < paymentDashboardInfo.lineItems.reduce((sum, l) => sum + (Number(l.unitCost) * Number(l.glUnits ?? 1) - Number(getValueOrDefault(l.discount, 0))), 0))) {
        openWarningMessage("Insufficient Funds");
        return;
      }
    }
    else {
      if (tenderType === ApiTenderTypeEnum.Cash &&
        patientMailingInformation?.amountTendered &&
        (patientMailingInformation?.amountTendered < paymentDashboardInfo?.paymentTotal)) {
        openWarningMessage("Insufficient Funds");
        return;
      }
      if (tenderType === ApiTenderTypeEnum.MoneyOrder &&
        patientMailingInformation?.moneyOrderAmount &&
        (patientMailingInformation?.moneyOrderAmount < paymentDashboardInfo?.paymentTotal)) {
        openWarningMessage("Insufficient Funds");
        return;
      }
    }

    if (!validateAll(getTenderInfo())) {
      openErrorMessage('Please complete the required information to continue.');
      return;
    }

    if (tenderType === tenderTypeOptions[0].value) {
      openErrorMessage(tenderTypeOptions[0].optionName);
      return;
    }
    if (tenderType === ApiTenderTypeEnum.CardManual && meta.error) {
      openErrorMessage(meta.error);
      return;
    }

    if (tenderType === ApiTenderTypeEnum.CardDevice && (
      patientMailingInformation.deviceSerialNumber && patientMailingInformation.deviceSerialNumber
      !== paymentTenderSettings.deviceSerialNumber)) {
      onSaveForFutureUse && onSaveForFutureUse()
    }

    if (tenderType === ApiTenderTypeEnum.CardManual) {
      setState({ ...patientMailingInformation, cardHolderName: patientMailingInformation.billingFirstName + ' ' + patientMailingInformation.billingLastName })
    }

    if (onCompletePayment) {
      if (
        tenderType === ApiTenderTypeEnum.CardManual &&
        (patientMailingInformation.expiration === undefined ||
          patientMailingInformation.expiration === '')
      ) {
        changeDateHandler(new Date(), 'expirationDate');
      }
      onCompletePayment();
    }
  };

  const prepend = {
    prependType: 'text',
    prependValue: '$',
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.checked;
    updateTenderMailingAddress(isPlan, value);
  };

  function updateTenderMailingAddress(isPlan: boolean | undefined, value: boolean) {
    isPlan
      ? updateTender({ isReusable: value })
      : setState({ ...patientMailingInformation, isReusable: value });
  };

  const handleDatePickerChange = (date: Date | [Date, Date]) => {
    let dateObj = Array.isArray(date) ? date[0] : date;
    const expirationDate = moment(dateObj)
      .add(2, 'days')
      .utc()
      .endOf('month')
      .toISOString();
    setPaymentCardData({
      ...paymentCardData,
      expirationDate: expirationDate,
    });
  };

  const changeDateHandler = (
    date: Date,
    name: keyof TenderInfo,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (name === 'expirationDate') {
      name = 'expiration';
      date = moment(date)
        .utc()
        .add(2, 'days')
        .endOf('month')
        .toDate();
    }
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    const dateString = convertDateToString(date);
    let valid = validateAll({ ...getTenderInfo(), [name]: dateString }, true);
    if (valid) {
      closeErrorMessage();
    }
    setState({ ...patientMailingInformation, [name]: dateString });
  };

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: keyof TenderInfo
  ) => {
    let propertyName = event.target.name;
    let value = event.target.value;

    validateAll({ ...paymentPlan?.tender, [name]: value }, true);

    if (value && value.length > 25) {
      value = value.slice(0, 25);
      event.target.value = value;
    }

    setPaymentCardData({
      ...paymentCardData,
      [propertyName]: value,
    });
  };

  const handlePaymentCardDataChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: keyof TenderInfo
  ) => {
    let value = event.target.value;

    validateAll({ ...paymentPlan?.tender, [name]: value }, true);

    setPaymentCardData({
      ...paymentCardData,
      [name]: value,
    });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: keyof TenderInfo
  ) => {
    let value = event.target.value;
    if (isGl) {
      if (name === "amountTendered" && (Number(value) >= paymentDashboardInfo.lineItems.reduce((sum, l) => sum + (Number(l.unitCost) * Number(l.glUnits ?? 1)), 0))) {
        closeErrorMessage()
      }

      if (name === "moneyOrderAmount" && (Number(value) >= paymentDashboardInfo.lineItems.reduce((sum, l) => sum + (Number(l.unitCost) * Number(l.glUnits ?? 1)), 0))) {
        closeErrorMessage()
      }
    }

    if (!isGl) {
      if (name === "moneyOrderAmount" && (Number(value) >= paymentDashboardInfo?.paymentTotal)) {
        closeErrorMessage()
      }

      if (name === "amountTendered" && (Number(value) >= paymentDashboardInfo?.paymentTotal)) {
        closeErrorMessage()
      }
    }

    const lengthValidator = getValidator(
      getAttribute(attributes, name),
      ValidatorType.LengthValidator
    );
    const max = lengthValidator?.value.max;

    if (max && value.length > max) return;

    let valid = validateAll({ ...getTenderInfo(), [name]: value }, true);
    if (valid) {
      closeErrorMessage();
    }

    let additionals = {};
    if (name === 'billingFirstName') {
      additionals = { firstName: value };
    }

    if (name === 'billingLastName') {
      additionals = { lastName: value }
    }
    
    setState({ ...patientMailingInformation, [name]: value, ...additionals });
  };

  const setState = useCallback(
    (param: PatientMailingInformation) => {
      dispatch(setPatientMailingInformation(param));
    },
    [dispatch]
  );

  const handleTenderTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let propertyName = event.target.name;
    let value = tenderTypeOptions[event.target.selectedIndex].value;
    setTenderType(value);

    setState({
      ...patientMailingInformation,
      expiration: patientMailingInformation.expiration
        ? patientMailingInformation.expiration
        : initialState.value.expiration,
      [propertyName]: value
    });
  };

  const onScanHandler = (eCheckModel: ECheckModel) => {
    if (!paymentPlan) return;
    const tender = {
      ...paymentPlan.tender,
      [nameof<TenderInfo>('type')]: ApiTenderTypeEnum.ECheck,
      [nameof<TenderInfo>('isPaperCheck')]: true,
      [nameof<TenderInfo>('accountNumber')]: eCheckModel.account,
      [nameof<TenderInfo>('routingNumber')]: eCheckModel.route,
    };

    dispatch(
      updateRecord({
        id: paymentPlan.id,
        paymentPlan: {
          ...paymentPlan,
          tender,
        },
      })
    );
    validateAll(tender, true);
  };

  const getPopoverContent = () => {
    const selectedTender = checkReceivedHasValues(isPlan, paymentPlan?.tender?.type, tenderType);
    if (
      selectedTender === ApiTenderTypeEnum.ECheck ||
      selectedTender === ApiTenderTypeEnum.PaperCheckAsECheck
    ) {
      return <ImgCardInfoRoutingAccountNumbers />;
    }

    if (selectedTender === ApiTenderTypeEnum.CardManual) {
      return <img src={cvcImage} alt="Look for cvc on the back of your card" />;
    }
    return '';
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>{getPopoverContent()}</Popover.Content>
    </Popover>
  );

  const handleDeviceTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    updateDeviceInfo(event.target.selectedIndex);
    let selectedDevice = deviceTypeOptions[event.target.selectedIndex];
    if (selectedDevice.value === paymentTenderSettings.deviceSerialNumber) {
      setSaveForFutureUseStatus && setSaveForFutureUseStatus(true)
    } else {
      setSaveForFutureUseStatus && setSaveForFutureUseStatus(false)
    }
  };

  function showInputError(name: keyof TenderInfo) {
    const message = getErrorMessage(name);
    return message.length ? message : undefined;
  }

  function paymentProcessing() {
    if (isProcessing) {
      return (
        <div className={`processingContainer`}>
          <span
            className={`processing-label paymentProcessing`}
          >
            <ProgressIcon />
            <span className='processingLabel'> Processing Payment... Please Wait.</span>
          </span>
        </div>)
    }
  }

  let tenderDivStyle = 'payment-plan-tender-container';
  if (tenderType === ApiTenderTypeEnum.MoneyOrder) {
    tenderDivStyle = 'payment-plan-tender-container-money-oreder';
  }
  if (tenderType === ApiTenderTypeEnum.CardManual) {
    tenderDivStyle = 'payment-plan-tender-container-card-manual';
  }

  const selectedTenderType = tenderType;
  const showPaymentMethod = shouldShowPaymentMethod(tenderType, paymentMethodCount, shouldShowMethodOnFile);
  const showCardDevice = selectedTenderType === ApiTenderTypeEnum.CardDevice;
  const showCardManual = selectedTenderType === ApiTenderTypeEnum.CardManual;
  const showECheck = selectedTenderType === ApiTenderTypeEnum.ECheck;
  const showCash =  selectedTenderType === ApiTenderTypeEnum.Cash;
  const showPaperCheck =  selectedTenderType === ApiTenderTypeEnum.PaperCheckAsECheck;
  const showMoneyOrder = selectedTenderType === ApiTenderTypeEnum.MoneyOrder;
  const showWallet = getShowWallet(isPlan, isGl, tenderType, shouldShowMethodOnFile);

  const balanceHeaderAmountPlans = checkReceivedHasValues(paymentPlan?.id, paymentPlan?.amount, total);
  const deviceSerialNumberPayments = patientMailingInformation.deviceSerialNumber
    ? patientMailingInformation.deviceSerialNumber
    : getDefaultSelectedDevice().serialNumber;

  const operation = useTypedSelector(state => state.paymentPlanInfo.operation);

  return (
    <>
      {operation !== PlanOperations.NotSet && <AuthorizationConfirmation
        paymentPlan={paymentPlan}
        paymentOnFileTenderType={paymentOnFileTenderType}
        onNext={next}
        isOpen={openAuthorizationModal}
        close={closeAuthorizationPopup}
      />}
      {paymentProcessing()}
      <div className="payment-plan-container height-calc">
        <div className="payment-plan-form-container">
          <div className={tenderDivStyle}>
            <BalanceHeader
              total={checkReceivedHasValues(isPlan, total, getValueOrDefault(paymentAmount, total))}
              amount={checkReceivedHasValues(isPlan, balanceHeaderAmountPlans, amount)}
              discount={discount}
              isGl={isGl}
            />
            <TenderCart
              name='tenderType'
              label='Payment Options:'
              value={tenderType}
              options={checkReceivedHasValues(isPlan, paymentPlanOptions, tenderTypeOptions)}
              onChange={handleTenderTypeChange}
            />
            {showPaymentMethod && paymentPermissions.paymentMethodOnFilePermission && <TenderCart
              name={'paymentFileTokenId'}
              label={formatLabel('method', '*Payment method')}
              value={patientMailingInformation.paymentFileTokenId}
              options={paymentMethods}
              error={showInputError('method')}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => dropDownChangeHandler(e.target, 'paymentFileTokenId')}
              onBlur={() => onBlur('method')}
            />
            }
            {showCardDevice && <TenderCart
              name={isPlan ? 'deviceSerialNumber' : 'deviceType'}
              label={formatLabel('deviceSerialNumber', 'Device')}
              value={checkReceivedHasValues(isPlan, paymentPlan?.tender?.deviceSerialNumber, deviceSerialNumberPayments)}
              options={deviceTypeOptions}
              error={showInputError('deviceSerialNumber')}
              onChange={isPlan
                ? (e: React.ChangeEvent<HTMLSelectElement>) => dropDownChangeHandler(e.target, 'deviceSerialNumber')
                : handleDeviceTypeChange
              }
              onBlur={() => onBlur('deviceSerialNumber')}
              showSaveForFutureUse={true}
              saveForFutureUseStatus={saveForFutureUseStatus}
              setSaveForFutureUseStatus={setSaveForFutureUseStatus}
            />}
            {showCardManual && <TenderCardManualCart
              isPlan={isPlan}
              source={patientMailingInformation}
              popoverElement={PopoverElement(popover)}
              formatLabel={formatLabel}
              errorClass={errorClass}
              onChangeCardData={handlePaymentCardDataChange}
              handleNameChange={handleNameChange}
              handleChange={handleChange}
              handleDatePickerChange={handleDatePickerChange}
              changeDateHandler={changeDateHandler}
              showInputError={showInputError}
              onBlur={onBlur}
            />}

            {showECheck && <TenderECheckCart
              isPlan={isPlan}
              source={patientMailingInformation}
              formatLabel={formatLabel}
              errorClass={errorClass}
              showInputError={showInputError}
              popoverElement={PopoverElement(popover)}
              handleChange={handleChange}
              onBlur={onBlur}
              onScanHandler={onScanHandler}
              organizationPath={facilityPath}
            />}

            {showCash && <TenderCashCart
              source={patientMailingInformation}
              formatLabel={formatLabel}
              errorClass={errorClass}
              showInputError={showInputError}
              onBlur={onBlur}
              handleChange={handleChange}
              prepend={prepend}
              paymentAmount={paymentAmount}
              total={total}
            />}

            {showPaperCheck && <TenderPaperCheckCart
              source={patientMailingInformation}
              handleChange={handleChange}
              errorClass={errorClass}
              showInputError={showInputError}
            />}

            {showMoneyOrder && <TenderMoneyOrderCart
              isGl={isGl}
              source={patientMailingInformation}
              total={total}
              amount={ isAdhocPayment ? paymentAmount : amount}
              discount={discount}
              prepend={prepend}
              formatLabel={formatLabel}
              errorClass={errorClass}
              showInputError={showInputError}
              handleChange={handleChange}
              changeDateHandler={changeDateHandler}
              onBlur={onBlur}
            />}

            {showWallet && <TenderWallet
              isPlan={isPlan}
              paymentMethods={paymentMethods}
              paymentPlan={paymentPlan}
              tenderType={tenderType}
              patient={patient}
              patientMailingInformation={patientMailingInformation}
              handleCheckBoxChange={handleCheckBoxChange}
            />}
            <div className="w-100"></div>
          </div>
        </div>
      </div>
      <Footer >
        <PlanFooterChildren {...{
          wizard,
          openErrorMessage,
          onCreatePlanButtonClick,
          closeErrorMessage,
          onCancelAddPlan,
          paymentPlan,
          disabledNewPlanCreation,
          organization,
          isGl,
          patientId,
          onCancel,
          organizationPath: facility?.path,
          patientPath,
          total,
          selectedOrganizationPath,
          onContinue: onNextPayment,
          onContinuePlan: openAuthorizationPopup,
          tenderType,
          cancelPlanProcess: cancel
        }} />
      </Footer>
    </>
  );
};

function getPaymentCardData(paymentPlan?: PaymentPlanModel) {
  return {
    firstName: paymentPlan?.billingInformation?.firstName,
    lastName: paymentPlan?.billingInformation?.lastName,
    cardNumber: '',
    expirationDate: moment()
      .utc()
      .endOf('month')
      .toISOString(),
    cvc: '',
    zipCode: paymentPlan?.billingInformation?.zipCode,
  } as { [key: string]: string };
}

function getShowWallet(isPlan?: boolean, isGl?: boolean, tenderType?: string, shouldShowMethodOnFile?: boolean) {
  return isPlan
    ? !isGl
    : !isGl && tenderType && [(shouldShowMethodOnFile ? String(ApiTenderTypeEnum.SavedOnFile) : ''), String(ApiTenderTypeEnum.CardDevice), String(ApiTenderTypeEnum.CardManual), String(ApiTenderTypeEnum.ECheck)]
      .includes(tenderType);
}

export function mapPatientMailingInformationToTenderInfo(patientMailingInformation: PatientMailingInformation) {
  return {
    ...patientMailingInformation,
    type: patientMailingInformation.tenderType,
    isPaperCheck: patientMailingInformation.wasECheckScannerApplied,
    expirationDate: patientMailingInformation.expiration,
    firstName: patientMailingInformation.firstName ? patientMailingInformation.firstName : patientMailingInformation.billingFirstName,
    lastName: patientMailingInformation.lastName ? patientMailingInformation.lastName : patientMailingInformation.billingLastName,
    zipCode: patientMailingInformation.billingZipCode,
    method: patientMailingInformation.paymentFileTokenId,
  };
}

export function tokenizeCard(paymentPlan: PaymentPlanModel, createCardToken: () => void, complete: (plan: PaymentPlanModel) => void) {
  const tenderType = getValueOrDefault(paymentPlan?.tenderType, paymentPlan?.tender?.type);
  if ([
    ApiTenderTypeEnum.CardDevice,
    ApiTenderTypeEnum.CardManual,
    ApiTenderTypeEnum.ECheck
  ].includes(tenderType as ApiTenderTypeEnum)) {
    createCardToken();
  } else {
    complete(paymentPlan);
  }
}

export function shouldShowPaymentMethod(tenderType?: string, paymentMethodCount?: number, shouldShowMethodOnFile?: boolean) {
  return tenderType === ApiTenderTypeEnum.SavedOnFile && paymentMethodCount && paymentMethodCount > 1  && shouldShowMethodOnFile;
}

export function validatePlanCreation(paymentPlanConfiguration: PaymentPlanConfiguration, isGl: boolean = false) {
  const disablePlansRecord = isGl ? paymentPlanConfiguration?.disableGLPlansRecord : paymentPlanConfiguration?.disablePlansRecord;
  return disablePlansRecord !== null && disablePlansRecord !== undefined;
}
