import { DataType } from "models/metaData/MetaDataEnums";
import { PatientPanelApiConfiguration } from "services/PatientPanelService";
import { MetaData } from "../../../../models/metaData/MetaData";
import { PaymentPanelApiConfiguration } from "../../../../services/PaymentPanelService";
import { Dashboards } from "../ConfigurationSelector";
import { useMapAttributes } from "./useGetConfiguration";
import { LineItem } from "models/LineItem";

export function useMapPaymentPanelConfiguration( organizationPath?: string) {
  const { mapAttributes } = useMapAttributes(organizationPath);
  return {
    mapPaymentPanelConfiguration: (key: keyof Dashboards, configuration: PaymentPanelApiConfiguration,): MetaData => ({
    ...configuration,
    entity:
    {
      name: key,
      attributes: mapAttributes(configuration.fieldSettings),
      organization: configuration.organization,
      sortByField: configuration.sortByField?.length ? configuration.sortByField : undefined,
      sortDescending: configuration.sortDescending,
      distributionOrder: configuration.distributionOrder,
      colorCode: configuration.colorCode,
      panelTitle: configuration.panelTitle,
      addRowEnabled: configuration.addRowEnabled
    },
    entities: []
  })
}
}

export function mapPatientPanelConfiguration(key: keyof Dashboards, configuration: PatientPanelApiConfiguration): MetaData {
  return {
    ...configuration,
    entity:
      {
        name: key,
        attributes: configuration.fieldSettings ? configuration.fieldSettings?.map(f => ({
          ...f,
          name: f.saveAs as keyof LineItem,
          dataType: DataType.String
        })) : [],
        organization: configuration.organization,
        panelTitle: configuration.panelTitle,
        addRowEnabled: configuration.addRowEnabled,
        colorCode: configuration.colorCode
    },
    entities: []
  }
}