import { useState } from 'react';
import { useHistory } from 'utils/useHistory';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'typeface-roboto';
import '../../assets/styles/components/_clientModalInfo.scss';
import { EDS_Button } from '@EH/eh.eds.react';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { ReactComponent as Close } from 'assets/svgs/icon-popup-close.svg';
import MultiSelect, { MultiSelectOption } from 'components/select/MultiSelect';
import { getOrganizationOption, getOrganizationOptions } from 'pages/Search/simpleSearch/OrganizationUserSearch';
import { OrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { Checkbox } from '@EHDS/core';

export const getLabel = (organization: OrganizationLevelDocument) =>
  `(${organization.tradingPartnerId}) ${organization.name}`;


export default (props: {
  isOpen: boolean;
  close: () => void;
  onNext: () => void;
}) => {
  const { isOpen, close, onNext } = props;
  const openClass = isOpen ? 'eds-modal.open' : '';
  let history = useHistory();
  const [organizationIdSelected, setOrganizationIdSelected] = useState('');
  const { useGetTradingPartners, useGetFacilities } = useOrganizations();
  const [isEnabled, setIsEnabled] = useState(false);

  const [
    selectedTradingPartner,
    setSelectedTradingPartner,
  ] = useState<MultiSelectOption | null>();

  const [
    selectedFacility,
    setSelectedFacility,
  ] = useState<MultiSelectOption | null>();

  let facilities = useGetFacilities(o =>
    o.path.includes(selectedTradingPartner?.value ?? '')
  );

  const tradingPartners = useGetTradingPartners();
  const tradingPartnerOptions = getOrganizationOptions(tradingPartners, t =>
    getOrganizationOption(t, o => getLabel(o))
  );

  const next = () => {
    history.push(`/admin-settings/${organizationIdSelected}/client-configuration`);
    onNext();
    };

  const getFacilities = () => {
    if(!isEnabled){
      return getOrganizationOptions(facilities.filter(facility => facility.isEnabled));
    }else{
      return getOrganizationOptions(facilities);
    }
  }

  return (
    <div>
      <section className={`eds-modal ${openClass}`} id="clientInfoModal">
        <div className="eds-modal_#container selectFacilityContainer">
          <header>            
              <div className="headerFacilitySearchText">Admin | Select Facility</div>
              <div className="text-right headerFacilitySearchCloseButton"  onClick={close}>
                <Close />
              </div>            
          </header>
          <div className="eds-modal_#content">
            <div className="adminSelectFacilityContainer">
              <div className="content-title">Facility Search</div>
              <div className="flexRow">
                <div className="flexRow">
                  <MultiSelect
                    disableCloseOnSelect={false}
                    label="Organization"
                    options={tradingPartnerOptions}
                    onChange={selected => {
                      if(selected?.value != selectedTradingPartner?.value){
                        setOrganizationIdSelected('');
                        setSelectedFacility(null);
                      }
                      setSelectedTradingPartner(selected);
                    }}
                    multiple={false}
                    name="tradingPartner"
                    values={selectedTradingPartner ?? null}
                    placeholder="Select an organization"
                  />
                </div>
                <div className="flexRow">
                  <MultiSelect
                    disableCloseOnSelect={false}
                    label={"Facility ID"}
                    name={"facilities"}
                    options={getFacilities()}
                    onChange={selected => {
                      setOrganizationIdSelected(facilities.find(x => x.path === selected?.value)?.id ?? '');
                      setSelectedFacility(selected);
                    }}
                    values={selectedFacility ?? null}
                    multiple={false}
                    placeholder="Select an existing facility"
                  />
                </div>
                <div className="flexRow">
                  <Checkbox label="Include Disabled Facilities" checked= {isEnabled} onChange={e => {
                    const { checked } = e.target;
                    setIsEnabled(checked);
                  }}/>
                </div>
              </div>

              <div className="flexRow">
                <EDS_Button
                  modifiers="eds-button.primary"
                  buttonText="Continue with this Facility"
                  disabled={organizationIdSelected ? false : true}
                  onClick={next}
                />
              </div>
              <div className="flexRow">
                <EDS_Button
                  modifiers="eds-button.secondary"
                  buttonText="Cancel"
                  onClick={close}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
