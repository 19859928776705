import { useEffect, useState } from 'react';
import { EDS_Button, EDS_Select } from '@EH/eh.eds.react';
import { Popup } from 'components/popup/Popup';
import 'assets/styles/components/_paymentPlanDetails.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '@experian/eds-styles/dist/eds-all.css';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import {
  GridApi,
  GridReadyEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { nameof } from '../../utils/Utils';
import { PatientModel, PaymentOnFile } from '../../models/PatientModel';
import { useDeletePaymentsOnFile } from './hooks/useDeletePaymentsOnFile';
import moment from 'moment';
import { PatientMailingInformation } from '../paymentInfo/PatientMailingInformationReducer';
import { Confirmation } from './popUps/Confirmation';
import { useTypedSelector } from '../../app/rootReducer';
import { EnumTransactionTenderType } from 'models/enums/EnumTransactionTenderType';
import { HeaderButton } from '../../pages/Search/planSearch/paymentPlanDetailsHeader/HeaderButton';
import { ReactComponent as DeleteIcon } from 'assets/svgs/manageWallet/delete.svg'
import { AlertIds, AlertTypes, removeAlert, setAlert } from '../alert/AlertReducer';
import { useDispatch } from 'react-redux';

function CardDateCell(params: {
  value: string;
  data: { tenderType?: EnumTransactionTenderType | string };
}) {
  const startOfMonth = moment()
    .utc()
    .startOf('month');

  let { data, value } = params;

  if (!moment(value ?? '').isValid() || data.tenderType == EnumTransactionTenderType.eCheck) {
    return <span>-</span>;
  }

  const expiredString = moment.utc(value).isBefore(startOfMonth) ? (
    <span className="expired">Expired</span>
  ) : null;

  return (
    <span>
      {moment.utc(value).format('MM/YY')}{' '}
      {expiredString}
    </span>
  );
}

export function PaymentsOnFile(props: {
  onClose: () => void;
  onSubmit?: () => void;
  patient?: PatientModel;
  paymentInfo?: PatientMailingInformation;
}) {
  const { onClose, onSubmit, patient, paymentInfo } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const plans = useTypedSelector(s => s.paymentPlanInfo.records);
  const usedMethods = plans?.filter(p => p.tender?.method).map(p => p.tender?.method) || [];
  if (paymentInfo?.paymentFileTokenId)
    usedMethods.push(paymentInfo?.paymentFileTokenId);

  const headerDefinitions = [
    {
      headerName: 'Tender Type',
      field: nameof<PaymentOnFile>('tenderType'),
      resizable: true,
      minWidth: 180,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      headerName: 'Payer Name',
      field: nameof<PaymentOnFile>('cardOwnerName'),
      sortable: true,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      resizable: true,
    },
    {
      headerName: 'Method Ending In',
      field: nameof<PaymentOnFile>('maskedAccount'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      headerName: 'Expiration Date',
      field: nameof<PaymentOnFile>('cardExpirationDate'),
      sortable: true,
      filter: true,
      resizable: true,
      cellRenderer: 'cardDateCell',
    },
  ];

  const paymentsOnFile = patient?.paymentsOnFile?.filter(method => method.isReusable) ?? [];
  const [selected, setSelected] = useState<PaymentOnFile[]>();

  const [gridApi, setGridApi] = useState < GridApi | null>(null);
  function onGridReady(params: GridReadyEvent) {
    setGridApi(params.api);
    params.api.setRowData(paymentsOnFile);
    params.api.sizeColumnsToFit();
  }

  useEffect(() => {
    gridApi?.setRowData(paymentsOnFile);
  }, [patient]);
  const dispatch = useDispatch();

  const paymentOnFileAlert = { id: AlertIds.PaymentOnFileAlert, type: AlertTypes.Error, message: 'Could not delete some payment methods.' };

  const isProcessing = useTypedSelector(s => s.services.calls.softDeletePaymentOnFile?.isProcessing);
  const { deletePaymentsOnFile } = useDeletePaymentsOnFile(
    patient?.id,
    () => dispatch(setAlert(paymentOnFileAlert)),
    () => dispatch(removeAlert(paymentOnFileAlert))
  );

  const pageSizeOptions = [
    { optionName: '5', value: 5 },
    { optionName: '10', value: 10 },
    { optionName: '15', value: 15 },
    { optionName: '20', value: 20 },
  ];

  const onChangePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = pageSizeOptions[event.target.selectedIndex].value;
    setPageSize(value);
    if (gridApi) {
      gridApi.paginationSetPageSize(value);
    }
  }

  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [currentPage, setCurrentPage] = useState(0);

  const onDelete = isProcessing
    ? undefined
    : () => {
      if (selected?.find(method => (usedMethods ?? []).includes(method.tokenId))) {
        dispatch(setAlert({ ...paymentOnFileAlert, message: 'Payment method currently in use cannot be deleted.' }))
        return;
      }
      setIsModalOpen(true);
    }

  const popupChildren = (
    <div className={'popup-content payment-methods-popup'} id={"test-payment-method"}>
      <div>
        <div className='header-text'>
          <h3>Manage Wallet</h3>
        </div>
        <div className="header-buttons">
          
          <HeaderButton
            onClick={onDelete}
            icon={<DeleteIcon />}
            title={isProcessing ? 'Processing...' : 'Remove Payment Method'}
            disabled={isProcessing}
          />
        </div>
        {isModalOpen ? (
          <Confirmation
            onAgree={() => deletePaymentsOnFile(selected)}
            onClose={() => setIsModalOpen(false)}
            confirmationButtonText="Yes, delete the method"
            cancelButtonText="Do not delete"
            question="Are you sure you want to delete this payment method?"
            explanation="The action cannot be undone."
          />
        ) : null}
          <div className={'section ag-theme-alpine payments-on-file-grid'}>
          <AgGridReact
            frameworkComponents={{
              cardDateCell: CardDateCell,
            }}
            columnDefs={headerDefinitions}
            onGridReady={onGridReady}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            onSelectionChanged={(e: SelectionChangedEvent) => {
              setSelected(
                e.api.getSelectedRows().map((payment: PaymentOnFile) => payment)
              );
            }}
            getRowClass={(params: { data: PaymentOnFile }) => {
              return (usedMethods ?? []).includes(params.data.tokenId)
                ? 'selected-method'
                : '';
            }}
            suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
            pagination={true}
            paginationPageSize={pageSize}
            suppressPaginationPanel={true}
            onPaginationChanged={() => { setCurrentPage(gridApi?.paginationGetCurrentPage() ?? 0) }}
            disableStaticMarkup={true}
          />
          <div className={"pagination-container"}>
            <div className={"pages"}>
              {currentPage ? <span onClick={() => gridApi?.paginationGoToPreviousPage()}>{"|<"}</span> : null}
              <span className={"page-number"}>{currentPage + 1}</span>
              {gridApi && gridApi.paginationGetTotalPages() !== (currentPage + 1) ? <span onClick={() => gridApi.paginationGoToNextPage()}>{">|"}</span> : null}
            </div>
            <div className={"pages-per-row"}>
            <EDS_Select
              name="paymentFileTokenId"
              label="Rows per page:"
              modifiers={'row-item row-item-size-double hidden'}
              options={pageSizeOptions}
              onChange={onChangePageSize}
              value={pageSize}
            />
            {currentPage + 1} of {gridApi && gridApi.paginationGetTotalPages()}
            </div>
          </div>
              
            <div className="footer-button">
            <EDS_Button
                modifiers={'manage-wallet-button eds-button eds-button.primary'}
                name={'send'}
                buttonText={'Done'}
                onClick={onClose}
              />
              <EDS_Button
                modifiers={'manage-wallet-button eds-button eds-button eds-button.basic cancel-button'}
                name={'cancel'}
                buttonText={'Cancel'}
                onClick={onClose}
              />
            </div>
        </div>
      </div>
    </div>
  );

  return (
    <Popup
      header={
        <span className='payments-on-file-header'>
          PaymentSafe<sup>&reg;</sup>&nbsp;&nbsp;&nbsp;
        </span>
      }
      children={popupChildren}
      onClose={onClose}
      customStyle={'payments-on-file-pop-up'}
      onSubmit={() => onSubmit && onSubmit()}
    />
  );
}
