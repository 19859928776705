import '../../assets/styles/components/_dashBoards.scss';
import Bills from '../paymentPlan/Bills';
import { useTypedSelector } from '../../app/rootReducer';
import { Wizard } from '../wizard/Wizard';
import { addPaymentDashboardState, setIsStay, setPanelConfiguration, setPaymentDashboardInitialState } from '../paymentDashboard/PaymentDashboardReducer';
import { FullOrganizationLevelDocument } from '../../models/OrganizationLevelDocument';
import { getWizardSteps } from '../wizards/PaymentWizard';
import useGetConfiguration from "../admin/paymentPanel/serviceHandlers/useGetConfiguration";
import { useOrganizations } from '../organizations/hooks/useOrganizations';
import { OrganizationReference } from '../../models/PaymentPlan';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { PatientEncounterModel } from 'models/PatientEncounterModel';
import { getPaymentPlanConfigurationService } from 'services/PaymentPlanService';
import { useGetPlansByPatientId } from 'features/paymentPlan/hooks/useGetPlansByPatientId';
import { replaceRecords as replacePlans } from 'features/paymentPlan/PaymentPlanReducer';
import { Dashboards } from 'features/admin/paymentPanel/ConfigurationSelector';
import { EDS_Accordion } from '@EH/eh.eds.react';
import { useUserUtils } from 'utils/useUserUtils';
import { getUserDepartmentOrFacilityesOptions } from 'utils/UtilsOrganizationDropdowns';
import { useGetPatientById } from 'features/patients/hooks/useGetPatientById';
import { GetPatientEncountersService } from 'services/patient/PatientService';
import OrganizationLevelTypes from 'models/enums/OrganizationLevelTypes';
import { PatientModel } from 'models/PatientModel';
import { setOpenPatientInfoModal } from 'features/patients/PatientsReducer';
import { setPatientInfoState } from 'features/patientInfo/PatientInfoReducer';
import { PopoverElement } from 'pages/Admin/OrganizationUserManagement/OrganizationUserManagement';
import { getPatientInformation } from 'utils/Utils';
import { usePahAlert } from 'features/paymentPlan/hooks/usePahAlert';
import UserPermissions from 'models/enums/UserPermissions';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import { Button } from 'reactstrap';
import LoadingProgress from 'components/loading/LoadingProgress';

export default function PaymentDashboard(props: { patientId?: string, isGl?: boolean, wizard: Wizard, selectedFacility: FullOrganizationLevelDocument, paymentType: boolean, isPreview?: boolean, preloadedLineItems?: PatientEncounterModel[], shouldShowPlan?: (data:boolean) => void , planConfiguration?: (data:any) => void }) {
  const { wizard, patientId, selectedFacility, paymentType, isGl, isPreview, shouldShowPlan, planConfiguration } = props;

  const dashboard = isGl ? 'GlPaymentDashboard' : 'PaymentDashboard';
  const selectedDashboard = !isPreview ? dashboard : localStorage.getItem('selectedDashboard') as keyof Dashboards;
  
  const { getLoggedUserOrganizations } = useUserUtils();
  const userOrganizations = getLoggedUserOrganizations();
  const organizations = useTypedSelector(s => s.organizations?.value);
  let userFacilityOptions = getUserDepartmentOrFacilityesOptions(organizations, userOrganizations);
  userFacilityOptions = userFacilityOptions?.filter(o => o.organizationLevelType == OrganizationLevelTypes.Facility);
  const { patient } = useGetPatientById(patientId);
  const organizationPath = selectedFacility.path;
  const loadedPanelConfiguration = useTypedSelector(s => isPreview ? s.paymentPanel.configurations[selectedDashboard] : s.paymentDashboard?.panelConfigurations?.[organizationPath]?.configuration);
  const name: string = isPreview ? loadedPanelConfiguration?.entity?.panelTitle as string : selectedFacility.name;
  const selectedOrganization: OrganizationReference = { name: name, path: selectedFacility.path, id: selectedFacility.id };
  const { getPanelConfiguration } = useGetConfiguration(organizationPath);
  const { useGetOrganizationById } = useOrganizations();
  const { getErrorMessage } = usePahAlert();
  const isStay = useTypedSelector(s => s.paymentDashboard.isStay);
  const planRecords = useTypedSelector(s => s.paymentPlanInfo?.records);
  const { getPlans } = useGetPlansByPatientId(patientId, organizationPath);

  const [paymentsInotherFacilities, setPaymentsInotherFacilities] = useState<FullOrganizationLevelDocument[]>([]);
  const [retrievedPaymentsInotherFacilities, setRetrievedPaymentsInotherFacilities] = useState<PatientEncounterModel[][]>([]);
  const [pahResponse, setPahResponse] = useState(false);
  const [accordionAlreadyAssigned, setAccordionAlreadyAssigned] = useState(false);
  const { havePermission } = useLoggedUserPermissions();
  const paymentDashboardValues = useTypedSelector(s => s.paymentDashboard?.values);

  const GetClientId = (organizationId?: string) => {
    return useGetOrganizationById(organizationId ?? '')?.clientId;
  }

  const panelConfiguration = useTypedSelector(s => (s.paymentPanel.configurations[selectedDashboard]));

  useMemo(async () => {
    if (!isPreview) {
      await getPanelConfiguration(selectedDashboard);
    }
    if (planRecords?.length === 0) {
      let [paymentPlans] = await Promise.all([getPlans()]);
      dispatch(replacePlans(paymentPlans ?? []));
    }
  }, [organizationPath, patientId]);

  const dispatch = useDispatch();

  useMemo(() => {

    if (panelConfiguration && Object.keys(panelConfiguration).length && !loadedPanelConfiguration) {
      dispatch(
        setPanelConfiguration({ organizationPath: organizationPath ?? '', configuration: { ...panelConfiguration } })
      );
    }

  }, [panelConfiguration]);

  const facilities = [{ organization: selectedOrganization }];
  const mappedFacilities = loadedPanelConfiguration?.mappings?.length && loadedPanelConfiguration?.mappings?.length != 0 ?
    facilities.concat(loadedPanelConfiguration?.mappings as ({ organization: OrganizationReference }[]))
    : facilities;  

useEffect(() => {
    mappedFacilities?.forEach(facility => {
        dispatch(addPaymentDashboardState({ value: { paymentTotal: 0, lineItems: [], isGl: Boolean(isGl) }, organizationPath: facility?.organization?.path ?? '' }));
      });
  
    async function fetchPaymentPlanConfig(path: string) {
      const response = await getPaymentPlanConfigurationService(path);
      return response?.result;
    }
  
    const fetchAllPaymentPlanConfigs = async () => {
      const planConfigs = await Promise.all(mappedFacilities.map(facility => fetchPaymentPlanConfig(facility.organization.path)));
      const showPaymentPlan = planConfigs.some((x) => x?.disablePlansRecord == null);
      if(shouldShowPlan && planConfiguration){
        shouldShowPlan(showPaymentPlan);
        planConfiguration(planConfigs);
      }
    };
    if(!isPreview && !accordionAlreadyAssigned){
      fetchAllPaymentPlanConfigs();
    }
  }, [mappedFacilities]);
  
  const retrievePatientData = async (phaQuery: URLSearchParams, clientId: string) => {
    let phaResult: PatientEncounterModel[];

    await GetPatientEncountersService({ clientId}, phaQuery).then(response => {
      if (response.result) {
        phaResult = response.result;
        setRetrievedPaymentsInotherFacilities((previousPaymentsInOtherFacilities) => [...previousPaymentsInOtherFacilities, phaResult]);
      } else if(response.errorResponse?.status === 500) {
        getErrorMessage();
      }
    });
  }
  
  const searchPatient = (stateValue?: PatientModel, clientId?: any) => {
    let phaQuery = new URLSearchParams();
    phaQuery.set('FirstName', stateValue?.firstName ?? '');
    phaQuery.set('LastName', stateValue?.lastName ?? '');
    phaQuery.set('Mrn', stateValue?.mrn ?? '');
    phaQuery.set('DateOfBirth', stateValue?.dateOfBirth ?? '');
    phaQuery.set('GuarantorAccountNo', stateValue?.guarantorAccountNo ?? '');
    phaQuery.set('AccountNo', stateValue?.accountNo ?? '');
    phaQuery.set('ConsolidationId', stateValue?.consolidationId || '');
    phaQuery.set('SecondaryMrn', stateValue?.secondaryMrn ?? '');
    phaQuery.set('secondaryConId', stateValue?.secondaryConId ?? '');
    retrievePatientData(phaQuery, clientId ?? '');
  };

  const openPatientInfo = (idForFacility?: string) => {
    const chosenFacility = paymentsInotherFacilities.find(e => e.id === idForFacility);
    const chosenPatient = getPatientInformation(chosenFacility, patient);
    dispatch(
      setPatientInfoState({
        ...chosenPatient
      })
    );
    dispatch(setOpenPatientInfoModal(true));
  };

  function getFacilityByMappedFacility(mapped: PatientEncounterModel[]) {
    let result;
    if (mapped?.length > 0) {
      result = paymentsInotherFacilities.find((org) => org?.clientId?.toString() === mapped[0]?.clientId?.toString());
    }
    return result;
  }

  const  accountExistsInFacilities = (accountNo?: string) => {
    let result = false;
    mappedFacilities.forEach((currentFacility) => {
      paymentDashboardValues[currentFacility?.organization?.path]?.lineItems?.forEach((lineItem) => {
        if (lineItem?.accountNumber?.toString()?.toUpperCase() === accountNo?.toString()?.toUpperCase()) {
          result = true;
          return result;
        }
      });
    });
    return result;
  }
  
  function onClickAccordion() {
    if (userFacilityOptions?.length > 0 && !accordionAlreadyAssigned) {
      const filteredPaymentsInotherFacilities =  userFacilityOptions.filter((u: any) => mappedFacilities.every(x => x.organization.id !== u.id));
      setPaymentsInotherFacilities(filteredPaymentsInotherFacilities);
      filteredPaymentsInotherFacilities.forEach((value) => {
        searchPatient(patient, value.clientId);
      });
      setAccordionAlreadyAssigned(true);
    }
    setPahResponse(true);
  }

  useEffect(() => {
    if(isStay){
      mappedFacilities.forEach(facility => {
        dispatch(
          setPaymentDashboardInitialState({ value: {paymentTotal: 0, lineItems: [], isGl:Boolean(isGl) }, organizationPath:facility?.organization?.path ?? ''})
        );
        });
      dispatch(setIsStay(false));
      }
    }, [isStay]);

    const getAddRowEnabled = () => {
      if (loadedPanelConfiguration && loadedPanelConfiguration.entity) {
        return loadedPanelConfiguration.entity.addRowEnabled;
      }
  }

  const mappedFacilitiesDashboards = useMemo(() => mappedFacilities?.map((mapped, index) => {
    const key = `payment-bills-${index}`;
    return (loadedPanelConfiguration ?
      <Bills
        key={key}
        isGl={isGl}
        wizard={wizard}
        patientId={patientId}
        clientId={GetClientId(mapped?.organization?.id)}
        organizationPath={mapped?.organization?.path}
        organizationName={mapped?.organization?.name}
        onContinue={() => wizard.selectStep(getWizardSteps(false)[5])}
        isPayment={paymentType}
        addRowEnabled={getAddRowEnabled()}
        isPreview={isPreview}
        selectedOrganization={selectedFacility}
        isStay={isStay}
        /> : <></>
      );
  
    }), [loadedPanelConfiguration, patientId, paymentType, isStay]);

  const  displayLineElement = (mapped: PatientEncounterModel[]) => {
    return <>
      {mapped?.map((valuePatientEncounter) => {
        const isInFacility = accountExistsInFacilities(valuePatientEncounter.accountNo);
        return(isInFacility ? <></> :      
        <div key={`${valuePatientEncounter.serviceeventId}`} className='line-item bg-light'>
          <div className="">
            <div className="line-item-attribute">
              <div className="line-item-attribute-label">Account Number:</div>
              <p>{valuePatientEncounter.accountNo}</p>
            </div>
          </div>
          <div className="">
            <div className="line-item-attribute">
              <div className="line-item-attribute-label">Payment Amount:</div>
              <p>{`$${valuePatientEncounter.patientAmtDue}`}</p>
            </div>
          </div>
        </div>
        )
      })}
    </>
  }

  const paymentsInOtherFacilitiesContent = () => {
    if(!retrievedPaymentsInotherFacilities.length && pahResponse) {
      return <>No accounts found</>
    } else if(retrievedPaymentsInotherFacilities.length) {
      return <>
        {retrievedPaymentsInotherFacilities?.map((mapped, index) => {
          const key = `payments-in-other-facilities-${index}`;
          const currentFacility = getFacilityByMappedFacility(mapped);
          const hasPaymentPermission = havePermission(UserPermissions.MakePayment, currentFacility?.path);
          return (hasPaymentPermission && mapped ?
            <div key={key}>
              <div className='payments-in-other-facilities__header'>
                <div>
                  <span>{currentFacility?.name}</span>
                  <span className="help">
                    <PopoverElement>
                      To make payments to this facility, navigate to Access Records.
                    </PopoverElement>
                  </span>
                </div>
                <button
                  type="button"
                  className="eds-button eds-button.basic mr-2"
                  onClick={() => {
                    openPatientInfo(currentFacility?.id);
                  }}
                >
                  <i className="material-icons eds-button_#icon">search</i>
                  <span className="eds-button_#label">Access Records</span>
                </button>
              </div>
              <div className='payments-in-other-facilities__body'>
                {displayLineElement(mapped)}
              </div>
            </div>
            :
            <></>
          );})
        }
      </>
    }
    return <LoadingProgress />;
  }

  const accordionPaymentsInOtherFacilities = () => {
    return (
    <EDS_Accordion
      accordionItems={[
        {summary: (
            <Button className={'payment-in-other-facilities-button'}
              onClick={() => { setTimeout(() => { onClickAccordion(); }, 3000); }}>
               Outstanding payments in other Facilities
            </Button>
          ),
          isOpen: false,
          content: (paymentsInOtherFacilitiesContent()),
        },
      ]}
      modifiers="payments-in-other-facilities"
    />)
  }
    return (
      <>
        {mappedFacilitiesDashboards}
        {!isGl ? <> {accordionPaymentsInOtherFacilities()} </> : <></>}
      </>);
  }
