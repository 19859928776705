import { useTypedSelector } from "app/rootReducer";
import MultiSelect, { MultiSelectOption } from "components/select/MultiSelect";
import { EDS_Button, EDS_Select } from '@EH/eh.eds.react';
import 'assets/styles/components/_cloneUserPermissions.scss';
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { FullOrganizationLevelDocument, OrganizationLevelDocument } from "models/OrganizationLevelDocument";
import OrganizationUserSearch, { getOrganizationOption, getOrganizationOptions, useGetUsersOptions } from "pages/Search/simpleSearch/OrganizationUserSearch";
import { setSimpleSearchInfoState, setSimpleSearchInfoToCloneState } from "pages/Search/simpleSearch/SimpleSearchReducer";
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AlertIds, AlertTypes, setAlert } from '../../alert/AlertReducer';
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from 'react-redux';
import { UserDetailModel } from "models/UserInfoAndRolesModel";
import { checkReceivedHasValues, nameof } from "utils/Utils";
import { OrganizationLevelSummary } from "models/OrganizationLevelSummary";
import OrganizationLevelTypes from "models/enums/OrganizationLevelTypes";
import { ClonePermissions, ClonePermissionsModel } from 'services/PermissionsService';
import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import CloneUserPermissionsConfirmation, { UserInfo } from "./CloneUserPermissionsConfirmation";
import { getOrganizationNames } from "./OrganizationTab/OrganizationForm";
import { setTradingPartner } from "./UsersAndPermissionsReducer";
import useOrganizationsUsersState from "./hooks/useOrganizationsUsersState";

export const getLabel = (organization: OrganizationLevelDocument) =>
  `(${organization.tradingPartnerId}) ${organization.name}`;

export default function CloneUserPermissions(props : { hasSSPPermission?: boolean, loggedUserOrganizationLevel: OrganizationLevelSummary | null; }){
  const dispatch = useDispatch();
  const { getOrganizationById, getFacilityByDepartment, getTradingPartnerByFacility, getOrganizationWithFallback } = useOrganizations();
  const cloneFromState = useTypedSelector(p => p.simpleSearchInfo?.value);
  const organizationsUsersState = useOrganizationsUsersState();
  const { clearState } = organizationsUsersState;
  const cloneToState = useTypedSelector(p => p.simpleSearchInfo?.searchToClone);
  const [cloneFromGridApi, setCloneFromGridApi] = useState <GridApi | null>(null);
  const [cloneToGridApi, setCloneToGridApi] = useState <GridApi | null>(null);
  const [originUser, setOriginUser] = useState<UserDetailModel>();
  const [destinationUsers, setDestinationUsers] = useState<UserDetailModel[]>([]);
  const [selectedOriginUsers, setSelectedOriginUsers] = useState<UserDetailModel[]>([]);
  const [selectedDestinationUsers, setSelectedDestinationUsers] = useState<UserDetailModel[]>([]);
  const { useGetTradingPartners, useGetFacilities } = useOrganizations();
  const tradingPartners = useGetTradingPartners();
  const [cloneToVisible, setCloneToVisible] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const userOptions = useTypedSelector(s => s.simpleSearchInfo.value.users);
  const cloneToUserOptions = cloneToState.users;
  const { users: allUsers } = useGetUsersOptions();
  const [
    selectedTradingPartner,
    setSelectedTradingPartner,
  ] = useState<MultiSelectOption | null>();
  const [
    selectedTradingPartnerToClone,
    setSelectedTradingPartnerToClone,
  ] = useState<MultiSelectOption | null>();
  const tradingPartnerOptions = getOrganizationOptions(tradingPartners, t =>
    getOrganizationOption(t, o => getLabel(o))
  );
  const tradingPartnerOptionsToClone = getOrganizationOptions(tradingPartners, t =>
    getOrganizationOption(t, o => getLabel(o))
  );
  const facilities = useGetFacilities(o =>
    o.path.includes(selectedTradingPartner?.value ?? '')
  );
  const facilitiesToClone = useGetFacilities(o =>
    o.path.includes(selectedTradingPartnerToClone?.value ?? '')
  );
  const users = allUsers.filter(u =>
    u.organizationLevels?.find(o =>
      facilities.find(f => f.path.includes(o.organizationLevel_Path))
    )
  );
  const usersToClone = allUsers.filter(u =>
    u.organizationLevels?.find(o =>
      facilitiesToClone.find(f => f.path.includes(o.organizationLevel_Path))
    )
  );

  const { hasSSPPermission, loggedUserOrganizationLevel } = props;
  
  useEffect(() => {
    if(hasSSPPermission) {
      setSelectedTradingPartnerToClone({label: loggedUserOrganizationLevel?.organizationLevel_Name ?? '', value: loggedUserOrganizationLevel?.organizationLevel_Path ?? ''});
      setSelectedTradingPartner({label: loggedUserOrganizationLevel?.organizationLevel_Name ?? '', value: loggedUserOrganizationLevel?.organizationLevel_Path ?? ''});
    }
  }, [hasSSPPermission])
  
  useMemo(() => {
    dispatch(
      setSimpleSearchInfoState({
        ...cloneFromState,
        facilities: [],
        departments: [],
        users: [],
      })
    );
    dispatch(
      setSimpleSearchInfoToCloneState({
        ...cloneToState,
        facilities: [],
        departments: [],
        users: [],
      })
    );
  }, []);
  
  useMemo(() => {
    clearState();
  }, []);

  function cloneFromOnGridReady(params: GridReadyEvent) {
    setCloneFromGridApi(params.api);
    params.api.sizeColumnsToFit();
  }

  function cloneToOnGridReady(params: GridReadyEvent) {
    setCloneToGridApi(params.api);
    params.api.sizeColumnsToFit();
  }

  const pageSizeOptions = [
    { optionName: '25', value: 25 },
    { optionName: '50', value: 50 },
    { optionName: '75', value: 75 },
    { optionName: '100', value: 100 },
  ];

  const [cloneFromPageSize, setCloneFromPageSize] = useState(pageSizeOptions[0].value);
  const [cloneFromCurrentPage, setCloneFromCurrentPage] = useState(0);

  const [cloneToPageSize, setCloneToPageSize] = useState(pageSizeOptions[0].value);
  const [cloneToCurrentPage, setCloneToCurrentPage] = useState(0);

  const onChangeCloneFromPageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = pageSizeOptions[event.target.selectedIndex].value;
    setCloneFromPageSize(value);
    if (cloneFromGridApi) {
      cloneFromGridApi.paginationSetPageSize(value);
    }
  }

  const onChangeCloneToPageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = pageSizeOptions[event.target.selectedIndex].value;
    setCloneToPageSize(value);
    if (cloneToGridApi) {
      cloneToGridApi.paginationSetPageSize(value);
    }
  }

  const headerDefinitions = [
    {
      headerName: 'Last Name',
      field: nameof<UserDetailModel>('lastName'),
      sortable: true,
      checkBoxSelection: true,
      resizable: true,
    },
    {
      headerName: 'First Name',
      field: nameof<UserDetailModel>('firstName'),
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Employee ID',
      field: nameof<UserDetailModel>('oneSourceUserId'),
      resizable: true,
      minWidth: 180,
    },
    {
      headerName: 'Organization',
      field: nameof<UserDetailModel>('organizationLevels'),
      sortable: true,
      filter: true,
      resizable: true,
      valueGetter: (params: { data?: UserDetailModel }) => {
        const path  = params.data?.organizationLevels?.find((o: any) => true)?.organizationLevel_Path ?? '';
        return getOrganizationWithFallback(path, OrganizationLevelTypes.TradingPartner)?.name ?? '-';
      },
    },
    {
      headerName: 'Facility',
      field: nameof<UserDetailModel>('organizationLevels'),
      sortable: true,
      filter: true,
      resizable: true,
      cellRenderer: 'facilitiesCell',
      valueGetter: (params: { data?: UserDetailModel }) => {
        const path  = params.data?.organizationLevels?.find(o => true)?.organizationLevel_Path ?? '';
        return getOrganizationWithFallback(path, OrganizationLevelTypes.Facility)?.name ?? '-';
      },
    },
    {
      headerName: 'Department',
      field: nameof<UserDetailModel>('organizationLevels'),
      sortable: true,
      filter: true,
      resizable: true,
      valueGetter: (params: { data?: UserDetailModel }) => {
        const path  = params.data?.organizationLevels?.find(o => true)?.organizationLevel_Path ?? '';
        return getOrganizationWithFallback(path, OrganizationLevelTypes.Department)?.name ?? '-';
      },
    },
  ];

  const cloneToHeaderDefinitions = [
    {
      resizable: true,
      maxWidth: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    ...headerDefinitions
  ];

  function openConfirmationModal() {
    return isConfirmationOpen && originUser ? (
      <CloneUserPermissionsConfirmation
        close={() => {setIsConfirmationOpen(false)}}
        isOpen={true}
        onNext={() => { onClonePermissions() }}
        originUserInfo={buildOriginUserInformation(originUser, getOrganizationById, getTradingPartnerByFacility, getFacilityByDepartment)}
        destinationUsersInfo={buildDestinationUsersInformation()}
      />
    ) : null;
  }

  function buildDestinationUsersInformation(){
    if(destinationUsers){
      const destinationsUsersInfo : UserInfo[] = [] ;
      destinationUsers.forEach(u => destinationsUsersInfo.push(
                            { user: `${u.firstName} ${u.lastName}`,
                              organization: GetOrganizationName({value: u.organizationLevels ?? [], levelType:OrganizationLevelTypes.TradingPartner}),
                              facility: GetOrganizationName({value: u.organizationLevels ?? [], levelType:OrganizationLevelTypes.Facility}),
                              department: GetOrganizationName({value: u.organizationLevels ?? [], levelType:OrganizationLevelTypes.Department})
                            })
                          );
         
      return destinationsUsersInfo ;
    };

    return [{user:'', organization: '' , facility: '', department: ''}];    
  }

  function buildUsersRows(userList: UserDetailModel[]){
    const fullUserRows : UserDetailModel[] = [];
    userList.forEach(r => r.organizationLevels?.forEach(org => { fullUserRows.push({...r,organizationLevels: [{...org}]})}));
    return fullUserRows;
  }

  function buildUserToCloneData(){
    if(originUser && destinationUsers){
      const bulkCloneDestinationUsers = destinationUsers.map(u => { return { organizationPaths: u.organizationLevels?.map(o => { return o.organizationLevel_Path }) ?? [], userId: u.id ?? ''}});
      const data : ClonePermissionsModel = { bulkCloneDestinationUsers : bulkCloneDestinationUsers, originUserId : originUser?.id ?? '', originUserOrganizationPath : originUser?.organizationLevels?.[0].organizationLevel_Path ?? ''};
      return data;
    }      
  }

  const onClonePermissions = async() => {
      const userToCloneData = buildUserToCloneData();
      if(userToCloneData){
        const response = await ClonePermissions(userToCloneData);     
      if(response){       
        dispatch(
          setAlert({
            id: AlertIds.ClonePermissionsAlert,
            type: !response?.err ? AlertTypes.Success : AlertTypes.Error,
            message: !response?.err 
              ? 'Successfully cloned permissions'
              : 'Could not clone permissions',
            dismissable: false,
          })
        );
      }
    }      
    setIsConfirmationOpen(false);
  };
  
  function GetOrganizationName(props: {
    value: OrganizationLevelSummary[];
    levelType: OrganizationLevelTypes;
  }) {
    const { value, levelType } = props;
    const organizations = value?.map(o => getOrganizationById(o.organizationLevel_Id))?.filter(o => o?.organizationLevelType === levelType);
  
    return organizations?.length <= 1 ? organizations?.map(f => f?.name)[0] ?? '': 'Multiple';
  }
  
  const showOrganizationSelect = (component: JSX.Element) => {
    if(!hasSSPPermission) {
      return (
        component
      )
    };
  }

  return (
    <div className="client-configuration">
      <div className="content">
        <div className="clone-permission-title">
            <h2>CLONE FROM</h2>
        </div>
        <div className={'organization-user-search'}>
            {showOrganizationSelect(
              <MultiSelect
              disableCloseOnSelect={false}
              label="Organization:"
              options={tradingPartnerOptions}
              onChange={selected => {
                dispatch(
                  setSimpleSearchInfoState({
                    ...cloneFromState,
                    facilities: [],
                    departments: [],
                    users: [],
                  })
                );
                setSelectedTradingPartner(selected);
                dispatch(setTradingPartner(selected));
              }}
              multiple={false}
              name="tradingPartner"
              values={selectedTradingPartner ?? null}
            />
            )}
            <OrganizationUserSearch
              {...{ facilities, users }}
              state={cloneFromState}
              setState={organizationUserState => {
                dispatch(
                  setSimpleSearchInfoState({ ...cloneFromState, ...organizationUserState })
                );
              }}
              initialize={false}
              showError={false}
            />
            <div className={'row buttons-row'}>
              <div className={'button-group'}>
                <EDS_Button
                  modifiers={'eds-button eds-button.primary'}
                  name={'select'}
                  buttonText={'Select'}
                  onClick={() => setSelectedOriginUsers(userOptions.map(o => o.user))}
                />
              </div>
            </div>
          </div>
          {selectedOriginUsers.length ?
            <> 
            <div className={'section ag-theme-alpine'}>
              <AgGridReact               
                columnDefs={headerDefinitions}
                onGridReady={cloneFromOnGridReady}
                rowData={buildUsersRows(selectedOriginUsers)}
                rowSelection={'single'}
                domLayout={'autoHeight'}
                animateRows={true}
                onSelectionChanged={(e: SelectionChangedEvent) => {
                  setOriginUser(
                    e.api.getSelectedRows().map((user: UserDetailModel ) => user)[0]
                  );
                }}
                suppressRowClickSelection={false}
                suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
                pagination={true}
                paginationPageSize={cloneFromPageSize}
                suppressPaginationPanel={true}
                onPaginationChanged={() => { setCloneFromCurrentPage(cloneFromGridApi?.paginationGetCurrentPage() ?? 0) }}
                suppressDragLeaveHidesColumns={true}
              />
              <div className={"pagination-container"}>
                <div className={"pages"}>
                  {cloneFromCurrentPage ? <span onClick={() => cloneFromGridApi?.paginationGoToPreviousPage()}>{"|<"}</span> : null}
                  <span className={"page-number"}>{cloneFromCurrentPage + 1}</span>
                  {cloneFromGridApi && cloneFromGridApi.paginationGetTotalPages() !== (cloneFromCurrentPage + 1) ? <span onClick={() => cloneFromGridApi.paginationGoToNextPage()}>{">|"}</span> : null}
                </div>
                <EDS_Select
                  name="userId"
                  label="Rows per page:"
                  modifiers={'row-item row-item-size-double'}
                  options={pageSizeOptions}
                  onChange={onChangeCloneFromPageSize}
                  value={cloneFromPageSize}
                />
                <div className="button-section">
                  <EDS_Button
                    modifiers={'eds-button eds-button.primary button-reverse'}
                    buttonText={'Clone to '}
                    disabled={!originUser}
                    onClick={() => setCloneToVisible(true)}
                    iconName={'arrow_forward'}
                  />
                </div>
              </div>          
          </div>
        </> : null}
        {cloneToVisible ? 
          <>
            <div className="clone-permission-title">
              <h2>CLONE TO</h2>
            </div>
            <div className={'organization-user-search'}>
              {showOrganizationSelect(
                <MultiSelect
                  disableCloseOnSelect={false}
                  label="Organization:"
                  options={tradingPartnerOptionsToClone}
                  onChange={selected => {
                    dispatch(
                      setSimpleSearchInfoToCloneState({
                        ...cloneToState,
                        facilities: [],
                        departments: [],
                        users: [],
                      })
                    );
                    setSelectedTradingPartnerToClone(selected);
                  }}
                  multiple={false}
                  name="tradingPartner"
                  values={selectedTradingPartnerToClone ?? null}
                />
              )}
              <OrganizationUserSearch
                {...{ facilities: facilitiesToClone, users: usersToClone }}
                state={cloneToState}
                setState={organizationUserState => {
                  dispatch(             
                    setSimpleSearchInfoToCloneState({...cloneToState, ...organizationUserState})
                  );
                }}
                initialize={false}
                showError={false}
              />
              <div className={'row buttons-row'}>
                <div className={'button-group'}>
                    <EDS_Button
                      modifiers={'eds-button eds-button.primary'}
                      name={'select'}
                      buttonText={'Select'}
                      onClick={() => setSelectedDestinationUsers(cloneToUserOptions.map(o => o.user))}
                    />
                  </div>
              </div>
            </div>
            {selectedDestinationUsers.length ?
            <>
            <div className={'section ag-theme-alpine'}>
              <AgGridReact                
                columnDefs={cloneToHeaderDefinitions}
                onGridReady={cloneToOnGridReady}
                rowData={buildUsersRows(selectedDestinationUsers)}
                domLayout={'autoHeight'}
                rowSelection={'multiple'}
                onSelectionChanged={(e: SelectionChangedEvent) => {
                  setDestinationUsers(
                    e.api.getSelectedRows().map((user: UserDetailModel ) => user)
                  );
                }}
                suppressRowClickSelection={true}
                suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
                pagination={true}
                paginationPageSize={cloneToPageSize}
                suppressPaginationPanel={true}
                onPaginationChanged={() => { setCloneToCurrentPage(cloneToGridApi?.paginationGetCurrentPage() ?? 0) }}
              />
              <div className={"pagination-container"}>
                <div className={"pages"}>
                  {checkReceivedHasValues(cloneToCurrentPage, <span onClick={() => cloneToGridApi?.paginationGoToPreviousPage()}>{"|<"}</span>, null)}
                  <span className={"page-number"}>{cloneToCurrentPage + 1}</span>
                  {checkReceivedHasValues(cloneToGridApi && cloneToGridApi.paginationGetTotalPages() !== (cloneToCurrentPage + 1), <span onClick={() => cloneToGridApi?.paginationGoToNextPage()}>{">|"}</span>, null)}
                </div>
                <EDS_Select
                  name="userId"
                  label="Rows per page:"
                  modifiers={'row-item row-item-size-double'}
                  options={pageSizeOptions}
                  onChange={onChangeCloneToPageSize}
                  value={cloneToPageSize}
                />
                <div className="button-section">
                  <EDS_Button
                    modifiers={'eds-button eds-button.primary button-reverse'}
                    buttonText={'Clone '}
                    disabled={destinationUsers?.length === 0}
                    onClick={() => setIsConfirmationOpen(true)}
                    iconName={'copy_all'}
                  />
                </div>
              </div>
            </div>
          </> : null}
        </> : null}       
      </div>
      <div>{openConfirmationModal()}</div>
    </div>
  );
}

export function buildOriginUserInformation(
  originUser: UserDetailModel,
  getOrganizationById: (id: string) => OrganizationLevelDocument | undefined,
  getTradingPartnerByFacility: (path?: string) => OrganizationLevelDocument | undefined,
  getFacilityByDepartment: (path?: string) => OrganizationLevelDocument | undefined,
) {
  if (originUser && originUser.organizationLevels) {
    //origin user has only one organization level here
    const userOrganization = getOrganizationById(originUser.organizationLevels[0]?.organizationLevel_Id);
    if (userOrganization) {
      const { tradingPartnerNames, facilityNames, departmentNames } = getOrganizationNames([userOrganization?.id], [userOrganization], getTradingPartnerByFacility, getFacilityByDepartment);

      return {
        user: `${originUser.firstName} ${originUser.lastName}`,
        organization: tradingPartnerNames.join(", "),
        facility: facilityNames.join(", "),
        department: departmentNames.join(", ")
      };
    }
  };
  return { user: '', organization: '', facility: '', department: '' };
}

export function buildOrganizationNames(
  params: { data?: UserDetailModel }, 
  getOrganizationData: (facilityPath?: string | undefined) => FullOrganizationLevelDocument | undefined,
  organizationLevelType: OrganizationLevelTypes) {
  if (params.data?.organizationLevels === undefined) {
    return '';
  }
  const organizations = params.data?.organizationLevels
    .map(o => getOrganizationData(o.organizationLevel_Path))
    .filter(
      o =>
        o?.organizationLevelType === organizationLevelType
    );
  return organizations.map(f => f?.name).join(', ');
}
    