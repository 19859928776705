const axios = require('axios').default;

export interface IAppSettings {
  CHIP_URL: string;
  CHIP_CLIENT_ID: string;
  ORGANIZATION_ID: string;
  IDLE_TIME_OUT: number;
  TOKEN_REFRESH_TIMEOUT: number;
  POWER_REPORTING_URL: string;
  GL_PATIENT_ID: string;
}

export function getAppSettings(): Promise<IAppSettings> {
  return axios
    .get('/appSettings.json')
    .then(function(response: any) {
      return response.data as IAppSettings;
    })
    .catch(function(error: any) {
      console.log(error);
    });
}
