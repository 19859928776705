import React from 'react';
import { EDS_TextBox } from '@EH/eh.eds.react';
import styles from '../advanceSearch.module.scss';

export function TextInput(props: {
  label: string;
  name: string;
  placeHolder: string;
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { label, name, placeHolder, value, onChange } = props;

  return (
    <div className="col-md-4">
      <EDS_TextBox
        label={label}
        name={name}
        placeHolder={placeHolder}
        modifiers={`${styles.filterItem}`}
        onChange={onChange}
        value={value || ''}
      />
    </div>
  );
}
