import { LineItem } from 'models/LineItem';
import { useState } from 'react';
import { refundTransactionService } from 'services/TransactionsService';
import { useTypedSelector } from 'app/rootReducer';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import { ProcessTransactionResponse } from 'models/Transaction';
import { getTransactionDetails } from 'features/transactionDetails/TransactionDetailsReducer';
import { useDispatch } from 'react-redux';
import { useCallService } from '../../../services/useCallService';

export function useRefundTransaction(
  transaction?: TransactionDetailsModel, 
  onSuccess?: () => void, 
  onError?: (message?: string) => void
  ) {
  const dispatch = useDispatch()
  const callService = useCallService();
  const [refundId, setRefundId] = useState<string>('');
  const [refundTransactionRes, setRefundTransactionRes] = useState<ProcessTransactionResponse>();

  const accounts = useTypedSelector(s => s.transactionDetails.accounts);
  const lineItems = accounts.map(a => getRefundLineItem(a, a.amount));
  const refundAmount = lineItems.reduce((sum, l) => sum + l.amount, 0)

  const refundTransaction = async () => {
    if (!transaction?.id) {
      onError && onError();
      return;
    }
    callService("refundTransaction", async () => {
      const response = await refundTransactionService(transaction.id, {
        refundAmount,
        lineItems,
        referenceNumber: transaction?.gatewayReferenceNumber ?? '',
      });
      dispatch(getTransactionDetails(transaction.id))
      if (response.err || response.result?.errorCode) {
        const message = response.result?.responseMessage || response.result?.errorMessage;
        onError && onError(message);
      } else {
        onSuccess && onSuccess();
        if (response.result) {
          setRefundId(response.result.id);
          setRefundTransactionRes(response.result);
        }
      }
    });
  }

  return { refundTransaction, refundId, refundTransactionRes }
}

export function getRefundLineItem (data: LineItem, refundAmount: string | number) {
  const amount = Number(refundAmount) || 0;
  return {
    ...data,
    amount,
    balance: amount * -1,
    discount: 0
  };
}