import { useOrganizations } from "../../../organizations/hooks/useOrganizations";
import PermissionsMenu from "../PermissionsMenu";
import useAddPermissions from "./hooks/useAddPermissions";
import useRemovePermissions from "./hooks/useRemovePermissions";
import { UserInfo } from "../ConfirmationPopups/AddOrRemovePermissions";

enum permissionsMenuModes {
  add,
  remove,
  view
};
export default function OrganizationPermissionsMenu(props: {
  organizationPaths?: string[],
  selectedItems: Set<string | undefined>,
  handleChange: (id?: string) => void,
  clearSelection: () => void,
  noUser?: boolean,
  userInfo?: UserInfo
}) {
  const { organizationPaths } = props;
  const { addPermissions } = useAddPermissions();
  const { removePermissions } = useRemovePermissions();
  const { organizations } = useOrganizations()
  
  const onSubmit = async (mode: permissionsMenuModes, permissions: Set<string | undefined>) => {
    let action = removePermissions;
    if (mode === permissionsMenuModes.add) {
      action = addPermissions;
    }   
    
    return await action(organizationPaths?.map(id => organizations.find(o => o.id == id)?.path || '') ?? [], Array.from(permissions).filter(p => !!p) as string[]);
  }

  return <PermissionsMenu
    {...props}
    {...{ onSubmit }}
  />
}