import { CreditCard } from "features/admin/creditCardConfig/CreditCardForm";
import { Config } from 'models/CreditCardConfigModel';
import { ClientFacilityConfiguration } from "models/Client";
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { emptyStringsToNull } from 'utils/Utils';
import {
  SaveCreditCardConfig,
  UpdateCreditCardConfig,
} from 'services/CreditCardService';

export default function useCreditCardConfig(clientOrganizationPath: string, creditCardConfig?: Config, clientConfiguration?: ClientFacilityConfiguration) {
  const { getOrganizationByPath } = useOrganizations();
  const clientIdOfFacility = getOrganizationByPath(clientOrganizationPath)?.clientId  
  const clientId = clientIdOfFacility ? Number(clientIdOfFacility) : creditCardConfig?.clientId ?? 0

  const setFromValues = (
    values: CreditCard,
    config?: Config,
    clientConfig?: ClientFacilityConfiguration
  ) : Config => {
    const organizationValues = getOrganizationByPath(
      config?.organization?.path || clientConfig?.organization?.path
    );

    const formValues: Config = {
      id: values.id,
      clientId: values.clientId,
      providerId: parseInt(values.providerId) || 0,
      configName: values.configName,
      tbApiId: values.tbApiId,
      tbApiToken: values.tbApiToken,
      merchantId: values.merchantId,
      merchantPass: values.merchantPass,
      tbMid: values.tbMid,
      tbTid: values.tbTid,
      tbReceiptId: values.tbReceiptId,
      paymentFormSourceId: values.paymentFormSourceId,
      notes: getInfoItem(values.notes),
      isActive: true,
      isProduction: values.isProduction,
      organization: {
        id: getInfoItem(organizationValues?.id),
        name: getInfoItem(organizationValues?.name),
        path: getInfoItem(organizationValues?.path),
      },
      credentialsVerified : false
    };
    return emptyStringsToNull(formValues) as Config;
  };

  const saveCreditCardConfig = (values: CreditCard) => {
    const data: Config = setFromValues(values, creditCardConfig, clientConfiguration);
    const { id } = values;

    if (typeof id === 'string' && id.length > 0) {
      return UpdateCreditCardConfig(id, data);
    } 
    return SaveCreditCardConfig(data);
  };

  const creditCardFormValues: CreditCard = {
    id: getInfoItem(creditCardConfig?.id),
    clientId: clientId,
    providerId: '' + getInfoItem(creditCardConfig?.providerId),
    configName: getInfoItem(creditCardConfig?.configName),
    tbApiId: getInfoItem(creditCardConfig?.tbApiId),
    tbApiToken: getInfoItem(creditCardConfig?.tbApiToken),
    merchantId: getInfoItem(creditCardConfig?.merchantId),
    merchantPass: getInfoItem(creditCardConfig?.merchantPass),
    notes: getInfoItem(creditCardConfig?.notes),
    tbMid: getInfoItem(creditCardConfig?.tbMid),
    tbTid: getInfoItem(creditCardConfig?.tbTid),
    tbReceiptId: getInfoItem(creditCardConfig?.tbReceiptId),
    paymentFormSourceId: getInfoItem(creditCardConfig?.paymentFormSourceId),
    isProduction: creditCardConfig?.isProduction ?? false
  };

  return {
    creditCardFormValues,
    saveCreditCardConfig,
  }
}

function getInfoItem(infoItem: any, defaultValue = '') {
  return infoItem || defaultValue; 
}