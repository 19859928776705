import { Attribute } from 'models/metaData/MetaData';
import { ValidatorType } from 'models/metaData/MetaDataEnums';
import { useCallback, useEffect, useRef } from 'react';
import { getLabel, getValidator } from 'utils/metadata/MetaDataUtils';
import DatePicker from 'react-datepicker';
import './dateTime.scss';
import { convertDateToLocaleDateString, convertDateToStringWithoutZuluTimeZone, convertStringToDate } from 'utils/UtilsDateTime';
import { LineItemError } from '../../paymentDashboard/PaymentDashboardReducer';
import { ReactComponent as Calendar } from 'assets/svgs/icon-calendar.svg';
import { getError } from 'utils/Utils';

export function Date(props: {
  attribute: Attribute;
  value: any;
  isFormattedDate?: boolean;
  onChange?: (value: any) => void;
  modifiers?: string;
  error?: boolean | string;
  onBlur?: (value: any) => void;
  setError?: (error: LineItemError) => void;
}) {
  const { attribute, value, isFormattedDate = false, onChange, onBlur, modifiers, error } = props;
  const label = getLabel(attribute);
  const name = attribute.name;
  const required = attribute.required;
  const dateValidator = getValidator(attribute, ValidatorType.DateTimeValidator)?.value;
  const maxDate = dateValidator?.max && convertStringToDate(dateValidator.max);
  const minDate = dateValidator?.min && convertStringToDate(dateValidator.min);
  
  useEffect(() => {
      !dateValidator && handleChange(date);
  },[]);
  
  const handleChange = useCallback(
    (dateToConvert: Date) => {
      if (!onChange) {
        return;
      }
      
      const dateString = isFormattedDate ? convertDateToLocaleDateString(dateToConvert, 'fr-CA') : convertDateToStringWithoutZuluTimeZone(dateToConvert);
      onChange(dateString);
    },
    [onChange]
  );

  const handleBlur = () => {
    const dateString = convertDateToStringWithoutZuluTimeZone(date);
    onBlur && onBlur(dateString);
  };

  const datePickerRef = useRef() as any;

  const handleClickDatePickerIcon=()=>{
    datePickerRef.current.setOpen(true);
  }
  
  let date = value && convertStringToDate(value);
  const errorColor = error ? '#e91313' : '';
  const backgroundErrorColor = error ? '#ffd6dd' : '';

  return (
    <div className="required-component">
      <div className={`eds-field ${modifiers}`}>
        <div className="eds-field_#label">{label}</div>
        <div
          className={`eds-field_#control eds-field_.eds-input eds-input`}
          style={{
            borderColor: errorColor,
            backgroundColor: backgroundErrorColor, 
            marginBottom: '5px'          
          }}
        >
          <div className="react-datepicker__date-wrapper">
            <DatePicker
              selected={date}
              onChange={handleChange}
              onBlur={handleBlur}
              dateFormat={"yyyy-MM-dd"}
              name={name}
              maxDate={maxDate}
              minDate={minDate}
              className="eds-input_#input"
              ref={datePickerRef} 
              customInput={<input className={'date-input'} type="date" />}
              // isClearable
            />
          </div>
          <div className="react-datepicker__calendar-icon-wrapper">
            <span className="calendarIcon" onClick ={()=>handleClickDatePickerIcon()}>
              <Calendar />
            </span>
          </div>
        </div>
      </div>
      <div className={`${getError(error, required) ? 'error-message-required' : ''}`}>
        {getError(error, required)}
      </div>
    </div>
  );
}

export default Date;
