import { EDS_TextBox } from '@EH/eh.eds.react';
import { GenericAttribute, GenericValidator } from 'models/metaData/MetaData';
import {
  DataType,
  ValidatorType,
  ValueType
} from 'models/metaData/MetaDataEnums';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import {
  getLabel,
  getValidator,
  getValueType,
} from 'utils/metadata/MetaDataUtils';
import { LineItem } from '../../../models/LineItem';
import { formatMoney, getError, roundDecimalNumber } from '../../../utils/Utils';
import { LineItemError } from '../../paymentDashboard/PaymentDashboardReducer';
import { PaymentPanelFieldType } from 'models/enums/EnumPaymentPanels';
interface Preppend {
  prependType?: string,
  prependValue?: string,
};

function getPreppend<T = LineItem>(dataType: DataType, valueType: ValueType, fieldType?: PaymentPanelFieldType, validator?: GenericValidator<T>) : Preppend | undefined {
  let prepend: Preppend | undefined = undefined;
  if(validator?.name === ValidatorType.FormatValidator){
    if(validator?.value.format === "$0.00" || validator?.value.format === "$.00"){
      prepend = {
        prependType: 'text',
        prependValue: '$',
      };
    }

    if(validator?.value.format === "%"){
      prepend = {
        prependType: 'text',
        prependValue: '%',
      };
    }
  }
  else{
    if (dataType === DataType.Money) {
      prepend = {
        prependType: 'text',
        prependValue: '$',
      };
    }
    else if(dataType === DataType.Decimal && valueType === ValueType.Percent){
      prepend = {
        prependType: 'text',
        prependValue: '%',
      };
    }
  }
  return prepend;
}

export function TextBox<T = LineItem>(props: {
  attribute: GenericAttribute<T>;
  value: any;
  id?: string;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  modifiers?: string;
  error?: boolean | string;
  setError?: (error: LineItemError) => void;
  disabled?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  noLabel?: boolean;
  originateFrom?: string;
}) {
  const { attribute, onChange, onBlur, modifiers, id, error, value, disabled, inputRef, noLabel, originateFrom } = props;

  const [isEditting, setIsEditting] = useState(false);

  const label = noLabel? undefined : getLabel(attribute);
  const name = attribute.name;
  const dataType = attribute.dataType;
  const required = attribute.required;
  const valueType = getValueType(attribute);
  const lengthValidator = getValidator(attribute, ValidatorType.LengthValidator);
  const decimalValidator = getValidator(attribute, ValidatorType.DecimalValidator);
  const rangeValidator = getValidator(attribute, ValidatorType.RangeValidator);
  const formatValidator = getValidator(attribute , ValidatorType.FormatValidator);
  const regexValidator = getValidator(attribute, ValidatorType.RegularExpressionValidator);
  const minValidator = getValidator(attribute, ValidatorType.MinValidator);
  const maxValidator = getValidator(attribute, ValidatorType.MaxValidator);

  useEffect(() => {
    onBlur && onBlur(value);
  }, [formatValidator, regexValidator]);

  if (originateFrom && originateFrom === "GLListManager") {
    setTimeout(() => {
      document.getElementById(id!)?.focus();
    }, 500);
  }

  let prepend: Preppend | undefined = getPreppend(dataType, valueType, attribute.fieldTypeOnAddAccount, formatValidator);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let targetValue: any = e.target.value;
      if (lengthValidator && lengthValidator?.value.max && targetValue && targetValue.length > lengthValidator.value.max || 0) return;
      const { left=12, right=2 } = decimalValidator?.value || {};
      if (decimalValidator && left && right && !new RegExp(`^\\d{0,${left}}(\\.\\d{0,${right}})?$`).test(targetValue)) return;

      onChange && onChange(targetValue);
    },
    [valueType, onChange]
  );

  const handleBlur = () => {
    setIsEditting(false);
    if ([DataType.Money, DataType.Decimal].includes(dataType)) {
      onChange && onChange(isNaN(+value) ? undefined : roundDecimalNumber(+value, 2));
    }
    onBlur && onBlur(value);
  }

  const getDisplayValue = () => {
    if(!value) {
      return value;
    }

    if (dataType === DataType.Money) {
      return formatMoney(value.toString(), decimalValidator?.value.left || 12, decimalValidator?.value.right || 2);
    }
    else if (dataType === DataType.Decimal) {
      return roundDecimalNumber(value, 2);
    }

    return value;
  }
 
  const moneyType = dataType === DataType.Money && 'text' ;
  const decimalType = dataType === DataType.Decimal ? 'number' : 'text';
  const type = moneyType ? moneyType : decimalType;     

  return (
    <>   
      <EDS_TextBox
        id={id}
        inputRef={inputRef}
        label={label}
        name={id ?? name}
        modifiers={`${modifiers} ${getError(error, required) ? 'invalid-field' : ''}`}
        type={attribute.dataType === DataType.Password ? 'password' : type}
        addPrepend={prepend}
        value={isEditting ? value : getDisplayValue()}
        onChange={handleChange}
        onBlur={handleBlur}
        className={'rowItem rowItemSize eds4'}
        min={rangeValidator?.value.min || minValidator?.value.min || null}
        max={rangeValidator?.value.max || maxValidator?.value.max || null}
        onFocus={() => setIsEditting(true)}
        disabled={disabled}
      />
      <div className={`${getError(error, required) ? 'error-message-required' : ''}`}>
        {getError(error, required)}
      </div>
    </>
  );
}

export default TextBox;
