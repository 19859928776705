import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'app/rootReducer';
import PaymentPlan from 'features/paymentPlan/PaymentPlan';
import Tender from 'features/paymentPlan/Tender';
import Authorization from 'features/paymentPlan/Authorization';
import PlanCreated from 'features/paymentPlan/PlanCreated';
import PayerInformation from 'features/paymentInfo/PayerInformation';
import { Wizard } from 'features/wizard/Wizard';
import { getWizardSteps, PaymentSteps } from 'features/wizards/PaymentWizard';
import { useGrossTotal, useTotal } from 'features/paymentPlan/useTotal';
import {
  addBackup,
  reset,
  resetSelected,
  resetOperation,
  updateRecord,
  replaceRecords,
} from 'features/paymentPlan/PaymentPlanReducer';
import { useGetPaymentPlanById } from 'features/paymentPlan/hooks/useGetPaymentPlanById';
import SinglePaymentAuthorization from 'features/singlePayment/SinglePaymentAuthorization';
import SinglePaymentComplete from 'features/singlePayment/SinglePaymentComplete';
import {
  removeLineItem,
  setIsPayingNow,
  setIsPaymentType,
  setIsStay,
  setIsScreenDisabled
} from 'features/paymentDashboard/PaymentDashboardReducer';
import {
  resetPatientMailingInformation,
} from 'features/paymentInfo/PatientMailingInformationReducer';
import { OrganizationLevelDocument } from '../../models/OrganizationLevelDocument';
import { EnumTransactionTenderType } from 'models/enums/EnumTransactionTenderType';
import { PlanOperations } from '../../models/metaData/MetaDataEnums';
import { planToServerLineItem } from './maps/PlanToServerLineItem';
import { PaymentTenderSettings } from 'features/singlePayment/userTenderSettings/userTenderSettingsReducer';
import { checkNumberHasValue, checkReceivedHasValues, checkStringHasValue, roundDecimalNumber } from 'utils/Utils';
import { useGetPatientPaymentsOnFile } from 'features/patients/hooks/useGetPatientPaymentsOnFile';
import ActionConfirmModal from 'features/admin/ActionConfirmModal';
import { useHistory } from 'utils/useHistory';
import { setOpenPatientInfoModal } from 'features/patients/PatientsReducer';
import { mapPatientMailingInformationToPlan } from './useOnCreatePlan';

export default (props: {
  children?: React.ReactNode;
  isGl?: boolean;
  wizard: Wizard;
  ids?: (string | null | undefined)[];
  onCancel?: () => void;
  onCreatePlanButtonClick?: () => void
  patientId?: string;
  organization?: OrganizationLevelDocument;
  setGlPatientId?: (id: { id: string }) => void;
  facilityInfo?: OrganizationLevelDocument;
  showNextPatientModal?: boolean;
}) => {
  const {
    children,
    isGl = false,
    wizard,
    ids,
    onCancel,
    onCreatePlanButtonClick,
    patientId,
    organization,
    setGlPatientId,
    facilityInfo,
    showNextPatientModal
  } = props;

  const id = ids ? ids[0] : '';
  const { paymentPlan, paymentPlanIdx } = useGetPaymentPlanById(id);
  const [singlePaymentOnFileTenderType, setSinglePaymentOnFileTenderType] = useState<{ type: string | EnumTransactionTenderType | undefined }>();

  const forPlan = !!paymentPlan?.lineItems?.length;
  const total = useTotal(paymentPlan, isGl, true, false);
  const amount = useGrossTotal(paymentPlan, isGl, forPlan);

  const dispatch = useDispatch();
  const cancel = useCallback(() => {
    checkOnCancel();
    wizard.selectStep(getWizardSteps(isGl)[0]);
    dispatch(reset({ index: paymentPlanIdx, id }));
    dispatch(resetSelected());
    dispatch(resetOperation());
    dispatch(resetPatientMailingInformation());
    dispatch(setIsPayingNow(false));
    dispatch(setIsScreenDisabled(false));
  }, [wizard, dispatch, onCancel]);

  const { getPatientPaymentsOnFile } = useGetPatientPaymentsOnFile(patientId);
  const paymentPlanOnContinue = () => {
    getPatientPaymentsOnFile();
    wizard.next();
  }

  const patientMailingInformation = useTypedSelector(
    i => i.patientMailingInformation?.value
  );

  const payerInformationOnContinue = (onSuccess?: ()=>void) => {
    getPatientPaymentsOnFile();
    let stepNumber = 5;
    if (!(operation == PlanOperations.NotSet || operation == PlanOperations.AddhockPayment)) {
      stepNumber = 2;
      if (paymentPlan?.id !== undefined) {
        dispatch(updateRecord({
          id: paymentPlan?.id,
          paymentPlan: {
            ...paymentPlan,
            notes: patientMailingInformation.notes,
            ...mapPatientMailingInformationToPlan(patientMailingInformation)
          }
        }));
      }
    }
    if ([PlanOperations.EditPlanDetails, PlanOperations.EditPlanTerms].includes(operation)) {
      cancel();
    } else {
      wizard.selectStep(getWizardSteps(isGl)[stepNumber]);
    }
    onSuccess && onSuccess();
  }

  let history = useHistory();
  const paymentDashboardInfo = useTypedSelector(s => s.paymentDashboard?.values[facilityInfo?.path!]);
  
  const openPatientInfo = () => {
    history.push(
      `/payment/${patientId}?facility=${checkStringHasValue(facilityInfo?.id)}&department=${checkStringHasValue(organization?.id)}`
    );
    dispatch(setIsPayingNow(false));
    dispatch(setIsScreenDisabled(false));
    dispatch(setOpenPatientInfoModal(true));
  };

  const done = () => {
    showNextPatientModal ? setOpenSaveModal(true) : stay();
    paymentPlan?.lineItems?.forEach(l =>  {
      dispatch(removeLineItem({ id: l.id, organizationPath: facilityInfo?.path ?? '' }))
    });

    if(isGl) {
      dispatch(setIsPaymentType(true));
    }
  }


  const checkOnCancel = () => {
    onCancel && onCancel();
  }

  const stay = useCallback(() => {
    checkOnCancel();
    wizard.selectStep(getWizardSteps(isGl)[0]);
    if (isGl) {
      dispatch(reset({ index: paymentPlanIdx, id }));
    }
    dispatch(setIsStay(true));
    dispatch(resetPatientMailingInformation());
    dispatch(resetSelected());
    dispatch(resetOperation());
    dispatch(setIsPayingNow(false));
    dispatch(setIsScreenDisabled(false));
    dispatch(replaceRecords([]));
  }, [wizard, dispatch, isGl]);


  useEffect(() => {
    if (paymentPlan) {
      dispatch(addBackup(paymentPlan));
    }
  }, [dispatch]);

  const [saveForFutureUseStatus, setSaveForFutureUseStatus] = useState<boolean>(false);
  const [saveForFutureUse, setSaveForFutureUse] = useState<PaymentTenderSettings>();

  const paymentAmount = checkReceivedHasValues(paymentPlan?.id,
    roundDecimalNumber(
      paymentPlan?.addhocAmount ? Number(paymentPlan?.addhocAmount) : paymentPlan?.lineItems?.reduce((sum, lineItem) => sum + (checkReceivedHasValues(lineItem?.isActive, checkNumberHasValue(lineItem?.amount), 0)), 0), 2
    ),
    undefined
  );


  const lineItemsDiscount = paymentDashboardInfo?.lineItems?.reduce((sum, lineItem) => sum + (checkReceivedHasValues(lineItem?.isActive, checkNumberHasValue(lineItem?.discount), 0)), 0);

  const operation = useTypedSelector(s => s.paymentPlanInfo.operation);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const isPlan = !useTypedSelector(s => s.paymentDashboard.isPaymentType);

  return (
    <React.Fragment>
      {wizard.state.currentStep?.slug === PaymentSteps.payer && (
        <PayerInformation
          total={checkReceivedHasValues(operation == PlanOperations.AddhockPayment, paymentAmount, total)}
          amount={checkReceivedHasValues(amount > 0 && operation != PlanOperations.AddhockPayment, amount, total)}
          discount={checkReceivedHasValues(operation == PlanOperations.NotSet, lineItemsDiscount, 0)}
          isGl={isGl}
          wizard={wizard}
          onContinue={payerInformationOnContinue}
          patientId={patientId}
          onBack={wizard.prev}
          onCancel={cancel}
          onCancelAddPlan={cancel}
          organization={organization}
          paymentPlan={paymentPlan}
          setGlPatientId={setGlPatientId}
          selectedFacility={{ id: facilityInfo?.id, path: facilityInfo?.path}}
          cancel={cancel}
        />
      )}

      {wizard.state.currentStep?.slug === PaymentSteps.plan && (
        <PaymentPlan
          total={total}
          amount={amount}
          discount={0}
          onContinue={paymentPlanOnContinue}
          onBack={wizard.prev}
          cancel={cancel}
          paymentPlanId={paymentPlan?.id}
          wizard={wizard}
          patientId={patientId}
          selectedOrganizationPath={facilityInfo?.path}
        />
      )}

      {wizard.state.currentStep?.slug === PaymentSteps.authorization && (
        <Authorization
          paymentPlan={paymentPlan}
          total={total}
          amount={amount}
          discount={0}
          onContinue={wizard.next}
          onBack={wizard.prev}
          cancel={cancel}
          patientId={patientId}
          selectedOrganizationPath={facilityInfo?.path}
        />
      )}

      {wizard.state.currentStep?.slug === PaymentSteps.created && (
        <PlanCreated
          done={done}
          paymentPlan={paymentPlan}
          total={total}
          amount={amount}
          discount={0}
          patientId={patientId}
          facilityInfo={facilityInfo}
        />
      )}

      {wizard.state.currentStep?.slug === PaymentSteps.singlePaymentTender && (
        <Tender
          isPlan={isPlan}
          paymentPlanId={paymentPlan?.id}
          isGl={isGl}
          total={total}
          paymentAmount={paymentAmount}
          amount={amount}
          discount={lineItemsDiscount}
          onCompletePayment={wizard.next}
          onCreatePlanButtonClick={onCreatePlanButtonClick}
          onBack={() => wizard.selectStep(getWizardSteps(isGl)[0])}
          cancel={cancel}
          patientId={patientId}
          organization={organization}
          setSinglePaymentOnFileTenderType={setSinglePaymentOnFileTenderType}
          saveForFutureUseStatus={saveForFutureUseStatus}
          setSaveForFutureUseStatus={setSaveForFutureUseStatus}
          saveForFutureUse={saveForFutureUse}
          setSaveForFutureUse={setSaveForFutureUse}
          selectedOrganizationPath={facilityInfo?.path}
          wizard={wizard}
          onCancel={onCancel}
          onCancelAddPlan={cancel}
          isAdhocPayment={!!paymentPlan}
        />
      )}

      {wizard.state.currentStep?.slug ===
        PaymentSteps.singlePaymentAuthorization && (
          <SinglePaymentAuthorization
            isGl={isGl}
            amount={amount}
            total={total}
            paymentAmount={paymentAmount}
            discount={lineItemsDiscount}
            onContinue={wizard.next}
            onBack={() => wizard.selectStep(getWizardSteps(isGl)[1])}
            cancel={cancel}
            isGL={isGl}
            organization={organization}
            selectedOrganizationPath={facilityInfo?.path}
            singlePaymentOnFileTenderType={singlePaymentOnFileTenderType}
            plan={paymentPlan}
          />
        )}

      {wizard.state.currentStep?.slug ===
        PaymentSteps.singlePaymentComplete && (
          <SinglePaymentComplete
            done={done}
            isGL={isGl}
            amount={amount}
            total={total}
            isAdhocPayment={!!paymentPlan}
            paymentAmount={paymentAmount}
            discount={lineItemsDiscount}
            facilityInfo={facilityInfo}
            lineItems={checkReceivedHasValues(paymentPlan, paymentPlan?.lineItems?.filter(l => !!l.amount).map(l => ({ ...l, ...planToServerLineItem(l, undefined, !!paymentPlan) })), paymentDashboardInfo?.lineItems)}
            organizationPath={facilityInfo?.path}
          />
        )}
      {children}

      {
        showNextPatientModal && (
          <ActionConfirmModal
            isOpen={openSaveModal}
            actionTitle={"Info"}
            actionButtonText={"Find Patient"}
            actionConfirmText={"Do you want to proceed with the next Patient?"}
            iconName={''}
            showCancelButton={true}
            actionCancelButtonText={"Stay"}
            useWarningStyle={false}
            useInfoStyle={true}
            isSecondaryButton={true}
            onActionConfirm={() => {
              openPatientInfo();
              setOpenSaveModal(false);
            }}
            onClose={() => {
              setOpenSaveModal(false);
            }}
            onSecondaryActionConfirm={() => {
              stay();
              setOpenSaveModal(false);
            }}
          />
        )
      }

    </React.Fragment>
  );
};