import { GridApi, GridReadyEvent, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { EDS_Select } from '@EH/eh.eds.react';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import moment from 'moment';
import { useState } from 'react';
import { RemitHistorySearchCriteria, getRemitHistory } from 'services/RemitService';
import 'assets/styles/components/_customHeaderCell.scss';

const columnDefs = [
  { headerName: 'File Identifier', field: 'fileIdentifier', flex: 1 },
  { headerName: 'File Name', field: 'fileName', flex: 1 },
  { headerName: 'User (if recreated)', field: 'user', flex: 1 },
  { headerName: 'Start time', field: 'startTime', flex: 1 },
  { headerName: 'End Time', field: 'endTime', flex: 1 },
  { headerName: 'Elapsed Time (DD hh:mm:ss)', field: 'elapsedTime', flex: 1 },
];
const pageSizeOptions = [
  { optionName: '25', value: 25 },
  { optionName: '50', value: 50 },
  { optionName: '75', value: 75 },
  { optionName: '100', value: 100 },
];
const defaultPageSize = pageSizeOptions[0].value;

type Props = {
  organizationPath?: string;
};


const RunHistoryRemit = ({ organizationPath }: Props) => {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [gridApi, setGridApi] = useState<any | null>(null);

  const onChangePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = pageSizeOptions[event.target.selectedIndex].value;
    setPageSize(value);
    gridApi?.paginationSetPageSize(value);
  }

  function onGridReady(params: GridReadyEvent) {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    updateData(params.api);
  }

function updateData(grid?: GridApi) {
    const dataSource: IDatasource = {
    async getRows(rowsParams: IGetRowsParams) {
      if(!organizationPath) {
        return;
      }

      grid?.showLoadingOverlay();
      const remitHistorySearchCriteria: RemitHistorySearchCriteria = {
        organizationPath,
        limit: pageSizeOptions[pageSizeOptions.length - 1].value,
        offset: grid?.paginationGetCurrentPage() ?? 0
      };
      const { result }: any = await getRemitHistory(remitHistorySearchCriteria);
      if (result) {
          rowsParams.endRow = result.total;
          rowsParams.successCallback(transformData(result), result.total);
       } 
       else {
          rowsParams.successCallback([], 0);
       }
       grid?.hideOverlay();
      }
    };
    grid?.setDatasource(dataSource);
    grid?.sizeColumnsToFit();
  }

  function transformData(result: any) {
    return result.records.map((record: any) => {
      let endTimeFormatted: string = '';
      let elapsedTimeFormatted: string = '';
      if (record.endTime) {
        endTimeFormatted = moment(record.endTime).format('MM/DD/YYYY h:mm:ss A');

        const elapsedSeconds =
          (new Date(record.endTime).getTime() -
            new Date(record.startTime).getTime()) /
          1000;
        const elapsedDays = elapsedSeconds / 86400;
        elapsedTimeFormatted = `${('0' + elapsedDays.toFixed(0)).slice(-2)} ${moment.utc(elapsedSeconds * 1000).format('HH:mm:ss')}`;
      }
      return {
        fileIdentifier: record.remitConfiguration.fileIdentifier,
        fileName: `${record.remitConfiguration.fileName}${record.remitConfiguration.fileExtension}`,
        user: `(${record.user.oneSourceUserId}) ${record.user.firstName} ${record.user.lastName}`,
        startTime: moment(record.startTime).format('MM/DD/YYYY h:mm:ss A'),
        endTime: endTimeFormatted,
        elapsedTime: elapsedTimeFormatted,
      };
    })
  }

  return (
    <>
      <div style={{ fontWeight: 'bold' }}>Run History</div>
      <div className="ag-theme-alpine">
        <AgGridReact
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowModelType={'infinite'}
          domLayout={'autoHeight'}
          suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
          pagination={true}
          paginationPageSize={defaultPageSize}
          cacheBlockSize={100}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={1}
          maxBlocksInCache={2}
          suppressDragLeaveHidesColumns={true}
          defaultColDef={{
            sortable: false,
            resizable: true,
            headerClass: 'custom-header-cell',
          }}
        />
      </div>
      <div className={"pagination-container"}>
        <EDS_Select
            name="userId"
            label="Rows per page:"
            modifiers={'row-item row-item-size-double'}
            options={pageSizeOptions}
            onChange={onChangePageSize}
            value={pageSize}
          />
        </div>
    </>
  );
};

export default RunHistoryRemit;
