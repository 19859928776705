import { PaymentPlanModel } from "models";
import moment from "moment";
import { EnumTransactionTenderType } from "../../../models/enums/EnumTransactionTenderType";
import { PaymentOnFile } from "../../../models/PatientModel";
import { getStartOfMonth } from "../../../utils/Utils";
import { useGetPatientById } from "../../patients/hooks/useGetPatientById";

const paymentOnFileDefaultOption = {
    optionName: 'Please Select Payment Method',
    value: '',
};

export const isPaymentMethodNotExpired = (paymentMethod: PaymentOnFile) =>
  paymentMethod.tenderType == EnumTransactionTenderType.Card
    ? moment(paymentMethod.cardExpirationDate).isAfter(getStartOfMonth())
    : true;
    
export function usePaymentMethodOptions(patientId?: string, paymentPlan?: PaymentPlanModel) {

    const { patient } = useGetPatientById(patientId);

    const paymentMethods = patient?.paymentsOnFile
        ?.filter( paymentMethod => {
            if(!isPaymentMethodNotExpired(paymentMethod)) return false;
            return paymentMethod.isReusable || (paymentPlan?.tender?.method && paymentPlan.tender.method === paymentMethod.tokenId);
        }).map((payment) => {
            return {
                optionName: `${payment.tenderType} - ${payment.maskedAccount || ''}`,
                value: payment.tokenId,
            };
        }) || [];

    return [paymentOnFileDefaultOption].concat(
        paymentMethods
    );
}