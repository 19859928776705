import { DeviceManager, DeviceManagerClient } from 'models/DeviceManagerModel';
import { callHttpApi } from './callHttpApi';

export interface DeviceManagerResponse {
  result?: DeviceManager[];
  err?: string;
}

export interface DeviceManagerClientsResponse {
  result?: DeviceManagerClient[];
  err?: string;
}

export async function UpdateDeviceManagerForClient(
  organizationPaths?: string[]
): Promise<DeviceManagerResponse> {
  const params = { organizationPaths };
  const headers = {
    'Content-Type': 'application/json',
  };
  return callHttpApi(
    'put',
    `/api/v1/Transactions/Configuration/devices`,
    params,
    undefined,
    headers
  );
}

export async function GetDeviceManagerClients(
  organizationPath?: string
): Promise<DeviceManagerClientsResponse> {
  return callHttpApi<DeviceManagerClient[]>(
    'get',
    `/api/v1/Transactions/Configuration/devices/${organizationPath}`
  );
}
