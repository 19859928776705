import { useEffect, useState } from 'react';
import moment from 'moment';
import { EDS_Button, EDS_Select } from '@EH/eh.eds.react';
import { useTypedSelector } from 'app/rootReducer';
import { LineItem, LineItems } from 'models/LineItem';
import Print from 'features/print/print';
import { EmailReceipt } from 'features/emailReceipt/EmailReceipt';
import BalanceHeader from 'features/paymentPlan/infoSections/BalanceHeader';
import Footer from 'features/footer/Footer';
import { sendEmailReceipts } from 'services/EmailReceiptsService';
import {
  ApiTenderTypeEnum,
  displayAmount,
  maskECheckNumber,
  formatDateWithTime,
  formatDate,
  languageOptions,
  checkReceivedHasValues,
  getValueOrDefault
} from 'utils/Utils';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import '../../assets/styles/components/_paymentPlan.scss';
import { useDispatch } from 'react-redux';
import { AlertIds, AlertTypes, setAlert } from '../alert/AlertReducer';
import TransactionDetailsPrint  from 'features/transactionDetails/TransactionDetailsPrint';
import { getLogoDataForOrganization } from 'features/admin/logoManager/LogoManagerReducer';
import { EnumTransactionTenderType } from 'models/enums/EnumTransactionTenderType';
import { convertUtcToLocalDate } from 'utils/UtilsDateTime';
import { PopoverClientElement } from 'pages/Search/simpleSearch/MainFielters/SearchByPatientInformation';
import { toolTipMessages } from 'pages/Search/advanceSearch/MainFilters/SearchByTransactionDetails';
import { getReceiptManager } from 'features/admin/receiptManager/ReceiptManagerReducer';
import { getEmptyModel } from 'pages/Admin/ReceiptManager';
import { ReceiptManagerResponse } from 'services/ReceiptManagerService';
import { ReceiptManager } from 'models/ReceiptManagerModel';
import { getRowValue } from 'features/paymentPlan/PlanUtils';
import { useGetReceipts } from 'features/admin/receipts/useGetReceipts';

export default (props: {
  done?: () => void;
  isGL?: boolean;
  total?: number;
  amount?: number;
  discount?: number;
  isAdhocPayment?: boolean;
  paymentAmount?: number;
  lineItems?: LineItem[];
  facilityInfo?: any;
  organizationPath?: string
}) => {

  const { done, total, amount, discount, isGL, paymentAmount, lineItems, isAdhocPayment, organizationPath } = props;
  
  const totalAmount = (isAdhocPayment ? paymentAmount : total) || 0;
  const patientMailingInfo = useTypedSelector(
    s => s.patientMailingInformation?.value
  );

  const currentTransactionInfo = useTypedSelector(
    s => s.currentTransactionStatus?.status
  );
  
  const selectedLineItems = isAdhocPayment && !lineItems?.some(item => item.isActive) ? lineItems : lineItems?.filter(item => item.isActive);

  const paymentPanelConfiguration = useTypedSelector(s => s.paymentDashboard?.panelConfigurations?.[getValueOrDefault(organizationPath, '')]?.configuration); 
  const paymentAttributes = checkReceivedHasValues(paymentPanelConfiguration, paymentPanelConfiguration?.entity?.attributes, []);

  const getRemainingBalance = (lineItems?: LineItems) => {
    if( !lineItems?.length ) {
      return 0;
    }

    const finalBalance = lineItems?.reduce(
      (sum, lineItem) => sum + Number(getValueOrDefault(lineItem.balance, 0)),
      0
    );

    const finalAmount = lineItems?.reduce(
      (sum, lineItem) => sum + Number(getValueOrDefault(lineItem.amount, 0)),
      0
    );
    
    const finalDiscount = lineItems?.reduce(
      (sum, lineItem) => sum + Number(getValueOrDefault(lineItem.discount, 0)),
      0
    );

    return finalBalance - (finalAmount - finalDiscount);
  }

  const remainingBalance = getRemainingBalance(selectedLineItems);

  const dispatch = useDispatch();

  const paymentCompleteAlert = { id: AlertIds.PaymentCompleteAlert, type: AlertTypes.Success, message: 'Transaction complete!', dismissable: true };
  useEffect(() => {
    dispatch(setAlert(paymentCompleteAlert))
  }, []);


  useEffect(() => {
    currentTransactionInfo?.organization?.path && dispatch(getLogoDataForOrganization(currentTransactionInfo?.organization?.path));
    loadReceiptManagerConfiguration(getValueOrDefault(currentTransactionInfo?.organization?.path, ''));
  }, [dispatch, currentTransactionInfo?.organization?.path]);

  const [showNotesPopup, setShowNotesPopup] = useState(false);
  const [isEmailPopupOpened, setIsEmailPopupOpened] = useState(false);
  const [emailReceiptSent, setEmailReceiptSent] = useState(false);
  const [emailError, setEmailError] = useState<string>('');
  const [language, setLanguague] = useState<string>('english');
  const { printPaymentReceipt } = useGetReceipts();
  const [printReceiptUrl, setPrintReceiptUrl] = useState<string>('');
  const [receiptManagerValues, setReceiptManagerValues] = useState<ReceiptManager>({});
  const firstName = checkReceivedHasValues(patientMailingInfo?.billingFirstName, patientMailingInfo?.billingFirstName, patientMailingInfo?.firstName);
  const lastName = checkReceivedHasValues(patientMailingInfo?.billingLastName, patientMailingInfo.billingLastName, patientMailingInfo?.lastName);

  const loadReceiptManagerConfiguration = (organizationPath: string) => {
    dispatch(getReceiptManager(organizationPath, (response?: ReceiptManagerResponse) => {
      if (!response?.err && response?.result !== undefined) {
        setReceiptManagerValues(getValueOrDefault(response?.result, getEmptyModel()));
      }
    }));
  }
  
  const formatBoldValue = (string: string) => {
    const delimiterIndex = string.lastIndexOf('.');
    const boldPart = string.substring(0, delimiterIndex);
    const restPart = string.substring(delimiterIndex);
    return (
      <>
        <span className="value-bold">{boldPart}</span>
        {restPart}
      </>
    );
  };

  const openNotesPopup = () => setShowNotesPopup(true);
  const closeNotesPopup = () => setShowNotesPopup(false);
  const renderPopup = (value: string) => (
    <div className="notes-popup">
      <button className="button-close material-icons" onClick={closeNotesPopup}>
        close
      </button>
      <div className="">{value}</div>
    </div>
  );

  const closeEmailPopup = () => {
    setIsEmailPopupOpened(false);
    setEmailReceiptSent(false);
  };

  const sendEmailReceipt = async (emailAddresses: string[]) => {
    const result =
      currentTransactionInfo?.id &&
      (await sendEmailReceipts(currentTransactionInfo?.id, {
        transactionId: currentTransactionInfo?.id,
        emailAddresses,
      }));

    if (result && result.err) {
      setEmailError(result.err);
    } else {
      setEmailReceiptSent(true);
    }
  };

  const tenderType = checkReceivedHasValues(patientMailingInfo?.tenderType === ApiTenderTypeEnum.SavedOnFile,
      patientMailingInfo?.originalTenderType,
      patientMailingInfo?.tenderType
    );

  const displayTenderType = (type: string | ApiTenderTypeEnum | undefined) => {
    if (type === ApiTenderTypeEnum.PaperCheckAsECheck) return 'Paper Check';
    if (type === ApiTenderTypeEnum.MoneyOrder) return 'Money Order';
    if (type === ApiTenderTypeEnum.Cash) return 'Cash';
    if (type === ApiTenderTypeEnum.ECheck || type === EnumTransactionTenderType.eCheck) return 'eCheck';
    if (type === ApiTenderTypeEnum.CardManual || type === EnumTransactionTenderType.Card) return 'Credit/Debit Manual Entry';
    if (type === ApiTenderTypeEnum.CardDevice) return 'Credit/Debit Device';
    return patientMailingInfo?.tenderType;
  };
  
  const showLanguageSelect = (hasMultilingualUnifiedReceipts?: boolean) => {
    if(hasMultilingualUnifiedReceipts){
      return (
        <EDS_Select
              name={'language'}
              label={''}
              options={languageOptions}
              value={language}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setLanguague(e.target.value)}
          />
      )
    }
  }

  const getPaymentTableHeaderRows = () => {
    return (
      <tr>
        <th>Collection Date</th>
        <th>Account Number</th>
        <th>Payment Amount</th>
      </tr>
    )
  }

  const getGlTableHeaderRows = () => {
    const attributeNames = paymentAttributes?.map((attr: any) => attr.label);

    return <tr className="table-header"> 
        {attributeNames?.map((header: string) => (
          <th key={header} className="cell">
            <div>
              {header}
            </div>
          </th>
        ))}
      </tr>
  }

  const getGlTableBodyRows = () => {
    return (
      currentTransactionInfo?.lineItems?.filter(l => l.isActive).map((lineItem) => (
        <tr key={lineItem._id}>
          {paymentAttributes?.map((attr: any) => (
            <td key={attr._id}>{getRowValue(lineItem, attr.name, currentTransactionInfo?.isGL ?? false)}</td>
          ))}
        </tr>
      ))
    )
  }

  const getPaymentBodyRows = () => {
    return currentTransactionInfo?.lineItems?.map(
      (row: LineItem) => (
        <tr key={row._id}>
        <td>
          {getRowValue(row, 'collectionDate', currentTransactionInfo?.isGL ?? false)}
          </td>
          <td>{getRowValue(row, 'accountNumber', currentTransactionInfo?.isGL ?? false)}</td>
          <td>
            $
            {getRowValue(row, 'amount', currentTransactionInfo?.isGL ?? false)}
          </td>
        </tr>
      )
    )
  }

  const openPrintPreview = async()=>{    
    const receiptURL = await printPaymentReceipt(currentTransactionInfo?.id,language);
    if(receiptURL){
      setPrintReceiptUrl( receiptURL)
    }     
  };

  return (
    <>
      <div className="payment-plan-container height-calc">
        <div className="payment-plan-form-container">
          <BalanceHeader total={paymentAmount ?? total}
            amount={amount}
            discount={discount} />
          <div className="flex-row">
            <div className="row-item row-item-size-double">
              <div className="eds-heading eds-heading.lg-caps mb-1">
                Payment Complete
              </div>
            </div>
          </div>
          <div className="flex-row">
            <div className="row-item row-item-size-double">
              <div className="eds-heading eds-heading.mdplus-caps mb-1">
                Payment Information
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Status:</div>
                <div className="col-7">
                  {currentTransactionInfo?.statusType ?? ''}
                </div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">
                  Reference:
                  <span className="help">
                    <PopoverClientElement
                      placement="top"
                      content={toolTipMessages.gatewayReferenceNumber}
                    />
                  </span>
                </div>
                <div className="col-7">
                  {getValueOrDefault(currentTransactionInfo?.gatewayReferenceNumber, '')}
                </div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Created On:</div>
                <div className="col-7">
                  {formatDateWithTime(moment(convertUtcToLocalDate(currentTransactionInfo?.createdDate)))}
                </div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Tender Type:</div>
                <div className="col-7">{displayTenderType(tenderType)}
                </div>
              </div>
              {(tenderType === ApiTenderTypeEnum.Cash) && (
                <div className="flex-row">
                  <div className="row-item row-item-size-double">
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Amount Tendered:
                      </div>
                      <div className="col-7">
                        ${displayAmount(patientMailingInfo?.amountTendered)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">Cash Back:</div>
                      <div className="col-7">
                        $
                        {displayAmount(checkReceivedHasValues(
                          (getValueOrDefault(patientMailingInfo?.amountTendered, 0)) - totalAmount > 0,
                          Number(patientMailingInfo.amountTendered) - totalAmount,
                          0
                          ))
                }
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Remaining Balance:
                      </div>
                      <div className="col-7">
                        ${displayAmount(remainingBalance)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(tenderType === ApiTenderTypeEnum.CardManual) && (
                <div className="flex-row">
                  <div className="row-item row-item-size-double">
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Card Type:
                      </div>
                      <div className="col-7">
                        {currentTransactionInfo?.cardBrand}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Card Number:
                      </div>
                      <div className="col-7">
                        {currentTransactionInfo?.tenderMaskedAccount}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Expiration Date:
                      </div>
                      <div className="col-7">
                        {moment
                          .utc(patientMailingInfo?.expiration)
                          .format('MM/YY')}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Remaining Balance:
                      </div>
                      <div className="col-7">
                        ${displayAmount(remainingBalance)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(tenderType === ApiTenderTypeEnum.ECheck) && (
                <div className="flex-row">
                  <div className="row-item row-item-size-double">
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Routing Number:
                      </div>
                      <div className="col-7">
                        {maskECheckNumber(patientMailingInfo?.routingNumber)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Account Number:
                      </div>
                      <div className="col-7">
                        {currentTransactionInfo?.tenderMaskedAccount}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Financial Institution:
                      </div>
                      <div className="col-7">
                        {patientMailingInfo?.financialInstitution}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Remaining Balance:
                      </div>
                      <div className="col-7">
                        ${displayAmount(remainingBalance)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tenderType === ApiTenderTypeEnum.PaperCheckAsECheck && (
                <div className="flex-row">
                  <div className="row-item row-item-size-double">
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Check Number:
                      </div>
                      <div className="col-7">
                        {patientMailingInfo?.paperCheckNumber}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Financial Institution:
                      </div>
                      <div className="col-7">
                        {patientMailingInfo?.financialInstitution}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Remaining Balance:
                      </div>
                      <div className="col-7">
                        ${displayAmount(remainingBalance)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tenderType === ApiTenderTypeEnum.MoneyOrder && (
                <div className="flex-row">
                  <div className="row-item row-item-size-double">
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Serial Number:
                      </div>
                      <div className="col-7">
                        {patientMailingInfo.moneyOrderSerialNumber}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Post Office:
                      </div>
                      <div className="col-7">
                        {patientMailingInfo.moneyOrderPostOffice}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Issue Date:
                      </div>
                      <div className="col-7">
                        {formatDate(moment.utc(patientMailingInfo.moneyOrderIssueDate))}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Amount Tendered:
                      </div>
                      <div className="col-7">
                        ${displayAmount(patientMailingInfo.moneyOrderAmount)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">Cash Back:</div>
                      <div className="col-7">
                        ${displayAmount(
                          isGL
                            ? patientMailingInfo.moneyOrderAmount ||
                              0 - total! > 0
                              ? (patientMailingInfo.moneyOrderAmount
                                  ? patientMailingInfo.moneyOrderAmount
                                  : 0) - (total ? total : 0)
                              : 0
                            : patientMailingInfo.moneyOrderAmount ||
                              0 - amount! > 0
                            ? (patientMailingInfo.moneyOrderAmount
                                ? patientMailingInfo.moneyOrderAmount
                                : 0) - totalAmount
                            : 0
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 confirmation-label">
                        Remaining Balance:
                      </div>
                      <div className="col-7">
                        ${displayAmount(remainingBalance)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <hr />
          
          <div className="flex-row">
            <table className={'row-item row-item-size-double'}>
              <div className="eds-heading eds-heading.mdplus-caps mb-1">
                Payment Summary
              </div>
              <thead>
              {
                checkReceivedHasValues(isGL,getGlTableHeaderRows(), getPaymentTableHeaderRows())
              }
              </thead>
              <tbody>
                {
                  checkReceivedHasValues(isGL, getGlTableBodyRows(), getPaymentBodyRows())
                }
              </tbody>
            </table>
          </div>
          <div className="flex-row">
            <div className="row-item row-item-size-double">
              <div className="eds-heading eds-heading.mdplus-caps right-align">
                Payment Total: ${formatBoldValue(displayAmount(getValueOrDefault(paymentAmount, total)))}
              </div>
            </div>
          </div>
          <hr />
          <div className="flex-row">
            <div className="row-item row-item-size-double">
              <div className="eds-heading eds-heading.mdplus-caps mb-1">
                Payer Information
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">
                  Payer First Name:
                </div>
                <div className="col-7">
                  {firstName}
                </div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Payer Last Name:</div>
                <div className="col-7">
                  {lastName}
                </div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Address:</div>
                <div className="col-7">
                  {patientMailingInfo.billingAddressLine1}
                </div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">City:</div>
                <div className="col-7">{patientMailingInfo.billingCity}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">State:</div>
                <div className="col-7">{patientMailingInfo.billingState}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Zip:</div>
                <div className="col-7">{patientMailingInfo.billingZipCode}</div>
              </div>
              <div className="row">
                <div className="col-5 confirmation-label">Notes:</div>
                <div className="col-7">{patientMailingInfo?.notes}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer>
        <div className="footer-buttons">
          <EDS_Button
            modifiers={'mr-3 eds-button eds-button.tertiary'}
            name={'email'}
            iconName={'email'}
            buttonText={'Email'}
            onClick={() => setIsEmailPopupOpened(true)}
          />
          <Print
            className="print-button"
            handleClick={openPrintPreview}
            ariaLabel="Print"
          />
          {
            showLanguageSelect(receiptManagerValues.generalSettings?.hasMultilingualUnifiedReceipts)
          }
          <div className="btnItem next-button">
            <EDS_Button
              modifiers={'mr-3 eds-button eds-button.primary done-button'}
              name={'done'}
              buttonText={'Done'}
              onClick={done}
            />
          </div>
        </div>
      </Footer>
      {isEmailPopupOpened && (
        <EmailReceipt
          emailReceiptSent={emailReceiptSent}
          onClose={closeEmailPopup}
          onSubmit={sendEmailReceipt}
          error={emailError}
        />
      )}
     
     {printReceiptUrl && <TransactionDetailsPrint receiptUrl={printReceiptUrl}/> }
    
    </>
  );
};

