import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPatient } from "../../../services/patient/PatientService";
import { replacePaymentsOnFile } from "../PatientsReducer";

export function useGetPatientPaymentsOnFile(id?: string){
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();

  const getPatientPaymentsOnFile = async () => {
    setIsProcessing(true);
    if (id) {
      const response = await getPatient(id);
      if (response.result) {
        dispatch(replacePaymentsOnFile({ patientId: response.result.id, paymentsOnFile: response.result.paymentsOnFile }));
      }
      setIsProcessing(false);
    }
    else return
  };

  return { isProcessing, getPatientPaymentsOnFile };
}