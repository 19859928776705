import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ExternalAddress {
  id?: string;
  url: string;
  mappedOrganizations: {
    organizationReference: {
      id: string;
      name: string;
      path: string;
    };
    createdBy?: {
      id: string;
      firstName: string;
      lastName: string;
      oneSourceUserId: string;
    };
    updatedBy?: {
      id: string;
      firstName: string;
      lastName: string;
      oneSourceUserId: string;
    };
    isActive?: boolean;
  }[];
  createdBy?: {
    id: string;
    firstName: string;
    lastName: string;
    oneSourceUserId: string;
  };
  updatedBy?: {
    id: string;
    firstName: string;
    lastName: string;
    oneSourceUserId: string;
  };
  isActive: boolean;
  createdDate?: string;
  updatedDate?: string;
}

export interface Metadata {
  isEditing?: boolean;
  idx?: number;
  tempId?: string;
}

export interface EditableExternalAddress extends ExternalAddress {
  metadata?: Metadata,
  errors?: string;
}
interface State {
  externalAddresses: EditableExternalAddress[];
}

const initialState: State = {
  externalAddresses: [],
};  

const reducerSlice = createSlice({
  name: 'Whitelist',
  initialState,
  reducers: {
    setExternalAddresses(state, action: PayloadAction<ExternalAddress[]>) {
      state.externalAddresses = action.payload;
    },
    setExternalAddress(state, action: PayloadAction<{ externalAddress: EditableExternalAddress, index?: number, unshift?: boolean }>) {
      const { externalAddress, index, unshift } = action.payload;
      if (index != undefined && index > -1) {
        state.externalAddresses[index] = externalAddress;
      } else {
        if (unshift) {
          state.externalAddresses.unshift(externalAddress);
        } else {
          state.externalAddresses.push(externalAddress);
        }
      }
    },
    removeExternalAddress(state, action: PayloadAction<number>) {
      const idx = action.payload
      if (idx > -1) {
        state.externalAddresses.splice(idx, 1);
      }
    },
    setExternalAddressErrors(state, action: PayloadAction<{id: string, errors: string}>) {
      const idx = state.externalAddresses.findIndex(ea => ea.id === action.payload.id);
      if (idx > -1) {
        state.externalAddresses[idx].errors = action.payload.errors;
      }
    },
    setExternalAddressIsEditing(state, action: PayloadAction<{id: string, isEditing: boolean}>) {
      const idx = state.externalAddresses.findIndex(ea => ea.id === action.payload.id);
      if (idx > -1) {
        if (!state.externalAddresses[idx].metadata) {
          state.externalAddresses[idx].metadata = {};
        }
        state.externalAddresses[idx].metadata!.isEditing = action.payload.isEditing;
      }
    },
  },
});

export const { setExternalAddresses, setExternalAddress, removeExternalAddress, setExternalAddressErrors, setExternalAddressIsEditing } = reducerSlice.actions;
export default reducerSlice.reducer;