import { useEffect, useState } from 'react';
import { FullOrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import moment from 'moment-timezone';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import {
  RemitScheduleResponse,
  UpdateHistory,
} from 'models/RemitScheduleModel';
import {
  GridColumnsChangedEvent,
  GridReadyEvent,
  RowNode,
} from 'ag-grid-community';
import { GetRemitSchedule } from 'services/RemitScheduleService';
import { formatDateWithTime } from 'utils/Utils';

interface ColumnEl extends GenericColumnEl<UpdateHistory> {}

interface GenericColumnEl<T> {
  name: keyof T;
  label: string;
}

export default function RemitConfigurationHistory(props: {
  organization: FullOrganizationLevelDocument | undefined;
}) {
  const { organization } = props;
  const [rowData, setRowData] = useState<any>([]);

  const getScheduleData = async () => {
    if (organization?.path) {
      const { result }: RemitScheduleResponse = await GetRemitSchedule(
        organization?.path
      );
      if (result) {
        const historyData: undefined | any[] = result.updateHistory?.map((record) => {
          if(!record.createdBy.id){
            record.createdBy = record.updatedBy;
          }

          if(!record.createdDate || record.createdDate === "0001-01-01T00:00:00"){
            record.createdDate = record.updatedDate;
          }

          return record;
        });

        historyData?.reverse();
        
        setRowData(historyData);
      }
    }
  };

  useEffect(() => {
    getScheduleData();
  }, [organization?.path]);

  const headerColumns: ColumnEl[] = [
    { label: 'Created/Updated By', name: 'createdBy' },
    { label: 'Date Created/Updated', name: 'createdDate' },
    { label: 'Run Hours', name: 'startHours' },
    { label: 'History (days)', name: 'historyInDays' },
  ];

  const headerDefinitions = headerColumns.map((el, index) => ({
    headerName: el.label,
    field: el.name,
    sortable: false,
    resizable: true,
    filter: false,
    cellRenderer: (params: { data: UpdateHistory; node: RowNode }) =>
      getTableCellValue(el, params.data),
  }));

  function sizeColumnsToFit(params: GridReadyEvent | GridColumnsChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  return (
    <>
      {!organization?.path ? (
        <>No Facility path</>
      ) : (
        <>
          <div className="column">
            <span className="bold" style={{ fontSize: '14px' }}>
              Configuration History
            </span>
            <div className="ag-theme-alpine mt-3">
              <AgGridReact
                columnDefs={headerDefinitions}
                onGridReady={sizeColumnsToFit}
                onGridColumnsChanged={sizeColumnsToFit}
                rowData={rowData}
                pagination={false}
                domLayout={'autoHeight'}
                gridAutoHeight={true}
                suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
                enableCellTextSelection={true}
                ensureDomOrder={true}
                suppressDragLeaveHidesColumns={true}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export function getTableCellValue(element: ColumnEl, data?: UpdateHistory) {
  if (!data) return '';

  let value = data[element.name];
  return getCellValue(element, value);
}

export function getCellValue(
  element: ColumnEl,
  value: any,
  defaultOnParse = '-'
) {
  if (!value) return '';

  if (element.name.toString() === 'createdBy') {
    value = `${
      value?.oneSourceUserId == null ? '' : '(' + value?.oneSourceUserId + ')'
    } ${value?.firstName == null ? '' : value?.firstName} ${
      value?.lastName == null ? '' : value?.lastName
    } `;
  }

  if (element.name.toString() === 'createdDate') {
    value = formatDateWithTime(moment(value));
  }

  if (element.name.toString() === 'startHours') {
    const modmodifiedValue = value.map((time: number) => {
      if (time > 12) {
        return `${time - 12} pm`;
      } else {
        return `${time} am`;
      }
    });
    value = modmodifiedValue.join(', ');
  }

  return value || defaultOnParse;
}
