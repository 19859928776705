import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as TransactionIcon } from 'assets/svgs/receipt.svg';
import { ReactComponent as PermissionsIcon } from 'assets/svgs/globalTools/icon-permissions.svg';
import { ReactComponent as WebServiceIcon } from 'assets/svgs/globalTools/webservice-icon.svg';
import { ReactComponent as AddFacility } from 'assets/svgs/icon-add-facility.svg';
import { ReactComponent as TokenLookUpIcon } from 'assets/svgs/search_small.svg';
import { ReactComponent as IconGeneralSettings } from 'assets/svgs/icon-settings-blue.svg';
import { ReactComponent as IconMonetization } from 'assets/svgs/icon-monetization.svg';
import { ReactComponent as IconTools } from 'assets/svgs/icon-tools-blue.svg';
import { ReactComponent as IconPayment } from 'assets/svgs/icon-payment.svg';
import { ReactComponent as IconRemit } from 'assets/svgs/icon-remit.svg';
import { ReactComponent as OrganizationIcon } from 'assets/svgs/org_icon_small.svg';

import { ReactComponent as ECareNextIcon } from 'assets/svgs/globalTools/icon-ecare-next.svg';
import { ReactComponent as EpicIcon } from 'assets/svgs/globalTools/epic-icon.svg';
import { ReactComponent as TokenIcon } from 'assets/svgs/globalTools/token-icon.svg';
import { ReactComponent as PaymentPlansIcon } from 'assets/svgs/globalTools/icon-payment-plans.svg';
import { ReactComponent as PaymentsIcon } from 'assets/svgs/globalTools/icon-payments.svg';
import { ReactComponent as RolesPermissionsIcon } from 'assets/svgs/globalTools/icon-roles-permissions.svg';
import { ReactComponent as SemaphoneIcon } from 'assets/svgs/globalTools/icon-semaphone.svg';


import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import GlobalLink from './GlobalLink';
import UserPermissions from 'models/enums/UserPermissions';
import { useLoggedUserPermissions } from 'app/hooks/useLoggedUserPermissions';
import { useTypedSelector } from 'app/rootReducer';
import * as router from 'react-router-dom';
import { useOrganizations } from '../../organizations/hooks/useOrganizations';

interface GlobalToolsProps {
  pathname: string;
  isFacilityDisabled: boolean;
  facilityId?: string;
}

export default function GlobalTools(props: GlobalToolsProps) {
  const { pathname } = props;
  const params = router.useParams<{ '*': string }>();

  const elements = params?.['*']?.split('/')
  const clientId = elements && elements.length > 1 ? elements[0] : undefined;
  const { getOrganizationById } = useOrganizations();
  const organization = getOrganizationById(clientId);
  const isFacilityDisabled = !organization?.isEnabled;
  const facilityId = clientId;

  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const [IsEpicToolsOpen, setIsEpicToolsOpen] = useState(false);
  const { hasPermissionOrSuperAdmin } = useLoggedUserPermissions();
  
  const globalTools = [
    'client-configuration',
    'remit-configuration',
    'organization-user-management',
    'token-guid-lookup',
    'transaction-info',
    'webService-error',
    'add-new-facility',
    'payment-panel-configuration',
    'payment-plan-configuration',
    'gl-list-manager',
    'users-and-permissions',
    'accepted-payments',
    'epic',
    'user-token-management',
    'epic-department-mapping',
  ];

  const partsOfPath = pathname.split('/');

  const getActiveClassFor = (tabName: string, subTab?: boolean) => {
    if (pathname.includes(tabName) && subTab) return 'sub-active';
    if (pathname.includes(tabName) && !subTab) return 'active';
    return '';
  };

  const sspUserPermission = hasPermissionOrSuperAdmin(UserPermissions.SelfServicePortal);
  const isAdmin = hasPermissionOrSuperAdmin(UserPermissions.FinancialAdministation);

  const renderSecondaryMenu = () => {
    if (globalTools.some(tool => pathname.includes(tool)) && facilityId) {
      return (
        <div className="general-settings-links">
          <h2 className="links-title">Facility Config. Tools</h2>
          <ul className="list-unstyled global-tools-links">
            <li
              className={`${
                partsOfPath[partsOfPath.length - 1] === 'client-configuration'
                  ? 'active'
                  : ''
              }`}
            >
              <Link to={`/admin-settings/${facilityId}/client-configuration`}>
                <div>
                  <IconGeneralSettings />
                </div>
                <span>General Settings</span>
              </Link>
            </li>
            <li className={`${getActiveClassFor('credit-card-config')}`}>
              <GlobalLink
                to={`/admin-settings/${facilityId}/client-configuration/credit-card-config`}
                isDisabled={isFacilityDisabled}
                disabledTitle="Facility is disabled"
              >
                <div>
                  <IconMonetization />
                </div>
                <span>Credit Card Config.</span>
              </GlobalLink>
            </li>
            <li className={`${getActiveClassFor('accepted-payments')}`}>
              <GlobalLink
                to={`/admin-settings/${facilityId}/accepted-payments`}
                isDisabled={isFacilityDisabled}
                disabledTitle="Facility is disabled"
              >
                <div>
                  <IconMonetization />
                </div>
                <span>Accepted Payments</span>
              </GlobalLink>
            </li>
            <li>
              <a
                href="# "
                className={''}
                onClick={(e: any) => {
                  e.preventDefault();
                    setIsEpicToolsOpen(isOpen => !isOpen);
                }}
              >
                <div>
                  <EpicIcon />
                </div>
                <span>Epic Tools</span>
                <span className="menu-status">
                  <Chevron open={IsEpicToolsOpen} />
                </span>
              </a>
              {shouldShowEpic(IsEpicToolsOpen, pathname) && (
                <ul className="list-unstyled tools-links">
                  <li className={`${getActiveClassFor('epic-whitelist-manager')}`}>
                    <Link
                      to={`/admin-settings/${facilityId}/epic/whitelist-manager`}
                    >
                      <span>URL Whitelist Manager</span>
                    </Link>
                  </li>
                  <li className={`${getActiveClassFor('integration-settings')}`}>
                    <Link
                      to={`/admin-settings/${facilityId}/epic/integration-settings`}
                    >
                      <span>Epic Integration Settings</span>
                    </Link>
                  </li>
                  <li className={`${getActiveClassFor('epic-department-mapping')}`}>
                    <GlobalLink
                      to={`/admin-settings/${facilityId}/epic-department-mapping`}
                      isDisabled={false}
                    >
                      <span>Epic Department Mapping</span>
                    </GlobalLink>
                  </li>
                  <li className={`${getActiveClassFor('epic-user-token-mapping')}`}>
                    <GlobalLink
                      to={`/admin-settings/${facilityId}/epic-user-token-mapping`}
                      isDisabled={false}
                    >
                      <span>Epic User Token Mapping</span>
                    </GlobalLink>
                  </li>
                </ul>
              )}
            </li>
            {/* Unused links hidden*/}
            {/* <li className={`${getActiveClassFor('ecare-next')}`}>
              <GlobalLink
                to={`/admin-settings/${facilityId}/ecare-next`}
                isDisabled={isFacilityDisabled}
                disabledTitle="Facility is disabled"
              >
                <ECareNextIcon />
                <span>eCare NEXT</span>
              </GlobalLink>
            </li>
            <li className={`${getActiveClassFor('payments')}`}>
              <GlobalLink
                to={`/admin-settings/${facilityId}/payments`}
                isDisabled={isFacilityDisabled}
                disabledTitle="Facility is disabled"
              >
                <PaymentsIcon />
                <span>Payments</span>
              </GlobalLink>
            </li>
            <li className={`${getActiveClassFor('payment-plans')}`}>
              <GlobalLink
                to={`/admin-settings/${facilityId}/payment-plans`}
                isDisabled={isFacilityDisabled}
                disabledTitle="Facility is disabled"
              >
                <PaymentPlansIcon />
                <span>Payment-Plans</span>
              </GlobalLink>
            </li> */}
            <li
              className={`${getActiveClassFor('payment-panel-configuration')}`}
            >
              <GlobalLink
                to={`/admin-settings/${facilityId}/payment-panel-configuration`}
                isDisabled={isFacilityDisabled}
                disabledTitle="Facility is disabled"
              >
                <IconPayment />
                <span>Payment Panel Config.</span>
              </GlobalLink>
            </li>
            <li className={`${getActiveClassFor('remit-configuration')}`}>
              <GlobalLink
                to={`/admin-settings/${facilityId}/remit-configuration`}
                isDisabled={isFacilityDisabled}
                disabledTitle="Facility is disabled"
              >
                <IconRemit />
                <span>Remit Config.</span>
              </GlobalLink>
            </li>
            {/* Unused links hidden*/}
            {/* <li className={`${getActiveClassFor('role-permissions')}`}>
              <GlobalLink
                to={`/admin-settings/${facilityId}/role-permissions`}
                isDisabled={isFacilityDisabled}
                disabledTitle="Facility is disabled"
              >
                <RolesPermissionsIcon />
                <span>Roles/Permissions</span>
              </GlobalLink>
            </li>

            <li className={`${getActiveClassFor('semaphone')}`}>
              <GlobalLink
                to={`/admin-settings/${facilityId}/semaphone`}
                isDisabled={isFacilityDisabled}
                disabledTitle="Facility is disabled"
              >
                <SemaphoneIcon />
                <span>Semaphone</span>
              </GlobalLink>
            </li> */}
            

            <li>
              <a
                href="# "
                className={isFacilityDisabled ? 'global-link-disabled' : ''}
                title={isFacilityDisabled ? 'Facility is disabled' : ''}
                onClick={(e: any) => {
                  e.preventDefault();
                  if (!isFacilityDisabled) {
                    setIsToolsOpen(isOpen => !isOpen);
                  }
                }}
              >
                <div>
                  <IconTools />
                </div>
                <span>Tools</span>
                <span className="menu-status">
                  {isToolsOpen ? <FaChevronUp /> : <FaChevronDown />}
                </span>
              </a>
              {isToolsOpen && (
                <ul className="list-unstyled tools-links">
                  {/* Unused links hidden */}
                  {/* <li>
                    <a href="# ">
                      <span>Authorized IPs</span>
                    </a>
                  </li>
                  <li>
                    <a href="# ">
                      <span>Custom Message Editor</span>
                    </a>
                  </li> */}
                  <li
                    className={`${getActiveClassFor('device-manager', true)}`}
                  >
                    <Link
                      to={`/admin-settings/${facilityId}/client-configuration/device-manager`}
                    >
                      <span>Device Manager</span>
                    </Link>
                  </li>
                  <li className={`${getActiveClassFor('gl-list-manager', true)}`}>
                    <Link to={`/admin-settings/${facilityId}/gl-list-manager`}>
                      <span>GL List Manager</span>
                    </Link>
                  </li>
                  {/* Unused links hidden */}
                  {/* <li>
                    <a href="# ">
                      <span>Hide Transaction</span>
                    </a>
                  </li> */}
                  <li className={`${getActiveClassFor('logo-manager', true)}`}>
                    <Link
                      to={`/admin-settings/${facilityId}/client-configuration/logo-manager`}
                    >
                      <span>Logo Manager</span>
                    </Link>
                  </li>
                  <li
                    className={`${getActiveClassFor(
                      'payment-plan-configuration',
                      true
                    )}`}
                  >
                    <Link
                      to={`/admin-settings/${facilityId}/client-configuration/payment-plan-configuration`}
                    >
                      <span>Payment Plans</span>
                    </Link>
                  </li>
                  {/* Unused links hidden */}
                  {/* <li>
                    <a href="# ">
                      <span>PayPlan Recurring Values</span>
                    </a>
                  </li> */}
                  
                  <li>
                    <Link
                      to={`/admin-settings/${facilityId}/client-configuration/receipt-manager`}
                    >
                      <span>Receipt Manager</span>
                    </Link>
                  </li>
                  <li className={`${getActiveClassFor('remit-service', true)}`}>
                    <Link
                      to={`/admin-settings/${facilityId}/client-configuration/remit-service`}
                    >
                      <span>Remit Service</span>
                    </Link>
                  </li>
                  {/* Unused links hidden */}
                  {/* <li>
                    <a href="# ">
                      <span>Service</span>
                    </a>
                  </li>
                  <li>
                    <a href="# ">
                      <span>Whitelist Editor</span>
                    </a>
                  </li>                  */}
                </ul>
              )}
            </li>
            <li className={`${getActiveClassFor('user-token-management')}`}>
              <Link
                to={addFacilityIdQueryParameter(`/admin-settings/${facilityId}/user-token-management`)}
              >
                <div>
                  <TokenIcon />
                </div>
                <span>User Token Management</span>
              </Link>
            </li>
          </ul>
        </div>
      );
    }
  };

  const showSettingLinks = (permission: boolean) => {
    
    if(!isAdmin && permission) return (
      <ul className="list-unstyled global-tools-links">
        <li className={`${getActiveClassFor('users-and-permissions')}`}>
            <Link
              to={addFacilityIdQueryParameter('/admin-settings/users-and-permissions')}
            >
              <div>
                <PermissionsIcon />
              </div>
              <span>Users & Permissions</span>
            </Link>
          </li>
      </ul>
    );
    return (
      <ul className="list-unstyled global-tools-links">
        <li className={`${getActiveClassFor('add-new-facility')}`}>
            <Link
              to={addFacilityIdQueryParameter('/admin-settings/add-new-facility')}
            >
              <div>
                <AddFacility />
              </div>
              <span>Add New Facility</span>
            </Link>
          </li>
          {/* Unused links hidden*/}
          {/* <li>
            <a href="# ">
              <div>
                <WhitelistIcon />
              </div>
              <span>External Whitelist Editor</span>
            </a>
          </li> */}
          <li className={`${getActiveClassFor('organization-user-management')}`}>
            <GlobalLink
              to={addFacilityIdQueryParameter(
                '/admin-settings/organization-user-management'
              )}
              isDisabled={false}
              disabledTitle="Organization & User Mgmt"
            >
              <div>
                <OrganizationIcon />
              </div>
              <span>Organization & User Mgmt</span>
            </GlobalLink>
          </li>
          <li className={`${getActiveClassFor('remit-management')}`}>
            <GlobalLink
              to={addFacilityIdQueryParameter(`/admin-settings/remit-management`)}
              isDisabled={false}
              disabledTitle="Remit Management"
            >
              <div>
                <IconRemit />
              </div>
              <span>Remit Management</span>
            </GlobalLink>
          </li>
          <li className={`${getActiveClassFor('token-guid-lookup')}`}>
            <Link
              to={addFacilityIdQueryParameter(
                '/admin-settings/token-guid-lookup'
              )}
            >
              <div>
                <TokenLookUpIcon />
              </div>
              <span>Token User GUID Look Up</span>
            </Link>
          </li>
          <li className={`${getActiveClassFor('transaction-info')}`}>
            <Link
              to={addFacilityIdQueryParameter('/admin-settings/transaction-info')}
            >
              <div>
                <TransactionIcon />
              </div>
              <span>Transaction Info</span>
            </Link>
          </li>
          <li className={`${getActiveClassFor('users-and-permissions')}`}>
            <Link
              to={addFacilityIdQueryParameter('/admin-settings/users-and-permissions')}
            >
              <div>
                <PermissionsIcon />
              </div>
              <span>Users & Permissions</span>
            </Link>
          </li>
          <li className={`${getActiveClassFor('transaction-lookup')}`}>
            <Link
              to={addFacilityIdQueryParameter('/admin-settings/transaction-lookup')}
            >
              <div>
                <WebServiceIcon />
              </div>
              <span>Webservice Error Logging</span>
            </Link>
          </li>
          {/* Unused links hidden*/}
          {/* <li className={`${getActiveClassFor('webService-error')}`}>
            <Link
              to={addFacilityIdQueryParameter('/admin-settings/webService-error')}
            >
              <div>
                <ErrorIcon />
              </div>
              <span>Webservice Error</span>
            </Link>
          </li> */}
        {renderSecondaryMenu()}
      </ul>
    );
  }

  function addFacilityIdQueryParameter(url: string) {
    if (!facilityId) return url;
    const query = new URLSearchParams();
    query.set('previousFacilityId', facilityId);
    return `${url}?${query}`;
  }

  return (
    <>
      <h2 className="links-title">Global Tools</h2>
      {showSettingLinks(sspUserPermission)}
    </>
  );
}

GlobalTools.defaultProps = {
  isFacilityDisabled: false,
};

function Chevron(props: { open: boolean }) {
  const { open } = props;
  return open ? <FaChevronUp /> : <FaChevronDown />;
}

function shouldShowEpic(isEpicToolsOpen: boolean, pathname: string) {
  return isEpicToolsOpen || pathname.includes("epic");
}