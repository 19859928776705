import { EnumPlanStatus } from 'models/enums/EnumPaymentPlan';

export const userOptions = [
  { value: '', optionName: '' },
  { value: 'fakeUserId1', optionName: 'John Smith' },
  { value: 'fakeUserId2', optionName: 'FakeFirstName FakeLastName' },
];

export const planStatusOptions = [
  ...Object.values(EnumPlanStatus)
    .filter(key => key !== 'None')
    .map(option => ({ value: option as string, optionName: option === EnumPlanStatus.Stopped ? 'Enterprise Stopped' : option as string })),
];

export const tenderTypes = ['eCheck', 'Credit/Debit'];
export const tenderTypesIds = [
  { id: 5, label: 'eCheck' },
  { id: 4, label: 'Credit/Debit' }
];