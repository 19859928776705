import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TabPane } from 'reactstrap';
import 'typeface-roboto';
import { useTypedSelector } from 'app/rootReducer';
import { AlertIds, AlertTypes, removeAlert, setAlert } from 'features/alert/AlertReducer';
import { errorMessage, hasMissingMandatoryFields } from '../searchUtils';
import { updateRecord } from './PaymentPlanSearchReducer';
import { SideFilters } from './SideFilters/SideFilters';
import { MainFilters } from './MainFilters/MainFilters';
import '@experian/eds-styles/dist/eds-all.css';
import styles from './planSearch.module.scss';
import OrganizationUserSearch, { getOrganizationOptions, useGetUserDepartmentsOptions } from '../simpleSearch/OrganizationUserSearch';
import { getUserDepartmentOrFacilityesOptions } from 'utils/UtilsOrganizationDropdowns';
import { useUserUtils } from 'utils/useUserUtils';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';

export default function PaymentPlanSearch(props:{filteredFacilities: any[]}) {
  const paymentPlanSearchInfo = useTypedSelector(s => s.paymentPlanSearchInfo?.value || []);
  const { useGetFacilities } = useOrganizations();
  const allFacilities = useGetFacilities();
  const { getLoggedUserOrganizations } = useUserUtils();
  const facilities = allFacilities;
  const userOrganisations = getLoggedUserOrganizations();
  const userFacilityOptions =  getUserDepartmentOrFacilityesOptions(facilities, userOrganisations)
  const facilityOptions = getOrganizationOptions( userFacilityOptions);
  const getUserDepartmentsOptions = useGetUserDepartmentsOptions();
  const departmentsOptions = getUserDepartmentsOptions(userOrganisations);
  const [paymentPlanSearchInitialized, setpaymentPlanSearchInitialized] = useState(false); 
  const paymentPlanSearchSaveSettingsChecked = useTypedSelector(s=> s.paymentPlanSearchInfo?.saveSettings ?? false);
  const dispatch = useDispatch();
  const alerts = useTypedSelector(s => s.alerts.alerts);
  const searchAlert = alerts.find(alert => alert.id === AlertIds.SearchAlert);

  useEffect(() => {
    if (searchAlert) {
      hasMissingMandatoryFields(mandatoryFields)
        ? dispatch(setAlert({
          id: AlertIds.SearchAlert,
          type: AlertTypes.Warning,
          message: errorMessage(hasMissingMandatoryFields(mandatoryFields)),
          dismissable: true
        }))
        : closeErrorMessage();
    }
  }, [
    paymentPlanSearchInfo.facilities,
    paymentPlanSearchInfo.tenderType,
  ]);

  useEffect(()=>{
    if (departmentsOptions?.length > 0 && !paymentPlanSearchSaveSettingsChecked && !paymentPlanSearchInitialized) {
      const currentPaymentPlanState = {...paymentPlanSearchInfo, facilities: facilityOptions, deparments: departmentsOptions};
      dispatch(updateRecord(currentPaymentPlanState));
      setpaymentPlanSearchInitialized(true);
    }
  }, [departmentsOptions?.length]);

  const mandatoryFields: { [key: string]: any } = {
    'Facility': !!paymentPlanSearchInfo.facilities?.length,
    'Department': !!paymentPlanSearchInfo.departments?.length,
    'Tender type': !!paymentPlanSearchInfo.tenderType?.find(tender => tender.isChecked),
  };

  const closeErrorMessage = () => {
    dispatch(removeAlert({ id: AlertIds.SearchAlert }));
  };

  return (
    <TabPane className="tab-pane-content" tabId="3">
      <div className={`container`}>
        <OrganizationUserSearch
          preselected={true}
          facilities={props.filteredFacilities}
          state={paymentPlanSearchInfo}
          setState={(organizationUserState) => { dispatch(updateRecord({ ...paymentPlanSearchInfo, ...organizationUserState })) }}
        />
        <div style={{ marginTop: '20px' }} className={`row`}>
          <div className="col-md-4">
            <SideFilters
              tenderTypes={paymentPlanSearchInfo.tenderType}
            />
          </div>
          <div className={[`col`, styles.mainFiltersContainer].join(' ')}>
            <MainFilters state={paymentPlanSearchInfo} />
          </div>
        </div>
      </div>
    </TabPane>
  );
}
