import { EDS_TextBox, EDS_Radio } from '@EH/eh.eds.react';
import { ECheckModel } from 'features/paymentInfo/checkScanner/ECheckModel';
import { TenderInfo } from 'models/PaymentPlan';
import { checkReceivedHasValues, nameof } from 'utils/Utils';
import { CheckScannerModal } from '../paymentInfo/checkScanner/CheckScannerModal';
import { ReactComponent as VisibilityOn } from 'assets/svgs/paymentPlanIcons/icon-eye-open.svg';
import { ReactComponent as VisibilityOff } from 'assets/svgs/paymentPlanIcons/icon-eye-closed.svg';
import { useState } from 'react';
import 'assets/styles/components/_paymentPlan.scss';

export function TenderECheckCart(props: {
  isPlan?: boolean;
  source: any;
  popoverElement?: JSX.Element;
  formatLabel: (name: keyof TenderInfo, label: string) => string;
  errorClass: (name: keyof TenderInfo) => '' | 'invalid-field';
  showInputError: (name: keyof TenderInfo) => string | undefined;
  onBlur: (name: keyof TenderInfo) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, name: keyof TenderInfo) => void;
  onScanHandler: (eCheckModel: ECheckModel) => void;
  organizationPath?: string;
}) {
  const {
    isPlan,
    source,
    popoverElement,
    formatLabel,
    errorClass,
    showInputError,
    onBlur,
    handleChange,
    onScanHandler,
    organizationPath
  } = props;

  const showConfirmField = checkReceivedHasValues(isPlan, !source?.isPaperCheck, !source.wasECheckScannerApplied);

  const [isVisiblityOn, setIsVisibilityOn] = useState(false);
  const [focused, setFocused] = useState<keyof TenderInfo | undefined>();
  return (
    <>
      <div className="flex-row">

      <div className={'eds-field row-item row-item-size'}>
          <label
            className={`eds-field ${errorClass('firstName')}`}
            htmlFor={'firstName'}
          >
            <div className={'eds-field_#label'}>
            {formatLabel(
              'firstName',
              '*First Name'
            )}
            </div>
            <div
              className={`eds-field_#control eds-field_.eds-input eds-input ${errorClass(
                'firstName'
              )}`}
            >
              <input
                className={`eds-input_#input`}
                id={'firstName'}
                type={'text'}
                name={nameof<TenderInfo>('firstName')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'firstName')}
                onBlur={() => onBlur('firstName')}
                value={source?.firstName}
                autoComplete={'off'}
              />
            </div>
          </label>
        </div>

        <div className={'eds-field row-item row-item-size'}>
          <label className={`eds-field ${errorClass('lastName')}`} htmlFor={'lastName'} >
            <div className={'eds-field_#label'}>{formatLabel('lastName','*Last Name')}</div>
              <div className={`eds-field_#control eds-field_.eds-input eds-input ${errorClass( 'lastName')}`}>
                <input
                  className={`eds-input_#input`}
                  id={'lastName'}
                  type={'text'}
                  name={nameof<TenderInfo>('lastName')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'lastName')}
                  onBlur={() => onBlur('lastName')}
                  value={source?.lastName}
                  autoComplete={'off'}
                />
            </div>
          </label>
        </div>

      </div>
      <div className="flex-row">
        <div className="full-row">
          <div className="tender-echeck-popover-wrapper">
            <span className="tender-echeck-popover-element">{popoverElement}</span>
            <span className={"visibility-button"} onClick={() => setIsVisibilityOn(!isVisiblityOn)}>
              {isVisiblityOn ? <VisibilityOn /> : <VisibilityOff />}
            </span>
          </div>
          <EDS_TextBox
            name={nameof<TenderInfo>('routingNumber')}
            label={formatLabel('routingNumber', '*Routing Number')}
            type={'text'}
            value={source?.routingNumber || ''}
            modifiers={`row-item row-item-size-double ${errorClass(
              'routingNumber'
            )}
            ${!isVisiblityOn && focused != 'routingNumber' ? "visiblityOff" : ''}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'routingNumber')}
            onFocus={() => { setFocused('routingNumber') }}
            onBlur={() => { setFocused(undefined); onBlur('routingNumber') }}
          />
          <span className={`${errorClass('routingNumber') ? 'error-message-required' : ''}`}>
            {showInputError('routingNumber')}
          </span>
        </div>
      </div>

      {showConfirmField && <div className="flex-row">
        <div className="full-row">
          <div className="row-item row-item-size-double">
            <span className="tender-echeck-popover-element">{popoverElement}</span>
          </div>
          <EDS_TextBox
            name={nameof<TenderInfo>('confirmRoutingNumber')}
            label={formatLabel(
              'confirmRoutingNumber',
              '*Confirm Routing Number'
            )}
            type={'text'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'confirmRoutingNumber')}
            value={source?.confirmRoutingNumber || ''}
            modifiers={`row-item row-item-size-double ${errorClass(
              'confirmRoutingNumber'
            )}
            ${!isVisiblityOn && focused != 'confirmRoutingNumber' ? "visiblityOff" : ''}`}
            onFocus={() => setFocused('confirmRoutingNumber')}
            onBlur={() => { setFocused(undefined); onBlur('confirmRoutingNumber') }}
          />
          <span className={`${checkReceivedHasValues(errorClass('confirmRoutingNumber'), 'error-message-required', '')}`}>
            {showInputError('confirmRoutingNumber')}
          </span>
        </div>
      </div>}

      <div className="flex-row">
        <div className="full-row">
          <EDS_TextBox
            name={nameof<TenderInfo>('financialInstitution')}
            label={formatLabel(
              'financialInstitution',
              'Financial Institution'
            )}
            type={'text'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'financialInstitution')}
            value={source?.financialInstitution || ''}
            modifiers={`row-item row-item-size-double ${errorClass(
              'financialInstitution'
            )}`}
            onBlur={() => onBlur('financialInstitution')}
          />
          <span className={`${checkReceivedHasValues(errorClass('financialInstitution'), 'error-message-required', '')}`}>
            {showInputError('financialInstitution')}
          </span>
        </div>
      </div>
      <div className="flex-row">
        <div className="full-row">
          <div className="tender-echeck-popover-wrapper">
            <span className="tender-echeck-popover-element">{popoverElement}</span>
          </div>
          <EDS_TextBox
            name={nameof<TenderInfo>('accountNumber')}
            label={formatLabel('accountNumber', '*Account Number')}
            type={'text'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'accountNumber')}
            value={ source?.accountNumber }
            modifiers={`row-item row-item-size-double ${errorClass(
              'accountNumber'
            )}
            ${!isVisiblityOn && focused != 'accountNumber' ? "visiblityOff" : ''}`}
            onFocus={() => setFocused('accountNumber')}
            onBlur={() => { setFocused(undefined); onBlur('accountNumber') }}
          />
          <span className={`${checkReceivedHasValues(errorClass('accountNumber'), 'error-message-required', '')}`}>
            {showInputError('accountNumber')}
          </span>
        </div>
      </div>
      {showConfirmField && <div className="flex-row">
        <div className="full-row">
          <div className="tender-echeck-popover-wrapper">
            <span className="tender-echeck-popover-element">{popoverElement}</span>
          </div>
          <EDS_TextBox
            name={nameof<TenderInfo>('confirmAccountNumber')}
            label={formatLabel(
              'confirmAccountNumber',
              '*Confirm Account Number'
            )}
            type={'text'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'confirmAccountNumber')}
            value={source?.confirmAccountNumber || ''}
            modifiers={`row-item row-item-size-double ${errorClass(
              'confirmAccountNumber'
            )}
            ${checkReceivedHasValues(!isVisiblityOn && focused != 'confirmAccountNumber', "visiblityOff", '')}`}
            onFocus={() => setFocused('confirmAccountNumber')}
            onBlur={() => { setFocused(undefined); onBlur('confirmAccountNumber') }}
          />
          <span className={`${checkReceivedHasValues(errorClass('confirmAccountNumber'), 'error-message-required', '')}`}>
            {showInputError('confirmAccountNumber')}
          </span>
        </div>
      </div>}

      <div className="flex-row">
        <div className="row-item row-item-size-double">
          <EDS_Radio
            name={nameof<TenderInfo>('accountType')}
            value={'checking'}
            label={'Checking'}
            modifiers={'mr-2'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'accountType')}
            checked={source?.accountType === 'checking'}
          />
          <EDS_Radio
            name={nameof<TenderInfo>('accountType')}
            value={'savings'}
            label={'Savings'}
            modifiers={'ml-2'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'accountType')}
            checked={source?.accountType === 'savings'}
          />
        </div>
      </div>
      <div className="flex-row">
        <div className="row-item row-item-size-double d-flex align-items-end">
          <CheckScannerModal onScanSucces={onScanHandler} organizationPath={organizationPath} />
        </div>
      </div>
    </>
  )
}