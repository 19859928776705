export enum PaymentPanelType {
  Payment,
  GlPayment,
  PaymentPlan,
  PatientSearch,
  EcnPayment
}

export enum PaymentPanelDataType
{
  Text = 1,
  Date,
  Checkbox,
  Integer,
  Decimal
}

export enum DistributionOrder
{
  None = 1,
  Equal,
  New,
  Newest,
  Oldest,
  OldestNoPlanOrBadDebt,
  Percent,
  LowPayment,
  HighPayment,
  Plans,
  BadDebt,
  Custom1,
  Custom2,
  Custom3,
  Custom4,
  Custom5,
  Custom6,
  Custom7,
  Custom8,
  Custom9,
  Custom10,
}

export enum PaymentPanelFieldType
{
  CheckBox = "CheckBox",
  CheckImage = "CheckImage",
  DropDown = "DropDown",
  Hidden = "Hidden",
  TextInput = "TextInput",
  Label = "Label",
  Calendar = "Calendar"
}
