import { AnyAction, combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import PermissionsComponentReducer from 'components/permissions/PermissionsComponentReducer';
import ExampleStore from 'features/example/ExampleStore';
import BreadcrumbReducer from 'features/breadcrumb/BreadcrumbReducer';
import PatientMailingInformationReducer from 'features/paymentInfo/PatientMailingInformationReducer';
import PaymentDashboardReducer from 'features/paymentDashboard/PaymentDashboardReducer';
import PaymentPlanReducer from 'features/paymentPlan/PaymentPlanReducer';
import PatientInfoReducer from 'features/patientInfo/PatientInfoReducer';
import Patients from 'features/patients/PatientsReducer';
import LoginReducer from 'pages/Loginpage/reducers/LoginPageReducer';
import SearchUserReducer from 'features/searchUser/SearchUserReducer';
import GlobalPermissionsReducer from 'features/globalPermissions/GlobalPermissionsReducer';
import GLPaymentDashboardReducer from 'pages/GLPaymentDashboard/GLPaymentDashboardReducer';
import MerchantConfigReducer from 'features/merchantConfig/MerchantConfigReducer';
import SimpleSearchReducer from 'pages/Search/simpleSearch/SimpleSearchReducer';
import AdvanceSearchReducer from 'pages/Search/advanceSearch/AdvanceSearchReducer';
import PaymentPlanSearchReducer from 'pages/Search/planSearch/PaymentPlanSearchReducer';
import TransactionDetailsReducer from 'features/transactionDetails/TransactionDetailsReducer';
import CurrentTransactionStatusReducer from 'features/transactions/CurrentTransactionStatusReducer';
import CustomSearchColumnsReducer from 'features/customizeSearchResultColumns/customizeSearchColumnsReducer';
import ClientMetadataReducer from 'features/metadata/client/ClientMetadataReducer';
import SidepaneReducer from 'features/sidepane/SidepaneReducer';
import OrganizationsReducer from 'features/organizations/OrganizationsReducer';
import TransactionInfoReducer from 'features/admin/TransactionInfoReducer';
import TokenGUIDLookupReducer from 'features/admin/tokenGUIDLookup/TokenGUIDLookupReducer';
import ClientReducer from 'features/admin/ClientReducer';
import WebServiceErrorReducer from 'features/admin/WebServiceErrorReducer';
import UserInfoReducer from 'features/admin/UserInfoReducer';
import PatientAdminInfoReducer from 'features/admin/PatientAdminInfoReducer';
import GetClientConfigurationReducer from 'features/admin/GetClientConfigurationReducer';
import CreditCardConfigReducer from 'features/admin/creditCardConfig/CreditCardConfigReducer';
import userTenderSettingsReducer from 'features/singlePayment/userTenderSettings/userTenderSettingsReducer';
import LogoManagerReducer from 'features/admin/logoManager/LogoManagerReducer';
import DeviceManagerReducer from 'features/admin/deviceManager/DeviceManagerReducer';
import AlertReducer from '../features/alert/AlertReducer';
import TokenInfoReducer from 'pages/Loginpage/reducers/TokenInfoReducer';
import RemitScheduleReducer from 'features/admin/remitService/RemitScheduleReducer';
import RemitRecreationReducer from 'features/admin/remitService/RemitRecreationReducer';
import ServicesReducer from 'services/ServicesReducer';
import RemitConfigurationReducer from '../features/admin/remitConfiguration/RemitConfigurationReducer';
import PaymentPanelReducer from '../features/admin/paymentPanel/PaymentPanelReducer';
import UsersAndPermissionsReducer from '../features/admin/UsersAndPermissions/UsersAndPermissionsReducer';
import ReceiptManagerReducer from '../features/admin/receiptManager/ReceiptManagerReducer';
import WhiteListReducer from '../features/admin/epic/whitelist/WhitelistReducer';

export const history = createBrowserHistory();

const appReducer = combineReducers({
  example: ExampleStore,
  alerts: AlertReducer,
  breadcrumb: BreadcrumbReducer,
  patientMailingInformation: PatientMailingInformationReducer,
  paymentDashboard: PaymentDashboardReducer,
  paymentInfo: PaymentDashboardReducer,
  paymentPlanInfo: PaymentPlanReducer,
  patientInfo: PatientInfoReducer,
  patients: Patients,
  loginInfo: LoginReducer,
  permissions: PermissionsComponentReducer,
  router: connectRouter(history),
  searchUser: SearchUserReducer,
  globalPermissions: GlobalPermissionsReducer,
  glPaymentDashboard: GLPaymentDashboardReducer,
  merchantConfig: MerchantConfigReducer,
  simpleSearchInfo: SimpleSearchReducer,
  advanceSearchInfo: AdvanceSearchReducer,
  paymentPlanSearchInfo: PaymentPlanSearchReducer,
  transactionDetails: TransactionDetailsReducer,
  currentTransactionStatus: CurrentTransactionStatusReducer,
  customSearchColumns: CustomSearchColumnsReducer,
  clientMetaData: ClientMetadataReducer,
  sidepane: SidepaneReducer,
  organizations: OrganizationsReducer,
  transactionInfo: TransactionInfoReducer,
  tokenGuidLookup: TokenGUIDLookupReducer,
  client: ClientReducer,
  webServiceError: WebServiceErrorReducer,
  userInfo: UserInfoReducer,
  patientAdminInfo: PatientAdminInfoReducer,
  creditCardConfig: CreditCardConfigReducer,
  userTenderSetting: userTenderSettingsReducer,
  logoManager: LogoManagerReducer,
  getClientFacilityConfiguration: GetClientConfigurationReducer,
  tokenInfo: TokenInfoReducer,
  deviceManager: DeviceManagerReducer,
  services: ServicesReducer,
  remitSchedule: RemitScheduleReducer,
  remitRecreationConfiguration: RemitRecreationReducer,
  remitConfiguration: RemitConfigurationReducer,
  paymentPanel: PaymentPanelReducer,
  usersAndPermissions: UsersAndPermissionsReducer,
  receiptManager: ReceiptManagerReducer,
  whitelistManager: WhiteListReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  return appReducer(state, action);
};

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
