import { useTypedSelector } from "../app/rootReducer";
import PaymentPlanDetailsLink from "../features/paymentPlan/PaymentPlanDetailsLink";
import { useSetOpenedTransaction } from "../features/searchTransaction/hooks/useSetOpenedTransaction";

export const PlanLinkRenderer = (params: { value: string; data?: { id: any } }) => {
  const { data } = params;
  const id = data?.id;
  return <PaymentPlanDetailsLink id={id} />
}


export const LinkRenderer = (params: { value: string; data?: { id: any } }) => {
  const { data } = params;
  const id = data?.id;
  const setOpenedTransaction = useSetOpenedTransaction();
  const openPlanId = useTypedSelector(s => s.paymentPlanInfo.openedPlanId);
  const openTransaction = useTypedSelector(s => s.transactionDetails.isOpenModal);
  const isModalOpen = openTransaction || openPlanId;
  return <a className={'link-transaction-id'} href={`/paymentDetails/${data?.id}`} target={"_blank"} rel="noreferrer" onClick={(e: any) => {
    if (!isModalOpen) {
      e.preventDefault();
      setOpenedTransaction(id);
    }
  }}>{id ?? ''}</a>;
}

export const cardBrand = "cardBrand";
export const popup = "popup";