import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import { PaymentPlanModel } from "../../../models";
import { LineItem } from "../../../models/LineItem";
import { CreatePaymentPlanResult, CreatePaymentPlanService, PatchPaymentPlanService } from "../../../services/PaymentPlanService";
import { createPatch, Operation } from 'rfc6902';
import { addBackup, changeSelected, updateRecord } from "../PaymentPlanReducer";
import { updateRecord as updatePatientRecord } from "features/patients/PatientsReducer"
import { useGetPatientById } from "../../patients/hooks/useGetPatientById";
import { ConvertPaymentPlanDates } from "../ConvertPaymentPlanDates";
import { PatientModel } from "models/PatientModel";
import { getServerLineItems } from "./useGetServerLineItems";
import { getTenderType } from '../PlanUtils';
import { ApiTenderTypeEnum } from 'utils/Utils';
import { EnumTransactionTenderType } from "models/enums/EnumTransactionTenderType";
import { CreditCardAdditionalData } from "models/PaymentPlan";
import { useCallService } from "../../../services/useCallService";
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { FullOrganizationLevelDocument } from "models/OrganizationLevelDocument";
import { mapPatientMailingInformationToPlan } from "../useOnCreatePlan";

interface PaymentPlanData {
  paymentPlan?: PaymentPlanModel, 
  lineItems?: LineItem[],  
  total?: number, 
  onSuccess?: () => void, 
  onError?: (message?:string) => void, 
  patientId?: string, 
  selectedOrganizationPath?: string,
  isMailingAddressRequired?: boolean
}

interface PaymentPlanToMap {
  paymentPlan?: PaymentPlanModel, 
  lineItems?: LineItem[], 
  patientId?: string, 
  patient?: PatientModel, 
  mergeLineItems: boolean, 
  facility?: FullOrganizationLevelDocument, 
  usePatientMailingInformation?: boolean, 
  isMailingAddressRequired?: boolean
}

export function useCreateOrUpdatePaymentPlan(
  paymentPlanData: PaymentPlanData
) {
  const { paymentPlan, lineItems, patientId, onSuccess, onError, isMailingAddressRequired, selectedOrganizationPath } = paymentPlanData;
  const dispatch = useDispatch();
  const planBackup = useTypedSelector(s => paymentPlan?.id && s.paymentPlanInfo.backups ? s.paymentPlanInfo.backups[paymentPlan?.id] : undefined);
  const callService = useCallService();
  const { getFacilityByDepartment } = useOrganizations();
  const facility = getFacilityByDepartment(paymentPlan?.organization?.path);

  const { patient } = useGetPatientById(patientId);
  const { data, active } = useMapPaymentPlan({paymentPlan, lineItems, patientId, patient, mergeLineItems: true, facility, usePatientMailingInformation: true, isMailingAddressRequired});
  const backup = useMapPaymentPlan({paymentPlan: planBackup, lineItems, patientId, patient, mergeLineItems: false, facility, usePatientMailingInformation: false, isMailingAddressRequired});  
  
  const createPaymentPlan = async () => {
    if (!paymentPlan || !patientId || (!paymentPlan.isGl && !patient)) {
      onError && onError();
      return;
    }
    callService("createOrUpdatePaymentPlan", async () => {
      try {
        let response: CreatePaymentPlanResult | null = null;
        if (paymentPlan.id && backup.data && data) {
          let original = { ...backup.data };
          let patch = createPatch(original, {
            ...data,
            authorized: original.authorized,
            numberOfPayments: original.numberOfPayments
          });
          let operations = patch.filter(o =>
            !o.path.toLocaleLowerCase().startsWith('/tenderadditionaldata')
          );

          if (operations.length < patch.length) {
            operations.push(
              createTenderAdditionalDataOperation(data.tenderAdditionalData)
            );
          }
          response = await PatchPaymentPlanService(paymentPlan.id, operations);
        } else {
          if (data) {
            let createPlanData = { ...data };
            delete createPlanData.tokenId;
            delete createPlanData.tender;
            response = await CreatePaymentPlanService(createPlanData);
          }
          if (response && !response?.err && response?.result?.id) {
            if (paymentPlan.id === null) {
              dispatch(changeSelected({ id: paymentPlan.id, isSelected: false }));
              dispatch(changeSelected({ id: response.result.id, isSelected: true }));
            }
          }
        }

        if (response && !response?.err) {
          const plan: PaymentPlanModel = {
            ...paymentPlan,
            lineItems: [...(paymentPlan.lineItems ?? []), ...(active ?? [])],
            ...response.result
          };

          dispatch(updateRecord({
            id: paymentPlan.id,
            paymentPlan: plan
          }));
          dispatch(addBackup(plan));
          if (patient) {
            dispatch(updatePatientRecord({
              id: patientId,
              patient: {
                ...patient,
                plans: { organizationPath : Array.from(new Set<string | null>([...(patient?.plans?.[selectedOrganizationPath ?? ''] || []), plan.id])) }               
              }
            }));
          }
          return onSuccess && onSuccess();
        }else{
          onError && onError(response?.errorResponse?.data?.validationErrors?.[0]?.errorMessage);
        }
      } catch (e) {
        console.log(e);
      }
      onError && onError();
    });
  };

  return { createPaymentPlan }
}

export function useLineItems(paymentPlan?: PaymentPlanModel, lineItems?: LineItem[], mergeLineItems?: boolean) {
  let combined: LineItem[] = [];
  let activeIdxs: number[] = [];
  let active: LineItem[] = [];

  if (paymentPlan || lineItems) {
    lineItems?.filter(l => l.isActive && !l.isEditing).forEach((l, idx) => {
      active.push({ ...l, isActive: false, isEditing: false, isContracted: false });
      activeIdxs.push(idx);
    })
    combined = mergeLineItems ? [...paymentPlan?.lineItems as LineItem[] || [], ...active] : [...paymentPlan?.lineItems as LineItem[] || []];
  }
  return {
    lineItems: combined.map(l => { return { ...l, amount: paymentPlan?.isGl ? (+(l.glUnits || 0) * +(l.unitCost || 0)) : ((l.balance ?? l.amount) || 0) }; }),
    activeIdxs,
    active
  };
}

// Create TenderAdditionalData operation with next format
// { op: 'replace', patch: '/tenderAdditionalData' value: { propertyName: value, propertyName1: value1, ...}}
// This is nessesary because PATCH operation with next format is not in BE for Dictionary objects: 
// { op: [add|replace]', patch: '/tenderAdditionalData/[property name]' value: [some value]}
function createTenderAdditionalDataOperation(tenderAdditionalData: any) {

  let keyValueMap:{[key: string]: any} = {};
  Object.entries(tenderAdditionalData).forEach(([key, value]) => {
    keyValueMap[key] = value
  });

  const operation: Operation = {
    op: 'replace',
    path: '/tenderAdditionalData',
    value: keyValueMap
  }
  return operation;
}

function getTenderAdditionalData(paymentPlan?: PaymentPlanModel, patient?: PatientModel) {

  if (paymentPlan?.tender?.type == ApiTenderTypeEnum.SavedOnFile && patient?.paymentsOnFile) {
    const paymentOnFile = patient.paymentsOnFile.find(
      p => p.tokenId == paymentPlan?.tender?.method
    );
    
    if (paymentOnFile?.tenderType == EnumTransactionTenderType.Card) {
      return {
        CardType: paymentOnFile?.cardBrand ?? '',
        ExpirationDate: paymentOnFile?.cardExpirationDate ?? '',
      };
    }
    
    if (paymentOnFile?.tenderType == EnumTransactionTenderType.eCheck) {
      return {
        RoutingNumber: paymentOnFile?.routingNumber ?? '',
        FinancialInstitution: paymentOnFile?.financialInstitution ?? ''
      };
    }
  }
  
  if (paymentPlan?.tender?.type == ApiTenderTypeEnum.CardManual && paymentPlan?.tenderAdditionalData) {
    return {
      CardType: (paymentPlan.tenderAdditionalData as CreditCardAdditionalData).CardType,
      ExpirationDate: (paymentPlan.tenderAdditionalData as CreditCardAdditionalData).ExpirationDate
    };
  }

  return paymentPlan?.tenderAdditionalData;
}

function useMapPaymentPlan(paymentPlanToMap: PaymentPlanToMap) {
  const { paymentPlan, lineItems, patientId, patient, usePatientMailingInformation, facility, mergeLineItems, isMailingAddressRequired } = paymentPlanToMap;
  const { lineItems: combinedLineItems, activeIdxs, active } = useLineItems(paymentPlan, lineItems, mergeLineItems);
  const { getPaymentPlanWithUtcDates } = ConvertPaymentPlanDates();
  const paymentPlanUtcDates = getPaymentPlanWithUtcDates(paymentPlan);
  const patientMailingInformation = useTypedSelector(s => s.patientMailingInformation.value);

  const serverLineItems = getServerLineItems(combinedLineItems, patient?.id ?? patientId);
  const { getFacilityByDepartment } = useOrganizations();
  const organization = getFacilityByDepartment(paymentPlan?.organization?.path);
  if (!paymentPlanUtcDates) return {};

  const tenderType = getTenderType({
    ...paymentPlanUtcDates,
    walletId: (usePatientMailingInformation ? patientMailingInformation.paymentFileTokenId : paymentPlanUtcDates.walletId) ?? ""
  }, patient);

  const paymentPlanData = {
    data: paymentPlanUtcDates.id
    ? {
      patientId: patient?.id ?? patientId,
      id: paymentPlanUtcDates.id,
      isActive: paymentPlanUtcDates.isActive,
      pauseHistories: paymentPlanUtcDates.pauseHistories,
      amount: paymentPlanUtcDates.amount,
      walletId: paymentPlanUtcDates.walletId ?? paymentPlanUtcDates?.tender?.method,
      paymentAmount: paymentPlanUtcDates.paymentAmount,
      paymentsRemaining: paymentPlanUtcDates.paymentsRemaining,
      authorized: paymentPlanUtcDates.authorized,
      termType: paymentPlanUtcDates.termType,
      numberOfPayments: paymentPlanUtcDates.paymentsRemaining,
      lineItems: serverLineItems,
      tenderType,
      startDate: paymentPlanUtcDates.startDate,
      patientFirstName: patient?.firstName,
      patientLastName: patient?.lastName,
	    patientMrn: patient?.mrn,
      patientGuarantorAccountNo: patient?.guarantorAccountNo,
      patientDateOfBirth: patient?.dateOfBirth,
      organization: organization,
      department: paymentPlanUtcDates.organization,
      facility: facility,
      tenderMaskedAccount: paymentPlanUtcDates.tenderMaskedAccount,
      tenderAdditionalData: getTenderAdditionalData(paymentPlanUtcDates, patient),
      notes: paymentPlanUtcDates.notes,
      notificationEmail: paymentPlanUtcDates.notificationEmail,
      cardBrand: paymentPlanUtcDates.cardBrand,
      paymentSource: paymentPlanUtcDates.paymentSource,
      nextPaymentDate: paymentPlanUtcDates.nextPaymentDate,
      ...(usePatientMailingInformation && patientMailingInformation?.firstName ?
        mapPatientMailingInformationToPlan(patientMailingInformation) :
        {
          billingInformation: paymentPlanUtcDates.billingInformation,
          mailingInformation: paymentPlanUtcDates.mailingInformation,
        }),
    } 
    : {
      ...paymentPlanUtcDates,
      patientId: patient?.id ?? patientId,
      walletId: paymentPlanUtcDates?.tender?.method ?? paymentPlanUtcDates.walletId,
      termType: paymentPlanUtcDates.termType,
      numberOfPayments: paymentPlanUtcDates.paymentsRemaining,
      lineItems: serverLineItems,
      tenderType,
      startDate: paymentPlanUtcDates.startDate,
      patientFirstName: patient?.firstName,
      patientLastName: patient?.lastName,
	    patientMrn: patient?.mrn,
      patientGuarantorAccountNo:patient?.guarantorAccountNo,
      patientDateOfBirth:patient?.dateOfBirth,
      organization: organization,
      tenderAdditionalData: getTenderAdditionalData(paymentPlanUtcDates, patient),
      cardBrand: paymentPlanUtcDates.cardBrand,
      paymentSource: paymentPlanUtcDates.paymentSource,
      nextPaymentDate: paymentPlanUtcDates.nextPaymentDate,
      department: paymentPlanUtcDates.organization,
      facility: facility,
    },
    activeIdxs,
    active
  };

  if(!isMailingAddressRequired) {
    delete paymentPlanData.data.mailingInformation;
  }

  return {
    active: paymentPlanData.active,
    activeIdxs: paymentPlanData.activeIdxs,
    data: paymentPlanData.data
  };
}