import { useDispatch } from "react-redux";
import { getAllExternalAdresses } from "../../../../services/WhitelistService";
import { setExternalAddresses } from "./WhitelistReducer";

export function useGetExternalAddresses() {
  const dispatch = useDispatch();
  async function getExternalAdresses() {
    const response = await getAllExternalAdresses();
    if (response.result) {
      dispatch(setExternalAddresses(response.result.externalAddresses));
    }
  }

  return {
    getExternalAdresses
  }
}