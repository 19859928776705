import React, { useEffect, useMemo, useState } from "react";
import * as router from 'react-router-dom';
import { Popup } from "../../../../components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "models/enums/EnumPopupType";
import { EDS_Select, EDS_TextArea, EDS_Button } from '@EH/eh.eds.react';
import { RemitFileFieldDescription } from "models/RemitPreDefinedListsModel";
import { ApiTenderTypeEnum, arrayToSelectOptions, copyToClipboard, enumToOptions } from "utils/Utils";
import { EnumTermType } from "models/enums/EnumPaymentPlan";
import { callService } from "services/ServicesReducer";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../app/rootReducer";
import { ConditionalTemplate, getTemplates, parseTemplate } from "services/RemitTemplatesService";
import { EnumCardBrand } from "models/enums/EnumCardBrand";
import EnumTransactionType from "models/enums/EnumTransactionType";
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import { FullOrganizationLevelDocument } from "models/OrganizationLevelDocument";
import { getDevices } from "features/paymentInfo/PatientMailingInformationReducer";
import { Device } from "models/Device";
import { useGetPaymentConfiguration } from "features/singlePayment/hooks/useGetPaymentConfiguration";
import { GlDisplayField } from "models/PaymentsConfiguration";
import ActionConfirmModal from "features/admin/ActionConfirmModal";
import { AlertIds, AlertTypes, setAlert} from 'features/alert/AlertReducer';
import { ParseResponse, TemplateType } from "models/RemitTemplatesModel";
import { EnumPaymentOrigination } from "models/enums/EnumPaymentOrigination";

export function ScribanTemplateBuilderPopup(props: {
  isOpen:boolean,
  template: string,
  onSubmit: (template: string) => void,
  onClose: () => void,
  remitFileFieldDescriptions?: RemitFileFieldDescription[]
}) {
  const { isOpen, onClose, remitFileFieldDescriptions = [] } = props;
  const params = router.useParams<{ clientId: string }>();

  const dispatch = useDispatch();

  const defaultValue = {value: '', optionValue: ''};

  const [template, setTemplate] = useState<string>(props.template);
  const [field, setField] = useState<string>('');
  const [value, setValue] = useState<{value: string | number, optionValue: string}>(defaultValue);
  const [openCleanConfirmation, setOpenCleanConfirmation] = useState(false);
  const [parseErrors, setParseErrors] = useState<string[]>([]);

  const { organizations, getOrganizationById, getDepartments } = useOrganizations()
  const selectedFacility = getOrganizationById(params.clientId);
  const selectedFacilityPath = selectedFacility?.path || '';

  const { getConfiguration } = useGetPaymentConfiguration(selectedFacilityPath);
  const glDisplayFields = useTypedSelector(
    state => state.paymentDashboard.configurations[selectedFacilityPath]?.configuration?.glDisplayFields ?? []
  );
  const isProcessing = useTypedSelector(s => s.services.calls.parseScribanTemplate?.isProcessing);
  const devices = useTypedSelector(s => s.patientMailingInformation?.devices);

  const filters = {
    notEmpty: (data: any, propName: string) => data[propName],
    notEmptyClientId: (data: any) => data['clientId'],
  }

  function enumToSelectOptions<E>(enumType: E) {
    return enumToOptions(enumType, key => ({ optionName: key.toString(), value: key.toString() }));
  }

  function arrayToIndexSelectOptions<S>(array: S[], propName: keyof S, onFilter?: (data: any, propName: any) => boolean) {
    const filteredArray = array.filter(element => !onFilter || onFilter(element, propName));
    return filteredArray.map((element, index) => ({
      optionName: element[propName],
      value: index,
      actualValue: element[propName]
    }));
  }

  const onParseComplete = (response: ParseResponse) => {
    setParseErrors([]);

    if (response.err || !response.result?.template) {
      dispatch(
        setAlert({
          id: AlertIds.ScribanTemplateError,
          type: AlertTypes.Error,
          message: response.err || 'Empty template was received',
          dismissable: false,}
        )
      )
      return;
    }

    if (response.result?.errors?.length) {
      setParseErrors(response.result?.errors);
      return;
    }
    props.onSubmit(response.result.template);
  }

  const onSubmit = () => {
   dispatch(callService('parseScribanTemplate', async () => {
      const data = { template, type: TemplateType.Scriban }
      await parseTemplate(data).then(onParseComplete);
    }));
  }

  const mapFieldNameValueOptions: {[key: string]: any} = {
    'PaymentPlanTerms': () =>
      enumToSelectOptions(EnumTermType),
    'CardType': () =>
      enumToSelectOptions(EnumCardBrand),
    'TransactionType': () =>
      enumToSelectOptions(EnumTransactionType),
    'TenderTypeId': () =>
      enumToSelectOptions(ApiTenderTypeEnum),
    'PaymentOrigination': () =>
      enumToSelectOptions(EnumPaymentOrigination),
    'DepartmentName': () =>
      arrayToIndexSelectOptions<FullOrganizationLevelDocument>(getDepartments(), 'name'),
    'DepartmentID': () =>
      arrayToIndexSelectOptions<FullOrganizationLevelDocument>(getDepartments(), 'id'),
    'FacilityName': () =>
      arrayToIndexSelectOptions<FullOrganizationLevelDocument>(organizations, 'name', filters.notEmptyClientId),
    'FacilityId': () =>
      arrayToIndexSelectOptions<FullOrganizationLevelDocument>(organizations, 'clientId', filters.notEmpty),
    'DeviceName': () =>
      arrayToIndexSelectOptions<Device>(devices, 'friendlyName'),
    'DeviceSerialNumber': () =>
      arrayToIndexSelectOptions<Device>(devices, 'serialNumber'),
    'GL_Number': () =>
      arrayToIndexSelectOptions<GlDisplayField>(glDisplayFields,'glNumber'),
    'GL_Description': () =>
      arrayToIndexSelectOptions<GlDisplayField>(glDisplayFields,'description'),
  };

  const valuesOptions = useMemo(() => {
    let getOptions = mapFieldNameValueOptions[field];
    getOptions = getOptions && getOptions() || [];
    return [{optionName: 'Select...', value: ''}, ...getOptions]
  }, [field]);

  const dbFieldsOptions = useMemo(() => {
    return [{optionName: 'Select...', value: ''}, ...arrayToSelectOptions(remitFileFieldDescriptions, 'displayName', 'fieldName').sort((a, b) => a.optionName.localeCompare(b.optionName))];
  }, [remitFileFieldDescriptions]);

  useEffect(() => {
    if (isOpen) {
      setTemplate(props.template);
      setField('');
      setValue(defaultValue)
    }
  }, [isOpen]);

  const [templates, setTemplates] = useState<ConditionalTemplate[]>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();

  const getTemplatesHandler = async () => {
    const templatesResult = await getTemplates();
    setTemplates(templatesResult.result ?? []);
  }

  const templateOptions = templates?.map(t => ({ optionName: t.name, value: t.id })) ?? [];
  templateOptions.splice(0, 0, { optionName: "Please select a template", value: "" });

  useEffect(() => {
    getConfiguration();
    dispatch(getDevices(selectedFacilityPath));
    getTemplatesHandler();
  }, []);

  const ParseErrors = (
    parseErrors.length  ? <div>
      {parseErrors.map((error, idx) => {
        return <div className="template-error-message">{`${idx+1}: ${error}`}</div>
      })}
    </div> : null
  );

  const selectedTemplate = templates?.find(t => t.id === selectedTemplateId);

  const bodyContent = (
    <div className="eds-modal_#content condition-builder-body">
      <div className="body-header">CONDITIONAL VALUE BUILDER</div>
      <div>
        <EDS_Select
          name="template"
          label=""
          options={templateOptions}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedId = e.target.value;
            setSelectedTemplateId(selectedId);
            const templateSelected = templates?.find(t => t.id === selectedId);
            templateSelected?.formula && setTemplate(templateSelected.formula);
          }}
          value={selectedTemplateId}
          modifiers={"template-dropdown"}
        />
      </div>
      <div className="template">
       <div>
        <span>Formula:</span>
       </div>
       <div className={parseErrors.length ? "template-error" : ''}>
        <EDS_TextArea
          name="formula"
          modifiers={parseErrors.length ? 'textarea-border-red' : ''}
          heightModifier={160}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSelectedTemplateId("");
              setTemplate(e.target.value);
          }}
          value={template}
        />
        { ParseErrors }
        </div>
      </div>
      <div className="template-values">
        <div>
          <EDS_Select
            name="dbfield"
            label="DB Field:"
            options={dbFieldsOptions}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setField(e.target.value)
              setValue(defaultValue);
            }}
            value={field}
          />
        </div>
        <div>
          <EDS_Button
            name="copyDbField"
            buttonText={'Copy'}
            onClick={() => {
              copyToClipboard(field)
            }}
            disabled={dbFieldsOptions.length <= 1}
          />
        </div>
        <div>
          <EDS_Select
            name="value"
            label="Value:"
            options={valuesOptions}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const newValue = valuesOptions[1]?.actualValue
                ? valuesOptions[+e.target.value+1].actualValue
                : e.target.value
              ;
              setValue({ value: newValue, optionValue: e.target.value});
            }}
            value={value.optionValue}
          />
        </div>
        <div>
          <EDS_Button
            name="copyDbFieldValue"
            buttonText={'Copy'}
            onClick={() => {
              copyToClipboard(value.value)
            }}
            disabled={valuesOptions.length <= 1}
          />
        </div>
      </div>
    </div>
  );

  const footerButtons = [
    {
      type: EnumPopupButtonsType.clear,
      style: EnumPopupButtonsStyle.secondary,
      disabled: isProcessing || !template,
      text: 'Clear',
    },
    {
      type: EnumPopupButtonsType.submit,
      style: EnumPopupButtonsStyle.primary,
      disabled: isProcessing,
      text: 'Apply'
    },
  ];

  return <div className="scriban-template-bulder">
    { isOpen ? <Popup
      header="PaymentSafe®"
      fullWidth={true}
      customStyle='scriban-template-builder-container'
      children={bodyContent}
      footer={footerButtons}
      onClose={onClose}
      onClear={() => {
        setOpenCleanConfirmation(true)
      }}
      onSubmit={() => {
        template ? onSubmit() : props.onSubmit(template);
      }}
    /> : null }
    <ActionConfirmModal
      isOpen={openCleanConfirmation}
      actionTitle={'Clean template'}
      actionButtonText={'Confirm'}
      actionConfirmText={'Are you sure you want to clean this conditional template?'}
      onActionConfirm={() => {
        setSelectedTemplateId("");
        setTemplate('');
        setParseErrors([]);
        setOpenCleanConfirmation(false);
      }}
      onClose={() => {
        setOpenCleanConfirmation(false);
      }}
    />
   </div>
}