import { useDispatch } from "react-redux";
import { CreateEpicDepartmentMappings, DeleteEpicDepartmentMappings, GetEpicDepartmentMappings, UpdateEpicDepartmentMappings } from "../../services/EpicDepartmentMappingService"
import { AlertIds, AlertTypes, setAlert } from "../alert/AlertReducer";
import { EditableEpicDepartmentMapping } from "../../pages/Admin/Epic/EpicDepartmentMappingPage";
import { useEffect, useState } from "react";
import { useGenericRefStore } from "./epic/departmentMappings/useGenericRefStore";
import { genericMappingUtils } from "./epic/tokenMappings/useEpicTokenUserMappings";
import { OrganizationLevelDocument } from "../../models/OrganizationLevelDocument";
import { ServiceCallResponse } from "../../services/callHttpApi";
import { useOrganizations } from "../organizations/hooks/useOrganizations";

export function useEpicDepartmentMappings(
  filteredMappings: EditableEpicDepartmentMapping[],
  setFilteredMappings: (mappings: EditableEpicDepartmentMapping[]) => void,
  setFilter: (fliter: string) => void,
  selectedOrganization?: OrganizationLevelDocument,
) {
  const dispatch = useDispatch();

  const [mappings, setMappings] = useState<EditableEpicDepartmentMapping[]>([]);

  const { storeMapping, dataRef } = useGenericRefStore<EditableEpicDepartmentMapping>();

  const { stopEditting, storeMappings } = genericMappingUtils<EditableEpicDepartmentMapping>({
    dataRef,
    filteredMappings,
    setFilter,
    setFilteredMappings,
    setMappings
  });

  const { getFacilityTradingPartnerPath } = useOrganizations();
  const tradingPartnerPath = getFacilityTradingPartnerPath(selectedOrganization?.path);
  useEffect(() => {
    tradingPartnerPath && getMappings(tradingPartnerPath)
  }, [tradingPartnerPath]);


  const getMappings = async (path?: string) => {
    const response = await GetEpicDepartmentMappings(path);
    if (response.result) {

      storeMappings(response.result);
    }
  }

  const deleteMappings = async (selectedMappings: string[], mappings: EditableEpicDepartmentMapping[], path?: string) => {
    const mappingsToDelete : string[] = mappings.filter(m => m.id && !m.metadata?.tempId).map(m => selectedMappings.find(x => x == m.id)).filter(m => m !== undefined) as string[];
    const response = (mappingsToDelete.length > 0) && await DeleteEpicDepartmentMappings(mappingsToDelete);
    if ((response && response?.result) || mappingsToDelete.length === 0) {
      dispatch(setAlert({ id: AlertIds.EpicDepartmentMappingsUpdate, type: AlertTypes.Success, message: 'Epic department mappings deleted', dismissable: true }));

      const response = await GetEpicDepartmentMappings(path);
      if (response.result) {
        const unselectedTempMappings = mappings.filter(m => m.metadata?.tempId && !selectedMappings.includes(m.metadata?.tempId ?? ""));
        
        storeMappings([]);        
        window.setTimeout(() => storeMappings(unselectedTempMappings.concat(response.result ?? [])), 0)
      }
    } else {
      dispatch(setAlert({ id: AlertIds.EpicDepartmentMappingsUpdate, type: AlertTypes.Error, message: 'Could not delete mappings', dismissable: true }));
    }
  }

  const saveMappings = async (mappings: EditableEpicDepartmentMapping[], path?: string) => {
    const updateSucceeded = await handleUpdateRequest({
      requestCall: UpdateEpicDepartmentMappings,
      dispatch,
      mappings: mappings.filter(m => m.id && !m.metadata?.tempId),
      stopEditting
    });
    const createSucceeded = await createMappings(mappings.filter(m => !m.id || m.metadata?.tempId));

    if (updateSucceeded && createSucceeded) {
      const response = await GetEpicDepartmentMappings(path);
      if (response.result) {
        storeMappings([]);
        window.setTimeout(() => storeMappings(response.result ?? []), 0)
      }
    }
  }

  const createMappings = async (mappings: EditableEpicDepartmentMapping[]) => {
    if (mappings.length) {
      const response = await CreateEpicDepartmentMappings(mappings);
      if (response.result) {
        dispatch(setAlert({ id: AlertIds.EpicDepartmentMappingsCreate, type: AlertTypes.Success, message: 'Epic department mappings created', dismissable: true }));
        stopEditting(mappings.map(m => m.metadata?.tempId ?? ""))
      } else {
        dispatch(setAlert({ id: AlertIds.EpicDepartmentMappingsCreate, type: AlertTypes.Error, message: 'Could not create mappings', dismissable: true }));
        return false;
      }
    }

    return true;
  }

  return { mappings, getMappings, saveMappings, deleteMappings, dataRef, storeMapping }
}

export async function handleUpdateRequest<T extends {id?: string}>(props: {
  requestCall: (mappings?: T[]) => Promise<ServiceCallResponse<T[]>>,
  mappings: T[],
  dispatch: (params: any) => void,
  stopEditting: (ids: string[]) => void,
  successMessage?: string
  }
) {
  const { requestCall, mappings, dispatch, stopEditting, successMessage } = props;
  if (mappings.length) {
    const response = await requestCall(mappings);
    if (response.result) {
      dispatch(setAlert({ id: AlertIds.EpicDepartmentMappingsUpdate, type: AlertTypes.Success, message: successMessage ?? 'Epic department mappings updated', dismissable: true }));
      stopEditting(mappings.map(m => m.id ?? ""))
    } else {
      dispatch(setAlert({ id: AlertIds.EpicDepartmentMappingsUpdate, type: AlertTypes.Error, message: 'Could not update mappings', dismissable: true }));
      return false;
    }
  }

  return true;
}