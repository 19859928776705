import { Checkbox, Input, Radio } from "@EHDS/core";
import React, { useState }  from "react";
import { PaymentSourceEnum, PredefinedDateFormats, RemitConfiguration, SettledStatusEnum, TransactionTypesEnum, TransactionTypesEnumLabels } from "../../../../models/admin/RemitConfiguration";
import useGeneralSettingValidator, { allowedExtensions } from "./useGeneralSettingValidator";
import { EDS_Select } from '@EH/eh.eds.react';
import { OrganizationLevelDocument } from "../../../../models/OrganizationLevelDocument";
import * as router from 'react-router-dom';
import { enumToMultiSelectOptions, enumToTenderTypeMultSelectOptions, sortAscByLabel, TenderTypeEnum, TenderTypeLabels, valueOrDefault } from "../../../../utils/Utils";
import MultiSelect, { MultiSelectOption } from "../../../../components/select/MultiSelect";
import { getOrganizationOptions, useGetFacilitesByTradingPartnerOptions } from "../../../../pages/Search/simpleSearch/OrganizationUserSearch";
import { useOrganizations } from "../../../organizations/hooks/useOrganizations";
import { RemitFileFieldDescription } from "models/RemitPreDefinedListsModel";
import { RowFilterPopUp } from "./rowFilter/RowFilterPopUp";
import { EnumCardBrand } from "models/enums/EnumCardBrand";
import { PopoverElement } from "../../../../pages/Admin/OrganizationUserManagement/OrganizationUserManagement";
import { Autocomplete } from "@material-ui/lab";
import { Paper, TextField } from '@material-ui/core';

export const getLabel = (organization: OrganizationLevelDocument) => `(${organization.clientId}) ${organization.name}`;
export default function GeneralFileSettingsForm(props: {
  configuration: RemitConfiguration,
  onChange: (configuration: RemitConfiguration) => void,
  client?: OrganizationLevelDocument,
  fileFieldDescriptions?: RemitFileFieldDescription[]
}) {
  const { configuration, onChange, fileFieldDescriptions } = props;
  const { getDepartments } = useOrganizations()
  function onChangeConfig(name: keyof RemitConfiguration, value: any) {
    validate(attributes.find(a => a.name == name), value, true);
    onChange({ ...configuration, [name]: value });
  }

  function selectAll(propertyName: any) {
    switch(propertyName) {
      case "paymentSources":
        if(configuration.paymentSources?.length == paymentSourceOptions.length) {
          onChangeConfig("paymentSources", []);
        } else {
          onChangeConfig("paymentSources", paymentSourceOptions);
        }     
        break;
      case "cardFilter":
        if(configuration.cardFilter?.length == cardBrandOptions.length) {
          onChangeConfig("cardFilter", []);
        } else {
          onChangeConfig("cardFilter", cardBrandOptions);
        }     
        break;
      case "transactionFilter":
        if(configuration.transactionFilter?.length == transactionTypeOptions.length) {
          onChangeConfig("transactionFilter", []);
        } else {
          onChangeConfig("transactionFilter", transactionTypeOptions);
        }     
        break;
      case "tenderFilter":
        if(configuration.tenderFilter?.length == tenderTypeOptions.length) {
          onChangeConfig("tenderFilter", []);
        } else {
          onChangeConfig("tenderFilter", tenderTypeOptions);
        }
    }
  }

  const [isRowFilterPopupOpen, setIsRowFilterPopupOpen] = useState(false);

  function onBlur(name: keyof RemitConfiguration, value: any) {
    validate(attributes.find(a => a.name == name), value);
  }

  function fileFieldDescriptionsToSelectOptions(remitFileFieldDescriptions?: RemitFileFieldDescription[]) {
    return remitFileFieldDescriptions?.map(remitFileFieldDescription => {
      return {
        optionName: remitFileFieldDescription.displayName,
        value: remitFileFieldDescription.fieldName,
      }
    }).sort((a, b) => a.optionName.localeCompare(b.optionName)) ?? [];
  }

  const { attributes, validate, getErrorMessage, errorClass } = useGeneralSettingValidator(configuration);
  const departments = getFilteredDepartments(configuration.clientFilter);
  const departmentOptions = getOrganizationOptions(departments);
  const tenderTypeOptions = enumToTenderTypeMultSelectOptions(TenderTypeEnum, TenderTypeLabels);
  const transactionTypeOptions = enumToMultiSelectOptions(TransactionTypesEnum, TransactionTypesEnumLabels);
  const cardBrandOptions = enumToMultiSelectOptions(EnumCardBrand);
  const paymentSourceOptions = enumToMultiSelectOptions(PaymentSourceEnum);
  const dateFormatOptions: MultiSelectOption[] = PredefinedDateFormats.map((dateFormat) => {
    return {
      label: dateFormat,
      value: dateFormat
    };
  });

  function buildValueProps<T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(name: keyof RemitConfiguration) {
    return {
      value: configuration[name]?.toString(),
      onChange: (e: React.ChangeEvent<T>) => onChangeConfig(name, e.target.value),
      onBlur: () => onBlur(name, configuration[name])
    }
  }

  function getFilteredDepartments(selectedClientFilters?: string[]) {
      return getDepartments((d) => {
        return !!selectedClientFilters?.find(facility =>
              facility && d.path.includes(facility));
      }) ?? [];
  }

  function buildCheckboxProps<T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(name: keyof RemitConfiguration) {
    return {
      checked: !!configuration[name],
      onChange: (e: React.ChangeEvent<T>) => onChangeConfig(name, !configuration[name]),
    }
  }

  const getAutocompleteOptionSelected = (option: MultiSelectOption, selected: MultiSelectOption) => {
    const value = valueOrDefault(selected.value, selected);
    return option.value == value;
  }

  const getAutocompleteOptionLabel = (option: MultiSelectOption) => (
    valueOrDefault(option.label, option)
  )

  function getMultiSelectValues ( allOptions: MultiSelectOption[], filteredOptions: MultiSelectOption[] | undefined ): MultiSelectOption[] {
   return filteredOptions?.map((o) => {
      const option = allOptions.find(c => o.value == c.value);
      let optionLabel = "";
      let optionValue = "";
      if(option) {
        optionLabel = option.label;
        optionValue = option.value;
      }

      return {
          label: optionLabel,
          value: optionValue
        }
    }).filter(x => x.value) ?? [];
  }

  let { clientId } = router.useParams<{ clientId: string }>();
  const { getOrganizationById } = useOrganizations();
  const selectedOrganization = getOrganizationById(clientId);
  const facilityOptions = useGetFacilitesByTradingPartnerOptions(getLabel, selectedOrganization);

  return <div className="row row-container remitSettings">
    {isRowFilterPopupOpen ? <RowFilterPopUp
      onClose={() => { setIsRowFilterPopupOpen(false) }}
      onChange={(value: string) => onChangeConfig("sqlRowFilter", value)}
    /> : null}
    <div className="col-lg-8">
      <div className="row">
        <div className="col-lg-4">
          <Input
            label="File Identifier:"
            placeholder={"Enter name"}
            className={'rowItem rowItemSize eds4'}
            {...buildValueProps("fileIdentifier")}
          />
        </div>
        <div className="col-lg-4">
          <Input
            label="Path:"
            placeholder={"Enter path"}
            className={'rowItem rowItemSize eds4'}
            {...buildValueProps("filePath")}
          />
        </div>
        <div className="col-lg-4">
          <div
            className={'rowItem rowItemSize eds4 input-with-tooltip'}>
            <Input
              label="File Name:"
              placeholder={"Enter file name"}
              {...buildValueProps("fileName")}
            />
            <PopoverElement>{`Allowed types: ${allowedExtensions.join(', ')}`}</PopoverElement>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <span className={`${errorClass && errorClass("fileIdentifier")}`}>
            {getErrorMessage && getErrorMessage("fileIdentifier")}
          </span>
        </div>
        <div className="col-lg-4">
        </div>
        <div className="col-lg-4">
          <span className={`${errorClass && errorClass("fileName")}`}>
            {getErrorMessage && getErrorMessage("fileName")}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 autocomplete-field">
        <label>
          <span className="actocomplate-label">Date Format:</span>
          <Autocomplete
            openOnFocus
            id={'dateFormat'}
            disableClearable
            options={dateFormatOptions}
            getOptionSelected={getAutocompleteOptionSelected}
            value={configuration?.dateFormat}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={'Enter Format'}
              />
            )}
            getOptionLabel={getAutocompleteOptionLabel}
            onInputChange={(_, newInputValue) => {
              onChangeConfig("dateFormat", newInputValue)
            }}
            PaperComponent={paperProps => (
              <Paper
                elevation={8}
                {...paperProps}
                className="customAutoComplete"
                style={{width: "560px"}}
              />
            )}
            freeSolo={true}
            forcePopupIcon={true}
          />
          </label>
        </div>
        <div className="col-lg-4">
          <MultiSelect
            label="Department Filter:"
            name={"departmentFilter"}
            options={departmentOptions}
            multiple={true}
            values={configuration.departmentFilter?.map((o) => {
              return {
                label: departmentOptions.find(f => o == f.value)?.label ?? "",
                value: o
              }
            }).filter(x => x.value) ?? []}
            onChange={ selected => { onChangeConfig("departmentFilter", selected.map((s) => s.value)) }}
            remit={true}
            isSortingOptions={true}
          />
        </div>
        <div className="col-lg-4">
          <MultiSelect
            label="Transaction Filter:"
            name={"transactionFilter"}
            options={sortAscByLabel(transactionTypeOptions)}
            multiple={true}
            searchCheckbox={true}
            button={'Select All'}
            allSelected={transactionTypeOptions.length == configuration.transactionFilter?.length}
            selectAll={(propertyName: string) => selectAll(propertyName)}
            values={ getMultiSelectValues(transactionTypeOptions, configuration?.transactionFilter) }
            onChange={ selected => { onChangeConfig("transactionFilter", selected) }}
            remit={true}
            isSortingOptions={true}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <MultiSelect
            label="Facility Filter:"
            name={"clientFilter"}
            options={facilityOptions}
            multiple={true}
            values={configuration.clientFilter?.map((o) => {
              return {
                label: facilityOptions.find(f => o == f.value)?.label ?? "",
                value: o
              }
            }).filter(x => x.value) ?? []}
            onChange={ selected => { onChangeConfig("clientFilter", selected.map((s) => s.value)) }}
            remit={true}
            isSortingOptions={true}
          />
        </div>

        <div className="col-lg-4">
          <MultiSelect
            label="Card Filter:"
            name={"cardFilter"}
            options={sortAscByLabel(cardBrandOptions)}
            multiple={true}
            searchCheckbox={true}
            button={'Select All'}
            selectAll={(propertyName: string) => selectAll(propertyName)}
            allSelected={cardBrandOptions.length == configuration.cardFilter?.length}
            values={configuration.cardFilter?.map((o) => {
              return {
                label: o.label,
                value: cardBrandOptions.find(c => o.label == c.label)?.value ?? ""
              }
            }).filter(x => x.value) ?? []}
            onChange={ selected => { onChangeConfig("cardFilter", selected) }}
            remit={true}
            isSortingOptions={true}
          />
        </div>

        <div className="col-lg-4">
          <MultiSelect
            label="Payment source filter:"
            name={"paymentSources"}
            options={sortAscByLabel(paymentSourceOptions)}
            multiple={true}
            searchCheckbox={true}
            button={'Select All'}
            selectAll={(propertyName: string) => selectAll(propertyName)}
            allSelected={paymentSourceOptions.length == configuration.paymentSources?.length}
            values={configuration.paymentSources?.map((o) => {
              return {
                label: o.label,
                value: paymentSourceOptions.find(c => o.label == c.label)?.value ?? ""
              }
            }).filter(x => x.value) ?? []}
            onChange={ selected => { onChangeConfig("paymentSources", selected) }}
            remit={true}
            isSortingOptions={true}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <MultiSelect
            label="Tender Filter:"
            name={"tenderFilter"}
            options={sortAscByLabel(tenderTypeOptions)}
            multiple={true}
            searchCheckbox={true}
            button={'Select All'}
            selectAll={(propertyName: string) => selectAll(propertyName)}
            allSelected={tenderTypeOptions.length == configuration.tenderFilter?.length}
            values={ getMultiSelectValues(tenderTypeOptions, configuration?.tenderFilter) }
            onChange={ selectedOptions => onChangeConfig("tenderFilter", selectedOptions) }
            remit={true}
            isSortingOptions={true}
          />
        </div>
        <div className="col-lg-4">
          <Input
            label="Row Filter:"
            placeholder={"Enter row filter"}
            className={'rowItem rowItemSize eds4'}
            value={configuration.sqlRowFilter}
            onFocus={(e) => {
              e.target.blur();
              setIsRowFilterPopupOpen(true);
            }}
          />
        </div>
        <div className="col-lg-4">
          <Checkbox label="IsTest" {...buildCheckboxProps("isTest")} />
        </div>
      </div>
    </div>


    <div className="col-lg-4">
      <div className="row">
        <div className="eds-field_#label">Formating Options:</div>
      </div>
      <div className="row">
        <Checkbox label="Create without records" {...buildCheckboxProps("createWithoutRecords")} />
      </div>
      <div className="row">
        <Checkbox label="Fixed width file" {...buildCheckboxProps("fixWidthFile")} />
      </div>
      <div className="row">
        <Checkbox label="Is 835" {...buildCheckboxProps("is835")} />
      </div>
      <div className="row" style={{width: "280px"}}>
        <EDS_Select
          label="Sort Column:"
          placeholder={"Select sort column"}
          modifiers={'rowItem rowItemSize eds4'}
          options={fileFieldDescriptionsToSelectOptions(fileFieldDescriptions)}
          {...buildValueProps("sortString")}
        />
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Radio
            label={"Ascending"}
            value={"false"}
            checked={configuration.orderDesc == false }
            onChange={(e) => onChangeConfig("orderDesc", false)}
          />
        </div>
        <div className="col-lg-6">
          <Radio
            label={"Descending"}
            value={"true"}
            checked={configuration.orderDesc == true}
            onChange={(e) => onChangeConfig("orderDesc", true)}
          />
        </div>
      </div>
      <div className="row">
        <div className="eds-field_#label">Transaction:</div>
      </div>
      <div className="row radio-group">
        <Radio
          label={"All"}
          value={SettledStatusEnum.All.toString()}
          checked={configuration.settledStatus ==  SettledStatusEnum[SettledStatusEnum.All]}
          onChange={(e) => onChangeConfig("settledStatus", SettledStatusEnum[parseInt(e.target.value)])}
        />
        <Radio
          label={"Settled"}
          value={SettledStatusEnum.Setled.toString()}
          checked={configuration.settledStatus ==  SettledStatusEnum[SettledStatusEnum.Setled]}
          onChange={(e) => onChangeConfig("settledStatus", SettledStatusEnum[parseInt(e.target.value)])}
        />
        <Radio
          label={"Non - settled"}
          value={SettledStatusEnum.NonSetled.toString()}
          checked={configuration.settledStatus ==  SettledStatusEnum[SettledStatusEnum.NonSetled]}
          onChange={(e) => onChangeConfig("settledStatus", SettledStatusEnum[parseInt(e.target.value)])}
        />
      </div>
    </div>
  </div>
}