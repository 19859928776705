import { Dispatch, SetStateAction, useState } from 'react';
import { voidTransaction } from 'services/TransactionsService';
import { TransactionDetailsModel } from '../../../models/TransactionDetailsModel';
import { isSuccessedTransaction } from 'utils/Utils';
import { getTransactionDetails } from 'features/transactionDetails/TransactionDetailsReducer';
import { useDispatch } from 'react-redux';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { useCallService } from '../../../services/useCallService';
import { FullOrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { ServiceCallResponse } from 'services/callHttpApi';

export function useVoidPayment(transaction?: TransactionDetailsModel, onSuccess?: () => void, onError?: (message?: string) => void) {
  const dispatch = useDispatch()
  const callService = useCallService();
  const [voidId, setVoidId] = useState<string>('');
  const [voidTransactionRes, setVoidTransactionRes] = useState<TransactionDetailsModel>();
  const { getFacilityByDepartment: usetFacilityByDepartment, getOrganizationById: useOrganizationById } = useOrganizations();


  const organization = useOrganizationById(transaction?.organization?.id)
  const facility = usetFacilityByDepartment(transaction?.department?.path);

  const buildPayload = (transaction: TransactionDetailsModel, organization:FullOrganizationLevelDocument, facility:FullOrganizationLevelDocument) => {
    return {
      id: transaction?.id,
      paymentClientId: organization.clientId || facility.clientId,
      referenceNumber: transaction?.gatewayReferenceNumber!,
      releaseFundsImmediately: true,
      notes: transaction.notes,
    };
  };
  
  const isValidPayload = (payload: any) => {
    return payload.id && payload.paymentClientId && payload.referenceNumber;
  };
  
  const handleResponse = (response: ServiceCallResponse<TransactionDetailsModel>, onSuccess: (() => void) | undefined, onError: ((message?: string) => void) | undefined  , setVoidId: Dispatch<SetStateAction<string>> , setVoidTransactionRes: Dispatch<SetStateAction<TransactionDetailsModel | undefined>>) => {
    const succededTransaction = isSuccessedTransaction(response.result?.statusType);
  
    if (response.err || response.errorResponse || !succededTransaction) {
      onError && onError(
        response.result?.responseMessage || response.errorResponse?.data?.title || response.err
      );
    } else {
      onSuccess && onSuccess();
      if (response.result) {
        setVoidId(response.result.id)
        setVoidTransactionRes(response.result);
      }
    }
  };
  
  const voidPayment = async () => {
    if (!transaction || !organization || !facility) {    
      onError && onError();
      return;
    }
  
    callService("voidTransaction", async () => {
      const payload = buildPayload(transaction, organization, facility);
  
      if (!isValidPayload(payload)) {
        onError && onError("One or more required payload fields are either undefined or empty.");
        return;
      }
  
      const response = await voidTransaction(payload);
      dispatch(getTransactionDetails(transaction.id))
  
      handleResponse(response, onSuccess, onError, setVoidId, setVoidTransactionRes);
    });
  };

  return { voidPayment, voidId, voidTransactionRes }
}