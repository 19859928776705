export enum CreditCardBrand {
  NotSet = 'NotSet',
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  AmericanExpress = 'American Express',
  Discover = 'Discover',
  DinerSClub = "Diner's Club",
  Jcb = 'JCB',
  UnionPay = 'UnionPay',
  Unknown = "Unknown"
}
