import { ClientFacilityConfiguration } from "../../../../models/Client";
import { GenericAttribute } from "../../../../models/metaData/MetaData";
import { DataType } from "../../../../models/metaData/MetaDataEnums";
import useErrorStore from "../../../../utils/useErrorStore";
import useTypedValidator from "../../../../utils/useTypedValidator";
import { required } from "../../../paymentDashboard/Validators";

export const useIntegrationSettingsValidator = (visibility: boolean) => {
  const attributes: GenericAttribute<ClientFacilityConfiguration>[] = [
    { name: "userName", label: "Epic Payment Page Username", dataType: DataType.String, validators: [required()] },
    { name: "password", label: "Epic Payment Page Password", dataType: visibility ? DataType.String : DataType.Password, validators: [required()] },
  ];
  const { addError, removeError, errorsRef, errors, getErrorMessageAsString } = useErrorStore();

  const typedSelectorProps = useTypedValidator({
    attributes,
    errors,
    removeError,
    addError,
    dispatchable: false,
  }
  );

  return {
    ...typedSelectorProps,
    getErrorMessage: (key: string) => {
      return typedSelectorProps.getErrorMessage(key, errors);
    },
    errorClass: (key: string) => {
      return typedSelectorProps.errorClass(key, errors);
    },
    errorsRef,
    getErrorMessageAsString,
  }
}