import { AuthorizationConfirmationPrint } from 'features/paymentPlan/AuthorizationConfirmationPrint'
import { ReactComponent as Close } from 'assets/svgs/icon-popup-close.svg';
import "../../assets/styles/components/_commonAuthorizationConfirmation.scss"


const CommonAuthorizationConfirmation = (props:any) => {
  const {openClass, close, paragraphs, onNext, printing, componentRef, content, openPrintPreview } = props;
  
  return (
    <div>
      <section className={`eds-modal ${openClass}`}>
        <div className="eds-modal_#container authorizationContainer">
          <header className="eds-modal_#header authorizationHeader">
          <div
            className="eds-modal_.title authorizationHeaderContainer">
            <div className="authorizationHeaderText">
              <span>PaymentSafe®</span>              
            </div>
            <div className="text-right authorizationHeaderCloseButton" onClick={close}>
              <Close />
            </div>
          </div>           
          </header>
          <div className="eds-modal_#content">
            <div className="authorizationModalContainer">
              <div className="conten-container">
                <div className="text-center">
                  <i
                    className="material-icons eds-button_#icon"
                    style={{
                      fontSize: 50,
                      display: 'inline',
                      color: '#426DA9',
                    }}
                  >
                    verified_user
                  </i>
                </div>
                <div className="content">{paragraphs}</div>
              </div>
              <div className="flexRow bottomBorder"></div>
              <div className="flexRow alignItemsRight">
                <div className="btnItem" onClick={openPrintPreview}>
                  <button className="eds-button eds-button.tertiary">
                    <div>
                    <i className="material-icons eds-button_#icon" style={{position:'relative', top:'4px'}}>print</i>
                    <span className="eds-button_#label">Print</span>
                    </div>
                  </button>
                </div>
                <div className="btnItem" onClick={close}>
                  <button className="eds-button eds-button.basic">
                    <span className="eds-button_#label">Decline</span>
                  </button>
                </div>
                <div className="btnItem">
                  <button
                    className="eds-button eds-button.primary"
                    onClick={onNext}
                  >
                    <span className="eds-button_#label">Authorize</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style={{ display: printing ? 'block' : 'none' }}>
        <AuthorizationConfirmationPrint
          style={{ display: printing ? 'none' : 'block' }}
          ref={componentRef}
          text={content}
        />
      </div>
    </div>
  )
}

export default CommonAuthorizationConfirmation