import { ServerPaymentModel } from 'models/PaymentModel';
import { callHttpApi } from 'services/callHttpApi';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import { CreateTransactionResult } from './models/CreateTransactionResult';
import MethodOnFileRequest from './models/MethodOnFileRequest';
import { EnumTransactionCommandType } from 'models/enums';

export async function CreatePaymentMethodOnFileTransaction(
  payment: ServerPaymentModel
): Promise<CreateTransactionResult> {
  return callHttpApi<TransactionDetailsModel, MethodOnFileRequest>(
    'post',
    'api/v1/Transactions/token',
    mapPaymenttMethodOnFileRequest(payment)
  );
}

function mapPaymenttMethodOnFileRequest(
  payment: ServerPaymentModel
): MethodOnFileRequest {
  return {
    guarantorId: payment?.guarantorId,
    organization: {
      id: payment?.organization?.id || '',
      name: payment?.organization?.name || '',
      path: payment?.organization?.path || '',
    },
    department: payment?.department,
    facility: payment?.facility,
    billingInformation: payment?.billingInformation,
    mailingInformation: payment?.mailingInformation,
    paymentSource: payment?.paymentSource,
    lineItems: payment.isGL ? payment.glDetails : payment.details,
    patientId: payment?.patientId,
    patientFirstName: payment?.patientFirstName,
    patientLastName: payment?.patientLastName,
    patientDateOfBirth: payment?.patientDateOfBirth,
    patientAccountNumber: payment?.patientAccountNumber,
    mrn: payment?.mrn,
    paymentPlanId: payment?.paymentPlanId,
    amount: payment?.paymentTotal,
    isGL: payment?.isGL,
    discount: payment?.discount,
    episode: payment?.episode,
    transactionCommand: EnumTransactionCommandType.Sale,
    notes: payment?.notes,
    isPaymentMethodReusable: payment?.isReusable,
    notificationEmail: payment?.notificationEmail,
    tokenId: payment?.paymentFileTokenId,
    TransactionGroupID: payment.transactionGroupId,
  }
}
