import React from 'react';
import 'bootstrap/scss/bootstrap.scss';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import 'assets/styles/components/_searchUser.scss';
import style from './searchBox.module.scss';
import { EDS_MenuItem } from '@EH/eh.eds.react';

function SearchResult(props: {
  searchResult?: string[];
  onClickItem?: (item: string) => void;
}) {
  const { searchResult, onClickItem } = props;
  if (!searchResult || searchResult.length < 1) return <></>;
  return (
    <div
      className="eds-popover eds-popover.reduced-padding eds-popover.no-margin" // eslint-disable-next-line 
      role="popover"
      style={{ display: 'block' }}
    >
      <div className={'eds-popover_#content'}>
        {searchResult?.map((s: string) => (
          <EDS_MenuItem
            key={s}
            menuItemText={s}
            modifiers={`${style.autocompleteItem}`}
            onClick={() => onClickItem && onClickItem(s)}
          />
        ))}
      </div>
    </div>
  );
}

export default (props: {
  value?: string;
  onChange?: (text: string) => void;
  onClick?: () => void;
  placeholder?: string;
  searchResult?: string[];
  onSelected?: (item: string) => void;
  [x: string]: any;
}) => {
  const {
    value,
    onChange,
    onClick,
    placeholder,
    className,
    searchResult,
    onSelected,
    ...rest
  } = props;

  const onChangeHandler = !onChange
    ? undefined
    : (event: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = event.target.value === '' ? '' : event.target.value;
        onChange(targetValue);
      };

  return (
    <div className={`${style.searchBox} ${className || ''}`} {...rest}>
      <div className={`input-group`}>
        <input
          data-testid={`searchBox`}
          type="text"
          className="form-control"
          placeholder={placeholder}
          value={value}
          style={{ borderRight: 'None' }}
          onChange={onChangeHandler}
        />
        <div className="input-group-append">
          <span
            data-testid={`searchIcon`}
            className="input-group-text transparent-appended-search-icon"
            onClick={onClick}
          >
            <i className="material-icons eds-link">search</i>
          </span>
        </div>
      </div>
      <SearchResult searchResult={searchResult} onClickItem={onSelected} />
    </div>
  );
};
