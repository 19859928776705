import { useTypedSelector } from 'app/rootReducer';
import { MultiSelectOption } from 'components/select/MultiSelect';
import { GlDisplayField } from 'models/PaymentsConfiguration';
import {
  addFilter,
  removeFilter,
  setAdvanceSearchInformation,
} from 'pages/Search/advanceSearch/AdvanceSearchReducer';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getPaymentConfigurations } from 'services/TransactionsService';
import { removeDuplicates } from 'utils/Utils';
import { CheckBoxNode } from '../../../components/checkBoxTree/CheckBoxTree';

export function useChangeHandlers() {
  const dispatch = useDispatch();
  const state = useTypedSelector(p => p.advanceSearchInfo?.value || {});
  const filters = useTypedSelector(p => p.advanceSearchInfo?.filters || {});

  return useCallback(() => {
    const setState = (patch: { [key: string]: any }) => {
      dispatch(
        setAdvanceSearchInformation({
          ...state,
          ...patch,
        })
      );
    };

    const setFilter = (
      name: string,
      value: any,
      displayName?: string,
      displayValue?: any
    ) => {
      if (Array.isArray(value) && value.length === 0) {
        dispatch(removeFilter(name));
      } else if (value) {
        dispatch(
          addFilter({
            name,
            filter: {
              displayName,
              value,
              displayValue,
            },
          })
        );
      } else {
        dispatch(removeFilter(name));
      }

      const newState = {} as any;
      newState[name] = value;
      setState(newState);
    };

    const handleChangeTextBox = (
      event: React.ChangeEvent<HTMLInputElement>,
      displayName?: string,
      allowWhiteSpace: boolean = false
    ) => {
      let propertyName = event.target.name;
      let value = event.target.value;
      if (!allowWhiteSpace) {
        value = value.trim();
      }
      setFilter(propertyName, value, displayName);
    };

    const setAdvancedSearchInfoState = (propertyName:any, value:string) => {
      setFilter(propertyName, value);
    }

    const handleChangeCheckBoxGroup = (
      checked: boolean,
      node: CheckBoxNode
    ) => {
      const displayValue = node.label || node.propertyName;

      const groupArray = (state as any)[node.alternateName as string] || [];
      const currentState: string[] = [...groupArray];

      const currentFilterState =
        (filters as any)[node.alternateName as string]?.displayValue || [];
      const currentFilter: string[] = [...currentFilterState];

      if (checked) {
        setFilter(
          node.alternateName as string,
          [...currentState, node.propertyName],
          node.alternateDisplayName,
          [...currentFilter, displayValue]
        );

        if (node.children && node.children.length > 0) {
          setFilter(
            node.children[0].alternateName as string,
            node.children.map(c => c.propertyName),
            node.children[0].alternateDisplayName,
            node.children.map(c => c.label)
          );
        }
      } else {
        currentState.splice(currentState.indexOf(node.propertyName), 1);
        currentFilter.splice(currentFilter.indexOf(displayValue), 1);
        setFilter(
          node.alternateName as string,
          currentState,
          node.alternateDisplayName,
          currentFilter
        );

        if (node.children && node.children.length > 0) {
          setFilter(
            node.children[0].alternateName as string,
            [],
            node.children[0].alternateDisplayName,
            []
          );
        }
      }
    };

    const handleChangeDropDown = (
      event: React.ChangeEvent<HTMLSelectElement>,
      displayName?: string
    ) => {
      let selectElement = event.target;
      let propertyName = selectElement.name;
      let optionIndex = selectElement.selectedIndex;
      let selectedOption = selectElement.options[optionIndex];
      setFilter(
        propertyName,
        selectedOption.value === ''
          ? undefined
          : { value: selectedOption.value, optionName: selectedOption.text },
        displayName,
        selectedOption.text
      );
    };

    const handleChangeDateRange = (
      date: string | undefined,
      name?: string,
      displayName?: string
    ) => {
      if (name) {
        setFilter(name, date, displayName);
      }
    };

    const ChangeMultiSelectOpion = (values?: MultiSelectOption[],propertyName?:string) => {
      let selectedValues= values?.map((options)=>{return options.value}).join(",");
      setFilter(
        propertyName??'',
        values,
        propertyName,
        selectedValues
      );
    };

    return {
      state,
      setState,
      setFilter,
      handleChangeTextBox,
      setAdvancedSearchInfoState,
      handleChangeCheckBoxGroup,
      handleChangeDropDown,
      handleChangeDateRange,
      ChangeMultiSelectOpion
    };
  }, [dispatch, filters, state])();
}

export async function GetGlDescriptions(organizationPath: string[]) {
  const response = await getPaymentConfigurations(organizationPath);
  const paymentConfigurations = (response.result ?? []).filter( c => c.glDisplayFields?.length);

  let glDisplayFields: GlDisplayField[] = [];
  for (let paymentConfiguration of paymentConfigurations) {
    glDisplayFields.push(...paymentConfiguration.glDisplayFields ?? []);
  }

  glDisplayFields = removeDuplicates(glDisplayFields, 'description');

  let glDescriptionOptions = [{ value: '', optionName: '' }];
  for (let glDisplayField of glDisplayFields) {
      glDescriptionOptions.push(getGlDisplayFieldOption(glDisplayField))
  }
  glDescriptionOptions = glDescriptionOptions.sort(sortByOptionName);

  return glDescriptionOptions;
}

function sortByOptionName(x: {value: string, optionName: string}, y: {value: string, optionName: string}) {
  const nameX = x.optionName.toUpperCase();
  const nameY = y.optionName.toUpperCase();
  if (nameX < nameY) {
    return -1;
  }
  if (nameX > nameY) {
    return 1;
  }
  return 0;
}

function getGlDisplayFieldOption(field: GlDisplayField) {
  return {
    value: field.description ?? '',
    optionName: field.description ?? '',
  };
}