import { LogoManager } from 'models/LogoManagerModel';
import { callHttpApi } from './callHttpApi';
import { FileWithPath } from 'react-dropzone';

export interface LogoManagerResponse {
  result?: LogoManager[];
  err?: string;
}

export async function GetLogoDataForOrganization(
  OrganizationPath: string
): Promise<LogoManagerResponse> {
  const params = { OrganizationPath };
  return callHttpApi<LogoManager[]>(
    'get',
    `/api/v1/Organizations/Configuration/Logos`,
    undefined,
    params
  );
}

export async function UploadLogoForOrganization(
  configurationId: string,
  file: FileWithPath
): Promise<LogoManagerResponse> {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  let formData = new FormData();
  formData.append('file', file);

  return callHttpApi<LogoManager[], FormData>(
    'post',
    `/api/v1/Organizations/Configuration/${configurationId}/Logos`,
    formData,
    undefined,
    headers
  );
}

export async function UpdateOrganizationLogo(
  configurationId: string,
  logoId: string
) {
  return callHttpApi(
    'put',
    `/api/v1/Organizations/Configuration/${configurationId}/Logos/${logoId}`
  );
}

export async function DeleteLogoForOrganization(
  configurationId: string,
  logoId: string
): Promise<LogoManagerResponse> {
  return callHttpApi<LogoManager[]>(
    'delete',
    `/api/v1/Organizations/Configuration/${configurationId}/Logos/${logoId}`
  );
}
