
export enum PredefinedDataType {
  Constant='Constant',
  Formatting='Formatting',
  ReplaceRegex='ReplaceRegex',
}

export interface RemitPredefinedData {
  id: string,
  type?: PredefinedDataType,
  value?: string | number,
}

export interface RemitPredefinedDataResponse {
  result?: RemitPredefinedData[];
  err?: string;
}