import { OrganizationReference } from "./PaymentPlan";
import { EnumTermType } from "./enums/EnumPaymentPlan";

export interface Tier {
  tId: number;
  floor: number;
  ceiling: number;
  calculateBy: string;
  minNumberOfPayments: number;
  maxNumberOfPayments: number;
  minPaymentAmount: number;    
  isEditing?: boolean,
  minNumberOfPaymentsIsEditable?: boolean,
  maxNumberOfPaymentsIsEditable?: boolean,
  minPaymentAmountIsEditable?: boolean
}

export interface Tiers {
  BillTiers: Tier[];
  GLTiers: Tier[];
}

export enum Terms {
  Weekly = 'Weekly',
  BiWeekly = 'Bi-Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annually = 'Annually'
}

export enum DistributionOrder
{
  OldestFirst = "Oldest First",
  NewestFirst = "Newest First",
  ProportionalSplit = "Proportional Split",
  SmallestBalanceFirst = "Smallest Balance First"
}

export enum PaymentPlanTiersCalculatedBy {
  NumberOfPayments = 'NumberOfPayments',
  PaymentAmount = 'PaymentAmount'
}

export interface Strings {
  AuthorizationText: string;
  GLDisplayFields: string[];
}

export interface Templates {
  PrintPage: string;
  CardAuthorizationText: string;
  GLCardAuthorizationText: string;
  CheckAuthorizationText: string;
  GLCheckAuthorizationText: string;
  PaymentAuthorizationText: string;
  PaymentsAuthorizationText: string;
  PaymentAuthorizationConfirmationText: string;
  PaymentsAuthorizationConfirmationText: string;
  PaymentsAuthorizationConfirmationTextForNotMonthlyTermTypes: string;
  PaymentsAuthorizationTextFotNotMonthlyTermTypes: string;
  PaymentNotificationEmailText: string;
  PaymentsAuthorizationTextForWeeklyAndBiWeeklyTermTypes: string;
}

export interface Toggles {
  UseOldestBillsFirstStrategyForLineItemsPayment?: boolean
}

export interface PlansRecord extends StopAllPlansRecord, DisablePlansRecord {}

export interface StopAllPlansRecord {
  notes?: string;
  stoppedBy?: string;
  stoppedOn?: string;
}

export interface DisablePlansRecord {
  notes?: string;
  disabledBy?: string;
  disabledOn?: string;
}

export interface PaymentPlanConfiguration {
  startDateMaxDaysOut: number;
  maximumPaymentRetries: number;
  maximumNumberOfPausesPerYear: number;
  tiers: Tiers;
  term: Terms;
  strings: Strings;
  toggles: Toggles;
  templates: Templates;
  stopAllPlansRecord?: StopAllPlansRecord;
  allowAddToPlan?: boolean;
  allowPlanDateChange?: boolean;
  autoPausedNotificationEmailAddress?: string;
  disablePlansRecord?: DisablePlansRecord;
  disableGLPlansRecord?: DisablePlansRecord;
  defaultPaymentPlanTerm?: string | EnumTermType;
  organization?: OrganizationReference;
  distributionOrder?: DistributionOrder;
  allowPAHSync?: boolean;
}
