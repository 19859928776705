import {
  getTransactionService,
  GetTransactionDetailsResult,
} from 'services/TransactionsService';
import { useCallback, useEffect, useRef } from 'react';
import { EnumTransactionStatusType } from 'models/enums';
import { sleep } from 'utils';
import { CardTokenResponse } from 'models/CardTokenResponse';
import { TransactionDetailsModel } from 'models/TransactionDetailsModel';
import { isSuccessedTransaction } from 'utils/Utils';
import { setCall } from 'services/ServicesReducer';
import { useDispatch } from 'react-redux';

export function useGetTransactionStatus(props: {
  intervalMS?: number,
  maxRetryCount?: number
  transactionInTerminalState?: boolean,
}) {
  const { intervalMS = 3000, maxRetryCount = 50, transactionInTerminalState = false } = props;

  const dispatch = useDispatch();
  const isCancelled = useRef(false);
  const messageCancelled = 'Cancelled';
  const messageMaxRetryReach = 'Reach max retry count.';

  const cancel = useCallback(() => {
    isCancelled.current = true;
  }, []);

  const setCreateTransactionProcessing = (isProcessing: boolean) => {
    dispatch(setCall({ createTransaction: { isProcessing } }));
  }

  const onErrorHandle = (message?: string, onError?: (err?: string) => void) => {
    onError && onError(message);
  };

  useEffect(() => {
    return () => {
      cancel();
    };
  }, [cancel]);

  const getTransactionStatus = useCallback(
    async (
      data: CardTokenResponse | TransactionDetailsModel,      
      onDone?: (res: GetTransactionDetailsResult) => void,
      onError?: (err?: string) => void
    ): Promise<GetTransactionDetailsResult> => {
      let retryCount = 0;
      setCreateTransactionProcessing(true);

      let res: GetTransactionDetailsResult = { result: data as any };
      while (!isCancelled.current) {

        if(data?.statusType === EnumTransactionStatusType.Unknown) {
          return {
            err: messageCancelled,
          };
        }

        if (!transactionInTerminalState || retryCount) { 
          res = await getTransactionService(data.id) 
        }

        if (!res.result) {
          cancel();
          setCreateTransactionProcessing(false);
          onErrorHandle(res.err, onError);
        }

        if (isTransactionInTerminalState(res.result)) {
          const finalResult = prepareResult(res);
          setCreateTransactionProcessing(false);
          if (onDone) onDone(finalResult);
          return finalResult;
        }

        retryCount++;

        if (retryCount == maxRetryCount) {
          setCreateTransactionProcessing(false);
          onErrorHandle(messageMaxRetryReach, onError);

          return { err: messageMaxRetryReach };
        }

        await sleep(intervalMS);
      }

      setCreateTransactionProcessing(false);
      onErrorHandle(messageCancelled, onError);

      return {
        err: messageCancelled,
      };
    },
    [intervalMS, isCancelled]
  );

  return {
    getTransactionStatus,
    cancel,
  };
}

export default useGetTransactionStatus;

export function prepareResult(
  res: GetTransactionDetailsResult
): GetTransactionDetailsResult {
  const transaction = res.result;

  if (!transaction) {
    return { err: 'transaction is null!' };
  }

  if (!isSuccessedTransaction(transaction?.statusType)) {
    // convert non-error status (e.g. timeout, cancelled, etc.) into error
    if (!res.err) {
      res = {
        err: transaction?.responseMessage
          ? transaction?.responseMessage
          : EnumTransactionStatusType[transaction?.statusType]
      };
    }
  }

  return res;
}

function isTransactionInTerminalState(
  transaction?: TransactionDetailsModel
): boolean {
  if (
    transaction?.statusType === EnumTransactionStatusType.Unknown ||
    transaction?.statusType === EnumTransactionStatusType.Created ||
    transaction?.statusType === EnumTransactionStatusType.Processing
  ) {
    return false;
  }

  return true;
}
