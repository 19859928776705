import ConfigurationTemplate from "../../../features/admin/clientConfiguration/ConfigurationTemplate"
import { ReactComponent as SaveIcon } from 'assets/svgs/admin/epic/integrationSettings/save.svg';
import { ReactComponent as VerifiedIcon } from 'assets/svgs/admin/epic/integrationSettings/verified.svg';
import { ReactComponent as VisibilityIcon } from 'assets/svgs/admin/epic/integrationSettings/visibility.svg';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as router from 'react-router-dom';
import { useOrganizations } from "../../../features/organizations/hooks/useOrganizations";
import { getClientConfiguration } from "../../../features/admin/GetClientConfigurationReducer";
import { ClientFacilityConfiguration, DeviceSettings, MyChartSettings } from "../../../models/Client";
import useSaveClientConfiguration, { clientConfigAlert } from "../../../features/admin/epic/integrationSettings/useSaveClientConfiguration";
import TextBox from "../../../features/componentFactory/wrapperComponents/TextBox";
import { useIntegrationSettingsValidator } from "../../../features/admin/epic/integrationSettings/useIntegrationSettingsValidator";
import { EDS_Checkbox } from '@EH/eh.eds.react';
import { Button, Radio } from "@EHDS/core";
import { useDeviceSettingsValidator } from "../../../features/admin/epic/integrationSettings/useDeviceSettingsValidator";
import { useMyChartSettingsValidator } from "../../../features/admin/epic/integrationSettings/useMyChartSettingsValidator";
import MultiSelect, { MultiSelectOption } from "../../../components/select/MultiSelect";
import "assets/styles/components/_integrationSettings.scss";
import { GenericAttribute } from "../../../models/metaData/MetaData";
import { AlertIds, AlertTypes, setAlert } from "../../../features/alert/AlertReducer";
import { useVerifyInterconnect } from "../../../features/admin/epic/integrationSettings/useVerifyInterconnect";

function setDefaultPrefix<T extends { authPrefix: string }>(key: keyof ClientFacilityConfiguration, data?: T) {
  return data && {
  [key]: {
      ...data,
    authPrefix: data?.authPrefix ?? "emp"
  }
}
}

const defaultChartSettings: MyChartSettings = {
  paymentPageUrl: "",
  authPrefix: "",
  isProduction: false
};

const defaultDeviceSettings: DeviceSettings = {
  interconnectUrl: "",
  authPrefix: "",
  isProduction: false,
  useNewEpicClientId: false
};

export default function IntetgrationSettings() {
  const dispatch = useDispatch();
  let { clientId } = router.useParams<{ clientId: string }>();
  const { getOrganizationById } = useOrganizations();
  const selectedOrganization = getOrganizationById(clientId);
  const organizationPath = selectedOrganization?.path;

  const [clientConfiguration, setClientConfiguration] = useState<ClientFacilityConfiguration>()

  useEffect(() => {
    if (!organizationPath) return;
    dispatch(getClientConfiguration(
      { OrganizationPath: organizationPath },
      (data) => data && setClientConfiguration({
        ...data,
        ...setDefaultPrefix("deviceSettings", data.deviceSettings ?? defaultDeviceSettings),
        ...setDefaultPrefix("myChartSettings", data.myChartSettings ?? defaultChartSettings),
      })));
  }, [organizationPath]);

  const [visiblity, setVisibility] = useState(false);

  const { getErrorMessage, getAttribute, validateAll } = useIntegrationSettingsValidator(visiblity);

  const onChange = (key: keyof ClientFacilityConfiguration, value: any,) => {
    clientConfiguration && setClientConfiguration({
      ...clientConfiguration,
      [key]: value
    })
  }

  const checkboxHandler = (key: keyof ClientFacilityConfiguration) => {
    onChange(key, !clientConfiguration?.[key])
  }

  const TabsEnum = {
    device: "device",
    myChart: "myChart"
  }
  const [tab, setTab] = useState(TabsEnum.device)

  const onUpdateDeviceSettings = (updated: DeviceSettings) => {
    onChange("deviceSettings", updated)
  }

  const onUpdateMyChartSettings = (updated: MyChartSettings) => {
    onChange("myChartSettings", updated)
  }

  const deviceValidator = useDeviceSettingsValidator();
  const myChartValidator = useMyChartSettingsValidator();

  const { saveClientConfiguration } = useSaveClientConfiguration();

  function validateConfiguration() {
    const tabValidationResult = tab === TabsEnum.device ?
      deviceValidator.validateAll(true, clientConfiguration?.deviceSettings) :
      myChartValidator.validateAll(true, clientConfiguration?.myChartSettings);


    if (!validateAll(true, clientConfiguration) || !tabValidationResult) {
      dispatch(
        setAlert({
          ...clientConfigAlert,
          message: "Error Configuration not saved. There are validation erros.",
        })
      );
      return false;
    }

    return true;
  }

  const { verifyInterconnect } = useVerifyInterconnect(
    () => dispatch(setAlert({
      id: AlertIds.ClientConfiguration,
      type: AlertTypes.Success,
      dismissable: true,
      message: "Interconnect Connection successful"
    })),
    (errorMessage?: string) => {
      const displayError = errorMessage ? ` - ${errorMessage}` : "";
      dispatch(setAlert({
        id: AlertIds.ClientConfiguration,
        type: AlertTypes.Error,
        message: `Interconnect Connection failure${displayError}`
      }))
    }
  )

  return (
    <ConfigurationTemplate
      title={'EPIC Integration Settings'}
      headerButtons={
        <div className={"header-buttons"} onClick={() => {
          if (validateConfiguration()) {
            clientConfiguration && saveClientConfiguration(clientConfiguration)
          }
        }
        }>
          <div className="header-button">
            <SaveIcon className="header-icon-secondary"/>
            <span className="text"> Save Settings </span>
          </div>
        </div>
      }
      titleModifer={`title--no-border-bottom`}
    >
      <div className={"form-row"}>
        <div className={"field"}>
          <TextBox
            value={clientConfiguration?.userName}
            attribute={getAttribute("userName")}
            onChange={value => {
              onChange("userName", value);
            }}
            error={getErrorMessage("userName")}
            onBlur={() => validateAll(false, clientConfiguration)}
          />
        </div>
        <div className={"field"}>
          <TextBox
            value={clientConfiguration?.password}
            attribute={getAttribute("password")}
            onChange={value => {
              onChange("password", value);
            }}
            error={getErrorMessage("password")}
            onBlur={() => validateAll(false, clientConfiguration)}
          />
        </div>
        <VisibilityIcon className={"visibility"} onClick={() => setVisibility(!visiblity)} />
      </div>
      <div className="form-row">
        <EDS_Checkbox
          className="field"
          checked={clientConfiguration?.returnAuthCode}
          label={""}
          onChange={() => checkboxHandler("returnAuthCode")}
        />
        Return Auth Code to Epic
      </div>
      <div className="form-row-normal">
        <Radio
          label={"Configure Webservices Integration for devices"}
          value={TabsEnum.device}
          name={"tab"}
          checked={tab === TabsEnum.device}
          onChange={() => setTab(TabsEnum.device)}
        />
        <Radio
          label={"Configure Epic external payment page (my chart)"}
          value={TabsEnum.myChart}
          name={"tab"}
          checked={tab === TabsEnum.myChart}
          onChange={() => setTab(TabsEnum.myChart)}
        />
      </div>
      {tab === TabsEnum.device ? <SubitemTab
        data={clientConfiguration?.deviceSettings}
        onUpdate={onUpdateDeviceSettings}
        urlKey={"interconnectUrl"}
        isDeviceTab={tab === TabsEnum.device}
        {...deviceValidator}
      /> : <></>}
      {tab === TabsEnum.myChart ? <SubitemTab
        data={clientConfiguration?.myChartSettings}
        onUpdate={onUpdateMyChartSettings}
        urlKey={"paymentPageUrl"}
        isDeviceTab={tab === TabsEnum.device}
        {...myChartValidator}
      /> : <></>}
      <div>
        <Button onClick={() => verifyInterconnect(organizationPath)} >
          <VerifiedIcon />
          <span className="title-button-text"> Verify Interconnect Credentials </span>
        </Button>
      </div>
    </ConfigurationTemplate>
  )
}
const options = [
  { value: "windows", label: "windows" },
  { value: "emp", label: "emp" },
  { value: "local", label: "local" },
];
export function SubitemTab<T extends { authPrefix?: string, isProduction?: boolean, useNewEpicClientId?: boolean }>(props: {
  data?: T,
  onUpdate: (updated: T) => void,
  urlKey: keyof T,
  isDeviceTab: boolean,
  getAttribute: (key: keyof T) => GenericAttribute<T>,
  getErrorMessage: (key: keyof T) => string,
  validateAll: (validOnly: boolean, currentEntity?: T) => boolean
}) {
  const { data, onUpdate, urlKey, isDeviceTab, getAttribute, getErrorMessage, validateAll} = props;
  const onChange = (key: keyof T, value: any) => {
    data && onUpdate({
      ...data,
      [key]: value
    })
  }

  return <div>
    <div className="eds-heading eds-heading.mdplus-caps mb-1 dataRequired-header">{isDeviceTab ? "Webservices Integration for devices" : "EPIC EXTERNAL PAYMENT PAGE (MY CHART)"}</div>
    <div className={"form-row"}>
      <div className={"field full-width"}>
        <TextBox
          value={data?.[urlKey]}
          attribute={getAttribute(urlKey)}
          onChange={value => {
            onChange(urlKey, value);
          }}
          error={getErrorMessage(urlKey)}
          onBlur={() => validateAll(false, data)}
        />
      </div>
      <div className={"field"}>
        <MultiSelect
          label={"Epic Interconnect auth prefix:"}
          name={"authPrefix"}
          options={options}
          onChange={(option?: MultiSelectOption | null) => {
            if (option) {
              onChange("authPrefix", option.value)
            }
          }}
          values={options.find(o => o.value === data?.authPrefix) ?? null}
          multiple={false}
        />
      </div>
    </div>
    <div className="form-row-normal">
      <Radio
        label={"Use with Live Environment"}
        value={"true"}
        name={"isProduction"}
        checked={data?.isProduction}
        onChange={() => onChange("isProduction", !data?.isProduction)}
      />
      <Radio
        label={"Use with Sandbox"}
        value={"false"}
        name={"isProduction"}
        checked={!data?.isProduction}
        onChange={() => onChange("isProduction", !data?.isProduction)}
      />
    </div>
    {isDeviceTab ?
      <div className="form-row">
        <EDS_Checkbox
          checked={data?.useNewEpicClientId}
          label={""}
          onChange={() => onChange("useNewEpicClientId", !data?.useNewEpicClientId)}
        /> 
        Epic Devices Service Use New Epic Client Id
      </div> : null}
  </div>
}