import React from "react";
import { Popup } from "../../../../../components/popup/Popup";
import { RowFilterForm } from "./RowFilterForm";
import 'assets/styles/components/_remitQueryBuilder.scss';

export function RowFilterPopUp(props: {
  onClose: () => void;
  onChange: (value: string) => void;
}) {
  const { onChange, onClose } = props;
  return <Popup
    header={"Row Filter"}
    customStyle={"row-filter-pop-up"}
    {...props}
  >
    
    <RowFilterForm
      onSave={(value) => {
        onChange(value);
        onClose();
      }}
    />
  </Popup>
}