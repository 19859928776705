import { useRef } from "react";
import { EditableExternalAddress, setExternalAddress } from "./WhitelistReducer";
import { useDispatch } from "react-redux";

export function useRefStore() {
  const dataRef = useRef<EditableExternalAddress[]>([]);
  const dispatch = useDispatch();
  function storeExternalAddress(
    idx: number,
    value: EditableExternalAddress,
  ) {
    dataRef.current[idx] = {
      ...value,
      metadata: {
        ...value.metadata,
        idx
      }
    };
  }

  function commitWorkingCopies(skipIdx?: number) {
    dataRef.current.forEach(ea => {
      if (ea.metadata?.idx === skipIdx) return;
      dispatch(setExternalAddress({ externalAddress: ea, index: ea.metadata?.idx }))
    })
    dataRef.current = [];
  }

  return {
    storeExternalAddress,
    commitWorkingCopies,
    dataRef
  }

}