import { LogoManager } from "models/LogoManagerModel";
import 'assets/styles/components/_logo.scss';

export default function Logo(props: {
  src?: LogoManager;
  print?: boolean
}) {
  const { src, print = false } = props;
  const logoType = src && atob(src.imageData).indexOf('<svg') !== -1
    ? 'data:image/svg+xml;base64,'
    : 'data:image/png;base64,';

  return (
    <div className={print ? "logo-wrapper-print": "logo-wrapper"}>
      {src?.imageData && <img src={`${logoType}${src?.imageData}`} alt={src?.name} className={print ? "object-contain-print" : "object-contain"}/>}
    </div>
  )
}