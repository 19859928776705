import moment from "moment";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import { GenericAttribute, GenericValidator } from "../../../models/metaData/MetaData";
import { DataType, ValidatorType } from "../../../models/metaData/MetaDataEnums";
import PaymentPlanModel from "../../../models/PaymentPlan";
import { PaymentPlanConfiguration, Tier } from "../../../models/PaymentPlanConfiguration";
import { displayAmount, getOneYearFromNowToEndOfDay, getTodaysDate } from "../../../utils/Utils";
import { date, decimal, GenericValidatorProps, required, validateGeneric } from "../../paymentDashboard/Validators";
import { addError, removeError } from "../PaymentPlanReducer";
import { convertCurrencyToNumber } from "../PlanUtils";

export default function usePlanValidator(plan?: PaymentPlanModel, tier?: Tier, tierOverride?: boolean, paymentPlanConfiguration?: PaymentPlanConfiguration) {
  const dispatch = useDispatch();

  const attributes: GenericAttribute<PaymentPlanModel>[] = [
    { name: "termType", dataType: DataType.String, validators: [required()], visible:true  },
    { name: "startDate", dataType: DataType.String, validators: getStartDateValidators(plan), visible: true },
    { name: "nextPaymentDate", dataType: DataType.String, validators: getNextPaymentDateValidators(plan, paymentPlanConfiguration), visible: true },
    { name: "paymentAmount", dataType: DataType.String, validators: [amountValidator(), required(), decimal(12, 2)], visible:true  },
    { name: "paymentsRemaining", dataType: DataType.String, validators: [paymentsValidator(), required()], visible:true  },
    { name: "authorized", dataType: DataType.String, validators: [required()], visible:true  },
    { name: "visible", dataType:DataType.Boolean, validators:[], visible:false}
  ];

  function amountValidator<T>(): GenericValidator<T> {
    const paymentAmount = convertCurrencyToNumber(plan?.paymentAmount || '0');
    const aboveCeiling = !isNaN(paymentAmount) && tier && paymentAmount > tier.ceiling

    return {
      message: `Between $${displayAmount(tier?.minPaymentAmount)} and $${displayAmount(aboveCeiling ? paymentAmount : tier?.ceiling)} required`,
      name: ValidatorType.TierValidator,
      custom: (props: GenericValidatorProps<T>) => {
        const { value, onPass, onFail } = props;
        const amount = convertCurrencyToNumber(value?.toString() || '');
        if (!tierOverride && (
          !plan ||
          amount == undefined || !tier ||
          amount < tier.minPaymentAmount ||
          (aboveCeiling && amount > paymentAmount)
        )) {
          onFail && onFail();
        } else {
          onPass && onPass();
        }
      }
    }
  }

  function paymentsValidator<T>(): GenericValidator<T> {
    return {
      message: `Between ${tier?.minNumberOfPayments} and ${tier?.maxNumberOfPayments} required`,
      name: ValidatorType.TierValidator,
      custom: (props: GenericValidatorProps<T>) => {
        const { onPass, onFail } = props;
        if (!tierOverride && (
          !plan ||
          !plan.paymentsRemaining || !tier ||
          plan.paymentsRemaining < tier.minNumberOfPayments ||
          plan.paymentsRemaining > tier.maxNumberOfPayments
        )) {
          onFail && onFail();
        } else {
          onPass && onPass();
        }
      }
    }
  }

  function validateAll(plan?: PaymentPlanModel, validOnly: boolean = false) {
    let valid = true;
    plan && attributes.forEach(attribute => {
      let result = validate(attribute, plan[attribute.name], validOnly);
      valid = valid && result;
    });
    return valid;
  }

  function validate(attr?: GenericAttribute<PaymentPlanModel>, value?: any, validOnly: boolean = false): boolean {
    return validateGeneric({
      onPass: (key: keyof PaymentPlanModel, validatorType: ValidatorType) => dispatch(removeError({ key, validatorType })),
      onFail: (key: keyof PaymentPlanModel, validatorType: ValidatorType, message?: string) =>
        dispatch(addError({
          [key]: { [validatorType]: message || '' }
        })),
      attr,
      value,
      validOnly
    })
  }

  const errors = useTypedSelector(s => s.paymentPlanInfo.errors);

  function getErrorMessage(name: keyof PaymentPlanModel) {
    return Object.values(errors?.[name] ?? {}).join(',');
  }

  function errorClass(name: keyof PaymentPlanModel) {
    return Object.values(errors?.[name] ?? {}).length ? 'invalid-field' : '';
  }

  return { validate, validateAll, attributes, errorClass, getErrorMessage}
}

function getNextPaymentDateValidators(plan?: PaymentPlanModel, paymentPlanConfiguration?: PaymentPlanConfiguration) {
  const min = getTodaysDate();
  const max = getOneYearFromNowToEndOfDay();
  return plan?.id && paymentPlanConfiguration?.allowPlanDateChange ? [required(), date({ min, max })] : [];
}

function getStartDateValidators(plan?: PaymentPlanModel) {
  const min = getTodaysDate();
  const max = getOneYearFromNowToEndOfDay();
  return [required(), ...plan?.id ? [] : [date({ min, max })]]
}