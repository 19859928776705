import React ,{ useState } from 'react';
import { useDispatch } from 'react-redux';
import { Label } from 'reactstrap';
import moment from 'moment';
import { EDS_Accordion } from '@EH/eh.eds.react';
import { convertStringToDate } from 'utils/UtilsDateTime';
import { DateRange } from 'components/dateRange/DateRange';
import { PaymentPlanSearchInformation, updateRecord } from '../PaymentPlanSearchReducer';
import { planStatusOptions } from '../FilterData';
import { InputField } from './InputField';
import styles from '../planSearch.module.scss';
import MultiSelect, { MultiSelectOption } from "../../../../components/select/MultiSelect";
import { useTypedSelector } from '../../../../app/rootReducer';
import { mapPaymentStatusToMultiSelectOptions } from 'utils/Utils';

type AccordionContents = {
  summary: string;
  isOpen?: boolean;
  content: JSX.Element;
  modifiersContainerOpen?: string;
}[];

export function MainFilters(props: {state: PaymentPlanSearchInformation}) {
  const { state } = props;
  const dispatch = useDispatch();

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    allowWhiteSpace: boolean = false
  ) => {
    let value: string = event.target.value;
    let field: string = event.target.name;
    if (!allowWhiteSpace) {
      value = value.trim();
    }
    dispatch(
      updateRecord({ ...state, [field]: value})
    );
  };

  const handleChangeDate = (
    date: string,
    field: string,
  ) => {
    dispatch(
      updateRecord({ ...state, [field]: moment(date).isValid() ? moment(date).format('YYYY-MM-DDTHH:mm:ss.000') : ''})
    );
  }

  function getAccordionItems(): AccordionContents {
    const accordionContents: AccordionContents = [];

    accordionContents.push({
      summary: 'Patient/Payer',
      isOpen: true,
      content: <SearchByPatientPayer
        state={state}
        onChange={handleChangeInput}
      />,
    });

    accordionContents.push({
      summary: 'Date and Amount',
      isOpen: true,
      modifiersContainerOpen: styles.accordionContainerSearchByDateAmount,
      content: <SearchByDateAmount
        state={state}
        onChange={handleChangeInput}
        onDateChange={handleChangeDate}
      />,
    });

    accordionContents.push({
      summary: 'Plan Details',
      isOpen: true,
      content: <SearchByPlanDetails
        state={state}
        onChange={handleChangeInput}
      />,
    });

    return accordionContents;
  }
  return (
    <EDS_Accordion
      accordionItems={getAccordionItems()}
      modifiers='accordionStyle'
    />
  );
}

function SearchByPatientPayer(
  props: {
    state: PaymentPlanSearchInformation,
    onChange: (event: React.ChangeEvent<HTMLInputElement>, allowWhiteSpace: boolean) => void,
  }
) {
  const { state, onChange } = props;
  return (
    <>
      <div className="row" style={{ alignItems: 'flex-end' }}>
        <InputField
          label="MRN:"
          name="mrn"
          placeHolder="Enter MRN"
          value={state.mrn}
          onChange={e => onChange(e, false)}
        />
        <InputField
          label="Patient First Name:"
          name="patientFirstName"
          placeHolder="Enter patient first name"
          value={state.patientFirstName}
          onChange={e => onChange(e, true)}
        />
        <InputField
          label="Patient Last Name:"
          name="patientLastName"
          placeHolder="Enter patient last name"
          value={state.patientLastName}
          onChange={e => onChange(e, true)}
        />
      </div>
      <div className="row" style={{ alignItems: 'flex-end' }}>
        <InputField
          label="Payer First Name:"
          name="payerFirstName"
          placeHolder="Enter payer first name"
          value={state.payerFirstName}
          onChange={e => onChange(e, true)}
        />
        <InputField
          label="Payer Last Name:"
          name="payerLastName"
          placeHolder="Enter payer last name"
          value={state.payerLastName}
          onChange={e => onChange(e, true)}
        />
        <InputField
          label="Guarantor ID:"
          name="guarantorAccountNo"
          placeHolder="Enter guarantor ID"
          value={state.guarantorAccountNo}
          onChange={e => onChange(e, false)}
        />
      </div>
    </>
  );
}

function SearchByDateAmount(
  props: {
    state: PaymentPlanSearchInformation,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onDateChange: (value: string, field: string) => void
  },
) {
  const { state, onChange, onDateChange } = props;
  const error = state.amountMin && state.amountMax && Number(state.amountMin) > Number(state.amountMax);
  return (
    <>
      <div className={styles.nowrap} style={{ alignItems: 'flex-end' }}>
        <div className={styles.dateRangeContainer}>
          <DateRange
            label="Submitted Date:"
            nameFrom="createdDateMin"
            nameTo="createdDateMax"
            value={[state.createdDateMin, state.createdDateMax]}
            maxDateFrom={(state.createdDateMax && convertStringToDate(state.createdDateMax)) || new Date()}
            minDateTo={(state.createdDateMin && convertStringToDate(state.createdDateMin)) || undefined}
            maxDateTo={new Date()}
            showIcon
            onChangeFrom={date =>
              onDateChange(date || '', 'createdDateMin')
            }
            onChangeTo={date =>
              onDateChange(date ||'', 'createdDateMax')
            }
          />
        </div>
        <div className={styles.paymondAmountContainer}>
          <div className={styles.paymondAmountLabel}>Payment Amount Range:</div>
          <div className={styles.paymondAmountWrapper}>
            <InputField
              type="number"
              name="amountMin"
              placeHolder="From"
              value={state.amountMin}
              className="col-md-6"
              onChange={onChange}
              error={!!error}
            />
            <InputField
              type="number"
              name="amountMax"
              placeHolder="To"
              value={state.amountMax}
              className="col-md-6"
              onChange={onChange}
              error={!!error}
            />
          </div>
          {error ? <><br /><Label className={`error-message-required col-md-12`}>
            Amount To should be greater than From
            </Label></> : null}
        </div>
      </div>
      <div>
        <div className={styles.dateRangeContainer}>
          <DateRange
            label="Next Payment Date:"
            nameFrom="nextPaymentDateMin"
            nameTo="nextPaymentDateMax"
            value={[state.nextPaymentDateMin, state.nextPaymentDateMax]}
            maxDateFrom={(state.nextPaymentDateMax && convertStringToDate(state.nextPaymentDateMax)) || undefined}
            minDateTo={(state.nextPaymentDateMin && convertStringToDate(state.nextPaymentDateMin)) || undefined}
            showIcon
            onChangeFrom={date =>
              onDateChange(date || '', 'nextPaymentDateMin')
            }
            onChangeTo={date =>
              onDateChange(date || '', 'nextPaymentDateMax')
            }
          />
        </div>
      </div>
    </>
  )
}

function SearchByPlanDetails(
  props: {
    state: PaymentPlanSearchInformation,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  },
) {
  const { state, onChange } = props;
  const dispatch = useDispatch();
  const selectedPlanStatus = useTypedSelector(p => p.paymentPlanSearchInfo?.value?.status|| []);
  const planStatusMultiOptions =  mapPaymentStatusToMultiSelectOptions(planStatusOptions); 
  const [allSelectedPlanStatus, setAllSelectedPlanStatus] = useState<boolean>(true);

  const ChangeSelectedPlanStatus=(selected: MultiSelectOption[], propertyName:string)=> {   
    ChangeMultiSelectOpion(selected,propertyName);   
    if(planStatusMultiOptions.length===selected.length){
        setAllSelectedPlanStatus(true)
    }
    else{
        setAllSelectedPlanStatus(false)
      }  
  }

  const ChangeMultiSelectOpion = (values?: MultiSelectOption[],propertyName?:string) => {    
    dispatch(updateRecord({ ...state, [propertyName??""]: values}));
  };

 const selectAllPlanStatus = ()=> { 
  if(allSelectedPlanStatus){  
    ChangeMultiSelectOpion([],"status");   
    setAllSelectedPlanStatus(false);
  }
  else{ 
    ChangeMultiSelectOpion(planStatusMultiOptions,"status");
      setAllSelectedPlanStatus(true)
  }
 }
  return (
    <>
      <div className="row" style={{ alignItems: 'flex-end' }}>
        <InputField
          label="Plan ID:"
          name="id"
          placeHolder="Enter plan ID"
          value={state.id}
          onChange={onChange}
        />
       <div className= "col-md-4" >
        <MultiSelect
          label={"Plan Status:"}
          name={"status"}       
          options={planStatusMultiOptions}
          onChange={ChangeSelectedPlanStatus}
          values={selectedPlanStatus??[]}
          multiple={true}
          searchCheckbox={true}
          selectAll={selectAllPlanStatus}
          allSelected={allSelectedPlanStatus}
          button={'All'}
          remit = {true}
        />
        </div>
        <InputField
          label="Legacy ID:"
          name="legacyId"
          placeHolder="Enter legacy ID"
          value={state.legacyId}
          onChange={onChange}
        />
      </div>
      <div className="row" style={{ alignItems: 'flex-end' }}>
        <InputField
          label="Transaction ID:"
          name="transactionId"
          placeHolder="Enter transaction ID"
          value={state.transactionId}
          onChange={onChange}
        />
        <InputField
          type="number"
          label="Method Ending In:"
          name="tenderMaskedAccount"
          placeHolder="Enter last 4 digits"
          className={`col-md-4 ${styles.noArrows}`}
          value={state.tenderMaskedAccount}
          onChange={onChange}
        />
      </div>
    </>
  );
}