import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTodaysDate, getYesterday } from 'utils/Utils';
import { MultiSelectOption } from '../../../components/select/MultiSelect';
import { GetTransactionResult } from '../../../models/GetTransactionResult';
import { UserDetailModel } from '../../../models/UserInfoAndRolesModel';
import { DepartmentMultiselectOption, UserMultiSelectOption } from './OrganizationUserSearch';

const yesterday = getYesterday();
const today = getTodaysDate();

export interface SimpleSearchInformation {
  patientConfirmationID?: string;
  submittedDate?: string;
  mrn?: string;
  users: UserMultiSelectOption[];
  departments: DepartmentMultiselectOption[];
  facilities: MultiSelectOption[];
  filterText?: string;
  cacheBlockSize?: number;
  maxBlocksInCache?: number;
  patientId?: string;

  accountNumber?: string;
  
  id?: string;
  patientAccountNumber?: string;
  submittedDateTimeMin?: string;
  submittedDateTimeMax?: string;
  billingId?: string;
  resetSelection?: boolean;
}

export interface OrganizationUserSettings {
  facilities: MultiSelectOption[],
  departments: DepartmentMultiselectOption[],
  users: UserMultiSelectOption[],
  billingId?: string
  resetSelection?: boolean;
}

export interface State {
  value: SimpleSearchInformation;
  searchToClone: SimpleSearchInformation;
  userSelector: {
    users: UserDetailModel[]
  },
  activeTab: string;
  saveSettings?: boolean;
  records: GetTransactionResult[];
  savedOrganizationUserSettings: {
    [key: string]: OrganizationUserSettings | undefined
  },
  pageSize: number;
}

export const initialState: State = {
  value: {
    patientConfirmationID: '',
    submittedDate: '',
    mrn: '',
    patientAccountNumber: '',
    users: [],
    departments: [],
    facilities: [],
    filterText: '',
    cacheBlockSize: 50,
    maxBlocksInCache: 2,
    patientId: '',
    accountNumber: '',
    id: '',
    submittedDateTimeMin: yesterday,
    submittedDateTimeMax: today,
    resetSelection: false
  },
  searchToClone:{
    patientConfirmationID: '',
    submittedDate: '',
    mrn: '',
    patientAccountNumber: '',
    users: [],
    departments: [],
    facilities: [],
    filterText: '',
    cacheBlockSize: 50,
    maxBlocksInCache: 2,
    patientId: '',
    accountNumber: '',
    id: '',
    submittedDateTimeMin: yesterday,
    submittedDateTimeMax: today,
    resetSelection: false
  },
  userSelector: {
    users: []
  },
  activeTab: '1',
  saveSettings: false,
  savedOrganizationUserSettings: {},
  records: [],
  pageSize: 100
};

const reducerSlice = createSlice({
  name: 'SimpleSearch',
  initialState,
  reducers: {
    setSimpleSearchInfoState(
      state,
      action: PayloadAction<SimpleSearchInformation>
    ) {
      state.value = action.payload;
    },
    setSimpleSearchInfoToCloneState(
      state,
      action: PayloadAction<SimpleSearchInformation>
    ){
      state.searchToClone = action.payload;
    },
    setActiveTab(state, action: PayloadAction<string>) {
      state.activeTab = action.payload;
    },
    setSaveSettings(state, action: PayloadAction<{[key: string]: boolean}>) {
      state.saveSettings = action.payload.saveSettings;
    },
    setOrganizationUSerSavedSettings(state, action: PayloadAction<{ tabIndex: string, settings: OrganizationUserSettings | undefined }>) {
      const { tabIndex, settings } = action.payload;
      state.savedOrganizationUserSettings[tabIndex] = settings;
    },
    setTransactionRecords(state, action: PayloadAction<GetTransactionResult[]>) {
      state.records = action.payload;
    },
    setPageSize(state,action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setUsers(state, action: PayloadAction<UserDetailModel[]>) {
      state.userSelector.users = action.payload;
    }
  },
});

export const {
  setSimpleSearchInfoState,
  setSimpleSearchInfoToCloneState,
  setActiveTab,
  setSaveSettings,
  setTransactionRecords,
  setOrganizationUSerSavedSettings,
  setPageSize,
  setUsers,
} = reducerSlice.actions;
export default reducerSlice.reducer;