import { useEffect, useState } from 'react'

type ReturnType<T> = [
  T,
  React.Dispatch<React.SetStateAction<T>>
];

type GenericObject<T> = { [key: string]: T };

function saveState<T>(component: string, key: string, state: T){
  try {
    const previousState = loadState(component);
    const object: GenericObject<T> =  !previousState ? {}: previousState;
    object[key] = state;
    const jsonState = JSON.stringify(object);
    localStorage.setItem(component, jsonState);    
  } catch(err) {     
  }
}

function loadState(key: string){
  try {
  
    const jsonState = localStorage.getItem(key);
    if (jsonState === null) {
      return undefined;
    }
    return JSON.parse(jsonState);
  } catch (err) {
    return undefined;
  }
}

export const useLocalStorage = <T,>(component: string, key: string, initialValue: T): ReturnType<T> => {
  const [state, setState] = useState<T>(
    () => {
      const value = loadState(component);     
      if(!initialValue) return;
      if(value)
      {
        return value[key];
      }
        return initialValue;     
    }
  );

  useEffect(() => {
    if (state) {     
      saveState(component, key, state);     
    }
  }, [state, key]);

  return [state, setState];
}