import moment from 'moment';
import { LineItem } from '../../models/LineItem';
import { GenericAttribute, GenericValidator, Validator } from '../../models/metaData/MetaData';
import { DataType, ValidatorType } from '../../models/metaData/MetaDataEnums';
import { emailValidation, formatValidation, regexValidation } from '../../utils/Utils';

export interface GenericValidatorProps<T> {
  value?: string | number;
  dataType?: DataType;
  validator: GenericValidator<T>;
  onFail?: () => void;
  onPass?: () => void;
  entity?: T;
}

export interface ValidatorProps extends GenericValidatorProps<LineItem> {}

export function validateRequired<T>(props: GenericValidatorProps<T>) {
  const { value, validator, onPass, onFail } = props;
  if (!value && validator?.value) {
    onFail && onFail();
  } else {
    onPass && onPass();
  }
}

export function validateEmail<T>(props: GenericValidatorProps<T>) {
  const { value, onPass, onFail } = props;
  if (value && !emailValidation(value?.toString() || '')) {
    onFail && onFail();
  } else {
    onPass && onPass();
  }
}

export function validateEqual<T>(props: GenericValidatorProps<T>) {
  const { value, validator, onPass, onFail } = props;
  if (value != validator.value) {
    onFail && onFail();
  } else {
    onPass && onPass();
  }
}

export function validateRange<T>(props: GenericValidatorProps<T>) {
  const { value, validator, dataType, onPass, onFail } = props;
  if (
    value &&
    (dataType === DataType.Decimal || dataType === DataType.Money) &&
    ((validator?.value.min !== undefined && value < validator.value.min) ||
      (validator?.value.max !== undefined && value > validator.value.max))
  ) {
    onFail && onFail();
  } else {
    onPass && onPass();
  }
}

export function validateMin<T>(props: GenericValidatorProps<T>) {
  const { value, validator, dataType, onPass, onFail } = props;
  const val: number = value == null || value == undefined || isNaN(Number(value)) ? 0 : Number(value);
  if (
    val &&
    (dataType === DataType.Decimal || dataType === DataType.Money) &&
    (validator?.value.min !== null && val >= validator.value.min)
  ) {
    onPass && onPass();
  } else {
    onFail && onFail();
  }
}

export function validateMax<T>(props: GenericValidatorProps<T>) {
  const { value, validator, dataType, onPass, onFail } = props;
  const val: number = value == null || value == undefined || isNaN(Number(value)) ? 0 : Number(value);
  if (
    val &&
    (dataType === DataType.Decimal || dataType === DataType.Money) &&
    (validator?.value.max !== undefined && val <= validator.value.max)
  ) {
    onPass && onPass();
  } else {
    onFail && onFail();
  }
}

function validateMinPaymentAmountOnTier<T>(props: GenericValidatorProps<T>) {
  const { value, validator, dataType, onPass, onFail } = props;

  const val: number = value == null || value == undefined || isNaN(Number(value)) ? 0 : Number(value);
  const minNumberOfPayments:number = validator.value.minNumberOfPayments;
  const floor:number = validator.value.floor;
  
  let maxNumberOfPayments = (floor / val);
  if (
    val &&
    (dataType === DataType.Decimal || dataType === DataType.Money) &&
    (maxNumberOfPayments >= minNumberOfPayments)
  ) {
    onPass && onPass();
  } else {
    onFail && onFail();
  }
}

export function validateLength<T>(props: GenericValidatorProps<T>) {
  const { value, validator, dataType, onPass, onFail } = props;
  if (
    value &&
    dataType === DataType.String &&
    ((validator?.value.min !== undefined && value.toString().length < validator.value.min) ||
      (validator?.value.min !== undefined && value.toString().length > validator.value.max))
  ) {
    onFail && onFail();
  } else {
    onPass && onPass();
  }
}

export function validateFormat<T>(props: GenericValidatorProps<T>) {
  const { value, validator, dataType, onPass, onFail } = props;
  const valueToValidate = value ? value.toString() : undefined;
  if (
    !valueToValidate?.length ||
    (dataType === DataType.String || dataType === DataType.Money || dataType === DataType.Decimal || dataType === DataType.Autocomplete) && 
    formatValidation(validator.value.format, valueToValidate)){
    onPass && onPass();
  } else {
    onFail && onFail();
  }
}

export function validateRegex<T>(props: GenericValidatorProps<T>) {
  const { value, validator, dataType, onPass, onFail } = props;
  if (
    value &&
    (dataType === DataType.String || dataType === DataType.Money || dataType === DataType.Decimal) && 
    regexValidation(validator.value.regex, value.toString()) || !value) {
    onPass && onPass();
  } else {
    onFail && onFail();
  }
}

export function validateAmountToTotal<T>(props: GenericValidatorProps<T>) {
  const { value, validator, dataType, onPass, onFail } = props;
  if (
    value &&
    dataType === DataType.Money &&
    validator?.value.total !== undefined &&
    value < validator.value.total
  ) {
    onFail && onFail();
  } else {
    onPass && onPass();
  }
}

export function validateDecimal<T>(props: GenericValidatorProps<T>) {
  const { value, validator, dataType, onPass, onFail } = props;
  if (value && dataType === DataType.Decimal) {
    let regex = '^\\d';
    let left = '*';
    if (validator?.value.left) {
      left = '{0,' + validator?.value.left + '}';
    }
    regex += left;
    if (validator?.value.right) {
      regex += '(\\.\\d{0,' + validator?.value.right + '}){0,1}';
    }
    regex += '$';

    const decimalRegex = new RegExp(regex);

    if (!decimalRegex.test(value.toString().replace(/,/g, ''))) {
      onFail && onFail();
      return;
    }
  }
  onPass && onPass();
}


export function validateTooLong(value?: string, validator?: Validator) {
  return !!(validator?.value?.max && value && value.length > validator.value.max);
}

export function validateDateRange<T>(props: GenericValidatorProps<T>) {
  const { value, validator, dataType, onPass, onFail } = props;
  if ( value && dataType === DataType.String ) {
    const dateValue = moment(value);
    const minDate= moment(validator.value.min);
    const maxDate= moment(validator.value.max);

    let pass = false;
    switch(dateValue.isValid()) {
      case (validator.value.min && minDate.isValid()) && 
            (validator.value.max && maxDate?.isValid()): {
        pass = dateValue.isSameOrAfter(minDate) && dateValue.isSameOrBefore(maxDate);
        break;
      }
      case validator.value.min && minDate.isValid(): {
        pass = dateValue.isSameOrAfter(minDate);
        break;
      }
      case validator.value.max && maxDate.isValid(): {
        pass = dateValue.isSameOrBefore(maxDate);
        break;
      }
    }

    if (pass) {
      onPass && onPass();
    } else {
      onFail && onFail();
    }
  }
}

export function validateGeneric<T>(props: {
  onPass: (key: keyof T, validatorType: ValidatorType) => void,
  onFail: (key: keyof T, validatorType: ValidatorType, message?: string) => void,
  attr?: GenericAttribute<T>,
  value?: any,
  validOnly: boolean,
  entity?: T,
}): boolean {
  const { attr, onPass, onFail, value, validOnly = false, entity } = props;
  if (!attr) return false;
  let valid = true;
  if (!attr.validators) return valid;

  attr.validators.forEach(validator => {
    const dataType = attr.dataType;

    execute({
      validator,
      value,
      dataType,
      onPass: () => {
        if (onPass) {
          onPass(attr.name, validator.name)
        }
      },
      onFail: (message?: string) => {
        valid = false;
        if (validOnly) return valid;
        if (onFail) {
          onFail(attr.name, validator.name, message);
        }
      },
      entity,
    })
  });

  return valid;
}

export function execute<T>(props: {
  value?: string | number;
  dataType?: DataType;
  validator: GenericValidator<T>;
  onFail?: (message?: string) => void;
  onPass?: () => void;
  entity?: T;
}) {
  const { value, validator, dataType, onPass, onFail, entity } = props;
  const onValidationFail = () => {
    onFail && onFail(validator.message);
  };

  switch (validator.name) {
    case ValidatorType.RangeValidator:
      validateRange({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.MinValidator:
      validateMin({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.MaxValidator:
      validateMax({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.MinPaymentAmountValidator:
      validateMinPaymentAmountOnTier({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.LengthValidator:
      validateLength({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.FormatValidator:
      validateFormat({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.RegularExpressionValidator:
      validateRegex({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.AmountToTotalValidator:
      validateAmountToTotal({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.RequiredValidator:
      validateRequired({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.DecimalValidator:
      validateDecimal({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.EmailValidator:
      validateEmail({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.EqualValidator:
      validateEqual({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    case ValidatorType.DateTimeValidator:
      validateDateRange({
        value,
        dataType,
        validator,
        onFail: onValidationFail,
        onPass,
      });
      break;
  
    default:
      if (validator.custom) {
        validator.custom({
          value,
          dataType,
          validator,
          onFail: onValidationFail,
          onPass,
          entity
        });
      }
      break;
  }
}

export function date(value: { min?: string, max?: string }) {
  return {
    message: 'Enter future date',
    name: ValidatorType.DateTimeValidator,
    value,
  }
}

export function length(value: { min?: number, max?: number }) {
  const { min, max } = value;
  return {
    message:
      min === max
        ? `Exact ${min} characters required`
        : (max && min
            ? `Between ${min} and ${max} characters required`
            : null) ||
          (min && `At least ${min} characters required.`) ||
          (max && `Up to ${max} characters required.`) ||
          '',
    name: ValidatorType.LengthValidator,
    value
  }
}

export function amountToTotal(value: { total?: number }) {
  const { total } = value;
  return {
    message: (total && `Greater than or equal to $${total}.`) || '',
    name: ValidatorType.AmountToTotalValidator,
    value
  }
}

export function equal(value: any, equalTo: string) {
  return {
    message: `Equal to ${equalTo}`,
    name: ValidatorType.EqualValidator,
    value: value,
  }
}

export function required(message: string = "Required") {
  return {
    message,
    name: ValidatorType.RequiredValidator,
    value: true,
  }
}

export function email(message: string = "Invalid Email") {
  return {
    message,
    name: ValidatorType.EmailValidator,
    value: true,
  }
}

export function decimal(left: number, right: number) {
  return {
    message: `${left} digits before . and ${right} after`,
    name: ValidatorType.DecimalValidator,
    value: {
      left,
      right
    },
  }
}

export function range(value: { min?: number, minFrom?: string, max?: number, maxFrom?: string }) {
  return {
    message: `Maximum allowed amount $${value.max}`,
    name: ValidatorType.RangeValidator,
    value,
  }
}

export function minimum(value: { min: number | null }) {
  return {
    message: `Minimum allowed value is ${value.min}`,
    name: ValidatorType.MinValidator,
    value,
  }
}

export function maximum(value: { max: number| null }) {
  return {
    message: `Maximum allowed value is ${value.max}`,
    name: ValidatorType.MaxValidator,
    value,
  }
}

export function minPaymentAmountValidatorOnTier(value: { floor: number| null, minNumberOfPayments: number| null }) {

  return {
    message: `Minimum Payment Amount is invalid`,
    name: ValidatorType.MinPaymentAmountValidator,
    value,
  }
}

export function format(value : { format?: string }) {
  return {
    message: `Expected format: ${ value.format }`,
    name: ValidatorType.FormatValidator,
    value,
  }
}

export function regex(value : { regex?: string }, message: string) {
  return {
    message,
    name: ValidatorType.RegularExpressionValidator,
    value,
  }
}