import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateRecord } from './PaymentPlanReducer';
import PaymentPlanModel from 'models/PaymentPlan';
import { CardDeviceTokenizationService } from 'services/transactions/CardDeviceTokenizationService';
import { CardEntryTokenizationService } from 'services/transactions/CardEntryTokenizationService';
import { ECheckTokenizationService } from 'services/transactions/ECheckTokenizationService';
import { useGetTransactionStatus } from 'utils/hooks/useGetTransactionStatus';
import { ApiTenderTypeEnum, maskECheckNumber, maskCardNumber, checkStringHasValue, checkReceivedHasValues } from 'utils/Utils';
import { useTypedSelector } from 'app/rootReducer';
import { useLineItems } from './hooks/useCreateOrUpdatePaymentPlan';
import { getServerLineItems } from './hooks/useGetServerLineItems';
import { mapCheckAccountType } from 'features/paymentPlan/PlanUtils';
import { PatientModel } from 'models/PatientModel';
import { useCallService } from '../../services/useCallService';
import { useOrganizations } from 'features/organizations/hooks/useOrganizations';
import { EnumTransactionStatusType } from 'models/enums';

/**
 * Creates a transaction then polls the server periodically for the status.
 * @param isGLPayment Whether it is a gl payment.
 * @param onSuccess Called when this asynchronus function ends.
 * @param onError Called on error. Provides a description of the error as an argument to the callback.
 * @param pollIntervalMS The interval for polling in milliseconds.
 */
export function useCreateCardToken(
  paymentPlan?: PaymentPlanModel,
  onSuccess?: (plan: PaymentPlanModel) => void,
  onError?: (msg: string, data?: any) => void,
  pollIntervalMS: number = 3000,
  patient?: PatientModel,
  patientId?: string,
  organizationPath?: string,
  isMailingAddressRequired?: boolean
) {
  const dispatch = useDispatch();

  const { getTransactionStatus } = useGetTransactionStatus({ intervalMS: pollIntervalMS });

  const lineItems = useTypedSelector(s => s.paymentDashboard?.values[organizationPath!]?.lineItems);
  
  const { getFacilityByDepartment } = useOrganizations();
  const facility = getFacilityByDepartment(paymentPlan?.organization?.path);

  const { lineItems: combinedLineItems } = useLineItems(
    paymentPlan,
    lineItems,
    true
  );

  const serverLineItems = getServerLineItems(
    combinedLineItems,
    paymentPlan?.patientId
  );

  const getNameOnCheck = () => {
    if(paymentPlan?.tender?.firstName) {
      return `${paymentPlan.tender?.firstName} ${paymentPlan.tender?.lastName}`
    }
    return `${paymentPlan?.billingInformation?.firstName} ${paymentPlan?.billingInformation?.lastName}`
  }

  const createTransaction= useCallback(async () => {

    if (paymentPlan?.tender?.type === ApiTenderTypeEnum.SavedOnFile) {
      if (onSuccess) onSuccess(paymentPlan);
      return;
    }

    const onErrorAction = (msg: string, data?: any) => {
      if (onError) {
        onError(msg, data);
      }
    };

    if (!paymentPlan) return;
    
    let data;
    const nameOnCheck = getNameOnCheck();
    const requestData = {
      tenderMaskedAccount: paymentPlan.tenderMaskedAccount,
      paymentSource: 'PaymentSafe',
      BillingInformation: paymentPlan.billingInformation,
      MailingInformation: paymentPlan.mailingInformation,
      isPaymentMethodReusable: paymentPlan.tender?.isReusable,
      organization: {
        id: checkStringHasValue(paymentPlan.organization?.id),
        name: checkStringHasValue(paymentPlan.organization?.name),
        path: checkStringHasValue(paymentPlan.organization?.path),
      },
      lineItems: serverLineItems,
      patientId: checkReceivedHasValues(paymentPlan.isGl, patientId, patient?.id),
      notes: paymentPlan.notes,
      patientFirstName: patient?.firstName,
      patientLastName: patient?.lastName,
      patientDateOfBirth: patient?.dateOfBirth,
      patientAccountNumber: patient?.accountNo,
      mrn: patient?.mrn,
      guarantorId: patient?.guarantorAccountNo,
      department: paymentPlan.organization,
      facility: facility,
    };

    const requestECheckData = { 
      TenderMaskedAccount: paymentPlan.tenderMaskedAccount,
      BillingInformation: paymentPlan.billingInformation,
      MailingInformation: paymentPlan.mailingInformation,
      isPaymentMethodReusable: paymentPlan.tender?.isReusable,
      Discount: paymentPlan.discount,
      ECheckModel: {
        CheckAccountType: mapCheckAccountType(paymentPlan.tender?.accountType),
        AccountNumber: paymentPlan.tender?.accountNumber,
        RoutingNumber: paymentPlan.tender?.routingNumber,
        NameOnCheck: nameOnCheck,
      },
      Organization: {
        id: checkStringHasValue(paymentPlan.organization?.id),
        name: checkStringHasValue(paymentPlan.organization?.name),
        path: checkStringHasValue(paymentPlan.organization?.path),
      },
      Department: paymentPlan.organization,
      Facility: facility,
      LineItems: serverLineItems,
      GuarantorId: patient?.guarantorAccountNo,
      isGL: paymentPlan.isGl,
      Notes: paymentPlan.notes,
      MRN: patient?.mrn,
      PatientId: checkReceivedHasValues(paymentPlan.isGl, patientId, patient?.id),
      PatientFirstName: patient?.firstName,
      PatientLastName: patient?.lastName,
      PatientDateOfBirth: patient?.dateOfBirth,
      PatientAccountNumber: patient?.accountNo,
      PaymentSource: 'PaymentSafe',
      NotificationEmail: paymentPlan.notificationEmail,
      TenderAdditionalData: {
        RoutingNumber: checkStringHasValue(maskECheckNumber(paymentPlan.tender?.routingNumber)),
        FinancialInstitution: checkStringHasValue(paymentPlan.tender?.financialInstitution),
        AccountType: mapCheckAccountType(paymentPlan.tender?.accountType),
      }
    }

    if (!isMailingAddressRequired) {
      delete requestData.MailingInformation;
      delete requestECheckData.MailingInformation;
    }

    if (paymentPlan.tender?.type == ApiTenderTypeEnum.CardDevice) {
      data = await CardDeviceTokenizationService({
        ...requestData,
        deviceSerialNumber: paymentPlan.tender?.deviceSerialNumber,
        device: {...paymentPlan?.tender?.device}
      });
    } else if (paymentPlan.tender?.type == ApiTenderTypeEnum.CardManual) {
      data = await CardEntryTokenizationService({
        ...requestData,
        cardNumber: paymentPlan.tender?.cardNumber?.split(' ').join(''),
        expirationDate: paymentPlan.tender?.expirationDate?.replace('/', ''),
        cvv: paymentPlan.tender?.cvc,
        nameOnCard: paymentPlan.tender?.cardHolder,
      });
    } else if (paymentPlan.tender?.type == ApiTenderTypeEnum.ECheck) {      
      data = await ECheckTokenizationService({
        ...requestECheckData,
      });
    }
    
    if (data?.err) {
      onErrorAction(data.err, data?.errorResponse);
      return;
    }
    
    if (!data?.result) {
      onErrorAction('Server did not return any data for the transaction.');
      return;
    }

    if(data.result.statusType === EnumTransactionStatusType.Unknown) {
      return;
    }

    const res = await getTransactionStatus(data.result);
    if (res.err) {
      onErrorAction(res.err);
      return;
    }

    let plan = {
      ...paymentPlan,
      tokenId: res?.result?.id,
      walletId: res.result!.id,
      tender: {
        ...paymentPlan.tender,
        cardNumber: maskCardNumber(res?.result?.cardNumber),
        expirationDate: res.result?.expirationDate,
        cvc: 'xxx',
        cardType: res.result?.cardType,
        cardHolder: res.result?.cardHolder,
        method: res.result?.id,
      },
      cardBrand: res.result?.cardBrand,
      tenderMaskedAccount: res.result?.tenderMaskedAccount,
      tenderAdditionalData: res.result?.tenderAdditionalData
    };

    dispatch(
      updateRecord({
        id: paymentPlan.id,
        paymentPlan: plan,
      })
    );
    if (onSuccess) onSuccess(plan);
  }, [
    CardDeviceTokenizationService,
    paymentPlan,
    getTransactionStatus,
    dispatch,
    onSuccess,
    onError,
  ]);

  const callService = useCallService();

  const createCardToken = () => callService("createCardToken", () => createTransaction());

  return {
    createCardToken,
  };
}

export default useCreateCardToken;
export type CreateTransactionAndGetStatusType = ReturnType<
  typeof useCreateCardToken
>;
