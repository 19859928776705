import { format, regex, required } from "features/paymentDashboard/Validators";
import { LineItem } from "models/LineItem";
import { PaymentPanelFieldType } from "models/enums/EnumPaymentPanels";
import { Annotation, Attribute, Validator } from "models/metaData/MetaData";
import { AnnotationType, ComponentType, DataType, ValidatorType, ValueType } from "models/metaData/MetaDataEnums";
import { PaymentPanelApiConfigurationFieldSetting } from "services/PaymentPanelService";

export function getDataTypeByFieldName(name?: keyof LineItem): DataType {
  const map: { [key in keyof LineItem]?: DataType } = {
    description: DataType.Autocomplete,
    id: DataType.Link,
    dateOfService: DataType.Date,
    amount: DataType.Money,
    discount: DataType.Money,
    discountPercent: DataType.Decimal,
    isNew: DataType.Boolean
  }
  return (name && map[name]) ?? DataType.String
}

export function getAnnotations(fieldSetting: PaymentPanelApiConfigurationFieldSetting | Attribute) {
  const annotations: Annotation[] = [];
  const fieldType = fieldSetting.fieldType;
  const fieldTypeOnAddAccount = fieldSetting.fieldTypeOnAddAccount;

  switch (fieldType)
  {
    case PaymentPanelFieldType.TextInput:
    case PaymentPanelFieldType.DropDown:
    case PaymentPanelFieldType.CheckBox:
    case PaymentPanelFieldType.Calendar:
    case PaymentPanelFieldType.Hidden:
    case PaymentPanelFieldType.CheckImage:
      annotations.push({ name: AnnotationType.Mutable, value: ComponentType[fieldType] });
      break;
  }
  
  switch (fieldTypeOnAddAccount)
  {
    case PaymentPanelFieldType.TextInput:
    case PaymentPanelFieldType.DropDown:
    case PaymentPanelFieldType.CheckBox:
    case PaymentPanelFieldType.Calendar:
    case PaymentPanelFieldType.Hidden:
    case PaymentPanelFieldType.CheckImage:
      annotations.push({ name: AnnotationType.ComponentType, value: ComponentType[fieldTypeOnAddAccount] });
      break;
    case PaymentPanelFieldType.Label:
      annotations.push({ name: AnnotationType.Immutable, value: true });
      break;
  }

  if(fieldSetting.saveAs === 'discountPercent'){
    annotations.push({ name: AnnotationType.ValueType, value: ValueType.Percent })
  }

  return annotations;
}

export function mapValidators(attribute: PaymentPanelApiConfigurationFieldSetting | Attribute, descriptionValidator?: Validator) {
  const validators: Validator[] = [];

  if(attribute.fieldTypeOnAddAccount === PaymentPanelFieldType.Label ||
    attribute.fieldTypeOnAddAccount === PaymentPanelFieldType.Hidden) {
    return validators;
  }

  if (attribute.required) {
    validators.push(required());
  }

  if(attribute.fieldTypeOnAddAccount === PaymentPanelFieldType.CheckBox ||
    attribute.fieldTypeOnAddAccount === PaymentPanelFieldType.CheckImage ||
    attribute.fieldTypeOnAddAccount === PaymentPanelFieldType.Calendar) {
    return validators;
  }

  if (attribute?.fieldTypeOnAddAccount === PaymentPanelFieldType.DropDown) {
    if(attribute?.listOptions?.length){
      validators.push({
        name: ValidatorType.RangeValidator,
        value: attribute?.listOptions?.map(o => o.savedValue),
      })
      return validators;
    }
  }

  if (attribute.format) { 
    validators.push(format({ format : attribute.format }));
  }

  if (attribute.maxLength) {
    validators.push({
      name: ValidatorType.LengthValidator,
      value: {
        max: attribute.maxLength,
      },
    })
  }

  if (attribute.regex) {
    validators.push(regex({ regex: attribute.regex }, attribute.validationErrorMessage || ''));
  }

  if (descriptionValidator && ( attribute.saveAs === 'description' || attribute.saveAs === 'gLDescriptionList')) {
    validators.push(descriptionValidator);
  }

  return validators;
}