import { useDispatch } from "react-redux";
import { useCallService } from "services/useCallService";
import { getRemitConfigurations as getRemitConfigurationsService, GetRemitConfigurationsQueryParams } from "../../../../services/RemitService";
import { setRemitConfigurations } from "../RemitConfigurationReducer";

export function useGetRemitConfigurations() {
  const callService = useCallService();
  const dispatch = useDispatch();

  const getRemitConfigurations = (params: GetRemitConfigurationsQueryParams) => {
    return callService("getRemitConfigurationsByClient", async () => {
      const response = await getRemitConfigurationsService(params);
      if (response.result) {
        const mappedResult = response.result.records.map((rc) => {
          return {
            ...rc,
            fileName: `${rc.fileName}${rc.fileExtension}`
          }
        });
        dispatch(setRemitConfigurations(mappedResult));
        return { total: response.result.total, records: mappedResult };
      }
    });
  }

  return {
    getRemitConfigurations
  }
}