import { useState, useMemo } from 'react';
import { State } from '../../services/ServicesReducer';
import { useCallService } from '../../services/useCallService';

interface ApiResponse<T> {
  result?: T;
  err?: string;
}

export function useApi<T, D>(
  action: (data: D) => Promise<{ result?: T; err?: string }>,
  key: keyof State["calls"]
) {
  const [requestData, setRequestData] = useState<D | undefined>(undefined);
  const [result, setResult] = useState<T | undefined>(undefined);
  const [err, setErr] = useState<string | undefined>(undefined);
  const callService = useCallService();

  const sendRequest = useMemo(() => {
    return async (data: D, onDone?: (res: ApiResponse<T>) => void) => {
      // before making the request
      setRequestData(data);

      return callService(key, async () => {
        // make the request
        const res = await action(data);

        // after the request
        setResult(res.result);
        setErr(res.err);

        // call the callback
        if (onDone) onDone(res);
        return res;
      })
    };
  }, [action]);

  return {
    state: {
      requestData,
      result,
      err,
    },
    sendRequest,
  };
}

export type UseApiType = ReturnType<typeof useApi>;
