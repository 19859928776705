import { GetTransactionResult } from 'models/GetTransactionResult';

export function planToIncorporateLineItemInTransactionResult(
  transResults?: GetTransactionResult[]
): GetTransactionResult[] {
  if (transResults && transResults.length > 0) {
    return transResults.map<GetTransactionResult>(tran => {
      return tran;
    });
  }
  return [];
}
