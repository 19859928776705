import DatePicker from 'react-datepicker';
import moment from 'moment';
import { EDS_TextBox } from '@EH/eh.eds.react';
import { ReactComponent as Calendar } from 'assets/svgs/paymentPlanIcons/icon-calendar.svg';
import { PatientMailingInformation } from 'features/paymentInfo/PatientMailingInformationReducer';
import { Field } from 'features/singlePayment/Field';
import { TenderInfo } from 'models/PaymentPlan';

export function TenderMoneyOrderCart (props: {
  isGl?: boolean;
  source: PatientMailingInformation;
  total?: number;
  amount?: number;
  discount?: number;
  prepend: { prependType: string; prependValue: string; };
  formatLabel: (name: keyof TenderInfo, label: string) => string;
  errorClass: (name: keyof TenderInfo) => '' | 'invalid-field';
  showInputError: (name: keyof TenderInfo) => string | undefined;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, name: keyof TenderInfo) => void;
  changeDateHandler: (date: Date, name: keyof TenderInfo, event?: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  onBlur: (name: keyof TenderInfo) => void;
}) {
  const {
    isGl,
    source,
    total,
    amount,
    discount,
    prepend,
    formatLabel,
    errorClass,
    showInputError,
    handleChange,
    changeDateHandler,
    onBlur,
  } = props;

  const cashBackGl = (source.moneyOrderAmount || 0) - (total ?? 0) > 0
    ? (Number(source.moneyOrderAmount) - ((total ?? 0) - (discount ?? 0))).toFixed(2)
    : '';
  const cashBack = source.moneyOrderAmount - (amount ?? total ?? 0) > 0
    ? (Number(source.moneyOrderAmount) - (amount ?? total ?? 0)).toFixed(2)
    : '';

  return (
    <>
      <div className="flex-row">
        <div className="flex-col">
          <Field<TenderInfo>
            modifiers={'row-item row-item-size-double'}
            errorClass={errorClass}
            onBlur={onBlur}
            showInputError={showInputError}
            name={'moneyOrderAmount'}
            formatLabel={name => formatLabel(name, '*Amount Tendered')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'moneyOrderAmount')}
            prepend={prepend}
            value={(source.moneyOrderAmount || 0) > 0 ? source?.moneyOrderAmount : ''}
          />
        </div>

        <EDS_TextBox
          name="cashBack"
          label="Cash Back:"
          modifiers="row-item row-item-size"
          type={'text'}
          addPrepend={prepend}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'cashBack')}
          value={isGl ? cashBackGl : cashBack}
        />
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <Field<TenderInfo>
            name={'moneyOrderSerialNumber'}
            formatLabel={name => formatLabel(name, '*Serial Number')}
            value={source?.moneyOrderSerialNumber ? source?.moneyOrderSerialNumber : ''}
            modifiers="row-item row-item-size-double"
            errorClass={errorClass}
            onBlur={onBlur}
            onChange={handleChange}
            showInputError={showInputError}
          />
        </div>
        <label
          className={`eds-field row-item row-item-size ${errorClass(
            'moneyOrderIssueDate'
          )}`}
          htmlFor={'moneyOrderIssueDate'}
        >
          <div className={'eds-field_#label'}>
            {formatLabel('moneyOrderIssueDate', '*Issue Date')}
          </div>
          <div className="expiration-date-calendar-icon">
            <Calendar />
          </div>
          <div className={`eds-field_#control eds-field_.eds-input eds-input`}>
            <DatePicker
              selected={(source?.moneyOrderIssueDate && moment(source?.moneyOrderIssueDate).toDate()) || undefined}
              onChange={(date: Date, e: React.ChangeEvent<HTMLInputElement>) => changeDateHandler(date, 'moneyOrderIssueDate', e)}
              showFullMonthYearPicker
              className={`eds-input_#input`}
              shouldCloseOnSelect={true}
              name="moneyOrderIssueDate"
              dateFormat="MM/dd/yyyy"
              onBlur={() => onBlur('moneyOrderIssueDate')}
              placeholderText='MM/DD/YYYY'
            />
          </div>
          {showInputError('moneyOrderIssueDate')}
        </label>
      </div>
      <div className="flex-row">
        <EDS_TextBox
          modifiers="row-item row-item-size"
          name="moneyOrderPostOffice"
          label="Post Office"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'moneyOrderPostOffice')}
          value={source?.moneyOrderPostOffice ? source?.moneyOrderPostOffice : ''}
        ></EDS_TextBox>
      </div>
    </>
  )
}