import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../app/rootReducer";
import { GenericAttribute } from "../../../models/metaData/MetaData";
import { DataType, ValidatorType } from "../../../models/metaData/MetaDataEnums";
import { AddressSettings } from "../../../models/PaymentsConfiguration";
import { getAttribute, getLabel } from "../../../utils/metadata/MetaDataUtils";
import { email, required, length, validateGeneric } from "../../paymentDashboard/Validators";
import { addError, PatientMailingInformation, removeError } from "../PatientMailingInformationReducer";

export default function usePatientMailingInformationValidator(isGl: boolean = false, configuration?: AddressSettings) {
  const patientMailingInformation = useTypedSelector(
    i => i.patientMailingInformation?.value
  );
  const dispatch = useDispatch();

  function getAttributes(patientMailingInformation: PatientMailingInformation): GenericAttribute<PatientMailingInformation>[] {
    const attributes: GenericAttribute<PatientMailingInformation>[] = [
      { name: "facilityId", dataType: DataType.String, validators: [...isGl ? [required()] : []], visible:true  },
      { name: "departmentId", dataType: DataType.String, validators: [...isGl ? [required()] : []], visible: true },
      { name: "billingAddressLine1", dataType: DataType.String, validators: [...isRequiredByConfiguration("streetAddressRequired")], visible: true },
      { name: "billingAddressLine2", dataType: DataType.String, validators: [], visible: true },
      { name: "billingZipCode", dataType: DataType.String, validators: [...isRequiredByConfiguration("zipRequired"), length({ min: 5, max: 5 })], visible: true },
      { name: "billingCity", dataType: DataType.String, validators: [...isRequiredByConfiguration("cityRequired")], visible: true },
      { name: "billingState", dataType: DataType.String, validators: [...isRequiredByConfiguration("stateRequired")], visible: true },
      { name: "billingPhoneNumber", dataType: DataType.String, validators: [...isRequiredByConfiguration("phoneNumberRequired")], visible: true },
      { name: "notificationEmail", dataType: DataType.String, validators: [email()], visible:true  },
      { name: "addressLine1", dataType: DataType.String, validators: [], visible:true },
      { name: "addressLine2", dataType: DataType.String, validators: [], visible: true },
      { name: "zipCode", dataType: DataType.String, validators: [length({ min: 5, max: 5 })], visible: true },
      { name: "city", dataType: DataType.String, validators: [], visible: true },
      { name: "mailingState", dataType: DataType.String, validators: [], visible: true },
      { name: "phoneNumber", dataType: DataType.String, validators: [], visible: true },
    ]
    
    if(patientMailingInformation.firstName || patientMailingInformation.lastName) {
      attributes.push({ name: "firstName", dataType: DataType.String, validators: [], visible:true  });
      attributes.push({ name: "lastName", dataType: DataType.String, validators: [], visible:true  });
    }else {
      attributes.push({ name: "billingFirstName", dataType: DataType.String, validators: [required()], visible: true });
      attributes.push({ name: "billingLastName", dataType: DataType.String, validators: [required()], visible: true });
    }
    return attributes;
  };

  function isRequiredByConfiguration(fieldName: keyof AddressSettings) {
    return !configuration || configuration?.[fieldName] || configuration?.allAddressFieldsRequired ? [required()] : []
  }

  function validateAll(mailingInfo: PatientMailingInformation, validOnly: boolean = false) {
    let valid = true;

    getAttributes(mailingInfo).forEach(attribute => {
      let result = validate(attribute, mailingInfo[attribute.name], validOnly);
      valid = valid && result;
    });
    return valid;
  }

  function validate(attr?: GenericAttribute<PatientMailingInformation>, value?: any, validOnly: boolean = false): boolean {
    return validateGeneric({
      onPass: (key: keyof PatientMailingInformation, validatorType: ValidatorType) => dispatch(removeError({ key, validatorType })),
      onFail: (key: keyof PatientMailingInformation, validatorType: ValidatorType, message?: string) =>
        dispatch(addError({
          [key]: { [validatorType]: message || '' }
        })),
      attr,
      value,
      validOnly
    })
  }

  const errors = useTypedSelector(s => s.patientMailingInformation.errors);

  function getErrorMessage(name: keyof PatientMailingInformation) {
    return errors[name] && Object.values(errors[name]).length ? Object.values(errors[name]).join(', ') : '';
  }

  function errorClass(name: keyof PatientMailingInformation) {
    return errors[name] && Object.values(errors[name]).length ? 'invalid-field' : '';
  }

  function formatLabel(name: keyof PatientMailingInformation, label: string) {
    const attribute = getAttribute(getAttributes(patientMailingInformation), name);
    const prefix = attribute?.validators?.find(v => v.name == ValidatorType.RequiredValidator) ? "*" : "";
    return attribute ? getLabel({ ...attribute, label: `${prefix}${label}` }) : label;
  }

  return { validate, validateAll, attributes: getAttributes(patientMailingInformation), errorClass, getErrorMessage, formatLabel }
}