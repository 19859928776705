import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../app/rootReducer';
import { PatientModel } from '../../models/PatientModel';
import PaymentPlanModel, { PayerInformation } from '../../models/PaymentPlan';
import { ApiTenderTypeEnum, displayAmount, maskCardNumber, maskECheckNumber } from '../../utils/Utils';
import { Wizard, WizardStep } from '../wizard/Wizard';
import { getWizardSteps } from '../wizards/PaymentWizard';
import { useGetConfiguration } from './hooks/useGetConfiguration';
import {
  changeSelected,
  initialPlan,
  updateRecord,
} from './PaymentPlanReducer';
import { useTotal } from './useTotal';
import { OrganizationLevelDocument } from "../../models/OrganizationLevelDocument";
import { PatientMailingInformation } from '../paymentInfo/PatientMailingInformationReducer';
import EnumTransactionTenderType from '../../models/enums/EnumTransactionTenderType';
import { CreditCardBrand } from '../../models/enums/CreditCardBrand';
import { PlanOperations } from '../../models/metaData/MetaDataEnums';
import { EditPlanDetailsSteps, EditPlanTermSteps } from '../planDetails/useEditPlanTerms';


export function useOnCreateOrUpdatePlan(props: {
  wizard: Wizard,
  isGl?: boolean,
  onSuccess?: () => any,
  total?: number,
  plan?: PaymentPlanModel,
  patient?: PatientModel,
  organization?: OrganizationLevelDocument,
  userWizardSteps?: WizardStep[],
}
) {
  const {
    wizard,
    isGl = false,
    onSuccess,
    plan,
    patient,
    organization,
    userWizardSteps,
  } = props

  const dispatch = useDispatch();
  const wizardSteps = userWizardSteps ?? getWizardSteps(isGl);
  const paymentPlan = plan ?? { ...initialPlan };
  const calculatedTotal = useTotal(paymentPlan, isGl);
  const totalOnAddToPlan = useTotal(paymentPlan, isGl, false, true);
  const total = props.total ?? calculatedTotal;
  const [error, setError] = useState(false);
  const { getPlanConfiguration } = useGetConfiguration(
    organization?.path ?? '',
    () => { setError(true) },
    () => {
      onSuccess && onSuccess();
    });

  const payerInfo = useTypedSelector(s => s.patientMailingInformation.value);
  

  const billingInformation: PayerInformation = getBillingInformation(payerInfo);

  const mailingInformation: PayerInformation = getMailingInformation(payerInfo);

  const mapOnEditPaymentPlan = (conditional: { [key: string]: any }) =>{
    const hasPayerInformation = billingInformation.firstName && billingInformation.lastName;
    const planWithTenderInfo = getPlanWithTenderInfo(false, patient, initialPlan, payerInfo);
    const initialTenderType = initialPlan.tender?.type === "Undefined" ? undefined : paymentPlan?.tender?.type;
    return {
      id: paymentPlan.id,
      paymentPlan: {
        ...paymentPlan,
        ...conditional,
        authorized: false,
        tender: {
          ...paymentPlan.tender,
          ...planWithTenderInfo?.tender,
          type: paymentPlan.tender?.type ?? initialTenderType ?? paymentPlan?.tenderType,
          accountType: paymentPlan.tender?.accountType ?? initialPlan.tender?.accountType,
          method: paymentPlan.walletId
        },
        billingInformation: hasPayerInformation ?
          billingInformation : paymentPlan.billingInformation,
        mailingInformation: hasPayerInformation ? checkPayerInfo() : paymentPlan.mailingInformation,
        notes: hasPayerInformation ? 
          payerInfo.notes : paymentPlan.notes,
        notificationEmail: hasPayerInformation ?
          payerInfo.notificationEmail : paymentPlan.notificationEmail,
      },
    };
  };

  const checkPayerInfo = () => {
    return payerInfo.isChecked ? billingInformation : mailingInformation
  }

  const mapOnNewPaymentPlan = useCallback((conditional: { paymentAmount?: string }) => {
    const planWithTenderInfo = getPlanWithTenderInfo(false, patient, initialPlan, payerInfo);
    return {
      id: initialPlan.id,
      paymentPlan: {
        ...initialPlan,
        ...planWithTenderInfo,
        ...conditional,
        ...mapPatientMailingInformationToPlan(payerInfo),
        onlyBillingInformation: payerInfo.isChecked,
        isGl: isGl,
        authorized: false,
        tender: {
          ...initialPlan.tender,
          ...planWithTenderInfo?.tender,
        },
        organization,
        notes: payerInfo?.notes,
      },
    }
  }, [paymentPlan, payerInfo]);


  const getConditional = () => {
    let conditional: { paymentAmount?: string } = {};
    if (total != paymentPlan.amount) {
      conditional.paymentAmount = displayAmount(
        (total || 0) / (paymentPlan.paymentsRemaining || 1)
      )
    }
    return conditional;
  };

  const setSelected = () => {
    if (!plan) {
      dispatch(
        changeSelected({ id: paymentPlan.id ?? null, isSelected: true })
      );
    }
  };

  const onCreatePlan = useCallback(
    async () => {
      if (await getPlanConfiguration()) {
        wizard.selectStep(wizardSteps[0]);
      }
      const conditionalOnCreate = getConditional();

      const createdRecord = mapOnNewPaymentPlan(conditionalOnCreate);
      dispatch(updateRecord(createdRecord));
      setSelected();
    },
    [wizard, paymentPlan, dispatch, wizardSteps]
  );

  const onUpdatePlan = useCallback(
    async (operation: PlanOperations) => {
      if (await getPlanConfiguration()) {
        let step = wizardSteps.find(s => s.slug == EditPlanTermSteps[0]);
        if (PlanOperations.EditPlanDetails == operation) {
          step = wizardSteps.find(s => s.slug == EditPlanDetailsSteps[0]);
        }
        wizard.selectStep(step);
      }
      const conditionalOnUpdate = getConditional();

      const updatedRecord = mapOnEditPaymentPlan(conditionalOnUpdate);
      dispatch(updateRecord(updatedRecord));
      setSelected();
    },
    [wizard, paymentPlan, dispatch, wizardSteps]
  );

  const updatePaymentAmountOnAddedLineItem = () => {
    let conditional: { paymentAmount?: string } = {};
    if (totalOnAddToPlan != paymentPlan.amount) {
      conditional.paymentAmount = displayAmount(
        (totalOnAddToPlan || 0) / (paymentPlan.paymentsRemaining || 1)
      )
    }

    return conditional;
  };

  const onAddToPlan = useCallback(
    async (onSuccess?: () => void) => {
      if (await getPlanConfiguration()) {
        wizard.selectStep(wizardSteps[0]);
      }
      const conditionalOnUpdate = updatePaymentAmountOnAddedLineItem();
      const updatedRecord = mapOnEditPaymentPlan(conditionalOnUpdate);
      dispatch(updateRecord(updatedRecord));

      setSelected();
      onSuccess && onSuccess();
    },
    [wizard, paymentPlan, dispatch, wizardSteps]
  );

  return { onCreatePlan, onUpdatePlan, onAddToPlan, error };
}
export function getMailingInformation(payerInfo: PatientMailingInformation) {
  return {
    addressLine1: payerInfo.addressLine1,
    addressLine2: payerInfo.addressLine2,
    city: payerInfo.city,
    firstName: payerInfo.firstName,
    lastName: payerInfo.lastName,
    phone: payerInfo.phoneNumber,
    state: payerInfo.mailingState,
    zipCode: payerInfo.zipCode,
  }
}

export function getBillingInformation(payerInfo: PatientMailingInformation) {
  return {
    addressLine1: payerInfo.billingAddressLine1,
    addressLine2: payerInfo.billingAddressLine2,
    city: payerInfo.billingCity,
    firstName: payerInfo.billingFirstName || payerInfo.firstName,
    lastName: payerInfo.billingLastName || payerInfo.lastName,
    phone: payerInfo.billingPhoneNumber,
    state: payerInfo.billingState,
    zipCode: payerInfo.billingZipCode,
  }
}

export function mapPatientMailingInformationToPlan(patientMailingInformation: PatientMailingInformation) {
  const billingInformation = getBillingInformation(patientMailingInformation);

  return {
    billingInformation,
    notificationEmail: patientMailingInformation.notificationEmail,
    mailingInformation: patientMailingInformation.isChecked
      ? billingInformation
      : getMailingInformation(patientMailingInformation),
  }
}


export function getPlanWithTenderInfo(isOldPlan: boolean, patient?: PatientModel, paymentPlan?: PaymentPlanModel, patientMailingInformation?: PatientMailingInformation) {
  const tenderInformation = isOldPlan ? paymentPlan?.tender : {
    ...patientMailingInformation,
    type: patientMailingInformation?.tenderType,
    expirationDate: patientMailingInformation?.expiration,
    method: patientMailingInformation?.paymentFileTokenId
  };

  const tenderType = isOldPlan ? paymentPlan?.tender?.type : patientMailingInformation?.tenderType;
  const plan = paymentPlan ?? initialPlan;
  if (tenderType == ApiTenderTypeEnum.CardDevice) {
    return {
      ...plan,
      tenderMaskedAccount: maskCardNumber(tenderInformation?.deviceSerialNumber),
      tenderType,
      tender: {
        ...paymentPlan?.tender,
        type: tenderInformation?.type,
        device: {
          id: patientMailingInformation?.deviceId,
          name: patientMailingInformation?.deviceName,
          serialNumber: patientMailingInformation?.deviceSerialNumber,
        }
      }
    }
  }

  if (tenderType == ApiTenderTypeEnum.CardManual) {
    return {
      ...plan,
      id: paymentPlan?.id ?? null,
      isActive: paymentPlan?.isActive ?? false,
      tender: {
        ...paymentPlan?.tender,
        type: tenderInformation?.type,
        cardNumber: tenderInformation?.cardNumber,
        expirationDate: tenderInformation?.expirationDate,
        cvc: patientMailingInformation?.cvc,
        cardHolder: getCardHolderName({ firstName: tenderInformation?.billingFirstName, lastName: tenderInformation?.billingLastName }),
      },
      tenderAdditionalData: {
        ExpirationDate: tenderInformation?.expiration
      },
      tenderType,
      walletId: tenderInformation?.method ?? paymentPlan?.walletId ?? '',
      tenderMaskedAccount: maskCardNumber(tenderInformation?.cardNumber),
    }
  }

  if (tenderType == ApiTenderTypeEnum.ECheck) {
    return {
      ...plan,
      tenderType,
      tender: {
        ...paymentPlan?.tender,
        ...tenderInformation,
        cardNumber: maskECheckNumber(tenderInformation?.accountNumber),
        expirationDate: mockExpirationDate(),
      },
      walletId: tenderInformation?.method ?? paymentPlan?.walletId ?? '',
      tenderMaskedAccount: maskECheckNumber(tenderInformation?.accountNumber),
    };
  }
  if (tenderType == ApiTenderTypeEnum.SavedOnFile) {
    const paymentOnFile = patient?.paymentsOnFile?.find(
      p => p.tokenId == tenderInformation?.method
    );

    const cardBrand = paymentOnFile?.tenderType == EnumTransactionTenderType.Card
      ? paymentOnFile.cardBrand
      : CreditCardBrand[CreditCardBrand.NotSet];

    return paymentOnFile &&
    {
      ...plan,
      ...paymentPlan,
      tender: {
        ...tenderInformation,
        routingNumber: paymentOnFile.routingNumber,
        financialInstitution: paymentOnFile.financialInstitution,
        cardNumber: paymentOnFile.maskedAccount,
        expirationDate: paymentOnFile.cardExpirationDate,
      },
      walletId: paymentPlan?.tender?.method ?? paymentPlan?.walletId ?? '',
      tenderMaskedAccount: paymentOnFile.maskedAccount,
      cardBrand,
    };
  }
};

export function getCardHolderName(paymentCardData: {firstName?: string, lastName?: string}){
  const firstName = paymentCardData.firstName ?? '';
  const lastName = paymentCardData.lastName ?? '';

  if (firstName || lastName) {
    return `${firstName.trim() || ''} ${lastName.trim() || ''}`.trim();
  }
}

export function mockExpirationDate(expirationDate?: string){
  let date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  const regex = /\d{2}\/\d{2}/;

  if (expirationDate && regex.test(expirationDate)) {
    const parts = expirationDate?.split('/');
    date.setMonth(Number(parts[0]));
    date.setFullYear(Number(parts[1]) + 2000);
  } else {
    date.setMonth(Number(date.getMonth() + 2));
  }

  return date.toISOString();
};