export enum OrganizationLevelTypes {
  None = 0,
  Role = 1,
  Enterprise = 2,
  TradingPartner = 3,
  Facility = 4,
  Department = 5,
}

export default OrganizationLevelTypes;
