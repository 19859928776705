import { PaymentPanelDataType } from "models/enums/EnumPaymentPanels";
import { LineItem } from "../../../../models/LineItem";
import { MetaData } from "../../../../models/metaData/MetaData";
import { PaymentPanelApiConfiguration } from "../../../../services/PaymentPanelService";
import { Dashboards } from "../ConfigurationSelector";
import { useUpdatePaymentPanelConfiguration } from "./update/useUpdatePaymentPanelConfiguration";

export function useUpdateConfiguration(key?: keyof Dashboards, organizationPath?: string) {
  const { updatePaymentPanelConfiguration, updatePaymentPanelConfigurationDraft, updatePaymentPlansPaymentPanelConfiguration, updateGlPaymentPanelConfiguration, updatePatientSearchPanelConfiguration, updateEcnPaymentPanelConfiguration } = useUpdatePaymentPanelConfiguration(key, organizationPath);
  function updateConfiguration(configuration?: MetaData, isLive: boolean = false, key?: keyof Dashboards, isCounterPart?: boolean) {
    const currentEntity = configuration?.entity;
    const liveServices: { [key in keyof Dashboards]: (configuration: PaymentPanelApiConfiguration, isCounterPart?: boolean) => void } = {
      PaymentDashboard: updatePaymentPanelConfiguration,
      PaymentDashboardDraft: updatePaymentPanelConfiguration,
      PaymentPlanDashboard: updatePaymentPlansPaymentPanelConfiguration,
      GlPaymentDashboard: updateGlPaymentPanelConfiguration,
      PatientSearchPopupDashboard: updatePatientSearchPanelConfiguration,
      PaymentPlanDashboardDraft: updatePaymentPlansPaymentPanelConfiguration,
      GlPaymentDashboardDraft: updateGlPaymentPanelConfiguration,
      PatientSearchPopupDashboardDraft: updatePatientSearchPanelConfiguration,
      EcnPaymentDashboard: updateEcnPaymentPanelConfiguration,
      EcnPaymentDashboardDraft: updateEcnPaymentPanelConfiguration
    }

    const draftServices: { [key in keyof Dashboards]: () => void } = {
      PaymentDashboard: updatePaymentPanelConfigurationDraft,
      PaymentDashboardDraft: updatePaymentPanelConfigurationDraft,
      PaymentPlanDashboard: updatePaymentPlansPaymentPanelConfiguration,
      GlPaymentDashboard: updateGlPaymentPanelConfiguration,
      PatientSearchPopupDashboard: updatePatientSearchPanelConfiguration,
      PaymentPlanDashboardDraft: updatePaymentPlansPaymentPanelConfiguration,
      GlPaymentDashboardDraft: updateGlPaymentPanelConfiguration,
      PatientSearchPopupDashboardDraft: updatePatientSearchPanelConfiguration,
      EcnPaymentDashboard: updateEcnPaymentPanelConfiguration,
      EcnPaymentDashboardDraft: updateEcnPaymentPanelConfiguration 
    }

    if (configuration && currentEntity && key) {
      const services = isLive ? liveServices : draftServices;
      const id = isLive ? configuration.liveId : configuration.draftId;
      const service = services[key];
      service && service({
        ...currentEntity,
        distributionOrder: currentEntity.distributionOrder,
        colorCode: currentEntity.colorCode,
        panelTitle: currentEntity.panelTitle ? currentEntity.panelTitle : "DummyTitle",
        addRowEnabled:currentEntity.addRowEnabled,
        mappings: configuration.mappings,
        isDraft: !isLive,
        id,
        fieldSettings: currentEntity.attributes.map((e, index) => ({
          label: e.label,
          visible: !!e.visible,
          required: e.required,
          saveAs: e.saveAs as keyof LineItem,
          loadFrom: e.loadFrom,
          dataType: PaymentPanelDataType.Text,
          fieldType: e.fieldType,
          fieldTypeOnAddAccount: e.fieldTypeOnAddAccount,
          placeholderText : e.placeholderText,
          listOptions: e.listOptions,
          format: e.format,
          regex: e.regex,
          maxLength: e.maxLength,
          validationErrorMessage: e.validationErrorMessage,
          position: index + 1,
          visibleOnReceipt:e.visibleOnReceipt,
        }))
      },
      isCounterPart);
    }
  }

  function updateConfigurationByDashboard(panelkey: keyof Dashboards, configuration?: MetaData) {
    key = panelkey;
    updateConfiguration(configuration, true, panelkey);
  }

  return {
    updateConfiguration, updateConfigurationByDashboard
  }
}
