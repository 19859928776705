import React from 'react';
import moment from 'moment';
import { useTypedSelector } from 'app/rootReducer';
import { EDS_Button } from '@EH/eh.eds.react';
import Footer from 'features/footer/Footer';
import { PaymentPlanModel } from '../../models';
import { ApiTenderTypeEnum, maskECheckNumber, ApiTenderTypeEnumLabels, coalesce, checkReceivedHasValues } from '../../utils/Utils';
import PayerMailingInformation from './infoSections/PayerMailingInformation';
import PayerBillingInformation from './infoSections/PayerBillingInformation';
import BalanceHeader from './infoSections/BalanceHeader';
import PaymentPlanTerms from './infoSections/PaymentPlanTerms';
import { useCreateOrUpdatePaymentPlan } from './hooks/useCreateOrUpdatePaymentPlan';
import '@experian/eds-styles/site-lib/material-design-icons/iconfont/material-icons.css';
import '@experian/eds-styles/dist/eds-all.css';
import '../../assets/styles/components/_paymentPlan.scss';
import { CardBrandIcon, cardBrand } from 'components/cardBrandIcon/cardBrandIcon';
import { CreditCardAdditionalData, ECheckAdditionalData } from 'models/PaymentPlan';
import { AlertIds, AlertTypes, setAlert } from '../alert/AlertReducer';
import { PaymentOnFile } from "models/PatientModel";
import { useGetPatientById } from "../patients/hooks/useGetPatientById";
import { useDispatch } from 'react-redux';
import EnumTransactionTenderType from 'models/enums/EnumTransactionTenderType';
import { PaymentConfiguration } from 'models/PaymentsConfiguration';
import { getNextMonthYearString } from 'utils/UtilsDateTime';

interface TenderTypeProps {
  type?: string;
  cardType?: string;
  tenderMaskedAccount?: string;
  tenderAdditionalData?: CreditCardAdditionalData | ECheckAdditionalData;
  method?: PaymentOnFile | undefined;
}
interface eCheckTenderTypeProps extends TenderTypeProps {
  cardNumber?: string;
  paperCheckNumber?: string;
  routingNumber?: string;
  accountNumber?: string;
  financialInstitution?: string;
}

interface CreditDebitTenderTypeProps extends TenderTypeProps {
  cardNumber?: string;
  expirationDate?: string;
  cvc?: string;
  tenderAdditionalData?: CreditCardAdditionalData | ECheckAdditionalData;
}

export default (props: {
  onContinue?: () => void;
  onBack?: () => void;
  cancel?: () => void;
  total?: number;
  amount?: number;
  discount?: number;
  paymentPlan?: PaymentPlanModel;
  patientId?: string;
  selectedOrganizationPath?: string;
}) => {
  const {
    onContinue,
    onBack,
    total,
    amount,
    discount,
    cancel,
    paymentPlan,
    patientId,
    selectedOrganizationPath
  } = props;

  const back = () => {
    if (onBack) onBack();
  };

  const lineItems = useTypedSelector(s => s.paymentDashboard?.values?.[selectedOrganizationPath ?? '']?.lineItems || []);
  const dispatch = useDispatch();
  const patient = useGetPatientById(patientId);

  const configuration: PaymentConfiguration | undefined = useTypedSelector(
    state => state.paymentDashboard?.configurations?.[selectedOrganizationPath ?? '']?.configuration
  );
  const isMailingAddressRequired = coalesce(true, configuration?.mailingAddressRequired);

  const isProcessing = useTypedSelector(s => s.services.calls.createOrUpdatePaymentPlan?.isProcessing);

  const { createPaymentPlan } = useCreateOrUpdatePaymentPlan(
    {
      paymentPlan,
      lineItems,
      total,
      onSuccess: onContinue,
      onError: (message?:string) => dispatch(setAlert({ id: AlertIds.PlanAlert, type: AlertTypes.Error, message: message || 'Could not create Payment Plan' })),
      patientId,
      selectedOrganizationPath,
      isMailingAddressRequired: configuration?.mailingAddressRequired
    }
  );

  const method = paymentPlan?.tender?.type && [ApiTenderTypeEnum.SavedOnFile.toString(), ApiTenderTypeEnum.Undefined.toString()].includes(paymentPlan.tender.type) ?
    patient.patient?.paymentsOnFile?.filter(m => m?.tokenId === paymentPlan?.tender?.method)[0]
    : undefined;
  const footerChildren = (
    <div className="footer-buttons">
      <EDS_Button
        modifiers={'eds-button eds-button.basic'}
        name={'back'}
        buttonText={'Back'}
        onClick={back}
        iconName={'chevron_left'}
      />
      <div className="footer-flex">
        <EDS_Button
          modifiers={'mr-2 eds-button eds-button.basic'}
          name={'cancel'}
          buttonText={'Cancel'}
          onClick={cancel}
        />
        <EDS_Button
          modifiers={'eds-button eds-button.primary button-reverse'}
          name={'createPaymentPlan'}
          buttonText={isProcessing ? 'Processing ...' : 'Next'}
          onClick={createPaymentPlan}
          disabled={isProcessing}
          iconName={isProcessing ? '' : 'chevron_right'}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className="payment-plan-container">
        <BalanceHeader total={total} amount={amount} discount={discount}/>
        <div className="payment-plan-form-container">
          <div className="flex-row">
            {paymentPlan && <PaymentPlanTerms {...paymentPlan} total={total} />}
          </div>
          <div className="flex-row">
            <TenderType
              {...paymentPlan?.tender}
              type={paymentPlan?.tender ? paymentPlan?.tender.type : paymentPlan?.tenderType}
              tenderMaskedAccount={paymentPlan?.tenderMaskedAccount}
              tenderAdditionalData={paymentPlan?.tenderAdditionalData}
              method={method}
            />
          </div>
          <div className="flex-row">
            <PayerBillingInformation
              payerInformation={paymentPlan?.billingInformation}
              mailingInformation={paymentPlan?.mailingInformation}
            />
          </div>
          {isMailingAddressRequired && (
            <div className="flex-row">
              <PayerMailingInformation
                payerInformation={paymentPlan?.onlyBillingInformation ? paymentPlan?.billingInformation : paymentPlan?.mailingInformation}
              />
            </div>
          )}
          <div className='row'>
            <div className="col-6 font-weight-bold eds-field_#label">Notes</div>
            <div className="col-6">{paymentPlan?.notes}</div>
          </div>
          <div className="row">
            <div className="col-6 font-weight-bold">Notification Email:</div>
            <div className="col-6">{paymentPlan?.notificationEmail}</div>
          </div>
        </div>
      </div>
      <Footer children={footerChildren} />
    </>
  );
};

const ECheckTenderType = (props: eCheckTenderTypeProps) => {
  const {
    routingNumber,
    financialInstitution,
    tenderMaskedAccount,
    method
  } = props;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-9 pr-0">
          {routingNumber && (
            <div className="row">
              <div className="col-6 font-weight-bold">Routing Number:</div>
              <div className="col-6 p-0">{method ? method?.routingNumber : maskECheckNumber(routingNumber)}</div>
            </div>
          )}
          <div className="row">
            <div className="col-6 font-weight-bold">Account Number:</div>
            <div className="col-6 p-0">
              {method ? method?.maskedAccount : tenderMaskedAccount}
            </div>
          </div>
          {financialInstitution && (
            <div className="row">
              <div className="col-6 font-weight-bold">Financial Institution:</div>
              <div className="col-6 p-0">{method ? method?.financialInstitution : financialInstitution}</div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const CreditDebitTenderType = (props: CreditDebitTenderTypeProps) => {
  const { cardType, tenderMaskedAccount, tenderAdditionalData, method } = props;
  const card = method ? method?.cardBrand?.toUpperCase() : cardType?.toUpperCase() ?? null;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-9 pr-0">
          <div className="row">
            <div className="col-6 font-weight-bold" >Card Type:</div>
            <div className="col-6 p-0" >{method ? method?.cardBrand : cardType}</div>
          </div>
          <div className="row">
            <div className="col-6 font-weight-bold" >Card Number:</div>
            <div className="col-6 p-0" >
              {method ? method?.maskedAccount : tenderMaskedAccount}
            </div>
          </div>
          <div className="row">
            <div className="col-6 font-weight-bold" >Expiration Date:</div>
            <div className="col-6 p-0">{getNextMonthYearString(method ? method?.cardExpirationDate : (tenderAdditionalData as CreditCardAdditionalData).ExpirationDate)}</div>
          </div>
        </div>
        <div className="col-3 pl-0">
          <div className="col-3 text-right">
            <CardBrandIcon brand={cardBrand[card as keyof typeof cardBrand]} width="68px" height="38px" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const TenderType = (props: TenderTypeProps) => {
  const { type, cardType, method } = props;
  const paymentType = type != null && (method?.tenderType != null ? method?.tenderType : ApiTenderTypeEnumLabels[type]);
  return (
    <div className="row-item row-item-size-double">
      <div className="eds-heading eds-heading.mdplus-caps mb-1">
        Tender Type
      </div>
      <div className="row">
        <div className=" col-9 pr-0">
          <div className="row">
            <div className="col-6 font-weight-bold">Payment Type:</div>
            <div className="col-6 p-0">
              {type != null &&
                (type === ApiTenderTypeEnum.CardDevice
                  ? cardType
                  : paymentType)}
            </div>
          </div>
        </div>
      </div>
      {type === ApiTenderTypeEnum.ECheck && <ECheckTenderType {...props} />}
      {type === ApiTenderTypeEnum.CardDevice && (
        <CreditDebitTenderType {...props} />
      )}
      {type === ApiTenderTypeEnum.CardManual && (
        <CreditDebitTenderType {...props} />
      )}
      {type === ApiTenderTypeEnum.SavedOnFile && (
        method?.tenderType && (method.tenderType === EnumTransactionTenderType.eCheck
          ? <ECheckTenderType {...props} /> : <CreditDebitTenderType {...props} />)
      )}
    </div>
  );
};
