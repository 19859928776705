import { GridColumnsChangedEvent, GridReadyEvent, ValueGetterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { UserDetailModel } from "../../../../models/UserInfoAndRolesModel";
import { getOrganizationHeader, nameof } from "../../../../utils/Utils"; 
import { useOrganizations } from "features/organizations/hooks/useOrganizations";
import OrganizationLevelTypes from "models/enums/OrganizationLevelTypes";


export default function UserList(props: {
  users: UserDetailModel[],
  onChangeSelected: (user: UserDetailModel, organizationPath: string) => void,
}) {
  const { users, onChangeSelected } = props;
  const { getOrganizationWithFallback } = useOrganizations();

  const rowData: UserDetailModel[] = [];

  users.forEach(u => {
    u.organizationLevels?.forEach(o => {
      rowData.push({
        ...u,
        organizationLevels: [o]
      })
    })
  })

  const headerDefinitions = [
    
    {
      headerName: "Last Name",
      field: nameof<UserDetailModel>("lastName"),
    },
    {
    headerName: "First Name",
      field: nameof<UserDetailModel>("firstName"),
      
    },
    {
      headerName: "OneSource User ID",
      field: nameof<UserDetailModel>("oneSourceUserId"),
    },
    {
      headerName: "Organization",
      field: nameof<UserDetailModel>("organizationLevels"),
      valueGetter: (params: ValueGetterParams) => {
        if (params.data?.organizationLevels === undefined) {
          return '';
        }
        const path  = params.data?.organizationLevels?.find((o: any) => true)?.organizationLevel_Path ?? '';
        return getOrganizationWithFallback(path, OrganizationLevelTypes.TradingPartner)?.name ?? '-';
      }
    },
    getOrganizationHeader(getOrganizationWithFallback, OrganizationLevelTypes.Facility),
    getOrganizationHeader(getOrganizationWithFallback, OrganizationLevelTypes.Department),
  ];

  function sizeColumnsToFit(params: GridReadyEvent | GridColumnsChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  return <div className="ag-theme-alpine">
    <AgGridReact
      columnDefs={headerDefinitions}
      defaultColDef={{
        sortable: false,
        resizable: true,
        filter: false,
      }}
      suppressDragLeaveHidesColumns={true}

      rowSelection={"single"}
      onSelectionChanged={(e) => {
        const rows = e.api.getSelectedRows();
        const data = rows[0];
        onChangeSelected(data, data.organizationLevels[0]?.organizationLevel_Path);
      }}

      onGridReady={sizeColumnsToFit}
      onGridColumnsChanged={sizeColumnsToFit}
      rowData={rowData}
      pagination={false}
      domLayout={'autoHeight'}
      gridAutoHeight={true}
      suppressColumnVirtualisation={process.env.NODE_ENV === 'test'}
      enableCellTextSelection={false}
      ensureDomOrder={true}
    />
  </div>
}