import { RemitConfiguration } from "models/admin/RemitConfiguration";
import { useDispatch } from "react-redux";
import { updateRemitConfiguration, createRemitConfiguration } from "services/RemitService";
import { useCallService } from "services/useCallService";
import { setRemitConfiguration } from "../RemitConfigurationReducer";
import { mapRemitConfigForApi } from "./RemitConfigurationMapper";

export function useSaveRemitConfiguration() {
  const callService = useCallService();
  const dispatch = useDispatch();
  const saveRemitConfiguration = async (remitConfiguration: RemitConfiguration) => {
    const mapped = mapRemitConfigForApi(remitConfiguration);
    return callService("saveRemitConfiguration", async () => {
      if (remitConfiguration.id) {
        return updateRemitConfiguration(remitConfiguration.id, mapped);
      } else {
        const request = await createRemitConfiguration(mapped);
        if(request?.result){
          dispatch(setRemitConfiguration({
            ...remitConfiguration,
            id: request.result.id
          }))          
        }
        return request;
      }
    });
  }

  return {
    saveRemitConfiguration
  }
}