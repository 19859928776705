import { EDS_Select } from '@EH/eh.eds.react';
import { Attribute } from 'models/metaData/MetaData';
import {
  DataType,
  ValidatorType,
  ValueType,
} from 'models/metaData/MetaDataEnums';
import React, { useCallback } from 'react';
import {
  getLabel,
  getValidator,
  getValueType,
} from 'utils/metadata/MetaDataUtils';
import { LineItem } from '../../../models/LineItem';

export function DropDown(props: {
  attribute: Attribute;
  value: any;
  onChange?: (value: any) => void;
  modifiers?: string;
  lineItem?: LineItem;
}) {
  const { attribute, value, onChange, modifiers, lineItem } = props;

  const label = getLabel(attribute);
  const name = attribute.name;
  const dataType = attribute.dataType;

  const valueType = getValueType(attribute);
  const suffix = [ValueType.Percent, ValueType.Discount].includes(valueType) ? '%' : '';

  const rangeValidator = getValidator(attribute, ValidatorType.RangeValidator);
  const range = rangeValidator?.value || [];
  let options = range.length > 0 ? range.map((r: any) => ({
    optionName: r + suffix,
    value: r + suffix,
  })) : attribute.listOptions?.map((o) => ({ optionName: o.displayValue, value: o.savedValue }));

  if(options?.length) {
    options.unshift({ optionName: 'Select', value: '' });
  }

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (!onChange) {
        return;
      }

      // for decimal data type parse the string
      const valueToChange =
        dataType === DataType.Decimal
          ? parseFloat(
              e.target.value.substring(0, e.target.value.length - suffix.length)
            )
          : e.target.value;

      onChange(valueToChange);
    },
    [dataType, onChange, suffix.length]
  );

  let discountAmount = 0;
  if (ValueType.Discount == valueType) {
    discountAmount = (value || 0) * (lineItem?.amount ?? 0) / 100;
  }

  return (
    <>
      <EDS_Select
        modifiers={modifiers}
        name={name}
        label={label}
        onChange={handleChange}
        value={value + suffix}
        options={options}
      />
        {discountAmount ? <span className={'discount-dolar-amount'} >-${discountAmount}</span> : null}
      </>
  );
}
