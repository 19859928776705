import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MerchantConfig, MerchantConfigs } from 'models/MerchantConfig';
import {
  createMerchantConfigService,
  getMerchantConfigService,
  GetMerchantConfigResponse,
} from 'services/MerchantConfigServce';
import { AppThunk } from 'app/rootReducer';
import { callService } from '../../services/ServicesReducer';

export interface MerchantConfigState {
  facility: string;
  provider: string;
  configName: string;
  sourceKey: string;
  pin: string;
  timeout: string;
  tbApiId: string;
  tbApiToken: string;
  tbReceiptOption: string;
  tbReceiptId: string;
  tbMid: string;
  tbTid: string;
  paymentUrl: string;
  adminNotes: string;
  isActive: boolean;
}

export interface State {
  value: MerchantConfigState;
  err?: string;
  result?: MerchantConfigs;
}

export const initialState: State = {
  value: {
    facility: '',
    provider: '',
    configName: '',
    sourceKey: '',
    pin: '',
    timeout: '',
    tbApiId: '',
    tbApiToken: '',
    tbReceiptOption: '',
    tbReceiptId: '',
    tbMid: '',
    tbTid: '',
    paymentUrl: '',
    adminNotes: '',
    isActive: false,
  },
  err: '',
  result: undefined,
};

const reducerSlice = createSlice({
  name: 'MerchantConfig',
  initialState,
  reducers: {
    onReceiveMerchantConfig(
      state,
      action: PayloadAction<GetMerchantConfigResponse>
    ) {
      state.result = action.payload.result;
      state.err = action.payload.err;
    },
    setMerchantConfig(state, action: PayloadAction<MerchantConfigState>) {
      state.value = action.payload;
    },
    resetMerchantConfig(state) {
      state.value = initialState.value;
    },
  },
});

export const {
  onReceiveMerchantConfig,
} = reducerSlice.actions;
export default reducerSlice.reducer;

export function getMerchantConfig(path: string, onDone?: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService("merchantConfigService", async () => {
      const response = await getMerchantConfigService(path);
      dispatch(onReceiveMerchantConfig(response));
      if (onDone) onDone();
    }))
  };
}

export function createMerchantConfig(
  merchantConfig: MerchantConfig,
  onDone?: () => void
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService("merchantConfigService", async () => {
      await createMerchantConfigService(merchantConfig);
      if (onDone) onDone();
    }))
  };
}

export const { setMerchantConfig, resetMerchantConfig } = reducerSlice.actions;
