import { useDispatch } from "react-redux";
import { ClientFacilityConfiguration } from "../../../../models/Client";
import { SaveClientConfiguration } from "../../../../services/ClientService";
import { AlertIds, AlertTypes, setAlert } from "../../../alert/AlertReducer";

export const clientConfigAlert = {
  id: AlertIds.ClientConfiguration,
  type: AlertTypes.Error,
  message: '',
};
export default function useSaveClientConfiguration() {
  const dispatch = useDispatch();
  async function saveClientConfiguration(clientConfiguration: ClientFacilityConfiguration) {
    const result = await SaveClientConfiguration({
      ...clientConfiguration,
      tradingPartnerId: clientConfiguration.tradingPartnerId ? parseInt(clientConfiguration.tradingPartnerId) : undefined
    });
    if (result && result?.err) {
      dispatch(
        setAlert({
          ...clientConfigAlert,
          message: `Error Configuration not saved. ${result.errorResponse?.data}`,
        })
      );
    } else {
      dispatch(
        setAlert({
          ...clientConfigAlert,
          type: AlertTypes.Success,
          message: `Success Configuration created.`,
          dismissable: true,
        })
      );
    }
  }

  return { saveClientConfiguration };
}