import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { setBreadcrumbLinks } from 'features/breadcrumb/BreadcrumbReducer';
import { useTypedSelector } from 'app/rootReducer';
import { useDispatch } from 'react-redux';
import {
  Organization,
  GeneralConfiguration,
  SaveClientFacilityConfiguration,
} from 'models/Client';
import 'assets/styles/components/_clientConfiguration.scss';
import ConfigurationTemplate from '../../features/admin/clientConfiguration/ConfigurationTemplate';
import ClientConfigurationForm, {
  ClientInfo,
} from '../../features/admin/logoManager/ClientConfigurationForm';
import { SaveClientConfiguration } from 'services/ClientService';
import {
  AlertIds,
  AlertTypes,
  setAlert,
} from '../../features/alert/AlertReducer';
import { getFacilityOptions } from 'utils/UtilsOrganizationDropdowns';
import { getClientOrganizationService, updateOrganization } from '../../services/OrganizationService';
import { useOrganizations } from '../../features/organizations/hooks/useOrganizations';
import { onReceiveGetClientConfiguration } from 'features/admin/GetClientConfigurationReducer';

import * as router from 'react-router-dom';
import { setOrganization } from 'features/organizations/OrganizationsReducer';
import { emptyStringsToNull } from 'utils/Utils';
import { setCall } from 'services/ServicesReducer';
import { FullOrganizationLevelDocument } from 'models/OrganizationLevelDocument';
import { DataRequiredForPayments } from '../../features/admin/clientConfiguration/DataRequiredForPayments';
import { useSavePaymentConfiguration } from '../../features/admin/clientConfiguration/useSavePaymentConfiguration';
import { useGetPaymentConfiguration } from '../../features/singlePayment/hooks/useGetPaymentConfiguration';

interface Props {
  children?: React.ReactNode;
  rootPath?: string;
}

interface UpdateFacility {
  data: FullOrganizationLevelDocument ;
  sucsessMassege: string;
  errorMessage: string;
}

function useBreadcrumb(rootPath?: string) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setBreadcrumbLinks([{ name: 'Client Configuration', slug: rootPath }])
    );

    return () => {
      dispatch(setBreadcrumbLinks([]));
    };
  }, [rootPath, dispatch]);
}

export default function ClientConfiguration(
  props: Props = { rootPath: 'client-configuration' }
) {
  const { rootPath } = props;
  const [orgWithNewAccessPartnerGrouping, setOrgWithNewAccessPartnerGrouping] = useState({id: '', name: '', path: '', organizationLevelType: 0, clientId: ''});
  useBreadcrumb(rootPath);
  const isProcessing = useTypedSelector(s => s.services.calls.GetClientConfiguration?.isProcessing);
  const clientConfiguration = useTypedSelector(
    s => s.getClientFacilityConfiguration?.result
  );
   const isProcessingConfiguration = useTypedSelector(s => s.services.calls.GetClientConfiguration?.isProcessing);
  const error = useTypedSelector(s => s.client?.err);
  const imageFile = clientConfiguration?.logos.find(x => x.isSelected === true)
    ?.imageData;
  const dispatch = useDispatch();
  const organizations = useTypedSelector(s => s.organizations?.value);
  const facilityOptions = useMemo(() => getFacilityOptions(organizations), [
    organizations,
  ]);
  const { getOrganizationById } = useOrganizations();

  const params = router.useParams<{ clientId: string }>();
  const selectedFacilityId = params.clientId;
  const organization = getOrganizationById(selectedFacilityId);

  const user = useTypedSelector(s => s.loginInfo.loggedUser);

  const { getConfiguration } = useGetPaymentConfiguration(organization?.path ?? '');

  useEffect(() => { getConfiguration() }, [organization?.path])

  const paymentConfiguration = useTypedSelector(s => s.paymentDashboard?.configurations[organization?.path ?? '']?.configuration)

  const setClientConfigurationToFormValues = (
    conf?: SaveClientFacilityConfiguration
  ) => {
    const tradingPartnerConverted = clientConfiguration?.tradingPartnerId || '';
    const orgConverted = conf?.organization?.name || '';
    return {
      organization: conf?.organization,
      facilityDescription: orgConverted,
      tradingPartnerId: parseInt(tradingPartnerConverted) || 0,
      isEnabled: organization?.isEnabled,
      isSuppressedFromFacilityList: conf?.isSuppressedFromFacilityList,
      isSuppressedFromPatientFacingDisplay: conf?.isSuppressedFromPatientFacingDisplay,
      hasAccessPartnerData: conf?.hasAccessPartnerData,
      isDefaultAmountSetToZero: conf?.isDefaultAmountSetToZero,
      isCombineHospitalUsers: conf?.isCombineHospitalUsers,
      isDefaultUserDeptToAllInSearch: conf?.isDefaultUserDeptToAllInSearch,
      showZeroBalances: conf?.showZeroBalances
    };
  };

  const setFromValues = (
    values: ClientInfo,
  ) => {
    let formValues = {
      id: values.id,
      organization: {
        id: organization?.id,
        path: organization?.path,
        name: organization?.name,
      },
      facilityDescription: values?.facilityDescription,
      tradingPartnerId: parseInt(values.tradingPartnerId),
      isEnabled: values.isEnabled,
      isSuppressedFromFacilityList: values.isSuppressedFromFacilityList,
      isSuppressedFromPatientFacingDisplay: values.isSuppressedFromPatientFacingDisplay,
      isDefaultAmountSetToZero: values.isDefaultAmountSetToZero,
      isCombineHospitalUsers: values.isCombineHospitalUsers,
      isDefaultUserDeptToAllInSearch: values.isDefaultUserDeptToAllInSearch,
      showZeroBalances: values.showZeroBalances
    };
    return emptyStringsToNull(formValues);
  };

  const { savePaymentConfiguration } = useSavePaymentConfiguration(organization?.path);

  const onSubmitClientConfigForm = (values: ClientInfo) => {
    savePaymentConfiguration({
      ...paymentConfiguration,
      organization: {
        ...paymentConfiguration.organization,
        id: organization?.id || "",
        name: organization?.name || "",
        path: organization?.path || ""
      }
    });
    submitForm(values);
    if (orgWithNewAccessPartnerGrouping) {
      dispatch(setOrganization(orgWithNewAccessPartnerGrouping));
    }
  };

  const clientConfigAlert = {
    id: AlertIds.ClientConfiguration,
    type: AlertTypes.Error,
    message: '',
  };

  const organizationAlert = {
    id: AlertIds.OrganizationAlert,
    type: AlertTypes.Error,
    message: '',
  };

  const submitFormUpdateFacility = async ( updatedFacility: UpdateFacility ) => {
    const organizationUpdateResult = await updateOrganization(updatedFacility.data);
    if (organizationUpdateResult && !organizationUpdateResult?.err) {
      !!updatedFacility.data.clientId && dispatch(
        setAlert({
          ...organizationAlert,
          type: AlertTypes.Success,
          message: updatedFacility.sucsessMassege,
          dismissable: true,
        })
      );
    } else {
      dispatch(
        setAlert({
          ...organizationAlert,
          message: updatedFacility.errorMessage,
        })
      );
    }
  }

  const updatedIsEnabled = (values: ClientInfo) => {
    if(!organization) return
    return {
      data:{ 
        ...organization, 
        isEnabled: values?.isEnabled, 
        lastModifiedBy: user?.id 
      }, 
      sucsessMassege: `${values.isEnabled ? 'Enabling': 'Disabling'} Facility will take effect when users login next time.`,
      errorMessage: `Could not ${values.isEnabled ? 'Enable': 'Disable'} Facility.`
    }
  }  

  const updatedAPG = () => {
    return {      
      data:{ 
        ...orgWithNewAccessPartnerGrouping, 
        lastModifiedBy: user?.id 
      }, 
      sucsessMassege: "The changes in the Access Partner Grouping will take effect when users login next time.",
      errorMessage: "Could not update Access Partner."      
    }
  }  

  const updatedAPGAndIsEnabled = (values: ClientInfo) => {
    return {      
      data:{ 
        ...orgWithNewAccessPartnerGrouping,   
        isEnabled: values.isEnabled,            
        lastModifiedBy: user?.id 
      }, 
      sucsessMassege: `The changes in the Access Partner Grouping and ${values.isEnabled ? 'Enabling': 'Disabling'} Facility will take effect when users login next time.`,
      errorMessage: `Could not update Access Partner and  ${values.isEnabled ? 'Enable': 'Disable'} Facility.`     
    }
  }  
  
  const submitForm = useCallback(
    async (values: ClientInfo) => {
      dispatch(setCall({ GetClientConfiguration: { isProcessing: true } }));
      let updatedFacility;
      if(values.isEnabled !== organization?.isEnabled && !orgWithNewAccessPartnerGrouping.id) {
        updatedFacility = updatedIsEnabled(values)
        if(!updatedFacility) return
        await submitFormUpdateFacility(updatedFacility)
      }
      if (orgWithNewAccessPartnerGrouping.id && values.isEnabled === organization?.isEnabled ) {
        updatedFacility = updatedAPG()
        await submitFormUpdateFacility(updatedFacility)
      }
      if (orgWithNewAccessPartnerGrouping.id && values.isEnabled !== organization?.isEnabled ) {
        updatedFacility = updatedAPGAndIsEnabled(values)
        await submitFormUpdateFacility(updatedFacility)
      }

      let getClientOrganizationResponse;
      if (updatedFacility) {
        getClientOrganizationResponse = await getClientOrganizationService({ path: organization?.path });
      }

      const {isEnabled, ...configData} = values

      const data: GeneralConfiguration = setFromValues(
        configData,
      );
      const result = await SaveClientConfiguration(data);
      if (result && result?.err) {
        dispatch(
          setAlert({
            ...clientConfigAlert,
            message: `Error Configuration not created. ${result.errorResponse?.data}`,
          })
        );
      } else {
        dispatch(
          setAlert({
            ...clientConfigAlert,
            type: AlertTypes.Success,
            message: `Success Configuration created.`,
            dismissable: true,
          })
        );
      }

      if (getClientOrganizationResponse?.result?.length) {
        dispatch(setOrganization(getClientOrganizationResponse?.result[0]));
      }

      dispatch(
        onReceiveGetClientConfiguration({
          result: result.result as any
        })
      );
      dispatch(setCall({ GetClientConfiguration: { isProcessing: false } }));
    },
    [dispatch, organization, orgWithNewAccessPartnerGrouping]
  );

  const accessPartnerGroupingUpdated = (accessProps: any) => {
    setOrgWithNewAccessPartnerGrouping(accessProps);
  }


  const [openTab, setOpenTab] = useState(0);

  const renderConfigurationForm = () => {
    if (clientConfiguration) {
      return (
        <ConfigurationTemplate title={'General Settings'}>
          <div className="eds-heading eds-heading.mdplus-caps mb-1">Facility Info</div>
          <ClientConfigurationForm
            formValues={setClientConfigurationToFormValues(clientConfiguration)}
            onSubmit={onSubmitClientConfigForm}
            submitting={isProcessingConfiguration}
            logoSelected={imageFile}
            organizationOptions={facilityOptions}
            accessPartnerGroupingUpdated={accessPartnerGroupingUpdated}
          />
          <DataRequiredForPayments
            configuration={paymentConfiguration}
            organizationPath={organization?.path ?? ""}
            openTab={openTab}
            setOpenTab={setOpenTab}
          />
        </ConfigurationTemplate>
      );
    } else {
      return (
        <div className="server-error">
          {`Unknown client configuration option`}
        </div>
      );
    }
  };

  if (isProcessing) {
    return <p>Processing</p>;
  } else if (error && error.length) {
    return (
      <div className="server-error">{`An error occurred while fetching the Client Configuration data: ${error}`}</div>
    );
  } else {
    return <div>{renderConfigurationForm()}</div>;
  }
}
