import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'app/rootReducer';
import { clearState } from './TransactionDetailsReducer';
import { AccountDetails } from 'features/accountDetails/AccountDetails';
import { RelatedTransactions } from './RelatedTransactions';
import 'assets/styles/components/_transactionDetailsModal.scss';
import 'assets/styles/components/_paymentPlanDetails.scss';
import { useSetOpenedTransaction } from '../searchTransaction/hooks/useSetOpenedTransaction';
import TransactionDetails from './common/TransactionDetails';
import { PatientDetails } from './common/PatientDetails';
import { Header } from './common/Header';
import {useAccountDetails, Source} from 'features/accountDetails/hooks/useAccountDetails';
import { ReactComponent as Close } from 'assets/svgs/icon-popup-close.svg';

export default function TransactionDetailsModal() {
  const dispatch = useDispatch();
  const stateTransactionDetails = useTypedSelector(s => s.transactionDetails);

  const onClose = () => {
    dispatch(clearState());
  };

  const setOpenedTransaction = useSetOpenedTransaction();
  const onDone = () => {
    if (stateTransactionDetails.parentId) {
      setOpenedTransaction(stateTransactionDetails.parentId);
    } else {
      onClose();
    }
  }
  
  const transactionDetails = stateTransactionDetails?.transactionDetailsData?.transactionDetails;
  useAccountDetails(transactionDetails);
  return (
    <section className={`payment-details-modal eds-modal eds-modal.open`}>
      <div className="eds-modal_#container detailsContainer" key={transactionDetails?.id}>
        <header className="eds-modal_#header headerTransactionDetails">
            <div className="headerTransactionDetailsText">
              <span>PaymentSafe® | Payment Detail</span>              
            </div>
            <div className="text-right  iconCancelButton" onClick={onClose}>
              <Close />
            </div>
        </header>
        <div className="eds-modal_#content payment-details-container">
          <div className="">
            <div className="payment-details-header">
              <Header />
              <PatientDetails />
              <TransactionDetails />
              <div className="card">
                <div className="card-header">
                  <span>Account Detail</span>
                </div>
                <div className="card-content">
                  <AccountDetails 
                    source={Source.Transaction}
                    data={transactionDetails} 
                  />
                </div>
              </div>        
              <div className="card">
                <div className="card-header">
                  <span>Related Transactions</span>
                </div>
                <div className="card-content">
                  <RelatedTransactions relatedTransactionArray={stateTransactionDetails?.transactionDetailsData?.relatedTransactions} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="eds-modal_#footer">
          <hr />
          <div className="text-right mr-4 mb-2">
            <button className="eds-button eds-button.basic" onClick={onDone}>
              <span className="button-label">Done</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
