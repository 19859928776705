import { PaymentPlanModel } from "../../../models";
import { addPaymentOnFileService } from "../../../services/patient/PatientService";
import { ApiTenderTypeEnum, maskCardNumber, maskECheckNumber} from 'utils/Utils';
import { EnumTransactionTenderType } from "models/enums/EnumTransactionTenderType";
import { useCallService } from "../../../services/useCallService";

export function useAddPaymentOnFile(patientId?: string, plan?: PaymentPlanModel, onError?: () => void, onSuccess?: () => void) {
  const callService = useCallService();
  async function addPaymentOnFile() {
    if (!patientId || !plan) return;
    return callService("addPaymentOnFile", async () => {
      const response = await addPaymentOnFileService(patientId, mapPaymentPlanToPaymentOnFile());

      const result = response.result;
      if (result && result.patient) {
        onSuccess && onSuccess();
      } else {
        onError && onError();
      }
      return response;
    });
  }

  function mapPaymentPlanToPaymentOnFile() {
    const isCardManual = plan?.tender?.type == ApiTenderTypeEnum.CardManual;
    return {
      tokenId: plan?.tokenId ?? mockTokenId(),
      token: 'mock token',
      tenderType: EnumTransactionTenderType.Card,
      maskedAccount: maskCardNumber(plan?.tender?.deviceSerialNumber),
      cardOwnerName: `${plan?.billingInformation?.firstName ?? ''} ${plan?.billingInformation?.lastName ?? ''}`,
      cardExpirationDate: isCardManual ? plan?.tender?.expirationDate : undefined,
      cardType: plan?.tender?.cardType,
      cardBrand: plan?.cardBrand ?? 'NotSet',
      isReusable: true,
      routingNumber: maskECheckNumber(plan?.tender?.routingNumber),
      financialInstitution: plan?.tender?.financialInstitution
    }
  }

  return { addPaymentOnFile };
}

export function mockTokenId() {
  return 'mockedTokenId' + Math.floor(Math.random() * 10 ** 8);
}