import ConfigurationTemplate from "../../../features/admin/clientConfiguration/ConfigurationTemplate"
import { ReactComponent as SaveIcon } from 'assets/svgs/admin/epic/integrationSettings/save.svg';
import { ReactComponent as AddIcon } from 'assets/svgs/admin/epic/add-circle.svg';
import { useEffect, useState } from "react";
import * as router from 'react-router-dom';
import { useOrganizations } from "../../../features/organizations/hooks/useOrganizations";
import TextBox from "../../../features/componentFactory/wrapperComponents/TextBox";
import { Button, Radio, TextArea } from "@EHDS/core";
import MultiSelect, { MultiSelectOption } from "../../../components/select/MultiSelect";
import "assets/styles/components/_integrationSettings.scss";
import { useGetTokens } from "../../../features/admin/epic/userTokenManagement/useGetTokens";
import { deleteToken, getTokenUserMappingByOneSourceUserName, UserToken } from "../../../services/TokenGUIDLookupService";
import { DataType } from "../../../models/metaData/MetaDataEnums";
import useSaveToken, { tokenAlert } from "../../../features/admin/epic/userTokenManagement/useSaveToken";
import { Popup } from "../../../components/popup/Popup";
import { EnumPopupButtonsStyle, EnumPopupButtonsType } from "../../../models/enums/EnumPopupType";
import { useTokenValidator } from "../../../features/admin/epic/userTokenManagement/useTokenValidator";
import { setAlert } from "../../../features/alert/AlertReducer";
import { useDispatch } from "react-redux";

export default function UserTokenManagement() {

  let { clientId } = router.useParams<{ clientId: string }>();
  const { getOrganizationById } = useOrganizations();
  const selectedOrganization = getOrganizationById(clientId);
  const organizationPath = selectedOrganization?.path;

  const [tokens, setTokens] = useState<UserToken[]>();
  const [selectedToken, setSelectedToken] = useState<UserToken>();
  const [selectedTokenId, setSelectedTokenId] = useState<string>();
  const { getTokens } = useGetTokens(setTokens);
  useEffect(() => {
    organizationPath && getTokens(organizationPath);
  }, [organizationPath])

  const options = tokens?.map(token => ({
    label: `${token.id} (${token.user?.oneSourceUserId ?? ''})`,
    value: token.id ?? ''
  })) || [];

  const onChange = (key: keyof UserToken, value: string) => {
    selectedToken && setSelectedToken({
      ...selectedToken,
      [key]: value
    })
  }

  const { saveToken } = useSaveToken((updated: UserToken) => {
    setSelectedToken(updated);
    setSelectedTokenId(updated.id);
    const idx = tokens?.findIndex(token => token.id === updated.id) ?? -1;
    const updatedTokens = [...tokens ?? []];
    if (idx !== -1) {
      updatedTokens[idx] = updated;
    } else {
      updatedTokens.push(updated);
    }
    setTokens(updatedTokens);
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isTokenForPaymentSafe, setIsTokenForPaymentSafe] = useState(false);

  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const [foundMappingTokenId, setFoundMappingTokenId] = useState<string>("");

  const { attributes: tokenAttributes, getErrorMessage, validateAll } = useTokenValidator();

  const dispatch = useDispatch();

  const runValidators = (validOnly: boolean = false, data?: UserToken) => {
    return validateAll(validOnly, data ?? selectedToken);
  }

  async function deleteTokenUserMapping(selectedToken: UserToken) {
    if (selectedToken && foundMappingTokenId) {
      if (selectedToken.id === foundMappingTokenId) {
        saveToken(selectedToken);
      }
      else {
        const response = await deleteToken(foundMappingTokenId);
        if (response?.result?.deleted) {
          saveToken(selectedToken);
        }
      }
    }
  }

  async function getTokenUserMapping (selectedToken: UserToken) {
    if (selectedToken.oneSourceUserName) {
      const response = await getTokenUserMappingByOneSourceUserName(selectedToken.oneSourceUserName);
      if (response.result) {
        setFoundMappingTokenId(response.result?.id);
        setIsConfirmationPopupOpen(true);
      } else {
        setFoundMappingTokenId("");
        saveToken(selectedToken);
      }
    }
  }

  const onSaveChanges = () => {
    if (selectedToken && runValidators()) {
      getTokenUserMapping(selectedToken);
    } else {
      dispatch(
        setAlert({
          ...tokenAlert,
          message: `Error token not saved. Please check the validation errors.`,
        })
      );
  }
}

  return (
    <ConfigurationTemplate
      title={'User Token Management'}
      headerButtons={
        <div className="header-buttons">
          <div className={"header-button"} onClick={() => { setIsPopupOpen(true) }}>
            <AddIcon className="header-secondary-icon"/>
            <span className="text"> New Token </span>
          </div>
          <div className={"header-button"} onClick={() => onSaveChanges()}>
            <SaveIcon className="header-secondary-icon"/>
            <span className="text"> Save Changes </span>
          </div>
        </div>
      }
    >
      <div className={"form-row space-above separated"}>
        <div className={"field extra-full-width"}>
          <MultiSelect
            label={"Choose a User Token:"}
            name={"authPrefix"}
            options={options}
            onChange={(option?: MultiSelectOption | null) => {
              setSelectedTokenId(option?.value);
            }}
            values={options.find(o => o.value === selectedToken?.id) ?? null}
            multiple={false}
            disableCloseOnSelect={false}
          />
        </div>
        <Button className="field-button"
          onClick={() => {
            const token = tokens?.find(token => token.id === selectedTokenId);
            setSelectedToken(selectedTokenId ? token : undefined);
            runValidators(true, token);
          }}
        >
          <span className="title-button-text"> Submit </span>
        </Button>
      </div>
      {selectedToken ?
        <>
          <div className={"form-row"}>
            <div className={"field extra-full-width"}>
              <TextBox
                value={selectedToken.oneSourceUserName}
                attribute={tokenAttributes.find(a => a.name === "oneSourceUserName") ?? { name: "oneSourceUserName", dataType: DataType.String }}
                onChange={value => onChange("oneSourceUserName", value)}
                error={getErrorMessage("oneSourceUserName")}
                onBlur={() => validateAll(false, selectedToken)}
              />
            </div>
          </div>
          <div className={"form-row"}>
            <div className={"field extra-full-width"}>
              <TextArea
                label={"Facility Host IP Addresses"}
                value={selectedToken.clientHostID}
                onChange={element => onChange("clientHostID", element.target.value)}
                onBlur={() => {
                  validateAll(false, selectedToken)
                }}
                className={getErrorMessage("clientHostID") ? 'invalid-field' : ''}
                hasError={getErrorMessage("clientHostID") ? true : false}
              />
              <div className="error-message-required">
                {getErrorMessage("clientHostID")}
              </div>
            </div>
          </div>
          <div className={"form-row"}>
            <div className={"field extra-full-width"}>
              <TextBox
                value={selectedToken.displayErrorMessage}
                attribute={tokenAttributes.find(a => a.name === "displayErrorMessage") ?? { name: "displayErrorMessage", dataType: DataType.String }}
                onChange={value => onChange("displayErrorMessage", value)}
                error={getErrorMessage("displayErrorMessage")}
                onBlur={() => validateAll(false, selectedToken)}
              />
            </div>
          </div>
        </>
        : <></>}

      {isPopupOpen ? <Popup
        customClassNames={{ title: "eds-heading eds-heading.mdplus-caps mb-1"}}
        header={<span>PaymentSafe &reg;</span>}
        title={"Create New Token"}
        onClose={() => {
          setIsPopupOpen(false)
          setIsTokenForPaymentSafe(false)
        }}
        children={<div className="radio-options">
          <Radio
            checked={!isTokenForPaymentSafe}
            label={"For Patient Simple"}
            onChange={() => setIsTokenForPaymentSafe(false)}
          />
          <Radio
            checked={isTokenForPaymentSafe}
            label={"For Payment Safe"}
            onChange={() => setIsTokenForPaymentSafe(true)}
          />
        </div>}
        onSubmit={() => {
          const token = {
            clientHostID: isTokenForPaymentSafe ? "onesource.passporthealth.com|paymentsafe.experianhealth.com|" : "patientsimple.com|",
            displayErrorMessage: "Authentication for this browser has failed.",
            user: {
              id: ""
            },
            organizationReference: selectedOrganization,
            oneSourceUserName: ""
          }
          setSelectedToken(token)
          setIsPopupOpen(false)
          setIsTokenForPaymentSafe(false)
          runValidators(false, token)
        }}
        footer={[
          {
            type: EnumPopupButtonsType.cancel,
            style: EnumPopupButtonsStyle.secondary,
            text: 'Cancel',
          },
          {
            type: EnumPopupButtonsType.submit,
            style: EnumPopupButtonsStyle.primary,
            text: 'Create New Token',
          },
        ]}
      /> : <></>}
      
      {isConfirmationPopupOpen ? <Popup
        customClassNames={{ title: "eds-heading eds-heading.mdplus-caps mb-1" , container: 'autoHeightWidth', footerButton: 'sameWidth'}}
        header={<span>PaymentSafe &reg;</span>}
        title={"Warning"}
        onClose={() => {
          setIsConfirmationPopupOpen(false)
        }}
        children={<p> {`This User is already Mapped to GUID "${foundMappingTokenId}" do you want to continue?`}</p>}
        onSubmit={() => {
          if (selectedToken) {
            deleteTokenUserMapping(selectedToken);
          }
          setIsConfirmationPopupOpen(false)
        }}
        footer={[
          {
            type: EnumPopupButtonsType.cancel,
            style: EnumPopupButtonsStyle.secondary,
            text: 'No',
          },
          {
            type: EnumPopupButtonsType.submit,
            style: EnumPopupButtonsStyle.primary,
            text: 'Yes',
          },
        ]}
      /> : <></>}
    </ConfigurationTemplate>
  )
}