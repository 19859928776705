import { useTypedSelector } from 'app/rootReducer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EnumPlanStatus } from '../../models/enums/EnumPaymentPlan';
import { useGetPatientById } from '../patients/hooks/useGetPatientById';
import { LineItemComponent, LineItemType } from './LineItemComponent';
import PaymentPlanComponent from './PaymentPlanComponent';
import { addDiscrepancy, removeDiscrepancy } from '../paymentPlan/PaymentPlanReducer';
import { PaymentPlanDetailsModal } from '../paymentPlan/PaymentPlanDetailsLink';
import { ClientFacilityConfiguration } from 'models/Client';
import { LineItem } from 'models/LineItem';
import { Attribute } from 'models/metaData/MetaData';
import { checkNewAccountNumber } from './DashBoards';
import PaymentPlanModel from 'models/PaymentPlan';
import { PlanOperations } from 'models/metaData/MetaDataEnums';
import { Dashboards } from 'features/admin/paymentPanel/ConfigurationSelector';

const AMOUNT_BALANCE_DISCREPANCY_KEY = 'AmountBalanceDiscrepancy'
const AMOUNT_BALANCE_DISCREPANCY_MESSAGE = 'The payment amount must equal account balance to create or add to a payment plan.';

export default function LineItems(
  props:
    {
      patientId?: string,
      organizationPath?: string,
      isGl?: boolean,
      wizardActive?: boolean,
      onEditLineItems?: () => boolean,
      isPayment?: boolean,
      clientConfiguration?: ClientFacilityConfiguration,
      isPreview?: boolean,
      typedAccountNumber?: string,
      accountNumberFound?: boolean,
      planAdhoc?: boolean,
    }) {
  const { isGl, wizardActive, patientId, organizationPath, onEditLineItems, isPayment, clientConfiguration, isPreview, typedAccountNumber, accountNumberFound, planAdhoc } = props;
  const stateLineItems = useTypedSelector(
    s => s.paymentDashboard?.values?.[organizationPath ?? '']?.lineItems || []
  );

  const { patient } = useGetPatientById(patientId);
  const paymentPlans = useTypedSelector(
    s => {
      return s.paymentPlanInfo?.records?.filter(plan => plan.patientId === patient?.id && [EnumPlanStatus.Active, EnumPlanStatus.Paused, EnumPlanStatus.Stopped].includes(plan.status || EnumPlanStatus.None));
    }
  );

  const lineItemsWithDiscrepancy = stateLineItems.filter(l => l.isActive && (l.balance ?? 0) > 0 && (l.amount != l.balance));

  const dispatch = useDispatch();

  useEffect(() => {
    if (lineItemsWithDiscrepancy.length > 0) {
      dispatch(addDiscrepancy({
        key: AMOUNT_BALANCE_DISCREPANCY_KEY,
        message: AMOUNT_BALANCE_DISCREPANCY_MESSAGE
      }));
    }
    else {
      dispatch(removeDiscrepancy(AMOUNT_BALANCE_DISCREPANCY_KEY));
    }
  }, []);

  const getDashboardType = () => {
    if (isGl)
      return 'GlPaymentDashboard';
    else if (isPayment)
      return 'PaymentDashboard';
    else
      return 'PaymentPlanDashboard';
  }
  
  const selectedDashboard = !isPreview ? getDashboardType() : localStorage.getItem('selectedDashboard') as keyof Dashboards;
  const paymentPanelConfiguration = useTypedSelector(s => !isPreview ? s.paymentDashboard?.panelConfigurations?.[organizationPath ?? '']?.configuration : s.paymentPlanInfo?.planPanelConfigurations?.[organizationPath ?? '']?.configuration);
  const defaultPanelConfiguration = JSON.parse(localStorage.getItem(`defaultConfig-${selectedDashboard}`) ?? '{}');
  const selectedPanelConfiguration  =  paymentPanelConfiguration || defaultPanelConfiguration;
  const attributes = selectedPanelConfiguration?.entity?.attributes ?? [];
  const addAccountEnabled = selectedPanelConfiguration?.entity?.addRowEnabled;
  const loadedPlanPanelConfiguration = useTypedSelector(s => (s.paymentPlanInfo?.planPanelConfigurations?.[organizationPath ?? '']?.configuration));
  const planAttributes = loadedPlanPanelConfiguration?.entity?.attributes ?? [];
  const paymentConfiguration = useTypedSelector(s => s.paymentDashboard?.panelConfigurations?.[organizationPath ?? '']?.configuration);
  const lineItemAttributes = paymentConfiguration?.entity?.attributes ?? [];
  const lineItems = useTypedSelector(s => s.paymentDashboard?.values?.[organizationPath ?? '']?.lineItems || []);
  const paymentPlanOperation = useTypedSelector(s => (s.paymentPlanInfo?.operation));
  const isCreatePlan = paymentPlanOperation === PlanOperations.CreatePlan;
  const isAddHoc = paymentPlanOperation === PlanOperations.AddhockPayment;

  const addToPlanLineItemComponents = (plan: PaymentPlanModel) => plan.lineItems?.filter(lineItem => lineItem.isActive && lineItem.isNew).map((lineItem, index) => {
    return getLineItemComponent(lineItem, index);
  });

  const getLineItemComponent = (lineItem: LineItem, index: number) => {
    const key = `${lineItem.id}-${index}-newPaymentPlan`;
    const newAttribute = filterAndMergeAttributes(lineItemAttributes, planAttributes);
    const updatedLineItem = { ...lineItem }

    return (
      <LineItemComponent
        key={key}
        lineItem={updatedLineItem}
        lineItemId={lineItem.id}
        type={LineItemType.Normal}
        wizardActive={false}
        organizationPath={organizationPath}
        onEditLineItems={onEditLineItems}
        externalAttributes={newAttribute}
        clientConfiguration={clientConfiguration}
        isPayment={isPayment}
        isPreview={isPreview}
        isPaymentOrPreviewPage={true}
        planAdhoc={planAdhoc}
      />
    )
  };

  const newPlanLineItemComponents = () => lineItems?.filter(lineItem => lineItem.isActive && lineItem.isNew).map((lineItem, index) => {
    return getLineItemComponent(lineItem, index);
  })

  const lineItemComponents = stateLineItems?.map((lineItem, index) => {
    let type = LineItemType.Normal;

    if ((!addAccountEnabled && !lineItem.pahAccount) || (isGl && lineItem.pahAccount)) {
      return <></>;
    }

    if (isGl) {
      type = LineItemType.Gl;
      lineItem = { ...lineItem, isContracted: true };
    }else{
      const previewLineItem: LineItem = { id: `previewLineItem-${index}`, amount: 0, isEditing: true, isNew: true, isActive: false };
      lineItem = isPreview ? previewLineItem : lineItem;
    }
    return (
      <LineItemComponent
        key={`lineItem-${lineItem.id}`}
        lineItem={lineItem}
        lineItemId={lineItem.id}
        type={type}
        wizardActive={wizardActive}
        organizationPath={organizationPath}
        onEditLineItems={onEditLineItems}
        externalAttributes={attributes}
        clientConfiguration={clientConfiguration}
        isPayment={isPayment}
        isPreview={isPreview}
        isGl={isGl}
        isPaymentOrPreviewPage={true}
        adapterCheckNewAccountNumber={adapterCheckNewAccountNumber}
        planAdhoc={planAdhoc}
        borderColor={selectedPanelConfiguration?.entity?.colorCode}
      />
    );
  });

  function adapterCheckNewAccountNumber(newAccount?: string) {
    if (!isGl) {
      checkNewAccountNumber(paymentPlans, newAccount, dispatch);
    }
  };

  function filterAndMergeAttributes(paymentAttributes: Attribute[], paymentPlanAttributes: Attribute[]) {
    if (isPreview) {
      return paymentPlanAttributes;
    }
    const filteredAttributes = paymentAttributes.filter(paymentAttribute => {
      return paymentPlanAttributes.some(planAttribute => planAttribute?.saveAs === paymentAttribute.saveAs);
    });

    paymentPlanAttributes.forEach(planAttribute => {
      if (!filteredAttributes.some(filteredAttribute => filteredAttribute.saveAs === planAttribute.saveAs)) {
        filteredAttributes.push(planAttribute);
      }
    });

    return filteredAttributes;
  }

  return <>
    <PaymentPlanDetailsModal />

    {(isGl || isPayment || isPreview) && lineItemComponents}

    {!isPayment && isCreatePlan && newPlanLineItemComponents()}
    {!isPreview && !isGl && !isPayment && paymentPlans?.map((plan) => {
      if (accountNumberFound) {
        if (plan?.lineItems?.length && plan.organization?.path.includes(organizationPath ?? '') && plan?.lineItems?.some(lineItem => lineItem.accountNumber === typedAccountNumber)) {
          return (
            <>
              {!isAddHoc && addToPlanLineItemComponents(plan)}
              <PaymentPlanComponent
                key={`paymentPlan-${plan.id}`}
                paymentPlanId={plan.id}
                wizardActive={wizardActive}
                planAdhoc={planAdhoc}
                organizationPath={organizationPath}
              />
            </>
          )
        } else {
          return null;
        }
      }
      else {
        if (plan?.lineItems?.length && plan.organization?.path.includes(organizationPath ?? '')) {
          return (
            <>
              {!isAddHoc && addToPlanLineItemComponents(plan)}
              <PaymentPlanComponent
                key={`paymentPlan-${plan.id}`}
                paymentPlanId={plan.id}
                wizardActive={wizardActive}
                planAdhoc={planAdhoc}
                organizationPath={organizationPath}
              />
            </>
          )
        } else {
          return null;
        }
      }
    })}
  </>;
}