import { useCallback } from 'react';
import 'assets/styles/components/_dropDownEditorModal.scss';

export function EditorButton(props: {
  title?: string;
  icon: any;
  disabled?: boolean;
  onClick: (() => void) | undefined;
  secondary?: JSX.Element;
  className?: string;
}) {
  const { title, icon, disabled = false, onClick, secondary, className = "" } = props;

  const clickHandler = useCallback(() => {
    if (disabled) return;
    onClick && onClick();
  }, [onClick, disabled]);

  return (
    <div
      className={`header-button ${disabled ? 'disabled' : ''} ${className}`}
      onClick={clickHandler}
    >
      <div className={secondary ? 'header-icon-secondary' : 'header-icon'}>
        {icon}
        {secondary}
      </div>
      {title ? <div className={`center-text${disabled ? ' center-text-disabled' : ''}`}>
        {title}
      </div> : null}
    </div>
  );
}
