import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/rootReducer';
import { ValueOptionNamePair } from 'models/ValueOptionNamePair';
import {
  cardBrands,
  getTodaysDate,
  getYesterday,
  mapCardBrandsForSearchResults,
  mapTypesForSearchResults,
  mapPaymentStatusToMultiSelectOptions
} from 'utils/Utils';
import { MultiSelectOption } from '../../../components/select/MultiSelect';
import { DepartmentMultiselectOption, UserMultiSelectOption } from '../simpleSearch/OrganizationUserSearch';
import { transactionTypes, tenderTypes, cardEntryTypes,paymentStatusOptions } from './FilterData';
import { GetGlDescriptions } from './useAdvanceSearchState';

const yesterday = getYesterday();
const today = getTodaysDate();

export interface AdvanceSearchInformation {
  users: UserMultiSelectOption[];
  departments: DepartmentMultiselectOption[];
  facilities: MultiSelectOption[];
  mrn?: string;
  episode?: string;
  patientId?: string;
  patientFirstName?: string;
  patientLastName?: string;
  patientAccountNumber?: string;
  payerFirstName?: string;
  payerLastName?: string;
  guarantorId?: string;

  submittedDateTimeMin?: string;
  submittedDateTimeMax?: string;
  settledDateTimeMin?: string;
  settledDateTimeMax?: string;
  paymentAmountFrom?: number;
  paymentAmountTo?: number;
  discountAmountFrom?: number;
  discountAmountTo?: number;

  transactionID?: string;
  batchID?: string;
  accountNumber?: string;
  creditCard?: string;
  paymentStatus?: MultiSelectOption[];
  authorizationCode?: string;
  resultsStatus?: ValueOptionNamePair;
  glDescription?: ValueOptionNamePair;
  transactionReferenceId?: string;
  gatewayReferenceNumber?: string;
  paymentPlanId?: string;
  deviceName?: string;
  deviceSerialNumber?: string;

  cardBrand?: string[];
  transactionTypes?: TreeFilterItem[];
  cardEntryTypes?: TreeFilterItem[];
  glTransactionTypes?: string[];
  tenderTypes?: TreeFilterItem[];
  billingId?: string;
}

export interface TreeFilterItem {
  label: string;
  isChecked: boolean;
  propertyName: string;
  parent?: string;
}

export interface FilterItem {
  displayName?: string;
  value?: any;
  displayValue?: any;
}

export interface State {
  value: AdvanceSearchInformation;
  saveSettings?: boolean;
  filters: {
    [key: string]: FilterItem;
  };
  glDescriptionOptions: {value: string, optionName: string}[];
  selectedFacilitiesPaths: string[];
}

export const initialState: State = {
  value: {
    facilities: [],
    departments: [],
    users: [],
    transactionTypes: mapTypesForSearchResults(transactionTypes),
    tenderTypes: [
      ...mapTypesForSearchResults(tenderTypes),
      ...mapCardBrandsForSearchResults(cardBrands, 'Credit/Debit'),
    ],
    cardEntryTypes: [ ...mapTypesForSearchResults(cardEntryTypes) ],
    submittedDateTimeMin: '',
    submittedDateTimeMax: '',
    paymentStatus : mapPaymentStatusToMultiSelectOptions(paymentStatusOptions)
  },
  saveSettings: false,
  filters: {},
  glDescriptionOptions: [{value: '', optionName: ''}],
  selectedFacilitiesPaths: []
};

const reducerSlice = createSlice({
  name: 'AdvanceSearch',
  initialState,
  reducers: {
    setAdvanceSearchInformation(state, action: PayloadAction<any>) {
      state.value = action.payload;
    },
    setSaveSettings(state, action: PayloadAction<{ [key: string]: boolean }>) {
      state.saveSettings = action.payload.saveSettings;
    },
    addFilter(
      state,
      action: PayloadAction<{
        name: string;
        filter: FilterItem;
      }>
    ) {
      const { name, filter } = action.payload;
      state.filters[name] = filter;
    },
    removeFilter(state, action: PayloadAction<string>) {
      const filterName = action.payload;
      delete state.filters[filterName];
      (state.value as any)[filterName] = (initialState.value as any)[
        filterName
      ];
    },
    clearFilters(state) {
      state.value = initialState.value;
      state.filters = initialState.filters;
    },
    setGlDescriptionOptions(state, action: PayloadAction<{value: string, optionName: string}[]>) {
      state.glDescriptionOptions = action.payload;
    },
    setSelectedFacilitiesPaths(state, action: PayloadAction<string[]>) {
      state.selectedFacilitiesPaths = action.payload;
    },
  },
});

export const {
  setAdvanceSearchInformation,
  setSaveSettings,
  addFilter,
  removeFilter,
  clearFilters,
  setGlDescriptionOptions,
  setSelectedFacilitiesPaths
} = reducerSlice.actions;

export default reducerSlice.reducer;

export function getGlDescriptionOptions(facilitiesPaths: string[]): AppThunk {
  return async (dispatch) => {
    dispatch(setSelectedFacilitiesPaths(facilitiesPaths));
    const glDescriptionOptions = await GetGlDescriptions(facilitiesPaths);
    dispatch(setGlDescriptionOptions(glDescriptionOptions));
  };
}