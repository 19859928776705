import { LineItem } from "models/LineItem";
import { DistributionOrder } from "models/enums/EnumPaymentPanels";
import { checkNumberHasValue } from "utils/Utils";

export function distributeAmount(lineItems: LineItem[], distributionAmount: number, distributionOrder?: DistributionOrder | string){
  switch(distributionOrder)
  {
    case DistributionOrder.Equal:
      return equalDistribution(lineItems, distributionAmount);
    case DistributionOrder.New:
      return newDistribution(lineItems, distributionAmount);
    case DistributionOrder.Newest:
      return newestDistribution(lineItems, distributionAmount);
    case DistributionOrder.Oldest:
      return oldestDistribution(lineItems, distributionAmount);
    case DistributionOrder.LowPayment:
      return lowPaymentDistribution(lineItems, distributionAmount);
    case DistributionOrder.HighPayment:
      return highPaymentDistribution(lineItems, distributionAmount);
    case DistributionOrder.BadDebt:
      return badDebtDistribution(lineItems, distributionAmount);
    default:
      return lineItems;
  }
}

const equalDistribution = (lineItems: LineItem[], distributionAmount: number) => {
  let totalAmount = distributionAmount;
  const distributionNonActiveLineItems : LineItem[] = [...lineItems].filter(x => !x.isActive);
  const distributionActiveLineItems: LineItem[] = [...lineItems].filter(x => x.isActive);
  let distributedLineItems: LineItem[] = [];

  while(totalAmount > 0) {
    const distributionAmountCents = totalAmount * 100; //avoid rounding issue
    const nonDistributedLineItemsTotal = distributionActiveLineItems.filter(x => !x.isBalanceDistributed).length;
    let remainder = distributionAmountCents % nonDistributedLineItemsTotal;
    let distributionAmountAdj = (distributionAmountCents - remainder) / 100;
    let splittedDistributionAmount = distributionAmountAdj / nonDistributedLineItemsTotal;

    distributionActiveLineItems.forEach(lineItem => {
      let balance = splittedDistributionAmount;
      if (remainder > 0) {
        balance += 0.01;
        remainder -= 1;
      }

      lineItem.balance = (balance <= lineItem.amount ? balance : lineItem.amount) - (lineItem?.discount ?? 0);
      lineItem.isBalanceDistributed = true;
      totalAmount = totalAmount - (balance <= lineItem.amount ? balance : lineItem.amount);
    });
  }

  distributedLineItems = distributionActiveLineItems.concat(distributionNonActiveLineItems);

  return distributedLineItems;
}

const newDistribution = (lineItems: LineItem[], distributionAmount: number) => {
  let totalAmount = distributionAmount;
  const distributionLineItems = [...lineItems];
  distributionLineItems.sort((a, b) => (a.amount > b.amount ? -1 : 1));
  
  const distributedLineItems : LineItem[] = distributionLineItems.filter(x => !x.pahAccount).map((lineItem) => {
    const distributedAmount = lineItem.amount <= totalAmount ? lineItem.amount : totalAmount;
    const distributedLineItem = { ...lineItem, isActive: distributedAmount !== 0, balance: distributedAmount - (lineItem?.discount ?? 0), isBalanceDistributed: true };
    totalAmount = totalAmount - distributedAmount;  
    return distributedLineItem; 
  });

  const distributedPahLineItems : LineItem[] = distributionLineItems.filter(x => x.pahAccount).map((lineItem) => {
    const distributedAmount = lineItem.amount <= totalAmount ? lineItem.amount : totalAmount;
    const distributedLineItem = { ...lineItem, isActive: distributedAmount !== 0, balance: distributedAmount - (lineItem?.discount ?? 0), isBalanceDistributed: true };
    totalAmount = totalAmount - distributedAmount;
    return distributedLineItem;   
  });
    
  return distributedLineItems.concat(distributedPahLineItems);
}

const newestDistribution = (lineItems: LineItem[], distributionAmount: number) => {
  let totalAmount = distributionAmount;
  const sortedLineItems = [...lineItems].sort((a, b) => (b.dateOfService ?? '') > (a.dateOfService ?? '') ? 1 : -1);
 
  const distributedLineItems : LineItem[] = sortedLineItems.map((lineItem) => {
    const distributedAmount = lineItem.amount <= totalAmount ? lineItem.amount : totalAmount;
    const distributedLineItem = { ...lineItem, isActive: distributedAmount !== 0, balance: distributedAmount - (lineItem?.discount ?? 0), isBalanceDistributed: true };
    totalAmount = totalAmount - distributedAmount;
    return distributedLineItem;   
  });

  return distributedLineItems;
}

const oldestDistribution = (lineItems: LineItem[], distributionAmount: number) => {
  let totalAmount = distributionAmount;
  const sortedLineItems = [...lineItems].sort((a, b) => (a.dateOfService ?? '') < (b.dateOfService ?? '') ? -1 : 1);

  const distributedLineItems : LineItem[] = sortedLineItems.map((lineItem) => {
    const distributedAmount = lineItem.amount <= totalAmount ? lineItem.amount : totalAmount;
    const distributedLineItem = { ...lineItem, isActive: distributedAmount !== 0, balance: distributedAmount - (lineItem?.discount ?? 0), isBalanceDistributed: true };
    totalAmount = totalAmount - distributedAmount;
    return distributedLineItem;   
  });

  return distributedLineItems;
}

const lowPaymentDistribution = (lineItems: LineItem[], distributionAmount: number) => {
  let totalAmount = distributionAmount;
  const distributionLineItems = lineItems.filter(x => x.patientBalance);
  const nonDistributionLineItems = lineItems.filter(x => !x.patientBalance);
  distributionLineItems.sort((a, b) => ((a.patientBalance ?? 0) < (b.patientBalance ?? 0) ? -1 : 1));

  const distributedLineItems : LineItem[] = distributionLineItems.map((lineItem) => {
    const distributedAmount = (lineItem.patientBalance ?? 0) <= totalAmount ? (lineItem.patientBalance ?? 0) : totalAmount;
    const distributedLineItem = { ...lineItem, isActive: distributedAmount !== 0, balance: distributedAmount - (lineItem?.discount ?? 0), isBalanceDistributed: true };
    totalAmount = totalAmount - distributedAmount;
    return distributedLineItem;   
  });

  return distributedLineItems.concat(nonDistributionLineItems);
}

const highPaymentDistribution = (lineItems: LineItem[], distributionAmount: number) => {
  let totalAmount = distributionAmount;
  const distributionLineItems = lineItems.filter(x => x.patientBalance);
  const nonDistributionLineItems = lineItems.filter(x => !x.patientBalance);
  distributionLineItems.sort((a, b) => ((a.patientBalance ?? 0) > (b.patientBalance ?? 0) ? -1 : 1));

  const distributedLineItems : LineItem[] = distributionLineItems.map((lineItem) => {
    const distributedAmount = (lineItem.patientBalance ?? 0) <= totalAmount ? (lineItem.patientBalance ?? 0) : totalAmount;
    const distributedLineItem = { ...lineItem, isActive: distributedAmount !== 0, balance: distributedAmount - (lineItem?.discount ?? 0), isBalanceDistributed: true };
    totalAmount = totalAmount - distributedAmount;
    return distributedLineItem;   
  });

  return distributedLineItems.concat(nonDistributionLineItems);
}

const badDebtDistribution = (lineItems: LineItem[], distributionAmount: number) => {
  let totalAmount = distributionAmount;
  const distributionLineItems = lineItems.filter(x => x.patientBalance && x.collectionFlag == "True");
  distributionLineItems.sort((a, b) => ((a.patientBalance ?? 0) > (b.patientBalance ?? 0) ? -1 : 1));
  
  const distributedLineItems : LineItem[] = distributionLineItems.map((lineItem) => {
    const distributedAmount = (lineItem.patientBalance ?? 0) <= totalAmount ? (lineItem.patientBalance ?? 0) : totalAmount;
    const distributedLineItem = { ...lineItem, isActive: distributedAmount !== 0, balance: distributedAmount - (lineItem?.discount ?? 0), isBalanceDistributed: true };
    totalAmount = totalAmount - distributedAmount;
    return distributedLineItem;   
  });

  const regularLineItems : LineItem[] = lineItems.filter(x => x.collectionFlag == "False" || !x.patientBalance).map((lineItem) => {return lineItem});

  return distributedLineItems.concat(regularLineItems);
}



