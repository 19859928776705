import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { GetPaymentPlansByPatientId } from "services/PaymentPlanService";
import { useCallService } from "../../../services/useCallService";

const validStatuses = "&validStatuses=Active&validStatuses=Paused";

export function useGetPlansByPatientId(
  id?: string,
  organizationPath?: string,
  onError?: () => {},
  onSuccess?: () => {}
) {
  const dispatch = useDispatch();
  const callService = useCallService()

  const getPlans = useCallback(async () => {
    if (!id || !organizationPath) {
      onError && onError();
      return;
    }

    return callService("getPaymentPlansByPatientId", async () => {
      const response = await GetPaymentPlansByPatientId(id, organizationPath, validStatuses);
      let plans = response.result;
      if (plans) {
        onSuccess && onSuccess();
        return plans;
      } else {
        onError && onError();
      }
    });
  }, [dispatch]);

  return { getPlans };
}