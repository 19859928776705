import { useRef } from 'react';
import 'assets/styles/components/_transactionDetailsModal.scss';
import 'assets/styles/components/_print.scss';

export default function TransactionDetailsPrint( props:{ receiptUrl:string}){
   
  const {receiptUrl} = props;
  const componentRef = useRef() as any;

  const oniFrameLoad = () => {
      componentRef.current.contentWindow.print();
  }
    return (
      <div>
       <iframe className = {'printiframe'} ref ={componentRef} src={receiptUrl} onLoad={oniFrameLoad} data-testid="printiframe" />
    </div>
    );
}

