import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LinkType {
  name: string;
  slug?: string;
}

export interface BreadcrumbState {
  links: LinkType[];
  currentLink?: LinkType;
}

export const initialState: BreadcrumbState = {
  links: [{name:'Home',slug:'/landing'}],
};

const reducerSlice = createSlice({
  name: 'Breadcrumb',
  initialState,
  reducers: {
    setBreadcrumbLinks(state, action: PayloadAction<LinkType[]>) {
      state.links = action.payload;
    },
  },
});

export const { setBreadcrumbLinks } = reducerSlice.actions;
export default reducerSlice.reducer;
