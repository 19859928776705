import { useTypedSelector } from 'app/rootReducer';
import ComponentFactory from 'features/componentFactory/ComponentFactory';
import { LineItem } from 'models/LineItem';
import { DataType, PlanOperations } from 'models/metaData/MetaDataEnums';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAttribute, getValidator } from 'utils/metadata/MetaDataUtils';
import { setLineItemAttribute, removeLineItem, updateLineItem, replaceLineItems } from './PaymentDashboardReducer';
import { updateRecord, resetOperation, changeSelected, setOperation, resetSelected, deactiveAllPlans, activatePlanLineItem, expandPlanLineItems } from '../paymentPlan/PaymentPlanReducer';
import { EDS_Button } from '@EH/eh.eds.react';
import { EnumPlanStatus } from '../../models/enums/EnumPaymentPlan';
import { Attribute, GenericValidator, MetaData } from '../../models/metaData/MetaData';
import { displayAmount, displayBalanceAmount, roundDecimalNumber, getDefaultValueByDataType, checkReceivedHasValues, valueOrDefault } from 'utils/Utils';
import { useLineItemValidator } from './validators/useLineItemValidator';
import { useBreakDownLineItems } from './hooks/useBreakDownLineItems';
import { AlertIds, AlertTypes, removeAlert, setAlert } from '../alert/AlertReducer';
import { ValidatorType } from '../../models/enums/Metadata';
import { ReactComponent as PlanIcon } from 'assets/svgs/planStatuses/plan.svg';
import { ReactComponent as Timer } from 'assets/svgs/planStatuses/timer.svg';
import { ReactComponent as Stop } from 'assets/svgs/planStatuses/stop.svg';
import { ReactComponent as Pause } from 'assets/svgs/planStatuses/pause.svg';
import { useSortLineItems } from '../paymentPlan/hooks/useSortLineItems';
import { ClientFacilityConfiguration } from 'models/Client';
import { PaymentConfiguration } from 'models/PaymentsConfiguration';
import * as router from 'react-router-dom';

export enum LineItemType {
  Normal = 'normal',
  Gl = 'GL',
  Plan = 'Plan',
  Sub = 'subItem',
  GlCreditVoid = 'GL Credit/Void',
}

export function calculateLineItemBalance(lineItem?: LineItem | null) {
  return Math.max(0, (Number(lineItem?.balance ?? 0)));
}

export function calculateLineItemPatientBalance(lineItem?: LineItem | null) {
  return Math.max(0, Number(lineItem?.patientBalance ?? 0));
}

export function calculateBalance(type: LineItemType, lineItem?: LineItem | null) {
  if (type === LineItemType.Gl) {
    return Number(lineItem?.glUnits ?? 0) * Number(lineItem?.unitCost ?? 0) - Number(lineItem?.discount || 0);
  }
  if (type === LineItemType.Plan) {
    return (
      lineItem?.lineItems?.reduce((sum, l) => sum + calculateLineItemBalance(l), 0) ?? 0
    );
  }
  return calculateLineItemBalance(lineItem);
}

export function LineItemComponent(props: {
  lineItem: LineItem;
  lineItemId?: string;
  type: LineItemType;
  wizardActive?: boolean;
  paymentPlanId?: string | null;
  facilityName?: string;
  departmentName?: string;
  organizationPath?: string;
  onClickAttribute?: (type: LineItemType, attr?: Attribute, value?: any) => void,
  disabledFields?: (keyof LineItem)[],
  onEditLineItems?: () => boolean,
  externalAttributes?: Attribute[];
  borderColor?: string;
  isPreview?: boolean;
  isGl?: boolean;
  openField?: keyof LineItem;
  clientConfiguration?: ClientFacilityConfiguration;
  isPayment?: boolean;
  selectedCustomField?: string,
  isPaymentOrPreviewPage?: boolean,
  adapterCheckNewAccountNumber?: any,
  planAdhoc?: boolean,
  adhocAmount?: number,
  planSelected?: boolean,
}) {
  const {
    lineItem,
    lineItemId,
    type = LineItemType.Normal,
    wizardActive = false,
    paymentPlanId,
    facilityName,
    departmentName,
    organizationPath,
    onClickAttribute,
    disabledFields,
    onEditLineItems,
    externalAttributes,
    clientConfiguration,
    isPayment,
    isPreview,
    isGl,
    openField,
    selectedCustomField,
    isPaymentOrPreviewPage,
    adapterCheckNewAccountNumber,
    planAdhoc,
    adhocAmount,
    planSelected,
  } = props;

  const [borderColor, setBorderColor] = useState(props.borderColor);
  const lineItems = useTypedSelector(s => s.paymentDashboard?.values?.[organizationPath ?? '']?.lineItems || []);
  const isStay = useTypedSelector(s => s.paymentDashboard.isStay);
  const [previewLineItem, setPreviewLineItem] = useState(lineItem);

  const selectedLineItem = (isPreview && !isGl) ? previewLineItem : lineItem;

  const { configuration: panelConfiguration } = useSortLineItems(organizationPath);

  const facility = useTypedSelector(s => s.glPaymentDashboard?.value.facility);
  const disabled = !lineItem?.isActive && wizardActive && isStay;
  const dispatch = useDispatch();

  const selected = useTypedSelector(s => s.paymentPlanInfo.selected) || [];
  const configuration = useTypedSelector(
    state => state.paymentDashboard.configurations[organizationPath ?? facility?.path ?? '']?.configuration
  );

  const { search } = router.useLocation();
  const queryParams = new URLSearchParams(search);
  const facilityId = queryParams.get("facility");
  const departmentId = queryParams.get("department");

  useEffect(() => {
    AddBorderColor(props, setBorderColor, configuration);
  }, [props.borderColor, (configuration as MetaData)?.entity?.colorCode]);


  useEffect(() => {
    if (planAdhoc) {
      DeactivateAllPaymentPlanLineItems();
    }
  }, [planAdhoc, isPayment])

  function DeactivateAllPaymentPlanLineItems() {
    let activeLineItems = [...(getListOrDefault(paymentPlan?.lineItems))];

    activeLineItems?.map((lineItem, index) => {
      let updateLineItem = {
        ...lineItem,
        isActive: false
      }
      activeLineItems[index] = { ...updateLineItem };
    });

    dispatch(updateRecord({ id: paymentPlan?.id, paymentPlan: { ...paymentPlan!, lineItems: activeLineItems } }));
  }

  const getLineItemContainerClass = () => {
    const borderClass = type == LineItemType.Gl ? 'border-color-gl' : '';
    const disabledClass = disabled ? 'disabled' : '';
    return `bg-light line-item ${borderClass} ${disabledClass}`;
  }

  const getContractedClassName = () => {
    let className = 'line-item-attributes';
    className = GetClassName(lineItem, hasErrors, className);
    return lineItem.isEditing ? className : className + ' preview';
  }

  const getPaymentDueLabel = () => {
    if (type === LineItemType.Plan)
      return 'Total Plan Balance:';
    return 'Payment Amount:';
  }

  const getListOrDefault = (list: any) => {
    return list && list.length > 0 ? list : [];
  }

  let paymentPlan = useTypedSelector(s => s.paymentPlanInfo.records?.find(p => p.id == paymentPlanId));
  const paymentPlanBackup = useTypedSelector(s => (s.paymentPlanInfo.backups ?? {})[paymentPlanId ?? '']);

  const { breakDown } = useBreakDownLineItems({
    lineItems: getListOrDefault(paymentPlan?.lineItems),
    organizationPath
  });

  const onClickComponentAttribute = (attr?: Attribute, value?: any) => {
    onClickAttribute && onClickAttribute(type, attr, value);
  };

  const onChangeAttribute = (attr?: Attribute, value?: any) => {
    if (!attr) return;
    const attributeName = attr.name;
    const attributeType = attr.saveAs;
    if (valueOrDefault(disabled, wizardActive)) return;

    let valid = validateAll({ ...selectedLineItem, [attributeName]: value }, false);

    if (valid) {
      dispatch(removeAlert(lineItemAlert));
    }

    if (isPreview && !isGl) {
      if (attributeType === "serviceType") {
        selectedLineItem['discountPercent'] = value;
        value = attr.listOptions?.find(option => option.savedValue === value)?.displayValue;
      }
      selectedLineItem[attributeName] = value;
      const updatedLineItem = {
        ...selectedLineItem,
      };
      setPreviewLineItem(updatedLineItem);
    }
    else {
      if (attributeType === "serviceType") {
        const discountAttribute = getAttribute(attributes, 'discountPercent');
        if (discountAttribute) {
          dispatch(setLineItemAttribute({ lineItemId: lineItemId, attributeName: discountAttribute.name, value: value, organizationPath: organizationPath ?? '' }));
        }
        value = attr.listOptions?.find(option => option.savedValue === value)?.displayValue;
      }
      dispatch(setLineItemAttribute({ lineItemId: lineItemId, attributeName: attributeName, value: value, organizationPath: organizationPath ?? '' }));
    }
  };

  const onBlur = (attr?: Attribute, value?: any) => {
    if (attr) {
      let updated = {...attr};
      if (LineItemType.Sub == type){
        let maxValueValidator: GenericValidator<LineItem> = {
          name: ValidatorType.RangeValidator,
          message: `Maximum ${lineItem.balance} allowed.`,
          value: {
            min: 0,
            max: lineItem.balance ?? 0
          },
          required: true,
        }

        updated = { 
          ...updated, validators: [...attr?.validators ?? [], ...[maxValueValidator]] };
      }

      validate(updated, value, false, selectedLineItem);
    }
  };

  const lineItemAlert = { id: AlertIds.LineItemAlert, type: AlertTypes.Error, message: "Please complete the required information to continue.3" }
  function validateCheckedPlans(isActiveVal?: boolean) {
    if (paymentPlanId != selected[0]){
      dispatch(deactiveAllPlans());
      dispatch(resetSelected());
      dispatch(activatePlanLineItem({ paymentPlanId: paymentPlanId ?? '', lineItemId : selectedLineItem.id ?? ''}));
      dispatch(changeSelected({ id: paymentPlanId??'', isSelected: true }));
      if(isActiveVal){
        dispatch(setOperation(PlanOperations.AddhockPayment));
        dispatch(expandPlanLineItems({ paymentPlanId: paymentPlanId ?? ''}))
      }
      else{
        dispatch(resetOperation());
      }
    }
  }
  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      calculateLineItemSum(e.target.checked);
      if (!isPayment){
        validateCheckedPlans(e.target.checked);
      }
    },
    [dispatch, lineItem, lineItems, panelConfiguration]
  );

  const onClickSubmit = useCallback(
    () => {
      let valid = validateAll(selectedLineItem);
      if (!valid) {
        dispatch(setAlert(lineItemAlert));
        return;
      }
      calculateLineItemSum(true);
      if (adapterCheckNewAccountNumber) {
        adapterCheckNewAccountNumber(selectedLineItem.accountNumber);
      }
    },
    [dispatch, lineItem, lineItems, panelConfiguration]
  );

  function calculateLineItemSum(isActiveVal?: boolean) {
    if (isPayment || isPreview) {
      updatePaymentLineItem(isActiveVal);
    }
    else {
      updatePaymentPlanIsActive(isActiveVal);
    }
  }

  function updatePaymentLineItem(isActiveVal?: boolean) {
    const { unitCost = 0, amount = 0 } = selectedLineItem;
    const units = type == LineItemType.Gl ? selectedLineItem.glUnits : selectedLineItem.units;
    let discountAmount = lineItem.discount ?? 0;
    discountAmount = lineItem.discountPercent === 0 ? 0 : discountAmount;
    
    if (lineItem.discountPercent) {
      discountAmount = lineItem.glNumber
        ? (lineItem.discountPercent * Number(units) * (unitCost)) / 100
        : (lineItem.discountPercent * (amount)) / 100;
      discountAmount = roundDecimalNumber(discountAmount, 2)!;
    }
  
    const updatedLineItem = {
      ...selectedLineItem,
      isEditing: false,
      isActive: isActiveVal,
      balance: lineItem.glNumber ? ((Number(units)) * (unitCost) - discountAmount) : ((amount) - discountAmount),
      amount: lineItem.glNumber ? Number(units) * (unitCost) : amount,
      discount: discountAmount,
      isBalanceDistributed: false
    }

    if (isPreview && !isGl) {
      setPreviewLineItem(updatedLineItem);
      return;
    }

    const updatedLineItems = [...lineItems];
    const lineItemIdx = updatedLineItems.findIndex(l => l.id === lineItem.id);
    updatedLineItems[lineItemIdx] = updatedLineItem;
    dispatch(replaceLineItems({ lineItems: updatedLineItems, organizationPath: organizationPath ?? '' }));
  }

  function updatePaymentPlanIsActive(isActiveVal?: boolean) {
    //If there are no line items in the plan then we shouldn't threat it as an AdHoc payment
    if (!paymentPlan?.lineItems?.length) {
      updatePaymentLineItem(isActiveVal);
      return;
    }

    const updatedLineItems = [...(getListOrDefault(paymentPlan?.lineItems))];
    const idx = updatedLineItems.findIndex(l => l.id == lineItem.id);
    if (idx >= 0) {
      let additional = { isActive: isActiveVal, isEditing: false, isNew: false, amount: 0};

      updatedLineItems?.forEach((lineItem, index) => {
        if (!lineItem.isActive && idx !== index) {
          updatedLineItems[index] = {
            ...updatedLineItems[index],
            amount: 0
          }
        } else if (idx === index) {
          updatedLineItems[idx] = {
            ...updatedLineItems[idx],
            ...additional,
          }
        }
      });
      validateAll(updatedLineItems[idx]); //updates existing error messages when setting amount to 0
    }

    const hasActiveLineItem = updatedLineItems.findIndex(l => l.isActive) >= 0;

    dispatch(deactiveAllPlans());
    dispatch(updateRecord({ id: paymentPlan?.id, paymentPlan: { ...paymentPlan!, lineItems: updatedLineItems } }));
    dispatch(replaceLineItems({ lineItems: updatedLineItems, organizationPath: organizationPath ?? '' }));
    dispatch(resetSelected());
    dispatch(changeSelected({ id: paymentPlan!.id, isSelected: true }));
    if(isActiveVal || hasActiveLineItem){
      dispatch(setOperation(PlanOperations.AddhockPayment));
    }
    else{
      dispatch(resetOperation());
    }
  }

  const onClickCancel = useCallback(
    () => {
      !isPreview && dispatch(removeLineItem({ id: lineItemId, organizationPath: organizationPath ?? '' }))
        && dispatch(resetOperation());
    },
    [dispatch, lineItem, lineItems, panelConfiguration]
  );

  const onClickEdit = useCallback(
    () => {
      const updatedLineItem = {
        ...selectedLineItem,
        isEditing: true,
        isActive: true
      }

      if (isPreview && !isGl) {
        setPreviewLineItem(updatedLineItem);
      }
      else {
        const updatedLineItems = [...lineItems];
        const lineItemIdx = updatedLineItems.findIndex(l => l.id === lineItem.id);
        updatedLineItems[lineItemIdx] = updatedLineItem;
        dispatch(replaceLineItems({ lineItems: updatedLineItems, organizationPath: organizationPath ?? '' }));
      }
    },
    [dispatch, lineItem, lineItems, panelConfiguration]
  );

  function onChange(attr?: Attribute, value?: any) {
    if (onEditLineItems && !onEditLineItems()) {
      return;
    }
    if (type == LineItemType.Plan) {
      return onChangePlanAmount(attr, { ...lineItem }, value);
    }
    if (type == LineItemType.Sub) {
      return onChangePlanLineItem(attr, value);
    }
    if (type == LineItemType.Gl) {
      if (attr?.autocomplete) {
        onChangeAutocompleteField(attr, value);
        return;
      }
      const configurationFieldsofLineItem = configuration?.glDisplayFields?.find(conf => conf.description == lineItem.description)
      const autocompletedFields: (keyof LineItem)[] = ["glNumber", "billingDepartment", "unitCost"];
      if (configurationFieldsofLineItem?.description !== "Others" && attr?.name && autocompletedFields.includes(attr.name)) {
        const config = configuration?.glDisplayFields?.find(conf => conf.description == lineItem.description);
        if (config && config[attr.name]) {
          return;
        }
      }
    }
    onChangeAttribute(attr, value);
  }

  function onChangePlanLineItem(attr?: Attribute, value?: any) {
    if (paymentPlan && attr) {
      let updated = { ...attr, validators: [...attr.validators ?? []] };

      let maxValueValidator: GenericValidator<LineItem> = {
        name: ValidatorType.RangeValidator,
        message: `Maximum ${lineItem.balance} allowed.`,
        value: {
          min: 0,
          max: lineItem.balance ?? 0
        },
        required: true,
      }

      updated.validators.push(maxValueValidator);

      validate(updated, value, false, lineItem);

      const updatedLineItems = [...(getListOrDefault(paymentPlan.lineItems))];
      const idx = updatedLineItems.findIndex(l => l.id == lineItem.id);
      if (idx >= 0) {
        updatedLineItems[idx] = {
          ...updatedLineItems[idx],
          [attr.name]: Number(value)
        }
      }
      dispatch(updateRecord({ id: paymentPlan.id, paymentPlan: { ...paymentPlan,lineItems: updatedLineItems, addhocAmount: adhocAmount } }));
    }
  }

  let timeout = useRef<number>();
  useEffect(() => () => clearTimeout(timeout.current), []);
  let breakDownCallback = (paymentAmount: string) => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const updatedLineItems = await breakDown(+paymentAmount);
      if (updatedLineItems && paymentPlan) {
        dispatch(updateRecord({
          id: paymentPlan.id,
          paymentPlan: {
            ...paymentPlan,
            addhocAmount: + paymentAmount,
            lineItems: updatedLineItems
          }
        }))
      }
    }, 1000);
  }

  function onChangePlanAmount(attr?: Attribute, initLineItem?: LineItem, value?: string) {
    let paymentPlanAmount = lineItem.lineItems?.reduce((sum, l) => sum + (Number(l.amount || 0)), 0) || 0
    if (paymentPlan && paymentPlanAmount) {
      validate(attr, value, false, lineItem);
      let updatedLineItems = paymentPlan.lineItems
      if (!Number(value) && initLineItem?.addhocAmount) {
        updatedLineItems = paymentPlanBackup.lineItems;
      }
      dispatch(updateRecord({
        id: paymentPlan.id,
        paymentPlan: {
          ...paymentPlan,
          addhocAmount: + (value ?? 0),
          isOpen: true,
          lineItems: updatedLineItems
        }
      }));
      if (value) {
        breakDownCallback(value);
      }
    }
  }

  function onChangeAutocompleteField(attr?: Attribute, value?: any) {
    let updated = { ...lineItem };
    if (attr?.autocomplete && lineItem.description != value) {
      let set
      if (value !== "Others") {
        set = attr.autocomplete.configuration.find(conf => {
          if (attr.autocomplete && value) {
            return conf[attr.autocomplete.key] == value;
          }
          return false;
        });
      }

      if (set) {
        (Object.entries(set) as [keyof LineItem, any][])
          .forEach((pair) => {
            const [key, setValue] = pair;
            updated[key] = setValue || '';
          });
      }

    }

    if (attr) updated[attr?.name] = value;

    dispatch(updateLineItem({ organizationPath: organizationPath ?? '', lineItemId: lineItemId, value: updated }));
  }

  function shouldAmountBeSetToEmpty(attribute: Attribute, value: any): boolean {
    return !!isPayment &&
      !clientConfiguration?.isDefaultAmountSetToZero &&
      attribute.name === 'amount' &&
      Object.is(value, -0);
  }

  function addGenericAttributesToLineItems() {
    const isPaymentPage = !!(facilityId && departmentId);
    if (!isPreview && isPaymentPage) {
      const exclusionList = [ 'dateOfService', 'accountNumber', 'amount', 'description', 'collectionDate' ];
      attributes.filter((a: Attribute) => !exclusionList.includes(a.name))
      .forEach((attribute: Attribute) => {
        const value = getValue(selectedLineItem, attribute.name);
        const defaultValue = getDefaultValueByDataType(attribute.dataType, value);
        dispatch(setLineItemAttribute({
          lineItemId,
          attributeName: attribute.name,
          value: defaultValue,
          organizationPath: organizationPath ?? ''
        }));
      })
    }
  }

  useEffect(() => {
    const attr = getAttribute(attributes, 'description');
    if (attr && !lineItem.description) {
      const defaultValue = getValidator(attr, ValidatorType.RangeValidator)?.value.find((option: string) => option === 'Others');
      dispatch(setLineItemAttribute({
        lineItemId,
        attributeName: attr.name,
        value: defaultValue,
        organizationPath: organizationPath ?? ''
      }))
    }
    addGenericAttributesToLineItems();
  }, [])

  // sort the attributes by the order annotation
  const config = configuration as MetaData;
  let attributes = GetAttributes(externalAttributes, getListOrDefault, config, lineItems);
  const sortedAttributes = [...attributes].sort((a, b) => {
    if (a.position && b.position) {
      return a.position - b.position;
    }
    return 0;
  });
  attributes = sortedAttributes;
  const { validate, validateAll, hasErrors } = useLineItemValidator(attributes);

  function validateAttribute(attribute: Attribute, value: any) {
    if (facilityName && attribute.name === 'facility') {
      value = facilityName;
    }
    if (departmentName && attribute.name === 'department') {
      value = departmentName;
    }
    if (attribute.dataType == DataType.Icon) {
      value = (
        <div className="plan-icons">
          <PlanIcon />
          {lineItem.status == EnumPlanStatus.Active ? <Timer /> : null}
          {lineItem.status == EnumPlanStatus.Paused ? <Pause /> : null}
          {lineItem.status == EnumPlanStatus.Stopped ? <Stop /> : null}
        </div>
      );
    }
    return value;
  }

  function validateAttributeVisibility(attribute: string | undefined) {
    if (isPayment || isPreview || type == LineItemType.Normal) {
      return true;
    }

    if (attribute != 'amount') {
      return true;
    }

    if (!isPayment && !planAdhoc && selectedLineItem.isActive) {
      return true;
    }

    return false;
  }

  function validateEditableAttribute(attribute: string | undefined, lineItem: LineItem) {
    if (!isPayment && !planAdhoc && attribute == 'amount') {
      return true;
    }

    return lineItem.isEditing;
  }

  const maxBalanceValidator = (attribute: string | undefined, lineItem: LineItem) => {
    return !isPayment && !planAdhoc && attribute == 'amount' && lineItem.amount > lineItem.balance! ?
      <div className="error-message-required">Maximum ${lineItem.balance!} allowed.</div>
      : null;
  }

  const components = attributes
    .filter((a: Attribute) => a.name !== 'isActive' && a.name !== 'isEditing')
    .map((attribute: Attribute, index: number) => {

      const attributeName = attribute.name;

      let value = getValue(selectedLineItem, attributeName);
      if (attribute.saveAs !== "serviceType" && selectedLineItem.isEditing) {
        value = checkReceivedHasValues(!attribute.saveAs?.includes('undefined'), getValue(selectedLineItem, attributeName), '');
        value = checkReceivedHasValues(shouldAmountBeSetToEmpty(attribute, value), '', value);
        value = validateAttribute(attribute, value);
      }

      let isBalanceZero = Number(selectedLineItem.balance) === 0;

      const requiredFieldClass = attribute.required ? 'required-field' : '';
      const modifiers = `line-item-attribute ${attribute.name} ${requiredFieldClass}`;
      let currentAttribute = attribute.name === openField || false;
      if (openField === "undefined") {
        currentAttribute = attribute.newCustomFieldName === selectedCustomField;
      }

      const key = `${attribute.name}-${index}-container`;

      return (
        <div key={key} onClick={(e) => {
          if (!isPayment) {
            return;
          }
          e.stopPropagation();
          onClickComponentAttribute && onClickComponentAttribute(attribute, value);
          return false;
        }}
          className={`${attribute.className} ${attribute.required ? 'attribute-required' : ''} ${isPreview && currentAttribute ? 'line-item-selected' : ''}`
          }
          style={{ display: validateAttributeVisibility(attribute.saveAs) ? 'block' : 'none' }}
          id="lineItem"
        >
          <ComponentFactory
            attribute={attribute}
            isEditing={validateEditableAttribute(attribute.saveAs, selectedLineItem)}
            lineItem={selectedLineItem}
            isPreview={isPreview}
            lineItemType={type}
            isBalanceZero={isBalanceZero}
            key={`${attribute.name}-${lineItemId}`}
            id={`${attribute.name}-${lineItemId}`}
            value={value}
            onClick={value => onClickComponentAttribute(attribute, value)}
            onChange={newValue => onChange(attribute, newValue)}
            onBlur={value => onBlur(attribute, value)}
            modifiers={modifiers}
            disabled={!!disabledFields?.find(f => f == attribute.name)}
            isPaymentOrPreviewPage={isPaymentOrPreviewPage}
          />
          {maxBalanceValidator(attribute.saveAs, value)}
        </div>
      );
    });

  const displayTotalBalance = (value: number) => {
    const balanceValue = displayBalanceAmount(displayAmount(value));
    return (
      <>
        <span>$</span>
        {balanceValue.firstPart}
        <span>{balanceValue.lastPart}</span>
      </>
    );
  };

  const displayadHocBreakDown = (value: number) => {
    const balanceValue = displayBalanceAmount(displayAmount(value));
    return (
      <>
        <span>-$</span>
        {balanceValue.firstPart}
        <span>{balanceValue.lastPart}</span>
      </>
    );
  };

  const getPlanStatus = (status: EnumPlanStatus) => {
    if (status === EnumPlanStatus.Active)
      return <Timer />;
    else if (status === EnumPlanStatus.Paused)
      return <Pause />
    else if (status === EnumPlanStatus.Stopped || status === EnumPlanStatus.Cancelled)
      return <Stop />
  }

  const isToolsNeeded = AddTools(selectedLineItem, isPreview);
  const getCheckBoxControls = () => {
    return (
      <div style={{ display: ((!planAdhoc && !isPayment) || (isPayment)) ? 'block' : 'none' }}>
        <input
          className={'eds-checkbox_#input'}
          type={'checkbox'}
          checked={selectedLineItem.isActive}
          name={'active'}
          onChange={(e) => {handleCheckboxChange(e); }}
        />
      </div>
    );
  }
  const getAdHocPaymentAmount = (amount?: number) => {
    return !isPayment ? (
      <>
        <p className={'line-item-balance-adhoc'}>
          {displayadHocBreakDown(amount!)}
        </p>
      </>
    ) : null
  }

  const adHocPaymentAmount = () => {
    return (
      <>
        {planAdhoc && paymentPlan?.isActive && adhocAmount! > 0 ? getAdHocPaymentAmount(adhocAmount) : null}
        {!planAdhoc && selectedLineItem.isActive ? getAdHocPaymentAmount(selectedLineItem.amount) : null}
      </>
    )
  }

  return (
    <div
      style={{ borderLeftColor: borderColor ?? "#aaaaaa" }}
      className={getLineItemContainerClass()}
      key={lineItem.id}
    >
      {type === LineItemType.Gl && !facility?.id ? (
        <div
          className={'disable-overlay'}
          onClick={e => {
            onEditLineItems && onEditLineItems();
            e.preventDefault();
            return false;
          }}
        ></div>
      ) : null}
      <div className="tools">
        {isToolsNeeded && (
          <>
            {getCheckBoxControls()}

            {(selectedLineItem.isNew || selectedLineItem.isActive) ?
              <div className="status-icon" id="status-icon">
                {getPlanStatus(lineItem.status as EnumPlanStatus)}
              </div>
              : <></>}


            {IsEditableLineItem(selectedLineItem, isPreview, type, isPayment) ?
              <EDS_Button
                iconName={'edit'}
                modifiers={'eds-button.tertiary edit-button'}
                disabled={wizardActive}
                onClick={onClickEdit}
                ariaLabel={'Edit Line Item'}
              /> : <></>}
          </>
        )}
      </div>
      <div className="line-item-container">
        <div
          className={getContractedClassName()}
        >
          {components}
        </div>
        {type === LineItemType.Normal && (
          <div className="expand-button-container">
            <i
              className={`eds-button_#icon material-icons`}
              role={'img'}
              aria-label={'expand'}
              onClick={() =>
                dispatch(
                  setLineItemAttribute({
                    lineItemId,
                    attributeName: 'isContracted',
                    value: !lineItem.isContracted,
                    organizationPath: organizationPath ?? ''
                  })
                )
              }
            >
              {lineItem.isContracted ? 'expand_more' : 'expand_less'}
            </i>
          </div>
        )}
      </div>
      <div className={'line-item-buttons'}>
        {lineItem.isNew && type !== LineItemType.Sub && (
          <EDS_Button
            iconName={'cancel'}
            modifiers={'eds-button.tertiary remove-button'}
            disabled={wizardActive}
            onClick={onClickCancel}
            ariaLabel={'Remove Line Item'}
          />
        )}
        {(selectedLineItem.isEditing) && (
          <>
            {(type === LineItemType.Normal || type === LineItemType.Gl) && (
              <EDS_Button
                buttonText={'Submit'}
                modifiers={'eds-button.primary submit-button'}
                disabled={wizardActive}
                onClick={onClickSubmit}
                ariaLabel={'Submit'}
              />
            )}
          </>
        )}
        {(!selectedLineItem.isEditing) && (<>
          <div className="line-item-attribute">
            <p className={'line-item-attribute-label'}>
              {' '}
              {getPaymentDueLabel()}
            </p>
            <p className={'line-item-balance'}>
              {displayTotalBalance(selectedLineItem.balance ?? 0)}
            </p>
            {adHocPaymentAmount()}

          </div>
        </>
        )}
      </div>
    </div>
  );
}

function GetAttributes(externalAttributes: Attribute[] | undefined, getListOrDefault: (list: any) => any, config: MetaData, lineItems: LineItem[]) {
  let attributes = [...externalAttributes ?? getListOrDefault(config?.entity?.attributes)];
  const index = attributes.findIndex((a: Attribute) => a.name === "accountNumber");
  const accountNumberAttribute = attributes[index];
  attributes[index] = {
    ...accountNumberAttribute,
    validators: [...accountNumberAttribute?.validators ?? []]
  }
  return attributes;
}

function AddTools(selectedLineItem: LineItem, isPreview: boolean | undefined) {
  return (!selectedLineItem.isEditing || isPreview);
}

function IsEditableLineItem(selectedLineItem: LineItem, isPreview: boolean | undefined, type: LineItemType, isPayment?: boolean) {
  return (selectedLineItem.isActive || isPreview || isPayment) && (type === LineItemType.Normal || type === LineItemType.Gl);
}

function AddBorderColor(props: { borderColor?: string; }, setBorderColor: React.Dispatch<React.SetStateAction<string | undefined>>, configuration: PaymentConfiguration) {
  if (props.borderColor) {
    setBorderColor(props.borderColor);
  }
  else {
    const entityConfig = configuration as MetaData;
    setBorderColor(entityConfig?.entity?.colorCode);
  }
}

function GetClassName(lineItem: LineItem, hasErrors: (lineItem?: LineItem | undefined) => boolean, className: string) {
  if (lineItem.isContracted && !hasErrors(lineItem)) {
    className += ' contracted';
  }
  else if (lineItem.isContracted && hasErrors(lineItem)) {
    className += ' contracted-withErrors';
  }
  return className;
}

function getValue(lineItem: LineItem, name: keyof LineItem) {
  return lineItem[name];
}
