import { callHttpApi } from './callHttpApi';
import { Organization, OrganizationSyncDetail } from 'models/Client';

export async function getOrganizationSync(
  ids: string[]
) {
  return await callHttpApi<OrganizationSyncDetail[], string[]>(
    'post',
    `api/v1/OrganizationSync/GetHistories`,
    ids
  );
}

export async function syncOrganization(data: Organization) {
  return await callHttpApi<string,Organization>(
    'post',
    `/api/v1/OrganizationSync`,
    data
  );
}