import { callHttpApi } from './callHttpApi';

interface RequestType {
  transactionId?: string;
  emailAddresses: string[];
}

export interface ResponseType {
  err?: string;
}

export async function sendEmailReceipts(id: string, data: RequestType) {
  return callHttpApi<ResponseType, RequestType>(
    'post',
    `api/v1/Transactions/${id}/email`,
    data
  );
}
