import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { configureLineItemsMetadataService } from 'services/MetadataService';
import { AppThunk } from 'app/rootReducer';
import getFakeMetaDataRegularLineItemsForClient from 'utils/metadata/MetadataLineItemsFakeConfig';
import { callService } from '../../../services/ServicesReducer';

export interface ClientMetaDataState {
  path: string;
  orgLevelId: string;
  application: string;
  name: string;
  description: string;
  version: string;
  modifiedTime: Date;
  isHidden: boolean;
  entities: string;
}

export interface State {
  value: ClientMetaDataState;
  err?: string;
}

export const initialState: State = {
  value: {
    path: '',
    orgLevelId: '',
    application: '',
    name: '',
    description: '',
    version: '',
    modifiedTime: new Date(),
    isHidden: false,
    entities: JSON.stringify(
      getFakeMetaDataRegularLineItemsForClient().entities
    ),
  },
  err: '',
};

const reducerSlice = createSlice({
  name: 'ClientMetaData',
  initialState,
  reducers: {
    setClientMetaData(state, action: PayloadAction<ClientMetaDataState>) {
      state.value = action.payload;
    },
  },
});

export default reducerSlice.reducer;

export function configureLineItemsMetadata(
  clientMetadata: ClientMetaDataState,
  onDone?: () => void
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(callService("configureLineItemsMetadata", async () => {
      await configureLineItemsMetadataService(clientMetadata);
      if (onDone) onDone();
    }))
  };
}

export const { setClientMetaData } = reducerSlice.actions;
